import { render, staticRenderFns } from "./component-preview.vue?vue&type=template&id=6ebbf605&scoped=true&lang=html"
import script from "./component-preview.vue?vue&type=script&lang=ts"
export * from "./component-preview.vue?vue&type=script&lang=ts"
import style0 from "./item-preview.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "./component-preview.vue?vue&type=style&index=1&id=6ebbf605&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ebbf605",
  null
  
)

export default component.exports