
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> オペレーション",
	"arg_type_-1": "?",
	"arg_type_1": "番号",
	"arg_type_2": "鎖",
	"arg_type_3": "ブール値",
	"arg_type_4": "リスト",
	"arg_type_41": "リスト<番号>",
	"arg_type_42": "リスト<文字列>",
	"arg_type_43": "リスト<ブール値>",
	"arg_type_44": "リスト<リスト>",
	"arg_type_46": "リスト<整数>",
	"arg_type_47": "リスト<実数>",
	"arg_type_5": "関数",
	"arg_type_6": "全体",
	"arg_type_7": "本物",
	"arg_type_8": "テーブル",
	"arg_type_806": "テーブル<?, 整数>",
	"complexity": "複雑さ {c}",
	"operations": "{o} 操作",
	"details": "詳細",
	"optional": "オプション",
	"deprecated_function": "この関数は非推奨です。",
	"deprecated_constant": "この定数は非推奨です。",
	"replaced_by": "{0} に置き換えられます。",
	"const_AREA_CIRCLE_1": "円形エリア 直径 3 マス (クロス)。",
	"const_AREA_CIRCLE_2": "直径5マスの円形エリア。",
	"const_AREA_CIRCLE_3": "直径7マスの円形エリア。",
	"const_AREA_LASER_LINE": "レーザーのゾーン、レーザーの最小範囲から最大範囲または障害物までの線。",
	"const_AREA_POINT": "1 つのボックスで構成される領域。",
	"const_CELL_EMPTY": "空のセルに対する getCellContent(cell) の戻り値。",
	"const_CELL_OBSTACLE": "障害物を含むセルの getCellContent(cell) の戻り値。",
	"const_CELL_PLAYER": "エンティティを含むセルの getCellContent(cell) の戻り値。",
	"const_CELL_ENTITY": "エンティティを含むセルの getCellContent(cell) の戻り値。",
	"const_COLOR_BLUE": "青色。",
	"const_COLOR_GREEN": "緑色。",
	"const_COLOR_RED": "赤色。",
	"const_E": "オイラー数。",
	"const_EFFECT_ABSOLUTE_SHIELD": "エンティティに絶対シールドを付与し、ダメージ (EFFECT_DAMAGE) によって失われるヒット ポイントの量を一定量減少させます。抵抗によって増幅されます。",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "エンティティから絶対シールドを削除します。特性によって増幅されません。ダメージ (EFFECT_DAMAGE) によって失われるヒット ポイントを絶対値で増加させることができます。",
	"const_EFFECT_AFTEREFFECT": "エンティティからヒット ポイントを削除します。科学によって増幅されます。最大ヒット ポイントを、削除したヒット ポイントの 5% だけ減少させます。",
	"const_EFFECT_ANTIDOTE": "ターゲットに存在するすべての毒 (EFFECT_POISON) を取り除きます。",
	"const_EFFECT_BOOST_MAX_LIFE": "エンティティのヒット ポイントと最大ヒット ポイントを増加させます。知恵によって増幅されます。",
	"const_EFFECT_BUFF_AGILITY": "エンティティに敏捷性を付与します。科学によって増幅されます。",
	"const_EFFECT_BUFF_MP": "エンティティに移動ポイントを付与します。科学によって増幅されます。",
	"const_EFFECT_BUFF_RESISTANCE": "エンティティに耐性を付与します。科学によって増幅されます。",
	"const_EFFECT_BUFF_STRENGTH": "エンティティに強さを与えます。科学によって増幅されます。",
	"const_EFFECT_BUFF_TP": "エンティティにアクション ポイントを付与します。科学によって増幅されます。",
	"const_EFFECT_BUFF_WISDOM": "エンティティに知恵を与えます。科学によって増幅されます。",
	"const_EFFECT_DAMAGE": "エンティティからヒット ポイントを削除します。増幅された力。シールド (EFFECT_ABSOLUTE_SHIELD、EFFECT_RELATIVE_SHIELD、EFFECT_VULNERABILITY、EFFECT_ABSOLUTE_VULNERABILITY)、ライフスティール (キャスターを除く)、およびダメージ回復 (EFFECT_DAMAGE_RETURN) と相互作用します。最大ヒット ポイントを、削除されたヒット ポイントの量の 5% 減少させます。",
	"const_EFFECT_DAMAGE_RETURN": "エンティティにダメージ反射を提供し、受信者にダメージを与えているエンティティからヒット ポイントを削除できるようにします。敏捷性によって増幅されます。最大ヒット ポイントを、削除したヒット ポイントの 5% だけ減少させます。",
	"const_EFFECT_DEBUFF": "エンティティに対するすべての効果の値をパーセンテージで減らします。",
	"const_EFFECT_HEAL": "最大ヒット ポイントによって制限された、エンティティのヒット ポイントを回復します。知恵によって増幅されます。",
	"const_EFFECT_INVERT": "キャスターの位置をエンティティの位置と入れ替えます。",
	"const_EFFECT_KILL": "エンティティからすべてのヒット ポイントを削除します。",
	"const_EFFECT_LIFE_DAMAGE": "術者のヒット ポイントのパーセンテージに基づいて、エンティティからヒット ポイントを削除します。シールド (EFFECT_ABSOLUTE_SHIELD、EFFECT_RELATIVE_SHIELD、EFFECT_VULNERABILITY、EFFECT_ABSOLUTE_VULNERABILITY) およびダメージ リターン (EFFECT_DAMAGE_RETURN) と相互作用します。最大ヒット ポイントを、削除したヒット ポイントの 5% だけ減少させます。",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "効果は、エリア内の影響を受けるエンティティの数で乗算されます。",
	"const_EFFECT_MODIFIER_ON_CASTER": "効果は引き続き術者に影響します。",
	"const_EFFECT_MODIFIER_STACKABLE": "効果は累積します。",
	"const_EFFECT_NOVA_DAMAGE": "最大ライフポイントを削除します。科学によって増幅されます。",
	"const_EFFECT_POISON": "エンティティからヒット ポイントを削除します。魔法で増幅。最大ヒット ポイントを、削除したヒット ポイントの 10% だけ減らします。",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "エンティティに絶対シールドを付与し、ダメージ (EFFECT_DAMAGE) によって失われるヒット ポイントの量を一定量減少させます。増幅できません。",
	"const_EFFECT_RAW_BUFF_AGILITY": "エンティティに敏捷性を付与します。増幅できません。",
	"const_EFFECT_RAW_BUFF_MAGIC": "エンティティに魔法を付与します。増幅できません。",
	"const_EFFECT_RAW_BUFF_MP": "エンティティに移動ポイントを付与します。増幅できません。",
	"const_EFFECT_RAW_BUFF_SCIENCE": "エンティティに科学を付与します。増幅できません。",
	"const_EFFECT_RAW_BUFF_STRENGTH": "エンティティに強さを与えます。増幅できません。",
	"const_EFFECT_RAW_BUFF_TP": "エンティティにアクション ポイントを付与します。増幅できません。",
	"const_EFFECT_RELATIVE_SHIELD": "相対的なシールドを提供し、ダメージ (EFFECT_DAMAGE) によって失われるヒット ポイントの量を相対的な量だけ減らします。抵抗によって増幅されます。",
	"const_EFFECT_RESURRECT": "エンティティを復活させます。最大 HP 値はエンティティの復活前の最大 HP 値の半分に等しく、現在の HP 値は復活前の最大 HP 値の 4 分の 1 に等しくなります。",
	"const_EFFECT_SHACKLE_MAGIC": "エンティティから魔法を取り除きます。魔法で増幅。",
	"const_EFFECT_SHACKLE_MP": "エンティティから移動ポイントを削除します。魔法で増幅。",
	"const_EFFECT_SHACKLE_STRENGTH": "エンティティから力を取り除きます。魔法で増幅。",
	"const_EFFECT_SHACKLE_TP": "エンティティからアクション ポイントを差し引きます。魔法で増幅。",
	"const_EFFECT_SUMMON": "電球を召喚します。チーム召喚上限に達した場合は効果なし。",
	"const_EFFECT_TARGET_ALLIES": "味方に影響を与えます。",
	"const_EFFECT_TARGET_CASTER": "キャスターに影響します。",
	"const_EFFECT_TARGET_ENEMIES": "敵に影響を与えます。",
	"const_EFFECT_TARGET_NON_SUMMONS": "召喚されていないエンティティ (ネギとタレット) に影響を与えます。",
	"const_EFFECT_TARGET_SUMMONS": "召喚されたエンティティ (電球) に影響を与えます。",
	"const_EFFECT_TELEPORT": "キャスターの位置を変更します。",
	"const_EFFECT_VULNERABILITY": "エンティティに関連するシールドを削除します。特性によって増幅されません。ダメージ (EFFECT_DAMAGE) によって除去されたヒット ポイントを相対的な量だけ増加させます。",
	"const_ENTITY_BULB": "電球タイプのエンティティを指します。",
	"const_ENTITY_LEEK": "ニラ型の実体を指します。",
	"const_ENTITY_TURRET": "タレット型エンティティを指します。",
	"const_FIGHT_CONTEXT_CHALLENGE": "チャレンジタイプの戦闘背景。",
	"const_FIGHT_CONTEXT_GARDEN": "菜園での戦いの文脈。",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "バトルロワイヤルでの戦闘のコンテキスト。",
	"const_FIGHT_CONTEXT_TEST": "戦闘状況をテストします。",
	"const_FIGHT_CONTEXT_TOURNAMENT": "トーナメント戦の背景。",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "バトルロワイヤルで戦う。",
	"const_FIGHT_TYPE_FARMER": "ブリーダーファイト。",
	"const_FIGHT_TYPE_SOLO": "ソロファイト。",
	"const_FIGHT_TYPE_TEAM": "チーム戦。\n",
	"const_INSTRUCTIONS_LIMIT": "エンティティがターン中に使用できる命令の最大数。",
	"const_MAX_TURNS": "戦闘中の最大ターン数。",
	"const_OPERATIONS_LIMIT": "ターン中にエンティティが使用できる操作の最大数。",
	"const_PI": "円の直径に対する円周の比率。",
	"const_SORT_ASC": "#sort 関数に昇順で並べ替えるように指示します。",
	"const_SORT_DESC": "#sort 関数に降順でソートするように指示します。",
	"const_TYPE_ARRAY": "値型 <b>リスト</b>",
	"const_TYPE_BOOLEAN": "値のタイプ <b>boolean</b>",
	"const_TYPE_FUNCTION": "値の型 <b>機能</b>",
	"const_TYPE_NULL": "値のタイプ <b>null</b>",
	"const_TYPE_NUMBER": "値のタイプ <b>数値</b>",
	"const_TYPE_STRING": "値の型 <b>文字列</b>",
	"const_USE_CRITICAL": "クリティカル ヒットが発生した場合に、#useWeapon、#useWeaponOnCell、#useChip、および #useChipOnCell 関数によって返される値。",
	"const_USE_FAILED": "失敗時に #useWeapon、#useWeaponOnCell、#useChip、および #useChipOnCell 関数によって返される値。",
	"const_USE_INVALID_COOLDOWN": "チップがまだ使用可能でない場合、#useChip および #useChipOnCell 関数によって返される値。",
	"const_USE_INVALID_POSITION": "#useWeapon、#useWeaponOnCell、#useChip、#useChipOnCell 関数が範囲が悪い場合や見通しが悪い場合に返す値。",
	"const_USE_INVALID_TARGET": "ターゲットが存在しない場合に #useWeapon および #useChip 関数によって返される値。",
	"const_USE_NOT_ENOUGH_TP": "キャスターがオブジェクトを使用するのに十分なアクション ポイントを持っていない場合、#useWeapon、#useWeaponOnCell、#useChip、および #useChipOnCell 関数によって返される値。",
	"const_USE_RESURRECT_INVALID_ENTITY": "指定されたエンティティが存在しないか、まだ死んでいない場合に #resurrect 関数によって返される値。",
	"const_USE_SUCCESS": "成功時に #useWeapon、#useWeaponOnCell、#useChip、および #useChipOnCell 関数によって返される値。",
	"const_USE_TOO_MANY_SUMMONS": "すでに <b>8</b> 個のライブ召喚がある場合、#summon によってエラーが返されます。\n",
	"func_abs": "数値 <b>number</b> の絶対値を返します。",
	"func_abs_arg_1": "絶対値を計算する数値。",
	"func_abs_return": "数値の絶対値。",
	"func_acos": "<b>argument</b> のアークコサインを区間 [0, #PI] で計算します。",
	"func_acos_arg_1": "逆余弦が計算される数値。",
	"func_acos_return": "<b>引数</b>の逆余弦。",
	"func_arrayConcat": "2 つの配列を端から端まで追加します。テキスト キーは保持され、すべての数字キーは再インデックスされます。",
	"func_arrayConcat_arg_1": "最初のテーブル。",
	"func_arrayConcat_arg_2": "2 番目のテーブル。",
	"func_arrayConcat_return": "2 つの配列の合計。",
	"func_arrayFilter": "コールバック関数が true を返したソース配列から、すべてのキーと値のペアを含む新しい配列を返します。コールバック関数が 1 つのパラメータを取る場合はソース配列の値が送信され、2 つのパラメータを取る場合はキーと値が送信されます。",
	"func_arrayFilter_arg_1": "オリジナルテーブル。",
	"func_arrayFilter_arg_2": "各要素に対して呼び出される関数。",
	"func_arrayFilter_return": "新しいテーブル。",
	"func_arrayFlatten": "ソース配列のすべての要素を含む新しい配列を返します。サブ配列に含まれるすべての要素が新しい配列に抽出されます.深さ引数は、抽出するサブ配列の最大深さを決定します.\n<code>var array = [1, 2, [[3, 4], 5], [6]];\ndebug(arrayFlatten(array, 1)); // [1,2,[3,4],5,6]</code>",
	"func_arrayFlatten_arg_1": "オリジナルテーブル。",
	"func_arrayFlatten_arg_2": "最大深度。",
	"func_arrayFlatten_return": "新しいテーブル。",
	"func_arrayFoldLeft": "配列 <b>array</b> [v1, v2, ..., vn] を値 <b>v0</b> から始めて左から減らし、関数 <b>f</b> を適用します。等しい :\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "オリジナルテーブル。",
	"func_arrayFoldLeft_arg_2": "適用する関数。",
	"func_arrayFoldLeft_arg_3": "開始値。",
	"func_arrayFoldLeft_return": "削減されたテーブル。",
	"func_arrayFoldRight": "配列 <b>array</b> [v1, v2, ..., vn] を値 <b>v0</b> から始めて、関数 <b>f</b> を適用して右から縮小します。等しい :\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "オリジナルテーブル。",
	"func_arrayFoldRight_arg_2": "適用する関数。",
	"func_arrayFoldRight_arg_3": "開始値。",
	"func_arrayFoldRight_return": "削減されたテーブル。",
	"func_arrayIter": "配列要素ごとにコールバック関数を呼び出します。コールバック関数が 1 つのパラメータを取る場合はソース配列の値が送信され、2 つのパラメータを取る場合はキーと値が送信されます。",
	"func_arrayIter_arg_1": "オリジナルテーブル。",
	"func_arrayIter_arg_2": "各要素に対して呼び出される関数。",
	"func_arrayMap": "ソース配列のキーごとに、コールバック関数によって返される値を含む新しい配列を返します。コールバック関数が 1 つのパラメータを取る場合はソース配列の値が送信され、2 つのパラメータを取る場合はキーと値が送信されます。",
	"func_arrayMap_arg_1": "オリジナルテーブル。",
	"func_arrayMap_arg_2": "各要素に対して呼び出される関数。",
	"func_arrayMap_return": "新しいテーブル。",
	"func_arrayMax": "<b>array</b> 配列の最大値要素を返します。<br/>配列の最大値の詳細については、#sort を参照してください。",
	"func_arrayMax_arg_1": "最大値を求める配列。",
	"func_arrayMax_return": "最高値アイテム。",
	"func_arrayMin": "<b>array</b> 配列の最小値要素を返します。<br/>配列の最小値の詳細については、#sort を参照してください。",
	"func_arrayMin_arg_1": "最小値が検索される配列。",
	"func_arrayMin_return": "最小値アイテム。",
	"func_arrayPartition": "2 つのリストを含む新しい配列を返します。1 つ目は、コールバック関数が true を返したすべてのキーと値のペアで、2 つ目はその他すべてです。コールバック関数が 1 つのパラメータを取る場合はソース配列の値が送信され、2 つのパラメータを取る場合はキーと値が送信されます。",
	"func_arrayPartition_arg_1": "オリジナルテーブル。",
	"func_arrayPartition_arg_2": "各要素に対して呼び出される関数。",
	"func_arrayPartition_return": "新しいテーブル。",
	"func_arraySort": "コールバック関数で定義された順序に従って配列をソートします。要素は 2 つずつ比較されます。コールバック関数は、最初の値が 2 番目の値の前、同じレベル、または後にあるかに応じて、値 -1、0、または 1 を返す必要があります。コールバック関数が 2 つのパラメータを取る場合は 2 つの値が送信され、4 つを取る場合はキーと値のペアが送信されます。",
	"func_arraySort_arg_1": "元のテーブル",
	"func_arraySort_arg_2": "ソート機能。",
	"func_arraySort_return": "ソートされた配列",
	"func_asin": "<b>argument</b> のアークサインを区間 [0, #PI] で計算します。",
	"func_asin_arg_1": "逆正弦が計算される数値。",
	"func_asin_return": "<b>引数</b>の逆正弦。",
	"func_assocSort": "配列 <b>array</b> を順序 <b>order</b> に従ってキー:値の関連付けを維持しながら並べ替えます。",
	"func_assocSort_arg_1": "並べ替える配列。",
	"func_assocSort_arg_2": "ソート順: #SORT_ASC または #SORT_DESC。",
	"func_atan": "<b>argument</b> の逆正接を区間 [0, #PI] で計算します。",
	"func_atan2": "デカルト座標 (<b>x</b>、<b>y</b>) を極座標 (<b>r</b>、<b>theta</b>) に変換します。この関数は、引数の符号を使用して、-#PI と #PI の間の <b>theta</b> 角度を返します。",
	"func_atan2_arg_1": "Y 座標。",
	"func_atan2_arg_2": "X 座標。",
	"func_atan2_return": "ポイント (x, y) の極座標での <b>theta</b> 角度。",
	"func_atan_arg_1": "逆正接が計算される数値。",
	"func_atan_return": "<b>引数</b>の逆正接。",
	"func_average": "配列 <b>array</b> に含まれる要素の平均を計算します。",
	"func_average_arg_1": "平均を計算する配列。",
	"func_average_return": "平均値。",
	"func_canUseChip": "ID <b>entity</b> のエンティティでエンティティが <b>chip</b> チップを使用できるかどうかを決定します。",
	"func_canUseChip_arg_1": "テストするチップの番号。",
	"func_canUseChip_arg_2": "チップを使用するエンティティ ID。",
	"func_canUseChip_return": "エンティティがチップを使用できる場合は <i>true</i>、そうでない場合は <i>false</i>。",
	"func_canUseChipOnCell": "エンティティが <b>cell</b> セルで <b>chip</b> チップを使用できるかどうかを決定します。",
	"func_canUseChipOnCell_arg_1": "テストするチップの番号。",
	"func_canUseChipOnCell_arg_2": "チップを使用するセルの番号。",
	"func_canUseChipOnCell_return": "エンティティがチップを使用できる場合は <i>true</i>、それ以外の場合は <i>false</i>。",
	"func_canUseWeapon": "エンティティが ID <b>entity</b> のエンティティを武器 <b>weapon</b> で撃つことができるかどうかを決定します。",
	"func_canUseWeapon_arg_1": "テストする武器。現在装備している武器がデフォルトです。",
	"func_canUseWeapon_arg_2": "起動したいエンティティの ID。",
	"func_canUseWeapon_return": "エンティティが発砲できる場合は <i>true</i>、そうでない場合は <i>false</i>。",
	"func_canUseWeaponOnCell": "エンティティが <b>武器</b> 武器で <b>セル</b> セルを撃つことができるかどうかを決定します。",
	"func_canUseWeaponOnCell_arg_1": "テストする武器。現在装備している武器がデフォルトです。",
	"func_canUseWeaponOnCell_arg_2": "撃ちたいセルの番号。",
	"func_canUseWeaponOnCell_return": "エンティティが発砲できる場合は <i>true</i>、そうでない場合は <i>false</i>。",
	"func_cbrt": "<b>数値</b>の立方根を計算する",
	"func_cbrt_arg_1": "立方根を計算する数値。",
	"func_cbrt_return": "<b>number</b> の立方根。",
	"func_ceil": "<b>number</b> の切り上げを返します。<br/>切り捨ては #floor で、切り捨ては #round で見つけることができます。",
	"func_ceil_arg_1": "上限の丸めを求める数値。",
	"func_ceil_return": "<b>数値</b>を切り上げます。",
	"func_charAt": "位置 <b>position</b> にある文字列 <b>string</b> の文字を返します。<br/>文字列の最初の文字は位置 0 にあります。",
	"func_charAt_arg_1": "文字を取得する文字列。",
	"func_charAt_arg_2": "検索する文字の位置。",
	"func_charAt_return": "検索された文字を含む文字列、または位置が無効な場合は空の文字列。",
	"func_chipNeedLos": "<b>チップ</b>が見通し線を使用する必要があるかどうかを返します。",
	"func_chipNeedLos_arg_1": "テストするチップの ID。",
	"func_chipNeedLos_return": "<b>チップ</b>が見通し線を使用する必要がある場合は<i>true</i>、それ以外の場合は<i>false</i>。",
	"func_clearMarks": "フィールド内の mark() および markText() によって作成されたすべてのマーキングをクリアします。",
	"func_clone": "<b>level</b> レベルでパラメータとして渡された <b>value</b> をコピーし、そのコピーを返します。たとえば、配列の場合、clone(array, 1) は配列をコピーしますが、その要素はコピーしません。clone(array, 2) は配列とすべての要素をコピーします。",
	"func_clone_arg_1": "複製する値",
	"func_clone_arg_2": "複製するレベルの数",
	"func_clone_return": "複製された値",
	"func_contains": "<b>search</b> 文字列が <b>string</b> 文字列内にあるかどうかを判断します。",
	"func_contains_arg_1": "検索が実行される文字列。",
	"func_contains_arg_2": "検索する文字列。",
	"func_contains_return": "<b>search</b> が <b>string</b> にある場合は true、そうでない場合は false。",
	"func_cos": "<b>角度</b>のコサインを計算します。",
	"func_cos_arg_1": "コサインを計算する数値 (ラジアン)。",
	"func_cos_return": "[-1, 1] の範囲の <b>角度</b> の余弦。",
	"func_count": "<b>array</b> 配列の要素数をカウントします。",
	"func_count_arg_1": "要素数をカウントする配列。",
	"func_count_return": "配列要素の数。",
	"func_debug": "個人ログに <b>オブジェクト</b> メッセージを記録し、戦闘終了時にレポートで確認できます。",
	"func_debug_arg_1": "記録するメッセージ。",
	"func_debugC": "<b>オブジェクト</b> メッセージを個人ログに記録します。これは、戦闘終了時にレポートで利用できる、色 <b>色</b> のものです。",
	"func_debugC_arg_1": "記録するメッセージ。",
	"func_debugC_arg_2": "メッセージの色。 #getColor 関数を使用できます。",
	"func_debugE": "<b>オブジェクト</b>のエラー メッセージを個人ログに記録し、戦闘終了時にレポートで確認できます。<br />エラー メッセージは戦闘レポートに赤で表示されます。",
	"func_debugE_arg_1": "ログに記録するエラー メッセージ。",
	"func_debugW": "<b>オブジェクト</b>の警告メッセージを個人ログに記録し、戦闘終了時にレポートで確認できます。 <br />警告メッセージは、戦闘レポートにオレンジ色で表示されます。",
	"func_debugW_arg_1": "記録する警告メッセージ。",
	"func_deleteRegister": "キー <b>key</b> に関連付けられているレジストリが存在する場合は削除します。",
	"func_deleteRegister_arg_1": "削除するレジストリ キー。",
	"func_endsWith": "<b>string</b> が <b>suffix</b> 文字列で終わるかどうかを判断します。",
	"func_endsWith_arg_1": "検索が実行される文字列",
	"func_endsWith_arg_2": "検索するサフィックス",
	"func_endsWith_return": "<b>string</b> が <b>suffix</b> で終わる場合は true",
	"func_exp": "オイラー数 #E を <b>数値</b> 乗します。",
	"func_exp_arg_1": "#E が累乗される指数。",
	"func_exp_return": "#E** <b>番号</b>。",
	"func_fill": "配列 <b>array</b> のサイズを <b>size</b> に変更し、すべてのボックスを <b>value</b> で埋めます。",
	"func_fill_arg_1": "完成するテーブル。",
	"func_fill_arg_2": "配列内の各正方形の値。",
	"func_fill_arg_3": "ボックスの数。デフォルトでは、現在の配列サイズ。",
	"func_floor": "<b>number</b> の切り捨てを計算します。<br/>切り上げは #ceil で、切り上げは #round で見つけることができます。",
	"func_floor_arg_1": "切り捨てを求める数値。",
	"func_floor_return": "<b>数値</b>に切り捨てます。",
	"func_getAbsoluteShield": "ID <b>entity</b> のエンティティの絶対シールドを返します。エンティティの絶対シールドを直接取得するには、パラメータなしで #getAbsoluteShield() を使用します。",
	"func_getAbsoluteShield_arg_1": "絶対シールドが返されるエンティティの ID。",
	"func_getAbsoluteShield_return": "エンティティ <b>エンティティ</b> の絶対的な盾。",
	"func_getAgility": "ID <b>entity</b> のエンティティの敏捷性を返します。エンティティのアジリティを直接取得するには、パラメータなしで #getAgility() を使用します。",
	"func_getAgility_arg_1": "敏捷性が返されるエンティティの ID。",
	"func_getAgility_return": "<b>entity</b> エンティティの敏捷性。",
	"func_getAIID": "エンティティ <b>entity</b> の AI ID を返します。パラメータなしで getAIID を使用して、AI の ID を取得します。",
	"func_getAIID_arg_1": "AI ID が返されるエンティティの ID。",
	"func_getAIID_return": "<b>entity</b> エンティティ AI ID。",
	"func_getAIName": "エンティティ <b>entity</b> の AI 名を返します。 AI の名前を取得するには、パラメーターを指定せずに getAINam() を使用します。",
	"func_getAIName_arg_1": "AI 名が返されるエンティティの ID。",
	"func_getAIName_return": "<b>entity</b> エンティティ AI の名前。",
	"func_getAliveAllies": "戦闘中に生存しているすべての味方の配列を返します。",
	"func_getAliveAllies_return": "生存しているすべての味方の ID を含む配列。",
	"func_getAliveEnemies": "戦闘中に生きているすべての敵の配列を返します。",
	"func_getAliveEnemiesCount": "戦いで生きている敵の数を返します。",
	"func_getAliveEnemiesCount_return": "生きている敵の数。",
	"func_getAliveEnemies_return": "生きているすべての敵の ID を含む配列。",
	"func_getAllChips": "ゲーム内のすべてのチップのリストを返します。",
	"func_getAllChips_return": "ゲーム内のすべてのチップのリスト。",
	"func_getAllEffects": "ゲーム内のすべての効果のリストを返します。",
	"func_getAllEffects_return": "ゲーム内のすべての効果のリスト。",
	"func_getAllWeapons": "ゲーム内のすべての武器のリストを返します。",
	"func_getAllWeapons_return": "ゲーム内のすべての武器のリスト。",
	"func_getAlliedTurret": "チームのタレット ID を返します。存在しない場合は -1 を返します。",
	"func_getAlliedTurret_return": "チームのタレット ID。",
	"func_getEnemyTurret": "敵のタレット ID を返すか、存在しない場合は -1 を返します。",
	"func_getEnemyTurret_return": "敵のタレット ID。",
	"func_getAllies": "味方とエンティティを含む配列を返します。",
	"func_getAlliesCount": "戦闘中の味方の数を返します。",
	"func_getAlliesCount_return": "味方の数。",
	"func_getAlliesLife": "味方の合計ヘルスを返します。",
	"func_getAlliesLife_return": "味方の合計ヘルス。",
	"func_getAllies_return": "味方とあなたのエンティティの表。",
	"func_getBirthTurn": "<b>エンティティ</b>が出現した戦いのターンを返します。例えばニラなら1、5ターン目召喚なら5を返す。",
	"func_getBirthTurn_arg_1": "スポーン ターンが返されるエンティティの ID。",
	"func_getBirthTurn_return": "<b>実体</b>が出現した戦闘ターン。",
	"func_getBlue": "色 <b>color</b> の青の量を 0 から 255 の範囲で返します。たとえば、getBlue(#COLOR_BLUE) = 255 および getBlue(#COLOR_GREEN) = 0 です。",
	"func_getBlue_arg_1": "青率が返される色。",
	"func_getBlue_return": "色 <b>color</b> の青の比率",
	"func_getBulbChips": "<b>bulb_chip</b> チップによって召喚された電球に装備されるチップのリストを返します。",
	"func_getBulbChips_arg_1": "電球のノミ。",
	"func_getBulbChips_return": "<b>bulb_chip</b> チップによって召喚された電球のチップ。",
	"func_getCell": "ID <b>entity</b> を持つエンティティが配置されているセルを返します。パラメータなしで getCell() を使用して、セルを取得します。",
	"func_getCell_arg_1": "セルが返されるエンティティの ID。",
	"func_getCell_return": "<b>エンティティ</b>が配置されているセル番号。",
	"func_getCellContent": "ID <b>cell</b> のセルの内容を返します。",
	"func_getCellContent_arg_1": "コンテンツが返されるセルの ID。",
	"func_getCellContent_return": "<b>cell</b> セルの内容: 空のセルは #CELL_EMPTY、エンティティは #CELL_ENTITY、障害物は #CELL_OBSTACLE。",
	"func_getCellDistance": "2 つのセル <b>cell1</b> と <b>cell2</b> の間の距離を返します。 <br />返される距離はセルの数で表され、2 つのセル間のさまざまな障害物は考慮されていません。\nカラスが飛ぶ距離を取得するには、#getDistance を参照してください。障害物を回避する 2 つのセル間の経路距離を取得するには、#getPathLength を参照してください。",
	"func_getCellDistance_arg_1": "開始セルの ID。",
	"func_getCellDistance_arg_2": "到着セルの ID。",
	"func_getCellDistance_return": "<b>cell1</b> と <b>cell2</b> の 2 つのセル間の距離。",
	"func_getCellFromXY": "位置 (<b>x</b>, <b>y</b>) のセルの ID を返します。",
	"func_getCellFromXY_arg_1": "セルの x 位置。",
	"func_getCellFromXY_arg_2": "セルの y 位置。",
	"func_getCellFromXY_return": "位置 (<b>x</b>, <b>y</b>) のセル ID。セルが存在しない場合は <b>null</b>。",
	"func_getCellsToUseChip": "エンティティが <b>entity</b> エンティティで <b>chip</b> チップを使用できるセルのリストを返します。",
	"func_getCellsToUseChip_arg_1": "エンティティが使用できるようにしたいチップ。",
	"func_getCellsToUseChip_arg_2": "ターゲット エンティティ。",
	"func_getCellsToUseChip_arg_3": "無視するセルの配列。",
	"func_getCellsToUseChip_return": "チップを使用できるセルのリスト。",
	"func_getCellsToUseChipOnCell": "エンティティが <b>cell</b> セルで <b>chip</b> チップを使用できるセルのリストを返します。",
	"func_getCellsToUseChipOnCell_arg_1": "エンティティが使用できるようにしたいチップ。",
	"func_getCellsToUseChipOnCell_arg_2": "ターゲット セル。",
	"func_getCellsToUseChipOnCell_arg_3": "無視するセルの配列。",
	"func_getCellsToUseChipOnCell_return": "チップを使用できるセルのリスト。",
	"func_getCellsToUseWeapon": "エンティティがエンティティ <b>entity</b> で武器 <b>weapon</b> を使用できるセルのリストを返します。",
	"func_getCellsToUseWeapon_arg_1": "テストする武器。現在装備している武器がデフォルトです。",
	"func_getCellsToUseWeapon_arg_2": "ターゲット エンティティ。",
	"func_getCellsToUseWeapon_arg_3": "無視するセルの配列。デフォルトでは空の配列です。",
	"func_getCellsToUseWeapon_return": "武器を使用できるセルのリスト。",
	"func_getCellsToUseWeaponOnCell": "エンティティが <b>cell</b> セルで <b>weapon</b> 武器を使用できるセルのリストを返します。",
	"func_getCellsToUseWeaponOnCell_arg_1": "テストする武器。現在装備している武器がデフォルトです。",
	"func_getCellsToUseWeaponOnCell_arg_2": "ターゲット セル。",
	"func_getCellsToUseWeaponOnCell_arg_3": "無視するセルの配列。デフォルトでは空の配列です。",
	"func_getCellsToUseWeaponOnCell_return": "武器を使用できるセルのリスト。",
	"func_getCellX": "セル <b>cell</b> の X 方向の位置を決定します。",
	"func_getCellX_arg_1": "X での位置が決定されるセル。",
	"func_getCellX_return": "セルの X 位置。",
	"func_getCellY": "セル <b>cell</b> の Y 位置を決定します。",
	"func_getCellY_arg_1": "Y での位置が決定されるセル。",
	"func_getCellY_return": "セルの Y 位置。",
	"func_getChipArea": "<b>チップ</b>の効果タイプの領域を返します。",
	"func_getChipArea_arg_1": "ゾーン タイプが返されるチップ。",
	"func_getChipArea_return": "AREA_* 定数の中のチップ <b>chip</b> の領域タイプ:\n<ul>\n<li>#AREA_POINT: 単一の正方形エリア</li>\n<li>#AREA_LASER_LINE: レーザーのライン</li>\n<li>#AREA_CIRCLE_1: 直径 3 マスの円形エリア</li>\n<li>#AREA_CIRCLE_2: 直径 5 マスの円形領域</li>\n<li>#AREA_CIRCLE_3: 直径 7 マスの円形エリア</li>\n</ul>",
	"func_getChipCooldown": "マーケットから取得した <b>chip</b> チップの回収時間を返します。",
	"func_getChipCooldown_arg_1": "クールダウンが返されるチップ。",
	"func_getChipCooldown_return": "<b>チップ</b>のクールダウン。",
	"func_getChipCost": "チップ <b>chip</b> のコストを TP で返します。",
	"func_getChipCost_arg_1": "かかったチップは戻ってきます。",
	"func_getChipCost_return": "<b>チップ</b>のコスト。",
	"func_getChipEffectiveArea": "<b>chip</b> チップが <b>from</b> セルの <b>cell</b> セルで使用された場合に影響を受けるセルのリストを返します。",
	"func_getChipEffectiveArea_arg_1": "テストするチップ。",
	"func_getChipEffectiveArea_arg_2": "ターゲット セル。",
	"func_getChipEffectiveArea_arg_3": "チップが使用されるセル。",
	"func_getChipEffectiveArea_return": "影響を受けるすべてのセルの ID を含む配列。",
	"func_getChipEffects": "<b>chip</b> チップの効果を返します。",
	"func_getChipEffects_arg_1": "効果が返されるチップ。",
	"func_getChipEffects_return": "<b>チップ</b> チップの効果。 #getWeaponEffects 関数と同じ戻り値。",
	"func_getChipFailure": "チップ <b>chip</b> の故障リスクのパーセンテージを返します。",
	"func_getChipFailure_arg_1": "失敗率が返されるチップ。",
	"func_getChipFailure_return": "<b>chip</b> チップの故障率。<b>0</b> から <b>100</b> までの整数。",
	"func_getChipMaxRange": "<b>chip</b> チップの最大範囲を返します。",
	"func_getChipMaxRange_arg_1": "最大範囲が返されるチップ。",
	"func_getChipMaxRange_return": "<b>チップ</b>の最大範囲。",
	"func_getChipMaxScope": "<b>chip</b> チップの最大範囲を返します。",
	"func_getChipMaxScope_arg_1": "最大範囲が返されるチップ。",
	"func_getChipMaxScope_return": "<b>チップ</b>の最大範囲。",
	"func_getChipMinRange": "<b>chip</b> チップの最小範囲を返します。",
	"func_getChipMinRange_arg_1": "最小範囲が返されるチップ。",
	"func_getChipMinRange_return": "<b>チップ</b>の最小範囲。",
	"func_getChipMinScope": "<b>chip</b> チップの最小範囲を返します。",
	"func_getChipMinScope_arg_1": "最小範囲が返されるチップ。",
	"func_getChipMinScope_return": "<b>チップ</b>の最小範囲。",
	"func_getChipLaunchType": "LAUNCH_TYPE_* 定数の中から <b>chip</b> チップの起動モードを返します。",
	"func_getChipLaunchType_arg_1": "スロー モードが返されるチップの ID。",
	"func_getChipLaunchType_return": "<b>chip</b>チップの投げ方。",
	"func_getChipName": "チップ <b>chip</b> の名前を返します。",
	"func_getChipName_arg_1": "名前が返されるチップ。",
	"func_getChipName_return": "<b>chip</b> チップの名前。",
	"func_getChips": "ID <b>entity</b> のエンティティの箇条書きを返します。",
	"func_getChips_arg_1": "弾丸が返されるエンティティの ID。",
	"func_getChips_return": "エンティティ <b>entity</b> の箇条書き ID を含む配列。",
	"func_getChipTargets": "<b>chip</b> チップがセル <b>cell</b> で使用された場合に影響を受けるエンティティを返します。",
	"func_getChipTargets_arg_1": "テストするチップ。",
	"func_getChipTargets_arg_2": "ターゲット セル。",
	"func_getChipTargets_return": "影響を受けるすべてのエンティティの ID を含む配列。",
	"func_getColor": "パラメータとして指定された色 (<b>赤</b>、<b>緑</b>、<b>青</b>) に対応する整数を返します。",
	"func_getColor_arg_1": "0 ～ 255 の赤の値。",
	"func_getColor_arg_2": "0 ～ 255 の緑の値。",
	"func_getColor_arg_3": "0 から 255 までの青の値。",
	"func_getColor_return": "パラメーターとして提供される色に対応する int。",
	"func_getCooldown": "<b>entity</b> エンティティの現在の <b>chip</b> クールダウンを返します。",
	"func_getCooldown_arg_1": "現在のクールダウンが返されるチップ。",
	"func_getCooldown_arg_2": "クールダウンが返されるエンティティ。",
	"func_getCooldown_return": "チップ <b>chip</b> の現在のクールダウン。これは、チップが使用可能になるまでのターン数です。現在使用可能な場合は <b>0</b> です。",
	"func_getCores": "ID <b>entity</b> のエンティティのコア数を返します。",
	"func_getCores_arg_1": "コア数が返されるエンティティ。",
	"func_getCores_return": "<b>entity</b> エンティティのコア数。",
	"func_getDate": "試合の日付を dd/MM/yyyy 形式で返します。",
	"func_getDate_return": "戦いの日付。",
	"func_getTime": "戦闘時間を HH:mm:ss 形式で返します。",
	"func_getTime_return": "戦いの時。",
	"func_getTimestamp": "1970 年 1 月 1 日からの秒数に等しい、戦いのタイムスタンプを返します。",
	"func_getTimestamp_return": "戦闘のタイムスタンプ。",
	"func_getDamageReturn": "ID <b>entity</b> のエンティティのダメージ回復率を返します。",
	"func_getDamageReturn_arg_1": "損害照会が返されるエンティティの ID。",
	"func_getDamageReturn_return": "ID <b>entity</b> のエンティティのダメージ回復率 (%)。",
	"func_getDeadAllies": "死んだ友好的なエンティティを返します。",
	"func_getDeadAllies_return": "味方の死亡エンティティの表。",
	"func_getDeadEnemies": "死んだ敵エンティティを返します。",
	"func_getDeadEnemiesCount": "戦闘で死亡した敵の数を返します。",
	"func_getDeadEnemiesCount_return": "死んだ敵の数。",
	"func_getDeadEnemies_return": "死んだ敵エンティティのテーブル。",
	"func_getDistance": "<b>cell1</b> と <b>cell2</b> の 2 つのセルの間をカラスが飛ぶ距離を計算します。 <br />セルの数で距離を取得するには、#getCellDistance を参照してください。さまざまな障害物を回避する 2 つのセル間のパスの長さを取得するには、#getPathLength を参照してください。",
	"func_getDistance_arg_1": "開始セル。",
	"func_getDistance_arg_2": "到着セル。",
	"func_getDistance_return": "カラスが 2 つのセルの間を飛ぶ距離。",
	"func_getPassiveEffects": "ID <b>entity</b> のエンティティのパッシブ効果のリストを返します。エンティティのパッシブ効果のリストを直接取得するには、パラメーターなしで #getPassiveEffects() を使用します。",
	"func_getPassiveEffects_arg_1": "パッシブ効果のリストが返されるエンティティの ID。",
	"func_getPassiveEffects_return": "<b>entity</b> エンティティに現在存在するパッシブ効果のリスト。\nパッシブ効果のリストは、効果を含む配列です。\n効果自体は、[<b>type</b>、<b>value</b>、<b>caster_id</b>、<b>turns</b>、 <b>重要</b>、<b>item_id</b>、<b>target_id</b>]。 #getEffects 関数によって返されるクラシック エフェクトと同じ構造です。",
	"func_getEnemies": "敵エンティティ (生きているか死んでいるか) を戦闘に戻します。",
	"func_getEnemiesCount": "戦闘中の敵の数を返します。",
	"func_getEnemiesCount_return": "敵の数。",
	"func_getEnemiesLife": "すべての敵エンティティのヒット ポイントの合計を計算します。",
	"func_getEnemiesLife_return": "敵チームのヒット ポイントの合計。",
	"func_getEnemies_return": "すべての敵エンティティの ID を含む配列。",
	"func_getEntityTurnOrder": "<b>エンティティ</b>の再生順序を示す 1 から n (現在再生中のエンティティの数) の値を返します。",
	"func_getEntityTurnOrder_arg_1": "再生順序が返されるエンティティの ID",
	"func_getEntityTurnOrder_return": "エンティティ <b>entity</b> のプレイ順",
	"func_getFarmerID": "エンティティ <b>エンティティ</b> のブリーダー ID を返します。",
	"func_getFarmerID_arg_1": "ブリーダー ID が返されるエンティティの ID。",
	"func_getFarmerID_return": "<b>entity</b> エンティティ ブリーダーの ID。",
	"func_getFarmerCountry": "エンティティ <b>entity</b> のブリーダーの国を返します。",
	"func_getFarmerCountry_arg_1": "ブリーダーの国が返されるエンティティの ID。",
	"func_getFarmerCountry_return": "<b>エンティティ</b>のブリーダーの国、または「?」示されていない場合。",
	"func_getFarmerName": "<b>entity</b> エンティティのブリーダー名を返します。",
	"func_getFarmerName_arg_1": "ブリーダー名が返されるエンティティの ID。",
	"func_getFarmerName_return": "<b>エンティティ</b>エンティティのブリーダーの名前。",
	"func_getFarthestAlly": "カラスが飛ぶように、エンティティの最も遠い味方を決定します。",
	"func_getFarthestAlly_return": "最も遠い友好的なエンティティの ID。",
	"func_getFarthestEnemy": "カラスが飛ぶように、あなたのエンティティから最も遠い敵を決定します。",
	"func_getFarthestEnemy_return": "最も遠い敵エンティティの ID。",
	"func_getFightID": "現在のファイト ID を返します。",
	"func_getFightID_return": "現在の戦いのID。",
	"func_getFightContext": "現在の戦いのコンテキストを返します。",
	"func_getFightContext_return": "戦闘状況に応じて: テストバトル (#FIGHT_CONTEXT_TEST)、アリーナバトル (#FIGHT_CONTEXT_GARDEN)、トーナメントバトル (#FIGHT_CONTEXT_TOURNAMENT)、チャレンジバトル (#FIGHT_CONTEXT_CHALLENGE)、バトルロワイヤル (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "現在の戦闘タイプを返します。",
	"func_getFightType_return": "戦いの種類に応じて: ソロファイト (#FIGHT_TYPE_SOLO)、ファーマーファイト (#FIGHT_TYPE_FARMER)、チームファイト (#FIGHT_TYPE_TEAM)、バトルロワイヤル (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "ID <b>entity</b> のエンティティの強度を返します。",
	"func_getForce_arg_1": "強さが返されるエンティティ。",
	"func_getForce_return": "<b>entity</b> エンティティの強さ。",
	"func_getFrequency": "ID <b>entity</b> のエンティティの頻度を返します。パラメータを指定せずに getFrequency() を使用して、頻度を取得します。",
	"func_getFrequency_arg_1": "頻度が返されるエンティティ。",
	"func_getFrequency_return": "<b>entity</b> エンティティの頻度。",
	"func_getGreen": "色 <b>color</b> の緑の量を 0 から 255 の範囲で返します。たとえば、getGreen(#COLOR_GREEN) = 255 および getGreen(#COLOR_RED) = 0 です。",
	"func_getGreen_arg_1": "緑率が返される色。",
	"func_getGreen_return": "色 <b>color</b> の緑の割合",
	"func_getInstructionsCount": "現在のターン中にエンティティが実行した命令の数を返します。",
	"func_getInstructionsCount_return": "現在のターン中にエンティティが実行した命令の数。",
	"func_getLaunchedEffects": "ID <b>entity</b> のエンティティによって引き起こされる効果のリストを返します。",
	"func_getLaunchedEffects_arg_1": "引き起こされた効果のリストが返されるエンティティの ID。",
	"func_getLaunchedEffects_return": "#getEffects によって返される配列と同じ形式の、ID <b>entity</b> を持つエンティティによって引き起こされる効果のリスト。",
	"func_getLeek": "エンティティの ID を返します。",
	"func_getLeek_return": "エンティティの ID。",
	"func_getEntity": "エンティティの ID を返します。",
	"func_getEntity_return": "エンティティの ID。",
	"func_getLeekID": "ID <b>leek</b> のネギの実際の ID を返します。",
	"func_getLeekID_arg_1": "実際の ID が返されるネギの ID。",
	"func_getLeekID_return": "ニラ <b>エンティティ</b>の実際の ID。",
	"func_getLeekOnCell": "セル <b>cell</b> にあるエンティティを返します。",
	"func_getLeekOnCell_arg_1": "エンティティを取得するセル。",
	"func_getLeekOnCell_return": "セル上のエンティティの ID、またはセルにエンティティがない場合は -1。",
	"func_getEntityOnCell": "セル <b>cell</b> にあるエンティティを返します。",
	"func_getEntityOnCell_arg_1": "エンティティを取得するセル。",
	"func_getEntityOnCell_return": "セル上のエンティティの ID、またはセルにエンティティがない場合は -1。",
	"func_getLevel": "ID <b>entity</b> のエンティティ レベルを返します。",
	"func_getLevel_arg_1": "レベルが返されるエンティティの ID。",
	"func_getLevel_return": "ID <b>entity</b> を持つエンティティのレベル。",
	"func_getLife": "ID <b>entity</b> を持つエンティティの現在の寿命を返します。パラメータなしで getLife() を使用して、ライフを取得します。",
	"func_getLife_arg_1": "寿命が返されるエンティティの ID。",
	"func_getLife_return": "<b>entity</b> エンティティの現在の寿命。",
	"func_getMagic": "ID <b>entity</b> のエンティティの魔法を返します。パラメータなしで getMagic() を使用して、マジックを取得します。",
	"func_getMagic_arg_1": "魔法が返されるエンティティの ID。",
	"func_getMagic_return": "ID実体<b>実体</b>の魔法。",
	"func_getMapType": "定数 #MAP_NEXUS、#MAP_FACTORY、#MAP_DESERT、#MAP_FOREST、#MAP_GLACIER、#MAP_BEACH の中から、戦闘が行われる地形のタイプ (工場、砂漠、森など) を返します。",
	"func_getMapType_return": "地形のタイプ。",
	"func_getMessageAuthor": "メッセージ <b>メッセージ</b> を作成したエンティティの ID を返します。",
	"func_getMessageAuthor_arg_1": "作成者が返されるメッセージ。",
	"func_getMessageAuthor_return": "メッセージ <b>メッセージ</b> を作成するエンティティの ID。",
	"func_getMessageParams": "メッセージ パラメータ <b>message</b> の配列を返します。",
	"func_getMessageParams_arg_1": "パラメータが返されるメッセージ。",
	"func_getMessageParams_return": "<b>メッセージ</b> メッセージ パラメータ。",
	"func_getMessages": "<b>entity</b> エンティティ メッセージ配列を返します。",
	"func_getMessages_arg_1": "メッセージが返されるエンティティ。",
	"func_getMessages_return": "メッセージの表。<br>メッセージ自体は、\n形式の配列: [<b>作成者</b>、<b>タイプ</b>、<b>パラメータ</b>]<br>\nさまざまなタイプのメッセージが定数で表されます。\n<ul>\n<li>#MESSAGE_HEAL: ケアのリクエスト</li>\n<li>#MESSAGE_ATTACK: 攻撃のリクエスト</li>\n<li>#MESSAGE_BUFF_FORCE: 強制ブースト リクエスト</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "メッセージ タイプ <b>メッセージ</b> を返します。",
	"func_getMessageType_arg_1": "タイプが返されるメッセージ (#MESSAGE_HEAL、#MESSAGE_ATTACK など)。",
	"func_getMessageType_return": "メッセージ タイプ <b>メッセージ</b>。",
	"func_getMP": "<b>entity</b> エンティティの現在の移動ポイント数を返します。 PM を取得するには、パラメーターを指定せずに getMP() を使用します。",
	"func_getMP_arg_1": "PM カウントが返されるエンティティの ID。",
	"func_getMP_return": "<b>エンティティ</b> エンティティの MP 番号。",
	"func_getName": "ID <b>entity</b> を持つエンティティの名前を返します。",
	"func_getName_arg_1": "名前が返されるエンティティの ID。",
	"func_getName_return": "エンティティ名 <b>エンティティ</b>。",
	"func_getNearestAlly": "あなたのエンティティに最も近い友好的なエンティティを返します。",
	"func_getNearestAlly_return": "最も近い友好的なエンティティの ID。",
	"func_getNearestAllyTo": "パラメータとして提供されたエンティティに最も近い同盟エンティティを返します。",
	"func_getNearestAllyTo_arg_1": "最も近い味方を知りたいエンティティの ID。",
	"func_getNearestAllyToCell": "パラメータとして提供されたセルに最も近い関連エンティティを返します。",
	"func_getNearestAllyToCell_arg_1": "最も近い味方を知りたいセルの ID。",
	"func_getNearestAllyToCell_return": "最も近い友好的なエンティティの ID。",
	"func_getNearestAllyTo_return": "最も近い友好的なエンティティの ID。",
	"func_getNearestEnemy": "あなたのエンティティに最も近い敵エンティティを返します。",
	"func_getNearestEnemy_return": "最も近い敵エンティティの ID。",
	"func_getNearestEnemyTo": "パラメータとして提供されたエンティティに最も近い敵エンティティを返します。",
	"func_getNearestEnemyTo_arg_1": "最も近い敵を知りたいエンティティの ID。",
	"func_getNearestEnemyToCell": "パラメータとして指定されたセルに最も近い敵エンティティを返します。",
	"func_getNearestEnemyToCell_arg_1": "最も近い敵を知りたいセルの ID。",
	"func_getNearestEnemyToCell_return": "最も近い敵エンティティの ID。",
	"func_getNearestEnemyTo_return": "最も近い敵エンティティの ID。",
	"func_getNextPlayer": "現在のプレーヤーの後に再生されるエンティティの ID を返します。",
	"func_getNextPlayer_return": "次のプレーヤー。",
	"func_getObstacles": "地形内の障害物スペースのリストを返します。",
	"func_getObstacles_return": "障害物セル ID を含む配列。",
	"func_getOperations": "ターンの開始以降、エンティティによって消費された操作の数を返します。エンティティがクラッシュしないように、この数は #OPERATIONS_LIMIT よりも小さくする必要があります。",
	"func_getOperations_return": "ターンの開始以降にエンティティによって消費された操作の数。",
	"func_getPath": "2 つのセル <b>cell1</b> と <b>cell2</b> の間の障害物を回避するパスを返します。存在する場合は、配列 <b>ignoredCells</b> に含まれるセルを無視します。プレイヤーが無視されたセルにいる場合、パスはその上を通過できます。<br /><br />\n開始セル <b>cell1</b> は、結果のパスの一部にはなりません。セル <b>cell2</b> は、空であるか、<b>ignoredCells</b> によって無視される場合にのみ、結果のパスの一部になります。<br /><br />\n2 つのセル間にパスが存在しない場合、<b>getPath</b> は <i>null</i> を返します。",
	"func_getPath_arg_1": "開始セル。",
	"func_getPath_arg_2": "到着セル。",
	"func_getPath_arg_3": "無視するセルの配列。",
	"func_getPath_return": "2 つのセル間のパスを構成するセルを含む配列。",
	"func_getPathLength": "2 つのセル <b>cell1</b> と <b>cell2</b> の間のパスの長さを返します。障害物を避け、配列 <b>ignoredCells</b> に含まれるセルを無視します。この関数は、<i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i> と同等です。\nプレイヤーが無視されたセルにいる場合、パスはその上を通過できます。<br /><br />\n開始セル <b>cell1</b> は結果にカウントされません。セル <b>cell2</b> は、空であるか、<b>ignoredCells</b> によって無視された場合にのみ、結果にカウントされます。<br /><br />\n2 つのセル間にパスが存在しない場合、<b>getPathLength</b> は <i>null</i> を返します。",
	"func_getPathLength_arg_1": "開始セル。",
	"func_getPathLength_arg_2": "到着セル。",
	"func_getPathLength_arg_3": "無視するセルの配列。",
	"func_getPathLength_return": "<b>cell1</b> と <b>cell2</b> の間のパスの長さ。",
	"func_getPreviousPlayer": "現在のプレイヤーの前にプレイしたエンティティの ID を返します。",
	"func_getPreviousPlayer_return": "前のプレーヤー。",
	"func_getRed": "色 <b>color</b> の赤の量を 0 から 255 の間で返します。たとえば、getRed(#COLOR_RED) = 255 および getRed(#COLOR_BLUE) = 0 です。",
	"func_getRed_arg_1": "赤率が返される色。",
	"func_getRed_return": "色の赤の割合 <b>color</b>",
	"func_getRegister": "キー <b>key</b> に関連付けられたエンティティ レジストリに格納されている値を返します。レジストリが存在しない場合は </i>null</i> を返します。",
	"func_getRegister_arg_1": "値が返されるレジストリ キー。",
	"func_getRegister_return": "<b>key</b> レジストリに格納されている値。",
	"func_getRegisters": "一連のエンティティ レジスタを連想配列 [<i>レジストリ キー</i>:<i>レジスタ値</i>] として返します。例: <code>debug(getRegisters());\n// 表示例:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "エンティティのすべてのレジスタに対応する連想配列。",
	"func_getRelativeShield": "ID <b>entity</b> のエンティティの相対的なシールドを返します。エンティティの相対シールドを直接取得するには、パラメータなしで #getRelativeShield() を使用します。",
	"func_getRelativeShield_arg_1": "相対シールドが返されるエンティティの ID。",
	"func_getRelativeShield_return": "<b>エンティティ</b>の相対的なシールド。<b>0</b> から <b100</b> までの整数。",
	"func_getResistance": "ID <b>entity</b> のエンティティの耐性を返します。パラメータなしで getResistance() を使用して抵抗を取得します。",
	"func_getResistance_arg_1": "抵抗が返されるエンティティの ID。",
	"func_getResistance_return": "ID <b>entity</b> を持つエンティティの耐性。",
	"func_getScience": "ID <b>entity</b> のエンティティの科学を返します。パラメータなしで getScience() を使用して科学を取得します。",
	"func_getScience_arg_1": "科学が返されるエンティティの ID。",
	"func_getScience_return": "ID エンティティ <b>エンティティ</b> の科学。",
	"func_getStrength": "ID <b>entity</b> のエンティティの強度を返します。パラメータなしで getStrength() を使用して、強度を取得します。",
	"func_getStrength_arg_1": "強さが返されるエンティティ。",
	"func_getStrength_return": "<b>entity</b> エンティティの強さ。",
	"func_getPower": "ID <b>entity</b> のエンティティのパワーを返します。",
	"func_getPower_arg_1": "力を取り戻す実体。",
	"func_getPower_return": "<b>エンティティ</b>の力。",
	"func_getSummoner": "呼び出しの場合、エンティティ <b>entity</b> を呼び出したエンティティを返します。",
	"func_getSummoner_arg_1": "サモナーが解雇されるエンティティの ID。",
	"func_getSummoner_return": "呼び出しの場合は <b>entity</b> を呼び出したエンティティの ID、それ以外の場合は <i>null</i>。",
	"func_getSummons": "ID <b>entity</b> を持つエンティティの現在有効な呼び出しの ID のリストを返します。",
	"func_getSummons_arg_1": "召喚状が返されるエンティティの ID。",
	"func_getSummons_return": "ID <b>entity</b> を持つエンティティの呼び出し ID のリスト。",
	"func_getTeamID": "エンティティ <b>entity</b> のチーム ID を返します。",
	"func_getTeamID_arg_1": "チーム ID が返されるエンティティの ID。",
	"func_getTeamID_return": "エンティティ <b>entity</b> のチーム ID。",
	"func_getTeamName": "エンティティ <b>entity</b> のチーム名を返します。",
	"func_getTeamName_arg_1": "チーム名が返されるエンティティの ID。",
	"func_getTeamName_return": "<b>entity</b> エンティティのチーム名。",
	"func_getTotalLife": "ID <b>entity</b> のエンティティの総寿命を返します。パラメータなしで getTotalLife() を使用して合計ライフを取得します。",
	"func_getTotalLife_arg_1": "合計寿命が返されるエンティティの ID。",
	"func_getTotalLife_return": "エンティティの総寿命。",
	"func_getTotalMP": "エンティティ <b>entity</b> の移動ポイントの最大数を返します。",
	"func_getTotalMP_arg_1": "最大移動ポイントが返されるエンティティの ID。",
	"func_getTotalMP_return": "<b>エンティティ</b>の移動ポイントの最大数。",
	"func_getTotalTP": "エンティティ <b>entity</b> のターン ポイントの最大数を返します。",
	"func_getTotalTP_arg_1": "ターン ポイントの最大数が返されるエンティティの ID。",
	"func_getTotalTP_return": "<b>entity</b> エンティティのターン ポイントの最大数。",
	"func_getTP": "エンティティ <b>entity</b> のターン ポイントの数を返します。パラメータなしで getTP() を使用して、PT を取得します。",
	"func_getTP_arg_1": "PT が返されるエンティティの ID。",
	"func_getTP_return": "<b>entity</b> エンティティの PT 番号。",
	"func_getTurn": "現在の戦闘ターンを返します。最大ターン数は #MAX_TURNS です。",
	"func_getTurn_return": "現在の戦闘ターン。",
	"func_getType": "エンティティ <b>entity</b> のエンティティ タイプを返します。",
	"func_getType_arg_1": "タイプが返されるエンティティの ID。",
	"func_getType_return": "<b>エンティティ</b>のエンティティ タイプ:<ul><li>ネギの場合は #ENTITY_LEEK。</li><li>球根の場合は #ENTITY_BULB。</li><li >タレットの場合は #ENTITY_TURRET。</li></ul>",
	"func_getWeapon": "現在装備されている武器 <b>エンティティ</b> を返します。",
	"func_getWeapon_arg_1": "現在の武器が返されるエンティティの ID。",
	"func_getWeapon_return": "<b>エンティティ</b>に現在装備されている武器の ID。エンティティに武器が装備されていない場合、またはエンティティが存在しない場合は null。",
	"func_getWeaponArea": "<b>武器</b>武器の効果範囲タイプを返します。",
	"func_getWeaponArea_arg_1": "エリア タイプが返される武器。",
	"func_getWeaponArea_return": "AREA_* 定数の中の <b>weapon</b> 武器のエリア タイプ:\n<ul>\n<li>#AREA_POINT: 単一の正方形エリア</li>\n<li>#AREA_LASER_LINE: レーザーのライン</li>\n<li>#AREA_CIRCLE_1: 直径 3 セルの円形エリア</li>\n<li>#AREA_CIRCLE_2: 直径 5 マスの円形領域</li>\n<li>#AREA_CIRCLE_3: 直径 7 マスの円形エリア</li>\n</ul>",
	"func_getWeaponCost": "<b>武器</b>武器のTPコストを返します。",
	"func_getWeaponCost_arg_1": "コストが返される武器の ID。",
	"func_getWeaponCost_return": "<b>武器</b>武器のTPコスト。",
	"func_getWeaponEffectiveArea": "セル <b>from</b> のセル <b>cell</b> で武器 <b>weapon</b> が使用された場合に影響を受けるセルのリストを返します。",
	"func_getWeaponEffectiveArea_arg_1": "テストする武器。",
	"func_getWeaponEffectiveArea_arg_2": "ターゲット セル。",
	"func_getWeaponEffectiveArea_arg_3": "武器が発射されるセル。",
	"func_getWeaponEffectiveArea_return": "影響を受けるすべてのセルの ID を含む配列。",
	"func_getWeaponPassiveEffects": "武器の<b>武器</b>のパッシブ効果を返します。",
	"func_getWeaponPassiveEffects_arg_1": "パッシブ効果が返される武器の ID。",
	"func_getWeaponPassiveEffects_return": "<b>武器</b>武器の効果を含む配列。各効果は、それ自体が次の形式の配列です。\n[タイプ、最小、最大、ターン、ターゲット、修飾子]。これらの効果は、#getWeaponEffects によって返されるものと同じです。",
	"func_getWeaponFailure": "<b>武器</b>の武器の失敗率を返します。",
	"func_getWeaponFailure_arg_1": "失敗率が返される武器の ID。",
	"func_getWeaponFailure_return": "<b>weapon</b> の武器ミス率。<b>0</b> から <b100</b> までの整数。",
	"func_getWeaponMaxRange": "<b>武器</b>武器の最大範囲を返します。",
	"func_getWeaponMaxRange_arg_1": "最大範囲が返される武器の ID。",
	"func_getWeaponMaxRange_return": "<b>武器</b>武器の最大範囲。",
	"func_getWeaponMaxScope": "<b>武器</b>武器の最大範囲を返します。",
	"func_getWeaponMaxScope_arg_1": "最大範囲が返される武器の ID。",
	"func_getWeaponMaxScope_return": "<b>武器</b>武器の最大範囲。",
	"func_getWeaponMinRange": "<b>武器</b>武器の最小範囲を返します。",
	"func_getWeaponMinRange_arg_1": "最小範囲が返される武器の ID。",
	"func_getWeaponMinRange_return": "<b>武器</b>武器の最小射程。",
	"func_getWeaponMinScope": "<b>武器</b>武器の最小範囲を返します。",
	"func_getWeaponMinScope_arg_1": "最小範囲が返される武器の ID。",
	"func_getWeaponMinScope_return": "<b>武器</b>武器の最小射程。",
	"func_getWeaponLaunchType": "LAUNCH_TYPE_* 定数の中で、<b>武器</b>武器の発射モードを返します。",
	"func_getWeaponLaunchType_arg_1": "投擲モードが返される武器の ID。現在装備している武器がデフォルトです。",
	"func_getWeaponLaunchType_return": "<b>武器</b>武器の投擲モード。",
	"func_getWeaponName": "<b>武器</b>武器の名前を返します。",
	"func_getWeaponName_arg_1": "名前が返される武器の ID。",
	"func_getWeaponName_return": "<b>武器</b>武器の名前。",
	"func_getWeapons": "ID <b>entity</b> のエンティティの武器を返します。",
	"func_getWeapons_arg_1": "武器が返されるエンティティの ID。",
	"func_getWeapons_return": "<b>エンティティ</b>の武器 ID を含む配列。",
	"func_getWeaponTargets": "セル <b>cell</b> で武器 <b>weapon</b> が使用された場合に影響を受けるエンティティを返します。",
	"func_getWeaponTargets_arg_1": "テストする武器。",
	"func_getWeaponTargets_arg_2": "ターゲット セル。",
	"func_getWeaponTargets_return": "影響を受けるすべてのエンティティの ID を含む配列。",
	"func_getWisdom": "ID <b>entity</b> のエンティティの知恵を返します。パラメータなしで getWisdom() を使用して、知恵を取得します。",
	"func_getWisdom_arg_1": "知恵が返されるエンティティの ID。",
	"func_getWisdom_return": "ID <b>entity</b> を持つエンティティの知恵。",
	"func_hypot": "辺が x と y の直角三角形の斜辺を返します。 <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i> に相当します。",
	"func_hypot_arg_1": "X 値。",
	"func_hypot_arg_2": "Y 値。",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "<b>element</b> 要素が <b>array</b> 配列内にあるかどうかを判断します。",
	"func_inArray_arg_1": "検索テーブル。",
	"func_inArray_arg_2": "検索するアイテム。",
	"func_inArray_return": "要素が配列内にある場合は <i>true</i>、それ以外の場合は <i>false</i>。",
	"func_include": "<b>ai</b> という名前の AI を現在の AI に含めます。 <br><br><b>警告</b>、インクルード関数はメイン ブロックでのみ呼び出す必要があり、そのパラメーターは直接記述された文字列でなければなりません\nコードで。詳細については、チュートリアルのセクション <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a> を参照してください。",
	"func_include_arg_1": "含める AI の名前。",
	"func_indexOf": "位置 <b>start</b> から開始して、文字列 <b>string</b> 内で文字列 <b>search</b> が最初に出現する位置を特定します。",
	"func_indexOf_arg_1": "検索が実行される文字列。",
	"func_indexOf_arg_2": "検索する文字列。",
	"func_indexOf_arg_3": "検索の開始位置。",
	"func_indexOf_return": "<b>start</b> から <b>string</b> 内で <b>search</b> が最初に出現する位置。文字列が見つからなかった場合は -1。",
	"func_insert": "<b>element</b> 要素を <b>array</b> の位置 <b>position</b> に挿入します。",
	"func_insert_arg_1": "要素が挿入される配列。",
	"func_insert_arg_2": "挿入するアイテム。",
	"func_insert_arg_3": "挿入位置。",
	"func_isAlive": "<b>エンティティ</b>が生きているかどうかを判断します。 <i>getLife(<b>entity</b>) > 0</i> と同等です。",
	"func_isAlive_arg_1": "テストするエンティティの ID。",
	"func_isAlive_return": "<b>エンティティ</b>が生きている場合は<i>true</i>、死んでいる場合は<i>false</i>。",
	"func_isAlly": "<b>エンティティ</b>が味方かどうかを決定します。",
	"func_isAlly_arg_1": "テストするエンティティの ID。",
	"func_isAlly_return": "<b>エンティティ</b>が味方か自分の場合は<i>true</i>、敵の場合は<i>false</i>。",
	"func_isChip": "値がチップを表す定数かどうかを判断します。<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false。",
	"func_isChip_arg_1": "決定する数。",
	"func_isChip_return": "値がチップ定数の場合は <i>true</i>。",
	"func_isDead": "<b>エンティティ</b>が死んでいるかどうかを判断します。 <i>getLife(<b>entity</b>) == 0</i> と同等です。",
	"func_isDead_arg_1": "テストするエンティティの ID。",
	"func_isDead_return": "<b>実体</b>が死んでいれば<i>真</i>、生きていれば<i>偽</i>。",
	"func_isEmpty": "配列 <b>array</b> が空かどうかを判断します。 <i>count(<b>array</b>) == 0</i> と同等です。",
	"func_isEmpty_arg_1": "テストする配列。",
	"func_isEmptyCell": "セルが空かどうかを判断します。",
	"func_isEmptyCell_arg_1": "テストするセル。",
	"func_isEmptyCell_return": "セルが空の場合は <i>true</i>、それ以外の場合は <i>false</i>。",
	"func_isEmpty_return": "配列が空の場合は <i>true</i>、それ以外の場合は <i>false</i>。",
	"func_isEnemy": "<b>エンティティ</b>が敵かどうかを判断します。",
	"func_isEnemy_arg_1": "テストするエンティティの ID。",
	"func_isEnemy_return": "<b>エンティティ</b>が敵の場合は<i>true</i>、味方または自分の場合は<i>false</i>。",
	"func_isInlineChip": "<b>チップ</b>をオンラインでのみ使用できるかどうかを決定します。",
	"func_isInlineChip_arg_1": "テストするチップの ID。",
	"func_isInlineChip_return": "チップがオンラインのみの場合は <i>true</i>、それ以外の場合は <i>false</i>。",
	"func_isInlineWeapon": "<b>武器</b>武器がオンラインでのみ使用できるかどうかを決定します。",
	"func_isInlineWeapon_arg_1": "テストする武器の ID。",
	"func_isInlineWeapon_return": "武器がオンラインでのみ使用できる場合は <i>true</i>、それ以外の場合は <i>false</i>。",
	"func_isLeek": "セル <b>cell</b> の内容がエンティティかどうかを判断します。",
	"func_isLeek_arg_1": "テストするセル。",
	"func_isLeek_return": "セルにエンティティが含まれている場合は <i>true</i>、そうでない場合は <i>false</i>。",
	"func_isEntity": "セル <b>cell</b> の内容がエンティティかどうかを判断します。",
	"func_isEntity_arg_1": "テストするセル。",
	"func_isEntity_return": "セルにエンティティが含まれている場合は <i>true</i>、そうでない場合は <i>false</i>。",
	"func_isObstacle": "セル <b>cell</b> の内容が障害物かどうかを判断します。",
	"func_isObstacle_arg_1": "テストするセル。",
	"func_isObstacle_return": "セルに障害物が含まれている場合は <i>true</i>、そうでない場合は <i>false</i>。",
	"func_isOnSameLine": "2 つのセル <b>cell1</b> と <b>cell2</b> が同じ行にあるかどうかを判断します。",
	"func_isOnSameLine_arg_1": "最初のセル。",
	"func_isOnSameLine_arg_2": "2 番目のセル。",
	"func_isOnSameLine_return": "2 つのセルが同じ行にある場合は <i>true</i>、それ以外の場合は <i>false</i>。",
	"func_isStatic": "<b>エンティティ</b> が静的かどうかを返します。静的エンティティは、移動することも移動することもできません。",
	"func_isStatic_arg_1": "テストするエンティティの ID。",
	"func_isStatic_return": "<b>entity</b> が静的な場合は <i>true</i>、それ以外の場合は <i>false</i>。",
	"func_isSummon": "<b>entity</b> が呼び出しかどうかを返します。",
	"func_isSummon_arg_1": "テストするエンティティの ID。",
	"func_isSummon_return": "<b>entity</b> が呼び出しの場合は <i>true</i>、それ以外の場合は <i>false</i>。",
	"func_isWeapon": "値が武器を表す定数かどうかを決定します。<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false。",
	"func_isWeapon_arg_1": "決定する数。",
	"func_isWeapon_return": "値が武器定数の場合は <i>true</i>。",
	"func_join": "複数の要素を 1 つの文字列に結合し、それらを <b>glue</b> 区切り記号で区切ります。",
	"func_join_arg_1": "マージするアイテムの配列。",
	"func_join_arg_2": "要素区切り文字。",
	"func_join_return": "マージの結果の文字列。",
	"func_jsonDecode": "文字列 <b>json</b> を LeekScript オブジェクト (数値、文字列、配列...) にデコードします。",
	"func_jsonDecode_arg_1": "デコードする JSON 文字列。",
	"func_jsonDecode_return": "デコードされた LeekScript オブジェクト。",
	"func_jsonEncode": "<b>object</b> オブジェクトを JSON 文字列にエンコードします。",
	"func_jsonEncode_arg_1": "JSON でエンコードするオブジェクト。",
	"func_jsonEncode_return": "JSON でエンコードされた文字列。",
	"func_keySort": "<b>配列</b>をキーで<b>order</b>順にソートします。",
	"func_keySort_arg_1": "並べ替える配列。",
	"func_keySort_arg_2": "ソート順: #SORT_ASC または #SORT_DESC。",
	"func_length": "<b>string</b> の長さを返します。",
	"func_length_arg_1": "長さが返される文字列。",
	"func_length_return": "<b>string</b> の長さ。",
	"func_lineOfSight": "<b>ignoredEntities</b> エンティティを無視して、<b>start</b> セルと <b>end</b> セルの間の視線をチェックします。",
	"func_lineOfSight_arg_1": "開始セル。",
	"func_lineOfSight_arg_2": "標的細胞。",
	"func_lineOfSight_arg_3": "無視するエンティティまたはエンティティのリスト。",
	"func_lineOfSight_return": "見通しがはっきりしている場合は <i>true</i> を返します。",
	"func_listen": "前のエンティティの say() の配列を [entity_id, message] の形式で返します。",
	"func_listen_return": "前の say() の配列。",
	"func_log": "数値 <b>number</b> の自然対数を計算します。",
	"func_log_arg_1": "]0 の範囲の数値。 +∞[。",
	"func_log_return": "<b>数値</b>の自然対数。",
	"func_log10": "数値 <b>number</b> の 10 を底とする対数を計算します。",
	"func_log10_arg_1": "]0 の範囲の数値。 +∞[。",
	"func_log10_return": "<b>number</b> の 10 を底とする対数。",
	"func_log2": "数値 <b>number</b> の 2 を底とする対数を計算します。",
	"func_log2_arg_1": "]0 の範囲の数値。 +∞[。",
	"func_log2_return": "<b>number</b> の 2 を底とする対数。",
	"func_mark": "パラメータで指定されたターン数の間、パラメータで指定された色の 1 つまたは複数のセルを地面にマークします。このマーキングは、エンティティのブリーダーのみが見ることができます。",
	"func_mark_arg_1": "マークするセルまたは複数のセルの配列",
	"func_mark_arg_2": "マーキング色",
	"func_mark_arg_3": "マーキング期間",
	"func_mark_return": "すべてがうまくいった場合は true を返します",
	"func_markText": "パラメータで指定されたターン数の間、地面のパラメータで指定された色の 1 つ以上のセルにテキストを書き込みます。これらのテキストは、エンティティの育成者にのみ表示されます。",
	"func_markText_arg_1": "書き込むセルまたは複数のセルの配列",
	"func_markText_arg_2": "書くテキスト（最大10文字）",
	"func_markText_arg_3": "テキストの色",
	"func_markText_arg_4": "テキストの長さ",
	"func_markText_return": "すべてがうまくいった場合は true を返します",
	"func_max": "2 つの数値 <b>a</b> と <b>b</b> の大きい方の値を返します。",
	"func_max_arg_1": "数。",
	"func_max_arg_2": "数。",
	"func_max_return": "<b>a</b> と <b>b</b> の間の大きい方の数。",
	"func_min": "2 つの数値 <b>a</b> と <b>b</b> の小さい方の値を返します。",
	"func_min_arg_1": "数。",
	"func_min_arg_2": "数。",
	"func_min_return": "<b>a</b> と <b>b</b> の間の小さい方の数値。",
	"func_moveAwayFrom": "最大 <b>mp</b> の移動ポイントを使用して、エンティティを別の <b>エンティティ</b> から遠ざけます。",
	"func_moveAwayFrom_arg_1": "あなたのエンティティが離れるべきエンティティ。",
	"func_moveAwayFrom_arg_2": "使用するMPの最大数。デフォルトでは制限なし。",
	"func_moveAwayFrom_return": "使用される移動ポイントの数。",
	"func_moveAwayFromCell": "最大 <b>mp</b> の移動ポイントを使用して、エンティティを 1 <b>cell</b> セル離れた場所に移動します。",
	"func_moveAwayFromCell_arg_1": "エンティティが離れるべきセル。",
	"func_moveAwayFromCell_arg_2": "使用するMPの最大数。デフォルトでは制限なし。",
	"func_moveAwayFromCell_return": "使用される移動ポイントの数。",
	"func_moveAwayFromCells": "最大 <b>mp</b> の移動ポイントを使用して、一連の <b>cells</b> 個のセルからエンティティを移動します。",
	"func_moveAwayFromCells_arg_1": "エンティティが離れるべきセルを含む配列。",
	"func_moveAwayFromCells_arg_2": "使用するMPの最大数。デフォルトでは制限なし。",
	"func_moveAwayFromCells_return": "使用される移動ポイントの数。",
	"func_moveAwayFromLeeks": "最大 <b>mp</b> の移動ポイントを使用して、一連の <b>エンティティ</b> からエンティティを遠ざけます。",
	"func_moveAwayFromLeeks_arg_1": "エンティティが離れるべきエンティティの ID を含む配列。",
	"func_moveAwayFromLeeks_arg_2": "使用するMPの最大数。デフォルトでは制限なし。",
	"func_moveAwayFromLeeks_return": "使用される移動ポイントの数。",
	"func_moveAwayFromEntities": "最大 <b>mp</b> の移動ポイントを使用して、一連の <b>エンティティ</b> からエンティティを遠ざけます。",
	"func_moveAwayFromEntities_arg_1": "エンティティが離れるべきエンティティの ID を含む配列。",
	"func_moveAwayFromEntities_arg_2": "使用するMPの最大数。デフォルトでは制限なし。",
	"func_moveAwayFromEntities_return": "使用される移動ポイントの数。",
	"func_moveAwayFromLine": "最大 <b>mp</b> の移動ポイントを使用して、2 つのセル <b>cell1</b> と <b>cell2</b> によって定義されるラインからエンティティを移動します。",
	"func_moveAwayFromLine_arg_1": "セル 1。",
	"func_moveAwayFromLine_arg_2": "セル 2。",
	"func_moveAwayFromLine_arg_3": "使用するMPの最大数。デフォルトでは制限なし。",
	"func_moveAwayFromLine_return": "使用される移動ポイントの数。",
	"func_moveToward": "最大 <b>mp</b> の移動ポイントを使用して、エンティティを別の <b>エンティティ</b> に近づけます。",
	"func_moveToward_arg_1": "あなたのエンティティがアプローチすべきエンティティ。",
	"func_moveToward_arg_2": "使用するMPの最大数。",
	"func_moveToward_return": "使用される移動ポイントの数。",
	"func_moveTowardCell": "最大 <b>mp</b> の移動ポイントを使用して、エンティティを 1 <b>cell</b> セル近くに移動します。",
	"func_moveTowardCell_arg_1": "フィーチャがアプローチするセル。",
	"func_moveTowardCell_arg_2": "使用するMPの最大数。デフォルトでは制限なし。",
	"func_moveTowardCell_return": "使用される移動ポイントの数。",
	"func_moveTowardCells": "最大 <b>mp</b> の移動ポイントを使用して、エンティティを <b>cells</b> 個のセルのセットに近づけます。",
	"func_moveTowardCells_arg_1": "エンティティがアプローチするセルを含む配列。",
	"func_moveTowardCells_arg_2": "使用するMPの最大数。デフォルトでは制限なし。",
	"func_moveTowardCells_return": "使用される移動ポイントの数。",
	"func_moveTowardLeeks": "最大 <b>mp</b> の移動ポイントを使用して、エンティティを <b>エンティティ</b> のセットに近づけます。",
	"func_moveTowardLeeks_arg_1": "エンティティがアプローチするエンティティの ID を含む配列。",
	"func_moveTowardLeeks_arg_2": "使用するMPの最大数。デフォルトでは制限なし。",
	"func_moveTowardLeeks_return": "使用される移動ポイントの数。",
	"func_moveTowardEntities": "最大 <b>mp</b> の移動ポイントを使用して、エンティティを <b>エンティティ</b> のセットに近づけます。",
	"func_moveTowardEntities_arg_1": "エンティティがアプローチするエンティティの ID を含む配列。",
	"func_moveTowardEntities_arg_2": "使用するMPの最大数。デフォルトでは制限なし。",
	"func_moveTowardEntities_return": "使用される移動ポイントの数。",
	"func_moveTowardLine": "最大 <b>mp</b> 移動ポイントを使用して、エンティティを 2 つのセル <b>cell1</b> と <b>cell2</b> で定義される線に近づけます。",
	"func_moveTowardLine_arg_1": "セル 1。",
	"func_moveTowardLine_arg_2": "セル 2。",
	"func_moveTowardLine_arg_3": "使用するMPの最大数。デフォルトでは制限なし。",
	"func_moveTowardLine_return": "使用される移動ポイントの数。",
	"func_number": "値を数値に変換します。値が文字列の場合、number 関数はそれを数値に変換しようとします。値が既に数値の場合、関数は数値を返し、その他の型の場合は <i>null</i> を返します。",
	"func_number_arg_1": "数値に変換する値。",
	"func_number_return": "変換された数値。",
	"func_pause": "関数を使用しているエンティティのブリーダーに対してのみ、戦いを一時停止します。",
	"func_pop": "<b>配列</b>から最後のセルを削除し、対応する要素を返します。",
	"func_pop_arg_1": "最後のボックスが削除されるアレイ。",
	"func_pop_return": "古い最後の箱に入っていたアイテム。",
	"func_pow": "基数を指数乗します。",
	"func_pow_arg_1": "本拠。",
	"func_pow_arg_2": "出品者です。",
	"func_pow_return": "<b>基数</b>の<b>指数</b>乗。",
	"func_push": "<b>array</b> の末尾に <b>element</b> 要素を追加します。",
	"func_pushAll": "<b>elements</b> 配列のすべての要素を <b>array</b> の末尾に追加します。",
	"func_pushAll_arg_1": "要素を追加する配列。",
	"func_pushAll_arg_2": "追加するアイテム。",
	"func_push_arg_1": "要素を追加する配列。",
	"func_push_arg_2": "追加するアイテム。",
	"func_rand": "0 (含む) と 1 (含まない) の間の実乱数を返します。",
	"func_randFloat": "<b>a</b> (含む) と <b>b</b> (含まない) の間の実乱数を返します。",
	"func_randFloat_arg_1": "下限。",
	"func_randFloat_arg_2": "上界。",
	"func_randFloat_return": "[a; の範囲の乱数。 b[。",
	"func_randInt": "<b>a</b> (含む) と <b>b</b> (含まない) の間のランダムな整数を返します。",
	"func_randInt_arg_1": "下限。",
	"func_randInt_arg_2": "上界。",
	"func_randInt_return": "範囲 [a; のランダムな整数。 b[。",
	"func_rand_return": "[0; の範囲の乱数。 1[.",
	"func_remove": "配列 <b>array</b> から <b>position</b> の位置にある要素を削除し、削除された要素を返します。",
	"func_remove_arg_1": "要素を削除する配列。",
	"func_remove_arg_2": "削除する要素の位置。",
	"func_removeElement": "<b>array</b> 内で最初に出現した <b>element</b> を削除します。要素が見つからない場合、配列は変更されません。",
	"func_removeElement_arg_1": "要素を削除する配列。",
	"func_removeElement_arg_2": "検索して削除するアイテム。",
	"func_removeKey": "キー <b>key</b> に関連付けられた配列 <b>array</b> から要素を削除します。",
	"func_removeKey_arg_1": "要素を削除する配列。",
	"func_removeKey_arg_2": "削除するアイテムに関連付けられたキー。",
	"func_remove_return": "配列から削除された要素。",
	"func_replace": "文字列 <b>string</b> 内のすべての <b>search</b> を <b>replace</b> に置き換えます。",
	"func_replace_arg_1": "置換が行われる文字列。",
	"func_replace_arg_2": "置換する部分文字列。",
	"func_replace_arg_3": "交換用弦。",
	"func_replace_return": "置換を含む結果文字列。",
	"func_resurrect": "CHIP_RESURRECTION チップを使用して、セル <b>cell</b> で、ID <b>entity</b> の死んだエンティティを復活させます。",
	"func_resurrect_arg_1": "復活させるエンティティの ID。",
	"func_resurrect_arg_2": "エンティティが再表示されるセル。",
	"func_resurrect_return": "定数 USE_* の中で、チップを起動した結果。",
	"func_reverse": "<b>配列</b>を反転します。",
	"func_reverse_arg_1": "反転するテーブル。",
	"func_round": "<b>number</b> の丸めを返します。<br/>#floor で切り捨て、#ceil で切り上げを見つけることができます。",
	"func_round_arg_1": "四捨五入を求める数値。",
	"func_round_return": "<b>数値</b>で四捨五入。",
	"func_say": "あなたのエンティティを話させます。",
	"func_say_arg_1": "あなたのエンティティがアリーナで発表するメッセージ。",
	"func_search": "配列 <b>array</b> 内の要素 <b>element</b> を位置 <b>start</b> から検索します。",
	"func_search_arg_1": "検索テーブル。",
	"func_search_arg_2": "検索するアイテム。",
	"func_search_arg_3": "検索の開始位置。",
	"func_search_return": "<b>start</b> から始まる <b>array</b> 内の <b>element</b> 要素の最初の出現位置。要素が見つからない場合は null。",
	"func_sendAll": "チーム全体にメッセージを送信します。",
	"func_sendAll_arg_1": "送信するメッセージのタイプ (MESSAGE_* 定数を参照)。",
	"func_sendAll_arg_2": "メッセージ パラメータ。任意の値を指定できます。",
	"func_sendTo": "ID <b>entity</b> のエンティティにメッセージを送信します。",
	"func_sendTo_arg_1": "メッセージの送信先エンティティの ID。",
	"func_sendTo_arg_2": "送信するメッセージのタイプ (MESSAGE_* 定数を参照)。",
	"func_sendTo_arg_3": "メッセージ パラメータ。任意の値を指定できます。",
	"func_sendTo_return": "送信が成功した場合は <i>true</i>、エラーが発生した場合は <i>false</i>。",
	"func_setRegister": "<b>値</b>を<b>キー</b>レジストリに保存します。\nキーと値は、それぞれ最大で <i>100</i> 文字と <i>5000</i> 文字を含む文字列です。ネギは最大 <i>100</i> 個のレジスターを持つことができます。すべてのレジスターがある場合、新しいレジスターへの保存は機能しません。\nすでに占有されています。",
	"func_setRegister_arg_1": "値を格納するレジストリ キー。",
	"func_setRegister_arg_2": "格納する値。",
	"func_setRegister_return": "操作が成功した場合は <i>true</i>、それ以外の場合は <i>false</i>。",
	"func_setWeapon": "エンティティに<b>武器</b>武器を装備します。",
	"func_setWeapon_arg_1": "装備する武器のID。",
	"func_shift": "<b>array</b> から最初のセルを削除し、対応する要素を返します。",
	"func_shift_arg_1": "最初のセルが削除される配列。",
	"func_shift_return": "古い最初のセルにあった要素。",
	"func_show": "1 ターンの間、プレイヤーに <b>color</b> の色の <b>cell</b> を表示します。この機能の使用には 1PT のコストがかかります。",
	"func_show_arg_1": "表示するセル",
	"func_show_arg_2": "マーキング色",
	"func_show_return": "すべてがうまくいった場合は true を返します",
	"func_shuffle": "ボードをランダムにシャッフルします。",
	"func_shuffle_arg_1": "シャッフルボード。",
	"func_signum": "数値 <b>number</b> の符号を決定します。",
	"func_signum_arg_1": "符号が決定される数。",
	"func_signum_return": "数値が正の場合は 1、数値がゼロの場合は 0、数値が負の場合は -1。",
	"func_sin": "パラメータとして渡された角度 <b>angle</b> の正弦を計算します",
	"func_sin_arg_1": "正弦が計算される角度",
	"func_sin_return": "角度 <b>angle</b> の正弦を返します",
	"func_sort": "<b>配列</b>を昇順または降順で並べ替えます。デフォルトの昇順は次のとおりです。\n<ul>\n<li>すべてのブール値 (最初に false、次に true)</li>\n<li>すべての数字 (最小から最大の順)</li>\n<li>すべてのチャンネル (アルファベット順)</li>\n<li>すべての null 要素</li>\n</ul>",
	"func_sort_arg_1": "ソートする配列。",
	"func_sort_arg_2": "<b>array</b> を昇順でソートするには #SORT_ASC を、降順でソートするには #SORT_DESC を使用します。",
	"func_split": "文字列 <b>string</b> を <b>delimiter</b> で区切られた部分文字列に分割します。",
	"func_split_arg_1": "チェーンを切断します。",
	"func_split_arg_2": "ある要素から別の要素への通過を区切る文字列。",
	"func_split_arg_3": "配列要素の最大数。",
	"func_split_return": "見つかった部分文字列を含む配列。",
	"func_sqrt": "数値 <b>number</b> の平方根を計算します。",
	"func_sqrt_arg_1": "根が計算される数値。",
	"func_sqrt_return": "<b>数値</b>の平方根。",
	"func_startsWith": "<b>string</b> が <b>prefix</b> で始まるかどうかを確認します。",
	"func_startsWith_arg_1": "検索文字列。",
	"func_startsWith_arg_2": "検索されたプレフィックス。",
	"func_startsWith_return": "<b>string</b> が <b>prefix</b> で始まる場合は true。",
	"func_string": "値を文字列に変換します。<br>\n値がすでに文字列である場合は、それが返されます。<br>\n値が x の場合、\"x\" が返されます。<br>\n値が配列の場合、\"[key1: value1, key2: value2, ...]\" という形式の文字列が返されます。<br>\n値がブール値の場合、true と false に対してそれぞれ「true」と「false」が返されます。<br>\n値が null の場合、\"null\" が返されます。",
	"func_string_arg_1": "文字列に変換する値。",
	"func_string_return": "変換された文字列。",
	"func_subArray": "位置 <b>start</b> から始まり、位置 <b>end</b> で終わる <b>array</b> の部分配列を返します。",
	"func_subArray_arg_1": "ソース テーブル。",
	"func_subArray_arg_2": "開始インデックス。",
	"func_subArray_arg_3": "インデックスを終了します。",
	"func_subArray_return": "サブアレイ。",
	"func_substring": "文字列 <b>string</b> の、インデックス <b>start</b> の文字から始まり、サイズ <b>length</b> の部分文字列を返します。",
	"func_substring_arg_1": "チェーンを切断します。",
	"func_substring_arg_2": "開始文字インデックス。",
	"func_substring_arg_3": "部分文字列の長さ。",
	"func_substring_return": "部分文字列。",
	"func_sum": "配列 <b>array</b> のすべての数値要素の合計を返します。",
	"func_sum_arg_1": "合計するテーブル。",
	"func_summon": "IA の関数 <b>ai</b> を持つセル <b>cell</b> 上のチップ <b>chip</b> によって決定されるエンティティを呼び出します。",
	"func_summon_arg_1": "召喚に使うチップ。チップは召喚タイプのチップでなければならず、召喚機能を使用するエンティティに装備する必要があります。",
	"func_summon_arg_2": "セルまたは召喚が表示されます。",
	"func_summon_arg_3": "関数としての呼び出し AI。",
	"func_summon_return": "召喚関数は #useChip 関数と同じリターンを持ちます。",
	"func_sum_return": "配列要素の合計。",
	"func_tan": "角度 <b>angle</b> のタンジェントを計算します (ラジアン単位)。",
	"func_tan_arg_1": "ラジアン単位の角度。",
	"func_tan_return": "<b>角度</b>のタンジェント。",
	"function_category_array": "リスト",
	"function_category_chip": "ノミ",
	"function_category_color": "色",
	"function_category_fight": "喧嘩",
	"function_category_leek": "ネギ",
	"function_category_map": "テーブル",
	"function_category_field": "接地",
	"function_category_math": "数字",
	"function_category_network": "通信網",
	"function_category_string": "チェーン",
	"function_category_utils": "ユーティリティ",
	"function_category_weapon": "兵器",
	"func_toDegrees": "角度 <b>ラジアン</b> を度に変換します。",
	"func_toDegrees_arg_1": "ラジアン値",
	"func_toDegrees_return": "角度 (度単位)。",
	"func_toLower": "<b>string</b> の大文字を小文字に変換します。",
	"func_toLower_arg_1": "変換する文字列",
	"func_toLower_return": "小文字の文字列",
	"func_toRadians": "角度 <b>度</b> をラジアンに変換します。",
	"func_toRadians_arg_1": "度の値",
	"func_toRadians_return": "ラジアン単位の角度。",
	"func_toUpper": "<b>string</b> の小文字を大文字に変換します。",
	"func_toUpper_arg_1": "変換する文字列。",
	"func_toUpper_return": "大文字の文字列。",
	"func_typeOf": "タイプ #TYPE_NULL、#TYPE_NUMBER、#TYPE_BOOLEAN、#TYPE_STRING、#TYPE_ARRAY、#TYPE_FUNCTION の中から値 <b>value</b> のタイプを返します。",
	"func_typeOf_arg_1": "タイプが返される値。",
	"func_typeOf_return": "<b>値</b>のタイプ。",
	"func_unshift": "<b>array</b> の先頭に <b>element</b> 要素を追加します。",
	"func_unshift_arg_1": "要素が追加される配列。",
	"func_unshift_arg_2": "追加するアイテム。",
	"func_useChip": "エンティティ <b>entity</b> でチップ <b>chip</b> を使用します。",
	"func_useChip_arg_1": "使用するチップ。",
	"func_useChip_arg_2": "ターゲット エンティティ。",
	"func_useChipOnCell": "セル <b>cell</b> でチップ <b>chip</b> を使用します。",
	"func_useChipOnCell_arg_1": "使用するチップ。",
	"func_useChipOnCell_arg_2": "標的細胞。",
	"func_useChipOnCell_return": "攻撃が開始された場合は 0 より大きい値。",
	"func_useChip_return": "UseChip の戻り値は次のとおりです。<br>\n<ul>\n<li>クリティカル ヒットで #USE_CRITICAL</li>\n<li>#USE_SUCCESS、成功した場合</li>\n<li>#USE_FAILED、失敗時</li>\n<li>#USE_INVALID_TARGET、ターゲットが存在しない場合</li>\n<li>#USE_NOT_ENOUGH_TP、エンティティに十分な TP がない場合</li>\n<li>#USE_INVALID_COOLDOWN、チップがまだ使用できない場合</li>\n<li>#USE_INVALID_POSITION、範囲が悪い場合、または見通しがはっきりしない場合</li>\n</ul>",
	"func_useWeapon": "選択した武器を<b>エンティティ</b>に使用。",
	"func_useWeapon_arg_1": "ターゲット エンティティ。",
	"func_useWeaponOnCell": "<b>セル</b>セルで選択した武器を使用します。",
	"func_useWeaponOnCell_arg_1": "標的細胞。",
	"func_useWeaponOnCell_return": "攻撃が開始された場合は 0 より大きい値。",
	"func_useWeapon_return": "UseWeapon の戻り値は次のとおりです。<br>\n<ul>\n<li>クリティカル ヒットで #USE_CRITICAL</li>\n<li>#USE_SUCCESS、成功した場合</li>\n<li>#USE_FAILED、失敗時</li>\n<li>#USE_INVALID_TARGET、ターゲットが存在しない場合</li>\n<li>#USE_NOT_ENOUGH_TP、エンティティに十分な TP がない場合</li>\n<li>#USE_INVALID_POSITION、範囲が悪いか、見通しがはっきりしない場合</li>\n</ul>",
	"func_weaponNeedLos": "<b>武器</b>の武器が発砲するために照準線が必要かどうかを返します。",
	"func_weaponNeedLos_arg_1": "テストする武器の ID。",
	"func_weaponNeedLos_return": "<b>武器</b>の武器を発射するために照準線が必要な場合は<i>true</i>、それ以外の場合は<i>false</i>。",
	"items": "アイテム",
	"max_cores": "最大",
	"max_level": "最大",
	"min_cores": "最小ハート",
	"min_level": "最小レベル",
	"n_operations": "<b>{0}</b> オペレーション",
	"parameters": "設定",
	"return": "フィードバック",
	"value": "価値",
	"variable_operations": "<b>変数</b>操作",
	"arg_type_9": "パッケージ",
	"arg_type_10": "間隔",
	"function_category_set": "パッケージ",
	"function_category_interval": "インターバル",
	"arg_type_96": "set<entier>"
}

	i18n.mergeLocaleMessage("ja", { "doc": messages })
	