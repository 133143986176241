
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> operación",
	"arg_type_-1": "?",
	"arg_type_1": "número",
	"arg_type_2": "cadena",
	"arg_type_3": "booleano",
	"arg_type_4": "listado",
	"arg_type_41": "lista<número>",
	"arg_type_42": "lista<cadena>",
	"arg_type_43": "lista<booleano>",
	"arg_type_44": "lista<lista>",
	"arg_type_46": "lista<entero>",
	"arg_type_47": "lista<real>",
	"arg_type_5": "función",
	"arg_type_6": "entero",
	"arg_type_7": "verdadero",
	"arg_type_8": "mesa",
	"arg_type_806": "tabla<?, entero>",
	"complexity": "Complejidad {c}",
	"operations": "{o} operaciones",
	"const_AREA_CIRCLE_1": "Área circular de 3 cuadrados de diámetro (cruz).",
	"const_AREA_CIRCLE_2": "Área circular de 5 cuadrados de diámetro.",
	"const_AREA_CIRCLE_3": "Área circular de 7 cuadrados de diámetro.",
	"const_AREA_LASER_LINE": "Zona de un láser, línea desde el alcance mínimo del láser hasta su alcance máximo o un obstáculo.",
	"const_AREA_POINT": "Área compuesta por una sola caja.",
	"const_CELL_EMPTY": "Valor devuelto de getCellContent(cell) para una celda vacía.",
	"const_CELL_OBSTACLE": "Valor devuelto de getCellContent(cell) para una celda que contiene un obstáculo.",
	"const_CELL_PLAYER": "Valor devuelto de getCellContent(cell) para una celda que contiene una entidad.",
	"const_CELL_ENTITY": "Valor devuelto de getCellContent(cell) para una celda que contiene una entidad.",
	"const_COLOR_BLUE": "Color azul.",
	"const_COLOR_GREEN": "Color verde.",
	"const_COLOR_RED": "Color rojo.",
	"const_E": "Número de Euler.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Otorga un escudo absoluto a una entidad, lo que reduce la cantidad de puntos de vida eliminados por el daño (EFFECT_DAMAGE) en una cantidad fija. Amplificado por la resistencia.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Elimina el escudo absoluto de una entidad. No se amplifica por una característica. Le permite aumentar los puntos de golpe eliminados por daño (EFFECT_DAMAGE) en una cantidad absoluta.",
	"const_EFFECT_AFTEREFFECT": "Elimina puntos de golpe de una entidad. Ampliado por la ciencia. Reduce los puntos de vida máximos en un 5% de la cantidad de puntos de vida eliminados.",
	"const_EFFECT_ANTIDOTE": "Elimina todo el veneno (EFFECT_POISON) presente en un objetivo.",
	"const_EFFECT_BOOST_MAX_LIFE": "Aumenta los puntos de golpe y los puntos de golpe máximos de una entidad. Amplificado por la sabiduría.",
	"const_EFFECT_BUFF_AGILITY": "Otorga agilidad a una entidad. Ampliado por la ciencia.",
	"const_EFFECT_BUFF_MP": "Otorga puntos de movimiento a una entidad. Ampliado por la ciencia.",
	"const_EFFECT_BUFF_RESISTANCE": "Otorga resistencia a una entidad. Ampliado por la ciencia.",
	"const_EFFECT_BUFF_STRENGTH": "Otorga fuerza a una entidad. Ampliado por la ciencia.",
	"const_EFFECT_BUFF_TP": "Otorga puntos de acción a una entidad. Ampliado por la ciencia.",
	"const_EFFECT_BUFF_WISDOM": "Otorga sabiduría a una entidad. Ampliado por la ciencia.",
	"const_EFFECT_DAMAGE": "Elimina puntos de golpe de una entidad. Fuerza amplificada. Interactúa con escudos (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), robo de vida (excepto lanzador) y devolución de daño (EFFECT_DAMAGE_RETURN). Reduce los puntos de vida máximos en un 5% de la cantidad de puntos de vida eliminados.",
	"const_EFFECT_DAMAGE_RETURN": "Proporciona reflejo de daño a una entidad, lo que permite eliminar puntos de vida de las entidades que infligen daño al destinatario. Amplificado por la agilidad. Reduce los puntos de vida máximos en un 5% de la cantidad de puntos de vida eliminados.",
	"const_EFFECT_DEBUFF": "Reduce el valor de todos los efectos sobre una entidad en un porcentaje.",
	"const_EFFECT_HEAL": "Restaura puntos de vida a una entidad, limitado por los puntos de vida máximos. Amplificado por la sabiduría.",
	"const_EFFECT_INVERT": "Intercambia la posición del lanzador con la de una entidad.",
	"const_EFFECT_KILL": "Elimina todos los puntos de vida de una entidad.",
	"const_EFFECT_LIFE_DAMAGE": "Elimina puntos de vida de una entidad, en función de un porcentaje de los puntos de vida del lanzador. Interactúa con escudos (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) y devolución de daño (EFFECT_DAMAGE_RETURN). Reduce los puntos de vida máximos en un 5% de la cantidad de puntos de vida eliminados.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "El efecto se multiplica por el número de entidades afectadas en la zona.",
	"const_EFFECT_MODIFIER_ON_CASTER": "El efecto todavía afecta al lanzador.",
	"const_EFFECT_MODIFIER_STACKABLE": "El efecto es acumulativo.",
	"const_EFFECT_NOVA_DAMAGE": "Elimina el máximo de puntos de vida. Ampliado por la ciencia.",
	"const_EFFECT_POISON": "Elimina puntos de golpe de una entidad. Amplificado por magia. Reduce los puntos de vida máximos en un 10 % de la cantidad de puntos de vida eliminados.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Otorga un escudo absoluto a una entidad, lo que reduce la cantidad de puntos de vida eliminados por el daño (EFFECT_DAMAGE) en una cantidad fija. No amplificable.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Otorga agilidad a una entidad. No amplificable.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Otorga magia a una entidad. No amplificable.",
	"const_EFFECT_RAW_BUFF_MP": "Otorga puntos de movimiento a una entidad. No amplificable.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Otorga ciencia a una entidad. No amplificable.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Otorga fuerza a una entidad. No amplificable.",
	"const_EFFECT_RAW_BUFF_TP": "Otorga puntos de acción a una entidad. No amplificable.",
	"const_EFFECT_RELATIVE_SHIELD": "Proporciona un escudo relativo, lo que reduce la cantidad de puntos de vida eliminados por el daño (EFFECT_DAMAGE) en una cantidad relativa. Amplificado por la resistencia.",
	"const_EFFECT_RESURRECT": "Resucita una entidad, con un número de HP máximo igual a la mitad del número de HP máximo de la entidad antes de la resurrección, y un número de HP actual igual a una cuarta parte del número de HP máximo antes de la resurrección.",
	"const_EFFECT_SHACKLE_MAGIC": "Elimina la magia de una entidad. Amplificado por magia.",
	"const_EFFECT_SHACKLE_MP": "Eliminar puntos de movimiento de una entidad. Amplificado por magia.",
	"const_EFFECT_SHACKLE_STRENGTH": "Elimina la fuerza de una entidad. Amplificado por magia.",
	"const_EFFECT_SHACKLE_TP": "Deducir puntos de acción de una entidad. Amplificado por magia.",
	"const_EFFECT_SUMMON": "Invoca una bombilla. No tiene efecto si se alcanza el límite de invocación del equipo.",
	"const_EFFECT_TARGET_ALLIES": "Afecta a los aliados.",
	"const_EFFECT_TARGET_CASTER": "Afecta al lanzador.",
	"const_EFFECT_TARGET_ENEMIES": "Afecta a los enemigos.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Afecta a entidades no invocadas (puerros y torretas).",
	"const_EFFECT_TARGET_SUMMONS": "Afecta a las entidades convocadas (Bombillas).",
	"const_EFFECT_TELEPORT": "Cambia la posición del lanzador.",
	"const_EFFECT_VULNERABILITY": "Elimina el escudo relativo a una entidad. No se amplifica por una característica. Aumenta los puntos de golpe eliminados por daño (EFFECT_DAMAGE) en una cantidad relativa.",
	"const_ENTITY_BULB": "Se refiere a una entidad de tipo Bulbo.",
	"const_ENTITY_LEEK": "Se refiere a una entidad de tipo puerro.",
	"const_ENTITY_TURRET": "Se refiere a una entidad de tipo Torreta.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Fondo de combate tipo desafío.",
	"const_FIGHT_CONTEXT_GARDEN": "Contexto de lucha en la huerta.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Contexto de combate en Battle Royale.",
	"const_FIGHT_CONTEXT_TEST": "Prueba el contexto de la batalla.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Fondo de lucha del torneo.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Lucha en Battle Royale.",
	"const_FIGHT_TYPE_FARMER": "Pelea de criadores.",
	"const_FIGHT_TYPE_SOLO": "Lucha en solitario.",
	"const_FIGHT_TYPE_TEAM": "Pelea de equipo.\n",
	"const_INSTRUCTIONS_LIMIT": "Número máximo de instrucciones que una entidad puede usar durante su turno.",
	"const_MAX_TURNS": "Número máximo de turnos en una pelea.",
	"const_OPERATIONS_LIMIT": "Número máximo de operaciones que una entidad puede usar durante su turno.",
	"const_PI": "La razón de la circunferencia de un círculo a su diámetro.",
	"const_SORT_ASC": "Le dice a la función #sort que ordene en orden ascendente.",
	"const_SORT_DESC": "Le dice a la función #sort que ordene en orden descendente.",
	"const_TYPE_ARRAY": "Tipo de valor <b>lista</b>",
	"const_TYPE_BOOLEAN": "Tipo de valor <b>booleano</b>",
	"const_TYPE_FUNCTION": "Tipo de valor <b>función</b>",
	"const_TYPE_NULL": "Tipo de valor <b>null</b>",
	"const_TYPE_NUMBER": "Tipo de valor <b>número</b>",
	"const_TYPE_STRING": "Tipo de valor <b>cadena de caracteres</b>",
	"const_USE_CRITICAL": "Valor devuelto por las funciones #useWeapon, #useWeaponOnCell, #useChip y #useChipOnCell en caso de golpe crítico.",
	"const_USE_FAILED": "Valor devuelto por las funciones #useWeapon, #useWeaponOnCell, #useChip y #useChipOnCell en caso de falla.",
	"const_USE_INVALID_COOLDOWN": "Valor devuelto por las funciones #useChip y #useChipOnCell si el chip aún no se puede utilizar.",
	"const_USE_INVALID_POSITION": "Valor devuelto por las funciones #useWeapon, #useWeaponOnCell, #useChip y #useChipOnCell si el alcance es malo o la línea de visión no es clara.",
	"const_USE_INVALID_TARGET": "Valor devuelto por las funciones #useWeapon y #useChip si el objetivo no existe.",
	"const_USE_NOT_ENOUGH_TP": "Valor devuelto por las funciones #useWeapon, #useWeaponOnCell, #useChip y #useChipOnCell si el lanzador no tiene suficientes puntos de acción para usar el objeto.",
	"const_USE_RESURRECT_INVALID_ENTITY": "Valor devuelto por la función #resurrect cuando la entidad especificada no existe o aún no está muerta.",
	"const_USE_SUCCESS": "Valor devuelto por las funciones #useWeapon, #useWeaponOnCell, #useChip y #useChipOnCell en caso de éxito.",
	"const_USE_TOO_MANY_SUMMONS": "Error devuelto por #summon cuando ya tienes <b>8</b> convocatorias en vivo.\n",
	"func_abs": "Devuelve el valor absoluto del número <b>número</b>.",
	"func_abs_arg_1": "El número cuyo valor absoluto se calculará.",
	"func_abs_return": "El valor absoluto del número.",
	"func_acos": "Calcula el arcocoseno de <b>argumento</b>, en el intervalo [0, #PI].",
	"func_acos_arg_1": "Número cuyo arcocoseno se calculará.",
	"func_acos_return": "El arco coseno del <b>argumento</b>.",
	"func_arrayConcat": "Agregue las dos matrices de extremo a extremo. Las claves de texto se conservan y todas las claves numéricas se vuelven a indexar.",
	"func_arrayConcat_arg_1": "Primera mesa.",
	"func_arrayConcat_arg_2": "Segunda mesa.",
	"func_arrayConcat_return": "Suma de las dos matrices.",
	"func_arrayFilter": "Devuelve una nueva matriz que contiene todos los pares clave/valor de la matriz de origen para la que la función de devolución de llamada devolvió verdadero. Si la función de devolución de llamada toma un parámetro, se enviará el valor de la matriz de origen, si toma dos parámetros, se enviará la clave y el valor.",
	"func_arrayFilter_arg_1": "Mesa original.",
	"func_arrayFilter_arg_2": "Función llamada para cada elemento.",
	"func_arrayFilter_return": "Mesa nueva.",
	"func_arrayFlatten": "Devuelve una nueva matriz que contiene todos los elementos de la matriz de origen. Todos los elementos contenidos en un subarreglo se extraen en el nuevo arreglo. El argumento de profundidad determina la profundidad máxima del subarreglo para extraer.\n<código>arreglo var = [1, 2, [[3, 4], 5], [6]];\ndepuración (arrayFlatten (arreglo, 1)); // [1,2,[3,4],5,6]</código>",
	"func_arrayFlatten_arg_1": "Mesa original.",
	"func_arrayFlatten_arg_2": "Profundidad máxima.",
	"func_arrayFlatten_return": "Mesa nueva.",
	"func_arrayFoldLeft": "Reducir el array <b>array</b> [v1, v2, ..., vn] desde la izquierda partiendo del valor <b>v0</b> y aplicando la función <b>f</b>. Es igual a :\n<código>f(f(f(v0, v1), v2), ...)</código>",
	"func_arrayFoldLeft_arg_1": "Mesa original.",
	"func_arrayFoldLeft_arg_2": "Función a aplicar.",
	"func_arrayFoldLeft_arg_3": "Valor inicial.",
	"func_arrayFoldLeft_return": "Mesa reducida.",
	"func_arrayFoldRight": "Reducir el array <b>array</b> [v1, v2, ..., vn] desde la derecha partiendo del valor <b>v0</b> y aplicando la función <b>f</b>. Es igual a :\n<código>f(v1, f(v2, ..., f(vn, v0)))</código>",
	"func_arrayFoldRight_arg_1": "Mesa original.",
	"func_arrayFoldRight_arg_2": "Función a aplicar.",
	"func_arrayFoldRight_arg_3": "Valor inicial.",
	"func_arrayFoldRight_return": "Mesa reducida.",
	"func_arrayIter": "Llama a la función de devolución de llamada para cada elemento de la matriz. Si la función de devolución de llamada toma un parámetro, se enviará el valor de la matriz de origen, si toma dos parámetros, se enviará la clave y el valor.",
	"func_arrayIter_arg_1": "Mesa original.",
	"func_arrayIter_arg_2": "Función llamada para cada elemento.",
	"func_arrayMap": "Devuelve una nueva matriz que contiene para cada tecla de la matriz de origen, el valor devuelto por la función de devolución de llamada. Si la función de devolución de llamada toma un parámetro, se enviará el valor de la matriz de origen, si toma dos parámetros, se enviará la clave y el valor.",
	"func_arrayMap_arg_1": "Mesa original.",
	"func_arrayMap_arg_2": "Función llamada para cada elemento.",
	"func_arrayMap_return": "Mesa nueva.",
	"func_arrayMax": "Devuelve el elemento de valor máximo de la matriz <b>array</b>.<br/>Para obtener más información sobre el valor máximo de una matriz, consulte #sort.",
	"func_arrayMax_arg_1": "Array en el que se buscará el valor máximo.",
	"func_arrayMax_return": "Artículo de valor máximo.",
	"func_arrayMin": "Devuelve el elemento de valor mínimo de la matriz <b>array</b>.<br/>Para obtener más información sobre el valor mínimo de una matriz, consulte #sort.",
	"func_arrayMin_arg_1": "Array en el que se buscará el valor mínimo.",
	"func_arrayMin_return": "Artículo de valor mínimo.",
	"func_arrayPartition": "Devuelve una nueva matriz que contiene dos listas, en la primera están todos los pares clave/valor para los que la función de devolución de llamada devolvió verdadero, en la segunda están todos los demás. Si la función de devolución de llamada toma un parámetro, se enviará el valor de la matriz de origen, si toma dos parámetros, se enviará la clave y el valor.",
	"func_arrayPartition_arg_1": "Mesa original.",
	"func_arrayPartition_arg_2": "Función llamada para cada elemento.",
	"func_arrayPartition_return": "Mesa nueva.",
	"func_arraySort": "Ordena la matriz según el orden definido por la función de devolución de llamada. Los elementos se comparan de dos en dos, la función de devolución de llamada debe devolver los valores -1, 0 o 1 dependiendo de si el primer valor está antes, al mismo nivel o después del segundo valor. Si la función callback toma 2 parámetros se envían los dos valores, si toma 4 se envían los pares clave/valor.",
	"func_arraySort_arg_1": "Mesa original",
	"func_arraySort_arg_2": "Ordenar función.",
	"func_arraySort_return": "La matriz ordenada",
	"func_asin": "Calcula el arcoseno del <b>argumento</b>, en el intervalo [0, #PI].",
	"func_asin_arg_1": "Número cuyo arcoseno se calculará.",
	"func_asin_return": "El arcoseno del <b>argumento</b>.",
	"func_assocSort": "Ordena la matriz <b>matriz</b> conservando la asociación clave:valor según el orden <b>orden</b>.",
	"func_assocSort_arg_1": "La matriz a ordenar.",
	"func_assocSort_arg_2": "El orden de clasificación: #SORT_ASC o #SORT_DESC.",
	"func_atan": "Calcula la arcotangente del <b>argumento</b>, en el intervalo [0, #PI].",
	"func_atan2": "Convierte coordenadas cartesianas (<b>x</b>, <b>y</b>) a coordenadas polares (<b>r</b>, <b>theta</b>). Esta función devuelve el ángulo <b>theta</b> entre -#PI y #PI usando los signos de los argumentos.",
	"func_atan2_arg_1": "Coordenada y.",
	"func_atan2_arg_2": "Coordenada x.",
	"func_atan2_return": "El ángulo <b>theta</b> en coordenadas polares del punto (x, y).",
	"func_atan_arg_1": "Número cuyo arcotangente se calculará.",
	"func_atan_return": "El arco tangente del <b>argumento</b>.",
	"func_average": "Calcula el promedio de los elementos contenidos en la matriz <b>matriz</b>.",
	"func_average_arg_1": "Matriz cuyo promedio queremos calcular.",
	"func_average_return": "Valor medio.",
	"func_canUseChip": "Determina si su entidad puede usar el chip <b>chip</b> en la entidad con id <b>entidad</b>.",
	"func_canUseChip_arg_1": "El número del chip a probar.",
	"func_canUseChip_arg_2": "La identificación de la entidad en la que desea usar el chip.",
	"func_canUseChip_return": "<i>verdadero</i> si su entidad puede usar el chip, <i>falso</i> de lo contrario.",
	"func_canUseChipOnCell": "Determina si su entidad puede usar el chip <b>chip</b> en la celda <b>celda</b>.",
	"func_canUseChipOnCell_arg_1": "El número del chip a probar.",
	"func_canUseChipOnCell_arg_2": "El número de la celda en la que desea utilizar el chip.",
	"func_canUseChipOnCell_return": "<i>verdadero</i> si su entidad puede usar el chip, <i>falso</i> de lo contrario.",
	"func_canUseWeapon": "Determina si su entidad puede disparar a la entidad con id <b>entidad</b> con el arma <b>arma</b>.",
	"func_canUseWeapon_arg_1": "El arma a probar. Predeterminado a su arma equipada actualmente.",
	"func_canUseWeapon_arg_2": "La identificación de la entidad a la que desea disparar.",
	"func_canUseWeapon_return": "<i>verdadero</i> si su entidad puede disparar, <i>falso</i> de lo contrario.",
	"func_canUseWeaponOnCell": "Determina si tu entidad puede disparar a la <b>célula</b> con el arma <b>arma</b>.",
	"func_canUseWeaponOnCell_arg_1": "El arma a probar. Por defecto a tu arma actualmente equipada.",
	"func_canUseWeaponOnCell_arg_2": "El número de la celda que desea disparar.",
	"func_canUseWeaponOnCell_return": "<i>verdadero</i> si su entidad puede disparar, <i>falso</i> de lo contrario.",
	"func_cbrt": "Calcula la raíz cúbica de <b>número</b>",
	"func_cbrt_arg_1": "Número cuya raíz cúbica queremos calcular.",
	"func_cbrt_return": "Raíz cúbica de <b>número</b>.",
	"func_ceil": "Devuelve el redondeo hacia arriba de <b>número</b>.<br/>Puede encontrar el redondeo hacia abajo con #piso y el redondeo con #redondeo.",
	"func_ceil_arg_1": "Número para el que queremos encontrar el redondeo superior.",
	"func_ceil_return": "Redondea el <b>número</b>.",
	"func_charAt": "Devuelve el carácter de la cadena <b>cadena</b> ubicada en la posición <b>posición</b>.<br/>El primer carácter de la cadena está en la posición 0.",
	"func_charAt_arg_1": "Cadena de la que queremos recuperar un carácter.",
	"func_charAt_arg_2": "Posición del personaje a buscar.",
	"func_charAt_return": "cadena que contiene el carácter buscado, o una cadena vacía si la posición no es válida.",
	"func_chipNeedLos": "Devuelve si el <b>chip</b> necesita línea de visión para ser utilizado.",
	"func_chipNeedLos_arg_1": "La identificación del chip a probar.",
	"func_chipNeedLos_return": "<i>verdadero</i> si el <b>chip</b> necesita línea de visión para ser utilizado, <i>falso</i> de lo contrario.",
	"func_clearMarks": "Borra todas las marcas hechas por mark() y markText() en el campo.",
	"func_clone": "Copia el <b>valor</b> pasado como parámetro en los niveles de <b>nivel</b> y devuelve la copia. En el caso de un arreglo, por ejemplo, un clon(arreglo, 1) copiará el arreglo pero no sus elementos, un clon(arreglo, 2) copiará el arreglo así como todos los elementos.",
	"func_clone_arg_1": "El valor a clonar",
	"func_clone_arg_2": "El número de niveles a clonar.",
	"func_clone_return": "El valor clonado",
	"func_contains": "Determina si una cadena de <b>búsqueda</b> está dentro de una cadena de <b>cadena</b>.",
	"func_contains_arg_1": "Cadena en la que se realizará la búsqueda.",
	"func_contains_arg_2": "Cadena a buscar.",
	"func_contains_return": "verdadero si <b>buscar</b> está en <b>cadena</b>, falso en caso contrario.",
	"func_cos": "Calcula el coseno del <b>ángulo</b>.",
	"func_cos_arg_1": "Número cuyo coseno se calculará (en radianes).",
	"func_cos_return": "El coseno del <b>ángulo</b>, en el intervalo [-1, 1].",
	"func_count": "Cuenta el número de elementos en la <b>matriz</b>.",
	"func_count_arg_1": "Array cuyo número de elementos será contado.",
	"func_count_return": "Número de elementos de la matriz.",
	"func_debug": "Registra un mensaje de <b>objeto</b> en el registro personal, disponible en el informe al final de la pelea.",
	"func_debug_arg_1": "El mensaje a grabar.",
	"func_debugC": "Registra un mensaje de <b>objeto</b> en el registro personal, disponible en el informe al final de la pelea, del color <b>color</b>.",
	"func_debugC_arg_1": "El mensaje a grabar.",
	"func_debugC_arg_2": "El color del mensaje. Puede utilizar la función #getColor.",
	"func_debugE": "Registra un mensaje de error de <b>objeto</b> en el registro personal, disponible en el informe al final del combate.<br />Los mensajes de error aparecen en rojo en el informe del combate.",
	"func_debugE_arg_1": "El mensaje de error para iniciar sesión.",
	"func_debugW": "Registra un mensaje de advertencia de <b>objeto</b> en el registro personal, disponible en el informe al final de la pelea. <br />Los mensajes de advertencia aparecen en naranja en el informe de combate.",
	"func_debugW_arg_1": "El mensaje de advertencia a grabar.",
	"func_deleteRegister": "Elimina el registro asociado con la clave <b>clave</b> si existe.",
	"func_deleteRegister_arg_1": "La clave de registro para eliminar.",
	"func_endsWith": "Determina si una <b>cadena</b> termina con una cadena de <b>sufijo</b>.",
	"func_endsWith_arg_1": "Cadena en la que se realizará la búsqueda",
	"func_endsWith_arg_2": "Sufijo para buscar",
	"func_endsWith_return": "verdadero si <b>cadena</b> termina con <b>sufijo</b>",
	"func_exp": "Eleva el número de Euler #E a la potencia del <b>número</b>.",
	"func_exp_arg_1": "El exponente al que se elevará #E.",
	"func_exp_return": "#E** <b>número</b>.",
	"func_fill": "Cambia el tamaño de la matriz <b>matriz</b> a <b>tamaño</b> y llena todos sus cuadros con <b>valor</b>.",
	"func_fill_arg_1": "La tabla a completar.",
	"func_fill_arg_2": "El valor de cada cuadrado en la matriz.",
	"func_fill_arg_3": "El número de cajas. De forma predeterminada, el tamaño de matriz actual.",
	"func_floor": "Calcula el redondeo hacia abajo de <b>número</b>.<br/>Puede encontrar el redondeo hacia arriba con #ceil y el redondeo con #round.",
	"func_floor_arg_1": "Número cuyo redondeo hacia abajo queremos encontrar.",
	"func_floor_return": "Redondeado a <b>número</b>.",
	"func_getAbsoluteShield": "Devuelve el escudo absoluto de la entidad con id <b>entidad</b>. Para recuperar directamente el escudo absoluto de su entidad, use #getAbsoluteShield() sin parámetros.",
	"func_getAbsoluteShield_arg_1": "El id de la entidad cuyo escudo absoluto será devuelto.",
	"func_getAbsoluteShield_return": "El escudo absoluto de la entidad <b>entidad</b>.",
	"func_getAgility": "Devuelve la agilidad de la entidad con id <b>entidad</b>. Para recuperar directamente la agilidad de su entidad, use #getAgility() sin parámetros.",
	"func_getAgility_arg_1": "El id de la entidad cuya agilidad será devuelta.",
	"func_getAgility_return": "La agilidad de la <b>entidad</b> entidad.",
	"func_getAIID": "Devuelve el AI id de la entidad <b>entidad</b>. Use getAIID sin parámetros para recuperar la identificación de su IA.",
	"func_getAIID_arg_1": "La identificación de la entidad cuya identificación AI se devolverá.",
	"func_getAIID_return": "El ID de AI de la entidad <b>entidad</b>.",
	"func_getAIName": "Devuelve el nombre de la entidad AI <b>entidad</b>. Use getAINam() sin parámetros para recuperar el nombre de su IA.",
	"func_getAIName_arg_1": "El id de la entidad cuyo nombre AI se devolverá.",
	"func_getAIName_return": "El nombre de la <b>entidad</b> entidad AI.",
	"func_getAliveAllies": "Devuelve una matriz de todos tus aliados vivos en combate.",
	"func_getAliveAllies_return": "Una matriz que contiene las identificaciones de todos tus aliados vivos.",
	"func_getAliveEnemies": "Devuelve una matriz de todos tus enemigos vivos en combate.",
	"func_getAliveEnemiesCount": "Devuelve el número de enemigos vivos en la pelea.",
	"func_getAliveEnemiesCount_return": "El número de enemigos vivos.",
	"func_getAliveEnemies_return": "Una matriz que contiene las identificaciones de todos tus enemigos vivos.",
	"func_getAllChips": "Devuelve la lista de todas las fichas del juego.",
	"func_getAllChips_return": "La lista de todas las fichas en el juego.",
	"func_getAllEffects": "Devuelve la lista de todos los efectos del juego.",
	"func_getAllEffects_return": "La lista de todos los efectos en el juego.",
	"func_getAllWeapons": "Devuelve la lista de todas las armas del juego.",
	"func_getAllWeapons_return": "La lista de todas las armas en el juego.",
	"func_getAlliedTurret": "Devuelve el id de la torreta de tu equipo o -1 si no existe.",
	"func_getAlliedTurret_return": "El ID de la torreta de tu equipo.",
	"func_getEnemyTurret": "Devuelve el id de la torreta enemiga o -1 si no existe.",
	"func_getEnemyTurret_return": "La identificación de la torreta enemiga.",
	"func_getAllies": "Devuelve una matriz que contiene tus aliados y tu entidad.",
	"func_getAlliesCount": "Devuelve el número de aliados en la pelea.",
	"func_getAlliesCount_return": "El número de aliados.",
	"func_getAlliesLife": "Devuelve la salud total de tus aliados.",
	"func_getAlliesLife_return": "La salud total de tus aliados.",
	"func_getAllies_return": "La tabla de aliados y tu entidad.",
	"func_getBirthTurn": "Devuelve el turno de la pelea donde apareció la <b>entidad</b>. Devuelve 1 si es un puerro por ejemplo, y 5 si es una invocación invocada en el turno 5.",
	"func_getBirthTurn_arg_1": "La identificación de la entidad cuyo turno de generación será devuelto.",
	"func_getBirthTurn_return": "El turno de combate donde apareció la <b>entidad</b>.",
	"func_getBlue": "Devuelve la cantidad de azul en el color <b>color</b>, entre 0 y 255. Por ejemplo, getBlue(#COLOR_BLUE) = 255 y getBlue(#COLOR_GREEN) = 0.",
	"func_getBlue_arg_1": "El color cuya tarifa azul se devolverá.",
	"func_getBlue_return": "La tasa de azul en el color <b>color</b>",
	"func_getBulbChips": "Devuelve la lista de chips que se equiparán en una bombilla invocada por el chip <b>bulb_chip</b>.",
	"func_getBulbChips_arg_1": "El chip del bulbo.",
	"func_getBulbChips_return": "Los chips de una bombilla invocados por el chip <b>bulb_chip</b>.",
	"func_getCell": "Devuelve la celda donde se encuentra la entidad con id <b>entidad</b>. Use getCell() sin parámetros para recuperar su celda.",
	"func_getCell_arg_1": "El id de la entidad cuya celda será devuelta.",
	"func_getCell_return": "El número de celular donde se encuentra la <b>entidad</b>.",
	"func_getCellContent": "Devuelve el contenido de una celda con id <b>celda</b>.",
	"func_getCellContent_arg_1": "El id de la celda cuyo contenido se devolverá.",
	"func_getCellContent_return": "El contenido de la celda <b>cell</b>: #CELL_EMPTY para una celda vacía, #CELL_ENTITY para una entidad, #CELL_OBSTACLE para un obstáculo.",
	"func_getCellDistance": "Devuelve la distancia entre dos celdas <b>celda1</b> y <b>celda2</b>. <br />La distancia devuelta se expresa en número de celdas y no tiene en cuenta los diversos obstáculos entre las dos celdas.\nPara obtener la distancia a vuelo de pájaro, consulte #getDistance y para obtener la distancia del camino entre las dos celdas evitando obstáculos, consulte #getPathLength.",
	"func_getCellDistance_arg_1": "El id de la celda inicial.",
	"func_getCellDistance_arg_2": "El id de la celda de llegada.",
	"func_getCellDistance_return": "La distancia entre las dos celdas <b>celda1</b> y <b>celda2</b>.",
	"func_getCellFromXY": "Devuelve el id de la celda en la posición (<b>x</b>, <b>y</b>).",
	"func_getCellFromXY_arg_1": "La posición x de la celda.",
	"func_getCellFromXY_arg_2": "La posición y de la celda.",
	"func_getCellFromXY_return": "La identificación de la celda en la posición (<b>x</b>, <b>y</b>), <b>null</b> si la celda no existe.",
	"func_getCellsToUseChip": "Devuelve la lista de celdas desde las que su entidad podrá usar el chip <b>chip</b> en la entidad <b>entidad</b>.",
	"func_getCellsToUseChip_arg_1": "El chip que la entidad quiere poder utilizar.",
	"func_getCellsToUseChip_arg_2": "La entidad de destino.",
	"func_getCellsToUseChip_arg_3": "Matriz de celdas para ignorar.",
	"func_getCellsToUseChip_return": "Lista de celdas donde se puede utilizar el chip.",
	"func_getCellsToUseChipOnCell": "Devuelve la lista de celdas desde las que su entidad podrá usar el chip <b>chip</b> en la celda <b>cell</b>.",
	"func_getCellsToUseChipOnCell_arg_1": "El chip que la entidad quiere poder utilizar.",
	"func_getCellsToUseChipOnCell_arg_2": "La celda objetivo.",
	"func_getCellsToUseChipOnCell_arg_3": "Matriz de celdas para ignorar.",
	"func_getCellsToUseChipOnCell_return": "Lista de celdas donde se puede utilizar el chip.",
	"func_getCellsToUseWeapon": "Devuelve la lista de celdas desde las que su entidad podrá usar el arma <b>arma</b> en la entidad <b>entidad</b>.",
	"func_getCellsToUseWeapon_arg_1": "El arma a probar. Predeterminado a su arma equipada actualmente.",
	"func_getCellsToUseWeapon_arg_2": "La entidad de destino.",
	"func_getCellsToUseWeapon_arg_3": "Matriz de celdas para ignorar. Por defecto una matriz vacía.",
	"func_getCellsToUseWeapon_return": "Lista de celdas desde las que se puede usar el arma.",
	"func_getCellsToUseWeaponOnCell": "Devuelve la lista de celdas desde las que su entidad podrá usar el arma <b>arma</b> en una celda <b>celda</b>.",
	"func_getCellsToUseWeaponOnCell_arg_1": "El arma a probar. Predeterminado a su arma equipada actualmente.",
	"func_getCellsToUseWeaponOnCell_arg_2": "La celda objetivo.",
	"func_getCellsToUseWeaponOnCell_arg_3": "Matriz de celdas para ignorar. Por defecto una matriz vacía.",
	"func_getCellsToUseWeaponOnCell_return": "Lista de celdas desde las que se puede usar el arma.",
	"func_getCellX": "Determina la posición en X de la celda <b>celda</b>.",
	"func_getCellX_arg_1": "La celda cuya posición en X será determinada.",
	"func_getCellX_return": "La posición X de la celda.",
	"func_getCellY": "Determina la posición Y de la celda <b>celda</b>.",
	"func_getCellY_arg_1": "La celda cuya posición en Y se determinará.",
	"func_getCellY_return": "La posición Y de la celda.",
	"func_getChipArea": "Devuelve el tipo de área de efecto del <b>chip</b>.",
	"func_getChipArea_arg_1": "El chip cuyo tipo de zona se devolverá.",
	"func_getChipArea_return": "El tipo de área del chip <b>chip</b> entre las constantes AREA_*:\n<ul>\n<li>#AREA_POINT: área cuadrada única</li>\n<li>#AREA_LASER_LINE: línea de un láser</li>\n<li>#AREA_CIRCLE_1: área circular de 3 cuadrados de diámetro</li>\n<li>#AREA_CIRCLE_2: área circular de 5 cuadrados de diámetro</li>\n<li>#AREA_CIRCLE_3: área circular de 7 cuadrados de diámetro</li>\n</ul>",
	"func_getChipCooldown": "Devuelve el tiempo de recuperación del chip <b>chip</b>, tomado del mercado.",
	"func_getChipCooldown_arg_1": "El chip cuyo tiempo de reutilización será devuelto.",
	"func_getChipCooldown_return": "El tiempo de reutilización del <b>chip</b>.",
	"func_getChipCost": "Devuelve el coste en TP del chip <b>chip</b>.",
	"func_getChipCost_arg_1": "El chip cuyo coste será devuelto.",
	"func_getChipCost_return": "El coste del <b>chip</b>.",
	"func_getChipEffectiveArea": "Devuelve la lista de celdas que se verán afectadas si el chip <b>chip</b> se usa en la celda <b>cell</b> desde una celda <b>from</b>.",
	"func_getChipEffectiveArea_arg_1": "El chip a probar.",
	"func_getChipEffectiveArea_arg_2": "La celda objetivo.",
	"func_getChipEffectiveArea_arg_3": "La celda desde la que se utiliza el chip.",
	"func_getChipEffectiveArea_return": "La matriz que contiene los ID de todas las celdas que se verán afectadas.",
	"func_getChipEffects": "Devuelve los efectos del chip <b>chip</b>.",
	"func_getChipEffects_arg_1": "El chip cuyos efectos serán devueltos.",
	"func_getChipEffects_return": "Los efectos del <b>chip</b> chip. Mismo valor de retorno que para la función #getWeaponEffects.",
	"func_getChipFailure": "Devuelve el porcentaje de riesgo de fallo del chip <b>chip</b>.",
	"func_getChipFailure_arg_1": "El chip cuyo porcentaje de falla será devuelto.",
	"func_getChipFailure_return": "<b>chip</b> porcentaje de falla del chip, un número entero entre <b>0</b> y <b>100</b>.",
	"func_getChipMaxRange": "Devuelve el rango máximo del chip <b>chip</b>.",
	"func_getChipMaxRange_arg_1": "El chip cuyo rango máximo será devuelto.",
	"func_getChipMaxRange_return": "El alcance máximo del <b>chip</b>.",
	"func_getChipMaxScope": "Devuelve el rango máximo del chip <b>chip</b>.",
	"func_getChipMaxScope_arg_1": "El chip cuyo rango máximo será devuelto.",
	"func_getChipMaxScope_return": "El alcance máximo del <b>chip</b>.",
	"func_getChipMinRange": "Devuelve el rango mínimo del chip <b>chip</b>.",
	"func_getChipMinRange_arg_1": "El chip cuyo rango mínimo será devuelto.",
	"func_getChipMinRange_return": "El rango mínimo del <b>chip</b>.",
	"func_getChipMinScope": "Devuelve el rango mínimo del chip <b>chip</b>.",
	"func_getChipMinScope_arg_1": "El chip cuyo rango mínimo será devuelto.",
	"func_getChipMinScope_return": "El rango mínimo del <b>chip</b>.",
	"func_getChipLaunchType": "Devuelve el modo de lanzamiento del chip <b>chip</b>, entre las constantes LAUNCH_TYPE_*.",
	"func_getChipLaunchType_arg_1": "La identificación del chip cuyo modo de lanzamiento será devuelto.",
	"func_getChipLaunchType_return": "El modo de lanzamiento del chip <b>chip</b>.",
	"func_getChipName": "Devuelve el nombre del chip <b>chip</b>.",
	"func_getChipName_arg_1": "El chip cuyo nombre será devuelto.",
	"func_getChipName_return": "El nombre del chip <b>chip</b>.",
	"func_getChips": "Devuelve las viñetas de la entidad con id <b>entidad</b>.",
	"func_getChips_arg_1": "El id de la entidad cuyas viñetas se devolverán.",
	"func_getChips_return": "Una matriz que contiene los identificadores de viñetas de la entidad <b>entidad</b>.",
	"func_getChipTargets": "Devuelve las entidades que se verán afectadas si se usa el chip <b>chip</b> en la celda <b>celda</b>.",
	"func_getChipTargets_arg_1": "El chip a probar.",
	"func_getChipTargets_arg_2": "La celda objetivo.",
	"func_getChipTargets_return": "La matriz que contiene los ID de todas las entidades que se verán afectadas.",
	"func_getColor": "Devuelve el entero correspondiente al color (<b>rojo</b>, <b>verde</b>, <b>azul</b>) proporcionado como parámetro.",
	"func_getColor_arg_1": "Valor rojo entre 0 y 255.",
	"func_getColor_arg_2": "Valor verde entre 0 y 255.",
	"func_getColor_arg_3": "Valor azul entre 0 y 255.",
	"func_getColor_return": "int correspondiente al color proporcionado como parámetro.",
	"func_getCooldown": "Devuelve el tiempo de reutilización actual del <b>chip</b> de la entidad <b>entidad</b>.",
	"func_getCooldown_arg_1": "El chip cuyo tiempo de reutilización actual se devolverá.",
	"func_getCooldown_arg_2": "La entidad cuyo tiempo de reutilización se devolverá.",
	"func_getCooldown_return": "El tiempo de reutilización actual del chip <b>chip</b>, este es el número de turnos antes de que el chip sea utilizable, <b>0</b> si es utilizable actualmente.",
	"func_getCores": "Devuelve el número de núcleos de la entidad con id <b>entidad</b>.",
	"func_getCores_arg_1": "La entidad cuyo número de núcleos se devolverá.",
	"func_getCores_return": "El número de núcleos de la entidad <b>entidad</b>.",
	"func_getDate": "Devuelve la fecha de la pelea, en formato dd/MM/yyyy.",
	"func_getDate_return": "La fecha de la pelea.",
	"func_getTime": "Devuelve el tiempo de batalla, en formato HH:mm:ss.",
	"func_getTime_return": "El tiempo de la pelea.",
	"func_getTimestamp": "Devuelve la marca de tiempo de la pelea, igual a la cantidad de segundos desde el 1 de enero de 1970.",
	"func_getTimestamp_return": "La marca de tiempo de la pelea.",
	"func_getDamageReturn": "Devuelve la tasa de devolución de daños de la entidad con id <b>entidad</b>.",
	"func_getDamageReturn_arg_1": "El id de la entidad desde la que se devolverá la remisión del daño.",
	"func_getDamageReturn_return": "La tasa de devolución de daños de la entidad con id <b>entidad</b> (en %).",
	"func_getDeadAllies": "Devuelve entidades amigas muertas.",
	"func_getDeadAllies_return": "La tabla de entidades muertas aliadas.",
	"func_getDeadEnemies": "Devuelve entidades enemigas muertas.",
	"func_getDeadEnemiesCount": "Devuelve el número de enemigos que murieron en combate.",
	"func_getDeadEnemiesCount_return": "El número de enemigos muertos.",
	"func_getDeadEnemies_return": "La tabla de entidades enemigas muertas.",
	"func_getDistance": "Calcula la distancia a vuelo de pájaro entre dos celdas <b>celda1</b> y <b>celda2</b>. <br />Para obtener la distancia en número de celdas, vea #getCellDistance, y para obtener la longitud del camino entre las dos celdas esquivando varios obstáculos, vea #getPathLength.",
	"func_getDistance_arg_1": "La celda inicial.",
	"func_getDistance_arg_2": "La celda de llegada.",
	"func_getDistance_return": "La distancia en línea recta entre las dos celdas.",
	"func_getPassiveEffects": "Devuelve la lista de efectos pasivos de la entidad con id <b>entidad</b>. Para recuperar directamente la lista de efectos pasivos de su entidad, use #getPassiveEffects() sin parámetro.",
	"func_getPassiveEffects_arg_1": "El id de la entidad cuya lista de efectos pasivos se devolverá.",
	"func_getPassiveEffects_return": "La lista de efectos pasivos actualmente presentes en la entidad <b>entidad</b>.\nLa lista de efectos pasivos es una matriz que contiene los efectos.\nUn efecto es en sí mismo una matriz de 7 cuadros de la forma: [<b>tipo</b>, <b>valor</b>, <b>caster_id</b>, <b>giros</b>, <b>crítico</b>, <b>item_id</b>, <b>target_id</b>]. Es la misma estructura que los efectos clásicos devueltos por la función #getEffects.",
	"func_getEnemies": "Devuelve las entidades enemigas (vivas o muertas) al combate.",
	"func_getEnemiesCount": "Devuelve el número de enemigos en la pelea.",
	"func_getEnemiesCount_return": "El número de enemigos.",
	"func_getEnemiesLife": "Calcula la suma de los puntos de golpe de todas las entidades enemigas.",
	"func_getEnemiesLife_return": "La suma de los puntos de vida del equipo enemigo.",
	"func_getEnemies_return": "Una matriz que contiene los identificadores de todas las entidades enemigas.",
	"func_getEntityTurnOrder": "Devuelve un valor entre 1 y n (número de entidades actualmente en juego) que indica la posición de la <b>entidad</b> en el orden de reproducción.",
	"func_getEntityTurnOrder_arg_1": "El id de la entidad cuya orden de juego será devuelta",
	"func_getEntityTurnOrder_return": "Lugar en el orden de juego de la entidad <b>entidad</b>",
	"func_getFarmerID": "Devuelve el ID de criador de la entidad <b>entidad</b>.",
	"func_getFarmerID_arg_1": "La identificación de la entidad cuya identificación de criador se devolverá.",
	"func_getFarmerID_return": "El id de la <b>entidad</b> creadora de entidades.",
	"func_getFarmerCountry": "Devuelve el país del criador de la entidad <b>entidad</b>.",
	"func_getFarmerCountry_arg_1": "El id de la entidad cuyo país de criador será devuelto.",
	"func_getFarmerCountry_return": "El país del criador de la entidad <b>entidad</b>, o \"?\" si no está indicado.",
	"func_getFarmerName": "Devuelve el nombre del criador de la entidad <b>entidad</b>.",
	"func_getFarmerName_arg_1": "El id de la entidad cuyo nombre de criador se devolverá.",
	"func_getFarmerName_return": "El nombre del obtentor de la <b>entidad</b> entidad.",
	"func_getFarthestAlly": "Determina el aliado más lejano de tu entidad, a vuelo de pájaro.",
	"func_getFarthestAlly_return": "El id de la entidad amiga más lejana.",
	"func_getFarthestEnemy": "Determina el enemigo más alejado de tu entidad, a vuelo de pájaro.",
	"func_getFarthestEnemy_return": "La identificación de la entidad enemiga más lejana.",
	"func_getFightID": "Devuelve la identificación de la pelea actual.",
	"func_getFightID_return": "El id de la pelea actual.",
	"func_getFightContext": "Devuelve el contexto de la pelea actual.",
	"func_getFightContext_return": "Según el contexto de la batalla: Batalla de prueba (#FIGHT_CONTEXT_TEST), Batalla de arena (#FIGHT_CONTEXT_GARDEN), Batalla de torneo (#FIGHT_CONTEXT_TOURNAMENT), Batalla de desafío (#FIGHT_CONTEXT_CHALLENGE), Battle Royale (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "Devuelve el tipo de combate actual.",
	"func_getFightType_return": "Según el tipo de pelea: pelea en solitario (#FIGHT_TYPE_SOLO), pelea de granjero (#FIGHT_TYPE_FARMER), pelea en equipo (#FIGHT_TYPE_TEAM), Battle Royale (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "Devuelve la fuerza de la entidad con id <b>entidad</b>.",
	"func_getForce_arg_1": "La entidad cuya fuerza será devuelta.",
	"func_getForce_return": "La fuerza de la <b>entidad</b> entidad.",
	"func_getFrequency": "Devuelve la frecuencia de la entidad con id <b>entidad</b>. Use getFrequency() sin parámetros para recuperar su frecuencia.",
	"func_getFrequency_arg_1": "La entidad cuya frecuencia se devolverá.",
	"func_getFrequency_return": "La frecuencia de la entidad <b>entidad</b>.",
	"func_getGreen": "Devuelve la cantidad de verde en el color <b>color</b>, entre 0 y 255. Por ejemplo, getGreen(#COLOR_GREEN) = 255 y getGreen(#COLOR_RED) = 0.",
	"func_getGreen_arg_1": "El color cuya tasa verde se devolverá.",
	"func_getGreen_return": "La tasa de verde en el color <b>color</b>",
	"func_getInstructionsCount": "Devuelve el número de instrucciones que ha realizado su entidad durante el turno actual.",
	"func_getInstructionsCount_return": "El número de instrucciones que ha realizado su entidad durante el turno actual.",
	"func_getLaunchedEffects": "Devuelve la lista de efectos causados por la entidad con id <b>entidad</b>.",
	"func_getLaunchedEffects_arg_1": "El id de la entidad cuya lista de efectos causados se devolverá.",
	"func_getLaunchedEffects_return": "La lista de efectos causados por la entidad con id <b>entidad</b>, de la misma forma que la matriz devuelta por #getEffects.",
	"func_getLeek": "Devuelve el id de su entidad.",
	"func_getLeek_return": "El id de su entidad.",
	"func_getEntity": "Devuelve el id de su entidad.",
	"func_getEntity_return": "El id de su entidad.",
	"func_getLeekID": "Devuelve el id real del puerro de id <b>puerro</b>.",
	"func_getLeekID_arg_1": "La identificación del puerro cuya identificación real se devolverá.",
	"func_getLeekID_return": "El id real de la <b>entidad</b> puerro.",
	"func_getLeekOnCell": "Devuelve la entidad que está en la celda <b>celda</b>.",
	"func_getLeekOnCell_arg_1": "La celda cuya entidad queremos recuperar.",
	"func_getLeekOnCell_return": "El id de la entidad en la celda, o -1 si la celda no tiene entidad.",
	"func_getEntityOnCell": "Devuelve la entidad que está en la celda <b>celda</b>.",
	"func_getEntityOnCell_arg_1": "La celda cuya entidad queremos recuperar.",
	"func_getEntityOnCell_return": "El id de la entidad en la celda, o -1 si la celda no tiene entidad.",
	"func_getLevel": "Devuelve el nivel de entidad con id <b>entidad</b>.",
	"func_getLevel_arg_1": "El id de la entidad cuyo nivel se devolverá.",
	"func_getLevel_return": "El nivel de la entidad con id <b>entidad</b>.",
	"func_getLife": "Devuelve la vida actual de la entidad con id <b>entidad</b>. Usa getLife() sin parámetros para recuperar tu vida.",
	"func_getLife_arg_1": "El id de la entidad cuya vida será devuelta.",
	"func_getLife_return": "La vida actual de la entidad <b>entidad</b>.",
	"func_getMagic": "Devuelve la magia de la entidad con id <b>entidad</b>. Usa getMagic() sin parámetros para recuperar tu magia.",
	"func_getMagic_arg_1": "La identificación de la entidad cuya magia será devuelta.",
	"func_getMagic_return": "La magia de id entidad <b>entidad</b>.",
	"func_getMapType": "Devuelve el tipo de terreno en el que se desarrolla la lucha (fábrica, desierto, bosque, etc.), entre las constantes #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER y #MAP_BEACH.",
	"func_getMapType_return": "El tipo de terreno.",
	"func_getMessageAuthor": "Devuelve el id de la entidad autora del mensaje <b>mensaje</b>.",
	"func_getMessageAuthor_arg_1": "El mensaje cuyo autor será devuelto.",
	"func_getMessageAuthor_return": "La identificación de la entidad que crea el mensaje <b>mensaje</b>.",
	"func_getMessageParams": "Devuelve la matriz de parámetros de mensaje <b>mensaje</b>.",
	"func_getMessageParams_arg_1": "El mensaje cuyos parámetros se devolverán.",
	"func_getMessageParams_return": "Los parámetros del mensaje <b>message</b>.",
	"func_getMessages": "Devuelve la matriz de mensajes de entidad <b>entidad</b>.",
	"func_getMessages_arg_1": "La entidad cuyos mensajes se devolverán.",
	"func_getMessages_return": "La tabla de sus mensajes.<br>Un mensaje se representa a sí mismo en forma de\nmatriz de la forma: [<b>autor</b>, <b>tipo</b>, <b>parámetros</b>]<br>\nLos diferentes tipos de mensajes están representados por las constantes:\n<ul>\n<li>#MESSAGE_HEAL: solicitud de atención</li>\n<li>#MESSAGE_ATTACK: solicitud de ataque</li>\n<li>#MESSAGE_BUFF_FORCE: forzar solicitud de impulso</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "Devuelve el tipo de mensaje <b>mensaje</b>.",
	"func_getMessageType_arg_1": "El mensaje cuyo tipo se devolverá (entre #MESSAGE_HEAL, #MESSAGE_ATTACK, etc.).",
	"func_getMessageType_return": "El tipo de mensaje <b>mensaje</b>.",
	"func_getMP": "Devuelve el recuento de puntos de movimiento actual de la entidad <b>entidad</b>. Use getMP() sin parámetros para recuperar sus PM.",
	"func_getMP_arg_1": "El id de la entidad cuyo recuento de MP se devolverá.",
	"func_getMP_return": "El número de MP de la <b>entidad</b> de la entidad.",
	"func_getName": "Devuelve el nombre de la entidad con id <b>entidad</b>.",
	"func_getName_arg_1": "El id de la entidad cuyo nombre se devolverá.",
	"func_getName_return": "El nombre de la entidad <b>entidad</b>.",
	"func_getNearestAlly": "Devuelve la entidad amiga más cercana a tu entidad.",
	"func_getNearestAlly_return": "El id de la entidad amiga más cercana.",
	"func_getNearestAllyTo": "Devuelve la entidad aliada más cercana a la entidad proporcionada como parámetro.",
	"func_getNearestAllyTo_arg_1": "El id de la entidad cuyo aliado más cercano queremos conocer.",
	"func_getNearestAllyToCell": "Devuelve la entidad aliada más cercana a la celda proporcionada como parámetro.",
	"func_getNearestAllyToCell_arg_1": "El id de la celda cuyo aliado más cercano queremos saber.",
	"func_getNearestAllyToCell_return": "El id de la entidad amiga más cercana.",
	"func_getNearestAllyTo_return": "El id de la entidad amiga más cercana.",
	"func_getNearestEnemy": "Devuelve la entidad enemiga más cercana a tu entidad.",
	"func_getNearestEnemy_return": "La identificación de la entidad enemiga más cercana.",
	"func_getNearestEnemyTo": "Devuelve la entidad enemiga más cercana a la entidad proporcionada como parámetro.",
	"func_getNearestEnemyTo_arg_1": "El id de la entidad cuyo enemigo más cercano queremos conocer.",
	"func_getNearestEnemyToCell": "Devuelve la entidad enemiga más cercana a la celda proporcionada como parámetro.",
	"func_getNearestEnemyToCell_arg_1": "El id de la celda cuyo enemigo más cercano queremos conocer.",
	"func_getNearestEnemyToCell_return": "La identificación de la entidad enemiga más cercana.",
	"func_getNearestEnemyTo_return": "La identificación de la entidad enemiga más cercana.",
	"func_getNextPlayer": "Devuelve el id de la entidad que jugará después del jugador actual.",
	"func_getNextPlayer_return": "El siguiente jugador.",
	"func_getObstacles": "Devuelve la lista de espacios de obstáculos en el terreno.",
	"func_getObstacles_return": "La matriz que contiene los ID de las celdas de obstáculos.",
	"func_getOperations": "Devuelve el número de operaciones consumidas por tu entidad desde el inicio de su turno. Este número debe permanecer por debajo de #OPERATIONS_LIMIT para que la entidad no se bloquee.",
	"func_getOperations_return": "Número de operaciones consumidas por tu entidad desde el inicio de su turno.",
	"func_getPath": "Devuelve el camino evitando obstáculos entre dos celdas <b>cell1</b> y <b>cell2</b>, si existe, ignorando las celdas contenidas en el array <b>ignoredCells</b> . Si un jugador está en una celda ignorada, el camino puede pasar por encima de él.<br /><br />\nLa celda inicial <b>celda1</b> nunca forma parte de la ruta resultante. La celda <b>celda2</b> es parte de la ruta resultante si y solo si está vacía o es ignorada por <b>ignoredCells</b>.<br /><br />\nSi no existe una ruta entre las dos celdas, <b>getPath</b> devuelve <i>null</i>.",
	"func_getPath_arg_1": "La celda inicial.",
	"func_getPath_arg_2": "La celda de llegada.",
	"func_getPath_arg_3": "La matriz de celdas a ignorar.",
	"func_getPath_return": "La matriz que contiene las celdas que forman el camino entre las dos celdas.",
	"func_getPathLength": "Devuelve la longitud del camino entre dos celdas <b>cell1</b> y <b>cell2</b>, evitando obstáculos, ignorando las celdas contenidas en el array <b>ignoredCells</b>. Esta función es equivalente a <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>.\nSi un jugador está en una celda ignorada, el camino puede pasar por encima de él.<br /><br />\nLa celda inicial <b>cell1</b> nunca se cuenta en el resultado. La celda <b>cell2</b> se cuenta en el resultado si y solo si está vacía o es ignorada por <b>ignoredCells</b>.<br /><br />\nSi no existe una ruta entre las dos celdas, <b>getPathLength</b> devuelve <i>null</i>.",
	"func_getPathLength_arg_1": "La celda inicial.",
	"func_getPathLength_arg_2": "La celda de llegada.",
	"func_getPathLength_arg_3": "La matriz de celdas a ignorar.",
	"func_getPathLength_return": "La longitud de la ruta entre <b>celda1</b> y <b>celda2</b>.",
	"func_getPreviousPlayer": "Devuelve el id de la entidad que jugó antes que el jugador actual.",
	"func_getPreviousPlayer_return": "El jugador anterior.",
	"func_getRed": "Devuelve la cantidad de rojo en el color <b>color</b>, entre 0 y 255. Por ejemplo, getRed(#COLOR_RED) = 255 y getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "El color cuya tasa roja se devolverá.",
	"func_getRed_return": "La tasa de rojo en el color <b>color</b>",
	"func_getRegister": "Devuelve el valor almacenado en el registro de entidad asociado a la clave <b>key</b> o </i>null</i> si el registro no existe.",
	"func_getRegister_arg_1": "La clave de registro cuyo valor se devolverá.",
	"func_getRegister_return": "El valor almacenado en el registro <b>clave</b>.",
	"func_getRegisters": "Devuelve el conjunto de registros de entidad como una matriz asociativa [<i>clave de registro</i>:<i>valor de registro</i>]. Ejemplo: <código>debug(getRegisters());\n// Muestra por ejemplo:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "La matriz asociativa correspondiente a todos los registros de la entidad.",
	"func_getRelativeShield": "Devuelve el escudo relativo de la entidad con id <b>entidad</b>. Para recuperar directamente el escudo relativo de su entidad, use #getRelativeShield() sin parámetros.",
	"func_getRelativeShield_arg_1": "El id de la entidad cuyo escudo relativo será devuelto.",
	"func_getRelativeShield_return": "El escudo relativo de la <b>entidad</b>, un número entero entre <b>0</b> y <b>100</b>.",
	"func_getResistance": "Devuelve la resistencia de la entidad con id <b>entidad</b>. Use getResistance() sin parámetros para recuperar su resistencia.",
	"func_getResistance_arg_1": "El id de la entidad cuya resistencia será devuelta.",
	"func_getResistance_return": "La resistencia de la entidad con id <b>entidad</b>.",
	"func_getScience": "Devuelve la ciencia de la entidad con id <b>entidad</b>. Use getScience() sin parámetros para recuperar su ciencia.",
	"func_getScience_arg_1": "El id de la entidad cuya ciencia será devuelta.",
	"func_getScience_return": "La ciencia del id entidad <b>entidad</b>.",
	"func_getStrength": "Devuelve la fuerza de la entidad con id <b>entidad</b>. Use getStrength() sin parámetros para recuperar su fuerza.",
	"func_getStrength_arg_1": "La entidad cuya fuerza será devuelta.",
	"func_getStrength_return": "La fuerza de la <b>entidad</b> entidad.",
	"func_getPower": "Devuelve el poder de la entidad con id <b>entidad</b>.",
	"func_getPower_arg_1": "La entidad cuyo poder será devuelto.",
	"func_getPower_return": "El poder de la <b>entidad</b>.",
	"func_getSummoner": "Devuelve la entidad que invocó a la entidad <b>entidad</b>, si es una invocación.",
	"func_getSummoner_arg_1": "El id de la entidad cuyo invocador será despedido.",
	"func_getSummoner_return": "El id de la entidad invocada <b>entity</b> si es una invocación, <i>null</i> de lo contrario.",
	"func_getSummons": "Devuelve la lista de identificadores de invocaciones activas actualmente de la entidad con identificador <b>entidad</b>.",
	"func_getSummons_arg_1": "El id de la entidad cuya citación será devuelta.",
	"func_getSummons_return": "La lista de identificadores de invocación de la entidad con identificador <b>entidad</b>.",
	"func_getTeamID": "Devuelve la identificación del equipo de la entidad <b>entidad</b>.",
	"func_getTeamID_arg_1": "La identificación de la entidad cuya identificación de equipo se devolverá.",
	"func_getTeamID_return": "La identificación del equipo de la entidad <b>entidad</b>.",
	"func_getTeamName": "Devuelve el nombre del equipo de la entidad <b>entidad</b>.",
	"func_getTeamName_arg_1": "La identificación de la entidad cuyo nombre de equipo se devolverá.",
	"func_getTeamName_return": "El nombre del equipo de la entidad <b>entidad</b>.",
	"func_getTotalLife": "Devuelve la vida total de la entidad con id <b>entidad</b>. Use getTotalLife() sin parámetros para recuperar su vida total.",
	"func_getTotalLife_arg_1": "El id de la entidad cuya vida total será devuelta.",
	"func_getTotalLife_return": "La vida total de la entidad.",
	"func_getTotalMP": "Devuelve el número máximo de puntos de movimiento de la entidad <b>entidad</b>.",
	"func_getTotalMP_arg_1": "El id de la entidad cuyos puntos máximos de movimiento serán devueltos.",
	"func_getTotalMP_return": "El número máximo de puntos de movimiento para la <b>entidad</b>.",
	"func_getTotalTP": "Devuelve el número máximo de puntos de giro de la entidad <b>entidad</b>.",
	"func_getTotalTP_arg_1": "El id de la entidad cuyo número máximo de puntos de giro se devolverá.",
	"func_getTotalTP_return": "El número máximo de puntos de giro de la entidad <b>entidad</b>.",
	"func_getTP": "Devuelve el número de puntos de giro de la entidad <b>entidad</b>. Utilice getTP() sin parámetros para recuperar sus PT.",
	"func_getTP_arg_1": "El id de la entidad cuyos PT se devolverán.",
	"func_getTP_return": "El número PT de la entidad <b>entidad</b>.",
	"func_getTurn": "Devuelve el turno de combate actual. El número máximo de turnos es #MAX_TURNS.",
	"func_getTurn_return": "El turno actual de combate.",
	"func_getType": "Devuelve el tipo de entidad de entidad <b>entidad</b>.",
	"func_getType_arg_1": "El id de la entidad cuyo tipo se devolverá.",
	"func_getType_return": "El tipo de entidad de <b>entidad</b>:<ul><li>#ENTITY_LEEK si es un puerro.</li><li>#ENTITY_BULB si es un bulbo.</li><li >#ENTITY_TURRET si es una torreta.</li></ul>",
	"func_getWeapon": "Devuelve la <b>entidad</b> del arma actualmente equipada.",
	"func_getWeapon_arg_1": "La identificación de la entidad cuya arma actual será devuelta.",
	"func_getWeapon_return": "La identificación del arma equipada actualmente en la <b>entidad</b>, nula si la entidad no tiene un arma equipada o la entidad no existe.",
	"func_getWeaponArea": "Devuelve el tipo de área de efecto del arma <b>arma</b>.",
	"func_getWeaponArea_arg_1": "El arma cuyo tipo de área se devolverá.",
	"func_getWeaponArea_return": "El tipo de área del arma <b>arma</b> entre las constantes AREA_*:\n<ul>\n<li>#AREA_POINT: área cuadrada única</li>\n<li>#AREA_LASER_LINE: línea de un láser</li>\n<li>#AREA_CIRCLE_1: área circular de 3 celdas de diámetro</li>\n<li>#AREA_CIRCLE_2: área circular de 5 cuadrados de diámetro</li>\n<li>#AREA_CIRCLE_3: área circular de 7 cuadrados de diámetro</li>\n</ul>",
	"func_getWeaponCost": "Devuelve el coste en TP del arma <b>arma</b>.",
	"func_getWeaponCost_arg_1": "El id del arma cuyo costo será devuelto.",
	"func_getWeaponCost_return": "El coste en PT del arma <b>arma</b>.",
	"func_getWeaponEffectiveArea": "Devuelve la lista de celdas que se verán afectadas si el arma <b>arma</b> se usa en la celda <b>celda</b> de la celda <b>de</b>.",
	"func_getWeaponEffectiveArea_arg_1": "El arma a probar.",
	"func_getWeaponEffectiveArea_arg_2": "La celda objetivo.",
	"func_getWeaponEffectiveArea_arg_3": "La celda desde la que se dispara el arma.",
	"func_getWeaponEffectiveArea_return": "La matriz que contiene los ID de todas las celdas que se verán afectadas.",
	"func_getWeaponPassiveEffects": "Devuelve los efectos pasivos del arma <b>arma</b>.",
	"func_getWeaponPassiveEffects_arg_1": "El id del arma cuyos efectos pasivos serán devueltos.",
	"func_getWeaponPassiveEffects_return": "Una matriz que contiene los efectos del arma <b>arma</b>. Cada efecto es en sí mismo una matriz de la forma\n[tipo, min, max, turnos, objetivos, modificadores]. Estos efectos son los mismos que los devueltos por #getWeaponEffects.",
	"func_getWeaponFailure": "Devuelve el porcentaje de probabilidad de falla del arma del <b>arma</b>.",
	"func_getWeaponFailure_arg_1": "El id del arma cuyo porcentaje de fallo será devuelto.",
	"func_getWeaponFailure_return": "<b>arma</b> porcentaje de fallo de arma, un número entero entre <b>0</b> y <b>100</b>.",
	"func_getWeaponMaxRange": "Devuelve el alcance máximo del arma <b>arma</b>.",
	"func_getWeaponMaxRange_arg_1": "El id del arma cuyo rango máximo será devuelto.",
	"func_getWeaponMaxRange_return": "El alcance máximo del arma <b>arma</b>.",
	"func_getWeaponMaxScope": "Devuelve el alcance máximo del arma <b>arma</b>.",
	"func_getWeaponMaxScope_arg_1": "El id del arma cuyo rango máximo será devuelto.",
	"func_getWeaponMaxScope_return": "El alcance máximo del arma <b>arma</b>.",
	"func_getWeaponMinRange": "Devuelve el alcance mínimo del arma <b>arma</b>.",
	"func_getWeaponMinRange_arg_1": "El id del arma cuyo rango mínimo será devuelto.",
	"func_getWeaponMinRange_return": "El alcance mínimo del arma <b>arma</b>.",
	"func_getWeaponMinScope": "Devuelve el alcance mínimo del arma <b>arma</b>.",
	"func_getWeaponMinScope_arg_1": "El id del arma cuyo rango mínimo será devuelto.",
	"func_getWeaponMinScope_return": "El alcance mínimo del arma <b>arma</b>.",
	"func_getWeaponLaunchType": "Devuelve el modo de lanzamiento del arma <b>arma</b>, entre las constantes LAUNCH_TYPE_*.",
	"func_getWeaponLaunchType_arg_1": "El id del arma cuyo modo de lanzamiento será devuelto. Por defecto a tu arma actualmente equipada.",
	"func_getWeaponLaunchType_return": "El modo de lanzamiento del arma <b>arma</b>.",
	"func_getWeaponName": "Devuelve el nombre del arma <b>arma</b>.",
	"func_getWeaponName_arg_1": "El id del arma cuyo nombre será devuelto.",
	"func_getWeaponName_return": "El nombre del arma <b>arma</b>.",
	"func_getWeapons": "Devuelve las armas de la entidad con id <b>entidad</b>.",
	"func_getWeapons_arg_1": "La identificación de la entidad cuyas armas serán devueltas.",
	"func_getWeapons_return": "Una matriz que contiene los identificadores de armas de la <b>entidad</b>.",
	"func_getWeaponTargets": "Devuelve las entidades que se verán afectadas si el arma <b>arma</b> se usa en la celda <b>celda</b>.",
	"func_getWeaponTargets_arg_1": "El arma a probar.",
	"func_getWeaponTargets_arg_2": "La celda objetivo.",
	"func_getWeaponTargets_return": "La matriz que contiene los ID de todas las entidades que se verán afectadas.",
	"func_getWisdom": "Devuelve la sabiduría de la entidad con id <b>entidad</b>. Utilice getWisdom() sin parámetros para recuperar su sabiduría.",
	"func_getWisdom_arg_1": "El id de la entidad cuya sabiduría será devuelta.",
	"func_getWisdom_return": "Sabiduría de la entidad id <b>entidad</b>.",
	"func_hypot": "Devuelve la hipotenusa del triángulo rectángulo de lados x e y. Equivalente a <i>raíz cuadrada(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "El valor de x.",
	"func_hypot_arg_2": "Ellos valoran.",
	"func_hypot_return": "<i>raíz cuadrada(x**2 + y**2)</i>.",
	"func_inArray": "Determina si el elemento <b>element</b> está en la matriz <b>array</b>.",
	"func_inArray_arg_1": "La tabla de búsqueda.",
	"func_inArray_arg_2": "El elemento a buscar.",
	"func_inArray_return": "<i>verdadero</i> si el elemento está en la matriz, <i>falso</i> de lo contrario.",
	"func_include": "Incluya la IA denominada <b>ai</b> en la IA actual. <br><br><b>Advertencia</b>, la función de inclusión debe llamarse solo en el bloque principal y su parámetro debe ser una cadena escrita directamente\nen el código. Para obtener más información, consulte la sección del tutorial: <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a>.",
	"func_include_arg_1": "El nombre de la IA a incluir.",
	"func_indexOf": "Determina la posición de la primera aparición de la cadena <b>buscar</b> en la cadena <b>cadena</b>, comenzando desde la posición <b>inicio</b>.",
	"func_indexOf_arg_1": "La cadena donde se realizará la búsqueda.",
	"func_indexOf_arg_2": "La cadena a buscar.",
	"func_indexOf_arg_3": "La posición inicial de la búsqueda.",
	"func_indexOf_return": "La posición de la primera aparición de <b>buscar</b> en <b>cadena</b> desde <b>inicio</b>, -1 si no se encontró la cadena.",
	"func_insert": "Inserta un elemento <b>element</b> en la <b>matriz</b> en la posición <b>posición</b>.",
	"func_insert_arg_1": "La matriz donde se insertará el elemento.",
	"func_insert_arg_2": "El elemento a insertar.",
	"func_insert_arg_3": "La posición de la inserción.",
	"func_isAlive": "Determina si una <b>entidad</b> está viva. Equivale a <i>getLife(<b>entidad</b>) > 0</i>.",
	"func_isAlive_arg_1": "El id de la entidad a probar.",
	"func_isAlive_return": "<i>verdadero</i> si la <b>entidad</b> está viva, <i>falso</i> si está muerta.",
	"func_isAlly": "Determina si la <b>entidad</b> es tu aliada.",
	"func_isAlly_arg_1": "El id de la entidad a probar.",
	"func_isAlly_return": "<i>verdadero</i> si la <b>entidad</b> es tu aliado o tú mismo, <i>falso</i> si es un enemigo.",
	"func_isChip": "Determina si un valor es una constante que representa un chip.<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "El número a determinar.",
	"func_isChip_return": "<i>verdadero</i> si el valor es una constante de chip.",
	"func_isDead": "Determina si la <b>entidad</b> está muerta. Equivalente a <i>getLife(<b>entidad</b>) == 0</i>.",
	"func_isDead_arg_1": "El id de la entidad a probar.",
	"func_isDead_return": "<i>verdadero</i> si la <b>entidad</b> está muerta, <i>falso</i> si está viva.",
	"func_isEmpty": "Determina si la matriz <b>matriz</b> está vacía. Equivalente a <i>count(<b>matriz</b>) == 0</i>.",
	"func_isEmpty_arg_1": "La matriz a probar.",
	"func_isEmptyCell": "Determina si una celda está vacía.",
	"func_isEmptyCell_arg_1": "La celda a probar.",
	"func_isEmptyCell_return": "<i>verdadero</i> si la celda está vacía, <i>falso</i> en caso contrario.",
	"func_isEmpty_return": "<i>verdadero</i> si la matriz está vacía, <i>falso</i> de lo contrario.",
	"func_isEnemy": "Determina si <b>entity</b> es tu enemigo.",
	"func_isEnemy_arg_1": "El id de la entidad a probar.",
	"func_isEnemy_return": "<i>verdadero</i> si la <b>entidad</b> es un enemigo, <i>falso</i> si es un aliado o usted mismo.",
	"func_isInlineChip": "Determina si el <b>chip</b> solo se puede usar en línea.",
	"func_isInlineChip_arg_1": "El id del chip a probar.",
	"func_isInlineChip_return": "<i>verdadero</i> si el chip es solo en línea, <i>falso</i> de lo contrario.",
	"func_isInlineWeapon": "Determina si el arma <b>arma</b> solo se puede usar en línea.",
	"func_isInlineWeapon_arg_1": "El ID del arma a probar.",
	"func_isInlineWeapon_return": "<i>verdadero</i> si el arma solo se puede usar en línea, <i>falso</i> de lo contrario.",
	"func_isLeek": "Determina si el contenido de la celda <b>celda</b> es una entidad.",
	"func_isLeek_arg_1": "La celda a probar.",
	"func_isLeek_return": "<i>verdadero</i> si la celda contiene una entidad, <i>falso</i> de lo contrario.",
	"func_isEntity": "Determina si el contenido de la celda <b>celda</b> es una entidad.",
	"func_isEntity_arg_1": "La celda a probar.",
	"func_isEntity_return": "<i>verdadero</i> si la celda contiene una entidad, <i>falso</i> de lo contrario.",
	"func_isObstacle": "Determina si el contenido de la celda <b>celda</b> es un obstáculo.",
	"func_isObstacle_arg_1": "La celda a probar.",
	"func_isObstacle_return": "<i>verdadero</i> si la celda contiene un obstáculo, <i>falso</i> de lo contrario.",
	"func_isOnSameLine": "Determina si dos celdas <b>celda1</b> y <b>celda2</b> están en la misma línea.",
	"func_isOnSameLine_arg_1": "La primera celda.",
	"func_isOnSameLine_arg_2": "La segunda celda.",
	"func_isOnSameLine_return": "<i>verdadero</i> si las dos celdas están en la misma línea, <i>falso</i> de lo contrario.",
	"func_isStatic": "Devuelve si la <b>entidad</b> es estática o no. Una entidad estática no puede moverse ni ser movida.",
	"func_isStatic_arg_1": "El id de la entidad a probar.",
	"func_isStatic_return": "<i>verdadero</i> si <b>entidad</b> es estática, <i>falso</i> de lo contrario.",
	"func_isSummon": "Devuelve si la <b>entidad</b> es una invocación o no.",
	"func_isSummon_arg_1": "El id de la entidad a probar.",
	"func_isSummon_return": "<i>verdadero</i> si <b>entidad</b> es una invocación, <i>falso</i> de lo contrario.",
	"func_isWeapon": "Determina si un valor es una constante que representa un arma.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false.",
	"func_isWeapon_arg_1": "El número a determinar.",
	"func_isWeapon_return": "<i>verdadero</i> si el valor es una constante de arma.",
	"func_join": "Combina múltiples elementos en una cadena, separándolos con un delimitador <b>pegamento</b>.",
	"func_join_arg_1": "Una matriz de elementos para fusionar.",
	"func_join_arg_2": "El elemento delimitador.",
	"func_join_return": "La cadena resultante de la fusión.",
	"func_jsonDecode": "Decodifica la cadena <b>json</b> al objeto LeekScript (número, cadena, matriz...).",
	"func_jsonDecode_arg_1": "La cadena JSON para decodificar.",
	"func_jsonDecode_return": "El objeto LeekScript decodificado.",
	"func_jsonEncode": "Codifica el objeto <b>object</b> en una cadena JSON.",
	"func_jsonEncode_arg_1": "El objeto a codificar en JSON.",
	"func_jsonEncode_return": "La cadena codificada en JSON.",
	"func_keySort": "Ordena la <b>matriz</b> por claves en <b>orden</b>.",
	"func_keySort_arg_1": "La matriz a ordenar.",
	"func_keySort_arg_2": "El orden de clasificación: #SORT_ASC o #SORT_DESC.",
	"func_length": "Devuelve la longitud de <b>cadena</b>.",
	"func_length_arg_1": "La cadena cuya longitud se devolverá.",
	"func_length_return": "La longitud de la <b>cadena</b>.",
	"func_lineOfSight": "Comprueba la línea de visión entre la celda <b>inicio</b> y la celda <b>fin</b>, ignorando las entidades <b>ignoredEntities</b>.",
	"func_lineOfSight_arg_1": "Celda de inicio.",
	"func_lineOfSight_arg_2": "Célula diana.",
	"func_lineOfSight_arg_3": "Una entidad o lista de entidades a ignorar.",
	"func_lineOfSight_return": "Devuelve <i>verdadero</i> si la línea de visión está despejada.",
	"func_listen": "Devuelve la matriz de say() de entidades anteriores, en la forma [entity_id, message].",
	"func_listen_return": "La matriz de say() anteriores.",
	"func_log": "Calcula el logaritmo natural del número <b>número</b>.",
	"func_log_arg_1": "Un número en el rango ]0; +∞[.",
	"func_log_return": "El logaritmo natural de <b>número</b>.",
	"func_log10": "Calcula el logaritmo en base 10 del número <b>número</b>.",
	"func_log10_arg_1": "Un número en el rango ]0; +∞[.",
	"func_log10_return": "El logaritmo en base 10 de <b>número</b>.",
	"func_log2": "Calcula el logaritmo en base 2 del número <b>número</b>.",
	"func_log2_arg_1": "Un número en el rango ]0; +∞[.",
	"func_log2_return": "El logaritmo en base 2 de <b>número</b>.",
	"func_mark": "Marca una o más celdas del color indicado en el parámetro en el suelo para el número de vueltas indicadas en el parámetro. Esta marca solo es visible para el criador de la entidad.",
	"func_mark_arg_1": "La celda o matriz de múltiples celdas para marcar",
	"func_mark_arg_2": "Color de marcado",
	"func_mark_arg_3": "Duración del marcado",
	"func_mark_return": "Devuelve verdadero si todo salió bien",
	"func_markText": "Escribe un texto en una o más celdas del color indicado en el parámetro en el suelo por el número de vueltas indicadas en el parámetro. Estos textos solo son visibles para el criador de la entidad.",
	"func_markText_arg_1": "La celda o matriz de múltiples celdas para escribir",
	"func_markText_arg_2": "El texto a escribir (máximo 10 caracteres)",
	"func_markText_arg_3": "Color de texto",
	"func_markText_arg_4": "Duración del texto",
	"func_markText_return": "Devuelve verdadero si todo salió bien",
	"func_max": "Devuelve el mayor valor entre los dos números <b>a</b> y <b>b</b>.",
	"func_max_arg_1": "Un número.",
	"func_max_arg_2": "Un número.",
	"func_max_return": "El número mayor entre <b>a</b> y <b>b</b>.",
	"func_min": "Devuelve el valor más pequeño entre los dos números <b>a</b> y <b>b</b>.",
	"func_min_arg_1": "Un número.",
	"func_min_arg_2": "Un número.",
	"func_min_return": "El número más pequeño entre <b>a</b> y <b>b</b>.",
	"func_moveAwayFrom": "Mueve su entidad lejos de otra <b>entidad</b>, usando el máximo de puntos de movimiento de <b>mp</b>.",
	"func_moveAwayFrom_arg_1": "La entidad de la que su entidad debe alejarse.",
	"func_moveAwayFrom_arg_2": "El número máximo de MP a usar. Por defecto, sin límite.",
	"func_moveAwayFrom_return": "El número de puntos de movimiento utilizados.",
	"func_moveAwayFromCell": "Mueve su entidad a una <b>celda</b> de distancia, utilizando <b>mp</b> puntos de movimiento.",
	"func_moveAwayFromCell_arg_1": "La celda de la que tu entidad debe alejarse.",
	"func_moveAwayFromCell_arg_2": "El número máximo de MP a usar. Por defecto, sin límite.",
	"func_moveAwayFromCell_return": "El número de puntos de movimiento utilizados.",
	"func_moveAwayFromCells": "Mueve su entidad lejos de un conjunto de <b>celdas</b> celdas, utilizando el máximo de puntos de movimiento de <b>mp</b>.",
	"func_moveAwayFromCells_arg_1": "La matriz que contiene las celdas de las que su entidad debe alejarse.",
	"func_moveAwayFromCells_arg_2": "El número máximo de MP a usar. Por defecto, sin límite.",
	"func_moveAwayFromCells_return": "El número de puntos de movimiento utilizados.",
	"func_moveAwayFromLeeks": "Mueve su entidad lejos de un conjunto de <b>entidades</b>, usando el máximo de puntos de movimiento de <b>mp</b>.",
	"func_moveAwayFromLeeks_arg_1": "La matriz que contiene los ID de las entidades de las que debe alejarse su entidad.",
	"func_moveAwayFromLeeks_arg_2": "El número máximo de MP a usar. Por defecto, sin límite.",
	"func_moveAwayFromLeeks_return": "El número de puntos de movimiento utilizados.",
	"func_moveAwayFromEntities": "Mueve su entidad lejos de un conjunto de <b>entidades</b>, usando el máximo de puntos de movimiento de <b>mp</b>.",
	"func_moveAwayFromEntities_arg_1": "La matriz que contiene los identificadores de las entidades de las que debe alejarse su entidad.",
	"func_moveAwayFromEntities_arg_2": "El número máximo de MP a usar. Por defecto, sin límite.",
	"func_moveAwayFromEntities_return": "El número de puntos de movimiento utilizados.",
	"func_moveAwayFromLine": "Mueve su entidad lejos de una línea definida por dos celdas <b>cell1</b> y <b>cell2</b>, utilizando un máximo de <b>mp</b> puntos de movimiento.",
	"func_moveAwayFromLine_arg_1": "Celda 1",
	"func_moveAwayFromLine_arg_2": "Celda 2.",
	"func_moveAwayFromLine_arg_3": "El número máximo de MP a usar. Por defecto, sin límite.",
	"func_moveAwayFromLine_return": "El número de puntos de movimiento utilizados.",
	"func_moveToward": "Acerca su entidad a otra <b>entidad</b>, utilizando el máximo de puntos de movimiento de <b>mp</b>.",
	"func_moveToward_arg_1": "La entidad a la que debe dirigirse su entidad.",
	"func_moveToward_arg_2": "El número máximo de MP a usar.",
	"func_moveToward_return": "El número de puntos de movimiento utilizados.",
	"func_moveTowardCell": "Mueve su entidad una celda <b>celda</b> más cerca, usando el máximo de puntos de movimiento <b>mp</b>.",
	"func_moveTowardCell_arg_1": "La celda a la que debe acercarse su función.",
	"func_moveTowardCell_arg_2": "El número máximo de MP a usar. Por defecto, sin límite.",
	"func_moveTowardCell_return": "El número de puntos de movimiento utilizados.",
	"func_moveTowardCells": "Mueve su entidad más cerca de un conjunto de <b>celdas</b> celdas, utilizando el máximo de puntos de movimiento de <b>mp</b>.",
	"func_moveTowardCells_arg_1": "La matriz que contiene las celdas a las que debe acercarse su entidad.",
	"func_moveTowardCells_arg_2": "El número máximo de MP a usar. Por defecto, sin límite.",
	"func_moveTowardCells_return": "El número de puntos de movimiento utilizados.",
	"func_moveTowardLeeks": "Acerca su entidad a un conjunto de <b>entidades</b>, utilizando puntos de movimiento máximos de <b>mp</b>.",
	"func_moveTowardLeeks_arg_1": "La matriz que contiene los identificadores de las entidades a las que debe acercarse su entidad.",
	"func_moveTowardLeeks_arg_2": "El número máximo de MP a usar. Por defecto, sin límite.",
	"func_moveTowardLeeks_return": "El número de puntos de movimiento utilizados.",
	"func_moveTowardEntities": "Acerca su entidad a un conjunto de <b>entidades</b>, utilizando puntos de movimiento máximos de <b>mp</b>.",
	"func_moveTowardEntities_arg_1": "La matriz que contiene los identificadores de las entidades a las que debe acercarse su entidad.",
	"func_moveTowardEntities_arg_2": "El número máximo de MP a usar. Por defecto, sin límite.",
	"func_moveTowardEntities_return": "El número de puntos de movimiento utilizados.",
	"func_moveTowardLine": "Acerca su entidad a una línea definida por dos celdas <b>celda1</b> y <b>celda2</b>, utilizando puntos de movimiento máximos de <b>mp</b>.",
	"func_moveTowardLine_arg_1": "Celda 1.",
	"func_moveTowardLine_arg_2": "Celda 2.",
	"func_moveTowardLine_arg_3": "El número máximo de MP a usar. Por defecto, sin límite.",
	"func_moveTowardLine_return": "El número de puntos de movimiento utilizados.",
	"func_number": "Convierte un valor en un número. Si el valor es una cadena, la función de número intentará convertirlo en un número, si el valor ya es un número, la función devuelve el número, y para cualquier otro tipo, devuelve <i>null</i>.",
	"func_number_arg_1": "El valor a convertir en un número.",
	"func_number_return": "El número convertido.",
	"func_pause": "Pausa la pelea, solo para el criador de la entidad que usa la función.",
	"func_pop": "Elimina la última celda de la <b>matriz</b> y devuelve el elemento correspondiente.",
	"func_pop_arg_1": "La matriz cuyo último cuadro se eliminará.",
	"func_pop_return": "El artículo que estaba en la antigua última caja.",
	"func_pow": "Eleva el número base a la potencia del exponente.",
	"func_pow_arg_1": "La base.",
	"func_pow_arg_2": "El expositor",
	"func_pow_return": "<b>base</b> elevada a la potencia del <b>exponente</b>.",
	"func_push": "Agrega el elemento <b>element</b> al final de la <b>matriz</b>.",
	"func_pushAll": "Añade todos los elementos de la matriz <b>elements</b> al final de la matriz <b>elements</b>.",
	"func_pushAll_arg_1": "La matriz donde queremos agregar los elementos.",
	"func_pushAll_arg_2": "Elementos para agregar.",
	"func_push_arg_1": "La matriz donde queremos agregar el elemento.",
	"func_push_arg_2": "El elemento a agregar.",
	"func_rand": "Devuelve un número aleatorio real, entre 0 (inclusive) y 1 (exclusivo).",
	"func_randFloat": "Devuelve un número aleatorio real, entre <b>a</b> (inclusive) y <b>b</b> (exclusivo).",
	"func_randFloat_arg_1": "Límite inferior.",
	"func_randFloat_arg_2": "Límite superior.",
	"func_randFloat_return": "Un número aleatorio en el rango [a; b[.",
	"func_randInt": "Devuelve un número entero aleatorio entre <b>a</b> (inclusive) y <b>b</b> (exclusivo).",
	"func_randInt_arg_1": "Límite inferior.",
	"func_randInt_arg_2": "Límite superior.",
	"func_randInt_return": "Un entero aleatorio en el rango [a; b[.",
	"func_rand_return": "Un número aleatorio en el rango [0; 1[.",
	"func_remove": "Elimina un elemento de la matriz <b>matriz</b> en la posición <b>posición</b> y devuelve el elemento eliminado.",
	"func_remove_arg_1": "Array del que queremos eliminar un elemento.",
	"func_remove_arg_2": "Posición del elemento a eliminar.",
	"func_removeElement": "Elimina la primera aparición de un <b>elemento</b> en la <b>matriz</b>. Si no se encuentra el elemento, la matriz no se modifica.",
	"func_removeElement_arg_1": "Array del que queremos eliminar un elemento.",
	"func_removeElement_arg_2": "Elemento para buscar y luego eliminar.",
	"func_removeKey": "Elimina un elemento de la matriz <b>matriz</b> asociada con la clave <b>clave</b>.",
	"func_removeKey_arg_1": "Array del que queremos eliminar un elemento.",
	"func_removeKey_arg_2": "La clave asociada con el elemento a eliminar.",
	"func_remove_return": "El elemento eliminado de la matriz.",
	"func_replace": "Reemplaza todas las apariciones de <b>buscar</b> con <b>reemplazar</b> en la cadena <b>cadena</b>.",
	"func_replace_arg_1": "Cadena en la que se realizan los reemplazos.",
	"func_replace_arg_2": "Subcadena a reemplazar.",
	"func_replace_arg_3": "Cuerda de repuesto.",
	"func_replace_return": "La cadena de resultado, con reemplazos.",
	"func_resurrect": "Utiliza el chip CHIP_RESURRECTION para resucitar una entidad muerta con id <b>entidad</b>, en la celda <b>celda</b>.",
	"func_resurrect_arg_1": "El id de la entidad a revivir.",
	"func_resurrect_arg_2": "La celda en la que reaparecerá la entidad.",
	"func_resurrect_return": "El resultado de lanzar el chip, entre las constantes USE_*.",
	"func_reverse": "Invierte la <b>matriz</b>.",
	"func_reverse_arg_1": "Tabla para invertir.",
	"func_round": "Devuelve el redondeo de <b>número</b>.<br/>Puede encontrar el redondeo hacia abajo con #piso y el redondeo hacia arriba con #ceil.",
	"func_round_arg_1": "Número cuyo redondeo queremos encontrar.",
	"func_round_return": "Redondeado por <b>número</b>.",
	"func_say": "Haz que tu entidad hable.",
	"func_say_arg_1": "Mensaje que su entidad anunciará en la arena.",
	"func_search": "Encuentra el elemento <b>elemento</b> en la matriz <b>matriz</b>, comenzando desde la posición <b>inicio</b>.",
	"func_search_arg_1": "La tabla de búsqueda.",
	"func_search_arg_2": "El elemento a buscar.",
	"func_search_arg_3": "La posición del inicio de la búsqueda.",
	"func_search_return": "La posición de la primera aparición del elemento <b>element</b> en la <b>matriz</b>, comenzando desde <b>inicio</b>, nulo si no se ha encontrado el elemento.",
	"func_sendAll": "Envía un mensaje a todo tu equipo.",
	"func_sendAll_arg_1": "El tipo de mensaje a enviar (ver las constantes MESSAGE_*).",
	"func_sendAll_arg_2": "Los parámetros del mensaje, que pueden ser cualquier valor.",
	"func_sendTo": "Envía un mensaje a la entidad con id <b>entidad</b>.",
	"func_sendTo_arg_1": "El id de la entidad a la que se enviará el mensaje.",
	"func_sendTo_arg_2": "El tipo de mensaje a enviar (ver las constantes MESSAGE_*).",
	"func_sendTo_arg_3": "Los parámetros del mensaje, que pueden ser cualquier valor.",
	"func_sendTo_return": "<i>verdadero</i> si el envío fue exitoso, <i>falso</i> si ocurrió un error.",
	"func_setRegister": "Almacena el <b>valor</b> en el registro <b>clave</b>.\nLa clave y el valor son cadenas que deben contener respectivamente <i>100</i> y <i>5000</i> caracteres como máximo. Un puerro puede tener un máximo de <i>100</i> registros, almacenar en un nuevo registro no funcionará si todos los registros\nya están ocupados.",
	"func_setRegister_arg_1": "La clave de registro donde almacenar el valor.",
	"func_setRegister_arg_2": "El valor a almacenar.",
	"func_setRegister_return": "<i>verdadero</i> si la operación fue exitosa, <i>falso</i> de lo contrario.",
	"func_setWeapon": "Equipa el arma <b>arma</b> en tu entidad.",
	"func_setWeapon_arg_1": "Id del arma a equipar.",
	"func_shift": "Elimina la primera celda de la <b>matriz</b> y devuelve el elemento correspondiente.",
	"func_shift_arg_1": "La matriz cuya primera celda se eliminará.",
	"func_shift_return": "El elemento que estaba en la antigua primera celda.",
	"func_show": "Muestra a los jugadores una <b>celda</b> del color <b>color</b> en el campo durante 1 turno. El uso de esta función cuesta 1PT.",
	"func_show_arg_1": "La celda para mostrar",
	"func_show_arg_2": "Color de marcado",
	"func_show_return": "Devuelve verdadero si todo salió bien",
	"func_shuffle": "Baraja un tablero al azar.",
	"func_shuffle_arg_1": "El tablero de tejo.",
	"func_signum": "Determina el signo del número <b>número</b>.",
	"func_signum_arg_1": "El número cuyo signo se determinará.",
	"func_signum_return": "1 si el número es positivo, 0 si el número es cero y -1 si el número es negativo.",
	"func_sin": "Calcula el seno del ángulo <b>ángulo</b> pasado como parámetro",
	"func_sin_arg_1": "El ángulo cuyo seno se calculará",
	"func_sin_return": "Devuelve el seno del ángulo <b>ángulo</b>",
	"func_sort": "Ordena la <b>matriz</b> en orden ascendente o descendente. El orden ascendente predeterminado es:\n<ul>\n<li>Todos los valores booleanos (primero falso y luego verdadero)</li>\n<li>Todos los números (ordenados de menor a mayor)</li>\n<li>Todos los canales (en orden alfabético)</li>\n<li>Todos los elementos nulos.</li>\n</ul>",
	"func_sort_arg_1": "Matriz para ordenar.",
	"func_sort_arg_2": "#SORT_ASC para ordenar la <b>matriz</b> en orden ascendente o #SORT_DESC para ordenarla en orden descendente.",
	"func_split": "Divide la cadena <b>cadena</b> en subcadenas delimitadas por <b>delimitador</b>.",
	"func_split_arg_1": "Cadena de corte.",
	"func_split_arg_2": "Cuerda que delimita el paso de un elemento a otro.",
	"func_split_arg_3": "Número máximo de elementos de matriz.",
	"func_split_return": "Matriz que contiene las subcadenas encontradas.",
	"func_sqrt": "Calcula la raíz cuadrada del número <b>número</b>.",
	"func_sqrt_arg_1": "Número cuya raíz se calculará.",
	"func_sqrt_return": "Raíz cuadrada de <b>número</b>.",
	"func_startsWith": "Comprueba si <b>cadena</b> comienza con <b>prefijo</b>.",
	"func_startsWith_arg_1": "Cadena de búsqueda.",
	"func_startsWith_arg_2": "Prefijo buscado.",
	"func_startsWith_return": "Verdadero si la <b>cadena</b> comienza con <b>prefijo</b>.",
	"func_string": "Convierte un valor en una cadena.<br>\nSi el valor ya es una cadena, se devuelve.<br>\nSi el valor es el número x, se devuelve \"x\".<br>\nSi el valor es una matriz, se devuelve una cadena de la forma \"[clave1: valor1, clave2: valor2, ...]\".<br>\nSi el valor es un booleano, \"verdadero\" y \"falso\" se devuelven como verdadero y falso respectivamente.<br>\nSi el valor es nulo, se devuelve \"null\".",
	"func_string_arg_1": "El valor para convertir a una cadena de caracteres.",
	"func_string_return": "La cadena convertida.",
	"func_subArray": "Devuelve un subarreglo de <b>arreglo</b> que comienza en la posición <b>inicio</b> y termina en la posición <b>final</b>.",
	"func_subArray_arg_1": "Tabla de fuentes.",
	"func_subArray_arg_2": "Índice inicial.",
	"func_subArray_arg_3": "Índice final.",
	"func_subArray_return": "Submatriz.",
	"func_substring": "Devuelve la subcadena de la cadena <b>cadena</b> a partir del carácter en el índice <b>inicio</b> y de tamaño <b>longitud</b>.",
	"func_substring_arg_1": "Cadena de corte.",
	"func_substring_arg_2": "Índice de caracteres iniciales.",
	"func_substring_arg_3": "Longitud de la subcadena.",
	"func_substring_return": "Subcadena.",
	"func_sum": "Devuelve la suma de todos los elementos numéricos de la matriz <b>matriz</b>.",
	"func_sum_arg_1": "Tabla para sumar.",
	"func_summon": "Invoca una entidad determinada por el chip <b>chip</b> en la celda <b>celda</b> que tiene por IA la función <b>ai</b>.",
	"func_summon_arg_1": "El chip utilizado para invocar. El chip debe ser un chip de tipo invocación y debe estar equipado en la entidad que usa la función de invocación.",
	"func_summon_arg_2": "Debería aparecer la celda o la convocatoria.",
	"func_summon_arg_3": "La invocación AI, como una función.",
	"func_summon_return": "La función de convocatoria tiene el mismo retorno que la función #useChip.",
	"func_sum_return": "Suma de elementos de matriz.",
	"func_tan": "Calcula la tangente del ángulo <b>ángulo</b> (en radianes).",
	"func_tan_arg_1": "Ángulo en radianes.",
	"func_tan_return": "Tangente del <b>ángulo</b>.",
	"function_category_array": "Liza",
	"function_category_chip": "Chips",
	"function_category_color": "Color",
	"function_category_fight": "Pelear",
	"function_category_leek": "Puerros",
	"function_category_map": "Mesas",
	"function_category_field": "Tono",
	"function_category_math": "Números",
	"function_category_network": "Red",
	"function_category_string": "Cadenas",
	"function_category_utils": "Utilidades",
	"function_category_weapon": "Armas",
	"func_toDegrees": "Convierte ángulos <b>radianes</b> a grados.",
	"func_toDegrees_arg_1": "Valor en radianes",
	"func_toDegrees_return": "Ángulo en grados.",
	"func_toLower": "Convierte caracteres en mayúsculas en <b>cadena</b> a minúsculas.",
	"func_toLower_arg_1": "Cadena para transformar",
	"func_toLower_return": "Cadena en minúsculas",
	"func_toRadians": "Convierte ángulos <b>grados</b> a radianes.",
	"func_toRadians_arg_1": "Valor en grados",
	"func_toRadians_return": "Ángulo en radianes.",
	"func_toUpper": "Convierte caracteres en minúsculas en <b>cadena</b> a mayúsculas.",
	"func_toUpper_arg_1": "Cadena para transformar.",
	"func_toUpper_return": "Cadena en mayúsculas.",
	"func_typeOf": "Devuelve el tipo del valor <b>valor</b>, entre los tipos: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "El valor cuyo tipo se devolverá.",
	"func_typeOf_return": "El tipo de <b>valor</b>.",
	"func_unshift": "Agrega el elemento <b>element</b> al principio de la <b>matriz</b>.",
	"func_unshift_arg_1": "Matriz en la que se agregará el elemento.",
	"func_unshift_arg_2": "Elemento para agregar.",
	"func_useChip": "Utilice el chip <b>chip</b> en la entidad <b>entidad</b>.",
	"func_useChip_arg_1": "Chip para usar.",
	"func_useChip_arg_2": "Entidad objetivo.",
	"func_useChipOnCell": "Utilice el chip <b>chip</b> en la celda <b>celda</b>.",
	"func_useChipOnCell_arg_1": "Chip para usar.",
	"func_useChipOnCell_arg_2": "Célula diana.",
	"func_useChipOnCell_return": "Un valor mayor que 0 si se lanzó el ataque.",
	"func_useChip_return": "Los valores de retorno de useChip son:<br>\n<ul>\n<li>#USE_CRITICAL, en golpe crítico</li>\n<li>#USE_SUCCESS, si tiene éxito</li>\n<li>#USE_FAILED, en caso de falla</li>\n<li>#USE_INVALID_TARGET, si el objetivo no existe</li>\n<li>#USE_NOT_ENOUGH_TP, si su entidad no tiene suficiente TP</li>\n<li>#USE_INVALID_COOLDOWN, si el chip aún no se puede usar</li>\n<li>#USE_INVALID_POSITION, si el rango es malo o la línea de visión no está clara</li>\n</ul>",
	"func_useWeapon": "Usa el arma seleccionada en la <b>entidad</b>.",
	"func_useWeapon_arg_1": "Entidad objetivo.",
	"func_useWeaponOnCell": "Usa el arma seleccionada en la celda <b>celda</b>.",
	"func_useWeaponOnCell_arg_1": "Célula diana.",
	"func_useWeaponOnCell_return": "Un valor mayor que 0 si se lanzó el ataque.",
	"func_useWeapon_return": "Los valores de retorno de useWeapon son:<br>\n<ul>\n<li>#USE_CRITICAL, en golpe crítico</li>\n<li>#USE_SUCCESS, si tiene éxito</li>\n<li>#USE_FAILED, en caso de falla</li>\n<li>#USE_INVALID_TARGET, si el objetivo no existe</li>\n<li>#USE_NOT_ENOUGH_TP, si su entidad no tiene suficiente TP</li>\n<li>#USE_INVALID_POSITION, si el rango es malo o la línea de visión no es clara</li>\n</ul>",
	"func_weaponNeedLos": "Devuelve si el arma <b>arma</b> necesita línea de visión para disparar.",
	"func_weaponNeedLos_arg_1": "El ID del arma a probar.",
	"func_weaponNeedLos_return": "<i>verdadero</i> si el arma <b>arma</b> necesita línea de visión para disparar, <i>falso</i> de lo contrario.",
	"items": "Elementos",
	"max_cores": "máximo",
	"max_level": "máximo",
	"min_cores": "Corazones mínimos",
	"min_level": "Nivel mínimo",
	"n_operations": "<b>{0}</b> operaciones",
	"parameters": "Configuraciones",
	"return": "Retorno",
	"value": "Valor",
	"variable_operations": "Operaciones con <b>variables</b>",
	"deprecated_function": "Esta función está obsoleta.",
	"deprecated_constant": "Esta constante está en desuso.",
	"replaced_by": "Se reemplaza por {0}.",
	"details": "Detalles",
	"optional": "opcional",
	"arg_type_9": "paquete",
	"arg_type_10": "intervalo",
	"function_category_set": "Paquetes",
	"function_category_interval": "Intervalos",
	"arg_type_96": "set<entier>"
}

	i18n.mergeLocaleMessage("es", { "doc": messages })
	