
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> operazione",
	"arg_type_-1": "?",
	"arg_type_1": "numero",
	"arg_type_2": "catena",
	"arg_type_3": "booleano",
	"arg_type_4": "elenco",
	"arg_type_41": "lista<numero>",
	"arg_type_42": "lista<stringa>",
	"arg_type_43": "lista<booleano>",
	"arg_type_44": "lista<lista>",
	"arg_type_46": "lista<intero>",
	"arg_type_47": "lista<reale>",
	"arg_type_5": "funzione",
	"arg_type_6": "intero",
	"arg_type_7": "vero",
	"arg_type_8": "tavolo",
	"arg_type_806": "tabella<?, intero>",
	"complexity": "Complessità {c}",
	"operations": "{o} operazioni",
	"const_AREA_CIRCLE_1": "Area circolare 3 quadrati di diametro (croce).",
	"const_AREA_CIRCLE_2": "Area circolare 5 quadrati di diametro.",
	"const_AREA_CIRCLE_3": "Area circolare 7 quadrati di diametro.",
	"const_AREA_LASER_LINE": "Zona di un laser, linea dalla portata minima del laser alla sua portata massima o un ostacolo.",
	"const_AREA_POINT": "Area costituita da un'unica cella.",
	"const_CELL_EMPTY": "Valore restituito di getCellContent(cell) per una cella vuota.",
	"const_CELL_OBSTACLE": "Valore restituito di getCellContent(cell) per una cella contenente un ostacolo.",
	"const_CELL_PLAYER": "Valore restituito di getCellContent(cell) per una cella contenente un'entità.",
	"const_CELL_ENTITY": "Valore restituito di getCellContent(cell) per una cella contenente un'entità.",
	"const_COLOR_BLUE": "Colore blu.",
	"const_COLOR_GREEN": "Colore verde.",
	"const_COLOR_RED": "Colore rosso.",
	"const_E": "Numero di Eulero.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Fornisce uno scudo assoluto a un'entità, riducendo la quantità di punti ferita rimossi dal danno (EFFECT_DAMAGE) di un importo fisso. Amplificato dalla resistenza.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Rimuove lo scudo assoluto da un'entità. Non è amplificato da una caratteristica. Ti permette di aumentare i punti ferita rimossi dal danno (EFFECT_DAMAGE) di un importo assoluto.",
	"const_EFFECT_AFTEREFFECT": "Rimuove i punti ferita da un'entità. Amplificato dalla scienza. Riduce i punti ferita massimi del 5% della quantità di punti ferita rimossi.",
	"const_EFFECT_ANTIDOTE": "Rimuove tutto il veleno (EFFECT_POISON) presente su un bersaglio.",
	"const_EFFECT_BOOST_MAX_LIFE": "Aumenta i punti ferita e i punti ferita massimi di un'entità. Amplificato dalla saggezza.",
	"const_EFFECT_BUFF_AGILITY": "Concede agilità a un'entità. Amplificato dalla scienza.",
	"const_EFFECT_BUFF_MP": "Concede punti movimento a un'entità. Amplificato dalla scienza.",
	"const_EFFECT_BUFF_RESISTANCE": "Conferisce resistenza a un'entità. Amplificato dalla scienza.",
	"const_EFFECT_BUFF_STRENGTH": "Conferisce forza a un'entità. Amplificato dalla scienza.",
	"const_EFFECT_BUFF_TP": "Concede punti azione a un'entità. Amplificato dalla scienza.",
	"const_EFFECT_BUFF_WISDOM": "Concedi saggezza a un'entità. Amplificato dalla scienza.",
	"const_EFFECT_DAMAGE": "Rimuove i punti ferita da un'entità. Forza amplificata. Interagisce con gli scudi (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), rubavita (eccetto caster) e danno restituito (EFFECT_DAMAGE_RETURN). Riduce i punti ferita massimi del 5% della quantità di punti ferita rimossi.",
	"const_EFFECT_DAMAGE_RETURN": "Fornisce la riflessione del danno a un'entità, consentendo di rimuovere i punti ferita dalle entità che infliggono danni al destinatario. Amplificato dall'agilità. Riduce i punti ferita massimi del 5% della quantità di punti ferita rimossi.",
	"const_EFFECT_DEBUFF": "Riduce di una percentuale il valore di tutti gli effetti su un'entità.",
	"const_EFFECT_HEAL": "Ripristina punti ferita a un'entità, limitati dai punti ferita massimi. Amplificato dalla saggezza.",
	"const_EFFECT_INVERT": "Scambia la posizione dell'incantatore con quella di un'entità.",
	"const_EFFECT_KILL": "Rimuove tutti i punti ferita da un'entità.",
	"const_EFFECT_LIFE_DAMAGE": "Rimuove punti ferita da un'entità, in base a una percentuale dei punti ferita dell'incantatore. Interagisce con gli scudi (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) e la restituzione del danno (EFFECT_DAMAGE_RETURN). Riduce i punti ferita massimi del 5% della quantità di punti ferita rimossi.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "L'effetto viene moltiplicato per il numero di entità interessate nell'area.",
	"const_EFFECT_MODIFIER_ON_CASTER": "L'effetto ha ancora effetto sull'incantatore.",
	"const_EFFECT_MODIFIER_STACKABLE": "L'effetto è cumulativo.",
	"const_EFFECT_NOVA_DAMAGE": "Rimuove i punti vita massimi. Amplificato dalla scienza.",
	"const_EFFECT_POISON": "Rimuove i punti ferita da un'entità. Amplificato dalla magia. Riduce i punti ferita massimi del 10% della quantità di punti ferita rimossi.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Fornisce uno scudo assoluto a un'entità, riducendo la quantità di punti ferita rimossi dal danno (EFFECT_DAMAGE) di un importo fisso. Non amplificabile.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Concede agilità a un'entità. Non amplificabile.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Conferisce magia a un'entità. Non amplificabile.",
	"const_EFFECT_RAW_BUFF_MP": "Concede punti movimento a un'entità. Non amplificabile.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Concede la scienza a un'entità. Non amplificabile.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Conferisce forza a un'entità. Non amplificabile.",
	"const_EFFECT_RAW_BUFF_TP": "Concede punti azione a un'entità. Non amplificabile.",
	"const_EFFECT_RELATIVE_SHIELD": "Fornisce uno scudo relativo, riducendo la quantità di punti ferita rimossi dal danno (EFFECT_DAMAGE) di una quantità relativa. Amplificato dalla resistenza.",
	"const_EFFECT_RESURRECT": "Resuscita un'entità, con un numero massimo di HP pari alla metà del numero massimo di HP dell'entità prima della resurrezione e un numero attuale di HP pari a un quarto del numero massimo di HP prima della resurrezione.",
	"const_EFFECT_SHACKLE_MAGIC": "Rimuove la magia da un'entità. Amplificato dalla magia.",
	"const_EFFECT_SHACKLE_MP": "Rimuovi i punti di movimento da un'entità. Amplificato dalla magia.",
	"const_EFFECT_SHACKLE_STRENGTH": "Rimuove la forza da un'entità. Amplificato dalla magia.",
	"const_EFFECT_SHACKLE_TP": "Sottrarre punti azione da un'entità. Amplificato dalla magia.",
	"const_EFFECT_SUMMON": "Evoca un bulbo. Nessun effetto se viene raggiunto il limite di convocazione di squadra.",
	"const_EFFECT_TARGET_ALLIES": "Colpisce gli alleati.",
	"const_EFFECT_TARGET_CASTER": "Colpisce l'incantatore.",
	"const_EFFECT_TARGET_ENEMIES": "Colpisce i nemici.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Colpisce le entità non evocate (porri e torrette).",
	"const_EFFECT_TARGET_SUMMONS": "Colpisce le entità evocate (Bulbs).",
	"const_EFFECT_TELEPORT": "Cambia la posizione dell'incantatore.",
	"const_EFFECT_VULNERABILITY": "Rimuove lo scudo relativo a un'entità. Non è amplificato da una caratteristica. Aumenta i punti ferita rimossi dal danno (EFFECT_DAMAGE) di un importo relativo.",
	"const_ENTITY_BULB": "Fa riferimento a un'entità di tipo Bulb.",
	"const_ENTITY_LEEK": "Si riferisce a un'entità di tipo Leek.",
	"const_ENTITY_TURRET": "Si riferisce a un'entità di tipo torretta.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Sfondo di combattimento di tipo sfida.",
	"const_FIGHT_CONTEXT_GARDEN": "Contesto di combattimento nell'orto.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Contesto del combattimento in Battle Royale.",
	"const_FIGHT_CONTEXT_TEST": "Prova il contesto della battaglia.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Sfondo di combattimento del torneo.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Combatti in Battle Royale.",
	"const_FIGHT_TYPE_FARMER": "Combattimento tra allevatori.",
	"const_FIGHT_TYPE_SOLO": "Combattimento solitaria.",
	"const_FIGHT_TYPE_TEAM": "Combattimento a squadre.\n",
	"const_INSTRUCTIONS_LIMIT": "Numero massimo di istruzioni che un'entità può utilizzare durante il suo turno.",
	"const_MAX_TURNS": "Numero massimo di turni in un combattimento.",
	"const_OPERATIONS_LIMIT": "Numero massimo di operazioni che un'entità può utilizzare durante il suo turno.",
	"const_PI": "Il rapporto tra la circonferenza di un cerchio e il suo diametro.",
	"const_SORT_ASC": "Indica alla funzione #sort di ordinare in ordine crescente.",
	"const_SORT_DESC": "Indica alla funzione #sort di ordinare in ordine decrescente.",
	"const_TYPE_ARRAY": "Tipo di valore <b>elenco</b>",
	"const_TYPE_BOOLEAN": "Tipo di valore <b>booleano</b>",
	"const_TYPE_FUNCTION": "Tipo di valore <b>funzione</b>",
	"const_TYPE_NULL": "Tipo di valore <b>null</b>",
	"const_TYPE_NUMBER": "Tipo di valore <b>numero</b>",
	"const_TYPE_STRING": "Tipo di valore <b>stringa di caratteri</b>",
	"const_USE_CRITICAL": "Valore restituito dalle funzioni #useWeapon, #useWeaponOnCell, #useChip e #useChipOnCell in caso di colpo critico.",
	"const_USE_FAILED": "Valore restituito dalle funzioni #useWeapon, #useWeaponOnCell, #useChip e #useChipOnCell in caso di errore.",
	"const_USE_INVALID_COOLDOWN": "Valore restituito dalle funzioni #useChip e #useChipOnCell se il chip non è ancora utilizzabile.",
	"const_USE_INVALID_POSITION": "Valore restituito dalle funzioni #useWeapon, #useWeaponOnCell, #useChip e #useChipOnCell se la portata è scarsa o la linea di vista non è libera.",
	"const_USE_INVALID_TARGET": "Valore restituito dalle funzioni #useWeapon e #useChip se il target non esiste.",
	"const_USE_NOT_ENOUGH_TP": "Valore restituito dalle funzioni #useWeapon, #useWeaponOnCell, #useChip e #useChipOnCell se l'incantatore non dispone di punti azione sufficienti per utilizzare l'oggetto.",
	"const_USE_RESURRECT_INVALID_ENTITY": "Valore restituito dalla funzione #resurrect quando l'entità specificata non esiste o non è ancora morta.",
	"const_USE_SUCCESS": "Valore restituito dalle funzioni #useWeapon, #useWeaponOnCell, #useChip e #useChipOnCell in caso di successo.",
	"const_USE_TOO_MANY_SUMMONS": "Errore restituito da #summon quando hai già <b>8</b> convocazioni dal vivo.\n",
	"func_abs": "Restituisce il valore assoluto del numero <b>number</b>.",
	"func_abs_arg_1": "Il numero di cui verrà calcolato il valore assoluto.",
	"func_abs_return": "Il valore assoluto del numero.",
	"func_acos": "Calcola l'arcoseno di <b>argomento</b>, nell'intervallo [0, #PI].",
	"func_acos_arg_1": "Numero di cui verrà calcolato l'arcoseno.",
	"func_acos_return": "L'arcocoseno dell'<b>argomento</b>.",
	"func_arrayConcat": "Aggiungi i due array uno dopo l'altro. Le chiavi di testo vengono mantenute e tutte le chiavi numeriche vengono reindicizzate.",
	"func_arrayConcat_arg_1": "Primo tavolo.",
	"func_arrayConcat_arg_2": "Secondo tavolo.",
	"func_arrayConcat_return": "Somma dei due array.",
	"func_arrayFilter": "Restituisce un nuovo array contenente tutte le coppie chiave/valore dell'array di origine per il quale la funzione di callback ha restituito true. Se la funzione di callback accetta un parametro, verrà inviato il valore dell'array di origine, se accetta due parametri, verranno inviati la chiave e il valore.",
	"func_arrayFilter_arg_1": "Tavola originale.",
	"func_arrayFilter_arg_2": "Funzione chiamata per ogni elemento.",
	"func_arrayFilter_return": "Nuovo tavolo.",
	"func_arrayFlatten": "Restituisce un nuovo array contenente tutti gli elementi dell'array di origine. Tutti gli elementi contenuti in un sottoarray vengono estratti nel nuovo array.L'argomento depth determina la profondità massima del sottoarray da estrarre.\n<codice>var array = [1, 2, [[3, 4], 5], [6]];\ndebug(arrayFlatten(array, 1)); // [1,2,[3,4],5,6]</codice>",
	"func_arrayFlatten_arg_1": "Tavola originale.",
	"func_arrayFlatten_arg_2": "Profondità massima.",
	"func_arrayFlatten_return": "Nuovo tavolo.",
	"func_arrayFoldLeft": "Ridurre l'array <b>array</b> [v1, v2, ..., vn] da sinistra partendo dal valore <b>v0</b> e applicando la funzione <b>f</b >. Equivale :\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "Tavola originale.",
	"func_arrayFoldLeft_arg_2": "Funzione da applicare.",
	"func_arrayFoldLeft_arg_3": "Valore iniziale.",
	"func_arrayFoldLeft_return": "Tavolo ridotto.",
	"func_arrayFoldRight": "Ridurre l'array <b>array</b> [v1, v2, ..., vn] da destra partendo dal valore <b>v0</b> e applicando la funzione <b>f</b >. Equivale :\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "Tavola originale.",
	"func_arrayFoldRight_arg_2": "Funzione da applicare.",
	"func_arrayFoldRight_arg_3": "Valore iniziale.",
	"func_arrayFoldRight_return": "Tavolo ridotto.",
	"func_arrayIter": "Chiama la funzione di callback per ogni elemento dell'array. Se la funzione di callback accetta un parametro, verrà inviato il valore dell'array di origine, se accetta due parametri, verranno inviati la chiave e il valore.",
	"func_arrayIter_arg_1": "Tavola originale.",
	"func_arrayIter_arg_2": "Funzione chiamata per ogni elemento.",
	"func_arrayMap": "Restituisce un nuovo array contenente per ogni chiave dell'array di origine, il valore restituito dalla funzione di callback. Se la funzione di callback accetta un parametro, verrà inviato il valore dell'array di origine, se accetta due parametri, verranno inviati la chiave e il valore.",
	"func_arrayMap_arg_1": "Tavola originale.",
	"func_arrayMap_arg_2": "Funzione chiamata per ogni elemento.",
	"func_arrayMap_return": "Nuovo tavolo.",
	"func_arrayMax": "Restituisce l'elemento di valore massimo dell'array <b>array</b>.<br/>Per maggiori informazioni sul valore massimo di un array vedi #sort.",
	"func_arrayMax_arg_1": "Array in cui verrà ricercato il valore massimo.",
	"func_arrayMax_return": "Articolo di valore massimo.",
	"func_arrayMin": "Restituisce l'elemento di valore minimo dell'array <b>array</b>.<br/>Per maggiori informazioni sul valore minimo di un array vedi #sort.",
	"func_arrayMin_arg_1": "Array in cui verrà ricercato il valore minimo.",
	"func_arrayMin_return": "Articolo di valore minimo.",
	"func_arrayPartition": "Restituisce un nuovo array contenente due liste, nella prima ci sono tutte le coppie chiave/valore per le quali la funzione di callback ha restituito true, nella seconda ci sono tutte le altre. Se la funzione di callback accetta un parametro, verrà inviato il valore dell'array di origine, se accetta due parametri, verranno inviati la chiave e il valore.",
	"func_arrayPartition_arg_1": "Tavola originale.",
	"func_arrayPartition_arg_2": "Funzione chiamata per ogni elemento.",
	"func_arrayPartition_return": "Nuovo tavolo.",
	"func_arraySort": "Ordina la matrice in base all'ordine definito dalla funzione di callback. Gli elementi vengono confrontati a due a due, la funzione di callback deve restituire i valori -1, 0 o 1 a seconda che il primo valore sia prima, allo stesso livello o dopo il secondo valore. Se la funzione di callback prende 2 parametri vengono inviati i due valori, se ne prende 4 vengono inviate le coppie chiave/valore.",
	"func_arraySort_arg_1": "Tavola originale",
	"func_arraySort_arg_2": "Funzione di ordinamento.",
	"func_arraySort_return": "L'array ordinato",
	"func_asin": "Calcola l'arcoseno di <b>argomento</b>, nell'intervallo [0, #PI].",
	"func_asin_arg_1": "Numero di cui verrà calcolato l'arcoseno.",
	"func_asin_return": "L'arcoseno dell'<b>argomento</b>.",
	"func_assocSort": "Ordina l'array <b>array</b> preservando l'associazione chiave:valore secondo l'ordine <b>order</b>.",
	"func_assocSort_arg_1": "Matrice da ordinare.",
	"func_assocSort_arg_2": "L'ordinamento: #SORT_ASC o #SORT_DESC.",
	"func_atan": "Calcola l'arcotangente di <b>argomento</b>, nell'intervallo [0, #PI].",
	"func_atan2": "Converte le coordinate cartesiane (<b>x</b>, <b>y</b>) in coordinate polari (<b>r</b>, <b>theta</b>). Questa funzione restituisce l'angolo <b>theta</b> tra -#PI e #PI utilizzando i segni degli argomenti.",
	"func_atan2_arg_1": "Coordinata y.",
	"func_atan2_arg_2": "Coordinata x.",
	"func_atan2_return": "L'angolo <b>theta</b> in coordinate polari del punto (x, y).",
	"func_atan_arg_1": "Numero di cui verrà calcolato l'arcotangente.",
	"func_atan_return": "L'arcotangente dell'<b>argomento</b>.",
	"func_average": "Calcola la media degli elementi contenuti nell'array <b>array</b>.",
	"func_average_arg_1": "Array di cui vogliamo calcolare la media.",
	"func_average_return": "Valore medio.",
	"func_canUseChip": "Determina se la tua entità può utilizzare il <b>chip</b> chip sull'entità con id <b>entity</b>.",
	"func_canUseChip_arg_1": "Il numero del chip da testare.",
	"func_canUseChip_arg_2": "L'ID entità su cui vuoi utilizzare il chip.",
	"func_canUseChip_return": "<i>true</i> se la tua entità può utilizzare il chip, <i>false</i> in caso contrario.",
	"func_canUseChipOnCell": "Determina se la tua entità può utilizzare il chip <b>chip</b> sulla cella <b>cell</b>.",
	"func_canUseChipOnCell_arg_1": "Il numero del chip da testare.",
	"func_canUseChipOnCell_arg_2": "Il numero della cella su cui vuoi utilizzare il chip.",
	"func_canUseChipOnCell_return": "<i>true</i> se la tua entità può utilizzare il chip, <i>false</i> in caso contrario.",
	"func_canUseWeapon": "Determina se la tua entità può sparare all'entità con id <b>entità</b> con l'arma <b>arma</b>.",
	"func_canUseWeapon_arg_1": "L'arma da testare. L'impostazione predefinita è l'arma attualmente equipaggiata.",
	"func_canUseWeapon_arg_2": "L'id dell'entità su cui vuoi attivare.",
	"func_canUseWeapon_return": "<i>true</i> se la tua entità può attivarsi, <i>false</i> in caso contrario.",
	"func_canUseWeaponOnCell": "Determina se la tua entità può sparare alla cellula <b>cell</b> con l'arma <b>arma</b>.",
	"func_canUseWeaponOnCell_arg_1": "L'arma da testare. L'impostazione predefinita è l'arma attualmente equipaggiata.",
	"func_canUseWeaponOnCell_arg_2": "Il numero della cella che vuoi sparare.",
	"func_canUseWeaponOnCell_return": "<i>true</i> se la tua entità può attivarsi, <i>false</i> in caso contrario.",
	"func_cbrt": "Calcola la radice cubica di <b>numero</b>",
	"func_cbrt_arg_1": "Numero di cui vogliamo calcolare la radice cubica.",
	"func_cbrt_return": "Radice cubica di <b>numero</b>.",
	"func_ceil": "Restituisce l'arrotondamento di <b>numero</b>.<br/>Puoi trovare l'arrotondamento per difetto con #floor e l'arrotondamento con #round.",
	"func_ceil_arg_1": "Numero per il quale vogliamo trovare l'arrotondamento superiore.",
	"func_ceil_return": "Arrotonda per eccesso il <b>numero</b>.",
	"func_charAt": "Restituisce il carattere della stringa <b>stringa</b> che si trova nella posizione <b>posizione</b>.<br/>Il primo carattere della stringa è nella posizione 0.",
	"func_charAt_arg_1": "Stringa da cui vogliamo recuperare un carattere.",
	"func_charAt_arg_2": "Posizione del carattere da trovare.",
	"func_charAt_return": "stringa contenente il carattere ricercato o una stringa vuota se la posizione non è valida.",
	"func_chipNeedLos": "Restituisce se il <b>chip</b> necessita di linea di vista per essere utilizzato.",
	"func_chipNeedLos_arg_1": "L'id del chip da testare.",
	"func_chipNeedLos_return": "<i>true</i> se il <b>chip</b> richiede l'uso della linea di vista, <i>false</i> in caso contrario.",
	"func_clearMarks": "Cancella tutti i contrassegni fatti da mark() e markText() nel campo.",
	"func_clone": "Copia il <b>valore</b> passato come parametro sui livelli <b>level</b> e restituisce la copia. Nel caso di un array, ad esempio, un clone(array, 1) copierà l'array ma non i suoi elementi, un clone(array, 2) copierà l'array così come tutti gli elementi.",
	"func_clone_arg_1": "Il valore da clonare",
	"func_clone_arg_2": "Il numero di livelli da clonare",
	"func_clone_return": "Il valore clonato",
	"func_contains": "Determina se una stringa <b>search</b> si trova all'interno di una stringa <b>string</b>.",
	"func_contains_arg_1": "Stringa in cui verrà eseguita la ricerca.",
	"func_contains_arg_2": "Stringa da cercare.",
	"func_contains_return": "true se <b>search</b> è in <b>string</b>, false in caso contrario.",
	"func_cos": "Calcola il coseno dell'<b>angolo</b>.",
	"func_cos_arg_1": "Numero di cui verrà calcolato il coseno (in radianti).",
	"func_cos_return": "Il coseno dell'<b>angolo</b>, nell'intervallo [-1, 1].",
	"func_count": "Conta il numero di elementi nell'array <b>array</b>.",
	"func_count_arg_1": "Array di cui verrà conteggiato il numero di elementi.",
	"func_count_return": "Numero di elementi dell'array.",
	"func_debug": "Registra un <b>oggetto</b> messaggio nel registro personale, disponibile nel rapporto al termine del combattimento.",
	"func_debug_arg_1": "Il messaggio da registrare.",
	"func_debugC": "Registra un <b>oggetto</b> messaggio nel log personale, disponibile nel report a fine combattimento, del colore <b>color</b>.",
	"func_debugC_arg_1": "Il messaggio da registrare.",
	"func_debugC_arg_2": "Il colore del messaggio. Puoi usare la funzione #getColor.",
	"func_debugE": "Registra un messaggio di errore dell'<b>oggetto</b> nel registro personale, disponibile nel report al termine del combattimento.<br />I messaggi di errore vengono visualizzati in rosso nel report del combattimento.",
	"func_debugE_arg_1": "Il messaggio di errore da registrare.",
	"func_debugW": "Registra un messaggio di avviso <b>oggetto</b> nel registro personale, disponibile nel rapporto alla fine del combattimento. <br />I messaggi di avviso appaiono in arancione nel rapporto di combattimento.",
	"func_debugW_arg_1": "Il messaggio di avviso da registrare.",
	"func_deleteRegister": "Elimina il registro associato alla chiave <b>key</b> se esiste.",
	"func_deleteRegister_arg_1": "La chiave di registro da eliminare.",
	"func_endsWith": "Determina se una <b>stringa</b> termina con una stringa <b>suffisso</b>.",
	"func_endsWith_arg_1": "Stringa in cui verrà eseguita la ricerca",
	"func_endsWith_arg_2": "Suffisso per cercare",
	"func_endsWith_return": "vero se <b>stringa</b> termina con <b>suffisso</b>",
	"func_exp": "Eleva il numero di Eulero #E alla potenza di <b>numero</b>.",
	"func_exp_arg_1": "L'esponente a cui sarà elevato #E.",
	"func_exp_return": "#E** <b>numero</b>.",
	"func_fill": "Ridimensiona l'array <b>array</b> a <b>size</b> e riempie tutte le sue caselle con <b>value</b>.",
	"func_fill_arg_1": "La tabella da completare.",
	"func_fill_arg_2": "Il valore per ogni quadrato nell'array.",
	"func_fill_arg_3": "Il numero di scatole. Per impostazione predefinita, la dimensione dell'array corrente.",
	"func_floor": "Calcola l'arrotondamento per difetto di <b>numero</b>.<br/>Puoi trovare l'arrotondamento per eccesso con #ceil e l'arrotondamento con #round.",
	"func_floor_arg_1": "Numero di cui vogliamo trovare l'arrotondamento per difetto.",
	"func_floor_return": "Arrotondato per difetto a <b>numero</b>.",
	"func_getAbsoluteShield": "Restituisce lo scudo assoluto dell'entità con id <b>entity</b>. Per recuperare direttamente lo scudo assoluto della tua entità, usa #getAbsoluteShield() senza parametri.",
	"func_getAbsoluteShield_arg_1": "L'id dell'entità il cui scudo assoluto verrà restituito.",
	"func_getAbsoluteShield_return": "Lo scudo assoluto dell'entità <b>entità</b>.",
	"func_getAgility": "Restituisce l'agilità dell'entità con id <b>entity</b>. Per recuperare direttamente l'agilità della tua entità, usa #getAgility() senza parametri.",
	"func_getAgility_arg_1": "L'id dell'entità di cui verrà restituita l'agilità.",
	"func_getAgility_return": "L'agilità dell'<b>entità</b> entità.",
	"func_getAIID": "Restituisce l'ID AI dell'entità <b>entity</b>. Usa getAIID senza parametri per recuperare l'ID della tua IA.",
	"func_getAIID_arg_1": "L'id dell'entità il cui ID AI verrà restituito.",
	"func_getAIID_return": "L'<b>entità</b> ID AI dell'entità.",
	"func_getAIName": "Restituisce il nome dell'entità AI <b>entity</b>. Usa getAINam() senza parametri per recuperare il nome della tua IA.",
	"func_getAIName_arg_1": "L'id dell'entità il cui nome AI verrà restituito.",
	"func_getAIName_return": "Il nome dell'<b>entità</b> entità AI.",
	"func_getAliveAllies": "Restituisce una schiera di tutti i tuoi alleati vivi in combattimento.",
	"func_getAliveAllies_return": "Un array contenente gli ID di tutti i tuoi alleati viventi.",
	"func_getAliveEnemies": "Restituisce una serie di tutti i tuoi nemici vivi in combattimento.",
	"func_getAliveEnemiesCount": "Restituisce il numero di nemici vivi nel combattimento.",
	"func_getAliveEnemiesCount_return": "Il numero di nemici viventi.",
	"func_getAliveEnemies_return": "Un array contenente gli ID di tutti i tuoi nemici viventi.",
	"func_getAllChips": "Restituisce l'elenco di tutte le fiche del gioco.",
	"func_getAllChips_return": "L'elenco di tutte le fiches nel gioco.",
	"func_getAllEffects": "Restituisce l'elenco di tutti gli effetti nel gioco.",
	"func_getAllEffects_return": "L'elenco di tutti gli effetti nel gioco.",
	"func_getAllWeapons": "Restituisce l'elenco di tutte le armi nel gioco.",
	"func_getAllWeapons_return": "L'elenco di tutte le armi del gioco.",
	"func_getAlliedTurret": "Restituisce l'id della torretta della tua squadra o -1 se non esiste.",
	"func_getAlliedTurret_return": "L'ID della torretta della tua squadra.",
	"func_getEnemyTurret": "Restituisce l'id della torretta nemica o -1 se non esiste.",
	"func_getEnemyTurret_return": "L'ID della torretta nemica.",
	"func_getAllies": "Restituisce un array contenente i tuoi alleati e la tua entità.",
	"func_getAlliesCount": "Restituisce il numero di alleati in combattimento.",
	"func_getAlliesCount_return": "Il numero di alleati.",
	"func_getAlliesLife": "Restituisce la salute totale dei tuoi alleati.",
	"func_getAlliesLife_return": "La salute totale dei tuoi alleati.",
	"func_getAllies_return": "La tabella degli alleati e la tua entità.",
	"func_getBirthTurn": "Restituisce il turno del combattimento in cui è apparsa l'<b>entità</b>. Restituisce 1 se è un porro per esempio, e 5 se è un'evocazione invocata al turno 5.",
	"func_getBirthTurn_arg_1": "L'id dell'entità di cui verrà restituito il turno di spawn.",
	"func_getBirthTurn_return": "Il turno di combattimento in cui è apparsa l'<b>entità</b>.",
	"func_getBlue": "Restituisce la quantità di blu nel colore <b>color</b>, compresa tra 0 e 255. Ad esempio, getBlue(#COLOR_BLUE) = 255 e getBlue(#COLOR_GREEN) = 0.",
	"func_getBlue_arg_1": "Il colore di cui verrà restituito il tasso di blu.",
	"func_getBlue_return": "Il tasso di blu nel colore <b>color</b>",
	"func_getBulbChips": "Restituisce l'elenco dei chip che saranno equipaggiati su un bulbo evocata dal chip <b>bulb_chip</b>.",
	"func_getBulbChips_arg_1": "La pulce del bulbo.",
	"func_getBulbChips_return": "I chip di un bulbo evocati dal chip <b>bulb_chip</b>.",
	"func_getCell": "Restituisce la cella in cui si trova l'entità con id <b>entity</b>. Usa getCell() senza parametri per recuperare la tua cella.",
	"func_getCell_arg_1": "L'id dell'entità la cui cella verrà restituita.",
	"func_getCell_return": "Il numero di cella in cui si trova l'<b>entità</b>.",
	"func_getCellContent": "Restituisce il contenuto di una cella con id <b>cella</b>.",
	"func_getCellContent_arg_1": "L'id della cella il cui contenuto verrà restituito.",
	"func_getCellContent_return": "Il contenuto della cella <b>cell</b>: #CELL_EMPTY per una cella vuota, #CELL_ENTITY per un'entità, #CELL_OBSTACLE per un ostacolo.",
	"func_getCellDistance": "Restituisce la distanza tra due celle <b>cell1</b> e <b>cell2</b>. <br />La distanza restituita è espressa in numero di celle, e non tiene conto dei vari ostacoli tra le due celle.\nPer ottenere la distanza in linea d'aria, vedere #getDistance e per ottenere la distanza del percorso tra le due celle evitando gli ostacoli, vedere #getPathLength.",
	"func_getCellDistance_arg_1": "L'id della cella iniziale.",
	"func_getCellDistance_arg_2": "L'id della cella di arrivo.",
	"func_getCellDistance_return": "La distanza tra le due celle <b>cell1</b> e <b>cell2</b>.",
	"func_getCellFromXY": "Restituisce l'id della cella in posizione (<b>x</b>, <b>y</b>).",
	"func_getCellFromXY_arg_1": "La posizione x della cella.",
	"func_getCellFromXY_arg_2": "La posizione y della cella.",
	"func_getCellFromXY_return": "L'id della cella alla posizione (<b>x</b>, <b>y</b>), <b>null</b> se la cella non esiste.",
	"func_getCellsToUseChip": "Restituisce l'elenco delle celle da cui la tua entità sarà in grado di utilizzare il chip <b>chip</b> sull'entità <b>entity</b>.",
	"func_getCellsToUseChip_arg_1": "Il chip che l'entità vuole poter utilizzare.",
	"func_getCellsToUseChip_arg_2": "L'entità di destinazione.",
	"func_getCellsToUseChip_arg_3": "Array di celle da ignorare.",
	"func_getCellsToUseChip_return": "Elenco delle celle in cui è possibile utilizzare il chip.",
	"func_getCellsToUseChipOnCell": "Restituisce l'elenco di celle da cui la tua entità sarà in grado di utilizzare il chip <b>chip</b> sulla cella <b>cell</b>.",
	"func_getCellsToUseChipOnCell_arg_1": "Il chip che l'entità vuole poter utilizzare.",
	"func_getCellsToUseChipOnCell_arg_2": "La cellula bersaglio.",
	"func_getCellsToUseChipOnCell_arg_3": "Array di celle da ignorare.",
	"func_getCellsToUseChipOnCell_return": "Elenco delle celle in cui è possibile utilizzare il chip.",
	"func_getCellsToUseWeapon": "Restituisce l'elenco delle celle da cui la tua entità potrà utilizzare l'arma <b>arma</b> sull'entità <b>entità</b>.",
	"func_getCellsToUseWeapon_arg_1": "L'arma da testare. L'impostazione predefinita è l'arma attualmente equipaggiata.",
	"func_getCellsToUseWeapon_arg_2": "L'entità di destinazione.",
	"func_getCellsToUseWeapon_arg_3": "Array di celle da ignorare. Per impostazione predefinita un array vuoto.",
	"func_getCellsToUseWeapon_return": "Elenco delle celle da cui è possibile utilizzare l'arma.",
	"func_getCellsToUseWeaponOnCell": "Restituisce l'elenco delle celle da cui la tua entità potrà utilizzare l'arma <b>arma</b> su una cella <b>cella</b>.",
	"func_getCellsToUseWeaponOnCell_arg_1": "L'arma da testare. L'impostazione predefinita è l'arma attualmente equipaggiata.",
	"func_getCellsToUseWeaponOnCell_arg_2": "La cellula bersaglio.",
	"func_getCellsToUseWeaponOnCell_arg_3": "Array di celle da ignorare. Per impostazione predefinita un array vuoto.",
	"func_getCellsToUseWeaponOnCell_return": "Elenco delle celle da cui è possibile utilizzare l'arma.",
	"func_getCellX": "Determina la posizione in X della cella <b>cell</b>.",
	"func_getCellX_arg_1": "La cella di cui verrà determinata la posizione in X.",
	"func_getCellX_return": "La posizione X della cella.",
	"func_getCellY": "Determina la posizione Y della cella <b>cell</b>.",
	"func_getCellY_arg_1": "La cella di cui verrà determinata la posizione in Y.",
	"func_getCellY_return": "La posizione Y della cella.",
	"func_getChipArea": "Restituisce il tipo di area di effetto del <b>chip</b>.",
	"func_getChipArea_arg_1": "Il chip di cui verrà restituito il tipo di zona.",
	"func_getChipArea_return": "Il tipo di area del chip <b>chip</b> tra le costanti AREA_*:\n<ul>\n<li>#AREA_POINT: singola area quadrata</li>\n<li>#AREA_LASER_LINE: linea di un laser</li>\n<li>#AREA_CIRCLE_1: area circolare di 3 quadrati di diametro</li>\n<li>#AREA_CIRCLE_2: area circolare di 5 quadrati di diametro</li>\n<li>#AREA_CIRCLE_3: area circolare di 7 quadrati di diametro</li>\n</ul>",
	"func_getChipCooldown": "Restituisce il tempo di recupero del chip <b>chip</b>, preso dal market.",
	"func_getChipCooldown_arg_1": "Il chip il cui tempo di recupero verrà restituito.",
	"func_getChipCooldown_return": "Il tempo di recupero del <b>chip</b>.",
	"func_getChipCost": "Restituisce il costo in TP del chip <b>chip</b>.",
	"func_getChipCost_arg_1": "Verrà restituito il chip quale costo.",
	"func_getChipCost_return": "Il costo del <b>chip</b>.",
	"func_getChipEffectiveArea": "Restituisce l'elenco delle celle che saranno interessate se il chip <b>chip</b> viene utilizzato sulla cella <b>cell</b> da una cella <b>from</b>.",
	"func_getChipEffectiveArea_arg_1": "Il chip da testare.",
	"func_getChipEffectiveArea_arg_2": "La cellula bersaglio.",
	"func_getChipEffectiveArea_arg_3": "La cella da cui viene utilizzato il chip.",
	"func_getChipEffectiveArea_return": "L'array contenente gli ID di tutte le celle interessate.",
	"func_getChipEffects": "Restituisce gli effetti del chip <b>chip</b>.",
	"func_getChipEffects_arg_1": "Il chip i cui effetti verranno restituiti.",
	"func_getChipEffects_return": "Gli effetti del <b>chip</b> chip. Stesso valore di ritorno della funzione #getWeaponEffects.",
	"func_getChipFailure": "Restituisce la percentuale di rischio di guasto del chip <b>chip</b>.",
	"func_getChipFailure_arg_1": "Il chip di cui verrà restituita la percentuale di errore.",
	"func_getChipFailure_return": "<b>chip</b> percentuale di guasto del chip, un numero intero compreso tra <b>0</b> e <b>100</b>.",
	"func_getChipMaxRange": "Restituisce la portata massima del chip <b>chip</b>.",
	"func_getChipMaxRange_arg_1": "Il chip la cui portata massima verrà restituita.",
	"func_getChipMaxRange_return": "La portata massima del <b>chip</b>.",
	"func_getChipMaxScope": "Restituisce la portata massima del chip <b>chip</b>.",
	"func_getChipMaxScope_arg_1": "Il chip la cui portata massima verrà restituita.",
	"func_getChipMaxScope_return": "La portata massima del <b>chip</b>.",
	"func_getChipMinRange": "Restituisce l'intervallo minimo del chip <b>chip</b>.",
	"func_getChipMinRange_arg_1": "Il chip il cui raggio minimo verrà restituito.",
	"func_getChipMinRange_return": "L'intervallo minimo del <b>chip</b>.",
	"func_getChipMinScope": "Restituisce l'intervallo minimo del chip <b>chip</b>.",
	"func_getChipMinScope_arg_1": "Il chip il cui raggio minimo verrà restituito.",
	"func_getChipMinScope_return": "L'intervallo minimo del <b>chip</b>.",
	"func_getChipLaunchType": "Restituisce la modalità di avvio del chip <b>chip</b>, tra le costanti LAUNCH_TYPE_*.",
	"func_getChipLaunchType_arg_1": "L'id del chip di cui verrà restituita la modalità di lancio.",
	"func_getChipLaunchType_return": "La modalità di lancio del chip <b>chip</b>.",
	"func_getChipName": "Restituisce il nome del chip <b>chip</b>.",
	"func_getChipName_arg_1": "Il chip il cui nome verrà restituito.",
	"func_getChipName_return": "Il nome del <b>chip</b> chip.",
	"func_getChips": "Restituisce i punti elenco dell'entità con id <b>entity</b>.",
	"func_getChips_arg_1": "L'id dell'entità di cui verranno restituiti i punti elenco.",
	"func_getChips_return": "Un array contenente gli ID dei punti elenco dell'entità <b>entity</b>.",
	"func_getChipTargets": "Restituisce le entità che saranno interessate se il chip <b>chip</b> viene utilizzato sulla cella <b>cell</b>.",
	"func_getChipTargets_arg_1": "Il chip da testare.",
	"func_getChipTargets_arg_2": "La cellula bersaglio.",
	"func_getChipTargets_return": "L'array contenente gli ID di tutte le entità che saranno interessate.",
	"func_getColor": "Restituisce l'intero corrispondente al colore (<b>rosso</b>, <b>verde</b>, <b>blu</b>) fornito come parametri.",
	"func_getColor_arg_1": "Valore rosso compreso tra 0 e 255.",
	"func_getColor_arg_2": "Valore verde compreso tra 0 e 255.",
	"func_getColor_arg_3": "Valore blu compreso tra 0 e 255.",
	"func_getColor_return": "int corrispondente al colore fornito come parametro.",
	"func_getCooldown": "Restituisce l'attuale cooldown <b>chip</b> dell'entità <b>entity</b>.",
	"func_getCooldown_arg_1": "Il chip il cui tempo di recupero attuale verrà restituito.",
	"func_getCooldown_arg_2": "L'entità il cui cooldown verrà restituito.",
	"func_getCooldown_return": "L'attuale cooldown del chip <b>chip</b>, questo è il numero di turni prima che il chip diventi utilizzabile, <b>0</b> se è attualmente utilizzabile.",
	"func_getCores": "Restituisce il numero di core dell'entità con id <b>entity</b>.",
	"func_getCores_arg_1": "L'entità di cui verrà restituito il numero di core.",
	"func_getCores_return": "Il numero di core dell'entità <b>entità</b>.",
	"func_getDate": "Restituisce la data del combattimento, nel formato gg/MM/aaaa.",
	"func_getDate_return": "La data del combattimento.",
	"func_getTime": "Restituisce l'ora della battaglia, nel formato HH:mm:ss.",
	"func_getTime_return": "Il tempo della combattimento.",
	"func_getTimestamp": "Restituisce il timestamp del combattimento, pari al numero di secondi dal 1 gennaio 1970.",
	"func_getTimestamp_return": "Il timestamp del combattimento.",
	"func_getDamageReturn": "Restituisce il tasso di restituzione del danno dell'entità con id <b>entity</b>.",
	"func_getDamageReturn_arg_1": "L'ID dell'entità da cui verrà restituito il rinvio del danno.",
	"func_getDamageReturn_return": "Il tasso di restituzione del danno dell'entità con id <b>entità</b> (in %).",
	"func_getDeadAllies": "Restituisce entità amiche morte.",
	"func_getDeadAllies_return": "La tabella delle entità morte alleate.",
	"func_getDeadEnemies": "Restituisce entità nemiche morte.",
	"func_getDeadEnemiesCount": "Restituisce il numero di nemici morti in combattimento.",
	"func_getDeadEnemiesCount_return": "Il numero di nemici morti.",
	"func_getDeadEnemies_return": "La tabella delle entità nemiche morte.",
	"func_getDistance": "Calcola la distanza in linea d'aria tra due celle <b>cell1</b> e <b>cell2</b>. <br />Per ottenere la distanza in numero di celle, vedi #getCellDistance, e per ottenere la lunghezza del percorso tra le due celle schivando i vari ostacoli, vedi #getPathLength.",
	"func_getDistance_arg_1": "La cella di partenza.",
	"func_getDistance_arg_2": "La cella di arrivo.",
	"func_getDistance_return": "La distanza in linea d'aria tra le due celle.",
	"func_getPassiveEffects": "Restituisce l'elenco degli effetti passivi dell'entità con id <b>entity</b>. Per recuperare direttamente l'elenco degli effetti passivi della tua entità, usa #getPassiveEffects() senza parametro.",
	"func_getPassiveEffects_arg_1": "L'id dell'entità di cui verrà restituito l'elenco degli effetti passivi.",
	"func_getPassiveEffects_return": "L'elenco degli effetti passivi attualmente presenti sull'entità <b>entità</b>.\nL'elenco degli effetti passivi è un array contenente gli effetti.\nUn effetto è esso stesso un array di 7 caselle della forma: [<b>type</b>, <b>value</b>, <b>caster_id</b>, <b>turns</b >, <b>critical</b>, <b>item_id</b>, <b>target_id</b>]. È la stessa struttura dei classici effetti restituiti dalla funzione #getEffects.",
	"func_getEnemies": "Riporta in combattimento le entità nemiche (vive o morte).",
	"func_getEnemiesCount": "Restituisce il numero di nemici nel combattimento.",
	"func_getEnemiesCount_return": "Il numero di nemici.",
	"func_getEnemiesLife": "Calcola la somma dei punti ferita di tutte le entità nemiche.",
	"func_getEnemiesLife_return": "La somma dei punti ferita della squadra nemica.",
	"func_getEnemies_return": "Un array contenente gli ID di tutte le entità nemiche.",
	"func_getEntityTurnOrder": "Restituisce un valore compreso tra 1 e n (numero di entità attualmente in riproduzione) che indica la posizione dell'<b>entità</b> nell'ordine di riproduzione.",
	"func_getEntityTurnOrder_arg_1": "L'ID dell'entità il cui ordine di riproduzione verrà restituito",
	"func_getEntityTurnOrder_return": "Posiziona in ordine di gioco l'entità <b>entity</b>",
	"func_getFarmerID": "Restituisce l'ID selezionatore dell'entità <b>entity</b>.",
	"func_getFarmerID_arg_1": "L'ID dell'entità di cui verrà restituito l'id dell'allevatore.",
	"func_getFarmerID_return": "L'ID dell'<b>entità</b> creatore di entità.",
	"func_getFarmerCountry": "Restituisce il paese dell'origine dell'entità <b>entità</b>.",
	"func_getFarmerCountry_arg_1": "L'ID dell'entità il cui paese dell'allevatore verrà restituito.",
	"func_getFarmerCountry_return": "Il paese dell'<b>entità</b> creatore dell'entità o \"?\" se non indicato.",
	"func_getFarmerName": "Restituisce il nome del selezionatore dell'entità <b>entità</b>.",
	"func_getFarmerName_arg_1": "L'id dell'entità di cui verrà restituito il nome dell'allevatore.",
	"func_getFarmerName_return": "Il nome del creatore dell'entità <b>entità</b>.",
	"func_getFarthestAlly": "Determina l'alleato più lontano della tua entità, in linea d'aria.",
	"func_getFarthestAlly_return": "L'id dell'entità amichevole più lontana.",
	"func_getFarthestEnemy": "Determina il nemico più lontano dalla tua entità, in linea d'aria.",
	"func_getFarthestEnemy_return": "L'id dell'entità nemica più lontana.",
	"func_getFightID": "Restituisce l'ID combattimento corrente.",
	"func_getFightID_return": "L'id della combattimento in corso.",
	"func_getFightContext": "Restituisce il contesto del combattimento in corso.",
	"func_getFightContext_return": "A seconda del contesto della battaglia: Test Battle (#FIGHT_CONTEXT_TEST), Arena Battle (#FIGHT_CONTEXT_GARDEN), Tournament Battle (#FIGHT_CONTEXT_TOURNAMENT), Challenge Battle (#FIGHT_CONTEXT_CHALLENGE), Battle Royale (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "Restituisce il tipo di combattimento attuale.",
	"func_getFightType_return": "A seconda del tipo di combattimento: combattimento singolo (#FIGHT_TYPE_SOLO), combattimento contadino (#FIGHT_TYPE_FARMER), combattimento di squadra (#FIGHT_TYPE_TEAM), Battle Royale (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "Restituisce la forza dell'entità con id <b>entity</b>.",
	"func_getForce_arg_1": "L'entità la cui forza verrà restituita.",
	"func_getForce_return": "La forza dell'<b>entità</b> entità.",
	"func_getFrequency": "Restituisce la frequenza dell'entità con id <b>entity</b>. Usa getFrequency() senza parametri per recuperare la tua frequenza.",
	"func_getFrequency_arg_1": "L'entità la cui frequenza verrà restituita.",
	"func_getFrequency_return": "La frequenza dell'entità <b>entità</b>.",
	"func_getGreen": "Restituisce la quantità di verde nel colore <b>color</b>, compresa tra 0 e 255. Ad esempio, getGreen(#COLOR_GREEN) = 255 e getGreen(#COLOR_RED) = 0.",
	"func_getGreen_arg_1": "Il colore di cui verrà restituito il tasso verde.",
	"func_getGreen_return": "Il tasso di verde nel colore <b>color</b>",
	"func_getInstructionsCount": "Restituisce il numero di istruzioni che la tua entità ha eseguito durante il turno corrente.",
	"func_getInstructionsCount_return": "Il numero di istruzioni che la tua entità ha eseguito durante il turno corrente.",
	"func_getLaunchedEffects": "Restituisce l'elenco degli effetti causati dall'entità con id <b>entity</b>.",
	"func_getLaunchedEffects_arg_1": "L'id dell'entità di cui verrà restituito l'elenco degli effetti causati.",
	"func_getLaunchedEffects_return": "L'elenco degli effetti causati dall'entità con id <b>entity</b>, nella stessa forma dell'array restituito da #getEffects.",
	"func_getLeek": "Restituisce l'id della tua entità.",
	"func_getLeek_return": "L'id della tua entità.",
	"func_getEntity": "Restituisce l'id della tua entità.",
	"func_getEntity_return": "L'id della tua entità.",
	"func_getLeekID": "Restituisce il vero id del porro di id <b>porro</b>.",
	"func_getLeekID_arg_1": "L'id del porro il cui ID effettivo verrà restituito.",
	"func_getLeekID_return": "Il vero id dell'<b>entità</b> del porro.",
	"func_getLeekOnCell": "Restituisce l'entità che si trova sulla cella <b>cella</b>.",
	"func_getLeekOnCell_arg_1": "La cella di cui vogliamo recuperare l'entità.",
	"func_getLeekOnCell_return": "L'id dell'entità nella cella o -1 se la cella non ha entità.",
	"func_getEntityOnCell": "Restituisce l'entità che si trova sulla cella <b>cell</b>.",
	"func_getEntityOnCell_arg_1": "La cella di cui vogliamo recuperare l'entità.",
	"func_getEntityOnCell_return": "L'id dell'entità nella cella o -1 se la cella non ha entità.",
	"func_getLevel": "Restituisce il livello di entità con id <b>entity</b>.",
	"func_getLevel_arg_1": "L'id dell'entità il cui livello verrà restituito.",
	"func_getLevel_return": "Il livello dell'entità con id <b>entity</b>.",
	"func_getLife": "Restituisce la vita attuale dell'entità con id <b>entity</b>. Usa getLife() senza parametri per recuperare la tua vita.",
	"func_getLife_arg_1": "L'id dell'entità la cui vita verrà restituita.",
	"func_getLife_return": "La vita attuale dell'entità <b>entità</b>.",
	"func_getMagic": "Restituisce la magia dell'entità con id <b>entity</b>. Usa getMagic() senza parametri per recuperare la tua magia.",
	"func_getMagic_arg_1": "L'id dell'entità la cui magia verrà restituita.",
	"func_getMagic_return": "La magia dell'id entità <b>entità</b>.",
	"func_getMapType": "Restituisce il tipo di terreno su cui si svolge il combattimento (fabbrica, deserto, foresta ecc.), tra le costanti #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER e #MAP_BEACH.",
	"func_getMapType_return": "Il tipo di terreno.",
	"func_getMessageAuthor": "Restituisce l'id dell'entità che crea il messaggio <b>message</b>.",
	"func_getMessageAuthor_arg_1": "Il messaggio di cui verrà restituito l'autore.",
	"func_getMessageAuthor_return": "L'id dell'entità che crea il messaggio <b>message</b>.",
	"func_getMessageParams": "Restituisce l'array dei parametri del messaggio <b>message</b>.",
	"func_getMessageParams_arg_1": "Il messaggio i cui parametri verranno restituiti.",
	"func_getMessageParams_return": "I parametri del messaggio <b>message</b>.",
	"func_getMessages": "Restituisce l'array del messaggio dell'entità <b>entity</b>.",
	"func_getMessages_arg_1": "L'entità i cui messaggi verranno restituiti.",
	"func_getMessages_return": "La tabella dei tuoi messaggi.<br>Un messaggio è esso stesso rappresentato sotto forma di a\narray della forma: [<b>autore</b>, <b>tipo</b>, <b>parametri</b>]<br>\nI diversi tipi di messaggi sono rappresentati dalle costanti:\n<ul>\n<li>#MESSAGE_HEAL: richiesta di assistenza</li>\n<li>#MESSAGE_ATTACK: richiesta di attacco</li>\n<li>#MESSAGE_BUFF_FORCE: richiesta potenziamento forzato</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "Restituisce il tipo di messaggio <b>message</b>.",
	"func_getMessageType_arg_1": "Il messaggio il cui tipo verrà restituito (tra #MESSAGE_HEAL, #MESSAGE_ATTACK, ecc.).",
	"func_getMessageType_return": "Il tipo di messaggio <b>message</b>.",
	"func_getMP": "Restituisce il conteggio dei punti di movimento corrente dell'entità <b>entity</b>. Usa getMP() senza parametri per recuperare i tuoi PM.",
	"func_getMP_arg_1": "L'ID dell'entità di cui verrà restituito il conteggio PM.",
	"func_getMP_return": "Il numero MP dell'entità <b>entità</b>.",
	"func_getName": "Restituisce il nome dell'entità con id <b>entity</b>.",
	"func_getName_arg_1": "L'id dell'entità il cui nome verrà restituito.",
	"func_getName_return": "Il nome dell'entità <b>entità</b>.",
	"func_getNearestAlly": "Restituisce l'entità amichevole più vicina alla tua entità.",
	"func_getNearestAlly_return": "L'id dell'entità amichevole più vicina.",
	"func_getNearestAllyTo": "Restituisce l'entità alleata più vicina all'entità fornita come parametro.",
	"func_getNearestAllyTo_arg_1": "L'id dell'entità di cui vogliamo conoscere il più stretto alleato.",
	"func_getNearestAllyToCell": "Restituisce l'entità alleata più vicina alla cella fornita come parametro.",
	"func_getNearestAllyToCell_arg_1": "L'id della cellula di cui vogliamo conoscere il più stretto alleato.",
	"func_getNearestAllyToCell_return": "L'id dell'entità amichevole più vicina.",
	"func_getNearestAllyTo_return": "L'id dell'entità amichevole più vicina.",
	"func_getNearestEnemy": "Restituisce l'entità nemica più vicina alla tua entità.",
	"func_getNearestEnemy_return": "L'id dell'entità nemica più vicina.",
	"func_getNearestEnemyTo": "Restituisce l'entità nemica più vicina all'entità fornita come parametro.",
	"func_getNearestEnemyTo_arg_1": "L'id dell'entità di cui vogliamo conoscere il nemico più prossimo.",
	"func_getNearestEnemyToCell": "Restituisce l'entità nemica più vicina alla cella fornita come parametro.",
	"func_getNearestEnemyToCell_arg_1": "L'id della cella di cui vogliamo conoscere il nemico più prossimo.",
	"func_getNearestEnemyToCell_return": "L'id dell'entità nemica più vicina.",
	"func_getNearestEnemyTo_return": "L'id dell'entità nemica più vicina.",
	"func_getNextPlayer": "Restituisce l'id dell'entità che giocherà dopo il giocatore corrente.",
	"func_getNextPlayer_return": "Il giocatore successivo.",
	"func_getObstacles": "Restituisce l'elenco degli spazi ostacolo nel terreno.",
	"func_getObstacles_return": "L'array contenente gli ID delle celle dell'ostacolo.",
	"func_getOperations": "Restituisce il numero di operazioni consumate dalla tua entità dall'inizio del suo turno. Questo numero deve rimanere inferiore a #OPERATIONS_LIMIT in modo che l'entità non vada in crash.",
	"func_getOperations_return": "Numero di operazioni consumate dalla tua entità dall'inizio del suo turno.",
	"func_getPath": "Restituisce il percorso evitando gli ostacoli tra due celle <b>cell1</b> e <b>cell2</b>, se esiste, ignorando le celle contenute nell'array <b>ignoredCells</b> . Se un giocatore si trova su una cella ignorata, il percorso può passarci sopra.<br /><br />\nLa cella iniziale <b>cella1</b> non fa mai parte del percorso risultante. La cella <b>cell2</b> fa parte del percorso risultante se e solo se è vuota o ignorata da <b>ignoredCells</b>.<br /><br />\nSe non esiste alcun percorso tra le due celle, <b>getPath</b> restituisce <i>null</i>.",
	"func_getPath_arg_1": "La cella di partenza.",
	"func_getPath_arg_2": "La cella di arrivo.",
	"func_getPath_arg_3": "Matrice di celle da ignorare.",
	"func_getPath_return": "La matrice contenente le celle che compongono il percorso tra le due celle.",
	"func_getPathLength": "Restituisce la lunghezza del percorso tra due celle <b>cell1</b> e <b>cell2</b>, evitando gli ostacoli, ignorando le celle contenute nell'array <b>ignoredCells</b>. Questa funzione equivale a <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>)))</i>.\nSe un giocatore si trova su una cella ignorata, il percorso può passarci sopra.<br /><br />\nLa cella iniziale <b>cella1</b> non viene mai conteggiata nel risultato. La cella <b>cell2</b> viene conteggiata nel risultato se e solo se è vuota o ignorata da <b>ignoredCells</b>.<br /><br />\nSe non esiste alcun percorso tra le due celle, <b>getPathLength</b> restituisce <i>null</i>.",
	"func_getPathLength_arg_1": "La cella di partenza.",
	"func_getPathLength_arg_2": "La cella di arrivo.",
	"func_getPathLength_arg_3": "Matrice di celle da ignorare.",
	"func_getPathLength_return": "La lunghezza del percorso tra <b>cell1</b> e <b>cell2</b>.",
	"func_getPreviousPlayer": "Restituisce l'id dell'entità che ha giocato prima del giocatore corrente.",
	"func_getPreviousPlayer_return": "Il giocatore precedente.",
	"func_getRed": "Restituisce la quantità di rosso nel colore <b>color</b>, compresa tra 0 e 255. Ad esempio, getRed(#COLOR_RED) = 255 e getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "Il colore di cui verrà restituito il tasso rosso.",
	"func_getRed_return": "Il tasso di rosso in <b>color</b>",
	"func_getRegister": "Restituisce il valore memorizzato nel registro entità associato alla chiave <b>key</b> o </i>null</i> se il registro non esiste.",
	"func_getRegister_arg_1": "La chiave di registro il cui valore verrà restituito.",
	"func_getRegister_return": "Il valore memorizzato nel registro <b>key</b>.",
	"func_getRegisters": "Restituisce l'insieme di registri di entità come un array associativo [<i>chiave di registro</i>:<i>valore registro</i>]. Esempio: <code>debug(getRegisters());\n// Visualizza ad esempio:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "L'array associativo corrispondente a tutti i registri dell'entità.",
	"func_getRelativeShield": "Restituisce lo scudo relativo dell'entità con id <b>entity</b>. Per recuperare direttamente lo scudo relativo della tua entità, usa #getRelativeShield() senza parametri.",
	"func_getRelativeShield_arg_1": "L'id dell'entità il cui scudo relativo verrà restituito.",
	"func_getRelativeShield_return": "Lo scudo relativo dell'<b>entità</b>, un numero intero compreso tra <b>0</b> e <b>100</b>.",
	"func_getResistance": "Restituisce la resistenza dell'entità con id <b>entity</b>. Usa getResistance() senza parametri per recuperare la tua resistenza.",
	"func_getResistance_arg_1": "L'id dell'entità la cui resistenza verrà restituita.",
	"func_getResistance_return": "La resistenza dell'entità con id <b>entità</b>.",
	"func_getScience": "Restituisce la scienza dell'entità con id <b>entity</b>. Usa getScience() senza parametri per recuperare la tua scienza.",
	"func_getScience_arg_1": "L'id dell'entità la cui scienza verrà restituita.",
	"func_getScience_return": "La scienza dell'entità <b>entità</b> id.",
	"func_getStrength": "Restituisce la forza dell'entità con id <b>entity</b>. Usa getStrength() senza parametri per recuperare la tua forza.",
	"func_getStrength_arg_1": "L'entità la cui forza verrà restituita.",
	"func_getStrength_return": "La forza dell'<b>entità</b> entità.",
	"func_getPower": "Restituisce la potenza dell'entità con id <b>entity</b>.",
	"func_getPower_arg_1": "L'entità il cui potere verrà restituito.",
	"func_getPower_return": "Il potere dell'<b>entità</b>.",
	"func_getSummoner": "Restituisce l'entità che ha richiamato l'entità <b>entity</b>, se si tratta di una chiamata.",
	"func_getSummoner_arg_1": "L'id dell'entità il cui evocatore verrà licenziato.",
	"func_getSummoner_return": "L'id dell'entità invocata <b>entity</b> se si tratta di un'invocazione, <i>null</i> in caso contrario.",
	"func_getSummons": "Restituisce l'elenco degli id delle invocazioni attualmente attive dell'entità con id <b>entità</b>.",
	"func_getSummons_arg_1": "L'id dell'entità di cui verranno restituite le convocazioni.",
	"func_getSummons_return": "L'elenco degli ID di chiamata dell'entità con ID <b>entity</b>.",
	"func_getTeamID": "Restituisce l'id del team dell'entità <b>entity</b>.",
	"func_getTeamID_arg_1": "L'ID dell'entità il cui ID team verrà restituito.",
	"func_getTeamID_return": "L'ID del team dell'entità <b>entity</b>.",
	"func_getTeamName": "Restituisce il nome del team dell'entità <b>entity</b>.",
	"func_getTeamName_arg_1": "L'id dell'entità di cui verrà restituito il nome del team.",
	"func_getTeamName_return": "Il nome del team dell'entità <b>entità</b>.",
	"func_getTotalLife": "Restituisce la vita totale dell'entità con id <b>entity</b>. Usa getTotalLife() senza parametri per recuperare la tua vita totale.",
	"func_getTotalLife_arg_1": "L'id dell'entità la cui vita totale verrà restituita.",
	"func_getTotalLife_return": "La vita totale dell'entità.",
	"func_getTotalMP": "Restituisce il numero massimo di punti di movimento dell'entità <b>entity</b>.",
	"func_getTotalMP_arg_1": "L'id dell'entità di cui verranno restituiti i punti di movimento massimi.",
	"func_getTotalMP_return": "Il numero massimo di punti movimento per l'<b>entità</b>.",
	"func_getTotalTP": "Restituisce il numero massimo di punti di svolta dell'entità <b>entity</b>.",
	"func_getTotalTP_arg_1": "L'id dell'entità di cui verrà restituito il numero massimo di punti di svolta.",
	"func_getTotalTP_return": "Il numero massimo di punti di svolta dell'entità <b>entità</b>.",
	"func_getTP": "Restituisce il numero di punti di svolta dell'entità <b>entity</b>. Usa getTP() senza parametri per recuperare i tuoi PT.",
	"func_getTP_arg_1": "L'id dell'entità i cui PT verranno restituiti.",
	"func_getTP_return": "Il numero PT dell'entità <b>entità</b>.",
	"func_getTurn": "Restituisce il turno di combattimento in corso. Il numero massimo di giri è #MAX_TURNS.",
	"func_getTurn_return": "L'attuale turno di combattimento.",
	"func_getType": "Restituisce il tipo di entità dell'entità <b>entity</b>.",
	"func_getType_arg_1": "L'id dell'entità il cui tipo verrà restituito.",
	"func_getType_return": "Il tipo di entità di <b>entità</b>:<ul><li>#ENTITY_LEEK se è un porro.</li><li>#ENTITY_BULB se è un \"bulbo\".</li><li >#ENTITY_TURRET se è una torretta.</li></ul>",
	"func_getWeapon": "Restituisce l'<b>entità</b> dell'arma attualmente equipaggiata.",
	"func_getWeapon_arg_1": "L'id dell'entità la cui arma attuale verrà restituita.",
	"func_getWeapon_return": "L'id dell'arma attualmente equipaggiata sull'<b>entità</b>, null se l'entità non ha armi equipaggiate o l'entità non esiste.",
	"func_getWeaponArea": "Restituisce il tipo di area di effetto dell'arma <b>arma</b>.",
	"func_getWeaponArea_arg_1": "L'arma il cui tipo di zona verrà restituito.",
	"func_getWeaponArea_return": "Il tipo di area dell'arma <b>arma</b> tra le costanti AREA_*:\n<ul>\n<li>#AREA_POINT: singola area quadrata</li>\n<li>#AREA_LASER_LINE: linea di un laser</li>\n<li>#AREA_CIRCLE_1: area circolare di 3 celle di diametro</li>\n<li>#AREA_CIRCLE_2: area circolare di 5 quadrati di diametro</li>\n<li>#AREA_CIRCLE_3: area circolare di 7 quadrati di diametro</li>\n</ul>",
	"func_getWeaponCost": "Restituisce il costo in PT dell'arma <b>arma</b>.",
	"func_getWeaponCost_arg_1": "L'id dell'arma il cui costo verrà restituito.",
	"func_getWeaponCost_return": "Il costo in PT dell'<b>arma</b> dell'arma.",
	"func_getWeaponEffectiveArea": "Restituisce l'elenco delle celle che saranno interessate se l'arma <b>arma</b> viene utilizzata sulla cella <b>cella</b> dalla cella <b>da</b>.",
	"func_getWeaponEffectiveArea_arg_1": "L'arma da testare.",
	"func_getWeaponEffectiveArea_arg_2": "La cellula bersaglio.",
	"func_getWeaponEffectiveArea_arg_3": "La cella da cui viene sparata l'arma.",
	"func_getWeaponEffectiveArea_return": "L'array contenente gli ID di tutte le celle interessate.",
	"func_getWeaponPassiveEffects": "Restituisce gli effetti passivi dell'arma <b>arma</b>.",
	"func_getWeaponPassiveEffects_arg_1": "L'id dell'arma i cui effetti passivi verranno restituiti.",
	"func_getWeaponPassiveEffects_return": "Un array contenente gli effetti dell'<b>arma</b> dell'arma. Ogni effetto è esso stesso un array della forma\n[tipo, minimo, massimo, turni, bersagli, modificatori]. Questi effetti sono gli stessi di quelli restituiti da #getWeaponEffects.",
	"func_getWeaponFailure": "Restituisce la percentuale di probabilità di fallimento dell'arma <b>arma</b>.",
	"func_getWeaponFailure_arg_1": "L'id dell'arma di cui verrà restituita la percentuale di guasto.",
	"func_getWeaponFailure_return": "<b>arma</b> percentuale di arma mancata, un numero intero compreso tra <b>0</b> e <b>100</b>.",
	"func_getWeaponMaxRange": "Restituisce la gittata massima dell'arma <b>arma</b>.",
	"func_getWeaponMaxRange_arg_1": "L'id dell'arma di cui verrà restituita la gittata massima.",
	"func_getWeaponMaxRange_return": "La portata massima dell'arma <b>arma</b>.",
	"func_getWeaponMaxScope": "Restituisce la gittata massima dell'arma <b>arma</b>.",
	"func_getWeaponMaxScope_arg_1": "L'id dell'arma di cui verrà restituita la gittata massima.",
	"func_getWeaponMaxScope_return": "La portata massima dell'arma <b>arma</b>.",
	"func_getWeaponMinRange": "Restituisce la gittata minima dell'arma <b>arma</b>.",
	"func_getWeaponMinRange_arg_1": "L'id dell'arma la cui gittata minima verrà restituita.",
	"func_getWeaponMinRange_return": "La portata minima dell'arma <b>arma</b>.",
	"func_getWeaponMinScope": "Restituisce la gittata minima dell'arma <b>arma</b>.",
	"func_getWeaponMinScope_arg_1": "L'id dell'arma la cui gittata minima verrà restituita.",
	"func_getWeaponMinScope_return": "La portata minima dell'arma <b>arma</b>.",
	"func_getWeaponLaunchType": "Restituisce la modalità di lancio dell'arma <b>arma</b>, tra le costanti LAUNCH_TYPE_*.",
	"func_getWeaponLaunchType_arg_1": "L'id dell'arma di cui verrà restituita la modalità di lancio. L'impostazione predefinita è l'arma attualmente equipaggiata.",
	"func_getWeaponLaunchType_return": "La modalità di lancio dell'arma <b>arma</b>.",
	"func_getWeaponName": "Restituisce il nome dell'arma <b>arma</b>.",
	"func_getWeaponName_arg_1": "L'id dell'arma il cui nome verrà restituito.",
	"func_getWeaponName_return": "Il nome dell'<b>arma</b> dell'arma.",
	"func_getWeapons": "Restituisce le armi dell'entità con id <b>entity</b>.",
	"func_getWeapons_arg_1": "L'id dell'entità le cui armi verranno restituite.",
	"func_getWeapons_return": "Un array contenente gli ID arma <b>entità</b>.",
	"func_getWeaponTargets": "Restituisce le entità che saranno interessate se l'arma <b>arma</b> viene utilizzata sulla cella <b>cella</b>.",
	"func_getWeaponTargets_arg_1": "L'arma da testare.",
	"func_getWeaponTargets_arg_2": "La cellula bersaglio.",
	"func_getWeaponTargets_return": "L'array contenente gli ID di tutte le entità che saranno interessate.",
	"func_getWisdom": "Restituisce la saggezza dell'entità con id <b>entity</b>. Usa getWisdom() senza parametri per recuperare la tua saggezza.",
	"func_getWisdom_arg_1": "L'id dell'entità la cui saggezza verrà restituita.",
	"func_getWisdom_return": "Saggezza dell'entità id <b>entità</b>.",
	"func_hypot": "Restituisce l'ipotenusa del triangolo rettangolo di lati x e y. Equivalente a <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "Il valore x.",
	"func_hypot_arg_2": "Il valore y.",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "Determina se l'elemento <b>element</b> si trova nell'array <b>array</b>.",
	"func_inArray_arg_1": "La tabella di ricerca.",
	"func_inArray_arg_2": "L'elemento da cercare.",
	"func_inArray_return": "<i>true</i> se l'elemento è nell'array, <i>false</i> altrimenti.",
	"func_include": "Includere l'IA con nome <b>ai</b> nell'IA corrente. <br><br><b>Attenzione</b>, la funzione include deve essere chiamata solo nel blocco principale e il suo parametro deve essere una stringa scritta direttamente\nnel codice. Per ulteriori informazioni, consulta la sezione del tutorial: <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a> .",
	"func_include_arg_1": "Il nome dell'IA da includere.",
	"func_indexOf": "Determina la posizione della prima occorrenza della stringa <b>search</b> nella stringa <b>string</b>, a partire dalla posizione <b>start</b>.",
	"func_indexOf_arg_1": "La stringa in cui verrà eseguita la ricerca.",
	"func_indexOf_arg_2": "La stringa da cercare.",
	"func_indexOf_arg_3": "La posizione iniziale della ricerca.",
	"func_indexOf_return": "La posizione della prima occorrenza di <b>search</b> in <b>string</b> da <b>start</b>, -1 se la stringa non è stata trovata.",
	"func_insert": "Inserisce un elemento <b>element</b> nell'<b>array</b> alla posizione <b>position</b>.",
	"func_insert_arg_1": "La matrice in cui verrà inserito l'elemento.",
	"func_insert_arg_2": "L'elemento da inserire.",
	"func_insert_arg_3": "La posizione dell'inserimento.",
	"func_isAlive": "Determina se un'<b>entità</b> è attiva. Equivalente a <i>getLife(<b>entità</b>) > 0</i>.",
	"func_isAlive_arg_1": "L'id dell'entità da testare.",
	"func_isAlive_return": "<i>true</i> se <b>entità</b> è viva, <i>false</i> se morta.",
	"func_isAlly": "Determina se <b>entità</b> è il tuo alleato.",
	"func_isAlly_arg_1": "L'id dell'entità da testare.",
	"func_isAlly_return": "<i>vero</i> se l'<b>entità</b> è un tuo alleato o te stesso, <i>falso</i> se è un nemico.",
	"func_isChip": "Determina se un valore è una costante che rappresenta un chip.<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "Il numero da determinare.",
	"func_isChip_return": "<i>true</i> se il valore è una costante chip.",
	"func_isDead": "Determina se <b>entità</b> è morta. Equivalente a <i>getLife(<b>entità</b>) == 0</i>.",
	"func_isDead_arg_1": "L'id dell'entità da testare.",
	"func_isDead_return": "<i>true</i> se <b>entità</b> è morta, <i>false</i> se viva.",
	"func_isEmpty": "Determina se l'array <b>array</b> è vuoto. Equivalente a <i>count(<b>array</b>) == 0</i>.",
	"func_isEmpty_arg_1": "La matrice da testare.",
	"func_isEmptyCell": "Determina se una cella è vuota.",
	"func_isEmptyCell_arg_1": "La cella da testare.",
	"func_isEmptyCell_return": "<i>true</i> se la cella è vuota, <i>false</i> in caso contrario.",
	"func_isEmpty_return": "<i>true</i> se l'array è vuoto, <i>false</i> in caso contrario.",
	"func_isEnemy": "Determina se <b>entità</b> è il tuo nemico.",
	"func_isEnemy_arg_1": "L'id dell'entità da testare.",
	"func_isEnemy_return": "<i>vero</i> se l'<b>entità</b> è un nemico, <i>falso</i> se è un alleato o te stesso.",
	"func_isInlineChip": "Determina se il <b>chip</b> può essere utilizzato solo online.",
	"func_isInlineChip_arg_1": "L'id del chip da testare.",
	"func_isInlineChip_return": "<i>true</i> se il chip è solo online, <i>false</i> altrimenti.",
	"func_isInlineWeapon": "Determina se l'<b>arma</b> dell'arma può essere utilizzata solo online.",
	"func_isInlineWeapon_arg_1": "L'ID dell'arma da testare.",
	"func_isInlineWeapon_return": "<i>true</i> se l'arma può essere utilizzata solo online, <i>false</i> in caso contrario.",
	"func_isLeek": "Determina se il contenuto della cella <b>cell</b> è un'entità.",
	"func_isLeek_arg_1": "La cella da testare.",
	"func_isLeek_return": "<i>true</i> se la cella contiene un'entità, <i>false</i> in caso contrario.",
	"func_isEntity": "Determina se il contenuto della cella <b>cell</b> è un'entità.",
	"func_isEntity_arg_1": "La cella da testare.",
	"func_isEntity_return": "<i>true</i> se la cella contiene un'entità, <i>false</i> in caso contrario.",
	"func_isObstacle": "Determina se il contenuto della cella <b>cell</b> è un ostacolo.",
	"func_isObstacle_arg_1": "La cella da testare.",
	"func_isObstacle_return": "<i>true</i> se la cella contiene un ostacolo, <i>false</i> in caso contrario.",
	"func_isOnSameLine": "Determina se due celle <b>cell1</b> e <b>cell2</b> si trovano sulla stessa riga.",
	"func_isOnSameLine_arg_1": "La prima cella.",
	"func_isOnSameLine_arg_2": "La seconda cella.",
	"func_isOnSameLine_return": "<i>true</i> se le due celle sono sulla stessa riga, <i>false</i> altrimenti.",
	"func_isStatic": "Restituisce se l'entità <b>entity</b> è statica o meno. Un'entità statica non può spostarsi o essere spostata.",
	"func_isStatic_arg_1": "L'id dell'entità da testare.",
	"func_isStatic_return": "<i>true</i> se <b>entity</b> è statico, <i>false</i> in caso contrario.",
	"func_isSummon": "Restituisce se <b>entità</b> è un'invocazione o meno.",
	"func_isSummon_arg_1": "L'id dell'entità da testare.",
	"func_isSummon_return": "<i>true</i> se <b>entity</b> è un'invocazione, <i>false</i> altrimenti.",
	"func_isWeapon": "Determina se un valore è una costante che rappresenta un'arma.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false.",
	"func_isWeapon_arg_1": "Il numero da determinare.",
	"func_isWeapon_return": "<i>true</i> se il valore è una costante dell'arma.",
	"func_join": "Unisce più elementi in una stringa, separandoli con un delimitatore <b>glue</b>.",
	"func_join_arg_1": "Un array di elementi da unire.",
	"func_join_arg_2": "Il delimitatore dell'elemento.",
	"func_join_return": "La stringa risultante dell'unione.",
	"func_jsonDecode": "Decodifica la stringa <b>json</b> nell'oggetto LeekScript (numero, stringa, array...).",
	"func_jsonDecode_arg_1": "La stringa JSON da decodificare.",
	"func_jsonDecode_return": "L'oggetto LeekScript decodificato.",
	"func_jsonEncode": "Codifica l'oggetto <b>object</b> in una stringa JSON.",
	"func_jsonEncode_arg_1": "L'oggetto da codificare in JSON.",
	"func_jsonEncode_return": "La stringa con codifica JSON.",
	"func_keySort": "Ordina l'<b>array</b> per chiavi in <b>ordine</b>.",
	"func_keySort_arg_1": "Matrice da ordinare.",
	"func_keySort_arg_2": "L'ordinamento: #SORT_ASC o #SORT_DESC.",
	"func_length": "Restituisce la lunghezza di <b>stringa</b>.",
	"func_length_arg_1": "La stringa di cui verrà restituita la lunghezza.",
	"func_length_return": "La lunghezza di <b>stringa</b>.",
	"func_lineOfSight": "Controlla la linea di vista tra la cella <b>start</b> e la cella <b>end</b>, ignorando le entità <b>ignoredEntities</b>.",
	"func_lineOfSight_arg_1": "Cella di partenza.",
	"func_lineOfSight_arg_2": "Cella di destinazione.",
	"func_lineOfSight_arg_3": "Un'entità o un elenco di entità da ignorare.",
	"func_lineOfSight_return": "Restituisce <i>true</i> se la linea di vista è libera.",
	"func_listen": "Restituisce l'array di say() delle entità precedenti, nella forma [entity_id, message].",
	"func_listen_return": "L'array di say() precedenti.",
	"func_log": "Calcola il logaritmo naturale del numero <b>number</b>.",
	"func_log_arg_1": "Un numero compreso nell'intervallo ]0; +∞[.",
	"func_log_return": "Il logaritmo naturale del <b>numero</b>.",
	"func_log10": "Calcola il logaritmo in base 10 del numero <b>number</b>.",
	"func_log10_arg_1": "Un numero compreso nell'intervallo ]0; +∞[.",
	"func_log10_return": "Il logaritmo in base 10 del <b>numero</b>.",
	"func_log2": "Calcola il logaritmo in base 2 del numero <b>number</b>.",
	"func_log2_arg_1": "Un numero compreso nell'intervallo ]0; +∞[.",
	"func_log2_return": "Il logaritmo in base 2 del <b>numero</b>.",
	"func_mark": "Segna a terra una o più celle del colore indicato in parametro per il numero di giri indicato in parametro. Questa marcatura è visibile solo all'allevatore dell'entità.",
	"func_mark_arg_1": "La cella o la matrice di più celle da contrassegnare",
	"func_mark_arg_2": "Colore della marcatura",
	"func_mark_arg_3": "Durata della marcatura",
	"func_mark_return": "Restituisce vero se tutto è andato bene",
	"func_markText": "Scrive a terra un testo su una o più celle del colore indicato in parametro per il numero di giri indicato in parametro. Questi testi sono visibili solo all'allevatore dell'entità.",
	"func_markText_arg_1": "La cella o l'array di più celle in cui scrivere",
	"func_markText_arg_2": "Il testo da scrivere (massimo 10 caratteri)",
	"func_markText_arg_3": "Colore del testo",
	"func_markText_arg_4": "Durata del testo",
	"func_markText_return": "Restituisce vero se tutto è andato bene",
	"func_max": "Restituisce il valore maggiore tra i due numeri <b>a</b> e <b>b</b>.",
	"func_max_arg_1": "Un numero.",
	"func_max_arg_2": "Un numero.",
	"func_max_return": "Il numero maggiore tra <b>a</b> e <b>b</b>.",
	"func_min": "Restituisce il valore più piccolo tra i due numeri <b>a</b> e <b>b</b>.",
	"func_min_arg_1": "Un numero.",
	"func_min_arg_2": "Un numero.",
	"func_min_return": "Il numero più piccolo tra <b>a</b> e <b>b</b>.",
	"func_moveAwayFrom": "Allontana la tua entità da un'altra <b>entità</b>, utilizzando il massimo di <b>mp</b> punti movimento.",
	"func_moveAwayFrom_arg_1": "L'entità da cui la tua entità dovrebbe allontanarsi.",
	"func_moveAwayFrom_arg_2": "Il numero massimo di MP da utilizzare. Per impostazione predefinita, nessun limite.",
	"func_moveAwayFrom_return": "Il numero di punti movimento utilizzati.",
	"func_moveAwayFromCell": "Sposta la tua entità di una <b>cella</b> cella di distanza, utilizzando fino a <b>mp</b> punti movimento.",
	"func_moveAwayFromCell_arg_1": "La cella da cui la tua entità dovrebbe allontanarsi.",
	"func_moveAwayFromCell_arg_2": "Il numero massimo di MP da utilizzare. Per impostazione predefinita, nessun limite.",
	"func_moveAwayFromCell_return": "Il numero di punti movimento utilizzati.",
	"func_moveAwayFromCells": "Allontana la tua entità da un insieme di <b>celle</b> celle, utilizzando il massimo di <b>mp</b> punti di movimento.",
	"func_moveAwayFromCells_arg_1": "L'array contenente le celle da cui la tua entità dovrebbe allontanarsi.",
	"func_moveAwayFromCells_arg_2": "Il numero massimo di MP da utilizzare. Per impostazione predefinita, nessun limite.",
	"func_moveAwayFromCells_return": "Il numero di punti movimento utilizzati.",
	"func_moveAwayFromLeeks": "Allontana la tua entità da un insieme di <b>entità</b>, utilizzando il massimo di <b>mp</b> punti movimento.",
	"func_moveAwayFromLeeks_arg_1": "L'array contenente gli ID delle entità da cui la tua entità dovrebbe allontanarsi.",
	"func_moveAwayFromLeeks_arg_2": "Il numero massimo di MP da utilizzare. Per impostazione predefinita, nessun limite.",
	"func_moveAwayFromLeeks_return": "Il numero di punti movimento utilizzati.",
	"func_moveAwayFromEntities": "Allontana la tua entità da un insieme di <b>entità</b>, utilizzando il massimo di <b>mp</b> punti movimento.",
	"func_moveAwayFromEntities_arg_1": "L'array contenente gli ID delle entità da cui la tua entità dovrebbe allontanarsi.",
	"func_moveAwayFromEntities_arg_2": "Il numero massimo di MP da utilizzare. Per impostazione predefinita, nessun limite.",
	"func_moveAwayFromEntities_return": "Il numero di punti movimento utilizzati.",
	"func_moveAwayFromLine": "Sposta la tua entità lontano da una linea definita da due celle <b>cell1</b> e <b>cell2</b>, utilizzando un massimo di <b>mp</b> punti di movimento.",
	"func_moveAwayFromLine_arg_1": "Cella 1.",
	"func_moveAwayFromLine_arg_2": "Cella 2.",
	"func_moveAwayFromLine_arg_3": "Il numero massimo di MP da utilizzare. Per impostazione predefinita, nessun limite.",
	"func_moveAwayFromLine_return": "Il numero di punti movimento utilizzati.",
	"func_moveToward": "Avvicina la tua entità a un'altra <b>entità</b>, utilizzando il massimo di <b>mp</b> punti movimento.",
	"func_moveToward_arg_1": "L'entità a cui dovrebbe rivolgersi la tua entità.",
	"func_moveToward_arg_2": "Il numero massimo di MP da utilizzare.",
	"func_moveToward_return": "Il numero di punti movimento utilizzati.",
	"func_moveTowardCell": "Avvicina la tua entità di una <b>cella</b> cella, utilizzando il massimo di <b>mp</b> punti di movimento.",
	"func_moveTowardCell_arg_1": "La cella a cui dovrebbe avvicinarsi la tua caratteristica.",
	"func_moveTowardCell_arg_2": "Il numero massimo di MP da utilizzare. Per impostazione predefinita, nessun limite.",
	"func_moveTowardCell_return": "Il numero di punti movimento utilizzati.",
	"func_moveTowardCells": "Sposta la tua entità più vicino a un insieme di <b>celle</b> celle, utilizzando il massimo di <b>mp</b> punti di movimento.",
	"func_moveTowardCells_arg_1": "L'array contenente le celle a cui dovrebbe avvicinarsi la tua entità.",
	"func_moveTowardCells_arg_2": "Il numero massimo di MP da utilizzare. Per impostazione predefinita, nessun limite.",
	"func_moveTowardCells_return": "Il numero di punti movimento utilizzati.",
	"func_moveTowardLeeks": "Avvicina la tua entità a un insieme di <b>entità</b>, utilizzando il massimo di <b>mp</b> punti di movimento.",
	"func_moveTowardLeeks_arg_1": "L'array contenente gli ID delle entità a cui dovrebbe accedere la tua entità.",
	"func_moveTowardLeeks_arg_2": "Il numero massimo di MP da utilizzare. Per impostazione predefinita, nessun limite.",
	"func_moveTowardLeeks_return": "Il numero di punti movimento utilizzati.",
	"func_moveTowardEntities": "Avvicina la tua entità a un insieme di <b>entità</b>, utilizzando il massimo di <b>mp</b> punti di movimento.",
	"func_moveTowardEntities_arg_1": "L'array contenente gli ID delle entità a cui la tua entità dovrebbe accedere.",
	"func_moveTowardEntities_arg_2": "Il numero massimo di MP da utilizzare. Per impostazione predefinita, nessun limite.",
	"func_moveTowardEntities_return": "Il numero di punti movimento utilizzati.",
	"func_moveTowardLine": "Avvicina la tua entità a una linea definita da due celle <b>cell1</b> e <b>cell2</b>, utilizzando il massimo dei punti di movimento <b>mp</b>.",
	"func_moveTowardLine_arg_1": "Cella 1.",
	"func_moveTowardLine_arg_2": "Cella 2.",
	"func_moveTowardLine_arg_3": "Il numero massimo di MP da utilizzare. Per impostazione predefinita, nessun limite.",
	"func_moveTowardLine_return": "Il numero di punti movimento utilizzati.",
	"func_number": "Converte un valore in un numero. Se il valore è una stringa, la funzione numerica proverà a convertirlo in un numero, se il valore è già un numero, la funzione restituisce il numero e, per qualsiasi altro tipo, restituisce <i>null</i>.",
	"func_number_arg_1": "Il valore da convertire in un numero.",
	"func_number_return": "Il numero convertito.",
	"func_pause": "Mette in pausa il combattimento, solo per l'allevatore dell'entità che utilizza la funzione.",
	"func_pop": "Rimuove l'ultima cella dall'array <b>array</b> e restituisce l'elemento corrispondente.",
	"func_pop_arg_1": "L'array la cui ultima casella verrà eliminata.",
	"func_pop_return": "L'oggetto che era nella vecchia ultima scatola.",
	"func_pow": "Eleva il numero di base alla potenza dell'esponente.",
	"func_pow_arg_1": "La base.",
	"func_pow_arg_2": "L'espositore.",
	"func_pow_return": "<b>base</b> elevata alla potenza <b>esponente</b>.",
	"func_push": "Aggiunge l'elemento <b>element</b> alla fine dell'<b>array</b>.",
	"func_pushAll": "Accoda tutti gli elementi dell'array <b>elements</b> alla fine dell'<b>array</b>.",
	"func_pushAll_arg_1": "L'array in cui vogliamo aggiungere gli elementi.",
	"func_pushAll_arg_2": "Elementi da aggiungere.",
	"func_push_arg_1": "L'array in cui vogliamo aggiungere l'elemento.",
	"func_push_arg_2": "L'elemento da aggiungere.",
	"func_rand": "Restituisce un numero casuale reale, compreso tra 0 (incluso) e 1 (escluso).",
	"func_randFloat": "Restituisce un numero casuale reale, compreso tra <b>a</b> (incluso) e <b>b</b> (escluso).",
	"func_randFloat_arg_1": "Limite inferiore.",
	"func_randFloat_arg_2": "Limite superiore.",
	"func_randFloat_return": "Un numero casuale nell'intervallo [a; B[.",
	"func_randInt": "Restituisce un numero intero casuale compreso tra <b>a</b> (incluso) e <b>b</b> (escluso).",
	"func_randInt_arg_1": "Limite inferiore.",
	"func_randInt_arg_2": "Limite superiore.",
	"func_randInt_return": "Un numero intero casuale nell'intervallo [a; B[.",
	"func_rand_return": "Un numero casuale nell'intervallo [0; 1[.",
	"func_remove": "Rimuove un elemento dall'array <b>array</b> nella posizione <b>position</b> e restituisce l'elemento eliminato.",
	"func_remove_arg_1": "Array da cui vogliamo eliminare un elemento.",
	"func_remove_arg_2": "Posizione dell'elemento da eliminare.",
	"func_removeElement": "Elimina la prima occorrenza di un <b>elemento</b> nell'<b>array</b>. Se l'elemento non viene trovato, l'array non viene modificato.",
	"func_removeElement_arg_1": "Array da cui vogliamo eliminare un elemento.",
	"func_removeElement_arg_2": "Elemento da trovare e quindi eliminare.",
	"func_removeKey": "Elimina un elemento dall'array <b>array</b> associato alla chiave <b>key</b>.",
	"func_removeKey_arg_1": "Array da cui vogliamo eliminare un elemento.",
	"func_removeKey_arg_2": "La chiave associata all'elemento da eliminare.",
	"func_remove_return": "L'elemento rimosso dalla matrice.",
	"func_replace": "Sostituisce tutte le occorrenze di <b>search</b> con <b>replace</b> nella stringa <b>string</b>.",
	"func_replace_arg_1": "Stringa in cui vengono effettuate le sostituzioni.",
	"func_replace_arg_2": "Sottostringa da sostituire.",
	"func_replace_arg_3": "Corda di ricambio.",
	"func_replace_return": "La stringa del risultato, con sostituzioni.",
	"func_resurrect": "Utilizza il chip CHIP_RESURRECTION per resuscitare un'entità morta con id <b>entity</b>, sulla cella <b>cell</b>.",
	"func_resurrect_arg_1": "L'id dell'entità da far rivivere.",
	"func_resurrect_arg_2": "La cella in cui l'entità verrà rigenerata.",
	"func_resurrect_return": "Il risultato del lancio del chip, tra le costanti USE_*.",
	"func_reverse": "Inverte l'<b>array</b>.",
	"func_reverse_arg_1": "Tavolo da invertire.",
	"func_round": "Restituisce l'arrotondamento di <b>numero</b>.<br/>Puoi trovare l'arrotondamento per difetto con #floor e l'arrotondamento per eccesso con #ceil.",
	"func_round_arg_1": "Numero di cui vogliamo trovare l'arrotondamento.",
	"func_round_return": "Arrotondato per <b>numero</b>.",
	"func_say": "Fai parlare la tua entità.",
	"func_say_arg_1": "Messaggio che la tua entità annuncerà nell'arena.",
	"func_search": "Trova l'elemento <b>element</b> nell'array <b>array</b>, a partire dalla posizione <b>start</b>.",
	"func_search_arg_1": "La tabella di ricerca.",
	"func_search_arg_2": "L'elemento da cercare.",
	"func_search_arg_3": "La posizione dell'inizio della ricerca.",
	"func_search_return": "La posizione della prima occorrenza dell'elemento <b>element</b> nell'<b>array</b>, a partire da <b>start</b>, null se l'elemento non è stato trovato.",
	"func_sendAll": "Invia un messaggio a tutta la tua squadra.",
	"func_sendAll_arg_1": "Il tipo di messaggio da inviare (vedere le costanti MESSAGE_*).",
	"func_sendAll_arg_2": "I parametri del messaggio, che possono essere qualsiasi valore.",
	"func_sendTo": "Invia un messaggio all'entità con id <b>entity</b>.",
	"func_sendTo_arg_1": "L'id dell'entità a cui verrà inviato il messaggio.",
	"func_sendTo_arg_2": "Il tipo di messaggio da inviare (vedere le costanti MESSAGE_*).",
	"func_sendTo_arg_3": "I parametri del messaggio, che possono essere qualsiasi valore.",
	"func_sendTo_return": "<i>true</i> se l'invio è andato a buon fine, <i>false</i> se si è verificato un errore.",
	"func_setRegister": "Memorizza il <b>valore</b> nel registro <b>chiave</b>.\nLa chiave e il valore sono stringhe che devono contenere rispettivamente <i>100</i> e <i>5000</i> caratteri al massimo. Un porro può avere un massimo di <i>100</i> registri, la memorizzazione in un nuovo registro non funzionerà se tutti i registri\nsono già occupati.",
	"func_setRegister_arg_1": "La chiave di registro in cui archiviare il valore.",
	"func_setRegister_arg_2": "Il valore da memorizzare.",
	"func_setRegister_return": "<i>true</i> se l'operazione è andata a buon fine, <i>false</i> in caso contrario.",
	"func_setWeapon": "Equipaggia l'<b>arma</b> dell'arma sulla tua entità.",
	"func_setWeapon_arg_1": "Id dell'arma da equipaggiare.",
	"func_shift": "Rimuove la prima cella dall'<b>array</b> e restituisce l'elemento corrispondente.",
	"func_shift_arg_1": "La matrice la cui prima cella verrà eliminata.",
	"func_shift_return": "L'elemento che era nella vecchia prima cella.",
	"func_show": "Mostra ai giocatori una <b>cella</b> del colore <b>colore</b> sul campo per 1 turno. L'utilizzo di questa funzione costa 1PT.",
	"func_show_arg_1": "La cella da mostrare",
	"func_show_arg_2": "Colore della marcatura",
	"func_show_return": "Restituisce vero se tutto è andato bene",
	"func_shuffle": "Mescola una scacchiera in modo casuale.",
	"func_shuffle_arg_1": "Il tabellone casuale.",
	"func_signum": "Determina il segno del numero <b>number</b>.",
	"func_signum_arg_1": "Il numero di cui verrà determinato il segno.",
	"func_signum_return": "1 se il numero è positivo, 0 se il numero è zero e -1 se il numero è negativo.",
	"func_sin": "Calcola il seno dell'angolo <b>angle</b> passato come parametro",
	"func_sin_arg_1": "L'angolo di cui verrà calcolato il seno",
	"func_sin_return": "Restituisce il seno dell'angolo <b>angle</b>",
	"func_sort": "Ordina l'<b>array</b> in ordine crescente o decrescente. L'ordine crescente predefinito è:\n<ul>\n<li>Tutti booleani (prima falso poi vero)</li>\n<li>Tutti i numeri (ordinati dal più piccolo al più grande)</li>\n<li>Tutti i canali (in ordine alfabetico)</li>\n<li>Tutti gli elementi nulli.</li>\n</ul>",
	"func_sort_arg_1": "Matrice da ordinare.",
	"func_sort_arg_2": "#SORT_ASC per ordinare l'<b>array</b> in ordine crescente o #SORT_DESC per ordinarlo in ordine decrescente.",
	"func_split": "Divide la stringa <b>stringa</b> in sottostringhe delimitate da <b>delimitatore</b>.",
	"func_split_arg_1": "Catena di taglio.",
	"func_split_arg_2": "Stringa che delimita il passaggio da un elemento all'altro.",
	"func_split_arg_3": "Numero massimo di elementi dell'array.",
	"func_split_return": "Matrice contenente le sottostringhe trovate.",
	"func_sqrt": "Calcola la radice quadrata del numero <b>number</b>.",
	"func_sqrt_arg_1": "Numero di cui verrà calcolata la radice.",
	"func_sqrt_return": "Radice quadrata di <b>numero</b>.",
	"func_startsWith": "Controlla se <b>stringa</b> inizia con <b>prefisso</b>.",
	"func_startsWith_arg_1": "Stringa di ricerca.",
	"func_startsWith_arg_2": "Prefisso cercato.",
	"func_startsWith_return": "Vero se la <b>stringa</b> inizia con <b>prefisso</b>.",
	"func_string": "Converte un valore in una stringa.<br>\nSe il valore è già una stringa, viene restituito.<br>\nSe il valore è il numero x, viene restituito \"x\".<br>\nSe il valore è un array, viene restituita una stringa nel formato \"[key1: value1, key2: value2, ...]\".<br>\nSe il valore è un booleano, \"true\" e \"false\" vengono restituiti rispettivamente per true e false.<br>\nSe il valore è null, viene restituito \"null\".",
	"func_string_arg_1": "Il valore da convertire in una stringa di caratteri.",
	"func_string_return": "La stringa convertita.",
	"func_subArray": "Restituisce un sottoarray di <b>array</b> che inizia alla posizione <b>start</b> e termina alla posizione <b>end</b>.",
	"func_subArray_arg_1": "Tabella delle fonti.",
	"func_subArray_arg_2": "Indice di partenza.",
	"func_subArray_arg_3": "Fine indice.",
	"func_subArray_return": "Sottoarray.",
	"func_substring": "Restituisce la sottostringa della stringa <b>stringa</b> a partire dal carattere all'indice <b>inizio</b> e di dimensione <b>lunghezza</b>.",
	"func_substring_arg_1": "Catena di taglio.",
	"func_substring_arg_2": "Indice dei caratteri iniziali.",
	"func_substring_arg_3": "Lunghezza della sottostringa.",
	"func_substring_return": "Sottostringa.",
	"func_sum": "Restituisce la somma di tutti gli elementi numerici nell'array <b>array</b>.",
	"func_sum_arg_1": "Tabella per sommare.",
	"func_summon": "Invoca un'entità determinata dal chip <b>chip</b> sulla cella <b>cell</b> avente per IA la funzione <b>ai</b>.",
	"func_summon_arg_1": "Il chip utilizzato per l'evocazione. Il chip deve essere di tipo Summon e deve essere equipaggiato sull'entità che utilizza la funzione Summon.",
	"func_summon_arg_2": "Dovrebbe apparire la cella o l'evocazione.",
	"func_summon_arg_3": "L'invocazione AI, come funzione.",
	"func_summon_return": "La funzione summon ha lo stesso ritorno della funzione #useChip.",
	"func_sum_return": "Somma degli elementi dell'array.",
	"func_tan": "Calcola la tangente dell'angolo <b>angle</b> (in radianti).",
	"func_tan_arg_1": "Angolo in radianti.",
	"func_tan_return": "Tangente dell'<b>angolo</b>.",
	"function_category_array": "Liste",
	"function_category_chip": "Chip",
	"function_category_color": "Colore",
	"function_category_fight": "Combattimento",
	"function_category_leek": "Porri",
	"function_category_map": "Tabelle",
	"function_category_field": "Terra",
	"function_category_math": "Numeri",
	"function_category_network": "Rete",
	"function_category_string": "Catene",
	"function_category_utils": "Utilità",
	"function_category_weapon": "Armi",
	"func_toDegrees": "Converte l'angolo <b>radianti</b> in gradi.",
	"func_toDegrees_arg_1": "Valore in radianti",
	"func_toDegrees_return": "Angolo in gradi.",
	"func_toLower": "Converte i caratteri maiuscoli in <b>stringa</b> in minuscoli.",
	"func_toLower_arg_1": "Stringa da trasformare",
	"func_toLower_return": "Stringa minuscola",
	"func_toRadians": "Converte l'angolo <b>gradi</b> in radianti.",
	"func_toRadians_arg_1": "Valore in gradi",
	"func_toRadians_return": "Angolo in radianti.",
	"func_toUpper": "Converte i caratteri minuscoli in <b>stringa</b> in maiuscoli.",
	"func_toUpper_arg_1": "Stringa da trasformare.",
	"func_toUpper_return": "Stringa maiuscola.",
	"func_typeOf": "Restituisce il tipo del valore <b>value</b>, tra i tipi: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "Il valore di cui verrà restituito il tipo.",
	"func_typeOf_return": "Il tipo di <b>valore</b>.",
	"func_unshift": "Aggiunge l'elemento <b>element</b> all'inizio dell'<b>array</b>.",
	"func_unshift_arg_1": "Matrice in cui verrà aggiunto l'elemento.",
	"func_unshift_arg_2": "Articolo da aggiungere.",
	"func_useChip": "Usa chip <b>chip</b> sull'entità <b>entity</b>.",
	"func_useChip_arg_1": "Chip da usare.",
	"func_useChip_arg_2": "Entità di destinazione.",
	"func_useChipOnCell": "Usa chip <b>chip</b> sulla cella <b>cell</b>.",
	"func_useChipOnCell_arg_1": "Chip da usare.",
	"func_useChipOnCell_arg_2": "Cella di destinazione.",
	"func_useChipOnCell_return": "Un valore maggiore di 0 se l'attacco è stato lanciato.",
	"func_useChip_return": "I valori di ritorno di useChip sono:<br>\n<ul>\n<li>#USE_CRITICAL, in caso di colpo critico</li>\n<li>#USE_SUCCESS, in caso di successo</li>\n<li>#USE_FAILED, in caso di errore</li>\n<li>#USE_INVALID_TARGET, se la destinazione non esiste</li>\n<li>#USE_NOT_ENOUGH_TP, se la tua entità non ha abbastanza TP</li>\n<li>#USE_INVALID_COOLDOWN, se il chip non è ancora utilizzabile</li>\n<li>#USE_INVALID_POSITION, se la portata è scarsa o la linea di vista non è chiara</li>\n</ul>",
	"func_useWeapon": "Usa l'arma selezionata sull'entità <b>entità</b>.",
	"func_useWeapon_arg_1": "Entità di destinazione.",
	"func_useWeaponOnCell": "Usa l'arma selezionata sulla cella <b>cella</b>.",
	"func_useWeaponOnCell_arg_1": "Cella di destinazione.",
	"func_useWeaponOnCell_return": "Un valore maggiore di 0 se l'attacco è stato lanciato.",
	"func_useWeapon_return": "I valori di ritorno di useWeapon sono:<br>\n<ul>\n<li>#USE_CRITICAL, in caso di colpo critico</li>\n<li>#USE_SUCCESS, in caso di successo</li>\n<li>#USE_FAILED, in caso di errore</li>\n<li>#USE_INVALID_TARGET, se la destinazione non esiste</li>\n<li>#USE_NOT_ENOUGH_TP, se la tua entità non ha abbastanza TP</li>\n<li>#USE_INVALID_POSITION, se la portata è scarsa o la linea di vista non è chiara</li>\n</ul>",
	"func_weaponNeedLos": "Restituisce se l'<b>arma</b> dell'arma necessita di linea di vista per sparare.",
	"func_weaponNeedLos_arg_1": "L'ID dell'arma da testare.",
	"func_weaponNeedLos_return": "<i>true</i> se l'<b>arma</b> arma ha bisogno di una linea di vista per sparare, <i>false</i> altrimenti.",
	"items": "Elementi",
	"max_cores": "max",
	"max_level": "max",
	"min_cores": "Cuori minimi",
	"min_level": "Livello minimo",
	"n_operations": "<b>{0}</b> operazioni",
	"parameters": "Parametri",
	"return": "Ritorno",
	"value": "Valore",
	"variable_operations": "<b>Operazioni variabili</b>",
	"deprecated_function": "Questa funzione è deprecata.",
	"deprecated_constant": "Questa costante è deprecata.",
	"replaced_by": "È sostituito da {0}.",
	"details": "Dettagli",
	"optional": "opzionale",
	"arg_type_9": "set",
	"arg_type_96": "set<intero>",
	"arg_type_10": "intervallo",
	"function_category_set": "Pacchetti",
	"function_category_interval": "Intervalli"
}

	i18n.mergeLocaleMessage("it", { "doc": messages })
	