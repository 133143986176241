
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> операция",
	"arg_type_-1": "?",
	"arg_type_1": "число",
	"arg_type_2": "цепь",
	"arg_type_3": "логический",
	"arg_type_4": "список",
	"arg_type_41": "список<число>",
	"arg_type_42": "список<строка>",
	"arg_type_43": "список<логическое>",
	"arg_type_44": "список<список>",
	"arg_type_46": "список<целое>",
	"arg_type_47": "список<реальный>",
	"arg_type_5": "функция",
	"arg_type_6": "весь",
	"arg_type_7": "настоящий",
	"arg_type_8": "стол",
	"arg_type_806": "таблица <?, целое число>",
	"complexity": "Сложность {c}",
	"operations": "{o} операций",
	"details": "Подробности",
	"optional": "необязательный",
	"deprecated_function": "Эта функция устарела.",
	"deprecated_constant": "Эта константа устарела.",
	"replaced_by": "Он заменен на {0}.",
	"const_AREA_CIRCLE_1": "Круглая площадка диаметром 3 квадрата (крест).",
	"const_AREA_CIRCLE_2": "Круглая площадка диаметром 5 квадратов.",
	"const_AREA_CIRCLE_3": "Круглая площадь 7 квадратов в диаметре.",
	"const_AREA_LASER_LINE": "Зона лазера, линия от минимальной дальности лазера до максимальной дальности или препятствие.",
	"const_AREA_POINT": "Площадь, состоящая из одной коробки.",
	"const_CELL_EMPTY": "Возвращаемое значение getCellContent(cell) для пустой ячейки.",
	"const_CELL_OBSTACLE": "Возвращаемое значение getCellContent(cell) для ячейки, содержащей препятствие.",
	"const_CELL_PLAYER": "Возвращаемое значение getCellContent(cell) для ячейки, содержащей сущность.",
	"const_CELL_ENTITY": "Возвращаемое значение getCellContent(cell) для ячейки, содержащей сущность.",
	"const_COLOR_BLUE": "Цвет синий.",
	"const_COLOR_GREEN": "Зеленого цвета.",
	"const_COLOR_RED": "Красный цвет.",
	"const_E": "Число Эйлера.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Наделяет сущность абсолютным щитом, уменьшая количество очков жизни, удаляемых уроном (EFFECT_DAMAGE), на фиксированную величину. Усиливается сопротивлением.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Снимает абсолютный щит с объекта. Не усиливается характеристикой. Позволяет увеличить очки жизни, удаляемые уроном (EFFECT_DAMAGE), на абсолютную величину.",
	"const_EFFECT_AFTEREFFECT": "Снимает очки жизни с сущности. Усилено наукой. Уменьшает максимальное количество хитов на 5% от количества удаленных хитов.",
	"const_EFFECT_ANTIDOTE": "Удаляет весь яд (EFFECT_POISON), присутствующий на цели.",
	"const_EFFECT_BOOST_MAX_LIFE": "Увеличивает очки жизни и максимальное количество очков жизни сущности. Усиленный мудростью.",
	"const_EFFECT_BUFF_AGILITY": "Дает ловкость сущности. Усилено наукой.",
	"const_EFFECT_BUFF_MP": "Предоставляет очки движения сущности. Усилено наукой.",
	"const_EFFECT_BUFF_RESISTANCE": "Предоставляет сопротивление сущности. Усилено наукой.",
	"const_EFFECT_BUFF_STRENGTH": "Дает силу сущности. Усилено наукой.",
	"const_EFFECT_BUFF_TP": "Предоставляет очки действия сущности. Усилено наукой.",
	"const_EFFECT_BUFF_WISDOM": "Наделите существо мудростью. Усилено наукой.",
	"const_EFFECT_DAMAGE": "Снимает очки жизни с сущности. Сила усилена. Взаимодействует со щитами (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), вампиризмом (кроме заклинателя) и возвратом урона (EFFECT_DAMAGE_RETURN). Уменьшает максимальное количество хитов на 5% от количества удаленных хитов.",
	"const_EFFECT_DAMAGE_RETURN": "Обеспечивает отражение урона сущности, позволяя снимать очки жизни с сущностей, наносящих урон получателю. Усиливается ловкостью. Уменьшает максимальное количество хитов на 5% от количества удаленных хитов.",
	"const_EFFECT_DEBUFF": "Уменьшает значение всех эффектов на сущность на процент.",
	"const_EFFECT_HEAL": "Восстанавливает хиты сущности, ограниченные максимальным количеством хитов. Усиленный мудростью.",
	"const_EFFECT_INVERT": "Меняет положение заклинателя с положением сущности.",
	"const_EFFECT_KILL": "Удаляет все очки жизни с сущности.",
	"const_EFFECT_LIFE_DAMAGE": "Удаляет очки жизни у существа, основываясь на проценте очков жизни заклинателя. Взаимодействует со щитами (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) и возвратом урона (EFFECT_DAMAGE_RETURN). Уменьшает максимальное количество хитов на 5% от количества удаленных хитов.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "Эффект умножается на количество затронутых сущностей в области.",
	"const_EFFECT_MODIFIER_ON_CASTER": "Эффект по-прежнему действует на заклинателя.",
	"const_EFFECT_MODIFIER_STACKABLE": "Эффект накопительный.",
	"const_EFFECT_NOVA_DAMAGE": "Удаляет максимальное количество очков жизни. Усилено наукой.",
	"const_EFFECT_POISON": "Снимает очки жизни с сущности. Усиливается магией. Уменьшает максимальное количество хитов на 10% от количества удаленных хитов.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Наделяет сущность абсолютным щитом, уменьшая количество очков жизни, удаляемых уроном (EFFECT_DAMAGE), на фиксированную величину. Не поддается усилению.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Дает ловкость сущности. Не поддается усилению.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Наделяет сущность магией. Не поддается усилению.",
	"const_EFFECT_RAW_BUFF_MP": "Предоставляет очки движения сущности. Не поддается усилению.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Дарует науку сущности. Не поддается усилению.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Дает силу сущности. Не поддается усилению.",
	"const_EFFECT_RAW_BUFF_TP": "Предоставляет очки действия сущности. Не поддается усилению.",
	"const_EFFECT_RELATIVE_SHIELD": "Обеспечивает относительный щит, уменьшая количество очков жизни, удаляемых уроном (EFFECT_DAMAGE), на относительную величину. Усиливается сопротивлением.",
	"const_EFFECT_RESURRECT": "Воскрешает сущность с максимальным числом HP, равным половине максимального числа HP сущности до воскрешения, и текущим числом HP, равным четверти максимального числа HP до воскрешения.",
	"const_EFFECT_SHACKLE_MAGIC": "Удалить магию из сущности. Усиливается магией.",
	"const_EFFECT_SHACKLE_MP": "Удалить точки движения с сущности. Усиливается магией.",
	"const_EFFECT_SHACKLE_STRENGTH": "Снимает силу с сущности. Усиливается магией.",
	"const_EFFECT_SHACKLE_TP": "Вычтите очки действия из объекта. Усиливается магией.",
	"const_EFFECT_SUMMON": "Призывает лампочку. Не действует, если достигнут предел призыва команды.",
	"const_EFFECT_TARGET_ALLIES": "Действует на союзников.",
	"const_EFFECT_TARGET_CASTER": "Влияет на заклинателя.",
	"const_EFFECT_TARGET_ENEMIES": "Воздействует на врагов.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Влияет на не призванных сущностей (луки и турели).",
	"const_EFFECT_TARGET_SUMMONS": "Влияет на призванных сущностей (Лампочки).",
	"const_EFFECT_TELEPORT": "Изменяет положение заклинателя.",
	"const_EFFECT_VULNERABILITY": "Снимает щит с объекта. Не усиливается характеристикой. Увеличивает количество очков жизни, удаляемых уроном (EFFECT_DAMAGE), на относительную величину.",
	"const_ENTITY_BULB": "Относится к объекту типа Bulb.",
	"const_ENTITY_LEEK": "Относится к объекту типа Leek.",
	"const_ENTITY_TURRET": "Относится к объекту типа турели.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Боевой фон типа вызова.",
	"const_FIGHT_CONTEXT_GARDEN": "Контекст драки в огороде.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Контекст боя в Battle Royale.",
	"const_FIGHT_CONTEXT_TEST": "Тестовый боевой контекст.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Предыстория турнирного боя.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Сражайтесь в Королевской битве.",
	"const_FIGHT_TYPE_FARMER": "Борьба с заводчиками.",
	"const_FIGHT_TYPE_SOLO": "Сольный бой.",
	"const_FIGHT_TYPE_TEAM": "Командный бой.\n",
	"const_INSTRUCTIONS_LIMIT": "Максимальное количество инструкций, которые сущность может использовать в свой ход.",
	"const_MAX_TURNS": "Максимальное количество ходов в бою.",
	"const_OPERATIONS_LIMIT": "Максимальное количество операций, которые сущность может использовать в свой ход.",
	"const_PI": "Отношение длины окружности к ее диаметру.",
	"const_SORT_ASC": "Указывает функции #sort сортировать в порядке возрастания.",
	"const_SORT_DESC": "Указывает функции #sort сортировать в порядке убывания.",
	"const_TYPE_ARRAY": "Тип значения <b>список</b>",
	"const_TYPE_BOOLEAN": "Тип значения <b>логический</b>",
	"const_TYPE_FUNCTION": "Тип значения <b>функция</b>",
	"const_TYPE_NULL": "Тип значения <b>null</b>",
	"const_TYPE_NUMBER": "Тип значения <b>число</b>",
	"const_TYPE_STRING": "Тип значения <b>строка символов</b>",
	"const_USE_CRITICAL": "Значение, возвращаемое функциями #useWeapon, #useWeaponOnCell, #useChip и #useChipOnCell в случае критического попадания.",
	"const_USE_FAILED": "Значение, возвращаемое функциями #useWeapon, #useWeaponOnCell, #useChip и #useChipOnCell в случае сбоя.",
	"const_USE_INVALID_COOLDOWN": "Значение, возвращаемое функциями #useChip и #useChipOnCell, если чип еще не используется.",
	"const_USE_INVALID_POSITION": "Значение, возвращаемое функциями #useWeapon, #useWeaponOnCell, #useChip и #useChipOnCell, если расстояние плохое или линия прямой видимости не ясна.",
	"const_USE_INVALID_TARGET": "Значение, возвращаемое функциями #useWeapon и #useChip, если цель не существует.",
	"const_USE_NOT_ENOUGH_TP": "Значение, возвращаемое функциями #useWeapon, #useWeaponOnCell, #useChip и #useChipOnCell, если у заклинателя недостаточно очков действия для использования объекта.",
	"const_USE_RESURRECT_INVALID_ENTITY": "Значение, возвращаемое функцией #resurrect, когда указанный объект не существует или еще не умер.",
	"const_USE_SUCCESS": "Значение, возвращаемое функциями #useWeapon, #useWeaponOnCell, #useChip и #useChipOnCell в случае успеха.",
	"const_USE_TOO_MANY_SUMMONS": "#summon возвращает ошибку, если у вас уже есть <b>8</b> живых вызовов.\n",
	"func_abs": "Возвращает абсолютное значение числа <b>number</b>.",
	"func_abs_arg_1": "Число, абсолютное значение которого будет вычислено.",
	"func_abs_return": "Абсолютное значение числа.",
	"func_acos": "Вычисляет арккосинус <b>аргумента</b> в интервале [0, #PI].",
	"func_acos_arg_1": "Число, арккосинус которого будет вычисляться.",
	"func_acos_return": "Арккосинус <b>аргумента</b>.",
	"func_arrayConcat": "Добавьте два массива встык. Текстовые клавиши сохраняются, а все цифровые клавиши переиндексируются.",
	"func_arrayConcat_arg_1": "Первый стол.",
	"func_arrayConcat_arg_2": "Второй стол.",
	"func_arrayConcat_return": "Сумма двух массивов.",
	"func_arrayFilter": "Возвращает новый массив, содержащий все пары ключ/значение из исходного массива, для которых функция обратного вызова вернула значение true. Если callback-функция принимает один параметр, то будет отправлено значение исходного массива, если два параметра, то будет отправлен ключ и значение.",
	"func_arrayFilter_arg_1": "Оригинальный стол.",
	"func_arrayFilter_arg_2": "Функция вызывается для каждого элемента.",
	"func_arrayFilter_return": "Новый стол.",
	"func_arrayFlatten": "Возвращает новый массив, содержащий все элементы исходного массива. Все элементы, содержащиеся в подмассиве, извлекаются в новый массив.Аргумент depth определяет максимальную глубину извлечения подмассива.\n<code>var array = [1, 2, [[3, 4], 5], [6]];\nотладка (массивFlatten (массив, 1)); // [1,2,[3,4],5,6]</code>",
	"func_arrayFlatten_arg_1": "Оригинальный стол.",
	"func_arrayFlatten_arg_2": "Максимальная глубина.",
	"func_arrayFlatten_return": "Новый стол.",
	"func_arrayFoldLeft": "Сократите массив <b>array</b> [v1, v2, ..., vn] слева, начиная со значения <b>v0</b> и применяя функцию <b>f</b>. Равно :\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "Оригинальный стол.",
	"func_arrayFoldLeft_arg_2": "Функция для применения.",
	"func_arrayFoldLeft_arg_3": "Начальное значение.",
	"func_arrayFoldLeft_return": "Уменьшенный стол.",
	"func_arrayFoldRight": "Сократите массив <b>array</b> [v1, v2, ..., vn] справа, начиная со значения <b>v0</b> и применяя функцию <b>f</b>. Равно :\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "Оригинальный стол.",
	"func_arrayFoldRight_arg_2": "Функция для применения.",
	"func_arrayFoldRight_arg_3": "Начальное значение.",
	"func_arrayFoldRight_return": "Уменьшенный стол.",
	"func_arrayIter": "Вызывает функцию обратного вызова для каждого элемента массива. Если callback-функция принимает один параметр, то будет отправлено значение исходного массива, если два параметра, то будет отправлен ключ и значение.",
	"func_arrayIter_arg_1": "Оригинальный стол.",
	"func_arrayIter_arg_2": "Функция вызывается для каждого элемента.",
	"func_arrayMap": "Возвращает новый массив, содержащий для каждого ключа исходного массива значение, возвращенное функцией обратного вызова. Если callback-функция принимает один параметр, то будет отправлено значение исходного массива, если два параметра, то будет отправлен ключ и значение.",
	"func_arrayMap_arg_1": "Оригинальный стол.",
	"func_arrayMap_arg_2": "Функция вызывается для каждого элемента.",
	"func_arrayMap_return": "Новый стол.",
	"func_arrayMax": "Возвращает элемент максимального значения массива <b>array</b>.<br/>Дополнительную информацию о максимальном значении массива см. в разделе #sort.",
	"func_arrayMax_arg_1": "Массив, в котором будет искаться максимальное значение.",
	"func_arrayMax_return": "Предмет максимальной стоимости.",
	"func_arrayMin": "Возвращает элемент минимального значения массива <b>array</b>.<br/>Дополнительную информацию о минимальном значении массива см. в разделе #sort.",
	"func_arrayMin_arg_1": "Массив, в котором будет искаться минимальное значение.",
	"func_arrayMin_return": "Элемент минимальной стоимости.",
	"func_arrayPartition": "Возвращает новый массив, содержащий два списка, в первом находятся все пары ключ/значение, для которых функция обратного вызова вернула true, во втором — все остальные. Если callback-функция принимает один параметр, то будет отправлено значение исходного массива, если два параметра, то будет отправлен ключ и значение.",
	"func_arrayPartition_arg_1": "Оригинальный стол.",
	"func_arrayPartition_arg_2": "Функция вызывается для каждого элемента.",
	"func_arrayPartition_return": "Новый стол.",
	"func_arraySort": "Сортирует массив в соответствии с порядком, заданным функцией обратного вызова. Элементы сравниваются два на два, функция обратного вызова должна возвращать значения -1, 0 или 1 в зависимости от того, находится ли первое значение до, на том же уровне или после второго значения. Если функция обратного вызова принимает 2 параметра, отправляются два значения, если принимает 4, отправляются пары ключ/значение.",
	"func_arraySort_arg_1": "Исходная таблица",
	"func_arraySort_arg_2": "Функция сортировки.",
	"func_arraySort_return": "Отсортированный массив",
	"func_asin": "Вычисляет арксинус <b>аргумента</b> в интервале [0, #PI].",
	"func_asin_arg_1": "Число, арксинус которого будет вычисляться.",
	"func_asin_return": "Арксинус <b>аргумента</b>.",
	"func_assocSort": "Сортирует массив <b>массив</b>, сохраняя связь ключ:значение в соответствии с порядком <b>порядок</b>.",
	"func_assocSort_arg_1": "Массив для сортировки.",
	"func_assocSort_arg_2": "Порядок сортировки: #SORT_ASC или #SORT_DESC.",
	"func_atan": "Вычисляет арктангенс <b>аргумента</b> в интервале [0, #PI].",
	"func_atan2": "Преобразует декартовы координаты (<b>x</b>, <b>y</b>) в полярные координаты (<b>r</b>, <b>тета</b>). Эта функция возвращает угол <b>тета</b> между -#PI и #PI, используя знаки аргументов.",
	"func_atan2_arg_1": "Y-координата.",
	"func_atan2_arg_2": "Х координата.",
	"func_atan2_return": "Угол <b>тета</b> в полярных координатах точки (x, y).",
	"func_atan_arg_1": "Число, арктангенс которого будет вычисляться.",
	"func_atan_return": "Арктангенс <b>аргумента</b>.",
	"func_average": "Вычисляет среднее значение элементов, содержащихся в массиве <b>array</b>.",
	"func_average_arg_1": "Массив, среднее значение которого мы хотим вычислить.",
	"func_average_return": "Средняя стоимость.",
	"func_canUseChip": "Определяет, может ли ваша сущность использовать чип <b>chip</b> для сущности с идентификатором <b>entity</b>.",
	"func_canUseChip_arg_1": "Номер тестируемого чипа.",
	"func_canUseChip_arg_2": "Идентификатор объекта, на котором вы хотите использовать чип.",
	"func_canUseChip_return": "<i>true</i>, если ваша организация может использовать чип, <i>false</i> в противном случае.",
	"func_canUseChipOnCell": "Определяет, может ли ваша сущность использовать чип <b>chip</b> в ячейке <b>cell</b>.",
	"func_canUseChipOnCell_arg_1": "Номер тестируемого чипа.",
	"func_canUseChipOnCell_arg_2": "Номер ячейки, на которой вы хотите использовать чип.",
	"func_canUseChipOnCell_return": "<i>true</i>, если ваша организация может использовать чип, <i>false</i> в противном случае.",
	"func_canUseWeapon": "Определяет, может ли ваша сущность стрелять в сущность с идентификатором <b>entity</b> из оружия <b>weapon</b>.",
	"func_canUseWeapon_arg_1": "Оружие для испытаний. По умолчанию используется ваше текущее экипированное оружие.",
	"func_canUseWeapon_arg_2": "Идентификатор объекта, по которому вы хотите стрелять.",
	"func_canUseWeapon_return": "<i>true</i>, если ваша сущность может стрелять, <i>false</i> в противном случае.",
	"func_canUseWeaponOnCell": "Определяет, может ли ваша сущность стрелять в клетку <b>cell</b> из оружия <b>weapon</b>.",
	"func_canUseWeaponOnCell_arg_1": "Оружие для испытаний. По умолчанию используется ваше текущее экипированное оружие.",
	"func_canUseWeaponOnCell_arg_2": "Номер ячейки, в которую вы хотите стрелять.",
	"func_canUseWeaponOnCell_return": "<i>true</i>, если ваша сущность может стрелять, <i>false</i> в противном случае.",
	"func_cbrt": "Вычислить кубический корень из <b>числа</b>",
	"func_cbrt_arg_1": "Число, кубический корень которого мы хотим вычислить.",
	"func_cbrt_return": "Кубический корень из <b>числа</b>.",
	"func_ceil": "Возвращает округление <b>number</b> в большую сторону.<br/>Вы можете найти округление в меньшую сторону с помощью #floor и округление с помощью #round.",
	"func_ceil_arg_1": "Число, для которого мы хотим найти округление вверх.",
	"func_ceil_return": "Округлите <b>число</b> вверх.",
	"func_charAt": "Возвращает символ строки <b>string</b>, расположенный в позиции <b>position</b>.<br/>Первый символ строки находится в позиции 0.",
	"func_charAt_arg_1": "Строка, из которой мы хотим получить символ.",
	"func_charAt_arg_2": "Положение персонажа найти.",
	"func_charAt_return": "строка, содержащая искомый символ, или пустая строка, если позиция недействительна.",
	"func_chipNeedLos": "Возвращает информацию о том, нужна ли <b>чипу</b> прямая видимость для использования.",
	"func_chipNeedLos_arg_1": "Идентификатор тестируемого чипа.",
	"func_chipNeedLos_return": "<i>true</i>, если для <b>микросхемы</b> требуется прямая видимость, <i>false</i> в противном случае.",
	"func_clearMarks": "Удаляет все отметки, сделанные функциями mark() и markText() в поле.",
	"func_clone": "Копирует <b>значение</b>, переданное в качестве параметра на уровнях <b>level</b>, и возвращает копию. Например, в случае массива клон (массив, 1) скопирует массив, но не его элементы, клон (массив, 2) скопирует массив, а также все элементы.",
	"func_clone_arg_1": "Значение для клонирования",
	"func_clone_arg_2": "Количество уровней для клонирования",
	"func_clone_return": "Клонированное значение",
	"func_contains": "Определяет, находится ли строка <b>search</b> внутри строки <b>string</b>.",
	"func_contains_arg_1": "Строка, в которой будет производиться поиск.",
	"func_contains_arg_2": "Строка для поиска.",
	"func_contains_return": "true, если <b>поиск</b> находится в <b>строке</b>, иначе false.",
	"func_cos": "Вычисляет косинус <b>угла</b>.",
	"func_cos_arg_1": "Число, косинус которого будет вычисляться (в радианах).",
	"func_cos_return": "Косинус <b>угла</b> в диапазоне [-1, 1].",
	"func_count": "Подсчитывает количество элементов в массиве <b>array</b>.",
	"func_count_arg_1": "Массив, количество элементов которого будет подсчитано.",
	"func_count_return": "Количество элементов массива.",
	"func_debug": "Записывает сообщение <b>object</b> в личный журнал, доступное в отчете по окончании боя.",
	"func_debug_arg_1": "Сообщение для записи.",
	"func_debugC": "Записывает в личный журнал <b>объектное</b> сообщение, доступное в отчете по окончании боя, цвета <b>color</b>.",
	"func_debugC_arg_1": "Сообщение для записи.",
	"func_debugC_arg_2": "Цвет сообщения. Вы можете использовать функцию #getColor.",
	"func_debugE": "Записывает сообщение об ошибке <b>объекта</b> в личный журнал, доступный в отчете по окончании боя.<br />Сообщения об ошибках отображаются в отчете о бое красным цветом.",
	"func_debugE_arg_1": "Сообщение об ошибке для регистрации.",
	"func_debugW": "Записывает предупреждающее сообщение об <b>объекте</b> в личном журнале, доступном в отчете по окончании боя. <br />Предупреждающие сообщения отображаются в боевом отчете оранжевым цветом.",
	"func_debugW_arg_1": "Предупреждающее сообщение для записи.",
	"func_deleteRegister": "Удаляет реестр, связанный с ключом <b>key</b>, если он существует.",
	"func_deleteRegister_arg_1": "Раздел реестра для удаления.",
	"func_endsWith": "Определяет, заканчивается ли <b>строка</b> строкой <b>суффикса</b>.",
	"func_endsWith_arg_1": "Строка, в которой будет производиться поиск",
	"func_endsWith_arg_2": "Суффикс для поиска",
	"func_endsWith_return": "true, если <b>строка</b> заканчивается <b>суффиксом</b>",
	"func_exp": "Возводит число Эйлера #E в степень <b>числа</b>.",
	"func_exp_arg_1": "Показатель степени, до которого будет возведено значение #E.",
	"func_exp_return": "#E** <b>номер</b>.",
	"func_fill": "Изменяет размер массива <b>массив</b> до <b>размера</b> и заполняет все его поля <b>значением</b>.",
	"func_fill_arg_1": "Таблица для заполнения.",
	"func_fill_arg_2": "Значение для каждого квадрата в массиве.",
	"func_fill_arg_3": "Количество ящиков. По умолчанию текущий размер массива.",
	"func_floor": "Вычисляет округление <b>number</b> в меньшую сторону.<br/>Вы можете найти округление в большую сторону с помощью #ceil и округление с помощью #round.",
	"func_floor_arg_1": "Число, округление которого в меньшую сторону мы хотим найти.",
	"func_floor_return": "Округлено до <b>числа</b>.",
	"func_getAbsoluteShield": "Возвращает абсолютный щит объекта с идентификатором <b>entity</b>. Чтобы напрямую получить абсолютный щит вашего объекта, используйте #getAbsoluteShield() без параметров.",
	"func_getAbsoluteShield_arg_1": "Идентификатор объекта, чей абсолютный щит будет возвращен.",
	"func_getAbsoluteShield_return": "Абсолютный щит объекта <b>entity</b>.",
	"func_getAgility": "Возвращает маневренность объекта с идентификатором <b>entity</b>. Чтобы напрямую получить гибкость вашей сущности, используйте #getAgility() без параметров.",
	"func_getAgility_arg_1": "Идентификатор объекта, ловкость которого будет возвращена.",
	"func_getAgility_return": "Гибкость объекта <b>entity</b>.",
	"func_getAIID": "Возвращает идентификатор AI объекта <b>entity</b>. Используйте getAIID без параметров, чтобы получить идентификатор вашего ИИ.",
	"func_getAIID_arg_1": "Идентификатор объекта, чей идентификатор AI будет возвращен.",
	"func_getAIID_return": "Идентификатор искусственного интеллекта объекта <b>entity</b>.",
	"func_getAIName": "Возвращает имя объекта AI <b>entity</b>. Используйте getAINam() без параметров, чтобы получить имя вашего ИИ.",
	"func_getAIName_arg_1": "Идентификатор объекта, чье имя AI будет возвращено.",
	"func_getAIName_return": "Имя объекта AI <b>entity</b>.",
	"func_getAliveAllies": "Возвращает массив всех ваших союзников, живущих в бою.",
	"func_getAliveAllies_return": "Массив, содержащий идентификаторы всех ваших живых союзников.",
	"func_getAliveEnemies": "Возвращает массив всех ваших врагов, живущих в бою.",
	"func_getAliveEnemiesCount": "Возвращает количество врагов, оставшихся в живых в бою.",
	"func_getAliveEnemiesCount_return": "Количество живых врагов.",
	"func_getAliveEnemies_return": "Массив, содержащий идентификаторы всех ваших живых врагов.",
	"func_getAllChips": "Возвращает список всех фишек в игре.",
	"func_getAllChips_return": "Список всех фишек в игре.",
	"func_getAllEffects": "Возвращает список всех эффектов в игре.",
	"func_getAllEffects_return": "Список всех эффектов в игре.",
	"func_getAllWeapons": "Возвращает список всего оружия в игре.",
	"func_getAllWeapons_return": "Список всего оружия в игре.",
	"func_getAlliedTurret": "Возвращает идентификатор башни вашей команды или -1, если она не существует.",
	"func_getAlliedTurret_return": "ID башни вашей команды.",
	"func_getEnemyTurret": "Возвращает идентификатор вражеской башни или -1, если она не существует.",
	"func_getEnemyTurret_return": "ID вражеской башни.",
	"func_getAllies": "Возвращает массив, содержащий ваших союзников и вашу сущность.",
	"func_getAlliesCount": "Возвращает количество союзников в бою.",
	"func_getAlliesCount_return": "Количество союзников.",
	"func_getAlliesLife": "Возвращает общее количество здоровья ваших союзников.",
	"func_getAlliesLife_return": "Общее здоровье ваших союзников.",
	"func_getAllies_return": "Таблица союзников и вашей сущности.",
	"func_getBirthTurn": "Возвращает ход боя, в котором появилась <b>сущность</b>. Возвращает 1, если это, например, лук-порей, и 5, если это призыв, вызванный на 5-м ходу.",
	"func_getBirthTurn_arg_1": "Идентификатор объекта, чей ход появления будет возвращен.",
	"func_getBirthTurn_return": "Боевой ход, в котором появилась <b>сущность</b>.",
	"func_getBlue": "Возвращает количество синего в цвете <b>color</b> в диапазоне от 0 до 255. Например, getBlue(#COLOR_BLUE) = 255 и getBlue(#COLOR_GREEN) = 0.",
	"func_getBlue_arg_1": "Цвет, скорость синего которого будет возвращена.",
	"func_getBlue_return": "Доля синего цвета в цвете <b>color</b>",
	"func_getBulbChips": "Возвращает список чипов, которые будут установлены на лампочке, вызванной чипом <b>bulb_chip</b>.",
	"func_getBulbChips_arg_1": "Луковичная блоха.",
	"func_getBulbChips_return": "Чипы лампочки, вызванные чипом <b>bulb_chip</b>.",
	"func_getCell": "Возвращает ячейку, в которой находится объект с идентификатором <b>entity</b>. Используйте getCell() без параметров, чтобы получить вашу ячейку.",
	"func_getCell_arg_1": "Идентификатор объекта, ячейка которого будет возвращена.",
	"func_getCell_return": "Номер ячейки, в которой находится <b>объект</b>.",
	"func_getCellContent": "Возвращает содержимое ячейки с идентификатором <b>cell</b>.",
	"func_getCellContent_arg_1": "Идентификатор ячейки, содержимое которой будет возвращено.",
	"func_getCellContent_return": "Содержимое ячейки <b>cell</b>: #CELL_EMPTY для пустой ячейки, #CELL_ENTITY для объекта, #CELL_OBSTACLE для препятствия.",
	"func_getCellDistance": "Возвращает расстояние между двумя ячейками <b>cell1</b> и <b>cell2</b>. <br />Возвращенное расстояние выражается в количестве ячеек и не учитывает различные препятствия между двумя ячейками.\nЧтобы получить расстояние по прямой, см. #getDistance, а чтобы получить расстояние пути между двумя ячейками, минуя препятствия, см. #getPathLength.",
	"func_getCellDistance_arg_1": "Идентификатор начальной ячейки.",
	"func_getCellDistance_arg_2": "Идентификатор ячейки прибытия.",
	"func_getCellDistance_return": "Расстояние между двумя ячейками <b>cell1</b> и <b>cell2</b>.",
	"func_getCellFromXY": "Возвращает идентификатор ячейки в позиции (<b>x</b>, <b>y</b>).",
	"func_getCellFromXY_arg_1": "X-позиция ячейки.",
	"func_getCellFromXY_arg_2": "Y-позиция ячейки.",
	"func_getCellFromXY_return": "Идентификатор ячейки в позиции (<b>x</b>, <b>y</b>), <b>null</b>, если ячейка не существует.",
	"func_getCellsToUseChip": "Возвращает список ячеек, из которых ваша сущность сможет использовать чип <b>chip</b> в сущности <b>entity</b>.",
	"func_getCellsToUseChip_arg_1": "Чип, который сущность хочет иметь возможность использовать.",
	"func_getCellsToUseChip_arg_2": "Целевой объект.",
	"func_getCellsToUseChip_arg_3": "Массив ячеек для игнорирования.",
	"func_getCellsToUseChip_return": "Список ячеек, где можно использовать чип.",
	"func_getCellsToUseChipOnCell": "Возвращает список ячеек, из которых ваша сущность сможет использовать чип <b>chip</b> в ячейке <b>cell</b>.",
	"func_getCellsToUseChipOnCell_arg_1": "Чип, который сущность хочет иметь возможность использовать.",
	"func_getCellsToUseChipOnCell_arg_2": "Целевая ячейка.",
	"func_getCellsToUseChipOnCell_arg_3": "Массив ячеек для игнорирования.",
	"func_getCellsToUseChipOnCell_return": "Список ячеек, где можно использовать чип.",
	"func_getCellsToUseWeapon": "Возвращает список ячеек, из которых ваша сущность сможет использовать оружие <b>weapon</b> на сущности <b>entity</b>.",
	"func_getCellsToUseWeapon_arg_1": "Оружие для испытаний. По умолчанию используется ваше текущее экипированное оружие.",
	"func_getCellsToUseWeapon_arg_2": "Целевой объект.",
	"func_getCellsToUseWeapon_arg_3": "Массив ячеек для игнорирования. По умолчанию пустой массив.",
	"func_getCellsToUseWeapon_return": "Список ячеек, из которых можно использовать оружие.",
	"func_getCellsToUseWeaponOnCell": "Возвращает список ячеек, из которых ваша сущность сможет использовать оружие <b>weapon</b> в ячейке <b>cell</b>.",
	"func_getCellsToUseWeaponOnCell_arg_1": "Оружие для испытаний. По умолчанию используется ваше текущее экипированное оружие.",
	"func_getCellsToUseWeaponOnCell_arg_2": "Целевая ячейка.",
	"func_getCellsToUseWeaponOnCell_arg_3": "Массив ячеек для игнорирования. По умолчанию пустой массив.",
	"func_getCellsToUseWeaponOnCell_return": "Список ячеек, из которых можно использовать оружие.",
	"func_getCellX": "Определяет положение по оси X ячейки <b>cell</b>.",
	"func_getCellX_arg_1": "Ячейка, положение которой в X будет определено.",
	"func_getCellX_return": "X-позиция ячейки.",
	"func_getCellY": "Определяет положение Y ячейки <b>cell</b>.",
	"func_getCellY_arg_1": "Ячейка, положение которой в Y будет определено.",
	"func_getCellY_return": "Положение Y ячейки.",
	"func_getChipArea": "Возвращает тип области эффекта <b>чипа</b>.",
	"func_getChipArea_arg_1": "Чип, тип зоны которого будет возвращен.",
	"func_getChipArea_return": "Тип области чипа <b>chip</b> среди констант AREA_*:\n<ул>\n<li>#AREA_POINT: один квадрат.</li>\n<li>#AREA_LASER_LINE: линия лазера</li>\n<li>#AREA_CIRCLE_1: круглая область диаметром 3 квадрата.</li>\n<li>#AREA_CIRCLE_2: круглая область диаметром 5 квадратов.</li>\n<li>#AREA_CIRCLE_3: круглая область диаметром 7 квадратов.</li>\n</ul>",
	"func_getChipCooldown": "Возвращает время восстановления чипа <b>chip</b>, взятое с рынка.",
	"func_getChipCooldown_arg_1": "Чип, время перезарядки которого будет возвращено.",
	"func_getChipCooldown_return": "Перезарядка <b>чипа</b>.",
	"func_getChipCost": "Возвращает стоимость чипа <b>chip</b> в TP.",
	"func_getChipCost_arg_1": "Чип стоимость которого будет возвращена.",
	"func_getChipCost_return": "Стоимость <b>чипа</b>.",
	"func_getChipEffectiveArea": "Возвращает список ячеек, которые будут затронуты, если чип <b>chip</b> используется в ячейке <b>cell</b> из ячейки <b>from</b>.",
	"func_getChipEffectiveArea_arg_1": "Чип для тестирования.",
	"func_getChipEffectiveArea_arg_2": "Целевая ячейка.",
	"func_getChipEffectiveArea_arg_3": "Ячейка, из которой используется чип.",
	"func_getChipEffectiveArea_return": "Массив, содержащий идентификаторы всех ячеек, которые будут затронуты.",
	"func_getChipEffects": "Возвращает эффекты чипа <b>chip</b>.",
	"func_getChipEffects_arg_1": "Фишка, эффекты которой будут возвращены.",
	"func_getChipEffects_return": "Эффекты <b>чипа</b> чипа. То же возвращаемое значение, что и для функции #getWeaponEffects.",
	"func_getChipFailure": "Возвращает процент риска отказа чипа <b>chip</b>.",
	"func_getChipFailure_arg_1": "Чип, процент отказов которого будет возвращен.",
	"func_getChipFailure_return": "<b>chip</b> процент отказа чипа, целое число от <b>0</b> до <b>100</b>.",
	"func_getChipMaxRange": "Возвращает максимальный диапазон чипа <b>chip</b>.",
	"func_getChipMaxRange_arg_1": "Чип, максимальный диапазон которого будет возвращен.",
	"func_getChipMaxRange_return": "Максимальная дальность действия <b>чипа</b>.",
	"func_getChipMaxScope": "Возвращает максимальный диапазон чипа <b>chip</b>.",
	"func_getChipMaxScope_arg_1": "Чип, максимальный диапазон которого будет возвращен.",
	"func_getChipMaxScope_return": "Максимальная дальность действия <b>чипа</b>.",
	"func_getChipMinRange": "Возвращает минимальный диапазон чипа <b>chip</b>.",
	"func_getChipMinRange_arg_1": "Чип, минимальный диапазон которого будет возвращен.",
	"func_getChipMinRange_return": "Минимальный диапазон <b>чипа</b>.",
	"func_getChipMinScope": "Возвращает минимальный диапазон чипа <b>chip</b>.",
	"func_getChipMinScope_arg_1": "Чип, минимальный диапазон которого будет возвращен.",
	"func_getChipMinScope_return": "Минимальный диапазон <b>чипа</b>.",
	"func_getChipLaunchType": "Возвращает режим запуска чипа <b>chip</b> среди констант LAUNCH_TYPE_*.",
	"func_getChipLaunchType_arg_1": "Идентификатор чипа, режим метания которого будет возвращен.",
	"func_getChipLaunchType_return": "Режим метания чипа <b>chip</b>.",
	"func_getChipName": "Возвращает имя чипа <b>chip</b>.",
	"func_getChipName_arg_1": "Чип, имя которого будет возвращено.",
	"func_getChipName_return": "Имя чипа <b>chip</b>.",
	"func_getChips": "Возвращает маркеры объекта с идентификатором <b>entity</b>.",
	"func_getChips_arg_1": "Идентификатор объекта, чьи пули будут возвращены.",
	"func_getChips_return": "Массив, содержащий идентификаторы маркеров объекта <b>entity</b>.",
	"func_getChipTargets": "Возвращает объекты, которые будут затронуты, если чип <b>chip</b> используется в ячейке <b>cell</b>.",
	"func_getChipTargets_arg_1": "Чип для тестирования.",
	"func_getChipTargets_arg_2": "Целевая ячейка.",
	"func_getChipTargets_return": "Массив, содержащий идентификаторы всех объектов, которые будут затронуты.",
	"func_getColor": "Возвращает целое число, соответствующее цвету (<b>красный</b>, <b>зеленый</b>, <b>синий</b>), указанному в качестве параметров.",
	"func_getColor_arg_1": "Красное значение от 0 до 255.",
	"func_getColor_arg_2": "Зеленое значение от 0 до 255.",
	"func_getColor_arg_3": "Значение синего от 0 до 255.",
	"func_getColor_return": "int, соответствующий цвету, указанному в качестве параметра.",
	"func_getCooldown": "Возвращает текущее время восстановления <b>chip</b> объекта <b>entity</b>.",
	"func_getCooldown_arg_1": "Чип, текущее время восстановления которого будет возвращено.",
	"func_getCooldown_arg_2": "Сущность, время восстановления которой будет возвращено.",
	"func_getCooldown_return": "Текущее время восстановления чипа <b>chip</b>, это количество ходов, прежде чем чип станет пригодным для использования, <b>0</b>, если он в настоящее время может быть использован.",
	"func_getCores": "Возвращает количество ядер объекта с идентификатором <b>entity</b>.",
	"func_getCores_arg_1": "Сущность, число ядер которой будет возвращено.",
	"func_getCores_return": "Количество ядер объекта <b>entity</b>.",
	"func_getDate": "Возвращает дату боя в формате дд/мм/гггг.",
	"func_getDate_return": "Дата боя.",
	"func_getTime": "Возвращает время боя в формате ЧЧ:мм:сс.",
	"func_getTime_return": "Время боя.",
	"func_getTimestamp": "Возвращает временную метку боя, равную количеству секунд с 1 января 1970 года.",
	"func_getTimestamp_return": "Отметка времени боя.",
	"func_getDamageReturn": "Возвращает коэффициент возврата урона объекта с идентификатором <b>entity</b>.",
	"func_getDamageReturn_arg_1": "Идентификатор объекта, от которого будет возвращена ссылка на повреждение.",
	"func_getDamageReturn_return": "Коэффициент возврата повреждений объекта с идентификатором <b>entity</b> (в %).",
	"func_getDeadAllies": "Возвращает мертвых дружественных существ.",
	"func_getDeadAllies_return": "Таблица союзных мертвых сущностей.",
	"func_getDeadEnemies": "Возвращает мертвых вражеских существ.",
	"func_getDeadEnemiesCount": "Возвращает количество врагов, погибших в бою.",
	"func_getDeadEnemiesCount_return": "Количество убитых врагов.",
	"func_getDeadEnemies_return": "Таблица мертвых вражеских сущностей.",
	"func_getDistance": "Вычисляет расстояние по прямой между двумя ячейками <b>cell1</b> и <b>cell2</b>. <br />Чтобы получить расстояние в количестве ячеек, см. #getCellDistance, а чтобы получить длину пути между двумя ячейками, уклоняясь от различных препятствий, см. #getPathLength.",
	"func_getDistance_arg_1": "Стартовая ячейка.",
	"func_getDistance_arg_2": "Ячейка прибытия.",
	"func_getDistance_return": "Расстояние по прямой между двумя ячейками.",
	"func_getPassiveEffects": "Возвращает список пассивных эффектов сущности с идентификатором <b>entity</b>. Чтобы напрямую получить список пассивных эффектов вашего объекта, используйте #getPassiveEffects() без параметра.",
	"func_getPassiveEffects_arg_1": "Идентификатор объекта, чей список пассивных эффектов будет возвращен.",
	"func_getPassiveEffects_return": "Список пассивных эффектов, присутствующих в настоящее время на объекте <b>entity</b>.\nСписок пассивных эффектов представляет собой массив, содержащий эффекты.\nЭффект сам по себе представляет собой массив из 7 полей вида: [<b>тип</b>, <b>значение</b>, <b>caster_id</b>, <b>повороты</b>, <b>критическое</b>, <b>идентификатор_элемента</b>, <b>идентификатор_цели</b>]. Это та же структура, что и у классических эффектов, возвращаемых функцией #getEffects.",
	"func_getEnemies": "Возвращает вражеские существа (живые или мертвые) в бой.",
	"func_getEnemiesCount": "Возвращает количество врагов в бою.",
	"func_getEnemiesCount_return": "Количество врагов.",
	"func_getEnemiesLife": "Вычисляет сумму очков жизни всех вражеских сущностей.",
	"func_getEnemiesLife_return": "Сумма очков жизни вражеской команды.",
	"func_getEnemies_return": "Массив, содержащий идентификаторы всех вражеских сущностей.",
	"func_getEntityTurnOrder": "Возвращает значение от 1 до n (количество объектов, находящихся в данный момент в игре), указывающее позицию <b>объекта</b> в порядке воспроизведения.",
	"func_getEntityTurnOrder_arg_1": "Идентификатор объекта, чей порядок воспроизведения будет возвращен",
	"func_getEntityTurnOrder_return": "Расположите в порядке игры сущность <b>entity</b>",
	"func_getFarmerID": "Возвращает идентификатор производителя объекта <b>entity</b>.",
	"func_getFarmerID_arg_1": "Идентификатор объекта, чей идентификатор заводчика будет возвращен.",
	"func_getFarmerID_return": "Идентификатор порождающей сущности <b>entity</b>.",
	"func_getFarmerCountry": "Возвращает страну производителя объекта <b>entity</b>.",
	"func_getFarmerCountry_arg_1": "Идентификатор объекта, чья страна заводчика будет возвращена.",
	"func_getFarmerCountry_return": "Страна родителя <b>объекта</b>, или \"?\" если не указано.",
	"func_getFarmerName": "Возвращает имя порождающего объекта <b>entity</b>.",
	"func_getFarmerName_arg_1": "Идентификатор объекта, чье имя производителя будет возвращено.",
	"func_getFarmerName_return": "Имя создателя объекта <b>entity</b>.",
	"func_getFarthestAlly": "Определяет самого дальнего союзника вашей сущности по прямой.",
	"func_getFarthestAlly_return": "Идентификатор самого дальнего дружественного объекта.",
	"func_getFarthestEnemy": "Определяет самого дальнего врага от вашей сущности по прямой.",
	"func_getFarthestEnemy_return": "Идентификатор самой дальней вражеской сущности.",
	"func_getFightID": "Возвращает текущий идентификатор боя.",
	"func_getFightID_return": "Идентификатор текущего боя.",
	"func_getFightContext": "Возвращает контекст текущего боя.",
	"func_getFightContext_return": "В зависимости от контекста битвы: тестовая битва (#FIGHT_CONTEXT_TEST), битва на арене (#FIGHT_CONTEXT_GARDEN), турнирная битва (#FIGHT_CONTEXT_TOURNAMENT), битва-вызов (#FIGHT_CONTEXT_CHALLENGE), королевская битва (#FIGHT_CONTEXT_BATTLE_ROYALE).",
	"func_getFightType": "Возвращает текущий тип боя.",
	"func_getFightType_return": "В зависимости от типа боя: Одиночный бой (#FIGHT_TYPE_SOLO), Фермерский бой (#FIGHT_TYPE_FARMER), Командный бой (#FIGHT_TYPE_TEAM), Королевская битва (#FIGHT_TYPE_BATTLE_ROYALE).",
	"func_getForce": "Возвращает силу объекта с идентификатором <b>entity</b>.",
	"func_getForce_arg_1": "Сущность, чья сила будет возвращена.",
	"func_getForce_return": "Сила объекта <b>entity</b>.",
	"func_getFrequency": "Возвращает частоту объекта с идентификатором <b>entity</b>. Используйте getFrequency() без параметров, чтобы получить вашу частоту.",
	"func_getFrequency_arg_1": "Сущность, частота которой будет возвращена.",
	"func_getFrequency_return": "Частота объекта <b>entity</b>.",
	"func_getGreen": "Возвращает количество зеленого в цвете <b>color</b> в диапазоне от 0 до 255. Например, getGreen(#COLOR_GREEN) = 255 и getGreen(#COLOR_RED) = 0.",
	"func_getGreen_arg_1": "Цвет, уровень зеленого которого будет возвращен.",
	"func_getGreen_return": "Коэффициент зеленого цвета в цвете <b>color</b>",
	"func_getInstructionsCount": "Возвращает количество инструкций, которые ваша сущность выполнила за текущий ход.",
	"func_getInstructionsCount_return": "Количество инструкций, которые ваша сущность выполнила за текущий ход.",
	"func_getLaunchedEffects": "Возвращает список эффектов, вызванных сущностью с идентификатором <b>entity</b>.",
	"func_getLaunchedEffects_arg_1": "Идентификатор объекта, чей список вызванных эффектов будет возвращен.",
	"func_getLaunchedEffects_return": "Список эффектов, вызванных сущностью с идентификатором <b>entity</b>, того же вида, что и массив, возвращаемый #getEffects.",
	"func_getLeek": "Возвращает идентификатор вашей сущности.",
	"func_getLeek_return": "Идентификатор вашей сущности.",
	"func_getEntity": "Возвращает идентификатор вашей сущности.",
	"func_getEntity_return": "Идентификатор вашей сущности.",
	"func_getLeekID": "Возвращает реальный идентификатор лука-порея с идентификатором <b>leek</b>.",
	"func_getLeekID_arg_1": "Идентификатор лука-порея, фактический идентификатор которого будет возвращен.",
	"func_getLeekID_return": "Настоящий идентификатор лука-порея <b>объекта</b>.",
	"func_getLeekOnCell": "Возвращает объект, который находится в ячейке <b>cell</b>.",
	"func_getLeekOnCell_arg_1": "Ячейка, сущность которой мы хотим получить.",
	"func_getLeekOnCell_return": "Идентификатор объекта в ячейке или -1, если в ячейке нет объекта.",
	"func_getEntityOnCell": "Возвращает объект, который находится в ячейке <b>cell</b>.",
	"func_getEntityOnCell_arg_1": "Ячейка, сущность которой мы хотим получить.",
	"func_getEntityOnCell_return": "Идентификатор объекта в ячейке или -1, если в ячейке нет объекта.",
	"func_getLevel": "Возвращает уровень сущности с идентификатором <b>entity</b>.",
	"func_getLevel_arg_1": "Идентификатор объекта, уровень которого будет возвращен.",
	"func_getLevel_return": "Уровень объекта с идентификатором <b>entity</b>.",
	"func_getLife": "Возвращает текущую жизнь объекта с идентификатором <b>entity</b>. Используйте getLife() без параметров, чтобы получить свою жизнь.",
	"func_getLife_arg_1": "Идентификатор сущности, жизнь которой будет возвращена.",
	"func_getLife_return": "Текущая жизнь объекта <b>entity</b>.",
	"func_getMagic": "Возвращает магию объекта с идентификатором <b>entity</b>. Используйте getMagic() без параметров, чтобы получить вашу магию.",
	"func_getMagic_arg_1": "Идентификатор сущности, чья магия будет возвращена.",
	"func_getMagic_return": "Магия объекта id <b>entity</b>.",
	"func_getMapType": "Возвращает тип местности, на которой происходит бой (фабрика, пустыня, лес и т. д.), среди констант #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER и #MAP_BEACH.",
	"func_getMapType_return": "Тип местности.",
	"func_getMessageAuthor": "Возвращает идентификатор объекта, создавшего сообщение <b>message</b>.",
	"func_getMessageAuthor_arg_1": "Сообщение, автор которого будет возвращен.",
	"func_getMessageAuthor_return": "Идентификатор объекта, создавшего сообщение <b>message</b>.",
	"func_getMessageParams": "Возвращает массив параметров сообщения <b>message</b>.",
	"func_getMessageParams_arg_1": "Сообщение, параметры которого будут возвращены.",
	"func_getMessageParams_return": "Параметры сообщения <b>message</b>.",
	"func_getMessages": "Возвращает массив сообщений объекта <b>entity</b>.",
	"func_getMessages_arg_1": "Сущность, чьи сообщения будут возвращены.",
	"func_getMessages_return": "Таблица ваших сообщений.<br>Само сообщение представлено в виде\nмассив вида: [<b>автор</b>, <b>тип</b>, <b>параметры</b>]<br>\nРазличные типы сообщений представлены константами:\n<ул>\n<li>#MESSAGE_HEAL: запрос на помощь</li>\n<li>#MESSAGE_ATTACK: запрос на атаку</li>\n<li>#MESSAGE_BUFF_FORCE: запрос принудительного повышения</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "Возвращает тип сообщения <b>message</b>.",
	"func_getMessageType_arg_1": "Сообщение, тип которого будет возвращен (среди #MESSAGE_HEAL, #MESSAGE_ATTACK и т. д.).",
	"func_getMessageType_return": "Тип сообщения <b>сообщение</b>.",
	"func_getMP": "Возвращает текущее количество точек движения объекта <b>entity</b>. Используйте getMP() без параметров для получения ваших PM.",
	"func_getMP_arg_1": "Идентификатор объекта, количество PM которого будет возвращено.",
	"func_getMP_return": "Номер MP объекта <b>entity</b>.",
	"func_getName": "Возвращает имя объекта с идентификатором <b>entity</b>.",
	"func_getName_arg_1": "Идентификатор объекта, имя которого будет возвращено.",
	"func_getName_return": "Имя сущности <b>сущность</b>.",
	"func_getNearestAlly": "Возвращает ближайшую дружественную сущность к вашей сущности.",
	"func_getNearestAlly_return": "Идентификатор ближайшего дружественного объекта.",
	"func_getNearestAllyTo": "Возвращает ближайший союзный объект к объекту, указанному в качестве параметра.",
	"func_getNearestAllyTo_arg_1": "Идентификатор объекта, чей ближайший союзник мы хотим знать.",
	"func_getNearestAllyToCell": "Возвращает ближайший связанный объект к ячейке, указанной в качестве параметра.",
	"func_getNearestAllyToCell_arg_1": "Идентификатор ячейки, чей ближайший союзник мы хотим знать.",
	"func_getNearestAllyToCell_return": "Идентификатор ближайшего дружественного объекта.",
	"func_getNearestAllyTo_return": "Идентификатор ближайшего дружественного объекта.",
	"func_getNearestEnemy": "Возвращает ближайшую вражескую сущность к вашей сущности.",
	"func_getNearestEnemy_return": "Идентификатор ближайшего вражеского объекта.",
	"func_getNearestEnemyTo": "Возвращает ближайший вражеский объект к объекту, указанному в качестве параметра.",
	"func_getNearestEnemyTo_arg_1": "Идентификатор объекта, чей ближайший враг мы хотим знать.",
	"func_getNearestEnemyToCell": "Возвращает ближайший вражеский объект к ячейке, указанной в качестве параметра.",
	"func_getNearestEnemyToCell_arg_1": "Идентификатор ячейки, чей ближайший враг мы хотим знать.",
	"func_getNearestEnemyToCell_return": "Идентификатор ближайшего вражеского объекта.",
	"func_getNearestEnemyTo_return": "Идентификатор ближайшего вражеского объекта.",
	"func_getNextPlayer": "Возвращает идентификатор объекта, который будет воспроизводиться после текущего игрока.",
	"func_getNextPlayer_return": "Следующий игрок.",
	"func_getObstacles": "Возвращает список мест препятствий на местности.",
	"func_getObstacles_return": "Массив, содержащий идентификаторы ячеек препятствия.",
	"func_getOperations": "Возвращает количество операций, потребленных вашей сущностью с начала ее хода. Это число должно оставаться меньше #OPERATIONS_LIMIT, чтобы не произошло сбоя сущности.",
	"func_getOperations_return": "Количество операций, потребленных вашей сущностью с начала ее хода.",
	"func_getPath": "Возвращает путь в обход препятствий между двумя ячейками <b>cell1</b> и <b>cell2</b>, если он существует, игнорируя ячейки, содержащиеся в массиве <b>ignoredCells</b> . Если игрок находится в игнорируемой ячейке, путь может пройти через него.<br /><br />\nНачальная ячейка <b>cell1</b> никогда не является частью результирующего пути. Ячейка <b>cell2</b> является частью результирующего пути тогда и только тогда, когда она пуста или игнорируется <b>ignoredCells</b>.<br /><br />\nЕсли пути между двумя ячейками не существует, <b>getPath</b> возвращает <i>null</i>.",
	"func_getPath_arg_1": "Стартовая ячейка.",
	"func_getPath_arg_2": "Ячейка прибытия.",
	"func_getPath_arg_3": "Массив ячеек для игнорирования.",
	"func_getPath_return": "Массив, содержащий ячейки, составляющие путь между двумя ячейками.",
	"func_getPathLength": "Возвращает длину пути между двумя ячейками <b>cell1</b> и <b>cell2</b>, минуя препятствия, игнорируя ячейки, содержащиеся в массиве <b>ignoredCells</b>. Эта функция эквивалентна <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>.\nЕсли игрок находится в игнорируемой ячейке, путь может пройти через него.<br /><br />\nНачальная ячейка <b>cell1</b> никогда не учитывается в результате. Ячейка <b>cell2</b> учитывается в результате тогда и только тогда, когда она пуста или игнорируется <b>ignoredCells</b>.<br /><br />\nЕсли пути между двумя ячейками не существует, <b>getPathLength</b> возвращает <i>null</i>.",
	"func_getPathLength_arg_1": "Стартовая ячейка.",
	"func_getPathLength_arg_2": "Ячейка прибытия.",
	"func_getPathLength_arg_3": "Массив ячеек для игнорирования.",
	"func_getPathLength_return": "Длина пути между <b>cell1</b> и <b>cell2</b>.",
	"func_getPreviousPlayer": "Возвращает идентификатор сущности, которая играла перед текущим игроком.",
	"func_getPreviousPlayer_return": "Предыдущий игрок.",
	"func_getRed": "Возвращает количество красного в цвете <b>color</b> в диапазоне от 0 до 255. Например, getRed(#COLOR_RED) = 255 и getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "Цвет, красный показатель которого будет возвращен.",
	"func_getRed_return": "Доля красного в <b>цвете</b>",
	"func_getRegister": "Возвращает значение, хранящееся в реестре сущностей, связанное с ключом <b>key</b> или </i>null</i>, если реестр не существует.",
	"func_getRegister_arg_1": "Раздел реестра, значение которого будет возвращено.",
	"func_getRegister_return": "Значение, хранящееся в реестре <b>key</b>.",
	"func_getRegisters": "Возвращает набор регистров сущностей в виде ассоциативного массива [<i>ключ реестра</i>:<i>значение регистра</i>]. Пример: <code>debug(getRegisters());\n// Отображает, например:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "Ассоциативный массив, соответствующий всем регистрам объекта.",
	"func_getRelativeShield": "Возвращает относительный щит объекта с идентификатором <b>entity</b>. Чтобы напрямую получить относительный щит вашего объекта, используйте #getRelativeShield() без параметров.",
	"func_getRelativeShield_arg_1": "Идентификатор объекта, чей относительный щит будет возвращен.",
	"func_getRelativeShield_return": "Относительный щит <b>объекта</b>, целое число от <b>0</b> до <b>100</b>.",
	"func_getResistance": "Возвращает сопротивление объекта с идентификатором <b>entity</b>. Используйте getResistance() без параметров, чтобы получить сопротивление.",
	"func_getResistance_arg_1": "Идентификатор объекта, чье сопротивление будет возвращено.",
	"func_getResistance_return": "Сопротивление объекта с идентификатором <b>entity</b>.",
	"func_getScience": "Возвращает науку сущности с идентификатором <b>entity</b>. Используйте getScience() без параметров, чтобы получить вашу науку.",
	"func_getScience_arg_1": "Идентификатор объекта, чья наука будет возвращена.",
	"func_getScience_return": "Наука об идентификаторе объекта <b>entity</b>.",
	"func_getStrength": "Возвращает силу объекта с идентификатором <b>entity</b>. Используйте getStrength() без параметров, чтобы получить вашу силу.",
	"func_getStrength_arg_1": "Сущность, чья сила будет возвращена.",
	"func_getStrength_return": "Сила объекта <b>entity</b>.",
	"func_getPower": "Возвращает мощность объекта с идентификатором <b>entity</b>.",
	"func_getPower_arg_1": "Сущность, сила которой будет возвращена.",
	"func_getPower_return": "Сила <b>сущности</b>.",
	"func_getSummoner": "Возвращает сущность, вызвавшую сущность <b>сущность</b>, если это вызов.",
	"func_getSummoner_arg_1": "Идентификатор сущности, призыватель которой будет уволен.",
	"func_getSummoner_return": "Идентификатор объекта, вызвавшего <b>entity</b>, если это вызов, <i>null</i> в противном случае.",
	"func_getSummons": "Возвращает список идентификаторов активных вызовов объекта с идентификатором <b>entity</b>.",
	"func_getSummons_arg_1": "Идентификатор объекта, вызов которого будет возвращен.",
	"func_getSummons_return": "Список идентификаторов вызова объекта с идентификатором <b>entity</b>.",
	"func_getTeamID": "Возвращает идентификатор команды объекта <b>entity</b>.",
	"func_getTeamID_arg_1": "Идентификатор объекта, чей идентификатор команды будет возвращен.",
	"func_getTeamID_return": "Идентификатор команды объекта <b>entity</b>.",
	"func_getTeamName": "Возвращает название группы объекта <b>entity</b>.",
	"func_getTeamName_arg_1": "Идентификатор объекта, имя команды которого будет возвращено.",
	"func_getTeamName_return": "Название команды объекта <b>entity</b>.",
	"func_getTotalLife": "Возвращает общее время существования объекта с идентификатором <b>entity</b>. Используйте getTotalLife() без параметров, чтобы получить общее количество жизней.",
	"func_getTotalLife_arg_1": "Идентификатор объекта, чья общая жизнь будет возвращена.",
	"func_getTotalLife_return": "Полная жизнь сущности.",
	"func_getTotalMP": "Возвращает максимальное количество очков движения объекта <b>entity</b>.",
	"func_getTotalMP_arg_1": "Идентификатор объекта, максимальное количество очков движения которого будет возвращено.",
	"func_getTotalMP_return": "Максимальное количество очков движения для <b>сущности</b>.",
	"func_getTotalTP": "Возвращает максимальное количество точек поворота объекта <b>entity</b>.",
	"func_getTotalTP_arg_1": "Идентификатор объекта, для которого будет возвращено максимальное количество точек поворота.",
	"func_getTotalTP_return": "Максимальное количество точек поворота объекта <b>entity</b>.",
	"func_getTP": "Возвращает количество точек поворота объекта <b>entity</b>. Используйте getTP() без параметров для получения ваших PT.",
	"func_getTP_arg_1": "Идентификатор объекта, чьи PT будут возвращены.",
	"func_getTP_return": "Номер PT объекта <b>entity</b>.",
	"func_getTurn": "Возвращает текущий боевой ход. Максимальное количество оборотов #MAX_TURNS.",
	"func_getTurn_return": "Текущий ход боя.",
	"func_getType": "Возвращает тип объекта <b>entity</b>.",
	"func_getType_arg_1": "Идентификатор объекта, тип которого будет возвращен.",
	"func_getType_return": "Тип объекта <b>entity</b>:<ul><li>#ENTITY_LEEK, если это лук-порей.</li><li>#ENTITY_BULB, если это луковица.</li><li >#ENTITY_TURRET, если это турель.</li></ul>",
	"func_getWeapon": "Возвращает текущее экипированное оружие <b>entity</b>.",
	"func_getWeapon_arg_1": "Идентификатор объекта, чье текущее оружие будет возвращено.",
	"func_getWeapon_return": "Идентификатор экипированного в данный момент оружия у <b>сущности</b>, null, если у сущности нет экипированного оружия или сущность не существует.",
	"func_getWeaponArea": "Возвращает тип области действия оружия <b>weapon</b>.",
	"func_getWeaponArea_arg_1": "Оружие, тип области которого будет возвращен.",
	"func_getWeaponArea_return": "Тип области оружия <b>weapon</b> среди констант AREA_*:\n<ул>\n<li>#AREA_POINT: один квадрат.</li>\n<li>#AREA_LASER_LINE: линия лазера</li>\n<li>#AREA_CIRCLE_1: круглая область диаметром 3 ячейки.</li>\n<li>#AREA_CIRCLE_2: круглая область диаметром 5 квадратов.</li>\n<li>#AREA_CIRCLE_3: круглая область диаметром 7 квадратов.</li>\n</ul>",
	"func_getWeaponCost": "Возвращает стоимость TP оружия <b>weapon</b>.",
	"func_getWeaponCost_arg_1": "Идентификатор оружия, стоимость которого будет возвращена.",
	"func_getWeaponCost_return": "Стоимость TP оружия <b>weapon</b>.",
	"func_getWeaponEffectiveArea": "Возвращает список ячеек, которые будут затронуты, если оружие <b>оружие</b> используется в ячейке <b>ячейка</b> из ячейки <b>из</b>.",
	"func_getWeaponEffectiveArea_arg_1": "Оружие для испытаний.",
	"func_getWeaponEffectiveArea_arg_2": "Целевая ячейка.",
	"func_getWeaponEffectiveArea_arg_3": "Ячейка, из которой стреляет оружие.",
	"func_getWeaponEffectiveArea_return": "Массив, содержащий идентификаторы всех ячеек, которые будут затронуты.",
	"func_getWeaponPassiveEffects": "Возвращает пассивные эффекты оружия <b>weapon</b>.",
	"func_getWeaponPassiveEffects_arg_1": "Идентификатор оружия, чьи пассивные эффекты будут возвращены.",
	"func_getWeaponPassiveEffects_return": "Массив, содержащий эффекты оружия <b>weapon</b>. Каждый эффект сам по себе является массивом вида\n[тип, мин., макс., ходы, цели, модификаторы]. Эти эффекты аналогичны эффектам, возвращаемым функцией #getWeaponEffects.",
	"func_getWeaponFailure": "Возвращает процент вероятности отказа <b>оружия</b> от оружия.",
	"func_getWeaponFailure_arg_1": "Идентификатор оружия, процент отказов которого будет возвращен.",
	"func_getWeaponFailure_return": "<b>оружие</b> процент промаха оружия, целое число от <b>0</b> до <b>100</b>.",
	"func_getWeaponMaxRange": "Возвращает максимальную дальность действия оружия <b>weapon</b>.",
	"func_getWeaponMaxRange_arg_1": "Идентификатор оружия, максимальная дальность которого будет возвращена.",
	"func_getWeaponMaxRange_return": "Максимальная дальность действия оружия <b>weapon</b>.",
	"func_getWeaponMaxScope": "Возвращает максимальную дальность действия оружия <b>weapon</b>.",
	"func_getWeaponMaxScope_arg_1": "Идентификатор оружия, максимальная дальность которого будет возвращена.",
	"func_getWeaponMaxScope_return": "Максимальная дальность действия оружия <b>weapon</b>.",
	"func_getWeaponMinRange": "Возвращает минимальную дальность действия оружия <b>weapon</b>.",
	"func_getWeaponMinRange_arg_1": "Идентификатор оружия, минимальная дальность которого будет возвращена.",
	"func_getWeaponMinRange_return": "Минимальная дальность действия оружия <b>weapon</b>.",
	"func_getWeaponMinScope": "Возвращает минимальную дальность действия оружия <b>weapon</b>.",
	"func_getWeaponMinScope_arg_1": "Идентификатор оружия, минимальная дальность которого будет возвращена.",
	"func_getWeaponMinScope_return": "Минимальная дальность действия оружия <b>weapon</b>.",
	"func_getWeaponLaunchType": "Возвращает режим запуска <b>оружия</b> среди констант LAUNCH_TYPE_*.",
	"func_getWeaponLaunchType_arg_1": "Идентификатор оружия, режим метания которого будет возвращен. По умолчанию используется ваше текущее экипированное оружие.",
	"func_getWeaponLaunchType_return": "Режим метания оружия <b>weapon</b>.",
	"func_getWeaponName": "Возвращает название оружия <b>weapon</b>.",
	"func_getWeaponName_arg_1": "Идентификатор оружия, имя которого будет возвращено.",
	"func_getWeaponName_return": "Название <b>оружия</b> оружия.",
	"func_getWeapons": "Возвращает оружие сущности с идентификатором <b>entity</b>.",
	"func_getWeapons_arg_1": "Идентификатор объекта, чье оружие будет возвращено.",
	"func_getWeapons_return": "Массив, содержащий идентификаторы оружия <b>entity</b>.",
	"func_getWeaponTargets": "Возвращает объекты, которые будут затронуты, если оружие <b>weapon</b> используется в ячейке <b>cell</b>.",
	"func_getWeaponTargets_arg_1": "Оружие для испытаний.",
	"func_getWeaponTargets_arg_2": "Целевая ячейка.",
	"func_getWeaponTargets_return": "Массив, содержащий идентификаторы всех объектов, которые будут затронуты.",
	"func_getWisdom": "Возвращает мудрость сущности с идентификатором <b>entity</b>. Используйте getWisdom() без параметров, чтобы получить свою мудрость.",
	"func_getWisdom_arg_1": "Идентификатор сущности, чья мудрость будет возвращена.",
	"func_getWisdom_return": "Мудрость сущности с идентификатором <b>entity</b>.",
	"func_hypot": "Возвращает гипотенузу прямоугольного треугольника со сторонами x и y. Эквивалентно <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "Значение x.",
	"func_hypot_arg_2": "Y-значение.",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "Определяет, находится ли элемент <b>element</b> в массиве <b>array</b>.",
	"func_inArray_arg_1": "Таблица поиска.",
	"func_inArray_arg_2": "Элемент для поиска.",
	"func_inArray_return": "<i>true</i>, если элемент находится в массиве, <i>false</i> в противном случае.",
	"func_include": "Включить ИИ с именем <b>ai</b> в текущий ИИ. <br><br><b>Внимание</b>, функция включения должна вызываться только в основном блоке, а ее параметр должен быть строкой, записанной напрямую\nв коде. Для получения дополнительной информации см. раздел руководства: <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a>.",
	"func_include_arg_1": "Имя ИИ для включения.",
	"func_indexOf": "Определяет позицию первого вхождения строки <b>search</b> в строку <b>string</b>, начиная с позиции <b>start</b>.",
	"func_indexOf_arg_1": "Строка, в которой будет выполняться поиск.",
	"func_indexOf_arg_2": "Строка для поиска.",
	"func_indexOf_arg_3": "Начальная позиция поиска.",
	"func_indexOf_return": "Позиция первого вхождения <b>search</b> в <b>string</b> от <b>start</b>, -1, если строка не найдена.",
	"func_insert": "Вставляет элемент <b>element</b> в <b>массив</b> в позиции <b>position</b>.",
	"func_insert_arg_1": "Массив, в который будет вставлен элемент.",
	"func_insert_arg_2": "Элемент для вставки.",
	"func_insert_arg_3": "Положение вставки.",
	"func_isAlive": "Определяет, жив ли <b>сущность</b>. Эквивалентно <i>getLife(<b>entity</b>) > 0</i>.",
	"func_isAlive_arg_1": "Идентификатор объекта для тестирования.",
	"func_isAlive_return": "<i>true</i>, если <b>сущность</b> жива, и <i>false</i>, если она мертва.",
	"func_isAlly": "Определяет, является ли <b>сущность</b> вашим союзником.",
	"func_isAlly_arg_1": "Идентификатор объекта для тестирования.",
	"func_isAlly_return": "<i>true</i>, если <b>сущность</b> является вашим союзником или вами, <i>false</i>, если это враг.",
	"func_isChip": "Определяет, является ли значение константой, представляющей чип.<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "Количество, которое необходимо определить.",
	"func_isChip_return": "<i>true</i>, если значение является константой чипа.",
	"func_isDead": "Определяет, мертв ли <b>сущность</b>. Эквивалентно <i>getLife(<b>entity</b>) == 0</i>.",
	"func_isDead_arg_1": "Идентификатор объекта для тестирования.",
	"func_isDead_return": "<i>true</i>, если <b>сущность</b> мертва, <i>false</i>, если она жива.",
	"func_isEmpty": "Определяет, является ли массив <b>массив</b> пустым. Эквивалентно <i>count(<b>массив</b>) == 0</i>.",
	"func_isEmpty_arg_1": "Массив для тестирования.",
	"func_isEmptyCell": "Определяет, пуста ли ячейка.",
	"func_isEmptyCell_arg_1": "Тестируемая ячейка.",
	"func_isEmptyCell_return": "<i>true</i>, если ячейка пуста, <i>false</i> в противном случае.",
	"func_isEmpty_return": "<i>true</i>, если массив пуст, иначе <i>false</i>.",
	"func_isEnemy": "Определяет, является ли <b>сущность</b> вашим врагом.",
	"func_isEnemy_arg_1": "Идентификатор объекта для тестирования.",
	"func_isEnemy_return": "<i>true</i>, если <b>сущность</b> является врагом, <i>false</i>, если это союзник или вы сами.",
	"func_isInlineChip": "Определяет, можно ли использовать <b>чип</b> только онлайн.",
	"func_isInlineChip_arg_1": "Идентификатор тестируемого чипа.",
	"func_isInlineChip_return": "<i>true</i>, если чип подключен только к сети, <i>false</i> в противном случае.",
	"func_isInlineWeapon": "Определяет, может ли <b>оружие</b> использоваться только онлайн.",
	"func_isInlineWeapon_arg_1": "ID оружия для тестирования.",
	"func_isInlineWeapon_return": "<i>true</i>, если оружие можно использовать только онлайн, <i>false</i> в противном случае.",
	"func_isLeek": "Определяет, является ли содержимое ячейки <b>cell</b> сущностью.",
	"func_isLeek_arg_1": "Тестируемая ячейка.",
	"func_isLeek_return": "<i>true</i>, если ячейка содержит объект, <i>false</i> в противном случае.",
	"func_isEntity": "Определяет, является ли содержимое ячейки <b>cell</b> сущностью.",
	"func_isEntity_arg_1": "Тестируемая ячейка.",
	"func_isEntity_return": "<i>true</i>, если ячейка содержит объект, <i>false</i> в противном случае.",
	"func_isObstacle": "Определяет, является ли содержимое ячейки <b>cell</b> препятствием.",
	"func_isObstacle_arg_1": "Тестируемая ячейка.",
	"func_isObstacle_return": "<i>true</i>, если ячейка содержит препятствие, <i>false</i> в противном случае.",
	"func_isOnSameLine": "Определяет, находятся ли две ячейки <b>cell1</b> и <b>cell2</b> на одной строке.",
	"func_isOnSameLine_arg_1": "Первая ячейка.",
	"func_isOnSameLine_arg_2": "Вторая ячейка.",
	"func_isOnSameLine_return": "<i>true</i>, если две ячейки находятся на одной строке, <i>false</i> в противном случае.",
	"func_isStatic": "Возвращает значение, является ли <b>объект</b> статическим или нет. Статическая сущность не может двигаться или перемещаться.",
	"func_isStatic_arg_1": "Идентификатор объекта для тестирования.",
	"func_isStatic_return": "<i>true</i>, если <b>объект</b> является статическим, <i>false</i> в противном случае.",
	"func_isSummon": "Возвращает, является ли <b>сущность</b> вызовом или нет.",
	"func_isSummon_arg_1": "Идентификатор объекта для тестирования.",
	"func_isSummon_return": "<i>true</i>, если <b>entity</b> является вызовом, <i>false</i> в противном случае.",
	"func_isWeapon": "Определяет, является ли значение константой, представляющей оружие.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false.",
	"func_isWeapon_arg_1": "Количество, которое необходимо определить.",
	"func_isWeapon_return": "<i>true</i>, если значение является константой оружия.",
	"func_join": "Объединяет несколько элементов в строку, разделяя их разделителем <b>клей</b>.",
	"func_join_arg_1": "Массив элементов для слияния.",
	"func_join_arg_2": "Разделитель элемента.",
	"func_join_return": "Результирующая строка слияния.",
	"func_jsonDecode": "Декодирует строку <b>json</b> в объект LeekScript (число, строка, массив...).",
	"func_jsonDecode_arg_1": "Строка JSON для декодирования.",
	"func_jsonDecode_return": "Расшифрованный объект LeekScript.",
	"func_jsonEncode": "Кодирует объект <b>object</b> в строку JSON.",
	"func_jsonEncode_arg_1": "Объект для кодирования в JSON.",
	"func_jsonEncode_return": "Строка в формате JSON.",
	"func_keySort": "Сортирует <b>массив</b> по ключам в порядке <b>order</b>.",
	"func_keySort_arg_1": "Массив для сортировки.",
	"func_keySort_arg_2": "Порядок сортировки: #SORT_ASC или #SORT_DESC.",
	"func_length": "Возвращает длину <b>строки</b>.",
	"func_length_arg_1": "Строка, длина которой будет возвращена.",
	"func_length_return": "Длина <b>строки</b>.",
	"func_lineOfSight": "Проверяет прямую видимость между <b>начальной</b> ячейкой и <b>конечной</b> ячейкой, игнорируя сущности <b>ignoredEntities</b>.",
	"func_lineOfSight_arg_1": "Стартовая ячейка.",
	"func_lineOfSight_arg_2": "Целевая ячейка.",
	"func_lineOfSight_arg_3": "Сущность или список сущностей, которые следует игнорировать.",
	"func_lineOfSight_return": "Возвращает <i>true</i>, если линия прямой видимости свободна.",
	"func_listen": "Возвращает массив say() предыдущих сущностей в форме [entity_id, message].",
	"func_listen_return": "Массив предыдущих say().",
	"func_log": "Вычисляет натуральный логарифм числа <b>number</b>.",
	"func_log_arg_1": "Число в диапазоне ]0; +∞[.",
	"func_log_return": "Натуральный логарифм <b>числа</b>.",
	"func_log10": "Вычисляет логарифм числа <b>число</b> по основанию 10.",
	"func_log10_arg_1": "Число в диапазоне ]0; +∞[.",
	"func_log10_return": "Логарифм <b>числа</b> по основанию 10.",
	"func_log2": "Вычисляет логарифм по основанию 2 числа <b>number</b>.",
	"func_log2_arg_1": "Число в диапазоне ]0; +∞[.",
	"func_log2_return": "Логарифм <b>числа</b> по основанию 2.",
	"func_mark": "Отмечает одну или несколько ячеек указанного в параметре цвета на земле на указанное в параметре количество ходов. Эта маркировка видна только заводчику объекта.",
	"func_mark_arg_1": "Ячейка или массив из нескольких ячеек для пометки",
	"func_mark_arg_2": "Цвет маркировки",
	"func_mark_arg_3": "Продолжительность маркировки",
	"func_mark_return": "Возвращает true, если все прошло хорошо",
	"func_markText": "Пишет текст на одной или нескольких ячейках указанного в параметре цвета на земле за указанное в параметре количество ходов. Эти тексты видны только создателю сущности.",
	"func_markText_arg_1": "Ячейка или массив из нескольких ячеек для записи",
	"func_markText_arg_2": "Текст для написания (максимум 10 символов)",
	"func_markText_arg_3": "Цвет текста",
	"func_markText_arg_4": "Длительность текста",
	"func_markText_return": "Возвращает true, если все прошло хорошо",
	"func_max": "Возвращает большее значение между двумя числами <b>a</b> и <b>b</b>.",
	"func_max_arg_1": "Число.",
	"func_max_arg_2": "Число.",
	"func_max_return": "Большее число между <b>a</b> и <b>b</b>.",
	"func_min": "Возвращает меньшее значение между двумя числами <b>a</b> и <b>b</b>.",
	"func_min_arg_1": "Число.",
	"func_min_arg_2": "Число.",
	"func_min_return": "Меньшее число между <b>a</b> и <b>b</b>.",
	"func_moveAwayFrom": "Перемещает вашу сущность от другой <b>сущности</b>, используя максимальное количество <b>mp</b> очков движения.",
	"func_moveAwayFrom_arg_1": "Сущность, от которой ваша сущность должна уйти.",
	"func_moveAwayFrom_arg_2": "Максимальное количество MP для использования. По умолчанию без ограничений.",
	"func_moveAwayFrom_return": "Количество использованных очков движения.",
	"func_moveAwayFromCell": "Перемещает вашу сущность на одну клетку <b>cell</b>, используя до <b>mp</b> очков движения.",
	"func_moveAwayFromCell_arg_1": "Ячейка, от которой ваша сущность должна уйти.",
	"func_moveAwayFromCell_arg_2": "Максимальное количество MP для использования. По умолчанию без ограничений.",
	"func_moveAwayFromCell_return": "Количество использованных очков движения.",
	"func_moveAwayFromCells": "Перемещает вашу сущность из набора ячеек <b>cells</b>, используя максимальное количество <b>mp</b> очков движения.",
	"func_moveAwayFromCells_arg_1": "Массив, содержащий ячейки, от которых ваша сущность должна уйти.",
	"func_moveAwayFromCells_arg_2": "Максимальное количество MP для использования. По умолчанию без ограничений.",
	"func_moveAwayFromCells_return": "Количество использованных очков движения.",
	"func_moveAwayFromLeeks": "Перемещает вашу сущность от набора <b>сущностей</b>, используя максимальное количество <b>mp</b> очков движения.",
	"func_moveAwayFromLeeks_arg_1": "Массив, содержащий идентификаторы сущностей, от которых ваша сущность должна удалиться.",
	"func_moveAwayFromLeeks_arg_2": "Максимальное количество MP для использования. По умолчанию без ограничений.",
	"func_moveAwayFromLeeks_return": "Количество использованных очков движения.",
	"func_moveAwayFromEntities": "Перемещает вашу сущность от набора <b>сущностей</b>, используя максимальное количество <b>mp</b> очков движения.",
	"func_moveAwayFromEntities_arg_1": "Массив, содержащий идентификаторы сущностей, от которых ваша сущность должна удалиться.",
	"func_moveAwayFromEntities_arg_2": "Максимальное количество MP для использования. По умолчанию без ограничений.",
	"func_moveAwayFromEntities_return": "Количество использованных очков движения.",
	"func_moveAwayFromLine": "Перемещает объект от линии, определяемой двумя ячейками <b>cell1</b> и <b>cell2</b>, используя максимум <b>mp</b> очков движения.",
	"func_moveAwayFromLine_arg_1": "Ячейка 1.",
	"func_moveAwayFromLine_arg_2": "Ячейка 2.",
	"func_moveAwayFromLine_arg_3": "Максимальное количество MP для использования. По умолчанию без ограничений.",
	"func_moveAwayFromLine_return": "Количество использованных очков движения.",
	"func_moveToward": "Приближает вашу сущность к другой <b>сущности</b>, используя максимальное количество очков движения <b>mp</b>.",
	"func_moveToward_arg_1": "Сущность, к которой должна подойти ваша сущность.",
	"func_moveToward_arg_2": "Максимальное количество MP для использования.",
	"func_moveToward_return": "Количество использованных очков движения.",
	"func_moveTowardCell": "Перемещает вашу сущность на одну ячейку <b>cell</b> ближе, используя максимальное количество <b>mp</b> очков движения.",
	"func_moveTowardCell_arg_1": "Ячейка, к которой должен подойти ваш объект.",
	"func_moveTowardCell_arg_2": "Максимальное количество MP для использования. По умолчанию без ограничений.",
	"func_moveTowardCell_return": "Количество использованных очков движения.",
	"func_moveTowardCells": "Приближает вашу сущность к набору ячеек <b>cells</b>, используя максимальное количество <b>mp</b> очков движения.",
	"func_moveTowardCells_arg_1": "Массив, содержащий ячейки, к которым должна подойти ваша сущность.",
	"func_moveTowardCells_arg_2": "Максимальное количество MP для использования. По умолчанию без ограничений.",
	"func_moveTowardCells_return": "Количество использованных очков движения.",
	"func_moveTowardLeeks": "Приближает вашу сущность к набору <b>сущностей</b>, используя максимальное количество <b>mp</b> очков движения.",
	"func_moveTowardLeeks_arg_1": "Массив, содержащий идентификаторы сущностей, к которым должна подойти ваша сущность.",
	"func_moveTowardLeeks_arg_2": "Максимальное количество MP для использования. По умолчанию без ограничений.",
	"func_moveTowardLeeks_return": "Количество использованных очков движения.",
	"func_moveTowardEntities": "Приближает вашу сущность к набору <b>сущностей</b>, используя максимальное количество <b>mp</b> очков движения.",
	"func_moveTowardEntities_arg_1": "Массив, содержащий идентификаторы сущностей, к которым должна подойти ваша сущность.",
	"func_moveTowardEntities_arg_2": "Максимальное количество MP для использования. По умолчанию без ограничений.",
	"func_moveTowardEntities_return": "Количество использованных очков движения.",
	"func_moveTowardLine": "Приближает вашу сущность к линии, определяемой двумя ячейками <b>cell1</b> и <b>cell2</b>, используя максимальное количество очков движения <b>mp</b>.",
	"func_moveTowardLine_arg_1": "Ячейка 1.",
	"func_moveTowardLine_arg_2": "Ячейка 2.",
	"func_moveTowardLine_arg_3": "Максимальное количество MP для использования. По умолчанию без ограничений.",
	"func_moveTowardLine_return": "Количество использованных очков движения.",
	"func_number": "Преобразует значение в число. Если значение является строкой, числовая функция попытается преобразовать его в число, если значение уже является числом, функция возвращает число, а для любого другого типа возвращает <i>null</i>.",
	"func_number_arg_1": "Значение для преобразования в число.",
	"func_number_return": "Преобразованный номер.",
	"func_pause": "Приостанавливает бой, только для заводчика существа, использующего эту функцию.",
	"func_pop": "Удаляет последнюю ячейку из массива <b>array</b> и возвращает соответствующий элемент.",
	"func_pop_arg_1": "Массив, последний ящик которого будет удален.",
	"func_pop_return": "Предмет, который был в старой последней коробке.",
	"func_pow": "Возводит базовое число в степень степени.",
	"func_pow_arg_1": "База.",
	"func_pow_arg_2": "Экспонент.",
	"func_pow_return": "<b>основание</b> возведено в степень <b>показатель степени</b>.",
	"func_push": "Добавляет элемент <b>element</b> в конец <b>массива</b>.",
	"func_pushAll": "Добавляет все элементы массива <b>elements</b> в конец <b>массива</b>.",
	"func_pushAll_arg_1": "Массив, в который мы хотим добавить элементы.",
	"func_pushAll_arg_2": "Элементы для добавления.",
	"func_push_arg_1": "Массив, в который мы хотим добавить элемент.",
	"func_push_arg_2": "Элемент для добавления.",
	"func_rand": "Возвращает реальное случайное число от 0 (включительно) до 1 (не включая).",
	"func_randFloat": "Возвращает реальное случайное число между <b>a</b> (включительно) и <b>b</b> (не включая).",
	"func_randFloat_arg_1": "Нижняя граница.",
	"func_randFloat_arg_2": "Верхняя граница.",
	"func_randFloat_return": "Случайное число в диапазоне [a; б[.",
	"func_randInt": "Возвращает случайное целое число от <b>a</b> (включительно) до <b>b</b> (не включая).",
	"func_randInt_arg_1": "Нижняя граница.",
	"func_randInt_arg_2": "Верхняя граница.",
	"func_randInt_return": "Случайное целое число в диапазоне [a; б[.",
	"func_rand_return": "Случайное число в диапазоне [0; 1[.",
	"func_remove": "Удаляет элемент из массива <b>array</b> в позиции <b>position</b> и возвращает удаленный элемент.",
	"func_remove_arg_1": "Массив, из которого мы хотим удалить элемент.",
	"func_remove_arg_2": "Позиция удаляемого элемента.",
	"func_removeElement": "Удаляет первое вхождение <b>элемента</b> в <b>массив</b>. Если элемент не найден, массив не изменяется.",
	"func_removeElement_arg_1": "Массив, из которого мы хотим удалить элемент.",
	"func_removeElement_arg_2": "Элемент, который нужно найти, а затем удалить.",
	"func_removeKey": "Удаляет элемент из массива <b>массив</b>, связанный с ключом <b>key</b>.",
	"func_removeKey_arg_1": "Массив, из которого мы хотим удалить элемент.",
	"func_removeKey_arg_2": "Ключ, связанный с удаляемым элементом.",
	"func_remove_return": "Элемент удален из массива.",
	"func_replace": "Заменяет все вхождения <b>search</b> на <b>replace</b> в строке <b>string</b>.",
	"func_replace_arg_1": "Строка, в которой производятся замены.",
	"func_replace_arg_2": "Подстрока для замены.",
	"func_replace_arg_3": "Сменная струна.",
	"func_replace_return": "Результирующая строка с заменами.",
	"func_resurrect": "Использует чип CHIP_RESURRECTION для воскрешения мертвого объекта с идентификатором <b>entity</b> в ячейке <b>cell</b>.",
	"func_resurrect_arg_1": "Идентификатор объекта для возрождения.",
	"func_resurrect_arg_2": "Ячейка, в которой сущность будет возрождаться.",
	"func_resurrect_return": "Результат запуска чипа, среди констант USE_*.",
	"func_reverse": "Переворачивает <b>массив</b>.",
	"func_reverse_arg_1": "Таблица для инвертирования.",
	"func_round": "Возвращает округление <b>number</b>.<br/>Вы можете найти округление в меньшую сторону с помощью #floor и округление в большую сторону с помощью #ceil.",
	"func_round_arg_1": "Число, округление которого мы хотим найти.",
	"func_round_return": "Округлено на <b>число</b>.",
	"func_say": "Заставьте свою сущность говорить.",
	"func_say_arg_1": "Сообщение, которое ваша сущность объявит на арене.",
	"func_search": "Находит элемент <b>element</b> в массиве <b>array</b>, начиная с позиции <b>start</b>.",
	"func_search_arg_1": "Таблица поиска.",
	"func_search_arg_2": "Элемент для поиска.",
	"func_search_arg_3": "Позиция начала поиска.",
	"func_search_return": "Позиция первого вхождения элемента <b>element</b> в <b>массив</b>, начиная с <b>start</b>, null, если элемент не был найден.",
	"func_sendAll": "Отправьте сообщение всей вашей команде.",
	"func_sendAll_arg_1": "Тип сообщения для отправки (см. константы MESSAGE_*).",
	"func_sendAll_arg_2": "Параметры сообщения, которые могут быть любыми значениями.",
	"func_sendTo": "Отправляет сообщение объекту с идентификатором <b>entity</b>.",
	"func_sendTo_arg_1": "Идентификатор объекта, которому будет отправлено сообщение.",
	"func_sendTo_arg_2": "Тип сообщения для отправки (см. константы MESSAGE_*).",
	"func_sendTo_arg_3": "Параметры сообщения, которые могут быть любыми значениями.",
	"func_sendTo_return": "<i>true</i>, если отправка прошла успешно, <i>false</i>, если произошла ошибка.",
	"func_setRegister": "Сохраняет <b>значение</b> в реестре <b>ключей</b>.\nКлюч и значение представляют собой строки, которые должны содержать не более <i>100</i> и <i>5000</i> символов соответственно. Лук-порей может иметь не более <i>100</i> регистров, сохранение в новом регистре не будет работать, если все регистры\nуже заняты.",
	"func_setRegister_arg_1": "Раздел реестра, в котором будет храниться значение.",
	"func_setRegister_arg_2": "Значение для сохранения.",
	"func_setRegister_return": "<i>true</i>, если операция прошла успешно, <i>false</i> в противном случае.",
	"func_setWeapon": "Наденьте <b>оружие</b> на свою сущность.",
	"func_setWeapon_arg_1": "Идентификатор оружия для экипировки.",
	"func_shift": "Удаляет первую ячейку из <b>массива</b> и возвращает соответствующий элемент.",
	"func_shift_arg_1": "Массив, первая ячейка которого будет удалена.",
	"func_shift_return": "Элемент, который был в старой первой ячейке.",
	"func_show": "Показывает игрокам <b>ячейку</b> цвета <b>color</b> на поле на 1 ход. Использование этой функции стоит 1 PT.",
	"func_show_arg_1": "Ячейка для отображения",
	"func_show_arg_2": "Цвет маркировки",
	"func_show_return": "Возвращает true, если все прошло хорошо",
	"func_shuffle": "Случайным образом перемешивает доску.",
	"func_shuffle_arg_1": "Шафлборд.",
	"func_signum": "Определяет знак числа <b>number</b>.",
	"func_signum_arg_1": "Число, знак которого будет определен.",
	"func_signum_return": "1, если число положительное, 0, если число равно нулю, и -1, если число отрицательное.",
	"func_sin": "Вычисляет синус угла <b>angle</b>, переданного в качестве параметра.",
	"func_sin_arg_1": "Угол, синус которого будет вычисляться",
	"func_sin_return": "Возвращает синус угла <b>угла</b>",
	"func_sort": "Сортирует <b>массив</b> в порядке возрастания или убывания. Восходящий порядок по умолчанию:\n<ул>\n<li>Все логические значения (сначала false, затем true)</li>\n<li>Все числа (от меньшего к большему)</li>\n<li>Все каналы (в алфавитном порядке)</li>\n<li>Все нулевые элементы.</li>\n</ul>",
	"func_sort_arg_1": "Массив для сортировки.",
	"func_sort_arg_2": "#SORT_ASC для сортировки <b>массива</b> по возрастанию или #SORT_DESC для сортировки по убыванию.",
	"func_split": "Разбивает строку <b>строка</b> на подстроки, разделенные <b>разделителем</b>.",
	"func_split_arg_1": "Режущая цепь.",
	"func_split_arg_2": "Строка, разграничивающая переход от одного элемента к другому.",
	"func_split_arg_3": "Максимальное количество элементов массива.",
	"func_split_return": "Массив, содержащий найденные подстроки.",
	"func_sqrt": "Вычисляет квадратный корень из числа <b>число</b>.",
	"func_sqrt_arg_1": "Число, корень которого будет вычисляться.",
	"func_sqrt_return": "Квадратный корень из <b>числа</b>.",
	"func_startsWith": "Проверяет, начинается ли <b>строка</b> с <b>префикса</b>.",
	"func_startsWith_arg_1": "Строка поиска.",
	"func_startsWith_arg_2": "Искомый префикс.",
	"func_startsWith_return": "Истинно, если <b>строка</b> начинается с <b>префикса</b>.",
	"func_string": "Преобразует значение в строку.<br>\nЕсли значение уже является строкой, оно возвращается.<br>\nЕсли значение равно числу x, возвращается \"x\".<br>\nЕсли значение представляет собой массив, возвращается строка вида \"[ключ1: значение1, ключ2: значение2, ...]\".<br>\nЕсли значение является логическим, возвращаются значения \"true\" и \"false\" для значений \"истина\" и \"ложь\" соответственно.<br>\nЕсли значение равно null, возвращается \"null\".",
	"func_string_arg_1": "Значение для преобразования в строку символов.",
	"func_string_return": "Преобразованная строка.",
	"func_subArray": "Возвращает подмассив <b>массива</b>, начиная с позиции <b>начало</b> и заканчивая позицией <b>конец</b>.",
	"func_subArray_arg_1": "Исходная таблица.",
	"func_subArray_arg_2": "Начальный индекс.",
	"func_subArray_arg_3": "Конечный указатель.",
	"func_subArray_return": "Подмассив.",
	"func_substring": "Возвращает подстроку строки <b>string</b>, начиная с символа с индексом <b>start</b> и размером <b>length</b>.",
	"func_substring_arg_1": "Режущая цепь.",
	"func_substring_arg_2": "Индекс начального символа.",
	"func_substring_arg_3": "Длина подстроки.",
	"func_substring_return": "Подстрока.",
	"func_sum": "Возвращает сумму всех числовых элементов массива <b>array</b>.",
	"func_sum_arg_1": "Таблица для суммирования.",
	"func_summon": "Вызывает объект, определяемый чипом <b>chip</b> в ячейке <b>cell</b>, имеющей для IA функцию <b>ai</b>.",
	"func_summon_arg_1": "Чип, используемый для призыва. Чип должен быть чипом призыва и должен быть установлен на объекте, использующем функцию вызова.",
	"func_summon_arg_2": "Ячейка или вызов должны появиться.",
	"func_summon_arg_3": "Вызов ИИ как функции.",
	"func_summon_return": "Функция вызова имеет тот же результат, что и функция #useChip.",
	"func_sum_return": "Сумма элементов массива.",
	"func_tan": "Вычисляет тангенс угла <b>angle</b> (в радианах).",
	"func_tan_arg_1": "Угол в радианах.",
	"func_tan_return": "Тангенс <b>угла</b>.",
	"function_category_array": "Списки",
	"function_category_chip": "Блохи",
	"function_category_color": "Цвет",
	"function_category_fight": "Драться",
	"function_category_leek": "Лук-порей",
	"function_category_map": "Столы",
	"function_category_field": "Земля",
	"function_category_math": "Числа",
	"function_category_network": "Сеть",
	"function_category_string": "Цепи",
	"function_category_utils": "Утилиты",
	"function_category_weapon": "Оружие",
	"func_toDegrees": "Преобразует угол <b>радианы</b> в градусы.",
	"func_toDegrees_arg_1": "Значение в радианах",
	"func_toDegrees_return": "Угол в градусах.",
	"func_toLower": "Преобразует символы верхнего регистра в <b>string</b> в нижний регистр.",
	"func_toLower_arg_1": "Строка для преобразования",
	"func_toLower_return": "Строка нижнего регистра",
	"func_toRadians": "Преобразует угол <b>градусы</b> в радианы.",
	"func_toRadians_arg_1": "Значение в градусах",
	"func_toRadians_return": "Угол в радианах.",
	"func_toUpper": "Преобразует символы нижнего регистра в <b>string</b> в верхний регистр.",
	"func_toUpper_arg_1": "Строка для преобразования.",
	"func_toUpper_return": "Строка в верхнем регистре.",
	"func_typeOf": "Возвращает тип значения <b>значение</b> из следующих типов: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "Значение, тип которого будет возвращен.",
	"func_typeOf_return": "Тип <b>значения</b>.",
	"func_unshift": "Добавляет элемент <b>element</b> в начало <b>массива</b>.",
	"func_unshift_arg_1": "Массив, в который будет добавлен элемент.",
	"func_unshift_arg_2": "Элемент для добавления.",
	"func_useChip": "Использовать чип <b>chip</b> на объекте <b>entity</b>.",
	"func_useChip_arg_1": "Чип для использования.",
	"func_useChip_arg_2": "Целевая сущность.",
	"func_useChipOnCell": "Используйте чип <b>chip</b> на ячейке <b>cell</b>.",
	"func_useChipOnCell_arg_1": "Чип для использования.",
	"func_useChipOnCell_arg_2": "Целевая ячейка.",
	"func_useChipOnCell_return": "Значение больше 0, если атака была запущена.",
	"func_useChip_return": "Возвращаемые значения useChip:<br>\n<ул>\n<li>#USE_CRITICAL, при критическом попадании</li>\n<li>#USE_SUCCESS, в случае успеха</li>\n<li>#USE_FAILED, при ошибке</li>\n<li>#USE_INVALID_TARGET, если цель не существует</li>\n<li>#USE_NOT_ENOUGH_TP, если у вашего объекта недостаточно TP</li>\n<li>#USE_INVALID_COOLDOWN, если чип еще не используется</li>\n<li>#USE_INVALID_POSITION, если диапазон плохой или линия прямой видимости не ясна</li>\n</ul>",
	"func_useWeapon": "Используйте выбранное оружие на <b>объекте</b>.",
	"func_useWeapon_arg_1": "Целевая сущность.",
	"func_useWeaponOnCell": "Используйте выбранное оружие на ячейке <b>cell</b>.",
	"func_useWeaponOnCell_arg_1": "Целевая ячейка.",
	"func_useWeaponOnCell_return": "Значение больше 0, если атака была запущена.",
	"func_useWeapon_return": "Возвращаемые значения useWeapon:<br>\n<ул>\n<li>#USE_CRITICAL, при критическом попадании</li>\n<li>#USE_SUCCESS, в случае успеха</li>\n<li>#USE_FAILED, при ошибке</li>\n<li>#USE_INVALID_TARGET, если цель не существует</li>\n<li>#USE_NOT_ENOUGH_TP, если у вашего объекта недостаточно TP</li>\n<li>#USE_INVALID_POSITION, если диапазон плохой или линия прямой видимости не ясна</li>\n</ul>",
	"func_weaponNeedLos": "Возвращает информацию о том, нужна ли <b>оружию</b> линия прямой видимости для стрельбы.",
	"func_weaponNeedLos_arg_1": "ID оружия для тестирования.",
	"func_weaponNeedLos_return": "<i>true</i>, если для стрельбы <b>оружию</b> нужна прямая видимость, в противном случае <i>false</i>.",
	"items": "Предметы",
	"max_cores": "макс",
	"max_level": "макс",
	"min_cores": "Минимум сердец",
	"min_level": "Минимальный уровень",
	"n_operations": "<b>{0}</b> операций",
	"parameters": "Настройки",
	"return": "Обратная связь",
	"value": "Ценить",
	"variable_operations": "<b>Переменные</b> Операции",
	"arg_type_9": "пакет",
	"arg_type_10": "интервал",
	"function_category_set": "Пакеты",
	"function_category_interval": "Интервалы",
	"arg_type_96": "set<entier>"
}

	i18n.mergeLocaleMessage("ru", { "doc": messages })
	