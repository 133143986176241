
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> operacja",
	"arg_type_-1": "?",
	"arg_type_1": "numer",
	"arg_type_2": "łańcuch",
	"arg_type_3": "logiczna",
	"arg_type_4": "lista",
	"arg_type_41": "lista<liczba>",
	"arg_type_42": "lista<łańcuch>",
	"arg_type_43": "lista<wartość logiczna>",
	"arg_type_44": "lista<lista>",
	"arg_type_46": "lista<liczba całkowita>",
	"arg_type_47": "lista<rzeczywiste>",
	"arg_type_5": "funkcjonować",
	"arg_type_6": "cały",
	"arg_type_7": "prawdziwy",
	"arg_type_8": "tabela",
	"arg_type_806": "tabela<?, liczba całkowita>",
	"complexity": "Złożoność {c}",
	"operations": "{o} operacji",
	"const_AREA_CIRCLE_1": "Okrągły obszar o średnicy 3 kwadratów (krzyżyk).",
	"const_AREA_CIRCLE_2": "Okrągły obszar o średnicy 5 kwadratów.",
	"const_AREA_CIRCLE_3": "Okrągły obszar o średnicy 7 kwadratów.",
	"const_AREA_LASER_LINE": "Strefa lasera, linia od minimalnego zasięgu lasera do maksymalnego zasięgu lub przeszkoda.",
	"const_AREA_POINT": "Obszar składający się z jednego pudełka.",
	"const_CELL_EMPTY": "Zwracana wartość funkcji getCellContent(cell) dla pustej komórki.",
	"const_CELL_OBSTACLE": "Zwracana wartość getCellContent(cell) dla komórki zawierającej przeszkodę.",
	"const_CELL_PLAYER": "Zwracana wartość funkcji getCellContent(cell) dla komórki zawierającej jednostkę.",
	"const_CELL_ENTITY": "Zwracana wartość funkcji getCellContent(cell) dla komórki zawierającej jednostkę.",
	"const_COLOR_BLUE": "Kolor niebieski.",
	"const_COLOR_GREEN": "Zielony kolor.",
	"const_COLOR_RED": "Kolor czerwony.",
	"const_E": "Liczba Eulera.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Zapewnia absolutną tarczę jednostce, zmniejszając liczbę punktów wytrzymałości usuwanych przez obrażenia (EFFECT_DAMAGE) o ustaloną wartość. Wzmocniony przez opór.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Usuwa absolutną tarczę z istoty. Nie jest wzmacniany przez charakterystykę. Pozwala zwiększyć liczbę punktów życia usuwanych przez obrażenia (EFFECT_DAMAGE) o wartość bezwzględną.",
	"const_EFFECT_AFTEREFFECT": "Usuwa punkty życia z istoty. Wzmocnione przez naukę. Zmniejsza maksymalne punkty życia o 5% ilości usuniętych punktów życia.",
	"const_EFFECT_ANTIDOTE": "Usuwa całą truciznę (EFFECT_POISON) obecną na celu.",
	"const_EFFECT_BOOST_MAX_LIFE": "Zwiększa punkty życia i maksymalne punkty życia jednostki. Wzmocniony mądrością.",
	"const_EFFECT_BUFF_AGILITY": "Zapewnia zwinność podmiotowi. Wzmocnione przez naukę.",
	"const_EFFECT_BUFF_MP": "Przyznaje punkty ruchu podmiotowi. Wzmocnione przez naukę.",
	"const_EFFECT_BUFF_RESISTANCE": "Zapewnia odporność na istotę. Wzmocnione przez naukę.",
	"const_EFFECT_BUFF_STRENGTH": "Daje siłę jednostce. Wzmocnione przez naukę.",
	"const_EFFECT_BUFF_TP": "Przyznaje podmiotowi punkty akcji. Wzmocnione przez naukę.",
	"const_EFFECT_BUFF_WISDOM": "Obdaruj istotę mądrością. Wzmocnione przez naukę.",
	"const_EFFECT_DAMAGE": "Usuwa punkty życia z istoty. Wzmocnienie siły. Wchodzi w interakcje z tarczami (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), kradzieżą życia (z wyjątkiem rzucającego) i powrotem obrażeń (EFFECT_DAMAGE_RETURN). Zmniejsza maksymalne punkty życia o 5% ilości usuniętych punktów życia.",
	"const_EFFECT_DAMAGE_RETURN": "Zapewnia odbicie obrażeń podmiotowi, pozwalając na usunięcie punktów wytrzymałości podmiotom zadającym obrażenia beneficjentowi. Wzmocniony zwinnością. Zmniejsza maksymalne punkty życia o 5% ilości usuniętych punktów życia.",
	"const_EFFECT_DEBUFF": "Zmniejsza wartość wszystkich efektów na bycie o procent.",
	"const_EFFECT_HEAL": "Przywraca punkty wytrzymałości podmiotowi, ograniczone przez maksymalną liczbę punktów życia. Wzmocniony mądrością.",
	"const_EFFECT_INVERT": "Zamienia pozycję rzucającego z pozycją istoty.",
	"const_EFFECT_KILL": "Usuwa wszystkie punkty wytrzymałości z istoty.",
	"const_EFFECT_LIFE_DAMAGE": "Usuwa punkty życia z istoty, w oparciu o procent punktów życia rzucającego. Wchodzi w interakcję z tarczami (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) i zwraca obrażenia (EFFECT_DAMAGE_RETURN). Zmniejsza maksymalne punkty życia o 5% ilości usuniętych punktów życia.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "Efekt jest mnożony przez liczbę dotkniętych podmiotów na danym obszarze.",
	"const_EFFECT_MODIFIER_ON_CASTER": "Efekt nadal wpływa na rzucającego.",
	"const_EFFECT_MODIFIER_STACKABLE": "Efekt jest kumulatywny.",
	"const_EFFECT_NOVA_DAMAGE": "Usuwa maksymalne punkty życia. Wzmocnione przez naukę.",
	"const_EFFECT_POISON": "Usuwa punkty życia z istoty. Wzmocniony magią. Zmniejsza maksymalne punkty życia o 10% ilości usuniętych punktów życia.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Zapewnia absolutną tarczę jednostce, zmniejszając liczbę punktów wytrzymałości usuwanych przez obrażenia (EFFECT_DAMAGE) o ustaloną wartość. Nie do amplifikacji.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Zapewnia zwinność podmiotowi. Nie do amplifikacji.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Daje magię podmiotowi. Nie do amplifikacji.",
	"const_EFFECT_RAW_BUFF_MP": "Przyznaje punkty ruchu podmiotowi. Nie do amplifikacji.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Zapewnia naukę jednostce. Nie do amplifikacji.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Daje siłę jednostce. Nie do amplifikacji.",
	"const_EFFECT_RAW_BUFF_TP": "Przyznaje podmiotowi punkty akcji. Nie do amplifikacji.",
	"const_EFFECT_RELATIVE_SHIELD": "Zapewnia względną tarczę, zmniejszając liczbę punktów wytrzymałości usuwanych przez obrażenia (EFFECT_DAMAGE) o względną wartość. Wzmocniony przez opór.",
	"const_EFFECT_RESURRECT": "Wskrzesza istotę z maksymalną liczbą HP równą połowie maksymalnej liczby HP istoty przed wskrzeszeniem i aktualną liczbą HP równą jednej czwartej maksymalnej liczby HP przed wskrzeszeniem.",
	"const_EFFECT_SHACKLE_MAGIC": "Usuń magię z bytu. Wzmocniony magią.",
	"const_EFFECT_SHACKLE_MP": "Usuń punkty ruchu z obiektu. Wzmocniony magią.",
	"const_EFFECT_SHACKLE_STRENGTH": "Usuwa siłę z istoty. Wzmocniony magią.",
	"const_EFFECT_SHACKLE_TP": "Odejmij punkty akcji od jednostki. Wzmocniony magią.",
	"const_EFFECT_SUMMON": "Przywołuje żarówkę. Brak efektu, jeśli limit przywołania drużyny zostanie osiągnięty.",
	"const_EFFECT_TARGET_ALLIES": "Wpływa na sojuszników.",
	"const_EFFECT_TARGET_CASTER": "Wpływa na rzucającego.",
	"const_EFFECT_TARGET_ENEMIES": "Wpływa na wrogów.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Wpływa na nieprzywołane istoty (pory i wieżyczki).",
	"const_EFFECT_TARGET_SUMMONS": "Wpływa na przywołane istoty (żarówki).",
	"const_EFFECT_TELEPORT": "Zmienia pozycję rzucającego.",
	"const_EFFECT_VULNERABILITY": "Usuwa tarczę względem istoty. Nie jest wzmacniany przez charakterystykę. Zwiększa punkty życia usuwane przez obrażenia (EFFECT_DAMAGE) o względną wartość.",
	"const_ENTITY_BULB": "Odnosi się do jednostki typu Bulb.",
	"const_ENTITY_LEEK": "Odnosi się do podmiotu typu Leek.",
	"const_ENTITY_TURRET": "Odnosi się do jednostki typu Turret.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Tło walki typu wyzwanie.",
	"const_FIGHT_CONTEXT_GARDEN": "Kontekst bójki w ogródku warzywnym.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Kontekst walki w Battle Royale.",
	"const_FIGHT_CONTEXT_TEST": "Przetestuj kontekst bitwy.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Tło walki turniejowej.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Walcz w Battle Royale.",
	"const_FIGHT_TYPE_FARMER": "Walka hodowców.",
	"const_FIGHT_TYPE_SOLO": "Samotna walka.",
	"const_FIGHT_TYPE_TEAM": "Walka drużynowa.\n",
	"const_INSTRUCTIONS_LIMIT": "Maksymalna liczba instrukcji, których jednostka może użyć podczas swojej tury.",
	"const_MAX_TURNS": "Maksymalna liczba tur w walce.",
	"const_OPERATIONS_LIMIT": "Maksymalna liczba operacji, których jednostka może użyć podczas swojej tury.",
	"const_PI": "Stosunek obwodu koła do jego średnicy.",
	"const_SORT_ASC": "Nakazuje funkcji #sort sortowanie w porządku rosnącym.",
	"const_SORT_DESC": "Nakazuje funkcji #sort sortowanie w kolejności malejącej.",
	"const_TYPE_ARRAY": "Typ wartości <b>lista</b>",
	"const_TYPE_BOOLEAN": "Typ wartości <b>logiczna</b>",
	"const_TYPE_FUNCTION": "Typ wartości <b>funkcja</b>",
	"const_TYPE_NULL": "Typ wartości <b>null</b>",
	"const_TYPE_NUMBER": "Typ wartości <b>liczba</b>",
	"const_TYPE_STRING": "Typ wartości <b>ciąg znaków</b>",
	"const_USE_CRITICAL": "Wartość zwracana przez funkcje #useWeapon, #useWeaponOnCell, #useChip i #useChipOnCell w przypadku trafienia krytycznego.",
	"const_USE_FAILED": "Wartość zwracana przez funkcje #useWeapon, #useWeaponOnCell, #useChip i #useChipOnCell w przypadku niepowodzenia.",
	"const_USE_INVALID_COOLDOWN": "Wartość zwracana przez funkcje #useChip i #useChipOnCell, jeśli chip nie nadaje się jeszcze do użytku.",
	"const_USE_INVALID_POSITION": "Wartość zwracana przez funkcje #useWeapon, #useWeaponOnCell, #useChip i #useChipOnCell, jeśli zasięg jest zły lub linia wzroku nie jest wyraźna.",
	"const_USE_INVALID_TARGET": "Wartość zwracana przez funkcje #useWeapon i #useChip, jeśli cel nie istnieje.",
	"const_USE_NOT_ENOUGH_TP": "Wartość zwracana przez funkcje #useWeapon, #useWeaponOnCell, #useChip i #useChipOnCell, jeśli rzucający nie ma wystarczającej liczby punktów akcji, aby użyć obiektu.",
	"const_USE_RESURRECT_INVALID_ENTITY": "Wartość zwracana przez funkcję #resurrect, gdy określona jednostka nie istnieje lub nie jest jeszcze martwa.",
	"const_USE_SUCCESS": "Wartość zwracana przez funkcje #useWeapon, #useWeaponOnCell, #useChip i #useChipOnCell w przypadku powodzenia.",
	"const_USE_TOO_MANY_SUMMONS": "Błąd zwracany przez #summon, gdy masz już <b>8</b> wezwań na żywo.\n",
	"func_abs": "Zwraca wartość bezwzględną liczby <b>liczba</b>.",
	"func_abs_arg_1": "Liczba, której wartość bezwzględna zostanie obliczona.",
	"func_abs_return": "Wartość bezwzględna liczby.",
	"func_acos": "Oblicza arcus cosinus <b>argumentu</b> w przedziale [0, #PI].",
	"func_acos_arg_1": "Liczba, dla której cosinus arcus zostanie obliczony.",
	"func_acos_return": "Cosinus łuku <b>argumentu</b>.",
	"func_arrayConcat": "Dodaj dwie tablice od końca do końca. Klucze tekstowe są zachowywane, a wszystkie klawisze numeryczne są ponownie indeksowane.",
	"func_arrayConcat_arg_1": "Pierwszy stół.",
	"func_arrayConcat_arg_2": "Drugi stół.",
	"func_arrayConcat_return": "Suma dwóch tablic.",
	"func_arrayFilter": "Zwraca nową tablicę zawierającą wszystkie pary klucz/wartość z tablicy źródłowej, dla których funkcja wywołania zwrotnego zwróciła wartość true. Jeśli funkcja wywołania zwrotnego przyjmuje jeden parametr, zostanie wysłana wartość tablicy źródłowej, jeśli przyjmuje dwa parametry, zostanie wysłany klucz i wartość.",
	"func_arrayFilter_arg_1": "Stół oryginalny.",
	"func_arrayFilter_arg_2": "Funkcja wywołana dla każdego elementu.",
	"func_arrayFilter_return": "Nowy stół.",
	"func_arrayFlatten": "Zwraca nową tablicę zawierającą wszystkie elementy tablicy źródłowej. Wszystkie elementy zawarte w podtablicy są wyodrębniane do nowej tablicy Argument głębokości określa maksymalną głębokość podtablicy do wyodrębnienia.\n<kod>zmienna tablica = [1, 2, [[3, 4], 5], [6]];\ndebug(tablicaFlatten(tablica, 1)); // [1,2,[3,4],5,6]</code>",
	"func_arrayFlatten_arg_1": "Stół oryginalny.",
	"func_arrayFlatten_arg_2": "Maksymalna głębokość.",
	"func_arrayFlatten_return": "Nowy stół.",
	"func_arrayFoldLeft": "Zmniejsz tablicę <b>array</b> [v1, v2, ..., vn] od lewej strony, zaczynając od wartości <b>v0</b> i stosując funkcję <b>f</b >. Równa się :\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "Stół oryginalny.",
	"func_arrayFoldLeft_arg_2": "Funkcja do zastosowania.",
	"func_arrayFoldLeft_arg_3": "Wartość początkowa.",
	"func_arrayFoldLeft_return": "Zmniejszony stół.",
	"func_arrayFoldRight": "Zmniejsz tablicę <b>array</b> [v1, v2, ..., vn] od prawej strony, zaczynając od wartości <b>v0</b> i stosując funkcję <b>f</b >. Równa się :\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "Stół oryginalny.",
	"func_arrayFoldRight_arg_2": "Funkcja do zastosowania.",
	"func_arrayFoldRight_arg_3": "Wartość początkowa.",
	"func_arrayFoldRight_return": "Zmniejszony stół.",
	"func_arrayIter": "Wywołuje funkcję wywołania zwrotnego dla każdego elementu tablicy. Jeśli funkcja wywołania zwrotnego przyjmuje jeden parametr, zostanie wysłana wartość tablicy źródłowej, jeśli przyjmuje dwa parametry, zostanie wysłany klucz i wartość.",
	"func_arrayIter_arg_1": "Stół oryginalny.",
	"func_arrayIter_arg_2": "Funkcja wywołana dla każdego elementu.",
	"func_arrayMap": "Zwraca nową tablicę zawierającą dla każdego klucza tablicy źródłowej wartość zwróconą przez funkcję wywołania zwrotnego. Jeśli funkcja wywołania zwrotnego przyjmuje jeden parametr, zostanie wysłana wartość tablicy źródłowej, jeśli przyjmuje dwa parametry, zostanie wysłany klucz i wartość.",
	"func_arrayMap_arg_1": "Stół oryginalny.",
	"func_arrayMap_arg_2": "Funkcja wywołana dla każdego elementu.",
	"func_arrayMap_return": "Nowy stół.",
	"func_arrayMax": "Zwraca element wartości maksymalnej tablicy <b>tablica</b>.<br/>Aby uzyskać więcej informacji na temat maksymalnej wartości tablicy, zobacz #sort.",
	"func_arrayMax_arg_1": "Tablica, w której będzie poszukiwana maksymalna wartość.",
	"func_arrayMax_return": "Pozycja o maksymalnej wartości.",
	"func_arrayMin": "Zwraca element wartości minimalnej tablicy <b>tablica</b>.<br/>Więcej informacji na temat wartości minimalnej tablicy można znaleźć w artykule #sort.",
	"func_arrayMin_arg_1": "Tablica, w której będzie poszukiwana wartość minimalna.",
	"func_arrayMin_return": "Pozycja o minimalnej wartości.",
	"func_arrayPartition": "Zwraca nową tablicę zawierającą dwie listy, w pierwszej znajdują się wszystkie pary klucz/wartość, dla których funkcja wywołania zwrotnego zwróciła wartość true, w drugiej są wszystkie pozostałe. Jeśli funkcja wywołania zwrotnego przyjmuje jeden parametr, zostanie wysłana wartość tablicy źródłowej, jeśli przyjmuje dwa parametry, zostanie wysłany klucz i wartość.",
	"func_arrayPartition_arg_1": "Stół oryginalny.",
	"func_arrayPartition_arg_2": "Funkcja wywołana dla każdego elementu.",
	"func_arrayPartition_return": "Nowy stół.",
	"func_arraySort": "Sortuje tablicę zgodnie z kolejnością zdefiniowaną przez funkcję wywołania zwrotnego. Elementy są porównywane dwa po dwa, funkcja wywołania zwrotnego musi zwrócić wartości -1, 0 lub 1 w zależności od tego, czy pierwsza wartość jest przed, na tym samym poziomie czy po drugiej wartości. Jeśli funkcja wywołania zwrotnego przyjmuje 2 parametry, wysyłane są dwie wartości, jeśli przyjmuje 4, wysyłane są pary klucz/wartość.",
	"func_arraySort_arg_1": "Stół oryginalny",
	"func_arraySort_arg_2": "Funkcja sortowania.",
	"func_arraySort_return": "Posortowana tablica",
	"func_asin": "Oblicza arcus sinus <b>argumentu</b> w przedziale [0, #PI].",
	"func_asin_arg_1": "Liczba, której arcus sinus zostanie obliczony.",
	"func_asin_return": "Sinus łuku <b>argumentu</b>.",
	"func_assocSort": "Sortuje tablicę <b>tablica</b> zachowując skojarzenie klucz:wartość zgodnie z kolejnością <b>kolejność</b>.",
	"func_assocSort_arg_1": "Tablica do sortowania.",
	"func_assocSort_arg_2": "Kolejność sortowania: #SORT_ASC lub #SORT_DESC.",
	"func_atan": "Oblicza arcus tangens <b>argumentu</b> w przedziale [0, #PI].",
	"func_atan2": "Konwertuje współrzędne kartezjańskie (<b>x</b>, <b>y</b>) na współrzędne biegunowe (<b>r</b>, <b>theta</b>). Ta funkcja zwraca kąt <b>theta</b> między -#PI a #PI, używając znaków argumentów.",
	"func_atan2_arg_1": "Współrzędna y.",
	"func_atan2_arg_2": "Współrzędna x.",
	"func_atan2_return": "Kąt <b>theta</b> we współrzędnych biegunowych punktu (x, y).",
	"func_atan_arg_1": "Liczba, dla której arcus tangens zostanie obliczony.",
	"func_atan_return": "Arc tangens <b>argumentu</b>.",
	"func_average": "Oblicza średnią elementów zawartych w tablicy <b>tablica</b>.",
	"func_average_arg_1": "Tablica, której średnią chcemy obliczyć.",
	"func_average_return": "Średnia wartość.",
	"func_canUseChip": "Określa, czy Twoja jednostka może używać chipa <b>chip</b> w jednostce o identyfikatorze <b>entity</b>.",
	"func_canUseChip_arg_1": "Numer testowanego chipa.",
	"func_canUseChip_arg_2": "Identyfikator podmiotu, na którym chcesz użyć chipa.",
	"func_canUseChip_return": "<i>true</i>, jeśli Twoja jednostka może korzystać z chipa, <i>false</i> w przeciwnym razie.",
	"func_canUseChipOnCell": "Określa, czy Twoja jednostka może używać chipa <b>chip</b> w komórce <b>cell</b>.",
	"func_canUseChipOnCell_arg_1": "Numer testowanego chipa.",
	"func_canUseChipOnCell_arg_2": "Numer komórki, na której chcesz użyć chipa.",
	"func_canUseChipOnCell_return": "<i>true</i>, jeśli Twoja jednostka może korzystać z chipa, <i>false</i> w przeciwnym razie.",
	"func_canUseWeapon": "Określa, czy Twoja jednostka może strzelać do jednostki o identyfikatorze <b>entity</b> z broni <b>broń</b>.",
	"func_canUseWeapon_arg_1": "Broń do przetestowania. Domyślnie do aktualnie posiadanej broni.",
	"func_canUseWeapon_arg_2": "Identyfikator podmiotu, na który chcesz strzelać.",
	"func_canUseWeapon_return": "<i>true</i>, jeśli Twoja jednostka może strzelać, <i>false</i> w przeciwnym razie.",
	"func_canUseWeaponOnCell": "Określa, czy Twoja jednostka może strzelać do komórki <b>komórki</b> za pomocą broni typu <b>weapon</b>.",
	"func_canUseWeaponOnCell_arg_1": "Broń do przetestowania. Domyślnie do aktualnie posiadanej broni.",
	"func_canUseWeaponOnCell_arg_2": "Numer komórki, do której chcesz strzelać.",
	"func_canUseWeaponOnCell_return": "<i>true</i>, jeśli Twoja jednostka może strzelać, <i>false</i> w przeciwnym razie.",
	"func_cbrt": "Oblicz pierwiastek sześcienny z <b>liczby</b>",
	"func_cbrt_arg_1": "Liczba, której pierwiastek sześcienny chcemy obliczyć.",
	"func_cbrt_return": "Pierwiastek sześcienny z <b>liczby</b>.",
	"func_ceil": "Zwraca zaokrąglenie w górę <b>liczby</b>.<br/>Zaokrąglenie w dół można znaleźć za pomocą #floor, a zaokrąglenie za pomocą #round.",
	"func_ceil_arg_1": "Liczba, dla której chcemy znaleźć górne zaokrąglenie.",
	"func_ceil_return": "Zaokrąglij w górę <b>liczbę</b>.",
	"func_charAt": "Zwraca znak ciągu <b>łańcuch</b> znajdujący się na pozycji <b>pozycja</b>.<br/>Pierwszy znak ciągu znajduje się na pozycji 0.",
	"func_charAt_arg_1": "Ciąg, z którego chcemy pobrać znak.",
	"func_charAt_arg_2": "Pozycja postaci do znalezienia.",
	"func_charAt_return": "ciąg zawierający poszukiwany znak lub pusty ciąg, jeśli pozycja jest nieprawidłowa.",
	"func_chipNeedLos": "Zwraca informację, czy <b>chip</b> wymaga linii wzroku, aby mógł być użyty.",
	"func_chipNeedLos_arg_1": "Identyfikator chipa do przetestowania.",
	"func_chipNeedLos_return": "<i>prawda</i>, jeśli <b>chip</b> potrzebuje linii wzroku do użycia, <i>fałsz</i> w przeciwnym razie.",
	"func_clearMarks": "Usuwa wszystkie oznaczenia wykonane przez mark() i markText() w polu.",
	"func_clone": "Kopiuje <b>wartość</b> przekazaną jako parametr na poziomach <b>poziom</b> i zwraca kopię. Na przykład w przypadku tablicy klon(tablica, 1) skopiuje tablicę, ale nie jej elementy, klon(tablica, 2) skopiuje tablicę, jak również wszystkie elementy.",
	"func_clone_arg_1": "Wartość do sklonowania",
	"func_clone_arg_2": "Liczba poziomów do sklonowania",
	"func_clone_return": "Sklonowana wartość",
	"func_contains": "Określa, czy ciąg <b>wyszukiwany</b> mieści się w ciągu <b>łańcuch</b>.",
	"func_contains_arg_1": "Ciąg, w którym zostanie przeprowadzone wyszukiwanie.",
	"func_contains_arg_2": "Ciąg do wyszukania.",
	"func_contains_return": "true, jeśli <b>search</b> jest w <b>string</b>, false w przeciwnym razie.",
	"func_cos": "Oblicza cosinus <b>kąta</b>.",
	"func_cos_arg_1": "Liczba, której cosinus zostanie obliczony (w radianach).",
	"func_cos_return": "Cosinus <b>kąta</b> z zakresu [-1, 1].",
	"func_count": "Zlicza elementy w tablicy <b>array</b>.",
	"func_count_arg_1": "Tablica, której liczba elementów zostanie zliczona.",
	"func_count_return": "Liczba elementów tablicy.",
	"func_debug": "Zapisuje wiadomość <b>obiektową</b> w osobistym dzienniku, dostępną w raporcie po zakończeniu walki.",
	"func_debug_arg_1": "Wiadomość do nagrania.",
	"func_debugC": "Zapisuje wiadomość <b>obiektową</b> w osobistym dzienniku, dostępną w raporcie po zakończeniu walki, w kolorze <b>kolor</b>.",
	"func_debugC_arg_1": "Wiadomość do nagrania.",
	"func_debugC_arg_2": "Kolor wiadomości. Możesz użyć funkcji #getColor.",
	"func_debugE": "Zapisuje komunikat o błędzie <b>obiektu</b> w osobistym dzienniku, dostępnym w raporcie po zakończeniu walki.<br />Komunikaty o błędach pojawiają się w raporcie walki na czerwono.",
	"func_debugE_arg_1": "Komunikat o błędzie do zarejestrowania.",
	"func_debugW": "Zapisuje wiadomość ostrzegawczą o <b>obiekcie</b> w osobistym dzienniku, dostępną w raporcie po zakończeniu walki. <br />Komunikaty ostrzegawcze pojawiają się w raporcie bojowym na pomarańczowo.",
	"func_debugW_arg_1": "Komunikat ostrzegawczy do zapisania.",
	"func_deleteRegister": "Usuwa rejestr powiązany z kluczem <b>kluczem</b>, jeśli istnieje.",
	"func_deleteRegister_arg_1": "Klucz rejestru do usunięcia.",
	"func_endsWith": "Określa, czy <b>łańcuch znaków</b> kończy się ciągiem <b>sufiksu</b>.",
	"func_endsWith_arg_1": "Ciąg, w którym zostanie przeprowadzone wyszukiwanie",
	"func_endsWith_arg_2": "Sufiks do wyszukiwania",
	"func_endsWith_return": "true, jeśli <b>string</b> kończy się <b>sufiksem</b>",
	"func_exp": "Podnosi liczbę Eulera #E do potęgi <b>liczba</b>.",
	"func_exp_arg_1": "Wykładnik, do którego #E zostanie podniesiony.",
	"func_exp_return": "#E** <b>liczba</b>.",
	"func_fill": "Zmienia rozmiar tablicy <b>tablica</b> na <b>rozmiar</b> i wypełnia wszystkie jej pola <b>wartością</b>.",
	"func_fill_arg_1": "Tabela do uzupełnienia.",
	"func_fill_arg_2": "Wartość dla każdego kwadratu w tablicy.",
	"func_fill_arg_3": "Liczba pudełek. Domyślnie bieżący rozmiar tablicy.",
	"func_floor": "Oblicza zaokrąglenie w dół <b>liczby</b>.<br/>Zaokrąglenie w górę można znaleźć za pomocą #ceil, a zaokrąglenie za pomocą #round.",
	"func_floor_arg_1": "Liczba, której zaokrąglenie w dół chcemy znaleźć.",
	"func_floor_return": "Zaokrąglone w dół do <b>liczby</b>.",
	"func_getAbsoluteShield": "Zwraca absolutną osłonę encji o id <b>entity</b>. Aby bezpośrednio pobrać absolutną osłonę swojej jednostki, użyj #getAbsoluteShield() bez parametrów.",
	"func_getAbsoluteShield_arg_1": "Identyfikator podmiotu, którego absolutna osłona zostanie zwrócona.",
	"func_getAbsoluteShield_return": "Absolutna osłona podmiotu <b>entity</b>.",
	"func_getAgility": "Zwraca zwinność podmiotu o identyfikatorze <b>entity</b>. Aby bezpośrednio pobrać zwinność swojej jednostki, użyj #getAgility() bez parametrów.",
	"func_getAgility_arg_1": "Identyfikator podmiotu, którego zwinność zostanie zwrócona.",
	"func_getAgility_return": "Elastyczność podmiotu <b>entity</b>.",
	"func_getAIID": "Zwraca identyfikator AI encji <b>entity</b>. Użyj getAIID bez parametru, aby pobrać identyfikator swojej sztucznej inteligencji.",
	"func_getAIID_arg_1": "Identyfikator podmiotu, którego identyfikator AI zostanie zwrócony.",
	"func_getAIID_return": "Identyfikator sztucznej inteligencji encji <b>entity</b>.",
	"func_getAIName": "Zwraca nazwę sztucznej inteligencji podmiotu <b>entity</b>. Użyj funkcji getAINam() bez parametrów, aby pobrać nazwę swojej sztucznej inteligencji.",
	"func_getAIName_arg_1": "Identyfikator jednostki, której nazwa AI zostanie zwrócona.",
	"func_getAIName_return": "Nazwa encji <b>entity</b> AI.",
	"func_getAliveAllies": "Zwraca tablicę wszystkich twoich sojuszników żyjących w walce.",
	"func_getAliveAllies_return": "Tablica zawierająca identyfikatory wszystkich żyjących sojuszników.",
	"func_getAliveEnemies": "Zwraca tablicę wszystkich twoich wrogów żyjących w walce.",
	"func_getAliveEnemiesCount": "Zwraca liczbę wrogów żyjących w walce.",
	"func_getAliveEnemiesCount_return": "Liczba żywych wrogów.",
	"func_getAliveEnemies_return": "Tablica zawierająca identyfikatory wszystkich żyjących wrogów.",
	"func_getAllChips": "Zwraca listę wszystkich żetonów w grze.",
	"func_getAllChips_return": "Lista wszystkich żetonów w grze.",
	"func_getAllEffects": "Zwraca listę wszystkich efektów w grze.",
	"func_getAllEffects_return": "Lista wszystkich efektów w grze.",
	"func_getAllWeapons": "Zwraca listę wszystkich broni w grze.",
	"func_getAllWeapons_return": "Lista wszystkich broni w grze.",
	"func_getAlliedTurret": "Zwraca identyfikator wieży twojej drużyny lub -1, jeśli nie istnieje.",
	"func_getAlliedTurret_return": "Identyfikator wieży twojej drużyny.",
	"func_getEnemyTurret": "Zwraca identyfikator wieży wroga lub -1, jeśli nie istnieje.",
	"func_getEnemyTurret_return": "Identyfikator wieży wroga.",
	"func_getAllies": "Zwraca tablicę zawierającą twoich sojuszników i twoją jednostkę.",
	"func_getAlliesCount": "Zwraca liczbę sojuszników w walce.",
	"func_getAlliesCount_return": "Liczba sojuszników.",
	"func_getAlliesLife": "Zwraca całkowite zdrowie twoich sojuszników.",
	"func_getAlliesLife_return": "Całkowite zdrowie twoich sojuszników.",
	"func_getAllies_return": "Tabela sojuszników i twoja jednostka.",
	"func_getBirthTurn": "Zwraca kolejkę walki, w której pojawił się <b>istota</b>. Zwraca 1, jeśli jest to na przykład por, i 5, jeśli jest to wezwanie wywołane w 5 turze.",
	"func_getBirthTurn_arg_1": "Identyfikator podmiotu, którego tura odrodzenia zostanie zwrócona.",
	"func_getBirthTurn_return": "Tura walki, w której pojawił się <b>istota</b>.",
	"func_getBlue": "Zwraca ilość koloru niebieskiego w kolorze <b>kolor</b> z przedziału od 0 do 255. Na przykład getBlue(#COLOR_BLUE) = 255 i getBlue(#COLOR_GREEN) = 0.",
	"func_getBlue_arg_1": "Kolor, którego niebieska stawka zostanie zwrócona.",
	"func_getBlue_return": "Stopień nasycenia koloru niebieskiego w kolorze <b>color</b>",
	"func_getBulbChips": "Zwraca listę żetonów, które będą wyposażone w żarówkę przywołaną przez chip <b>bulb_chip</b>.",
	"func_getBulbChips_arg_1": "Pchła żarówki.",
	"func_getBulbChips_return": "Czipy żarówki przywołane przez chip <b>bulb_chip</b>.",
	"func_getCell": "Zwraca komórkę, w której znajduje się jednostka o identyfikatorze <b>encja</b>. Użyj getCell() bez parametrów, aby pobrać komórkę.",
	"func_getCell_arg_1": "Identyfikator jednostki, której komórka zostanie zwrócona.",
	"func_getCell_return": "Numer komórki, w której znajduje się <b>podmiot</b>.",
	"func_getCellContent": "Zwraca zawartość komórki o identyfikatorze <b>cell</b>.",
	"func_getCellContent_arg_1": "Identyfikator komórki, której zawartość zostanie zwrócona.",
	"func_getCellContent_return": "Zawartość komórki <b>cell</b>: #CELL_EMPTY dla pustej komórki, #CELL_ENTITY dla jednostki, #CELL_OBSTACLE dla przeszkody.",
	"func_getCellDistance": "Zwraca odległość między dwiema komórkami <b>cell1</b> i <b>cell2</b>. <br />Zwrócona odległość jest wyrażona w liczbie komórek i nie uwzględnia różnych przeszkód między dwiema komórkami.\nAby uzyskać odległość w linii prostej, zobacz #getDistance i aby uzyskać odległość ścieżki między dwiema komórkami unikając przeszkód, zobacz #getPathLength.",
	"func_getCellDistance_arg_1": "Identyfikator komórki początkowej.",
	"func_getCellDistance_arg_2": "Identyfikator komórki przybycia.",
	"func_getCellDistance_return": "Odległość między dwiema komórkami <b>cell1</b> i <b>cell2</b>.",
	"func_getCellFromXY": "Zwraca identyfikator komórki na pozycji (<b>x</b>, <b>y</b>).",
	"func_getCellFromXY_arg_1": "Pozycja x komórki.",
	"func_getCellFromXY_arg_2": "Pozycja y komórki.",
	"func_getCellFromXY_return": "Identyfikator komórki na pozycji (<b>x</b>, <b>y</b>), <b>null</b>, jeśli komórka nie istnieje.",
	"func_getCellsToUseChip": "Zwraca listę komórek, z których Twoja jednostka będzie mogła używać chipa <b>chip</b> na jednostce <b>entity</b>.",
	"func_getCellsToUseChip_arg_1": "Chip, którego jednostka chce móc używać.",
	"func_getCellsToUseChip_arg_2": "Jednostka docelowa.",
	"func_getCellsToUseChip_arg_3": "Tablica komórek do zignorowania.",
	"func_getCellsToUseChip_return": "Lista komórek, w których można użyć chipa.",
	"func_getCellsToUseChipOnCell": "Zwraca listę komórek, z których Twoja jednostka będzie mogła użyć <b>chipu</b> na komórce <b>cell</b>.",
	"func_getCellsToUseChipOnCell_arg_1": "Chip, którego jednostka chce móc używać.",
	"func_getCellsToUseChipOnCell_arg_2": "Komórka docelowa.",
	"func_getCellsToUseChipOnCell_arg_3": "Tablica komórek do zignorowania.",
	"func_getCellsToUseChipOnCell_return": "Lista komórek, w których można użyć chipa.",
	"func_getCellsToUseWeapon": "Zwraca listę komórek, z których Twoja jednostka będzie mogła użyć broni <b>broń</b> na jednostce <b>podmiot</b>.",
	"func_getCellsToUseWeapon_arg_1": "Broń do przetestowania. Domyślnie do aktualnie posiadanej broni.",
	"func_getCellsToUseWeapon_arg_2": "Jednostka docelowa.",
	"func_getCellsToUseWeapon_arg_3": "Tablica komórek do zignorowania. Domyślnie pusta tablica.",
	"func_getCellsToUseWeapon_return": "Lista komórek, z których można użyć broni.",
	"func_getCellsToUseWeaponOnCell": "Zwraca listę komórek, z których Twoja jednostka będzie mogła użyć broni <b>weapon</b> na komórce <b>cell</b>.",
	"func_getCellsToUseWeaponOnCell_arg_1": "Broń do przetestowania. Domyślnie do aktualnie posiadanej broni.",
	"func_getCellsToUseWeaponOnCell_arg_2": "Komórka docelowa.",
	"func_getCellsToUseWeaponOnCell_arg_3": "Tablica komórek do zignorowania. Domyślnie pusta tablica.",
	"func_getCellsToUseWeaponOnCell_return": "Lista komórek, z których można użyć broni.",
	"func_getCellX": "Określa pozycję w X komórki <b>cell</b>.",
	"func_getCellX_arg_1": "Komórka, której pozycja w X zostanie określona.",
	"func_getCellX_return": "Pozycja X komórki.",
	"func_getCellY": "Określa pozycję Y komórki <b>cell</b>.",
	"func_getCellY_arg_1": "Komórka, której pozycja w Y zostanie określona.",
	"func_getCellY_return": "Pozycja Y komórki.",
	"func_getChipArea": "Zwraca obszar typu efektu <b>chipu</b>.",
	"func_getChipArea_arg_1": "Chip, którego typ strefy zostanie zwrócony.",
	"func_getChipArea_return": "Typ obszaru chipa <b>chip</b> wśród stałych AREA_*:\n<ul>\n<li>#AREA_POINT: pojedynczy kwadratowy obszar</li>\n<li>#AREA_LASER_LINE: linia lasera</li>\n<li>#AREA_CIRCLE_1: okrągły obszar o średnicy 3 kwadratów</li>\n<li>#AREA_CIRCLE_2: okrągły obszar o średnicy 5 kwadratów</li>\n<li>#AREA_CIRCLE_3: okrągły obszar o średnicy 7 kwadratów</li>\n</ul>",
	"func_getChipCooldown": "Zwraca czas odzyskania <b>chipu</b> z rynku.",
	"func_getChipCooldown_arg_1": "Żeton, którego czas odnowienia zostanie zwrócony.",
	"func_getChipCooldown_return": "Czas odnowienia <b>żetonu</b>.",
	"func_getChipCost": "Zwraca koszt w TP chipa <b>chip</b>.",
	"func_getChipCost_arg_1": "Chip, którego koszt zostanie zwrócony.",
	"func_getChipCost_return": "Koszt <b>czipu</b>.",
	"func_getChipEffectiveArea": "Zwraca listę komórek, na które wpłynie to, jeśli chip <b>chip</b> zostanie użyty w komórce <b>cell</b> z komórki <b>z</b>.",
	"func_getChipEffectiveArea_arg_1": "Chip do przetestowania.",
	"func_getChipEffectiveArea_arg_2": "Komórka docelowa.",
	"func_getChipEffectiveArea_arg_3": "Komórka, z której używany jest chip.",
	"func_getChipEffectiveArea_return": "Tablica zawierająca identyfikatory wszystkich komórek, których to dotyczy.",
	"func_getChipEffects": "Zwraca efekty chipa <b>chip</b>.",
	"func_getChipEffects_arg_1": "Chip, którego efekty zostaną zwrócone.",
	"func_getChipEffects_return": "Skutki działania <b>chipu</b> chipa. Taka sama wartość zwracana jak w przypadku funkcji #getWeaponEffects.",
	"func_getChipFailure": "Zwraca procentowe ryzyko awarii chipa <b>chip</b>.",
	"func_getChipFailure_arg_1": "Chip, którego procent awarii zostanie zwrócony.",
	"func_getChipFailure_return": "<b>chip</b> procent awarii chipa, liczba całkowita z przedziału od <b>0</b> do <b>100</b>.",
	"func_getChipMaxRange": "Zwraca maksymalny zasięg chipa <b>chip</b>.",
	"func_getChipMaxRange_arg_1": "Chip, którego maksymalny zasięg zostanie zwrócony.",
	"func_getChipMaxRange_return": "Maksymalny zasięg <b>czipu</b>.",
	"func_getChipMaxScope": "Zwraca maksymalny zasięg chipa <b>chip</b>.",
	"func_getChipMaxScope_arg_1": "Chip, którego maksymalny zasięg zostanie zwrócony.",
	"func_getChipMaxScope_return": "Maksymalny zasięg <b>czipu</b>.",
	"func_getChipMinRange": "Zwraca minimalny zakres chipa <b>chip</b>.",
	"func_getChipMinRange_arg_1": "Chip, którego minimalny zasięg zostanie zwrócony.",
	"func_getChipMinRange_return": "Minimalny zasięg <b>chipu</b>.",
	"func_getChipMinScope": "Zwraca minimalny zakres chipa <b>chip</b>.",
	"func_getChipMinScope_arg_1": "Chip, którego minimalny zasięg zostanie zwrócony.",
	"func_getChipMinScope_return": "Minimalny zasięg <b>chipu</b>.",
	"func_getChipLaunchType": "Zwraca tryb uruchamiania układu <b>chip</b> wśród stałych LAUNCH_TYPE_*.",
	"func_getChipLaunchType_arg_1": "Identyfikator żetonu, którego tryb rzucania zostanie zwrócony.",
	"func_getChipLaunchType_return": "Tryb rzucania chipa <b>chip</b>.",
	"func_getChipName": "Zwraca nazwę chipa <b>chip</b>.",
	"func_getChipName_arg_1": "Chip, którego nazwa zostanie zwrócona.",
	"func_getChipName_return": "Nazwa układu <b>chip</b>.",
	"func_getChips": "Zwraca punktory encji o identyfikatorze <b>entity</b>.",
	"func_getChips_arg_1": "Identyfikator podmiotu, którego pociski zostaną zwrócone.",
	"func_getChips_return": "Tablica zawierająca identyfikatory punktorów encji <b>entity</b>.",
	"func_getChipTargets": "Zwraca jednostki, na które wpłynie to, jeśli chip <b>chip</b> zostanie użyty w komórce <b>komórka</b>.",
	"func_getChipTargets_arg_1": "Chip do przetestowania.",
	"func_getChipTargets_arg_2": "Komórka docelowa.",
	"func_getChipTargets_return": "Tablica zawierająca identyfikatory wszystkich jednostek, których to dotyczy.",
	"func_getColor": "Zwraca liczbę całkowitą odpowiadającą kolorowi (<b>czerwony</b>, <b>zielony</b>, <b>niebieski</b>) podanym jako parametry.",
	"func_getColor_arg_1": "Czerwona wartość między 0 a 255.",
	"func_getColor_arg_2": "Zielona wartość od 0 do 255.",
	"func_getColor_arg_3": "Niebieska wartość między 0 a 255.",
	"func_getColor_return": "int odpowiadający kolorowi podanemu jako parametr.",
	"func_getCooldown": "Zwraca bieżący czas odnowienia <b>żetonu</b> podmiotu <b>podmiot</b>.",
	"func_getCooldown_arg_1": "Żeton, którego aktualny czas odnowienia zostanie zwrócony.",
	"func_getCooldown_arg_2": "Istota, której czas odnowienia zostanie zwrócony.",
	"func_getCooldown_return": "Bieżący czas odnowienia żetonu <b>żeton</b>, to liczba tur, zanim żeton stanie się użyteczny, <b>0</b>, jeśli jest aktualnie użyteczny.",
	"func_getCores": "Zwraca liczbę rdzeni podmiotu o identyfikatorze <b>entity</b>.",
	"func_getCores_arg_1": "Jednostka, której liczba rdzeni zostanie zwrócona.",
	"func_getCores_return": "Liczba rdzeni jednostki <b>entity</b>.",
	"func_getDate": "Zwraca datę walki w formacie dd/MM/rrrr.",
	"func_getDate_return": "Data walki.",
	"func_getTime": "Zwraca czas bitwy w formacie HH:mm:ss.",
	"func_getTime_return": "Czas walki.",
	"func_getTimestamp": "Zwraca znacznik czasu walki równy liczbie sekund od 1 stycznia 1970 r.",
	"func_getTimestamp_return": "Znacznik czasu walki.",
	"func_getDamageReturn": "Zwraca współczynnik zwrotu obrażeń podmiotu o identyfikatorze <b>entity</b>.",
	"func_getDamageReturn_arg_1": "Identyfikator podmiotu, z którego zostanie zwrócone zgłoszenie szkody.",
	"func_getDamageReturn_return": "Współczynnik zwrotu szkody podmiotu o id <b>entity</b> (w %).",
	"func_getDeadAllies": "Zwraca martwe przyjazne istoty.",
	"func_getDeadAllies_return": "Tabela sprzymierzonych martwych istot.",
	"func_getDeadEnemies": "Zwraca martwe wrogie istoty.",
	"func_getDeadEnemiesCount": "Zwraca liczbę wrogów, którzy zginęli w walce.",
	"func_getDeadEnemiesCount_return": "Liczba zabitych wrogów.",
	"func_getDeadEnemies_return": "Tabela martwych bytów wroga.",
	"func_getDistance": "Oblicza odległość w linii prostej między dwiema komórkami <b>cell1</b> i <b>cell2</b>. <br />Aby uzyskać odległość wyrażoną w liczbie komórek, zobacz #getCellDistance, a aby uzyskać długość ścieżki między dwiema komórkami omijającymi różne przeszkody, zobacz #getPathLength.",
	"func_getDistance_arg_1": "Komórka startowa.",
	"func_getDistance_arg_2": "Cela przybycia.",
	"func_getDistance_return": "Odległość w linii prostej między dwiema komórkami.",
	"func_getPassiveEffects": "Zwraca listę efektów pasywnych encji o id <b>entity</b>. Aby bezpośrednio pobrać listę pasywnych efektów swojej jednostki, użyj #getPassiveEffects() bez parametru.",
	"func_getPassiveEffects_arg_1": "Identyfikator podmiotu, którego lista pasywnych efektów zostanie zwrócona.",
	"func_getPassiveEffects_return": "Lista pasywnych efektów obecnych obecnie w elemencie <b>entity</b>.\nLista efektów pasywnych jest tablicą zawierającą efekty.\nEfekt sam w sobie jest tablicą 7 pól o postaci: [<b>typ</b>, <b>wartość</b>, <b>identyfikator rzucającego</b>, <b>zwroty</b >, <b>krytyczne</b>, <b>identyfikator_pozycji</b>, <b>identyfikator_celu</b>]. Jest to ta sama struktura, co klasyczne efekty zwracane przez funkcję #getEffects.",
	"func_getEnemies": "Przywraca wrogie istoty (żywe lub martwe) do walki.",
	"func_getEnemiesCount": "Zwraca liczbę wrogów w walce.",
	"func_getEnemiesCount_return": "Liczba wrogów.",
	"func_getEnemiesLife": "Oblicza sumę punktów życia wszystkich wrogich jednostek.",
	"func_getEnemiesLife_return": "Suma punktów wytrzymałości drużyny przeciwnej.",
	"func_getEnemies_return": "Tablica zawierająca identyfikatory wszystkich wrogich jednostek.",
	"func_getEntityTurnOrder": "Zwraca wartość z przedziału od 1 do n (liczba podmiotów aktualnie w grze), wskazując pozycję <b>obiektu</b> w kolejności odtwarzania.",
	"func_getEntityTurnOrder_arg_1": "Identyfikator podmiotu, którego zlecenie odtwarzania zostanie zwrócone",
	"func_getEntityTurnOrder_return": "Umieść w kolejności gry podmiot <b>podmiot</b>",
	"func_getFarmerID": "Zwraca identyfikator hodowcy podmiotu <b>entity</b>.",
	"func_getFarmerID_arg_1": "Identyfikator podmiotu, którego identyfikator hodowcy zostanie zwrócony.",
	"func_getFarmerID_return": "Identyfikator hodowcy encji <b>entity</b>.",
	"func_getFarmerCountry": "Zwraca kraj hodowcy encji <b>entity</b>.",
	"func_getFarmerCountry_arg_1": "Identyfikator podmiotu, którego kraj hodowcy zostanie zwrócony.",
	"func_getFarmerCountry_return": "Kraj hodowcy podmiotu <b>podmiot</b> lub „?” jeśli nie wskazano.",
	"func_getFarmerName": "Zwraca nazwę hodowcy encji <b>entity</b>.",
	"func_getFarmerName_arg_1": "Identyfikator podmiotu, którego nazwa hodowcy zostanie zwrócona.",
	"func_getFarmerName_return": "Nazwa hodowcy encji <b>entity</b>.",
	"func_getFarthestAlly": "Określa najdalszego sojusznika twojej istoty w linii prostej.",
	"func_getFarthestAlly_return": "Identyfikator najdalszej przyjaznej jednostki.",
	"func_getFarthestEnemy": "Określa najdalszego wroga od twojej istoty w linii prostej.",
	"func_getFarthestEnemy_return": "Identyfikator najdalszego wrogiego podmiotu.",
	"func_getFightID": "Zwraca bieżący identyfikator walki.",
	"func_getFightID_return": "Identyfikator bieżącej walki.",
	"func_getFightContext": "Zwraca kontekst bieżącej walki.",
	"func_getFightContext_return": "W zależności od kontekstu bitwy: Bitwa testowa (#FIGHT_CONTEXT_TEST), Bitwa na arenie (#FIGHT_CONTEXT_GARDEN), Bitwa turniejowa (#FIGHT_CONTEXT_TOURNAMENT), Bitwa z wyzwaniem (#FIGHT_CONTEXT_CHALLENGE), Battle Royale (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "Zwraca bieżący typ walki.",
	"func_getFightType_return": "W zależności od rodzaju walki: walka solo (#FIGHT_TYPE_SOLO), walka farmera (#FIGHT_TYPE_FARMER), walka drużynowa (#FIGHT_TYPE_TEAM), Battle Royale (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "Zwraca siłę encji o id <b>entity</b>.",
	"func_getForce_arg_1": "Istota, której siła zostanie zwrócona.",
	"func_getForce_return": "Siła podmiotu <b>entity</b>.",
	"func_getFrequency": "Zwraca częstotliwość encji o id <b>entity</b>. Użyj getFrequency() bez parametrów, aby pobrać swoją częstotliwość.",
	"func_getFrequency_arg_1": "Jednostka, której częstotliwość zostanie zwrócona.",
	"func_getFrequency_return": "Częstotliwość encji <b>entity</b>.",
	"func_getGreen": "Zwraca ilość koloru zielonego w kolorze <b>kolor</b> z przedziału od 0 do 255. Na przykład getGreen(#COLOR_GREEN) = 255 i getGreen(#COLOR_RED) = 0.",
	"func_getGreen_arg_1": "Kolor, którego zielona stawka zostanie zwrócona.",
	"func_getGreen_return": "Szybkość koloru zielonego w kolorze <b>color</b>",
	"func_getInstructionsCount": "Zwraca liczbę instrukcji, które Twoja jednostka wykonała w bieżącej turze.",
	"func_getInstructionsCount_return": "Liczba instrukcji, które Twoja jednostka wykonała w bieżącej turze.",
	"func_getLaunchedEffects": "Zwraca listę efektów wywołanych przez obiekt o identyfikatorze <b>entity</b>.",
	"func_getLaunchedEffects_arg_1": "Identyfikator podmiotu, którego lista wywołanych skutków zostanie zwrócona.",
	"func_getLaunchedEffects_return": "Lista efektów wywołanych przez obiekt o identyfikatorze <b>entity</b>, w takiej samej formie jak tablica zwrócona przez #getEffects.",
	"func_getLeek": "Zwraca identyfikator Twojej jednostki.",
	"func_getLeek_return": "Identyfikator Twojego podmiotu.",
	"func_getEntity": "Zwraca identyfikator Twojej jednostki.",
	"func_getEntity_return": "Identyfikator Twojego podmiotu.",
	"func_getLeekID": "Zwraca rzeczywisty identyfikator pora z id <b>leek</b>.",
	"func_getLeekID_arg_1": "Identyfikator pora, którego rzeczywisty identyfikator zostanie zwrócony.",
	"func_getLeekID_return": "Prawdziwy identyfikator pora <b>podmiotu</b>.",
	"func_getLeekOnCell": "Zwraca jednostkę znajdującą się w komórce <b>cell</b>.",
	"func_getLeekOnCell_arg_1": "Komórka, której obiekt chcemy pobrać.",
	"func_getLeekOnCell_return": "Identyfikator jednostki w komórce lub -1, jeśli komórka nie ma jednostki.",
	"func_getEntityOnCell": "Zwraca jednostkę znajdującą się w komórce <b>cell</b>.",
	"func_getEntityOnCell_arg_1": "Komórka, której obiekt chcemy pobrać.",
	"func_getEntityOnCell_return": "Identyfikator jednostki w komórce lub -1, jeśli komórka nie ma jednostki.",
	"func_getLevel": "Zwraca poziom encji z id <b>entity</b>.",
	"func_getLevel_arg_1": "Identyfikator podmiotu, którego poziom zostanie zwrócony.",
	"func_getLevel_return": "Poziom podmiotu o identyfikatorze <b>entity</b>.",
	"func_getLife": "Zwraca bieżący czas życia podmiotu o identyfikatorze <b>entity</b>. Użyj getLife() bez parametrów, aby odzyskać swoje życie.",
	"func_getLife_arg_1": "Identyfikator podmiotu, którego życie zostanie zwrócone.",
	"func_getLife_return": "Bieżący czas życia encji <b>entity</b>.",
	"func_getMagic": "Zwraca magię obiektu o identyfikatorze <b>entity</b>. Użyj getMagic() bez parametrów, aby odzyskać swoją magię.",
	"func_getMagic_arg_1": "Identyfikator podmiotu, którego magia zostanie zwrócona.",
	"func_getMagic_return": "Magia identyfikatora encji <b>jednostki</b>.",
	"func_getMapType": "Zwraca rodzaj terenu, na którym toczy się walka (fabryka, pustynia, las itp.), wśród stałych #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_LAS, #MAP_GLACIER i #MAP_BEACH.",
	"func_getMapType_return": "Rodzaj terenu.",
	"func_getMessageAuthor": "Zwraca identyfikator podmiotu będącego autorem wiadomości <b>message</b>.",
	"func_getMessageAuthor_arg_1": "Wiadomość, której autor zostanie zwrócony.",
	"func_getMessageAuthor_return": "Identyfikator podmiotu tworzącego wiadomość <b>message</b>.",
	"func_getMessageParams": "Zwraca tablicę parametrów komunikatu <b>message</b>.",
	"func_getMessageParams_arg_1": "Wiadomość, której parametry zostaną zwrócone.",
	"func_getMessageParams_return": "Parametry wiadomości <b>message</b>.",
	"func_getMessages": "Zwraca tablicę komunikatów encji <b>entity</b>.",
	"func_getMessages_arg_1": "Jednostka, której komunikaty zostaną zwrócone.",
	"func_getMessages_return": "Tabela Twoich wiadomości.<br>Wiadomość jest reprezentowana w formie a\ntablica postaci: [<b>autor</b>, <b>typ</b>, <b>parametry</b>]<br>\nRóżne typy komunikatów są reprezentowane przez stałe:\n<ul>\n<li>#MESSAGE_HEAL: prośba o opiekę</li>\n<li>#MESSAGE_ATTACK: prośba o atak</li>\n<li>#MESSAGE_BUFF_FORCE: wymuś żądanie przyspieszenia</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "Zwraca typ wiadomości <b>message</b>.",
	"func_getMessageType_arg_1": "Wiadomość, której typ zostanie zwrócony (między #MESSAGE_HEAL, #MESSAGE_ATTACK itp.).",
	"func_getMessageType_return": "Typ wiadomości <b>wiadomość</b>.",
	"func_getMP": "Zwraca bieżącą liczbę punktów ruchu obiektu <b>entity</b>. Użyj getMP() bez parametrów, aby pobrać swoje PM.",
	"func_getMP_arg_1": "Identyfikator podmiotu, którego liczba PM zostanie zwrócona.",
	"func_getMP_return": "Numer MP podmiotu <b>entity</b>.",
	"func_getName": "Zwraca nazwę podmiotu o identyfikatorze <b>entity</b>.",
	"func_getName_arg_1": "Identyfikator podmiotu, którego nazwa zostanie zwrócona.",
	"func_getName_return": "Nazwa podmiotu <b>podmiot</b>.",
	"func_getNearestAlly": "Zwraca najbliższą przyjazną istotę twojej jednostce.",
	"func_getNearestAlly_return": "Identyfikator najbliższej przyjaznej jednostki.",
	"func_getNearestAllyTo": "Zwraca najbliższą jednostkę pokrewną jednostce podanej jako parametr.",
	"func_getNearestAllyTo_arg_1": "Identyfikator podmiotu, którego najbliższego sojusznika chcemy poznać.",
	"func_getNearestAllyToCell": "Zwraca najbliższą sprzymierzoną jednostkę do komórki podanej jako parametr.",
	"func_getNearestAllyToCell_arg_1": "Identyfikator komórki, której najbliższego sojusznika chcemy poznać.",
	"func_getNearestAllyToCell_return": "Identyfikator najbliższej przyjaznej jednostki.",
	"func_getNearestAllyTo_return": "Identyfikator najbliższej przyjaznej jednostki.",
	"func_getNearestEnemy": "Zwraca najbliższy wrogi byt do twojego bytu.",
	"func_getNearestEnemy_return": "Identyfikator najbliższego wrogiego podmiotu.",
	"func_getNearestEnemyTo": "Zwraca najbliższą wrogą jednostkę jednostce podanej jako parametr.",
	"func_getNearestEnemyTo_arg_1": "Identyfikator podmiotu, którego najbliższego wroga chcemy poznać.",
	"func_getNearestEnemyToCell": "Zwraca najbliższą wrogą jednostkę do komórki podanej jako parametr.",
	"func_getNearestEnemyToCell_arg_1": "Identyfikator komórki, której najbliższego wroga chcemy poznać.",
	"func_getNearestEnemyToCell_return": "Identyfikator najbliższego wrogiego podmiotu.",
	"func_getNearestEnemyTo_return": "Identyfikator najbliższego wrogiego podmiotu.",
	"func_getNextPlayer": "Zwraca identyfikator podmiotu, który będzie grał po bieżącym graczu.",
	"func_getNextPlayer_return": "Następny gracz.",
	"func_getObstacles": "Zwraca listę pól przeszkód w terenie.",
	"func_getObstacles_return": "Tablica zawierająca identyfikatory komórek przeszkód.",
	"func_getOperations": "Zwraca liczbę operacji wykorzystanych przez twoją jednostkę od początku jej tury. Ta liczba musi być mniejsza niż #OPERATIONS_LIMIT, aby jednostka nie uległa awarii.",
	"func_getOperations_return": "Liczba operacji wykorzystanych przez twoją jednostkę od początku jej tury.",
	"func_getPath": "Zwraca ścieżkę omijającą przeszkody między dwiema komórkami <b>cell1</b> i <b>cell2</b>, jeśli istnieje, ignorując komórki zawarte w tablicy <b>ignoredCells</b> . Jeśli gracz znajduje się w ignorowanej komórce, ścieżka może go ominąć.<br /><br />\nKomórka początkowa <b>cell1</b> nigdy nie jest częścią ścieżki wynikowej. Komórka <b>cell2</b> jest częścią ścieżki wynikowej wtedy i tylko wtedy, gdy jest pusta lub ignorowana przez <b>ignoredCells</b>.<br /><br />\nJeśli między dwiema komórkami nie ma ścieżki, <b>getPath</b> zwraca wartość <i>null</i>.",
	"func_getPath_arg_1": "Komórka startowa.",
	"func_getPath_arg_2": "Cela przybycia.",
	"func_getPath_arg_3": "Tablica komórek do zignorowania.",
	"func_getPath_return": "Tablica zawierająca komórki tworzące ścieżkę między dwiema komórkami.",
	"func_getPathLength": "Zwraca długość ścieżki między dwiema komórkami <b>cell1</b> i <b>cell2</b>, omijając przeszkody, ignorując komórki zawarte w tablicy <b>ignoredCells</b>. Ta funkcja jest równoważna funkcji <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>.\nJeśli gracz znajduje się w ignorowanej komórce, ścieżka może go ominąć.<br /><br />\nKomórka początkowa <b>cell1</b> nigdy nie jest wliczana do wyniku. Komórka <b>cell2</b> jest liczona w wyniku wtedy i tylko wtedy, gdy jest pusta lub ignorowana przez <b>ignoredCells</b>.<br /><br />\nJeśli między dwiema komórkami nie ma ścieżki, <b>getPathLength</b> zwraca wartość <i>null</i>.",
	"func_getPathLength_arg_1": "Komórka startowa.",
	"func_getPathLength_arg_2": "Cela przybycia.",
	"func_getPathLength_arg_3": "Tablica komórek do zignorowania.",
	"func_getPathLength_return": "Długość ścieżki między <b>komórką1</b> a <b>komórką2</b>.",
	"func_getPreviousPlayer": "Zwraca identyfikator podmiotu, który grał przed bieżącym graczem.",
	"func_getPreviousPlayer_return": "Poprzedni gracz.",
	"func_getRed": "Zwraca ilość czerwieni w kolorze <b>kolor</b> z przedziału od 0 do 255. Na przykład getRed(#COLOR_RED) = 255 i getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "Kolor, którego czerwona stawka zostanie zwrócona.",
	"func_getRed_return": "Współczynnik czerwieni w <b>kolorze</b>",
	"func_getRegister": "Zwraca wartość przechowywaną w rejestrze jednostek powiązanym z kluczem <b>klucz</b> lub </i>null</i>, jeśli rejestr nie istnieje.",
	"func_getRegister_arg_1": "Klucz rejestru, którego wartość zostanie zwrócona.",
	"func_getRegister_return": "Wartość przechowywana w rejestrze <b>kluczy</b>.",
	"func_getRegisters": "Zwraca zestaw rejestrów jednostek jako tablicę asocjacyjną [<i>klucz rejestru</i>:<i>wartość rejestru</i>]. Przykład: <kod>debug(getRegisters());\n// Wyświetla na przykład:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "Tablica asocjacyjna odpowiadająca wszystkim rejestrom jednostki.",
	"func_getRelativeShield": "Zwraca względną osłonę encji o identyfikatorze <b>entity</b>. Aby bezpośrednio pobrać względną osłonę swojej jednostki, użyj #getRelativeShield() bez parametrów.",
	"func_getRelativeShield_arg_1": "Identyfikator podmiotu, którego względna osłona zostanie zwrócona.",
	"func_getRelativeShield_return": "Względna osłona elementu <b>jednostka</b>, liczba całkowita z przedziału od <b>0</b> do <b>100</b>.",
	"func_getResistance": "Zwraca opór obiektu o id <b>entity</b>. Użyj getResistance() bez parametrów, aby pobrać swój opór.",
	"func_getResistance_arg_1": "Identyfikator podmiotu, którego opór zostanie zwrócony.",
	"func_getResistance_return": "Opór podmiotu o identyfikatorze <b>entity</b>.",
	"func_getScience": "Zwraca wartość naukową podmiotu o identyfikatorze <b>entity</b>. Użyj getScience() bez parametrów, aby pobrać swoją naukę.",
	"func_getScience_arg_1": "Identyfikator podmiotu, którego nauka zostanie zwrócona.",
	"func_getScience_return": "Nauka o id encji <b>jednostka</b>.",
	"func_getStrength": "Zwraca siłę encji o id <b>entity</b>. Użyj getStrength() bez parametrów, aby odzyskać swoją siłę.",
	"func_getStrength_arg_1": "Istota, której siła zostanie zwrócona.",
	"func_getStrength_return": "Siła podmiotu <b>entity</b>.",
	"func_getPower": "Zwraca potęgę jednostki o identyfikatorze <b>entity</b>.",
	"func_getPower_arg_1": "Podmiot, którego moc zostanie zwrócona.",
	"func_getPower_return": "Siła <b>podmiotu</b>.",
	"func_getSummoner": "Zwraca obiekt, który go wywołał, <b>obiekt</b>, jeśli jest to wywołanie.",
	"func_getSummoner_arg_1": "Identyfikator podmiotu, którego przywoływacz zostanie zwolniony.",
	"func_getSummoner_return": "Identyfikator wywołanej jednostki <b>encja</b>, jeśli jest to wywołanie, <i>null</i> w przeciwnym razie.",
	"func_getSummons": "Zwraca listę identyfikatorów aktualnie aktywnych wywołań encji o id <b>entity</b>.",
	"func_getSummons_arg_1": "Identyfikator podmiotu, którego wezwanie zostanie zwrócone.",
	"func_getSummons_return": "Lista identyfikatorów wywołań encji o id <b>entity</b>.",
	"func_getTeamID": "Zwraca identyfikator zespołu encji <b>entity</b>.",
	"func_getTeamID_arg_1": "Identyfikator podmiotu, którego identyfikator zespołu zostanie zwrócony.",
	"func_getTeamID_return": "Identyfikator zespołu podmiotu <b>entity</b>.",
	"func_getTeamName": "Zwraca nazwę zespołu podmiotu <b>entity</b>.",
	"func_getTeamName_arg_1": "Identyfikator podmiotu, którego nazwa zespołu zostanie zwrócona.",
	"func_getTeamName_return": "Nazwa zespołu podmiotu <b>entity</b>.",
	"func_getTotalLife": "Zwraca całkowity czas życia podmiotu o identyfikatorze <b>entity</b>. Użyj funkcji getTotalLife() bez parametrów, aby pobrać całkowite życie.",
	"func_getTotalLife_arg_1": "Identyfikator jednostki, której całkowity czas życia zostanie zwrócony.",
	"func_getTotalLife_return": "Całkowity czas życia jednostki.",
	"func_getTotalMP": "Zwraca maksymalną liczbę punktów ruchu obiektu <b>entity</b>.",
	"func_getTotalMP_arg_1": "Identyfikator podmiotu, którego maksymalna liczba punktów ruchu zostanie zwrócona.",
	"func_getTotalMP_return": "Maksymalna liczba punktów ruchu dla <b>podmiotu</b>.",
	"func_getTotalTP": "Zwraca maksymalną liczbę punktów zwrotnych obiektu <b>entity</b>.",
	"func_getTotalTP_arg_1": "Identyfikator podmiotu, którego maksymalna liczba punktów zwrotnych zostanie zwrócona.",
	"func_getTotalTP_return": "Maksymalna liczba punktów zwrotnych obiektu <b>entity</b>.",
	"func_getTP": "Zwraca liczbę punktów zwrotnych obiektu <b>entity</b>. Użyj getTP() bez parametrów, aby pobrać swoje PT.",
	"func_getTP_arg_1": "Identyfikator podmiotu, którego PT zostaną zwrócone.",
	"func_getTP_return": "Numer PT podmiotu <b>entity</b>.",
	"func_getTurn": "Zwraca bieżącą turę walki. Maksymalna liczba tur to #MAX_TURNS.",
	"func_getTurn_return": "Obecna tura walki.",
	"func_getType": "Zwraca typ encji encji <b>jednostka</b>.",
	"func_getType_arg_1": "Identyfikator jednostki, której typ zostanie zwrócony.",
	"func_getType_return": "Typ jednostki <b>podmiotu</b>:<ul><li>#ENTITY_LEEK, jeśli jest to por.</li><li>#ENTITY_BULB, jeśli jest to „cebula”.</li><li >#ENTITY_TURRET, jeśli jest to wieżyczka.</li></ul>",
	"func_getWeapon": "Zwraca aktualnie wyposażoną broń <b>podmiot</b>.",
	"func_getWeapon_arg_1": "Identyfikator podmiotu, którego aktualna broń zostanie zwrócona.",
	"func_getWeapon_return": "Identyfikator aktualnie wyposażonej broni w <b>jednostce</b>, null, jeśli jednostka nie ma wyposażonej broni lub jednostka nie istnieje.",
	"func_getWeaponArea": "Zwraca obszar typu efektu broni <b>weapon</b>.",
	"func_getWeaponArea_arg_1": "Broń, której typ obszaru zostanie zwrócony.",
	"func_getWeaponArea_return": "Typ obszaru broni <b>weapon</b> wśród stałych AREA_*:\n<ul>\n<li>#AREA_POINT: pojedynczy kwadratowy obszar</li>\n<li>#AREA_LASER_LINE: linia lasera</li>\n<li>#AREA_CIRCLE_1: okrągły obszar o średnicy 3 komórek</li>\n<li>#AREA_CIRCLE_2: okrągły obszar o średnicy 5 kwadratów</li>\n<li>#AREA_CIRCLE_3: okrągły obszar o średnicy 7 kwadratów</li>\n</ul>",
	"func_getWeaponCost": "Zwraca koszt TP broni <b>weapon</b>.",
	"func_getWeaponCost_arg_1": "Identyfikator broni, której koszt zostanie zwrócony.",
	"func_getWeaponCost_return": "Koszt TP broni <b>weapon</b>.",
	"func_getWeaponEffectiveArea": "Zwraca listę komórek, na które wpłynie to, jeśli broń <b>broń</b> zostanie użyta na komórce <b>cell</b> z komórki <b>z</b>.",
	"func_getWeaponEffectiveArea_arg_1": "Broń do przetestowania.",
	"func_getWeaponEffectiveArea_arg_2": "Komórka docelowa.",
	"func_getWeaponEffectiveArea_arg_3": "Cela, z której strzela broń.",
	"func_getWeaponEffectiveArea_return": "Tablica zawierająca identyfikatory wszystkich komórek, których to dotyczy.",
	"func_getWeaponPassiveEffects": "Zwraca pasywne efekty broni <b>broń</b>.",
	"func_getWeaponPassiveEffects_arg_1": "Identyfikator broni, której efekty pasywne zostaną zwrócone.",
	"func_getWeaponPassiveEffects_return": "Tablica zawierająca efekty broni <b>weapon</b>. Każdy efekt sam w sobie jest tablicą formy\n[typ, min, max, tury, cele, modyfikatory]. Te efekty są takie same, jak te zwracane przez #getWeaponEffects.",
	"func_getWeaponFailure": "Zwraca procentową szansę na niepowodzenie broni <b>broń</b>.",
	"func_getWeaponFailure_arg_1": "Identyfikator broni, której procent awarii zostanie zwrócony.",
	"func_getWeaponFailure_return": "<b>weapon</b> procent chybienia broni, liczba całkowita z przedziału od <b>0</b> do <b>100</b>.",
	"func_getWeaponMaxRange": "Zwraca maksymalny zasięg broni <b>weapon</b>.",
	"func_getWeaponMaxRange_arg_1": "Identyfikator broni, której maksymalny zasięg zostanie zwrócony.",
	"func_getWeaponMaxRange_return": "Maksymalny zasięg broni <b>weapon</b>.",
	"func_getWeaponMaxScope": "Zwraca maksymalny zasięg broni <b>weapon</b>.",
	"func_getWeaponMaxScope_arg_1": "Identyfikator broni, której maksymalny zasięg zostanie zwrócony.",
	"func_getWeaponMaxScope_return": "Maksymalny zasięg broni <b>weapon</b>.",
	"func_getWeaponMinRange": "Zwraca minimalny zasięg broni <b>weapon</b>.",
	"func_getWeaponMinRange_arg_1": "Identyfikator broni, której minimalny zasięg zostanie zwrócony.",
	"func_getWeaponMinRange_return": "Minimalny zasięg broni <b>weapon</b>.",
	"func_getWeaponMinScope": "Zwraca minimalny zasięg broni <b>weapon</b>.",
	"func_getWeaponMinScope_arg_1": "Identyfikator broni, której minimalny zasięg zostanie zwrócony.",
	"func_getWeaponMinScope_return": "Minimalny zasięg broni <b>weapon</b>.",
	"func_getWeaponLaunchType": "Zwraca tryb uruchamiania broni <b>weapon</b> wśród stałych LAUNCH_TYPE_*.",
	"func_getWeaponLaunchType_arg_1": "Identyfikator broni, której tryb rzucania zostanie zwrócony. Domyślnie do aktualnie posiadanej broni.",
	"func_getWeaponLaunchType_return": "Tryb rzucania broni <b>weapon</b>.",
	"func_getWeaponName": "Zwraca nazwę broni <b>weapon</b>.",
	"func_getWeaponName_arg_1": "Identyfikator broni, której nazwa zostanie zwrócona.",
	"func_getWeaponName_return": "Nazwa broni <b>broń</b>.",
	"func_getWeapons": "Zwraca broń podmiotu o identyfikatorze <b>entity</b>.",
	"func_getWeapons_arg_1": "Identyfikator podmiotu, którego broń zostanie zwrócona.",
	"func_getWeapons_return": "Tablica zawierająca identyfikatory broni <b>entity</b>.",
	"func_getWeaponTargets": "Zwraca elementy, na które wpłynie to, jeśli broń <b>broń</b> zostanie użyta na komórce <b>cell</b>.",
	"func_getWeaponTargets_arg_1": "Broń do przetestowania.",
	"func_getWeaponTargets_arg_2": "Komórka docelowa.",
	"func_getWeaponTargets_return": "Tablica zawierająca identyfikatory wszystkich jednostek, których to dotyczy.",
	"func_getWisdom": "Zwraca mądrość podmiotu o id <b>entity</b>. Użyj getWisdom() bez parametrów, aby odzyskać swoją mądrość.",
	"func_getWisdom_arg_1": "Identyfikator podmiotu, którego mądrość zostanie zwrócona.",
	"func_getWisdom_return": "Mądrość identyfikatora jednostki <b>entity</b>.",
	"func_hypot": "Zwraca przeciwprostokątną trójkąta prostokątnego o bokach x i y. Odpowiednik <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "Wartość x.",
	"func_hypot_arg_2": "Wartość y.",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "Określa, czy element <b>element</b> znajduje się w tablicy <b>array</b>.",
	"func_inArray_arg_1": "Tabela wyszukiwania.",
	"func_inArray_arg_2": "Element do wyszukania.",
	"func_inArray_return": "<i>true</i>, jeśli element jest w tablicy, <i>false</i> w przeciwnym razie.",
	"func_include": "Dołącz sztuczną inteligencję o nazwie <b>ai</b> do bieżącej sztucznej inteligencji. <br><br><b>Uwaga</b>, funkcja include musi być wywołana tylko w bloku głównym, a jej parametr musi być napisem zapisanym bezpośrednio\nw kodzie. Aby uzyskać więcej informacji, zobacz sekcję samouczka: <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a> .",
	"func_include_arg_1": "Nazwa sztucznej inteligencji do uwzględnienia.",
	"func_indexOf": "Określa pozycję pierwszego wystąpienia ciągu <b>szukaj</b> w ciągu <b>łańcuch</b>, zaczynając od pozycji <b>początek</b>.",
	"func_indexOf_arg_1": "Ciąg, w którym zostanie przeprowadzone wyszukiwanie.",
	"func_indexOf_arg_2": "Ciąg do wyszukania.",
	"func_indexOf_arg_3": "Pozycja początkowa wyszukiwania.",
	"func_indexOf_return": "Pozycja pierwszego wystąpienia <b>szukaj</b> w <b>łańcuchu</b> od <b>początku</b>, -1, jeśli ciąg nie został znaleziony.",
	"func_insert": "Wstawia element <b>element</b> do <b>tablicy</b> na pozycji <b>pozycja</b>.",
	"func_insert_arg_1": "Tablica, w której element zostanie wstawiony.",
	"func_insert_arg_2": "Element do wstawienia.",
	"func_insert_arg_3": "Pozycja wkładu.",
	"func_isAlive": "Określa, czy <b>podmiot</b> żyje. Odpowiednik <i>getLife(<b>entity</b>) > 0</i>.",
	"func_isAlive_arg_1": "Identyfikator jednostki do przetestowania.",
	"func_isAlive_return": "<i>prawda</i>, jeśli <b>podmiot</b> żyje, <i>fałsz</i>, jeśli nie żyje.",
	"func_isAlly": "Określa, czy <b>podmiot</b> jest twoim sojusznikiem.",
	"func_isAlly_arg_1": "Identyfikator jednostki do przetestowania.",
	"func_isAlly_return": "<i>prawda</i>, jeśli <b>podmiot</b> jest twoim sojusznikiem lub tobą, <i>fałsz</i>, jeśli jest wrogiem.",
	"func_isChip": "Określa, czy wartość jest stałą reprezentującą chip.<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "Numer do ustalenia.",
	"func_isChip_return": "<i>true</i> jeśli wartością jest stała układu.",
	"func_isDead": "Określa, czy <b>podmiot</b> nie żyje. Odpowiednik <i>getLife(<b>entity</b>) == 0</i>.",
	"func_isDead_arg_1": "Identyfikator jednostki do przetestowania.",
	"func_isDead_return": "<i>prawda</i>, jeśli <b>podmiot</b> nie żyje, <i>fałsz</i>, jeśli żyje.",
	"func_isEmpty": "Określa, czy tablica <b>tablica</b> jest pusta. Odpowiednik <i>liczba(<b>tablica</b>) == 0</i>.",
	"func_isEmpty_arg_1": "Tablica do przetestowania.",
	"func_isEmptyCell": "Określa, czy komórka jest pusta.",
	"func_isEmptyCell_arg_1": "Komórka do przetestowania.",
	"func_isEmptyCell_return": "<i>true</i>, jeśli komórka jest pusta, <i>false</i> w przeciwnym razie.",
	"func_isEmpty_return": "<i>true</i>, jeśli tablica jest pusta, <i>false</i> w przeciwnym razie.",
	"func_isEnemy": "Określa, czy <b>podmiot</b> jest twoim wrogiem.",
	"func_isEnemy_arg_1": "Identyfikator jednostki do przetestowania.",
	"func_isEnemy_return": "<i>prawda</i>, jeśli <b>podmiot</b> jest wrogiem, <i>fałsz</i>, jeśli jest to sojusznik lub ty.",
	"func_isInlineChip": "Określa, czy <b>chip</b> może być używany tylko online.",
	"func_isInlineChip_arg_1": "Identyfikator chipa do przetestowania.",
	"func_isInlineChip_return": "<i>true</i>, jeśli chip jest tylko online, <i>false</i> w przeciwnym razie.",
	"func_isInlineWeapon": "Określa, czy broń <b>broń</b> może być używana tylko online.",
	"func_isInlineWeapon_arg_1": "Identyfikator broni do przetestowania.",
	"func_isInlineWeapon_return": "<i>true</i>, jeśli broni można używać tylko online, <i>false</i> w przeciwnym razie.",
	"func_isLeek": "Określa, czy zawartość komórki <b>cell</b> jest jednostką.",
	"func_isLeek_arg_1": "Komórka do przetestowania.",
	"func_isLeek_return": "<i>true</i>, jeśli komórka zawiera jednostkę, <i>false</i> w przeciwnym razie.",
	"func_isEntity": "Określa, czy zawartość komórki <b>cell</b> jest jednostką.",
	"func_isEntity_arg_1": "Komórka do przetestowania.",
	"func_isEntity_return": "<i>true</i>, jeśli komórka zawiera jednostkę, <i>false</i> w przeciwnym razie.",
	"func_isObstacle": "Określa, czy zawartość komórki <b>cell</b> jest przeszkodą.",
	"func_isObstacle_arg_1": "Komórka do przetestowania.",
	"func_isObstacle_return": "<i>true</i>, jeśli komórka zawiera przeszkodę, <i>false</i> w przeciwnym razie.",
	"func_isOnSameLine": "Określa, czy dwie komórki <b>cell1</b> i <b>cell2</b> znajdują się w tej samej linii.",
	"func_isOnSameLine_arg_1": "Pierwsza komórka.",
	"func_isOnSameLine_arg_2": "Druga komórka.",
	"func_isOnSameLine_return": "<i>prawda</i>, jeśli dwie komórki znajdują się w tej samej linii, <i>fałsz</i> w przeciwnym razie.",
	"func_isStatic": "Zwraca informację, czy <b>obiekt</b> jest statyczny, czy nie. Obiekt statyczny nie może się poruszać ani być przenoszony.",
	"func_isStatic_arg_1": "Identyfikator jednostki do przetestowania.",
	"func_isStatic_return": "<i>prawda</i>, jeśli <b>obiekt</b> jest statyczny, <i>fałsz</i> w przeciwnym razie.",
	"func_isSummon": "Zwraca czy <b>encja</b> jest wywołaniem czy nie.",
	"func_isSummon_arg_1": "Identyfikator jednostki do przetestowania.",
	"func_isSummon_return": "<i>true</i>, jeśli <b>encja</b> jest wywołaniem, <i>false</i> w przeciwnym razie.",
	"func_isWeapon": "Określa, czy wartość jest stałą reprezentującą broń.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false.",
	"func_isWeapon_arg_1": "Numer do ustalenia.",
	"func_isWeapon_return": "<i>true</i> jeśli wartością jest stała broni.",
	"func_join": "Łączy wiele elementów w łańcuch, oddzielając je separatorem <b>klejem</b>.",
	"func_join_arg_1": "Tablica elementów do scalenia.",
	"func_join_arg_2": "Ogranicznik elementu.",
	"func_join_return": "Wynikowy ciąg scalania.",
	"func_jsonDecode": "Dekoduje łańcuch <b>json</b> do obiektu LeekScript (liczba, łańcuch, tablica...).",
	"func_jsonDecode_arg_1": "Ciąg JSON do zdekodowania.",
	"func_jsonDecode_return": "Zdekodowany obiekt LeekScript.",
	"func_jsonEncode": "Koduje obiekt <b>object</b> w łańcuch JSON.",
	"func_jsonEncode_arg_1": "Obiekt do zakodowania w formacie JSON.",
	"func_jsonEncode_return": "Ciąg zakodowany w formacie JSON.",
	"func_keySort": "Sortuje <b>tablicę</b> według kluczy w kolejności <b>kolejności</b>.",
	"func_keySort_arg_1": "Tablica do sortowania.",
	"func_keySort_arg_2": "Kolejność sortowania: #SORT_ASC lub #SORT_DESC.",
	"func_length": "Zwraca długość <b>łańcucha znaków</b>.",
	"func_length_arg_1": "Ciąg, którego długość zostanie zwrócona.",
	"func_length_return": "Długość <b>łańcucha znaków</b>.",
	"func_lineOfSight": "Sprawdza linię wzroku między komórką <b>początkową</b> a komórką <b>końcową</b>, ignorując <b>ignorowane elementy</b>.",
	"func_lineOfSight_arg_1": "Komórka startowa.",
	"func_lineOfSight_arg_2": "Komórka docelowa.",
	"func_lineOfSight_arg_3": "Jednostka lub lista jednostek do zignorowania.",
	"func_lineOfSight_return": "Zwraca <i>true</i>, jeśli linia wzroku jest wolna.",
	"func_listen": "Zwraca tablicę say() poprzednich jednostek w postaci [identyfikator_obiektu, komunikat].",
	"func_listen_return": "Tablica poprzednich say().",
	"func_log": "Oblicza logarytm naturalny liczby <b>liczba</b>.",
	"func_log_arg_1": "Liczba z zakresu ]0; +∞[.",
	"func_log_return": "Logarytm naturalny z <b>liczby</b>.",
	"func_log10": "Oblicza logarytm o podstawie 10 z liczby <b>liczba</b>.",
	"func_log10_arg_1": "Liczba z zakresu ]0; +∞[.",
	"func_log10_return": "Logarytm o podstawie 10 z <b>liczby</b>.",
	"func_log2": "Oblicza logarytm o podstawie 2 liczby <b>liczba</b>.",
	"func_log2_arg_1": "Liczba z zakresu ]0; +∞[.",
	"func_log2_return": "Logarytm o podstawie 2 z <b>liczby</b>.",
	"func_mark": "Zaznacza jedną lub więcej komórek koloru wskazanego w parametrze na ziemi przez liczbę obrotów wskazaną w parametrze. To oznaczenie jest widoczne tylko dla hodowcy jednostki.",
	"func_mark_arg_1": "Komórka lub tablica wielu komórek do oznaczenia",
	"func_mark_arg_2": "Kolor oznaczenia",
	"func_mark_arg_3": "Czas trwania znakowania",
	"func_mark_return": "Zwraca true, jeśli wszystko poszło dobrze",
	"func_markText": "Zapisuje tekst na jednej lub kilku komórkach koloru wskazanego w parametrze na ziemi przez liczbę zwojów wskazaną w parametrze. Te teksty są widoczne tylko dla hodowcy jednostki.",
	"func_markText_arg_1": "Komórka lub tablica wielu komórek do zapisu",
	"func_markText_arg_2": "Tekst do wpisania (maksymalnie 10 znaków)",
	"func_markText_arg_3": "Kolor tekstu",
	"func_markText_arg_4": "Czas trwania tekstu",
	"func_markText_return": "Zwraca true, jeśli wszystko poszło dobrze",
	"func_max": "Zwraca większą wartość między dwiema liczbami <b>a</b> i <b>b</b>.",
	"func_max_arg_1": "Numer.",
	"func_max_arg_2": "Numer.",
	"func_max_return": "Większa liczba między <b>a</b> a <b>b</b>.",
	"func_min": "Zwraca mniejszą wartość między dwiema liczbami <b>a</b> i <b>b</b>.",
	"func_min_arg_1": "Numer.",
	"func_min_arg_2": "Numer.",
	"func_min_return": "Mniejsza liczba między <b>a</b> a <b>b</b>.",
	"func_moveAwayFrom": "Odsuwa twój obiekt od innego <b>obiektu</b>, zużywając maksymalną liczbę <b>mp</b> punktów ruchu.",
	"func_moveAwayFrom_arg_1": "Podmiot, z którego Twój podmiot powinien się wycofać.",
	"func_moveAwayFrom_arg_2": "Maksymalna liczba MP do wykorzystania. Domyślnie bez limitu.",
	"func_moveAwayFrom_return": "Liczba wykorzystanych punktów ruchu.",
	"func_moveAwayFromCell": "Przesuwa twoją istotę o jedną <b>komórkę</b> komórkę dalej, zużywając maksymalnie <b>mp</b> punktów ruchu.",
	"func_moveAwayFromCell_arg_1": "Komórka, z której Twoja jednostka powinna się oddalić.",
	"func_moveAwayFromCell_arg_2": "Maksymalna liczba MP do wykorzystania. Domyślnie bez limitu.",
	"func_moveAwayFromCell_return": "Liczba wykorzystanych punktów ruchu.",
	"func_moveAwayFromCells": "Odsuwa twoją jednostkę od zestawu <b>komórek</b> komórek, używając maksymalnej liczby <b>mp</b> punktów ruchu.",
	"func_moveAwayFromCells_arg_1": "Tablica zawierająca komórki, z których Twoja jednostka powinna się oddalić.",
	"func_moveAwayFromCells_arg_2": "Maksymalna liczba MP do wykorzystania. Domyślnie bez limitu.",
	"func_moveAwayFromCells_return": "Liczba wykorzystanych punktów ruchu.",
	"func_moveAwayFromLeeks": "Odsuwa twój obiekt od zestawu <b>obiektów</b>, wykorzystując maksymalną liczbę <b>mp</b> punktów ruchu.",
	"func_moveAwayFromLeeks_arg_1": "Tablica zawierająca identyfikatory podmiotów, od których podmiot powinien się odsunąć.",
	"func_moveAwayFromLeeks_arg_2": "Maksymalna liczba MP do wykorzystania. Domyślnie bez limitu.",
	"func_moveAwayFromLeeks_return": "Liczba wykorzystanych punktów ruchu.",
	"func_moveAwayFromEntities": "Odsuwa twój obiekt od zestawu <b>obiektów</b>, wykorzystując maksymalną liczbę <b>mp</b> punktów ruchu.",
	"func_moveAwayFromEntities_arg_1": "Tablica zawierająca identyfikatory podmiotów, od których podmiot powinien się odsunąć.",
	"func_moveAwayFromEntities_arg_2": "Maksymalna liczba MP do wykorzystania. Domyślnie bez limitu.",
	"func_moveAwayFromEntities_return": "Liczba wykorzystanych punktów ruchu.",
	"func_moveAwayFromLine": "Odsuwa obiekt od linii zdefiniowanej przez dwie komórki <b>cell1</b> i <b>cell2</b>, używając maksymalnie <b>mp</b> punktów ruchu.",
	"func_moveAwayFromLine_arg_1": "Komórka 1.",
	"func_moveAwayFromLine_arg_2": "Komórka 2.",
	"func_moveAwayFromLine_arg_3": "Maksymalna liczba MP do wykorzystania. Domyślnie bez limitu.",
	"func_moveAwayFromLine_return": "Liczba wykorzystanych punktów ruchu.",
	"func_moveToward": "Zbliża twój obiekt do innego <b>obiektu</b>, wykorzystując maksymalną liczbę <b>mp</b> punktów ruchu.",
	"func_moveToward_arg_1": "Jednostka, do której Twoja jednostka powinna się zwrócić.",
	"func_moveToward_arg_2": "Maksymalna liczba MP do wykorzystania.",
	"func_moveToward_return": "Liczba wykorzystanych punktów ruchu.",
	"func_moveTowardCell": "Przesuwa twoją jednostkę o jedną <b>komórkę</b> komórkę bliżej, używając maksymalnej liczby <b>mp</b> punktów ruchu.",
	"func_moveTowardCell_arg_1": "Komórka, do której powinien zbliżać się Twój obiekt.",
	"func_moveTowardCell_arg_2": "Maksymalna liczba MP do wykorzystania. Domyślnie bez limitu.",
	"func_moveTowardCell_return": "Liczba wykorzystanych punktów ruchu.",
	"func_moveTowardCells": "Przesuwa twoją jednostkę bliżej zestawu komórek <b>komórek</b>, używając maksymalnej liczby punktów ruchu <b>mp</b>.",
	"func_moveTowardCells_arg_1": "Tablica zawierająca komórki, do których jednostka powinna się zbliżyć.",
	"func_moveTowardCells_arg_2": "Maksymalna liczba MP do wykorzystania. Domyślnie bez limitu.",
	"func_moveTowardCells_return": "Liczba wykorzystanych punktów ruchu.",
	"func_moveTowardLeeks": "Zbliża twój obiekt do zbioru <b>obiektów</b>, używając maksymalnej liczby <b>mp</b> punktów ruchu.",
	"func_moveTowardLeeks_arg_1": "Tablica zawierająca identyfikatory podmiotów, do których podmiot powinien się zbliżać.",
	"func_moveTowardLeeks_arg_2": "Maksymalna liczba MP do wykorzystania. Domyślnie bez limitu.",
	"func_moveTowardLeeks_return": "Liczba wykorzystanych punktów ruchu.",
	"func_moveTowardEntities": "Zbliża twój obiekt do zbioru <b>obiektów</b>, używając maksymalnej liczby <b>mp</b> punktów ruchu.",
	"func_moveTowardEntities_arg_1": "Tablica zawierająca identyfikatory podmiotów, do których podmiot powinien się zbliżać.",
	"func_moveTowardEntities_arg_2": "Maksymalna liczba MP do wykorzystania. Domyślnie bez limitu.",
	"func_moveTowardEntities_return": "Liczba wykorzystanych punktów ruchu.",
	"func_moveTowardLine": "Zbliża obiekt do linii wyznaczonej przez dwie komórki <b>cell1</b> i <b>cell2</b>, wykorzystując maksymalną liczbę punktów ruchu <b>mp</b>.",
	"func_moveTowardLine_arg_1": "Komórka 1.",
	"func_moveTowardLine_arg_2": "Komórka 2.",
	"func_moveTowardLine_arg_3": "Maksymalna liczba MP do wykorzystania. Domyślnie bez limitu.",
	"func_moveTowardLine_return": "Liczba wykorzystanych punktów ruchu.",
	"func_number": "Konwertuje wartość na liczbę. Jeśli wartością jest łańcuch, funkcja liczbowa spróbuje przekonwertować ją na liczbę, jeśli wartość jest już liczbą, funkcja zwraca liczbę, a dla każdego innego typu zwraca wartość <i>null</i>.",
	"func_number_arg_1": "Wartość do przekonwertowania na liczbę.",
	"func_number_return": "Przekonwertowana liczba.",
	"func_pause": "Wstrzymuje walkę, tylko dla hodowcy podmiotu korzystającego z funkcji.",
	"func_pop": "Usuwa ostatnią komórkę z <b>tablicy</b> i zwraca odpowiedni element.",
	"func_pop_arg_1": "Tablica, której ostatnie pole zostanie usunięte.",
	"func_pop_return": "Przedmiot, który był w starym ostatnim pudełku.",
	"func_pow": "Podnosi liczbę bazową do potęgi wykładniczej.",
	"func_pow_arg_1": "Baza.",
	"func_pow_arg_2": "Wystawca.",
	"func_pow_return": "<b>podstawa</b> podniesiona do potęgi <b>wykładnika</b>.",
	"func_push": "Dodaje element <b>element</b> na końcu <b>tablicy</b>.",
	"func_pushAll": "Dołącza wszystkie elementy tablicy <b>elements</b> na końcu <b>tablicy</b>.",
	"func_pushAll_arg_1": "Tablica, do której chcemy dodać elementy.",
	"func_pushAll_arg_2": "Elementy do dodania.",
	"func_push_arg_1": "Tablica, do której chcemy dodać element.",
	"func_push_arg_2": "Element do dodania.",
	"func_rand": "Zwraca rzeczywistą liczbę losową z przedziału od 0 (włącznie) do 1 (wyłącznie).",
	"func_randFloat": "Zwraca rzeczywistą liczbę losową z przedziału od <b>a</b> (włącznie) do <b>b</b> (wyłącznie).",
	"func_randFloat_arg_1": "Dolna granica.",
	"func_randFloat_arg_2": "Górna granica.",
	"func_randFloat_return": "Losowa liczba z zakresu [a; B[.",
	"func_randInt": "Zwraca losową liczbę całkowitą z zakresu od <b>a</b> (włącznie) do <b>b</b> (wyłącznie).",
	"func_randInt_arg_1": "Dolna granica.",
	"func_randInt_arg_2": "Górna granica.",
	"func_randInt_return": "Losowa liczba całkowita z zakresu [a; B[.",
	"func_rand_return": "Losowa liczba z zakresu [0; 1[.",
	"func_remove": "Usuwa element z tablicy <b>tablica</b> na pozycji <b>pozycja</b> i zwraca usunięty element.",
	"func_remove_arg_1": "Tablica, z której chcemy usunąć element.",
	"func_remove_arg_2": "Pozycja elementu do usunięcia.",
	"func_removeElement": "Usuwa pierwsze wystąpienie <b>elementu</b> w <b>tablicy</b>. Jeśli element nie zostanie znaleziony, tablica nie zostanie zmodyfikowana.",
	"func_removeElement_arg_1": "Tablica, z której chcemy usunąć element.",
	"func_removeElement_arg_2": "Element do znalezienia, a następnie usunięcia.",
	"func_removeKey": "Usuwa element z tablicy <b>tablica</b> powiązana z kluczem <b>klucz</b>.",
	"func_removeKey_arg_1": "Tablica, z której chcemy usunąć element.",
	"func_removeKey_arg_2": "Klucz powiązany z elementem do usunięcia.",
	"func_remove_return": "Element usunięty z tablicy.",
	"func_replace": "Zamienia wszystkie wystąpienia słowa <b>szukaj</b> na <b>zastąp</b> w ciągu <b>łańcuch</b>.",
	"func_replace_arg_1": "Ciąg, w którym dokonywane są zamiany.",
	"func_replace_arg_2": "Podciąg do zastąpienia.",
	"func_replace_arg_3": "Zamienny sznurek.",
	"func_replace_return": "Ciąg wynikowy z zamiennikami.",
	"func_resurrect": "Używa chipa CHIP_RESURRECTION do wskrzeszenia martwego bytu o identyfikatorze <b>entity</b> w komórce <b>cell</b>.",
	"func_resurrect_arg_1": "Identyfikator podmiotu do ożywienia.",
	"func_resurrect_arg_2": "Komórka, w której jednostka pojawi się ponownie.",
	"func_resurrect_return": "Wynik uruchomienia chipa, wśród stałych USE_*.",
	"func_reverse": "Odwraca <b>tablicę</b>.",
	"func_reverse_arg_1": "Tabela do odwrócenia.",
	"func_round": "Zwraca zaokrąglenie <b>liczby</b>.<br/>Zaokrąglenie w dół można znaleźć za pomocą #podłoga, a zaokrąglenie w górę za pomocą #ceil.",
	"func_round_arg_1": "Liczba, której zaokrąglenie chcemy znaleźć.",
	"func_round_return": "Zaokrąglone przez <b>liczbę</b>.",
	"func_say": "Spraw, by Twoja jednostka przemówiła.",
	"func_say_arg_1": "Wiadomość, którą twoja jednostka ogłosi na arenie.",
	"func_search": "Znajduje element <b>element</b> w tablicy <b>array</b>, zaczynając od pozycji <b>początek</b>.",
	"func_search_arg_1": "Tabela wyszukiwania.",
	"func_search_arg_2": "Element do wyszukania.",
	"func_search_arg_3": "Pozycja początku wyszukiwania.",
	"func_search_return": "Pozycja pierwszego wystąpienia elementu <b>element</b> w <b>tablicy</b>, zaczynając od <b>start</b>, null, jeśli element nie został znaleziony.",
	"func_sendAll": "Wyślij wiadomość do całego zespołu.",
	"func_sendAll_arg_1": "Typ wiadomości do wysłania (zobacz stałe MESSAGE_*).",
	"func_sendAll_arg_2": "Parametry komunikatu, które mogą mieć dowolną wartość.",
	"func_sendTo": "Wysyła wiadomość do podmiotu o identyfikatorze <b>entity</b>.",
	"func_sendTo_arg_1": "Identyfikator podmiotu, do którego zostanie wysłana wiadomość.",
	"func_sendTo_arg_2": "Typ wiadomości do wysłania (zobacz stałe MESSAGE_*).",
	"func_sendTo_arg_3": "Parametry komunikatu, które mogą mieć dowolną wartość.",
	"func_sendTo_return": "<i>true</i>, jeśli wysłanie się powiodło, <i>false</i>, jeśli wystąpił błąd.",
	"func_setRegister": "Przechowuje <b>wartość</b> w rejestrze <b>kluczy</b>.\nKlucz i wartość to ciągi znaków, które muszą zawierać maksymalnie odpowiednio <i>100</i> i <i>5000</i> znaków. Por może mieć maksymalnie <i>100</i> rejestrów, zapisanie w nowym rejestrze nie będzie działać, jeśli wszystkie rejestry\nsą już zajęci.",
	"func_setRegister_arg_1": "Klucz rejestru, w którym ma być przechowywana wartość.",
	"func_setRegister_arg_2": "Wartość do przechowywania.",
	"func_setRegister_return": "<i>true</i>, jeśli operacja się powiodła, <i>false</i> w przeciwnym razie.",
	"func_setWeapon": "Wyposaż swój byt w <b>broń</b>.",
	"func_setWeapon_arg_1": "Identyfikator broni do wyposażenia.",
	"func_shift": "Usuwa pierwszą komórkę z <b>tablicy</b> i zwraca odpowiedni element.",
	"func_shift_arg_1": "Tablica, której pierwsza komórka zostanie usunięta.",
	"func_shift_return": "Element, który znajdował się w starej pierwszej komórce.",
	"func_show": "Pokazuje graczom <b>komórkę</b> koloru <b>kolor</b> na polu przez 1 turę. Korzystanie z tej funkcji kosztuje 1PT.",
	"func_show_arg_1": "Komórka do pokazania",
	"func_show_arg_2": "Kolor oznaczenia",
	"func_show_return": "Zwraca true, jeśli wszystko poszło dobrze",
	"func_shuffle": "Losowo tasuje planszę.",
	"func_shuffle_arg_1": "Tablica do losowania.",
	"func_signum": "Określa znak liczby <b>liczba</b>.",
	"func_signum_arg_1": "Liczba, której znak zostanie określony.",
	"func_signum_return": "1, jeśli liczba jest dodatnia, 0, jeśli liczba jest równa zero, i -1, jeśli liczba jest ujemna.",
	"func_sin": "Oblicza sinus kąta <b>kąt</b> przekazany jako parametr",
	"func_sin_arg_1": "Kąt, którego sinus zostanie obliczony",
	"func_sin_return": "Zwraca sinus kąta <b>kąt</b>",
	"func_sort": "Sortuje <b>tablicę</b> w porządku rosnącym lub malejącym. Domyślna kolejność rosnąca to:\n<ul>\n<li>Wszystkie wartości logiczne (najpierw fałsz, potem prawda)</li>\n<li>Wszystkie liczby (uporządkowane od najmniejszej do największej)</li>\n<li>Wszystkie kanały (w kolejności alfabetycznej)</li>\n<li>Wszystkie elementy puste.</li>\n</ul>",
	"func_sort_arg_1": "Tablica do sortowania.",
	"func_sort_arg_2": "#SORT_ASC, aby posortować <b>tablicę</b> w porządku rosnącym lub #SORT_DESC, aby posortować w porządku malejącym.",
	"func_split": "Dzieli ciąg <b>łańcuch znaków</b> na podłańcuchy oddzielone <b>przegrodą</b>.",
	"func_split_arg_1": "Łańcuch tnący.",
	"func_split_arg_2": "Ciąg ograniczający przejście z jednego elementu do drugiego.",
	"func_split_arg_3": "Maksymalna liczba elementów tablicy.",
	"func_split_return": "Tablica zawierająca znalezione podciągi.",
	"func_sqrt": "Oblicza pierwiastek kwadratowy z liczby <b>liczba</b>.",
	"func_sqrt_arg_1": "Liczba, której pierwiastek zostanie obliczony.",
	"func_sqrt_return": "Pierwiastek kwadratowy z <b>liczby</b>.",
	"func_startsWith": "Sprawdza, czy <b>łańcuch znaków</b> zaczyna się od <b>prefiksu</b>.",
	"func_startsWith_arg_1": "Szukana fraza.",
	"func_startsWith_arg_2": "Wyszukiwany prefiks.",
	"func_startsWith_return": "Prawda, jeśli <b>łańcuch znaków</b> zaczyna się od <b>prefiksu</b>.",
	"func_string": "Konwertuje wartość na ciąg znaków.<br>\nJeśli wartość jest już ciągiem, jest zwracana.<br>\nJeśli wartością jest liczba x, zwracane jest „x”.<br>\nJeśli wartością jest tablica, zwracany jest ciąg w postaci „[klucz1: wartość1, klucz2: wartość2, ...]”.<br>\nJeśli wartością jest wartość logiczna, wartości „prawda” i „fałsz” są zwracane odpowiednio dla wartości prawda i fałsz.<br>\nJeśli wartość jest równa null, zwracane jest „null”.",
	"func_string_arg_1": "Wartość do przekonwertowania na ciąg znaków.",
	"func_string_return": "Przekonwertowany ciąg.",
	"func_subArray": "Zwraca podtablicę <b>tablicy</b> rozpoczynającą się od pozycji <b>początkowej</b> i kończącą się na pozycji <b>koniec</b>.",
	"func_subArray_arg_1": "Tabela źródłowa.",
	"func_subArray_arg_2": "Indeks startowy.",
	"func_subArray_arg_3": "Indeks końcowy.",
	"func_subArray_return": "Podtablica.",
	"func_substring": "Zwraca podłańcuch łańcucha <b>łańcuch</b>, zaczynając od znaku w indeksie <b>początek</b> i o rozmiarze <b>długość</b>.",
	"func_substring_arg_1": "Łańcuch tnący.",
	"func_substring_arg_2": "Początkowy indeks znaków.",
	"func_substring_arg_3": "Długość podciągu.",
	"func_substring_return": "Podciąg.",
	"func_sum": "Zwraca sumę wszystkich elementów numerycznych tablicy <b>tablica</b>.",
	"func_sum_arg_1": "Tabela do podsumowania.",
	"func_summon": "Wywołuje jednostkę określoną przez chip <b>chip</b> w komórce <b>cell</b> mającej dla IA funkcję <b>ai</b>.",
	"func_summon_arg_1": "Chip używany do przywoływania. Chip musi być chipem typu summon i musi być wyposażony w jednostkę korzystającą z funkcji summon.",
	"func_summon_arg_2": "Powinna pojawić się komórka lub wezwanie.",
	"func_summon_arg_3": "Inwokacja AI, jako funkcja.",
	"func_summon_return": "Funkcja summon daje taki sam zwrot jak funkcja #useChip.",
	"func_sum_return": "Suma elementów tablicy.",
	"func_tan": "Oblicza tangens kąta <b>kąt</b> (w radianach).",
	"func_tan_arg_1": "Kąt w radianach.",
	"func_tan_return": "Tangens <b>kąta</b>.",
	"function_category_array": "Listy",
	"function_category_chip": "Pchły",
	"function_category_color": "Kolor",
	"function_category_fight": "Walka",
	"function_category_leek": "Pory",
	"function_category_map": "Stoły",
	"function_category_field": "Grunt",
	"function_category_math": "Liczby",
	"function_category_network": "Sieć",
	"function_category_string": "Więzy",
	"function_category_utils": "Narzędzia",
	"function_category_weapon": "Bronie",
	"func_toDegrees": "Konwertuje kąt <b>radiany</b> na stopnie.",
	"func_toDegrees_arg_1": "Wartość w radianach",
	"func_toDegrees_return": "Kąt w stopniach.",
	"func_toLower": "Konwertuje wielkie litery w <b>łańcuchu znaków</b> na małe litery.",
	"func_toLower_arg_1": "Ciąg do przekształcenia",
	"func_toLower_return": "Ciąg małych liter",
	"func_toRadians": "Konwertuje kąt <b>stopni</b> na radiany.",
	"func_toRadians_arg_1": "Wartość w stopniach",
	"func_toRadians_return": "Kąt w radianach.",
	"func_toUpper": "Konwertuje małe litery w <b>łańcuchu znaków</b> na wielkie litery.",
	"func_toUpper_arg_1": "Ciąg do przekształcenia.",
	"func_toUpper_return": "Ciąg wielkich liter.",
	"func_typeOf": "Zwraca typ wartości <b>wartość</b> spośród typów: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "Wartość, której typ zostanie zwrócony.",
	"func_typeOf_return": "Typ <b>wartości</b>.",
	"func_unshift": "Dodaje element <b>element</b> na początku <b>tablicy</b>.",
	"func_unshift_arg_1": "Tablica, w której element zostanie dodany.",
	"func_unshift_arg_2": "Pozycja do dodania.",
	"func_useChip": "Użyj chipa <b>chip</b> na elemencie <b>entity</b>.",
	"func_useChip_arg_1": "Chip do wykorzystania.",
	"func_useChip_arg_2": "Jednostka docelowa.",
	"func_useChipOnCell": "Użyj chipa <b>chip</b> na komórce <b>cell</b>.",
	"func_useChipOnCell_arg_1": "Chip do wykorzystania.",
	"func_useChipOnCell_arg_2": "Komórka docelowa.",
	"func_useChipOnCell_return": "Wartość większa niż 0, jeśli atak został rozpoczęty.",
	"func_useChip_return": "Wartości zwracane przez useChip to:<br>\n<ul>\n<li>#USE_CRITICAL, przy trafieniu krytycznym</li>\n<li>#USE_SUCCESS, jeśli się powiedzie</li>\n<li>#USE_FAILED, w przypadku awarii</li>\n<li>#USE_INVALID_TARGET, jeśli cel nie istnieje</li>\n<li>#USE_NOT_ENOUGH_TP, jeśli Twoja jednostka nie ma wystarczającej liczby TP</li>\n<li>#USE_INVALID_COOLDOWN, jeśli chip nie nadaje się jeszcze do użytku</li>\n<li>#USE_INVALID_POSITION, jeśli zasięg jest zły lub linia wzroku nie jest wyraźna</li>\n</ul>",
	"func_useWeapon": "Użyj wybranej broni na <b>jednostce</b>.",
	"func_useWeapon_arg_1": "Jednostka docelowa.",
	"func_useWeaponOnCell": "Użyj wybranej broni na komórce <b>celi</b>.",
	"func_useWeaponOnCell_arg_1": "Komórka docelowa.",
	"func_useWeaponOnCell_return": "Wartość większa niż 0, jeśli atak został rozpoczęty.",
	"func_useWeapon_return": "Wartości zwracane przez useWeapon to:<br>\n<ul>\n<li>#USE_CRITICAL, przy trafieniu krytycznym</li>\n<li>#USE_SUCCESS, jeśli się powiedzie</li>\n<li>#USE_FAILED, w przypadku awarii</li>\n<li>#USE_INVALID_TARGET, jeśli cel nie istnieje</li>\n<li>#USE_NOT_ENOUGH_TP, jeśli Twoja jednostka nie ma wystarczającej liczby TP</li>\n<li>#USE_INVALID_POSITION, jeśli zasięg jest zły lub linia wzroku nie jest wyraźna</li>\n</ul>",
	"func_weaponNeedLos": "Zwraca, czy broń <b>weapon</b> potrzebuje linii wzroku, aby strzelać.",
	"func_weaponNeedLos_arg_1": "Identyfikator broni do przetestowania.",
	"func_weaponNeedLos_return": "<i>prawda</i>, jeśli <b>broń</b> potrzebuje linii wzroku do strzału, <i>fałsz</i> w przeciwnym razie.",
	"items": "Rzeczy",
	"max_cores": "maks",
	"max_level": "maks",
	"min_cores": "Minimalne serca",
	"min_level": "Minimalny poziom",
	"n_operations": "<b>{0}</b> operacji",
	"parameters": "Ustawienia",
	"return": "Informacja zwrotna",
	"value": "Wartość",
	"variable_operations": "<b>Operacje na zmiennych</b>",
	"deprecated_function": "Ta funkcja jest przestarzała.",
	"deprecated_constant": "Ta stała jest przestarzała.",
	"replaced_by": "Zastępuje go {0}.",
	"details": "Detale",
	"optional": "opcjonalny",
	"arg_type_9": "zestaw",
	"arg_type_10": "interwał",
	"function_category_set": "Pakiety",
	"function_category_interval": "Interwały",
	"arg_type_96": "set<entier>"
}

	i18n.mergeLocaleMessage("pl", { "doc": messages })
	