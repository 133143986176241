
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> bewerking",
	"arg_type_-1": "?",
	"arg_type_1": "nummer",
	"arg_type_2": "tv-zender",
	"arg_type_3": "booleaans",
	"arg_type_4": "lijst",
	"arg_type_41": "lijst<nummer>",
	"arg_type_42": "lijst<tekenreeks>",
	"arg_type_43": "lijst<booleaans>",
	"arg_type_44": "lijst<lijst>",
	"arg_type_46": "lijst<geheel getal>",
	"arg_type_47": "lijst<echt>",
	"arg_type_5": "functie",
	"arg_type_6": "geheel",
	"arg_type_7": "echt",
	"arg_type_8": "tafel",
	"arg_type_806": "tabel<?, geheel getal>",
	"complexity": "Complexiteit {c}",
	"operations": "{o} operaties",
	"const_AREA_CIRCLE_1": "Cirkelvormig gebied met een diameter van 3 vierkanten (kruis).",
	"const_AREA_CIRCLE_2": "Cirkelvormig gebied met een diameter van 5 vierkanten.",
	"const_AREA_CIRCLE_3": "Cirkelvormig gebied met een diameter van 7 vierkanten.",
	"const_AREA_LASER_LINE": "Zone van een laser, lijn van het minimale bereik van de laser tot het maximale bereik of een obstakel.",
	"const_AREA_POINT": "Gebied bestaande uit een enkele cel.",
	"const_CELL_EMPTY": "Retourwaarde van getCellContent(cell) voor een lege cel.",
	"const_CELL_OBSTACLE": "Retourwaarde van getCellContent(cel) voor een cel die een obstakel bevat.",
	"const_CELL_PLAYER": "Retourwaarde van getCellContent(cel) voor een cel die een entiteit bevat.",
	"const_CELL_ENTITY": "Retourwaarde van getCellContent(cel) voor een cel die een entiteit bevat.",
	"const_COLOR_BLUE": "Kleur blauw.",
	"const_COLOR_GREEN": "Groene kleur.",
	"const_COLOR_RED": "Rode kleur.",
	"const_E": "Eulers nummer.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Geeft een entiteit een absoluut schild, waardoor het aantal levenspunten dat wordt verwijderd door schade (EFFECT_DAMAGE) met een vast bedrag wordt verminderd. Versterkt door weerstand.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Verwijdert het absolute schild van een entiteit. Wordt niet versterkt door een eigenschap. Hiermee kun je de door schade verwijderde hitpoints (EFFECT_DAMAGE) met een absoluut bedrag verhogen.",
	"const_EFFECT_AFTEREFFECT": "Verwijdert hitpoints van een entiteit. Versterkt door de wetenschap. Vermindert de maximale hitpoints met 5% van het aantal verwijderde hitpoints.",
	"const_EFFECT_ANTIDOTE": "Verwijdert al het gif (EFFECT_POISON) dat op een doelwit aanwezig is.",
	"const_EFFECT_BOOST_MAX_LIFE": "Verhoogt hitpoints en maximale hitpoints van een entiteit. Versterkt door wijsheid.",
	"const_EFFECT_BUFF_AGILITY": "Verleent flexibiliteit aan een entiteit. Versterkt door de wetenschap.",
	"const_EFFECT_BUFF_MP": "Geeft bewegingspunten aan een entiteit. Versterkt door de wetenschap.",
	"const_EFFECT_BUFF_RESISTANCE": "Verleent weerstand aan een entiteit. Versterkt door de wetenschap.",
	"const_EFFECT_BUFF_STRENGTH": "Geeft kracht aan een entiteit. Versterkt door de wetenschap.",
	"const_EFFECT_BUFF_TP": "Verleent actiepunten aan een entiteit. Versterkt door de wetenschap.",
	"const_EFFECT_BUFF_WISDOM": "Verleent wijsheid aan een entiteit. Versterkt door de wetenschap.",
	"const_EFFECT_DAMAGE": "Verwijdert hitpoints van een entiteit. Kracht versterkt. Werkt samen met schilden (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), lifesteal (behalve caster) en schadeteruggave (EFFECT_DAMAGE_RETURN). Vermindert de maximale hitpoints met 5% van het aantal verwijderde hitpoints.",
	"const_EFFECT_DAMAGE_RETURN": "Biedt schade-reflectie aan een entiteit, waardoor hitpoints kunnen worden verwijderd van entiteiten die schade toebrengen aan de begunstigde. Versterkt door behendigheid. Vermindert de maximale hitpoints met 5% van het aantal verwijderde hitpoints.",
	"const_EFFECT_DEBUFF": "Verlaagt de waarde van alle effecten op een entiteit met een percentage.",
	"const_EFFECT_HEAL": "Herstelt hitpoints naar een entiteit, beperkt door de maximale hitpoints. Versterkt door wijsheid.",
	"const_EFFECT_INVERT": "Verwisselt de positie van de caster met die van een entiteit.",
	"const_EFFECT_KILL": "Verwijdert alle hitpoints van een entiteit.",
	"const_EFFECT_LIFE_DAMAGE": "Verwijdert hitpoints van een entiteit, gebaseerd op een percentage van de hitpoints van de caster. Werkt samen met schilden (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) en schaderetour (EFFECT_DAMAGE_RETURN). Vermindert de maximale hitpoints met 5% van het aantal verwijderde hitpoints.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "Het effect wordt vermenigvuldigd met het aantal getroffen entiteiten in het gebied.",
	"const_EFFECT_MODIFIER_ON_CASTER": "Het effect heeft nog steeds invloed op de caster.",
	"const_EFFECT_MODIFIER_STACKABLE": "Het effect is cumulatief.",
	"const_EFFECT_NOVA_DAMAGE": "Verwijdert maximale levenspunten. Versterkt door de wetenschap.",
	"const_EFFECT_POISON": "Verwijdert hitpoints van een entiteit. Versterkt door magie. Vermindert de maximale hitpoints met 10% van het aantal verwijderde hitpoints.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Geeft een entiteit een absoluut schild, waardoor het aantal levenspunten dat wordt verwijderd door schade (EFFECT_DAMAGE) met een vast bedrag wordt verminderd. Niet te versterken.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Verleent flexibiliteit aan een entiteit. Niet te versterken.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Verleent magie aan een entiteit. Niet te versterken.",
	"const_EFFECT_RAW_BUFF_MP": "Geeft bewegingspunten aan een entiteit. Niet te versterken.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Verleent wetenschap aan een entiteit. Niet te versterken.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Geeft kracht aan een entiteit. Niet te versterken.",
	"const_EFFECT_RAW_BUFF_TP": "Verleent actiepunten aan een entiteit. Niet te versterken.",
	"const_EFFECT_RELATIVE_SHIELD": "Biedt een relatief schild, waardoor het aantal hitpoints dat wordt verwijderd door schade (EFFECT_DAMAGE) met een relatief aantal wordt verminderd. Versterkt door weerstand.",
	"const_EFFECT_RESURRECT": "Brengt een entiteit tot leven, met een maximaal HP-nummer dat gelijk is aan de helft van het maximale HP-nummer van de entiteit vóór de wederopstanding, en een huidig HP-nummer dat gelijk is aan een kwart van het maximale HP-nummer vóór de wederopstanding.",
	"const_EFFECT_SHACKLE_MAGIC": "Verwijdert magie van een entiteit. Versterkt door magie.",
	"const_EFFECT_SHACKLE_MP": "Verwijder bewegingspunten van een entiteit. Versterkt door magie.",
	"const_EFFECT_SHACKLE_STRENGTH": "Verwijdert kracht van een entiteit. Versterkt door magie.",
	"const_EFFECT_SHACKLE_TP": "Trek actiepunten af van een entiteit. Versterkt door magie.",
	"const_EFFECT_SUMMON": "Roept een lamp op. Geen effect als de teamoproeplimiet is bereikt.",
	"const_EFFECT_TARGET_ALLIES": "Beïnvloedt bondgenoten.",
	"const_EFFECT_TARGET_CASTER": "Heeft invloed op de caster.",
	"const_EFFECT_TARGET_ENEMIES": "Heeft invloed op vijanden.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Beïnvloedt niet-opgeroepen entiteiten (prei en torentjes).",
	"const_EFFECT_TARGET_SUMMONS": "Beïnvloedt opgeroepen entiteiten (Bulbs).",
	"const_EFFECT_TELEPORT": "Verandert de positie van het zwenkwiel.",
	"const_EFFECT_VULNERABILITY": "Verwijdert schild ten opzichte van een entiteit. Wordt niet versterkt door een eigenschap. Verhoogt de hitpoints verwijderd door schade (EFFECT_DAMAGE) met een relatief bedrag.",
	"const_ENTITY_BULB": "Verwijst naar een Bulb-type entiteit.",
	"const_ENTITY_LEEK": "Verwijst naar een Leek-type entiteit.",
	"const_ENTITY_TURRET": "Verwijst naar een entiteit van het type Turret.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Uitdagingstype gevechtsachtergrond.",
	"const_FIGHT_CONTEXT_GARDEN": "Context van de strijd in de moestuin.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Context van gevechten in Battle Royale.",
	"const_FIGHT_CONTEXT_TEST": "Test gevechtscontext.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Toernooi gevecht achtergrond.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Vecht in Battle Royale.",
	"const_FIGHT_TYPE_FARMER": "Fokker gevecht.",
	"const_FIGHT_TYPE_SOLO": "Solo gevecht.",
	"const_FIGHT_TYPE_TEAM": "Teamgevecht.\n",
	"const_INSTRUCTIONS_LIMIT": "Maximaal aantal instructies dat een entiteit tijdens zijn beurt kan gebruiken.",
	"const_MAX_TURNS": "Maximaal aantal beurten in een gevecht.",
	"const_OPERATIONS_LIMIT": "Maximaal aantal bewerkingen dat een entiteit tijdens zijn beurt kan gebruiken.",
	"const_PI": "De verhouding van de omtrek van een cirkel tot zijn diameter.",
	"const_SORT_ASC": "Vertelt de #sort-functie om in oplopende volgorde te sorteren.",
	"const_SORT_DESC": "Vertelt de #sort-functie om in aflopende volgorde te sorteren.",
	"const_TYPE_ARRAY": "Waardetype <b>lijst</b>",
	"const_TYPE_BOOLEAN": "Type waarde <b>booleaans</b>",
	"const_TYPE_FUNCTION": "Waardetype <b>functie</b>",
	"const_TYPE_NULL": "Type waarde <b>null</b>",
	"const_TYPE_NUMBER": "Waarde type <b>getal</b>",
	"const_TYPE_STRING": "Type waarde <b>tekenreeks</b>",
	"const_USE_CRITICAL": "Waarde geretourneerd door de functies #useWeapon, #useWeaponOnCell, #useChip en #useChipOnCell in het geval van een voltreffer.",
	"const_USE_FAILED": "Waarde geretourneerd door de functies #useWeapon, #useWeaponOnCell, #useChip en #useChipOnCell bij een storing.",
	"const_USE_INVALID_COOLDOWN": "Waarde geretourneerd door de functies #useChip en #useChipOnCell als de chip nog niet bruikbaar is.",
	"const_USE_INVALID_POSITION": "Waarde geretourneerd door de functies #useWeapon, #useWeaponOnCell, #useChip en #useChipOnCell als het bereik slecht is of de zichtlijn niet duidelijk is.",
	"const_USE_INVALID_TARGET": "Waarde geretourneerd door de functies #useWeapon en #useChip als het doelwit niet bestaat.",
	"const_USE_NOT_ENOUGH_TP": "Waarde geretourneerd door de functies #useWeapon, #useWeaponOnCell, #useChip en #useChipOnCell als de caster niet genoeg actiepunten heeft om het object te gebruiken.",
	"const_USE_RESURRECT_INVALID_ENTITY": "Waarde die wordt geretourneerd door de functie #resurrect wanneer de opgegeven entiteit niet bestaat of nog niet dood is.",
	"const_USE_SUCCESS": "Waarde geretourneerd door de functies #useWeapon, #useWeaponOnCell, #useChip en #useChipOnCell bij succes.",
	"const_USE_TOO_MANY_SUMMONS": "Fout geretourneerd door #summon wanneer je al <b>8</b> live summons hebt.\n",
	"func_abs": "Retourneert de absolute waarde van het getal <b>getal</b>.",
	"func_abs_arg_1": "Het getal waarvan de absolute waarde wordt berekend.",
	"func_abs_return": "De absolute waarde van het getal.",
	"func_acos": "Berekent de arccosinus van <b>argument</b>, in het interval [0, #PI].",
	"func_acos_arg_1": "Getal waarvan de arccosinus wordt berekend.",
	"func_acos_return": "De boogcosinus van <b>argument</b>.",
	"func_arrayConcat": "Voeg de twee arrays end-to-end toe. Teksttoetsen blijven behouden en alle numerieke toetsen worden opnieuw geïndexeerd.",
	"func_arrayConcat_arg_1": "Eerste tafel.",
	"func_arrayConcat_arg_2": "Tweede tafel.",
	"func_arrayConcat_return": "Som van de twee arrays.",
	"func_arrayFilter": "Retourneert een nieuwe array met alle sleutel/waarde-paren van de bronarray waarvoor de callback-functie true heeft geretourneerd. Als de callback-functie één parameter nodig heeft, wordt de waarde van de bronarray verzonden, als er twee parameters nodig zijn, worden de sleutel en de waarde verzonden.",
	"func_arrayFilter_arg_1": "Originele tafel.",
	"func_arrayFilter_arg_2": "Functie aangeroepen voor elk element.",
	"func_arrayFilter_return": "Nieuwe tafel.",
	"func_arrayFlatten": "Retourneert een nieuwe array die alle elementen van de bronarray bevat. Alle elementen in een subarray worden geëxtraheerd naar de nieuwe array.Het diepte-argument bepaalt de maximale diepte van de subarray die moet worden geëxtraheerd.\n<code>var-array = [1, 2, [[3, 4], 5], [6]];\ndebug(arrayFlatten(array, 1)); // [1,2,[3,4],5,6]</code>",
	"func_arrayFlatten_arg_1": "Originele tafel.",
	"func_arrayFlatten_arg_2": "Maximale diepte.",
	"func_arrayFlatten_return": "Nieuwe tafel.",
	"func_arrayFoldLeft": "Verklein de array <b>array</b> [v1, v2, ..., vn] vanaf de linkerkant beginnend bij de waarde <b>v0</b> en pas de functie <b>f</b > toe. gelijk aan:\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "Originele tafel.",
	"func_arrayFoldLeft_arg_2": "Functie om toe te passen.",
	"func_arrayFoldLeft_arg_3": "Beginwaarde.",
	"func_arrayFoldLeft_return": "Verlaagde tafel.",
	"func_arrayFoldRight": "Verklein de array <b>array</b> [v1, v2, ..., vn] van rechts beginnend met de waarde <b>v0</b> en pas de functie <b>f</b > toe. gelijk aan:\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "Originele tafel.",
	"func_arrayFoldRight_arg_2": "Functie om toe te passen.",
	"func_arrayFoldRight_arg_3": "Beginwaarde.",
	"func_arrayFoldRight_return": "Verlaagde tafel.",
	"func_arrayIter": "Roept de callback-functie aan voor elk array-element. Als de callback-functie één parameter nodig heeft, wordt de waarde van de bronarray verzonden, als er twee parameters nodig zijn, worden de sleutel en de waarde verzonden.",
	"func_arrayIter_arg_1": "Originele tafel.",
	"func_arrayIter_arg_2": "Functie aangeroepen voor elk element.",
	"func_arrayMap": "Retourneert een nieuwe array die voor elke sleutel van de bronarray de waarde bevat die wordt geretourneerd door de callback-functie. Als de callback-functie één parameter nodig heeft, wordt de waarde van de bronarray verzonden, als er twee parameters nodig zijn, worden de sleutel en de waarde verzonden.",
	"func_arrayMap_arg_1": "Originele tafel.",
	"func_arrayMap_arg_2": "Functie aangeroepen voor elk element.",
	"func_arrayMap_return": "Nieuwe tafel.",
	"func_arrayMax": "Retourneert het maximale waarde-element van de <b>array</b> array.<br/>Zie #sort voor meer informatie over de maximale waarde van een array.",
	"func_arrayMax_arg_1": "Array waarin de maximale waarde wordt gezocht.",
	"func_arrayMax_return": "Artikel met maximale waarde.",
	"func_arrayMin": "Retourneert het minimumwaarde-element van de <b>array</b> array.<br/>Zie #sort voor meer informatie over de minimumwaarde van een array.",
	"func_arrayMin_arg_1": "Array waarin de minimale waarde wordt gezocht.",
	"func_arrayMin_return": "Artikel met minimale waarde.",
	"func_arrayPartition": "Retourneert een nieuwe array die twee lijsten bevat, in de eerste zijn alle sleutel/waarde-paren waarvoor de callback-functie waar heeft geretourneerd, in de tweede zijn alle andere. Als de callback-functie één parameter nodig heeft, wordt de waarde van de bronarray verzonden, als er twee parameters nodig zijn, worden de sleutel en de waarde verzonden.",
	"func_arrayPartition_arg_1": "Originele tafel.",
	"func_arrayPartition_arg_2": "Functie aangeroepen voor elk element.",
	"func_arrayPartition_return": "Nieuwe tafel.",
	"func_arraySort": "Sorteert de array volgens de volgorde die is gedefinieerd door de callback-functie. De elementen worden twee aan twee vergeleken, de callback-functie moet de waarden -1, 0 of 1 retourneren, afhankelijk van of de eerste waarde vóór, op hetzelfde niveau of na de tweede waarde is. Als de callback-functie 2 parameters nodig heeft, worden de twee waarden verzonden, als er 4 nodig zijn, worden de sleutel/waarde-paren verzonden.",
	"func_arraySort_arg_1": "Originele tafel",
	"func_arraySort_arg_2": "Sorteer functie.",
	"func_arraySort_return": "De gesorteerde array",
	"func_asin": "Berekent de boogsinus van <b>argument</b>, in het interval [0, #PI].",
	"func_asin_arg_1": "Getal waarvan de boogsinus wordt berekend.",
	"func_asin_return": "De boogsinus van <b>argument</b>.",
	"func_assocSort": "Sorteert de array <b>array</b> met behoud van de sleutel:waarde-associatie volgens de volgorde <b>volgorde</b>.",
	"func_assocSort_arg_1": "De array die moet worden gesorteerd.",
	"func_assocSort_arg_2": "De sorteervolgorde: #SORT_ASC of #SORT_DESC.",
	"func_atan": "Berekent de arctangens van <b>argument</b>, in het interval [0, #PI].",
	"func_atan2": "Converteert cartesiaanse coördinaten (<b>x</b>, <b>y</b>) naar poolcoördinaten (<b>r</b>, <b>theta</b>). Deze functie retourneert de <b>theta</b>-hoek tussen -#PI en #PI met behulp van de tekens van de argumenten.",
	"func_atan2_arg_1": "Y-coördinaat.",
	"func_atan2_arg_2": "X coördinaat.",
	"func_atan2_return": "De <b>theta</b> hoek in poolcoördinaten van het punt (x, y).",
	"func_atan_arg_1": "Getal waarvan de arctangens wordt berekend.",
	"func_atan_return": "De boogtangens van <b>argument</b>.",
	"func_average": "Berekent het gemiddelde van de elementen in de array <b>array</b>.",
	"func_average_arg_1": "Array waarvan we het gemiddelde willen berekenen.",
	"func_average_return": "Gemiddelde waarde.",
	"func_canUseChip": "Bepaalt of uw entiteit de <b>chip</b>-chip mag gebruiken op de entiteit met id <b>entiteit</b>.",
	"func_canUseChip_arg_1": "Het nummer van de te testen chip.",
	"func_canUseChip_arg_2": "Het entiteits-ID waarop u de chip wilt gebruiken.",
	"func_canUseChip_return": "<i>true</i> als uw entiteit de chip kan gebruiken, <i>false</i> anders.",
	"func_canUseChipOnCell": "Bepaalt of uw entiteit de <b>chip</b>-chip op de <b>cel</b>-cel kan gebruiken.",
	"func_canUseChipOnCell_arg_1": "Het nummer van de te testen chip.",
	"func_canUseChipOnCell_arg_2": "Het nummer van de cel waarop u de chip wilt gebruiken.",
	"func_canUseChipOnCell_return": "<i>true</i> als uw entiteit de chip kan gebruiken, <i>false</i> anders.",
	"func_canUseWeapon": "Bepaalt of je entiteit de entiteit met identiteit <b>entiteit</b> kan neerschieten met het wapen <b>wapen</b>.",
	"func_canUseWeapon_arg_1": "Het wapen om te testen. Standaard ingesteld op je momenteel uitgeruste wapen.",
	"func_canUseWeapon_arg_2": "De id van de entiteit waarop u wilt vuren.",
	"func_canUseWeapon_return": "<i>true</i> als je entiteit kan vuren, <i>false</i> anders.",
	"func_canUseWeaponOnCell": "Bepaalt of je entiteit de <b>cel</b> cel kan neerschieten met het <b>wapen</b> wapen.",
	"func_canUseWeaponOnCell_arg_1": "Het wapen om te testen. Standaard ingesteld op je momenteel uitgeruste wapen.",
	"func_canUseWeaponOnCell_arg_2": "Het nummer van de cel die u wilt fotograferen.",
	"func_canUseWeaponOnCell_return": "<i>true</i> als je entiteit kan vuren, <i>false</i> anders.",
	"func_cbrt": "Bereken de derdemachtswortel van <b>getal</b>",
	"func_cbrt_arg_1": "Getal waarvan we de derdemachtswortel willen berekenen.",
	"func_cbrt_return": "Kubuswortel van <b>getal</b>.",
	"func_ceil": "Retourneert de afronding naar boven van <b>getal</b>.<br/>U kunt de afronding naar beneden vinden met #floor, en de afronding met #round.",
	"func_ceil_arg_1": "Getal waarvoor we de afronding naar boven willen vinden.",
	"func_ceil_return": "Rond <b>getal</b> naar boven af.",
	"func_charAt": "Retourneert het teken van de tekenreeks <b>tekenreeks</b> op positie <b>positie</b>.<br/>Het eerste teken van de tekenreeks staat op positie 0.",
	"func_charAt_arg_1": "Tekenreeks waaruit we een teken willen ophalen.",
	"func_charAt_arg_2": "Positie van het personage dat moet worden gevonden.",
	"func_charAt_return": "tekenreeks die het gezochte teken bevat, of een lege tekenreeks als de positie ongeldig is.",
	"func_chipNeedLos": "Retourneert of de <b>chip</b> een gezichtslijn nodig heeft om te worden gebruikt.",
	"func_chipNeedLos_arg_1": "De id van de te testen chip.",
	"func_chipNeedLos_return": "<i>true</i> als de <b>chip</b> zichtlijn nodig heeft om te worden gebruikt, <i>false</i> anders.",
	"func_clearMarks": "Wist alle markeringen gemaakt door mark() en markText() in het veld.",
	"func_clone": "Kopieert de <b>waarde</b> doorgegeven als een parameter op <b>niveau</b> niveaus en retourneert de kopie. In het geval van een array bijvoorbeeld, kopieert een kloon (array, 1) de array maar niet de elementen ervan, een kloon (array, 2) kopieert zowel de array als alle elementen.",
	"func_clone_arg_1": "De waarde om te klonen",
	"func_clone_arg_2": "Het aantal niveaus dat moet worden gekloond",
	"func_clone_return": "De gekloonde waarde",
	"func_contains": "Bepaalt of een <b>zoekreeks</b> zich binnen een <b>reeks</b>-reeks bevindt.",
	"func_contains_arg_1": "Tekenreeks waarin de zoekopdracht zal worden uitgevoerd.",
	"func_contains_arg_2": "String om naar te zoeken.",
	"func_contains_return": "waar als <b>zoeken</b> in <b>tekenreeks</b> staat, anders onwaar.",
	"func_cos": "Berekent de cosinus van <b>hoek</b>.",
	"func_cos_arg_1": "Getal waarvan de cosinus wordt berekend (in radialen).",
	"func_cos_return": "De cosinus van <b>hoek</b>, in het interval [-1, 1].",
	"func_count": "Telt het aantal elementen in de <b>array</b> array.",
	"func_count_arg_1": "Array waarvan het aantal elementen wordt geteld.",
	"func_count_return": "Aantal array-elementen.",
	"func_debug": "Neemt een <b>object</b>-bericht op in het persoonlijke logboek, beschikbaar in het rapport aan het einde van het gevecht.",
	"func_debug_arg_1": "Het bericht om op te nemen.",
	"func_debugC": "Neemt een <b>object</b>-bericht op in het persoonlijke logboek, beschikbaar in het rapport aan het einde van het gevecht, van de kleur <b>kleur</b>.",
	"func_debugC_arg_1": "Het bericht om op te nemen.",
	"func_debugC_arg_2": "De kleur van het bericht. U kunt de functie #getColor gebruiken.",
	"func_debugE": "Registreert een <b>object</b>-foutmelding in het persoonlijke logboek, beschikbaar in het rapport aan het einde van het gevecht.<br />Foutmeldingen verschijnen in rood in het gevechtsrapport.",
	"func_debugE_arg_1": "De foutmelding om te loggen.",
	"func_debugW": "Neemt een <b>object</b>-waarschuwingsbericht op in het persoonlijke logboek, beschikbaar in het rapport aan het einde van het gevecht. <br />Waarschuwingsberichten verschijnen in het oranje in het gevechtsrapport.",
	"func_debugW_arg_1": "Het waarschuwingsbericht om op te nemen.",
	"func_deleteRegister": "Verwijdert het register dat is gekoppeld aan sleutel <b>sleutel</b> als deze bestaat.",
	"func_deleteRegister_arg_1": "De registersleutel die moet worden verwijderd.",
	"func_endsWith": "Bepaalt of een <b>string</b> eindigt met een <b>suffix</b> string.",
	"func_endsWith_arg_1": "Tekenreeks waarin de zoekopdracht zal worden uitgevoerd",
	"func_endsWith_arg_2": "Achtervoegsel om te zoeken",
	"func_endsWith_return": "waar als <b>tekenreeks</b> eindigt met <b>achtervoegsel</b>",
	"func_exp": "Verhoogt het getal van Euler #E tot de <b>getal</b> macht.",
	"func_exp_arg_1": "De exponent waartoe #E wordt verhoogd.",
	"func_exp_return": "#E** <b>nummer</b>.",
	"func_fill": "Wijzigt de grootte van de array <b>array</b> naar <b>size</b> en vult alle vakjes met <b>waarde</b>.",
	"func_fill_arg_1": "De tafel om te voltooien.",
	"func_fill_arg_2": "De waarde voor elk vierkant in de matrix.",
	"func_fill_arg_3": "Het aantal dozen. Standaard de huidige arraygrootte.",
	"func_floor": "Berekent de afronding naar beneden van <b>getal</b>.<br/>U vindt de afronding naar boven met #ceil en de afronding met #round.",
	"func_floor_arg_1": "Getal waarvan we de afronding naar beneden willen vinden.",
	"func_floor_return": "Naar beneden afgerond op <b>getal</b>.",
	"func_getAbsoluteShield": "Retourneert het absolute schild van de entiteit met id <b>entiteit</b>. Gebruik #getAbsoluteShield() zonder parameters om het absolute schild van uw entiteit direct op te halen.",
	"func_getAbsoluteShield_arg_1": "De id van de entiteit waarvan het absolute schild wordt geretourneerd.",
	"func_getAbsoluteShield_return": "Het absolute schild van de entiteit <b>entiteit</b>.",
	"func_getAgility": "Retourneert de flexibiliteit van de entiteit met id <b>entiteit</b>. Gebruik #getAgility() zonder parameters om de wendbaarheid van uw entiteit direct op te halen.",
	"func_getAgility_arg_1": "De id van de entiteit waarvan de behendigheid wordt geretourneerd.",
	"func_getAgility_return": "De flexibiliteit van de entiteit <b>entity</b>.",
	"func_getAIID": "Retourneert de AI-id van de entiteit <b>entiteit</b>. Gebruik getAIID zonder parameter om de id van uw AI op te halen.",
	"func_getAIID_arg_1": "De id van de entiteit waarvan de AI-id wordt geretourneerd.",
	"func_getAIID_return": "De AI-ID van de <b>entiteit</b> entiteit.",
	"func_getAIName": "Retourneert de AI-naam van entiteit <b>entiteit</b>. Gebruik getAINam() zonder parameters om de naam van uw AI op te halen.",
	"func_getAIName_arg_1": "De id van de entiteit waarvan de AI-naam wordt geretourneerd.",
	"func_getAIName_return": "De naam van de <b>entiteit</b> entiteit AI.",
	"func_getAliveAllies": "Retourneert een reeks van al je bondgenoten die nog in gevecht zijn.",
	"func_getAliveAllies_return": "Een array met de id's van al je levende bondgenoten.",
	"func_getAliveEnemies": "Retourneert een reeks van al je vijanden die nog in gevecht zijn.",
	"func_getAliveEnemiesCount": "Geeft het aantal levende vijanden in het gevecht terug.",
	"func_getAliveEnemiesCount_return": "Het aantal levende vijanden.",
	"func_getAliveEnemies_return": "Een array met de id's van al je levende vijanden.",
	"func_getAllChips": "Geeft de lijst met alle fiches in het spel terug.",
	"func_getAllChips_return": "De lijst met alle fiches in het spel.",
	"func_getAllEffects": "Retourneert de lijst met alle effecten in het spel.",
	"func_getAllEffects_return": "De lijst met alle effecten in het spel.",
	"func_getAllWeapons": "Geeft de lijst met alle wapens in het spel terug.",
	"func_getAllWeapons_return": "De lijst met alle wapens in het spel.",
	"func_getAlliedTurret": "Retourneert de turret-id van uw team of -1 als deze niet bestaat.",
	"func_getAlliedTurret_return": "De toren-ID van je team.",
	"func_getEnemyTurret": "Retourneert de vijandelijke geschutskoepel-ID of -1 als deze niet bestaat.",
	"func_getEnemyTurret_return": "De vijandelijke geschuts-ID.",
	"func_getAllies": "Retourneert een array met je bondgenoten en je entiteit.",
	"func_getAlliesCount": "Geeft het aantal bondgenoten in het gevecht terug.",
	"func_getAlliesCount_return": "Het aantal bondgenoten.",
	"func_getAlliesLife": "Geeft de totale gezondheid van je bondgenoten terug.",
	"func_getAlliesLife_return": "De totale gezondheid van je bondgenoten.",
	"func_getAllies_return": "De tafel van bondgenoten en uw entiteit.",
	"func_getBirthTurn": "Geeft de beurt van het gevecht terug waar de <b>entiteit</b> verscheen. Retourneert 1 als het bijvoorbeeld een prei is, en 5 als het een dagvaarding is die wordt ingeroepen in beurt 5.",
	"func_getBirthTurn_arg_1": "De id van de entiteit waarvan de spawn-beurt wordt geretourneerd.",
	"func_getBirthTurn_return": "De gevechtsbeurt waarin <b>entiteit</b> verscheen.",
	"func_getBlue": "Retourneert de hoeveelheid blauw in de kleur <b>kleur</b>, tussen 0 en 255. Bijvoorbeeld getBlue(#COLOR_BLUE) = 255 en getBlue(#COLOR_GREEN) = 0.",
	"func_getBlue_arg_1": "De kleur waarvan de blauwwaarde wordt geretourneerd.",
	"func_getBlue_return": "De mate van blauw in de kleur <b>kleur</b>",
	"func_getBulbChips": "Retourneert de lijst met chips die worden uitgerust op een lamp die wordt opgeroepen door de <b>bulb_chip</b>-chip.",
	"func_getBulbChips_arg_1": "De bolvlo.",
	"func_getBulbChips_return": "De chips van een lamp opgeroepen door de <b>bulb_chip</b> chip.",
	"func_getCell": "Retourneert de cel waar de entiteit met id <b>entiteit</b> zich bevindt. Gebruik getCell() zonder parameters om uw cel op te halen.",
	"func_getCell_arg_1": "De id van de entiteit waarvan de cel wordt geretourneerd.",
	"func_getCell_return": "Het celnummer waar de <b>entiteit</b> zich bevindt.",
	"func_getCellContent": "Retourneert de inhoud van een cel met id <b>cel</b>.",
	"func_getCellContent_arg_1": "De id van de cel waarvan de inhoud wordt geretourneerd.",
	"func_getCellContent_return": "De inhoud van de <b>cel</b> cel: #CELL_EMPTY voor een lege cel, #CELL_ENTITY voor een entiteit, #CELL_OBSTACLE voor een obstakel.",
	"func_getCellDistance": "Retourneert de afstand tussen twee cellen <b>cel1</b> en <b>cel2</b>. <br />De geretourneerde afstand wordt uitgedrukt in aantal cellen en houdt geen rekening met de verschillende obstakels tussen de twee cellen.\nOm de afstand hemelsbreed te krijgen, zie #getDistance en om de padafstand tussen de twee cellen te krijgen om obstakels te vermijden, zie #getPathLength.",
	"func_getCellDistance_arg_1": "De id van de startcel.",
	"func_getCellDistance_arg_2": "De id van de aankomstcel.",
	"func_getCellDistance_return": "De afstand tussen de twee cellen <b>cel1</b> en <b>cel2</b>.",
	"func_getCellFromXY": "Retourneert de id van de cel op positie (<b>x</b>, <b>y</b>).",
	"func_getCellFromXY_arg_1": "De x-positie van de cel.",
	"func_getCellFromXY_arg_2": "De y-positie van de cel.",
	"func_getCellFromXY_return": "De cel-ID op positie (<b>x</b>, <b>y</b>), <b>null</b> als de cel niet bestaat.",
	"func_getCellsToUseChip": "Retourneert de lijst met cellen waaruit uw entiteit de <b>chip</b>-chip op de <b>entiteit</b>-entiteit kan gebruiken.",
	"func_getCellsToUseChip_arg_1": "De chip die de entiteit wil kunnen gebruiken.",
	"func_getCellsToUseChip_arg_2": "De doelentiteit.",
	"func_getCellsToUseChip_arg_3": "Matrix van te negeren cellen.",
	"func_getCellsToUseChip_return": "Lijst met cellen waar de chip kan worden gebruikt.",
	"func_getCellsToUseChipOnCell": "Retourneert de lijst met cellen van waaruit uw entiteit de <b>chip</b> op cel <b>cel</b> kan gebruiken.",
	"func_getCellsToUseChipOnCell_arg_1": "De chip die de entiteit wil kunnen gebruiken.",
	"func_getCellsToUseChipOnCell_arg_2": "De doelcel.",
	"func_getCellsToUseChipOnCell_arg_3": "Matrix van te negeren cellen.",
	"func_getCellsToUseChipOnCell_return": "Lijst met cellen waar de chip kan worden gebruikt.",
	"func_getCellsToUseWeapon": "Retourneert de lijst met cellen van waaruit uw entiteit het wapen <b>wapen</b> kan gebruiken op de entiteit <b>entiteit</b>.",
	"func_getCellsToUseWeapon_arg_1": "Het wapen om te testen. Standaard ingesteld op je momenteel uitgeruste wapen.",
	"func_getCellsToUseWeapon_arg_2": "De doelentiteit.",
	"func_getCellsToUseWeapon_arg_3": "Matrix van te negeren cellen. Standaard een lege array.",
	"func_getCellsToUseWeapon_return": "Lijst met cellen van waaruit het wapen kan worden gebruikt.",
	"func_getCellsToUseWeaponOnCell": "Retourneert de lijst met cellen van waaruit uw entiteit het <b>wapen</b>-wapen op een <b>cel</b>-cel kan gebruiken.",
	"func_getCellsToUseWeaponOnCell_arg_1": "Het wapen om te testen. Standaard ingesteld op je momenteel uitgeruste wapen.",
	"func_getCellsToUseWeaponOnCell_arg_2": "De doelcel.",
	"func_getCellsToUseWeaponOnCell_arg_3": "Matrix van te negeren cellen. Standaard een lege array.",
	"func_getCellsToUseWeaponOnCell_return": "Lijst met cellen van waaruit het wapen kan worden gebruikt.",
	"func_getCellX": "Bepaalt de positie in X van de cel <b>cel</b>.",
	"func_getCellX_arg_1": "De cel waarvan de positie in X wordt bepaald.",
	"func_getCellX_return": "De X-positie van de cel.",
	"func_getCellY": "Bepaalt de Y-positie van de cel <b>cel</b>.",
	"func_getCellY_arg_1": "De cel waarvan de positie in Y zal worden bepaald.",
	"func_getCellY_return": "De Y-positie van de cel.",
	"func_getChipArea": "Retourneert het gebied van het effecttype van de <b>chip</b>.",
	"func_getChipArea_arg_1": "De chip waarvan het zonetype wordt geretourneerd.",
	"func_getChipArea_return": "Het gebiedstype van de chip <b>chip</b> onder de AREA_* constanten:\n<u>\n<li>#AREA_POINT: enkel vierkant gebied</li>\n<li>#AREA_LASER_LINE: lijn van een laser</li>\n<li>#AREA_CIRCLE_1: rond gebied met een diameter van 3 vierkanten</li>\n<li>#AREA_CIRCLE_2: rond gebied met een diameter van 5 vierkanten</li>\n<li>#AREA_CIRCLE_3: rond gebied met een diameter van 7 vierkanten</li>\n</ul>",
	"func_getChipCooldown": "Retourneert de hersteltijd van de <b>chip</b> chip, overgenomen uit de markt.",
	"func_getChipCooldown_arg_1": "De chip waarvan de cooldown wordt teruggegeven.",
	"func_getChipCooldown_return": "De cooldown van de <b>chip</b>.",
	"func_getChipCost": "Retourneert de kosten in TP van de chip <b>chip</b>.",
	"func_getChipCost_arg_1": "De chip die kosten zal worden geretourneerd.",
	"func_getChipCost_return": "De kosten van de <b>chip</b>.",
	"func_getChipEffectiveArea": "Retourneert de lijst met cellen die worden beïnvloed als de <b>chip</b>-chip wordt gebruikt op de <b>cel</b>-cel van een <b>uit</b>-cel.",
	"func_getChipEffectiveArea_arg_1": "De te testen chip.",
	"func_getChipEffectiveArea_arg_2": "De doelcel.",
	"func_getChipEffectiveArea_arg_3": "De cel van waaruit de chip wordt gebruikt.",
	"func_getChipEffectiveArea_return": "De array met de id's van alle cellen die worden beïnvloed.",
	"func_getChipEffects": "Retourneert de effecten van de <b>chip</b>-chip.",
	"func_getChipEffects_arg_1": "De chip waarvan de effecten worden geretourneerd.",
	"func_getChipEffects_return": "De effecten van de <b>chip</b>-chip. Dezelfde retourwaarde als voor de functie #getWeaponEffects.",
	"func_getChipFailure": "Retourneert het procentuele risico op falen van de chip <b>chip</b>.",
	"func_getChipFailure_arg_1": "De chip waarvan het uitvalpercentage wordt geretourneerd.",
	"func_getChipFailure_return": "<b>chip</b> chipfoutpercentage, een geheel getal tussen <b>0</b> en <b>100</b>.",
	"func_getChipMaxRange": "Retourneert het maximale bereik van de <b>chip</b>-chip.",
	"func_getChipMaxRange_arg_1": "De chip waarvan het maximale bereik wordt geretourneerd.",
	"func_getChipMaxRange_return": "Het maximale bereik van de <b>chip</b>.",
	"func_getChipMaxScope": "Retourneert het maximale bereik van de <b>chip</b>-chip.",
	"func_getChipMaxScope_arg_1": "De chip waarvan het maximale bereik wordt geretourneerd.",
	"func_getChipMaxScope_return": "Het maximale bereik van de <b>chip</b>.",
	"func_getChipMinRange": "Retourneert het minimale bereik van de <b>chip</b>-chip.",
	"func_getChipMinRange_arg_1": "De chip waarvan het minimale bereik wordt geretourneerd.",
	"func_getChipMinRange_return": "Het minimale bereik van de <b>chip</b>.",
	"func_getChipMinScope": "Retourneert het minimale bereik van de <b>chip</b>-chip.",
	"func_getChipMinScope_arg_1": "De chip waarvan het minimale bereik wordt geretourneerd.",
	"func_getChipMinScope_return": "Het minimale bereik van de <b>chip</b>.",
	"func_getChipLaunchType": "Retourneert de startmodus van de <b>chip</b>-chip, onder de LAUNCH_TYPE_*-constanten.",
	"func_getChipLaunchType_arg_1": "De id van de chip waarvan de throwing-modus wordt geretourneerd.",
	"func_getChipLaunchType_return": "De werpmodus van de <b>chip</b>-chip.",
	"func_getChipName": "Retourneert de naam van de chip <b>chip</b>.",
	"func_getChipName_arg_1": "De chip waarvan de naam wordt geretourneerd.",
	"func_getChipName_return": "De naam van de <b>chip</b>-chip.",
	"func_getChips": "Retourneert de opsommingstekens van de entiteit met id <b>entiteit</b>.",
	"func_getChips_arg_1": "De id van de entiteit waarvan de opsommingstekens worden geretourneerd.",
	"func_getChips_return": "Een array met de opsommingstekens van de entiteit <b>entiteit</b>.",
	"func_getChipTargets": "Retourneert de entiteiten die worden beïnvloed als de <b>chip</b>-chip wordt gebruikt op cel <b>cel</b>.",
	"func_getChipTargets_arg_1": "De te testen chip.",
	"func_getChipTargets_arg_2": "De doelcel.",
	"func_getChipTargets_return": "De array met de id's van alle entiteiten die worden beïnvloed.",
	"func_getColor": "Retourneert het gehele getal dat overeenkomt met de kleur (<b>rood</b>, <b>groen</b>, <b>blauw</b>) die als parameters is opgegeven.",
	"func_getColor_arg_1": "Roodwaarde tussen 0 en 255.",
	"func_getColor_arg_2": "Groenwaarde tussen 0 en 255.",
	"func_getColor_arg_3": "Blauwwaarde tussen 0 en 255.",
	"func_getColor_return": "int die overeenkomt met de kleur die als parameter is opgegeven.",
	"func_getCooldown": "Retourneert de huidige cooldown van de <b>chip</b> van de entiteit <b>entity</b>.",
	"func_getCooldown_arg_1": "De chip waarvan de huidige cooldown wordt geretourneerd.",
	"func_getCooldown_arg_2": "De entiteit waarvan de cooldown wordt geretourneerd.",
	"func_getCooldown_return": "De huidige cooldown van de chip <b>chip</b>, dit is het aantal beurten voordat de chip bruikbaar wordt, <b>0</b> als deze momenteel bruikbaar is.",
	"func_getCores": "Retourneert het aantal kernen van de entiteit met id <b>entiteit</b>.",
	"func_getCores_arg_1": "De entiteit waarvan het aantal kernen wordt geretourneerd.",
	"func_getCores_return": "Het aantal cores van de entiteit <b>entity</b>.",
	"func_getDate": "Retourneert de datum van het gevecht, in de notatie dd/MM/jjjj.",
	"func_getDate_return": "De datum van het gevecht.",
	"func_getTime": "Retourneert de gevechtstijd, in de notatie UU:mm:ss.",
	"func_getTime_return": "De tijd van het gevecht.",
	"func_getTimestamp": "Retourneert de tijdstempel van het gevecht, gelijk aan het aantal seconden sinds 1 januari 1970.",
	"func_getTimestamp_return": "De tijdstempel van het gevecht.",
	"func_getDamageReturn": "Retourneert het schadepercentage van de entiteit met id <b>entiteit</b>.",
	"func_getDamageReturn_arg_1": "De id van de entiteit waarvan de schadeverwijzing wordt geretourneerd.",
	"func_getDamageReturn_return": "Het schadepercentage van de entiteit met identiteit <b>entiteit</b> (in %).",
	"func_getDeadAllies": "Retourneert dode vriendelijke entiteiten.",
	"func_getDeadAllies_return": "De tabel met geallieerde dode entiteiten.",
	"func_getDeadEnemies": "Geeft dode vijandelijke entiteiten terug.",
	"func_getDeadEnemiesCount": "Retourneert het aantal vijanden dat is omgekomen in een gevecht.",
	"func_getDeadEnemiesCount_return": "Het aantal dode vijanden.",
	"func_getDeadEnemies_return": "De tabel met dode vijandelijke entiteiten.",
	"func_getDistance": "Berekent hemelsbreed de afstand tussen twee cellen <b>cel1</b> en <b>cel2</b>. <br />Om de afstand in aantal cellen te krijgen, zie #getCellDistance, en om de lengte te krijgen van het pad tussen de twee cellen die de verschillende obstakels ontwijken, zie #getPathLength.",
	"func_getDistance_arg_1": "De startcel.",
	"func_getDistance_arg_2": "De aankomstcel.",
	"func_getDistance_return": "De afstand hemelsbreed tussen de twee cellen.",
	"func_getPassiveEffects": "Retourneert de lijst met passieve effecten van de entiteit met id <b>entiteit</b>. Gebruik #getPassiveEffects() zonder parameters om de lijst met passieve effecten van uw entiteit direct op te halen.",
	"func_getPassiveEffects_arg_1": "De id van de entiteit waarvan de lijst met passieve effecten wordt geretourneerd.",
	"func_getPassiveEffects_return": "De lijst met passieve effecten die momenteel aanwezig zijn op de entiteit <b>entity</b>.\nDe lijst met passieve effecten is een array met de effecten.\nEen effect is zelf een array van 7 vakken van de vorm: [<b>type</b>, <b>value</b>, <b>caster_id</b>, <b>turns</b >, <b>kritiek</b>, <b>item_id</b>, <b>target_id</b>]. Het is dezelfde structuur als de klassieke effecten die worden geretourneerd door de functie #getEffects.",
	"func_getEnemies": "Brengt vijandelijke entiteiten (levend of dood) terug in de strijd.",
	"func_getEnemiesCount": "Geeft het aantal vijanden in het gevecht terug.",
	"func_getEnemiesCount_return": "Het aantal vijanden.",
	"func_getEnemiesLife": "Berekent de som van de hitpoints van alle vijandelijke entiteiten.",
	"func_getEnemiesLife_return": "De som van de hitpoints van het vijandelijke team.",
	"func_getEnemies_return": "Een array met de ID's van alle vijandelijke entiteiten.",
	"func_getEntityTurnOrder": "Retourneert een waarde tussen 1 en n (aantal entiteiten die momenteel worden afgespeeld) waarmee de positie van de <b>entiteit</b> in afspeelvolgorde wordt aangegeven.",
	"func_getEntityTurnOrder_arg_1": "De id van de entiteit waarvan de afspeelvolgorde wordt geretourneerd",
	"func_getEntityTurnOrder_return": "Plaats in volgorde van spelen van de entiteit <b>entiteit</b>",
	"func_getFarmerID": "Retourneert de fokker-ID van de entiteit <b>entiteit</b>.",
	"func_getFarmerID_arg_1": "De id van de entiteit waarvan de fokker-id wordt geretourneerd.",
	"func_getFarmerID_return": "De id van de fokker van de <b>entity</b> entiteit.",
	"func_getFarmerCountry": "Retourneert het land van de fokker van de entiteit <b>entiteit</b>.",
	"func_getFarmerCountry_arg_1": "De id van de entiteit waarvan het land van de fokker wordt geretourneerd.",
	"func_getFarmerCountry_return": "Het land van de fokker van de <b>entiteit</b> entiteit, of \"?\" indien niet aangegeven.",
	"func_getFarmerName": "Retourneert de naam van de fokker van de entiteit <b>entity</b>.",
	"func_getFarmerName_arg_1": "De id van de entiteit waarvan de naam van de fokker wordt geretourneerd.",
	"func_getFarmerName_return": "De naam van de fokker van de <b>entiteit</b> entiteit.",
	"func_getFarthestAlly": "Bepaalt hemelsbreed de verste bondgenoot van je entiteit.",
	"func_getFarthestAlly_return": "De id van de verste bevriende entiteit.",
	"func_getFarthestEnemy": "Bepaalt hemelsbreed de verste vijand van je entiteit.",
	"func_getFarthestEnemy_return": "De id van de verste vijandelijke entiteit.",
	"func_getFightID": "Retourneert het huidige gevechts-ID.",
	"func_getFightID_return": "De id van het huidige gevecht.",
	"func_getFightContext": "Geeft de context van het huidige gevecht terug.",
	"func_getFightContext_return": "Afhankelijk van gevechtscontext: testgevecht (#FIGHT_CONTEXT_TEST), arenagevecht (#FIGHT_CONTEXT_GARDEN), toernooigevecht (#FIGHT_CONTEXT_TOURNAMENT), uitdagingsgevecht (#FIGHT_CONTEXT_CHALLENGE), Battle Royale (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "Geeft het huidige gevechtstype terug.",
	"func_getFightType_return": "Afhankelijk van het type gevecht: sologevecht (#FIGHT_TYPE_SOLO), boerengevecht (#FIGHT_TYPE_FARMER), teamgevecht (#FIGHT_TYPE_TEAM), Battle Royale (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "Retourneert de sterkte van de entiteit met id <b>entiteit</b>.",
	"func_getForce_arg_1": "De entiteit waarvan de kracht wordt teruggegeven.",
	"func_getForce_return": "De kracht van de <b>entiteit</b> entiteit.",
	"func_getFrequency": "Retourneert de frequentie van de entiteit met id <b>entiteit</b>. Gebruik getFrequency() zonder parameters om uw frequentie op te halen.",
	"func_getFrequency_arg_1": "De entiteit waarvan de frequentie wordt geretourneerd.",
	"func_getFrequency_return": "De frequentie van de entiteit <b>entity</b>.",
	"func_getGreen": "Retourneert de hoeveelheid groen in de kleur <b>kleur</b>, tussen 0 en 255. Bijvoorbeeld getGreen(#COLOR_GREEN) = 255 en getGreen(#COLOR_RED) = 0.",
	"func_getGreen_arg_1": "De kleur waarvan de groensnelheid wordt geretourneerd.",
	"func_getGreen_return": "De snelheid van groen in de kleur <b>kleur</b>",
	"func_getInstructionsCount": "Retourneert het aantal instructies dat uw entiteit tijdens de huidige beurt heeft uitgevoerd.",
	"func_getInstructionsCount_return": "Het aantal instructies dat je entiteit tijdens de huidige beurt heeft uitgevoerd.",
	"func_getLaunchedEffects": "Retourneert de lijst met effecten veroorzaakt door de entiteit met id <b>entiteit</b>.",
	"func_getLaunchedEffects_arg_1": "De id van de entiteit waarvan de lijst met veroorzaakte effecten wordt geretourneerd.",
	"func_getLaunchedEffects_return": "De lijst met effecten veroorzaakt door de entiteit met id <b>entity</b>, in dezelfde vorm als de array die wordt geretourneerd door #getEffects.",
	"func_getLeek": "Retourneert de id van uw entiteit.",
	"func_getLeek_return": "De id van uw entiteit.",
	"func_getEntity": "Retourneert de id van uw entiteit.",
	"func_getEntity_return": "De id van uw entiteit.",
	"func_getLeekID": "Retourneert de echte id van de prei van id <b>leek</b>.",
	"func_getLeekID_arg_1": "De id van de prei waarvan de werkelijke id wordt geretourneerd.",
	"func_getLeekID_return": "De echte id van de prei <b>entiteit</b>.",
	"func_getLeekOnCell": "Retourneert de entiteit die zich op cel <b>cel</b> bevindt.",
	"func_getLeekOnCell_arg_1": "De cel waarvan we de entiteit willen ophalen.",
	"func_getLeekOnCell_return": "De id van de entiteit in de cel, of -1 als de cel geen entiteit heeft.",
	"func_getEntityOnCell": "Retourneert de entiteit die zich op cel <b>cel</b> bevindt.",
	"func_getEntityOnCell_arg_1": "De cel waarvan we de entiteit willen ophalen.",
	"func_getEntityOnCell_return": "De id van de entiteit in de cel, of -1 als de cel geen entiteit heeft.",
	"func_getLevel": "Retourneert het entiteitsniveau met id <b>entiteit</b>.",
	"func_getLevel_arg_1": "De id van de entiteit waarvan het niveau wordt geretourneerd.",
	"func_getLevel_return": "Het niveau van de entiteit met id <b>entiteit</b>.",
	"func_getLife": "Retourneert de huidige levensduur van de entiteit met id <b>entiteit</b>. Gebruik getLife() zonder parameters om je leven op te halen.",
	"func_getLife_arg_1": "De id van de entiteit wiens leven wordt geretourneerd.",
	"func_getLife_return": "De huidige levensduur van de entiteit <b>entity</b>.",
	"func_getMagic": "Retourneert de magie van de entiteit met id <b>entiteit</b>. Gebruik getMagic() zonder parameters om je magie op te halen.",
	"func_getMagic_arg_1": "De id van de entiteit waarvan de magie wordt geretourneerd.",
	"func_getMagic_return": "De magie van id-entiteit <b>entiteit</b>.",
	"func_getMapType": "Geeft het type terrein terug waarop het gevecht plaatsvindt (fabriek, woestijn, bos etc.), tussen de constanten #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER en #MAP_BEACH.",
	"func_getMapType_return": "Het type terrein.",
	"func_getMessageAuthor": "Retourneert de id van de entiteit die het bericht <b>bericht</b> heeft geschreven.",
	"func_getMessageAuthor_arg_1": "Het bericht waarvan de auteur wordt geretourneerd.",
	"func_getMessageAuthor_return": "De id van de entiteit die het bericht <b>bericht</b> opstelt.",
	"func_getMessageParams": "Retourneert de reeks berichtparameters <b>message</b>.",
	"func_getMessageParams_arg_1": "Het bericht waarvan de parameters worden geretourneerd.",
	"func_getMessageParams_return": "De <b>bericht</b> berichtparameters.",
	"func_getMessages": "Retourneert de berichtarray van de <b>entiteit</b> entiteit.",
	"func_getMessages_arg_1": "De entiteit waarvan de berichten worden geretourneerd.",
	"func_getMessages_return": "De tabel met uw berichten.<br>Een bericht wordt zelf weergegeven in de vorm van een\nmatrix van de vorm: [<b>auteur</b>, <b>type</b>, <b>parameters</b>]<br>\nDe verschillende soorten berichten worden weergegeven door de constanten:\n<u>\n<li>#MESSAGE_HEAL: verzoek om zorg</li>\n<li>#MESSAGE_ATTACK: verzoek om aan te vallen</li>\n<li>#MESSAGE_BUFF_FORCE: boostverzoek forceren</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "Retourneert het berichttype <b>bericht</b>.",
	"func_getMessageType_arg_1": "Het bericht waarvan het type wordt geretourneerd (tussen #MESSAGE_HEAL, #MESSAGE_ATTACK, enz.).",
	"func_getMessageType_return": "Het berichttype <b>bericht</b>.",
	"func_getMP": "Retourneert het huidige aantal bewegingspunten van de entiteit <b>entity</b>. Gebruik getMP() zonder parameters om je PM's op te halen.",
	"func_getMP_arg_1": "De id van de entiteit waarvan de PM-telling wordt geretourneerd.",
	"func_getMP_return": "Het MP-nummer van de entiteit <b>entity</b>.",
	"func_getName": "Retourneert de naam van de entiteit met id <b>entiteit</b>.",
	"func_getName_arg_1": "De id van de entiteit waarvan de naam wordt geretourneerd.",
	"func_getName_return": "De naam van de entiteit <b>entiteit</b>.",
	"func_getNearestAlly": "Retourneert de dichtstbijzijnde vriendelijke entiteit voor uw entiteit.",
	"func_getNearestAlly_return": "De id van de dichtstbijzijnde bevriende entiteit.",
	"func_getNearestAllyTo": "Retourneert de entiteit die het dichtst bij de entiteit staat die als parameter is opgegeven.",
	"func_getNearestAllyTo_arg_1": "De id van de entiteit waarvan we de nauwste bondgenoot willen weten.",
	"func_getNearestAllyToCell": "Retourneert de entiteit die het dichtst bij de cel staat die als parameter is opgegeven.",
	"func_getNearestAllyToCell_arg_1": "De id van de cel waarvan we de nauwste bondgenoot willen weten.",
	"func_getNearestAllyToCell_return": "De id van de dichtstbijzijnde bevriende entiteit.",
	"func_getNearestAllyTo_return": "De id van de dichtstbijzijnde bevriende entiteit.",
	"func_getNearestEnemy": "Retourneert de vijandelijke entiteit die het dichtst bij jouw entiteit staat.",
	"func_getNearestEnemy_return": "De id van de dichtstbijzijnde vijandelijke entiteit.",
	"func_getNearestEnemyTo": "Retourneert de vijandelijke entiteit die het dichtst bij de entiteit staat die als parameter is opgegeven.",
	"func_getNearestEnemyTo_arg_1": "De id van de entiteit wiens naaste vijand we willen weten.",
	"func_getNearestEnemyToCell": "Retourneert de vijandelijke entiteit die zich het dichtst bij de cel bevindt die als parameter is opgegeven.",
	"func_getNearestEnemyToCell_arg_1": "De id van de cel waarvan we de naaste vijand willen weten.",
	"func_getNearestEnemyToCell_return": "De id van de dichtstbijzijnde vijandelijke entiteit.",
	"func_getNearestEnemyTo_return": "De id van de dichtstbijzijnde vijandelijke entiteit.",
	"func_getNextPlayer": "Retourneert de id van de entiteit die zal spelen na de huidige speler.",
	"func_getNextPlayer_return": "De volgende speler.",
	"func_getObstacles": "Geeft de lijst met hindernissen op het terrein terug.",
	"func_getObstacles_return": "De array met de ID's van de obstakelcellen.",
	"func_getOperations": "Retourneert het aantal bewerkingen dat door uw entiteit is verbruikt sinds het begin van zijn beurt. Dit aantal moet lager blijven dan #OPERATIONS_LIMIT zodat de entiteit niet vastloopt.",
	"func_getOperations_return": "Aantal bewerkingen dat door uw entiteit is verbruikt sinds het begin van zijn beurt.",
	"func_getPath": "Retourneert het pad waarbij obstakels tussen twee cellen <b>cell1</b> en <b>cell2</b> worden vermeden, indien aanwezig, waarbij de cellen in de array <b>ignoredCells</b> worden genegeerd. Als een speler zich op een genegeerde cel bevindt, kan het pad er overheen gaan.<br /><br />\nDe startcel <b>cell1</b> maakt nooit deel uit van het resulterende pad. Cel <b>cell2</b> maakt deel uit van het resulterende pad als en slechts als het leeg is of wordt genegeerd door <b>ignoredCells</b>.<br /><br />\nAls er geen pad tussen de twee cellen bestaat, retourneert <b>getPath</b> <i>null</i>.",
	"func_getPath_arg_1": "De startcel.",
	"func_getPath_arg_2": "De aankomstcel.",
	"func_getPath_arg_3": "De reeks cellen die moet worden genegeerd.",
	"func_getPath_return": "De array met de cellen die het pad tussen de twee cellen vormen.",
	"func_getPathLength": "Retourneert de lengte van het pad tussen twee cellen <b>cell1</b> en <b>cell2</b>, vermijdt obstakels en negeert de cellen in de array <b>ignoredCells</b>. Deze functie is gelijk aan <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>.\nAls een speler zich op een genegeerde cel bevindt, kan het pad er overheen gaan.<br /><br />\nDe startcel <b>cell1</b> wordt nooit meegeteld in het resultaat. Cel <b>cell2</b> wordt meegeteld in het resultaat als en alleen als deze leeg is of wordt genegeerd door <b>ignoredCells</b>.<br /><br />\nAls er geen pad bestaat tussen de twee cellen, retourneert <b>getPathLength</b> <i>null</i>.",
	"func_getPathLength_arg_1": "De startcel.",
	"func_getPathLength_arg_2": "De aankomstcel.",
	"func_getPathLength_arg_3": "De reeks cellen die moet worden genegeerd.",
	"func_getPathLength_return": "De lengte van het pad tussen <b>cel1</b> en <b>cel2</b>.",
	"func_getPreviousPlayer": "Retourneert de id van de entiteit die vóór de huidige speler speelde.",
	"func_getPreviousPlayer_return": "De vorige speler.",
	"func_getRed": "Retourneert de hoeveelheid rood in de kleur <b>kleur</b>, tussen 0 en 255. Bijvoorbeeld getRed(#COLOR_RED) = 255 en getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "De kleur waarvan de roodsnelheid wordt geretourneerd.",
	"func_getRed_return": "De snelheid van rood in de kleur <b>kleur</b>",
	"func_getRegister": "Retourneert de waarde die is opgeslagen in het entiteitsregister dat is gekoppeld aan sleutel <b>key</b> of </i>null</i> als het register niet bestaat.",
	"func_getRegister_arg_1": "De registersleutel waarvan de waarde wordt geretourneerd.",
	"func_getRegister_return": "De waarde die is opgeslagen in het register <b>sleutel</b>.",
	"func_getRegisters": "Retourneert de set entiteitsregisters als een associatieve array [<i>registersleutel</i>:<i>registerwaarde</i>]. Voorbeeld: <code>debug(getRegisters());\n// Toont bijvoorbeeld:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "De associatieve array die overeenkomt met alle registers van de entiteit.",
	"func_getRelativeShield": "Retourneert het relatieve schild van de entiteit met id <b>entiteit</b>. Gebruik #getRelativeShield() zonder parameters om het relatieve schild van uw entiteit direct op te halen.",
	"func_getRelativeShield_arg_1": "De id van de entiteit waarvan het relatieve schild wordt geretourneerd.",
	"func_getRelativeShield_return": "Het relatieve schild van de <b>entiteit</b>, een geheel getal tussen <b>0</b> en <b>100</b>.",
	"func_getResistance": "Retourneert de weerstand van de entiteit met id <b>entiteit</b>. Gebruik getResistance() zonder parameters om uw weerstand op te halen.",
	"func_getResistance_arg_1": "De id van de entiteit waarvan de weerstand wordt geretourneerd.",
	"func_getResistance_return": "De weerstand van de entiteit met identiteit <b>entiteit</b>.",
	"func_getScience": "Retourneert de wetenschap van de entiteit met id <b>entity</b>. Gebruik getScience() zonder parameters om uw wetenschap op te halen.",
	"func_getScience_arg_1": "De id van de entiteit waarvan de wetenschap wordt geretourneerd.",
	"func_getScience_return": "De wetenschap van id-entiteit <b>entiteit</b>.",
	"func_getStrength": "Retourneert de sterkte van de entiteit met id <b>entiteit</b>. Gebruik getStrength() zonder parameters om je kracht op te halen.",
	"func_getStrength_arg_1": "De entiteit waarvan de kracht wordt teruggegeven.",
	"func_getStrength_return": "De kracht van de <b>entiteit</b> entiteit.",
	"func_getPower": "Retourneert de macht van de entiteit met id <b>entiteit</b>.",
	"func_getPower_arg_1": "De entiteit waarvan de macht zal worden teruggegeven.",
	"func_getPower_return": "De kracht van <b>entiteit</b>.",
	"func_getSummoner": "Retourneert de entiteit die de entiteit <b>entiteit</b> heeft aangeroepen, als het een aanroep is.",
	"func_getSummoner_arg_1": "De id van de entiteit waarvan de oproeper wordt ontslagen.",
	"func_getSummoner_return": "De id van de aangeroepen entiteit <b>entiteit</b> als het een aanroeping is, anders <i>null</i>.",
	"func_getSummons": "Retourneert de lijst met id's van momenteel actieve aanroepen van de entiteit met id <b>entity</b>.",
	"func_getSummons_arg_1": "De id van de entiteit waarvan de dagvaarding wordt geretourneerd.",
	"func_getSummons_return": "De lijst met aanroep-ID's van de entiteit met id <b>entiteit</b>.",
	"func_getTeamID": "Retourneert de team-ID van entiteit <b>entiteit</b>.",
	"func_getTeamID_arg_1": "De id van de entiteit waarvan de team-id wordt geretourneerd.",
	"func_getTeamID_return": "De team-ID van de entiteit <b>entiteit</b>.",
	"func_getTeamName": "Retourneert de teamnaam van de entiteit <b>entiteit</b>.",
	"func_getTeamName_arg_1": "De id van de entiteit waarvan de teamnaam wordt geretourneerd.",
	"func_getTeamName_return": "De teamnaam van de entiteit <b>entity</b>.",
	"func_getTotalLife": "Retourneert de totale levensduur van de entiteit met id <b>entiteit</b>. Gebruik getTotalLife() zonder parameters om uw totale levensduur op te halen.",
	"func_getTotalLife_arg_1": "De id van de entiteit waarvan de totale levensduur wordt geretourneerd.",
	"func_getTotalLife_return": "De totale levensduur van de entiteit.",
	"func_getTotalMP": "Retourneert het maximum aantal bewegingspunten van de entiteit <b>entiteit</b>.",
	"func_getTotalMP_arg_1": "De id van de entiteit waarvan de maximale verplaatsingspunten worden geretourneerd.",
	"func_getTotalMP_return": "Het maximale aantal verplaatsingspunten voor de <b>entiteit</b>.",
	"func_getTotalTP": "Retourneert het maximum aantal keerpunten van de entiteit <b>entiteit</b>.",
	"func_getTotalTP_arg_1": "De id van de entiteit waarvan het maximale aantal keerpunten wordt geretourneerd.",
	"func_getTotalTP_return": "Het maximale aantal keerpunten van de entiteit <b>entity</b>.",
	"func_getTP": "Retourneert het aantal keerpunten van de entiteit <b>entiteit</b>. Gebruik getTP() zonder parameters om uw PT's op te halen.",
	"func_getTP_arg_1": "De id van de entiteit waarvan de PT's worden geretourneerd.",
	"func_getTP_return": "Het PT-nummer van de <b>entiteit</b> entiteit.",
	"func_getTurn": "Geeft de huidige gevechtsbeurt terug. Het maximale aantal beurten is #MAX_TURNS.",
	"func_getTurn_return": "De huidige strijd.",
	"func_getType": "Retourneert het entiteitstype van entiteit <b>entiteit</b>.",
	"func_getType_arg_1": "De id van de entiteit waarvan het type wordt geretourneerd.",
	"func_getType_return": "Het entiteitstype van <b>entiteit</b>:<ul><li>#ENTITY_LEEK als het een prei is.</li><li>#ENTITY_BULB als het een bol is.</li><li >#ENTITY_TURRET als het een geschutskoepel is.</li></ul>",
	"func_getWeapon": "Geeft de momenteel uitgeruste wapen <b>entiteit</b> terug.",
	"func_getWeapon_arg_1": "De id van de entiteit waarvan het huidige wapen wordt geretourneerd.",
	"func_getWeapon_return": "De id van het momenteel uitgeruste wapen op de <b>entiteit</b>, null als de entiteit geen uitgerust wapen heeft of de entiteit niet bestaat.",
	"func_getWeaponArea": "Retourneert het effectgebied van het wapen <b>wapen</b>.",
	"func_getWeaponArea_arg_1": "Het wapen waarvan het gebiedstype wordt geretourneerd.",
	"func_getWeaponArea_return": "Het gebiedstype van het wapen <b>wapen</b> tussen de AREA_*-constanten:\n<u>\n<li>#AREA_POINT: enkel vierkant gebied</li>\n<li>#AREA_LASER_LINE: lijn van een laser</li>\n<li>#AREA_CIRCLE_1: rond gebied met een diameter van 3 cellen</li>\n<li>#AREA_CIRCLE_2: rond gebied met een diameter van 5 vierkanten</li>\n<li>#AREA_CIRCLE_3: rond gebied met een diameter van 7 vierkanten</li>\n</ul>",
	"func_getWeaponCost": "Retourneert de TP-kosten van het <b>wapen</b> wapen.",
	"func_getWeaponCost_arg_1": "De id van het wapen waarvan de kosten worden geretourneerd.",
	"func_getWeaponCost_return": "De TP-kosten van het <b>wapen</b> wapen.",
	"func_getWeaponEffectiveArea": "Retourneert de lijst met cellen die worden beïnvloed als het wapen <b>wapen</b> wordt gebruikt op cel <b>cel</b> vanaf cel <b>van</b>.",
	"func_getWeaponEffectiveArea_arg_1": "Het wapen om te testen.",
	"func_getWeaponEffectiveArea_arg_2": "De doelcel.",
	"func_getWeaponEffectiveArea_arg_3": "De cel van waaruit het wapen wordt afgevuurd.",
	"func_getWeaponEffectiveArea_return": "De array met de id's van alle cellen die worden beïnvloed.",
	"func_getWeaponPassiveEffects": "Retourneert de passieve effecten van het wapen <b>wapen</b>.",
	"func_getWeaponPassiveEffects_arg_1": "De id van het wapen waarvan de passieve effecten worden geretourneerd.",
	"func_getWeaponPassiveEffects_return": "Een array met de effecten van het <b>wapen</b> wapen. Elk effect is zelf een array van het formulier\n[type, min, max, bochten, doelen, modifiers]. Deze effecten zijn dezelfde als die worden geretourneerd door #getWeaponEffects.",
	"func_getWeaponFailure": "Retourneert het <b>wapen</b> kanspercentage voor het mislukken van wapens.",
	"func_getWeaponFailure_arg_1": "De id van het wapen waarvan het faalpercentage wordt geretourneerd.",
	"func_getWeaponFailure_return": "<b>wapen</b> wapen miss percentage, een geheel getal tussen <b>0</b> en <b>100</b>.",
	"func_getWeaponMaxRange": "Retourneert het maximale bereik van het <b>wapen</b> wapen.",
	"func_getWeaponMaxRange_arg_1": "De id van het wapen waarvan het maximale bereik wordt geretourneerd.",
	"func_getWeaponMaxRange_return": "Het maximale bereik van het <b>wapen</b> wapen.",
	"func_getWeaponMaxScope": "Retourneert het maximale bereik van het <b>wapen</b> wapen.",
	"func_getWeaponMaxScope_arg_1": "De id van het wapen waarvan het maximale bereik wordt geretourneerd.",
	"func_getWeaponMaxScope_return": "Het maximale bereik van het <b>wapen</b> wapen.",
	"func_getWeaponMinRange": "Retourneert het minimale bereik van het <b>wapen</b> wapen.",
	"func_getWeaponMinRange_arg_1": "De id van het wapen waarvan het minimale bereik wordt geretourneerd.",
	"func_getWeaponMinRange_return": "Het minimale bereik van het <b>wapen</b> wapen.",
	"func_getWeaponMinScope": "Retourneert het minimale bereik van het <b>wapen</b> wapen.",
	"func_getWeaponMinScope_arg_1": "De id van het wapen waarvan het minimale bereik wordt geretourneerd.",
	"func_getWeaponMinScope_return": "Het minimale bereik van het <b>wapen</b> wapen.",
	"func_getWeaponLaunchType": "Retourneert de startmodus van het <b>wapen</b> wapen, onder de LAUNCH_TYPE_* constanten.",
	"func_getWeaponLaunchType_arg_1": "De id van het wapen waarvan de werpmodus wordt geretourneerd. Standaard ingesteld op je momenteel uitgeruste wapen.",
	"func_getWeaponLaunchType_return": "De werpmodus van het <b>wapen</b> wapen.",
	"func_getWeaponName": "Retourneert de naam van het <b>wapen</b> wapen.",
	"func_getWeaponName_arg_1": "De id van het wapen waarvan de naam wordt geretourneerd.",
	"func_getWeaponName_return": "De naam van het <b>wapen</b> wapen.",
	"func_getWeapons": "Retourneert de wapens van de entiteit met id <b>entiteit</b>.",
	"func_getWeapons_arg_1": "De id van de entiteit waarvan de wapens worden teruggegeven.",
	"func_getWeapons_return": "Een array met de wapen-ID's van de <b>entiteit</b>.",
	"func_getWeaponTargets": "Retourneert de entiteiten die worden beïnvloed als het wapen <b>wapen</b> wordt gebruikt op cel <b>cel</b>.",
	"func_getWeaponTargets_arg_1": "Het wapen om te testen.",
	"func_getWeaponTargets_arg_2": "De doelcel.",
	"func_getWeaponTargets_return": "De array met de id's van alle entiteiten die worden beïnvloed.",
	"func_getWisdom": "Retourneert de wijsheid van de entiteit met id <b>entiteit</b>. Gebruik getWisdom() zonder parameters om je wijsheid op te halen.",
	"func_getWisdom_arg_1": "De id van de entiteit waarvan de wijsheid wordt geretourneerd.",
	"func_getWisdom_return": "De wijsheid van de entiteit met identiteit <b>entiteit</b>.",
	"func_hypot": "Geeft de schuine zijde van de rechthoekige driehoek met zijden x en y. Gelijk aan <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "De x-waarde.",
	"func_hypot_arg_2": "De y-waarde.",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "Bepaalt of het <b>element</b> element zich in de <b>array</b> array bevindt.",
	"func_inArray_arg_1": "De zoektabel.",
	"func_inArray_arg_2": "Het item om naar te zoeken.",
	"func_inArray_return": "<i>true</i> als het element in de array staat, <i>false</i> anders.",
	"func_include": "Neem de AI genaamd <b>ai</b> op in de huidige AI. <br><br><b>Waarschuwing</b>, de functie include mag alleen in het hoofdblok worden aangeroepen en de parameter moet een rechtstreeks geschreven tekenreeks zijn\nin de code. Zie voor meer informatie het gedeelte in de tutorial: <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a> .",
	"func_include_arg_1": "De naam van de op te nemen AI.",
	"func_indexOf": "Bepaalt de positie van het eerste voorkomen van de tekenreeks <b>zoeken</b> in de tekenreeks <b>tekenreeks</b>, beginnend bij positie <b>start</b>.",
	"func_indexOf_arg_1": "De tekenreeks waarin de zoekopdracht wordt uitgevoerd.",
	"func_indexOf_arg_2": "De tekenreeks waarnaar moet worden gezocht.",
	"func_indexOf_arg_3": "De startpositie van de zoekopdracht.",
	"func_indexOf_return": "De positie van het eerste voorkomen van <b>zoeken</b> in <b>tekenreeks</b> vanaf <b>start</b>, -1 als de tekenreeks niet is gevonden.",
	"func_insert": "Voegt een <b>element</b> element in de <b>array</b> in op positie <b>position</b>.",
	"func_insert_arg_1": "De array waarin het element wordt ingevoegd.",
	"func_insert_arg_2": "Het item dat moet worden ingevoegd.",
	"func_insert_arg_3": "De positie van de insertie.",
	"func_isAlive": "Bepaalt of een <b>entiteit</b> in leven is. Gelijk aan <i>getLife(<b>entiteit</b>) > 0</i>.",
	"func_isAlive_arg_1": "De id van de te testen entiteit.",
	"func_isAlive_return": "<i>true</i> als <b>entiteit</b> leeft, <i>false</i> als dood.",
	"func_isAlly": "Bepaalt of <b>entiteit</b> uw bondgenoot is.",
	"func_isAlly_arg_1": "De id van de te testen entiteit.",
	"func_isAlly_return": "<i>true</i> als de <b>entiteit</b> je bondgenoot of jezelf is, <i>false</i> als het een vijand is.",
	"func_isChip": "Bepaalt of een waarde een constante is die een chip vertegenwoordigt.<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "Het aantal nader te bepalen.",
	"func_isChip_return": "<i>true</i> als de waarde een chipconstante is.",
	"func_isDead": "Bepaalt of <b>entiteit</b> dood is. Gelijk aan <i>getLife(<b>entiteit</b>) == 0</i>.",
	"func_isDead_arg_1": "De id van de te testen entiteit.",
	"func_isDead_return": "<i>true</i> als <b>entiteit</b> dood is, <i>false</i> als ze leeft.",
	"func_isEmpty": "Bepaalt of de array <b>array</b> leeg is. Gelijk aan <i>count(<b>array</b>) == 0</i>.",
	"func_isEmpty_arg_1": "De array die moet worden getest.",
	"func_isEmptyCell": "Bepaalt of een cel leeg is.",
	"func_isEmptyCell_arg_1": "De te testen cel.",
	"func_isEmptyCell_return": "<i>true</i> als de cel leeg is, <i>false</i> anders.",
	"func_isEmpty_return": "<i>true</i> als de array leeg is, <i>false</i> anders.",
	"func_isEnemy": "Bepaalt of <b>entiteit</b> je vijand is.",
	"func_isEnemy_arg_1": "De id van de te testen entiteit.",
	"func_isEnemy_return": "<i>true</i> als de <b>entiteit</b> een vijand is, <i>false</i> als het een bondgenoot of jezelf is.",
	"func_isInlineChip": "Bepaalt of de <b>chip</b> alleen online kan worden gebruikt.",
	"func_isInlineChip_arg_1": "De id van de te testen chip.",
	"func_isInlineChip_return": "<i>true</i> als de chip alleen online is, <i>false</i> anders.",
	"func_isInlineWeapon": "Bepaalt of het <b>wapen</b> wapen alleen online kan worden gebruikt.",
	"func_isInlineWeapon_arg_1": "De ID van het te testen wapen.",
	"func_isInlineWeapon_return": "<i>true</i> als het wapen alleen online gebruikt kan worden, <i>false</i> anders.",
	"func_isLeek": "Bepaalt of de inhoud van cel <b>cel</b> een entiteit is.",
	"func_isLeek_arg_1": "De te testen cel.",
	"func_isLeek_return": "<i>true</i> als de cel een entiteit bevat, <i>false</i> anders.",
	"func_isEntity": "Bepaalt of de inhoud van cel <b>cel</b> een entiteit is.",
	"func_isEntity_arg_1": "De te testen cel.",
	"func_isEntity_return": "<i>true</i> als de cel een entiteit bevat, <i>false</i> anders.",
	"func_isObstacle": "Bepaalt of de inhoud van cel <b>cel</b> een obstakel is.",
	"func_isObstacle_arg_1": "De te testen cel.",
	"func_isObstacle_return": "<i>true</i> als de cel een obstakel bevat, <i>false</i> anders.",
	"func_isOnSameLine": "Bepaalt of twee cellen <b>cel1</b> en <b>cel2</b> op dezelfde regel staan.",
	"func_isOnSameLine_arg_1": "De eerste cel.",
	"func_isOnSameLine_arg_2": "De tweede cel.",
	"func_isOnSameLine_return": "<i>true</i> als de twee cellen op dezelfde regel staan, <i>false</i> anders.",
	"func_isStatic": "Retourneert of de entiteit <b>entiteit</b> statisch is of niet. Een statische entiteit kan niet worden verplaatst of verplaatst.",
	"func_isStatic_arg_1": "De id van de te testen entiteit.",
	"func_isStatic_return": "<i>true</i> als <b>entiteit</b> statisch is, <i>false</i> anders.",
	"func_isSummon": "Retourneert of <b>entiteit</b> een aanroep is of niet.",
	"func_isSummon_arg_1": "De id van de te testen entiteit.",
	"func_isSummon_return": "<i>true</i> als <b>entiteit</b> een aanroeping is, <i>false</i> anders.",
	"func_isWeapon": "Bepaalt of een waarde een constante is die een wapen vertegenwoordigt.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false.",
	"func_isWeapon_arg_1": "Het aantal nader te bepalen.",
	"func_isWeapon_return": "<i>waar</i> als de waarde een wapenconstante is.",
	"func_join": "Voegt meerdere elementen samen tot een tekenreeks en scheidt ze met een <b>glue</b> scheidingsteken.",
	"func_join_arg_1": "Een reeks items om samen te voegen.",
	"func_join_arg_2": "Het elementscheidingsteken.",
	"func_join_return": "De resulterende tekenreeks van de samenvoeging.",
	"func_jsonDecode": "Decodeert string <b>json</b> naar LeekScript-object (nummer, string, array...).",
	"func_jsonDecode_arg_1": "De JSON-tekenreeks die moet worden gedecodeerd.",
	"func_jsonDecode_return": "Het gedecodeerde LeekScript-object.",
	"func_jsonEncode": "Codeert het <b>object</b>-object in een JSON-tekenreeks.",
	"func_jsonEncode_arg_1": "Het object dat moet worden gecodeerd in JSON.",
	"func_jsonEncode_return": "De JSON-gecodeerde tekenreeks.",
	"func_keySort": "Sorteert de <b>array</b> op sleutels in <b>volgorde</b> volgorde.",
	"func_keySort_arg_1": "De array die moet worden gesorteerd.",
	"func_keySort_arg_2": "De sorteervolgorde: #SORT_ASC of #SORT_DESC.",
	"func_length": "Retourneert de lengte van <b>tekenreeks</b>.",
	"func_length_arg_1": "De tekenreeks waarvan de lengte wordt geretourneerd.",
	"func_length_return": "De lengte van <b>tekenreeks</b>.",
	"func_lineOfSight": "Controleert de zichtlijn tussen de <b>start</b> cel en de <b>eind</b> cel, waarbij <b>ignoredEntities</b> entiteiten worden genegeerd.",
	"func_lineOfSight_arg_1": "Beginnende cel.",
	"func_lineOfSight_arg_2": "Doel cel.",
	"func_lineOfSight_arg_3": "Een entiteit of lijst met te negeren entiteiten.",
	"func_lineOfSight_return": "Retourneert <i>true</i> als de zichtlijn vrij is.",
	"func_listen": "Retourneert de array van say() van eerdere entiteiten, in de vorm [entity_id, message].",
	"func_listen_return": "De array van vorige say()s.",
	"func_log": "Berekent de natuurlijke logaritme van het getal <b>getal</b>.",
	"func_log_arg_1": "Een getal in het bereik ]0; +∞[.",
	"func_log_return": "De natuurlijke logaritme van <b>getal</b>.",
	"func_log10": "Berekent de logaritme met grondtal 10 van het getal <b>getal</b>.",
	"func_log10_arg_1": "Een getal in het bereik ]0; +∞[.",
	"func_log10_return": "De logaritme met grondtal 10 van <b>getal</b>.",
	"func_log2": "Berekent de logaritme met grondtal 2 van het getal <b>getal</b>.",
	"func_log2_arg_1": "Een getal in het bereik ]0; +∞[.",
	"func_log2_return": "De logaritme met grondtal 2 van <b>getal</b>.",
	"func_mark": "Markeert een of meer cellen van de kleur aangegeven in parameter op de grond voor het aantal beurten aangegeven in parameter. Deze markering is alleen zichtbaar voor de fokker van de entiteit.",
	"func_mark_arg_1": "De cel of matrix van meerdere cellen die moet worden gemarkeerd",
	"func_mark_arg_2": "Kleur markeren",
	"func_mark_arg_3": "Duur van de markering",
	"func_mark_return": "Retourneert waar als alles goed is gegaan",
	"func_markText": "Schrijft een tekst op een of meer cellen van de kleur aangegeven in parameter op de grond voor het aantal beurten aangegeven in parameter. Deze teksten zijn alleen zichtbaar voor de fokker van de entiteit.",
	"func_markText_arg_1": "De cel of array van meerdere cellen om naar te schrijven",
	"func_markText_arg_2": "De te schrijven tekst (maximaal 10 tekens)",
	"func_markText_arg_3": "Tekst kleur",
	"func_markText_arg_4": "Tekst duur",
	"func_markText_return": "Retourneert waar als alles goed is gegaan",
	"func_max": "Retourneert de grootste waarde tussen de twee getallen <b>a</b> en <b>b</b>.",
	"func_max_arg_1": "Een getal.",
	"func_max_arg_2": "Een getal.",
	"func_max_return": "Het grootste getal tussen <b>a</b> en <b>b</b>.",
	"func_min": "Retourneert de kleinere waarde tussen de twee getallen <b>a</b> en <b>b</b>.",
	"func_min_arg_1": "Een getal.",
	"func_min_arg_2": "Een getal.",
	"func_min_return": "Het kleinste getal tussen <b>a</b> en <b>b</b>.",
	"func_moveAwayFrom": "Verplaatst uw entiteit weg van een andere <b>entiteit</b>, met maximale <b>mp</b> bewegingspunten.",
	"func_moveAwayFrom_arg_1": "De entiteit waar uw entiteit vandaan moet komen.",
	"func_moveAwayFrom_arg_2": "Het maximale aantal te gebruiken MP's. Standaard geen limiet.",
	"func_moveAwayFrom_return": "Het aantal gebruikte bewegingspunten.",
	"func_moveAwayFromCell": "Verplaatst uw entiteit één <b>cel</b> cel verder, waarbij maximaal <b>mp</b> verplaatsingspunten worden gebruikt.",
	"func_moveAwayFromCell_arg_1": "De cel waar je entiteit vandaan moet komen.",
	"func_moveAwayFromCell_arg_2": "Het maximale aantal te gebruiken MP's. Standaard geen limiet.",
	"func_moveAwayFromCell_return": "Het aantal gebruikte bewegingspunten.",
	"func_moveAwayFromCells": "Verplaatst uw entiteit weg van een reeks <b>cells</b> cellen, waarbij maximale <b>mp</b> verplaatsingspunten worden gebruikt.",
	"func_moveAwayFromCells_arg_1": "De array met de cellen waar uw entiteit vandaan moet komen.",
	"func_moveAwayFromCells_arg_2": "Het maximale aantal te gebruiken MP's. Standaard geen limiet.",
	"func_moveAwayFromCells_return": "Het aantal gebruikte bewegingspunten.",
	"func_moveAwayFromLeeks": "Verplaatst uw entiteit weg van een reeks <b>entiteiten</b>, met behulp van maximale <b>mp</b> verplaatsingspunten.",
	"func_moveAwayFromLeeks_arg_1": "De array met de id's van de entiteiten waar uw entiteit vandaan moet komen.",
	"func_moveAwayFromLeeks_arg_2": "Het maximale aantal te gebruiken MP's. Standaard geen limiet.",
	"func_moveAwayFromLeeks_return": "Het aantal gebruikte bewegingspunten.",
	"func_moveAwayFromEntities": "Verplaatst uw entiteit weg van een reeks <b>entiteiten</b>, met maximale <b>mp</b> verplaatsingspunten.",
	"func_moveAwayFromEntities_arg_1": "De array met de id's van de entiteiten waar uw entiteit vandaan moet komen.",
	"func_moveAwayFromEntities_arg_2": "Het maximale aantal te gebruiken MP's. Standaard geen limiet.",
	"func_moveAwayFromEntities_return": "Het aantal gebruikte bewegingspunten.",
	"func_moveAwayFromLine": "Verplaatst uw entiteit weg van een lijn gedefinieerd door twee cellen <b>cell1</b> en <b>cell2</b>, met een maximum van <b>mp</b> verplaatsingspunten.",
	"func_moveAwayFromLine_arg_1": "Cel 1.",
	"func_moveAwayFromLine_arg_2": "Cel 2.",
	"func_moveAwayFromLine_arg_3": "Het maximale aantal te gebruiken MP's. Standaard geen limiet.",
	"func_moveAwayFromLine_return": "Het aantal gebruikte bewegingspunten.",
	"func_moveToward": "Brengt uw entiteit dichter bij een andere <b>entiteit</b>, met maximale <b>mp</b> bewegingspunten.",
	"func_moveToward_arg_1": "De entiteit die uw entiteit moet benaderen.",
	"func_moveToward_arg_2": "Het maximale aantal te gebruiken MP's.",
	"func_moveToward_return": "Het aantal gebruikte bewegingspunten.",
	"func_moveTowardCell": "Brengt uw entiteit één <b>cel</b> cel dichterbij, met maximale <b>mp</b> bewegingspunten.",
	"func_moveTowardCell_arg_1": "De cel die uw functie moet benaderen.",
	"func_moveTowardCell_arg_2": "Het maximale aantal te gebruiken MP's. Standaard geen limiet.",
	"func_moveTowardCell_return": "Het aantal gebruikte bewegingspunten.",
	"func_moveTowardCells": "Verplaatst uw entiteit dichter naar een reeks <b>cellen</b> cellen, gebruikmakend van maximale <b>mp</b> verplaatsingspunten.",
	"func_moveTowardCells_arg_1": "De matrix met de cellen die uw entiteit moet benaderen.",
	"func_moveTowardCells_arg_2": "Het maximale aantal te gebruiken MP's. Standaard geen limiet.",
	"func_moveTowardCells_return": "Het aantal gebruikte bewegingspunten.",
	"func_moveTowardLeeks": "Brengt uw entiteit dichter bij een reeks <b>entiteiten</b>, gebruikmakend van maximale <b>mp</b> bewegingspunten.",
	"func_moveTowardLeeks_arg_1": "De array met de id's van de entiteiten die uw entiteit moet benaderen.",
	"func_moveTowardLeeks_arg_2": "Het maximale aantal te gebruiken MP's. Standaard geen limiet.",
	"func_moveTowardLeeks_return": "Het aantal gebruikte bewegingspunten.",
	"func_moveTowardEntities": "Brengt uw entiteit dichter bij een reeks <b>entiteiten</b>, gebruikmakend van maximale <b>mp</b> bewegingspunten.",
	"func_moveTowardEntities_arg_1": "De array met de id's van de entiteiten die uw entiteit moet benaderen.",
	"func_moveTowardEntities_arg_2": "Het maximale aantal te gebruiken MP's. Standaard geen limiet.",
	"func_moveTowardEntities_return": "Het aantal gebruikte bewegingspunten.",
	"func_moveTowardLine": "Brengt uw entiteit dichter bij een lijn gedefinieerd door twee cellen <b>cell1</b> en <b>cell2</b>, met maximale <b>mp</b> bewegingspunten.",
	"func_moveTowardLine_arg_1": "Cel 1.",
	"func_moveTowardLine_arg_2": "Cel 2.",
	"func_moveTowardLine_arg_3": "Het maximale aantal te gebruiken MP's. Standaard geen limiet.",
	"func_moveTowardLine_return": "Het aantal gebruikte bewegingspunten.",
	"func_number": "Converteert een waarde naar een getal. Als de waarde een tekenreeks is, probeert de getalfunctie deze om te zetten in een getal, als de waarde al een getal is, retourneert de functie het getal en voor elk ander type retourneert het <i>null</i>.",
	"func_number_arg_1": "De waarde die moet worden geconverteerd naar een getal.",
	"func_number_return": "Het geconverteerde nummer.",
	"func_pause": "Pauzeert het gevecht, alleen voor de fokker van de entiteit die de functie gebruikt.",
	"func_pop": "Verwijdert de laatste cel uit de <b>array</b> array en retourneert het overeenkomstige element.",
	"func_pop_arg_1": "De array waarvan het laatste vak wordt verwijderd.",
	"func_pop_return": "Het item dat in de oude laatste doos zat.",
	"func_pow": "Verhoogt het grondtal tot de exponent macht.",
	"func_pow_arg_1": "De basis.",
	"func_pow_arg_2": "De exposant.",
	"func_pow_return": "<b>grondtal</b> verheven tot de <b>exponent</b> macht.",
	"func_push": "Voegt het <b>element</b>-element toe aan het einde van de <b>array</b>.",
	"func_pushAll": "Voegt alle elementen van de <b>elementen</b>-array toe aan het einde van de <b>array</b>.",
	"func_pushAll_arg_1": "De array waaraan we de elementen willen toevoegen.",
	"func_pushAll_arg_2": "Artikelen om toe te voegen.",
	"func_push_arg_1": "De array waar we het element willen toevoegen.",
	"func_push_arg_2": "Het item dat moet worden toegevoegd.",
	"func_rand": "Retourneert een echt willekeurig getal, tussen 0 (inclusief) en 1 (exclusief).",
	"func_randFloat": "Retourneert een reëel willekeurig getal tussen <b>a</b> (inclusief) en <b>b</b> (exclusief).",
	"func_randFloat_arg_1": "Ondergrens.",
	"func_randFloat_arg_2": "Bovengrens.",
	"func_randFloat_return": "Een willekeurig getal in het bereik [a; B[.",
	"func_randInt": "Retourneert een willekeurig geheel getal tussen <b>a</b> (inclusief) en <b>b</b> (exclusief).",
	"func_randInt_arg_1": "Ondergrens.",
	"func_randInt_arg_2": "Bovengrens.",
	"func_randInt_return": "Een willekeurig geheel getal in het bereik [a; B[.",
	"func_rand_return": "Een willekeurig getal in het bereik [0; 1[.",
	"func_remove": "Verwijdert een element uit de array <b>array</b> op positie <b>position</b> en retourneert het verwijderde element.",
	"func_remove_arg_1": "Array waaruit we een element willen verwijderen.",
	"func_remove_arg_2": "Positie van het te verwijderen element.",
	"func_removeElement": "Verwijdert het eerste voorkomen van een <b>element</b> in de <b>array</b>. Als het element niet wordt gevonden, wordt de array niet gewijzigd.",
	"func_removeElement_arg_1": "Array waaruit we een element willen verwijderen.",
	"func_removeElement_arg_2": "Item om te zoeken en vervolgens te verwijderen.",
	"func_removeKey": "Verwijdert een element uit de array <b>array</b> geassocieerd met de sleutel <b>key</b>.",
	"func_removeKey_arg_1": "Array waaruit we een element willen verwijderen.",
	"func_removeKey_arg_2": "De sleutel die is gekoppeld aan het item dat moet worden verwijderd.",
	"func_remove_return": "Het element dat uit de array is verwijderd.",
	"func_replace": "Vervangt alle keren dat <b>zoeken</b> voorkomt door <b>vervangen</b> in de tekenreeks <b>tekenreeks</b>.",
	"func_replace_arg_1": "Tekenreeks waarin vervangingen worden gemaakt.",
	"func_replace_arg_2": "Subtekenreeks om te vervangen.",
	"func_replace_arg_3": "Vervangende snaar.",
	"func_replace_return": "De resultaattekenreeks, met vervangingen.",
	"func_resurrect": "Gebruikt de CHIP_RESURRECTION-chip om een dode entiteit tot leven te wekken met id <b>entiteit</b>, op cel <b>cel</b>.",
	"func_resurrect_arg_1": "De id van de entiteit die nieuw leven moet worden ingeblazen.",
	"func_resurrect_arg_2": "De cel waarop de entiteit weer zal verschijnen.",
	"func_resurrect_return": "Het resultaat van het lanceren van de chip, onder de constanten USE_*.",
	"func_reverse": "Keert de <b>array</b> om.",
	"func_reverse_arg_1": "Tabel om te keren.",
	"func_round": "Retourneert de afronding van <b>getal</b>.<br/>U vindt de afronding naar beneden met #vloer en de afronding naar boven met #ceil.",
	"func_round_arg_1": "Getal waarvan we de afronding willen vinden.",
	"func_round_return": "Afgerond op <b>getal</b>.",
	"func_say": "Laat je entiteit spreken.",
	"func_say_arg_1": "Bericht dat uw entiteit zal aankondigen in de arena.",
	"func_search": "Vindt het element <b>element</b> in de array <b>array</b>, beginnend vanaf positie <b>start</b>.",
	"func_search_arg_1": "De zoektabel.",
	"func_search_arg_2": "Het item om naar te zoeken.",
	"func_search_arg_3": "De positie van het begin van de zoekopdracht.",
	"func_search_return": "De positie van het eerste voorkomen van het <b>element</b>-element in de <b>array</b>, beginnend bij <b>start</b>, null als het element niet is gevonden.",
	"func_sendAll": "Stuur een bericht naar je hele team.",
	"func_sendAll_arg_1": "Het type bericht dat moet worden verzonden (zie de MESSAGE_*-constanten).",
	"func_sendAll_arg_2": "De berichtparameters, die elke waarde kunnen zijn.",
	"func_sendTo": "Stuurt een bericht naar de entiteit met id <b>entiteit</b>.",
	"func_sendTo_arg_1": "De id van de entiteit waarnaar het bericht wordt verzonden.",
	"func_sendTo_arg_2": "Het type bericht dat moet worden verzonden (zie de MESSAGE_*-constanten).",
	"func_sendTo_arg_3": "De berichtparameters, die elke waarde kunnen zijn.",
	"func_sendTo_return": "<i>true</i> als de verzending succesvol was, <i>false</i> als er een fout is opgetreden.",
	"func_setRegister": "Slaat <b>waarde</b> op in het register <b>sleutel</b>.\nDe sleutel en de waarde zijn strings die maximaal respectievelijk <i>100</i> en <i>5000</i> tekens mogen bevatten. Een prei kan maximaal <i>100</i> registers hebben, opslaan in een nieuw register werkt niet als alle registers\nzijn al bezig.",
	"func_setRegister_arg_1": "De registersleutel waar de waarde moet worden opgeslagen.",
	"func_setRegister_arg_2": "De waarde om op te slaan.",
	"func_setRegister_return": "<i>true</i> als de operatie succesvol was, <i>false</i> anders.",
	"func_setWeapon": "Rust het <b>wapen</b> wapen uit op je entiteit.",
	"func_setWeapon_arg_1": "Id van het wapen om uit te rusten.",
	"func_shift": "Verwijdert de eerste cel uit de <b>matrix</b> en retourneert het overeenkomstige element.",
	"func_shift_arg_1": "De array waarvan de eerste cel wordt verwijderd.",
	"func_shift_return": "Het element dat in de oude eerste doos zat.",
	"func_show": "Toont spelers een <b>cel</b> van de kleur <b>kleur</b> op het veld gedurende 1 beurt. Het gebruik van deze functie kost 1PT.",
	"func_show_arg_1": "De cel die moet worden weergegeven",
	"func_show_arg_2": "Kleur markeren",
	"func_show_return": "Retourneert waar als alles goed is gegaan",
	"func_shuffle": "Schudt een bord willekeurig.",
	"func_shuffle_arg_1": "Het sjoelbord.",
	"func_signum": "Bepaalt het teken van het getal <b>getal</b>.",
	"func_signum_arg_1": "Het nummer waarvan het teken wordt bepaald.",
	"func_signum_return": "1 als het getal positief is, 0 als het getal nul is en -1 als het getal negatief is.",
	"func_sin": "Berekent de sinus van de hoek <b>hoek</b> doorgegeven als parameter",
	"func_sin_arg_1": "De hoek waarvan de sinus wordt berekend",
	"func_sin_return": "Retourneert de sinus van de hoek <b>hoek</b>",
	"func_sort": "Sorteert de <b>matrix</b> in oplopende of aflopende volgorde. De standaard oplopende volgorde is:\n<u>\n<li>Alle booleans (eerst onwaar, dan waar)</li>\n<li>Alle getallen (gerangschikt van klein naar groot)</li>\n<li>Alle zenders (in alfabetische volgorde)</li>\n<li>Alle null-elementen.</li>\n</ul>",
	"func_sort_arg_1": "Matrix om te sorteren.",
	"func_sort_arg_2": "#SORT_ASC om <b>array</b> in oplopende volgorde te sorteren of #SORT_DESC om in aflopende volgorde te sorteren.",
	"func_split": "Splitst de tekenreeks <b>tekenreeks</b> in subtekenreeksen gescheiden door <b>scheidingsteken</b>.",
	"func_split_arg_1": "Scherpe ketting.",
	"func_split_arg_2": "Tekenreeks die de overgang van het ene element naar het andere afbakent.",
	"func_split_arg_3": "Maximaal aantal array-elementen.",
	"func_split_return": "Array met de gevonden substrings.",
	"func_sqrt": "Berekent de vierkantswortel van het getal <b>getal</b>.",
	"func_sqrt_arg_1": "Getal waarvan de wortel wordt berekend.",
	"func_sqrt_return": "Vierkantswortel van <b>getal</b>.",
	"func_startsWith": "Controleert of <b>string</b> begint met <b>prefix</b>.",
	"func_startsWith_arg_1": "Zoekreeks.",
	"func_startsWith_arg_2": "Gezocht voorvoegsel.",
	"func_startsWith_return": "Waar als de <b>string</b> begint met <b>prefix</b>.",
	"func_string": "Converteert een waarde naar een tekenreeks.<br>\nAls de waarde al een tekenreeks is, wordt deze geretourneerd.<br>\nAls de waarde getal x is, wordt \"x\" geretourneerd.<br>\nAls de waarde een array is, wordt een tekenreeks in de vorm \"[sleutel1: waarde1, sleutel2: waarde2, ...]\" geretourneerd.<br>\nAls de waarde een Booleaanse waarde is, worden 'true' en 'false' geretourneerd voor respectievelijk true en false.<br>\nAls de waarde null is, wordt \"null\" geretourneerd.",
	"func_string_arg_1": "De waarde die moet worden geconverteerd naar een tekenreeks.",
	"func_string_return": "De geconverteerde tekenreeks.",
	"func_subArray": "Retourneert een subarray van <b>array</b> beginnend op positie <b>start</b> en eindigend op positie <b>end</b>.",
	"func_subArray_arg_1": "Bron tabel.",
	"func_subArray_arg_2": "Startindex.",
	"func_subArray_arg_3": "Eindindex.",
	"func_subArray_return": "Subarray.",
	"func_substring": "Retourneert de subtekenreeks van de tekenreeks <b>tekenreeks</b> beginnend bij het teken bij index <b>start</b> en met de grootte <b>lengte</b>.",
	"func_substring_arg_1": "Scherpe ketting.",
	"func_substring_arg_2": "Tekenindex starten.",
	"func_substring_arg_3": "Lengte van de subtekenreeks.",
	"func_substring_return": "Subtekenreeks.",
	"func_sum": "Retourneert de som van alle numerieke elementen in de array <b>array</b>.",
	"func_sum_arg_1": "Tabel optellen.",
	"func_summon": "Roept een entiteit op die wordt bepaald door de chip <b>chip</b> op de cel <b>cel</b> met voor IA de functie <b>ai</b>.",
	"func_summon_arg_1": "De chip die wordt gebruikt voor oproepen. De chip moet een summon-type chip zijn en moet zijn uitgerust met de entiteit die de summon-functie gebruikt.",
	"func_summon_arg_2": "De cel of oproep zou moeten verschijnen.",
	"func_summon_arg_3": "De aanroep-AI, als een functie.",
	"func_summon_return": "De summon-functie heeft hetzelfde rendement als de #useChip-functie.",
	"func_sum_return": "Som van array-elementen.",
	"func_tan": "Berekent de tangens van hoek <b>hoek</b> (in radialen).",
	"func_tan_arg_1": "Hoek in radialen.",
	"func_tan_return": "Tangens van <b>hoek</b>.",
	"function_category_array": "Lijsten",
	"function_category_chip": "Vlooien",
	"function_category_color": "Kleur",
	"function_category_fight": "Gevecht",
	"function_category_leek": "Prei",
	"function_category_map": "Tafels",
	"function_category_field": "Grond",
	"function_category_math": "Nummers",
	"function_category_network": "Netwerk",
	"function_category_string": "Kettingen",
	"function_category_utils": "Nutsvoorzieningen",
	"function_category_weapon": "Wapens",
	"func_toDegrees": "Converteert hoek <b>radialen</b> naar graden.",
	"func_toDegrees_arg_1": "Waarde in radialen",
	"func_toDegrees_return": "Hoek in graden.",
	"func_toLower": "Converteert hoofdletters in <b>tekenreeks</b> naar kleine letters.",
	"func_toLower_arg_1": "String om te transformeren",
	"func_toLower_return": "Tekenreeks in kleine letters",
	"func_toRadians": "Converteert hoek <b>graden</b> naar radialen.",
	"func_toRadians_arg_1": "Waarde in graden",
	"func_toRadians_return": "Hoek in radialen.",
	"func_toUpper": "Converteert kleine letters in <b>tekenreeks</b> naar hoofdletters.",
	"func_toUpper_arg_1": "String om te transformeren.",
	"func_toUpper_return": "Tekenreeks in hoofdletters.",
	"func_typeOf": "Retourneert het type van de waarde <b>waarde</b>, onder de typen: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "De waarde waarvan het type wordt geretourneerd.",
	"func_typeOf_return": "Het type <b>waarde</b>.",
	"func_unshift": "Voegt het <b>element</b>-element toe aan het begin van de <b>array</b>.",
	"func_unshift_arg_1": "Array waarin het element zal worden toegevoegd.",
	"func_unshift_arg_2": "Artikel om toe te voegen.",
	"func_useChip": "Gebruik chip <b>chip</b> op entiteit <b>entiteit</b>.",
	"func_useChip_arg_1": "Chip om te gebruiken.",
	"func_useChip_arg_2": "Doelentiteit.",
	"func_useChipOnCell": "Gebruik chip <b>chip</b> op cel <b>cel</b>.",
	"func_useChipOnCell_arg_1": "Chip om te gebruiken.",
	"func_useChipOnCell_arg_2": "Doel cel.",
	"func_useChipOnCell_return": "Een waarde groter dan 0 als de aanval is gelanceerd.",
	"func_useChip_return": "De retourwaarden van useChip zijn:<br>\n<u>\n<li>#USE_CRITICAL, bij voltreffer</li>\n<li>#USE_SUCCESS, indien succesvol</li>\n<li>#USE_FAILED, bij mislukking</li>\n<li>#USE_INVALID_TARGET, als doel niet bestaat</li>\n<li>#USE_NOT_ENOUGH_TP, als uw entiteit niet genoeg TP heeft</li>\n<li>#USE_INVALID_COOLDOWN, als de chip nog niet bruikbaar is</li>\n<li>#USE_INVALID_POSITION, als het bereik slecht is of de zichtlijn niet duidelijk is</li>\n</ul>",
	"func_useWeapon": "Gebruik het geselecteerde wapen op de entiteit <b>entity</b>.",
	"func_useWeapon_arg_1": "Doelentiteit.",
	"func_useWeaponOnCell": "Gebruik het geselecteerde wapen op de <b>cel</b> cel.",
	"func_useWeaponOnCell_arg_1": "Doel cel.",
	"func_useWeaponOnCell_return": "Een waarde groter dan 0 als de aanval is gelanceerd.",
	"func_useWeapon_return": "De retourwaarden van useWeapon zijn:<br>\n<ul>\n<li>#USE_CRITICAL, bij voltreffer</li>\n<li>#USE_SUCCESS, indien succesvol</li>\n<li>#USE_FAILED, bij mislukking</li>\n<li>#USE_INVALID_TARGET, als doel niet bestaat</li>\n<li>#USE_NOT_ENOUGH_TP, als uw entiteit niet genoeg TP heeft</li>\n<li>#USE_INVALID_POSITION, als het bereik slecht is of de zichtlijn niet duidelijk is</li>\n</ul>",
	"func_weaponNeedLos": "Geeft terug of het <b>wapen</b> wapen een gezichtslijn nodig heeft om te vuren.",
	"func_weaponNeedLos_arg_1": "De ID van het te testen wapen.",
	"func_weaponNeedLos_return": "<i>true</i> als het <b>wapen</b> wapen een zichtlijn nodig heeft om te vuren, <i>false</i> anders.",
	"items": "Artikelen",
	"max_cores": "max",
	"max_level": "max",
	"min_cores": "Minimale harten",
	"min_level": "Min. niveau",
	"n_operations": "<b>{0}</b> bewerkingen",
	"parameters": "Instellingen",
	"return": "Feedback",
	"value": "Waarde",
	"variable_operations": "<b>Variabele</b> bewerkingen",
	"deprecated_function": "Deze functie is verouderd.",
	"deprecated_constant": "Deze constante is verouderd.",
	"replaced_by": "Het wordt vervangen door {0}.",
	"details": "Details",
	"optional": "optioneel",
	"arg_type_9": "pakket",
	"arg_type_10": "interval",
	"function_category_set": "Pakketten",
	"function_category_interval": "Intervallen",
	"arg_type_96": "set<entier>"
}

	i18n.mergeLocaleMessage("nl", { "doc": messages })
	