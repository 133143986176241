import { render, staticRenderFns } from "./avatar.vue?vue&type=template&id=4f73fc95&scoped=true&lang=html"
import script from "./avatar.vue?vue&type=script&lang=ts"
export * from "./avatar.vue?vue&type=script&lang=ts"
import style0 from "./avatar.vue?vue&type=style&index=0&id=4f73fc95&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f73fc95",
  null
  
)

export default component.exports