
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> operation",
	"arg_type_-1": "any",
	"arg_type_1": "number",
	"arg_type_2": "string",
	"arg_type_3": "boolean",
	"arg_type_4": "array",
	"arg_type_41": "array<number>",
	"arg_type_42": "array<string>",
	"arg_type_43": "array<boolean>",
	"arg_type_44": "array<array>",
	"arg_type_46": "array<integer>",
	"arg_type_47": "arary<real>",
	"arg_type_5": "function",
	"arg_type_6": "integer",
	"arg_type_7": "real",
	"arg_type_8": "map",
	"arg_type_806": "map<?, integer>",
	"complexity": "Complexity {c}",
	"operations": "{o} operations",
	"const_AREA_CIRCLE_1": "Circular area with a diameter of 3 cells (cross).",
	"const_AREA_CIRCLE_2": "Circular area with a diameter of 5 cells.",
	"const_AREA_CIRCLE_3": "Circular area with a diameter of 7 cells.",
	"const_AREA_LASER_LINE": "Laser-type effective area: line going from the chip/weapon's min range to its max range (or the first obstacle)",
	"const_AREA_POINT": "Single cell area.",
	"const_CELL_EMPTY": "Return value of #getCellContent for an empty cell.",
	"const_CELL_OBSTACLE": "Return value of #getCellContent for cell containing an obstacle.",
	"const_CELL_PLAYER": "Return value of #getCellContent for cell containing an entity.",
	"const_CELL_ENTITY": "Return value of #getCellContent for cell containing an entity.",
	"const_COLOR_BLUE": "Integer corresponding to Blue color.",
	"const_COLOR_GREEN": "Integer corresponding to Green color.",
	"const_COLOR_RED": "Integer corresponding to Red color.",
	"const_E": "E number.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Provides an absolute shield to an entity, diminishing the quantity of life points removed by damages (EFFECT_DAMAGE) of a fixed value. Amplified by resistance.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Removes absolute shield from an entity. Not amplified by a characteristic. Allows an increase of life points removed by damages (EFFECT_DAMAGE) of a fixed value.",
	"const_EFFECT_AFTEREFFECT": "Removes life points from an entity. Amplified by science. Diminish the maximum life points by 5% of the removed life points.",
	"const_EFFECT_ANTIDOTE": "Removes all poisons (EFFECT_POISON) from a target.",
	"const_EFFECT_BOOST_MAX_LIFE": "Increases the life points and the maximum life points of an entity. Amplified by wisdom.",
	"const_EFFECT_BUFF_AGILITY": "Provides an agility bonus to an entity. Amplified by science.",
	"const_EFFECT_BUFF_MP": "Provides a movement points bonus to an entity. Amplified by science.",
	"const_EFFECT_BUFF_RESISTANCE": "Provides a resistance bonus to an entity. Amplified by science.",
	"const_EFFECT_BUFF_STRENGTH": "Provides a strength bonus to an entity. Amplified by science.",
	"const_EFFECT_BUFF_TP": "Provides an action points bonus to an entity. Amplified by science.",
	"const_EFFECT_BUFF_WISDOM": "Provides a wisdom bonus to an entity. Amplified by science.",
	"const_EFFECT_DAMAGE": "Removes life points from an entity. Amplified by strength. Interacts with shields (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), life steal (with the exception of the caster), and damage return (EFFECT_DAMAGE_RETURN). Reduces the maximum life points by 5% of the removed life points.",
	"const_EFFECT_DAMAGE_RETURN": "Provides damage return to an entity, removing life points of entities inflicting damages to the recipient. Amplified by agility. Reduces the maximum life points by 5% of the removed life points.",
	"const_EFFECT_DEBUFF": "Reduces the value of all the effects applied to an entity by a percentage.",
	"const_EFFECT_HEAL": "Gives back life points to an entity, limited by the maximum life points. Amplified by wisdom.",
	"const_EFFECT_INVERT": "Swaps the position of the caster with that of an entity.",
	"const_EFFECT_KILL": "Removes all the life points from an entity.",
	"const_EFFECT_LIFE_DAMAGE": "Removes life points of an entity by a percentage of the caster's life points. Interacts with shields (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) and damage return (EFFECT_DAMAGE_RETURN). Reduces the maximum life points by 5% of te removed life points.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "This effect value is multiplied by the number of affected entities in the area.",
	"const_EFFECT_MODIFIER_ON_CASTER": "This effect always affects the caster.",
	"const_EFFECT_MODIFIER_STACKABLE": "This effect is stackable.",
	"const_EFFECT_NOVA_DAMAGE": "Remove maximum life points, limited by the life points of the entity. Amplified by science.",
	"const_EFFECT_POISON": "Removes life points from an entity at the begining of its turn. Amplified by magic. Reduces the maximum life points by 10% of the removed life points. Not affected by shields and damage return.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Provides absolute shield to an entity, diminishing the quantity of life points removed by damages (EFFECT_DAMAGE) of a fixed value.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Provides an agility bonus to an entity.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Provides a magic bonus to an entity.",
	"const_EFFECT_RAW_BUFF_MP": "Provides a movement points bonus to an entity.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Provides a science bonus to an entity.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Provides a strength bonus to an entity.",
	"const_EFFECT_RAW_BUFF_TP": "Provides an action points bonus to an entity.",
	"const_EFFECT_RELATIVE_SHIELD": "Provides an relative shield, diminishing le quantity of life points removed by damages (EFFECT_DAMAGE) of a relative value. Amplified by resistance.",
	"const_EFFECT_RESURRECT": "Resurrects an entity, with its maximum life points reduced to half its max life points before the resurrection, and its life points reduced to a quarter of the maximum life points before the resurrection.",
	"const_EFFECT_SHACKLE_MAGIC": "Removes magic from an entity. Amplified by magic.",
	"const_EFFECT_SHACKLE_MP": "Removes movement points from an entity. Amplified by magic.",
	"const_EFFECT_SHACKLE_STRENGTH": "Removes strength from an entity. Amplified by magic.",
	"const_EFFECT_SHACKLE_TP": "Removes action points from an entity. Amplified by magic.",
	"const_EFFECT_SUMMON": "Summon a bulb. No effect if the team's invocation limit is reached.",
	"const_EFFECT_TARGET_ALLIES": "Effect affects allies.",
	"const_EFFECT_TARGET_CASTER": "Effect affects caster.",
	"const_EFFECT_TARGET_ENEMIES": "Effect affects enemis.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Effect affects non summoned entities (Leek and Turrets).",
	"const_EFFECT_TARGET_SUMMONS": "Effect affects summoned entited (Bulbs.)",
	"const_EFFECT_TELEPORT": "Changes the caster position.",
	"const_EFFECT_VULNERABILITY": "Removes relative shield from an entity. Not amplified by a characteristic. Allows to augment the life points removed by dammages (EFFECT_DAMAGE) of a relative value.",
	"const_ENTITY_BULB": "Represents the entity type of a bulb.",
	"const_ENTITY_LEEK": "Represents the entity type of a leek.",
	"const_ENTITY_TURRET": "Represents the entity type of a turret.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Fight context for challenges.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Fight context for Battle Royales.",
	"const_FIGHT_CONTEXT_GARDEN": "Fight context for garden fights.",
	"const_FIGHT_CONTEXT_TEST": "Fight context for test fights.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Fight context for tournament fights.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Fight type of Battle Royale.",
	"const_FIGHT_TYPE_FARMER": "Fight type of farmer fights.",
	"const_FIGHT_TYPE_SOLO": "Fight type of solo fights.",
	"const_FIGHT_TYPE_TEAM": "Fight type of team fights.",
	"const_MAX_TURNS": "Maximum duration of a fight.",
	"const_OPERATIONS_LIMIT": "Maximum operations allowed for an entity during its turn.",
	"const_PI": "Relation between a circle's circonference and its diameter.",
	"const_SORT_ASC": "Indicates a sorting function to use ascending order.",
	"const_SORT_DESC": "Indicates a sorting function to use descending order.",
	"const_TYPE_ARRAY": "<b>array</b> type.",
	"const_TYPE_BOOLEAN": "<b>boolean</b> type.",
	"const_TYPE_FUNCTION": "<b>function</b> type.",
	"const_TYPE_NULL": "<b>null</b> type.",
	"const_TYPE_NUMBER": "<b>number</b> type.",
	"const_TYPE_STRING": "<b>string</b> type.",
	"const_USE_RESURRECT_INVALID_ENTITY": "Return value of the function #resurrect when the specified entity does not exist or is not dead yet.",
	"const_USE_TOO_MANY_SUMMONS": "Error thrown by #summon when you already have <b>8</b> alive summons.",
	"func_abs": "Returns the absolute value of given <b>number</b>.",
	"func_abs_arg_1": "A number.",
	"func_abs_return": "Given number's absolute value.",
	"func_acos": "Computes the arccos of <b>argument</b>, within [0, #PI].",
	"func_acos_arg_1": "A number.",
	"func_acos_return": "Given number's arccos.",
	"func_arrayConcat": "Chains given arrays together. String keys are kept while numerical keys are reindexed.",
	"func_arrayConcat_arg_1": "First array.",
	"func_arrayConcat_arg_2": "Second array.",
	"func_arrayConcat_return": "Resulting array.",
	"func_arrayFilter": "Returns an array containing all (key: value) pairs from given <b>array</b> for which <b>callback</b> function returned true. If the <b>callback</b> function is single-input, it is\napplied on the array's values, if the <b>callback</b>\nfunction requires two inputs, it is applied on the array's (key: value) pairs.",
	"func_arrayFilter_arg_1": "Source array.",
	"func_arrayFilter_arg_2": "Filtering function.",
	"func_arrayFilter_return": "Resulting array.",
	"func_arrayFlatten": "Returns an array containing all elements contained in given <b>array</b>. All elements within a sub-array are recursively exported to the new array, stopping given recursion <b>depth</b>.\n<code>var tableau = [1, 2, [3, [4]], 5, [6]];\ndebug(arrayFlatten(tableau,1)); // [1,2,3,[4],5,6]</code>",
	"func_arrayFlatten_arg_1": "Source array.",
	"func_arrayFlatten_arg_2": "Maximun recursion depth.",
	"func_arrayFlatten_return": "Resulting array.",
	"func_arrayFoldLeft": "Reduces given <b>array</b> [v1, v2, ..., vn] from the left and starting with <b>v0</b>, through successive application of <b>f</b> function. Is equivalent to:\n<code>f(f(f(v0, v1), v2), ...).</code>",
	"func_arrayFoldLeft_arg_1": "Source array.",
	"func_arrayFoldLeft_arg_2": "Reducing function.",
	"func_arrayFoldLeft_arg_3": "Starting value.",
	"func_arrayFoldLeft_return": "Array reduced to final result.",
	"func_arrayFoldRight": "Reduces given <b>array</b> [v1, v2, ..., vn]\nfrom the right and starting with <b>v0</b>, through\nsuccessive application of <b>f</b> function. Is equivalent\nto:\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>.",
	"func_arrayFoldRight_arg_1": "Source array.",
	"func_arrayFoldRight_arg_2": "Reducing function.",
	"func_arrayFoldRight_arg_3": "Starting value.",
	"func_arrayFoldRight_return": "Array reduced to final result.",
	"func_arrayIter": "Calls a <b>callback</b> function for each element\nwithin given <b>array</b>. If the <b>callback</b> function\n is single-input, it is applied on the array's values, if the\n<b>callback</b> function requires two inputs, it is applied\non the array's (key: value) pairs.",
	"func_arrayIter_arg_1": "Source array.",
	"func_arrayIter_arg_2": "Callback function.",
	"func_arrayMap": "Returns an array which contains the results of <b>callback</b> function when called on each element within given <b>array</b> (and indexed with the same keys as the original array). If the <b>callback</b>\nfunction is single-input, it is applied on the array's values, if the <b>callback</b> function requires two inputs, it is applied on the array's (key: value) pairs.",
	"func_arrayMap_arg_1": "Source array.",
	"func_arrayMap_arg_2": "Function to be applied on each element from given array.",
	"func_arrayMap_return": "Resulting array.",
	"func_arrayMax": "Finds the greatest value within given <b>array</b>.<br/>See also: #sort.",
	"func_arrayMax_arg_1": "An array of numbers.",
	"func_arrayMax_return": "The greatest value contained in given array.",
	"func_arrayMin": "Finds the least value within given <b>array</b>.<br/>See also: #sort.",
	"func_arrayMin_arg_1": "An array of numbers.",
	"func_arrayMin_return": "The least value contained in given array.",
	"func_arrayPartition": "Returns a two elements array, each being a list of (key: value) pairs from given <b>array</b>. The first element contains pairs for which <b>callback</b> function returned true, and the second one\ncontains all other pairs. If the <b>callback</b> function is single-input, it is applied on the array's values, if the <b>callback</b>",
	"func_arrayPartition_arg_1": "Source array.",
	"func_arrayPartition_arg_2": "Callback function.",
	"func_arrayPartition_return": "Resulting two element array.",
	"func_arraySort": "Sorts the elements from given <b>array</b> according to <b>callback</b> function. Elements are compared by pairs, each one being an input of the callback function. The callback functions needs to return\n-1, 0 or 1, respectively if the first input element is before, at the same place or after the second input element.\n\nIf the <b>callback</b> function requires two inputs, it is\napplied on the array's values, if the <b>callback</b>\nfunction requires four inputs, it is applied on the array's (key: value) pairs.\n\n<code>var tableau = [1, 8, 4, 5, 3, 2, 7, 6];\ndebug(arraySort(tableau, function(a, b) {\n    return (a == b) ? 0 : (a < b ? (-1) : 1);\n})); // returns [1, 2, 3, 4, 5, 6, 7, 8]</code>",
	"func_arraySort_arg_1": "Source array.",
	"func_arraySort_arg_2": "Sorting function.",
	"func_arraySort_return": "Sorted array",
	"func_asin": "Computes the arcsin of <b>argument</b>, within [0, #PI].",
	"func_asin_arg_1": "A number.",
	"func_asin_return": "Given number's arcsin.",
	"func_assocSort": "Sorts an <b>array</b>, while keeping the key: value coupling, using given <b>order</b>.",
	"func_assocSort_arg_1": "Array that needs sorting.",
	"func_assocSort_arg_2": "Sorting order: #SORT_ASC or #SORT_DESC.",
	"func_atan": "Computes the arctan of <b>argument</b>, within [0, #PI].",
	"func_atan2": "Computes the azimuth of given point (<b>x</b>, <b>y</b>) in polar coordinates. Return value is between - #PI and #PI, depending on parameters' signs.",
	"func_atan2_arg_1": "X coordinate.",
	"func_atan2_arg_2": "Y coordinate.",
	"func_atan2_return": "Azimuth of given point, in polar coordinates.",
	"func_atan_arg_1": "A number.",
	"func_atan_return": "Given number's arctan.",
	"func_average": "Computes the average of an array's elements.",
	"func_average_arg_1": "An array.",
	"func_average_return": "Average of given array's elements.",
	"func_canUseChip": "Checks whether your entity can use given <b>chip</b>, targeting given <b>entity</b>.",
	"func_canUseChip_arg_1": "The chip your entity wants to use.",
	"func_canUseChip_arg_2": "Targeted entity.",
	"func_canUseChip_return": "<i>true</i> if your entity can use given chip, <i>false</i> otherwise.",
	"func_canUseChipOnCell": "Checks whether your entity can use given <b>chip</b>, targeting given <b>cell</b>.",
	"func_canUseChipOnCell_arg_1": "The chip your entity wants to use.",
	"func_canUseChipOnCell_arg_2": "Targeted cell.",
	"func_canUseChipOnCell_return": "<i>true</i> if your entity can use given chip, <i>false</i> otherwise.",
	"func_canUseWeapon": "Checks whether your entity can shoot given <b>weapon</b>, targeting given <b>entity</b>.",
	"func_canUseWeapon_arg_1": "The weapon your entity wants to use. By default, the currently equipped weapon.",
	"func_canUseWeapon_arg_2": "Targeted entity.",
	"func_canUseWeapon_return": "<i>true</i> if your entity can shoot given weapon, <i>false</i> otherwise.",
	"func_canUseWeaponOnCell": "Checks whether your entity can shoot given <b>weapon</b>, targeting given <b>cell</b>.",
	"func_canUseWeaponOnCell_arg_1": "The weapon your entity wants to use. By default, the currently equipped weapon.",
	"func_canUseWeaponOnCell_arg_2": "Targeted cell.",
	"func_canUseWeaponOnCell_return": "<i>true</i> if your entity can shoot given weapon, <i>false</i> otherwise.",
	"func_cbrt": "Computes the cubic root of given <b>number</b>.",
	"func_cbrt_arg_1": "A number.",
	"func_cbrt_return": "Given number's cubic root.",
	"func_ceil": "Computes the rounded up value of given <b>number</b>.<br/>The rounded down value can be computed using #floor and the closest integer using #round.",
	"func_ceil_arg_1": "A number.",
	"func_ceil_return": "Number's value as an integer, rounded up.",
	"func_charAt": "Returns the character at given <b>position</b> within given <b>string</b>.<br/>First character is at position 0.",
	"func_charAt_arg_1": "A string.",
	"func_charAt_arg_2": "Position of the character to return.",
	"func_charAt_return": "character from given position. Empty string if position is invalid.",
	"func_chipNeedLos": "Returns whether given <b>chip</b> requires a line of sight in order to be shot.",
	"func_chipNeedLos_arg_1": "Desired chip's id.",
	"func_chipNeedLos_return": "returns whether given <b>chip</b> requires a line of sight in order to be shot.",
	"func_clearMarks": "Erases all markings made by mark() and markText() in the field.",
	"func_clone": "Copies the <b>value</b> passed in parameter to <b>level</b> levels and returns the copy. In the case of an array for example, a clone(array, 1) will copy the array but not its elements, a clone(array, 2) will copy the array as well as all elements.",
	"func_clone_arg_1": "The value to clone",
	"func_clone_arg_2": "The number of levels to clone",
	"func_clone_return": "The cloned value",
	"func_contains": "Checks whether <b>search</b> string is contained within <b>string</b>.",
	"func_contains_arg_1": "String in which the search is performed.",
	"func_contains_arg_2": "String to be loooked for.",
	"func_contains_return": "<i>true</i> if <b>search</b> string is contained in <b>string</b>, <i>false</i> otherwise.",
	"func_cos": "Computes an <b>angle</b>'s cosine.",
	"func_cos_arg_1": "A number.",
	"func_cos_return": "Given number's cosine, within [-1, 1].",
	"func_count": "Counts the elements contained within an <b>array</b>.",
	"func_count_arg_1": "An array.",
	"func_count_return": "Given array's number of elements.",
	"func_debug": "Logs an <b>object</b> message into your personnal log, available at the end of a fight.",
	"func_debug_arg_1": "A message to log.",
	"func_debugC": "Logs an <b>object</b> message into your personnal log, available at the end of a fight, of the color <b>color</b>.",
	"func_debugC_arg_1": "A message to log.",
	"func_debugC_arg_2": "The message color. You can use the function #getColor.",
	"func_debugE": "Logs an <b>object</b> error message into your personnal log, available at the end of a fight.<br />Error messages appear in red.",
	"func_debugE_arg_1": "A message to log.",
	"func_debugW": "Logs an <b>object</b> warning message into your personnal log, available at the end of a fight.<br />Warning messages appear in orange.",
	"func_debugW_arg_1": "A message to log.",
	"func_deleteRegister": "Removes given <b>key</b> (and associated value) from your entity's register.",
	"func_deleteRegister_arg_1": "Key of the (key: value) pair to be deleted.",
	"func_endsWith": "Checks whether given <b>string</b> ends with given <b>suffix</b>",
	"func_endsWith_arg_1": "String to be checked",
	"func_endsWith_arg_2": "Suffix to be found",
	"func_endsWith_return": "<i>true</i> if <b>string</b> ends with <b>suffix</b>, <i>false</i> otherwise",
	"func_exp": "Computes the exponential of <b>number</b>, by exponentiation of #E.",
	"func_exp_arg_1": "A number.",
	"func_exp_return": "#E ** <b>number</b>.",
	"func_fill": "Resizes <b>array</b> to given <b>size</b> and fills it with <b>value</b>.",
	"func_fill_arg_1": "Array to be filled.",
	"func_fill_arg_2": "Value to fill array with.",
	"func_fill_arg_3": "Size of final array.",
	"func_floor": "Computes the rounded down value of given <b>number</b>.<br/>The rounded up value can be computed using #ceil and the closest integer using #round.",
	"func_floor_arg_1": "A number.",
	"func_floor_return": "Number's value as an integer, rounded down.",
	"func_getAbsoluteShield": "Return a <b>entity</b>'s absolute shield value.<br />For your entity, prefer #getAbsoluteShield() with no input.",
	"func_getAbsoluteShield_arg_1": "Leek whose absolute shield you want.",
	"func_getAbsoluteShield_return": "Leek's absolute shield value.",
	"func_getAgility": "Returns given <b>entity</b>'s agility.<br />For your entity's agility, prefer #getAgility() with no input.",
	"func_getAgility_arg_1": "Leek whose agility you want.",
	"func_getAgility_return": "<b>entity</b>'s agility.",
	"func_getAIID": "Returns the id of given <b>entity</b>'s AI.",
	"func_getAIID_arg_1": "Leek whose AI ID you want.",
	"func_getAIID_return": "The id of <b>entity</b>'s AI.",
	"func_getAIName": "Returns the name of given <b>entity</b>'s AI.",
	"func_getAIName_arg_1": "Leek whose AI name you want.",
	"func_getAIName_return": "The name of <b>entity</b>'s AI.",
	"func_getAliveAllies": "Returns an array containing all of your alive allies in a fight.",
	"func_getAliveAllies_return": "An array containing all of your alive allies in a fight.",
	"func_getAliveEnemies": "Returns an array containing all of your alive enemies in a fight.",
	"func_getAliveEnemiesCount": "Counts the alive enemies in the fight.",
	"func_getAliveEnemiesCount_return": "The number of alive enemies.",
	"func_getAliveEnemies_return": "An array containing all all of your alive enemies in a fight.",
	"func_getAllChips": "Returns a list of all chips in the game.",
	"func_getAllChips_return": "The list of all chips in the game.",
	"func_getAllEffects": "Returns a list of all effects in the game.",
	"func_getAllEffects_return": "The list of all effects in the game.",
	"func_getAllWeapons": "Returns the list of all weapons in the game.",
	"func_getAllWeapons_return": "The list of all weapons in the game.",
	"func_getAlliedTurret": "Returns your team's turret id or -1 if it doesn't exist.",
	"func_getAlliedTurret_return": "Your team's turret id.",
	"func_getEnemyTurret": "Returns the id of the enemy turret or -1 if it doesn't exist.",
	"func_getEnemyTurret_return": "The id of the enemy turret.",
	"func_getAllies": "Returns an array containing all of your allies in a fight.",
	"func_getAlliesCount": "Returns the number of allies in the fight.",
	"func_getAlliesCount_return": "Number of allies.",
	"func_getAlliesLife": "Returns the sum of all of your allies' HP",
	"func_getAlliesLife_return": "Sum of all of your allies' HP.",
	"func_getAllies_return": "An array containing all of your allies in a fight.",
	"func_getBirthTurn": "Returns the turn during which given <b>entity</b> has appeared. For instance, returns 1 for a leek or 5 for a bulb summonned during turn 5.",
	"func_getBirthTurn_arg_1": "Desired entity.",
	"func_getBirthTurn_return": "Combat turn during which given entity has appeared.",
	"func_getBlue": "Returns the blue component of given <b>color</b>. For instance getBlue(#COLOR_BLUE) = 255 and getBlue(#COLOR_RED) = 0.",
	"func_getBlue_arg_1": "Color whose blue component is to be returned.",
	"func_getBlue_return": "Blue component of given <b>color</b>",
	"func_getBulbChips": "Returns the list of chips that will be equipped on a bulb invoked by the <b>bulb_chip</b> chip.",
	"func_getBulbChips_arg_1": "The bulb chip.",
	"func_getBulbChips_return": "The chips of a bulb invoked by the chip <b>bulb_chip</b>.",
	"func_getCell": "Returns given <b>entity</b>'s position, as a cell id.",
	"func_getCell_arg_1": "Leek whose position is determined.",
	"func_getCell_return": "Cell id of given <b>entity</b>'s position.",
	"func_getCellContent": "Determines the content of given <b>cell</b>.",
	"func_getCellContent_arg_1": "Id of the cell to be tested.",
	"func_getCellContent_return": "Content of <b>cell</b>: #CELL_EMPTY for an empty cell, #CELL_ENTITY for an entity, #CELL_OBSTACLE for an obstacle.",
	"func_getCellDistance": "Computes the distance between cells <b>cell1</b> and <b>cell2</b>.<br />Distance is in amount of cells and does not take obstacles into account.<br />For as a crow's flies distance, see #getDistance and for an actual path (taking obstacles into account), see #getPathLength.",
	"func_getCellDistance_arg_1": "Cell id of starting point.",
	"func_getCellDistance_arg_2": "Cell id of destination.",
	"func_getCellDistance_return": "Distance between <b>cell1</b> and <b>cell2</b>.",
	"func_getCellFromXY": "Returns the id of the cell at coordinates (<b>x</b>, <b>y</b>).",
	"func_getCellFromXY_arg_1": "X-coordinate of the desired cell.",
	"func_getCellFromXY_arg_2": "Y-coordinate of the desired cell.",
	"func_getCellFromXY_return": "Id of the cell at coordinates (<b>x</b>, <b>y</b>). <b>null</b> if it does not exist.",
	"func_getCellsToUseChip": "Lists all cells that allow your entity to use a given <b>chip</b>, targeting given <b>entity</b>.",
	"func_getCellsToUseChip_arg_1": "The chip your entity wants to use.",
	"func_getCellsToUseChip_arg_2": "Targeted entity.",
	"func_getCellsToUseChip_arg_3": "Array of cells to ignore.",
	"func_getCellsToUseChipOnCell": "Lists all cells that allow your entity to use a given <b>chip</b>, targeting given <b>cell</b>.",
	"func_getCellsToUseChipOnCell_arg_1": "The chip your entity wants to use.",
	"func_getCellsToUseChipOnCell_arg_2": "Targeted cell.",
	"func_getCellsToUseChipOnCell_arg_3": "Array of cells to ignore.",
	"func_getCellsToUseChipOnCell_return": "List of all cells that allow using given chip.",
	"func_getCellsToUseChip_return": "List of all cells that allow using given chip.",
	"func_getCellsToUseWeapon": "Lists all cells that allow your entity to shoot a given <b>weapon</b>, targeting given <b>entity</b>.",
	"func_getCellsToUseWeapon_arg_1": "The weapon your entity wants to use.",
	"func_getCellsToUseWeapon_arg_2": "Targeted entity.",
	"func_getCellsToUseWeapon_arg_3": "Array of cells to ignore.",
	"func_getCellsToUseWeapon_return": "List of all cells that allow your entity to shoot given weapon.",
	"func_getCellsToUseWeaponOnCell": "Lists all cells that allow your entity to shoot a given <b>weapon</b>, targeting given <b>cell</b>.",
	"func_getCellsToUseWeaponOnCell_arg_1": "The weapon your entity wants to use.",
	"func_getCellsToUseWeaponOnCell_arg_2": "Targeted cell.",
	"func_getCellsToUseWeaponOnCell_arg_3": "Array of cells to ignore.",
	"func_getCellsToUseWeaponOnCell_return": "List of all cells that allow your entity to shoot.",
	"func_getCellX": "Computes the x-coordinate of given <b>cell</b>.",
	"func_getCellX_arg_1": "Id of the cell.",
	"func_getCellX_return": "Its x-coordinate/",
	"func_getCellY": "Computes the y-coordinate of given <b>cell</b>.",
	"func_getCellY_arg_1": "Id of the cell.",
	"func_getCellY_return": "Its y-coordinate/",
	"func_getChipArea": "Returns given <b>chip</b>'s effective area type.",
	"func_getChipArea_arg_1": "Desired chip.",
	"func_getChipArea_return": "Given <b>chip</b>'s effective area type among AREA_* constants:\n<ul>\n<li>#AREA_POINT: single-cell effective area</li>\n<li>#AREA_LASER_LINE: laser-type effective area (line)</li>\n<li>#AREA_CIRCLE_1: circular effective area with 3 cells diameter (cross)</li>\n<li>#AREA_CIRCLE_2: circular effective area with 5 cells diameter </li>\n<li>#AREA_CIRCLE_3: circular effective area with 7 cells diameter.</li>\n</ul>",
	"func_getChipCooldown": "Returns a <b>chip</b>'s cooldown as shown in the market.",
	"func_getChipCooldown_arg_1": "Desired chip.",
	"func_getChipCooldown_return": "Given <b>chip</b>'s coolodwn",
	"func_getChipCost": "Returns a <b>chip</b>'s cost, in TP.",
	"func_getChipCost_arg_1": "Desired chip.",
	"func_getChipCost_return": "Given <b>chip</b>'s cost, in TP.",
	"func_getChipEffectiveArea": "Lists the cells affected if given <b>chip</b> were used on given <b>cell</b> from <b>from</b> cell.",
	"func_getChipEffectiveArea_arg_1": "Chip to be used.",
	"func_getChipEffectiveArea_arg_2": "Targeted cell.",
	"func_getChipEffectiveArea_arg_3": "Cell from which the chip is used.",
	"func_getChipEffectiveArea_return": "Array that contains the id of all affected cells.",
	"func_getChipEffects": "Lists a <b>chip</b>'s effects.",
	"func_getChipEffects_arg_1": "Desired chip.",
	"func_getChipEffects_return": "Given <b>chip</b>'s effects. Format is the same as #getWeaponEffects",
	"func_getChipFailure": "Returns a <b>chip</b>'s failure rate.",
	"func_getChipFailure_arg_1": "Desired chip.",
	"func_getChipFailure_return": "Given <b>chip</b>'s failure rate, in percents, integer between <b>0</b> and <b>100</b>.",
	"func_getChipLaunchType": "Returns the launch mode of the <b>chip</b>, from the LAUNCH_TYPE_* constants.",
	"func_getChipLaunchType_arg_1": "The id of the chip whose launch mode will be returned.",
	"func_getChipLaunchType_return": "The launch mode of the <b>chip</b>.",
	"func_getChipMaxRange": "Returns a <b>chip</b>'s max range.",
	"func_getChipMaxRange_arg_1": "Desired chip.",
	"func_getChipMaxRange_return": "Given <b>chip</b>'s maximum range.",
	"func_getChipMaxScope": "Returns a <b>chip</b>'s max scope.",
	"func_getChipMaxScope_arg_1": "Desired chip.",
	"func_getChipMaxScope_return": "Given <b>chip</b>'s maximum scope.",
	"func_getChipMinRange": "Returns a <b>chip</b>'s min range.",
	"func_getChipMinRange_arg_1": "Desired chip.",
	"func_getChipMinRange_return": "Returns a <b>chip</b>'s min range.",
	"func_getChipMinScope": "Returns a <b>chip</b>'s min scope.",
	"func_getChipMinScope_arg_1": "Desired chip.",
	"func_getChipMinScope_return": "Given <b>chip</b>'s minimum scope.",
	"func_getChipName": "Returns a <b>chip</b>'s name.",
	"func_getChipName_arg_1": "Desired chip.",
	"func_getChipName_return": "Given <b>chip</b>'s name",
	"func_getChips": "Lists given <b>entity</b>'s chips.",
	"func_getChips_arg_1": "Desired entity.",
	"func_getChips_return": "Array containing all of given <b>entity</b>'s chips.",
	"func_getChipTargets": "Lists entities that will be affected if given <b>chip</b> were used on given <b>cell</b>.",
	"func_getChipTargets_arg_1": "Chip to be used.",
	"func_getChipTargets_arg_2": "Targeted cell.",
	"func_getChipTargets_return": "Array containing the affected entities.",
	"func_getColor": "Gives an integer that corresponds to given color on a (<b>red</b>, <b>green</b>, <b>blue</b>) scale. See #mark on how to use given integer.",
	"func_getColor_arg_1": "Red value, between 0 and 255.",
	"func_getColor_arg_2": "Green value, between 0 and 255.",
	"func_getColor_arg_3": "Blue value, between 0 and 255.",
	"func_getColor_return": "integer that corresponds to given color.",
	"func_getCooldown": "Returns the current cooldown of given <b>chip</b> for given <b>entity</b>.",
	"func_getCooldown_arg_1": "Desired chip.",
	"func_getCooldown_arg_2": "Desired entity.",
	"func_getCooldown_return": "Given <b>chip</b>'s current cooldown, i.e. the amount of turns owner must wait to cast it again.",
	"func_getCores": "Returns given <b>entity</b>'s number of cores.",
	"func_getCores_arg_1": "Desired entity.",
	"func_getCores_return": "Given <b>entity</b>'s number of cores.",
	"func_getDate": "Returns the fight date, in the dd/MM/yyyy format.",
	"func_getDate_return": "The date of fight.",
	"func_getTime": "Returns the fight date, in the HH:mm:ss format.",
	"func_getTime_return": "The time of fight.",
	"func_getTimestamp": "Returns the timestamp of the fight, equal to the number of seconds since January 1, 1970.",
	"func_getTimestamp_return": "The timestamp of the fight",
	"func_getDamageReturn": "Returns given <b>entity</b>'s damage return ratio.",
	"func_getDamageReturn_arg_1": "Desired entity's id.",
	"func_getDamageReturn_return": "Entity's damage return ratio (in %).",
	"func_getDeadAllies": "Lists dead allies.",
	"func_getDeadAllies_return": "Array of dead allies.",
	"func_getDeadEnemies": "Lists dead enemies.",
	"func_getDeadEnemiesCount": "Counts the dead enemies in the fight.",
	"func_getDeadEnemiesCount_return": "The number of dead enemies.",
	"func_getDeadEnemies_return": "Array of dead enemies.",
	"func_getDistance": "Computes as the crow flies distance between <b>cell1</b> and <b>cell2</b>.<br>For distance in number of cells, see #getCellDistance, and for an actual path (taking obstacles into account), see #getPathLength.",
	"func_getDistance_arg_1": "Cell id of starting point.",
	"func_getDistance_arg_2": "Cell id of arrival.",
	"func_getDistance_return": "Crow's fly distance between given cells.",
	"func_getPassiveEffects": "Lists the passive effects on given entity. For your entity, prefer #getPassiveEffects() with no input.",
	"func_getPassiveEffects_arg_1": "Leek whose passive effects are listed.",
	"func_getPassiveEffects_return": "Array of effects affecting given <b>entity</b>.\nAn effect itself is a 7-cell array of the following form: [<b>type</b>, b>value</b>, <b>caster_id</b>, <b>turns</b>, <b>critical</b>, <b>item_id</b>, <b>target_id</b>]. The format is the same as the effects returned by #getEffects.",
	"func_getEnemies": "Lists all enemies (dead and alive) within a fight.",
	"func_getEnemiesCount": "Counts the enemies in the fight.",
	"func_getEnemiesCount_return": "The number of enemies.",
	"func_getEnemiesLife": "Returns the sum of all of your enemies' HP",
	"func_getEnemiesLife_return": "Sum of all of your enemies' HP",
	"func_getEnemies_return": "Array containing entity ids of all enemies.",
	"func_getEntityTurnOrder": "Indicates the position of given <b>entity</b> in the acting order, between 1 and the amount of entities in the game.",
	"func_getEntityTurnOrder_arg_1": "Desired entity.",
	"func_getEntityTurnOrder_return": "Given entity's position within the acting order.",
	"func_getFarmerCountry": "Returns the country of given <b>farmer</b>.",
	"func_getFarmerCountry_arg_1": "Desired farmer.",
	"func_getFarmerCountry_return": "The country of given <b>farmer</b>, or \"?\" if not specified.",
	"func_getFarmerID": "Returns the id of the given <b>farmer</b>.",
	"func_getFarmerID_arg_1": "Desired entity.",
	"func_getFarmerID_return": "The id of given <b>farmer</b>.",
	"func_getFarmerName": "Returns the name of given <b>entity</b>'s farmer.",
	"func_getFarmerName_arg_1": "Desired entity.",
	"func_getFarmerName_return": "The name of given <b>entity</b>'s farmer.",
	"func_getFarthestAlly": "Returns the farthest ally, using as the crow flies distance.",
	"func_getFarthestAlly_return": "Farthest ally's entity id.",
	"func_getFarthestEnemy": "Returns the farthest enemy, using as the crow flies distance.",
	"func_getFarthestEnemy_return": "Farthest enemy's entity id.",
	"func_getFightContext": "Returns current fight's context",
	"func_getFightContext_return": "Depending on fight's context: Test fight (#FIGHT_CONTEXT_TEST), Garden fight (#FIGHT_CONTEXT_GARDEN), Tournament fight (#FIGHT_CONTEXT_TOURNAMENT), Challenge fight (#FIGHT_CONTEXT_CHALLENGE), Battle Royale (#FIGHT_CONTEXT_BATTLE_ROYALE).",
	"func_getFightType": "Returns current fight's type",
	"func_getFightType_return": "Depending on fight's type: Solo fight (#FIGHT_TYPE_SOLO), Farmer fight (#FIGHT_TYPE_FARMER), Team fight (#FIGHT_TYPE_TEAM), Battle Royale (#FIGHT_TYPE_BATTLE_ROYALE).",
	"func_getForce": "Return's given <b>entity</b>'s strength.",
	"func_getForce_arg_1": "Desired entity.",
	"func_getForce_return": "Given <b>entity</b>'s strength.",
	"func_getFrequency": "Return's given <b>entity</b>'s frequency.",
	"func_getFrequency_arg_1": "Desired entity.",
	"func_getFrequency_return": "Given <b>entity</b>'s frequency.",
	"func_getGreen": "Returns the green component of given <b>color</b>. For instance getGreen(#COLOR_GREEN) = 255 and getGreen(#COLOR_BLUE) = 0.",
	"func_getGreen_arg_1": "Color whose green component is to be returned.",
	"func_getGreen_return": "Green component of given <b>color</b>.",
	"func_getInstructionsCount": "Returns the number of instructions used by your entity this turn.",
	"func_getInstructionsCount_return": "The number of instructions used by your entity this turn.",
	"func_getLaunchedEffects": "Returns the list of effects launched by given <b>entity</b>.",
	"func_getLaunchedEffects_arg_1": "Desired entity's id.",
	"func_getLaunchedEffects_return": "Array containing all effects launched by the caller, similar to #getEffets' results.",
	"func_getLeek": "Returns your leek's id.",
	"func_getLeekID": "Returns your leek's absolute id (does not depend on fight).",
	"func_getLeekID_arg_1": "Desired leek.",
	"func_getLeekID_return": "Given <b>leek</b>'s absolute id.",
	"func_getLeekOnCell": "Returns the entity present on given <b>cell</b>, if any.",
	"func_getLeekOnCell_arg_1": "Desired <b>cell</b>.",
	"func_getLeekOnCell_return": "The id of an entity that is currently occupying given <b>cell</b>, -1 if cell is not occupied by an entity.",
	"func_getLeek_return": "Your entity's id.",
	"func_getEntity": "Returns the id of your entity",
	"func_getEntity_return": "The id of your entity.",
	"func_getEntityOnCell": "Returns the entity that is on the cell <b>cell</b>.",
	"func_getEntityOnCell_arg_1": "The cell from which we want to retrieve the entity.",
	"func_getEntityOnCell_return": "The id of the entity located on the cell, or -1 if the cell has no entity",
	"func_getLevel": "Returns given <b>entity</b>'s level.",
	"func_getLevel_arg_1": "Desired entity.",
	"func_getLevel_return": "Given <b>entity</b>'s level.",
	"func_getLife": "Returns given <b>entity</b>'s HP count.",
	"func_getLife_arg_1": "Desired entity.",
	"func_getLife_return": "Given <b>entity</b>'s HP count.",
	"func_getMagic": "Returns given <b>entity</b>'s magic.",
	"func_getMagic_arg_1": "Desired entity's id.",
	"func_getMagic_return": "Given entity's magic.",
	"func_getMapType": "Returns the map type on which the fight takes place (factory, desert, forest, etc.), among the constants #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER and #MAP_BEACH.",
	"func_getMapType_return": "The map type.",
	"func_getMessageAuthor": "Returns given <b>message</b>'s author (entity).",
	"func_getMessageAuthor_arg_1": "Message whose author is desired.",
	"func_getMessageAuthor_return": "Given <b>message</b>'s author.",
	"func_getMessageParams": "Returns given <b>message</b>'s parameters.",
	"func_getMessageParams_arg_1": "Message whose parameters are desired.",
	"func_getMessageParams_return": "Given <b>message</b>'s parameters.",
	"func_getMessages": "Lists all messages addressed to given <b>entity</b>.",
	"func_getMessages_arg_1": "Leek whose messages are returned.",
	"func_getMessages_return": "Array of messages.<br />A message itself is a 3-cell array of the following form: [<b>author</b>, <b>type</b>, <b>parameters</b>]<br />Message types are:\n<ul>\n\t<li>#MESSAGE_HEAL: request for heal</li>\n\t<li>#MESSAGE_ATTACK: request for attack</li>\n\t<li>#MESSAGE_BUFF_FORCE: request for strength buff</li>\n\t<li>...</li>\n</ul>",
	"func_getMessageType": "Returns given <b>message</b>'s message type.",
	"func_getMessageType_arg_1": "Message whose type is desired.",
	"func_getMessageType_return": "Given <b>message</b>'s type.",
	"func_getMP": "Returns given <b>entity</b>'s MP count.",
	"func_getMP_arg_1": "Desired entity.",
	"func_getMP_return": "Given <b>entity</b>'s MP count.",
	"func_getName": "Returns given <b>entity</b>'s name.",
	"func_getName_arg_1": "Desired entity.",
	"func_getName_return": "Given <b>entity</b>'s name.",
	"func_getNearestAlly": "Returns the allied entity closest to your entity.",
	"func_getNearestAlly_return": "Id of the nearest ally",
	"func_getNearestAllyTo": "Returns, among your allies, the closest one to given <b>entity</b>.",
	"func_getNearestAllyTo_arg_1": "Targeted entity.",
	"func_getNearestAllyToCell": "Returns, among your allies, the closest one to given <b>cell</b>.",
	"func_getNearestAllyToCell_arg_1": "Targeted cell.",
	"func_getNearestAllyToCell_return": "Leek id of your ally that is closest to given <b>cell</b>.",
	"func_getNearestAllyTo_return": "Leek id of your ally that is closest to given <b>entity</b>.",
	"func_getNearestEnemy": "Returns the enemy entity closest to your entity.",
	"func_getNearestEnemy_return": "Id of the nearest enemy.",
	"func_getNearestEnemyTo": "Returns, among your enemies, the closest one to given <b>entity</b>.",
	"func_getNearestEnemyTo_arg_1": "Targeted entity.",
	"func_getNearestEnemyToCell": "Returns, among your enemies, the closest one to given <b>cell</b>.",
	"func_getNearestEnemyToCell_arg_1": "Targeted cell.",
	"func_getNearestEnemyToCell_return": "Leek id of your enemy that is closest to given <b>cell</b>.",
	"func_getNearestEnemyTo_return": "Leek id of your enemy that is closest to given <b>entity</b>.",
	"func_getNextPlayer": "Returns the entity id of the next entity to play.",
	"func_getNextPlayer_return": "Leek id of the next player.",
	"func_getObstacles": "Lists all cells that are occupied by obstacles.",
	"func_getObstacles_return": "Array of cells occupied by obstacles.",
	"func_getOperations": "Returns the operations' counter, as started at the beginning of turn. This counter needs to stay below #OPERATIONS_LIMIT in order for the entity not to stop processing.",
	"func_getOperations_return": "Count of operations since the beginning of the turn.",
	"func_getPath": "Finds a path between <b>cell1</b> and <b>cell2</b>, avoiding occupied cells, except <b>ignoredCells</b>, which are considered empty even when an entity occupies them.<br /><br />\nThe starting cell <b>cell1</b> is never included in the resulting path. The destination cell <b>cell2</b> is included in the resulting path if and only if it is empty or ignored by <b>ignoredCells</b>.<br /><br />\nIf no path exists between the two cells, <b>getPath</b> returns <i>null</i>.",
	"func_getPath_arg_1": "Cell id of starting point.",
	"func_getPath_arg_2": "Cell id of destination.",
	"func_getPath_arg_3": "Array of cells considered inoccupied even when an entity is present.",
	"func_getPath_return": "Array of cells that build a path between given cells. Considering <b>ignoredCells</b> as empty.  Empty array if no path is found.",
	"func_getPathLength": "Returns the length of a path between <b>cell1</b> and <b>cell2</b> that avoids occupied cells, except <b>ignoredCells</b> which are considered empty even when an entity is present. This function is equivalent to <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>.<br /><br />\nThe starting cell <b>cell1</b> is never counted in the result. The destination cell <b>cell2</b> is counted in the result if and only if it is empty or ignored by <b>ignoredCells</b>.<br /><br />\nIf no path exists between the two cells, <b>getPathLength</b> returns <i>null</i>",
	"func_getPathLength_arg_1": "Cell id of starting point.",
	"func_getPathLength_arg_2": "Cell id of destination.",
	"func_getPathLength_arg_3": "Array of cells considered inoccupied even when an entity is present.",
	"func_getPathLength_return": "Length of the path between <b>cell1</b> and <b>cell2</b>. -1 if no path is found.",
	"func_getPreviousPlayer": "Returns the entity id of the entity who just played.",
	"func_getPreviousPlayer_return": "Leek id of the entity who just played.",
	"func_getRed": "Returns the red component of given <b>color</b>. For instance getRed(#COLOR_RED) = 255 and getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "Color whose red component is to be returned.",
	"func_getRed_return": "Red component of given <b>color</b>.",
	"func_getRegister": "Returns the value associated to given <b>key</b> in your entity's register, <i>null</i> if it is not found.",
	"func_getRegister_arg_1": "Desired key.",
	"func_getRegister_return": "The value associated to given <b>key</b> in your entity's register.",
	"func_getRegisters": "Returns your entity's whole register, as a [<i>key</i>: <i>value</i>] associative array. For example:\n<code>debug(getRegisters());\n// would, for example, debug :\n// ['reg1' : '314323', 'reg2' : 'test_string']</code>",
	"func_getRegisters_return": "Your entity's register, as an associative array.",
	"func_getRelativeShield": "Returns given <b>entity</b>'s relative shield. For your entity's relative shield, prefer #getRelativeShield() with no input.",
	"func_getRelativeShield_arg_1": "Desired entity.",
	"func_getRelativeShield_return": "Given <b>entity</b>'s relative shield, in percents: integer between 0 and 100.",
	"func_getResistance": "Returns given <b>entity</b>'s resistance.",
	"func_getResistance_arg_1": "Desired entity's id.",
	"func_getResistance_return": "Given entity's resistance.",
	"func_getScience": "Returns given <b>entity</b>'s science.",
	"func_getScience_arg_1": "Desired entity's id.",
	"func_getScience_return": "Given entity's science.",
	"func_getStrength": "Return's given <b>entity</b>'s strength.",
	"func_getStrength_arg_1": "Desired entity.",
	"func_getStrength_return": "Given <b>entity</b>'s strength.",
	"func_getPower": "Return's given <b>entity</b>'s power.",
	"func_getPower_arg_1": "Desired entity.",
	"func_getPower_return": "Given <b>entity</b>'s power.",
	"func_getSummoner": "Returns given <b>entity</b>'s summoner, if it is a summon.",
	"func_getSummoner_arg_1": "Desired entity.",
	"func_getSummoner_return": "ID of the entity's sumonner, if given entity is a summon, <i>null</i> otherwise.",
	"func_getSummons": "Returns the id list of the <b>entity</b>'s alive summons.",
	"func_getSummons_arg_1": "Desired entity's id.",
	"func_getSummons_return": "The id list of the <b>entity</b>'s alive summons.",
	"func_getTeamID": "Returns the id of given <b>entity</b>'s team.",
	"func_getTeamID_arg_1": "Desired entity.",
	"func_getTeamID_return": "The id of given <b>entity</b>'s team.",
	"func_getTeamName": "Returns the name of given <b>entity</b>'s team.",
	"func_getTeamName_arg_1": "Desired entity.",
	"func_getTeamName_return": "The name of given <b>entity</b>'s team.",
	"func_getTotalLife": "Returns given <b>entity</b>'s maximum life.",
	"func_getTotalLife_arg_1": "Desired entity.",
	"func_getTotalLife_return": "Given <b>entity</b>'s maximum life.",
	"func_getTotalMP": "Returns given <b>entity</b>'s total MP count (not counting this turn's movements).",
	"func_getTotalMP_arg_1": "Desired entity's id.",
	"func_getTotalMP_return": "Given entity's total MP count.",
	"func_getTotalTP": "Returns given <b>entity</b>'s total TP count (not counting this turn's actions).",
	"func_getTotalTP_arg_1": "Desired entity's id.",
	"func_getTotalTP_return": "Given entity's total TP count.",
	"func_getTP": "Returns given <b>entity</b>'s TP count.",
	"func_getTP_arg_1": "Desired entity.",
	"func_getTP_return": "Given <b>entity</b>'s TP count.",
	"func_getTurn": "Returns current turn's count. Maximum duration of a fight is #MAX_TURNS.",
	"func_getTurn_return": "Current turn's count.",
	"func_getType": "Returns given <b>entity</b>'s type.",
	"func_getType_arg_1": "Entity's ID",
	"func_getType_return": "Given entity's type:\n<ul>\n<li>#ENTITY_LEEK if given entity is a leek.</li>\n<li>#ENTITY_BULB if given entity is a bulb.</li>\n<li>#ENTITY_TURRET if given entity is a turret.</li>\n</ul>",
	"func_getWeapon": "Returns the weapon currently equipped by given <b>entity</b>.",
	"func_getWeapon_arg_1": "Desired entity.",
	"func_getWeapon_return": "The weapon's id, null if given <b>entity</b> has no equipped weapon.",
	"func_getWeaponArea": "Returns given <b>weapon</b>'s effective area type.",
	"func_getWeaponArea_arg_1": "Desired weapon.",
	"func_getWeaponArea_return": "Given <b>weapon</b>'s effective area type among AREA_* constants:\n<ul>\n<li>#AREA_POINT: single-cell effective area</li>\n<li>#AREA_LASER_LINE: laser-type effective area (line)</li>\n<li>#AREA_CIRCLE_1: circular effective area with 3 cells diameter (cross)</li>\n<li>#AREA_CIRCLE_2: circular effective area with 5 cells diameter </li>\n<li>#AREA_CIRCLE_3: circular effective area with 7 cells diameter.</li>\n</ul>",
	"func_getWeaponCost": "Returns a <b>weapon</b>'s cost, in TP.",
	"func_getWeaponCost_arg_1": "Desired weapon.",
	"func_getWeaponCost_return": "Given <b>weapon</b>'s cost, in TP.",
	"func_getWeaponEffectiveArea": "Lists the cells affected if given <b>weapon</b> were shot on given <b>cell</b>, from <b>from</b> cell.",
	"func_getWeaponEffectiveArea_arg_1": "Weapon to be used.",
	"func_getWeaponEffectiveArea_arg_2": "Targeted cell.",
	"func_getWeaponEffectiveArea_arg_3": "Cell from which the chip is used.",
	"func_getWeaponEffectiveArea_return": "Array that contains the id of all affected cells.",
	"func_getWeaponLaunchType": "Returns the weapon's <b>weapon</b> launch mode, from the LAUNCH_TYPE_* constants.",
	"func_getWeaponLaunchType_arg_1": "The id of the weapon whose launch mode will be returned. By default your currently equipped weapon.",
	"func_getWeaponLaunchType_return": "The weapon's <b>weapon</b> launch mode.",
	"func_getWeaponPassiveEffects": "Lists a <b>weapon</b>'s passive effects.",
	"func_getWeaponPassiveEffects_arg_1": "Desired weapon.",
	"func_getWeaponPassiveEffects_return": "Array containing given's weapon passive effects. An effect itself if an array, in the following format: [type, min, max, turns, targets, modifiers]. It's the same format as the effets returned by #getWeaponEffects.",
	"func_getWeaponFailure": "Returns a <b>weapon</b>'s failure rate.",
	"func_getWeaponFailure_arg_1": "Desired weapon.",
	"func_getWeaponFailure_return": "Given <b>chip</b>'s failure rate, in percents, integer between <b>0</b> and <b>100</b>.",
	"func_getWeaponMaxRange": "Returns a <b>weapon</b>'s max range.",
	"func_getWeaponMaxRange_arg_1": "Desired weapon.",
	"func_getWeaponMaxRange_return": "Given <b>weapon</b>'s maximum range.",
	"func_getWeaponMaxScope": "Returns a <b>weapon</b>'s max scope.",
	"func_getWeaponMaxScope_arg_1": "Desired weapon.",
	"func_getWeaponMaxScope_return": "Given <b>weapon</b>'s maximum scope.",
	"func_getWeaponMinRange": "Returns a <b>weapon</b>'s min range.",
	"func_getWeaponMinRange_arg_1": "Desired weapon.",
	"func_getWeaponMinRange_return": "Given <b>weapon</b>'s minimum range.",
	"func_getWeaponMinScope": "Returns a <b>weapon</b>'s min scope.",
	"func_getWeaponMinScope_arg_1": "Desired weapon.",
	"func_getWeaponMinScope_return": "Given <b>weapon</b>'s minimum scope.",
	"func_getWeaponName": "Returns a <b>weapon</b>'s name.",
	"func_getWeaponName_arg_1": "Desired weapon.",
	"func_getWeaponName_return": "Given <b>weapon</b>'s name",
	"func_getWeapons": "Lists given <b>entity</b>'s weapons.",
	"func_getWeapons_arg_1": "Desired entity.",
	"func_getWeapons_return": "Array containing all of given <b>entity</b>'s weapons.",
	"func_getWeaponTargets": "Lists entities that will be affected if given <b>weapon</b> were used on given <b>cell</b>.",
	"func_getWeaponTargets_arg_1": "Weapon to be used.",
	"func_getWeaponTargets_arg_2": "Targeted cell.",
	"func_getWeaponTargets_return": "Array containing the affected entities.",
	"func_getWisdom": "Returns given <b>entity</b>'s wisdom.",
	"func_getWisdom_arg_1": "Desired entity's id.",
	"func_getWisdom_return": "Given entity's wisdom.",
	"func_hypot": "Returns the hypothenuse of a right-angled triangle with sides <b>x</b> and <b>y</b>. Equivalent to <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "X value.",
	"func_hypot_arg_2": "Y value.",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "Checks whether given <b>element</b> is inside given <b>array</b>.",
	"func_inArray_arg_1": "Array in which search is performed.",
	"func_inArray_arg_2": "Element to look for.",
	"func_inArray_return": "<i>true</i> if given <b>element</b> is contained in <b>array</b>, <i>false</i> otherwise.",
	"func_include": "Includes the AI named <b>ai</b> inside current AI.<br/><br/>Warning: the <b>include</b> function shall only be called inside the main AI block, and its parameter shall NOT be a variable. More information in the tutorial: <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a>.",
	"func_include_arg_1": "AI's name (hard-coded string only)",
	"func_indexOf": "Finds the first occurrence of <b>search</b> string within given <b>string</b>, starting at given <b>position</b>.",
	"func_indexOf_arg_1": "String to be looked into.",
	"func_indexOf_arg_2": "String to be looked for.",
	"func_indexOf_arg_3": "Position within <b>string</b> where the search starts.",
	"func_indexOf_return": "First matching substring's position , -1 if no match was found.",
	"func_insert": "Inserts an <b>element</b> in an <b>array</b>, at given <b>position</b>.",
	"func_insert_arg_1": "Array in which element is added.",
	"func_insert_arg_2": "Element to add.",
	"func_insert_arg_3": "Insertion position.",
	"func_isAlive": "Checks whether a <b>entity</b> is alive. Equivalent to <i>getLife(<b>entity</b>) > 0</i>.",
	"func_isAlive_arg_1": "Desired entity.",
	"func_isAlive_return": "<i>true</i> if <b>entity</b> is alive, <i>false</i> otherwise.",
	"func_isAlly": "Checks whether a <b>entity</b> is your ally.",
	"func_isAlly_arg_1": "Desired entity.",
	"func_isAlly_return": "<i>true</i> if <b>entity</b> is an ally (or you), <i>false</i> otherwise.",
	"func_isChip": "Checks whether given <b>value</b> can represent a chip.<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "Value to check.",
	"func_isChip_return": "<i>true</i> if <b>value</b> is a chip, <i>false</i> otherwise.",
	"func_isDead": "Checks whether a <b>entity</b> is dead. Equivalent to <i>getLife(<b>entity</b>) == 0</i>.",
	"func_isDead_arg_1": "Desired entity.",
	"func_isDead_return": "<i>true</i> if <b>entity</b> is dead, <i>false</i> otherwise.",
	"func_isEmpty": "Checks whether given <b>array</b> is empty. Equivalent to <i>count(<b>array</b>) == 0</i>.",
	"func_isEmpty_arg_1": "Array to check.",
	"func_isEmptyCell": "Checks whether given <b>cell</b> is empty.",
	"func_isEmptyCell_arg_1": "Cell to check.",
	"func_isEmptyCell_return": "<i>true</i> if <b>cell</b> is empty, <i>false</i> otherwise.",
	"func_isEmpty_return": "<i>true</i> if <b>array</b> is empty, <i>false</i> otherwise.",
	"func_isEnemy": "Checks whether a <b>entity</b> is your enemy.",
	"func_isEnemy_arg_1": "Desired entity.",
	"func_isEnemy_return": "<i>true</i> if <b>entity</b> is an enemy, <i>false</i> otherwise.",
	"func_isInlineChip": "Checks whether given <b>chip</b> is inline, i.e. only usable if the target is in the same line as the caster.",
	"func_isInlineChip_arg_1": "Desired chip.",
	"func_isInlineChip_return": "<i>true</i> if <b>chip</b> is inline, <i>false</i> otherwise.",
	"func_isInlineWeapon": "Checks whether given <b>weapon</b> is inline, i.e. only usable if the target is in the same line as the caster.",
	"func_isInlineWeapon_arg_1": "Desired weapon.",
	"func_isInlineWeapon_return": "<i>true</i> if <b>weapon</b> is inline, <i>false</i> otherwise.",
	"func_isLeek": "Checks whether given <b>cell</b> is occupied by an entity.",
	"func_isLeek_arg_1": "Cell to check.",
	"func_isLeek_return": "<i>true</i> if <b>cell</b> is occupied by an entity, <i>false</i> otherwise.",
	"func_isObstacle": "Checks whether given <b>cell</b> is occupied by an obstacle.",
	"func_isObstacle_arg_1": "Cell to check.",
	"func_isObstacle_return": "<i>true</i> if <b>cell</b> is occupied by an obstacle, <i>false</i> otherwise.",
	"func_isOnSameLine": "Checks whether <b>cell1</b> and <b>cell2</b> are on the same line.",
	"func_isOnSameLine_arg_1": "First cell.",
	"func_isOnSameLine_arg_2": "Second cell.",
	"func_isOnSameLine_return": "<i>true</i> if cells are on the same line, <i>false</i> otherwise.",
	"func_isStatic": "Returns wether given <b>entity</b> is a static entity or not. A static entity cannot move or be moved.",
	"func_isStatic_arg_1": "Desired entity.",
	"func_isStatic_return": "<i>true</i> if given entity is a static entity, <i>false</i> otherwise.",
	"func_isSummon": "Returns wether given <b>entity</b> is a summoned entity or not.",
	"func_isSummon_arg_1": "Desired entity.",
	"func_isSummon_return": "<i>true</i> if given entity is a summoned entity, <i>false</i> otherwise.",
	"func_isWeapon": "Checks whether given <b>value</b> can represent a weapon.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false.",
	"func_isWeapon_arg_1": "Value to check.",
	"func_isWeapon_return": "<i>true</i> if <b>value</b> is a weapon, <i>false</i> otherwise.",
	"func_join": "Creates a single string containing all elements of given <b>array</b>, separated by <b>glue</b> delimiters.",
	"func_join_arg_1": "An array of elements to be joined into a string.",
	"func_join_arg_2": "A string that is inserted between consecutive elements of the array.",
	"func_join_return": "The resulting string.",
	"func_jsonDecode": "Decode the string <b>json</b> into a LeekScript object (number, string, array...).",
	"func_jsonDecode_arg_1": "The string to decode.",
	"func_jsonDecode_return": "The decoded LeekScript Object.",
	"func_jsonEncode": "Encode the object <b>object</b> into a JSON string.",
	"func_jsonEncode_arg_1": "The object to encode in JSON.",
	"func_jsonEncode_return": "The JSON encoded string",
	"func_keySort": "Sorts an <b>array</b> using key values, using given sorting <b>order</b>.",
	"func_keySort_arg_1": "Array that is sorted.",
	"func_keySort_arg_2": "Sorting order: #SORT_ASC or #SORT_DESC.",
	"func_length": "Returns given <b>string</b>'s length.",
	"func_length_arg_1": "Desired string.",
	"func_length_return": "Given <b>string</b>'s length",
	"func_lineOfSight": "Checks whether an entity on <b>start</b> cell can see <b>end</b> cell, ignoring given <b>ignoredEntities</b>.",
	"func_lineOfSight_arg_1": "Cell id of starting point.",
	"func_lineOfSight_arg_2": "Cell id of target.",
	"func_lineOfSight_arg_3": "Ignored entity or list of ignored entities (those entities will not block a line of sight).",
	"func_lineOfSight_return": "<i>true</i> if the line of sight is clear, <i>false</i> otherwise.",
	"func_listen": "Lists say() performed by previous entities, as [entity_id, message].",
	"func_listen_return": "Array containing previous say().",
	"func_log": "Computes the natural logarithm of given <b>number</b>.",
	"func_log_arg_1": "A positive number (within ]0; +∞[ ).",
	"func_log_return": "Given number's natural logarithm.",
	"func_log10": "Computes the decimal logarithm of given <b>number</b>.",
	"func_log10_arg_1": "A positive number (within ]0; +∞[ ).",
	"func_log10_return": "Given number's decimal logarithm.",
	"func_log2": "Computes the base 2 logarithm of given <b>number</b>.",
	"func_log2_arg_1": "A positive number (within ]0; +∞[ ).",
	"func_log2_return": "Given number's base 2 logarithm.",
	"func_mark": "Marks one or several cells on terrain in given <b>color</b> for the given <b>duration</b>. The mark is only visible by the marking entity's farmer.",
	"func_mark_arg_1": "A cell or array of cells.",
	"func_mark_arg_2": "Marking's color (see #getColor()).",
	"func_mark_arg_3": "Duration of the marking.",
	"func_mark_return": "<i>true</i> if all went well, <i>false</i> otherwise.",
	"func_markText": "Writes a text on one or more cells of the color indicated in parameter on the field for the number of turns indicated in parameter. These texts are visible only by the farmer of the entity",
	"func_markText_arg_1": "The cell or array of several cells to write to",
	"func_markText_arg_2": "The text to write (maximum 10 characters)",
	"func_markText_arg_3": "Text color",
	"func_markText_arg_4": "Text duration",
	"func_markText_return": "Returns true if everything went well",
	"func_max": "Finds the greatest value among two numbers <b>a</b> and <b>b</b>.",
	"func_max_arg_1": "A number.",
	"func_max_arg_2": "Another number.",
	"func_max_return": "The greatest of the two numbers.",
	"func_min": "Finds the least value among two numbers <b>a</b> and <b>b</b>.",
	"func_min_arg_1": "A number.",
	"func_min_arg_2": "Another number.",
	"func_min_return": "The least of the two numbers.",
	"func_moveAwayFrom": "Moves your entity away from another <b>entity</b>, using at most <b>mp</b> Movement Points.",
	"func_moveAwayFrom_arg_1": "The entity from which you want to flee.",
	"func_moveAwayFrom_arg_2": "The maximum amount of MP to spend.",
	"func_moveAwayFrom_return": "The actual amount of MP spent.",
	"func_moveAwayFromCell": "Moves your entity away from given <b>cell</b>, using at most <b>mp</b> Movement Points.",
	"func_moveAwayFromCell_arg_1": "The cell from which you want to flee.",
	"func_moveAwayFromCell_arg_2": "The maximum amount of MP to spend.",
	"func_moveAwayFromCell_return": "The actual amount of MP spent.",
	"func_moveAwayFromCells": "Moves your entity away from a given group of <b>cells</b>, using at most <b>mp</b> Movement Points.",
	"func_moveAwayFromCells_arg_1": "Array containing the cells from which you want to flee.",
	"func_moveAwayFromCells_arg_2": "The maximum amount of MP to spend.",
	"func_moveAwayFromCells_return": "The actual amount of MP spent.",
	"func_moveAwayFromLeeks": "Moves your entity away from a given group of <b>entities</b>, using at most <b>mp</b> Movement Points.",
	"func_moveAwayFromLeeks_arg_1": "Array containing the entities from which you want to flee.",
	"func_moveAwayFromLeeks_arg_2": "The maximum amount of MP to spend.",
	"func_moveAwayFromLeeks_return": "The actual amount of MP spent.",
	"func_moveAwayFromEntities": "Moves your entity away from a set of entities <b>entities</b>, using at most <b>mp</b> movement points",
	"func_moveAwayFromEntities_arg_1": "The array containing the ids of the entities your entity should move away from",
	"func_moveAwayFromEntities_arg_2": "The maximum number of MP to use.",
	"func_moveAwayFromEntities_return": "The number of movement points used.",
	"func_moveAwayFromLine": "Moves your entity away from the line between <b>cell1</b> and <b>cell2</b>, using at most <b>mp</b> Movement Points.",
	"func_moveAwayFromLine_arg_1": "First cell of the line.",
	"func_moveAwayFromLine_arg_2": "Final cell of the line.",
	"func_moveAwayFromLine_arg_3": "The maximum amount of MP to spend.",
	"func_moveAwayFromLine_return": "The actual amount of MP spent.",
	"func_moveToward": "Moves your entity closer to another <b>entity</b>, using at most <b>mp</b> Movement Points.",
	"func_moveToward_arg_1": "The entity you want to get closer to.",
	"func_moveToward_arg_2": "The maximum amount of MP to spend.",
	"func_moveToward_return": "The actual amount of MP spent.",
	"func_moveTowardCell": "Moves your entity closer to given <b>cell</b>, using at most <b>mp</b> Movement Points.",
	"func_moveTowardCell_arg_1": "The cell you want to get closer to.",
	"func_moveTowardCell_arg_2": "The maximum amount of MP to spend.",
	"func_moveTowardCell_return": "The actual amount of MP spent.",
	"func_moveTowardCells": "Moves your entity closer to a given group of <b>cells</b>, using at most <b>mp</b> Movement Points.",
	"func_moveTowardCells_arg_1": "Array containing the cells to which you want to get closer.",
	"func_moveTowardCells_arg_2": "The maximum amount of MP to spend.",
	"func_moveTowardCells_return": "The actual amount of MP spent.",
	"func_moveTowardLeeks": "Moves your entity closer to a given group of <b>entities</b>, using at most <b>mp</b> Movement Points.",
	"func_moveTowardLeeks_arg_1": "Array containing the entities to which you want to get closer.",
	"func_moveTowardLeeks_arg_2": "The maximum amount of MP to spend.",
	"func_moveTowardLeeks_return": "The actual amount of MP spent.",
	"func_moveTowardEntities": "Moves your entity closer to a set of entities <b>entities</b>, using at most <b>mp</b> movement points",
	"func_moveTowardEntities_arg_1": "The array containing the ids of the entities to which your entity should move closer.",
	"func_moveTowardEntities_arg_2": "The maximum number of MP to use.",
	"func_moveTowardEntities_return": "The number of movement points used.",
	"func_moveTowardLine": "Moves your entity towards the line between <b>cell1</b> and <b>cell2</b>, using at most <b>mp</b> Movement Points.",
	"func_moveTowardLine_arg_1": "The first cell of the line.",
	"func_moveTowardLine_arg_2": "The final cell of the line.",
	"func_moveTowardLine_arg_3": "The maximum amount of MP to spend.",
	"func_moveTowardLine_return": "The actual amount of MP spent.",
	"func_number": "Casts a value into a number. If the value is a string, this function attempts to convert it into a number. If the value is a number, this function returns the number. For any other type,\nthis function returns <i>null</i>",
	"func_number_arg_1": "The value to convert.",
	"func_number_return": "The resulting number.",
	"func_pause": "Pauses the fight, only for the pausing entity's farmer.",
	"func_pop": "Removes the last element of an array, and returns this element.",
	"func_pop_arg_1": "The array to truncate.",
	"func_pop_return": "The truncated element.",
	"func_pow": "Exponentiates a <b>base</b> number to the <b>exp</b> power.",
	"func_pow_arg_1": "Number to exponentiate.",
	"func_pow_arg_2": "Exponant.",
	"func_pow_return": "<b>base</b>**<b>exp</b>.",
	"func_push": "Adds given <b>element</b> at the end of given <b>array</b>.",
	"func_pushAll": "Adds all elements from <b>elements</b> array at the end of given <b>array</b>.",
	"func_pushAll_arg_1": "Array which will be lengthen.",
	"func_pushAll_arg_2": "Array containing elements to add.",
	"func_push_arg_1": "Array which will be lengthen.",
	"func_push_arg_2": "Additionnal element.",
	"func_rand": "Returns a random real number, between 0 (included) and 1 (excluded).",
	"func_randFloat": "Returns a random real number, between <b>a</b> (included) and <b>b</b> (excluded).",
	"func_randFloat_arg_1": "The lower bound of the interval.",
	"func_randFloat_arg_2": "The upper bound of the interval.",
	"func_randFloat_return": "A random number within [a;b).",
	"func_randInt": "Returns a random integer, between <b>a</b> (included) and <b>b</b> (excluded).",
	"func_randInt_arg_1": "The lower bound of the interval.",
	"func_randInt_arg_2": "The upper bound of the interval.",
	"func_randInt_return": "A random integer within [a;b).",
	"func_rand_return": "A random number within [0;1).",
	"func_remove": "Removes the element at given <b>position</b> from given <b>array</b>, and returns this element.",
	"func_remove_arg_1": "The array from which an element is removed.",
	"func_remove_arg_2": "The position of the element to remove.",
	"func_removeElement": "Removes the first occurrence of given <b>element</b> from given <b>array</b>. <b>array</b> is unchanged if <b>element</b> cannot be found.",
	"func_removeElement_arg_1": "The array from which an element is removed.",
	"func_removeElement_arg_2": "The element to look for and remove.",
	"func_removeKey": "Removes the element of given <b>array</b> associated with given <b>key</b>.",
	"func_removeKey_arg_1": "The array from which an element is removed.",
	"func_removeKey_arg_2": "The key of the element to remove.",
	"func_remove_return": "The removed element.",
	"func_replace": "Replaces all occurrence of <b>search</b> string with <b>replace</b> string, within given <b>string</b>.",
	"func_replace_arg_1": "String inside which substitutions are performed.",
	"func_replace_arg_2": "Substring to be replaced.",
	"func_replace_arg_3": "Replacing substring.",
	"func_replace_return": "Resulting string.",
	"func_resurrect": "Uses CHIP_RESURRECTION to resurrect an entity of id <b>entity</b> on the cell <b>cell</b>.",
	"func_resurrect_arg_1": "The id of the entity to revive.",
	"func_resurrect_arg_2": "The cell on which the entity reappear",
	"func_resurrect_return": "The result of the launch of the chip, among USE_* constants.",
	"func_reverse": "Reverses the given <b>array</b>.",
	"func_reverse_arg_1": "Array that is reversed.",
	"func_round": "Computes the integer closest to a given <b>number</b>.<br />The rounded down value can be computed using #floor and the rounded up value using #ceil.",
	"func_round_arg_1": "Number",
	"func_round_return": "Rounded value of <b>number</b>, to the closest integer.",
	"func_say": "Allows your entity to talk. Costs one TP.",
	"func_say_arg_1": "Message your entity says, visible by all viewers of the fight.",
	"func_search": "Searches for given <b>element</b> inside given <b>array</b>, starting at <b>start</b> position.",
	"func_search_arg_1": "The array to look into.",
	"func_search_arg_2": "The element to look for.",
	"func_search_arg_3": "The starting position.",
	"func_search_return": "The position of the first occurrence of given <b>element</b> inside given <b>array</b> starting at given <b>start</b> position, null if no match was found.",
	"func_sendAll": "Sends a message to all of your allies.",
	"func_sendAll_arg_1": "The type of message to send (see MESSAGE_* constants).",
	"func_sendAll_arg_2": "The parameters of the message, which can take any form.",
	"func_sendTo": "Sends a message to given <b>entity</b>.",
	"func_sendTo_arg_1": "Message's addressee",
	"func_sendTo_arg_2": "The type of message to send (see MESSAGE_* constants).",
	"func_sendTo_arg_3": "The parameters of the message, which can take any form.",
	"func_sendTo_return": "<i>true</i> if the message was successfully sent, <i>false</i> if an error occured.",
	"func_setRegister": "Stores given <b>value</b> in your entity's register, associated with given <b>key</b>. Given <b>key</b> and <b>value</b> are strings, respectively  with maximum lengths of <i>100</i> and <i>5000</i>\ncharacters. A leek can have at most 100 distinct keys in its register, any additionnal (key:value) pair will not be stored if your entity's register is full.",
	"func_setRegister_arg_1": "Register key to index given value.",
	"func_setRegister_arg_2": "Value to be stored.",
	"func_setRegister_return": "<i>true</i> if operation succeeded, <i>false</i> otherwise.",
	"func_setWeapon": "Equips given <b>weapon</b> on your entity.",
	"func_setWeapon_arg_1": "Weapon's id.",
	"func_shift": "Removes the first element of given <b>array</b>, and returns the corresponding element.",
	"func_shift_arg_1": "The array to be truncated.",
	"func_shift_return": "The removed element.",
	"func_show": "Shows all players given <b>cell</b> in given <b>color</b>, for one turn. Costs one TP.",
	"func_show_arg_1": "Cell to be showned.",
	"func_show_arg_2": "Marking's color (see #getColor()).",
	"func_show_return": "<i>true</i> if all went well, <i>false</i> otherwise.",
	"func_shuffle": "Shuffles the contents of given <b>array</b>.",
	"func_shuffle_arg_1": "Array to be shuffled.",
	"func_signum": "Checks the sign of given <b>number</b>.",
	"func_signum_arg_1": "Input number.",
	"func_signum_return": "1 if <b>number</b> is positive, 0 if it is 0 and -1 if it is negative.",
	"func_sin": "Computes an <b>angle</b>'s sine.",
	"func_sin_arg_1": "A number.",
	"func_sin_return": "Given number's sine, within [-1, 1].",
	"func_sort": "Sorts given <b>array</b>, using the following order:\n<ul>\n<li>Booleans (false then true)</li>\n<li>Numbers (least to greatest)</li>\n<li>Strings (sorted alphabetically)</li>\n<li>null elements</li>\n</ul>, using the given order.",
	"func_sort_arg_1": "Array that needs sorting.",
	"func_sort_arg_2": "#SORT_ASC to sort from least to greatest, or #SORT_DESC to sort from greatest to least. More details in #sort (especially for multiple types arrays).",
	"func_split": "Splits given <b>string</b> into substrings separated by given <b>delimiter</b>.",
	"func_split_arg_1": "String that needs splitting.",
	"func_split_arg_2": "String that separates desired substrings.",
	"func_split_arg_3": "Maximum size of output array.",
	"func_split_return": "Array containing all substrings.",
	"func_sqrt": "Computes given <b>number</b>'s square root.",
	"func_sqrt_arg_1": "A number.",
	"func_sqrt_return": "Given <b>number</b>'s square root.",
	"func_startsWith": "Checks whether given <b>string</b> starts with given <b>prefix</b>.",
	"func_startsWith_arg_1": "String to look into.",
	"func_startsWith_arg_2": "Prefix to look for.",
	"func_startsWith_return": "<i>true</i> if given <b>string</b> starts with given <b>prefix</b>, <i>false</i> otherwise.",
	"func_string": "Casts a value to a string container.<br />Returns:\n<ul><li>value if it is a string</li>\n<li>\"value\" if it is a number</li>\n<li>\"[key1: value1, key2: value2... ...]\" if value is an array</li>\n<li>\"true\" or \"false\" if value is a boolean</li>\n<li>\"null\" if value is null</li>\n</ul>.",
	"func_string_arg_1": "Value that needs conversion.",
	"func_string_return": "Resulting string.",
	"func_subArray": "Extracts a sub-array, starting at <b>start</b> position and ending at <b>end</b> position, from given <b>array</b>.",
	"func_subArray_arg_1": "Source array.",
	"func_subArray_arg_2": "Starting position.",
	"func_subArray_arg_3": "Ending position.",
	"func_subArray_return": "The desired sub-array.",
	"func_substring": "Extracts a substring, starting at <b>start</b> position and of given <b>length</b>, from given <b>string</b>",
	"func_substring_arg_1": "Source string.",
	"func_substring_arg_2": "Starting position.",
	"func_substring_arg_3": "Length of the output.",
	"func_substring_return": "The desired substring.",
	"func_sum": "Computes the sum of all elements within given <b>array</b>.",
	"func_sum_arg_1": "Source array.",
	"func_summon": "Uses given <b>chip</b> to summon the corresponding entity on given <b>cell</b>. The entity uses the AI contained in given <b>ai</b> function.",
	"func_summon_arg_1": "Desired chip. It has to be a summoning chip, equipped on the sumonning entity.",
	"func_summon_arg_2": "Cell where the summon should appear.",
	"func_summon_arg_3": "The sumonned entity's AI, as a function.",
	"func_summon_return": "Summoning result, see #useChip for details.",
	"func_sum_return": "Computed sum.",
	"func_tan": "Computes given <b>angle</b>'s tangent.",
	"func_tan_arg_1": "A number.",
	"func_tan_return": "Given <b>angle</b>'s tangent.",
	"function_category_array": "Arrays",
	"function_category_chip": "Chips",
	"function_category_color": "Color",
	"function_category_fight": "Fight",
	"function_category_leek": "Leeks",
	"function_category_map": "Map",
	"function_category_field": "Field",
	"function_category_math": "Numbers",
	"function_category_network": "Network",
	"function_category_string": "Strings",
	"function_category_utils": "Utility",
	"function_category_weapon": "Weapons",
	"func_toDegrees": "Converts given <b>angle</b> from radians to degrees.",
	"func_toDegrees_arg_1": "Angle's measure in radians.",
	"func_toDegrees_return": "Angle's measure in degrees.",
	"func_toLower": "Converts all capital letters from given <b>string</b> to lower characters.",
	"func_toLower_arg_1": "Input string.",
	"func_toLower_return": "String in lower characters.",
	"func_toRadians": "Converts given <b>angle</b> from degrees to radians.",
	"func_toRadians_arg_1": "Angle's measure in degrees.",
	"func_toRadians_return": "Angle's measure in radians.",
	"func_toUpper": "Converts all lower characters from given <b>string</b> to capital letters.",
	"func_toUpper_arg_1": "Input string.",
	"func_toUpper_return": "String in upper characters.",
	"func_typeOf": "Determines the type of given <b>value</b>, among: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "Value that needs testing.",
	"func_typeOf_return": "<b>values</b>'s type.",
	"func_unshift": "Adds given <b>element</b> at the beginning of given <b>array</b>.",
	"func_unshift_arg_1": "Array.",
	"func_unshift_arg_2": "Additional element.",
	"func_useChip": "Use given <b>chip</b>, targeting given <b>entity</b>.",
	"func_useChip_arg_1": "Chip to use.",
	"func_useChip_arg_2": "Targeted entity.",
	"func_useChipOnCell": "Use given <b>chip</b>, targeting given <b>cell</b>.",
	"func_useChipOnCell_arg_1": "Chip to use.",
	"func_useChipOnCell_arg_2": "Targeted cell.",
	"func_useChipOnCell_return": "See #useChip.",
	"func_useChip_return": "<ul>\n\t<li>#USE_CRITICAL, in case of critical hit</li>\n\t<li>#USE_SUCCESS, in case of success</li>\n\t<li>#USE_FAILED, in case of failure</li>\n\t<li>#USE_INVALID_TARGET, if the target does not exist</li>\n\t<li>#USE_NOT_ENOUGH_TP, if your entity does not have enough TP</li>\n\t<li>#USE_INVALID_COOLDOWN, if given <b>chip</b>'s cooldown is not expired</li>\n\t<li>#USE_INVALID_POSITION, if the chip's range does not allow its use, or line of sight is not clear.</li>\n</ul>",
	"func_useWeapon": "Fires your entity's weapon, targeting given <b>entity</b>.",
	"func_useWeapon_arg_1": "Targeted entity.",
	"func_useWeaponOnCell": "Fires your entity's weapon, targeting given <b>cell</b>.",
	"func_useWeaponOnCell_arg_1": "Targeted cell.",
	"func_useWeaponOnCell_return": "See #useWeapon.",
	"func_useWeapon_return": "<ul>\n\t<li>#USE_CRITICAL, in case of critical hit</li>\n\t<li>#USE_SUCCESS, in case of success</li>\n\t<li>#USE_FAILED, in case of failure</li>\n\t<li>#USE_INVALID_TARGET, if the target does not exist</li>\n\t<li>#USE_NOT_ENOUGH_TP, if your entity does not have enough TP</li>\n\t<li>#USE_INVALID_POSITION, if the weapon's scope does not allow its use, or line of sight is not clear.</li>\n</ul>",
	"func_weaponNeedLos": "Returns whether given <b>weapon</b> requires a line of sight in order to be shot.",
	"func_weaponNeedLos_arg_1": "Desired weapon's id.",
	"func_weaponNeedLos_return": "<i>true</i> if given <b>weapon</b> requires a line of sight, <i>false</i> otherwise.",
	"items": "Items",
	"max_cores": "max",
	"max_level": "max",
	"min_cores": "Min cores",
	"min_level": "Min level",
	"n_operations": "<b>{0}</b> operations",
	"parameters": "Parameters",
	"return": "Return",
	"value": "Value",
	"variable_operations": "<b>Variable</b> operations",
	"const_INSTRUCTIONS_LIMIT": "Maximum number of instructions an entity can use during its turn.",
	"const_USE_CRITICAL": "Value returned by the #useWeapon, #useWeaponOnCell, #useChip and #useChipOnCell functions in the event of a critical hit.",
	"const_USE_FAILED": "Value returned by the #useWeapon, #useWeaponOnCell, #useChip and #useChipOnCell functions on failure.",
	"const_USE_INVALID_COOLDOWN": "Value returned by the #useChip and #useChipOnCell functions if the chip is not yet usable.",
	"const_USE_INVALID_POSITION": "Value returned by the #useWeapon, #useWeaponOnCell, #useChip and #useChipOnCell functions if the range is bad or the line of sight is not clear.",
	"const_USE_INVALID_TARGET": "Value returned by the #useWeapon and #useChip functions if the target does not exist.",
	"const_USE_NOT_ENOUGH_TP": "Value returned by the #useWeapon, #useWeaponOnCell, #useChip and #useChipOnCell functions if the caster does not have enough action points to use the object.",
	"const_USE_SUCCESS": "Value returned by the #useWeapon, #useWeaponOnCell, #useChip and #useChipOnCell functions on success.",
	"func_getFightID": "Returns the current fight id.",
	"func_getFightID_return": "The id of the current fight.",
	"func_isEntity": "Determines whether the contents of cell <b>cell</b> is an entity.",
	"func_isEntity_arg_1": "The cell to be tested.",
	"func_isEntity_return": "<i>true</i> if the cell contains an entity, <i>false</i> otherwise.",
	"deprecated_function": "This function is deprecated.",
	"deprecated_constant": "This constant is deprecated.",
	"replaced_by": "It is replaced by {0}.",
	"details": "Details",
	"optional": "optional",
	"arg_type_9": "set",
	"arg_type_96": "set<integer>",
	"arg_type_10": "interval",
	"function_category_set": "Sets",
	"function_category_interval": "Intervals"
}

	i18n.mergeLocaleMessage("en", { "doc": messages })
	