
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> operasjon",
	"arg_type_-1": "?",
	"arg_type_1": "antall",
	"arg_type_2": "kjede",
	"arg_type_3": "boolsk",
	"arg_type_4": "liste",
	"arg_type_41": "liste<nummer>",
	"arg_type_42": "liste<streng>",
	"arg_type_43": "liste<boolesk>",
	"arg_type_44": "liste<liste>",
	"arg_type_46": "liste<heltall>",
	"arg_type_47": "liste<ekte>",
	"arg_type_5": "funksjon",
	"arg_type_6": "hel",
	"arg_type_7": "ekte",
	"arg_type_8": "bord",
	"arg_type_806": "tabell<?, heltall>",
	"complexity": "Kompleksitet {c}",
	"operations": "{o} operasjoner",
	"const_AREA_CIRCLE_1": "Sirkulært område 3 ruter i diameter (kryss).",
	"const_AREA_CIRCLE_2": "Sirkulært område 5 ruter i diameter.",
	"const_AREA_CIRCLE_3": "Sirkulært område 7 ruter i diameter.",
	"const_AREA_LASER_LINE": "Sone til en laser, linje fra minimumsområdet til laseren til dets maksimale rekkevidde eller en hindring.",
	"const_AREA_POINT": "Område som består av en enkelt boks.",
	"const_CELL_EMPTY": "Returverdi for getCellContent(celle) for en tom celle.",
	"const_CELL_OBSTACLE": "Returverdi for getCellContent(celle) for en celle som inneholder en hindring.",
	"const_CELL_PLAYER": "Returverdi for getCellContent(celle) for en celle som inneholder en enhet.",
	"const_CELL_ENTITY": "Returverdi for getCellContent(celle) for en celle som inneholder en enhet.",
	"const_COLOR_BLUE": "Farge blå.",
	"const_COLOR_GREEN": "Grønn farge.",
	"const_COLOR_RED": "Rød farge.",
	"const_E": "Eulers nummer.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Gir absolutt skjold til en enhet, og reduserer mengden treffpoeng som er fjernet av skade (EFFECT_DAMAGE) med et fast beløp. Forsterkes av motstand.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Fjerner absolutt skjold fra en enhet. Er ikke forsterket av en karakteristikk. Lar deg øke treffpoengene fjernet av skade (EFFECT_DAMAGE) med et absolutt beløp.",
	"const_EFFECT_AFTEREFFECT": "Fjerner treffpunkter fra en enhet. Forsterket av vitenskap. Reduserer maksimale treffpoeng med 5 % av antall treffpoeng som er fjernet.",
	"const_EFFECT_ANTIDOTE": "Fjerner all gift (EFFECT_POISON) som finnes på et mål.",
	"const_EFFECT_BOOST_MAX_LIFE": "Øker treffpoeng og maksimale treffpoeng for en enhet. Forsterket av visdom.",
	"const_EFFECT_BUFF_AGILITY": "Gir smidighet til en enhet. Forsterket av vitenskap.",
	"const_EFFECT_BUFF_MP": "Gir bevegelsespoeng til en enhet. Forsterket av vitenskap.",
	"const_EFFECT_BUFF_RESISTANCE": "Gir motstand til en enhet. Forsterket av vitenskap.",
	"const_EFFECT_BUFF_STRENGTH": "Gir styrke til en enhet. Forsterket av vitenskap.",
	"const_EFFECT_BUFF_TP": "Gir handlingspoeng til en enhet. Forsterket av vitenskap.",
	"const_EFFECT_BUFF_WISDOM": "Gi visdom til en enhet. Forsterket av vitenskap.",
	"const_EFFECT_DAMAGE": "Fjerner treffpunkter fra en enhet. Force Amplified. Samhandler med skjold (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), lifesteal (unntatt caster) og skaderetur (EFFECT_DAMAGE_RETURN). Reduserer maksimale treffpoeng med 5 % av antall treffpoeng som er fjernet.",
	"const_EFFECT_DAMAGE_RETURN": "Gir skaderefleksjon til en enhet, og gjør det mulig å fjerne treffpunkter fra enheter som påfører mottakeren skade. Forsterket av smidighet. Reduserer maksimale treffpoeng med 5 % av antall treffpoeng som er fjernet.",
	"const_EFFECT_DEBUFF": "Reduserer verdien av alle effekter på en enhet med en prosentandel.",
	"const_EFFECT_HEAL": "Gjenoppretter treffpoeng til en enhet, begrenset av maksimale treffpoeng. Forsterket av visdom.",
	"const_EFFECT_INVERT": "Bytter posisjonen til hjulet med den til en enhet.",
	"const_EFFECT_KILL": "Fjerner alle treffpunkter fra en enhet.",
	"const_EFFECT_LIFE_DAMAGE": "Fjerner treffpoeng fra en enhet, basert på en prosentandel av casterens treffpoeng. Samhandler med skjold (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) og skaderetur (EFFECT_DAMAGE_RETURN). Reduserer maksimale treffpoeng med 5 % av antall treffpoeng som er fjernet.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "Effekten multipliseres med antall berørte enheter i området.",
	"const_EFFECT_MODIFIER_ON_CASTER": "Effekten påvirker fortsatt hjulet.",
	"const_EFFECT_MODIFIER_STACKABLE": "Effekten er kumulativ.",
	"const_EFFECT_NOVA_DAMAGE": "Fjerner maks livspoeng. Forsterket av vitenskap.",
	"const_EFFECT_POISON": "Fjerner treffpunkter fra en enhet. Forsterket av magi. Reduserer maksimale treffpoeng med 10 % av antall treffpoeng som er fjernet.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Gir absolutt skjold til en enhet, og reduserer mengden treffpoeng som er fjernet av skade (EFFECT_DAMAGE) med et fast beløp. Ikke forsterkbar.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Gir smidighet til en enhet. Kan ikke forsterkes.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Gir magi til en enhet. Kan ikke forsterkes.",
	"const_EFFECT_RAW_BUFF_MP": "Gir bevegelsespoeng til en enhet. Kan ikke forsterkes.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Gir vitenskap til en enhet. Ikke forsterkbar.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Gir styrke til en enhet. Ikke forsterkbar.",
	"const_EFFECT_RAW_BUFF_TP": "Gir handlingspoeng til en enhet. Ikke forsterkbar.",
	"const_EFFECT_RELATIVE_SHIELD": "Gir et relativt skjold, og reduserer mengden treffpoeng som er fjernet av skade (EFFECT_DAMAGE) med en relativ mengde. Forsterkes av motstand.",
	"const_EFFECT_RESURRECT": "Gjenoppliver en enhet, med et maksimalt HP-tall lik halvparten av enhetens maksimale HP-tall før oppstandelse, og et gjeldende HP-tall lik en fjerdedel av det maksimale HP-tallet før oppstandelse.",
	"const_EFFECT_SHACKLE_MAGIC": "Fjerner magi fra en enhet. Forsterket av magi.",
	"const_EFFECT_SHACKLE_MP": "Fjern bevegelsespunkter fra en enhet. Forsterket av magi.",
	"const_EFFECT_SHACKLE_STRENGTH": "Fjerner styrke fra en enhet. Forsterket av magi.",
	"const_EFFECT_SHACKLE_TP": "Trekk fra handlingspunkter fra en enhet. Forsterket av magi.",
	"const_EFFECT_SUMMON": "Kaller frem en pære. Ingen effekt hvis innkallingsgrensen er nådd.",
	"const_EFFECT_TARGET_ALLIES": "Påvirker allierte.",
	"const_EFFECT_TARGET_CASTER": "Påvirker hjulet.",
	"const_EFFECT_TARGET_ENEMIES": "Påvirker fiender.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Påvirker ikke-innkalte enheter (purre og tårn).",
	"const_EFFECT_TARGET_SUMMONS": "Påvirker tilkalte enheter (pærer).",
	"const_EFFECT_TELEPORT": "Endrer posisjonen til hjulet.",
	"const_EFFECT_VULNERABILITY": "Fjerner skjold i forhold til en enhet. Er ikke forsterket av en karakteristikk. Øker treffpoeng fjernet av skade (EFFECT_DAMAGE) med et relativt beløp.",
	"const_ENTITY_BULB": "Refererer til en enhet av pæretypen.",
	"const_ENTITY_LEEK": "Refererer til en purre-type enhet.",
	"const_ENTITY_TURRET": "Refererer til en enhet av typen Turret.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Kampbakgrunn av utfordringstype.",
	"const_FIGHT_CONTEXT_GARDEN": "Kontekst av kamp i grønnsakshagen.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Kontekst av kamp i Battle Royale.",
	"const_FIGHT_CONTEXT_TEST": "Test kampkontekst.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Bakgrunn for turneringskamp.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Kjemp i Battle Royale.",
	"const_FIGHT_TYPE_FARMER": "Oppdretterkamp.",
	"const_FIGHT_TYPE_SOLO": "Solo kamp.",
	"const_FIGHT_TYPE_TEAM": "Lagkamp.\n",
	"const_INSTRUCTIONS_LIMIT": "Maksimalt antall instruksjoner en enhet kan bruke under sin tur.",
	"const_MAX_TURNS": "Maksimalt antall svinger i en kamp.",
	"const_OPERATIONS_LIMIT": "Maksimalt antall operasjoner en enhet kan bruke under sin tur.",
	"const_PI": "Forholdet mellom omkretsen av en sirkel og dens diameter.",
	"const_SORT_ASC": "Ber #sort-funksjonen sortere i stigende rekkefølge.",
	"const_SORT_DESC": "Ber #sort-funksjonen sortere i synkende rekkefølge.",
	"const_TYPE_ARRAY": "Verditype <b>liste</b>",
	"const_TYPE_BOOLEAN": "Type verdi <b>boolsk</b>",
	"const_TYPE_FUNCTION": "Verditype <b>funksjon</b>",
	"const_TYPE_NULL": "Type verdi <b>null</b>",
	"const_TYPE_NUMBER": "Verditype <b>nummer</b>",
	"const_TYPE_STRING": "Type verdi <b>tegnstreng</b>",
	"const_USE_CRITICAL": "Verdi returnert av funksjonene #useWeapon, #useWeaponOnCell, #useChip og #useChipOnCell i tilfelle et kritisk treff.",
	"const_USE_FAILED": "Verdi returnert av funksjonene #useWeapon, #useWeaponOnCell, #useChip og #useChipOnCell ved feil.",
	"const_USE_INVALID_COOLDOWN": "Verdi returnert av #useChip- og #useChipOnCell-funksjonene hvis brikken ennå ikke er brukbar.",
	"const_USE_INVALID_POSITION": "Verdi returnert av funksjonene #useWeapon, #useWeaponOnCell, #useChip og #useChipOnCell hvis rekkevidden er dårlig eller siktlinjen ikke er klar.",
	"const_USE_INVALID_TARGET": "Verdi returnert av #useWeapon- og #useChip-funksjonene hvis målet ikke eksisterer.",
	"const_USE_NOT_ENOUGH_TP": "Verdi returnert av funksjonene #useWeapon, #useWeaponOnCell, #useChip og #useChipOnCell hvis casteren ikke har nok handlingspunkter til å bruke objektet.",
	"const_USE_RESURRECT_INVALID_ENTITY": "Verdi returnert av #resurrect-funksjonen når den angitte enheten ikke eksisterer eller ennå ikke er død.",
	"const_USE_SUCCESS": "Verdi returnert av funksjonene #useWeapon, #useWeaponOnCell, #useChip og #useChipOnCell etter suksess.",
	"const_USE_TOO_MANY_SUMMONS": "Feil returnert av #summon når du allerede har <b>8</b> live-innkallinger.\n",
	"func_abs": "Returnerer den absolutte verdien av tallet <b>tallet</b>.",
	"func_abs_arg_1": "Tallet hvis absolutte verdi vil bli beregnet.",
	"func_abs_return": "Den absolutte verdien av tallet.",
	"func_acos": "Beregner arccosinus til <b>argument</b>, i intervallet [0, #PI].",
	"func_acos_arg_1": "Antall hvis arccosine vil bli beregnet.",
	"func_acos_return": "Buekosinus til <b>argument</b>.",
	"func_arrayConcat": "Legg til de to matrisene ende mot ende. Teksttastene bevares og alle talltastene indekseres på nytt.",
	"func_arrayConcat_arg_1": "Første bord.",
	"func_arrayConcat_arg_2": "Andre bord.",
	"func_arrayConcat_return": "Summen av de to matrisene.",
	"func_arrayFilter": "Returnerer en ny matrise som inneholder alle nøkkel/verdi-par fra kildematrisen som tilbakeringingsfunksjonen returnerte sant for. Hvis tilbakeringingsfunksjonen tar én parameter, vil verdien til kildematrisen sendes, hvis den tar to parametere, sendes nøkkelen og verdien.",
	"func_arrayFilter_arg_1": "Originalt bord.",
	"func_arrayFilter_arg_2": "Funksjon kalt for hvert element.",
	"func_arrayFilter_return": "Nytt bord.",
	"func_arrayFlatten": "Returnerer en ny matrise som inneholder alle elementene i kildematrisen. Alle elementene i en sub-array trekkes ut i den nye matrisen. Dybdeargumentet bestemmer den maksimale sub-array-dybden som skal trekkes ut.\n<kode>var array = [1, 2, [[3, 4], 5], [6]];\ndebug(arrayFlatten(array, 1)); // [1,2,[3,4],5,6]</code>",
	"func_arrayFlatten_arg_1": "Originalt bord.",
	"func_arrayFlatten_arg_2": "Maksimal dybde.",
	"func_arrayFlatten_return": "Nytt bord.",
	"func_arrayFoldLeft": "Reduser arrayet <b>array</b> [v1, v2, ..., vn] fra venstre med verdien <b>v0</b> og bruk funksjonen <b>f</b >. Er lik :\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "Originalt bord.",
	"func_arrayFoldLeft_arg_2": "Funksjon å bruke.",
	"func_arrayFoldLeft_arg_3": "Startverdi.",
	"func_arrayFoldLeft_return": "Redusert bord.",
	"func_arrayFoldRight": "Reduser arrayet <b>array</b> [v1, v2, ..., vn] fra høyre med verdien <b>v0</b> og bruk funksjonen <b>f</b >. Er lik :\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "Originalt bord.",
	"func_arrayFoldRight_arg_2": "Funksjon å bruke.",
	"func_arrayFoldRight_arg_3": "Startverdi.",
	"func_arrayFoldRight_return": "Redusert bord.",
	"func_arrayIter": "Kaller tilbakeringingsfunksjonen for hvert matriseelement. Hvis tilbakeringingsfunksjonen tar én parameter, vil verdien til kildematrisen sendes, hvis den tar to parametere, sendes nøkkelen og verdien.",
	"func_arrayIter_arg_1": "Originalt bord.",
	"func_arrayIter_arg_2": "Funksjon kalt for hvert element.",
	"func_arrayMap": "Returnerer en ny matrise som inneholder for hver nøkkel i kildematrisen, verdien som returneres av tilbakeringingsfunksjonen. Hvis tilbakeringingsfunksjonen tar én parameter, vil verdien til kildematrisen sendes, hvis den tar to parametere, sendes nøkkelen og verdien.",
	"func_arrayMap_arg_1": "Originalt bord.",
	"func_arrayMap_arg_2": "Funksjon kalt for hvert element.",
	"func_arrayMap_return": "Nytt bord.",
	"func_arrayMax": "Returnerer maksimumsverdielementet til <b>array</b>-matrisen.<br/>For mer informasjon om maksimalverdien til en matrise, se #sort.",
	"func_arrayMax_arg_1": "Matrise der maksimalverdien vil bli søkt.",
	"func_arrayMax_return": "Vare med maksimal verdi.",
	"func_arrayMin": "Returnerer minimumsverdielementet til <b>array</b>-matrisen.<br/>For mer informasjon om minimumsverdien til en matrise, se #sort.",
	"func_arrayMin_arg_1": "Matrise der minimumsverdien vil bli søkt.",
	"func_arrayMin_return": "Minste verdi element.",
	"func_arrayPartition": "Returnerer en ny matrise som inneholder to lister, i den første er alle nøkkel/verdi-parene som tilbakeringingsfunksjonen returnerte sann for, i den andre er alle de andre. Hvis tilbakeringingsfunksjonen tar én parameter, vil verdien til kildematrisen sendes, hvis den tar to parametere, sendes nøkkelen og verdien.",
	"func_arrayPartition_arg_1": "Originalt bord.",
	"func_arrayPartition_arg_2": "Funksjon kalt for hvert element.",
	"func_arrayPartition_return": "Nytt bord.",
	"func_arraySort": "Sorterer matrisen i henhold til rekkefølgen definert av tilbakeringingsfunksjonen. Elementene sammenlignes to og to, tilbakeringingsfunksjonen må returnere verdiene -1, 0 eller 1 avhengig av om den første verdien er før, på samme nivå eller etter den andre verdien. Hvis tilbakeringingsfunksjonen tar 2 parametere, sendes de to verdiene, hvis den tar 4, sendes nøkkel/verdi-parene.",
	"func_arraySort_arg_1": "Originalt bord",
	"func_arraySort_arg_2": "Sorteringsfunksjon.",
	"func_arraySort_return": "Den sorterte matrisen",
	"func_asin": "Beregner arcsine av <b>argument</b>, i intervallet [0, #PI].",
	"func_asin_arg_1": "Antallet hvis arcsine vil bli beregnet.",
	"func_asin_return": "Arcsinus av <b>argument</b>.",
	"func_assocSort": "Sorterer matrisen <b>matrise</b> og beholder nøkkel:verdi-tilknytningen i henhold til rekkefølgen <b>rekkefølge</b>.",
	"func_assocSort_arg_1": "Matrisen for å sortere.",
	"func_assocSort_arg_2": "Sorteringsrekkefølgen: #SORT_ASC eller #SORT_DESC.",
	"func_atan": "Beregner arctangensen til <b>argument</b>, i intervallet [0, #PI].",
	"func_atan2": "Konverterer kartesiske koordinater (<b>x</b>, <b>y</b>) til polare koordinater (<b>r</b>, <b>theta</b>). Denne funksjonen returnerer <b>theta</b>-vinkelen mellom -#PI og #PI ved å bruke fortegnene til argumentene.",
	"func_atan2_arg_1": "Y-koordinat.",
	"func_atan2_arg_2": "X koordinat.",
	"func_atan2_return": "<b>theta</b>-vinkelen i polare koordinater til punktet (x, y).",
	"func_atan_arg_1": "Nummer hvis arctangens vil bli beregnet.",
	"func_atan_return": "Buetangensen til <b>argument</b>.",
	"func_average": "Beregner gjennomsnittet av elementene i arrayen <b>array</b>.",
	"func_average_arg_1": "Array hvis gjennomsnitt vi ønsker å beregne.",
	"func_average_return": "Gjennomsnittlig verdi.",
	"func_canUseChip": "Bestemmer om enheten din kan bruke <b>brikken</b>-brikken på enheten med ID-en <b>entity</b>.",
	"func_canUseChip_arg_1": "Nummeret på brikken som skal testes.",
	"func_canUseChip_arg_2": "Enhets-ID-en du vil bruke brikken på.",
	"func_canUseChip_return": "<i>true</i> hvis enheten din kan bruke brikken, <i>false</i> ellers.",
	"func_canUseChipOnCell": "Bestemmer om enheten din kan bruke <b>brikken</b>-brikken på <b>cellen</b>-cellen.",
	"func_canUseChipOnCell_arg_1": "Nummeret på brikken som skal testes.",
	"func_canUseChipOnCell_arg_2": "Nummeret på cellen du vil bruke brikken på.",
	"func_canUseChipOnCell_return": "<i>true</i> hvis enheten din kan bruke brikken, <i>false</i> ellers.",
	"func_canUseWeapon": "Bestemmer om enheten din kan skyte enheten med id <b>entity</b> med våpenet <b>våpenet</b>.",
	"func_canUseWeapon_arg_1": "Våpenet å teste. Standard til ditt for øyeblikket utstyrte våpen.",
	"func_canUseWeapon_arg_2": "ID-en til enheten du vil skyte på.",
	"func_canUseWeapon_return": "<i>true</i> hvis enheten din kan skyte, <i>false</i> ellers.",
	"func_canUseWeaponOnCell": "Bestemmer om enheten din kan skyte <b>celle</b>-cellen med <b>våpen</b>-våpenet.",
	"func_canUseWeaponOnCell_arg_1": "Våpenet å teste. Standard til ditt for øyeblikket utstyrte våpen.",
	"func_canUseWeaponOnCell_arg_2": "Nummeret på cellen du vil skyte.",
	"func_canUseWeaponOnCell_return": "<i>true</i> hvis enheten din kan skyte, <i>false</i> ellers.",
	"func_cbrt": "Beregn kuberoten av <b>tall</b>",
	"func_cbrt_arg_1": "Tall hvis terningrot vi ønsker å beregne.",
	"func_cbrt_return": "Terningrot av <b>tall</b>.",
	"func_ceil": "Returnerer avrundingen opp av <b>tall</b>.<br/>Du kan finne avrundingen ned med #gulv, og avrundingen med #runde.",
	"func_ceil_arg_1": "Tall som vi ønsker å finne den øvre avrundingen for.",
	"func_ceil_return": "Rund opp <b>tall</b>.",
	"func_charAt": "Returnerer tegnet til strengen <b>streng</b> plassert ved posisjon <b>posisjon</b>.<br/>Det første tegnet i strengen er på posisjon 0.",
	"func_charAt_arg_1": "Streng som vi ønsker å hente en karakter fra.",
	"func_charAt_arg_2": "Posisjonen til karakteren å finne.",
	"func_charAt_return": "streng som inneholder det søkte tegnet, eller en tom streng hvis posisjonen er ugyldig.",
	"func_chipNeedLos": "Returnerer om <b>brikken</b> trenger siktlinje for å kunne brukes.",
	"func_chipNeedLos_arg_1": "IDen til brikken som skal testes.",
	"func_chipNeedLos_return": "<i>true</i> hvis <b>brikken</b> trenger siktlinje for å brukes, <i>false</i> ellers.",
	"func_clearMarks": "Fjerner alle markeringer laget av mark() og markText() i feltet.",
	"func_clone": "Kopierer <b>verdien</b> som er sendt som en parameter på <b>nivå</b>-nivåer og returnerer kopien. Når det gjelder en matrise for eksempel, vil en klone(matrise, 1) kopiere matrisen, men ikke dens elementer, en klone(matrise, 2) vil kopiere matrisen så vel som alle elementene.",
	"func_clone_arg_1": "Verdien for å klone",
	"func_clone_arg_2": "Antall nivåer som skal klones",
	"func_clone_return": "Den klonede verdien",
	"func_contains": "Bestemmer om en <b>søkestreng</b> er innenfor en <b>streng</b>.",
	"func_contains_arg_1": "Streng som søket skal utføres i.",
	"func_contains_arg_2": "String å søke etter.",
	"func_contains_return": "sant hvis <b>søk</b> er i <b>streng</b>, ellers usann.",
	"func_cos": "Beregner cosinus til <b>vinkel</b>.",
	"func_cos_arg_1": "Tallet hvis cosinus vil bli beregnet (i radianer).",
	"func_cos_return": "Cosinus til <b>vinkel</b>, i intervallet [-1, 1].",
	"func_count": "Teller antall elementer i <b>matrisen</b> matrisen.",
	"func_count_arg_1": "Array hvis antall elementer vil telles.",
	"func_count_return": "Antall matriseelementer.",
	"func_debug": "Registrerer en <b>objekt</b>-melding i den personlige loggen, tilgjengelig i rapporten på slutten av kampen.",
	"func_debug_arg_1": "Meldingen som skal spilles inn.",
	"func_debugC": "Registrerer en <b>objekt</b>-melding i den personlige loggen, tilgjengelig i rapporten ved slutten av kampen, med fargen <b>farge</b>.",
	"func_debugC_arg_1": "Meldingen som skal spilles inn.",
	"func_debugC_arg_2": "Fargen på meldingen. Du kan bruke #getColor-funksjonen.",
	"func_debugE": "Registrerer en <b>objekt</b> feilmelding i den personlige loggen, tilgjengelig i rapporten ved slutten av kampen.<br />Feilmeldinger vises i rødt i kamprapporten.",
	"func_debugE_arg_1": "Feilmeldingen for å logge.",
	"func_debugW": "Registrerer en <b>objekt</b>-advarselsmelding i den personlige loggen, tilgjengelig i rapporten på slutten av kampen. <br />Advarselsmeldinger vises i oransje i kamprapporten.",
	"func_debugW_arg_1": "Advarselsmeldingen som skal spilles inn.",
	"func_deleteRegister": "Sletter registret knyttet til nøkkel <b>nøkkel</b> hvis det finnes.",
	"func_deleteRegister_arg_1": "Registernøkkelen som skal slettes.",
	"func_endsWith": "Bestemmer om en <b>streng</b> slutter med en <b>suffiks</b>-streng.",
	"func_endsWith_arg_1": "Streng som søket skal utføres i",
	"func_endsWith_arg_2": "Suffiks for å søke",
	"func_endsWith_return": "true hvis <b>streng</b> slutter med <b>suffiks</b>",
	"func_exp": "Hever Eulers tall #E til <b>tall</b> potens.",
	"func_exp_arg_1": "Eksponenten som #E vil heves til.",
	"func_exp_return": "#E** <b>nummer</b>.",
	"func_fill": "Endrer størrelsen på <b>matrisen</b> til <b>størrelsen</b> og fyller alle boksene med <b>verdi</b>.",
	"func_fill_arg_1": "Tabellen for å fullføre.",
	"func_fill_arg_2": "Verdien for hver firkant i matrisen.",
	"func_fill_arg_3": "Antall bokser. Som standard er gjeldende matrisestørrelse.",
	"func_floor": "Beregner avrundingen ned av <b>tall</b>.<br/>Du kan finne avrundingen opp med #tak, og avrundingen med #rund.",
	"func_floor_arg_1": "Tallet hvis avrunding nedover vi ønsker å finne.",
	"func_floor_return": "Avrundet ned til <b>tall</b>.",
	"func_getAbsoluteShield": "Returnerer det absolutte skjoldet til enheten med id <b>entity</b>. For å hente enhetens absolutte skjold direkte, bruk #getAbsoluteShield() uten parametere.",
	"func_getAbsoluteShield_arg_1": "ID-en til enheten hvis absolutte skjold vil bli returnert.",
	"func_getAbsoluteShield_return": "Det absolutte skjoldet til enheten <b>enhet</b>.",
	"func_getAgility": "Returnerer smidigheten til enheten med id <b>entity</b>. For å hente enhetens smidighet direkte, bruk #getAgility() uten parametere.",
	"func_getAgility_arg_1": "ID-en til enheten hvis smidighet vil bli returnert.",
	"func_getAgility_return": "Smidigheten til <b>entitet</b>-enheten.",
	"func_getAIID": "Returnerer AI-ID-en til enheten <b>entitet</b>. Bruk getAIID uten parameter for å hente AI-IDen din.",
	"func_getAIID_arg_1": "ID-en til enheten hvis AI-ID vil bli returnert.",
	"func_getAIID_return": "<b>entity</b>-enhetens AI-ID.",
	"func_getAIName": "Returnerer AI-navnet til entitet <b>enhet</b>. Bruk getAINam() uten parametere for å hente navnet på AI-en din.",
	"func_getAIName_arg_1": "ID-en til enheten hvis AI-navn vil bli returnert.",
	"func_getAIName_return": "Navnet på <b>entity</b>-enhetens AI.",
	"func_getAliveAllies": "Returnerer en rekke av alle dine allierte i live i kamp.",
	"func_getAliveAllies_return": "En rekke som inneholder IDene til alle dine levende allierte.",
	"func_getAliveEnemies": "Returnerer en rekke av alle fiendene dine i live i kamp.",
	"func_getAliveEnemiesCount": "Returnerer antall fiender i live i kampen.",
	"func_getAliveEnemiesCount_return": "Antallet levende fiender.",
	"func_getAliveEnemies_return": "En rekke som inneholder IDene til alle dine levende fiender.",
	"func_getAllChips": "Returnerer listen over alle sjetongene i spillet.",
	"func_getAllChips_return": "Listen over alle sjetongene i spillet.",
	"func_getAllEffects": "Returnerer listen over alle effekter i spillet.",
	"func_getAllEffects_return": "Listen over alle effekter i spillet.",
	"func_getAllWeapons": "Returnerer listen over alle våpen i spillet.",
	"func_getAllWeapons_return": "Listen over alle våpen i spillet.",
	"func_getAlliedTurret": "Returnerer lagets turret-ID eller -1 hvis den ikke eksisterer.",
	"func_getAlliedTurret_return": "Teamets tårn-ID.",
	"func_getEnemyTurret": "Returnerer fiendens turret-ID eller -1 hvis den ikke eksisterer.",
	"func_getEnemyTurret_return": "Fiendens tårn-ID.",
	"func_getAllies": "Returnerer en matrise som inneholder dine allierte og din enhet.",
	"func_getAlliesCount": "Returnerer antall allierte i kampen.",
	"func_getAlliesCount_return": "Antall allierte.",
	"func_getAlliesLife": "Returnerer den totale helsen til dine allierte.",
	"func_getAlliesLife_return": "Den totale helsen til dine allierte.",
	"func_getAllies_return": "Tabellen over allierte og din enhet.",
	"func_getBirthTurn": "Returnerer kampens tur der <b>enheten</b> dukket opp. Returnerer 1 hvis det for eksempel er en purre, og 5 hvis det er en innkalling påberopt på tur 5.",
	"func_getBirthTurn_arg_1": "ID-en til enheten hvis spawn-tur vil bli returnert.",
	"func_getBirthTurn_return": "Kampsvingen der <b>entity</b> dukket opp.",
	"func_getBlue": "Returnerer mengden blått i fargen <b>farge</b>, mellom 0 og 255. For eksempel, getBlue(#COLOR_BLUE) = 255 og getBlue(#COLOR_GREEN) = 0.",
	"func_getBlue_arg_1": "Fargen hvis blåpris vil bli returnert.",
	"func_getBlue_return": "Frekvensen av blått i fargen <b>farge</b>",
	"func_getBulbChips": "Returnerer listen over sjetonger som vil være utstyrt på en pære tilkalt av <b>bulb_chip</b>-brikken.",
	"func_getBulbChips_arg_1": "Løkeloppen.",
	"func_getBulbChips_return": "Brikkene til en pære tilkalt av <b>bulb_chip</b>-brikken.",
	"func_getCell": "Returnerer cellen der enheten med id <b>entity</b> er plassert. Bruk getCell() uten parametere for å hente cellen din.",
	"func_getCell_arg_1": "ID-en til enheten hvis celle skal returneres.",
	"func_getCell_return": "Cellenummeret der <b>enheten</b> er plassert.",
	"func_getCellContent": "Returnerer innholdet i en celle med id <b>celle</b>.",
	"func_getCellContent_arg_1": "ID-en til cellen hvis innhold vil bli returnert.",
	"func_getCellContent_return": "Innholdet i <b>cellen</b>-cellen: #CELL_EMPTY for en tom celle, #CELL_ENTITY for en enhet, #CELL_OBSTACLE for en hindring.",
	"func_getCellDistance": "Returnerer avstanden mellom to celler <b>celle1</b> og <b>celle2</b>. <br />Den returnerte avstanden er uttrykt i antall celler, og tar ikke hensyn til de ulike hindringene mellom de to cellene.\nFor å få avstanden i luftlinje, se #getDistance og for å få baneavstanden mellom de to cellene for å unngå hindringer, se #getPathLength.",
	"func_getCellDistance_arg_1": "IDen til startcellen.",
	"func_getCellDistance_arg_2": "ID-en til ankomstcellen.",
	"func_getCellDistance_return": "Avstanden mellom de to cellene <b>celle1</b> og <b>celle2</b>.",
	"func_getCellFromXY": "Returnerer ID-en til cellen ved posisjon (<b>x</b>, <b>y</b>).",
	"func_getCellFromXY_arg_1": "X-posisjonen til cellen.",
	"func_getCellFromXY_arg_2": "Y-posisjonen til cellen.",
	"func_getCellFromXY_return": "Celle-ID-en ved posisjon (<b>x</b>, <b>y</b>), <b>null</b> hvis cellen ikke eksisterer.",
	"func_getCellsToUseChip": "Returnerer listen over celler der enheten din vil kunne bruke <b>brikken</b>-brikken på <b>entiteten</b>-enheten.",
	"func_getCellsToUseChip_arg_1": "Brikken som enheten ønsker å kunne bruke.",
	"func_getCellsToUseChip_arg_2": "Målenheten.",
	"func_getCellsToUseChip_arg_3": "En rekke celler å ignorere.",
	"func_getCellsToUseChip_return": "Liste over celler hvor brikken kan brukes.",
	"func_getCellsToUseChipOnCell": "Returnerer listen over celler som enheten din vil kunne bruke <b>brikken</b> fra på <b>cellen</b>-cellen.",
	"func_getCellsToUseChipOnCell_arg_1": "Brikken som enheten ønsker å kunne bruke.",
	"func_getCellsToUseChipOnCell_arg_2": "Målcellen.",
	"func_getCellsToUseChipOnCell_arg_3": "En rekke celler å ignorere.",
	"func_getCellsToUseChipOnCell_return": "Liste over celler hvor brikken kan brukes.",
	"func_getCellsToUseWeapon": "Returnerer listen over celler som enheten din vil kunne bruke våpenet <b>våpenet</b> fra på enheten <b>entiteten</b>.",
	"func_getCellsToUseWeapon_arg_1": "Våpenet å teste. Standard til ditt for øyeblikket utstyrte våpen.",
	"func_getCellsToUseWeapon_arg_2": "Målenheten.",
	"func_getCellsToUseWeapon_arg_3": "En rekke celler å ignorere. Som standard en tom matrise.",
	"func_getCellsToUseWeapon_return": "Liste over celler som våpenet kan brukes fra.",
	"func_getCellsToUseWeaponOnCell": "Returnerer listen over celler som enheten din vil kunne bruke <b>våpen</b>-våpenet fra på en <b>celle</b>-celle.",
	"func_getCellsToUseWeaponOnCell_arg_1": "Våpenet å teste. Standard til ditt for øyeblikket utstyrte våpen.",
	"func_getCellsToUseWeaponOnCell_arg_2": "Målcellen.",
	"func_getCellsToUseWeaponOnCell_arg_3": "En rekke celler å ignorere. Som standard en tom matrise.",
	"func_getCellsToUseWeaponOnCell_return": "Liste over celler som våpenet kan brukes fra.",
	"func_getCellX": "Bestemmer posisjonen i X av cellen <b>cellen</b>.",
	"func_getCellX_arg_1": "Cellen hvis posisjon i X vil bli bestemt.",
	"func_getCellX_return": "X-posisjonen til cellen.",
	"func_getCellY": "Bestemmer Y-posisjonen til cellen <b>cellen</b>.",
	"func_getCellY_arg_1": "Cellen hvis posisjon i Y vil bli bestemt.",
	"func_getCellY_return": "Y-posisjonen til cellen.",
	"func_getChipArea": "Returnerer effektområdet til <b>brikken</b>.",
	"func_getChipArea_arg_1": "Brikken hvis sonetype vil bli returnert.",
	"func_getChipArea_return": "Arealtypen til brikken <b>brikken</b> blant AREA_* konstantene:\n<ul>\n<li>#AREA_POINT: enkelt kvadratisk område</li>\n<li>#AREA_LASER_LINE: linje på en laser</li>\n<li>#AREA_CIRCLE_1: sirkulært område med 3 kvadrater i diameter</li>\n<li>#AREA_CIRCLE_2: sirkulært område med 5 kvadrater i diameter</li>\n<li>#AREA_CIRCLE_3: sirkulært område med 7 kvadrater i diameter</li>\n</ul>",
	"func_getChipCooldown": "Returnerer gjenopprettingstiden for <b>brikke</b>, tatt fra markedet.",
	"func_getChipCooldown_arg_1": "Brikken hvis nedkjøling vil bli returnert.",
	"func_getChipCooldown_return": "Nedkjølingen av <b>brikken</b>.",
	"func_getChipCost": "Returnerer kostnaden i TP for <b>brikken</b>.",
	"func_getChipCost_arg_1": "Brikken som koster vil bli returnert.",
	"func_getChipCost_return": "Kostnaden for <b>brikken</b>.",
	"func_getChipEffectiveArea": "Returnerer listen over celler som vil bli påvirket hvis <b>brikken</b>-brikken brukes på <b>celle</b>-cellen fra en <b>fra</b>-celle.",
	"func_getChipEffectiveArea_arg_1": "Brikken som skal testes.",
	"func_getChipEffectiveArea_arg_2": "Målcellen.",
	"func_getChipEffectiveArea_arg_3": "Cellen som brikken brukes fra.",
	"func_getChipEffectiveArea_return": "Matrisen som inneholder IDene til alle cellene som vil bli påvirket.",
	"func_getChipEffects": "Returnerer effekten av <b>brikken</b>-brikken.",
	"func_getChipEffects_arg_1": "Brikken hvis effekter vil bli returnert.",
	"func_getChipEffects_return": "Effektene av <b>brikken</b>-brikken. Samme returverdi som for #getWeaponEffects-funksjonen.",
	"func_getChipFailure": "Returnerer den prosentvise risikoen for feil på <b>brikken</b>.",
	"func_getChipFailure_arg_1": "Brikken hvis feilprosent vil bli returnert.",
	"func_getChipFailure_return": "<b>brikke</b> brikkefeilprosent, et heltall mellom <b>0</b> og <b>100</b>.",
	"func_getChipMaxRange": "Returnerer maksimumsområdet til <b>brikken</b>-brikken.",
	"func_getChipMaxRange_arg_1": "Brikken hvis maksimale rekkevidde vil bli returnert.",
	"func_getChipMaxRange_return": "Maksimal rekkevidde for <b>brikken</b>.",
	"func_getChipMaxScope": "Returnerer maksimumsområdet til <b>brikken</b>-brikken.",
	"func_getChipMaxScope_arg_1": "Brikken hvis maksimale rekkevidde vil bli returnert.",
	"func_getChipMaxScope_return": "Maksimal rekkevidde for <b>brikken</b>.",
	"func_getChipMinRange": "Returnerer minimumsområdet til <b>brikken</b>-brikken.",
	"func_getChipMinRange_arg_1": "Brikken hvis minimumsområde vil bli returnert.",
	"func_getChipMinRange_return": "Minimumsområdet for <b>brikken</b>.",
	"func_getChipMinScope": "Returnerer minimumsområdet til <b>brikken</b>-brikken.",
	"func_getChipMinScope_arg_1": "Brikken hvis minimumsområde vil bli returnert.",
	"func_getChipMinScope_return": "Minimumsområdet for <b>brikken</b>.",
	"func_getChipLaunchType": "Returnerer lanseringsmodusen til <b>brikken</b>-brikken, blant LAUNCH_TYPE_*-konstantene.",
	"func_getChipLaunchType_arg_1": "IDen til brikken hvis kastemodus vil bli returnert.",
	"func_getChipLaunchType_return": "Kastmodusen til <b>chip</b>-brikken.",
	"func_getChipName": "Returnerer navnet på brikken <b>brikke</b>.",
	"func_getChipName_arg_1": "Brikken hvis navn vil bli returnert.",
	"func_getChipName_return": "Navnet på <b>brikken</b>-brikken.",
	"func_getChips": "Returnerer punktene til enheten med id <b>entity</b>.",
	"func_getChips_arg_1": "ID-en til enheten hvis kuler vil bli returnert.",
	"func_getChips_return": "En matrise som inneholder punkt-ID-ene til enheten <b>entity</b>.",
	"func_getChipTargets": "Returnerer enhetene som vil bli påvirket hvis <b>brikke</b>-brikken brukes på celle <b>celle</b>.",
	"func_getChipTargets_arg_1": "Brikken som skal testes.",
	"func_getChipTargets_arg_2": "Målcellen.",
	"func_getChipTargets_return": "Matrisen som inneholder IDene til alle enhetene som vil bli berørt.",
	"func_getColor": "Returnerer heltallet som tilsvarer fargen (<b>rød</b>, <b>grønn</b>, <b>blå</b>) oppgitt som parametere.",
	"func_getColor_arg_1": "Rød verdi mellom 0 og 255.",
	"func_getColor_arg_2": "Grønn verdi mellom 0 og 255.",
	"func_getColor_arg_3": "Blå verdi mellom 0 og 255.",
	"func_getColor_return": "int som tilsvarer fargen oppgitt som parameter.",
	"func_getCooldown": "Returnerer gjeldende <b>brikke</b>-nedkjøling av <b>entitet</b>-enheten.",
	"func_getCooldown_arg_1": "Brikken hvis nåværende nedkjøling vil bli returnert.",
	"func_getCooldown_arg_2": "Enheten hvis nedkjøling vil bli returnert.",
	"func_getCooldown_return": "Gjeldende nedkjøling av brikken <b>brikken</b>, dette er antall omdreininger før brikken blir brukbar, <b>0</b> hvis den er brukbar for øyeblikket.",
	"func_getCores": "Returnerer antall kjerner til enheten med id <b>entity</b>.",
	"func_getCores_arg_1": "Enheten hvis antall kjerner vil bli returnert.",
	"func_getCores_return": "Antall kjerner til <b>entity</b>-enheten.",
	"func_getDate": "Returnerer datoen for kampen, i formatet dd/MM/åååå.",
	"func_getDate_return": "Datoen for kampen.",
	"func_getTime": "Returnerer kamptiden, i TT:mm:ss-format.",
	"func_getTime_return": "Tidspunktet for kampen.",
	"func_getTimestamp": "Returnerer kampens tidsstempel, lik antall sekunder siden 1. januar 1970.",
	"func_getTimestamp_return": "Kampens tidsstempel.",
	"func_getDamageReturn": "Returnerer skadereturraten for enheten med id <b>entity</b>.",
	"func_getDamageReturn_arg_1": "ID-en til enheten som skadehenvisningen vil bli returnert fra.",
	"func_getDamageReturn_return": "Skadereturgraden til enheten med id <b>entity</b> (i %).",
	"func_getDeadAllies": "Returnerer døde vennlige enheter.",
	"func_getDeadAllies_return": "Tabellen over allierte døde enheter.",
	"func_getDeadEnemies": "Returnerer døde fiendtlige enheter.",
	"func_getDeadEnemiesCount": "Returnerer antall fiender som døde i kamp.",
	"func_getDeadEnemiesCount_return": "Antall døde fiender.",
	"func_getDeadEnemies_return": "Tabellen over døde fiendens enheter.",
	"func_getDistance": "Beregner luftlinjeavstanden mellom to celler <b>celle1</b> og <b>celle2</b>. <br />For å få avstanden i antall celler, se #getCellDistance, og for å få lengden på banen mellom de to cellene som unngår de forskjellige hindringene, se #getPathLength.",
	"func_getDistance_arg_1": "Startcellen.",
	"func_getDistance_arg_2": "Ankomstcellen.",
	"func_getDistance_return": "Avstanden i luftlinje mellom de to cellene.",
	"func_getPassiveEffects": "Returnerer listen over passive effekter av enheten med id <b>entity</b>. For å hente listen over passive effekter av enheten din direkte, bruk #getPassiveEffects() uten parametere.",
	"func_getPassiveEffects_arg_1": "ID-en til enheten hvis liste over passive effekter vil bli returnert.",
	"func_getPassiveEffects_return": "Listen over passive effekter som for øyeblikket er tilstede på <b>entity</b>-enheten.\nListen over passive effekter er en matrise som inneholder effektene.\nEn effekt er i seg selv en rekke med 7 bokser av formen: [<b>type</b>, <b>verdi</b>, <b>caster_id</b>, <b>turns</b >, <b>kritisk</b>, <b>item_id</b>, <b>target_id</b>]. Det er den samme strukturen som de klassiske effektene som returneres av #getEffects-funksjonen.",
	"func_getEnemies": "Returnerer fiendtlige enheter (levende eller døde) inn i kamp.",
	"func_getEnemiesCount": "Returnerer antall fiender i kampen.",
	"func_getEnemiesCount_return": "Antall fiender.",
	"func_getEnemiesLife": "Beregner summen av treffpoeng for alle fiendtlige enheter.",
	"func_getEnemiesLife_return": "Summen av treffpoengene til fiendens lag.",
	"func_getEnemies_return": "En matrise som inneholder IDene til alle fiendtlige enheter.",
	"func_getEntityTurnOrder": "Returnerer en verdi mellom 1 og n (antall enheter som er i spill) som indikerer posisjonen til <b>enheten</b> i avspillingsrekkefølge.",
	"func_getEntityTurnOrder_arg_1": "ID-en til enheten hvis spillerekkefølge vil bli returnert",
	"func_getEntityTurnOrder_return": "Plasser i rekkefølgen til enheten <b>entiteten</b>",
	"func_getFarmerID": "Returnerer oppdretter-ID-en til enheten <b>entitet</b>.",
	"func_getFarmerID_arg_1": "ID-en til enheten hvis oppdretter-ID vil bli returnert.",
	"func_getFarmerID_return": "ID-en til <b>entity</b>-enhetsoppdretteren.",
	"func_getFarmerCountry": "Returnerer landet til oppdretteren av <b>enheten</b>.",
	"func_getFarmerCountry_arg_1": "ID-en til enheten hvis oppdretterland vil bli returnert.",
	"func_getFarmerCountry_return": "Landet til <b>enheten</b> enhetens oppdretter, eller \"?\" hvis ikke angitt.",
	"func_getFarmerName": "Returnerer oppdretternavnet til <b>enheten</b>-enheten.",
	"func_getFarmerName_arg_1": "ID-en til enheten hvis oppdretternavn vil bli returnert.",
	"func_getFarmerName_return": "Navnet på oppdretteren av <b>enheten</b>-enheten.",
	"func_getFarthestAlly": "Bestemmer den lengste allierte til enheten din i luftlinje.",
	"func_getFarthestAlly_return": "ID-en til den mest vennlige enheten.",
	"func_getFarthestEnemy": "Bestemmer fienden lengst unna enheten din, i luftlinje.",
	"func_getFarthestEnemy_return": "ID-en til den fiendtlige enheten lengst borte.",
	"func_getFightID": "Returnerer gjeldende kamp-ID.",
	"func_getFightID_return": "IDen til den nåværende kampen.",
	"func_getFightContext": "Returnerer konteksten til den gjeldende kampen.",
	"func_getFightContext_return": "Avhengig av kampsammenheng: Test Battle (#FIGHT_CONTEXT_TEST), Arena Battle (#FIGHT_CONTEXT_GARDEN), Tournament Battle (#FIGHT_CONTEXT_TOURNAMENT), Challenge Battle (#FIGHT_CONTEXT_CHALLENGE), Battle Royale (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "Returnerer gjeldende kamptype.",
	"func_getFightType_return": "Avhengig av type kamp: Solokamp (#FIGHT_TYPE_SOLO), Bondekamp (#FIGHT_TYPE_FARMER), lagkamp (#FIGHT_TYPE_TEAM), Battle Royale (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "Returnerer styrken til enheten med id <b>entity</b>.",
	"func_getForce_arg_1": "Enheten hvis styrke vil bli returnert.",
	"func_getForce_return": "Styrken til <b>entity</b>-enheten.",
	"func_getFrequency": "Returnerer frekvensen til enheten med id <b>entity</b>. Bruk getFrequency() uten parametere for å hente frekvensen din.",
	"func_getFrequency_arg_1": "Enheten hvis frekvens vil bli returnert.",
	"func_getFrequency_return": "Frekvensen til <b>entitet</b>-enheten.",
	"func_getGreen": "Returnerer mengden grønt i fargen <b>farge</b>, mellom 0 og 255. For eksempel, getGreen(#COLOR_GREEN) = 255 og getGreen(#COLOR_RED) = 0.",
	"func_getGreen_arg_1": "Fargen hvis grønne sats vil bli returnert.",
	"func_getGreen_return": "Frekvensen for grønt i fargen <b>farge</b>",
	"func_getInstructionsCount": "Returnerer antall instruksjoner enheten din har utført i løpet av gjeldende sving.",
	"func_getInstructionsCount_return": "Antall instruksjoner enheten din har utført i løpet av den gjeldende svingen.",
	"func_getLaunchedEffects": "Returnerer listen over effekter forårsaket av enheten med id <b>entity</b>.",
	"func_getLaunchedEffects_arg_1": "ID-en til enheten hvis liste over forårsakede effekter vil bli returnert.",
	"func_getLaunchedEffects_return": "Listen over effekter forårsaket av enheten med id <b>entity</b>, av samme form som matrisen returnert av #getEffects.",
	"func_getLeek": "Returnerer ID-en til enheten din.",
	"func_getLeek_return": "ID-en til enheten din.",
	"func_getEntity": "Returnerer ID-en til enheten din.",
	"func_getEntity_return": "ID-en til enheten din.",
	"func_getLeekID": "Returnerer den virkelige ID-en til purren til ID <b>leek</b>.",
	"func_getLeekID_arg_1": "ID-en til purren hvis faktiske ID vil bli returnert.",
	"func_getLeekID_return": "Den virkelige ID-en til purre-<b>enheten</b>.",
	"func_getLeekOnCell": "Returnerer enheten som er på celle <b>celle</b>.",
	"func_getLeekOnCell_arg_1": "Cellen hvis enhet vi ønsker å hente.",
	"func_getLeekOnCell_return": "ID-en til enheten på cellen, eller -1 hvis cellen ikke har noen enhet.",
	"func_getEntityOnCell": "Returnerer enheten som er på celle <b>celle</b>.",
	"func_getEntityOnCell_arg_1": "Cellen hvis enhet vi ønsker å hente.",
	"func_getEntityOnCell_return": "ID-en til enheten på cellen, eller -1 hvis cellen ikke har noen enhet.",
	"func_getLevel": "Returnerer enhetsnivået med id <b>entity</b>.",
	"func_getLevel_arg_1": "ID-en til enheten hvis nivå vil bli returnert.",
	"func_getLevel_return": "Nivået til enheten med id <b>entity</b>.",
	"func_getLife": "Returnerer gjeldende levetid for enheten med id <b>entity</b>. Bruk getLife() uten parametere for å hente livet ditt.",
	"func_getLife_arg_1": "ID-en til enheten hvis liv vil bli returnert.",
	"func_getLife_return": "Den nåværende levetiden til <b>enheten</b>-enheten.",
	"func_getMagic": "Returnerer magien til enheten med id <b>entity</b>. Bruk getMagic() uten parametere for å hente magien din.",
	"func_getMagic_arg_1": "ID-en til enheten hvis magi vil bli returnert.",
	"func_getMagic_return": "Magien til id-enhet <b>enhet</b>.",
	"func_getMapType": "Returnerer terrengtypen som kampen finner sted på (fabrikk, ørken, skog osv.), blant konstantene #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER og #MAP_BEACH.",
	"func_getMapType_return": "Type terreng.",
	"func_getMessageAuthor": "Returnerer ID-en til enheten som skriver meldingen <b>meldingen</b>.",
	"func_getMessageAuthor_arg_1": "Meldingen hvis forfatter vil bli returnert.",
	"func_getMessageAuthor_return": "ID-en til enheten som skriver meldingen <b>meldingen</b>.",
	"func_getMessageParams": "Returnerer utvalget av meldingsparametere <b>melding</b>.",
	"func_getMessageParams_arg_1": "Meldingen hvis parametere vil bli returnert.",
	"func_getMessageParams_return": "Meldingsparametere for <b>melding</b>.",
	"func_getMessages": "Returnerer <b>entity</b>-enhetsmeldingsmatrisen.",
	"func_getMessages_arg_1": "Enheten hvis meldinger vil bli returnert.",
	"func_getMessages_return": "Tabellen over meldingene dine.<br>En melding er i seg selv representert i form av en\narray av skjemaet: [<b>author</b>, <b>type</b>, <b>parameters</b>]<br>\nDe forskjellige typene meldinger er representert av konstantene:\n<ul>\n<li>#MESSAGE_HEAL: forespørsel om omsorg</li>\n<li>#MESSAGE_ATTACK: forespørsel om å angripe</li>\n<li>#MESSAGE_BUFF_FORCE: tvinge forespørsel om boost</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "Returnerer meldingstypen <b>melding</b>.",
	"func_getMessageType_arg_1": "Meldingen hvis type vil bli returnert (blant #MESSAGE_HEAL, #MESSAGE_ATTACK osv.).",
	"func_getMessageType_return": "Meldingstypen <b>melding</b>.",
	"func_getMP": "Returnerer gjeldende bevegelsespunkttelling for <b>entitet</b>-enheten. Bruk getMP() uten parametere for å hente PM-ene dine.",
	"func_getMP_arg_1": "ID-en til enheten hvis PM-antall vil bli returnert.",
	"func_getMP_return": "<b>Entity</b>-enhetens MP-nummer.",
	"func_getName": "Returnerer navnet på enheten med id <b>entity</b>.",
	"func_getName_arg_1": "ID-en til enheten hvis navn vil bli returnert.",
	"func_getName_return": "Enhetsnavnet <b>enhet</b>.",
	"func_getNearestAlly": "Returnerer den nærmeste vennlige enheten til enheten din.",
	"func_getNearestAlly_return": "ID-en til nærmeste vennlige enhet.",
	"func_getNearestAllyTo": "Returnerer den nærmeste allierte enheten til enheten oppgitt som en parameter.",
	"func_getNearestAllyTo_arg_1": "ID-en til enheten hvis nærmeste allierte vi ønsker å vite.",
	"func_getNearestAllyToCell": "Returnerer den nærmeste allierte enheten til cellen oppgitt som parameter.",
	"func_getNearestAllyToCell_arg_1": "ID-en til cellen hvis nærmeste allierte vi ønsker å vite.",
	"func_getNearestAllyToCell_return": "ID-en til nærmeste vennlige enhet.",
	"func_getNearestAllyTo_return": "ID-en til nærmeste vennlige enhet.",
	"func_getNearestEnemy": "Returnerer den nærmeste fiendeenheten til enheten din.",
	"func_getNearestEnemy_return": "ID-en til nærmeste fiendeenhet.",
	"func_getNearestEnemyTo": "Returnerer den nærmeste fiendens enhet til enheten som er oppgitt som en parameter.",
	"func_getNearestEnemyTo_arg_1": "ID-en til enheten hvis nærmeste fiende vi ønsker å vite.",
	"func_getNearestEnemyToCell": "Returnerer den nærmeste fiendens enhet til cellen oppgitt som parameter.",
	"func_getNearestEnemyToCell_arg_1": "ID-en til cellen hvis nærmeste fiende vi ønsker å vite.",
	"func_getNearestEnemyToCell_return": "ID-en til nærmeste fiendeenhet.",
	"func_getNearestEnemyTo_return": "ID-en til nærmeste fiendeenhet.",
	"func_getNextPlayer": "Returnerer ID-en til enheten som skal spille etter gjeldende spiller.",
	"func_getNextPlayer_return": "Den neste spilleren.",
	"func_getObstacles": "Returnerer listen over hinderplasser i terrenget.",
	"func_getObstacles_return": "Matrisen som inneholder hindringscelle-ID-ene.",
	"func_getOperations": "Returnerer antall operasjoner som er forbrukt av enheten din siden starten av sin tur. Dette tallet må være lavere enn #OPERATIONS_LIMIT slik at enheten ikke krasjer.",
	"func_getOperations_return": "Antall operasjoner forbrukt av enheten din siden starten av sin tur.",
	"func_getPath": "Returnerer banen for å unngå hindringer mellom to celler <b>celle1</b> og <b>celle2</b>, hvis den finnes, og ignorerer cellene i matrisen <b>ignoredCells</b> . Hvis en spiller er på en ignorert celle, kan banen gå over dem.<br /><br />\nStartcellen <b>celle1</b> er aldri en del av den resulterende banen. Celle <b>celle2</b> er en del av den resulterende banen hvis og bare hvis den er tom eller ignorert av <b>ignoredCells</b>.<br /><br />\nHvis det ikke finnes noen bane mellom de to cellene, returnerer <b>getPath</b> <i>null</i>.",
	"func_getPath_arg_1": "Startcellen.",
	"func_getPath_arg_2": "Ankomstcellen.",
	"func_getPath_arg_3": "En rekke celler å ignorere.",
	"func_getPath_return": "Matrisen som inneholder cellene som utgjør banen mellom de to cellene.",
	"func_getPathLength": "Returnerer lengden på banen mellom to celler <b>celle1</b> og <b>celle2</b>, unngår hindringer, ignorerer cellene i arrayen <b>ignoredCells</b>. Denne funksjonen tilsvarer <i>count(getPath(<b>celle1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>.\nHvis en spiller er på en ignorert celle, kan banen gå over dem.<br /><br />\nStartcellen <b>celle1</b> telles aldri med i resultatet. Celle <b>celle2</b> telles i resultatet hvis og bare hvis den er tom eller ignorert av <b>ignoredCells</b>.<br /><br />\nHvis det ikke finnes noen bane mellom de to cellene, returnerer <b>getPathLength</b> <i>null</i>.",
	"func_getPathLength_arg_1": "Startcellen.",
	"func_getPathLength_arg_2": "Ankomstcellen.",
	"func_getPathLength_arg_3": "En rekke celler å ignorere.",
	"func_getPathLength_return": "Lengden på banen mellom <b>celle1</b> og <b>celle2</b>.",
	"func_getPreviousPlayer": "Returnerer ID-en til enheten som spilte før gjeldende spiller.",
	"func_getPreviousPlayer_return": "Den forrige spilleren.",
	"func_getRed": "Returnerer mengden rødt i fargen <b>farge</b>, mellom 0 og 255. For eksempel, getRed(#COLOR_RED) = 255 og getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "Fargen hvis røde rate vil bli returnert.",
	"func_getRed_return": "Frekvensen for rødt i fargen <b>farge</b>",
	"func_getRegister": "Returnerer verdien som er lagret i enhetsregisteret knyttet til nøkkel <b>nøkkel</b> eller </i>null</i> hvis registret ikke eksisterer.",
	"func_getRegister_arg_1": "Registernøkkelen hvis verdi vil bli returnert.",
	"func_getRegister_return": "Verdien som er lagret i <b>nøkkel</b>-registeret.",
	"func_getRegisters": "Returnerer settet med enhetsregistre som en assosiativ matrise [<i>registernøkkel</i>:<i>registerverdi</i>]. Eksempel: <kode>debug(getRegisters());\n// Viser for eksempel:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "Den assosiative matrisen som tilsvarer alle registrene til enheten.",
	"func_getRelativeShield": "Returnerer det relative skjoldet til enheten med id <b>entity</b>. For å hente enhetens relative skjold direkte, bruk #getRelativeShield() uten parametere.",
	"func_getRelativeShield_arg_1": "ID-en til enheten hvis relative skjold vil bli returnert.",
	"func_getRelativeShield_return": "Det relative skjoldet til <b>enheten</b>, et heltall mellom <b>0</b> og <b>100</b>.",
	"func_getResistance": "Returnerer motstanden til enheten med id <b>entity</b>. Bruk getResistance() uten parametere for å hente motstanden din.",
	"func_getResistance_arg_1": "ID-en til enheten hvis motstand vil bli returnert.",
	"func_getResistance_return": "Motstanden til enheten med id <b>entity</b>.",
	"func_getScience": "Returnerer vitenskapen om enheten med id <b>entity</b>. Bruk getScience() uten parametere for å hente vitenskapen din.",
	"func_getScience_arg_1": "ID-en til enheten hvis vitenskap vil bli returnert.",
	"func_getScience_return": "Vitenskapen om id-enhet <b>enhet</b>.",
	"func_getStrength": "Returnerer styrken til enheten med id <b>entity</b>. Bruk getStrength() uten parametere for å hente styrken din.",
	"func_getStrength_arg_1": "Enheten hvis styrke vil bli returnert.",
	"func_getStrength_return": "Styrken til <b>entity</b>-enheten.",
	"func_getPower": "Returnerer potensen til enheten med id <b>entity</b>.",
	"func_getPower_arg_1": "Enheten hvis makt vil bli returnert.",
	"func_getPower_return": "Kraften til <b>enhet</b>.",
	"func_getSummoner": "Returnerer enheten som påkalte enheten <b>entiteten</b>, hvis det er en påkalling.",
	"func_getSummoner_arg_1": "ID-en til enheten hvis innkaller vil bli sparket.",
	"func_getSummoner_return": "ID-en til enheten påkalte <b>enheten</b> hvis det er en påkalling, <i>null</i> ellers.",
	"func_getSummons": "Returnerer listen over IDer for gjeldende aktiveringer av enheten med ID <b>entity</b>.",
	"func_getSummons_arg_1": "ID-en til enheten hvis innkalling vil bli returnert.",
	"func_getSummons_return": "Listen over påkallings-ID-er for enheten med ID-en <b>entity</b>.",
	"func_getTeamID": "Returnerer team-ID-en til enheten <b>enhet</b>.",
	"func_getTeamID_arg_1": "ID-en til enheten hvis team-ID vil bli returnert.",
	"func_getTeamID_return": "Team-ID-en til enheten <b>enhet</b>.",
	"func_getTeamName": "Returnerer teamnavnet til enheten <b>enhet</b>.",
	"func_getTeamName_arg_1": "ID-en til enheten hvis teamnavn vil bli returnert.",
	"func_getTeamName_return": "Teamnavnet til <b>enheten</b>-enheten.",
	"func_getTotalLife": "Returnerer den totale levetiden til enheten med id <b>entity</b>. Bruk getTotalLife() uten parametere for å hente ditt totale liv.",
	"func_getTotalLife_arg_1": "ID-en til enheten hvis totale levetid vil bli returnert.",
	"func_getTotalLife_return": "Den totale levetiden til enheten.",
	"func_getTotalMP": "Returnerer det maksimale antallet bevegelsespunkter for enheten <b>entiteten</b>.",
	"func_getTotalMP_arg_1": "ID-en til enheten hvis maksimale bevegelsespoeng vil bli returnert.",
	"func_getTotalMP_return": "Maksimalt antall bevegelsespunkter for <b>enheten</b>.",
	"func_getTotalTP": "Returnerer det maksimale antallet vendepunkter for enheten <b>enhet</b>.",
	"func_getTotalTP_arg_1": "ID-en til enheten hvis maksimale antall vendepunkter vil bli returnert.",
	"func_getTotalTP_return": "Maksimalt antall vendepunkter for <b>entitet</b>-enheten.",
	"func_getTP": "Returnerer antall vendepunkter for enheten <b>enhet</b>. Bruk getTP() uten parametere for å hente PT-ene dine.",
	"func_getTP_arg_1": "ID-en til enheten hvis PT-er vil bli returnert.",
	"func_getTP_return": "PT-nummeret til <b>enheten</b>-enheten.",
	"func_getTurn": "Returnerer gjeldende kampsving. Maksimalt antall svinger er #MAX_TURNS.",
	"func_getTurn_return": "Den nåværende kampturen.",
	"func_getType": "Returnerer enhetstypen for enhet <b>enhet</b>.",
	"func_getType_arg_1": "ID-en til enheten hvis type vil bli returnert.",
	"func_getType_return": "Entitetstypen <b>entitet</b>:<ul><li>#ENTITY_LEEK hvis det er en purre.</li><li>#ENTITY_BULB hvis det er en 'a bulb.</li><li >#ENTITY_TURRET hvis det er et tårn.</li></ul>",
	"func_getWeapon": "Returnerer den for øyeblikket utstyrte våpen-<b>enheten</b>.",
	"func_getWeapon_arg_1": "ID-en til enheten hvis nåværende våpen vil bli returnert.",
	"func_getWeapon_return": "ID-en til det for øyeblikket utstyrte våpenet på <b>enheten</b>, null hvis enheten ikke har noe utstyrt våpen eller enheten ikke eksisterer.",
	"func_getWeaponArea": "Returnerer effektområdet til <b>våpenet</b>-våpenet.",
	"func_getWeaponArea_arg_1": "Våpenet hvis områdetype vil bli returnert.",
	"func_getWeaponArea_return": "<b>våpen</b>-våpenets områdetype blant AREA_*-konstantene:\n<ul>\n<li>#AREA_POINT: enkelt kvadratisk område</li>\n<li>#AREA_LASER_LINE: linje på en laser</li>\n<li>#AREA_CIRCLE_1: sirkulært område med 3 celler i diameter</li>\n<li>#AREA_CIRCLE_2: sirkulært område med 5 kvadrater i diameter</li>\n<li>#AREA_CIRCLE_3: sirkulært område med 7 kvadrater i diameter</li>\n</ul>",
	"func_getWeaponCost": "Returnerer TP-kostnaden for <b>våpenet</b>-våpenet.",
	"func_getWeaponCost_arg_1": "ID-en til våpenet hvis kostnad vil bli returnert.",
	"func_getWeaponCost_return": "TP-kostnaden for <b>våpen</b>-våpenet.",
	"func_getWeaponEffectiveArea": "Returnerer listen over celler som vil bli påvirket hvis våpenet <b>våpenet</b> brukes på celle <b>celle</b> fra celle <b>fra</b>.",
	"func_getWeaponEffectiveArea_arg_1": "Våpenet å teste.",
	"func_getWeaponEffectiveArea_arg_2": "Målcellen.",
	"func_getWeaponEffectiveArea_arg_3": "Cellen som våpenet avfyres fra.",
	"func_getWeaponEffectiveArea_return": "Matrisen som inneholder IDene til alle cellene som vil bli påvirket.",
	"func_getWeaponPassiveEffects": "Returnerer de passive effektene av våpenet <b>våpenet</b>.",
	"func_getWeaponPassiveEffects_arg_1": "ID-en til våpenet hvis passive effekter vil bli returnert.",
	"func_getWeaponPassiveEffects_return": "En matrise som inneholder effektene av <b>våpenet</b>-våpenet. Hver effekt er i seg selv en rekke av formen\n[type, min, maks, svinger, mål, modifikatorer]. Disse effektene er de samme som returneres av #getWeaponEffects.",
	"func_getWeaponFailure": "Returnerer prosentandelen for <b>våpen</b> sjanse for våpenfeil.",
	"func_getWeaponFailure_arg_1": "ID-en til våpenet hvis feilprosent vil bli returnert.",
	"func_getWeaponFailure_return": "<b>våpen</b> våpenmissprosent, et heltall mellom <b>0</b> og <b>100</b>.",
	"func_getWeaponMaxRange": "Returnerer maksimal rekkevidde for <b>våpen</b>-våpenet.",
	"func_getWeaponMaxRange_arg_1": "ID-en til våpenet hvis maksimale rekkevidde vil bli returnert.",
	"func_getWeaponMaxRange_return": "Maksimal rekkevidde for <b>våpen</b>-våpenet.",
	"func_getWeaponMaxScope": "Returnerer maksimal rekkevidde for <b>våpen</b>-våpenet.",
	"func_getWeaponMaxScope_arg_1": "ID-en til våpenet hvis maksimale rekkevidde vil bli returnert.",
	"func_getWeaponMaxScope_return": "Maksimal rekkevidde for <b>våpen</b>-våpenet.",
	"func_getWeaponMinRange": "Returnerer minimumsrekkevidden til <b>våpenet</b>-våpenet.",
	"func_getWeaponMinRange_arg_1": "ID-en til våpenet hvis minimumsrekkevidde vil bli returnert.",
	"func_getWeaponMinRange_return": "Minimumsrekkevidden til <b>våpenet</b>-våpenet.",
	"func_getWeaponMinScope": "Returnerer minimumsrekkevidden til <b>våpenet</b>-våpenet.",
	"func_getWeaponMinScope_arg_1": "ID-en til våpenet hvis minimumsrekkevidde vil bli returnert.",
	"func_getWeaponMinScope_return": "Minimumsrekkevidden til <b>våpenet</b>-våpenet.",
	"func_getWeaponLaunchType": "Returnerer utskytningsmodusen til <b>våpen</b>-våpenet, blant LAUNCH_TYPE_*-konstantene.",
	"func_getWeaponLaunchType_arg_1": "ID-en til våpenet hvis kastemodus vil bli returnert. Standard til ditt for øyeblikket utstyrte våpen.",
	"func_getWeaponLaunchType_return": "Kastemodusen til <b>våpen</b>-våpenet.",
	"func_getWeaponName": "Returnerer navnet på <b>våpenet</b>-våpenet.",
	"func_getWeaponName_arg_1": "ID-en til våpenet hvis navn vil bli returnert.",
	"func_getWeaponName_return": "Navnet på <b>våpenet</b>-våpenet.",
	"func_getWeapons": "Returnerer våpnene til enheten med id <b>entity</b>.",
	"func_getWeapons_arg_1": "ID-en til enheten hvis våpen vil bli returnert.",
	"func_getWeapons_return": "En matrise som inneholder <b>entity</b>-våpen-ID-ene.",
	"func_getWeaponTargets": "Returnerer enhetene som vil bli påvirket hvis våpenet <b>våpenet</b> brukes på celle <b>cellen</b>.",
	"func_getWeaponTargets_arg_1": "Våpenet å teste.",
	"func_getWeaponTargets_arg_2": "Målcellen.",
	"func_getWeaponTargets_return": "Matrisen som inneholder IDene til alle enhetene som vil bli berørt.",
	"func_getWisdom": "Returnerer visdommen til enheten med id <b>entity</b>. Bruk getWisdom() uten parametere for å hente visdommen din.",
	"func_getWisdom_arg_1": "ID-en til enheten hvis visdom vil bli returnert.",
	"func_getWisdom_return": "Visdommen til enheten med id <b>entity</b>.",
	"func_hypot": "Returnerer hypotenusen til den rette trekanten med sidene x og y. Tilsvarer <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "X-verdien.",
	"func_hypot_arg_2": "Y-verdien.",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "Bestemmer om <b>element</b>-elementet er i <b>array</b>-matrisen.",
	"func_inArray_arg_1": "Søketabellen.",
	"func_inArray_arg_2": "Elementet å søke etter.",
	"func_inArray_return": "<i>true</i> hvis elementet er i matrisen, <i>false</i> ellers.",
	"func_include": "Inkluder AI med navnet <b>ai</b> i gjeldende AI. <br><br><b>Advarsel</b>, inkluderer funksjonen må kalles bare i hovedblokken, og parameteren må være en streng skrevet direkte\ni koden. For mer informasjon, se delen i opplæringen: <a href='http://leekwas.com/help/tutorial#includes'>http://leekwas.com/help/tutorial#includes</a>.",
	"func_include_arg_1": "Navnet på AI som skal inkluderes.",
	"func_indexOf": "Bestemmer posisjonen til den første forekomsten av strengen <b>søk</b> i strengen <b>strengen</b>, med start fra posisjon <b>start</b>.",
	"func_indexOf_arg_1": "Strengen der søket skal utføres.",
	"func_indexOf_arg_2": "Strengen å søke etter.",
	"func_indexOf_arg_3": "Startposisjonen til søket.",
	"func_indexOf_return": "Plasseringen av den første forekomsten av <b>søk</b> i <b>streng</b> fra <b>start</b>, -1 hvis strengen ikke ble funnet.",
	"func_insert": "Setter inn et <b>element</b> i <b>matrisen</b> ved posisjon <b>posisjon</b>.",
	"func_insert_arg_1": "Matrisen der elementet skal settes inn.",
	"func_insert_arg_2": "Elementet som skal settes inn.",
	"func_insert_arg_3": "Plasseringen av innsettingen.",
	"func_isAlive": "Bestemmer om en <b>enhet</b> er i live. Tilsvarer <i>getLife(<b>entity</b>) > 0</i>.",
	"func_isAlive_arg_1": "ID-en til enheten som skal testes.",
	"func_isAlive_return": "<i>sant</i> hvis <b>enheten</b> er i live, <i>usant</i> hvis den er død.",
	"func_isAlly": "Avgjør om <b>enhet</b> er din allierte.",
	"func_isAlly_arg_1": "ID-en til enheten som skal testes.",
	"func_isAlly_return": "<i>sant</i> hvis <b>enheten</b> er din allierte eller deg selv, <i>usant</i> hvis det er en fiende.",
	"func_isChip": "Bestemmer om en verdi er en konstant som representerer en brikke.<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "Antallet som skal bestemmes.",
	"func_isChip_return": "<i>true</i> hvis verdien er en brikkekonstant.",
	"func_isDead": "Avgjør om <b>entity</b> er død. Tilsvarer <i>getLife(<b>entity</b>) == 0</i>.",
	"func_isDead_arg_1": "ID-en til enheten som skal testes.",
	"func_isDead_return": "<i>sant</i> hvis <b>enheten</b> er død, <i>usant</i> hvis den lever.",
	"func_isEmpty": "Bestemmer om arrayen <b>array</b> er tom. Tilsvarer <i>count(<b>array</b>) == 0</i>.",
	"func_isEmpty_arg_1": "Matrisen som skal testes.",
	"func_isEmptyCell": "Bestemmer om en celle er tom.",
	"func_isEmptyCell_arg_1": "Cellen som skal testes.",
	"func_isEmptyCell_return": "<i>true</i> hvis cellen er tom, <i>usant</i> ellers.",
	"func_isEmpty_return": "<i>true</i> hvis matrisen er tom, <i>false</i> ellers.",
	"func_isEnemy": "Avgjør om <b>enhet</b> er din fiende.",
	"func_isEnemy_arg_1": "ID-en til enheten som skal testes.",
	"func_isEnemy_return": "<i>sant</i> hvis <b>enheten</b> er en fiende, <i>usant</i> hvis det er en alliert eller deg selv.",
	"func_isInlineChip": "Avgjør om <b>brikken</b> kun kan brukes på nettet.",
	"func_isInlineChip_arg_1": "IDen til brikken som skal testes.",
	"func_isInlineChip_return": "<i>true</i> hvis brikken bare er online, <i>false</i> ellers.",
	"func_isInlineWeapon": "Bestemmer om <b>våpen</b>-våpenet bare kan brukes online.",
	"func_isInlineWeapon_arg_1": "ID-en til våpenet som skal testes.",
	"func_isInlineWeapon_return": "<i>sant</i> hvis våpenet bare kan brukes online, <i>false</i> ellers.",
	"func_isLeek": "Bestemmer om innholdet i celle <b>celle</b> er en enhet.",
	"func_isLeek_arg_1": "Cellen som skal testes.",
	"func_isLeek_return": "<i>true</i> hvis cellen inneholder en enhet, <i>false</i> ellers.",
	"func_isEntity": "Bestemmer om innholdet i celle <b>celle</b> er en enhet.",
	"func_isEntity_arg_1": "Cellen som skal testes.",
	"func_isEntity_return": "<i>true</i> hvis cellen inneholder en enhet, <i>false</i> ellers.",
	"func_isObstacle": "Bestemmer om innholdet i celle <b>celle</b> er en hindring.",
	"func_isObstacle_arg_1": "Cellen som skal testes.",
	"func_isObstacle_return": "<i>true</i> hvis cellen inneholder en hindring, <i>usant</i> ellers.",
	"func_isOnSameLine": "Bestemmer om to celler <b>celle1</b> og <b>celle2</b> er på samme linje.",
	"func_isOnSameLine_arg_1": "Den første cellen.",
	"func_isOnSameLine_arg_2": "Den andre cellen.",
	"func_isOnSameLine_return": "<i>true</i> hvis de to cellene er på samme linje, <i>false</i> ellers.",
	"func_isStatic": "Returnerer om <b>entitet</b> er statisk eller ikke. En statisk enhet kan ikke flyttes eller flyttes.",
	"func_isStatic_arg_1": "ID-en til enheten som skal testes.",
	"func_isStatic_return": "<i>true</i> hvis <b>entity</b> er statisk, <i>false</i> ellers.",
	"func_isSummon": "Returnerer om <b>enhet</b> er en påkalling eller ikke.",
	"func_isSummon_arg_1": "ID-en til enheten som skal testes.",
	"func_isSummon_return": "<i>true</i> hvis <b>entity</b> er en påkallelse, <i>usant</i> ellers.",
	"func_isWeapon": "Bestemmer om en verdi er en konstant som representerer et våpen.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = usant.",
	"func_isWeapon_arg_1": "Antallet som skal bestemmes.",
	"func_isWeapon_return": "<i>sant</i> hvis verdien er en våpenkonstant.",
	"func_join": "Slår sammen flere elementer til en streng, og skiller dem med et <b>lim</b>-skilletegn.",
	"func_join_arg_1": "En rekke elementer som skal slås sammen.",
	"func_join_arg_2": "Elementavgrenseren.",
	"func_join_return": "Den resulterende strengen av sammenslåingen.",
	"func_jsonDecode": "Dekod <b>json</b>-streng til LeekScript-objekt (nummer, streng, matrise...).",
	"func_jsonDecode_arg_1": "JSON-strengen som skal dekodes.",
	"func_jsonDecode_return": "Det dekodede LeekScript-objektet.",
	"func_jsonEncode": "Koder <b>objekt</b>-objektet til en JSON-streng.",
	"func_jsonEncode_arg_1": "Objektet som skal kodes i JSON.",
	"func_jsonEncode_return": "Den JSON-kodede strengen.",
	"func_keySort": "Sorterer <b>matrisen</b> etter nøkler i <b>rekkefølge</b>.",
	"func_keySort_arg_1": "Matrisen for å sortere.",
	"func_keySort_arg_2": "Sorteringsrekkefølgen: #SORT_ASC eller #SORT_DESC.",
	"func_length": "Returnerer lengden på <b>streng</b>.",
	"func_length_arg_1": "Strengen hvis lengde vil bli returnert.",
	"func_length_return": "Lengden på <b>streng</b>.",
	"func_lineOfSight": "Kontrollerer siktlinjen mellom <b>start</b>-cellen og <b>slutt</b>-cellen, og ignorerer <b>ignoredEntities</b>-enheter.",
	"func_lineOfSight_arg_1": "Startcelle.",
	"func_lineOfSight_arg_2": "Målcelle.",
	"func_lineOfSight_arg_3": "En enhet eller liste over enheter som skal ignoreres.",
	"func_lineOfSight_return": "Returnerer <i>true</i> hvis siktlinjen er fri.",
	"func_listen": "Returnerer matrisen av say() for tidligere enheter, i formen [entity_id, message].",
	"func_listen_return": "Utvalget av tidligere si()-er.",
	"func_log": "Beregner den naturlige logaritmen til tallet <b>tallet</b>.",
	"func_log_arg_1": "Et tall i området ]0; +∞[.",
	"func_log_return": "Den naturlige logaritmen til <b>tall</b>.",
	"func_log10": "Beregner grunntallet 10-logaritmen til tallet <b>tallet</b>.",
	"func_log10_arg_1": "Et tall i området ]0; +∞[.",
	"func_log10_return": "Grunntallet 10-logaritmen til <b>tall</b>.",
	"func_log2": "Beregner grunntallslogaritmen til tallet <b>tallet</b>.",
	"func_log2_arg_1": "Et tall i området ]0; +∞[.",
	"func_log2_return": "Grunntallet 2-logaritmen til <b>tall</b>.",
	"func_mark": "Merker én eller flere celler med fargen som er angitt i parameteren på bakken for antall svinger som er angitt i parameteren. Denne merkingen er kun synlig for oppdretteren av enheten.",
	"func_mark_arg_1": "Cellen eller matrisen med flere celler som skal merkes",
	"func_mark_arg_2": "Markeringsfarge",
	"func_mark_arg_3": "Varighet av merking",
	"func_mark_return": "Returnerer sant hvis alt gikk bra",
	"func_markText": "Skriver en tekst på en eller flere celler med fargen som er angitt i parameter på bakken for antall svinger som er angitt i parameter. Disse tekstene er kun synlige for oppdretteren av enheten.",
	"func_markText_arg_1": "Cellen eller matrisen med flere celler det skal skrives til",
	"func_markText_arg_2": "Teksten som skal skrives (maks. 10 tegn)",
	"func_markText_arg_3": "Tekstfarge",
	"func_markText_arg_4": "Tekstvarighet",
	"func_markText_return": "Returnerer sant hvis alt gikk bra",
	"func_max": "Returnerer den største verdien mellom de to tallene <b>a</b> og <b>b</b>.",
	"func_max_arg_1": "Et tall.",
	"func_max_arg_2": "Et tall.",
	"func_max_return": "Det største tallet mellom <b>a</b> og <b>b</b>.",
	"func_min": "Returnerer den minste verdien mellom de to tallene <b>a</b> og <b>b</b>.",
	"func_min_arg_1": "Et tall.",
	"func_min_arg_2": "Et tall.",
	"func_min_return": "Det minste tallet mellom <b>a</b> og <b>b</b>.",
	"func_moveAwayFrom": "Flytter enheten bort fra en annen <b>enhet</b> ved å bruke maksimale <b>mp</b> bevegelsespunkter.",
	"func_moveAwayFrom_arg_1": "Entiteten enheten din skal flytte bort fra.",
	"func_moveAwayFrom_arg_2": "Maksimalt antall MP som skal brukes. Som standard, ingen grense.",
	"func_moveAwayFrom_return": "Antall bevegelsespunkter som brukes.",
	"func_moveAwayFromCell": "Flytter enheten en <b>celle</b> celle unna, og bruker opp <b>mp</b> bevegelsespunkter.",
	"func_moveAwayFromCell_arg_1": "Cellen enheten din skal bevege seg bort fra.",
	"func_moveAwayFromCell_arg_2": "Maksimalt antall MP som skal brukes. Som standard, ingen grense.",
	"func_moveAwayFromCell_return": "Antall bevegelsespunkter som brukes.",
	"func_moveAwayFromCells": "Flytter enheten bort fra et sett med <b>celler</b>-celler, ved å bruke maksimale <b>mp</b>-bevegelsespunkter.",
	"func_moveAwayFromCells_arg_1": "Matrisen som inneholder cellene enheten din skal bevege seg bort fra.",
	"func_moveAwayFromCells_arg_2": "Maksimalt antall MP som skal brukes. Som standard, ingen grense.",
	"func_moveAwayFromCells_return": "Antall bevegelsespunkter som brukes.",
	"func_moveAwayFromLeeks": "Flytter enheten bort fra et sett med <b>enheter</b> ved å bruke maksimale <b>mp</b> bevegelsespunkter.",
	"func_moveAwayFromLeeks_arg_1": "Matrisen som inneholder ID-ene til enhetene enheten din skal flytte bort fra.",
	"func_moveAwayFromLeeks_arg_2": "Maksimalt antall MP som skal brukes. Som standard, ingen grense.",
	"func_moveAwayFromLeeks_return": "Antall bevegelsespunkter som brukes.",
	"func_moveAwayFromEntities": "Flytter enheten bort fra et sett med <b>enheter</b> ved å bruke maksimale <b>mp</b> bevegelsespunkter.",
	"func_moveAwayFromEntities_arg_1": "Matrisen som inneholder ID-ene til enhetene enheten din skal flytte bort fra.",
	"func_moveAwayFromEntities_arg_2": "Maksimalt antall MP som skal brukes. Som standard, ingen grense.",
	"func_moveAwayFromEntities_return": "Antall bevegelsespunkter som brukes.",
	"func_moveAwayFromLine": "Flytter enheten bort fra en linje definert av to celler <b>celle1</b> og <b>celle2</b>, med maksimalt <b>mp</b> bevegelsespunkter.",
	"func_moveAwayFromLine_arg_1": "Celle 1.",
	"func_moveAwayFromLine_arg_2": "Celle 2.",
	"func_moveAwayFromLine_arg_3": "Maksimalt antall MP som skal brukes. Som standard, ingen grense.",
	"func_moveAwayFromLine_return": "Antall bevegelsespunkter som brukes.",
	"func_moveToward": "Bringer enheten din nærmere en annen <b>enhet</b> ved å bruke maksimale <b>mp</b> bevegelsespunkter.",
	"func_moveToward_arg_1": "Enheten din enhet bør henvende seg til.",
	"func_moveToward_arg_2": "Maksimalt antall MP som skal brukes.",
	"func_moveToward_return": "Antall bevegelsespunkter som brukes.",
	"func_moveTowardCell": "Bringer enheten din én <b>celle</b> celle nærmere ved å bruke maksimale <b>mp</b> bevegelsespunkter.",
	"func_moveTowardCell_arg_1": "Cellen funksjonen din skal nærme seg.",
	"func_moveTowardCell_arg_2": "Maksimalt antall MP som skal brukes. Som standard, ingen grense.",
	"func_moveTowardCell_return": "Antall bevegelsespunkter som brukes.",
	"func_moveTowardCells": "Bringer enheten din nærmere et sett med <b>celle</b>-celler, ved å bruke maksimale <b>mp</b>-bevegelsespunkter.",
	"func_moveTowardCells_arg_1": "Matrisen som inneholder cellene enheten din skal nærme seg.",
	"func_moveTowardCells_arg_2": "Maksimalt antall MP som skal brukes. Som standard, ingen grense.",
	"func_moveTowardCells_return": "Antall bevegelsespunkter som brukes.",
	"func_moveTowardLeeks": "Bringer enheten din nærmere et sett med <b>enheter</b> ved å bruke maksimale <b>mp</b> bevegelsespunkter.",
	"func_moveTowardLeeks_arg_1": "Matrisen som inneholder ID-ene til enhetene enheten din bør nærme seg.",
	"func_moveTowardLeeks_arg_2": "Maksimalt antall MP som skal brukes. Som standard, ingen grense.",
	"func_moveTowardLeeks_return": "Antall bevegelsespunkter som brukes.",
	"func_moveTowardEntities": "Bringer enheten din nærmere et sett med <b>enheter</b> ved å bruke maksimale <b>mp</b> bevegelsespunkter.",
	"func_moveTowardEntities_arg_1": "Matrisen som inneholder ID-ene til enhetene enheten din bør nærme seg.",
	"func_moveTowardEntities_arg_2": "Maksimalt antall MP som skal brukes. Som standard, ingen grense.",
	"func_moveTowardEntities_return": "Antall bevegelsespunkter som brukes.",
	"func_moveTowardLine": "Bringer enheten din nærmere en linje definert av to celler <b>celle1</b> og <b>celle2</b>, med maksimale <b>mp</b> bevegelsespunkter.",
	"func_moveTowardLine_arg_1": "Celle 1.",
	"func_moveTowardLine_arg_2": "Celle 2.",
	"func_moveTowardLine_arg_3": "Maksimalt antall MP som skal brukes. Som standard, ingen grense.",
	"func_moveTowardLine_return": "Antall bevegelsespunkter som brukes.",
	"func_number": "Konverterer en verdi til et tall. Hvis verdien er en streng, vil tallfunksjonen prøve å konvertere den til et tall, hvis verdien allerede er et tall, returnerer funksjonen tallet, og for enhver annen type returnerer den <i>null</i>.",
	"func_number_arg_1": "Verdien som skal konverteres til et tall.",
	"func_number_return": "Det konverterte tallet.",
	"func_pause": "Pauser kampen, kun for oppdretteren av enheten som bruker funksjonen.",
	"func_pop": "Fjerner den siste cellen fra <b>array</b>-matrisen og returnerer det tilsvarende elementet.",
	"func_pop_arg_1": "Arrayen hvis siste boks vil bli slettet.",
	"func_pop_return": "Varen som var i den gamle siste boksen.",
	"func_pow": "Hever grunntallet til eksponentpotensen.",
	"func_pow_arg_1": "Basen.",
	"func_pow_arg_2": "Utstilleren.",
	"func_pow_return": "<b>base</b> hevet til <b>eksponent</b> potens.",
	"func_push": "Legger til <b>element</b>-elementet på slutten av <b>matrisen</b>.",
	"func_pushAll": "Legger til alle elementene i <b>elementer</b>-matrisen til slutten av <b>matrisen</b>.",
	"func_pushAll_arg_1": "Matrisen der vi ønsker å legge til elementene.",
	"func_pushAll_arg_2": "Elementer å legge til.",
	"func_push_arg_1": "Matrisen der vi ønsker å legge til elementet.",
	"func_push_arg_2": "Elementet som skal legges til.",
	"func_rand": "Returnerer et reelt tilfeldig tall, mellom 0 (inklusive) og 1 (eksklusivt).",
	"func_randFloat": "Returnerer et reelt tilfeldig tall, mellom <b>a</b> (inklusive) og <b>b</b> (eksklusivt).",
	"func_randFloat_arg_1": "Nedre grense.",
	"func_randFloat_arg_2": "Øvre grense.",
	"func_randFloat_return": "Et tilfeldig tall i området [a; b[.",
	"func_randInt": "Returnerer et tilfeldig heltall mellom <b>a</b> (inklusive) og <b>b</b> (eksklusivt).",
	"func_randInt_arg_1": "Nedre grense.",
	"func_randInt_arg_2": "Øvre grense.",
	"func_randInt_return": "Et tilfeldig heltall i området [a; b[.",
	"func_rand_return": "Et tilfeldig tall i området [0; 1[.",
	"func_remove": "Fjerner et element fra arrayet <b>array</b> ved posisjon <b>posisjon</b>, og returnerer det slettede elementet.",
	"func_remove_arg_1": "Array som vi ønsker å slette et element fra.",
	"func_remove_arg_2": "Plassering av elementet som skal slettes.",
	"func_removeElement": "Sletter den første forekomsten av et <b>element</b> i <b>matrisen</b>. Hvis elementet ikke blir funnet, endres ikke matrisen.",
	"func_removeElement_arg_1": "Array som vi ønsker å slette et element fra.",
	"func_removeElement_arg_2": "Element å finne og deretter slette.",
	"func_removeKey": "Sletter et element fra matrisen <b>matrise</b> knyttet til nøkkelen <b>nøkkel</b>.",
	"func_removeKey_arg_1": "Array som vi ønsker å slette et element fra.",
	"func_removeKey_arg_2": "Nøkkelen knyttet til elementet som skal slettes.",
	"func_remove_return": "Elementet fjernet fra matrisen.",
	"func_replace": "Erstatter alle forekomster av <b>søk</b> med <b>erstatt</b> i strengen <b>streng</b>.",
	"func_replace_arg_1": "String som det gjøres erstatninger i.",
	"func_replace_arg_2": "Delstreng som skal erstattes.",
	"func_replace_arg_3": "Erstatningsstreng.",
	"func_replace_return": "Resultatstrengen, med erstatninger.",
	"func_resurrect": "Bruker CHIP_RESURRECTION-brikken til å gjenopplive en død enhet med id <b>entity</b>, på celle <b>celle</b>.",
	"func_resurrect_arg_1": "ID-en til enheten som skal gjenopplives.",
	"func_resurrect_arg_2": "Cellen som enheten skal gjenopprettes på.",
	"func_resurrect_return": "Resultatet av lansering av brikken, blant konstantene USE_*.",
	"func_reverse": "Reverserer <b>matrisen</b>.",
	"func_reverse_arg_1": "Tabell for å invertere.",
	"func_round": "Returnerer avrundingen av <b>tall</b>.<br/>Du kan finne avrundingen ned med #gulv, og avrundingen opp med #tak.",
	"func_round_arg_1": "Tallet hvis avrunding vi ønsker å finne.",
	"func_round_return": "Avrundet etter <b>tall</b>.",
	"func_say": "Få din enhet til å snakke.",
	"func_say_arg_1": "Melding som din enhet vil kunngjøre på arenaen.",
	"func_search": "Finner elementet <b>element</b> i arrayet <b>array</b>, med start fra posisjon <b>start</b>.",
	"func_search_arg_1": "Søketabellen.",
	"func_search_arg_2": "Elementet å søke etter.",
	"func_search_arg_3": "Posisjonen for starten av søket.",
	"func_search_return": "Plasseringen av den første forekomsten av <b>elementet</b>-elementet i <b>matrisen</b>, starter fra <b>start</b>, null hvis elementet ikke er funnet.",
	"func_sendAll": "Send en melding til hele teamet ditt.",
	"func_sendAll_arg_1": "Type melding som skal sendes (se MESSAGE_* konstantene).",
	"func_sendAll_arg_2": "Meldingsparameterne, som kan være en hvilken som helst verdi.",
	"func_sendTo": "Sender en melding til enheten med id <b>entity</b>.",
	"func_sendTo_arg_1": "ID-en til enheten som meldingen skal sendes til.",
	"func_sendTo_arg_2": "Type melding som skal sendes (se MESSAGE_* konstantene).",
	"func_sendTo_arg_3": "Meldingsparameterne, som kan være en hvilken som helst verdi.",
	"func_sendTo_return": "<i>true</i> hvis sendingen var vellykket, <i>false</i> hvis det oppsto en feil.",
	"func_setRegister": "Lagrer <b>verdien</b> i <b>nøkkel</b>-registeret.\nNøkkelen og verdien er strenger som maksimalt må inneholde <i>100</i> og <i>5000</i> tegn. En purre kan ha maksimalt <i>100</i> registre, lagring i et nytt register vil ikke fungere hvis alle registre\ner allerede okkupert.",
	"func_setRegister_arg_1": "Registernøkkelen hvor verdien skal lagres.",
	"func_setRegister_arg_2": "Verdien å lagre.",
	"func_setRegister_return": "<i>true</i> hvis operasjonen var vellykket, ellers <i>false</i>.",
	"func_setWeapon": "Utstyr <b>våpen</b>-våpenet på enheten din.",
	"func_setWeapon_arg_1": "ID for våpenet som skal utstyres.",
	"func_shift": "Fjerner den første cellen fra <b>matrisen</b> og returnerer det tilsvarende elementet.",
	"func_shift_arg_1": "Arrayen hvis første boks vil bli slettet.",
	"func_shift_return": "Elementet som var i den gamle første boksen.",
	"func_show": "Viser spillerne en <b>celle</b> med fargen <b>farge</b> på banen i 1 omgang. Bruk av denne funksjonen koster 1PT.",
	"func_show_arg_1": "Cellen som skal vises",
	"func_show_arg_2": "Markeringsfarge",
	"func_show_return": "Returnerer sant hvis alt gikk bra",
	"func_shuffle": "Blander et brett tilfeldig.",
	"func_shuffle_arg_1": "Shufflebrettet.",
	"func_signum": "Bestemmer tegnet for tallet <b>tallet</b>.",
	"func_signum_arg_1": "Nummeret hvis fortegn vil bli bestemt.",
	"func_signum_return": "1 hvis tallet er positivt, 0 hvis tallet er null, og -1 hvis tallet er negativt.",
	"func_sin": "Beregner sinusen til vinkelen <b>vinkel</b> som sendes som parameter",
	"func_sin_arg_1": "Vinkelen hvis sinus vil bli beregnet",
	"func_sin_return": "Returnerer sinusen til vinkelen <b>vinkel</b>",
	"func_sort": "Sorterer <b>matrisen</b> i stigende eller synkende rekkefølge. Standard stigende rekkefølge er:\n<ul>\n<li>Alle booleaner (først usant og deretter sant)</li>\n<li>Alle tall (ordnet fra minste til største)</li>\n<li>Alle kanaler (i alfabetisk rekkefølge)</li>\n<li>Alle null-elementer.</li>\n</ul>",
	"func_sort_arg_1": "Array for å sortere.",
	"func_sort_arg_2": "#SORT_ASC for å sortere <b>array</b> i stigende rekkefølge eller #SORT_DESC for å sortere den i synkende rekkefølge.",
	"func_split": "Deler strengen <b>streng</b> i understrenger avgrenset med <b>skilletegn</b>.",
	"func_split_arg_1": "Skjære kjede.",
	"func_split_arg_2": "Streng som avgrenser passasjen fra ett element til et annet.",
	"func_split_arg_3": "Maksimalt antall array-elementer.",
	"func_split_return": "Array som inneholder delstrengene som ble funnet.",
	"func_sqrt": "Beregner kvadratroten av tallet <b>tallet</b>.",
	"func_sqrt_arg_1": "Tallet hvis rot vil bli beregnet.",
	"func_sqrt_return": "Kvadratroten av <b>tall</b>.",
	"func_startsWith": "Sjekker om <b>streng</b> begynner med <b>prefiks</b>.",
	"func_startsWith_arg_1": "Søkestreng.",
	"func_startsWith_arg_2": "Søkte prefiks.",
	"func_startsWith_return": "Sann hvis <b>strengen</b> starter med <b>prefiks</b>.",
	"func_string": "Konverterer en verdi til en streng.<br>\nHvis verdien allerede er en streng, returneres den.<br>\nHvis verdien er nummer x, returneres \"x\".<br>\nHvis verdien er en matrise, returneres en streng av formen \"[nøkkel1: verdi1, nøkkel2: verdi2, ...]\".<br>\nHvis verdien er en boolsk, returneres \"true\" og \"false\" for henholdsvis sant og usant.<br>\nHvis verdien er null, returneres \"null\".",
	"func_string_arg_1": "Verdien som skal konverteres til en tegnstreng.",
	"func_string_return": "Den konverterte strengen.",
	"func_subArray": "Returnerer en undermatrise av <b>matrise</b> som starter ved posisjon <b>start</b> og slutter ved posisjon <b>slutt</b>.",
	"func_subArray_arg_1": "Kildetabell.",
	"func_subArray_arg_2": "Startindeks.",
	"func_subArray_arg_3": "Sluttindeks.",
	"func_subArray_return": "Sub-array.",
	"func_substring": "Returnerer understrengen til strengen <b>streng</b> fra tegnet ved indeksen <b>start</b> og størrelsen <b>lengde</b>.",
	"func_substring_arg_1": "Skjære kjede.",
	"func_substring_arg_2": "Starttegnindeks.",
	"func_substring_arg_3": "Lengde på delstrengen.",
	"func_substring_return": "Understreng.",
	"func_sum": "Returnerer summen av alle de numeriske elementene i matrisen <b>matrise</b>.",
	"func_sum_arg_1": "Tabell for å summere.",
	"func_summon": "Påkaller en enhet bestemt av brikken <b>brikken</b> på cellen <b>cellen</b> som har funksjonen <b>ai</b> for IA.",
	"func_summon_arg_1": "Brikken som brukes til innkalling. Brikken må være en summon-brikke og må være utstyrt på enheten som bruker summon-funksjonen.",
	"func_summon_arg_2": "Cellen eller innkallingen skal vises.",
	"func_summon_arg_3": "Påkallelsen AI, som en funksjon.",
	"func_summon_return": "Summon-funksjonen har samme retur som #useChip-funksjonen.",
	"func_sum_return": "Summen av matriseelementer.",
	"func_tan": "Beregner tangens til vinkel <b>vinkel</b> (i radianer).",
	"func_tan_arg_1": "Vinkel i radianer.",
	"func_tan_return": "Tangent av <b>vinkel</b>.",
	"function_category_array": "Lister",
	"function_category_chip": "Lopper",
	"function_category_color": "Farge",
	"function_category_fight": "Slåss",
	"function_category_leek": "Purre",
	"function_category_map": "Tabeller",
	"function_category_field": "Bakke",
	"function_category_math": "Tall",
	"function_category_network": "Nettverk",
	"function_category_string": "Kjeder",
	"function_category_utils": "Verktøy",
	"function_category_weapon": "Våpen",
	"func_toDegrees": "Konverterer vinkel <b>radianer</b> til grader.",
	"func_toDegrees_arg_1": "Verdi i radianer",
	"func_toDegrees_return": "Vinkel i grader.",
	"func_toLower": "Konverterer store bokstaver i <b>streng</b> til små bokstaver.",
	"func_toLower_arg_1": "String for å transformere",
	"func_toLower_return": "Snøre med små bokstaver",
	"func_toRadians": "Konverterer vinkel <b>grader</b> til radianer.",
	"func_toRadians_arg_1": "Verdi i grader",
	"func_toRadians_return": "Vinkel i radianer.",
	"func_toUpper": "Konverterer små bokstaver i <b>streng</b> til store bokstaver.",
	"func_toUpper_arg_1": "String for å transformere.",
	"func_toUpper_return": "Store bokstaver.",
	"func_typeOf": "Returnerer typen til verdien <b>verdi</b>, blant typene: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "Verdien hvis type vil bli returnert.",
	"func_typeOf_return": "Typen <b>verdi</b>.",
	"func_unshift": "Legger til <b>element</b>-elementet i begynnelsen av <b>matrisen</b>.",
	"func_unshift_arg_1": "Array der elementet vil bli lagt til.",
	"func_unshift_arg_2": "Element å legge til.",
	"func_useChip": "Bruk brikke <b>brikke</b> på enhet <b>enhet</b>.",
	"func_useChip_arg_1": "Chip å bruke.",
	"func_useChip_arg_2": "Målenhet.",
	"func_useChipOnCell": "Bruk brikke <b>brikke</b> på celle <b>celle</b>.",
	"func_useChipOnCell_arg_1": "Chip å bruke.",
	"func_useChipOnCell_arg_2": "Målcelle.",
	"func_useChipOnCell_return": "En verdi større enn 0 hvis angrepet ble satt i gang.",
	"func_useChip_return": "Returverdiene for useChip er:<br>\n<ul>\n<li>#USE_CRITICAL, ved kritisk treff</li>\n<li>#USE_SUCCESS, hvis vellykket</li>\n<li>#USE_FAILED, ved feil</li>\n<li>#USE_INVALID_TARGET, hvis målet ikke eksisterer</li>\n<li>#USE_NOT_ENOUGH_TP, hvis enheten din ikke har nok TP</li>\n<li>#USE_INVALID_COOLDOWN, hvis brikken ikke er brukbar ennå</li>\n<li>#USE_INVALID_POSITION, hvis rekkevidden er dårlig eller siktlinjen ikke er tydelig</li>\n</ul>",
	"func_useWeapon": "Bruk det valgte våpenet på <b>entity</b>-enheten.",
	"func_useWeapon_arg_1": "Målenhet.",
	"func_useWeaponOnCell": "Bruk det valgte våpenet på <b>cellen</b>-cellen.",
	"func_useWeaponOnCell_arg_1": "Målcelle.",
	"func_useWeaponOnCell_return": "En verdi større enn 0 hvis angrepet ble satt i gang.",
	"func_useWeapon_return": "Returverdiene for useWeapon er:<br>\n<ul>\n<li>#USE_CRITICAL, ved kritisk treff</li>\n<li>#USE_SUCCESS, hvis vellykket</li>\n<li>#USE_FAILED, ved feil</li>\n<li>#USE_INVALID_TARGET, hvis målet ikke eksisterer</li>\n<li>#USE_NOT_ENOUGH_TP, hvis enheten din ikke har nok TP</li>\n<li>#USE_INVALID_POSITION, hvis rekkevidden er dårlig eller siktlinjen ikke er tydelig</li>\n</ul>",
	"func_weaponNeedLos": "Returnerer hvis <b>våpenet</b>-våpenet trenger siktlinje for å skyte.",
	"func_weaponNeedLos_arg_1": "ID-en til våpenet som skal testes.",
	"func_weaponNeedLos_return": "<i>sant</i> hvis <b>våpenet</b>-våpenet trenger siktlinje for å skyte, <i>false</i> ellers.",
	"items": "Varer",
	"max_cores": "maks",
	"max_level": "maks",
	"min_cores": "Minimum hjerter",
	"min_level": "Min nivå",
	"n_operations": "<b>{0}</b> operasjoner",
	"parameters": "Innstillinger",
	"return": "Tilbakemelding",
	"value": "Verdi",
	"variable_operations": "<b>Variabel</b> operasjoner",
	"deprecated_function": "Denne funksjonen er utdatert.",
	"deprecated_constant": "Denne konstanten er utdatert.",
	"replaced_by": "Den er erstattet av {0}.",
	"details": "Detaljer",
	"optional": "valgfri",
	"arg_type_9": "pakke",
	"arg_type_10": "intervall",
	"function_category_set": "Pakker",
	"function_category_interval": "Intervaller",
	"arg_type_96": "sett<entier>"
}

	i18n.mergeLocaleMessage("no", { "doc": messages })
	