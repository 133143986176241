
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> toiminto",
	"arg_type_-1": "?",
	"arg_type_1": "määrä",
	"arg_type_2": "ketju",
	"arg_type_3": "boolean",
	"arg_type_4": "lista",
	"arg_type_41": "luettelo<numero>",
	"arg_type_42": "luettelo<merkkijono>",
	"arg_type_43": "lista<boolean>",
	"arg_type_44": "lista<lista>",
	"arg_type_46": "lista<kokonaisluku>",
	"arg_type_47": "lista<todellinen>",
	"arg_type_5": "toiminto",
	"arg_type_6": "koko",
	"arg_type_7": "todellinen",
	"arg_type_8": "pöytä",
	"arg_type_806": "taulukko<?, kokonaisluku>",
	"complexity": "Monimutkaisuus {c}",
	"operations": "{o} toimintoa",
	"const_AREA_CIRCLE_1": "Pyöreä alue, halkaisijaltaan 3 neliötä (risti).",
	"const_AREA_CIRCLE_2": "Pyöreä alue, halkaisijaltaan 5 neliötä.",
	"const_AREA_CIRCLE_3": "Pyöreä alue, halkaisijaltaan 7 neliötä.",
	"const_AREA_LASER_LINE": "Laserin vyöhyke, viiva laserin minimietäisyydeltä sen maksimialueelle tai este.",
	"const_AREA_POINT": "Alue, joka koostuu yhdestä solusta.",
	"const_CELL_EMPTY": "Palautusarvo getCellContent(cell) tyhjälle solulle.",
	"const_CELL_OBSTACLE": "Palautusarvo getCellContent(cell) solulle, joka sisältää esteen.",
	"const_CELL_PLAYER": "Palautusarvo getCellContent(cell) solulle, joka sisältää kokonaisuuden.",
	"const_CELL_ENTITY": "Palautusarvo getCellContent(cell) solulle, joka sisältää kokonaisuuden.",
	"const_COLOR_BLUE": "Väri sininen.",
	"const_COLOR_GREEN": "Vihreä väri.",
	"const_COLOR_RED": "Punainen väri.",
	"const_E": "Eulerin numero.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Antaa kokonaisuudelle absoluuttisen suojan vähentäen vaurion (EFFECT_DAMAGE) poistamien osumapisteiden määrää kiinteällä määrällä. Vahvistaa vastus.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Poistaa kokonaisuuden absoluuttisen suojan. Ominaisuus ei vahvista sitä. Mahdollistaa vaurion (EFFECT_DAMAGE) poistamien osumapisteiden lisäämisen absoluuttisella määrällä.",
	"const_EFFECT_AFTEREFFECT": "Poistaa osumapisteet entiteetistä. Tieteen vahvistama. Vähentää enimmäisosumapisteitä 5 % poistettujen osumapisteiden määrästä.",
	"const_EFFECT_ANTIDOTE": "Poistaa kaikki kohteessa oleva myrkky (EFFECT_POISON).",
	"const_EFFECT_BOOST_MAX_LIFE": "Lisää kokonaisuuden osumapisteitä ja enimmäisosumapisteitä. Viisauden vahvistama.",
	"const_EFFECT_BUFF_AGILITY": "Antaa taholle ketteryyttä. Tieteen vahvistama.",
	"const_EFFECT_BUFF_MP": "Antaa liikepisteitä entiteetille. Tieteen vahvistama.",
	"const_EFFECT_BUFF_RESISTANCE": "Antaa vastustusta entiteetille. Tieteen vahvistama.",
	"const_EFFECT_BUFF_STRENGTH": "Antaa voimaa kokonaisuudelle. Tieteen vahvistama.",
	"const_EFFECT_BUFF_TP": "Antaa toimipisteitä entiteetille. Tieteen vahvistama.",
	"const_EFFECT_BUFF_WISDOM": "Anna viisautta entiteetille. Tieteen vahvistama.",
	"const_EFFECT_DAMAGE": "Poistaa osumapisteet entiteetistä. Vahvistettu voima. Toimii vuorovaikutuksessa kilpien (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), lifestealin (paitsi pyörän) ja vahingonkorvauksen (EFFECT_DAMAGE_RETURN) kanssa. Vähentää enimmäisosumapisteitä 5 % poistettujen osumapisteiden määrästä.",
	"const_EFFECT_DAMAGE_RETURN": "Heijastaa vaurioita entiteetille, mikä mahdollistaa osumapisteiden poistamisen kohteista, jotka aiheuttavat vahinkoa vastaanottajalle. Vahvistaa ketteryys. Vähentää enimmäisosumapisteitä 5 % poistettujen osumapisteiden määrästä.",
	"const_EFFECT_DEBUFF": "Vähentää kaikkien kokonaisuuteen kohdistuvien vaikutusten arvoa prosentteina.",
	"const_EFFECT_HEAL": "Palauttaa osumapisteet kokonaisuuteen, jonka enimmäisosumapisteet rajoittavat. Viisauden vahvistama.",
	"const_EFFECT_INVERT": "Vaihtaa pyörän paikan entiteetin asemaan.",
	"const_EFFECT_KILL": "Poistaa kaikki osumapisteet entiteetistä.",
	"const_EFFECT_LIFE_DAMAGE": "Poistaa osumapisteet kokonaisuudesta prosenttiosuuden perusteella pyöräilijän osumapisteistä. Vuorovaikuttaa kilpien (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) ja vaurioiden palautuksen (EFFECT_DAMAGE_RETURN) kanssa. Vähentää enimmäisosumapisteitä 5 % poistettujen osumapisteiden määrästä.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "Vaikutus kerrotaan vaikutusalaan kuuluvien kokonaisuuksien lukumäärällä alueella.",
	"const_EFFECT_MODIFIER_ON_CASTER": "Vaikutus vaikuttaa edelleen pyörään.",
	"const_EFFECT_MODIFIER_STACKABLE": "Vaikutus on kumulatiivinen.",
	"const_EFFECT_NOVA_DAMAGE": "Poistaa maksimielämän pisteet. Tieteen vahvistama.",
	"const_EFFECT_POISON": "Poistaa osumapisteet entiteetistä. Vahvistettu taikuudella. Vähentää enimmäisosumapisteitä 10 % poistettujen osumapisteiden määrästä.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Antaa kokonaisuudelle absoluuttisen suojan vähentäen vaurion (EFFECT_DAMAGE) poistamien osumapisteiden määrää kiinteällä määrällä. Ei vahvistettavissa.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Antaa taholle ketteryyttä. Ei vahvistettavissa.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Antaa taikuutta entiteetille. Ei vahvistettavissa.",
	"const_EFFECT_RAW_BUFF_MP": "Antaa liikepisteitä entiteetille. Ei vahvistettavissa.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Antaa tieteen entiteetille. Ei vahvistettavissa.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Antaa voimaa kokonaisuudelle. Ei vahvistettavissa.",
	"const_EFFECT_RAW_BUFF_TP": "Antaa toimipisteitä entiteetille. Ei vahvistettavissa.",
	"const_EFFECT_RELATIVE_SHIELD": "Tarjoaa suhteellisen suojan ja vähentää vaurion (EFFECT_DAMAGE) poistamien osumapisteiden määrää suhteellisella määrällä. Vahvistaa vastus.",
	"const_EFFECT_RESURRECT": "Herättää entiteetin henkiin, jonka HP:n enimmäismäärä on puolet entiteetin enimmäismäärästä ennen ylösnousemusta ja nykyinen HP-numero neljäsosaa HP:n enimmäismäärästä ennen ylösnousemusta.",
	"const_EFFECT_SHACKLE_MAGIC": "Poistaa taikuuden kokonaisuudesta. Vahvistettu taikuudella.",
	"const_EFFECT_SHACKLE_MP": "Poista liikepisteet entiteetistä. Vahvistettu taikuudella.",
	"const_EFFECT_SHACKLE_STRENGTH": "Poistaa voiman kokonaisuudesta. Vahvistettu taikuudella.",
	"const_EFFECT_SHACKLE_TP": "Vähennä toimintapisteitä entiteetistä. Vahvistettu taikuudella.",
	"const_EFFECT_SUMMON": "Kutsuu lampun. Ei vaikutusta, jos joukkueen kutsuraja saavutetaan.",
	"const_EFFECT_TARGET_ALLIES": "Vaikuttaa liittolaisiin.",
	"const_EFFECT_TARGET_CASTER": "Vaikuttaa pyörään.",
	"const_EFFECT_TARGET_ENEMIES": "Vaikuttaa vihollisiin.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Vaikuttaa kutsumattomiin entiteeteihin (purjoihin ja torneineen).",
	"const_EFFECT_TARGET_SUMMONS": "Vaikuttaa kutsuttuihin yksiköihin (bulbs).",
	"const_EFFECT_TELEPORT": "Muuttaa pyörän asentoa.",
	"const_EFFECT_VULNERABILITY": "Poistaa suojan suhteessa kokonaisuuteen. Ominaisuus ei vahvista sitä. Lisää vaurion (EFFECT_DAMAGE) poistamia osumapisteitä suhteellisella määrällä.",
	"const_ENTITY_BULB": "Viittaa Bulb-tyyppiseen kokonaisuuteen.",
	"const_ENTITY_LEEK": "Viittaa Leek-tyyppiseen kokonaisuuteen.",
	"const_ENTITY_TURRET": "Viittaa Turret-tyyppiseen kokonaisuuteen.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Haastetyyppinen taistelutausta.",
	"const_FIGHT_CONTEXT_GARDEN": "Konteksti taistelu kasvimaassa.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Taistelun konteksti Battle Royalessa.",
	"const_FIGHT_CONTEXT_TEST": "Testaa taistelukontekstia.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Turnaustaistelun tausta.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Taistele Battle Royalessa.",
	"const_FIGHT_TYPE_FARMER": "Kasvattajan taistelu.",
	"const_FIGHT_TYPE_SOLO": "Yksintaistelua.",
	"const_FIGHT_TYPE_TEAM": "Joukkueen taistelu.\n",
	"const_INSTRUCTIONS_LIMIT": "Enimmäismäärä käskyjä, joita entiteetti voi käyttää vuoronsa aikana.",
	"const_MAX_TURNS": "Maksimi kierrosten määrä taistelussa.",
	"const_OPERATIONS_LIMIT": "Enimmäismäärä operaatioita, joita kokonaisuus voi käyttää vuoronsa aikana.",
	"const_PI": "Ympyrän kehän suhde sen halkaisijaan.",
	"const_SORT_ASC": "Käskee #sort-funktiota lajittelemaan nousevaan järjestykseen.",
	"const_SORT_DESC": "Käskee #sort-funktiota lajittelemaan laskevaan järjestykseen.",
	"const_TYPE_ARRAY": "Arvotyyppi <b>luettelo</b>",
	"const_TYPE_BOOLEAN": "Arvon tyyppi <b>looginen</b>",
	"const_TYPE_FUNCTION": "Arvotyyppi <b>funktio</b>",
	"const_TYPE_NULL": "Arvon tyyppi <b>null</b>",
	"const_TYPE_NUMBER": "Arvotyyppi <b>number</b>",
	"const_TYPE_STRING": "Arvon tyyppi <b>merkkijono</b>",
	"const_USE_CRITICAL": "#useWeapon-, #useWeaponOnCell-, #useChip- ja #useChipOnCell-funktioiden palauttama arvo kriittisen osuman sattuessa.",
	"const_USE_FAILED": "#useWeapon-, #useWeaponOnCell-, #useChip- ja #useChipOnCell-funktioiden palauttama arvo epäonnistuessa.",
	"const_USE_INVALID_COOLDOWN": "#useChip- ja #useChipOnCell-funktioiden palauttama arvo, jos siru ei ole vielä käyttökelpoinen.",
	"const_USE_INVALID_POSITION": "#useWeapon-, #useWeaponOnCell-, #useChip- ja #useChipOnCell-funktioiden palauttama arvo, jos kantama on huono tai näköyhteys ei ole selvä.",
	"const_USE_INVALID_TARGET": "#useWeapon- ja #useChip-funktioiden palauttama arvo, jos kohdetta ei ole olemassa.",
	"const_USE_NOT_ENOUGH_TP": "#useWeapon-, #useWeaponOnCell-, #useChip- ja #useChipOnCell-funktioiden palauttama arvo, jos pyöräilijällä ei ole tarpeeksi toimintapisteitä objektin käyttämiseen.",
	"const_USE_RESURRECT_INVALID_ENTITY": "#resurrect-funktion palauttama arvo, kun määritettyä entiteettiä ei ole olemassa tai se ei ole vielä kuollut.",
	"const_USE_SUCCESS": "#useWeapon-, #useWeaponOnCell-, #useChip- ja #useChipOnCell-funktioiden palauttama arvo onnistumisen yhteydessä.",
	"const_USE_TOO_MANY_SUMMONS": "#summon palautti virheen, kun sinulla on jo <b>8</b> suoraa kutsua.\n",
	"func_abs": "Palauttaa luvun <b>luku</b> itseisarvon.",
	"func_abs_arg_1": "Luku, jonka itseisarvo lasketaan.",
	"func_abs_return": "Numeron itseisarvo.",
	"func_acos": "Laskee <b>argumentin</b> arkosiinin välillä [0, #PI].",
	"func_acos_arg_1": "Luku, jonka arkosiini lasketaan.",
	"func_acos_return": "<b>argumentin</b> kaarikosini.",
	"func_arrayConcat": "Lisää kaksi taulukkoa päästä päähän. Tekstinäppäimet säilytetään ja kaikki numeronäppäimet indeksoidaan uudelleen.",
	"func_arrayConcat_arg_1": "Ensimmäinen pöytä.",
	"func_arrayConcat_arg_2": "Toinen pöytä.",
	"func_arrayConcat_return": "Kahden taulukon summa.",
	"func_arrayFilter": "Palauttaa uuden taulukon, joka sisältää kaikki avain/arvo-parit lähdetaulukosta, jolle takaisinsoittofunktio palautti tosi. Jos takaisinsoittotoiminto ottaa yhden parametrin, lähetetään lähdetaulukon arvo, jos se ottaa kaksi parametria, lähetetään avain ja arvo.",
	"func_arrayFilter_arg_1": "Alkuperäinen pöytä.",
	"func_arrayFilter_arg_2": "Jokaiselle elementille vaadittu toiminto.",
	"func_arrayFilter_return": "Uusi pöytä.",
	"func_arrayFlatten": "Palauttaa uuden taulukon, joka sisältää kaikki lähdetaulukon elementit. Kaikki alitaulukon sisältämät elementit puretaan uuteen taulukkoon. Syvyysargumentti määrittää poimittavan alitaulukon suurimman syvyyden.\n<koodi>muuttuja array = [1, 2, [[3, 4], 5], [6]];\ndebug(arrayFlatten(matriisi, 1)); // [1,2,[3,4],5,6]</code>",
	"func_arrayFlatten_arg_1": "Alkuperäinen pöytä.",
	"func_arrayFlatten_arg_2": "Suurin syvyys.",
	"func_arrayFlatten_return": "Uusi pöytä.",
	"func_arrayFoldLeft": "Pienennä taulukkoa <b>taulukko</b> [v1, v2, ..., vn] vasemmalta alkaen arvosta <b>v0</b> ja käyttämällä funktiota <b>f</b>. Yhtä:\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "Alkuperäinen pöytä.",
	"func_arrayFoldLeft_arg_2": "Sovellettava toiminto.",
	"func_arrayFoldLeft_arg_3": "Lähtöarvo.",
	"func_arrayFoldLeft_return": "Pienempi pöytä.",
	"func_arrayFoldRight": "Pienennä taulukkoa <b>taulukko</b> [v1, v2, ..., vn] oikealta alkaen arvosta <b>v0</b> ja käyttämällä funktiota <b>f</b>. Yhtä:\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "Alkuperäinen pöytä.",
	"func_arrayFoldRight_arg_2": "Sovellettava toiminto.",
	"func_arrayFoldRight_arg_3": "Lähtöarvo.",
	"func_arrayFoldRight_return": "Pienempi pöytä.",
	"func_arrayIter": "Kutsuu takaisinkutsun jokaiselle taulukon elementille. Jos takaisinsoittotoiminto ottaa yhden parametrin, lähetetään lähdetaulukon arvo, jos se ottaa kaksi parametria, lähetetään avain ja arvo.",
	"func_arrayIter_arg_1": "Alkuperäinen pöytä.",
	"func_arrayIter_arg_2": "Jokaiselle elementille vaadittu toiminto.",
	"func_arrayMap": "Palauttaa uuden taulukon, joka sisältää jokaiselle lähdetaulukon avaimelle takaisinkutsun palauttaman arvon. Jos takaisinsoittotoiminto ottaa yhden parametrin, lähetetään lähdetaulukon arvo, jos se ottaa kaksi parametria, lähetetään avain ja arvo.",
	"func_arrayMap_arg_1": "Alkuperäinen pöytä.",
	"func_arrayMap_arg_2": "Jokaiselle elementille vaadittu toiminto.",
	"func_arrayMap_return": "Uusi pöytä.",
	"func_arrayMax": "Palauttaa <b>taulukon</b> taulukon enimmäisarvoelementin.<br/>Lisätietoja taulukon enimmäisarvosta on kohdassa #sort.",
	"func_arrayMax_arg_1": "Taulukko, josta haetaan suurinta arvoa.",
	"func_arrayMax_return": "Suurin arvoinen kohde.",
	"func_arrayMin": "Palauttaa <b>taulukon</b> taulukon vähimmäisarvoelementin.<br/>Lisätietoja taulukon vähimmäisarvosta on kohdassa #sort.",
	"func_arrayMin_arg_1": "Taulukko, josta vähimmäisarvoa etsitään.",
	"func_arrayMin_return": "Vähimmäisarvotuote.",
	"func_arrayPartition": "Palauttaa uuden taulukon, joka sisältää kaksi listaa, joista ensimmäisessä ovat kaikki avain/arvo-parit, joille takaisinsoittofunktio palautti tosi, toisessa ovat kaikki muut. Jos takaisinsoittotoiminto ottaa yhden parametrin, lähetetään lähdetaulukon arvo, jos se ottaa kaksi parametria, lähetetään avain ja arvo.",
	"func_arrayPartition_arg_1": "Alkuperäinen pöytä.",
	"func_arrayPartition_arg_2": "Jokaiselle elementille vaadittu toiminto.",
	"func_arrayPartition_return": "Uusi pöytä.",
	"func_arraySort": "Lajittelee taulukon takaisinsoittotoiminnon määrittämän järjestyksen mukaan. Elementtejä verrataan kahdella, takaisinsoittofunktion tulee palauttaa arvot -1, 0 tai 1 riippuen siitä, onko ensimmäinen arvo ennen, samalla tasolla vai toisen arvon jälkeen. Jos takaisinsoittotoiminto ottaa 2 parametria, lähetetään kaksi arvoa, jos se kestää 4, lähetetään avain/arvo-parit.",
	"func_arraySort_arg_1": "Alkuperäinen pöytä",
	"func_arraySort_arg_2": "Lajittelutoiminto.",
	"func_arraySort_return": "Lajiteltu joukko",
	"func_asin": "Laskee <b>argumentin</b> arsinin välillä [0, #PI].",
	"func_asin_arg_1": "Luku, jonka arsini lasketaan.",
	"func_asin_return": "<b>argumentin</b> arsini.",
	"func_assocSort": "Lajittelee taulukon <b>taulukon</b> säilyttäen avain:arvo-assosioinnin <b>järjestyksen</b> mukaan.",
	"func_assocSort_arg_1": "Lajiteltava joukko.",
	"func_assocSort_arg_2": "Lajittelujärjestys: #SORT_ASC tai #SORT_DESC.",
	"func_atan": "Laskee <b>argumentin</b> arktangentin välillä [0, #PI].",
	"func_atan2": "Muuntaa suorakulmaiset koordinaatit (<b>x</b>, <b>y</b>) napakoordinaateiksi (<b>r</b>, <b>theta</b>). Tämä funktio palauttaa <b>theta</b>-kulman -#PI:n ja #PI:n välillä käyttämällä argumenttien merkkejä.",
	"func_atan2_arg_1": "Y-koordinaatti.",
	"func_atan2_arg_2": "X koordinaatti.",
	"func_atan2_return": "<b>theta</b>-kulma pisteen napakoordinaateina (x, y).",
	"func_atan_arg_1": "Luku, jonka arktangentti lasketaan.",
	"func_atan_return": "<b>argumentin</b> arctangentti.",
	"func_average": "Laskee taulukon <b>taulukon</b> sisältämien elementtien keskiarvon.",
	"func_average_arg_1": "Taulukko, jonka keskiarvon haluamme laskea.",
	"func_average_return": "Keskiarvo.",
	"func_canUseChip": "Määrittää, voiko entiteettisi käyttää <b>sirua</b> entiteetissä, jonka tunnus on <b>entity</b>.",
	"func_canUseChip_arg_1": "Testattavan sirun numero.",
	"func_canUseChip_arg_2": "Entiteettitunnus, jossa haluat käyttää sirua.",
	"func_canUseChip_return": "<i>tosi</i>, jos entiteettisi voi käyttää sirua, <i>false</i> muussa tapauksessa.",
	"func_canUseChipOnCell": "Määrittää, voiko entiteettisi käyttää <b>solussa</b> olevaa <b>sirua</b>.",
	"func_canUseChipOnCell_arg_1": "Testattavan sirun numero.",
	"func_canUseChipOnCell_arg_2": "Sen solun numero, jossa haluat käyttää sirua.",
	"func_canUseChipOnCell_return": "<i>tosi</i>, jos entiteettisi voi käyttää sirua, <i>false</i> muussa tapauksessa.",
	"func_canUseWeapon": "Määrittää, voiko entiteettisi ampua entiteettiä, jonka tunnus on <b>entity</b> aseella <b>aseella</b>.",
	"func_canUseWeapon_arg_1": "Ase testattavaksi. Oletusarvo tällä hetkellä varustetulle asellesi.",
	"func_canUseWeapon_arg_2": "Sen entiteetin tunnus, jonka haluat käynnistää.",
	"func_canUseWeapon_return": "<i>tosi</i>, jos entiteettisi voi käynnistyä, <i>false</i> muussa tapauksessa.",
	"func_canUseWeaponOnCell": "Määrittää, voiko entiteettisi ampua <b>soluun</b> <b>aseella</b>.",
	"func_canUseWeaponOnCell_arg_1": "Ase testattavaksi. Oletusarvo tällä hetkellä varustetulle asellesi.",
	"func_canUseWeaponOnCell_arg_2": "Sen solun numero, jonka haluat ampua.",
	"func_canUseWeaponOnCell_return": "<i>tosi</i>, jos entiteettisi voi käynnistyä, <i>false</i> muussa tapauksessa.",
	"func_cbrt": "Laske <b>luvun</b> kuutiojuuri",
	"func_cbrt_arg_1": "Luku, jonka kuutiojuuren haluamme laskea.",
	"func_cbrt_return": "<b>luvun</b> kuutiojuuri.",
	"func_ceil": "Palauttaa luvun <b>luvun</b> pyöristyksen ylöspäin.<br/>Löydät pyöristyksen alaspäin komennolla #floor ja pyöristyksen #round.",
	"func_ceil_arg_1": "Numero, jolle haluamme löytää ylemmän pyöristyksen.",
	"func_ceil_return": "Pyöristä <b>numero</b> ylöspäin.",
	"func_charAt": "Palauttaa kohdassa <b>sijainti</b> sijaitsevan merkkijonon <b>merkkijonon</b> merkin.<br/>Merkinjonon ensimmäinen merkki on paikassa 0.",
	"func_charAt_arg_1": "Merkkijono, josta haluamme hakea merkin.",
	"func_charAt_arg_2": "Etsittävän hahmon sijainti.",
	"func_charAt_return": "merkkijono, joka sisältää etsityn merkin, tai tyhjä merkkijono, jos sijainti on virheellinen.",
	"func_chipNeedLos": "Palauttaa, tarvitseeko <b>siru</b> näkökentän käyttöä.",
	"func_chipNeedLos_arg_1": "Testattavan sirun tunnus.",
	"func_chipNeedLos_return": "<i>tosi</i>, jos <b>siru</b> tarvitsee näköyhteyden, muutoin <i>false</i>.",
	"func_clearMarks": "Tyhjentää kaikki mark()- ja markText()-kentässä tekemät merkinnät.",
	"func_clone": "Kopioi <b>tason</b>-tasolla parametrina välitetyn <b>arvon</b> ja palauttaa kopion. Esimerkiksi taulukon tapauksessa klooni(taulukko, 1) kopioi taulukon, mutta ei sen elementtejä, klooni(taulukko, 2) kopioi taulukon sekä kaikki elementit.",
	"func_clone_arg_1": "Kloonattava arvo",
	"func_clone_arg_2": "Kloonattavien tasojen määrä",
	"func_clone_return": "Kloonattu arvo",
	"func_contains": "Määrittää, onko <b>haku</b> merkkijono <b>merkkijonon</b> sisällä.",
	"func_contains_arg_1": "Merkkijono, jossa haku suoritetaan.",
	"func_contains_arg_2": "Haettava merkkijono.",
	"func_contains_return": "tosi, jos <b>haku</b> on <b>merkkijonossa</b>, muussa tapauksessa epätosi.",
	"func_cos": "Laskee <b>kulman</b> kosinin.",
	"func_cos_arg_1": "Luku, jonka kosini lasketaan (radiaaneina).",
	"func_cos_return": "<b>kulman</b> kosini välissä [-1, 1].",
	"func_count": "Laskee <b>taulukon</b> elementtien määrän.",
	"func_count_arg_1": "Taulukko, jonka elementtien määrä lasketaan.",
	"func_count_return": "Taulukon elementtien lukumäärä.",
	"func_debug": "Tallentaa <b>objekti</b>-viestin henkilökohtaiseen lokiin, joka on käytettävissä raportissa taistelun lopussa.",
	"func_debug_arg_1": "Tallennettava viesti.",
	"func_debugC": "Tallentaa <b>objekti</b>-viestin henkilökohtaiseen lokiin, joka on saatavilla taistelun lopussa olevassa raportissa, väriltään <b>väri</b>.",
	"func_debugC_arg_1": "Tallennettava viesti.",
	"func_debugC_arg_2": "Viestin väri. Voit käyttää #getColor-toimintoa.",
	"func_debugE": "Tallentaa <b>objektin</b> virheilmoituksen henkilökohtaiseen lokiin, joka on saatavilla raportissa taistelun lopussa.<br />Virheilmoitukset näkyvät punaisina taisteluraportissa.",
	"func_debugE_arg_1": "Virheilmoitus kirjattavaksi.",
	"func_debugW": "Tallentaa <b>objektin</b> varoitusviestin henkilökohtaiseen lokiin, joka on käytettävissä raportissa taistelun lopussa. <br />Varoitusviestit näkyvät oranssina taisteluraportissa.",
	"func_debugW_arg_1": "Varoitusviesti tallennettavaksi.",
	"func_deleteRegister": "Poistaa avaimeen <b>avain</b> liittyvän rekisterin, jos sellainen on olemassa.",
	"func_deleteRegister_arg_1": "Poistettava rekisteriavain.",
	"func_endsWith": "Määrittää, päättyykö <b>merkkijono</b> merkkijonoon <b>pääte</b>.",
	"func_endsWith_arg_1": "Merkkijono, jossa haku suoritetaan",
	"func_endsWith_arg_2": "Haun pääte",
	"func_endsWith_return": "tosi, jos <b>merkkijono</b> päättyy <b>liitteeseen</b>",
	"func_exp": "Nostaa Eulerin luvun #E <b>luvun</b> potenssiin.",
	"func_exp_arg_1": "Eksponentti, johon #E nostetaan.",
	"func_exp_return": "#E** <b>numero</b>.",
	"func_fill": "Muuttaa taulukon <b>taulukon</b> koon <b>kokoon</b> ja täyttää kaikki sen laatikot <b>arvolla</b>.",
	"func_fill_arg_1": "Taulukko täytettäväksi.",
	"func_fill_arg_2": "Arvo jokaiselle taulukon neliölle.",
	"func_fill_arg_3": "Laatikoiden lukumäärä. Oletuksena nykyinen taulukon koko.",
	"func_floor": "Laskee <b>luvun</b> pyöristyksen alaspäin.<br/>Löydät pyöristyksen ylöspäin komennolla #ceil ja pyöristyksen #round.",
	"func_floor_arg_1": "Numero, jonka pyöristyksen alaspäin haluamme löytää.",
	"func_floor_return": "Pyöristetty alaspäin <b>numeroon</b>.",
	"func_getAbsoluteShield": "Palauttaa kokonaisuuden absoluuttisen suojan, jonka tunnus on <b>entity</b>. Jos haluat suoraan hakea entiteettisi absoluuttisen suojan, käytä #getAbsoluteShield() ilman parametreja.",
	"func_getAbsoluteShield_arg_1": "Sen entiteetin tunnus, jonka absoluuttinen suoja palautetaan.",
	"func_getAbsoluteShield_return": "Kokonaisuuden <b>entiteetin</b> absoluuttinen suoja.",
	"func_getAgility": "Palauttaa entiteetin ketteryyden, jonka tunnus on <b>entity</b>. Voit hakea entiteettisi ketteryyden suoraan käyttämällä #getAgility()-komentoa ilman parametreja.",
	"func_getAgility_arg_1": "Sen entiteetin tunnus, jonka ketteryys palautetaan.",
	"func_getAgility_return": "<b>Entiteetin</b> kokonaisuuden ketteryys.",
	"func_getAIID": "Palauttaa entiteetin <b>entiteetin</b> AI-tunnuksen. Käytä getAIID:tä ilman parametreja hakeaksesi tekoälytunnuksesi.",
	"func_getAIID_arg_1": "Sen entiteetin tunnus, jonka AI-tunnus palautetaan.",
	"func_getAIID_return": "<b>entiteetin</b> entiteetin AI-tunnus.",
	"func_getAIName": "Palauttaa entiteetin AI <b>entiteetin</b> nimen. Käytä getAINam()-komentoa ilman parametreja hakeaksesi tekoälysi nimen.",
	"func_getAIName_arg_1": "Sen entiteetin tunnus, jonka AI-nimi palautetaan.",
	"func_getAIName_return": "<b>entiteetin</b> entiteetin AI nimi.",
	"func_getAliveAllies": "Palauttaa joukon kaikkia liittolaisiasi elossa taistelussa.",
	"func_getAliveAllies_return": "Taulukko, joka sisältää kaikkien elävien liittolaistesi tunnukset.",
	"func_getAliveEnemies": "Palauttaa joukon kaikkia vihollisesi elossa taistelussa.",
	"func_getAliveEnemiesCount": "Palauttaa taistelussa elävien vihollisten määrän.",
	"func_getAliveEnemiesCount_return": "Elävien vihollisten määrä.",
	"func_getAliveEnemies_return": "Joukko, joka sisältää kaikkien elävien vihollistesi tunnukset.",
	"func_getAllChips": "Palauttaa luettelon kaikista pelin merkeistä.",
	"func_getAllChips_return": "Luettelo kaikista pelin merkeistä.",
	"func_getAllEffects": "Palauttaa luettelon kaikista pelin tehosteista.",
	"func_getAllEffects_return": "Luettelo kaikista pelin tehosteista.",
	"func_getAllWeapons": "Palauttaa luettelon kaikista pelin aseista.",
	"func_getAllWeapons_return": "Luettelo kaikista pelin aseista.",
	"func_getAlliedTurret": "Palauttaa joukkueesi tornin tunnuksen tai -1, jos sitä ei ole olemassa.",
	"func_getAlliedTurret_return": "Joukkueesi tornin tunnus.",
	"func_getEnemyTurret": "Palauttaa vihollisen tornin tunnuksen tai -1, jos sitä ei ole olemassa.",
	"func_getEnemyTurret_return": "Vihollisen tornin tunnus.",
	"func_getAllies": "Palauttaa joukon, joka sisältää liittolaiset ja entiteettisi.",
	"func_getAlliesCount": "Palauttaa taistelussa olevien liittolaisten lukumäärän.",
	"func_getAlliesCount_return": "Liittoutuneiden määrä.",
	"func_getAlliesLife": "Palauttaa liittolaistenne täydellisen terveyden.",
	"func_getAlliesLife_return": "Liittolaistenne täydellinen terveys.",
	"func_getAllies_return": "Liittoutuneiden taulukko ja entiteettisi.",
	"func_getBirthTurn": "Palauttaa taistelun vuoron, jossa <b>olio</b> ilmestyi. Palauttaa 1, jos se on esimerkiksi purjo, ja 5, jos se on kutsu käännöksellä 5.",
	"func_getBirthTurn_arg_1": "Sen entiteetin tunnus, jonka synnytysvuoro palautetaan.",
	"func_getBirthTurn_return": "Taisteluvuoro, jossa <b>entiteetti</b> ilmestyi.",
	"func_getBlue": "Palauttaa sinisen määrän värissä <b>väri</b> välillä 0–255. Esimerkiksi getBlue(#COLOR_BLUE) = 255 ja getBlue(#COLOR_GREEN) = 0.",
	"func_getBlue_arg_1": "Väri, jonka sininen hinta palautetaan.",
	"func_getBlue_return": "Sinisen määrä värissä <b>väri</b>",
	"func_getBulbChips": "Palauttaa luettelon siruista, jotka varustetaan <b>bulb_chip</b>-sirun kutsumalla polttimolla.",
	"func_getBulbChips_arg_1": "Sipulin kirppu.",
	"func_getBulbChips_return": "Lampun sirut, jotka <b>bulb_chip</b>-siru kutsuu.",
	"func_getCell": "Palauttaa solun, jossa entiteetti, jonka tunnus on <b>entity</b>, sijaitsee. Käytä getCell()-komentoa ilman parametreja solun hakemiseen.",
	"func_getCell_arg_1": "Sen entiteetin tunnus, jonka solu palautetaan.",
	"func_getCell_return": "Solunumero, jossa <b>entiteetti</b> sijaitsee.",
	"func_getCellContent": "Palauttaa solun sisällön, jonka tunnus on <b>solu</b>.",
	"func_getCellContent_arg_1": "Sen solun tunnus, jonka sisältö palautetaan.",
	"func_getCellContent_return": "<b>solun</b> sisältö: #CELL_EMPTY tyhjälle solulle, #CELL_ENTITY kokonaisuudelle, #CELL_OBSTACLE esteelle.",
	"func_getCellDistance": "Palauttaa kahden solun <b>solu1</b> ja <b>solu2</b> välisen etäisyyden. <br />Palautettu etäisyys ilmaistaan solujen lukumääränä, eikä siinä oteta huomioon solujen välisiä erilaisia esteitä.\nJos haluat saada etäisyyden linnuntietä, katso #getDistance ja saadaksesi selville kahden solun välisen polun etäisyyden välttäen esteitä, katso #getPathLength.",
	"func_getCellDistance_arg_1": "Aloitussolun tunnus.",
	"func_getCellDistance_arg_2": "Saapumissolun tunnus.",
	"func_getCellDistance_return": "Kahden solun <b>solu1</b> ja <b>solu2</b> välinen etäisyys.",
	"func_getCellFromXY": "Palauttaa asemassa olevan solun tunnuksen (<b>x</b>, <b>y</b>).",
	"func_getCellFromXY_arg_1": "Solun x-sijainti.",
	"func_getCellFromXY_arg_2": "Solun y-sijainti.",
	"func_getCellFromXY_return": "Solun tunnus sijainnissa (<b>x</b>, <b>y</b>), <b>null</b>, jos solua ei ole olemassa.",
	"func_getCellsToUseChip": "Palauttaa luettelon soluista, joista entiteettisi voi käyttää <b>entiteetin</b> sirua.",
	"func_getCellsToUseChip_arg_1": "Siru, jota entiteetti haluaa pystyä käyttämään.",
	"func_getCellsToUseChip_arg_2": "Kohdekokonaisuus.",
	"func_getCellsToUseChip_arg_3": "Ohettavien solujen joukko.",
	"func_getCellsToUseChip_return": "Luettelo soluista, joissa sirua voidaan käyttää.",
	"func_getCellsToUseChipOnCell": "Palauttaa luettelon soluista, joista entiteettisi voi käyttää <b>solussa</b> olevaa <b>sirua</b>.",
	"func_getCellsToUseChipOnCell_arg_1": "Siru, jota entiteetti haluaa pystyä käyttämään.",
	"func_getCellsToUseChipOnCell_arg_2": "Kohdesolu.",
	"func_getCellsToUseChipOnCell_arg_3": "Ohettavien solujen joukko.",
	"func_getCellsToUseChipOnCell_return": "Luettelo soluista, joissa sirua voidaan käyttää.",
	"func_getCellsToUseWeapon": "Palauttaa luettelon soluista, joista entiteettisi voi käyttää <b>asetta</b> entiteetissä <b>entiteetissä</b>.",
	"func_getCellsToUseWeapon_arg_1": "Ase testattavaksi. Oletusarvo tällä hetkellä varustetulle asellesi.",
	"func_getCellsToUseWeapon_arg_2": "Kohdekokonaisuus.",
	"func_getCellsToUseWeapon_arg_3": "Ohettavien solujen joukko. Oletuksena tyhjä taulukko.",
	"func_getCellsToUseWeapon_return": "Luettelo soluista, joista asetta voidaan käyttää.",
	"func_getCellsToUseWeaponOnCell": "Palauttaa luettelon soluista, joista entiteettisi voi käyttää <b>ase</b>-asetta <b>solussa</b>.",
	"func_getCellsToUseWeaponOnCell_arg_1": "Ase testattavaksi. Oletusarvo tällä hetkellä varustetulle asellesi.",
	"func_getCellsToUseWeaponOnCell_arg_2": "Kohdesolu.",
	"func_getCellsToUseWeaponOnCell_arg_3": "Ohettavien solujen joukko. Oletuksena tyhjä taulukko.",
	"func_getCellsToUseWeaponOnCell_return": "Luettelo soluista, joista asetta voidaan käyttää.",
	"func_getCellX": "Määrittää solun <b>solun</b> sijainnin X:ssä.",
	"func_getCellX_arg_1": "Solu, jonka sijainti X:ssä määritetään.",
	"func_getCellX_return": "Solun X-sijainti.",
	"func_getCellY": "Määrittää solun <b>solun</b> Y-paikan.",
	"func_getCellY_arg_1": "Solu, jonka sijainti Y:ssä määritetään.",
	"func_getCellY_return": "Solun Y-sijainti.",
	"func_getChipArea": "Palauttaa <b>sirun</b> tehostetyypin alueen.",
	"func_getChipArea_arg_1": "Siru, jonka vyöhyketyyppi palautetaan.",
	"func_getChipArea_return": "Sirun <b>chip</b> aluetyyppi AREA_*-vakioiden joukossa:\n<ul>\n<li>#AREA_POINT: yksi neliöalue</li>\n<li>#AREA_LASER_LINE: laserin viiva</li>\n<li>#AREA_CIRCLE_1: pyöreä alue, halkaisijaltaan 3 neliötä</li>\n<li>#AREA_CIRCLE_2: pyöreä alue, halkaisijaltaan 5 neliötä</li>\n<li>#AREA_CIRCLE_3: pyöreä alue, halkaisijaltaan 7 neliötä</li>\n</ul>",
	"func_getChipCooldown": "Palauttaa <b>sirun</b> sirun palautumisajan, joka on otettu markkinoilta.",
	"func_getChipCooldown_arg_1": "Siru, jonka jäähdytys palautetaan.",
	"func_getChipCooldown_return": "<b>sirun</b> jäähtyminen.",
	"func_getChipCost": "Palauttaa sirun <b>sirun</b> hinnan TP:nä.",
	"func_getChipCost_arg_1": "Siru, jonka hinta palautetaan.",
	"func_getChipCost_return": "<b>Sirun</b> hinta.",
	"func_getChipEffectiveArea": "Palauttaa luettelon soluista, joihin vaikuttaa, jos <b>sirua</b> käytetään <b>solussa</b> solusta <b>lähteestä</b>.",
	"func_getChipEffectiveArea_arg_1": "Testattava siru.",
	"func_getChipEffectiveArea_arg_2": "Kohdesolu.",
	"func_getChipEffectiveArea_arg_3": "Solu, josta sirua käytetään.",
	"func_getChipEffectiveArea_return": "Taulukko, joka sisältää kaikkien vaikutusten kohteena olevien solujen tunnukset.",
	"func_getChipEffects": "Palauttaa <b>sirun</b> vaikutukset.",
	"func_getChipEffects_arg_1": "Siru, jonka vaikutukset palautetaan.",
	"func_getChipEffects_return": "<b>sirun</b> vaikutukset. Sama palautusarvo kuin #getWeaponEffects-funktiolla.",
	"func_getChipFailure": "Palauttaa sirun <b>sirun</b> epäonnistumisriskin prosenttiosuuden.",
	"func_getChipFailure_arg_1": "Siru, jonka epäonnistumisprosentti palautetaan.",
	"func_getChipFailure_return": "<b>sirun</b> sirun epäonnistumisprosentti, kokonaisluku välillä <b>0</b> ja <b>100</b>.",
	"func_getChipMaxRange": "Palauttaa <b>sirun</b> sirun enimmäisalueen.",
	"func_getChipMaxRange_arg_1": "Siru, jonka maksimialue palautetaan.",
	"func_getChipMaxRange_return": "<b>Sirun</b> enimmäiskantama.",
	"func_getChipMaxScope": "Palauttaa <b>sirun</b> sirun enimmäisalueen.",
	"func_getChipMaxScope_arg_1": "Siru, jonka maksimialue palautetaan.",
	"func_getChipMaxScope_return": "<b>Sirun</b> enimmäiskantama.",
	"func_getChipMinRange": "Palauttaa <b>sirun</b> pienimmän kantaman.",
	"func_getChipMinRange_arg_1": "Siru, jonka vähimmäisetäisyys palautetaan.",
	"func_getChipMinRange_return": "<b>Sirun</b> vähimmäisetäisyys.",
	"func_getChipMinScope": "Palauttaa <b>sirun</b> pienimmän kantaman.",
	"func_getChipMinScope_arg_1": "Siru, jonka vähimmäisetäisyys palautetaan.",
	"func_getChipMinScope_return": "<b>Sirun</b> vähimmäisetäisyys.",
	"func_getChipLaunchType": "Palauttaa <b>sirun</b> käynnistystilan LAUNCH_TYPE_*-vakioiden joukossa.",
	"func_getChipLaunchType_arg_1": "Sen sirun tunnus, jonka heittotila palautetaan.",
	"func_getChipLaunchType_return": "<b>sirun</b> heittotapa.",
	"func_getChipName": "Palauttaa sirun nimen <b>siru</b>.",
	"func_getChipName_arg_1": "Siru, jonka nimi palautetaan.",
	"func_getChipName_return": "<b>sirun</b> nimi.",
	"func_getChips": "Palauttaa entiteetin luettelomerkit, jonka tunnus on <b>entity</b>.",
	"func_getChips_arg_1": "Sen entiteetin tunnus, jonka luodit palautetaan.",
	"func_getChips_return": "Taulukko, joka sisältää kokonaisuuden <b>entiteetin</b> luettelomerkit.",
	"func_getChipTargets": "Palauttaa entiteetit, joihin vaikuttaa, jos <b>sirua</b> käytetään solussa <b>solussa</b>.",
	"func_getChipTargets_arg_1": "Testattava siru.",
	"func_getChipTargets_arg_2": "Kohdesolu.",
	"func_getChipTargets_return": "Taulukko, joka sisältää kaikkien vaikutusten kohteena olevien entiteettien tunnukset.",
	"func_getColor": "Palauttaa parametreina annettua väriä (<b>punainen</b>, <b>vihreä</b>, <b>sininen</b>) vastaavan kokonaisluvun.",
	"func_getColor_arg_1": "Punainen arvo välillä 0 - 255.",
	"func_getColor_arg_2": "Vihreä arvo välillä 0 - 255.",
	"func_getColor_arg_3": "Sininen arvo välillä 0 - 255.",
	"func_getColor_return": "int, joka vastaa parametrina annettua väriä.",
	"func_getCooldown": "Palauttaa <b>entiteetin</b> entiteetin nykyisen <b>sirun</b> jäähtymisen.",
	"func_getCooldown_arg_1": "Siru, jonka nykyinen jäähdytys palautetaan.",
	"func_getCooldown_arg_2": "Entiteetti, jonka jäähdytys palautetaan.",
	"func_getCooldown_return": "Sirun <b>sirun</b> nykyinen jäähtyminen, tämä on kierrosten määrä ennen kuin siru tulee käyttökelpoiseksi, <b>0</b>, jos se on tällä hetkellä käyttökelpoinen.",
	"func_getCores": "Palauttaa entiteetin ytimien määrän, jonka tunnus on <b>entity</b>.",
	"func_getCores_arg_1": "Entiteetti, jonka ytimien määrä palautetaan.",
	"func_getCores_return": "<b>entiteetin</b> entiteetin ytimien lukumäärä.",
	"func_getDate": "Palauttaa ottelun päivämäärän muodossa pp/KK/vvvv.",
	"func_getDate_return": "Taistelun päivämäärä.",
	"func_getTime": "Palauttaa taisteluajan muodossa HH:mm:ss.",
	"func_getTime_return": "Taistelun aika.",
	"func_getTimestamp": "Palauttaa ottelun aikaleiman, joka on yhtä monta sekuntia 1. tammikuuta 1970 lähtien.",
	"func_getTimestamp_return": "Taistelun aikaleima.",
	"func_getDamageReturn": "Palauttaa entiteetin vahingonpalautusprosentin, jonka tunnus on <b>entity</b>.",
	"func_getDamageReturn_arg_1": "Sen tahon tunnus, jolta vahinkoviite palautetaan.",
	"func_getDamageReturn_return": "Tunnuksella <b>entity</b> olevan entiteetin vahinkojen palautusprosentti (%).",
	"func_getDeadAllies": "Palauttaa kuolleet ystävälliset olennot.",
	"func_getDeadAllies_return": "Taulukko liittoutuneista kuolleista olennoista.",
	"func_getDeadEnemies": "Palauttaa kuolleet vihollisolennot.",
	"func_getDeadEnemiesCount": "Palauttaa taistelussa kuolleiden vihollisten määrän.",
	"func_getDeadEnemiesCount_return": "Kuolleiden vihollisten määrä.",
	"func_getDeadEnemies_return": "Kuolleiden vihollisolioiden taulukko.",
	"func_getDistance": "Laskee etäisyyden linnuntietä kahden solun <b>solu1</b> ja <b>solu2</b> välillä. <br />Jos haluat saada etäisyyden solujen lukumääränä, katso #getCellDistance, ja saadaksesi selville kahden eri esteitä välttävän solun välisen reitin pituuden kohdasta #getPathLength.",
	"func_getDistance_arg_1": "Aloitussolu.",
	"func_getDistance_arg_2": "Saapumissolu.",
	"func_getDistance_return": "Kahden solun välinen etäisyys linnuntietä.",
	"func_getPassiveEffects": "Palauttaa luettelon passiivisista vaikutuksista entiteetille, jonka tunnus on <b>entity</b>. Voit hakea entiteettisi passiivisten tehosteiden luettelon suoraan käyttämällä #getPassiveEffects()-toimintoa ilman parametreja.",
	"func_getPassiveEffects_arg_1": "Sen entiteetin tunnus, jonka passiivisten vaikutusten luettelo palautetaan.",
	"func_getPassiveEffects_return": "Luettelo tällä hetkellä <b>entiteetissä</b> olevista passiivisista tehosteista.\nPassiivisten tehosteiden luettelo on tehosteet sisältävä matriisi.\nTehoste on itsessään joukko 7 laatikkoa, joiden muoto on: [<b>tyyppi</b>, <b>arvo</b>, <b>caster_id</b>, <b>turns</b>, <b>kriittinen</b>, <b>tuotetunnus</b>, <b>target_id</b>]. Se on sama rakenne kuin #getEffects-funktion palauttamat klassiset tehosteet.",
	"func_getEnemies": "Palauttaa vihollisen entiteetit (eläviä tai kuolleita) taisteluun.",
	"func_getEnemiesCount": "Palauttaa vihollisten lukumäärän taistelussa.",
	"func_getEnemiesCount_return": "Vihollisten määrä.",
	"func_getEnemiesLife": "Laskee kaikkien viholliskokonaisuuksien osumapisteiden summan.",
	"func_getEnemiesLife_return": "Vihollisjoukkueen osumapisteiden summa.",
	"func_getEnemies_return": "Taulukko, joka sisältää kaikkien vihollisolioiden tunnukset.",
	"func_getEntityTurnOrder": "Palauttaa arvon välillä 1 - n (peleissä olevien entiteettien lukumäärä), joka ilmaisee <b>entiteetin</b> sijainnin toistojärjestyksessä.",
	"func_getEntityTurnOrder_arg_1": "Sen kokonaisuuden tunnus, jonka toistojärjestys palautetaan",
	"func_getEntityTurnOrder_return": "Aseta kokonaisuuden <b>kokonaisuus</b> pelijärjestykseen",
	"func_getFarmerID": "Palauttaa entiteetin <b>entiteetin</b> kasvattajan tunnuksen.",
	"func_getFarmerID_arg_1": "Sen entiteetin tunnus, jonka kasvattajatunnus palautetaan.",
	"func_getFarmerID_return": "<b>entiteetin</b> entiteetin kasvattajan tunnus.",
	"func_getFarmerCountry": "Palauttaa entiteetin <b>entiteetin</b> kasvattajan maan.",
	"func_getFarmerCountry_arg_1": "Sen yhteisön tunnus, jonka kasvattajamaa palautetaan.",
	"func_getFarmerCountry_return": "<b>Kohteen</b> entiteetin kasvattajan maa tai \"?\" jos ei ole ilmoitettu.",
	"func_getFarmerName": "Palauttaa <b>entiteetin</b> entiteetin kasvattajan nimen.",
	"func_getFarmerName_arg_1": "Sen entiteetin tunnus, jonka kasvattajan nimi palautetaan.",
	"func_getFarmerName_return": "<b>entiteetin</b> entiteetin kasvattajan nimi.",
	"func_getFarthestAlly": "Määrittää kokonaisuutesi kaukaisimman liittolaisen linnuntietä.",
	"func_getFarthestAlly_return": "Kaikkein ystävällisimmän kokonaisuuden tunnus.",
	"func_getFarthestEnemy": "Määrittää linnuntietä kaukaisimman vihollisen entiteettistäsi.",
	"func_getFarthestEnemy_return": "Kauimpana olevan vihollisen kokonaisuuden tunnus.",
	"func_getFightID": "Palauttaa nykyisen taistelutunnuksen.",
	"func_getFightID_return": "Nykyisen taistelun tunnus.",
	"func_getFightContext": "Palauttaa nykyisen taistelun kontekstin.",
	"func_getFightContext_return": "Taistelun kontekstista riippuen: testitaistelu (#FIGHT_CONTEXT_TEST), areenataistelu (#FIGHT_CONTEXT_GARDEN), turnaustaistelu (#FIGHT_CONTEXT_TOURNAMENT), haastetaistelu (#FIGHT_CONTEXT_CHALLENGE), Battle Royale (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "Palauttaa nykyisen taistelutyypin.",
	"func_getFightType_return": "Riippuen taistelutyypistä: Soolotaistelu (#FIGHT_TYPE_SOLO), Farmer-taistelu (#FIGHT_TYPE_FARMER), Joukkuetaistelu (#FIGHT_TYPE_TEAM), Battle Royale (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "Palauttaa entiteetin vahvuuden, jonka tunnus on <b>entity</b>.",
	"func_getForce_arg_1": "Entiteetti, jonka voimat palautetaan.",
	"func_getForce_return": "<b>Entiteetin</b> entiteetin vahvuus.",
	"func_getFrequency": "Palauttaa entiteetin tiheyden, jonka tunnus on <b>entity</b>. Käytä getFrequency()-toimintoa ilman parametreja taajuuden hakemiseen.",
	"func_getFrequency_arg_1": "Entiteetti, jonka taajuus palautetaan.",
	"func_getFrequency_return": "<b>entiteetin</b> kokonaisuuden esiintymistiheys.",
	"func_getGreen": "Palauttaa vihreän määrän värissä <b>väri</b> välillä 0–255. Esimerkiksi getGreen(#COLOR_GREEN) = 255 ja getGreen(#COLOR_RED) = 0.",
	"func_getGreen_arg_1": "Väri, jonka vihreä hinta palautetaan.",
	"func_getGreen_return": "Vihreän määrä värissä <b>väri</b>",
	"func_getInstructionsCount": "Palauttaa ohjeiden määrän, jotka entiteettisi on suorittanut nykyisen käännöksen aikana.",
	"func_getInstructionsCount_return": "Entiteettisi suorittamien ohjeiden määrä nykyisen käännöksen aikana.",
	"func_getLaunchedEffects": "Palauttaa luettelon vaikutuksista, jotka on aiheuttanut entiteetti, jonka tunnus on <b>entity</b>.",
	"func_getLaunchedEffects_arg_1": "Sen entiteetin tunnus, jonka aiheuttamien vaikutusten luettelo palautetaan.",
	"func_getLaunchedEffects_return": "Luettelo vaikutuksista, jotka on aiheuttanut entiteetti, jonka tunnus on <b>entity</b> ja joka on samaa muotoa kuin #getEffectsin palauttama taulukko.",
	"func_getLeek": "Palauttaa entiteettisi tunnuksen.",
	"func_getLeek_return": "Yhteisösi tunnus.",
	"func_getEntity": "Palauttaa entiteettisi tunnuksen.",
	"func_getEntity_return": "Yhteisösi tunnus.",
	"func_getLeekID": "Palauttaa id:n <b>purjo</b> todellisen tunnuksen.",
	"func_getLeekID_arg_1": "Sen purjan tunnus, jonka todellinen tunnus palautetaan.",
	"func_getLeekID_return": "Purjo-<b>kokonaisuuden</b> todellinen tunnus.",
	"func_getLeekOnCell": "Palauttaa solussa <b>solussa</b> olevan entiteetin.",
	"func_getLeekOnCell_arg_1": "Solu, jonka entiteetin haluamme noutaa.",
	"func_getLeekOnCell_return": "Solun entiteetin tunnus tai -1, jos solussa ei ole entiteettiä.",
	"func_getEntityOnCell": "Palauttaa solussa <b>solussa</b> olevan entiteetin.",
	"func_getEntityOnCell_arg_1": "Solu, jonka entiteetin haluamme noutaa.",
	"func_getEntityOnCell_return": "Solun entiteetin tunnus tai -1, jos solussa ei ole entiteettiä.",
	"func_getLevel": "Palauttaa entiteettitason tunnuksella <b>entity</b>.",
	"func_getLevel_arg_1": "Sen entiteetin tunnus, jonka taso palautetaan.",
	"func_getLevel_return": "Sen entiteetin taso, jonka tunnus on <b>entity</b>.",
	"func_getLife": "Palauttaa entiteetin nykyisen käyttöiän tunnuksella <b>entity</b>. Käytä getLife()-toimintoa ilman parametreja elämäsi hakemiseen.",
	"func_getLife_arg_1": "Sen kokonaisuuden tunnus, jonka henki palautetaan.",
	"func_getLife_return": "<b>Entiteetin</b> entiteetin nykyinen käyttöikä.",
	"func_getMagic": "Palauttaa entiteetin taian, jonka tunnus on <b>entity</b>. Käytä getMagic()-funktiota ilman parametreja saadaksesi taikuuden.",
	"func_getMagic_arg_1": "Sen entiteetin tunnus, jonka taikuutta palautetaan.",
	"func_getMagic_return": "Tunnusolion <b>entity</b> taika.",
	"func_getMapType": "Palauttaa maaston tyypin, jolla taistelu käydään (tehdas, aavikko, metsä jne.), vakioiden #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIE ja #MAP_BEACH joukosta.",
	"func_getMapType_return": "Maaston tyyppi.",
	"func_getMessageAuthor": "Palauttaa viestin <b>viestin</b> luoneen entiteetin tunnuksen.",
	"func_getMessageAuthor_arg_1": "Viesti, jonka kirjoittaja palautetaan.",
	"func_getMessageAuthor_return": "Viestin <b>viestin</b> kirjoittajan tunnus.",
	"func_getMessageParams": "Palauttaa viestiparametrien joukon <b>viesti</b>.",
	"func_getMessageParams_arg_1": "Viesti, jonka parametrit palautetaan.",
	"func_getMessageParams_return": "<b>viesti</b>-viestin parametrit.",
	"func_getMessages": "Palauttaa <b>entiteetin</b> entiteettiviestitaulukon.",
	"func_getMessages_arg_1": "Entiteetti, jonka viestit palautetaan.",
	"func_getMessages_return": "Viestisi taulukko.<br>Viesti itsessään esitetään muodossa a\nlomakkeen joukko: [<b>tekijä</b>, <b>tyyppi</b>, <b>parametrit</b>]<br>\nErityyppiset viestit esitetään vakioilla:\n<ul>\n<li>#MESSAGE_HEAL: hoitopyyntö</li>\n<li>#MESSAGE_ATTACK: hyökkäyspyyntö</li>\n<li>#MESSAGE_BUFF_FORCE: pakota tehostuspyyntö</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "Palauttaa viestin tyypin <b>viesti</b>.",
	"func_getMessageType_arg_1": "Viesti, jonka tyyppi palautetaan (#MESSAGE_HEAL, #MESSAGE_ATTACK jne.).",
	"func_getMessageType_return": "Viestityyppi <b>viesti</b>.",
	"func_getMP": "Palauttaa <b>entiteetin</b> entiteetin nykyisen liikepistemäärän. Käytä getMP():tä ilman parametreja PM-viestien noutamiseen.",
	"func_getMP_arg_1": "Sen entiteetin tunnus, jonka PM-määrä palautetaan.",
	"func_getMP_return": "<b>Entiteetin</b> entiteetin MP-numero.",
	"func_getName": "Palauttaa entiteetin nimen, jonka tunnus on <b>entity</b>.",
	"func_getName_arg_1": "Sen entiteetin tunnus, jonka nimi palautetaan.",
	"func_getName_return": "Kokonaisuuden nimi <b>entity</b>.",
	"func_getNearestAlly": "Palauttaa entiteettiäsi lähimmän ystävällisen kokonaisuuden.",
	"func_getNearestAlly_return": "Lähimmän ystävällisen tahon tunnus.",
	"func_getNearestAllyTo": "Palauttaa lähimmän liittoutuneen entiteetin parametrina annettuun entiteettiin.",
	"func_getNearestAllyTo_arg_1": "Sen entiteetin tunnus, jonka lähimmän liittolaisen haluamme tietää.",
	"func_getNearestAllyToCell": "Palauttaa lähimmän liittoutuneen entiteetin parametrina annettuun soluun.",
	"func_getNearestAllyToCell_arg_1": "Sen solun tunnus, jonka lähimmän liittolaisen haluamme tietää.",
	"func_getNearestAllyToCell_return": "Lähimmän ystävällisen tahon tunnus.",
	"func_getNearestAllyTo_return": "Lähimmän ystävällisen tahon tunnus.",
	"func_getNearestEnemy": "Palauttaa entiteettiäsi lähimmän vihollisen entiteetin.",
	"func_getNearestEnemy_return": "Lähimmän vihollisen kokonaisuuden tunnus.",
	"func_getNearestEnemyTo": "Palauttaa lähimmän vihollisen entiteetin parametrina annetulle entiteetille.",
	"func_getNearestEnemyTo_arg_1": "Sen kokonaisuuden tunnus, jonka lähimmän vihollisen haluamme tietää.",
	"func_getNearestEnemyToCell": "Palauttaa lähimmän vihollisen entiteetin parametrina annettuun soluun.",
	"func_getNearestEnemyToCell_arg_1": "Sen solun tunnus, jonka lähimmän vihollisen haluamme tietää.",
	"func_getNearestEnemyToCell_return": "Lähimmän vihollisen kokonaisuuden tunnus.",
	"func_getNearestEnemyTo_return": "Lähimmän vihollisen kokonaisuuden tunnus.",
	"func_getNextPlayer": "Palauttaa sen entiteetin tunnuksen, joka pelataan nykyisen pelaajan jälkeen.",
	"func_getNextPlayer_return": "Seuraava pelaaja.",
	"func_getObstacles": "Palauttaa luettelon maaston estetiloista.",
	"func_getObstacles_return": "Matriisi, joka sisältää estesolujen tunnukset.",
	"func_getOperations": "Palauttaa entiteettisi kuluttamien toimintojen määrän vuoronsa alusta. Tämän luvun on oltava pienempi kuin #OPERATIONS_LIMIT, jotta kokonaisuus ei kaatuisi.",
	"func_getOperations_return": "Entiteettisi käyttämien toimintojen määrä vuoronsa alusta.",
	"func_getPath": "Palauttaa polun välttäen esteitä kahden solun <b>solu1</b> ja <b>solu2</b> välillä, jos sellainen on olemassa, jättäen huomioimatta taulukon <b>ignoredCells</b> sisältämät solut. Jos pelaaja on ohitetussa solussa, polku voi kulkea hänen yli.<br /><br />\nAloitussolu <b>solu1</b> ei koskaan ole osa tuloksena olevaa polkua. Solu <b>solu2</b> on osa tuloksena olevaa polkua silloin ja vain, jos se on tyhjä tai jos <b>ignoredCells</b> jättää sen huomiotta.<br /><br />\nJos näiden kahden solun välillä ei ole polkua, <b>getPath</b> palauttaa <i>nollan</i>.",
	"func_getPath_arg_1": "Aloitussolu.",
	"func_getPath_arg_2": "Saapumissolu.",
	"func_getPath_arg_3": "Ohitettavien solujen joukko.",
	"func_getPath_return": "Matriisi, joka sisältää solut, jotka muodostavat reitin kahden solun välillä.",
	"func_getPathLength": "Palauttaa kahden solun <b>solu1</b> ja <b>solu2</b> välisen polun, välttäen esteitä ja jättäen huomioimatta taulukon <b>ignoredCells</b> sisältämät solut. Tämä funktio vastaa funktiota <i>count(getPath(<b>solu1</b>, <b>cell2</b>, <b>ignoreedCells</b>))</i>.\nJos pelaaja on ohitetussa solussa, polku voi kulkea hänen yli.<br /><br />\nAloitussolua <b>solu1</b> ei koskaan lasketa tulokseen. Solu <b>solu2</b> lasketaan tulokseen silloin ja vain, jos se on tyhjä tai jos <b>ignoredCells</b> jättää sen huomiotta.<br /><br />\nJos kahden solun välillä ei ole polkua, <b>getPathLength</b> palauttaa <i>nollan</i>.",
	"func_getPathLength_arg_1": "Aloitussolu.",
	"func_getPathLength_arg_2": "Saapumissolu.",
	"func_getPathLength_arg_3": "Ohitettavien solujen joukko.",
	"func_getPathLength_return": "Polun pituus <b>solun1</b> ja <b>solun2</b> välillä.",
	"func_getPreviousPlayer": "Palauttaa entiteetin tunnuksen, joka pelasi ennen nykyistä pelaajaa.",
	"func_getPreviousPlayer_return": "Edellinen pelaaja.",
	"func_getRed": "Palauttaa punaisen määrän värissä <b>väri</b> välillä 0–255. Esimerkiksi getRed(#COLOR_RED) = 255 ja getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "Väri, jonka punainen hinta palautetaan.",
	"func_getRed_return": "Punaisen määrä <b>värissä</b>",
	"func_getRegister": "Palauttaa arvon, joka on tallennettu kokonaisuusrekisteriin, joka liittyy avaimeen <b>avain</b> tai </i>null</i>, jos rekisteriä ei ole olemassa.",
	"func_getRegister_arg_1": "Rekisteriavain, jonka arvo palautetaan.",
	"func_getRegister_return": "<b>avain</b>-rekisteriin tallennettu arvo.",
	"func_getRegisters": "Palauttaa kokonaisuusrekisterien joukon assosiatiivisena taulukkona [<i>rekisteriavain</i>:<i>rekisteriarvo</i>]. Esimerkki: <code>debug(getRegisters());\n// Näyttää esimerkiksi:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "Assosiatiivinen taulukko, joka vastaa kokonaisuuden kaikkia rekistereitä.",
	"func_getRelativeShield": "Palauttaa entiteetin suhteellisen suojan, jonka tunnus on <b>entity</b>. Jos haluat suoraan hakea entiteettisi suhteellisen suojan, käytä #getRelativeShield() ilman parametreja.",
	"func_getRelativeShield_arg_1": "Sen entiteetin tunnus, jonka suhteellinen suoja palautetaan.",
	"func_getRelativeShield_return": "<b>entiteetin</b> suhteellinen suoja, kokonaisluku väliltä <b>0</b> ja <b>100</b>.",
	"func_getResistance": "Palauttaa entiteetin vastuksen, jonka tunnus on <b>entity</b>. Käytä getResistance()-komentoa ilman parametreja resistanssin palauttamiseen.",
	"func_getResistance_arg_1": "Sen entiteetin tunnus, jonka vastus palautetaan.",
	"func_getResistance_return": "Sen entiteetin vastus, jonka tunnus on <b>entity</b>.",
	"func_getScience": "Palauttaa entiteetin tieteen, jonka tunnus on <b>entity</b>. Käytä getScience():tä ilman parametreja tieteen hakemiseen.",
	"func_getScience_arg_1": "Sen entiteetin tunnus, jonka tiede palautetaan.",
	"func_getScience_return": "Tiede identiteetistä <b>entiteetistä</b>.",
	"func_getStrength": "Palauttaa entiteetin vahvuuden, jonka tunnus on <b>entity</b>. Käytä getStrength()-funktiota ilman parametreja voimasi hakemiseen.",
	"func_getStrength_arg_1": "Entiteetti, jonka voimat palautetaan.",
	"func_getStrength_return": "<b>Entiteetin</b> entiteetin vahvuus.",
	"func_getPower": "Palauttaa entiteetin tehon, jonka tunnus on <b>entity</b>.",
	"func_getPower_arg_1": "Entiteetti, jonka valta palautetaan.",
	"func_getPower_return": "<b>entiteetin</b> voima.",
	"func_getSummoner": "Palauttaa entiteetin, joka kutsui entiteetin <b>entiteetin</b>, jos se on kutsu.",
	"func_getSummoner_arg_1": "Sen tahon tunnus, jonka kutsuja erotetaan.",
	"func_getSummoner_return": "Sen entiteetin tunnus, joka kutsui <b>entiteetin</b>, jos se on kutsu, <i>null</i> muussa tapauksessa.",
	"func_getSummons": "Palauttaa luettelon entiteetin, jonka tunnus on <b>entiteetti</b>, tällä hetkellä elossa olevien kutsujen id.",
	"func_getSummons_arg_1": "Sen tahon tunnus, jonka kutsu palautetaan.",
	"func_getSummons_return": "Luettelo entiteetin kutsutunnuksista, jonka tunnus on <b>entity</b>.",
	"func_getTeamID": "Palauttaa entiteetin <b>entiteetin</b> tiimitunnuksen.",
	"func_getTeamID_arg_1": "Sen entiteetin tunnus, jonka tiimitunnus palautetaan.",
	"func_getTeamID_return": "Yhteisön <b>entiteetin</b> tiimitunnus.",
	"func_getTeamName": "Palauttaa entiteetin <b>entiteetin</b> tiimin nimen.",
	"func_getTeamName_arg_1": "Sen entiteetin tunnus, jonka joukkueen nimi palautetaan.",
	"func_getTeamName_return": "<b>entiteetin</b> kokonaisuuden tiimin nimi.",
	"func_getTotalLife": "Palauttaa kokonaisuuden kokonaiskeston, jonka tunnus on <b>entity</b>. Käytä getTotalLife()-toimintoa ilman parametreja noutaaksesi koko elämäsi.",
	"func_getTotalLife_arg_1": "Sen kokonaisuuden tunnus, jonka koko elinikä palautetaan.",
	"func_getTotalLife_return": "Kokonaisuuden koko elinikä.",
	"func_getTotalMP": "Palauttaa kokonaisuuden <b>entiteetin</b> liikepisteiden enimmäismäärän.",
	"func_getTotalMP_arg_1": "Sen entiteetin tunnus, jonka enimmäisliikepisteet palautetaan.",
	"func_getTotalMP_return": "<b>entiteetin</b> liikepisteiden enimmäismäärä.",
	"func_getTotalTP": "Palauttaa kokonaisuuden <b>entiteetin</b> käännekohtien enimmäismäärän.",
	"func_getTotalTP_arg_1": "Sen entiteetin tunnus, jonka enimmäismäärä käännepisteitä palautetaan.",
	"func_getTotalTP_return": "<b>entiteetin</b> kokonaisuuden käännekohtien enimmäismäärä.",
	"func_getTP": "Palauttaa entiteetin <b>entiteetin</b> käännepisteiden määrän. Käytä getTP():tä ilman parametreja hakeaksesi PT:t.",
	"func_getTP_arg_1": "Sen entiteetin tunnus, jonka PT:t palautetaan.",
	"func_getTP_return": "<b>entiteetin</b> PT-numero.",
	"func_getTurn": "Palauttaa nykyisen taisteluvuoron. Kierrosten enimmäismäärä on #MAX_TURNS.",
	"func_getTurn_return": "Nykyinen taistelun käänne.",
	"func_getType": "Palauttaa entiteetin <b>entiteetin</b> entiteettityypin.",
	"func_getType_arg_1": "Sen entiteetin tunnus, jonka tyyppi palautetaan.",
	"func_getType_return": "<b>entiteetin</b> entiteettityyppi:<ul><li>#ENTITY_LEEK, jos se on purjo.</li><li>#ENTITY_BULB, jos se on sipuli.</li><li #ENTITY_TURRET, jos se on torni.</li></ul>",
	"func_getWeapon": "Palauttaa tällä hetkellä varustetun aseen <b>olion</b>.",
	"func_getWeapon_arg_1": "Sen entiteetin tunnus, jonka nykyinen ase palautetaan.",
	"func_getWeapon_return": "Tällä hetkellä varustetun aseen tunnus <b>entiteetissä</b>, tyhjä, jos entiteetillä ei ole varustettua asetta tai entiteettiä ei ole olemassa.",
	"func_getWeaponArea": "Palauttaa <b>ase</b>-aseen vaikutustyypin alueen.",
	"func_getWeaponArea_arg_1": "Ase, jonka aluetyyppi palautetaan.",
	"func_getWeaponArea_return": "<b>Aseen</b> aseen aluetyyppi AREA_*-vakioiden joukossa:\n<ul>\n<li>#AREA_POINT: yksi neliöalue</li>\n<li>#AREA_LASER_LINE: laserin viiva</li>\n<li>#AREA_CIRCLE_1: pyöreä alue, halkaisijaltaan 3 solua</li>\n<li>#AREA_CIRCLE_2: pyöreä alue, halkaisijaltaan 5 neliötä</li>\n<li>#AREA_CIRCLE_3: pyöreä alue, halkaisijaltaan 7 neliötä</li>\n</ul>",
	"func_getWeaponCost": "Palauttaa <b>ase</b>-aseen TP-hinnan.",
	"func_getWeaponCost_arg_1": "Sen aseen tunnus, jonka hinta palautetaan.",
	"func_getWeaponCost_return": "<b>aseen</b> TP-hinta.",
	"func_getWeaponEffectiveArea": "Palauttaa luettelon soluista, joihin vaikuttaa, jos <b>asetta</b> käytetään solussa <b>solussa</b> solusta <b>josta</b>.",
	"func_getWeaponEffectiveArea_arg_1": "Ase testattavaksi.",
	"func_getWeaponEffectiveArea_arg_2": "Kohdesolu.",
	"func_getWeaponEffectiveArea_arg_3": "Solu, josta ase ammutaan.",
	"func_getWeaponEffectiveArea_return": "Taulukko, joka sisältää kaikkien vaikutusten kohteena olevien solujen tunnukset.",
	"func_getWeaponPassiveEffects": "Palauttaa aseen <b>aseen</b> passiiviset vaikutukset.",
	"func_getWeaponPassiveEffects_arg_1": "Sen aseen tunnus, jonka passiiviset vaikutukset palautetaan.",
	"func_getWeaponPassiveEffects_return": "Joukko, joka sisältää <b>ase</b> aseen vaikutukset. Jokainen tehoste on itsessään joukko muotoja\n[tyyppi, min, maksimi, käännökset, kohteet, muuntajat]. Nämä tehosteet ovat samat kuin #getWeaponEffectsin palauttamat tehosteet.",
	"func_getWeaponFailure": "Palauttaa <b>ase</b> aseen epäonnistumisen mahdollisuuden.",
	"func_getWeaponFailure_arg_1": "Sen aseen tunnus, jonka epäonnistumisprosentti palautetaan.",
	"func_getWeaponFailure_return": "<b>ase</b> aseen puuttumisprosentti, kokonaisluku välillä <b>0</b> ja <b>100</b>.",
	"func_getWeaponMaxRange": "Palauttaa <b>aseen</b> aseen maksimikantaman.",
	"func_getWeaponMaxRange_arg_1": "Sen aseen tunnus, jonka maksimikantama palautetaan.",
	"func_getWeaponMaxRange_return": "<b>ase-aseen</b> enimmäiskantama.",
	"func_getWeaponMaxScope": "Palauttaa <b>aseen</b> aseen maksimikantaman.",
	"func_getWeaponMaxScope_arg_1": "Sen aseen tunnus, jonka maksimikantama palautetaan.",
	"func_getWeaponMaxScope_return": "<b>ase-aseen</b> enimmäiskantama.",
	"func_getWeaponMinRange": "Palauttaa <b>ase-aseen</b> vähimmäisetäisyyden.",
	"func_getWeaponMinRange_arg_1": "Sen aseen tunnus, jonka vähimmäisetäisyys palautetaan.",
	"func_getWeaponMinRange_return": "<b>Aseen</b> aseen vähimmäisetäisyys.",
	"func_getWeaponMinScope": "Palauttaa <b>ase-aseen</b> vähimmäisetäisyyden.",
	"func_getWeaponMinScope_arg_1": "Sen aseen tunnus, jonka vähimmäisetäisyys palautetaan.",
	"func_getWeaponMinScope_return": "<b>Aseen</b> aseen vähimmäisetäisyys.",
	"func_getWeaponLaunchType": "Palauttaa <b>ase</b>-aseen laukaisutilan LAUNCH_TYPE_*-vakioiden joukossa.",
	"func_getWeaponLaunchType_arg_1": "Sen aseen tunnus, jonka heittotapa palautetaan. Oletusarvo tällä hetkellä varustetulle asellesi.",
	"func_getWeaponLaunchType_return": "<b>ase-aseen</b> heittotapa.",
	"func_getWeaponName": "Palauttaa <b>aseen</b> aseen nimen.",
	"func_getWeaponName_arg_1": "Sen aseen tunnus, jonka nimi palautetaan.",
	"func_getWeaponName_return": "<b>ase-aseen</b> nimi.",
	"func_getWeapons": "Palauttaa entiteetin aseet, jonka tunnus on <b>entity</b>.",
	"func_getWeapons_arg_1": "Sen entiteetin tunnus, jonka aseet palautetaan.",
	"func_getWeapons_return": "Joukko, joka sisältää <b>entiteetin</b> asetunnukset.",
	"func_getWeaponTargets": "Palauttaa entiteetit, joihin vaikuttaa, jos <b>asetta</b> käytetään solussa <b>solussa</b>.",
	"func_getWeaponTargets_arg_1": "Ase testattavaksi.",
	"func_getWeaponTargets_arg_2": "Kohdesolu.",
	"func_getWeaponTargets_return": "Taulukko, joka sisältää kaikkien vaikutusten kohteena olevien entiteettien tunnukset.",
	"func_getWisdom": "Palauttaa entiteetin viisauden, jonka tunnus on <b>entity</b>. Käytä getWisdom()-funktiota ilman parametreja viisautesi hakemiseen.",
	"func_getWisdom_arg_1": "Sen entiteetin tunnus, jonka viisaus palautetaan.",
	"func_getWisdom_return": "Tunnuksella <b>entity</b> olevan entiteetin viisaus.",
	"func_hypot": "Palauttaa oikean kolmion hypotenuusan, jonka sivut ovat x ja y. Vastaa <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "X-arvo.",
	"func_hypot_arg_2": "Y-arvo.",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "Määrittää, onko <b>elementti</b>-elementti <b>array</b>-taulukossa.",
	"func_inArray_arg_1": "Hakutaulukko.",
	"func_inArray_arg_2": "Haettava kohde.",
	"func_inArray_return": "<i>true</i>, jos elementti on taulukossa, <i>false</i> muussa tapauksessa.",
	"func_include": "Sisällytä tekoäly nimellä <b>ai</b> nykyiseen tekoälyyn. <br><br><b>Varoitus</b>, include-funktiota tulee kutsua vain päälohkossa ja sen parametrin on oltava suoraan kirjoitettu merkkijono\nkoodissa. Lisätietoja on opetusohjelman osiossa: <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a>.",
	"func_include_arg_1": "Sisällytettävän tekoälyn nimi.",
	"func_indexOf": "Määrittää merkkijonon <b>haku</b> ensimmäisen esiintymän sijainnin merkkijonossa <b>merkkijono</b> alkaen kohdasta <b>aloitus</b>.",
	"func_indexOf_arg_1": "Merkkijono, jossa haku suoritetaan.",
	"func_indexOf_arg_2": "Etsittävä merkkijono.",
	"func_indexOf_arg_3": "Haun lähtökohta.",
	"func_indexOf_return": "Haun <b>haku</b> ensimmäisen esiintymän sijainti <b>merkkijonossa</b> alkaen <b>aloitus</b>, -1, jos merkkijonoa ei löytynyt.",
	"func_insert": "Lisää <b>elementin</b> <b>taulukkoon</b> kohtaan <b>sijainti</b>.",
	"func_insert_arg_1": "Taulukko, johon elementti lisätään.",
	"func_insert_arg_2": "Lisättävä kohde.",
	"func_insert_arg_3": "Lisäyksen sijainti.",
	"func_isAlive": "Määrittää, onko <b>entiteetti</b> elossa. Vastaa <i>getLife(<b>entity</b>) > 0</i>.",
	"func_isAlive_arg_1": "Testattavan entiteetin tunnus.",
	"func_isAlive_return": "<i>tosi</i>, jos <b>entiteetti</b> on elossa, <i>false</i>, jos kuollut.",
	"func_isAlly": "Määrittää, onko <b>entiteetti</b> liittolainen.",
	"func_isAlly_arg_1": "Testattavan entiteetin tunnus.",
	"func_isAlly_return": "<i>tosi</i>, jos <b>entiteetti</b> on liittolainen tai sinä, <i>epätosi</i>, jos se on vihollinen.",
	"func_isChip": "Määrittää, onko arvo alibittiä edustava vakio.<br><br>isChip(CHIP_RAGE) = tosi;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "Määritettävä määrä.",
	"func_isChip_return": "<i>true</i>, jos arvo on alibittivakio.",
	"func_isDead": "Määrittää, onko <b>entiteetti</b> kuollut. Vastaa <i>getLife(<b>entity</b>) == 0</i>.",
	"func_isDead_arg_1": "Testattavan entiteetin tunnus.",
	"func_isDead_return": "<i>tosi</i>, jos <b>entiteetti</b> on kuollut, <i>false</i>, jos elossa.",
	"func_isEmpty": "Määrittää, onko <b>taulukko</b> tyhjä. Vastaa <i>count(<b>array</b>) == 0</i>.",
	"func_isEmpty_arg_1": "Testattava joukko.",
	"func_isEmptyCell": "Määrittää, onko solu tyhjä.",
	"func_isEmptyCell_arg_1": "Testattava solu.",
	"func_isEmptyCell_return": "<i>tosi</i>, jos solu on tyhjä, <i>false</i> muussa tapauksessa.",
	"func_isEmpty_return": "<i>tosi</i>, jos taulukko on tyhjä, <i>false</i> muussa tapauksessa.",
	"func_isEnemy": "Määrittää, onko <b>entiteetti</b> vihollisesi.",
	"func_isEnemy_arg_1": "Testattavan entiteetin tunnus.",
	"func_isEnemy_return": "<i>tosi</i>, jos <b>entiteetti</b> on vihollinen, <i>false</i>, jos se on liittolainen tai sinä itse.",
	"func_isInlineChip": "Määrittää, voidaanko <b>sirua</b> käyttää vain verkossa.",
	"func_isInlineChip_arg_1": "Testattavan sirun tunnus.",
	"func_isInlineChip_return": "<i>tosi</i>, jos siru on vain online-tilassa, <i>false</i> muuten.",
	"func_isInlineWeapon": "Määrittää, voidaanko <b>asetta</b> käyttää vain verkossa.",
	"func_isInlineWeapon_arg_1": "Testattavan aseen tunnus.",
	"func_isInlineWeapon_return": "<i>tosi</i>, jos asetta voidaan käyttää vain verkossa, <i>false</i> muuten.",
	"func_isLeek": "Määrittää, onko solun <b>solu</b> sisältö kokonaisuus.",
	"func_isLeek_arg_1": "Testattava solu.",
	"func_isLeek_return": "<i>tosi</i>, jos solu sisältää entiteetin, <i>false</i> muussa tapauksessa.",
	"func_isEntity": "Määrittää, onko solun <b>solu</b> sisältö kokonaisuus.",
	"func_isEntity_arg_1": "Testattava solu.",
	"func_isEntity_return": "<i>tosi</i>, jos solu sisältää entiteetin, <i>false</i> muussa tapauksessa.",
	"func_isObstacle": "Määrittää, onko solun <b>solu</b> sisältö este.",
	"func_isObstacle_arg_1": "Testattava solu.",
	"func_isObstacle_return": "<i>tosi</i>, jos solu sisältää esteen, <i>false</i> muussa tapauksessa.",
	"func_isOnSameLine": "Määrittää, ovatko kaksi solua <b>solu1</b> ja <b>solu2</b> samalla rivillä.",
	"func_isOnSameLine_arg_1": "Ensimmäinen solu.",
	"func_isOnSameLine_arg_2": "Toinen solu.",
	"func_isOnSameLine_return": "<i>tosi</i>, jos molemmat solut ovat samalla rivillä, <i>epätosi</i> muussa tapauksessa.",
	"func_isStatic": "Palauttaa onko <b>entiteetti</b> staattinen vai ei. Staattista kokonaisuutta ei voi liikkua tai siirtää.",
	"func_isStatic_arg_1": "Testattavan entiteetin tunnus.",
	"func_isStatic_return": "<i>tosi</i>, jos <b>entiteetti</b> on staattinen, <i>epätosi</i> muuten.",
	"func_isSummon": "Palauttaa onko <b>entiteetti</b> kutsu vai ei.",
	"func_isSummon_arg_1": "Testattavan entiteetin tunnus.",
	"func_isSummon_return": "<i>tosi</i>, jos <b>entiteetti</b> on kutsu, <i>false</i> muussa tapauksessa.",
	"func_isWeapon": "Määrittää, onko arvo asetta edustava vakio.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false.",
	"func_isWeapon_arg_1": "Määritettävä määrä.",
	"func_isWeapon_return": "<i>true</i>, jos arvo on asevakio.",
	"func_join": "Yhdistää useita elementtejä merkkijonoksi erottamalla ne <b>liima</b>-erottimella.",
	"func_join_arg_1": "Joukko yhdistettäviä kohteita.",
	"func_join_arg_2": "Elementin erotin.",
	"func_join_return": "Tuloksena oleva yhdistämisen merkkijono.",
	"func_jsonDecode": "Purkaa merkkijonon <b>json</b> LeekScript-objektiksi (numero, merkkijono, taulukko...).",
	"func_jsonDecode_arg_1": "Purettava JSON-merkkijono.",
	"func_jsonDecode_return": "Purettu LeekScript-objekti.",
	"func_jsonEncode": "Koodaa <b>objektin</b>-objektin JSON-merkkijonoksi.",
	"func_jsonEncode_arg_1": "JSON-muotoon koodattava objekti.",
	"func_jsonEncode_return": "JSON-koodattu merkkijono.",
	"func_keySort": "Lajittelee <b>taulukon</b> avainten mukaan <b>järjestykseen</b>.",
	"func_keySort_arg_1": "Lajiteltava joukko.",
	"func_keySort_arg_2": "Lajittelujärjestys: #SORT_ASC tai #SORT_DESC.",
	"func_length": "Palauttaa <b>merkkijonon</b> pituuden.",
	"func_length_arg_1": "Merkkijono, jonka pituus palautetaan.",
	"func_length_return": "<b>merkkijonon</b> pituus.",
	"func_lineOfSight": "Tarkistaa näkökentän <b>aloitus</b>- ja <b>loppusolun</b> välillä jättäen huomiotta <b>ignoredEntities</b>-yksiköt.",
	"func_lineOfSight_arg_1": "Aloitussolu.",
	"func_lineOfSight_arg_2": "Kohdesolu.",
	"func_lineOfSight_arg_3": "Entiteetti tai luettelo kohteista, jotka jätetään huomiotta.",
	"func_lineOfSight_return": "Palauttaa <i>true</i>, jos näköyhteys on vapaa.",
	"func_listen": "Palauttaa aiempien entiteettien sanoma()-taulukon muodossa [entity_id, message].",
	"func_listen_return": "Edellisten sanojen()-joukko.",
	"func_log": "Laskee luvun <b>luku</b> luonnollisen logaritmin.",
	"func_log_arg_1": "Luku alueella ]0; +∞[.",
	"func_log_return": "<b>luvun</b> luonnollinen logaritmi.",
	"func_log10": "Laskee luvun <b>luku</b> 10 kantalogaritmin.",
	"func_log10_arg_1": "Luku alueella ]0; +∞[.",
	"func_log10_return": "<b>luvun</b> 10 peruslogaritmi.",
	"func_log2": "Laskee luvun <b>luku</b> 2 kantalogaritmin.",
	"func_log2_arg_1": "Luku alueella ]0; +∞[.",
	"func_log2_return": "<b>luvun</b> 2 peruslogaritmi.",
	"func_mark": "Merkitsee yhden tai useamman parametrissa ilmoitetun värin solun maahan parametrissa ilmoitetun kierrosmäärän verran. Tämä merkintä näkyy vain kokonaisuuden kasvattajalle.",
	"func_mark_arg_1": "Merkittävä solu tai useiden solujen ryhmä",
	"func_mark_arg_2": "Merkintäväri",
	"func_mark_arg_3": "Merkinnän kesto",
	"func_mark_return": "Palauttaa todeksi, jos kaikki meni hyvin",
	"func_markText": "Kirjoittaa tekstin yhteen tai useampaan soluun parametrissa ilmoitetun värin kanssa maahan parametrissa ilmoitetun kierrosmäärän ajan. Nämä tekstit näkyvät vain entiteetin kasvattajalle.",
	"func_markText_arg_1": "Solu tai useiden solujen ryhmä, johon kirjoitetaan",
	"func_markText_arg_2": "Kirjoitettava teksti (enintään 10 merkkiä)",
	"func_markText_arg_3": "Tekstin väri",
	"func_markText_arg_4": "Tekstin kesto",
	"func_markText_return": "Palauttaa todeksi, jos kaikki meni hyvin",
	"func_max": "Palauttaa suuremman arvon kahden luvun <b>a</b> ja <b>b</b> välillä.",
	"func_max_arg_1": "Numero.",
	"func_max_arg_2": "Numero.",
	"func_max_return": "Suurempi luku välillä <b>a</b> ja <b>b</b>.",
	"func_min": "Palauttaa pienemmän arvon kahden luvun <b>a</b> ja <b>b</b> välillä.",
	"func_min_arg_1": "Numero.",
	"func_min_arg_2": "Numero.",
	"func_min_return": "Pienempi luku välillä <b>a</b> ja <b>b</b>.",
	"func_moveAwayFrom": "Siirtää entiteettiäsi pois toisesta <b>entiteetistä</b> käyttämällä enimmäisliikepisteitä <b>mp</b>.",
	"func_moveAwayFrom_arg_1": "Entiteetti, josta entiteettisi tulisi muuttaa pois.",
	"func_moveAwayFrom_arg_2": "Käytettävien MP-tiedostojen enimmäismäärä. Oletuksena ei rajoitusta.",
	"func_moveAwayFrom_return": "Käytettyjen liikepisteiden määrä.",
	"func_moveAwayFromCell": "Siirtää entiteettiäsi yhden <b>solun</b> solun päähän käyttämällä <b>mp</b> liikepisteitä.",
	"func_moveAwayFromCell_arg_1": "Solu, josta entiteettisi pitäisi siirtyä pois.",
	"func_moveAwayFromCell_arg_2": "Käytettävien MP-tiedostojen enimmäismäärä. Oletuksena ei rajoitusta.",
	"func_moveAwayFromCell_return": "Käytettyjen liikepisteiden määrä.",
	"func_moveAwayFromCells": "Siirtää entiteetin pois joukosta <b>soluja</b> käyttämällä enimmäisliikepisteitä <b>mp</b>.",
	"func_moveAwayFromCells_arg_1": "Matriisi, joka sisältää solut, joista kokonaisuuden tulisi siirtyä pois.",
	"func_moveAwayFromCells_arg_2": "Käytettävien MP-tiedostojen enimmäismäärä. Oletuksena ei rajoitusta.",
	"func_moveAwayFromCells_return": "Käytettyjen liikepisteiden määrä.",
	"func_moveAwayFromLeeks": "Siirtää entiteettiäsi pois <b>entiteettien</b> joukosta käyttämällä enimmäisliikepisteitä <b>mp</b>.",
	"func_moveAwayFromLeeks_arg_1": "Taulukko, joka sisältää niiden entiteettien tunnukset, joista entiteettisi pitäisi siirtyä pois.",
	"func_moveAwayFromLeeks_arg_2": "Käytettävien MP-tiedostojen enimmäismäärä. Oletuksena ei rajoitusta.",
	"func_moveAwayFromLeeks_return": "Käytettyjen liikepisteiden määrä.",
	"func_moveAwayFromEntities": "Siirtää entiteettiäsi pois <b>entiteettien</b> joukosta käyttämällä enimmäisliikepisteitä <b>mp</b>.",
	"func_moveAwayFromEntities_arg_1": "Taulukko, joka sisältää niiden entiteettien tunnukset, joista entiteettisi pitäisi siirtyä pois.",
	"func_moveAwayFromEntities_arg_2": "Käytettävien MP-tiedostojen enimmäismäärä. Oletuksena ei rajoitusta.",
	"func_moveAwayFromEntities_return": "Käytettyjen liikepisteiden määrä.",
	"func_moveAwayFromLine": "Siirtää entiteetin pois kahden solun <b>solu1</b> ja <b>solu2</b> määrittelemästä viivasta käyttämällä enintään <b>mp</b> liikepistettä.",
	"func_moveAwayFromLine_arg_1": "Solu 1.",
	"func_moveAwayFromLine_arg_2": "Solu 2.",
	"func_moveAwayFromLine_arg_3": "Käytettävien MP-tiedostojen enimmäismäärä. Oletuksena ei rajoitusta.",
	"func_moveAwayFromLine_return": "Käytettyjen liikepisteiden määrä.",
	"func_moveToward": "Tuo entiteettiäsi lähemmäksi toista <b>entiteettiä</b> käyttämällä enimmäisliikepisteitä <b>mp</b>.",
	"func_moveToward_arg_1": "Entiteetti, jonka yksikkösi tulisi lähestyä.",
	"func_moveToward_arg_2": "Käytettävien MP-tiedostojen enimmäismäärä.",
	"func_moveToward_return": "Käytettyjen liikepisteiden määrä.",
	"func_moveTowardCell": "Siirtää entiteettiäsi yhden <b>solun</b> solua lähemmäs käyttämällä enimmäisliikepisteitä <b>mp</b>.",
	"func_moveTowardCell_arg_1": "Solu, jonka ominaisuuden tulisi lähestyä.",
	"func_moveTowardCell_arg_2": "Käytettävien MP-tiedostojen enimmäismäärä. Oletuksena ei rajoitusta.",
	"func_moveTowardCell_return": "Käytettyjen liikepisteiden määrä.",
	"func_moveTowardCells": "Siirtää entiteetin lähemmäksi <b>solujen</b> solujoukkoa käyttämällä enimmäisliikepisteitä <b>mp</b>.",
	"func_moveTowardCells_arg_1": "Taulukko, joka sisältää solut, joita entiteetin tulee lähestyä.",
	"func_moveTowardCells_arg_2": "Käytettävien MP-tiedostojen enimmäismäärä. Oletuksena ei rajoitusta.",
	"func_moveTowardCells_return": "Käytettyjen liikepisteiden määrä.",
	"func_moveTowardLeeks": "Tuo entiteettiäsi lähemmäksi <b>kokonaisuuksien</b> joukkoa käyttämällä enimmäisliikepisteitä <b>mp</b>.",
	"func_moveTowardLeeks_arg_1": "Taulukko, joka sisältää niiden entiteettien tunnukset, joita entiteettisi tulee lähestyä.",
	"func_moveTowardLeeks_arg_2": "Käytettävien MP-tiedostojen enimmäismäärä. Oletuksena ei rajoitusta.",
	"func_moveTowardLeeks_return": "Käytettyjen liikepisteiden määrä.",
	"func_moveTowardEntities": "Tuo entiteettiäsi lähemmäksi <b>kokonaisuuksien</b> joukkoa käyttämällä enimmäisliikepisteitä <b>mp</b>.",
	"func_moveTowardEntities_arg_1": "Taulukko, joka sisältää niiden entiteettien tunnukset, joita entiteettisi tulee lähestyä.",
	"func_moveTowardEntities_arg_2": "Käytettävien MP-tiedostojen enimmäismäärä. Oletuksena ei rajoitusta.",
	"func_moveTowardEntities_return": "Käytettyjen liikepisteiden määrä.",
	"func_moveTowardLine": "Tuo entiteettiäsi lähemmäksi kahden solun <b>solu1</b> ja <b>solu2</b> määrittelemää linjaa käyttämällä enimmäisliikepisteitä <b>mp</b>.",
	"func_moveTowardLine_arg_1": "Solu 1.",
	"func_moveTowardLine_arg_2": "Solu 2.",
	"func_moveTowardLine_arg_3": "Käytettävien MP-tiedostojen enimmäismäärä. Oletuksena ei rajoitusta.",
	"func_moveTowardLine_return": "Käytettyjen liikepisteiden määrä.",
	"func_number": "Muuntaa arvon luvuksi. Jos arvo on merkkijono, numerofunktio yrittää muuntaa sen luvuksi, jos arvo on jo numero, funktio palauttaa luvun ja minkä tahansa muun tyypin kohdalla se palauttaa <i>nollan</i>.",
	"func_number_arg_1": "Arvo, joka muunnetaan luvuksi.",
	"func_number_return": "Muunnettu luku.",
	"func_pause": "Keskeyttää taistelun vain toimintoa käyttävän entiteetin kasvattajalle.",
	"func_pop": "Poistaa viimeisen solun <b>taulukosta</b> ja palauttaa vastaavan elementin.",
	"func_pop_arg_1": "Taulukko, jonka viimeinen laatikko poistetaan.",
	"func_pop_return": "Tuote, joka oli vanhassa viimeisessä laatikossa.",
	"func_pow": "Nostaa kantaluvun eksponenttipotenssiin.",
	"func_pow_arg_1": "Basso.",
	"func_pow_arg_2": "Näytteilleasettaja.",
	"func_pow_return": "<b>kanta</b> korotettu <b>eksponentin</b> potenssiin.",
	"func_push": "Lisää <b>elementin</b>-elementin <b>taulukon</b> loppuun.",
	"func_pushAll": "Liittää kaikki <b>elements</b>-taulukon elementit <b>taulukon</b> loppuun.",
	"func_pushAll_arg_1": "Taulukko, johon haluamme lisätä elementit.",
	"func_pushAll_arg_2": "Lisättävät kohteet.",
	"func_push_arg_1": "Taulukko, johon haluamme lisätä elementin.",
	"func_push_arg_2": "Lisättävä kohde.",
	"func_rand": "Palauttaa todellisen satunnaisluvun välillä 0 (mukaan lukien) ja 1 (ei sisällä).",
	"func_randFloat": "Palauttaa todellisen satunnaisluvun välillä <b>a</b> (mukaan lukien) ja <b>b</b> (ei sisällä).",
	"func_randFloat_arg_1": "Alaraja.",
	"func_randFloat_arg_2": "Yläraja.",
	"func_randFloat_return": "Satunnaisluku alueella [a; b[.",
	"func_randInt": "Palauttaa satunnaisen kokonaisluvun välillä <b>a</b> (mukaan lukien) ja <b>b</b> (ei sisällä).",
	"func_randInt_arg_1": "Alaraja.",
	"func_randInt_arg_2": "Yläraja.",
	"func_randInt_return": "Satunnainen kokonaisluku alueella [a; b[.",
	"func_rand_return": "Satunnaisluku alueella [0; 1[.",
	"func_remove": "Poistaa elementin taulukosta <b>taulukosta</b> sijainnista <b>sijainti</b> ja palauttaa poistetun elementin.",
	"func_remove_arg_1": "Taulukko, josta haluamme poistaa elementin.",
	"func_remove_arg_2": "Poistettavan elementin sijainti.",
	"func_removeElement": "Poistaa <b>elementin</b> ensimmäisen esiintymän <b>taulukosta</b>. Jos elementtiä ei löydy, taulukkoa ei muokata.",
	"func_removeElement_arg_1": "Taulukko, josta haluamme poistaa elementin.",
	"func_removeElement_arg_2": "Etsittävä ja sitten poistettava kohde.",
	"func_removeKey": "Poistaa elementin taulukosta <b>matriisi</b>, joka liittyy avaimeen <b>avain</b>.",
	"func_removeKey_arg_1": "Taulukko, josta haluamme poistaa elementin.",
	"func_removeKey_arg_2": "Poistettavaan kohteeseen liittyvä avain.",
	"func_remove_return": "Elementti poistettu taulukosta.",
	"func_replace": "Korvaa kaikki <b>haun</b> esiintymät sanalla <b>replace</b> merkkijonossa <b>merkkijono</b>.",
	"func_replace_arg_1": "Merkkijono, jossa korvaukset tehdään.",
	"func_replace_arg_2": "Korvattava osamerkkijono.",
	"func_replace_arg_3": "Korvaava merkkijono.",
	"func_replace_return": "Tulosmerkkijono vaihtoineen.",
	"func_resurrect": "Käyttää CHIP_RESURRECTION-sirua herättääkseen kuolleen kokonaisuuden, jonka tunnus on <b>entity</b>, solussa <b>solussa</b>.",
	"func_resurrect_arg_1": "Elvytettävän kokonaisuuden tunnus.",
	"func_resurrect_arg_2": "Solu, jossa entiteetti syntyy uudelleen.",
	"func_resurrect_return": "Sirun käynnistämisen tulos vakioiden USE_* joukossa.",
	"func_reverse": "Kääntää <b>taulukon</b>.",
	"func_reverse_arg_1": "Käänteinen taulukko.",
	"func_round": "Palauttaa <b>luvun</b> pyöristyksen.<br/>Löydät pyöristyksen alaspäin #floor ja pyöristyksen ylöspäin #ceil.",
	"func_round_arg_1": "Numero, jonka pyöristyksen haluamme löytää.",
	"func_round_return": "Pyöristetty <b>luvulla</b>.",
	"func_say": "Anna olemuksesi puhua.",
	"func_say_arg_1": "Viesti, jonka yhteisösi ilmoittaa areenalla.",
	"func_search": "Etsii elementin <b>element</b> taulukosta <b>array</b> alkaen paikasta <b>aloitus</b>.",
	"func_search_arg_1": "Hakutaulukko.",
	"func_search_arg_2": "Haettava kohde.",
	"func_search_arg_3": "Haun aloituspaikka.",
	"func_search_return": "Elementin <b>element</b> ensimmäisen esiintymisen sijainti <b>taulukossa</b> alkaen <b>alusta</b>, nolla, jos elementtiä ei löydy.",
	"func_sendAll": "Lähetä viesti koko tiimillesi.",
	"func_sendAll_arg_1": "Lähetettävän viestin tyyppi (katso MESSAGE_* vakiot).",
	"func_sendAll_arg_2": "Viestin parametrit, jotka voivat olla mikä tahansa arvo.",
	"func_sendTo": "Lähettää viestin entiteetille, jonka tunnus on <b>entity</b>.",
	"func_sendTo_arg_1": "Sen entiteetin tunnus, jolle viesti lähetetään.",
	"func_sendTo_arg_2": "Lähetettävän viestin tyyppi (katso MESSAGE_* vakiot).",
	"func_sendTo_arg_3": "Viestin parametrit, jotka voivat olla mikä tahansa arvo.",
	"func_sendTo_return": "<i>tosi</i>, jos lähetys onnistui, <i>false</i>, jos tapahtui virhe.",
	"func_setRegister": "Tallentaa <b>arvon</b> <b>avain</b>-rekisteriin.\nAvain ja arvo ovat merkkijonoja, joissa saa olla enintään <i>100</i> ja <i>5000</i> merkkiä. Purjossa voi olla enintään <i>100</i> rekisteriä, tallentaminen uuteen rekisteriin ei toimi, jos kaikki rekisterit\novat jo kiireisiä.",
	"func_setRegister_arg_1": "Rekisteriavain, johon arvo tallennetaan.",
	"func_setRegister_arg_2": "Tallennettava arvo.",
	"func_setRegister_return": "<i>tosi</i>, jos toiminto onnistui, <i>false</i> muussa tapauksessa.",
	"func_setWeapon": "Varusta <b>ase</b> aseellasi.",
	"func_setWeapon_arg_1": "Varustettavan aseen tunnus.",
	"func_shift": "Poistaa ensimmäisen solun <b>taulukosta</b> ja palauttaa vastaavan elementin.",
	"func_shift_arg_1": "Taulukko, jonka ensimmäinen solu poistetaan.",
	"func_shift_return": "Elementti, joka oli vanhassa ensimmäisessä laatikossa.",
	"func_show": "Näyttää pelaajille <b>solun</b> värillä <b>väri</b> kentällä 1 kierroksen ajan. Tämän ominaisuuden käyttö maksaa 1 PT.",
	"func_show_arg_1": "Näytettävä solu",
	"func_show_arg_2": "Merkintäväri",
	"func_show_return": "Palauttaa todeksi, jos kaikki meni hyvin",
	"func_shuffle": "Sekoittaa laudan satunnaisesti.",
	"func_shuffle_arg_1": "Sekoitustaulu.",
	"func_signum": "Määrittää luvun <b>numero</b> etumerkin.",
	"func_signum_arg_1": "Numero, jonka merkki määritetään.",
	"func_signum_return": "1, jos luku on positiivinen, 0, jos luku on nolla, ja -1, jos luku on negatiivinen.",
	"func_sin": "Laskee parametrina välitetyn kulman <b>kulman</b> sinin",
	"func_sin_arg_1": "Kulma, jonka sini lasketaan",
	"func_sin_return": "Palauttaa kulman <b>kulman</b> sinin",
	"func_sort": "Lajittelee <b>taulukon</b> nousevaan tai laskevaan järjestykseen. Oletusarvoinen nouseva järjestys on:\n<ul>\n<li>Kaikki totuusarvot (ensin epätosi ja sitten tosi)</li>\n<li>Kaikki numerot (järjestyksessä pienimmästä suurimpaan)</li>\n<li>Kaikki kanavat (aakkosjärjestyksessä)</li>\n<li>Kaikki nollaelementit.</li>\n</ul>",
	"func_sort_arg_1": "Lajiteltava joukko.",
	"func_sort_arg_2": "#SORT_ASC lajitella <b>taulukko</b> nousevaan järjestykseen tai #SORT_DESC lajitellaksesi sen laskevaan järjestykseen.",
	"func_split": "Jakaa merkkijonon <b>merkkijono</b> alimerkkijonoihin, jotka on erotettu <b>erottimella</b>.",
	"func_split_arg_1": "Katkaisuketju.",
	"func_split_arg_2": "Merkkijono, joka rajaa siirtymisen elementistä toiseen.",
	"func_split_arg_3": "Matriisielementtien enimmäismäärä.",
	"func_split_return": "Taulukko, joka sisältää löydetyt alimerkkijonot.",
	"func_sqrt": "Laskee luvun <b>luku</b> neliöjuuren.",
	"func_sqrt_arg_1": "Numero, jonka juuri lasketaan.",
	"func_sqrt_return": "<b>luvun</b> neliöjuuri.",
	"func_startsWith": "Tarkistaa, alkaako <b>merkkijono</b> <b>etuliitteellä</b>.",
	"func_startsWith_arg_1": "Hakumerkkijono.",
	"func_startsWith_arg_2": "Haettu etuliite.",
	"func_startsWith_return": "Tosi, jos <b>merkkijono</b> alkaa <b>etuliitteellä</b>.",
	"func_string": "Muuntaa arvon merkkijonoksi.<br>\nJos arvo on jo merkkijono, se palautetaan.<br>\nJos arvo on numero x, palautetaan \"x\".<br>\nJos arvo on taulukko, palautetaan merkkijono muotoa \"[avain1: arvo1, avain2: arvo2, ...]\".<br>\nJos arvo on Boolen arvo, \"true\" ja \"false\" palautetaan arvolle tosi ja epätosi.<br>\nJos arvo on nolla, palautetaan \"null\".",
	"func_string_arg_1": "Merkkijonoksi muutettava arvo.",
	"func_string_return": "Muunnettu merkkijono.",
	"func_subArray": "Palauttaa <b>taulukon</b> alitaulukon, joka alkaa paikasta <b>alku</b> ja päättyy kohtaan <b>loppu</b>.",
	"func_subArray_arg_1": "Lähdetaulukko.",
	"func_subArray_arg_2": "Aloitusindeksi.",
	"func_subArray_arg_3": "Loppuindeksi.",
	"func_subArray_return": "Alajoukko.",
	"func_substring": "Palauttaa merkkijonon <b>merkkijonon</b> alimerkkijonon, joka alkaa merkistä hakemiston <b>alkussa</b> ja jonka koko on <b>pituus</b>.",
	"func_substring_arg_1": "Katkaisuketju.",
	"func_substring_arg_2": "Aloitusmerkkihakemisto.",
	"func_substring_arg_3": "Alimerkkijonon pituus.",
	"func_substring_return": "Alimerkkijono.",
	"func_sum": "Palauttaa kaikkien taulukon <b>taulukon</b> numeeristen elementtien summan.",
	"func_sum_arg_1": "Taulukko summaan.",
	"func_summon": "Kutsuu solun <b>solussa</b> olevan sirun <b>sirun</b> määrittämän entiteetin, jolla on IA:lle funktio <b>ai</b>.",
	"func_summon_arg_1": "Kutsumiseen käytetty siru. Sirun on oltava kutsutyyppinen siru, ja sen on oltava varustettu kutsutoimintoa käyttävässä entiteetissä.",
	"func_summon_arg_2": "Solun tai kutsun pitäisi ilmestyä.",
	"func_summon_arg_3": "Invocation AI, funktiona.",
	"func_summon_return": "Kutsufunktiolla on sama palautus kuin #useChip-funktiolla.",
	"func_sum_return": "Taulukon elementtien summa.",
	"func_tan": "Laskee kulman <b>kulman</b> tangentin (radiaaneina).",
	"func_tan_arg_1": "Kulma radiaaneina.",
	"func_tan_return": "<b>kulman</b> tangentti.",
	"function_category_array": "Luettelot",
	"function_category_chip": "Kirput",
	"function_category_color": "Väri",
	"function_category_fight": "Taistella",
	"function_category_leek": "Purjo",
	"function_category_map": "Taulukot",
	"function_category_field": "Maadoitus",
	"function_category_math": "Numerot",
	"function_category_network": "Verkko",
	"function_category_string": "Ketjut",
	"function_category_utils": "Apuohjelmat",
	"function_category_weapon": "Aseet",
	"func_toDegrees": "Muuntaa kulman <b>radiaanit</b> asteina.",
	"func_toDegrees_arg_1": "Arvo radiaaneina",
	"func_toDegrees_return": "Kulma asteina.",
	"func_toLower": "Muuntaa <b>merkkijonon</b> isot kirjaimet pieniksi kirjaimiksi.",
	"func_toLower_arg_1": "Muunnettava merkkijono",
	"func_toLower_return": "Pienet kirjaimet",
	"func_toRadians": "Muuntaa kulman <b>astetta</b> radiaaneiksi.",
	"func_toRadians_arg_1": "Arvo asteina",
	"func_toRadians_return": "Kulma radiaaneina.",
	"func_toUpper": "Muuntaa <b>merkkijonon</b> pienet kirjaimet isoiksi.",
	"func_toUpper_arg_1": "Muunnettava merkkijono.",
	"func_toUpper_return": "Isot kirjaimet.",
	"func_typeOf": "Palauttaa arvon <b>arvon</b> tyypin seuraavista tyypeistä: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "Arvo, jonka tyyppi palautetaan.",
	"func_typeOf_return": "<b>arvon</b> tyyppi.",
	"func_unshift": "Lisää <b>elementin</b>-elementin <b>taulukon</b> alkuun.",
	"func_unshift_arg_1": "Taulukko, johon elementti lisätään.",
	"func_unshift_arg_2": "Lisättävä kohde.",
	"func_useChip": "Käytä sirua <b>sirua</b> entiteetissä <b>entiteetissä</b>.",
	"func_useChip_arg_1": "Siru käytettäväksi.",
	"func_useChip_arg_2": "Kohdekokonaisuus.",
	"func_useChipOnCell": "Käytä sirua <b>sirua</b> solussa <b>solussa</b>.",
	"func_useChipOnCell_arg_1": "Siru käytettäväksi.",
	"func_useChipOnCell_arg_2": "Kohdesolu.",
	"func_useChipOnCell_return": "Arvo on suurempi kuin 0, jos hyökkäys aloitettiin.",
	"func_useChip_return": "UseChipin palautusarvot ovat:<br>\n<ul>\n<li>#USE_CRITICAL, kriittinen osuma</li>\n<li>#USE_SUCCESS, jos onnistuu</li>\n<li>#USE_FAILED, epäonnistuessa</li>\n<li>#USE_INVALID_TARGET, jos kohdetta ei ole olemassa</li>\n<li>#USE_NOT_ENOUGH_TP, jos entiteetilläsi ei ole tarpeeksi TP:tä</li>\n<li>#USE_INVALID_COOLDOWN, jos siru ei ole vielä käyttökelpoinen</li>\n<li>#USE_INVALID_POSITION, jos kantama on huono tai näköyhteys epäselvä</li>\n</ul>",
	"func_useWeapon": "Käytä valittua asetta <b>entiteetissä</b>.",
	"func_useWeapon_arg_1": "Kohdekokonaisuus.",
	"func_useWeaponOnCell": "Käytä valittua asetta <b>solussa</b>.",
	"func_useWeaponOnCell_arg_1": "Kohdesolu.",
	"func_useWeaponOnCell_return": "Arvo on suurempi kuin 0, jos hyökkäys aloitettiin.",
	"func_useWeapon_return": "UseWeaponin palautusarvot ovat:<br>\n<ul>\n<li>#USE_CRITICAL, kriittinen osuma</li>\n<li>#USE_SUCCESS, jos onnistuu</li>\n<li>#USE_FAILED, epäonnistuessa</li>\n<li>#USE_INVALID_TARGET, jos kohdetta ei ole olemassa</li>\n<li>#USE_NOT_ENOUGH_TP, jos entiteetilläsi ei ole tarpeeksi TP:tä</li>\n<li>#USE_INVALID_POSITION, jos kantama on huono tai näköyhteys epäselvä</li>\n</ul>",
	"func_weaponNeedLos": "Palauttaa, tarvitseeko <b>ase</b> näkökenttä ampuakseen.",
	"func_weaponNeedLos_arg_1": "Testattavan aseen tunnus.",
	"func_weaponNeedLos_return": "<i>tosi</i>, jos <b>ase</b> tarvitsee näkökentän ampuakseen, <i>false</i> muuten.",
	"items": "Tuotteet",
	"max_cores": "max",
	"max_level": "max",
	"min_cores": "Vähintään sydämet",
	"min_level": "Min taso",
	"n_operations": "<b>{0}</b> toimintoa",
	"parameters": "Asetukset",
	"return": "Palaute",
	"value": "Arvo",
	"variable_operations": "<b>Muuttuja</b> -toiminnot",
	"deprecated_function": "Tämä toiminto on vanhentunut.",
	"deprecated_constant": "Tämä vakio on vanhentunut.",
	"replaced_by": "Se korvataan tekstillä {0}.",
	"details": "Yksityiskohdat",
	"optional": "valinnainen",
	"arg_type_9": "paketti",
	"arg_type_10": "intervalli",
	"function_category_set": "Paketit",
	"function_category_interval": "Intervallit",
	"arg_type_96": "set<entier>"
}

	i18n.mergeLocaleMessage("fi", { "doc": messages })
	