
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> Vorgang",
	"arg_type_-1": "?",
	"arg_type_1": "nummer",
	"arg_type_2": "kette",
	"arg_type_3": "boolesch",
	"arg_type_4": "liste",
	"arg_type_41": "liste<nummer>",
	"arg_type_42": "liste<kette>",
	"arg_type_43": "list<boolean>",
	"arg_type_44": "liste<liste>",
	"arg_type_46": "liste<ganzzahl>",
	"arg_type_47": "liste<real>",
	"arg_type_5": "funktion",
	"arg_type_6": "ganzzahl",
	"arg_type_7": "real",
	"arg_type_8": "tisch",
	"arg_type_806": "tabelle<?, ganzzahl>",
	"complexity": "Komplexität {c}",
	"operations": "{o} Operationen",
	"const_AREA_CIRCLE_1": "Kreisfläche 3 Quadrate im Durchmesser (Kreuz).",
	"const_AREA_CIRCLE_2": "Kreisfläche 5 Quadrate im Durchmesser.",
	"const_AREA_CIRCLE_3": "Kreisfläche 7 Quadrate im Durchmesser.",
	"const_AREA_LASER_LINE": "Zone eines Lasers, Linie von der minimalen Reichweite des Lasers zu seiner maximalen Reichweite oder einem Hindernis.",
	"const_AREA_POINT": "Bereich, der aus einer einzelnen Zelle besteht.",
	"const_CELL_EMPTY": "Rückgabewert von getCellContent(cell) für eine leere Zelle.",
	"const_CELL_OBSTACLE": "Rückgabewert von getCellContent(cell) für eine Zelle, die ein Hindernis enthält.",
	"const_CELL_PLAYER": "Rückgabewert von getCellContent(cell) für eine Zelle, die eine Entität enthält.",
	"const_CELL_ENTITY": "Rückgabewert von getCellContent(cell) für eine Zelle, die eine Entität enthält.",
	"const_COLOR_BLUE": "Farbe blau.",
	"const_COLOR_GREEN": "Grüne Farbe.",
	"const_COLOR_RED": "Rote Farbe.",
	"const_E": "Eulersche Zahl.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Gewährt einem Wesen absoluten Schild und reduziert die Menge der durch Schaden entfernten Trefferpunkte (EFFECT_DAMAGE) um einen festen Betrag. Verstärkt durch Widerstand.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Entfernt den absoluten Schild von einer Entität. Wird nicht durch eine Eigenschaft verstärkt. Ermöglicht es Ihnen, durch Schaden entfernte Trefferpunkte (EFFECT_DAMAGE) um einen absoluten Betrag zu erhöhen.",
	"const_EFFECT_AFTEREFFECT": "Entfernt Trefferpunkte von einer Entität. Verstärkt durch die Wissenschaft. Reduziert die maximalen Trefferpunkte um 5 % der Menge der entfernten Trefferpunkte.",
	"const_EFFECT_ANTIDOTE": "Entfernt alle Gifte (EFFECT_POISON), die auf einem Ziel vorhanden sind.",
	"const_EFFECT_BOOST_MAX_LIFE": "Erhöht Trefferpunkte und maximale Trefferpunkte einer Entität. Verstärkt durch Weisheit.",
	"const_EFFECT_BUFF_AGILITY": "Gewährt einer Entität Agilität. Verstärkt durch die Wissenschaft.",
	"const_EFFECT_BUFF_MP": "Gewährt einer Entität Bewegungspunkte. Verstärkt durch die Wissenschaft.",
	"const_EFFECT_BUFF_RESISTANCE": "Gewährt einer Entität Widerstand. Verstärkt durch die Wissenschaft.",
	"const_EFFECT_BUFF_STRENGTH": "Verleiht einem Wesen Stärke. Verstärkt durch die Wissenschaft.",
	"const_EFFECT_BUFF_TP": "Gewährt einer Entität Aktionspunkte. Verstärkt durch die Wissenschaft.",
	"const_EFFECT_BUFF_WISDOM": "Gewähre einem Wesen Weisheit. Verstärkt durch die Wissenschaft.",
	"const_EFFECT_DAMAGE": "Entfernt Trefferpunkte von einer Entität. Kraft verstärkt. Interagiert mit Schilden (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), Lebensraub (außer Zauberer) und Schadensrückgabe (EFFECT_DAMAGE_RETURN). Reduziert die maximalen Trefferpunkte um 5 % der Menge der entfernten Trefferpunkte.",
	"const_EFFECT_DAMAGE_RETURN": "Bietet Schadensreflexion für eine Entität, wodurch Trefferpunkte von Entitäten entfernt werden können, die dem Begünstigten Schaden zufügen. Verstärkt durch Agilität. Reduziert die maximalen Trefferpunkte um 5 % der Menge der entfernten Trefferpunkte.",
	"const_EFFECT_DEBUFF": "Reduziert den Wert aller Effekte auf eine Entität um einen Prozentsatz.",
	"const_EFFECT_HEAL": "Stellt Trefferpunkte für eine Entität wieder her, begrenzt durch die maximalen Trefferpunkte. Verstärkt durch Weisheit.",
	"const_EFFECT_INVERT": "Vertauscht die Position des Zauberers mit der eines Wesens.",
	"const_EFFECT_KILL": "Entfernt alle Trefferpunkte von einer Entität.",
	"const_EFFECT_LIFE_DAMAGE": "Entfernt Trefferpunkte von einer Entität, basierend auf einem Prozentsatz der Trefferpunkte des Zaubernden. Interagiert mit Schilden (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) und Schadensrückgabe (EFFECT_DAMAGE_RETURN). Reduziert die maximalen Trefferpunkte um 5 % der Menge der entfernten Trefferpunkte.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "Der Effekt wird mit der Anzahl der betroffenen Entitäten in dem Gebiet multipliziert.",
	"const_EFFECT_MODIFIER_ON_CASTER": "Der Effekt wirkt sich immer noch auf den Zaubernden aus.",
	"const_EFFECT_MODIFIER_STACKABLE": "Der Effekt ist kumulativ.",
	"const_EFFECT_NOVA_DAMAGE": "Entfernt maximale Lebenspunkte. Verstärkt durch die Wissenschaft.",
	"const_EFFECT_POISON": "Entfernt Trefferpunkte von einer Entität. Durch Magie verstärkt. Reduziert die maximalen Trefferpunkte um 10 % der Menge der entfernten Trefferpunkte.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Gewährt einem Wesen absoluten Schild und reduziert die Menge der durch Schaden entfernten Trefferpunkte (EFFECT_DAMAGE) um einen festen Betrag. Nicht verstärkbar.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Gewährt einer Entität Agilität. Nicht verstärkbar.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Gewährt einem Wesen Magie. Nicht verstärkbar.",
	"const_EFFECT_RAW_BUFF_MP": "Gewährt einer Entität Bewegungspunkte. Nicht verstärkbar.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Gewährt einer Entität Wissenschaft. Nicht verstärkbar.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Verleiht einem Wesen Stärke. Nicht verstärkbar.",
	"const_EFFECT_RAW_BUFF_TP": "Gewährt einer Entität Aktionspunkte. Nicht verstärkbar.",
	"const_EFFECT_RELATIVE_SHIELD": "Bietet einen relativen Schild, der die Menge der durch Schaden entfernten Trefferpunkte (EFFECT_DAMAGE) um einen relativen Betrag reduziert. Verstärkt durch Widerstand.",
	"const_EFFECT_RESURRECT": "Belebt eine Entität wieder, mit einer maximalen HP-Zahl, die der Hälfte der maximalen HP-Zahl der Entität vor der Wiederbelebung entspricht, und einer aktuellen HP-Zahl, die einem Viertel der maximalen HP-Zahl vor der Wiederbelebung entspricht.",
	"const_EFFECT_SHACKLE_MAGIC": "Entferne Magie von einer Entität. Durch Magie verstärkt.",
	"const_EFFECT_SHACKLE_MP": "Entfernen Sie Bewegungspunkte von einer Entität. Durch Magie verstärkt.",
	"const_EFFECT_SHACKLE_STRENGTH": "Entzieht einer Entität Stärke. Durch Magie verstärkt.",
	"const_EFFECT_SHACKLE_TP": "Ziehe Aktionspunkte von einer Entität ab. Durch Magie verstärkt.",
	"const_EFFECT_SUMMON": "Beschwört eine Glühbirne. Keine Auswirkung, wenn das Teambeschwörungslimit erreicht ist.",
	"const_EFFECT_TARGET_ALLIES": "Betrifft Verbündete.",
	"const_EFFECT_TARGET_CASTER": "Beeinflusst den Zaubernden.",
	"const_EFFECT_TARGET_ENEMIES": "Beeinflusst Feinde.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Betrifft nicht beschworene Wesen (Lauch und Geschütztürme).",
	"const_EFFECT_TARGET_SUMMONS": "Beeinflusst beschworene Wesen (Glühbirnen).",
	"const_EFFECT_TELEPORT": "Ändert die Position des Zaubernden.",
	"const_EFFECT_VULNERABILITY": "Entfernt Schild relativ zu einer Entität. Wird nicht durch eine Eigenschaft verstärkt. Erhöht durch Schaden entfernte Trefferpunkte (EFFECT_DAMAGE) um einen relativen Betrag.",
	"const_ENTITY_BULB": "Bezieht sich auf eine Entität vom Typ Bulb.",
	"const_ENTITY_LEEK": "Bezieht sich auf eine Entität vom Typ Lauch.",
	"const_ENTITY_TURRET": "Bezieht sich auf eine Einheit vom Typ Revolver.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Kampfhintergrund vom Herausforderungstyp.",
	"const_FIGHT_CONTEXT_GARDEN": "Kontext des Kampfes im Gemüsegarten.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Kontext des Kampfes in Battle Royale.",
	"const_FIGHT_CONTEXT_TEST": "Kampfkontext testen.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Hintergrund des Turnierkampfes.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Kämpfe im Battle Royale.",
	"const_FIGHT_TYPE_FARMER": "Züchter Kampf.",
	"const_FIGHT_TYPE_SOLO": "Solo-Kampf.",
	"const_FIGHT_TYPE_TEAM": "Teamkampf.\n",
	"const_INSTRUCTIONS_LIMIT": "Maximale Anzahl an Anweisungen, die eine Entität während ihres Zuges verwenden kann.",
	"const_MAX_TURNS": "Maximale Anzahl an Runden in einem Kampf.",
	"const_OPERATIONS_LIMIT": "Maximale Anzahl an Operationen, die eine Entität während ihres Zuges verwenden kann.",
	"const_PI": "Das Verhältnis des Umfangs eines Kreises zu seinem Durchmesser.",
	"const_SORT_ASC": "Weist die Funktion #sort an, in aufsteigender Reihenfolge zu sortieren.",
	"const_SORT_DESC": "Weist die Funktion #sort an, in absteigender Reihenfolge zu sortieren.",
	"const_TYPE_ARRAY": "Werttyp <b>Liste</b>",
	"const_TYPE_BOOLEAN": "Werttyp <b>boolesch</b>",
	"const_TYPE_FUNCTION": "Werttyp <b>Funktion</b>",
	"const_TYPE_NULL": "Werttyp <b>null</b>",
	"const_TYPE_NUMBER": "Werttyp <b>Zahl</b>",
	"const_TYPE_STRING": "Werttyp <b>Zeichenkette</b>",
	"const_USE_CRITICAL": "Wert, der von den Funktionen #useWeapon, #useWeaponOnCell, #useChip und #useChipOnCell im Falle eines kritischen Treffers zurückgegeben wird.",
	"const_USE_FAILED": "Wert, der von den Funktionen #useWeapon, #useWeaponOnCell, #useChip und #useChipOnCell bei einem Fehler zurückgegeben wird.",
	"const_USE_INVALID_COOLDOWN": "Wert, der von den Funktionen #useChip und #useChipOnCell zurückgegeben wird, wenn der Chip noch nicht verwendbar ist.",
	"const_USE_INVALID_POSITION": "Wert, der von den Funktionen #useWeapon, #useWeaponOnCell, #useChip und #useChipOnCell zurückgegeben wird, wenn die Reichweite schlecht oder die Sichtlinie nicht klar ist.",
	"const_USE_INVALID_TARGET": "Wert, der von den Funktionen #useWeapon und #useChip zurückgegeben wird, wenn das Ziel nicht vorhanden ist.",
	"const_USE_NOT_ENOUGH_TP": "Wert, der von den Funktionen #useWeapon, #useWeaponOnCell, #useChip und #useChipOnCell zurückgegeben wird, wenn der Zauberer nicht genügend Aktionspunkte hat, um das Objekt zu verwenden.",
	"const_USE_RESURRECT_INVALID_ENTITY": "Wert, der von der #resurrect-Funktion zurückgegeben wird, wenn die angegebene Entität nicht existiert oder noch nicht tot ist.",
	"const_USE_SUCCESS": "Wert, der von den Funktionen #useWeapon, #useWeaponOnCell, #useChip und #useChipOnCell bei Erfolg zurückgegeben wird.",
	"const_USE_TOO_MANY_SUMMONS": "Von #summon zurückgegebener Fehler, wenn Sie bereits <b>8</b> Live-Beschwörungen haben.\n",
	"func_abs": "Gibt den Absolutwert der Zahl <b>Zahl</b> zurück.",
	"func_abs_arg_1": "Die Zahl, deren absoluter Wert berechnet wird.",
	"func_abs_return": "Der absolute Wert der Zahl.",
	"func_acos": "Berechnet den Arkuskosinus von <b>Argument</b> im Intervall [0, #PI].",
	"func_acos_arg_1": "Zahl, deren Arkuskosinus berechnet wird.",
	"func_acos_return": "Der Arkuskosinus von <b>Argument</b>.",
	"func_arrayConcat": "Fügen Sie die beiden Arrays Ende an Ende hinzu. Textschlüssel bleiben erhalten und alle Zifferntasten werden neu indiziert.",
	"func_arrayConcat_arg_1": "Erster Tisch.",
	"func_arrayConcat_arg_2": "Zweiter Tisch.",
	"func_arrayConcat_return": "Summe der beiden Arrays.",
	"func_arrayFilter": "Gibt ein neues Array zurück, das alle Schlüssel/Wert-Paare aus dem Quellarray enthält, für die die Callback-Funktion wahr zurückgegeben hat. Wenn die Callback-Funktion einen Parameter übernimmt, wird der Wert des Quellarrays gesendet, wenn sie zwei Parameter benötigt, werden der Schlüssel und der Wert gesendet.",
	"func_arrayFilter_arg_1": "Originaltisch.",
	"func_arrayFilter_arg_2": "Funktion, die für jedes Element aufgerufen wird.",
	"func_arrayFilter_return": "Neuer Tisch.",
	"func_arrayFlatten": "Gibt ein neues Array zurück, das alle Elemente des Quellarrays enthält. Alle in einem Subarray enthaltenen Elemente werden in das neue Array extrahiert.Das Tiefenargument bestimmt die maximale zu extrahierende Subarray-Tiefe.\n<code>var array = [1, 2, [[3, 4], 5], [6]];\ndebug(arrayFlatten(array, 1)); // [1,2,[3,4],5,6]</code>",
	"func_arrayFlatten_arg_1": "Originaltisch.",
	"func_arrayFlatten_arg_2": "Maximale Tiefe.",
	"func_arrayFlatten_return": "Neuer Tisch.",
	"func_arrayFoldLeft": "Reduzieren Sie das Array <b>array</b> [v1, v2, ..., vn] von links ausgehend vom Wert <b>v0</b> und wenden Sie die Funktion <b>f</b> an. Gleich :\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "Originaltisch.",
	"func_arrayFoldLeft_arg_2": "Anzuwendende Funktion.",
	"func_arrayFoldLeft_arg_3": "Startwert.",
	"func_arrayFoldLeft_return": "Reduzierter Tisch.",
	"func_arrayFoldRight": "Reduzieren Sie das Array <b>array</b> [v1, v2, ..., vn] von rechts ausgehend vom Wert <b>v0</b> und wenden Sie die Funktion <b>f</b> an. Gleich :\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "Originaltisch.",
	"func_arrayFoldRight_arg_2": "Anzuwendende Funktion.",
	"func_arrayFoldRight_arg_3": "Startwert.",
	"func_arrayFoldRight_return": "Reduzierter Tisch.",
	"func_arrayIter": "Ruft die Callback-Funktion für jedes Array-Element auf. Wenn die Callback-Funktion einen Parameter übernimmt, wird der Wert des Quellarrays gesendet, wenn sie zwei Parameter benötigt, werden der Schlüssel und der Wert gesendet.",
	"func_arrayIter_arg_1": "Originaltisch.",
	"func_arrayIter_arg_2": "Funktion, die für jedes Element aufgerufen wird.",
	"func_arrayMap": "Gibt ein neues Array zurück, das für jeden Schlüssel des Quellarrays den von der Callback-Funktion zurückgegebenen Wert enthält. Wenn die Callback-Funktion einen Parameter übernimmt, wird der Wert des Quellarrays gesendet, wenn sie zwei Parameter benötigt, werden der Schlüssel und der Wert gesendet.",
	"func_arrayMap_arg_1": "Originaltisch.",
	"func_arrayMap_arg_2": "Funktion, die für jedes Element aufgerufen wird.",
	"func_arrayMap_return": "Neuer Tisch.",
	"func_arrayMax": "Gibt das Maximalwertelement des <b>array</b>-Arrays zurück.<br/>Weitere Informationen zum Maximalwert eines Arrays finden Sie unter #sort.",
	"func_arrayMax_arg_1": "Array, in dem der Maximalwert gesucht wird.",
	"func_arrayMax_return": "Artikel mit Höchstwert.",
	"func_arrayMin": "Gibt das Element mit dem Mindestwert des Arrays <b>array</b> zurück.<br/>Weitere Informationen zum Mindestwert eines Arrays finden Sie unter #sort.",
	"func_arrayMin_arg_1": "Array, in dem der Minimalwert gesucht wird.",
	"func_arrayMin_return": "Artikel mit Mindestwert.",
	"func_arrayPartition": "Gibt ein neues Array zurück, das zwei Listen enthält, in der ersten alle Schlüssel/Wert-Paare, für die die Callback-Funktion wahr zurückgegeben hat, in der zweiten alle anderen. Wenn die Callback-Funktion einen Parameter übernimmt, wird der Wert des Quellarrays gesendet, wenn sie zwei Parameter benötigt, werden der Schlüssel und der Wert gesendet.",
	"func_arrayPartition_arg_1": "Originaltisch.",
	"func_arrayPartition_arg_2": "Funktion, die für jedes Element aufgerufen wird.",
	"func_arrayPartition_return": "Neuer Tisch.",
	"func_arraySort": "Sortiert das Array gemäß der durch die Callback-Funktion definierten Reihenfolge. Die Elemente werden paarweise verglichen, die Callback-Funktion muss die Werte -1, 0 oder 1 zurückgeben, je nachdem ob der erste Wert vor, auf gleicher Höhe oder nach dem zweiten Wert liegt. Nimmt die Callback-Funktion 2 Parameter entgegen, werden die beiden Werte gesendet, nimmt sie 4, werden die Schlüssel/Wert-Paare gesendet.",
	"func_arraySort_arg_1": "Ursprünglicher Tisch",
	"func_arraySort_arg_2": "Sortierfunktion.",
	"func_arraySort_return": "Das sortierte Array",
	"func_asin": "Berechnet den Arkussinus von <b>Argument</b> im Intervall [0, #PI].",
	"func_asin_arg_1": "Zahl, deren Arcussinus berechnet wird.",
	"func_asin_return": "Der Arkussinus von <b>Argument</b>.",
	"func_assocSort": "Sortiert das Array <b>array</b> unter Beibehaltung der Schlüssel:Wert-Zuordnung gemäß der Reihenfolge <b>order</b>.",
	"func_assocSort_arg_1": "Das zu sortierende Array.",
	"func_assocSort_arg_2": "Die Sortierreihenfolge: #SORT_ASC oder #SORT_DESC.",
	"func_atan": "Berechnet den Arkustangens von <b>Argument</b> im Intervall [0, #PI].",
	"func_atan2": "Konvertiert kartesische Koordinaten (<b>x</b>, <b>y</b>) in Polarkoordinaten (<b>r</b>, <b>Theta</b>). Diese Funktion gibt den <b>Theta</b>-Winkel zwischen -#PI und #PI unter Verwendung der Vorzeichen der Argumente zurück.",
	"func_atan2_arg_1": "Y-Koordinate.",
	"func_atan2_arg_2": "X-Koordinate.",
	"func_atan2_return": "Der <b>Theta</b>-Winkel in Polarkoordinaten des Punktes (x, y).",
	"func_atan_arg_1": "Zahl, deren Arkustangens berechnet wird.",
	"func_atan_return": "Der Arkustangens von <b>Argument</b>.",
	"func_average": "Berechnet den Durchschnitt der im Array <b>array</b> enthaltenen Elemente.",
	"func_average_arg_1": "Array, dessen Durchschnitt wir berechnen möchten.",
	"func_average_return": "Durchschnittswert.",
	"func_canUseChip": "Legt fest, ob Ihre Entität den <b>Chip</b>-Chip auf der Entität mit der Identität <b>Entität</b> verwenden kann.",
	"func_canUseChip_arg_1": "Die Nummer des zu testenden Chips.",
	"func_canUseChip_arg_2": "Die Entitäts-ID, für die Sie den Chip verwenden möchten.",
	"func_canUseChip_return": "<i>true</i>, wenn Ihr Unternehmen den Chip verwenden kann, andernfalls <i>false</i>.",
	"func_canUseChipOnCell": "Legt fest, ob Ihr Unternehmen den <b>Chip</b> Chip auf der <b>Zelle</b> Zelle verwenden kann.",
	"func_canUseChipOnCell_arg_1": "Die Nummer des zu testenden Chips.",
	"func_canUseChipOnCell_arg_2": "Die Nummer der Zelle, auf der Sie den Chip verwenden möchten.",
	"func_canUseChipOnCell_return": "<i>true</i>, wenn Ihr Unternehmen den Chip verwenden kann, andernfalls <i>false</i>.",
	"func_canUseWeapon": "Bestimmt, ob Ihre Entität die Entität mit der ID <b>Entität</b> mit der Waffe <b>Waffe</b> erschießen kann.",
	"func_canUseWeapon_arg_1": "Die Waffe zum Testen. Standardmäßig Ihre aktuell ausgerüstete Waffe.",
	"func_canUseWeapon_arg_2": "Die ID der Entität, auf die Sie feuern möchten.",
	"func_canUseWeapon_return": "<i>true</i>, wenn Ihre Entität feuern kann, andernfalls <i>false</i>.",
	"func_canUseWeaponOnCell": "Bestimmt, ob Ihr Wesen die <b>Zelle</b> mit der <b>Waffe</b> Waffe erschießen kann.",
	"func_canUseWeaponOnCell_arg_1": "Die Waffe zum Testen. Standardmäßig Ihre aktuell ausgerüstete Waffe.",
	"func_canUseWeaponOnCell_arg_2": "Die Nummer der Zelle, die Sie aufnehmen möchten.",
	"func_canUseWeaponOnCell_return": "<i>true</i>, wenn Ihre Entität feuern kann, andernfalls <i>false</i>.",
	"func_cbrt": "Berechne die Kubikwurzel von <b>Zahl</b>",
	"func_cbrt_arg_1": "Zahl, deren Kubikwurzel wir berechnen wollen.",
	"func_cbrt_return": "Kubikwurzel von <b>Zahl</b>.",
	"func_ceil": "Gibt die Aufrundung von <b>Zahl</b> zurück.<br/>Sie finden die Abrundung mit #floor und die Rundung mit #round.",
	"func_ceil_arg_1": "Zahl, für die wir die obere Rundung finden wollen.",
	"func_ceil_return": "<b>Zahl</b> aufrunden.",
	"func_charAt": "Gibt das Zeichen der Zeichenfolge <b>string</b> zurück, das sich an Position <b>position</b> befindet.<br/>Das erste Zeichen der Zeichenfolge befindet sich an Position 0.",
	"func_charAt_arg_1": "Zeichenfolge, aus der wir ein Zeichen abrufen möchten.",
	"func_charAt_arg_2": "Position des gesuchten Zeichens.",
	"func_charAt_return": "zeichenfolge, die das gesuchte Zeichen enthält, oder eine leere Zeichenfolge, wenn die Position ungültig ist.",
	"func_chipNeedLos": "Gibt zurück, ob der <b>Chip</b> eine Sichtverbindung benötigt, um verwendet zu werden.",
	"func_chipNeedLos_arg_1": "Die ID des zu testenden Chips.",
	"func_chipNeedLos_return": "<i>true</i>, wenn der <b>Chip</b> Sichtverbindung benötigt, sonst <i>false</i>.",
	"func_clearMarks": "Löscht alle Markierungen von mark() und markText() im Feld.",
	"func_clone": "Kopiert den als Parameter übergebenen <b>Wert</b> auf <b>Level</b>-Ebenen und gibt die Kopie zurück. Im Fall eines Arrays zum Beispiel kopiert ein clone(array, 1) das Array, aber nicht seine Elemente, ein clone(array, 2) kopiert das Array sowie alle Elemente.",
	"func_clone_arg_1": "Der zu klonende Wert",
	"func_clone_arg_2": "Die Anzahl der zu klonenden Ebenen",
	"func_clone_return": "Der geklonte Wert",
	"func_contains": "Bestimmt, ob sich ein <b>Suchstring</b> innerhalb eines <b>String</b>-Strings befindet.",
	"func_contains_arg_1": "String, in dem die Suche durchgeführt wird.",
	"func_contains_arg_2": "Zeichenfolge, nach der gesucht werden soll.",
	"func_contains_return": "true wenn <b>search</b> in <b>string</b> ist, andernfalls false.",
	"func_cos": "Berechnet den Kosinus von <b>Winkel</b>.",
	"func_cos_arg_1": "Zahl, deren Kosinus berechnet wird (im Bogenmaß).",
	"func_cos_return": "Der Kosinus von <b>Winkel</b> im Bereich [-1, 1].",
	"func_count": "Zählt die Anzahl der Elemente im Array <b>array</b>.",
	"func_count_arg_1": "Array, dessen Anzahl von Elementen gezählt wird.",
	"func_count_return": "Anzahl der Array-Elemente.",
	"func_debug": "Zeichnet eine <b>Objekt</b>-Nachricht im persönlichen Protokoll auf, verfügbar im Bericht am Ende des Kampfes.",
	"func_debug_arg_1": "Die aufzuzeichnende Nachricht.",
	"func_debugC": "Zeichnet eine <b>Objekt</b>-Nachricht in der Farbe <b>Farbe</b> im persönlichen Protokoll auf, verfügbar im Bericht am Ende des Kampfes.",
	"func_debugC_arg_1": "Die aufzuzeichnende Nachricht.",
	"func_debugC_arg_2": "Die Farbe der Nachricht. Sie können die Funktion #getColor verwenden.",
	"func_debugE": "Zeichnet eine <b>Objekt</b>-Fehlermeldung im persönlichen Log auf, verfügbar im Bericht am Ende des Kampfes.<br />Fehlermeldungen erscheinen im Kampfbericht rot.",
	"func_debugE_arg_1": "Die zu protokollierende Fehlermeldung.",
	"func_debugW": "Zeichnet eine <b>Objekt</b>-Warnmeldung im persönlichen Logbuch auf, verfügbar im Bericht am Ende des Kampfes. <br />Warnmeldungen erscheinen im Kampfbericht in Orange.",
	"func_debugW_arg_1": "Die aufzuzeichnende Warnmeldung.",
	"func_deleteRegister": "Löscht die Registrierung, die dem Schlüssel <b>Schlüssel</b> zugeordnet ist, falls vorhanden.",
	"func_deleteRegister_arg_1": "Der zu löschende Registrierungsschlüssel.",
	"func_endsWith": "Bestimmt, ob ein <b>String</b> mit einem <b>Suffix</b>-String endet.",
	"func_endsWith_arg_1": "String, in dem die Suche durchgeführt wird",
	"func_endsWith_arg_2": "Suffix zu suchen",
	"func_endsWith_return": "wahr, wenn <b>string</b> mit <b>suffix</b> endet",
	"func_exp": "Erhöht Eulers Zahl #E mit der <b>Zahl</b>.",
	"func_exp_arg_1": "Der Exponent, zu dem #E erhoben wird.",
	"func_exp_return": "#E** <b>Nummer</b>.",
	"func_fill": "Ändert die Größe des Arrays <b>array</b> auf <b>size</b> und füllt alle seine Felder mit <b>value</b>.",
	"func_fill_arg_1": "Die auszufüllende Tabelle.",
	"func_fill_arg_2": "Der Wert für jedes Quadrat im Array.",
	"func_fill_arg_3": "Die Anzahl der Boxen. Standardmäßig die aktuelle Arraygröße.",
	"func_floor": "Berechnet das Abrunden von <b>Zahl</b>.<br/>Sie finden das Aufrunden mit #ceil und das Runden mit #round.",
	"func_floor_arg_1": "Zahl, deren Abrundung wir finden wollen.",
	"func_floor_return": "Abgerundet auf <b>Zahl</b>.",
	"func_getAbsoluteShield": "Gibt den absoluten Schild der Entität mit der ID <b>entity</b> zurück. Um den absoluten Schild Ihrer Entität direkt abzurufen, verwenden Sie #getAbsoluteShield() ohne Parameter.",
	"func_getAbsoluteShield_arg_1": "Die ID der Entität, deren absoluter Schild zurückgegeben wird.",
	"func_getAbsoluteShield_return": "Der absolute Schild der Entität <b>Entität</b>.",
	"func_getAgility": "Gibt die Agilität der Entität mit der ID <b>entity</b> zurück. Um die Agilität Ihrer Entität direkt abzurufen, verwenden Sie #getAgility() ohne Parameter.",
	"func_getAgility_arg_1": "Die ID der Entität, deren Agilität zurückgegeben wird.",
	"func_getAgility_return": "Die Agilität der <b>Entität</b>-Entität.",
	"func_getAIID": "Gibt die AI-ID der Entität <b>Entität</b> zurück. Verwenden Sie getAIID ohne Parameter, um die ID Ihrer KI abzurufen.",
	"func_getAIID_arg_1": "Die ID der Entität, deren AI-ID zurückgegeben wird.",
	"func_getAIID_return": "Die <b>Entität</b> Entitäts-AI-ID.",
	"func_getAIName": "Gibt den Namen der Entitäts-AI <b>Entität</b> zurück. Verwenden Sie getAINam() ohne Parameter, um den Namen Ihrer KI abzurufen.",
	"func_getAIName_arg_1": "Die ID der Entität, deren AI-Name zurückgegeben wird.",
	"func_getAIName_return": "Der Name der <b>Entität</b> der Entitäts-AI.",
	"func_getAliveAllies": "Gibt eine Reihe all deiner im Kampf lebenden Verbündeten zurück.",
	"func_getAliveAllies_return": "Ein Array, das die IDs aller Ihrer lebenden Verbündeten enthält.",
	"func_getAliveEnemies": "Gibt ein Array all Ihrer Feinde zurück, die im Kampf leben.",
	"func_getAliveEnemiesCount": "Gibt die Anzahl der im Kampf lebenden Feinde zurück.",
	"func_getAliveEnemiesCount_return": "Die Anzahl der lebenden Feinde.",
	"func_getAliveEnemies_return": "Ein Array, das die IDs all Ihrer lebenden Feinde enthält.",
	"func_getAllChips": "Gibt die Liste aller Chips im Spiel zurück.",
	"func_getAllChips_return": "Die Liste aller Chips im Spiel.",
	"func_getAllEffects": "Gibt die Liste aller Effekte im Spiel zurück.",
	"func_getAllEffects_return": "Die Liste aller Effekte im Spiel.",
	"func_getAllWeapons": "Gibt die Liste aller Waffen im Spiel zurück.",
	"func_getAllWeapons_return": "Die Liste aller Waffen im Spiel.",
	"func_getAlliedTurret": "Gibt die Turm-ID Ihres Teams oder -1 zurück, falls sie nicht existiert.",
	"func_getAlliedTurret_return": "Die Turm-ID Ihres Teams.",
	"func_getEnemyTurret": "Gibt die ID des feindlichen Geschützturms oder -1 zurück, falls sie nicht existiert.",
	"func_getEnemyTurret_return": "Die ID des feindlichen Geschützturms.",
	"func_getAllies": "Gibt ein Array zurück, das Ihre Verbündeten und Ihre Entität enthält.",
	"func_getAlliesCount": "Gibt die Anzahl der Verbündeten im Kampf zurück.",
	"func_getAlliesCount_return": "Die Zahl der Verbündeten.",
	"func_getAlliesLife": "Gibt die Gesamtgesundheit deiner Verbündeten zurück.",
	"func_getAlliesLife_return": "Die Gesamtgesundheit Ihrer Verbündeten.",
	"func_getAllies_return": "Die Tabelle der Verbündeten und Ihrer Entität.",
	"func_getBirthTurn": "Gibt die Runde des Kampfes zurück, in der die <b>Entität</b> erschienen ist. Gibt 1 zurück, wenn es sich beispielsweise um einen Lauch handelt, und 5, wenn es sich um eine Beschwörung in Runde 5 handelt.",
	"func_getBirthTurn_arg_1": "Die ID der Entität, deren Spawn-Runde zurückgegeben wird.",
	"func_getBirthTurn_return": "Die Kampfrunde, in der <b>Entität</b> erschien.",
	"func_getBlue": "Gibt den Blauanteil in der Farbe <b>color</b> zwischen 0 und 255 zurück. Beispiel: getBlue(#COLOR_BLUE) = 255 und getBlue(#COLOR_GREEN) = 0.",
	"func_getBlue_arg_1": "Die Farbe, deren Blauanteil zurückgegeben wird.",
	"func_getBlue_return": "Der Anteil von Blau in der Farbe <b>Farbe</b>",
	"func_getBulbChips": "Gibt die Liste der Chips zurück, die auf einer durch den <b>bulb_chip</b>-Chip beschworenen Glühbirne ausgerüstet werden.",
	"func_getBulbChips_arg_1": "Der Knollenfloh.",
	"func_getBulbChips_return": "Die Chips einer durch den <b>bulb_chip</b>-Chip beschworenen Glühbirne.",
	"func_getCell": "Gibt die Zelle zurück, in der sich die Entität mit der ID <b>Entität</b> befindet. Verwenden Sie getCell() ohne Parameter, um Ihre Zelle abzurufen.",
	"func_getCell_arg_1": "Die ID der Entität, deren Zelle zurückgegeben wird.",
	"func_getCell_return": "Die Zellennummer, in der sich die <b>Entität</b> befindet.",
	"func_getCellContent": "Gibt den Inhalt einer Zelle mit der ID <b>cell</b> zurück.",
	"func_getCellContent_arg_1": "Die ID der Zelle, deren Inhalt zurückgegeben wird.",
	"func_getCellContent_return": "Der Inhalt der Zelle <b>Zelle</b>: #CELL_EMPTY für eine leere Zelle, #CELL_ENTITY für eine Entität, #CELL_OBSTACLE für ein Hindernis.",
	"func_getCellDistance": "Gibt den Abstand zwischen zwei Zellen <b>Zelle1</b> und <b>Zelle2</b> zurück. <br />Die zurückgegebene Entfernung wird in der Anzahl der Zellen ausgedrückt und berücksichtigt nicht die verschiedenen Hindernisse zwischen den beiden Zellen.\nUm die Distanz in Luftlinie zu erhalten, siehe #getDistance und um die Pfaddistanz zwischen den beiden Zellen zu erhalten, die Hindernissen ausweichen, siehe #getPathLength.",
	"func_getCellDistance_arg_1": "Die ID der Startzelle.",
	"func_getCellDistance_arg_2": "Die ID der Ankunftszelle.",
	"func_getCellDistance_return": "Der Abstand zwischen den beiden Zellen <b>cell1</b> und <b>cell2</b>.",
	"func_getCellFromXY": "Gibt die ID der Zelle an Position (<b>x</b>, <b>y</b>) zurück.",
	"func_getCellFromXY_arg_1": "Die x-Position der Zelle.",
	"func_getCellFromXY_arg_2": "Die y-Position der Zelle.",
	"func_getCellFromXY_return": "Die Zellen-ID an Position (<b>x</b>, <b>y</b>), <b>null</b>, wenn die Zelle nicht existiert.",
	"func_getCellsToUseChip": "Gibt die Liste der Zellen zurück, aus denen Ihre Entität den <b>Chip</b>-Chip auf der <b>Entität</b>-Entität verwenden kann.",
	"func_getCellsToUseChip_arg_1": "Der Chip, den die Entität verwenden möchte.",
	"func_getCellsToUseChip_arg_2": "Die Zielentität.",
	"func_getCellsToUseChip_arg_3": "Array von Zellen, die ignoriert werden sollen.",
	"func_getCellsToUseChip_return": "Liste der Zellen, in denen der Chip verwendet werden kann.",
	"func_getCellsToUseChipOnCell": "Gibt die Liste der Zellen zurück, aus denen Ihre Entität den <b>Chip</b> auf Zelle <b>Zelle</b> verwenden kann.",
	"func_getCellsToUseChipOnCell_arg_1": "Der Chip, den die Entität verwenden möchte.",
	"func_getCellsToUseChipOnCell_arg_2": "Die Zielzelle.",
	"func_getCellsToUseChipOnCell_arg_3": "Array von Zellen, die ignoriert werden sollen.",
	"func_getCellsToUseChipOnCell_return": "Liste der Zellen, in denen der Chip verwendet werden kann.",
	"func_getCellsToUseWeapon": "Gibt die Liste der Zellen zurück, aus denen Ihre Entität die Waffe <b>Waffe</b> auf die Entität <b>Entität</b> verwenden kann.",
	"func_getCellsToUseWeapon_arg_1": "Die Waffe zum Testen. Standardmäßig Ihre aktuell ausgerüstete Waffe.",
	"func_getCellsToUseWeapon_arg_2": "Die Zielentität.",
	"func_getCellsToUseWeapon_arg_3": "Array von Zellen, die ignoriert werden sollen. Standardmäßig ein leeres Array.",
	"func_getCellsToUseWeapon_return": "Liste der Zellen, aus denen die Waffe verwendet werden kann.",
	"func_getCellsToUseWeaponOnCell": "Gibt die Liste der Zellen zurück, aus denen Ihre Entität die <b>Waffe</b> Waffe auf einer <b>Zelle</b> Zelle verwenden kann.",
	"func_getCellsToUseWeaponOnCell_arg_1": "Die Waffe zum Testen. Standardmäßig Ihre aktuell ausgerüstete Waffe.",
	"func_getCellsToUseWeaponOnCell_arg_2": "Die Zielzelle.",
	"func_getCellsToUseWeaponOnCell_arg_3": "Array von Zellen, die ignoriert werden sollen. Standardmäßig ein leeres Array.",
	"func_getCellsToUseWeaponOnCell_return": "Liste der Zellen, aus denen die Waffe verwendet werden kann.",
	"func_getCellX": "Bestimmt die Position in X der Zelle <b>cell</b>.",
	"func_getCellX_arg_1": "Die Zelle, deren Position in X bestimmt wird.",
	"func_getCellX_return": "Die X-Position der Zelle.",
	"func_getCellY": "Bestimmt die Y-Position der Zelle <b>cell</b>.",
	"func_getCellY_arg_1": "Die Zelle, deren Position in Y bestimmt wird.",
	"func_getCellY_return": "Die Y-Position der Zelle.",
	"func_getChipArea": "Gibt den Effektbereichstyp des <b>Chips</b> zurück.",
	"func_getChipArea_arg_1": "Der Chip, dessen Zonentyp zurückgegeben wird.",
	"func_getChipArea_return": "Der Bereichstyp des <b>Chip</b>-Chips unter den AREA_*-Konstanten:\n<ul>\n<li>#AREA_POINT: einzelner quadratischer Bereich</li>\n<li>#AREA_LASER_LINE: Linie eines Lasers</li>\n<li>#AREA_CIRCLE_1: kreisförmiger Bereich mit 3 Quadraten im Durchmesser</li>\n<li>#AREA_CIRCLE_2: kreisförmiger Bereich mit 5 Quadraten im Durchmesser</li>\n<li>#AREA_CIRCLE_3: kreisförmiger Bereich mit 7 Quadraten im Durchmesser</li>\n</ul>",
	"func_getChipCooldown": "Gibt die <b>Chip</b>-Chip-Erholungszeit aus dem Markt zurück.",
	"func_getChipCooldown_arg_1": "Der Chip, dessen Abklingzeit zurückgegeben wird.",
	"func_getChipCooldown_return": "Die <b>Chip</b>-Abklingzeit.",
	"func_getChipCost": "Gibt die Kosten in TP des Chips <b>Chip</b> zurück.",
	"func_getChipCost_arg_1": "Der kostenintensive Chip wird zurückerstattet.",
	"func_getChipCost_return": "Die Kosten für den <b>Chip</b>.",
	"func_getChipEffectiveArea": "Gibt die Liste der Zellen zurück, die betroffen sind, wenn der <b>Chip</b>-Chip auf der <b>cell</b>-Zelle von einer <b>von</b>-Zelle verwendet wird.",
	"func_getChipEffectiveArea_arg_1": "Der zu testende Chip.",
	"func_getChipEffectiveArea_arg_2": "Die Zielzelle.",
	"func_getChipEffectiveArea_arg_3": "Die Zelle, aus der der Chip verwendet wird.",
	"func_getChipEffectiveArea_return": "Das Array, das die IDs aller betroffenen Zellen enthält.",
	"func_getChipEffects": "Gibt die Effekte des <b>Chip</b>-Chips zurück.",
	"func_getChipEffects_arg_1": "Der Chip, dessen Effekte zurückgegeben werden.",
	"func_getChipEffects_return": "Die Auswirkungen des <b>Chips</b> Chips. Gleicher Rückgabewert wie bei der Funktion #getWeaponEffects.",
	"func_getChipFailure": "Gibt das prozentuale Ausfallrisiko des Chips <b>chip</b> zurück.",
	"func_getChipFailure_arg_1": "Der Chip, dessen Fehlerprozentsatz zurückgegeben wird.",
	"func_getChipFailure_return": "<b>Chip</b> Prozentsatz der Chipfehler, eine Ganzzahl zwischen <b>0</b> und <b>100</b>.",
	"func_getChipMaxRange": "Gibt die maximale Reichweite des <b>Chip</b>-Chips zurück.",
	"func_getChipMaxRange_arg_1": "Der Chip, dessen maximale Reichweite zurückgegeben wird.",
	"func_getChipMaxRange_return": "Die maximale Reichweite des <b>Chips</b>.",
	"func_getChipMaxScope": "Gibt die maximale Reichweite des <b>Chip</b>-Chips zurück.",
	"func_getChipMaxScope_arg_1": "Der Chip, dessen maximale Reichweite zurückgegeben wird.",
	"func_getChipMaxScope_return": "Die maximale Reichweite des <b>Chips</b>.",
	"func_getChipMinRange": "Gibt die Mindestreichweite des <b>Chip</b>-Chips zurück.",
	"func_getChipMinRange_arg_1": "Der Chip, dessen Mindestreichweite zurückgegeben wird.",
	"func_getChipMinRange_return": "Die minimale Reichweite des <b>Chips</b>.",
	"func_getChipMinScope": "Gibt die Mindestreichweite des <b>Chip</b>-Chips zurück.",
	"func_getChipMinScope_arg_1": "Der Chip, dessen Mindestreichweite zurückgegeben wird.",
	"func_getChipMinScope_return": "Die minimale Reichweite des <b>Chips</b>.",
	"func_getChipLaunchType": "Gibt den Startmodus des <b>Chip</b>-Chips unter den LAUNCH_TYPE_*-Konstanten zurück.",
	"func_getChipLaunchType_arg_1": "Die ID des Chips, dessen Wurfmodus zurückgegeben wird.",
	"func_getChipLaunchType_return": "Der Wurfmodus des <b>Chips</b> Chips.",
	"func_getChipName": "Gibt den Namen des Chips <b>chip</b> zurück.",
	"func_getChipName_arg_1": "Der Chip, dessen Name zurückgegeben wird.",
	"func_getChipName_return": "Der Name des <b>Chip</b>-Chips.",
	"func_getChips": "Gibt die Aufzählungszeichen der Entität mit der ID <b>Entität</b> zurück.",
	"func_getChips_arg_1": "Die ID der Entität, deren Aufzählungszeichen zurückgegeben werden.",
	"func_getChips_return": "Ein Array, das die Bullet-IDs der Entität <b>entity</b> enthält.",
	"func_getChipTargets": "Gibt die Entitäten zurück, die betroffen sind, wenn der <b>Chip</b>-Chip auf der Zelle <b>cell</b> verwendet wird.",
	"func_getChipTargets_arg_1": "Der zu testende Chip.",
	"func_getChipTargets_arg_2": "Die Zielzelle.",
	"func_getChipTargets_return": "Das Array, das die IDs aller betroffenen Entitäten enthält.",
	"func_getColor": "Gibt die ganze Zahl zurück, die der als Parameter angegebenen Farbe (<b>rot</b>, <b>grün</b>, <b>blau</b>) entspricht.",
	"func_getColor_arg_1": "Roter Wert zwischen 0 und 255.",
	"func_getColor_arg_2": "Grüner Wert zwischen 0 und 255.",
	"func_getColor_arg_3": "Blauwert zwischen 0 und 255.",
	"func_getColor_return": "int entsprechend der als Parameter bereitgestellten Farbe.",
	"func_getCooldown": "Gibt die aktuelle <b>Chip</b>-Abklingzeit der <b>Entität</b>-Entität zurück.",
	"func_getCooldown_arg_1": "Der Chip, dessen aktuelle Abklingzeit zurückgegeben wird.",
	"func_getCooldown_arg_2": "Die Entität, deren Abklingzeit zurückgegeben wird.",
	"func_getCooldown_return": "Die aktuelle Abklingzeit des Chips <b>Chip</b>, dies ist die Anzahl der Runden, bevor der Chip verwendbar wird, <b>0</b>, wenn er derzeit verwendbar ist.",
	"func_getCores": "Gibt die Anzahl der Kerne der Entität mit der ID <b>entity</b> zurück.",
	"func_getCores_arg_1": "Die Entität, deren Anzahl an Kernen zurückgegeben wird.",
	"func_getCores_return": "Die Anzahl der Kerne der <b>Entität</b>-Entität.",
	"func_getDate": "Gibt das Datum des Kampfes im Format TT/MM/JJJJ zurück.",
	"func_getDate_return": "Das Datum des Kampfes.",
	"func_getTime": "Gibt die Kampfzeit im Format HH:mm:ss zurück.",
	"func_getTime_return": "Die Zeit des Kampfes.",
	"func_getTimestamp": "Gibt den Zeitstempel des Kampfes zurück, der der Anzahl der Sekunden seit dem 1. Januar 1970 entspricht.",
	"func_getTimestamp_return": "Der Zeitstempel des Kampfes.",
	"func_getDamageReturn": "Gibt die Schadensrückgaberate der Entität mit der ID <b>Entität</b> zurück.",
	"func_getDamageReturn_arg_1": "Die ID der Entität, von der die Schadensmeldung zurückgegeben wird.",
	"func_getDamageReturn_return": "Die Schadensrücklaufquote der Entität mit der Identität <b>Entität</b> (in %).",
	"func_getDeadAllies": "Gibt tote freundliche Entitäten zurück.",
	"func_getDeadAllies_return": "Die Tabelle der verbündeten toten Entitäten.",
	"func_getDeadEnemies": "Gibt tote feindliche Einheiten zurück.",
	"func_getDeadEnemiesCount": "Gibt die Anzahl der Feinde zurück, die im Kampf gestorben sind.",
	"func_getDeadEnemiesCount_return": "Die Anzahl der toten Feinde.",
	"func_getDeadEnemies_return": "Die Tabelle der toten feindlichen Einheiten.",
	"func_getDistance": "Berechnet die Entfernung in Luftlinie zwischen zwei Zellen <b>Zelle1</b> und <b>Zelle2</b>. <br />Um die Entfernung als Anzahl von Zellen zu erhalten, siehe #getCellDistance, und um die Länge des Pfades zwischen den beiden Zellen zu erhalten, die den verschiedenen Hindernissen ausweichen, siehe #getPathLength.",
	"func_getDistance_arg_1": "Die Ausgangszelle.",
	"func_getDistance_arg_2": "Die Ankunftszelle.",
	"func_getDistance_return": "Der Luftlinienabstand zwischen den beiden Zellen.",
	"func_getPassiveEffects": "Gibt die Liste der passiven Effekte der Entität mit der ID <b>Entität</b> zurück. Um die Liste der passiven Effekte Ihrer Entität direkt abzurufen, verwenden Sie #getPassiveEffects() ohne Parameter.",
	"func_getPassiveEffects_arg_1": "Die ID der Entität, deren Liste der passiven Effekte zurückgegeben wird.",
	"func_getPassiveEffects_return": "Die Liste der passiven Effekte, die derzeit auf der <b>Entität</b> vorhanden sind.\nDie Liste der passiven Effekte ist ein Array, das die Effekte enthält.\nEin Effekt selbst ist ein Array aus 7 Feldern der Form: [<b>type</b>, <b>value</b>, <b>caster_id</b>, <b>turns</b >, <b>kritisch</b>, <b>item_id</b>, <b>target_id</b>]. Es ist dieselbe Struktur wie die klassischen Effekte, die von der Funktion #getEffects zurückgegeben werden.",
	"func_getEnemies": "Bringt feindliche Einheiten (lebend oder tot) in den Kampf zurück.",
	"func_getEnemiesCount": "Gibt die Anzahl der Feinde im Kampf zurück.",
	"func_getEnemiesCount_return": "Die Anzahl der Feinde.",
	"func_getEnemiesLife": "Berechnet die Summe der Trefferpunkte aller feindlichen Einheiten.",
	"func_getEnemiesLife_return": "Die Summe der Trefferpunkte des gegnerischen Teams.",
	"func_getEnemies_return": "Ein Array, das die IDs aller feindlichen Einheiten enthält.",
	"func_getEntityTurnOrder": "Gibt einen Wert zwischen 1 und n (Anzahl der derzeit im Spiel befindlichen Entitäten) zurück, der die Position der <b>Entität</b> in der Wiedergabereihenfolge angibt.",
	"func_getEntityTurnOrder_arg_1": "Die ID der Entität, deren Spielreihenfolge zurückgegeben wird",
	"func_getEntityTurnOrder_return": "Platz in der Spielreihenfolge der Entität <b>Entität</b>",
	"func_getFarmerID": "Gibt die Züchter-ID der Entität <b>Entität</b> zurück.",
	"func_getFarmerID_arg_1": "Die ID der Entität, deren Züchter-ID zurückgegeben wird.",
	"func_getFarmerID_return": "Die ID des Entitätsbrüters der <b>Entität</b>.",
	"func_getFarmerCountry": "Gibt das Land des Züchters der Entität <b>Entität</b> zurück.",
	"func_getFarmerCountry_arg_1": "Die ID der Entität, deren Land des Züchters zurückgegeben wird.",
	"func_getFarmerCountry_return": "Das Land des Züchters der <b>Einheit</b> oder \"?\" falls nicht angegeben.",
	"func_getFarmerName": "Gibt den Züchternamen der <b>Entität</b>-Entität zurück.",
	"func_getFarmerName_arg_1": "Die ID der Entität, deren Züchtername zurückgegeben wird.",
	"func_getFarmerName_return": "Der Name des Züchters der <b>Einheit</b>.",
	"func_getFarthestAlly": "Bestimmt den am weitesten entfernten Verbündeten Ihrer Entität in Luftlinie.",
	"func_getFarthestAlly_return": "Die ID der am weitesten entfernten freundlichen Entität.",
	"func_getFarthestEnemy": "Bestimmt den am weitesten entfernten Feind von Ihrer Entität, wie die Krähe fliegt.",
	"func_getFarthestEnemy_return": "Die ID der am weitesten entfernten feindlichen Entität.",
	"func_getFightID": "Gibt die aktuelle Kampf-ID zurück.",
	"func_getFightID_return": "Die ID des aktuellen Kampfes.",
	"func_getFightContext": "Gibt den Kontext des aktuellen Kampfes zurück.",
	"func_getFightContext_return": "Abhängig vom Kampfkontext: Testkampf (#FIGHT_CONTEXT_TEST), Arenakampf (#FIGHT_CONTEXT_GARDEN), Turnierkampf (#FIGHT_CONTEXT_TOURNAMENT), Herausforderungskampf (#FIGHT_CONTEXT_CHALLENGE), Battle Royale (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "Gibt den aktuellen Kampftyp zurück.",
	"func_getFightType_return": "Abhängig von der Art des Kampfes: Einzelkampf (#FIGHT_TYPE_SOLO), Bauernkampf (#FIGHT_TYPE_FARMER), Teamkampf (#FIGHT_TYPE_TEAM), Battle Royale (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "Gibt die Stärke der Entität mit der ID <b>entity</b> zurück.",
	"func_getForce_arg_1": "Die Entität, deren Stärke zurückgegeben wird.",
	"func_getForce_return": "Die Stärke der <b>Entität</b>-Entität.",
	"func_getFrequency": "Gibt die Häufigkeit der Entität mit der ID <b>entity</b> zurück. Verwenden Sie getFrequency() ohne Parameter, um Ihre Frequenz abzurufen.",
	"func_getFrequency_arg_1": "Die Entität, deren Frequenz zurückgegeben wird.",
	"func_getFrequency_return": "Die Häufigkeit der Entität <b>Entität</b>.",
	"func_getGreen": "Gibt den Grünanteil in der Farbe <b>color</b> zwischen 0 und 255 zurück. Beispiel: getGreen(#COLOR_GREEN) = 255 und getGreen(#COLOR_RED) = 0.",
	"func_getGreen_arg_1": "Die Farbe, deren Grünanteil zurückgegeben wird.",
	"func_getGreen_return": "Der Grünanteil in der Farbe <b>Farbe</b>",
	"func_getInstructionsCount": "Gibt die Anzahl der Anweisungen zurück, die Ihre Entität während der aktuellen Runde ausgeführt hat.",
	"func_getInstructionsCount_return": "Die Anzahl der Anweisungen, die Ihre Entität während der aktuellen Runde ausgeführt hat.",
	"func_getLaunchedEffects": "Gibt die Liste der Effekte zurück, die von der Entität mit der ID <b>Entität</b> verursacht wurden.",
	"func_getLaunchedEffects_arg_1": "Die ID der Entität, deren Liste der verursachten Auswirkungen zurückgegeben wird.",
	"func_getLaunchedEffects_return": "Die Liste der Effekte, die von der Entität mit der ID <b>entity</b> verursacht wurden, in derselben Form wie das von #getEffects zurückgegebene Array.",
	"func_getLeek": "Gibt die ID Ihrer Entität zurück.",
	"func_getLeek_return": "Die ID Ihrer Entität.",
	"func_getEntity": "Gibt die ID Ihrer Entität zurück.",
	"func_getEntity_return": "Die ID Ihrer Entität.",
	"func_getLeekID": "Gibt die echte ID des Porree von ID <b>Leek</b> zurück.",
	"func_getLeekID_arg_1": "Die ID des Lauchs, dessen tatsächliche ID zurückgegeben wird.",
	"func_getLeekID_return": "Die echte ID der Lauch-<b>Entität</b>.",
	"func_getLeekOnCell": "Gibt die Entität zurück, die sich auf Zelle <b>cell</b> befindet.",
	"func_getLeekOnCell_arg_1": "Die Zelle, deren Entität wir abrufen möchten.",
	"func_getLeekOnCell_return": "Die ID der Entität in der Zelle oder -1, wenn die Zelle keine Entität hat.",
	"func_getEntityOnCell": "Gibt die Entität zurück, die sich auf Zelle <b>cell</b> befindet.",
	"func_getEntityOnCell_arg_1": "Die Zelle, deren Entität wir abrufen möchten.",
	"func_getEntityOnCell_return": "Die ID der Entität in der Zelle oder -1, wenn die Zelle keine Entität hat.",
	"func_getLevel": "Gibt die Entitätsebene mit der ID <b>Entität</b> zurück.",
	"func_getLevel_arg_1": "Die ID der Entität, deren Ebene zurückgegeben wird.",
	"func_getLevel_return": "Die Ebene der Entität mit der ID <b>Entität</b>.",
	"func_getLife": "Gibt die aktuelle Lebensdauer der Entität mit der ID <b>entity</b> zurück. Verwenden Sie getLife() ohne Parameter, um Ihr Leben abzurufen.",
	"func_getLife_arg_1": "Die ID der Entität, deren Leben zurückgegeben wird.",
	"func_getLife_return": "Das aktuelle Leben der Entität <b>Entität</b>.",
	"func_getMagic": "Gibt die Magie der Entität mit der ID <b>Entität</b> zurück. Verwenden Sie getMagic() ohne Parameter, um Ihre Magie abzurufen.",
	"func_getMagic_arg_1": "Die ID der Entität, deren Magie zurückgegeben wird.",
	"func_getMagic_return": "Die Magie der ID-Entität <b>Entität</b>.",
	"func_getMapType": "Gibt die Art des Geländes zurück, auf dem der Kampf stattfindet (Fabrik, Wüste, Wald usw.), unter den Konstanten #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER und #MAP_BEACH.",
	"func_getMapType_return": "Die Art des Geländes.",
	"func_getMessageAuthor": "Gibt die ID der Entität zurück, die die Nachricht verfasst hat <b>message</b>.",
	"func_getMessageAuthor_arg_1": "Die Nachricht, deren Autor zurückgegeben wird.",
	"func_getMessageAuthor_return": "Die ID der Entität, die die Nachricht verfasst hat <b>message</b>.",
	"func_getMessageParams": "Gibt das Array von Nachrichtenparametern <b>message</b> zurück.",
	"func_getMessageParams_arg_1": "Die Nachricht, deren Parameter zurückgegeben werden.",
	"func_getMessageParams_return": "Die Nachrichtenparameter der <b>Nachricht</b>.",
	"func_getMessages": "Gibt das Entity Message Array <b>entity</b> zurück.",
	"func_getMessages_arg_1": "Die Entität, deren Nachrichten zurückgegeben werden.",
	"func_getMessages_return": "Die Tabelle Ihrer Nachrichten.<br>Eine Nachricht selbst wird in Form von a dargestellt\nArray der Form: [<b>Autor</b>, <b>Typ</b>, <b>Parameter</b>]<br>\nDie verschiedenen Arten von Nachrichten werden durch die Konstanten dargestellt:\n<ul>\n<li>#MESSAGE_HEAL: Pflegeantrag</li>\n<li>#MESSAGE_ATTACK: Angriffsanfrage</li>\n<li>#MESSAGE_BUFF_FORCE: Verstärkungsanforderung erzwingen</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "Gibt den Nachrichtentyp <b>Nachricht</b> zurück.",
	"func_getMessageType_arg_1": "Die Nachricht, deren Typ zurückgegeben wird (unter anderem #MESSAGE_HEAL, #MESSAGE_ATTACK usw.).",
	"func_getMessageType_return": "Der Nachrichtentyp <b>Nachricht</b>.",
	"func_getMP": "Gibt die aktuelle Bewegungspunktzahl der <b>Entität</b>-Entität zurück. Verwenden Sie getMP() ohne Parameter, um Ihre PMs abzurufen.",
	"func_getMP_arg_1": "Die ID der Entität, deren PM-Zählung zurückgegeben wird.",
	"func_getMP_return": "Die MP-Nummer der <b>Entität</b>.",
	"func_getName": "Gibt den Namen der Entität mit der ID <b>Entität</b> zurück.",
	"func_getName_arg_1": "Die ID der Entität, deren Name zurückgegeben wird.",
	"func_getName_return": "Der Entitätsname <b>Entität</b>.",
	"func_getNearestAlly": "Gibt die nächste befreundete Entität zu Ihrer Entität zurück.",
	"func_getNearestAlly_return": "Die ID der nächsten freundlichen Entität.",
	"func_getNearestAllyTo": "Gibt die am nächsten verwandte Entität zu der als Parameter bereitgestellten Entität zurück.",
	"func_getNearestAllyTo_arg_1": "Die ID der Entität, deren engster Verbündeter wir wissen wollen.",
	"func_getNearestAllyToCell": "Gibt die nächste verbündete Entität zu der als Parameter bereitgestellten Zelle zurück.",
	"func_getNearestAllyToCell_arg_1": "Die ID der Zelle, deren engster Verbündeter wir wissen wollen.",
	"func_getNearestAllyToCell_return": "Die ID der nächsten freundlichen Entität.",
	"func_getNearestAllyTo_return": "Die ID der nächsten freundlichen Entität.",
	"func_getNearestEnemy": "Bringt die nächste feindliche Entität zu Ihrer Entität zurück.",
	"func_getNearestEnemy_return": "Die ID der nächsten feindlichen Entität.",
	"func_getNearestEnemyTo": "Gibt die nächste feindliche Entität zu der als Parameter angegebenen Entität zurück.",
	"func_getNearestEnemyTo_arg_1": "Die ID der Entität, deren engsten Feind wir kennen wollen.",
	"func_getNearestEnemyToCell": "Gibt die nächstgelegene feindliche Entität zu der als Parameter bereitgestellten Zelle zurück.",
	"func_getNearestEnemyToCell_arg_1": "Die ID der Zelle, deren engsten Feind wir kennen wollen.",
	"func_getNearestEnemyToCell_return": "Die ID der nächsten feindlichen Entität.",
	"func_getNearestEnemyTo_return": "Die ID der nächsten feindlichen Entität.",
	"func_getNextPlayer": "Gibt die ID der Entität zurück, die nach dem aktuellen Spieler spielen wird.",
	"func_getNextPlayer_return": "Der nächste Spieler.",
	"func_getObstacles": "Gibt die Liste der Hindernisfelder im Gelände zurück.",
	"func_getObstacles_return": "Das Array, das die IDs der Hinderniszellen enthält.",
	"func_getOperations": "Gibt die Anzahl der Operationen zurück, die Ihre Entität seit Beginn ihrer Runde verbraucht hat. Diese Zahl muss kleiner als #OPERATIONS_LIMIT bleiben, damit die Entität nicht abstürzt.",
	"func_getOperations_return": "Anzahl der Operationen, die Ihre Entität seit Beginn ihrer Runde verbraucht hat.",
	"func_getPath": "Gibt den Pfad zurück, der Hindernisse zwischen zwei Zellen <b>cell1</b> und <b>cell2</b> vermeidet, sofern vorhanden, wobei die im Array <b>ignoredCells</b> enthaltenen Zellen ignoriert werden. Wenn sich ein Spieler auf einem ignorierten Feld befindet, kann der Pfad über ihn hinweggehen.<br /><br />\nDie Startzelle <b>cell1</b> ist niemals Teil des resultierenden Pfads. Zelle <b>cell2</b> ist nur dann Teil des resultierenden Pfads, wenn sie leer ist oder von <b>ignoredCells</b> ignoriert wird.<br /><br />\nWenn zwischen den beiden Zellen kein Pfad existiert, gibt <b>getPath</b> <i>null</i> zurück.",
	"func_getPath_arg_1": "Die Ausgangszelle.",
	"func_getPath_arg_2": "Die Ankunftszelle.",
	"func_getPath_arg_3": "Das zu ignorierende Array von Zellen.",
	"func_getPath_return": "Das Array, das die Zellen enthält, die den Pfad zwischen den beiden Zellen bilden.",
	"func_getPathLength": "Gibt die Länge des Pfades zwischen zwei Zellen <b>cell1</b> und <b>cell2</b> zurück, wobei Hindernisse umgangen und die im Array <b>ignoredCells</b> enthaltenen Zellen ignoriert werden. Diese Funktion entspricht <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>.\nWenn sich ein Spieler auf einem ignorierten Feld befindet, kann der Pfad über ihn hinweggehen.<br /><br />\nDie Startzelle <b>Zelle1</b> wird nie im Ergebnis gezählt. Zelle <b>cell2</b> wird nur dann im Ergebnis gezählt, wenn sie leer ist oder von <b>ignoredCells</b> ignoriert wird.<br /><br />\nWenn zwischen den beiden Zellen kein Pfad existiert, gibt <b>getPathLength</b> <i>null</i> zurück.",
	"func_getPathLength_arg_1": "Die Ausgangszelle.",
	"func_getPathLength_arg_2": "Die Ankunftszelle.",
	"func_getPathLength_arg_3": "Das zu ignorierende Array von Zellen.",
	"func_getPathLength_return": "Die Länge des Pfads zwischen <b>cell1</b> und <b>cell2</b>.",
	"func_getPreviousPlayer": "Gibt die ID der Entität zurück, die vor dem aktuellen Spieler gespielt hat.",
	"func_getPreviousPlayer_return": "Der vorherige Spieler.",
	"func_getRed": "Gibt den Rotanteil in der Farbe <b>color</b> zwischen 0 und 255 zurück. Beispiel: getRed(#COLOR_RED) = 255 und getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "Die Farbe, deren Rotanteil zurückgegeben wird.",
	"func_getRed_return": "Der Rotanteil in <b>Farbe</b>",
	"func_getRegister": "Gibt den Wert zurück, der in der Entitätsregistrierung gespeichert ist, die dem Schlüssel <b>key</b> oder </i>null</i> zugeordnet ist, wenn die Registrierung nicht vorhanden ist.",
	"func_getRegister_arg_1": "Der Registrierungsschlüssel, dessen Wert zurückgegeben wird.",
	"func_getRegister_return": "Der in der <b>Schlüssel</b>-Registrierung gespeicherte Wert.",
	"func_getRegisters": "Gibt den Satz von Entitätsregistern als assoziatives Array zurück [<i>Registrierungsschlüssel</i>:<i>Registerwert</i>]. Beispiel: <code>debug(getRegisters());\n// Zeigt zum Beispiel an:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "Das assoziative Array, das allen Registern der Entität entspricht.",
	"func_getRelativeShield": "Gibt den relativen Schild der Entität mit der ID <b>entity</b> zurück. Um den relativen Schild Ihrer Entität direkt abzurufen, verwenden Sie #getRelativeShield() ohne Parameter.",
	"func_getRelativeShield_arg_1": "Die ID der Entität, deren relativer Schild zurückgegeben wird.",
	"func_getRelativeShield_return": "Der relative Schild der <b>Entität</b>, eine ganze Zahl zwischen <b>0</b> und <b>100</b>.",
	"func_getResistance": "Gibt den Widerstand der Entität mit der ID <b>entity</b> zurück. Verwenden Sie getResistance() ohne Parameter, um Ihren Widerstand abzurufen.",
	"func_getResistance_arg_1": "Die ID der Entität, deren Widerstand zurückgegeben wird.",
	"func_getResistance_return": "Der Widerstand der Entität mit der Identität <b>Entität</b>.",
	"func_getScience": "Gibt die Wissenschaft der Entität mit der ID <b>Entität</b> zurück. Verwenden Sie getScience() ohne Parameter, um Ihre Wissenschaft abzurufen.",
	"func_getScience_arg_1": "Die ID der Entität, deren Wissenschaft zurückgegeben wird.",
	"func_getScience_return": "Die Wissenschaft der Identität <b>Entität</b>.",
	"func_getStrength": "Gibt die Stärke der Entität mit der ID <b>entity</b> zurück. Verwenden Sie getStrength() ohne Parameter, um Ihre Stärke abzurufen.",
	"func_getStrength_arg_1": "Die Entität, deren Stärke zurückgegeben wird.",
	"func_getStrength_return": "Die Stärke der <b>Entität</b>-Entität.",
	"func_getPower": "Gibt die Leistung der Entität mit der ID <b>Entität</b> zurück.",
	"func_getPower_arg_1": "Die Entität, deren Macht zurückgegeben wird.",
	"func_getPower_return": "Die Macht der <b>Entität</b>.",
	"func_getSummoner": "Gibt die Entität zurück, die die Entität <b>entity</b> aufgerufen hat, wenn es sich um einen Aufruf handelt.",
	"func_getSummoner_arg_1": "Die ID der Entität, deren Beschwörer gefeuert wird.",
	"func_getSummoner_return": "Die ID der aufgerufenen Entität <b>Entität</b>, wenn es sich um einen Aufruf handelt, andernfalls <i>null</i>.",
	"func_getSummons": "Gibt die Liste der IDs aktuell aktiver Aufrufe der Entität mit der ID <b>Entität</b> zurück.",
	"func_getSummons_arg_1": "Die ID der Entität, deren Vorladung zurückgesendet wird.",
	"func_getSummons_return": "Die Liste der Aufruf-IDs der Entität mit der ID <b>Entität</b>.",
	"func_getTeamID": "Gibt die Team-ID der Entität <b>entity</b> zurück.",
	"func_getTeamID_arg_1": "Die ID der Entität, deren Team-ID zurückgegeben wird.",
	"func_getTeamID_return": "Die Team-ID der Entität <b>Entität</b>.",
	"func_getTeamName": "Gibt den Teamnamen der Entität <b>entity</b> zurück.",
	"func_getTeamName_arg_1": "Die ID der Entität, deren Teamname zurückgegeben wird.",
	"func_getTeamName_return": "Der Teamname der Entität <b>Entität</b>.",
	"func_getTotalLife": "Gibt die Gesamtlebensdauer der Entität mit der ID <b>Entität</b> zurück. Verwenden Sie getTotalLife() ohne Parameter, um Ihr gesamtes Leben abzurufen.",
	"func_getTotalLife_arg_1": "Die ID der Entität, deren gesamte Lebensdauer zurückgegeben wird.",
	"func_getTotalLife_return": "Die Gesamtlebensdauer der Entität.",
	"func_getTotalMP": "Gibt die maximale Anzahl an Bewegungspunkten der Entität <b>entity</b> zurück.",
	"func_getTotalMP_arg_1": "Die ID der Entität, deren maximale Bewegungspunkte zurückgegeben werden.",
	"func_getTotalMP_return": "Die maximale Anzahl an Bewegungspunkten für die <b>Entität</b>.",
	"func_getTotalTP": "Gibt die maximale Anzahl von Wendepunkten der Entität <b>entity</b> zurück.",
	"func_getTotalTP_arg_1": "Die ID der Entität, deren maximale Anzahl von Wendepunkten zurückgegeben wird.",
	"func_getTotalTP_return": "Die maximale Anzahl von Wendepunkten der <b>Entität</b>-Entität.",
	"func_getTP": "Gibt die Anzahl der Wendepunkte der Entität <b>entity</b> zurück. Verwenden Sie getTP() ohne Parameter, um Ihre PTs abzurufen.",
	"func_getTP_arg_1": "Die ID der Entität, deren PTs zurückgegeben werden.",
	"func_getTP_return": "Die PT-Nummer der <b>Entität</b>-Entität.",
	"func_getTurn": "Gibt die aktuelle Kampfrunde zurück. Die maximale Anzahl an Zügen beträgt #MAX_TURNS.",
	"func_getTurn_return": "Die aktuelle Kampfrunde.",
	"func_getType": "Gibt den Entitätstyp der Entität <b>entity</b> zurück.",
	"func_getType_arg_1": "Die ID der Entität, deren Typ zurückgegeben wird.",
	"func_getType_return": "Der Entitätstyp von <b>Entität</b>:<ul><li>#ENTITY_LEEK, wenn es ein Lauch ist.</li><li>#ENTITY_BULB, wenn es eine Zwiebel ist.</li><li >#ENTITY_TURRET, wenn es sich um einen Turm handelt.</li></ul>",
	"func_getWeapon": "Gibt die aktuell ausgerüstete Waffen-<b>Entität</b> zurück.",
	"func_getWeapon_arg_1": "Die ID der Entität, deren aktuelle Waffe zurückgegeben wird.",
	"func_getWeapon_return": "Die ID der aktuell ausgerüsteten Waffe auf der <b>Entität</b>, null, wenn die Entität keine ausgerüstete Waffe hat oder die Entität nicht existiert.",
	"func_getWeaponArea": "Gibt den Typ des Wirkungsbereichs der Waffe <b>Waffe</b> zurück.",
	"func_getWeaponArea_arg_1": "Die Waffe, deren Zonentyp zurückgegeben wird.",
	"func_getWeaponArea_return": "Der Bereichstyp der <b>Waffe</b>-Waffe unter den AREA_*-Konstanten:\n<ul>\n<li>#AREA_POINT: einzelner quadratischer Bereich</li>\n<li>#AREA_LASER_LINE: Linie eines Lasers</li>\n<li>#AREA_CIRCLE_1: kreisförmiger Bereich mit 3 Zellen Durchmesser</li>\n<li>#AREA_CIRCLE_2: kreisförmiger Bereich mit 5 Quadraten im Durchmesser</li>\n<li>#AREA_CIRCLE_3: kreisförmiger Bereich mit 7 Quadraten im Durchmesser</li>\n</ul>",
	"func_getWeaponCost": "Gibt die TP-Kosten der <b>Waffe</b> zurück.",
	"func_getWeaponCost_arg_1": "Die ID der Waffe, deren Kosten zurückgegeben werden.",
	"func_getWeaponCost_return": "Die TP-Kosten der <b>Waffe</b> Waffe.",
	"func_getWeaponEffectiveArea": "Gibt die Liste der Zellen zurück, die betroffen sind, wenn die Waffe <b>Waffe</b> auf Zelle <b>Zelle</b> von Zelle <b>von</b> verwendet wird.",
	"func_getWeaponEffectiveArea_arg_1": "Die Waffe zum Testen.",
	"func_getWeaponEffectiveArea_arg_2": "Die Zielzelle.",
	"func_getWeaponEffectiveArea_arg_3": "Die Zelle, aus der die Waffe abgefeuert wird.",
	"func_getWeaponEffectiveArea_return": "Das Array, das die IDs aller betroffenen Zellen enthält.",
	"func_getWeaponPassiveEffects": "Gibt die passiven Effekte der Waffe <b>Waffe</b> zurück.",
	"func_getWeaponPassiveEffects_arg_1": "Die ID der Waffe, deren passive Effekte zurückgegeben werden.",
	"func_getWeaponPassiveEffects_return": "Ein Array, das die Effekte der <b>Waffe</b> Waffe enthält. Jeder Effekt ist selbst ein Array des Formulars\n[Typ, Min., Max., Wendungen, Ziele, Modifikatoren]. Diese Effekte sind dieselben wie die von #getWeaponEffects zurückgegebenen.",
	"func_getWeaponFailure": "Gibt den Prozentsatz der <b>Waffe</b> Waffenversagenswahrscheinlichkeit zurück.",
	"func_getWeaponFailure_arg_1": "Die ID der Waffe, deren Fehlerprozentsatz zurückgegeben wird.",
	"func_getWeaponFailure_return": "<b>Waffe</b> Waffenfehlerprozentsatz, eine ganze Zahl zwischen <b>0</b> und <b>100</b>.",
	"func_getWeaponMaxRange": "Gibt die maximale Reichweite der <b>Waffe</b> Waffe zurück.",
	"func_getWeaponMaxRange_arg_1": "Die ID der Waffe, deren maximale Reichweite zurückgegeben wird.",
	"func_getWeaponMaxRange_return": "Die maximale Reichweite der <b>Waffe</b> Waffe.",
	"func_getWeaponMaxScope": "Gibt die maximale Reichweite der <b>Waffe</b> Waffe zurück.",
	"func_getWeaponMaxScope_arg_1": "Die ID der Waffe, deren maximale Reichweite zurückgegeben wird.",
	"func_getWeaponMaxScope_return": "Die maximale Reichweite der <b>Waffe</b> Waffe.",
	"func_getWeaponMinRange": "Gibt die Mindestreichweite der <b>Waffe</b> Waffe zurück.",
	"func_getWeaponMinRange_arg_1": "Die ID der Waffe, deren Mindestreichweite zurückgegeben wird.",
	"func_getWeaponMinRange_return": "Die Mindestreichweite der <b>Waffe</b> Waffe.",
	"func_getWeaponMinScope": "Gibt die Mindestreichweite der <b>Waffe</b> Waffe zurück.",
	"func_getWeaponMinScope_arg_1": "Die ID der Waffe, deren Mindestreichweite zurückgegeben wird.",
	"func_getWeaponMinScope_return": "Die Mindestreichweite der <b>Waffe</b> Waffe.",
	"func_getWeaponLaunchType": "Gibt den Startmodus der Waffe <b>Waffe</b> unter den LAUNCH_TYPE_*-Konstanten zurück.",
	"func_getWeaponLaunchType_arg_1": "Die ID der Waffe, deren Wurfmodus zurückgegeben wird. Standardmäßig Ihre aktuell ausgerüstete Waffe.",
	"func_getWeaponLaunchType_return": "Der Wurfmodus der <b>Waffe</b> Waffe.",
	"func_getWeaponName": "Gibt den Namen der <b>Waffe</b> Waffe zurück.",
	"func_getWeaponName_arg_1": "Die ID der Waffe, deren Name zurückgegeben wird.",
	"func_getWeaponName_return": "Der Name der <b>Waffe</b> Waffe.",
	"func_getWeapons": "Gibt die Waffen der Entität mit der ID <b>Entität</b> zurück.",
	"func_getWeapons_arg_1": "Die ID der Entität, deren Waffen zurückgegeben werden.",
	"func_getWeapons_return": "Ein Array, das die Waffen-IDs der <b>Entität</b> enthält.",
	"func_getWeaponTargets": "Gibt die Entitäten zurück, die betroffen sind, wenn die Waffe <b>Waffe</b> auf Zelle <b>Zelle</b> verwendet wird.",
	"func_getWeaponTargets_arg_1": "Die Waffe zum Testen.",
	"func_getWeaponTargets_arg_2": "Die Zielzelle.",
	"func_getWeaponTargets_return": "Das Array, das die IDs aller betroffenen Entitäten enthält.",
	"func_getWisdom": "Gibt die Weisheit der Entität mit der ID <b>Entität</b> zurück. Verwenden Sie getWisdom() ohne Parameter, um Ihre Weisheit abzurufen.",
	"func_getWisdom_arg_1": "Die ID der Entität, deren Weisheit zurückgegeben wird.",
	"func_getWisdom_return": "Die Weisheit der Entität mit Identität <b>Entität</b>.",
	"func_hypot": "Gibt die Hypotenuse des rechtwinkligen Dreiecks mit den Seiten x und y zurück. Entspricht <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "Der x-Wert.",
	"func_hypot_arg_2": "Sie schätzen.",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "Bestimmt, ob sich das Element <b>element</b> im Array <b>array</b> befindet.",
	"func_inArray_arg_1": "Die Suchtabelle.",
	"func_inArray_arg_2": "Das zu suchende Element.",
	"func_inArray_return": "<i>true</i> wenn das Element im Array ist, andernfalls <i>false</i>.",
	"func_include": "Fügen Sie die KI mit dem Namen <b>ai</b> in die aktuelle KI ein. <br><br><b>Warnung</b>, die Include-Funktion darf nur im Hauptblock aufgerufen werden, und ihr Parameter muss ein direkt geschriebener String sein\nim Code. Weitere Informationen finden Sie im Abschnitt des Tutorials: <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a> .",
	"func_include_arg_1": "Der Name der einzuschließenden KI.",
	"func_indexOf": "Ermittelt die Position des ersten Vorkommens des Strings <b>search</b> im String <b>string</b>, beginnend bei Position <b>start</b>.",
	"func_indexOf_arg_1": "Die Zeichenfolge, in der die Suche durchgeführt wird.",
	"func_indexOf_arg_2": "Die Zeichenfolge, nach der gesucht werden soll.",
	"func_indexOf_arg_3": "Die Startposition der Suche.",
	"func_indexOf_return": "Die Position des ersten Vorkommens von <b>search</b> in <b>string</b> ab <b>start</b>, -1, wenn der String nicht gefunden wurde.",
	"func_insert": "Fügt ein <b>element</b>-Element in das <b>Array</b> an Position <b>position</b> ein.",
	"func_insert_arg_1": "Das Array, in das das Element eingefügt wird.",
	"func_insert_arg_2": "Das einzufügende Element.",
	"func_insert_arg_3": "Die Position der Einfügung.",
	"func_isAlive": "Bestimmt, ob eine <b>Entität</b> lebt. Äquivalent zu <i>getLife(<b>entity</b>) > 0</i>.",
	"func_isAlive_arg_1": "Die ID der zu testenden Entität.",
	"func_isAlive_return": "<i>wahr</i> wenn <b>Entität</b> lebt, <i>falsch</i> wenn tot.",
	"func_isAlly": "Bestimmt, ob <b>Entität</b> Ihr Verbündeter ist.",
	"func_isAlly_arg_1": "Die ID der zu testenden Entität.",
	"func_isAlly_return": "<i>wahr</i>, wenn die <b>Entität</b> Ihr Verbündeter oder Sie selbst ist, <i>falsch</i>, wenn es ein Feind ist.",
	"func_isChip": "Bestimmt, ob ein Wert eine Konstante ist, die einen Chip darstellt.<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "Die zu bestimmende Zahl.",
	"func_isChip_return": "<i>wahr</i>, wenn der Wert eine Chipkonstante ist.",
	"func_isDead": "Bestimmt, ob <b>Entität</b> tot ist. Äquivalent zu <i>getLife(<b>entity</b>) == 0</i>.",
	"func_isDead_arg_1": "Die ID der zu testenden Entität.",
	"func_isDead_return": "<i>wahr</i> wenn <b>Entität</b> tot ist, <i>falsch</i> wenn lebendig.",
	"func_isEmpty": "Bestimmt, ob das Array <b>array</b> leer ist. Entspricht <i>count(<b>array</b>) == 0</i>.",
	"func_isEmpty_arg_1": "Das zu testende Array.",
	"func_isEmptyCell": "Bestimmt, ob eine Zelle leer ist.",
	"func_isEmptyCell_arg_1": "Die zu testende Zelle.",
	"func_isEmptyCell_return": "<i>true</i>, wenn die Zelle leer ist, andernfalls <i>false</i>.",
	"func_isEmpty_return": "<i>true</i>, wenn das Array leer ist, andernfalls <i>false</i>.",
	"func_isEnemy": "Bestimmt, ob <b>Entität</b> Ihr Feind ist.",
	"func_isEnemy_arg_1": "Die ID der zu testenden Entität.",
	"func_isEnemy_return": "<i>wahr</i>, wenn die <b>Entität</b> ein Feind ist, <i>falsch</i>, wenn es ein Verbündeter oder Sie selbst ist.",
	"func_isInlineChip": "Legt fest, ob der <b>Chip</b> nur online verwendet werden kann.",
	"func_isInlineChip_arg_1": "Die ID des zu testenden Chips.",
	"func_isInlineChip_return": "<i>true</i>, wenn der Chip nur online ist, andernfalls <i>false</i>.",
	"func_isInlineWeapon": "Legt fest, ob die <b>Waffe</b> Waffe nur online verwendet werden kann.",
	"func_isInlineWeapon_arg_1": "Die ID der zu testenden Waffe.",
	"func_isInlineWeapon_return": "<i>true</i>, wenn die Waffe nur online verwendet werden kann, andernfalls <i>false</i>.",
	"func_isLeek": "Bestimmt, ob der Inhalt der Zelle <b>cell</b> eine Entität ist.",
	"func_isLeek_arg_1": "Die zu testende Zelle.",
	"func_isLeek_return": "<i>true</i>, wenn die Zelle eine Entität enthält, andernfalls <i>false</i>.",
	"func_isEntity": "Bestimmt, ob der Inhalt der Zelle <b>cell</b> eine Entität ist.",
	"func_isEntity_arg_1": "Die zu testende Zelle.",
	"func_isEntity_return": "<i>true</i>, wenn die Zelle eine Entität enthält, andernfalls <i>false</i>.",
	"func_isObstacle": "Bestimmt, ob der Inhalt der Zelle <b>cell</b> ein Hindernis ist.",
	"func_isObstacle_arg_1": "Die zu testende Zelle.",
	"func_isObstacle_return": "<i>true</i>, wenn die Zelle ein Hindernis enthält, andernfalls <i>false</i>.",
	"func_isOnSameLine": "Bestimmt, ob sich zwei Zellen <b>cell1</b> und <b>cell2</b> in derselben Zeile befinden.",
	"func_isOnSameLine_arg_1": "Die erste Zelle.",
	"func_isOnSameLine_arg_2": "Die zweite Zelle.",
	"func_isOnSameLine_return": "<i>true</i>, wenn die beiden Zellen auf derselben Zeile liegen, andernfalls <i>false</i>.",
	"func_isStatic": "Gibt zurück, ob <b>entity</b> statisch ist oder nicht. Eine statische Entität kann sich nicht bewegen oder bewegt werden.",
	"func_isStatic_arg_1": "Die ID der zu testenden Entität.",
	"func_isStatic_return": "<i>true</i> wenn <b>entity</b> statisch ist, andernfalls <i>false</i>.",
	"func_isSummon": "Gibt zurück, ob <b>entity</b> ein Aufruf ist oder nicht.",
	"func_isSummon_arg_1": "Die ID der zu testenden Entität.",
	"func_isSummon_return": "<i>true</i>, wenn <b>entity</b> ein Aufruf ist, andernfalls <i>false</i>.",
	"func_isWeapon": "Bestimmt, ob ein Wert eine Konstante ist, die eine Waffe darstellt.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false.",
	"func_isWeapon_arg_1": "Die zu bestimmende Zahl.",
	"func_isWeapon_return": "<i>wahr</i>, wenn der Wert eine Waffenkonstante ist.",
	"func_join": "Führt mehrere Elemente zu einem String zusammen und trennt sie durch ein <b>Glue</b>-Trennzeichen.",
	"func_join_arg_1": "Ein Array von Elementen, die zusammengeführt werden sollen.",
	"func_join_arg_2": "Das Elementtrennzeichen.",
	"func_join_return": "Die resultierende Zeichenfolge der Zusammenführung.",
	"func_jsonDecode": "Dekodiert den String <b>json</b> in ein LeekScript-Objekt (Zahl, String, Array...).",
	"func_jsonDecode_arg_1": "Die zu decodierende JSON-Zeichenfolge.",
	"func_jsonDecode_return": "Das decodierte LeekScript-Objekt.",
	"func_jsonEncode": "Codiert das Objekt <b>object</b> in eine JSON-Zeichenfolge.",
	"func_jsonEncode_arg_1": "Das in JSON zu codierende Objekt.",
	"func_jsonEncode_return": "Die JSON-codierte Zeichenfolge.",
	"func_keySort": "Sortiert das <b>Array</b> nach Schlüsseln in <b>Reihenfolge</b>.",
	"func_keySort_arg_1": "Das zu sortierende Array.",
	"func_keySort_arg_2": "Die Sortierreihenfolge: #SORT_ASC oder #SORT_DESC.",
	"func_length": "Gibt die Länge von <b>string</b> zurück.",
	"func_length_arg_1": "Die Zeichenfolge, deren Länge zurückgegeben wird.",
	"func_length_return": "Die Länge von <b>Zeichenfolge</b>.",
	"func_lineOfSight": "Überprüft die Sichtlinie zwischen der <b>Start</b>-Zelle und der <b>End</b>-Zelle, wobei <b>ignoredEntities</b>-Entitäten ignoriert werden.",
	"func_lineOfSight_arg_1": "Startzelle.",
	"func_lineOfSight_arg_2": "Zielzelle.",
	"func_lineOfSight_arg_3": "Eine Entität oder Liste von zu ignorierenden Entitäten.",
	"func_lineOfSight_return": "Gibt <i>true</i> zurück, wenn die Sichtlinie frei ist.",
	"func_listen": "Gibt das Array von say() vorheriger Entitäten in der Form [entity_id, message] zurück.",
	"func_listen_return": "Das Array der vorherigen say(s).",
	"func_log": "Berechnet den natürlichen Logarithmus der Zahl <b>Zahl</b>.",
	"func_log_arg_1": "Eine Zahl im Bereich ]0; +∞[.",
	"func_log_return": "Der natürliche Logarithmus von <b>Zahl</b>.",
	"func_log10": "Berechnet den Logarithmus zur Basis 10 der Zahl <b>Zahl</b>.",
	"func_log10_arg_1": "Eine Zahl im Bereich ]0; +∞[.",
	"func_log10_return": "Der Logarithmus zur Basis 10 von <b>Zahl</b>.",
	"func_log2": "Berechnet den Logarithmus zur Basis 2 der Zahl <b>Zahl</b>.",
	"func_log2_arg_1": "Eine Zahl im Bereich ]0; +∞[.",
	"func_log2_return": "Der Logarithmus zur Basis 2 von <b>Zahl</b>.",
	"func_mark": "Markiert ein oder mehrere Felder der im Parameter angegebenen Farbe auf dem Boden für die im Parameter angegebene Anzahl von Umdrehungen. Diese Kennzeichnung ist nur für den Züchter der Entität sichtbar.",
	"func_mark_arg_1": "Die zu markierende Zelle oder das Array aus mehreren Zellen",
	"func_mark_arg_2": "Markierungsfarbe",
	"func_mark_arg_3": "Dauer der Markierung",
	"func_mark_return": "Gibt true zurück, wenn alles gut gelaufen ist",
	"func_markText": "Schreibt einen Text auf ein oder mehrere Felder der im Parameter angegebenen Farbe auf den Boden für die im Parameter angegebene Anzahl von Umdrehungen. Diese Texte sind nur für den Züchter der Entität sichtbar.",
	"func_markText_arg_1": "Die Zelle oder das Array aus mehreren Zellen, in die geschrieben werden soll",
	"func_markText_arg_2": "Der zu schreibende Text (maximal 10 Zeichen)",
	"func_markText_arg_3": "Textfarbe",
	"func_markText_arg_4": "Textdauer",
	"func_markText_return": "Gibt true zurück, wenn alles gut gelaufen ist",
	"func_max": "Gibt den größeren Wert zwischen den beiden Zahlen <b>a</b> und <b>b</b> zurück.",
	"func_max_arg_1": "Eine Zahl.",
	"func_max_arg_2": "Eine Zahl.",
	"func_max_return": "Die größere Zahl zwischen <b>a</b> und <b>b</b>.",
	"func_min": "Gibt den kleineren Wert zwischen den beiden Zahlen <b>a</b> und <b>b</b> zurück.",
	"func_min_arg_1": "Eine Zahl.",
	"func_min_arg_2": "Eine Zahl.",
	"func_min_return": "Die kleinere Zahl zwischen <b>a</b> und <b>b</b>.",
	"func_moveAwayFrom": "Bewegt Ihre Entität von einer anderen <b>Entität</b> weg und verwendet dabei maximale <b>MP</b>-Bewegungspunkte.",
	"func_moveAwayFrom_arg_1": "Die Entität, von der sich Ihre Entität entfernen sollte.",
	"func_moveAwayFrom_arg_2": "Die maximale Anzahl an zu verwendenden MP. Standardmäßig keine Begrenzung.",
	"func_moveAwayFrom_return": "Die Anzahl der verwendeten Bewegungspunkte.",
	"func_moveAwayFromCell": "Bewegt deine Entität um eine <b>Zelle</b> Zelle weg und verbraucht dabei <b>MP</b> Bewegungspunkte.",
	"func_moveAwayFromCell_arg_1": "Die Zelle, von der sich Ihre Entität entfernen soll.",
	"func_moveAwayFromCell_arg_2": "Die maximale Anzahl an zu verwendenden MP. Standardmäßig keine Begrenzung.",
	"func_moveAwayFromCell_return": "Die Anzahl der verwendeten Bewegungspunkte.",
	"func_moveAwayFromCells": "Bewegt Ihre Entität von einer Reihe von <b>Zellen</b> Zellen weg, wobei maximale <b>MP</b> Bewegungspunkte verwendet werden.",
	"func_moveAwayFromCells_arg_1": "Das Array, das die Zellen enthält, von denen sich Ihre Entität entfernen soll.",
	"func_moveAwayFromCells_arg_2": "Die maximale Anzahl an zu verwendenden MP. Standardmäßig keine Begrenzung.",
	"func_moveAwayFromCells_return": "Die Anzahl der verwendeten Bewegungspunkte.",
	"func_moveAwayFromLeeks": "Bewegt Ihre Entität von einer Reihe von <b>Entitäten</b> weg, wobei maximale <b>MP</b>-Bewegungspunkte verwendet werden.",
	"func_moveAwayFromLeeks_arg_1": "Das Array mit den IDs der Entitäten, von denen sich Ihre Entität entfernen soll.",
	"func_moveAwayFromLeeks_arg_2": "Die maximale Anzahl an zu verwendenden MP. Standardmäßig keine Begrenzung.",
	"func_moveAwayFromLeeks_return": "Die Anzahl der verwendeten Bewegungspunkte.",
	"func_moveAwayFromEntities": "Bewegt Ihre Entität von einer Reihe von <b>Entitäten</b> weg, wobei maximale <b>MP</b>-Bewegungspunkte verwendet werden.",
	"func_moveAwayFromEntities_arg_1": "Das Array mit den IDs der Entitäten, von denen sich Ihre Entität entfernen soll.",
	"func_moveAwayFromEntities_arg_2": "Die maximale Anzahl an zu verwendenden MP. Standardmäßig keine Begrenzung.",
	"func_moveAwayFromEntities_return": "Die Anzahl der verwendeten Bewegungspunkte.",
	"func_moveAwayFromLine": "Bewegt Ihr Objekt von einer Linie weg, die durch zwei Zellen <b>Zelle1</b> und <b>Zelle2</b> definiert ist, wobei maximal <b>mp</b> Bewegungspunkte verwendet werden.",
	"func_moveAwayFromLine_arg_1": "Zelle 1.",
	"func_moveAwayFromLine_arg_2": "Zelle 2.",
	"func_moveAwayFromLine_arg_3": "Die maximale Anzahl an zu verwendenden MP. Standardmäßig keine Begrenzung.",
	"func_moveAwayFromLine_return": "Die Anzahl der verwendeten Bewegungspunkte.",
	"func_moveToward": "Bringt Ihre Entität näher an eine andere <b>Entität</b>, wobei maximale <b>MP</b>-Bewegungspunkte verwendet werden.",
	"func_moveToward_arg_1": "Die Entität, an die sich Ihre Entität wenden sollte.",
	"func_moveToward_arg_2": "Die maximale Anzahl an zu verwendenden MP.",
	"func_moveToward_return": "Die Anzahl der verwendeten Bewegungspunkte.",
	"func_moveTowardCell": "Bewegt Ihre Entität um eine <b>Zelle</b> Zelle näher, wobei maximale <b>MP</b>-Bewegungspunkte verwendet werden.",
	"func_moveTowardCell_arg_1": "Die Zelle, der sich Ihr Feature nähern soll.",
	"func_moveTowardCell_arg_2": "Die maximale Anzahl an zu verwendenden MP. Standardmäßig keine Begrenzung.",
	"func_moveTowardCell_return": "Die Anzahl der verwendeten Bewegungspunkte.",
	"func_moveTowardCells": "Bewegt Ihre Entität näher an eine Reihe von <b>Zellen</b> Zellen, wobei maximale <b>MP</b> Bewegungspunkte verwendet werden.",
	"func_moveTowardCells_arg_1": "Das Array, das die Zellen enthält, an die sich Ihre Entität annähern soll.",
	"func_moveTowardCells_arg_2": "Die maximale Anzahl an zu verwendenden MP. Standardmäßig keine Begrenzung.",
	"func_moveTowardCells_return": "Die Anzahl der verwendeten Bewegungspunkte.",
	"func_moveTowardLeeks": "Bringt Ihre Entität näher an eine Reihe von <b>Entitäten</b>, wobei maximale <b>MP</b>-Bewegungspunkte verwendet werden.",
	"func_moveTowardLeeks_arg_1": "Das Array, das die IDs der Entitäten enthält, an die sich Ihre Entität wenden soll.",
	"func_moveTowardLeeks_arg_2": "Die maximale Anzahl an zu verwendenden MP. Standardmäßig keine Begrenzung.",
	"func_moveTowardLeeks_return": "Die Anzahl der verwendeten Bewegungspunkte.",
	"func_moveTowardEntities": "Bringt Ihre Entität näher an eine Reihe von <b>Entitäten</b>, wobei maximale <b>MP</b>-Bewegungspunkte verwendet werden.",
	"func_moveTowardEntities_arg_1": "Das Array, das die IDs der Entitäten enthält, an die sich Ihre Entität wenden soll.",
	"func_moveTowardEntities_arg_2": "Die maximale Anzahl an zu verwendenden MP. Standardmäßig keine Begrenzung.",
	"func_moveTowardEntities_return": "Die Anzahl der verwendeten Bewegungspunkte.",
	"func_moveTowardLine": "Bringt Ihr Objekt näher an eine Linie heran, die durch zwei Zellen <b>Zelle1</b> und <b>Zelle2</b> definiert ist, wobei maximale <b>MP</b>-Bewegungspunkte verwendet werden.",
	"func_moveTowardLine_arg_1": "Zelle 1.",
	"func_moveTowardLine_arg_2": "Zelle 2.",
	"func_moveTowardLine_arg_3": "Die maximale Anzahl an zu verwendenden MP. Standardmäßig keine Begrenzung.",
	"func_moveTowardLine_return": "Die Anzahl der verwendeten Bewegungspunkte.",
	"func_number": "Konvertiert einen Wert in eine Zahl. Wenn der Wert eine Zeichenfolge ist, versucht die Zahlenfunktion, ihn in eine Zahl umzuwandeln, wenn der Wert bereits eine Zahl ist, gibt die Funktion die Zahl zurück, und für jeden anderen Typ gibt sie <i>null</i> zurück.",
	"func_number_arg_1": "Der Wert, der in eine Zahl konvertiert werden soll.",
	"func_number_return": "Die konvertierte Zahl.",
	"func_pause": "Pausiert den Kampf, nur für den Züchter der Entität, die die Funktion verwendet.",
	"func_pop": "Entfernt die letzte Zelle aus dem <b>Array</b> und gibt das entsprechende Element zurück.",
	"func_pop_arg_1": "Das Array, dessen letzte Box gelöscht wird.",
	"func_pop_return": "Der Artikel, der sich in der alten letzten Kiste befand.",
	"func_pow": "Potenziert die Basiszahl mit dem Exponenten.",
	"func_pow_arg_1": "Die Basis.",
	"func_pow_arg_2": "Der Aussteller.",
	"func_pow_return": "<b>Basis</b> potenziert mit <b>Exponent</b>.",
	"func_push": "Fügt das Element <b>element</b> am Ende des <b>Arrays</b> hinzu.",
	"func_pushAll": "Hängt alle Elemente des Arrays <b>elements</b> an das Ende des Arrays <b>array</b> an.",
	"func_pushAll_arg_1": "Das Array, dem wir die Elemente hinzufügen möchten.",
	"func_pushAll_arg_2": "Elemente zum Hinzufügen.",
	"func_push_arg_1": "Das Array, in dem wir das Element hinzufügen möchten.",
	"func_push_arg_2": "Das hinzuzufügende Element.",
	"func_rand": "Gibt eine echte Zufallszahl zwischen 0 (einschließlich) und 1 (ausschließlich) zurück.",
	"func_randFloat": "Gibt eine echte Zufallszahl zwischen <b>a</b> (einschließlich) und <b>b</b> (ausschließlich) zurück.",
	"func_randFloat_arg_1": "Untere Grenze.",
	"func_randFloat_arg_2": "Obere Grenze.",
	"func_randFloat_return": "Eine Zufallszahl im Bereich [a; B[.",
	"func_randInt": "Gibt eine zufällige Ganzzahl zwischen <b>a</b> (einschließlich) und <b>b</b> (ausschließlich) zurück.",
	"func_randInt_arg_1": "Untere Grenze.",
	"func_randInt_arg_2": "Obere Grenze.",
	"func_randInt_return": "Eine zufällige ganze Zahl im Bereich [a; B[.",
	"func_rand_return": "Eine Zufallszahl im Bereich [0; 1[.",
	"func_remove": "Entfernt ein Element aus dem Array <b>array</b> an Position <b>position</b> und gibt das gelöschte Element zurück.",
	"func_remove_arg_1": "Array, aus dem wir ein Element löschen möchten.",
	"func_remove_arg_2": "Position des zu löschenden Elements.",
	"func_removeElement": "Löscht das erste Vorkommen eines <b>Elements</b> im <b>Array</b>. Wenn das Element nicht gefunden wird, wird das Array nicht geändert.",
	"func_removeElement_arg_1": "Array, aus dem wir ein Element löschen möchten.",
	"func_removeElement_arg_2": "Zu suchendes und dann zu löschendes Element.",
	"func_removeKey": "Löscht ein Element aus dem Array <b>array</b>, das dem Schlüssel <b>key</b> zugeordnet ist.",
	"func_removeKey_arg_1": "Array, aus dem wir ein Element löschen möchten.",
	"func_removeKey_arg_2": "Der Schlüssel, der dem zu löschenden Element zugeordnet ist.",
	"func_remove_return": "Das aus dem Array entfernte Element.",
	"func_replace": "Ersetzt alle Vorkommen von <b>search</b> durch <b>replace</b> in der Zeichenfolge <b>string</b>.",
	"func_replace_arg_1": "Zeichenfolge, in der Ersetzungen vorgenommen werden.",
	"func_replace_arg_2": "Zu ersetzende Teilzeichenfolge.",
	"func_replace_arg_3": "Ersatzsaite.",
	"func_replace_return": "Die Ergebniszeichenfolge mit Ersetzungen.",
	"func_resurrect": "Verwendet den Chip CHIP_RESURRECTION, um eine tote Entität mit der Identität <b>Entität</b> auf Zelle <b>Zelle</b> wiederzubeleben.",
	"func_resurrect_arg_1": "Die ID der wiederzubelebenden Entität.",
	"func_resurrect_arg_2": "Die Zelle, auf der die Entität wieder erscheint.",
	"func_resurrect_return": "Das Ergebnis des Starts des Chips, unter den Konstanten USE_*.",
	"func_reverse": "Kehrt das <b>Array</b> um.",
	"func_reverse_arg_1": "Tabelle zum Invertieren.",
	"func_round": "Gibt die Rundung von <b>Zahl</b> zurück.<br/>Sie finden die Abrundung mit #floor und die Aufrundung mit #ceil.",
	"func_round_arg_1": "Zahl, deren Rundung wir finden wollen.",
	"func_round_return": "Gerundet um <b>Zahl</b>.",
	"func_say": "Bringen Sie Ihr Wesen zum Sprechen.",
	"func_say_arg_1": "Nachricht, die Ihre Entität in der Arena ankündigen wird.",
	"func_search": "Findet das Element <b>element</b> im Array <b>array</b>, beginnend bei Position <b>start</b>.",
	"func_search_arg_1": "Die Suchtabelle.",
	"func_search_arg_2": "Das zu suchende Element.",
	"func_search_arg_3": "Die Position des Beginns der Suche.",
	"func_search_return": "Die Position des ersten Vorkommens des Elements <b>element</b> im <b>array</b>, beginnend bei <b>start</b>, null, wenn das Element nicht gefunden wurde.",
	"func_sendAll": "Senden Sie eine Nachricht an Ihr gesamtes Team.",
	"func_sendAll_arg_1": "Der Typ der zu sendenden Nachricht (siehe die MESSAGE_*-Konstanten).",
	"func_sendAll_arg_2": "Die Nachrichtenparameter, die einen beliebigen Wert haben können.",
	"func_sendTo": "Sendet eine Nachricht an die Entität mit der ID <b>Entität</b>.",
	"func_sendTo_arg_1": "Die ID der Entität, an die die Nachricht gesendet wird.",
	"func_sendTo_arg_2": "Der Typ der zu sendenden Nachricht (siehe die MESSAGE_*-Konstanten).",
	"func_sendTo_arg_3": "Die Nachrichtenparameter, die einen beliebigen Wert haben können.",
	"func_sendTo_return": "<i>true</i> wenn der Versand erfolgreich war, <i>false</i> wenn ein Fehler aufgetreten ist.",
	"func_setRegister": "Speichert den <b>Wert</b> in der <b>Schlüssel</b>-Registrierung.\nDer Schlüssel und der Wert sind Zeichenfolgen, die höchstens <i>100</i> bzw. <i>5000</i> Zeichen enthalten dürfen. Ein Porree kann maximal <i>100</i> Register haben, das Speichern in einem neuen Register funktioniert nicht, wenn alle Register vorhanden sind\nsind bereits besetzt.",
	"func_setRegister_arg_1": "Der Registrierungsschlüssel, in dem der Wert gespeichert werden soll.",
	"func_setRegister_arg_2": "Der zu speichernde Wert.",
	"func_setRegister_return": "<i>true</i>, wenn die Operation erfolgreich war, andernfalls <i>false</i>.",
	"func_setWeapon": "Rüsten Sie Ihre Entität mit der <b>Waffe</b> aus.",
	"func_setWeapon_arg_1": "ID der auszurüstenden Waffe.",
	"func_shift": "Entfernt die erste Zelle aus dem <b>Array</b> und gibt das entsprechende Element zurück.",
	"func_shift_arg_1": "Das Array, dessen erstes Feld gelöscht wird.",
	"func_shift_return": "Das Element, das sich in der alten ersten Zelle befand.",
	"func_show": "Zeigt den Spielern für 1 Runde ein <b>Feld</b> der Farbe <b>Farbe</b> auf dem Spielfeld. Die Nutzung dieser Funktion kostet 1 PT.",
	"func_show_arg_1": "Die anzuzeigende Zelle",
	"func_show_arg_2": "Markierungsfarbe",
	"func_show_return": "Gibt true zurück, wenn alles gut gelaufen ist",
	"func_shuffle": "Mischt ein Brett zufällig.",
	"func_shuffle_arg_1": "Das Shuffleboard.",
	"func_signum": "Ermittelt das Vorzeichen der Zahl <b>Zahl</b>.",
	"func_signum_arg_1": "Die Zahl, deren Vorzeichen bestimmt wird.",
	"func_signum_return": "1, wenn die Zahl positiv ist, 0, wenn die Zahl Null ist, und -1, wenn die Zahl negativ ist.",
	"func_sin": "Berechnet den Sinus des als Parameter übergebenen Winkels <b>angle</b>",
	"func_sin_arg_1": "Der Winkel, dessen Sinus berechnet wird",
	"func_sin_return": "Gibt den Sinus des Winkels <b>angle</b> zurück",
	"func_sort": "Sortiert das <b>Array</b> in aufsteigender oder absteigender Reihenfolge. Die standardmäßige aufsteigende Reihenfolge ist:\n<ul>\n<li>Alle booleschen Werte (zuerst falsch, dann wahr)</li>\n<li>Alle Zahlen (geordnet vom kleinsten zum größten)</li>\n<li>Alle Kanäle (in alphabetischer Reihenfolge)</li>\n<li>Alles Null-Elemente.</li>\n</ul>",
	"func_sort_arg_1": "Array zu sortieren.",
	"func_sort_arg_2": "#SORT_ASC um <b>array</b> in aufsteigender Reihenfolge zu sortieren oder #SORT_DESC um es in absteigender Reihenfolge zu sortieren.",
	"func_split": "Teilt den String <b>string</b> in Teilstrings, die durch <b>delimiter</b> getrennt sind.",
	"func_split_arg_1": "Schneidkette.",
	"func_split_arg_2": "Zeichenfolge, die den Übergang von einem Element zum anderen begrenzt.",
	"func_split_arg_3": "Maximale Anzahl von Array-Elementen.",
	"func_split_return": "Array mit den gefundenen Teilstrings.",
	"func_sqrt": "Berechnet die Quadratwurzel der Zahl <b>Zahl</b>.",
	"func_sqrt_arg_1": "Zahl, deren Wurzel berechnet wird.",
	"func_sqrt_return": "Quadratwurzel von <b>Zahl</b>.",
	"func_startsWith": "Überprüft, ob <b>string</b> mit <b>prefix</b> beginnt.",
	"func_startsWith_arg_1": "Suchbegriff.",
	"func_startsWith_arg_2": "Präfix gesucht.",
	"func_startsWith_return": "Wahr, wenn die <b>Zeichenfolge</b> mit <b>Präfix</b> beginnt.",
	"func_string": "Wandelt einen Wert in einen String um.<br>\nWenn der Wert bereits ein String ist, wird er zurückgegeben.<br>\nWenn der Wert die Zahl x ist, wird \"x\" zurückgegeben.<br>\nWenn der Wert ein Array ist, wird ein String der Form \"[Schlüssel1: Wert1, Schlüssel2: Wert2, ...]\" zurückgegeben.<br>\nWenn der Wert ein boolescher Wert ist, werden „true“ und „false“ für „true“ bzw. „false“ zurückgegeben.<br>\nWenn der Wert null ist, wird \"null\" zurückgegeben.",
	"func_string_arg_1": "Der Wert, der in eine Zeichenfolge konvertiert werden soll.",
	"func_string_return": "Die konvertierte Zeichenfolge.",
	"func_subArray": "Gibt ein Subarray von <b>array</b> zurück, das an Position <b>start</b> beginnt und an Position <b>end</b> endet.",
	"func_subArray_arg_1": "Quelltabelle.",
	"func_subArray_arg_2": "Startindex.",
	"func_subArray_arg_3": "Index beenden.",
	"func_subArray_return": "Sub-Array.",
	"func_substring": "Gibt den Teilstring des Strings <b>string</b> ab dem Zeichen am Index <b>start</b> und der Größe <b>length</b> zurück.",
	"func_substring_arg_1": "Schneidkette.",
	"func_substring_arg_2": "Startzeichenindex.",
	"func_substring_arg_3": "Länge des Teilstrings.",
	"func_substring_return": "Teilzeichenfolge.",
	"func_sum": "Gibt die Summe aller numerischen Elemente des Arrays <b>array</b> zurück.",
	"func_sum_arg_1": "Tabelle zur Summe.",
	"func_summon": "Ruft eine vom Chip bestimmte Entität <b>chip</b> auf der Zelle <b>cell</b> auf, die für IA die Funktion <b>ai</b> hat.",
	"func_summon_arg_1": "Der Chip, der zum Beschwören verwendet wird. Der Chip muss ein Beschwörungstyp-Chip sein und muss auf der Entität ausgerüstet sein, die die Beschwörungsfunktion verwendet.",
	"func_summon_arg_2": "Die Zelle oder Vorladung sollte erscheinen.",
	"func_summon_arg_3": "Der Aufruf AI als Funktion.",
	"func_summon_return": "Die Summon-Funktion hat die gleiche Rückgabe wie die #useChip-Funktion.",
	"func_sum_return": "Summe der Array-Elemente.",
	"func_tan": "Berechnet den Tangens des Winkels <b>Winkel</b> (im Bogenmaß).",
	"func_tan_arg_1": "Winkel im Bogenmaß.",
	"func_tan_return": "Tangens von <b>Winkel</b>.",
	"function_category_array": "Listen",
	"function_category_chip": "Flöhe",
	"function_category_color": "Farbe",
	"function_category_fight": "Streit",
	"function_category_leek": "Lauch",
	"function_category_map": "Tische",
	"function_category_field": "Boden",
	"function_category_math": "Zahlen",
	"function_category_network": "Netzwerk",
	"function_category_string": "Ketten",
	"function_category_utils": "Dienstprogramme",
	"function_category_weapon": "Waffen",
	"func_toDegrees": "Konvertiert Winkel <b>Bogenmaß</b> in Grad.",
	"func_toDegrees_arg_1": "Wert in Radiant",
	"func_toDegrees_return": "Winkel in Grad.",
	"func_toLower": "Konvertiert Großbuchstaben in <b>string</b> in Kleinbuchstaben.",
	"func_toLower_arg_1": "Zu transformierende Zeichenfolge",
	"func_toLower_return": "Zeichenfolge in Kleinbuchstaben",
	"func_toRadians": "Konvertiert Winkel <b>Grad</b> in Bogenmaß.",
	"func_toRadians_arg_1": "Wert in Grad",
	"func_toRadians_return": "Winkel im Bogenmaß.",
	"func_toUpper": "Konvertiert Kleinbuchstaben in <b>string</b> in Großbuchstaben.",
	"func_toUpper_arg_1": "Zu transformierende Zeichenfolge.",
	"func_toUpper_return": "Zeichenfolge in Großbuchstaben.",
	"func_typeOf": "Gibt den Typ des Werts <b>Wert</b> zurück, unter den Typen: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "Der Wert, dessen Typ zurückgegeben wird.",
	"func_typeOf_return": "Der Typ des <b>Werts</b>.",
	"func_unshift": "Fügt das Element <b>element</b> am Anfang des <b>Arrays</b> hinzu.",
	"func_unshift_arg_1": "Array, in dem das Element hinzugefügt wird.",
	"func_unshift_arg_2": "Artikel hinzuzufügen.",
	"func_useChip": "Verwenden Sie Chip <b>Chip</b> auf Entität <b>Entität</b>.",
	"func_useChip_arg_1": "Chip zu verwenden.",
	"func_useChip_arg_2": "Zieleinheit.",
	"func_useChipOnCell": "Verwenden Sie Chip <b>Chip</b> auf Zelle <b>Zelle</b>.",
	"func_useChipOnCell_arg_1": "Chip zu verwenden.",
	"func_useChipOnCell_arg_2": "Zielzelle.",
	"func_useChipOnCell_return": "Ein Wert größer als 0, wenn der Angriff gestartet wurde.",
	"func_useChip_return": "Die Rückgabewerte von useChip sind:<br>\n<ul>\n<li>#USE_CRITICAL, bei kritischem Treffer</li>\n<li>#USE_SUCCESS, falls erfolgreich</li>\n<li>#USE_FAILED, bei Fehler</li>\n<li>#USE_INVALID_TARGET, wenn das Ziel nicht existiert</li>\n<li>#USE_NOT_ENOUGH_TP, wenn Ihre Entität nicht genug TP hat</li>\n<li>#USE_INVALID_COOLDOWN, wenn der Chip noch nicht verwendbar ist</li>\n<li>#USE_INVALID_POSITION, wenn die Reichweite schlecht oder die Sichtlinie nicht klar ist</li>\n</ul>",
	"func_useWeapon": "Verwenden Sie die ausgewählte Waffe auf <b>Entität</b>.",
	"func_useWeapon_arg_1": "Zieleinheit.",
	"func_useWeaponOnCell": "Verwenden Sie die ausgewählte Waffe auf der <b>Zelle</b> Zelle.",
	"func_useWeaponOnCell_arg_1": "Zielzelle.",
	"func_useWeaponOnCell_return": "Ein Wert größer als 0, wenn der Angriff gestartet wurde.",
	"func_useWeapon_return": "Die Rückgabewerte von useWeapon sind:<br>\n<ul>\n<li>#USE_CRITICAL, bei kritischem Treffer</li>\n<li>#USE_SUCCESS, falls erfolgreich</li>\n<li>#USE_FAILED, bei Fehler</li>\n<li>#USE_INVALID_TARGET, wenn das Ziel nicht existiert</li>\n<li>#USE_NOT_ENOUGH_TP, wenn Ihre Entität nicht genug TP hat</li>\n<li>#USE_INVALID_POSITION, wenn die Reichweite schlecht oder die Sichtlinie nicht klar ist</li>\n</ul>",
	"func_weaponNeedLos": "Gibt zurück, ob die <b>Waffe</b> zum Abfeuern eine Sichtverbindung benötigt.",
	"func_weaponNeedLos_arg_1": "Die ID der zu testenden Waffe.",
	"func_weaponNeedLos_return": "<i>true</i>, wenn die <b>Waffe</b> zum Abfeuern eine Sichtlinie benötigt, andernfalls <i>false</i>.",
	"items": "Artikel",
	"max_cores": "max",
	"max_level": "max",
	"min_cores": "Minimale Herzen",
	"min_level": "Mindeststufe",
	"n_operations": "<b>{0}</b> Vorgänge",
	"parameters": "Einstellungen",
	"return": "Rückmeldung",
	"value": "Wert",
	"variable_operations": "<b>Variable</b> Operationen",
	"deprecated_function": "Diese Funktion ist veraltet.",
	"deprecated_constant": "Diese Konstante ist veraltet.",
	"replaced_by": "Es wird durch {0} ersetzt.",
	"details": "Einzelheiten",
	"optional": "optional",
	"arg_type_9": "zusammen",
	"arg_type_10": "intervall",
	"function_category_set": "Sets",
	"function_category_interval": "Intervalle",
	"arg_type_96": "zusammen<ganz>"
}

	i18n.mergeLocaleMessage("de", { "doc": messages })
	