
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> opération",
	"arg_type_-1": "?",
	"arg_type_1": "nombre",
	"arg_type_2": "chaîne",
	"arg_type_3": "booléen",
	"arg_type_4": "liste",
	"arg_type_41": "liste<nombre>",
	"arg_type_42": "liste<chaîne>",
	"arg_type_43": "liste<booléen>",
	"arg_type_44": "liste<liste>",
	"arg_type_46": "liste<entier>",
	"arg_type_47": "liste<réel>",
	"arg_type_5": "fonction",
	"arg_type_6": "entier",
	"arg_type_7": "réel",
	"arg_type_8": "table",
	"arg_type_9": "ensemble",
	"arg_type_96": "ensemble<entier>",
	"arg_type_10": "intervalle",
	"arg_type_806": "table<?, entier>",
	"complexity": "Complexité {c}",
	"operations": "{o} opérations",
	"details": "Détails",
	"optional": "optionnel",
	"deprecated_function": "Cette fonction est dépréciée.",
	"deprecated_constant": "Cette constante est dépréciée.",
	"replaced_by": "Elle est remplacée par {0}.",
	"const_AREA_CIRCLE_1": "Zone circulaire de 3 cases de diamètre (croix).",
	"const_AREA_CIRCLE_2": "Zone circulaire de 5 cases de diamètre.",
	"const_AREA_CIRCLE_3": "Zone circulaire de 7 cases de diamètre.",
	"const_AREA_LASER_LINE": "Zone d'une laser, ligne depuis la portée minimum du laser jusqu’à sa portée maximum ou bien un obstacle.",
	"const_AREA_POINT": "Zone constituée d'une seule case.",
	"const_CELL_EMPTY": "Valeur de retour de getCellContent(cell) pour une case vide.",
	"const_CELL_OBSTACLE": "Valeur de retour de getCellContent(cell) pour une case contenant un obstacle.",
	"const_CELL_PLAYER": "Valeur de retour de getCellContent(cell) pour une case contenant une entité.",
	"const_CELL_ENTITY": "Valeur de retour de getCellContent(cell) pour une case contenant une entité.",
	"const_COLOR_BLUE": "Couleur bleue.",
	"const_COLOR_GREEN": "Couleur verte.",
	"const_COLOR_RED": "Couleur rouge.",
	"const_E": "Nombre d'Euler.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Procure du bouclier absolu à une entité, permettant de réduire la quantité de points de vie retirée par les dégâts (EFFECT_DAMAGE) d'un montant fixe. Amplifié par la résistance.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Retire du bouclier absolu à une entité. N'est pas amplifié par une caractéristique. Permet d'augmenter les points de vie retiré par les dégâts (EFFECT_DAMAGE) d'un montant absolu.",
	"const_EFFECT_AFTEREFFECT": "Retire des points de vie à une entité. Amplifié par la science. Réduit le maximum de points de vie de 5% du montant de points de vie retiré.",
	"const_EFFECT_ANTIDOTE": "Retire tous les poison (EFFECT_POISON) présent sur une cible.",
	"const_EFFECT_BOOST_MAX_LIFE": "Augmente les points de vie et le maximum de points de vie d'une entité. Amplifié par la sagesse.",
	"const_EFFECT_BUFF_AGILITY": "Procure de l'agilité à une entité. Amplifié par la science.",
	"const_EFFECT_BUFF_MP": "Procure des points de mouvement à une entité. Amplifié par la science.",
	"const_EFFECT_BUFF_RESISTANCE": "Procure de la résistance à une entité. Amplifié par la science.",
	"const_EFFECT_BUFF_STRENGTH": "Procure de la force à une entité. Amplifié par la science.",
	"const_EFFECT_BUFF_TP": "Procure des points d'action à une entité. Amplifié par la science.",
	"const_EFFECT_BUFF_WISDOM": "Procure de la sagesse à une entité. Amplifié par la science.",
	"const_EFFECT_DAMAGE": "Retire des points de vie à une entité. Amplifié par la force. Interagit avec les boucliers (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), le vol de vie (à l'exception du lanceur), et le retour de dégâts (EFFECT_DAMAGE_RETURN). Réduit le maximum de points de vie de 5% du montant de points de vie retiré.",
	"const_EFFECT_DAMAGE_RETURN": "Procure du renvoi de dégâts à une entité, permettant de retirer des points de vie aux entités infligeant des dégâts au bénéficiaire. Amplifié par l'agilité. Réduit le maximum de points de vie de 5% du montant de points de vie retiré.",
	"const_EFFECT_DEBUFF": "Réduit la valeur de tous les effets présents sur une entité d'un pourcentage.",
	"const_EFFECT_HEAL": "Rend des points de vie à une entité, limité par le maximum de points de vie. Amplifié par la sagesse.",
	"const_EFFECT_INVERT": "Échange la position du lanceur avec celle d'une entité.",
	"const_EFFECT_KILL": "Retire tous les points de vie d'une entité.",
	"const_EFFECT_LIFE_DAMAGE": "Retire des points de vie à une entité, dépendant d'un pourcentage de la vie du lanceur. Interagit avec les boucliers (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) et le retour de dégâts (EFFECT_DAMAGE_RETURN). Réduit le maximum de points de vie de 5% du montant de points de vie retiré.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "L'effet est multiplié par le nombre d'entités affectées dans la zone.",
	"const_EFFECT_MODIFIER_ON_CASTER": "L'effet affecte toujours le lanceur.",
	"const_EFFECT_MODIFIER_STACKABLE": "L'effet est cumulable.",
	"const_EFFECT_NOVA_DAMAGE": "Retire des points de vie max. Amplifié par la science.",
	"const_EFFECT_POISON": "Retire des points de vie à une entité. Amplifié par la magie. Réduit le maximum de points de vie de 10% du montant de points de vie retiré.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Procure du bouclier absolu à une entité, permettant de réduire la quantité de points de vie retirée par les dégâts (EFFECT_DAMAGE) d'un montant fixe. Non amplifiable.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Procure de l'agilité à une entité. Non amplifiable.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Procure de la magie à une entité. Non amplifiable.",
	"const_EFFECT_RAW_BUFF_MP": "Procure des points de mouvement à une entité. Non amplifiable.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Procure de la science à une entité. Non amplifiable.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Procure de la force à une entité. Non amplifiable.",
	"const_EFFECT_RAW_BUFF_TP": "Procure des points d'action à une entité. Non amplifiable.",
	"const_EFFECT_RELATIVE_SHIELD": "Procure un bouclier relatif, permettant de réduire la quantité de points de vie retiré par les dégâts (EFFECT_DAMAGE) d'un montant relatif. Amplifié par la résistance.",
	"const_EFFECT_RESURRECT": "Ressuscite une entité, avec un nombre de PV maximum égal à la moitié du nombre de PV maximum de l'entité avant résurrection, et un nombre de PV courant égal au quart du nombre de PV maximum avant résurrection.",
	"const_EFFECT_SHACKLE_MAGIC": "Retire de la magie à une entité. Amplifié par la magie.",
	"const_EFFECT_SHACKLE_MP": "Retire des points de mouvement à une entité. Amplifié par la magie.",
	"const_EFFECT_SHACKLE_STRENGTH": "Retire de la force à une entité. Amplifié par la magie.",
	"const_EFFECT_SHACKLE_TP": "Retire des points d'action à une entité. Amplifié par la magie.",
	"const_EFFECT_SUMMON": "Invoque un bulbe. Aucun effet si la limite d'invocation de l'équipe est atteinte.",
	"const_EFFECT_TARGET_ALLIES": "Affecte les alliés.",
	"const_EFFECT_TARGET_CASTER": "Affecte le lanceur.",
	"const_EFFECT_TARGET_ENEMIES": "Affecte les ennemis.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Affecte les entités non-invoquées (Poireaux et tourelles).",
	"const_EFFECT_TARGET_SUMMONS": "Affecte les entités invoquées (Bulbes).",
	"const_EFFECT_TELEPORT": "Change la position du lanceur.",
	"const_EFFECT_VULNERABILITY": "Retire du bouclier relatif à une entité. N'est pas amplifié par une caractéristique. Permet d'augmenter les points de vie retiré par les dégâts (EFFECT_DAMAGE) d'un montant relatif.",
	"const_ENTITY_BULB": "Désigne une entité de type Bulbe.",
	"const_ENTITY_LEEK": "Désigne une entité de type Poireau.",
	"const_ENTITY_TURRET": "Désigne une entité de type Tourelle.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Contexte de combat de type défi.",
	"const_FIGHT_CONTEXT_GARDEN": "Contexte de combat dans le potager.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Contexte de combat en Battle Royale.",
	"const_FIGHT_CONTEXT_TEST": "Contexte de combat de test.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Contexte de combat de tournois.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Combat en Battle Royale.",
	"const_FIGHT_TYPE_FARMER": "Combat d'éleveur.",
	"const_FIGHT_TYPE_SOLO": "Combat en solo.",
	"const_FIGHT_TYPE_TEAM": "Combat en équipe.\n",
	"const_INSTRUCTIONS_LIMIT": "Nombre d'instructions maximales qu'une entité peut utiliser pendant son tour.",
	"const_MAX_TURNS": "Nombre de tours maximum dans un combat.",
	"const_OPERATIONS_LIMIT": "Nombre d'opérations maximales qu'une entité peut utiliser pendant son tour.",
	"const_PI": "Le rapport de la circonférence d’un cercle à son diamètre.",
	"const_SORT_ASC": "Indique à la fonction #sort un tri dans l'ordre croissant.",
	"const_SORT_DESC": "Indique à la fonction #sort un tri dans l'ordre décroissant.",
	"const_TYPE_ARRAY": "Type de valeur <b>liste</b>",
	"const_TYPE_BOOLEAN": "Type de valeur <b>booléen</b>",
	"const_TYPE_FUNCTION": "Type de valeur <b>fonction</b>",
	"const_TYPE_NULL": "Type de valeur <b>null</b>",
	"const_TYPE_NUMBER": "Type de valeur <b>nombre</b>",
	"const_TYPE_STRING": "Type de valeur <b>chaîne de caractères</b>",
	"const_USE_CRITICAL": "Valeur renvoyée par les fonctions #useWeapon, #useWeaponOnCell, #useChip et #useChipOnCell en cas de coup critique.",
	"const_USE_FAILED": "Valeur renvoyée par les fonctions #useWeapon, #useWeaponOnCell, #useChip et #useChipOnCell en cas de d'échec.",
	"const_USE_INVALID_COOLDOWN": "Valeur renvoyée par les fonctions #useChip et #useChipOnCell si la puce n'est pas encore utilisable.",
	"const_USE_INVALID_POSITION": "Valeur renvoyée par les fonctions #useWeapon, #useWeaponOnCell, #useChip et #useChipOnCell si la portée est mauvaise ou la ligne de vue n'est pas dégagée.",
	"const_USE_INVALID_TARGET": "Valeur renvoyée par les fonctions #useWeapon et #useChip si la cible n'existe pas.",
	"const_USE_NOT_ENOUGH_TP": "Valeur renvoyée par les fonctions #useWeapon, #useWeaponOnCell, #useChip et #useChipOnCell si le lanceur n'a pas assez de points d'action pour utiliser l'objet.",
	"const_USE_RESURRECT_INVALID_ENTITY": "Valeur renvoyée par la fonction #resurrect lorsque l'entité spécifiée n'existe pas ou n'est pas encore morte.",
	"const_USE_SUCCESS": "Valeur renvoyée par les fonctions #useWeapon, #useWeaponOnCell, #useChip et #useChipOnCell en cas de réussite.",
	"const_USE_TOO_MANY_SUMMONS": "Erreur renvoyée par #summon lorsque vous avez déjà <b>8</b> invocations vivantes.\n",
	"func_abs": "Renvoie la valeur absolue du nombre <b>number</b>.",
	"func_abs_arg_1": "Le nombre dont la valeur absolue sera calculée.",
	"func_abs_return": "La valeur absolue du nombre.",
	"func_acos": "Calcule l'arc cosinus d'<b>argument</b>, dans l'intervalle [0, #PI].",
	"func_acos_arg_1": "Nombre dont l'arc cosinus sera calculé.",
	"func_acos_return": "L'arc cosinus d'<b>argument</b>.",
	"func_arrayConcat": "Ajoute les deux tableaux bout à bout. Les clés textuelles sont conservées et toutes les clés numériques sont réindexées.",
	"func_arrayConcat_arg_1": "Premier tableau.",
	"func_arrayConcat_arg_2": "Second tableau.",
	"func_arrayConcat_return": "Somme des deux tableaux.",
	"func_arrayFilter": "Retourne un nouveau tableau contenant tous les couples clé/valeur du tableau source pour lesquels la fonction callback a renvoyé true. Si la fonction callback prend un paramètre, c'est la valeur du tableau source qui sera envoyée, si elle prend deux paramètres c'est la clé et la valeur qui seront envoyées.",
	"func_arrayFilter_arg_1": "Tableau d'origine.",
	"func_arrayFilter_arg_2": "Fonction appelée pour chaque élément.",
	"func_arrayFilter_return": "Nouveau tableau.",
	"func_arrayFlatten": "Retourne un nouveau tableau contenant tous les éléments du tableau source. Tous les éléments contenus dans un sous tableau sont extraits dans le nouveau tableau.L'argument depth détermine la profondeur maximale de sous tableau à extraire.\n<code>var tableau = [1, 2, [[3, 4], 5], [6]];\ndebug(arrayFlatten(tableau, 1)); // [1,2,[3,4],5,6]</code>",
	"func_arrayFlatten_arg_1": "Tableau d'origine.",
	"func_arrayFlatten_arg_2": "Profondeur maximale.",
	"func_arrayFlatten_return": "Nouveau tableau.",
	"func_arrayFoldLeft": "Réduit le tableau <b>array</b> [v1, v2, ..., vn] par la gauche en partant de la valeur <b>v0</b> et en appliquant la fonction <b>f</b>. Équivaut à :\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "Tableau d'origine.",
	"func_arrayFoldLeft_arg_2": "Fonction à appliquer.",
	"func_arrayFoldLeft_arg_3": "Valeur de départ.",
	"func_arrayFoldLeft_return": "Tableau réduit.",
	"func_arrayFoldRight": "Réduit le tableau <b>array</b> [v1, v2, ..., vn] par la droite en partant de la valeur <b>v0</b> et en appliquant la fonction <b>f</b>. Équivaut à :\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "Tableau d'origine.",
	"func_arrayFoldRight_arg_2": "Fonction à appliquer.",
	"func_arrayFoldRight_arg_3": "Valeur de départ.",
	"func_arrayFoldRight_return": "Tableau réduit.",
	"func_arrayIter": "Appelle la fonction callback pour chaque élément du tableau. Si la fonction callback prend un paramètre, c'est la valeur du tableau source qui sera envoyée, si elle prend deux paramètres c'est la clé et la valeur qui seront envoyées.",
	"func_arrayIter_arg_1": "Tableau d'origine.",
	"func_arrayIter_arg_2": "Fonction appelée pour chaque élément.",
	"func_arrayMap": "Retourne un nouveau tableau contenant pour chaque clé du tableau source, la valeur retournée par la fonction callback. Si la fonction callback prend un paramètre, c'est la valeur du tableau source qui sera envoyée, si elle prend deux paramètres c'est la clé et la valeur qui seront envoyées.",
	"func_arrayMap_arg_1": "Tableau d'origine.",
	"func_arrayMap_arg_2": "Fonction appelée pour chaque élément.",
	"func_arrayMap_return": "Nouveau tableau.",
	"func_arrayMax": "Retourne l'élément de valeur maximale du tableau <b>array</b>.<br/>Pour plus d'informations sur la valeur maximale d'un tableau voir #sort.",
	"func_arrayMax_arg_1": "Tableau dans lequel sera recherchée la valeur maximale.",
	"func_arrayMax_return": "Elément de valeur maximale.",
	"func_arrayMin": "Retourne l'élément de valeur minimale du tableau <b>array</b>.<br/>Pour plus d'informations sur la valeur minimale d'un tableau voir #sort.",
	"func_arrayMin_arg_1": "Tableau dans lequel sera recherchée la valeur minimale.",
	"func_arrayMin_return": "Elément de valeur minimale.",
	"func_arrayPartition": "Retourne un nouveau tableau contenant deux listes, dans la première se trouvent tous les couples clés/valeurs pour lesquels la fonction callback a renvoyé true, dans la seconde se trouvent tous les autres. Si la fonction callback prend un paramètre, c'est la valeur du tableau source qui sera envoyée, si elle prend deux paramètres c'est la clé et la valeur qui seront envoyées.",
	"func_arrayPartition_arg_1": "Tableau d'origine.",
	"func_arrayPartition_arg_2": "Fonction appelée pour chaque élément.",
	"func_arrayPartition_return": "Nouveau tableau.",
	"func_arraySort": "Trie le tableau selon l'ordre définit par la fonction callback. Les éléments sont comparés deux à deux, la fonction callback doit renvoyer les valeurs -1, 0 ou 1 selon si la premiere valeur est avant, au même niveau ou après la seconde valeur. Si la fonction callback prend 2 paramètre, ce sont les deux valeurs qui sont envoyées, si elle en prend 4, ce sont les couples clé/valeur qui sont envoyés.",
	"func_arraySort_arg_1": "Tableau d'origine",
	"func_arraySort_arg_2": "Fonction de tri.",
	"func_arraySort_return": "Le tableau trié",
	"func_asin": "Calcule l'arc sinus d'<b>argument</b>, dans l'intervalle [0, #PI].",
	"func_asin_arg_1": "Nombre dont l'arc sinus sera calculé.",
	"func_asin_return": "L'arc sinus d'<b>argument</b>.",
	"func_assocSort": "Trie le tableau <b>array</b> en conservant l'association clé : valeur selon l'ordre <b>order</b>.",
	"func_assocSort_arg_1": "Le tableau à trier.",
	"func_assocSort_arg_2": "L'ordre de tri : #SORT_ASC ou #SORT_DESC.",
	"func_atan": "Calcule l'arc tangente d'<b>argument</b>, dans l'intervalle [0, #PI].",
	"func_atan2": "Convertit les coordonnées cartésiennes (<b>x</b>, <b>y</b>) en coordonnées polaires (<b>r</b>, <b>theta</b>). Cette fonction retourne l'angle <b>theta</b> entre -#PI et #PI en utilisant les signes des arguments.",
	"func_atan2_arg_1": "Coordonnée en y.",
	"func_atan2_arg_2": "Coordonnée en x.",
	"func_atan2_return": "L'angle <b>theta</b> en coordonnées polaires du point (x, y).",
	"func_atan_arg_1": "Nombre dont l'arc tangente sera calculée.",
	"func_atan_return": "L'arc tangente d'<b>argument</b>.",
	"func_average": "Calcule la moyenne des éléments contenus dans le tableau <b>array</b>.",
	"func_average_arg_1": "Tableau dont on veut calculer la moyenne.",
	"func_average_return": "Valeur moyenne.",
	"func_canUseChip": "Détermine si votre entité peut utiliser la puce <b>chip</b> sur l'entité d'id <b>entity</b>.",
	"func_canUseChip_arg_1": "Le numéro de la puce à tester.",
	"func_canUseChip_arg_2": "L'id de l'entité sur lequel vous voulez utiliser la puce.",
	"func_canUseChip_return": "<i>true</i> si votre entité peut utiliser la puce, <i>false</i> sinon.",
	"func_canUseChipOnCell": "Détermine si votre entité peut utiliser la puce <b>chip</b> sur la cellule <b>cell</b>.",
	"func_canUseChipOnCell_arg_1": "Le numéro de la puce à tester.",
	"func_canUseChipOnCell_arg_2": "Le numéro de la cellule sur laquelle vous voulez utiliser la puce.",
	"func_canUseChipOnCell_return": "<i>true</i> si votre entité peut utiliser la puce, <i>false</i> sinon.",
	"func_canUseWeapon": "Détermine si votre entité peut tirer sur l'entité d'id <b>entity</b> avec l'arme <b>weapon</b>.",
	"func_canUseWeapon_arg_1": "L'arme à tester. Par défaut votre arme actuellement équipée.",
	"func_canUseWeapon_arg_2": "L'id de l'entité sur lequel vous voulez tirer.",
	"func_canUseWeapon_return": "<i>true</i> si votre entité peut tirer, <i>false</i> sinon.",
	"func_canUseWeaponOnCell": "Détermine si votre entité peut tirer sur la cellule <b>cell</b> avec l'arme <b>weapon</b>.",
	"func_canUseWeaponOnCell_arg_1": "L'arme à tester. Par défaut votre arme actuellement équipée.",
	"func_canUseWeaponOnCell_arg_2": "Le numéro de la cellule sur laquelle vous voulez tirer.",
	"func_canUseWeaponOnCell_return": "<i>true</i> si votre entité peut tirer, <i>false</i> sinon.",
	"func_cbrt": "Calcule la racine cubique de <b>number</b>",
	"func_cbrt_arg_1": "Nombre dont on veut calculer la racine cubique.",
	"func_cbrt_return": "Racine cubique de <b>number</b>.",
	"func_ceil": "Retourne l'arrondi à l'entier supérieur de <b>number</b>.<br/>Vous pouvez trouver l'arrondi à l'entier inférieur avec #floor, et l'arrondi avec #round.",
	"func_ceil_arg_1": "Nombre dont on veut trouver l'arrondi supérieur.",
	"func_ceil_return": "Arrondi à l'entier supérieur de <b>number</b>.",
	"func_charAt": "Retourne le caractère de la chaîne <b>string</b> situé à la position <b>position</b>.<br/>Le premier caractère de la chaîne se trouve à la position 0.",
	"func_charAt_arg_1": "Chaîne dont on veut récupérer un caractère.",
	"func_charAt_arg_2": "Position du caractère à trouver.",
	"func_charAt_return": "chaîne contenant le caractère recherché, ou une chaîne vide si la position est invalide.",
	"func_chipNeedLos": "Renvoie si la puce <b>chip</b> a besoin d'une ligne de vue pour être utilisée.",
	"func_chipNeedLos_arg_1": "L'id de la puce à tester.",
	"func_chipNeedLos_return": "<i>true</i> si la puce <b>chip</b> a besoin d'une ligne de vue pour être utilisée, <i>false</i> sinon.",
	"func_clearMarks": "Efface tous les marquages effectués par mark() et markText() sur le terrain.",
	"func_clone": "Copie la valeur <b>value</b> passée en paramètre sur <b>level</b> niveaux et retourne la copie. Dans le cas d'un tableau par exemple, un clone(tableau, 1) copiera le tableau mais pas ses éléments, un clone(tableau, 2) copiera le tableau ainsi que tous les éléments.",
	"func_clone_arg_1": "La valeur à cloner",
	"func_clone_arg_2": "Le nombre de niveaux à cloner",
	"func_clone_return": "La valeur clonée",
	"func_contains": "Détermine si une chaîne <b>search</b> se trouve dans une chaîne <b>string</b>.",
	"func_contains_arg_1": "Chaîne dans laquelle la recherche sera effectuée.",
	"func_contains_arg_2": "Chaîne à rechercher.",
	"func_contains_return": "true si <b>search</b> est dans <b>string</b>, false sinon.",
	"func_cos": "Calcule le cosinus de <b>angle</b>.",
	"func_cos_arg_1": "Nombre dont le cosinus sera calculé (en radians).",
	"func_cos_return": "Le cosinus de <b>angle</b>, dans l'intervalle [-1, 1].",
	"func_count": "Compte le nombre d'éléments du tableau <b>array</b>.",
	"func_count_arg_1": "Tableau dont le nombre d'éléments sera compté.",
	"func_count_return": "Nombre d'éléments du tableau.",
	"func_debug": "Enregistre un message <b>object</b> dans le log personnel, disponible dans le rapport à la fin du combat.",
	"func_debug_arg_1": "Le message à enregistrer.",
	"func_debugC": "Enregistre un message <b>object</b> dans le log personnel, disponible dans le rapport à la fin du combat, de la couleur <b>color</b>.",
	"func_debugC_arg_1": "Le message à enregistrer.",
	"func_debugC_arg_2": "La couleur du message. Vous pouvez utiliser la fonction #getColor.",
	"func_debugE": "Enregistre un message d'erreur <b>object</b> dans le log personnel, disponible dans le rapport à la fin du combat.<br />Les messages d'erreur apparaissent en rouge dans le rapport de combat.",
	"func_debugE_arg_1": "Le message d'erreur à enregistrer.",
	"func_debugW": "Enregistre un message d'avertissement <b>object</b> dans le log personnel, disponible dans le rapport à la fin du combat. <br />Les messages d'avertissement apparaissent en orange dans le rapport de combat.",
	"func_debugW_arg_1": "Le message d'avertissement à enregistrer.",
	"func_deleteRegister": "Supprime le registre associé à la clé <b>key</b> s'il existe.",
	"func_deleteRegister_arg_1": "La clé du registre à supprimer.",
	"func_endsWith": "Détermine si une chaîne <b>string</b> se termine par une chaîne <b>suffix</b>.",
	"func_endsWith_arg_1": "Chaîne dans laquelle la recherche sera effectuée",
	"func_endsWith_arg_2": "Suffixe à rechercher",
	"func_endsWith_return": "true si <b>string</b> se termine par <b>suffix</b>",
	"func_exp": "Éleve le nombre d'Euler #E à la puissance <b>number</b>.",
	"func_exp_arg_1": "L'exposant auquel #E sera élevé.",
	"func_exp_return": "#E ** <b>number</b>.",
	"func_fill": "Redimensionne le tableau <b>array</b> à la taille <b>size</b> et remplit toutes ses cases avec <b>value</b>.",
	"func_fill_arg_1": "Le tableau à remplir.",
	"func_fill_arg_2": "La valeur pour chaque case du tableau.",
	"func_fill_arg_3": "Le nombre de cases. Par défaut, la taille courante du tableau.",
	"func_floor": "Calcule l'arrondi à l'entier inférieur de <b>number</b>.<br/>Vous pouvez trouver l'arrondi à l'entier supérieur avec #ceil, et l'arrondi avec #round.",
	"func_floor_arg_1": "Nombre dont on veut trouver l'arrondi inférieur.",
	"func_floor_return": "Arrondi à l'entier inférieur de <b>number</b>.",
	"func_getAbsoluteShield": "Retourne le bouclier absolu de l'entité d'id <b>entity</b>. Pour récupérer directement le bouclier absolu de votre entité, utilisez #getAbsoluteShield() sans paramètre.",
	"func_getAbsoluteShield_arg_1": "L'id de l'entité dont le bouclier absolu sera retourné.",
	"func_getAbsoluteShield_return": "Le bouclier absolu de l'entité <b>entity</b>.",
	"func_getAgility": "Retourne l'agilité de l'entité d'id <b>entity</b>. Pour récupérer directement l'agilité de votre entité, utilisez #getAgility() sans paramètre.",
	"func_getAgility_arg_1": "L'id de l'entité dont l'agilité sera retournée.",
	"func_getAgility_return": "L'agilité de l'entité <b>entity</b>.",
	"func_getAIID": "Renvoie l'id de l'IA de l'entité <b>entity</b>. Utilisez getAIID sans paramètre pour récupérer l'id de votre IA.",
	"func_getAIID_arg_1": "L'id de l'entité dont l'id d'IA sera renvoyé.",
	"func_getAIID_return": "L'id de l'IA de l'entité <b>entity</b>.",
	"func_getAIName": "Renvoie le nom de l'IA de l'entité <b>entity</b>. Utilisez getAIName() sans paramètre pour récupérer le nom de votre IA.",
	"func_getAIName_arg_1": "L'id de l'entité dont le nom d'IA sera renvoyé.",
	"func_getAIName_return": "Le nom de l'IA de l'entité <b>entity</b>.",
	"func_getAliveAllies": "Retourne un tableau de tous vos alliés vivants dans le combat.",
	"func_getAliveAllies_return": "Un tableau contenant les ids de tous vos alliés vivants.",
	"func_getAliveEnemies": "Retourne un tableau de tous vos ennemis vivants dans le combat.",
	"func_getAliveEnemiesCount": "Renvoie le nombre d'ennemis vivants dans le combat.",
	"func_getAliveEnemiesCount_return": "Le nombre d'ennemis vivants.",
	"func_getAliveEnemies_return": "Un tableau contenant les ids de tous vos ennemis vivants.",
	"func_getAllChips": "Retourne la liste de toutes les puces du jeu.",
	"func_getAllChips_return": "La liste de toutes les puces du jeu.",
	"func_getAllEffects": "Retourne la liste de tous les effets du jeu.",
	"func_getAllEffects_return": "La liste de tous les effets du jeu.",
	"func_getAllWeapons": "Retourne la liste de toutes les armes du jeu.",
	"func_getAllWeapons_return": "La liste de toutes les armes du jeu.",
	"func_getAlliedTurret": "Retourne l'id de la tourelle de votre équipe ou -1 si elle n'existe pas.",
	"func_getAlliedTurret_return": "L'id de la tourelle de votre équipe.",
	"func_getEnemyTurret": "Retourne l'id de la tourelle ennemie ou -1 si elle n'existe pas.",
	"func_getEnemyTurret_return": "L'id de la tourelle ennemie.",
	"func_getAllies": "Retourne un tableau contenant vos alliés, et votre entité.",
	"func_getAlliesCount": "Renvoie le nombre d'alliés dans le combat.",
	"func_getAlliesCount_return": "Le nombre d'alliés.",
	"func_getAlliesLife": "Retourne la vie totale de vos alliés.",
	"func_getAlliesLife_return": "La vie totale de vos alliés.",
	"func_getAllies_return": "Le tableau des alliés et votre entité.",
	"func_getBirthTurn": "Renvoie le tour du combat où est apparue l'entité <b>entity</b>. Renvoie 1 s'il s'agit d'un poireau par exemple, et 5 s'il d'agit d'une invocation invoquée au tour 5.",
	"func_getBirthTurn_arg_1": "L'id de l'entité dont le tour d'apparition sera renvoyé.",
	"func_getBirthTurn_return": "Le tour de combat où <b>entity</b> est apparue.",
	"func_getBlue": "Renvoie le taux de bleu dans la couleur <b>color</b>, entre 0 et 255. Par exemple, getBlue(#COLOR_BLUE) = 255 et getBlue(#COLOR_GREEN) = 0.",
	"func_getBlue_arg_1": "La couleur dont le taux de bleu sera renvoyé.",
	"func_getBlue_return": "Le taux de bleu dans la couleur <b>color</b>",
	"func_getBulbChips": "Renvoie la liste des puces qui seront équipées sur un bulbe invoqué par la puce <b>bulb_chip</b>.",
	"func_getBulbChips_arg_1": "La puce de bulbe.",
	"func_getBulbChips_return": "Les puces d'un bulbe invoqué par la puce <b>bulb_chip</b>.",
	"func_getCell": "Retourne la cellule où se trouve l'entité d'id <b>entity</b>. Utilisez getCell() sans paramètre pour récupérer votre cellule.",
	"func_getCell_arg_1": "L'id de l'entité dont la cellule sera retournée.",
	"func_getCell_return": "Le numéro de la cellule où se trouve l'entité <b>entity</b>.",
	"func_getCellContent": "Retourne le contenu d'une cellule d'id <b>cell</b>.",
	"func_getCellContent_arg_1": "L'id de la cellule dont le contenu sera retourné.",
	"func_getCellContent_return": "Le contenu de la cellule <b>cell</b> : #CELL_EMPTY pour une cellule vide, #CELL_ENTITY pour une entité, #CELL_OBSTACLE pour un obstacle.",
	"func_getCellDistance": "Retourne la distance entre deux cellules <b>cell1</b> et <b>cell2</b>. <br />La distance retournée est exprimée en nombre de cellules, et ne tient pas compte des divers obstacles entre les deux cellules.\nPour obtenir la distance à vol d'oiseau, voir #getDistance et pour obtenir la distance du chemin entre les deux cellules en évitant les obstacles, voir #getPathLength.",
	"func_getCellDistance_arg_1": "L'id de la cellule de départ.",
	"func_getCellDistance_arg_2": "L'id de la cellule d'arrivée.",
	"func_getCellDistance_return": "La distance entre les deux cellules <b>cell1</b> et <b>cell2</b>.",
	"func_getCellFromXY": "Retourne l'id de la cellule se trouvant à la position (<b>x</b>, <b>y</b>).",
	"func_getCellFromXY_arg_1": "La position en x de la cellule.",
	"func_getCellFromXY_arg_2": "La position en y de la cellule.",
	"func_getCellFromXY_return": "L'id de la cellule à la position (<b>x</b>, <b>y</b>), <b>null</b> si la cellule n'existe pas.",
	"func_getCellsToUseChip": "Retourne la liste des cellules à partir desquelles votre entité pourra utiliser la puce <b>chip</b> sur l'entité <b>entity</b>.",
	"func_getCellsToUseChip_arg_1": "La puce que l'entité veut pouvoir utiliser.",
	"func_getCellsToUseChip_arg_2": "L'entité cible.",
	"func_getCellsToUseChip_arg_3": "Tableau de cellules à ignorer.",
	"func_getCellsToUseChip_return": "Liste des cellules d'où la puce pourra être utilisée.",
	"func_getCellsToUseChipOnCell": "Retourne la liste des cellules à partir desquelles votre entité pourra utiliser la puce <b>chip</b> sur la cellule <b>cell</b>.",
	"func_getCellsToUseChipOnCell_arg_1": "La puce que l'entité veut pouvoir utiliser.",
	"func_getCellsToUseChipOnCell_arg_2": "La cellule cible.",
	"func_getCellsToUseChipOnCell_arg_3": "Tableau de cellules à ignorer.",
	"func_getCellsToUseChipOnCell_return": "Liste des cellules d'où la puce pourra être utilisée.",
	"func_getCellsToUseWeapon": "Retourne la liste des cellules à partir desquelles votre entité pourra utiliser l'arme <b>weapon</b> sur l'entité <b>entity</b>.",
	"func_getCellsToUseWeapon_arg_1": "L'arme à tester. Par défaut votre arme actuellement équipée.",
	"func_getCellsToUseWeapon_arg_2": "L'entité cible.",
	"func_getCellsToUseWeapon_arg_3": "Tableau de cellules à ignorer. Par défaut un tableau vide.",
	"func_getCellsToUseWeapon_return": "Liste des cellules d'où l'arme pourra être utilisée.",
	"func_getCellsToUseWeaponOnCell": "Retourne la liste des cellules à partir desquelles votre entité pourra utiliser l'arme <b>weapon</b> sur une cellule <b>cell</b>.",
	"func_getCellsToUseWeaponOnCell_arg_1": "L'arme à tester. Par défaut votre arme actuellement équipée.",
	"func_getCellsToUseWeaponOnCell_arg_2": "La cellule cible.",
	"func_getCellsToUseWeaponOnCell_arg_3": "Tableau de cellules à ignorer. Par défaut un tableau vide.",
	"func_getCellsToUseWeaponOnCell_return": "Liste des cellules d'où l'arme pourra être utilisée.",
	"func_getCellX": "Détermine la position en X de la cellule <b>cell</b>.",
	"func_getCellX_arg_1": "La cellule dont la position en X sera déterminée.",
	"func_getCellX_return": "La position en X de la cellule.",
	"func_getCellY": "Détermine la position en Y de la cellule <b>cell</b>.",
	"func_getCellY_arg_1": "La cellule dont la position en Y sera déterminée.",
	"func_getCellY_return": "La position en Y de la cellule.",
	"func_getChipArea": "Renvoie le type de zone d'effet de de la puce <b>chip</b>.",
	"func_getChipArea_arg_1": "La puce dont le type de zone sera renvoyé.",
	"func_getChipArea_return": "Le type de zone de la puce <b>chip</b> parmi les constantes AREA_* :\n<ul>\n<li>#AREA_POINT : zone d'une seule case</li>\n<li>#AREA_LASER_LINE : ligne d'un laser</li>\n<li>#AREA_CIRCLE_1 : zone circulaire de 3 cases de diamètre</li>\n<li>#AREA_CIRCLE_2 : zone circulaire de 5 cases de diamètre</li>\n<li>#AREA_CIRCLE_3 : zone circulaire de 7 cases de diamètre</li>\n</ul>",
	"func_getChipCooldown": "Renvoie le temps de récupération de la puce <b>chip</b>, issu du marché.",
	"func_getChipCooldown_arg_1": "La puce dont le cooldown sera renvoyé.",
	"func_getChipCooldown_return": "Le cooldown de la puce <b>chip</b>.",
	"func_getChipCost": "Renvoie le coût en PT de la puce <b>chip</b>.",
	"func_getChipCost_arg_1": "La puce dont le coût sera renvoyé.",
	"func_getChipCost_return": "Le coût de la puce <b>chip</b>.",
	"func_getChipEffectiveArea": "Renvoie la liste des cellules qui seront affectés si la puce <b>chip</b> est utilisée sur la cellule <b>cell</b> depuis une cellule <b>from</b>.",
	"func_getChipEffectiveArea_arg_1": "La puce à tester.",
	"func_getChipEffectiveArea_arg_2": "La cellule cible.",
	"func_getChipEffectiveArea_arg_3": "La cellule depuis laquelle la puce est utilisée.",
	"func_getChipEffectiveArea_return": "Le tableau contenant les ids de toutes les cellules qui seront affectés.",
	"func_getChipEffects": "Renvoie les effets de la puce <b>chip</b>.",
	"func_getChipEffects_arg_1": "La puce dont les effets seront renvoyés.",
	"func_getChipEffects_return": "Les effets de la puce <b>chip</b>. Même valeur de retour que pour la fonction #getWeaponEffects.",
	"func_getChipFailure": "Renvoie le pourcentage de risque d'échec de la puce <b>chip</b>.",
	"func_getChipFailure_arg_1": "La puce dont le pourcentage d'échec sera renvoyé.",
	"func_getChipFailure_return": "Pourcentage d'échec de la puce <b>chip</b>, un nombre entier entre <b>0</b> et <b>100</b>.",
	"func_getChipMaxRange": "Renvoie la portée maximale de la puce <b>chip</b>.",
	"func_getChipMaxRange_arg_1": "La puce dont la portée maximale sera renvoyée.",
	"func_getChipMaxRange_return": "La portée maximale de la puce <b>chip</b>.",
	"func_getChipMaxScope": "Renvoie la portée maximale de la puce <b>chip</b>.",
	"func_getChipMaxScope_arg_1": "La puce dont la portée maximale sera renvoyée.",
	"func_getChipMaxScope_return": "La portée maximale de la puce <b>chip</b>.",
	"func_getChipMinRange": "Renvoie la portée minimale de la puce <b>chip</b>.",
	"func_getChipMinRange_arg_1": "La puce dont la portée minimale sera renvoyée.",
	"func_getChipMinRange_return": "La portée minimale de la puce <b>chip</b>.",
	"func_getChipMinScope": "Renvoie la portée minimale de la puce <b>chip</b>.",
	"func_getChipMinScope_arg_1": "La puce dont la portée minimale sera renvoyée.",
	"func_getChipMinScope_return": "La portée minimale de la puce <b>chip</b>.",
	"func_getChipLaunchType": "Renvoie le mode de lancé de la puce <b>chip</b>, parmi les constantes LAUNCH_TYPE_*.",
	"func_getChipLaunchType_arg_1": "L'id de la puce dont le mode de lancé sera renvoyé.",
	"func_getChipLaunchType_return": "Le mode de lancé de la puce <b>chip</b>.",
	"func_getChipName": "Renvoie le nom de la puce <b>chip</b>.",
	"func_getChipName_arg_1": "La puce dont le nom sera renvoyé.",
	"func_getChipName_return": "Le nom de la puce <b>chip</b>.",
	"func_getChips": "Renvoie les puces de l'entité d'id <b>entity</b>.",
	"func_getChips_arg_1": "L'id de l'entité dont les puces seront renvoyées.",
	"func_getChips_return": "Un tableau contenant les ids des puces de l'entité <b>entity</b>.",
	"func_getChipTargets": "Renvoie les entités qui seront affectées si la puce <b>chip</b> est utilisée sur la cellule <b>cell</b>.",
	"func_getChipTargets_arg_1": "La puce à tester.",
	"func_getChipTargets_arg_2": "La cellule cible.",
	"func_getChipTargets_return": "Le tableau contenant les ids de tous les entités qui seront affectées.",
	"func_getColor": "Retourne l'entier correspondant à la couleur (<b>red</b>, <b>green</b>, <b>blue</b>) fournie en paramètres.",
	"func_getColor_arg_1": "Valeur du rouge entre 0 et 255.",
	"func_getColor_arg_2": "Valeur du vert entre 0 et 255.",
	"func_getColor_arg_3": "Valeur du bleu entre 0 et 255.",
	"func_getColor_return": "int correspondant à la couleur fournie en paramètre.",
	"func_getCooldown": "Renvoie le cooldown actuel de la puce <b>chip</b> de l'entité <b>entity</b>.",
	"func_getCooldown_arg_1": "La puce dont le cooldown actuel sera renvoyé.",
	"func_getCooldown_arg_2": "L'entité dont le cooldown sera renvoyé.",
	"func_getCooldown_return": "Le cooldown actuel de la puce <b>chip</b>, il s'agit du nombre de tours avant lesquels la puce deviendra utilisable, <b>0</b> si elle est actuellement utilisable.",
	"func_getCores": "Renvoie le nombre de coeurs de l'entité d'id <b>entity</b>.",
	"func_getCores_arg_1": "L'entité dont le nombre de coeurs sera retournée.",
	"func_getCores_return": "Le nombre de coeurs de l'entité <b>entity</b>.",
	"func_getDate": "Renvoie la date du combat, au format dd/MM/yyyy.",
	"func_getDate_return": "La date du combat.",
	"func_getTime": "Renvoie le temps du combat, au format HH:mm:ss.",
	"func_getTime_return": "Le temps du combat.",
	"func_getTimestamp": "Renvoie l'horodatage du combat, égual au nombre de secondes depuis le 1er janvier 1970.",
	"func_getTimestamp_return": "L'horodatage du combat.",
	"func_getDamageReturn": "Retourne le taux de renvoi de dommages de l'entité d'id <b>entity</b>.",
	"func_getDamageReturn_arg_1": "L'id de l'entité dont le renvoi de dommages sera retourné.",
	"func_getDamageReturn_return": "Le taux de renvoi de dommages de l'entité d'id <b>entity</b> (en %).",
	"func_getDeadAllies": "Renvoie les entités alliées mortes.",
	"func_getDeadAllies_return": "Le tableau des entités alliées mortes.",
	"func_getDeadEnemies": "Renvoie les entités ennemies mortes.",
	"func_getDeadEnemiesCount": "Renvoie le nombre d'ennemis morts dans le combat.",
	"func_getDeadEnemiesCount_return": "Le nombre d'ennemis morts.",
	"func_getDeadEnemies_return": "Le tableau des entités ennemies mortes.",
	"func_getDistance": "Calcule la distance à vol d'oiseau entre deux cellules <b>cell1</b> et <b>cell2</b>. <br />Pour obtenir la distance en nombre de cellules, voir #getCellDistance, et pour obtenir la longueur du chemin entre les deux cellules en esquivant les divers obstacles, voir #getPathLength.",
	"func_getDistance_arg_1": "La cellule de départ.",
	"func_getDistance_arg_2": "La cellule d'arrivée.",
	"func_getDistance_return": "La distance à vol d'oiseau entre les deux cellules.",
	"func_getPassiveEffects": "Retourne la liste des effets passifs de l'entité d'id <b>entity</b>. Pour récupérer directement la liste des effets passifs de votre entité, utilisez #getPassiveEffects() sans paramètre.",
	"func_getPassiveEffects_arg_1": "L'id de l'entité dont la liste des effets passif sera retourné.",
	"func_getPassiveEffects_return": "La liste des effets passifs actuellement présents sur l'entité <b>entity</b>.\nLa liste des effets passifs est un tableau contenant les effets.\nUn effet est lui-même un tableau de 7 cases de la forme : [<b>type</b>, <b>value</b>, <b>caster_id</b>, <b>turns</b>, <b>critical</b>, <b>item_id</b>, <b>target_id</b>]. C'est la même structure que les effets classiques renvoyés par la fonction #getEffects.",
	"func_getEnemies": "Renvoie les entités ennemies (vivantes ou mortes) dans le combat.",
	"func_getEnemiesCount": "Renvoie le nombre d'ennemis dans le combat.",
	"func_getEnemiesCount_return": "Le nombre d'ennemis.",
	"func_getEnemiesLife": "Calcule la somme des points de vie de tous les entités ennemies.",
	"func_getEnemiesLife_return": "La somme des points de vie de l'équipe ennemie.",
	"func_getEnemies_return": "Un tableau contenant les ids de tous les entités ennemies.",
	"func_getEntityTurnOrder": "Retourne une valeur entre 1 et n (nombre d'entités actuellement en jeu) indiquant la position de l'entité <b>entity</b> dans l'ordre de jeu.",
	"func_getEntityTurnOrder_arg_1": "L'id de l'entité dont l'ordre de jeu sera renvoyé",
	"func_getEntityTurnOrder_return": "Place dans l'ordre de jeu de l'entité <b>entity</b>",
	"func_getFarmerID": "Renvoie l'id de l'éleveur de l'entité <b>entity</b>.",
	"func_getFarmerID_arg_1": "L'id de l'entité dont l'id d'éleveur sera renvoyé.",
	"func_getFarmerID_return": "L'id de l'éleveur de l'entité <b>entity</b>.",
	"func_getFarmerCountry": "Renvoie le pays de l'éleveur de l'entité <b>entity</b>.",
	"func_getFarmerCountry_arg_1": "L'id de l'entité dont le pays de d'éleveur sera renvoyé.",
	"func_getFarmerCountry_return": "Le pays de l'éleveur de l'entité <b>entity</b>, ou \"?\" si non indiqué.",
	"func_getFarmerName": "Renvoie le nom de l'éleveur de l'entité <b>entity</b>.",
	"func_getFarmerName_arg_1": "L'id de l'entité dont le nom d'éleveur sera renvoyé.",
	"func_getFarmerName_return": "Le nom de de l'éleveur de l'entité <b>entity</b>.",
	"func_getFarthestAlly": "Détermine l'allié le plus éloigné de votre entité, à vol d'oiseau.",
	"func_getFarthestAlly_return": "L'id de l'entité alliée la plus éloignée.",
	"func_getFarthestEnemy": "Détermine l'ennemi le plus éloigné de votre entité, à vol d'oiseau.",
	"func_getFarthestEnemy_return": "L'id de l'entité ennemie la plus éloignée.",
	"func_getFightID": "Retourne l'id du combat actuel.",
	"func_getFightID_return": "L'id du combat actuel.",
	"func_getFightContext": "Retourne le contexte du combat actuel.",
	"func_getFightContext_return": "Selon le contexte du combat : Combat de test (#FIGHT_CONTEXT_TEST), Combat en arène (#FIGHT_CONTEXT_GARDEN), Combat en tournoi (#FIGHT_CONTEXT_TOURNAMENT), Combat en défi (#FIGHT_CONTEXT_CHALLENGE), Battle Royale (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "Retourne le type de combat actuel.",
	"func_getFightType_return": "Selon le type de combat : Combat en solo (#FIGHT_TYPE_SOLO), Combat d'éleveur (#FIGHT_TYPE_FARMER), Combat d'équipe (#FIGHT_TYPE_TEAM), Battle Royale (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "Renvoie la force de l'entité d'id <b>entity</b>.",
	"func_getForce_arg_1": "L'entité dont la force sera retournée.",
	"func_getForce_return": "La force de l'entité <b>entity</b>.",
	"func_getFrequency": "Renvoie la fréquence de l'entité d'id <b>entity</b>. Utilisez getFrequency() sans paramètre pour récupérer votre fréquence.",
	"func_getFrequency_arg_1": "L'entité dont la fréquence sera retournée.",
	"func_getFrequency_return": "La fréquence de l'entité <b>entity</b>.",
	"func_getGreen": "Renvoie le taux de vert dans la couleur <b>color</b>, entre 0 et 255. Par exemple, getGreen(#COLOR_GREEN) = 255 et getGreen(#COLOR_RED) = 0.",
	"func_getGreen_arg_1": "La couleur dont le taux de vert sera renvoyé.",
	"func_getGreen_return": "Le taux de vert dans la couleur <b>color</b>",
	"func_getInstructionsCount": "Renvoie le nombre d'instructions que votre entité a effectué durant le tour actuel.",
	"func_getInstructionsCount_return": "Le nombre d'instructions que votre entité a effectué durant le tour actuel.",
	"func_getLaunchedEffects": "Renvoie la liste des effets qu'a provoqué l'entité d'id <b>entity</b>.",
	"func_getLaunchedEffects_arg_1": "L'id de l'entité dont la liste des effets provoqués sera retournée.",
	"func_getLaunchedEffects_return": "La liste des effets provoqués l'entité d'id <b>entity</b>, de la même forme que le tableau renvoyé par #getEffects.",
	"func_getLeek": "Renvoie l'id de votre entité.",
	"func_getLeek_return": "L'id de votre entité.",
	"func_getEntity": "Renvoie l'id de votre entité.",
	"func_getEntity_return": "L'id de votre entité.",
	"func_getLeekID": "Renvoie l'id réel du poireau d'id <b>leek</b>.",
	"func_getLeekID_arg_1": "L'id du poireau dont l'id réel sera retourné.",
	"func_getLeekID_return": "L'id réel du poireau <b>entity</b>.",
	"func_getLeekOnCell": "Renvoie l'entité qui se trouve sur la cellule <b>cell</b>.",
	"func_getLeekOnCell_arg_1": "La cellule dont on veut récupérer l'entité.",
	"func_getLeekOnCell_return": "L'id de l'entité se trouvant sur la cellule, ou -1 si la cellule ne comporte pas d'entité.",
	"func_getEntityOnCell": "Renvoie l'entité qui se trouve sur la cellule <b>cell</b>.",
	"func_getEntityOnCell_arg_1": "La cellule dont on veut récupérer l'entité.",
	"func_getEntityOnCell_return": "L'id de l'entité se trouvant sur la cellule, ou -1 si la cellule ne comporte pas d'entité.",
	"func_getLevel": "Renvoie le niveau de l'entité d'id <b>entity</b>.",
	"func_getLevel_arg_1": "L'id de l'entité dont le niveau sera renvoyé.",
	"func_getLevel_return": "Le niveau de l'entité d'id <b>entity</b>.",
	"func_getLife": "Renvoie la vie actuelle de l'entité d'id <b>entity</b>. Utilisez getLife() sans paramètre pour récupérer votre vie.",
	"func_getLife_arg_1": "L'id de l'entité dont la vie sera renvoyée.",
	"func_getLife_return": "La vie actuelle de l'entité <b>entity</b>.",
	"func_getMagic": "Renvoie la magie de l'entité d'id <b>entity</b>. Utilisez getMagic() sans paramètre pour récupérer votre magie.",
	"func_getMagic_arg_1": "L'id de l'entité dont la magie sera retournée.",
	"func_getMagic_return": "La magie de l'entité d'id <b>entity</b>.",
	"func_getMapType": "Renvoie le type de terrain sur lequel se déroule le combat (usine, désert, forêt etc.), parmi les constantes #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER et #MAP_BEACH.",
	"func_getMapType_return": "Le type de terrain.",
	"func_getMessageAuthor": "Renvoie l'id de l'entité auteur du message <b>message</b>.",
	"func_getMessageAuthor_arg_1": "Le message dont l'auteur sera renvoyé.",
	"func_getMessageAuthor_return": "L'id de l'entité auteur du message <b>message</b>.",
	"func_getMessageParams": "Renvoie le tableau des paramètres du message <b>message</b>.",
	"func_getMessageParams_arg_1": "Le message dont les paramètres seront renvoyés.",
	"func_getMessageParams_return": "Les paramètres du message <b>message</b>.",
	"func_getMessages": "Renvoie le tableau des messages de l'entité <b>entity</b>.",
	"func_getMessages_arg_1": "L'entité dont les messages seront renvoyés.",
	"func_getMessages_return": "Le tableau de vos messages.<br>Un message est représenté lui-même sous la forme d'un\ntableau de la forme : [<b>auteur</b>, <b>type</b>, <b>paramètres</b>]<br>\nLes différents types de messages sont représentés par les constantes :\n<ul>\n\t<li>#MESSAGE_HEAL : demande de soins</li>\n\t<li>#MESSAGE_ATTACK : demande d'attaquer</li>\n\t<li>#MESSAGE_BUFF_FORCE : demande de boost force</li>\n\t<li>...</li>\n</ul>",
	"func_getMessageType": "Renvoie le type du message <b>message</b>.",
	"func_getMessageType_arg_1": "Le message dont le type sera renvoyé (parmis #MESSAGE_HEAL, #MESSAGE_ATTACK, etc.).",
	"func_getMessageType_return": "Le type du message <b>message</b>.",
	"func_getMP": "Revoie le nombre de points de mouvements actuel de l'entité <b>entity</b>. Utilisez getMP() sans paramètre pour récupérer vos PM.",
	"func_getMP_arg_1": "L'id de l'entité dont le nombre de PM sera renvoyé.",
	"func_getMP_return": "Le nombre de PM de l'entité <b>entity</b>.",
	"func_getName": "Renvoie le nom de l'entité d'id <b>entity</b>.",
	"func_getName_arg_1": "L'id de l'entité dont le nom sera renvoyé.",
	"func_getName_return": "Le nom de l'entité <b>entity</b>.",
	"func_getNearestAlly": "Renvoie l'entité alliée la plus proche de votre entité.",
	"func_getNearestAlly_return": "L'id de l'entité alliée la plus proche.",
	"func_getNearestAllyTo": "Renvoie l'entité alliée la plus proche de l'entité fourni en paramètre.",
	"func_getNearestAllyTo_arg_1": "L'id de l'entité dont on veut connaitre l'allié le plus proche.",
	"func_getNearestAllyToCell": "Renvoie l'entité alliée la plus proche de la cellule fournie en paramètre.",
	"func_getNearestAllyToCell_arg_1": "L'id de la cellule dont on veut connaitre l'allié le plus proche.",
	"func_getNearestAllyToCell_return": "L'id de l'entité alliée la plus proche.",
	"func_getNearestAllyTo_return": "L'id de l'entitée alliée la plus proche.",
	"func_getNearestEnemy": "Renvoie l'entité ennemie la plus proche de votre entité.",
	"func_getNearestEnemy_return": "L'id de l'entité ennemie la plus proche.",
	"func_getNearestEnemyTo": "Renvoie l'entité ennemie la plus proche de l'entité fourni en paramètre.",
	"func_getNearestEnemyTo_arg_1": "L'id de l'entité dont on veut connaitre l'ennemi le plus proche.",
	"func_getNearestEnemyToCell": "Renvoie l'entité ennemie la plus proche de la cellule fournie en paramètre.",
	"func_getNearestEnemyToCell_arg_1": "L'id de la cellule dont on veut connaitre l'ennemi le plus proche.",
	"func_getNearestEnemyToCell_return": "L'id de l'entité ennemie la plus proche.",
	"func_getNearestEnemyTo_return": "L'id de l'entité ennemie la plus proche.",
	"func_getNextPlayer": "Renvoie l'id de l'entité qui jouera après le joueur actuel.",
	"func_getNextPlayer_return": "Le joueur suivant.",
	"func_getObstacles": "Renvoie la liste des cases obstacles du terrain.",
	"func_getObstacles_return": "Le tableau contenant les id des cellules obstacles.",
	"func_getOperations": "Renvoie le nombre d'opérations consommées par votre entité depuis le début de son tour. Ce nombre doit rester inférieur à #OPERATIONS_LIMIT pour ne pas que l'entité plante.",
	"func_getOperations_return": "Nombre d'opérations consommées par votre entité depuis le début de son tour.",
	"func_getPath": "Renvoie le chemin en évitant les obstacles entre deux cellules <b>cell1</b> et <b>cell2</b>, si celui-ci existe, en ignorant les cellules contenues dans le tableau <b>ignoredCells</b>. Si un joueur se situe sur une cellule ignorée, le chemin peut passer sur lui.<br /><br />\nLa cellule de départ <b>cell1</b> ne fait jamais partie du chemin résultant. La cellule <b>cell2</b> fait partie du chemin résultant si et seulement si elle est vide ou ignorée par <b>ignoredCells</b>.<br /><br />\nSi aucun chemin n'existe entre les deux cellules, <b>getPath</b> renvoie <i>null</i>.",
	"func_getPath_arg_1": "La cellule de départ.",
	"func_getPath_arg_2": "La cellule d'arrivée.",
	"func_getPath_arg_3": "Le tableau des cellules à ignorer.",
	"func_getPath_return": "Le tableau contenant les cellules constituant le chemin entre les deux cellules.",
	"func_getPathLength": "Renvoie la longueur du chemin entre deux cellules <b>cell1</b> et <b>cell2</b>, en esquivant les obstacles, en ignorant les cellules contenues dans le tableau <b>ignoredCells</b>. Cette fonction équivaut à <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>.\nSi un joueur se situe sur une cellule ignorée, le chemin peut passer sur lui.<br /><br />\nLa cellule de départ <b>cell1</b> n'est jamais comptée dans le résultat. La cellule <b>cell2</b> est comptée dans le résultat si et seulement si elle est vide ou ignorée par <b>ignoredCells</b>.<br /><br />\nSi aucun chemin n'existe entre les deux cellules, <b>getPathLength</b> renvoie <i>null</i>.",
	"func_getPathLength_arg_1": "La cellule de départ.",
	"func_getPathLength_arg_2": "La cellule d'arrivée.",
	"func_getPathLength_arg_3": "Le tableau des cellules à ignorer.",
	"func_getPathLength_return": "La longueur du chemin entre <b>cell1</b> et <b>cell2</b>.",
	"func_getPreviousPlayer": "Renvoie l'id de l'entité ayant joué avant le joueur actuel.",
	"func_getPreviousPlayer_return": "Le joueur précédent.",
	"func_getRed": "Renvoie le taux de rouge dans la couleur <b>color</b>, entre 0 et 255. Par exemple, getRed(#COLOR_RED) = 255 et getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "La couleur dont le taux de rouge sera renvoyé.",
	"func_getRed_return": "Le taux de rouge dans la couleur <b>color</b>",
	"func_getRegister": "Renvoie la valeur stockée dans le registre de l'entité associé à la clé <b>key</b> ou </i>null</i> si le registre n'existe pas.",
	"func_getRegister_arg_1": "La clé du registre dont la valeur sera retournée.",
	"func_getRegister_return": "La valeur stockée dans le registre de clé <b>key</b>.",
	"func_getRegisters": "Renvoie l'ensemble des registres de l'entité sous la forme d'un tableau associatif [<i>clé du registre</i> : <i>valeur du registre</i>]. Exemple : <code>debug(getRegisters());\n// Affiche par exemple :\n// ['reg1' : '314323', 'reg2' : 'test_string']</code>",
	"func_getRegisters_return": "Le tableau associatif correspondant à tous les registres de l'entité.",
	"func_getRelativeShield": "Retourne le bouclier relatif de l'entité d'id <b>entity</b>. Pour récupérer directement le bouclier relatif de votre entité, utilisez #getRelativeShield() sans paramètre.",
	"func_getRelativeShield_arg_1": "L'id de l'entité dont le bouclier relatif sera retourné.",
	"func_getRelativeShield_return": "Le bouclier relatif de l'entité <b>entity</b>, un nombre entier entre <b>0</b> et <b>100</b>.",
	"func_getResistance": "Renvoie la résistance de l'entité d'id <b>entity</b>. Utilisez getResistance() sans paramètre pour récupérer votre résistance.",
	"func_getResistance_arg_1": "L'id de l'entité dont la résistance sera retournée.",
	"func_getResistance_return": "La résistance de l'entité d'id <b>entity</b>.",
	"func_getScience": "Renvoie la science de l'entité d'id <b>entity</b>. Utilisez getScience() sans paramètre pour récupérer votre science.",
	"func_getScience_arg_1": "L'id de l'entité dont la science sera retournée.",
	"func_getScience_return": "La science de l'entité d'id <b>entity</b>.",
	"func_getStrength": "Renvoie la force de l'entité d'id <b>entity</b>. Utilisez getStrength() sans paramètre pour récupérer votre force.",
	"func_getStrength_arg_1": "L'entité dont la force sera retournée.",
	"func_getStrength_return": "La force de l'entité <b>entity</b>.",
	"func_getPower": "Renvoie la puissance de l'entité d'id <b>entity</b>.",
	"func_getPower_arg_1": "L'entité dont la puissance sera retournée.",
	"func_getPower_return": "La puissance de l'entité <b>entity</b>.",
	"func_getSummoner": "Renvoie l'entité a invoqué l'entité <b>entity</b>, s'il s'agit d'une invocation.",
	"func_getSummoner_arg_1": "L'id de l'entité dont l'invocateur sera renvoyé.",
	"func_getSummoner_return": "L'id de l'entité a invoqué <b>entity</b> s'il s'agit d'une invocation, <i>null</i> sinon.",
	"func_getSummons": "Renvoie la liste des ids des invocations actuellement en vie de l'entité d'id <b>entity</b>.",
	"func_getSummons_arg_1": "L'id de l'entité dont les invocations seront renvoyées.",
	"func_getSummons_return": "La liste des ids des invocations de l'entité d'id <b>entity</b>.",
	"func_getTeamID": "Renvoie l'id de l'équipe de l'entité <b>entity</b>.",
	"func_getTeamID_arg_1": "L'id de l'entité dont l'id d'équipe sera renvoyé.",
	"func_getTeamID_return": "L'id de l'équipe de l'entité <b>entity</b>.",
	"func_getTeamName": "Renvoie le nom de l'équipe de l'entité <b>entity</b>.",
	"func_getTeamName_arg_1": "L'id de l'entité dont le nom d'équipe sera renvoyé.",
	"func_getTeamName_return": "Le nom de l'équipe de l'entité <b>entity</b>.",
	"func_getTotalLife": "Renvoie la vie totale de l'entité d'id <b>entity</b>. Utilisez getTotalLife() sans paramètre pour récupérer votre vie totale.",
	"func_getTotalLife_arg_1": "L'id de l'entité dont la vie totale sera retournée.",
	"func_getTotalLife_return": "La vie totale de l'entité.",
	"func_getTotalMP": "Renvoie le nombre maximal de points de mouvement de l'entité <b>entity</b>.",
	"func_getTotalMP_arg_1": "L'id de l'entité dont le le nombre maximal de points de mouvement sera retourné.",
	"func_getTotalMP_return": "Le nombre maximal de points de mouvement de l'entité <b>entity</b>.",
	"func_getTotalTP": "Renvoie le nombre maximal de points de tour de l'entité <b>entity</b>.",
	"func_getTotalTP_arg_1": "L'id de l'entité dont le le nombre maximal de points de tour sera retourné.",
	"func_getTotalTP_return": "Le nombre maximal de points de tour de l'entité <b>entity</b>.",
	"func_getTP": "Renvoie le nombre de points de tour de l'entité <b>entity</b>. Utilisez getTP() sans paramètre pour récupérer vos PT.",
	"func_getTP_arg_1": "L'id de l'entité dont les PT seront renvoyés.",
	"func_getTP_return": "Le nombre de PT de l'entité <b>entity</b>.",
	"func_getTurn": "Renvoie le tour actuel du combat. Le nombre de tours maximum est #MAX_TURNS.",
	"func_getTurn_return": "Le tour actuel du combat.",
	"func_getType": "Renvoie le type d'entité de l'entité <b>entity</b>.",
	"func_getType_arg_1": "L'id de l'entité dont le type sera renvoyé.",
	"func_getType_return": "Le type d'entité de <b>entity</b> :<ul><li>#ENTITY_LEEK s'il s'agit d'un poireau.</li><li>#ENTITY_BULB s'il s'agit d'un bulbe.</li><li>#ENTITY_TURRET s'il s'agit d'une tourelle.</li></ul>",
	"func_getWeapon": "Renvoie l'arme actuellement équipée l'entité <b>entity</b>.",
	"func_getWeapon_arg_1": "L'id de l'entité dont l'arme actuelle sera renvoyée.",
	"func_getWeapon_return": "L'id de l'arme actuellement équipée sur l'entité <b>entity</b>, null si l'entité n'a pas d'arme équipée ou si l'entité n'existe pas.",
	"func_getWeaponArea": "Renvoie le type de zone d'effet de l'arme <b>weapon</b>.",
	"func_getWeaponArea_arg_1": "L'arme dont le type de zone sera renvoyé.",
	"func_getWeaponArea_return": "Le type de zone de l'arme <b>weapon</b> parmi les constantes AREA_* :\n<ul>\n<li>#AREA_POINT : zone d'une seule case</li>\n<li>#AREA_LASER_LINE : ligne d'un laser</li>\n<li>#AREA_CIRCLE_1 : zone circulaire de 3 cases de diamètre</li>\n<li>#AREA_CIRCLE_2 : zone circulaire de 5 cases de diamètre</li>\n<li>#AREA_CIRCLE_3 : zone circulaire de 7 cases de diamètre</li>\n</ul>",
	"func_getWeaponCost": "Renvoie le coût en PT de l'arme <b>weapon</b>.",
	"func_getWeaponCost_arg_1": "L'id de l'arme dont le coût sera renvoyé.",
	"func_getWeaponCost_return": "Le coût en PT de l'arme <b>weapon</b>.",
	"func_getWeaponEffectiveArea": "Renvoie la liste des cellules qui seront affectées si l'arme <b>weapon</b> est utilisée sur la cellule <b>cell</b> depuis la cellule <b>from</b>.",
	"func_getWeaponEffectiveArea_arg_1": "L'arme à tester.",
	"func_getWeaponEffectiveArea_arg_2": "La cellule cible.",
	"func_getWeaponEffectiveArea_arg_3": "La cellule depuis laquelle l'arme est utilisée.",
	"func_getWeaponEffectiveArea_return": "Le tableau contenant les ids de toutes les cellules qui seront affectées.",
	"func_getWeaponPassiveEffects": "Renvoie les effets passifs de l'arme <b>weapon</b>.",
	"func_getWeaponPassiveEffects_arg_1": "L'id de l'arme dont les effets passifs seront retournés.",
	"func_getWeaponPassiveEffects_return": "Un tableau contenant les effets de l'arme <b>weapon</b>. Chaque effet est lui-même un tableau de la forme\n[type, min, max, turns, targets, modifiers]. Ces effets sont les mêmes que ceux renvoyés par #getWeaponEffects.",
	"func_getWeaponFailure": "Renvoie le pourcentage de risque d'échec de l'arme <b>weapon</b>.",
	"func_getWeaponFailure_arg_1": "L'id de l'arme dont le pourcentage d'échec sera renvoyé.",
	"func_getWeaponFailure_return": "Pourcentage d'échec de l'arme <b>weapon</b>, un nombre entier entre <b>0</b> et <b>100</b>.",
	"func_getWeaponMaxRange": "Renvoie la portée maximale de l'arme <b>weapon</b>.",
	"func_getWeaponMaxRange_arg_1": "L'id de l'arme dont la portée maximale sera renvoyée.",
	"func_getWeaponMaxRange_return": "La portée maximale de l'arme <b>weapon</b>.",
	"func_getWeaponMaxScope": "Renvoie la portée maximale de l'arme <b>weapon</b>.",
	"func_getWeaponMaxScope_arg_1": "L'id de l'arme dont la portée maximale sera renvoyée.",
	"func_getWeaponMaxScope_return": "La portée maximale de l'arme <b>weapon</b>.",
	"func_getWeaponMinRange": "Renvoie la portée minimale de l'arme <b>weapon</b>.",
	"func_getWeaponMinRange_arg_1": "L'id de l'arme dont la portée minimale sera renvoyée.",
	"func_getWeaponMinRange_return": "La portée minimale de l'arme <b>weapon</b>.",
	"func_getWeaponMinScope": "Renvoie la portée minimale de l'arme <b>weapon</b>.",
	"func_getWeaponMinScope_arg_1": "L'id de l'arme dont la portée minimale sera renvoyée.",
	"func_getWeaponMinScope_return": "La portée minimale de l'arme <b>weapon</b>.",
	"func_getWeaponLaunchType": "Renvoie le mode de lancé de l'arme <b>weapon</b>, parmi les constantes LAUNCH_TYPE_*.",
	"func_getWeaponLaunchType_arg_1": "L'id de l'arme dont le mode de lancé sera renvoyé. Par défaut votre arme actuellement équipée.",
	"func_getWeaponLaunchType_return": "Le mode de lancé de l'arme <b>weapon</b>.",
	"func_getWeaponName": "Renvoie le nom de l'arme <b>weapon</b>.",
	"func_getWeaponName_arg_1": "L'id de l'arme dont le nom sera renvoyé.",
	"func_getWeaponName_return": "Le nom de l'arme <b>weapon</b>.",
	"func_getWeapons": "Renvoie les armes de l'entité d'id <b>entity</b>.",
	"func_getWeapons_arg_1": "L'id de l'entité dont les armes seront renvoyées.",
	"func_getWeapons_return": "Un tableau contenant les ids des armes de l'entité <b>entity</b>.",
	"func_getWeaponTargets": "Renvoie les entités qui seront affectées si l'arme <b>weapon</b> est utilisée sur la cellule <b>cell</b>.",
	"func_getWeaponTargets_arg_1": "L'arme à tester.",
	"func_getWeaponTargets_arg_2": "La cellule cible.",
	"func_getWeaponTargets_return": "Le tableau contenant les ids de tous les entités qui seront affectées.",
	"func_getWisdom": "Renvoie la sagesse de l'entité d'id <b>entity</b>. Utilisez getWisdom() sans paramètre pour récupérer votre sagesse.",
	"func_getWisdom_arg_1": "L'id de l'entité dont la sagesse sera retournée.",
	"func_getWisdom_return": "La sagesse de l'entité d'id <b>entity</b>.",
	"func_hypot": "Renvoie l'hypoténuse du triangle rectangle de côtés x et y. Équivaut à <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "La valeur x.",
	"func_hypot_arg_2": "La valeur y.",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "Détermine si l'élément <b>element</b> est dans le tableau <b>array</b>.",
	"func_inArray_arg_1": "Le tableau de recherche.",
	"func_inArray_arg_2": "L'élément à rechercher.",
	"func_inArray_return": "<i>vrai</i> si l'élément est dans le tableau, <i>faux</i> sinon.",
	"func_include": "Inclut l'IA de nom <b>ai</b> dans l'IA courante. <br><br><b>Attention</b>, la fonction include doit être appelée uniquement dans le bloc principal, et son paramètre doit être une chaîne écrite directement\ndans le code. Pour plus d'informations, voir la section dans le tutoriel : <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a>.",
	"func_include_arg_1": "Le nom de l'IA à inclure.",
	"func_indexOf": "Détermine la position de la première occurrence de la chaîne <b>search</b> dans la chaîne <b>string</b>, à partir de la position <b>start</b>.",
	"func_indexOf_arg_1": "La chaîne où la recherche sera effectuée.",
	"func_indexOf_arg_2": "La chaîne à rechercher.",
	"func_indexOf_arg_3": "La position de départ de la recherche.",
	"func_indexOf_return": "La position de la première occurrence de <b>search</b> dans <b>string</b> à partir de <b>start</b>, -1 si la chaîne n'a pas été trouvée.",
	"func_insert": "Insère un élément <b>element</b> dans le tableau <b>array</b> à la position <b>position</b>.",
	"func_insert_arg_1": "Le tableau où l'élément sera inséré.",
	"func_insert_arg_2": "L'élément à insérer.",
	"func_insert_arg_3": "La position de l'insertion.",
	"func_isAlive": "Détermine si une entité <b>entity</b> est vivant. Équivalent à <i>getLife(<b>entity</b>) > 0</i>.",
	"func_isAlive_arg_1": "L'id de l'entité à tester.",
	"func_isAlive_return": "<i>vrai</i> si l'entité <b>entity</b> est vivant, <i>faux</i> s'il est mort.",
	"func_isAlly": "Détermine si l'entité <b>entity</b> est votre allié.",
	"func_isAlly_arg_1": "L'id de l'entité à tester.",
	"func_isAlly_return": "<i>vrai</i> si l'entité <b>entity</b> est votre allié ou bien vous-même, <i>faux</i> s'il s'agit d'un ennemi.",
	"func_isChip": "Détermine si une valeur est une constante représentant une puce.<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "Le nombre à déterminer.",
	"func_isChip_return": "<i>true</i> si la valeur est une constante de puce.",
	"func_isDead": "Détermine si l'entité <b>entity</b> est mort. Équivalent à <i>getLife(<b>entity</b>) == 0</i>.",
	"func_isDead_arg_1": "L'id de l'entité à tester.",
	"func_isDead_return": "<i>vrai</i> si l'entité <b>entity</b> est mort, <i>faux</i> s'il est vivant.",
	"func_isEmpty": "Détermine si le tableau <b>array</b> est vide. Équivalent à <i>count(<b>array</b>) == 0</i>.",
	"func_isEmpty_arg_1": "Le tableau à tester.",
	"func_isEmptyCell": "Détermine si une cellule est vide.",
	"func_isEmptyCell_arg_1": "La cellule à tester.",
	"func_isEmptyCell_return": "<i>vrai</i> si la cellule est vide, <i>faux</i> sinon.",
	"func_isEmpty_return": "<i>vrai</i> si le tableau est vide, <i>faux</i> sinon.",
	"func_isEnemy": "Détermine si l'entité <b>entity</b> est votre ennemi.",
	"func_isEnemy_arg_1": "L'id de l'entité à tester.",
	"func_isEnemy_return": "<i>vrai</i> si l'entité <b>entity</b> est un ennemi, <i>faux</i> s'il s'agit d'un allié ou bien vous-même.",
	"func_isInlineChip": "Détermine si la puce <b>chip</b> peut être utlisée uniquement en ligne.",
	"func_isInlineChip_arg_1": "L'id de la puce à tester.",
	"func_isInlineChip_return": "<i>vrai</i> si la puce est utilisable uniquement en ligne, <i>faux</i> sinon.",
	"func_isInlineWeapon": "Détermine si l'arme <b>weapon</b> peut être utilisée uniquement en ligne.",
	"func_isInlineWeapon_arg_1": "L'id de l'arme à tester.",
	"func_isInlineWeapon_return": "<i>vrai</i> si l'arme est utilisable uniquement en ligne, <i>faux</i> sinon.",
	"func_isLeek": "Détermine si le contenu de la cellule <b>cell</b> est une entité.",
	"func_isLeek_arg_1": "La cellule à tester.",
	"func_isLeek_return": "<i>vrai</i> si la cellule contient une entité, <i>faux</i> sinon.",
	"func_isEntity": "Détermine si le contenu de la cellule <b>cell</b> est une entité.",
	"func_isEntity_arg_1": "La cellule à tester.",
	"func_isEntity_return": "<i>vrai</i> si la cellule contient une entité, <i>faux</i> sinon.",
	"func_isObstacle": "Détermine si le contenu de la cellule <b>cell</b> est un obstacle.",
	"func_isObstacle_arg_1": "La cellule à tester.",
	"func_isObstacle_return": "<i>vrai</i> si la cellule contient un obstacle, <i>faux</i> sinon.",
	"func_isOnSameLine": "Détermine si deux cellules <b>cell1</b> et <b>cell2</b> sont sur la même ligne.",
	"func_isOnSameLine_arg_1": "La première cellule.",
	"func_isOnSameLine_arg_2": "La deuxième cellule.",
	"func_isOnSameLine_return": "<i>vrai</i> si les deux cellules sont sur la même ligne, <i>faux</i> sinon.",
	"func_isStatic": "Renvoie si l'entité <b>entity</b> est statique ou non. Une entité statique ne peut pas se déplacer ou être déplacée.",
	"func_isStatic_arg_1": "L'id de l'entité à tester.",
	"func_isStatic_return": "<i>true</i> si <b>entity</b> est statique, <i>false</i> sinon.",
	"func_isSummon": "Renvoie si l'entité <b>entity</b> est une invocation ou non.",
	"func_isSummon_arg_1": "L'id de l'entité à tester.",
	"func_isSummon_return": "<i>true</i> si <b>entity</b> est une invocation, <i>false</i> sinon.",
	"func_isWeapon": "Détermine si une valeur est une constante représentant une arme.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false.",
	"func_isWeapon_arg_1": "Le nombre à déterminer.",
	"func_isWeapon_return": "<i>true</i> si la valeur est une constante d'arme.",
	"func_join": "Fusionne plusieurs éléments en une chaîne de caractères, en les séparant par un délimiteur <b>glue</b>.",
	"func_join_arg_1": "Un tableau d'éléments à fusionner.",
	"func_join_arg_2": "Le délimiteur des éléments.",
	"func_join_return": "La chaîne de caractère résultante de la fusion.",
	"func_jsonDecode": "Décode la chaîne <b>json</b> en objet LeekScript (nombre, chaîne, tableau...).",
	"func_jsonDecode_arg_1": "La chaîne JSON à décoder.",
	"func_jsonDecode_return": "L'objet LeekScript décodé.",
	"func_jsonEncode": "Encode l'objet <b>object</b> en chaîne JSON.",
	"func_jsonEncode_arg_1": "L'objet à encoder en JSON.",
	"func_jsonEncode_return": "La chaîne encodée en JSON.",
	"func_keySort": "Trie le tableau <b>array</b> selon les clés selon l'ordre <b>order</b>.",
	"func_keySort_arg_1": "Le tableau à trier.",
	"func_keySort_arg_2": "L'ordre de tri : #SORT_ASC ou #SORT_DESC.",
	"func_length": "Renvoie la longueur de la chaîne <b>string</b>.",
	"func_length_arg_1": "La chaîne dont la longueur sera retournée.",
	"func_length_return": "La longueur de la chaîne <b>string</b>.",
	"func_lineOfSight": "Vérifie la ligne de vue entre la cellule <b>start</b> et la cellule <b>end</b>, en ignorant les entités <b>ignoredEntities</b>.",
	"func_lineOfSight_arg_1": "Cellule de départ.",
	"func_lineOfSight_arg_2": "Cellule cible.",
	"func_lineOfSight_arg_3": "Une entité ou liste d'entités à ignorer.",
	"func_lineOfSight_return": "Retourne <i>vrai</i> si la ligne de vue est dégagée.",
	"func_listen": "Renvoie le tableau des say() des entités précédentes, sous la forme [entity_id, message].",
	"func_listen_return": "Le tableau des say() précédents.",
	"func_log": "Calcule le logarithme néperien du nombre <b>number</b>.",
	"func_log_arg_1": "Un nombre compris dans l'intervalle ]0; +∞[.",
	"func_log_return": "Le logarithme néperien de <b>number</b>.",
	"func_log10": "Calcule le logarithme en base 10 du nombre <b>number</b>.",
	"func_log10_arg_1": "Un nombre compris dans l'intervalle ]0; +∞[.",
	"func_log10_return": "Le logarithme en base 10 de <b>number</b>.",
	"func_log2": "Calcule le logarithme en base 2 du nombre <b>number</b>.",
	"func_log2_arg_1": "Un nombre compris dans l'intervalle ]0; +∞[.",
	"func_log2_return": "Le logarithme en base 2 de <b>number</b>.",
	"func_mark": "Marque une ou plusieurs cellules de la couleur indiquée en paramètre sur le terrain pour le nombre de tour indiqué en paramètre. Ce marquage n'est visible que par l'éleveur de l'entité.",
	"func_mark_arg_1": "La cellule ou tableau de plusieurs cellules à marquer",
	"func_mark_arg_2": "Couleur du marquage",
	"func_mark_arg_3": "Durée du marquage",
	"func_mark_return": "Retourne true si tout s'est bien déroulé",
	"func_markText": "Écrit un texte sur une ou plusieurs cellules de la couleur indiquée en paramètre sur le terrain pour le nombre de tour indiqué en paramètre. Ces textes ne sont visibles que par l'éleveur de l'entité.",
	"func_markText_arg_1": "La cellule ou tableau de plusieurs cellules où écrire",
	"func_markText_arg_2": "Le texte à écrire (maximum 10 caractères)",
	"func_markText_arg_3": "Couleur du texte",
	"func_markText_arg_4": "Durée du texte",
	"func_markText_return": "Retourne true si tout s'est bien déroulé",
	"func_max": "Renvoie le plus grande valeur entre les deux nombres <b>a</b> et <b>b</b>.",
	"func_max_arg_1": "Un nombre.",
	"func_max_arg_2": "Un nombre.",
	"func_max_return": "Le plus grand nombre entre <b>a</b> et <b>b</b>.",
	"func_min": "Renvoie la plus petite valeur entre les deux nombres <b>a</b> et <b>b</b>.",
	"func_min_arg_1": "Un nombre.",
	"func_min_arg_2": "Un nombre.",
	"func_min_return": "Le plus petit nombre entre <b>a</b> et <b>b</b>.",
	"func_moveAwayFrom": "Éloigne votre entité d'un autre entité <b>entity</b>, en utilisant au maximum <b>mp</b> points de mouvement.",
	"func_moveAwayFrom_arg_1": "L'entité dont votre entité doit s'éloigner.",
	"func_moveAwayFrom_arg_2": "Le nombre maximum de PM à utiliser. Par défaut, pas de limite.",
	"func_moveAwayFrom_return": "Le nombre de points de mouvements utilisés.",
	"func_moveAwayFromCell": "Éloigne votre entité d'une cellule <b>cell</b>, en utilisant au maximum <b>mp</b> points de mouvement.",
	"func_moveAwayFromCell_arg_1": "La cellule dont votre entité doit s'éloigner.",
	"func_moveAwayFromCell_arg_2": "Le nombre maximum de PM à utiliser. Par défaut, pas de limite.",
	"func_moveAwayFromCell_return": "Le nombre de points de mouvements utilisés.",
	"func_moveAwayFromCells": "Éloigne votre entité d'un ensemble de cellules <b>cells</b>, en utilisant au maximum <b>mp</b> points de mouvement.",
	"func_moveAwayFromCells_arg_1": "Le tableau contenant les cellules dont votre entité doit s'éloigner.",
	"func_moveAwayFromCells_arg_2": "Le nombre maximum de PM à utiliser. Par défaut, pas de limite.",
	"func_moveAwayFromCells_return": "Le nombre de points de mouvements utilisés.",
	"func_moveAwayFromLeeks": "Éloigne votre entité d'un ensemble de entités <b>entities</b>, en utilisant au maximum <b>mp</b> points de mouvement.",
	"func_moveAwayFromLeeks_arg_1": "Le tableau contenant les ids des entités dont votre entité doit s'éloigner.",
	"func_moveAwayFromLeeks_arg_2": "Le nombre maximum de PM à utiliser. Par défaut, pas de limite.",
	"func_moveAwayFromLeeks_return": "Le nombre de points de mouvements utilisés.",
	"func_moveAwayFromEntities": "Éloigne votre entité d'un ensemble de entités <b>entities</b>, en utilisant au maximum <b>mp</b> points de mouvement.",
	"func_moveAwayFromEntities_arg_1": "Le tableau contenant les ids des entités dont votre entité doit s'éloigner.",
	"func_moveAwayFromEntities_arg_2": "Le nombre maximum de PM à utiliser. Par défaut, pas de limite.",
	"func_moveAwayFromEntities_return": "Le nombre de points de mouvements utilisés.",
	"func_moveAwayFromLine": "Éloigne votre entité d'une ligne définie par deux cellules <b>cell1</b> et <b>cell2</b>, en utilisant au maximum <b>mp</b> points de mouvement.",
	"func_moveAwayFromLine_arg_1": "La cellule 1.",
	"func_moveAwayFromLine_arg_2": "La cellule 2.",
	"func_moveAwayFromLine_arg_3": "Le nombre maximum de PM à utiliser. Par défaut, pas de limite.",
	"func_moveAwayFromLine_return": "Le nombre de points de mouvements utilisés.",
	"func_moveToward": "Rapproche votre entité d'une autre entité <b>entity</b>, en utilisant au maximum <b>mp</b> points de mouvement.",
	"func_moveToward_arg_1": "L'entité vers lequelle votre entité doit se rapprocher.",
	"func_moveToward_arg_2": "Le nombre maximum de PM à utiliser.",
	"func_moveToward_return": "Le nombre de points de mouvements utilisés.",
	"func_moveTowardCell": "Rapproche votre entité d'une cellule <b>cell</b>, en utilisant au maximum <b>mp</b> points de mouvement.",
	"func_moveTowardCell_arg_1": "La cellule vers laquelle votre entité doit se rapprocher.",
	"func_moveTowardCell_arg_2": "Le nombre maximum de PM à utiliser. Par défaut, pas de limite.",
	"func_moveTowardCell_return": "Le nombre de points de mouvements utilisés.",
	"func_moveTowardCells": "Rapproche votre entité d'un ensemble de cellules <b>cells</b>, en utilisant au maximum <b>mp</b> points de mouvement.",
	"func_moveTowardCells_arg_1": "Le tableau contenant les cellules vers lesquelles votre entité doit se rapprocher.",
	"func_moveTowardCells_arg_2": "Le nombre maximum de PM à utiliser. Par défaut, pas de limite.",
	"func_moveTowardCells_return": "Le nombre de points de mouvements utilisés.",
	"func_moveTowardLeeks": "Rapproche votre entité d'un ensemble d'entités <b>entities</b>, en utilisant au maximum <b>mp</b> points de mouvement.",
	"func_moveTowardLeeks_arg_1": "Le tableau contenant les ids des entités vers lesquelles votre entité doit se rapprocher.",
	"func_moveTowardLeeks_arg_2": "Le nombre maximum de PM à utiliser. Par défaut, pas de limite.",
	"func_moveTowardLeeks_return": "Le nombre de points de mouvements utilisés.",
	"func_moveTowardEntities": "Rapproche votre entité d'un ensemble d'entités <b>entities</b>, en utilisant au maximum <b>mp</b> points de mouvement.",
	"func_moveTowardEntities_arg_1": "Le tableau contenant les ids des entités vers lesquelles votre entité doit se rapprocher.",
	"func_moveTowardEntities_arg_2": "Le nombre maximum de PM à utiliser. Par défaut, pas de limite.",
	"func_moveTowardEntities_return": "Le nombre de points de mouvements utilisés.",
	"func_moveTowardLine": "Rapproche votre entité d'une ligne définie par deux cellules <b>cell1</b> et <b>cell2</b>, en utilisant au maximum <b>mp</b> points de mouvement.",
	"func_moveTowardLine_arg_1": "La cellule 1.",
	"func_moveTowardLine_arg_2": "La cellule 2.",
	"func_moveTowardLine_arg_3": "Le nombre maximum de PM à utiliser. Par défaut, pas de limite.",
	"func_moveTowardLine_return": "Le nombre de points de mouvements utilisés.",
	"func_number": "Convertit une valeur en nombre. Si la valeur est une chaîne, la fonction number va essayer de la convertir en nombre, si la valeur est déjà un nombre, la fonction renvoie le nombre, et pour tout autre type, elle renvoie <i>null</i>.",
	"func_number_arg_1": "La valeur à convertir en nombre.",
	"func_number_return": "Le nombre converti.",
	"func_pause": "Met en pause le combat, uniquement pour l'éleveur de l'entité qui utilise la fonction.",
	"func_pop": "Supprime la dernière case du tableau <b>array</b> et renvoie l'élément correspondant.",
	"func_pop_arg_1": "Le tableau dont la dernière case sera supprimée.",
	"func_pop_return": "L'élément qui était dans l'ancienne dernière case.",
	"func_pow": "Éleve le nombre base à la puissance exposant.",
	"func_pow_arg_1": "La base.",
	"func_pow_arg_2": "L'exposant.",
	"func_pow_return": "<b>base</b> élevée à la puissance <b>exposant</b>.",
	"func_push": "Ajoute l'élément <b>element</b> à la fin du tableau <b>array</b>.",
	"func_pushAll": "Ajoute tous les éléments du tableau <b>elements</b> à la fin du tableau <b>array</b>.",
	"func_pushAll_arg_1": "Le tableau où l'on veut ajouter les éléments.",
	"func_pushAll_arg_2": "Les éléments à ajouter.",
	"func_push_arg_1": "Le tableau où l'on veut ajouter l'élément.",
	"func_push_arg_2": "L'élément à ajouter.",
	"func_rand": "Renvoie un nombre aléatoire réel, compris entre 0 (inclus) et 1 (exclu).",
	"func_randFloat": "Renvoie un nombre aléatoire réel, compris entre <b>a</b> (inclus) et <b>b</b> (exclu).",
	"func_randFloat_arg_1": "Borne inférieure.",
	"func_randFloat_arg_2": "Borne supérieure.",
	"func_randFloat_return": "Un nombre aléatoire dans l'intervalle [a; b[.",
	"func_randInt": "Renvoie un nombre aléatoire entier, compris entre <b>a</b> (inclus) et <b>b</b> (exclu).",
	"func_randInt_arg_1": "Borne inférieure.",
	"func_randInt_arg_2": "Borne supérieure.",
	"func_randInt_return": "Un nombre entier aléatoire dans l'intervalle [a; b[.",
	"func_rand_return": "Un nombre aléatoire dans l'intervalle [0; 1[.",
	"func_remove": "Supprime un élément du tableau <b>array</b> à la position <b>position</b>, et retourne l'élément supprimé.",
	"func_remove_arg_1": "Tableau dont on veut supprimer un élément.",
	"func_remove_arg_2": "Position de l'élément à supprimer.",
	"func_removeElement": "Supprime la première occurrence d'un élément <b>element</b> dans le tableau <b>array</b>. Si l'élement n'est pas trouvé, le tableau n'est pas modifié.",
	"func_removeElement_arg_1": "Tableau dont on veut supprimer un élément.",
	"func_removeElement_arg_2": "Élement à rechercher puis à supprimer.",
	"func_removeKey": "Supprime un élément du tableau <b>array</b> associé à la clé <b>key</b>.",
	"func_removeKey_arg_1": "Tableau dont on veut supprimer un élément.",
	"func_removeKey_arg_2": "La clé associée à l'élément à supprimer.",
	"func_remove_return": "L'élément enlevé du tableau.",
	"func_replace": "Remplace toutes les occurrences de <b>search</b> par <b>replace</b> dans la chaîne <b>string</b>.",
	"func_replace_arg_1": "Chaîne dans laquelle les remplacements sont effectués.",
	"func_replace_arg_2": "Sous-chaîne à remplacer.",
	"func_replace_arg_3": "Chaîne de remplacement.",
	"func_replace_return": "La chaîne résultat, avec les remplacements.",
	"func_resurrect": "Utilise la puce CHIP_RESURRECTION pour ressusciter une entité d'id <b>entity</b> morte, sur la cellule <b>cell</b>.",
	"func_resurrect_arg_1": "L'id de l'entité à faire revivre.",
	"func_resurrect_arg_2": "La cellule sur laquelle l'entité réapparaîtra.",
	"func_resurrect_return": "Le résultat du lancement de la puce, parmi les constantes USE_*.",
	"func_reverse": "Inverse le tableau <b>array</b>.",
	"func_reverse_arg_1": "Tableau à inverser.",
	"func_round": "Retourne l'arrondi de <b>number</b>.<br/>Vous pouvez trouver l'arrondi à l'entier inférieur avec #floor, et l'arrondi à l'entier supérieur avec #ceil.",
	"func_round_arg_1": "Nombre dont on veut trouver l'arrondi.",
	"func_round_return": "Arrondi de <b>number</b>.",
	"func_say": "Fait parler votre entité.",
	"func_say_arg_1": "Message qu'annonçera votre entité dans l'arène.",
	"func_search": "Recherche l'élément <b>element</b> dans le tableau <b>array</b>, à partir de la position <b>start</b>.",
	"func_search_arg_1": "Le tableau de recherche.",
	"func_search_arg_2": "L'élément à rechercher.",
	"func_search_arg_3": "La position du début de la recherche.",
	"func_search_return": "La position de la première occurrence de l'élément <b>element</b> dans le tableau <b>array</b>, à partir de <b>start</b>, null si l'élément n'a pas été trouvé.",
	"func_sendAll": "Envoie un message à toute votre équipe.",
	"func_sendAll_arg_1": "Le type du message à envoyer (voir les constantes MESSAGE_*).",
	"func_sendAll_arg_2": "Les paramètres du message, qui peuvent être n'importe quelle valeur.",
	"func_sendTo": "Envoie un message à l'entité d'id <b>entity</b>.",
	"func_sendTo_arg_1": "L'id de l'entité auquelle sera envoyé le message.",
	"func_sendTo_arg_2": "Le type du message à envoyer (voir les constantes MESSAGE_*).",
	"func_sendTo_arg_3": "Les paramètres du message, qui peuvent être n'importe quelle valeur.",
	"func_sendTo_return": "<i>true</i> si l'envoi a été effecuté, <i>false</i> si une erreur est survenue.",
	"func_setRegister": "Stocke la valeur <b>value</b> dans le registre de clé <b>key</b>.\nLa clé et la valeur sont des chaînes qui doivent contenir respectivement <i>100</i> et <i>5000</i> caractères au maximum. Un poireau peut posséder au maximum <i>100</i> registres, le stockage dans un nouveau registre ne fonctionnera pas si tous les registres\nsont déjà occupés.",
	"func_setRegister_arg_1": "La clé du registre où stocker la valeur.",
	"func_setRegister_arg_2": "La valeur à stocker.",
	"func_setRegister_return": "<i>true</i> si l'opération s'est bien passée, <i>false</i> sinon.",
	"func_setWeapon": "Équipe l'arme <b>weapon</b> sur votre entité.",
	"func_setWeapon_arg_1": "Id de l'arme à équiper.",
	"func_shift": "Supprime la première case du tableau <b>array</b> et renvoie l'élément correspondant.",
	"func_shift_arg_1": "Le tableau dont la première case sera supprimée.",
	"func_shift_return": "L'élément qui était dans l'ancienne première case.",
	"func_show": "Montre aux joueurs une cellule <b>cell</b> de la couleur <b>color</b> sur le terrain pour 1 tour. L'utilisation de cette fonction coûte 1PT.",
	"func_show_arg_1": "La cellule à montrer",
	"func_show_arg_2": "Couleur du marquage",
	"func_show_return": "Retourne true si tout s'est bien déroulé",
	"func_shuffle": "Mélange un tableau de manière aléatoire.",
	"func_shuffle_arg_1": "Le tableau à mélanger.",
	"func_signum": "Détermine le signe du nombre <b>number</b>.",
	"func_signum_arg_1": "Le nombre dont le signe sera déterminé.",
	"func_signum_return": "1 si le nombre est positif, 0 si le nombre est nul et -1 si le nombre est négatif.",
	"func_sin": "Calcule le sinus de l'angle <b>angle</b> passé en paramètre",
	"func_sin_arg_1": "L'angle dont le sinus sera calculé",
	"func_sin_return": "Retourne le sinus de l'angle <b>angle</b>",
	"func_sort": "Trie le tableau <b>array</b> par ordre croissant ou décroissant. L'ordre croissant par défaut est :\n<ul>\n\t<li>Tous les booléens (d'abord faux puis vrais)</li>\n\t<li>Tous les nombres (classés du plus petit au plus grand)</li>\n\t<li>Toutes les chaînes (dans l'ordre alphabétique)</li>\n\t<li>Tous les éléments null.</li>\n</ul>",
	"func_sort_arg_1": "Tableau à trier.",
	"func_sort_arg_2": "#SORT_ASC pour trier <b>array</b> dans l'ordre croissant ou #SORT_DESC pour le trier dans l'ordre décroissant.",
	"func_split": "Découpe la chaîne <b>string</b> sous-chaînes en délimitées par <b>delimiter</b>.",
	"func_split_arg_1": "Chaîne à découper.",
	"func_split_arg_2": "Chaîne délimitant le passage d'un élément à un autre.",
	"func_split_arg_3": "Nombre d'éléments maximum du tableau.",
	"func_split_return": "Tableau contenant les sous-chaînes trouvées.",
	"func_sqrt": "Calcule la racine carrée du nombre <b>number</b>.",
	"func_sqrt_arg_1": "Nombre dont la racine sera calculée.",
	"func_sqrt_return": "Racine carrée de <b>number</b>.",
	"func_startsWith": "Vérifie si la chaîne <b>string</b> commence par la chaîne <b>prefix</b>.",
	"func_startsWith_arg_1": "Chaîne de recherche.",
	"func_startsWith_arg_2": "Préfixe recherché.",
	"func_startsWith_return": "Vrai si la <b>string</b> commence par <b>prefix</b>.",
	"func_string": "Convertit une valeur en chaîne de caractères.<br>\nSi la valeur est déjà une chaîne, elle est renvoyée.<br>\nSi la valeur est nombre x, \"x\" est renvoyé.<br>\nSi la valeur est un tableau, une chaîne sous la forme \"[clé1 : valeur1, clé2 : valeur2, ...]\" est renvoyée.<br>\nSi la valeur est un booléen, \"true\" et \"false\" sont respectivement renvoyées pour les valeurs true et false.<br>\nSi la valeur est null, \"null\" est renvoyée.",
	"func_string_arg_1": "La valeur à convertir en chaîne de caractères.",
	"func_string_return": "La chaîne convertie.",
	"func_subArray": "Retourne un sous-tableau de <b>array</b> commençant à la position <b>start</b> et finissant à la position <b>end</b>.",
	"func_subArray_arg_1": "Tableau source.",
	"func_subArray_arg_2": "Indice de départ.",
	"func_subArray_arg_3": "Indice de fin.",
	"func_subArray_return": "Sous-tableau.",
	"func_substring": "Retourne la sous-chaîne de la chaîne <b>string</b> à partir du caractère à l'indice <b>start</b> et de taille <b>length</b>.",
	"func_substring_arg_1": "Chaîne à découper.",
	"func_substring_arg_2": "Indice du caractère de départ.",
	"func_substring_arg_3": "Longueur de la sous-chaîne.",
	"func_substring_return": "Sous-chaîne.",
	"func_sum": "Retourne la somme de tous les éléments numériques du tableau <b>array</b>.",
	"func_sum_arg_1": "Tableau à sommer.",
	"func_summon": "Invoque une entité déterminée par la puce <b>chip</b> sur la cellule <b>cell</b> ayant pour IA la fonction <b>ai</b>.",
	"func_summon_arg_1": "La puce utilisée pour l'invocation. La puce doit être une puce de type invocation et doit être équipée sur l'entité qui utilise la fonction summon.",
	"func_summon_arg_2": "La cellule ou l'invocation doit apparaître.",
	"func_summon_arg_3": "L'IA de l'invocation, sous la forme d'une fonction.",
	"func_summon_return": "La fonction summon a le même retour que la fonction #useChip.",
	"func_sum_return": "Somme des éléments du tableau.",
	"func_tan": "Calcule la tangente de l'angle <b>angle</b> (en radians).",
	"func_tan_arg_1": "Angle en radians.",
	"func_tan_return": "Tangente de <b>angle</b>.",
	"function_category_array": "Listes",
	"function_category_chip": "Puces",
	"function_category_color": "Couleur",
	"function_category_fight": "Combat",
	"function_category_leek": "Poireaux",
	"function_category_map": "Tables",
	"function_category_field": "Terrain",
	"function_category_math": "Nombres",
	"function_category_network": "Réseau",
	"function_category_string": "Chaînes",
	"function_category_utils": "Utilitaires",
	"function_category_weapon": "Armes",
	"function_category_set": "Ensembles",
	"function_category_interval": "Intervalles",
	"func_toDegrees": "Convertit l'angle <b>radians</b> en degrés.",
	"func_toDegrees_arg_1": "Valeur en radians",
	"func_toDegrees_return": "Angle en degrés.",
	"func_toLower": "Convertit les caractères majuscules de la chaîne <b>string</b> en minuscules.",
	"func_toLower_arg_1": "Chaîne à transformer",
	"func_toLower_return": "Chaîne en minuscules",
	"func_toRadians": "Convertit l'angle <b>degrees</b> en radians.",
	"func_toRadians_arg_1": "Valeur en degrés",
	"func_toRadians_return": "Angle en radians.",
	"func_toUpper": "Convertit les caractères minuscules de la chaîne <b>string</b> par des majuscules.",
	"func_toUpper_arg_1": "Chaîne à transformer.",
	"func_toUpper_return": "Chaîne en majuscules.",
	"func_typeOf": "Renvoie le type de la valeur <b>value</b>, parmis les types : #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "La valeur dont le type sera retourné.",
	"func_typeOf_return": "Le type de <b>value</b>.",
	"func_unshift": "Ajoute l'élément <b>element</b> au début du tableau <b>array</b>.",
	"func_unshift_arg_1": "Tableau dans lequel l'élément sera ajouté.",
	"func_unshift_arg_2": "Element à ajouter.",
	"func_useChip": "Utilise le chip <b>chip</b> sur l'entité <b>entity</b>.",
	"func_useChip_arg_1": "Chip à utiliser.",
	"func_useChip_arg_2": "Entité cible.",
	"func_useChipOnCell": "Utilise le chip <b>chip</b> sur la cellule <b>cell</b>.",
	"func_useChipOnCell_arg_1": "Chip à utiliser.",
	"func_useChipOnCell_arg_2": "Cellule cible.",
	"func_useChipOnCell_return": "Une valeur supérieure à 0 si l'attaque a été lancée.",
	"func_useChip_return": "Les valeurs de retour de useChip sont :<br>\n<ul>\n\t<li>#USE_CRITICAL, en cas de coup critique</li>\n\t<li>#USE_SUCCESS, en cas de réussite</li>\n\t<li>#USE_FAILED, en cas de d'échec</li>\n\t<li>#USE_INVALID_TARGET, si la cible n'existe pas</li>\n\t<li>#USE_NOT_ENOUGH_TP, si votre entité n'a pas assez de TP</li>\n\t<li>#USE_INVALID_COOLDOWN, si la puce n'est pas encore utilisable</li>\n\t<li>#USE_INVALID_POSITION, si la portée est mauvaise ou la ligne de vue n'est pas dégagée</li>\n</ul>",
	"func_useWeapon": "Utilise l'arme sélectionnée sur l'entité <b>entity</b>.",
	"func_useWeapon_arg_1": "Entité ciblée.",
	"func_useWeaponOnCell": "Utilise l'arme sélectionnée sur la cellule <b>cell</b>.",
	"func_useWeaponOnCell_arg_1": "Cellule ciblée.",
	"func_useWeaponOnCell_return": "Une valeur supérieure à 0 si l'attaque a été lancée.",
	"func_useWeapon_return": "Les valeurs de retour de useWeapon sont :<br>\n<ul>\n\t<li>#USE_CRITICAL, en cas de coup critique</li>\n\t<li>#USE_SUCCESS, en cas de réussite</li>\n\t<li>#USE_FAILED, en cas de d'échec</li>\n\t<li>#USE_INVALID_TARGET, si la cible n'existe pas</li>\n\t<li>#USE_NOT_ENOUGH_TP, si votre entité n'a pas assez de TP</li>\n\t<li>#USE_INVALID_POSITION, si la portée est mauvaise ou la ligne de vue n'est pas dégagée</li>\n</ul>",
	"func_weaponNeedLos": "Renvoie si l'arme <b>weapon</b> a besoin d'une ligne de vue pour tirer.",
	"func_weaponNeedLos_arg_1": "L'id de l'arme à tester.",
	"func_weaponNeedLos_return": "<i>true</i> si l'arme <b>weapon</b> a besoin d'une ligne de vue pour tirer, <i>false</i> sinon.",
	"items": "Items",
	"max_cores": "max",
	"max_level": "max",
	"min_cores": "Coeurs min",
	"min_level": "Level min",
	"n_operations": "<b>{0}</b> opérations",
	"parameters": "Paramètres",
	"return": "Retour",
	"value": "Valeur",
	"variable_operations": "Opérations <b>variables</b>"
}

	i18n.mergeLocaleMessage("fr", { "doc": messages })
	