import { render, staticRenderFns } from "./type.vue?vue&type=template&id=6e15c8e4&scoped=true"
import script from "./type.vue?vue&type=script&lang=ts"
export * from "./type.vue?vue&type=script&lang=ts"
import style0 from "./type.vue?vue&type=style&index=0&id=6e15c8e4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e15c8e4",
  null
  
)

export default component.exports