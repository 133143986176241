
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> operação",
	"arg_type_-1": "?",
	"arg_type_1": "número",
	"arg_type_2": "corrente",
	"arg_type_3": "boleano",
	"arg_type_4": "lista",
	"arg_type_41": "lista<número>",
	"arg_type_42": "lista<string>",
	"arg_type_43": "lista<booleano>",
	"arg_type_44": "lista<lista>",
	"arg_type_46": "lista<inteiro>",
	"arg_type_47": "lista<real>",
	"arg_type_5": "função",
	"arg_type_6": "inteiro",
	"arg_type_7": "real",
	"arg_type_8": "mesa",
	"arg_type_806": "tabela<?, inteiro>",
	"complexity": "Complexidade {c}",
	"operations": "{o} operações",
	"const_AREA_CIRCLE_1": "Área circular 3 quadrados de diâmetro (cruz).",
	"const_AREA_CIRCLE_2": "Área circular 5 quadrados de diâmetro.",
	"const_AREA_CIRCLE_3": "Área circular 7 quadrados de diâmetro.",
	"const_AREA_LASER_LINE": "Zona de um laser, linha do alcance mínimo do laser ao seu alcance máximo ou um obstáculo.",
	"const_AREA_POINT": "Área composta por uma única caixa.",
	"const_CELL_EMPTY": "Valor de retorno de getCellContent(cell) para uma célula vazia.",
	"const_CELL_OBSTACLE": "Valor de retorno de getCellContent(cell) para uma célula contendo um obstáculo.",
	"const_CELL_PLAYER": "Valor de retorno de getCellContent(cell) para uma célula que contém uma entidade.",
	"const_CELL_ENTITY": "Valor de retorno de getCellContent(cell) para uma célula que contém uma entidade.",
	"const_COLOR_BLUE": "Cor azul.",
	"const_COLOR_GREEN": "Cor verde.",
	"const_COLOR_RED": "Cor vermelha.",
	"const_E": "Número de Euler.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Concede escudo absoluto a uma entidade, reduzindo a quantidade de pontos de vida removidos por dano (EFFECT_DAMAGE) em um valor fixo. Amplificado por resistência.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Remove o escudo absoluto de uma entidade. Não é amplificado por uma característica. Permite aumentar os pontos de vida removidos por dano (EFFECT_DAMAGE) em um valor absoluto.",
	"const_EFFECT_AFTEREFFECT": "Remove pontos de vida de uma entidade. Amplificado pela ciência. Reduz os pontos de vida máximos em 5% da quantidade de pontos de vida removidos.",
	"const_EFFECT_ANTIDOTE": "Remove todo veneno (EFFECT_POISON) presente em um alvo.",
	"const_EFFECT_BOOST_MAX_LIFE": "Aumenta os pontos de vida e os pontos de vida máximos de uma entidade. Amplificado pela sabedoria.",
	"const_EFFECT_BUFF_AGILITY": "Concede agilidade a uma entidade. Amplificado pela ciência.",
	"const_EFFECT_BUFF_MP": "Concede pontos de movimento a uma entidade. Amplificado pela ciência.",
	"const_EFFECT_BUFF_RESISTANCE": "Concede resistência a uma entidade. Amplificado pela ciência.",
	"const_EFFECT_BUFF_STRENGTH": "Concede força a uma entidade. Amplificado pela ciência.",
	"const_EFFECT_BUFF_TP": "Concede pontos de ação a uma entidade. Amplificado pela ciência.",
	"const_EFFECT_BUFF_WISDOM": "Conceder sabedoria a uma entidade. Amplificado pela ciência.",
	"const_EFFECT_DAMAGE": "Remove pontos de vida de uma entidade. Força Amplificada. Interage com escudos (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), roubo de vida (exceto caster) e retorno de dano (EFFECT_DAMAGE_RETURN). Reduz os pontos de vida máximos em 5% da quantidade de pontos de vida removidos.",
	"const_EFFECT_DAMAGE_RETURN": "Fornece reflexão de dano a uma entidade, permitindo remover pontos de vida de entidades que infligem danos ao destinatário. Amplificado pela agilidade. Reduz os pontos de vida máximos em 5% da quantidade de pontos de vida removidos.",
	"const_EFFECT_DEBUFF": "Reduz o valor de todos os efeitos em uma entidade em uma porcentagem.",
	"const_EFFECT_HEAL": "Restaura os pontos de vida de uma entidade, limitados pelos pontos de vida máximos. Amplificado pela sabedoria.",
	"const_EFFECT_INVERT": "Troca a posição do lançador pela de uma entidade.",
	"const_EFFECT_KILL": "Remove todos os pontos de vida de uma entidade.",
	"const_EFFECT_LIFE_DAMAGE": "Remove pontos de vida de uma entidade, com base em uma porcentagem dos pontos de vida do lançador. Interage com escudos (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) e retorno de dano (EFFECT_DAMAGE_RETURN). Reduz os pontos de vida máximos em 5% da quantidade de pontos de vida removidos.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "O efeito é multiplicado pelo número de entidades afetadas na área.",
	"const_EFFECT_MODIFIER_ON_CASTER": "O efeito ainda afeta o lançador.",
	"const_EFFECT_MODIFIER_STACKABLE": "O efeito é cumulativo.",
	"const_EFFECT_NOVA_DAMAGE": "Remove pontos de vida máximos. Amplificado pela ciência.",
	"const_EFFECT_POISON": "Remove pontos de vida de uma entidade. Amplificado por magia. Reduz os pontos de vida máximos em 10% da quantidade de pontos de vida removidos.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Concede escudo absoluto a uma entidade, reduzindo a quantidade de pontos de vida removidos por dano (EFFECT_DAMAGE) em um valor fixo. Não amplificável.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Concede agilidade a uma entidade. Não amplificável.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Concede magia a uma entidade. Não amplificável.",
	"const_EFFECT_RAW_BUFF_MP": "Concede pontos de movimento a uma entidade. Não amplificável.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Concede ciência a uma entidade. Não amplificável.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Concede força a uma entidade. Não amplificável.",
	"const_EFFECT_RAW_BUFF_TP": "Concede pontos de ação a uma entidade. Não amplificável.",
	"const_EFFECT_RELATIVE_SHIELD": "Fornece um escudo relativo, reduzindo a quantidade de pontos de vida removidos por dano (EFFECT_DAMAGE) em uma quantidade relativa. Amplificado por resistência.",
	"const_EFFECT_RESURRECT": "Ressuscita uma entidade, com um número máximo de HP igual à metade do número máximo de HP da entidade antes da ressurreição e um número atual de HP igual a um quarto do número máximo de HP antes da ressurreição.",
	"const_EFFECT_SHACKLE_MAGIC": "Remova a magia de uma entidade. Amplificado por magia.",
	"const_EFFECT_SHACKLE_MP": "Remova pontos de movimento de uma entidade. Amplificado por magia.",
	"const_EFFECT_SHACKLE_STRENGTH": "Remove a força de uma entidade. Amplificado por magia.",
	"const_EFFECT_SHACKLE_TP": "Deduza pontos de ação de uma entidade. Amplificado por magia.",
	"const_EFFECT_SUMMON": "Invoca uma lâmpada. Nenhum efeito se o limite de convocação da equipe for atingido.",
	"const_EFFECT_TARGET_ALLIES": "Afeta aliados.",
	"const_EFFECT_TARGET_CASTER": "Afeta o lançador.",
	"const_EFFECT_TARGET_ENEMIES": "Afeta os inimigos.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Afeta entidades não invocadas (Leeks e torres).",
	"const_EFFECT_TARGET_SUMMONS": "Afeta entidades invocadas (Lâmpadas).",
	"const_EFFECT_TELEPORT": "Altera a posição do lançador.",
	"const_EFFECT_VULNERABILITY": "Remove o escudo relativo a uma entidade. Não é amplificado por uma característica. Aumenta os pontos de vida removidos por dano (EFFECT_DAMAGE) em uma quantidade relativa.",
	"const_ENTITY_BULB": "Refere-se a uma entidade do tipo Bulb.",
	"const_ENTITY_LEEK": "Refere-se a uma entidade do tipo Leek.",
	"const_ENTITY_TURRET": "Refere-se a uma entidade do tipo Turret.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Fundo de combate tipo desafio.",
	"const_FIGHT_CONTEXT_GARDEN": "Contexto de luta na horta.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Contexto de combate em Battle Royale.",
	"const_FIGHT_CONTEXT_TEST": "Teste o contexto da batalha.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Fundo de luta de torneio.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Lute no Battle Royale.",
	"const_FIGHT_TYPE_FARMER": "Luta de criadores.",
	"const_FIGHT_TYPE_SOLO": "Luta solo.",
	"const_FIGHT_TYPE_TEAM": "Luta em equipe.\n",
	"const_INSTRUCTIONS_LIMIT": "Número máximo de instruções que uma entidade pode usar durante seu turno.",
	"const_MAX_TURNS": "Número máximo de turnos em uma luta.",
	"const_OPERATIONS_LIMIT": "Número máximo de operações que uma entidade pode usar durante seu turno.",
	"const_PI": "A razão entre a circunferência de um círculo e seu diâmetro.",
	"const_SORT_ASC": "Diz à função #sort para classificar em ordem crescente.",
	"const_SORT_DESC": "Diz à função #sort para classificar em ordem decrescente.",
	"const_TYPE_ARRAY": "Tipo de valor <b>lista</b>",
	"const_TYPE_BOOLEAN": "Tipo de valor <b>booleano</b>",
	"const_TYPE_FUNCTION": "Tipo de valor <b>função</b>",
	"const_TYPE_NULL": "Tipo de valor <b>nulo</b>",
	"const_TYPE_NUMBER": "Tipo de valor <b>número</b>",
	"const_TYPE_STRING": "Tipo de valor <b>cadeia de caracteres</b>",
	"const_USE_CRITICAL": "Valor retornado pelas funções #useWeapon, #useWeaponOnCell, #useChip e #useChipOnCell em caso de acerto crítico.",
	"const_USE_FAILED": "Valor retornado pelas funções #useWeapon, #useWeaponOnCell, #useChip e #useChipOnCell em caso de falha.",
	"const_USE_INVALID_COOLDOWN": "Valor retornado pelas funções #useChip e #useChipOnCell caso o chip ainda não seja utilizável.",
	"const_USE_INVALID_POSITION": "Valor retornado pelas funções #useWeapon, #useWeaponOnCell, #useChip e #useChipOnCell se o alcance for ruim ou a linha de visão não estiver clara.",
	"const_USE_INVALID_TARGET": "Valor retornado pelas funções #useWeapon e #useChip caso o alvo não exista.",
	"const_USE_NOT_ENOUGH_TP": "Valor retornado pelas funções #useWeapon, #useWeaponOnCell, #useChip e #useChipOnCell caso o lançador não tenha pontos de ação suficientes para utilizar o objeto.",
	"const_USE_RESURRECT_INVALID_ENTITY": "Valor retornado pela função #resurrect quando a entidade especificada não existe ou ainda não está morta.",
	"const_USE_SUCCESS": "Valor retornado pelas funções #useWeapon, #useWeaponOnCell, #useChip e #useChipOnCell em caso de sucesso.",
	"const_USE_TOO_MANY_SUMMONS": "Erro retornado por #summon quando você já tem <b>8</b> invocações ao vivo.\n",
	"func_abs": "Retorna o valor absoluto do número <b>número</b>.",
	"func_abs_arg_1": "O número cujo valor absoluto será calculado.",
	"func_abs_return": "O valor absoluto do número.",
	"func_acos": "Calcula o arco cosseno do <b>argumento</b>, no intervalo [0, #PI].",
	"func_acos_arg_1": "Número cujo arco-cosseno será calculado.",
	"func_acos_return": "O arco cosseno do <b>argumento</b>.",
	"func_arrayConcat": "Adicione as duas matrizes de ponta a ponta. As chaves de texto são preservadas e todas as chaves numéricas são reindexadas.",
	"func_arrayConcat_arg_1": "Primeira mesa.",
	"func_arrayConcat_arg_2": "Segunda mesa.",
	"func_arrayConcat_return": "Soma das duas matrizes.",
	"func_arrayFilter": "Retorna uma nova matriz contendo todos os pares chave/valor da matriz de origem para a qual a função de retorno de chamada retornou verdadeiro. Se a função callback receber um parâmetro, o valor do array fonte será enviado, se receber dois parâmetros, a chave e o valor serão enviados.",
	"func_arrayFilter_arg_1": "Mesa original.",
	"func_arrayFilter_arg_2": "Função chamada para cada elemento.",
	"func_arrayFilter_return": "Nova mesa.",
	"func_arrayFlatten": "Retorna uma nova matriz contendo todos os elementos da matriz de origem. Todos os elementos contidos em um subarray são extraídos para o novo array.O argumento depth determina a profundidade máxima do subarray a ser extraído.\n<código>var array = [1, 2, [[3, 4], 5], [6]];\ndebug(arrayFlatten(array, 1)); // [1,2,[3,4],5,6]</código>",
	"func_arrayFlatten_arg_1": "Mesa original.",
	"func_arrayFlatten_arg_2": "Profundidade máxima.",
	"func_arrayFlatten_return": "Nova mesa.",
	"func_arrayFoldLeft": "Reduza o array <b>array</b> [v1, v2, ..., vn] da esquerda a partir do valor <b>v0</b> e aplicando a função <b>f</b >. É igual a :\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "Mesa original.",
	"func_arrayFoldLeft_arg_2": "Função a aplicar.",
	"func_arrayFoldLeft_arg_3": "Valor inicial.",
	"func_arrayFoldLeft_return": "Tabela reduzida.",
	"func_arrayFoldRight": "Reduza o array <b>array</b> [v1, v2, ..., vn] da direita começando pelo valor <b>v0</b> e aplicando a função <b>f</b >. É igual a :\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "Mesa original.",
	"func_arrayFoldRight_arg_2": "Função a aplicar.",
	"func_arrayFoldRight_arg_3": "Valor inicial.",
	"func_arrayFoldRight_return": "Tabela reduzida.",
	"func_arrayIter": "Chama a função de retorno de chamada para cada elemento da matriz. Se a função callback receber um parâmetro, o valor do array fonte será enviado, se receber dois parâmetros, a chave e o valor serão enviados.",
	"func_arrayIter_arg_1": "Mesa original.",
	"func_arrayIter_arg_2": "Função chamada para cada elemento.",
	"func_arrayMap": "Retorna um novo array contendo para cada chave do array fonte, o valor retornado pela função callback. Se a função callback receber um parâmetro, o valor do array fonte será enviado, se receber dois parâmetros, a chave e o valor serão enviados.",
	"func_arrayMap_arg_1": "Mesa original.",
	"func_arrayMap_arg_2": "Função chamada para cada elemento.",
	"func_arrayMap_return": "Nova mesa.",
	"func_arrayMax": "Retorna o elemento de valor máximo do array <b>array</b>.<br/>Para mais informações sobre o valor máximo de um array veja #sort.",
	"func_arrayMax_arg_1": "Array em que se buscará o valor máximo.",
	"func_arrayMax_return": "Item de valor máximo.",
	"func_arrayMin": "Retorna o elemento de valor mínimo do array <b>array</b>.<br/>Para mais informações sobre o valor mínimo de um array veja #sort.",
	"func_arrayMin_arg_1": "Array em que se buscará o valor mínimo.",
	"func_arrayMin_return": "Item de valor mínimo.",
	"func_arrayPartition": "Retorna um novo array contendo duas listas, na primeira estão todos os pares chave/valor para os quais a função callback retornou true, na segunda estão todos os outros. Se a função callback receber um parâmetro, o valor do array fonte será enviado, se receber dois parâmetros, a chave e o valor serão enviados.",
	"func_arrayPartition_arg_1": "Mesa original.",
	"func_arrayPartition_arg_2": "Função chamada para cada elemento.",
	"func_arrayPartition_return": "Nova mesa.",
	"func_arraySort": "Classifica o array de acordo com a ordem definida pela função callback. Os elementos são comparados dois a dois, a função callback deve retornar os valores -1, 0 ou 1 dependendo se o primeiro valor está antes, no mesmo nível ou depois do segundo valor. Se a função callback leva 2 parâmetros, os dois valores são enviados, se leva 4, os pares chave/valor são enviados.",
	"func_arraySort_arg_1": "Mesa original",
	"func_arraySort_arg_2": "Função de classificação.",
	"func_arraySort_return": "A matriz classificada",
	"func_asin": "Calcula o arco-seno do <b>argumento</b>, no intervalo [0, #PI].",
	"func_asin_arg_1": "Número cujo arco-seno será calculado.",
	"func_asin_return": "O arco-seno do <b>argumento</b>.",
	"func_assocSort": "Classifica a matriz <b>array</b> preservando a associação chave:valor de acordo com a ordem <b>ordem</b>.",
	"func_assocSort_arg_1": "A matriz a ser classificada.",
	"func_assocSort_arg_2": "A ordem de classificação: #SORT_ASC ou #SORT_DESC.",
	"func_atan": "Calcula o arco tangente do <b>argumento</b>, no intervalo [0, #PI].",
	"func_atan2": "Converte coordenadas cartesianas (<b>x</b>, <b>y</b>) em coordenadas polares (<b>r</b>, <b>theta</b>). Esta função retorna o ângulo <b>theta</b> entre -#PI e #PI usando os sinais dos argumentos.",
	"func_atan2_arg_1": "Coordenada y.",
	"func_atan2_arg_2": "Coordenada x.",
	"func_atan2_return": "O ângulo <b>teta</b> em coordenadas polares do ponto (x, y).",
	"func_atan_arg_1": "Número cujo arco tangente será calculado.",
	"func_atan_return": "O arco tangente do <b>argumento</b>.",
	"func_average": "Calcula a média dos elementos contidos no array <b>array</b>.",
	"func_average_arg_1": "Array cuja média queremos calcular.",
	"func_average_return": "Valor médio.",
	"func_canUseChip": "Determina se sua entidade pode usar o chip <b>chip</b> na entidade com id <b>entidade</b>.",
	"func_canUseChip_arg_1": "O número do chip a ser testado.",
	"func_canUseChip_arg_2": "O ID da entidade na qual você deseja usar o chip.",
	"func_canUseChip_return": "<i>true</i> se sua entidade pode usar o chip, <i>false</i> caso contrário.",
	"func_canUseChipOnCell": "Determina se sua entidade pode usar o chip <b>chip</b> na célula <b>cell</b>.",
	"func_canUseChipOnCell_arg_1": "O número do chip a ser testado.",
	"func_canUseChipOnCell_arg_2": "O número do celular no qual você deseja usar o chip.",
	"func_canUseChipOnCell_return": "<i>true</i> se sua entidade pode usar o chip, <i>false</i> caso contrário.",
	"func_canUseWeapon": "Determina se sua entidade pode atirar na entidade com id <b>entity</b> com a arma <b>weapon</b>.",
	"func_canUseWeapon_arg_1": "A arma para testar. O padrão é sua arma atualmente equipada.",
	"func_canUseWeapon_arg_2": "O id da entidade na qual você deseja disparar.",
	"func_canUseWeapon_return": "<i>true</i> se sua entidade pode disparar, <i>false</i> caso contrário.",
	"func_canUseWeaponOnCell": "Determina se sua entidade pode atirar na célula <b>cell</b> com a arma <b>weapon</b>.",
	"func_canUseWeaponOnCell_arg_1": "A arma para testar. O padrão é sua arma atualmente equipada.",
	"func_canUseWeaponOnCell_arg_2": "O número da célula que você deseja fotografar.",
	"func_canUseWeaponOnCell_return": "<i>true</i> se sua entidade pode disparar, <i>false</i> caso contrário.",
	"func_cbrt": "Calcule a raiz cúbica de <b>número</b>",
	"func_cbrt_arg_1": "Número cuja raiz cúbica queremos calcular.",
	"func_cbrt_return": "Raiz cúbica de <b>número</b>.",
	"func_ceil": "Retorna o arredondamento de <b>número</b>.<br/>Você pode encontrar o arredondamento para baixo com #floor e o arredondamento com #round.",
	"func_ceil_arg_1": "Número para o qual queremos encontrar o arredondamento superior.",
	"func_ceil_return": "Arredonde <b>número</b> para cima.",
	"func_charAt": "Retorna o caractere da string <b>string</b> localizado na posição <b>posição</b>.<br/>O primeiro caractere da string está na posição 0.",
	"func_charAt_arg_1": "String da qual queremos recuperar um caractere.",
	"func_charAt_arg_2": "Posição do personagem a encontrar.",
	"func_charAt_return": "string contendo o caractere procurado ou uma string vazia se a posição for inválida.",
	"func_chipNeedLos": "Retorna se o <b>chip</b> precisa de linha de visão para ser usado.",
	"func_chipNeedLos_arg_1": "O id do chip a ser testado.",
	"func_chipNeedLos_return": "<i>true</i> se o <b>chip</b> precisar de linha de visão para ser usado, <i>false</i> caso contrário.",
	"func_clearMarks": "Limpa todas as marcações feitas por mark() e markText() no campo.",
	"func_clone": "Copia o <b>valor</b> passado como parâmetro nos níveis <b>nível</b> e retorna a cópia. No caso de uma matriz, por exemplo, um clone(array, 1) copiará a matriz, mas não seus elementos, um clone(array, 2) copiará a matriz e todos os elementos.",
	"func_clone_arg_1": "O valor para clonar",
	"func_clone_arg_2": "O número de níveis para clonar",
	"func_clone_return": "O valor clonado",
	"func_contains": "Determina se uma string <b>search</b> está dentro de uma string <b>string</b>.",
	"func_contains_arg_1": "String na qual a pesquisa será realizada.",
	"func_contains_arg_2": "String a ser pesquisada.",
	"func_contains_return": "true se <b>search</b> estiver em <b>string</b>, false caso contrário.",
	"func_cos": "Calcula o cosseno do <b>ângulo</b>.",
	"func_cos_arg_1": "Número cujo cosseno será calculado (em radianos).",
	"func_cos_return": "O cosseno do <b>ângulo</b>, no intervalo [-1, 1].",
	"func_count": "Conta o número de elementos no array <b>array</b>.",
	"func_count_arg_1": "Array cujo número de elementos será contado.",
	"func_count_return": "Número de elementos da matriz.",
	"func_debug": "Registra uma mensagem de <b>objeto</b> no log pessoal, disponível no relatório ao final da luta.",
	"func_debug_arg_1": "A mensagem a ser gravada.",
	"func_debugC": "Registra uma mensagem de <b>objeto</b> no log pessoal, disponível no relatório ao final da luta, da cor <b>cor</b>.",
	"func_debugC_arg_1": "A mensagem a ser gravada.",
	"func_debugC_arg_2": "A cor da mensagem. Você pode usar a função #getColor.",
	"func_debugE": "Registra uma mensagem de erro de <b>objeto</b> no log pessoal, disponível no relatório ao final da luta.<br />As mensagens de erro aparecem em vermelho no relatório de luta.",
	"func_debugE_arg_1": "A mensagem de erro a ser registrada.",
	"func_debugW": "Registra uma mensagem de alerta de <b>objeto</b> no log pessoal, disponível no relatório ao final da luta. <br />Mensagens de advertência aparecem em laranja no relatório de combate.",
	"func_debugW_arg_1": "A mensagem de aviso a ser gravada.",
	"func_deleteRegister": "Exclui o registro associado à chave <b>chave</b>, se existir.",
	"func_deleteRegister_arg_1": "A chave de registro a ser excluída.",
	"func_endsWith": "Determina se uma <b>string</b> termina com uma string <b>sufixo</b>.",
	"func_endsWith_arg_1": "String na qual a pesquisa será realizada",
	"func_endsWith_arg_2": "Sufixo para pesquisar",
	"func_endsWith_return": "verdadeiro se <b>string</b> terminar com <b>sufixo</b>",
	"func_exp": "Eleva o número #E de Euler à potência de <b>número</b>.",
	"func_exp_arg_1": "O expoente ao qual #E será elevado.",
	"func_exp_return": "#E** <b>número</b>.",
	"func_fill": "Redimensiona o array <b>array</b> para <b>tamanho</b> e preenche todas as suas caixas com <b>valor</b>.",
	"func_fill_arg_1": "A tabela para completar.",
	"func_fill_arg_2": "O valor para cada quadrado na matriz.",
	"func_fill_arg_3": "O número de caixas. Por padrão, o tamanho atual da matriz.",
	"func_floor": "Calcula o arredondamento de <b>número</b>.<br/>Você pode encontrar o arredondamento com #ceil e o arredondamento com #round.",
	"func_floor_arg_1": "Número cujo arredondamento queremos encontrar.",
	"func_floor_return": "Arredondado para <b>número</b>.",
	"func_getAbsoluteShield": "Retorna o escudo absoluto da entidade com id <b>entidade</b>. Para recuperar diretamente o escudo absoluto da sua entidade, use #getAbsoluteShield() sem parâmetros.",
	"func_getAbsoluteShield_arg_1": "O id da entidade cujo escudo absoluto será retornado.",
	"func_getAbsoluteShield_return": "O escudo absoluto da entidade <b>entidade</b>.",
	"func_getAgility": "Retorna a agilidade da entidade com id <b>entity</b>. Para recuperar diretamente a agilidade da sua entidade, use #getAgility() sem parâmetros.",
	"func_getAgility_arg_1": "O id da entidade cuja agilidade será retornada.",
	"func_getAgility_return": "A agilidade da entidade <b>entidade</b>.",
	"func_getAIID": "Retorna o AI id da entidade <b>entity</b>. Use getAIID sem parâmetro para recuperar o id do seu AI.",
	"func_getAIID_arg_1": "O id da entidade cujo ID AI será retornado.",
	"func_getAIID_return": "O ID de IA da entidade <b>entidade</b>.",
	"func_getAIName": "Retorna o nome da entidade AI <b>entity</b>. Use getAINam() sem parâmetros para recuperar o nome do seu AI.",
	"func_getAIName_arg_1": "O id da entidade cujo nome AI será retornado.",
	"func_getAIName_return": "O nome da entidade AI da <b>entidade</b>.",
	"func_getAliveAllies": "Retorna uma matriz de todos os seus aliados vivos em combate.",
	"func_getAliveAllies_return": "Uma matriz contendo os ids de todos os seus aliados vivos.",
	"func_getAliveEnemies": "Retorna uma matriz de todos os seus inimigos vivos em combate.",
	"func_getAliveEnemiesCount": "Retorna o número de inimigos vivos na luta.",
	"func_getAliveEnemiesCount_return": "O número de inimigos vivos.",
	"func_getAliveEnemies_return": "Uma matriz contendo os ids de todos os seus inimigos vivos.",
	"func_getAllChips": "Retorna a lista de todas as fichas do jogo.",
	"func_getAllChips_return": "A lista de todas as fichas do jogo.",
	"func_getAllEffects": "Retorna a lista de todos os efeitos no jogo.",
	"func_getAllEffects_return": "A lista de todos os efeitos no jogo.",
	"func_getAllWeapons": "Retorna a lista de todas as armas do jogo.",
	"func_getAllWeapons_return": "A lista de todas as armas do jogo.",
	"func_getAlliedTurret": "Retorna o ID da torre de sua equipe ou -1 se não existir.",
	"func_getAlliedTurret_return": "ID da torre da sua equipe.",
	"func_getEnemyTurret": "Retorna o id da torre inimiga ou -1 se não existir.",
	"func_getEnemyTurret_return": "A ID da torre inimiga.",
	"func_getAllies": "Retorna uma matriz contendo seus aliados e sua entidade.",
	"func_getAlliesCount": "Retorna o número de aliados na luta.",
	"func_getAlliesCount_return": "O número de aliados.",
	"func_getAlliesLife": "Retorna a saúde total de seus aliados.",
	"func_getAlliesLife_return": "A saúde total de seus aliados.",
	"func_getAllies_return": "A tabela de aliados e sua entidade.",
	"func_getBirthTurn": "Retorna o turno da luta onde a <b>entidade</b> apareceu. Retorna 1 se for um alho-poró, por exemplo, e 5 se for uma convocação invocada no turno 5.",
	"func_getBirthTurn_arg_1": "O id da entidade cujo turno de spawn será retornado.",
	"func_getBirthTurn_return": "O turno de combate onde a <b>entidade</b> apareceu.",
	"func_getBlue": "Retorna a quantidade de azul na cor <b>color</b>, entre 0 e 255. Por exemplo, getBlue(#COLOR_BLUE) = 255 e getBlue(#COLOR_GREEN) = 0.",
	"func_getBlue_arg_1": "A cor cuja taxa azul será retornada.",
	"func_getBlue_return": "A taxa de azul na cor <b>color</b>",
	"func_getBulbChips": "Retorna a lista de chips que serão equipados em uma lâmpada invocada pelo chip <b>bulb_chip</b>.",
	"func_getBulbChips_arg_1": "A pulga do bulbo.",
	"func_getBulbChips_return": "Os chips de uma lâmpada convocados pelo chip <b>bulb_chip</b>.",
	"func_getCell": "Retorna a célula onde está localizada a entidade com id <b>entity</b>. Use getCell() sem parâmetros para recuperar sua célula.",
	"func_getCell_arg_1": "O id da entidade cuja célula será retornada.",
	"func_getCell_return": "O número da célula onde a <b>entidade</b> está localizada.",
	"func_getCellContent": "Retorna o conteúdo de uma célula com id <b>célula</b>.",
	"func_getCellContent_arg_1": "O id da célula cujo conteúdo será retornado.",
	"func_getCellContent_return": "O conteúdo da célula <b>célula</b>: #CELL_EMPTY para uma célula vazia, #CELL_ENTITY para uma entidade, #CELL_OBSTACLE para um obstáculo.",
	"func_getCellDistance": "Retorna a distância entre duas células <b>célula1</b> e <b>célula2</b>. <br />A distância devolvida é expressa em número de células, e não tem em conta os vários obstáculos entre as duas células.\nPara obter a distância em linha reta, consulte #getDistance e para obter a distância do caminho entre as duas células evitando obstáculos, consulte #getPathLength.",
	"func_getCellDistance_arg_1": "O id da célula inicial.",
	"func_getCellDistance_arg_2": "O id da célula de chegada.",
	"func_getCellDistance_return": "A distância entre as duas células <b>célula1</b> e <b>célula2</b>.",
	"func_getCellFromXY": "Retorna o id da célula na posição (<b>x</b>, <b>y</b>).",
	"func_getCellFromXY_arg_1": "A posição x da célula.",
	"func_getCellFromXY_arg_2": "A posição y da célula.",
	"func_getCellFromXY_return": "O id da célula na posição (<b>x</b>, <b>y</b>), <b>nulo</b> se a célula não existir.",
	"func_getCellsToUseChip": "Retorna a lista de células a partir das quais sua entidade poderá usar o chip <b>chip</b> na entidade <b>entity</b>.",
	"func_getCellsToUseChip_arg_1": "O chip que a entidade quer poder usar.",
	"func_getCellsToUseChip_arg_2": "A entidade alvo.",
	"func_getCellsToUseChip_arg_3": "Matriz de células a serem ignoradas.",
	"func_getCellsToUseChip_return": "Lista de células onde o chip pode ser utilizado.",
	"func_getCellsToUseChipOnCell": "Retorna a lista de células das quais sua entidade poderá usar o chip <b>chip</b> na célula <b>célula</b>.",
	"func_getCellsToUseChipOnCell_arg_1": "O chip que a entidade quer poder usar.",
	"func_getCellsToUseChipOnCell_arg_2": "A célula alvo.",
	"func_getCellsToUseChipOnCell_arg_3": "Matriz de células a serem ignoradas.",
	"func_getCellsToUseChipOnCell_return": "Lista de células onde o chip pode ser utilizado.",
	"func_getCellsToUseWeapon": "Retorna a lista de células a partir das quais sua entidade poderá usar a arma <b>weapon</b> na entidade <b>entity</b>.",
	"func_getCellsToUseWeapon_arg_1": "A arma para testar. O padrão é sua arma atualmente equipada.",
	"func_getCellsToUseWeapon_arg_2": "A entidade alvo.",
	"func_getCellsToUseWeapon_arg_3": "Matriz de células a serem ignoradas. Por padrão, uma matriz vazia.",
	"func_getCellsToUseWeapon_return": "Lista de células a partir das quais a arma pode ser usada.",
	"func_getCellsToUseWeaponOnCell": "Retorna a lista de células a partir das quais sua entidade poderá usar a arma <b>weapon</b> em uma célula <b>cell</b>.",
	"func_getCellsToUseWeaponOnCell_arg_1": "A arma para testar. O padrão é sua arma atualmente equipada.",
	"func_getCellsToUseWeaponOnCell_arg_2": "A célula alvo.",
	"func_getCellsToUseWeaponOnCell_arg_3": "Matriz de células a serem ignoradas. Por padrão, uma matriz vazia.",
	"func_getCellsToUseWeaponOnCell_return": "Lista de células a partir das quais a arma pode ser usada.",
	"func_getCellX": "Determina a posição em X da célula <b>célula</b>.",
	"func_getCellX_arg_1": "A célula cuja posição em X será determinada.",
	"func_getCellX_return": "A posição X da célula.",
	"func_getCellY": "Determina a posição Y da célula <b>célula</b>.",
	"func_getCellY_arg_1": "A célula cuja posição em Y será determinada.",
	"func_getCellY_return": "A posição Y da célula.",
	"func_getChipArea": "Retorna a área do tipo de efeito do <b>chip</b>.",
	"func_getChipArea_arg_1": "O chip cujo tipo de zona será retornado.",
	"func_getChipArea_return": "O tipo de área do chip <b>chip</b> entre as constantes AREA_*:\n<ul>\n<li>#AREA_POINT: área quadrada única</li>\n<li>#AREA_LASER_LINE: linha de um laser</li>\n<li>#AREA_CIRCLE_1: área circular com 3 quadrados de diâmetro</li>\n<li>#AREA_CIRCLE_2: área circular com 5 quadrados de diâmetro</li>\n<li>#AREA_CIRCLE_3: área circular com 7 quadrados de diâmetro</li>\n</ul>",
	"func_getChipCooldown": "Retorna o tempo de recuperação do chip <b>chip</b>, retirado do mercado.",
	"func_getChipCooldown_arg_1": "O chip cujo cooldown será devolvido.",
	"func_getChipCooldown_return": "O tempo de espera do <b>chip</b>.",
	"func_getChipCost": "Retorna o custo em TP do chip <b>chip</b>.",
	"func_getChipCost_arg_1": "O chip cujo custo será devolvido.",
	"func_getChipCost_return": "O custo do <b>chip</b>.",
	"func_getChipEffectiveArea": "Retorna a lista de células que serão afetadas se o chip <b>chip</b> for usado na célula <b>célula</b> de uma célula <b>de</b>.",
	"func_getChipEffectiveArea_arg_1": "O chip a ser testado.",
	"func_getChipEffectiveArea_arg_2": "A célula alvo.",
	"func_getChipEffectiveArea_arg_3": "A célula da qual o chip é usado.",
	"func_getChipEffectiveArea_return": "A matriz contendo os ids de todas as células que serão afetadas.",
	"func_getChipEffects": "Retorna os efeitos do chip <b>chip</b>.",
	"func_getChipEffects_arg_1": "O chip cujos efeitos serão devolvidos.",
	"func_getChipEffects_return": "Os efeitos do chip <b>chip</b>. Mesmo valor de retorno da função #getWeaponEffects.",
	"func_getChipFailure": "Retorna o percentual de risco de falha do chip <b>chip</b>.",
	"func_getChipFailure_arg_1": "O chip cuja porcentagem de falha será retornada.",
	"func_getChipFailure_return": "Porcentagem de falha do chip <b>chip</b>, um número inteiro entre <b>0</b> e <b>100</b>.",
	"func_getChipMaxRange": "Retorna o alcance máximo do chip <b>chip</b>.",
	"func_getChipMaxRange_arg_1": "O chip cujo alcance máximo será retornado.",
	"func_getChipMaxRange_return": "O alcance máximo do <b>chip</b>.",
	"func_getChipMaxScope": "Retorna o alcance máximo do chip <b>chip</b>.",
	"func_getChipMaxScope_arg_1": "O chip cujo alcance máximo será retornado.",
	"func_getChipMaxScope_return": "O alcance máximo do <b>chip</b>.",
	"func_getChipMinRange": "Retorna o alcance mínimo do chip <b>chip</b>.",
	"func_getChipMinRange_arg_1": "O chip cujo alcance mínimo será retornado.",
	"func_getChipMinRange_return": "O alcance mínimo do <b>chip</b>.",
	"func_getChipMinScope": "Retorna o alcance mínimo do chip <b>chip</b>.",
	"func_getChipMinScope_arg_1": "O chip cujo alcance mínimo será retornado.",
	"func_getChipMinScope_return": "O alcance mínimo do <b>chip</b>.",
	"func_getChipLaunchType": "Retorna o modo de inicialização do chip <b>chip</b>, entre as constantes LAUNCH_TYPE_*.",
	"func_getChipLaunchType_arg_1": "O id do chip cujo modo de lançamento será retornado.",
	"func_getChipLaunchType_return": "O modo de lançamento do chip <b>chip</b>.",
	"func_getChipName": "Retorna o nome do chip <b>chip</b>.",
	"func_getChipName_arg_1": "O chip cujo nome será retornado.",
	"func_getChipName_return": "O nome do chip <b>chip</b>.",
	"func_getChips": "Retorna os marcadores da entidade com id <b>entidade</b>.",
	"func_getChips_arg_1": "O id da entidade cujos marcadores serão retornados.",
	"func_getChips_return": "Uma matriz contendo os IDs de marcadores da entidade <b>entity</b>.",
	"func_getChipTargets": "Retorna as entidades que serão afetadas se o chip <b>chip</b> for usado na célula <b>cell</b>.",
	"func_getChipTargets_arg_1": "O chip a ser testado.",
	"func_getChipTargets_arg_2": "A célula alvo.",
	"func_getChipTargets_return": "A matriz contendo os IDs de todas as entidades que serão afetadas.",
	"func_getColor": "Retorna o número inteiro correspondente à cor (<b>vermelho</b>, <b>verde</b>, <b>azul</b>) fornecida como parâmetro.",
	"func_getColor_arg_1": "Valor vermelho entre 0 e 255.",
	"func_getColor_arg_2": "Valor verde entre 0 e 255.",
	"func_getColor_arg_3": "Valor azul entre 0 e 255.",
	"func_getColor_return": "int correspondente à cor fornecida como parâmetro.",
	"func_getCooldown": "Retorna o cooldown atual do <b>chip</b> da entidade <b>entity</b>.",
	"func_getCooldown_arg_1": "O chip cujo cooldown atual será devolvido.",
	"func_getCooldown_arg_2": "A entidade cujo cooldown será retornado.",
	"func_getCooldown_return": "O cooldown atual do chip <b>chip</b>, este é o número de turnos antes que o chip se torne utilizável, <b>0</b> se estiver utilizável no momento.",
	"func_getCores": "Retorna o número de núcleos da entidade com id <b>entity</b>.",
	"func_getCores_arg_1": "A entidade cujo número de núcleos será retornado.",
	"func_getCores_return": "O número de núcleos da entidade <b>entity</b>.",
	"func_getDate": "Retorna a data da luta, no formato dd/MM/aaaa.",
	"func_getDate_return": "A data da luta.",
	"func_getTime": "Retorna o tempo de batalha, no formato HH:mm:ss.",
	"func_getTime_return": "A hora da luta.",
	"func_getTimestamp": "Retorna o timestamp da luta, igual ao número de segundos desde 1º de janeiro de 1970.",
	"func_getTimestamp_return": "O timestamp da luta.",
	"func_getDamageReturn": "Retorna a taxa de retorno de dano da entidade com id <b>entidade</b>.",
	"func_getDamageReturn_arg_1": "A id da entidade da qual a referência de dano será retornada.",
	"func_getDamageReturn_return": "A taxa de retorno de dano da entidade com id <b>entity</b> (em %).",
	"func_getDeadAllies": "Retorna entidades amigas mortas.",
	"func_getDeadAllies_return": "A tabela de entidades mortas aliadas.",
	"func_getDeadEnemies": "Retorna entidades inimigas mortas.",
	"func_getDeadEnemiesCount": "Retorna o número de inimigos que morreram em combate.",
	"func_getDeadEnemiesCount_return": "O número de inimigos mortos.",
	"func_getDeadEnemies_return": "A tabela de entidades inimigas mortas.",
	"func_getDistance": "Calcula a distância em voo de corvo entre duas células <b>célula1</b> e <b>célula2</b>. <br />Para obter a distância em número de células, consulte #getCellDistance, e para obter o comprimento do caminho entre as duas células desviando dos vários obstáculos, consulte #getPathLength.",
	"func_getDistance_arg_1": "A célula inicial.",
	"func_getDistance_arg_2": "A célula de chegada.",
	"func_getDistance_return": "A distância em que o corvo voa entre as duas células.",
	"func_getPassiveEffects": "Retorna a lista de efeitos passivos da entidade com id <b>entity</b>. Para recuperar diretamente a lista de efeitos passivos de sua entidade, use #getPassiveEffects() sem parâmetro.",
	"func_getPassiveEffects_arg_1": "O id da entidade cuja lista de efeitos passivos será retornada.",
	"func_getPassiveEffects_return": "A lista de efeitos passivos atualmente presentes na entidade <b>entidade</b>.\nA lista de efeitos passivos é um array contendo os efeitos.\nUm efeito é em si uma matriz de 7 caixas da forma: [<b>type</b>, <b>value</b>, <b>caster_id</b>, <b>turns</b>, <b>crítico</b>, <b>item_id</b>, <b>target_id</b>]. É a mesma estrutura dos efeitos clássicos retornados pela função #getEffects.",
	"func_getEnemies": "Retorna as entidades inimigas (vivas ou mortas) para o combate.",
	"func_getEnemiesCount": "Retorna o número de inimigos na luta.",
	"func_getEnemiesCount_return": "O número de inimigos.",
	"func_getEnemiesLife": "Calcula a soma dos pontos de vida de todas as entidades inimigas.",
	"func_getEnemiesLife_return": "A soma dos pontos de vida do time inimigo.",
	"func_getEnemies_return": "Uma matriz contendo os IDs de todas as entidades inimigas.",
	"func_getEntityTurnOrder": "Retorna um valor entre 1 e n (número de entidades atualmente em jogo) indicando a posição da <b>entidade</b> na ordem de jogo.",
	"func_getEntityTurnOrder_arg_1": "O id da entidade cuja ordem de reprodução será retornada",
	"func_getEntityTurnOrder_return": "Coloque na ordem de jogo da entidade <b>entidade</b>",
	"func_getFarmerID": "Retorna o ID do criador da entidade <b>entity</b>.",
	"func_getFarmerID_arg_1": "O id da entidade cujo id do criador será retornado.",
	"func_getFarmerID_return": "O id do criador da entidade <b>entidade</b>.",
	"func_getFarmerCountry": "Retorna o país do criador da entidade <b>entity</b>.",
	"func_getFarmerCountry_arg_1": "O id da entidade cujo país do criador será retornado.",
	"func_getFarmerCountry_return": "O país do criador da entidade <b>entity</b> ou \"?\" se não for indicado.",
	"func_getFarmerName": "Retorna o nome do criador da entidade <b>entidade</b>.",
	"func_getFarmerName_arg_1": "O id da entidade cujo nome do criador será retornado.",
	"func_getFarmerName_return": "O nome do criador da entidade <b>entidade</b>.",
	"func_getFarthestAlly": "Determina o aliado mais distante de sua entidade, como o corvo voa.",
	"func_getFarthestAlly_return": "O id da entidade amiga mais distante.",
	"func_getFarthestEnemy": "Determina o inimigo mais distante de sua entidade, como o corvo voa.",
	"func_getFarthestEnemy_return": "O id da entidade inimiga mais distante.",
	"func_getFightID": "Retorna o ID da luta atual.",
	"func_getFightID_return": "O id da luta atual.",
	"func_getFightContext": "Retorna o contexto da luta atual.",
	"func_getFightContext_return": "Dependendo do contexto da batalha: Batalha de Teste (#FIGHT_CONTEXT_TEST), Batalha de Arena (#FIGHT_CONTEXT_GARDEN), Batalha de Torneio (#FIGHT_CONTEXT_TOURNAMENT), Batalha de Desafio (#FIGHT_CONTEXT_CHALLENGE), Batalha Real (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "Retorna o tipo de combate atual.",
	"func_getFightType_return": "Dependendo do tipo de luta: luta solo (#FIGHT_TYPE_SOLO), luta de fazendeiro (#FIGHT_TYPE_FARMER), luta de equipe (#FIGHT_TYPE_TEAM), Battle Royale (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "Retorna a força da entidade com id <b>entidade</b>.",
	"func_getForce_arg_1": "A entidade cuja força será devolvida.",
	"func_getForce_return": "A força da entidade <b>entidade</b>.",
	"func_getFrequency": "Retorna a frequência da entidade com id <b>entidade</b>. Use getFrequency() sem parâmetros para recuperar sua frequência.",
	"func_getFrequency_arg_1": "A entidade cuja frequência será retornada.",
	"func_getFrequency_return": "A frequência da entidade <b>entidade</b>.",
	"func_getGreen": "Retorna a quantidade de verde na cor <b>cor</b>, entre 0 e 255. Por exemplo, getGreen(#COLOR_GREEN) = 255 e getGreen(#COLOR_RED) = 0.",
	"func_getGreen_arg_1": "A cor cuja taxa verde será retornada.",
	"func_getGreen_return": "A taxa de verde na cor <b>color</b>",
	"func_getInstructionsCount": "Retorna o número de instruções que sua entidade executou durante o turno atual.",
	"func_getInstructionsCount_return": "O número de instruções que sua entidade executou durante o turno atual.",
	"func_getLaunchedEffects": "Retorna a lista de efeitos causados pela entidade com id <b>entity</b>.",
	"func_getLaunchedEffects_arg_1": "O id da entidade cuja lista de efeitos causados será retornada.",
	"func_getLaunchedEffects_return": "A lista de efeitos causados pela entidade com id <b>entity</b>, da mesma forma que o array retornado por #getEffects.",
	"func_getLeek": "Retorna o id da sua entidade.",
	"func_getLeek_return": "O id da sua entidade.",
	"func_getEntity": "Retorna o id da sua entidade.",
	"func_getEntity_return": "O id da sua entidade.",
	"func_getLeekID": "Retorna o id real do alho-poró de id <b>alho-porro</b>.",
	"func_getLeekID_arg_1": "O id do alho-poró cujo id real será retornado.",
	"func_getLeekID_return": "O ID real da <b>entidade</b> de alho-poró.",
	"func_getLeekOnCell": "Retorna a entidade que está na célula <b>célula</b>.",
	"func_getLeekOnCell_arg_1": "A célula cuja entidade queremos recuperar.",
	"func_getLeekOnCell_return": "O id da entidade na célula ou -1 se a célula não tiver nenhuma entidade.",
	"func_getEntityOnCell": "Retorna a entidade que está na célula <b>célula</b>.",
	"func_getEntityOnCell_arg_1": "A célula cuja entidade queremos recuperar.",
	"func_getEntityOnCell_return": "O id da entidade na célula ou -1 se a célula não tiver nenhuma entidade.",
	"func_getLevel": "Retorna o nível da entidade com id <b>entidade</b>.",
	"func_getLevel_arg_1": "O id da entidade cujo nível será retornado.",
	"func_getLevel_return": "O nível da entidade com id <b>entity</b>.",
	"func_getLife": "Retorna a vida atual da entidade com id <b>entidade</b>. Use getLife() sem parâmetros para recuperar sua vida.",
	"func_getLife_arg_1": "O id da entidade cuja vida será retornada.",
	"func_getLife_return": "A vida atual da entidade <b>entidade</b>.",
	"func_getMagic": "Retorna a mágica da entidade com id <b>entity</b>. Use getMagic() sem parâmetros para recuperar sua mágica.",
	"func_getMagic_arg_1": "O id da entidade cuja mágica será retornada.",
	"func_getMagic_return": "A magia da entidade id <b>entidade</b>.",
	"func_getMapType": "Retorna o tipo de terreno em que ocorre a luta (fábrica, deserto, floresta etc.), dentre as constantes #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER e #MAP_BEACH.",
	"func_getMapType_return": "O tipo de terreno.",
	"func_getMessageAuthor": "Retorna o id da entidade que criou a mensagem <b>mensagem</b>.",
	"func_getMessageAuthor_arg_1": "A mensagem cujo autor será retornado.",
	"func_getMessageAuthor_return": "O id da entidade que cria a mensagem <b>mensagem</b>.",
	"func_getMessageParams": "Retorna o array de parâmetros de mensagem <b>message</b>.",
	"func_getMessageParams_arg_1": "A mensagem cujos parâmetros serão retornados.",
	"func_getMessageParams_return": "Os parâmetros da mensagem <b>mensagem</b>.",
	"func_getMessages": "Retorna a matriz de mensagens da entidade <b>entity</b>.",
	"func_getMessages_arg_1": "A entidade cujas mensagens serão retornadas.",
	"func_getMessages_return": "A tabela de suas mensagens.<br>Uma mensagem é representada na forma de um\narray do formulário: [<b>autor</b>, <b>tipo</b>, <b>parâmetros</b>]<br>\nOs diferentes tipos de mensagens são representados pelas constantes:\n<ul>\n<li>#MESSAGE_HEAL: solicitação de atendimento</li>\n<li>#MESSAGE_ATTACK: pedido de ataque</li>\n<li>#MESSAGE_BUFF_FORCE: forçar solicitação de reforço</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "Retorna o tipo de mensagem <b>mensagem</b>.",
	"func_getMessageType_arg_1": "A mensagem cujo tipo será retornado (entre #MESSAGE_HEAL, #MESSAGE_ATTACK, etc.).",
	"func_getMessageType_return": "O tipo de mensagem <b>mensagem</b>.",
	"func_getMP": "Retorna a contagem de ponto de movimento atual da entidade <b>entidade</b>. Use getMP() sem parâmetros para recuperar seus PMs.",
	"func_getMP_arg_1": "O id da entidade cuja contagem de PM será retornada.",
	"func_getMP_return": "O número MP da entidade <b>entidade</b>.",
	"func_getName": "Retorna o nome da entidade com id <b>entidade</b>.",
	"func_getName_arg_1": "O id da entidade cujo nome será retornado.",
	"func_getName_return": "O nome da entidade <b>entidade</b>.",
	"func_getNearestAlly": "Retorna a entidade amiga mais próxima da sua entidade.",
	"func_getNearestAlly_return": "O id da entidade amiga mais próxima.",
	"func_getNearestAllyTo": "Retorna a entidade aliada mais próxima da entidade fornecida como parâmetro.",
	"func_getNearestAllyTo_arg_1": "O id da entidade cujo aliado mais próximo queremos saber.",
	"func_getNearestAllyToCell": "Retorna a entidade aliada mais próxima da célula fornecida como parâmetro.",
	"func_getNearestAllyToCell_arg_1": "O id da célula cujo aliado mais próximo queremos saber.",
	"func_getNearestAllyToCell_return": "O id da entidade amiga mais próxima.",
	"func_getNearestAllyTo_return": "O id da entidade amiga mais próxima.",
	"func_getNearestEnemy": "Retorna a entidade inimiga mais próxima da sua entidade.",
	"func_getNearestEnemy_return": "O id da entidade inimiga mais próxima.",
	"func_getNearestEnemyTo": "Retorna a entidade inimiga mais próxima da entidade fornecida como parâmetro.",
	"func_getNearestEnemyTo_arg_1": "O id da entidade cujo inimigo mais próximo queremos conhecer.",
	"func_getNearestEnemyToCell": "Retorna a entidade inimiga mais próxima da célula fornecida como parâmetro.",
	"func_getNearestEnemyToCell_arg_1": "O id da célula cujo inimigo mais próximo queremos conhecer.",
	"func_getNearestEnemyToCell_return": "O id da entidade inimiga mais próxima.",
	"func_getNearestEnemyTo_return": "O id da entidade inimiga mais próxima.",
	"func_getNextPlayer": "Retorna o id da entidade que jogará após o jogador atual.",
	"func_getNextPlayer_return": "O próximo jogador.",
	"func_getObstacles": "Retorna a lista de espaços de obstáculos no terreno.",
	"func_getObstacles_return": "A matriz que contém os IDs das células de obstáculo.",
	"func_getOperations": "Retorna o número de operações consumidas por sua entidade desde o início de seu turno. Este número deve permanecer menor que #OPERATIONS_LIMIT para que a entidade não falhe.",
	"func_getOperations_return": "Número de operações consumidas por sua entidade desde o início de seu turno.",
	"func_getPath": "Retorna o caminho evitando obstáculos entre duas células <b>cell1</b> e <b>cell2</b>, caso exista, ignorando as células contidas no array <b>ignoredCells</b> . Se um jogador estiver em uma célula ignorada, o caminho pode passar por cima dele.<br /><br />\nA célula inicial <b>célula1</b> nunca faz parte do caminho resultante. A célula <b>célula2</b> faz parte do caminho resultante se e somente se estiver vazia ou for ignorada por <b>ignoredCells</b>.<br /><br />\nSe não existir nenhum caminho entre as duas células, <b>getPath</b> retornará <i>null</i>.",
	"func_getPath_arg_1": "A célula inicial.",
	"func_getPath_arg_2": "A célula de chegada.",
	"func_getPath_arg_3": "A matriz de células a serem ignoradas.",
	"func_getPath_return": "A matriz que contém as células que compõem o caminho entre as duas células.",
	"func_getPathLength": "Retorna o comprimento do caminho entre duas células <b>célula1</b> e <b>célula2</b>, evitando obstáculos, ignorando as células contidas no array <b>ignoredCells</b>. Esta função é equivalente a <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>.\nSe um jogador estiver em uma célula ignorada, o caminho pode passar por cima dele.<br /><br />\nA célula inicial <b>célula1</b> nunca é contada no resultado. A célula <b>célula2</b> é contada no resultado se e somente se estiver vazia ou for ignorada por <b>ignoredCells</b>.<br /><br />\nSe não existir nenhum caminho entre as duas células, <b>getPathLength</b> retornará <i>nulo</i>.",
	"func_getPathLength_arg_1": "A célula inicial.",
	"func_getPathLength_arg_2": "A célula de chegada.",
	"func_getPathLength_arg_3": "A matriz de células a serem ignoradas.",
	"func_getPathLength_return": "O comprimento do caminho entre <b>célula1</b> e <b>célula2</b>.",
	"func_getPreviousPlayer": "Retorna o id da entidade que jogou antes do jogador atual.",
	"func_getPreviousPlayer_return": "O jogador anterior.",
	"func_getRed": "Retorna a quantidade de vermelho na cor <b>color</b>, entre 0 e 255. Por exemplo, getRed(#COLOR_RED) = 255 e getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "A cor cuja taxa vermelha será retornada.",
	"func_getRed_return": "A taxa de vermelho na cor <b>color</b>",
	"func_getRegister": "Retorna o valor armazenado no registro da entidade associada à chave <b>key</b> ou </i>nulo</i> se o registro não existir.",
	"func_getRegister_arg_1": "A chave de registro cujo valor será retornado.",
	"func_getRegister_return": "O valor armazenado no registro <b>chave</b>.",
	"func_getRegisters": "Retorna o conjunto de registros de entidade como uma matriz associativa [<i>chave de registro</i>:<i>valor de registro</i>]. Exemplo: <code>debug(getRegisters());\n// Mostra por exemplo:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "A matriz associativa correspondente a todos os registradores da entidade.",
	"func_getRelativeShield": "Retorna o escudo relativo da entidade com id <b>entity</b>. Para recuperar diretamente o escudo relativo da sua entidade, use #getRelativeShield() sem parâmetros.",
	"func_getRelativeShield_arg_1": "O id da entidade cujo escudo relativo será retornado.",
	"func_getRelativeShield_return": "O escudo relativo da <b>entidade</b>, um inteiro entre <b>0</b> e <b>100</b>.",
	"func_getResistance": "Retorna a resistência da entidade com id <b>entity</b>. Use getResistance() sem parâmetros para recuperar sua resistência.",
	"func_getResistance_arg_1": "O id da entidade cuja resistência será retornada.",
	"func_getResistance_return": "A resistência da entidade com id <b>entidade</b>.",
	"func_getScience": "Retorna a ciência da entidade com id <b>entidade</b>. Use getScience() sem parâmetros para recuperar sua ciência.",
	"func_getScience_arg_1": "O id da entidade cuja ciência será retornada.",
	"func_getScience_return": "A ciência da entidade id <b>entity</b>.",
	"func_getStrength": "Retorna a força da entidade com id <b>entidade</b>. Use getStrength() sem parâmetros para recuperar sua força.",
	"func_getStrength_arg_1": "A entidade cuja força será devolvida.",
	"func_getStrength_return": "A força da entidade <b>entidade</b>.",
	"func_getPower": "Retorna o poder da entidade com id <b>entidade</b>.",
	"func_getPower_arg_1": "A entidade cujo poder será devolvido.",
	"func_getPower_return": "O poder da <b>entidade</b>.",
	"func_getSummoner": "Retorna a entidade que invocou a entidade <b>entidade</b>, se for uma invocação.",
	"func_getSummoner_arg_1": "O id da entidade cujo invocador será demitido.",
	"func_getSummoner_return": "O id da entidade invocada <b>entidade</b> se for uma invocação, <i>nulo</i> caso contrário.",
	"func_getSummons": "Retorna a lista de ids de invocações atualmente ativas da entidade com id <b>entity</b>.",
	"func_getSummons_arg_1": "O id da entidade cuja convocação será retornada.",
	"func_getSummons_return": "A lista de IDs de invocação da entidade com id <b>entity</b>.",
	"func_getTeamID": "Retorna o ID da equipe da entidade <b>entity</b>.",
	"func_getTeamID_arg_1": "O id da entidade cujo ID de equipe será retornado.",
	"func_getTeamID_return": "O ID da equipe da entidade <b>entity</b>.",
	"func_getTeamName": "Retorna o nome da equipe da entidade <b>entity</b>.",
	"func_getTeamName_arg_1": "O id da entidade cujo nome da equipe será retornado.",
	"func_getTeamName_return": "O nome da equipe da entidade <b>entidade</b>.",
	"func_getTotalLife": "Retorna a vida total da entidade com id <b>entidade</b>. Use getTotalLife() sem parâmetros para recuperar sua vida total.",
	"func_getTotalLife_arg_1": "O id da entidade cuja vida total será retornada.",
	"func_getTotalLife_return": "A vida total da entidade.",
	"func_getTotalMP": "Retorna o número máximo de pontos de movimento da entidade <b>entity</b>.",
	"func_getTotalMP_arg_1": "O id da entidade cujos pontos máximos de movimento serão retornados.",
	"func_getTotalMP_return": "O número máximo de pontos de movimento para a <b>entidade</b>.",
	"func_getTotalTP": "Retorna o número máximo de pontos de giro da entidade <b>entidade</b>.",
	"func_getTotalTP_arg_1": "O id da entidade cujo número máximo de pontos de virada será retornado.",
	"func_getTotalTP_return": "O número máximo de pontos de giro da entidade <b>entidade</b>.",
	"func_getTP": "Retorna o número de pontos de giro da entidade <b>entidade</b>. Use getTP() sem parâmetros para recuperar seus PTs.",
	"func_getTP_arg_1": "O id da entidade cujos PTs serão retornados.",
	"func_getTP_return": "O número PT da entidade <b>entidade</b>.",
	"func_getTurn": "Retorna o turno de combate atual. O número máximo de voltas é #MAX_TURNS.",
	"func_getTurn_return": "O turno atual do combate.",
	"func_getType": "Retorna o tipo de entidade <b>entity</b> da entidade.",
	"func_getType_arg_1": "O id da entidade cujo tipo será retornado.",
	"func_getType_return": "O tipo de entidade de <b>entidade</b>:<ul><li>#ENTITY_LEEK se for um alho-poró.</li><li>#ENTITY_BULB se for uma 'lâmpada.</li><li >#ENTITY_TURRET se for uma torre.</li></ul>",
	"func_getWeapon": "Retorna a <b>entidade</b> da arma atualmente equipada.",
	"func_getWeapon_arg_1": "O id da entidade cuja arma atual será retornada.",
	"func_getWeapon_return": "O id da arma atualmente equipada na <b>entidade</b>, nulo se a entidade não tiver nenhuma arma equipada ou se a entidade não existir.",
	"func_getWeaponArea": "Retorna a área do tipo de efeito da arma <b>weapon</b>.",
	"func_getWeaponArea_arg_1": "A arma cujo tipo de área será retornada.",
	"func_getWeaponArea_return": "O tipo de área da arma <b>weapon</b> entre as constantes AREA_*:\n<ul>\n<li>#AREA_POINT: área quadrada única</li>\n<li>#AREA_LASER_LINE: linha de um laser</li>\n<li>#AREA_CIRCLE_1: área circular com 3 células de diâmetro</li>\n<li>#AREA_CIRCLE_2: área circular com 5 quadrados de diâmetro</li>\n<li>#AREA_CIRCLE_3: área circular com 7 quadrados de diâmetro</li>\n</ul>",
	"func_getWeaponCost": "Retorna o custo em TP da arma <b>weapon</b>.",
	"func_getWeaponCost_arg_1": "O id da arma cujo custo será devolvido.",
	"func_getWeaponCost_return": "O custo em TP da arma <b>weapon</b>.",
	"func_getWeaponEffectiveArea": "Retorna a lista de células que serão afetadas se a arma <b>arma</b> for usada na célula <b>célula</b> da célula <b>de</b>.",
	"func_getWeaponEffectiveArea_arg_1": "A arma para testar.",
	"func_getWeaponEffectiveArea_arg_2": "A célula alvo.",
	"func_getWeaponEffectiveArea_arg_3": "A célula de onde a arma é disparada.",
	"func_getWeaponEffectiveArea_return": "A matriz contendo os ids de todas as células que serão afetadas.",
	"func_getWeaponPassiveEffects": "Retorna os efeitos passivos da arma <b>weapon</b>.",
	"func_getWeaponPassiveEffects_arg_1": "O id da arma cujos efeitos passivos serão devolvidos.",
	"func_getWeaponPassiveEffects_return": "Uma matriz contendo os efeitos da arma <b>weapon</b>. Cada efeito é em si uma matriz da forma\n[tipo, mínimo, máximo, turnos, alvos, modificadores]. Esses efeitos são os mesmos retornados por #getWeaponEffects.",
	"func_getWeaponFailure": "Retorna a porcentagem de chance de falha da arma <b>arma</b>.",
	"func_getWeaponFailure_arg_1": "O id da arma cuja porcentagem de falha será retornada.",
	"func_getWeaponFailure_return": "<b>arma</b> porcentagem de erro de arma, um número inteiro entre <b>0</b> e <b>100</b>.",
	"func_getWeaponMaxRange": "Retorna o alcance máximo da arma <b>weapon</b>.",
	"func_getWeaponMaxRange_arg_1": "O id da arma cujo alcance máximo será retornado.",
	"func_getWeaponMaxRange_return": "O alcance máximo da arma <b>weapon</b>.",
	"func_getWeaponMaxScope": "Retorna o alcance máximo da arma <b>weapon</b>.",
	"func_getWeaponMaxScope_arg_1": "O id da arma cujo alcance máximo será retornado.",
	"func_getWeaponMaxScope_return": "O alcance máximo da arma <b>weapon</b>.",
	"func_getWeaponMinRange": "Retorna o alcance mínimo da arma <b>weapon</b>.",
	"func_getWeaponMinRange_arg_1": "O id da arma cujo alcance mínimo será retornado.",
	"func_getWeaponMinRange_return": "O alcance mínimo da arma <b>weapon</b>.",
	"func_getWeaponMinScope": "Retorna o alcance mínimo da arma <b>weapon</b>.",
	"func_getWeaponMinScope_arg_1": "O id da arma cujo alcance mínimo será retornado.",
	"func_getWeaponMinScope_return": "O alcance mínimo da arma <b>weapon</b>.",
	"func_getWeaponLaunchType": "Retorna o modo de lançamento da arma <b>weapon</b>, entre as constantes LAUNCH_TYPE_*.",
	"func_getWeaponLaunchType_arg_1": "O id da arma cujo modo de arremesso será retornado. O padrão é sua arma atualmente equipada.",
	"func_getWeaponLaunchType_return": "O modo de arremesso da arma <b>weapon</b>.",
	"func_getWeaponName": "Retorna o nome da arma <b>weapon</b>.",
	"func_getWeaponName_arg_1": "O id da arma cujo nome será retornado.",
	"func_getWeaponName_return": "O nome da arma <b>weapon</b>.",
	"func_getWeapons": "Retorna as armas da entidade com id <b>entity</b>.",
	"func_getWeapons_arg_1": "O id da entidade cujas armas serão devolvidas.",
	"func_getWeapons_return": "Uma matriz contendo os IDs de armas da <b>entidade</b>.",
	"func_getWeaponTargets": "Retorna as entidades que serão afetadas caso a arma <b>weapon</b> seja usada na célula <b>cell</b>.",
	"func_getWeaponTargets_arg_1": "A arma para testar.",
	"func_getWeaponTargets_arg_2": "A célula alvo.",
	"func_getWeaponTargets_return": "A matriz contendo os IDs de todas as entidades que serão afetadas.",
	"func_getWisdom": "Retorna a sabedoria da entidade com id <b>entidade</b>. Use getWisdom() sem parâmetros para recuperar sua sabedoria.",
	"func_getWisdom_arg_1": "O id da entidade cuja sabedoria será devolvida.",
	"func_getWisdom_return": "Sabedoria do ID da entidade <b>entidade</b>.",
	"func_hypot": "Retorna a hipotenusa do triângulo retângulo com lados x e y. Equivalente a <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "O valor x.",
	"func_hypot_arg_2": "O valor de y.",
	"func_hypot_return": "<i>quadrado(x**2 + y**2)</i>.",
	"func_inArray": "Determina se o elemento <b>element</b> está na matriz <b>array</b>.",
	"func_inArray_arg_1": "A tabela de pesquisa.",
	"func_inArray_arg_2": "O item a ser pesquisado.",
	"func_inArray_return": "<i>true</i> se o elemento estiver no array, <i>false</i> caso contrário.",
	"func_include": "Inclua a IA chamada <b>ai</b> na IA atual. <br><br><b>Atenção</b>, a função include deve ser chamada somente no bloco principal, e seu parâmetro deve ser uma string escrita diretamente\nno código. Para obter mais informações, consulte a seção no tutorial: <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a> .",
	"func_include_arg_1": "O nome da IA a incluir.",
	"func_indexOf": "Determina a posição da primeira ocorrência da string <b>search</b> na string <b>string</b>, começando na posição <b>start</b>.",
	"func_indexOf_arg_1": "A string onde a pesquisa será realizada.",
	"func_indexOf_arg_2": "A string a ser pesquisada.",
	"func_indexOf_arg_3": "A posição inicial da pesquisa.",
	"func_indexOf_return": "A posição da primeira ocorrência de <b>search</b> em <b>string</b> de <b>start</b>, -1 se a string não foi encontrada.",
	"func_insert": "Insere um elemento <b>element</b> no <b>array</b> na posição <b>position</b>.",
	"func_insert_arg_1": "A matriz onde o elemento será inserido.",
	"func_insert_arg_2": "O item a ser inserido.",
	"func_insert_arg_3": "A posição da inserção.",
	"func_isAlive": "Determina se uma <b>entidade</b> está viva. Equivalente a <i>getLife(<b>entity</b>) > 0</i>.",
	"func_isAlive_arg_1": "O id da entidade a ser testada.",
	"func_isAlive_return": "<i>true</i> se a <b>entidade</b> estiver viva, <i>false</i> se estiver morta.",
	"func_isAlly": "Determina se a <b>entidade</b> é sua aliada.",
	"func_isAlly_arg_1": "O id da entidade a ser testada.",
	"func_isAlly_return": "<i>verdadeiro</i> se a <b>entidade</b> for seu aliado ou você mesmo, <i>falso</i> se for um inimigo.",
	"func_isChip": "Determina se um valor é uma constante que representa um chip.<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "O número a ser determinado.",
	"func_isChip_return": "<i>true</i> se o valor for uma constante de chip.",
	"func_isDead": "Determina se a <b>entidade</b> está morta. Equivalente a <i>getLife(<b>entity</b>) == 0</i>.",
	"func_isDead_arg_1": "O id da entidade a ser testada.",
	"func_isDead_return": "<i>true</i> se a <b>entidade</b> estiver morta, <i>false</i> se estiver viva.",
	"func_isEmpty": "Determina se o array <b>array</b> está vazio. Equivalente a <i>count(<b>array</b>) == 0</i>.",
	"func_isEmpty_arg_1": "A matriz a ser testada.",
	"func_isEmptyCell": "Determina se uma célula está vazia.",
	"func_isEmptyCell_arg_1": "A célula a ser testada.",
	"func_isEmptyCell_return": "<i>true</i> se a célula estiver vazia, <i>false</i> caso contrário.",
	"func_isEmpty_return": "<i>true</i> se o array estiver vazio, <i>false</i> caso contrário.",
	"func_isEnemy": "Determina se <b>entidade</b> é seu inimigo.",
	"func_isEnemy_arg_1": "O id da entidade a ser testada.",
	"func_isEnemy_return": "<i>true</i> se a <b>entidade</b> for um inimigo, <i>false</i> se for um aliado ou você mesmo.",
	"func_isInlineChip": "Determina se o <b>chip</b> pode ser usado apenas online.",
	"func_isInlineChip_arg_1": "O id do chip a ser testado.",
	"func_isInlineChip_return": "<i>true</i> se o chip for somente online, <i>false</i> caso contrário.",
	"func_isInlineWeapon": "Determina se a arma <b>weapon</b> só pode ser usada online.",
	"func_isInlineWeapon_arg_1": "O ID da arma a ser testada.",
	"func_isInlineWeapon_return": "<i>true</i> se a arma só pode ser usada online, <i>false</i> caso contrário.",
	"func_isLeek": "Determina se o conteúdo da célula <b>célula</b> é uma entidade.",
	"func_isLeek_arg_1": "A célula a ser testada.",
	"func_isLeek_return": "<i>true</i> se a célula contiver uma entidade, <i>false</i> caso contrário.",
	"func_isEntity": "Determina se o conteúdo da célula <b>célula</b> é uma entidade.",
	"func_isEntity_arg_1": "A célula a ser testada.",
	"func_isEntity_return": "<i>true</i> se a célula contiver uma entidade, <i>false</i> caso contrário.",
	"func_isObstacle": "Determina se o conteúdo da célula <b>célula</b> é um obstáculo.",
	"func_isObstacle_arg_1": "A célula a ser testada.",
	"func_isObstacle_return": "<i>true</i> se a célula contiver um obstáculo, <i>false</i> caso contrário.",
	"func_isOnSameLine": "Determina se duas células <b>célula1</b> e <b>célula2</b> estão na mesma linha.",
	"func_isOnSameLine_arg_1": "A primeira célula.",
	"func_isOnSameLine_arg_2": "A segunda célula.",
	"func_isOnSameLine_return": "<i>true</i> se as duas células estiverem na mesma linha, <i>false</i> caso contrário.",
	"func_isStatic": "Retorna se a <b>entidade</b> é estática ou não. Uma entidade estática não pode se mover ou ser movida.",
	"func_isStatic_arg_1": "O id da entidade a ser testada.",
	"func_isStatic_return": "<i>true</i> se <b>entity</b> for estático, <i>false</i> caso contrário.",
	"func_isSummon": "Retorna se <b>entidade</b> é uma invocação ou não.",
	"func_isSummon_arg_1": "O id da entidade a ser testada.",
	"func_isSummon_return": "<i>true</i> se <b>entity</b> for uma invocação, <i>false</i> caso contrário.",
	"func_isWeapon": "Determina se um valor é uma constante que representa uma arma.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false.",
	"func_isWeapon_arg_1": "O número a ser determinado.",
	"func_isWeapon_return": "<i>true</i> se o valor for uma constante de arma.",
	"func_join": "Mescla vários elementos em uma string, separando-os com um delimitador <b>cola</b>.",
	"func_join_arg_1": "Uma matriz de itens para mesclar.",
	"func_join_arg_2": "O delimitador do elemento.",
	"func_join_return": "A cadeia de caracteres resultante da mesclagem.",
	"func_jsonDecode": "Decodifica string <b>json</b> para objeto LeekScript (número, string, array...).",
	"func_jsonDecode_arg_1": "A string JSON a ser decodificada.",
	"func_jsonDecode_return": "O objeto LeekScript decodificado.",
	"func_jsonEncode": "Codifica o objeto <b>objeto</b> em uma string JSON.",
	"func_jsonEncode_arg_1": "O objeto a ser codificado em JSON.",
	"func_jsonEncode_return": "A string codificada em JSON.",
	"func_keySort": "Classifica a <b>matriz</b> por chaves na ordem <b>ordem</b>.",
	"func_keySort_arg_1": "A matriz a ser classificada.",
	"func_keySort_arg_2": "A ordem de classificação: #SORT_ASC ou #SORT_DESC.",
	"func_length": "Retorna o comprimento da <b>string</b>.",
	"func_length_arg_1": "A string cujo comprimento será retornado.",
	"func_length_return": "O comprimento da <b>string</b>.",
	"func_lineOfSight": "Verifica a linha de visão entre a célula <b>inicial</b> e a célula <b>final</b>, ignorando as entidades <b>ignoredEntities</b>.",
	"func_lineOfSight_arg_1": "Célula inicial.",
	"func_lineOfSight_arg_2": "Célula Alvo.",
	"func_lineOfSight_arg_3": "Uma entidade ou lista de entidades a serem ignoradas.",
	"func_lineOfSight_return": "Retorna <i>true</i> se a linha de visão estiver limpa.",
	"func_listen": "Retorna o array de say() das entidades anteriores, na forma [entity_id, message].",
	"func_listen_return": "A matriz de say()s anteriores.",
	"func_log": "Calcula o logaritmo natural do número <b>número</b>.",
	"func_log_arg_1": "Um número no intervalo ]0; +∞[.",
	"func_log_return": "O logaritmo natural de <b>número</b>.",
	"func_log10": "Calcula o logaritmo de base 10 do número <b>número</b>.",
	"func_log10_arg_1": "Um número no intervalo ]0; +∞[.",
	"func_log10_return": "O logaritmo de base 10 de <b>número</b>.",
	"func_log2": "Calcula o logaritmo de base 2 do número <b>número</b>.",
	"func_log2_arg_1": "Um número no intervalo ]0; +∞[.",
	"func_log2_return": "O logaritmo de base 2 de <b>número</b>.",
	"func_mark": "Marca uma ou mais células da cor indicada no parâmetro no terreno para o número de voltas indicado no parâmetro. Esta marcação só é visível ao criador da entidade.",
	"func_mark_arg_1": "A célula ou matriz de várias células para marcar",
	"func_mark_arg_2": "Cor de marcação",
	"func_mark_arg_3": "Duração da marcação",
	"func_mark_return": "Retorna verdadeiro se tudo correu bem",
	"func_markText": "Escreve um texto em uma ou mais células da cor indicada em parâmetro no chão para o número de voltas indicado em parâmetro. Esses textos são visíveis apenas para o criador da entidade.",
	"func_markText_arg_1": "A célula ou matriz de várias células para gravar",
	"func_markText_arg_2": "O texto a escrever (máximo 10 caracteres)",
	"func_markText_arg_3": "Cor do texto",
	"func_markText_arg_4": "Duração do texto",
	"func_markText_return": "Retorna verdadeiro se tudo correu bem",
	"func_max": "Retorna o maior valor entre os dois números <b>a</b> e <b>b</b>.",
	"func_max_arg_1": "Um número.",
	"func_max_arg_2": "Um número.",
	"func_max_return": "O maior número entre <b>a</b> e <b>b</b>.",
	"func_min": "Retorna o menor valor entre os dois números <b>a</b> e <b>b</b>.",
	"func_min_arg_1": "Um número.",
	"func_min_arg_2": "Um número.",
	"func_min_return": "O menor número entre <b>a</b> e <b>b</b>.",
	"func_moveAwayFrom": "Afasta sua entidade de outra <b>entidade</b>, usando o máximo de <b>mp</b> pontos de movimento.",
	"func_moveAwayFrom_arg_1": "A entidade da qual sua entidade deve se afastar.",
	"func_moveAwayFrom_arg_2": "O número máximo de MP a ser usado. Por padrão, sem limite.",
	"func_moveAwayFrom_return": "O número de pontos de movimento usados.",
	"func_moveAwayFromCell": "Move sua entidade para uma <b>célula</b> de distância, usando até <b>mp</b> pontos de movimento.",
	"func_moveAwayFromCell_arg_1": "A célula da qual sua entidade deve se afastar.",
	"func_moveAwayFromCell_arg_2": "O número máximo de MP a ser usado. Por padrão, sem limite.",
	"func_moveAwayFromCell_return": "O número de pontos de movimento usados.",
	"func_moveAwayFromCells": "Move sua entidade para longe de um conjunto de <b>células</b>, usando o máximo de <b>mp</b> pontos de movimento.",
	"func_moveAwayFromCells_arg_1": "A matriz que contém as células das quais sua entidade deve se afastar.",
	"func_moveAwayFromCells_arg_2": "O número máximo de MP a ser usado. Por padrão, sem limite.",
	"func_moveAwayFromCells_return": "O número de pontos de movimento usados.",
	"func_moveAwayFromLeeks": "Move sua entidade para longe de um conjunto de <b>entidades</b>, usando o máximo de <b>mp</b> pontos de movimento.",
	"func_moveAwayFromLeeks_arg_1": "A matriz que contém os IDs das entidades das quais sua entidade deve se afastar.",
	"func_moveAwayFromLeeks_arg_2": "O número máximo de MP a ser usado. Por padrão, sem limite.",
	"func_moveAwayFromLeeks_return": "O número de pontos de movimento usados.",
	"func_moveAwayFromEntities": "Move sua entidade para longe de um conjunto de <b>entidades</b>, usando o máximo de <b>mp</b> pontos de movimento.",
	"func_moveAwayFromEntities_arg_1": "A matriz que contém os IDs das entidades das quais sua entidade deve se afastar.",
	"func_moveAwayFromEntities_arg_2": "O número máximo de MP a ser usado. Por padrão, sem limite.",
	"func_moveAwayFromEntities_return": "O número de pontos de movimento usados.",
	"func_moveAwayFromLine": "Move sua entidade para longe de uma linha definida por duas células <b>célula1</b> e <b>célula2</b>, usando um máximo de <b>mp</b> pontos de movimento.",
	"func_moveAwayFromLine_arg_1": "Célula 1.",
	"func_moveAwayFromLine_arg_2": "Célula 2.",
	"func_moveAwayFromLine_arg_3": "O número máximo de MP a ser usado. Por padrão, sem limite.",
	"func_moveAwayFromLine_return": "O número de pontos de movimento usados.",
	"func_moveToward": "Aproxima sua entidade de outra <b>entidade</b>, usando o máximo de <b>mp</b> pontos de movimento.",
	"func_moveToward_arg_1": "A entidade que sua entidade deve abordar.",
	"func_moveToward_arg_2": "O número máximo de MP a ser usado.",
	"func_moveToward_return": "O número de pontos de movimento usados.",
	"func_moveTowardCell": "Move sua entidade uma <b>célula</b> para mais perto, usando o máximo de <b>mp</b> pontos de movimento.",
	"func_moveTowardCell_arg_1": "A célula que seu recurso deve abordar.",
	"func_moveTowardCell_arg_2": "O número máximo de MP a ser usado. Por padrão, sem limite.",
	"func_moveTowardCell_return": "O número de pontos de movimento usados.",
	"func_moveTowardCells": "Aproxima sua entidade de um conjunto de <b>células</b>, usando o máximo de <b>mp</b> pontos de movimento.",
	"func_moveTowardCells_arg_1": "A matriz contendo as células que sua entidade deve abordar.",
	"func_moveTowardCells_arg_2": "O número máximo de MP a ser usado. Por padrão, sem limite.",
	"func_moveTowardCells_return": "O número de pontos de movimento usados.",
	"func_moveTowardLeeks": "Aproxima sua entidade de um conjunto de <b>entidades</b>, usando o máximo de <b>mp</b> pontos de movimento.",
	"func_moveTowardLeeks_arg_1": "A matriz contendo os ids das entidades que sua entidade deve abordar.",
	"func_moveTowardLeeks_arg_2": "O número máximo de MP a ser usado. Por padrão, sem limite.",
	"func_moveTowardLeeks_return": "O número de pontos de movimento usados.",
	"func_moveTowardEntities": "Aproxima sua entidade de um conjunto de <b>entidades</b>, usando o máximo de <b>mp</b> pontos de movimento.",
	"func_moveTowardEntities_arg_1": "A matriz contendo os ids das entidades que sua entidade deve abordar.",
	"func_moveTowardEntities_arg_2": "O número máximo de MP a ser usado. Por padrão, sem limite.",
	"func_moveTowardEntities_return": "O número de pontos de movimento usados.",
	"func_moveTowardLine": "Aproxima sua entidade de uma linha definida por duas células <b>célula1</b> e <b>célula2</b>, usando no máximo <b>mp</b> pontos de movimento.",
	"func_moveTowardLine_arg_1": "Célula 1.",
	"func_moveTowardLine_arg_2": "Célula 2.",
	"func_moveTowardLine_arg_3": "O número máximo de MP a ser usado. Por padrão, sem limite.",
	"func_moveTowardLine_return": "O número de pontos de movimento usados.",
	"func_number": "Converte um valor em um número. Se o valor for uma string, a função number tentará convertê-lo em um número, se o valor já for um número, a função retornará o número e, para qualquer outro tipo, retornará <i>null</i>.",
	"func_number_arg_1": "O valor a ser convertido em um número.",
	"func_number_return": "O número convertido.",
	"func_pause": "Pausa a luta, apenas para o criador da entidade que está usando a função.",
	"func_pop": "Remove a última célula da matriz <b>array</b> e retorna o elemento correspondente.",
	"func_pop_arg_1": "A matriz cuja última caixa será excluída.",
	"func_pop_return": "O item que estava na velha última caixa.",
	"func_pow": "Eleva o número base à potência do expoente.",
	"func_pow_arg_1": "A base.",
	"func_pow_arg_2": "O expositor.",
	"func_pow_return": "<b>base</b> elevada à potência <b>expoente</b>.",
	"func_push": "Adiciona o elemento <b>element</b> ao final do <b>array</b>.",
	"func_pushAll": "Acrescenta todos os elementos do array <b>elements</b> ao final do <b>array</b>.",
	"func_pushAll_arg_1": "A matriz onde queremos adicionar os elementos.",
	"func_pushAll_arg_2": "Itens a adicionar.",
	"func_push_arg_1": "A matriz onde queremos adicionar o elemento.",
	"func_push_arg_2": "O item a ser adicionado.",
	"func_rand": "Retorna um número aleatório real, entre 0 (inclusivo) e 1 (exclusivo).",
	"func_randFloat": "Retorna um número aleatório real, entre <b>a</b> (inclusivo) e <b>b</b> (exclusivo).",
	"func_randFloat_arg_1": "Limite inferior.",
	"func_randFloat_arg_2": "Limite superior.",
	"func_randFloat_return": "Um número aleatório no intervalo [a; b[.",
	"func_randInt": "Retorna um número inteiro aleatório entre <b>a</b> (inclusivo) e <b>b</b> (exclusivo).",
	"func_randInt_arg_1": "Limite inferior.",
	"func_randInt_arg_2": "Limite superior.",
	"func_randInt_return": "Um inteiro aleatório no intervalo [a; b[.",
	"func_rand_return": "Um número aleatório no intervalo [0; 1[.",
	"func_remove": "Remove um elemento da matriz <b>array</b> na posição <b>posição</b> e retorna o elemento excluído.",
	"func_remove_arg_1": "Array do qual queremos deletar um elemento.",
	"func_remove_arg_2": "Posição do elemento a eliminar.",
	"func_removeElement": "Exclui a primeira ocorrência de um <b>elemento</b> no <b>array</b>. Se o elemento não for encontrado, o array não é modificado.",
	"func_removeElement_arg_1": "Array do qual queremos deletar um elemento.",
	"func_removeElement_arg_2": "Item para localizar e excluir.",
	"func_removeKey": "Exclui um elemento do array <b>array</b> associado à chave <b>key</b>.",
	"func_removeKey_arg_1": "Array do qual queremos deletar um elemento.",
	"func_removeKey_arg_2": "A chave associada ao item a ser excluído.",
	"func_remove_return": "O elemento removido da matriz.",
	"func_replace": "Substitui todas as ocorrências de <b>search</b> por <b>replace</b> na string <b>string</b>.",
	"func_replace_arg_1": "String na qual as substituições são feitas.",
	"func_replace_arg_2": "Substring para substituir.",
	"func_replace_arg_3": "Cadeia de substituição.",
	"func_replace_return": "A string de resultado, com substituições.",
	"func_resurrect": "Usa o chip CHIP_RESURRECTION para ressuscitar uma entidade morta com id <b>entidade</b>, na célula <b>célula</b>.",
	"func_resurrect_arg_1": "O id da entidade a ser revivida.",
	"func_resurrect_arg_2": "A célula na qual a entidade reaparecerá.",
	"func_resurrect_return": "O resultado do lançamento do chip, entre as constantes USE_*.",
	"func_reverse": "Inverte a <b>matriz</b>.",
	"func_reverse_arg_1": "Mesa para inverter.",
	"func_round": "Retorna o arredondamento de <b>número</b>.<br/>Você pode encontrar o arredondamento para baixo com #floor e o arredondamento para cima com #ceil.",
	"func_round_arg_1": "Número cujo arredondamento queremos encontrar.",
	"func_round_return": "Arredondado por <b>número</b>.",
	"func_say": "Faça sua entidade falar.",
	"func_say_arg_1": "Mensagem que sua entidade anunciará na arena.",
	"func_search": "Localiza o elemento <b>element</b> no array <b>array</b>, começando na posição <b>início</b>.",
	"func_search_arg_1": "A tabela de pesquisa.",
	"func_search_arg_2": "O item a ser pesquisado.",
	"func_search_arg_3": "A posição do início da pesquisa.",
	"func_search_return": "A posição da primeira ocorrência do elemento <b>element</b> no <b>array</b>, começando em <b>start</b>, null se o elemento não foi encontrado.",
	"func_sendAll": "Envie uma mensagem para toda a sua equipe.",
	"func_sendAll_arg_1": "O tipo de mensagem a ser enviada (consulte as constantes MESSAGE_*).",
	"func_sendAll_arg_2": "Os parâmetros da mensagem, que podem ser qualquer valor.",
	"func_sendTo": "Envia uma mensagem para a entidade com id <b>entidade</b>.",
	"func_sendTo_arg_1": "O id da entidade para a qual a mensagem será enviada.",
	"func_sendTo_arg_2": "O tipo de mensagem a ser enviada (consulte as constantes MESSAGE_*).",
	"func_sendTo_arg_3": "Os parâmetros da mensagem, que podem ser qualquer valor.",
	"func_sendTo_return": "<i>true</i> se o envio foi bem-sucedido, <i>false</i> se ocorreu um erro.",
	"func_setRegister": "Armazena o <b>valor</b> no registro <b>chave</b>.\nA chave e o valor são strings que devem conter respectivamente <i>100</i> e <i>5000</i> caracteres no máximo. Um alho-poró pode ter no máximo <i>100</i> registros, armazenar em um novo registro não funcionará se todos os registros\njá estão ocupados.",
	"func_setRegister_arg_1": "A chave do registro onde armazenar o valor.",
	"func_setRegister_arg_2": "O valor a ser armazenado.",
	"func_setRegister_return": "<i>true</i> se a operação foi bem-sucedida, <i>false</i> caso contrário.",
	"func_setWeapon": "Equipe a arma <b>weapon</b> em sua entidade.",
	"func_setWeapon_arg_1": "Id da arma a equipar.",
	"func_shift": "Remove a primeira célula do <b>array</b> e retorna o elemento correspondente.",
	"func_shift_arg_1": "A matriz cuja primeira célula será excluída.",
	"func_shift_return": "O elemento que estava na antiga primeira caixa.",
	"func_show": "Mostra aos jogadores uma <b>célula</b> da cor <b>cor</b> no campo por 1 turno. O uso desse recurso custa 1PT.",
	"func_show_arg_1": "A célula para mostrar",
	"func_show_arg_2": "Cor de marcação",
	"func_show_return": "Retorna verdadeiro se tudo correu bem",
	"func_shuffle": "Embaralha um tabuleiro aleatoriamente.",
	"func_shuffle_arg_1": "O tabuleiro de embaralhar.",
	"func_signum": "Determina o sinal do número <b>número</b>.",
	"func_signum_arg_1": "O número cujo sinal será determinado.",
	"func_signum_return": "1 se o número for positivo, 0 se o número for zero e -1 se o número for negativo.",
	"func_sin": "Calcula o seno do ângulo <b>ângulo</b> passado como parâmetro",
	"func_sin_arg_1": "O ângulo cujo seno será calculado",
	"func_sin_return": "Retorna o seno do ângulo <b>ângulo</b>",
	"func_sort": "Classifica a <b>array</b> em ordem crescente ou decrescente. A ordem crescente padrão é:\n<ul>\n<li>Todos os booleanos (primeiro falso e depois verdadeiro)</li>\n<li>Todos os números (ordenados do menor para o maior)</li>\n<li>Todos os canais (em ordem alfabética)</li>\n<li>Todos os elementos nulos.</li>\n</ul>",
	"func_sort_arg_1": "Matriz para ordenar.",
	"func_sort_arg_2": "#SORT_ASC para classificar <b>array</b> em ordem crescente ou #SORT_DESC para classificá-lo em ordem decrescente.",
	"func_split": "Divide a string <b>string</b> em substrings delimitadas por <b>delimiter</b>.",
	"func_split_arg_1": "Cadeia de corte.",
	"func_split_arg_2": "String delimitando a passagem de um elemento para outro.",
	"func_split_arg_3": "Número máximo de elementos da matriz.",
	"func_split_return": "Array contendo as substrings encontradas.",
	"func_sqrt": "Calcula a raiz quadrada do número <b>número</b>.",
	"func_sqrt_arg_1": "Número cuja raiz será calculada.",
	"func_sqrt_return": "Raiz quadrada de <b>número</b>.",
	"func_startsWith": "Verifica se <b>string</b> começa com <b>prefixo</b>.",
	"func_startsWith_arg_1": "Seqüência de pesquisa.",
	"func_startsWith_arg_2": "Prefixo pesquisado.",
	"func_startsWith_return": "Verdadeiro se a <b>string</b> começar com <b>prefixo</b>.",
	"func_string": "Converte um valor em uma string.<br>\nSe o valor já for uma string, ele será retornado.<br>\nSe o valor for o número x, \"x\" será retornado.<br>\nSe o valor for uma matriz, uma string no formato \"[key1: value1, key2: value2, ...]\" será retornada.<br>\nSe o valor for booleano, \"true\" e \"false\" serão retornados para true e false, respectivamente.<br>\nSe o valor for nulo, \"nulo\" será retornado.",
	"func_string_arg_1": "O valor a ser convertido em uma cadeia de caracteres.",
	"func_string_return": "A string convertida.",
	"func_subArray": "Retorna um subarray de <b>array</b> começando na posição <b>start</b> e terminando na posição <b>end</b>.",
	"func_subArray_arg_1": "Tabela de origem.",
	"func_subArray_arg_2": "Índice inicial.",
	"func_subArray_arg_3": "Índice final.",
	"func_subArray_return": "Sub-array.",
	"func_substring": "Retorna a substring da string <b>string</b> a partir do caractere no índice <b>início</b> e de tamanho <b>comprimento</b>.",
	"func_substring_arg_1": "Cadeia de corte.",
	"func_substring_arg_2": "Índice de caracteres inicial.",
	"func_substring_arg_3": "Comprimento da substring.",
	"func_substring_return": "Substring.",
	"func_sum": "Retorna a soma de todos os elementos numéricos no array <b>array</b>.",
	"func_sum_arg_1": "Tabela para somar.",
	"func_summon": "Chama uma entidade determinada pelo chip <b>chip</b> na célula <b>célula</b> tendo por IA a função <b>ai</b>.",
	"func_summon_arg_1": "O chip usado para invocar. O chip deve ser do tipo summon e deve estar equipado na entidade que usa a função summon.",
	"func_summon_arg_2": "A célula ou convocação deve aparecer.",
	"func_summon_arg_3": "A invocação AI, como uma função.",
	"func_summon_return": "A função summon tem o mesmo retorno que a função #useChip.",
	"func_sum_return": "Soma dos elementos do array.",
	"func_tan": "Calcula a tangente do ângulo <b>ângulo</b> (em radianos).",
	"func_tan_arg_1": "Ângulo em radianos.",
	"func_tan_return": "Tangente do <b>ângulo</b>.",
	"function_category_array": "Listas",
	"function_category_chip": "Pulgas",
	"function_category_color": "Cor",
	"function_category_fight": "Lutar",
	"function_category_leek": "Alho-poró",
	"function_category_map": "Tabelas",
	"function_category_field": "Chão",
	"function_category_math": "Números",
	"function_category_network": "Rede",
	"function_category_string": "Correntes",
	"function_category_utils": "Serviços de utilidade pública",
	"function_category_weapon": "Armas",
	"func_toDegrees": "Converte o ângulo <b>radianos</b> em graus.",
	"func_toDegrees_arg_1": "Valor em radianos",
	"func_toDegrees_return": "Ângulo em graus.",
	"func_toLower": "Converte caracteres maiúsculos em <b>string</b> para minúsculos.",
	"func_toLower_arg_1": "String para transformar",
	"func_toLower_return": "Cadeia de caracteres em minúsculas",
	"func_toRadians": "Converte <b>graus</b> do ângulo em radianos.",
	"func_toRadians_arg_1": "Valor em graus",
	"func_toRadians_return": "Ângulo em radianos.",
	"func_toUpper": "Converte caracteres minúsculos em <b>string</b> para maiúsculos.",
	"func_toUpper_arg_1": "String para transformar.",
	"func_toUpper_return": "Seqüência de letras maiúsculas.",
	"func_typeOf": "Retorna o tipo do valor <b>value</b>, dentre os tipos: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "O valor cujo tipo será retornado.",
	"func_typeOf_return": "O tipo de <b>valor</b>.",
	"func_unshift": "Adiciona o elemento <b>element</b> ao início do <b>array</b>.",
	"func_unshift_arg_1": "Array no qual o elemento será adicionado.",
	"func_unshift_arg_2": "Artigo a adicionar.",
	"func_useChip": "Use chip <b>chip</b> na entidade <b>entity</b>.",
	"func_useChip_arg_1": "Chip para usar.",
	"func_useChip_arg_2": "Entidade alvo.",
	"func_useChipOnCell": "Use chip <b>chip</b> na célula <b>célula</b>.",
	"func_useChipOnCell_arg_1": "Chip para usar.",
	"func_useChipOnCell_arg_2": "Célula Alvo.",
	"func_useChipOnCell_return": "Um valor maior que 0 se o ataque foi lançado.",
	"func_useChip_return": "Os valores de retorno de useChip são:<br>\n<ul>\n<li>#USE_CRITICAL, em acerto crítico</li>\n<li>#USE_SUCCESS, se bem-sucedido</li>\n<li>#USE_FAILED, em caso de falha</li>\n<li>#USE_INVALID_TARGET, se o destino não existir</li>\n<li>#USE_NOT_ENOUGH_TP, se sua entidade não tiver TP suficiente</li>\n<li>#USE_INVALID_COOLDOWN, se o chip ainda não puder ser usado</li>\n<li>#USE_INVALID_POSITION, se o alcance for ruim ou a linha de visão não estiver clara</li>\n</ul>",
	"func_useWeapon": "Use a arma selecionada na entidade <b>entidade</b>.",
	"func_useWeapon_arg_1": "Entidade alvo.",
	"func_useWeaponOnCell": "Use a arma selecionada na célula <b>célula</b>.",
	"func_useWeaponOnCell_arg_1": "Célula Alvo.",
	"func_useWeaponOnCell_return": "Um valor maior que 0 se o ataque foi lançado.",
	"func_useWeapon_return": "Os valores de retorno de useWeapon são:<br>\n<ul>\n<li>#USE_CRITICAL, em acerto crítico</li>\n<li>#USE_SUCCESS, se bem-sucedido</li>\n<li>#USE_FAILED, em caso de falha</li>\n<li>#USE_INVALID_TARGET, se o destino não existir</li>\n<li>#USE_NOT_ENOUGH_TP, se sua entidade não tiver TP suficiente</li>\n<li>#USE_INVALID_POSITION, se o alcance for ruim ou a linha de visão não estiver clara</li>\n</ul>",
	"func_weaponNeedLos": "Retorna se a arma <b>weapon</b> precisa de linha de visão para disparar.",
	"func_weaponNeedLos_arg_1": "O ID da arma a ser testada.",
	"func_weaponNeedLos_return": "<i>true</i> se a arma <b>weapon</b> precisa de linha de visão para disparar, <i>false</i> caso contrário.",
	"items": "Unid",
	"max_cores": "máximo",
	"max_level": "máximo",
	"min_cores": "Corações mínimos",
	"min_level": "Nível mínimo",
	"n_operations": "<b>{0}</b> operações",
	"parameters": "Configurações",
	"return": "Opinião",
	"value": "Valor",
	"variable_operations": "Operações <b>Variáveis</b>",
	"deprecated_function": "Esta função está obsoleta.",
	"deprecated_constant": "Esta constante é obsoleta.",
	"replaced_by": "É substituído por {0}.",
	"details": "Detalhes",
	"optional": "opcional",
	"arg_type_9": "pacote",
	"arg_type_10": "intervalo",
	"function_category_set": "Pacotes",
	"function_category_interval": "Intervalos",
	"arg_type_96": "conjunto<entier>"
}

	i18n.mergeLocaleMessage("pt", { "doc": messages })
	