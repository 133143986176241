import { WeaponTemplate } from '@/model/weapon'

export const WEAPONS: {[key: string]: WeaponTemplate} = Object.freeze({
  '1': { id: 1, name: 'pistol', level: 1, min_range: 1, max_range: 7, launch_type: 7, effects: [ { id: 1, value1: 15, value2: 5, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 3, area: 1, los: true, template: 1, passive_effects: [], forgotten: false, item: 37, max_uses: 4 },
  '2': { id: 2, name: 'machine_gun', level: 8, min_range: 1, max_range: 6, launch_type: 1, effects: [ { id: 1, value1: 10, value2: 5, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 1, value1: 10, value2: 5, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 1, value1: 10, value2: 5, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 4, area: 1, los: true, template: 2, passive_effects: [], forgotten: false, item: 38, max_uses: 3 },
  '3': { id: 3, name: 'double_gun', level: 45, min_range: 2, max_range: 7, launch_type: 7, effects: [ { id: 1, value1: 18, value2: 7, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 13, value1: 9, value2: 3, turns: 2, targets: 31, modifiers: 1, type: 6 } ], cost: 4, area: 1, los: true, template: 3, passive_effects: [], forgotten: false, item: 39, max_uses: 3 },
  '4': { id: 4, name: 'shotgun', level: 16, min_range: 1, max_range: 5, launch_type: 1, effects: [ { id: 1, value1: 33, value2: 10, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 27, value1: 25, value2: 0, turns: 1, targets: 31, modifiers: 1, type: 0 } ], cost: 5, area: 1, los: true, template: 4, passive_effects: [], forgotten: false, item: 41, max_uses: 2 },
  '5': { id: 5, name: 'magnum', level: 27, min_range: 1, max_range: 8, launch_type: 7, effects: [ { id: 1, value1: 25, value2: 15, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 5, area: 1, los: true, template: 5, passive_effects: [], forgotten: false, item: 45, max_uses: 2 },
  '6': { id: 6, name: 'laser', level: 38, min_range: 2, max_range: 9, launch_type: 1, effects: [ { id: 1, value1: 43, value2: 16, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 6, area: 2, los: true, template: 6, passive_effects: [], forgotten: false, item: 42, max_uses: 2 },
  '7': { id: 7, name: 'grenade_launcher', level: 135, min_range: 4, max_range: 7, launch_type: 7, effects: [ { id: 1, value1: 55, value2: 8, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 6, area: 4, los: true, template: 7, passive_effects: [], forgotten: false, item: 43, max_uses: 2 },
  '8': { id: 8, name: 'flame_thrower', level: 90, min_range: 2, max_range: 8, launch_type: 1, effects: [ { id: 1, value1: 35, value2: 5, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 13, value1: 24, value2: 6, turns: 2, targets: 31, modifiers: 1, type: 6 } ], cost: 6, area: 2, los: true, template: 8, passive_effects: [], forgotten: false, item: 46, max_uses: 2 },
  '9': { id: 9, name: 'destroyer', level: 85, min_range: 1, max_range: 6, launch_type: 7, effects: [ { id: 1, value1: 40, value2: 20, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 19, value1: 17, value2: 0, turns: 2, targets: 31, modifiers: 1, type: 7 } ], cost: 6, area: 1, los: true, template: 9, passive_effects: [], forgotten: false, item: 40, max_uses: 2 },
  '10': { id: 10, name: 'gazor', level: 297, min_range: 2, max_range: 7, launch_type: 1, effects: [ { id: 13, value1: 27, value2: 5, turns: 3, targets: 31, modifiers: 1, type: 6 } ], cost: 8, area: 5, los: true, template: 10, passive_effects: [], forgotten: false, item: 48, max_uses: 2 },
  '11': { id: 11, name: 'electrisor', level: 211, min_range: 7, max_range: 7, launch_type: 7, effects: [ { id: 1, value1: 70, value2: 10, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 7, area: 3, los: true, template: 11, passive_effects: [], forgotten: false, item: 44, max_uses: 2 },
  '12': { id: 12, name: 'm_laser', level: 299, min_range: 5, max_range: 12, launch_type: 1, effects: [ { id: 1, value1: 90, value2: 10, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 8, area: 2, los: true, template: 12, passive_effects: [], forgotten: false, item: 47, max_uses: 2 },
  '13': { id: 13, name: 'b_laser', level: 95, min_range: 2, max_range: 8, launch_type: 1, effects: [ { id: 1, value1: 50, value2: 10, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 2, value1: 50, value2: 10, turns: 0, targets: 31, modifiers: 0, type: 2 } ], cost: 5, area: 2, los: true, template: 13, passive_effects: [], forgotten: false, item: 60, max_uses: 3 },
  '14': { id: 14, name: 'katana', level: 257, min_range: 1, max_range: 1, launch_type: 7, effects: [ { id: 1, value1: 77, value2: 0, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 18, value1: 0.3, value2: 0.1, turns: 1, targets: 31, modifiers: 1, type: 7 } ], cost: 7, area: 1, los: true, template: 14, passive_effects: [], forgotten: false, item: 107, max_uses: 2 },
  '15': { id: 15, name: 'broadsword', level: 30, min_range: 1, max_range: 1, launch_type: 7, effects: [ { id: 1, value1: 39, value2: 2, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 38, value1: 40, value2: 0, turns: 2, targets: 31, modifiers: 5, type: 5 } ], cost: 5, area: 1, los: true, template: 15, passive_effects: [], forgotten: false, item: 108, max_uses: 2 },
  '16': { id: 16, name: 'axe', level: 110, min_range: 1, max_range: 1, launch_type: 7, effects: [ { id: 1, value1: 55, value2: 22, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 17, value1: 0.7, value2: 0.1, turns: 1, targets: 31, modifiers: 1, type: 7 } ], cost: 6, area: 1, los: true, template: 16, passive_effects: [], forgotten: false, item: 109, max_uses: 2 },
  '17': { id: 17, name: 'j_laser', level: 153, min_range: 5, max_range: 11, launch_type: 1, effects: [ { id: 27, value1: 25, value2: 0, turns: 2, targets: 31, modifiers: 1, type: 0 }, { id: 29, value1: 25, value2: 0, turns: 2, targets: 31, modifiers: 5, type: 0 } ], cost: 5, area: 2, los: true, template: 17, passive_effects: [], forgotten: false, item: 115, max_uses: 3 },
  '18': { id: 18, name: 'illicit_grenade_launcher', level: 136, min_range: 4, max_range: 7, launch_type: 7, effects: [ { id: 1, value1: 10, value2: 0, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 1, value1: 10, value2: 0, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 1, value1: 10, value2: 0, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 1, value1: 10, value2: 0, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 6, area: 4, los: true, template: 18, passive_effects: [ { id: 33, value1: 10, value2: 0, turns: -1, targets: 31, modifiers: 1, type: 0 } ], forgotten: true, item: 116, max_uses: 2 },
  '19': { id: 19, name: 'mysterious_electrisor', level: 212, min_range: 7, max_range: 7, launch_type: 7, effects: [ { id: 30, value1: 34, value2: 6, turns: 0, targets: 31, modifiers: 1, type: 1 }, { id: 26, value1: 7, value2: 0, turns: 2, targets: 31, modifiers: 1, type: 0 } ], cost: 7, area: 3, los: true, template: 19, passive_effects: [ { id: 34, value1: 4, value2: 0, turns: -1, targets: 31, modifiers: 1, type: 0 } ], forgotten: true, item: 117, max_uses: 2 },
  '20': { id: 20, name: 'unbridled_gazor', level: 298, min_range: 2, max_range: 7, launch_type: 1, effects: [ { id: 1, value1: 81, value2: 4, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 8, area: 5, los: true, template: 20, passive_effects: [ { id: 36, value1: 20, value2: 0, turns: -1, targets: 31, modifiers: 1, type: 0 } ], forgotten: true, item: 118, max_uses: 2 },
  '21': { id: 21, name: 'revoked_m_laser', level: 300, min_range: 5, max_range: 12, launch_type: 1, effects: [ { id: 13, value1: 50, value2: 10, turns: 2, targets: 31, modifiers: 1, type: 6 } ], cost: 8, area: 2, los: true, template: 21, passive_effects: [ { id: 35, value1: 5, value2: 0, turns: -1, targets: 31, modifiers: 1, type: 0 } ], forgotten: true, item: 119, max_uses: 2 },
  '22': { id: 22, name: 'rifle', level: 271, min_range: 7, max_range: 9, launch_type: 7, effects: [ { id: 1, value1: 73, value2: 6, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 7, area: 1, los: true, template: 22, passive_effects: [], forgotten: false, item: 151, max_uses: 2 },
  '23': { id: 23, name: 'rhino', level: 187, min_range: 2, max_range: 4, launch_type: 7, effects: [ { id: 1, value1: 54, value2: 6, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 5, area: 1, los: true, template: 23, passive_effects: [], forgotten: false, item: 153, max_uses: 3 },
  '24': { id: 24, name: 'explorer_rifle', level: 272, min_range: 7, max_range: 9, launch_type: 7, effects: [ { id: 2, value1: 78, value2: 8, turns: 0, targets: 31, modifiers: 0, type: 2 } ], cost: 7, area: 1, los: true, template: 24, passive_effects: [ { id: 50, value1: 1, value2: 0, turns: 2, targets: 31, modifiers: 1, type: 0 } ], forgotten: true, item: 175, max_uses: 2 },
  '25': { id: 25, name: 'lightninger', level: 237, min_range: 6, max_range: 10, launch_type: 3, effects: [ { id: 1, value1: 99, value2: 8, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 9, area: 8, los: true, template: 25, passive_effects: [], forgotten: false, item: 180, max_uses: 2 },
  '27': { id: 27, name: 'neutrino', level: 12, min_range: 2, max_range: 6, launch_type: 2, effects: [ { id: 1, value1: 25, value2: 5, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 26, value1: 8, value2: 0, turns: 2, targets: 31, modifiers: 1, type: 0 } ], cost: 4, area: 1, los: true, template: 27, passive_effects: [], forgotten: false, item: 182, max_uses: 3 },
  '29': { id: 29, name: 'bazooka', level: 169, min_range: 8, max_range: 12, launch_type: 2, effects: [ { id: 1, value1: 110, value2: 8, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 11, area: 5, los: true, template: 29, passive_effects: [], forgotten: false, item: 184, max_uses: 1 },
  '32': { id: 32, name: 'dark_katana', level: 258, min_range: 1, max_range: 1, launch_type: 7, effects: [ { id: 1, value1: 99, value2: 0, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 26, value1: 15, value2: 0, turns: 1, targets: 31, modifiers: 5, type: 0 }, { id: 1, value1: 44, value2: 0, turns: 0, targets: 31, modifiers: 4, type: 1 } ], cost: 7, area: 1, los: true, template: 32, passive_effects: [ { id: 55, value1: 100, value2: 0, turns: -1, targets: 31, modifiers: 17, type: 0 } ], forgotten: true, item: 187, max_uses: 2 },
  '33': { id: 33, name: 'enhanced_lightninger', level: 238, min_range: 6, max_range: 10, launch_type: 7, effects: [ { id: 1, value1: 89, value2: 4, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 57, value1: 100, value2: 0, turns: 0, targets: 31, modifiers: 4, type: 0 } ], cost: 9, area: 11, los: true, template: 33, passive_effects: [ { id: 56, value1: 1, value2: 0, turns: -1, targets: 31, modifiers: 1, type: 0 } ], forgotten: true, item: 225, max_uses: 2 },
  '34': { id: 34, name: 'unstable_destroyer', level: 86, min_range: 1, max_range: 6, launch_type: 7, effects: [ { id: 1, value1: 10, value2: 80, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 6, area: 1, los: true, template: 34, passive_effects: [ { id: 58, value1: 50, value2: 100, turns: 0, targets: 31, modifiers: 0, type: 0 } ], forgotten: true, item: 226, max_uses: 2 },
  '35': { id: 35, name: 'sword', level: 75, min_range: 1, max_range: 1, launch_type: 7, effects: [ { id: 1, value1: 50, value2: 10, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 54, value1: 8, value2: 0, turns: 2, targets: 31, modifiers: 5, type: 0 } ], cost: 6, area: 1, los: true, template: 35, passive_effects: [], forgotten: false, item: 277, max_uses: 2 },
  '36': { id: 36, name: 'heavy_sword', level: 288, min_range: 1, max_range: 1, launch_type: 7, effects: [ { id: 1, value1: 156, value2: 17, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 27, value1: 60, value2: 0, turns: 1, targets: 31, modifiers: 1, type: 0 } ], cost: 15, area: 1, los: true, template: 36, passive_effects: [], forgotten: false, item: 278, max_uses: 1 },
  '37': { id: 37, name: 'odachi', level: 50, min_range: 1, max_range: 1, launch_type: 7, effects: [ { id: 1, value1: 100, value2: 20, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 9, area: 1, los: true, template: 37, passive_effects: [], forgotten: false, item: 408, max_uses: 1 },
  '38': { id: 38, name: 'excalibur', level: 100, min_range: 1, max_range: 1, launch_type: 7, effects: [ { id: 1, value1: 150, value2: 20, turns: 0, targets: 31, modifiers: 0, type: 1 }, { id: 13, value1: 150, value2: 20, turns: 2, targets: 31, modifiers: 1, type: 6 } ], cost: 12, area: 1, los: true, template: 38, passive_effects: [], forgotten: false, item: 409, max_uses: 1 },
  '39': { id: 39, name: 'scythe', level: 200, min_range: 1, max_range: 1, launch_type: 7, effects: [ { id: 1, value1: 300, value2: 50, turns: 0, targets: 31, modifiers: 0, type: 1 } ], cost: 15, area: 1, los: true, template: 39, passive_effects: [], forgotten: false, item: 410, max_uses: 1 }
})