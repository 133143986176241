
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> operasi",
	"arg_type_-1": "?",
	"arg_type_1": "nomor",
	"arg_type_2": "rantai",
	"arg_type_3": "boolean",
	"arg_type_4": "daftar",
	"arg_type_41": "daftar<nomor>",
	"arg_type_42": "daftar<string>",
	"arg_type_43": "daftar<boolean>",
	"arg_type_44": "daftar<daftar>",
	"arg_type_46": "daftar<bilangan bulat>",
	"arg_type_47": "daftar<nyata>",
	"arg_type_5": "fungsi",
	"arg_type_6": "seluruh",
	"arg_type_7": "nyata",
	"arg_type_8": "meja",
	"arg_type_806": "tabel<?, bilangan bulat>",
	"complexity": "Kompleksitas {c}",
	"operations": "{o} operasi",
	"details": "Detail",
	"optional": "opsional",
	"deprecated_function": "Fungsi ini sudah tidak digunakan lagi.",
	"deprecated_constant": "Konstanta ini sudah usang.",
	"replaced_by": "Itu diganti dengan {0}.",
	"const_AREA_CIRCLE_1": "Luas lingkaran 3 kotak dengan diameter (silang).",
	"const_AREA_CIRCLE_2": "Luas lingkaran 5 kotak dengan diameter.",
	"const_AREA_CIRCLE_3": "Luas lingkaran 7 kotak dengan diameter.",
	"const_AREA_LASER_LINE": "Zona laser, garis dari jangkauan minimum laser ke jangkauan maksimumnya atau penghalang.",
	"const_AREA_POINT": "Area yang terdiri dari satu sel.",
	"const_CELL_EMPTY": "Mengembalikan nilai getCellContent(cell) untuk sel kosong.",
	"const_CELL_OBSTACLE": "Mengembalikan nilai getCellContent(cell) untuk sel yang berisi rintangan.",
	"const_CELL_PLAYER": "Mengembalikan nilai getCellContent(cell) untuk sel yang berisi entitas.",
	"const_CELL_ENTITY": "Mengembalikan nilai getCellContent(cell) untuk sel yang berisi entitas.",
	"const_COLOR_BLUE": "Warna biru.",
	"const_COLOR_GREEN": "Warna hijau.",
	"const_COLOR_RED": "Warna merah.",
	"const_E": "Nomor Euler.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Memberikan perisai absolut ke entitas, mengurangi jumlah poin hit yang dihilangkan oleh kerusakan (EFFECT_DAMAGE) dengan jumlah yang tetap. Diperkuat oleh resistensi.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Menghapus perisai absolut dari suatu entitas. Tidak diperkuat oleh karakteristik. Memungkinkan Anda meningkatkan poin hit yang dihilangkan oleh kerusakan (EFFECT_DAMAGE) dengan jumlah absolut.",
	"const_EFFECT_AFTEREFFECT": "Menghapus hit point dari entitas. Diperkuat oleh sains. Mengurangi poin hit maksimum sebesar 5% dari jumlah poin hit yang dihapus.",
	"const_EFFECT_ANTIDOTE": "Menghapus semua racun (EFFECT_POISON) yang ada pada target.",
	"const_EFFECT_BOOST_MAX_LIFE": "Meningkatkan poin hit dan poin hit maksimum dari suatu entitas. Diperkuat oleh kebijaksanaan.",
	"const_EFFECT_BUFF_AGILITY": "Memberikan kelincahan pada entitas. Diperkuat oleh sains.",
	"const_EFFECT_BUFF_MP": "Memberikan poin pergerakan ke entitas. Diperkuat oleh sains.",
	"const_EFFECT_BUFF_RESISTANCE": "Memberikan resistensi ke entitas. Diperkuat oleh sains.",
	"const_EFFECT_BUFF_STRENGTH": "Memberikan kekuatan pada entitas. Diperkuat oleh sains.",
	"const_EFFECT_BUFF_TP": "Memberikan poin tindakan ke entitas. Diperkuat oleh sains.",
	"const_EFFECT_BUFF_WISDOM": "Berikan kebijaksanaan kepada entitas. Diperkuat oleh sains.",
	"const_EFFECT_DAMAGE": "Menghapus hit point dari entitas. Kekuatan Dikuatkan. Berinteraksi dengan perisai (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), lifesteal (kecuali caster), dan damage return (EFFECT_DAMAGE_RETURN). Mengurangi poin hit maksimum sebesar 5% dari jumlah poin hit yang dihapus.",
	"const_EFFECT_DAMAGE_RETURN": "Memberikan refleksi kerusakan pada suatu entitas, memungkinkan untuk menghilangkan poin hit dari entitas yang menimbulkan kerusakan pada penerima. Diperkuat oleh kelincahan. Mengurangi poin hit maksimum sebesar 5% dari jumlah poin hit yang dihilangkan.",
	"const_EFFECT_DEBUFF": "Mengurangi nilai semua efek pada entitas dengan persentase.",
	"const_EFFECT_HEAL": "Mengembalikan poin hit ke entitas, dibatasi oleh poin hit maksimum. Diperkuat oleh kebijaksanaan.",
	"const_EFFECT_INVERT": "Tukar posisi kastor dengan posisi entitas.",
	"const_EFFECT_KILL": "Menghapus semua hit point dari entitas.",
	"const_EFFECT_LIFE_DAMAGE": "Menghapus poin hit dari entitas, berdasarkan persentase poin hit kastor. Berinteraksi dengan perisai (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) dan pengembalian kerusakan (EFFECT_DAMAGE_RETURN). Mengurangi poin hit maksimum sebesar 5% dari jumlah poin hit yang dihilangkan.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "Efeknya dikalikan dengan jumlah entitas yang terkena dampak di area tersebut.",
	"const_EFFECT_MODIFIER_ON_CASTER": "Efeknya masih mempengaruhi kastor.",
	"const_EFFECT_MODIFIER_STACKABLE": "Efeknya kumulatif.",
	"const_EFFECT_NOVA_DAMAGE": "Menghapus poin kehidupan maksimal. Diperkuat oleh sains.",
	"const_EFFECT_POISON": "Menghapus hit point dari entitas. Diperkuat dengan sihir. Mengurangi poin hit maksimum sebesar 10% dari jumlah poin hit yang dihapus.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Memberikan perisai absolut ke entitas, mengurangi jumlah poin hit yang dihilangkan oleh kerusakan (EFFECT_DAMAGE) dengan jumlah yang tetap. Tidak dapat diamplifikasi.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Memberikan kelincahan pada entitas. Tidak dapat diamplifikasi.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Memberikan sihir ke entitas. Tidak dapat diamplifikasi.",
	"const_EFFECT_RAW_BUFF_MP": "Memberikan poin pergerakan ke entitas. Tidak dapat diamplifikasi.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Memberikan sains kepada entitas. Tidak dapat diamplifikasi.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Memberikan kekuatan pada entitas. Tidak dapat diamplifikasi.",
	"const_EFFECT_RAW_BUFF_TP": "Memberikan poin tindakan ke entitas. Tidak dapat diamplifikasi.",
	"const_EFFECT_RELATIVE_SHIELD": "Memberikan perisai relatif, mengurangi jumlah hit point yang dihilangkan oleh kerusakan (EFFECT_DAMAGE) dengan jumlah relatif. Diperkuat oleh resistensi.",
	"const_EFFECT_RESURRECT": "Membangkitkan entitas, dengan nomor HP maksimum sama dengan setengah nomor HP maksimum entitas sebelum kebangkitan, dan nomor HP saat ini sama dengan seperempat nomor HP maksimum sebelum kebangkitan.",
	"const_EFFECT_SHACKLE_MAGIC": "Hapus sihir dari entitas. Diperkuat dengan sihir.",
	"const_EFFECT_SHACKLE_MP": "Hapus titik pergerakan dari entitas. Diperkuat dengan sihir.",
	"const_EFFECT_SHACKLE_STRENGTH": "Menghilangkan kekuatan dari suatu entitas. Diperkuat dengan sihir.",
	"const_EFFECT_SHACKLE_TP": "Kurangi poin tindakan dari entitas. Diperkuat dengan sihir.",
	"const_EFFECT_SUMMON": "Memanggil bohlam. Tidak berpengaruh jika batas pemanggilan tim tercapai.",
	"const_EFFECT_TARGET_ALLIES": "Mempengaruhi sekutu.",
	"const_EFFECT_TARGET_CASTER": "Mempengaruhi kastor.",
	"const_EFFECT_TARGET_ENEMIES": "Mempengaruhi musuh.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Mempengaruhi entitas yang tidak dipanggil (Leeks dan menara).",
	"const_EFFECT_TARGET_SUMMONS": "Mempengaruhi entitas yang dipanggil (Umbi).",
	"const_EFFECT_TELEPORT": "Mengubah posisi kastor.",
	"const_EFFECT_VULNERABILITY": "Menghapus perisai relatif terhadap entitas. Tidak diperkuat oleh karakteristik. Meningkatkan poin hit yang dihapus oleh kerusakan (EFFECT_DAMAGE) dengan jumlah relatif.",
	"const_ENTITY_BULB": "Mengacu pada entitas tipe Bulb.",
	"const_ENTITY_LEEK": "Mengacu pada entitas tipe daun bawang.",
	"const_ENTITY_TURRET": "Mengacu pada entitas tipe Turret.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Latar belakang tempur tipe tantangan.",
	"const_FIGHT_CONTEXT_GARDEN": "Konteks pertarungan di kebun sayur.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Konteks pertempuran di Battle Royale.",
	"const_FIGHT_CONTEXT_TEST": "Uji konteks pertempuran.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Latar belakang pertarungan turnamen.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Bertarung di Battle Royale.",
	"const_FIGHT_TYPE_FARMER": "Pertarungan peternak.",
	"const_FIGHT_TYPE_SOLO": "Pertarungan tunggal.",
	"const_FIGHT_TYPE_TEAM": "Pertarungan tim.\n",
	"const_INSTRUCTIONS_LIMIT": "Jumlah maksimum instruksi yang dapat digunakan entitas selama gilirannya.",
	"const_MAX_TURNS": "Jumlah putaran maksimum dalam pertarungan.",
	"const_OPERATIONS_LIMIT": "Jumlah maksimum operasi yang dapat digunakan entitas selama gilirannya.",
	"const_PI": "Rasio keliling lingkaran dengan diameternya.",
	"const_SORT_ASC": "Beri tahu fungsi #sort untuk mengurutkan dalam urutan menaik.",
	"const_SORT_DESC": "Beri tahu fungsi #sort untuk mengurutkan dalam urutan menurun.",
	"const_TYPE_ARRAY": "Jenis nilai <b>daftar</b>",
	"const_TYPE_BOOLEAN": "Jenis nilai <b>boolean</b>",
	"const_TYPE_FUNCTION": "Jenis nilai <b>fungsi</b>",
	"const_TYPE_NULL": "Jenis nilai <b>null</b>",
	"const_TYPE_NUMBER": "Jenis nilai <b>angka</b>",
	"const_TYPE_STRING": "Jenis nilai <b>string karakter</b>",
	"const_USE_CRITICAL": "Nilai yang dikembalikan oleh fungsi #useWeapon, #useWeaponOnCell, #useChip, dan #useChipOnCell jika terjadi serangan kritis.",
	"const_USE_FAILED": "Nilai dikembalikan oleh fungsi #useWeapon, #useWeaponOnCell, #useChip dan #useChipOnCell pada kegagalan.",
	"const_USE_INVALID_COOLDOWN": "Nilai dikembalikan oleh fungsi #useChip dan #useChipOnCell jika chip belum dapat digunakan.",
	"const_USE_INVALID_POSITION": "Nilai yang dikembalikan oleh fungsi #useWeapon, #useWeaponOnCell, #useChip, dan #useChipOnCell jika jangkauannya buruk atau garis pandang tidak jelas.",
	"const_USE_INVALID_TARGET": "Nilai dikembalikan oleh fungsi #useWeapon dan #useChip jika target tidak ada.",
	"const_USE_NOT_ENOUGH_TP": "Nilai yang dikembalikan oleh fungsi #useWeapon, #useWeaponOnCell, #useChip, dan #useChipOnCell jika kastor tidak memiliki cukup poin tindakan untuk menggunakan objek.",
	"const_USE_RESURRECT_INVALID_ENTITY": "Nilai yang dikembalikan oleh fungsi #resurrect saat entitas yang ditentukan tidak ada atau belum mati.",
	"const_USE_SUCCESS": "Nilai yang dikembalikan oleh fungsi #useWeapon, #useWeaponOnCell, #useChip, dan #useChipOnCell jika berhasil.",
	"const_USE_TOO_MANY_SUMMONS": "Kesalahan dikembalikan oleh #summon saat Anda sudah memiliki <b>8</b> panggilan langsung.\n",
	"func_abs": "Mengembalikan nilai absolut dari angka <b>angka</b>.",
	"func_abs_arg_1": "Angka yang nilai absolutnya akan dihitung.",
	"func_abs_return": "Nilai absolut dari angka tersebut.",
	"func_acos": "Menghitung arccosine dari <b>argumen</b>, dalam interval [0, #PI].",
	"func_acos_arg_1": "Angka yang arccosine-nya akan dihitung.",
	"func_acos_return": "Arc cosinus dari <b>argumen</b>.",
	"func_arrayConcat": "Tambahkan dua array ujung ke ujung. Kunci teks dipertahankan dan semua kunci numerik diindeks ulang.",
	"func_arrayConcat_arg_1": "Tabel pertama.",
	"func_arrayConcat_arg_2": "Tabel kedua.",
	"func_arrayConcat_return": "Jumlah dari dua array.",
	"func_arrayFilter": "Mengembalikan larik baru yang berisi semua pasangan kunci/nilai dari larik sumber yang fungsi callback-nya mengembalikan true. Jika fungsi callback mengambil satu parameter, nilai array sumber akan dikirim, jika mengambil dua parameter, kunci dan nilai akan dikirim.",
	"func_arrayFilter_arg_1": "Meja asli.",
	"func_arrayFilter_arg_2": "Fungsi dipanggil untuk setiap elemen.",
	"func_arrayFilter_return": "Tabel baru.",
	"func_arrayFlatten": "Mengembalikan larik baru yang berisi semua elemen larik sumber. Semua elemen yang terkandung dalam sub-array diekstraksi ke dalam array baru Argumen kedalaman menentukan kedalaman sub-array maksimum untuk diekstrak.\n<kode>var larik = [1, 2, [[3, 4], 5], [6]];\ndebug(arrayFlatten(array, 1)); // [1,2,[3,4],5,6]</kode>",
	"func_arrayFlatten_arg_1": "Meja asli.",
	"func_arrayFlatten_arg_2": "Kedalaman maksimum.",
	"func_arrayFlatten_return": "Tabel baru.",
	"func_arrayFoldLeft": "Kurangi array <b>array</b> [v1, v2, ..., vn] dari kiri mulai dari nilai <b>v0</b> dan terapkan fungsi <b>f</b >. Sama dengan :\n<kode>f(f(f(v0, v1), v2), ...)</kode>",
	"func_arrayFoldLeft_arg_1": "Meja asli.",
	"func_arrayFoldLeft_arg_2": "Berfungsi untuk melamar.",
	"func_arrayFoldLeft_arg_3": "Nilai awal.",
	"func_arrayFoldLeft_return": "Tabel yang dikurangi.",
	"func_arrayFoldRight": "Kurangi array <b>array</b> [v1, v2, ..., vn] dari kanan mulai dari nilai <b>v0</b> dan terapkan fungsi <b>f</b >. Sama dengan :\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "Meja asli.",
	"func_arrayFoldRight_arg_2": "Berfungsi untuk melamar.",
	"func_arrayFoldRight_arg_3": "Nilai awal.",
	"func_arrayFoldRight_return": "Tabel yang dikurangi.",
	"func_arrayIter": "Memanggil fungsi callback untuk setiap elemen array. Jika fungsi callback mengambil satu parameter, nilai array sumber akan dikirim, jika mengambil dua parameter, kunci dan nilai akan dikirim.",
	"func_arrayIter_arg_1": "Meja asli.",
	"func_arrayIter_arg_2": "Fungsi dipanggil untuk setiap elemen.",
	"func_arrayMap": "Mengembalikan larik baru yang berisi untuk setiap kunci larik sumber, nilai yang dikembalikan oleh fungsi callback. Jika fungsi callback mengambil satu parameter, nilai array sumber akan dikirim, jika mengambil dua parameter, kunci dan nilai akan dikirim.",
	"func_arrayMap_arg_1": "Meja asli.",
	"func_arrayMap_arg_2": "Fungsi dipanggil untuk setiap elemen.",
	"func_arrayMap_return": "Tabel baru.",
	"func_arrayMax": "Mengembalikan elemen nilai maksimum dari larik <b>array</b>.<br/>Untuk informasi selengkapnya tentang nilai maksimum larik, lihat #sort.",
	"func_arrayMax_arg_1": "Array yang akan dicari nilai maksimumnya.",
	"func_arrayMax_return": "Barang bernilai maksimum.",
	"func_arrayMin": "Mengembalikan elemen nilai minimum larik <b>array</b>.<br/>Untuk informasi selengkapnya tentang nilai minimum larik, lihat #sort.",
	"func_arrayMin_arg_1": "Array yang akan dicari nilai minimumnya.",
	"func_arrayMin_return": "Barang dengan nilai minimum.",
	"func_arrayPartition": "Mengembalikan larik baru yang berisi dua daftar, yang pertama adalah semua pasangan kunci/nilai yang fungsi panggilan baliknya benar, yang kedua adalah yang lainnya. Jika fungsi callback mengambil satu parameter, nilai array sumber akan dikirim, jika mengambil dua parameter, kunci dan nilai akan dikirim.",
	"func_arrayPartition_arg_1": "Meja asli.",
	"func_arrayPartition_arg_2": "Fungsi dipanggil untuk setiap elemen.",
	"func_arrayPartition_return": "Tabel baru.",
	"func_arraySort": "Mengurutkan larik menurut urutan yang ditentukan oleh fungsi callback. Elemen dibandingkan dua per dua, fungsi panggilan balik harus mengembalikan nilai -1, 0 atau 1 tergantung pada apakah nilai pertama sebelum, pada level yang sama, atau setelah nilai kedua. Jika fungsi panggilan balik mengambil 2 parameter, dua nilai dikirim, jika dibutuhkan 4, pasangan kunci/nilai dikirim.",
	"func_arraySort_arg_1": "Tabel asli",
	"func_arraySort_arg_2": "Sortir fungsi.",
	"func_arraySort_return": "Array yang diurutkan",
	"func_asin": "Menghitung arcsine <b>argumen</b>, dalam interval [0, #PI].",
	"func_asin_arg_1": "Angka yang arcsine-nya akan dihitung.",
	"func_asin_return": "Arcsine dari <b>argumen</b>.",
	"func_assocSort": "Mengurutkan larik <b>array</b> yang mempertahankan asosiasi kunci:nilai menurut urutan <b>urutan</b>.",
	"func_assocSort_arg_1": "Array untuk mengurutkan.",
	"func_assocSort_arg_2": "Urutan sortir: #SORT_ASC atau #SORT_DESC.",
	"func_atan": "Menghitung arctangen <b>argumen</b>, dalam interval [0, #PI].",
	"func_atan2": "Mengonversi koordinat Cartesian (<b>x</b>, <b>y</b>) menjadi koordinat polar (<b>r</b>, <b>theta</b>). Fungsi ini mengembalikan sudut <b>theta</b> antara -#PI dan #PI menggunakan tanda argumen.",
	"func_atan2_arg_1": "Koordinat y.",
	"func_atan2_arg_2": "Koordinat x.",
	"func_atan2_return": "Sudut <b>theta</b> dalam koordinat kutub titik (x, y).",
	"func_atan_arg_1": "Angka yang arctangennya akan dihitung.",
	"func_atan_return": "Garis singgung busur dari <b>argumen</b>.",
	"func_average": "Menghitung rata-rata elemen yang terkandung dalam larik <b>array</b>.",
	"func_average_arg_1": "Array yang rata-ratanya ingin kita hitung.",
	"func_average_return": "Nilai rata-rata.",
	"func_canUseChip": "Menentukan apakah entitas Anda dapat menggunakan chip <b>chip</b> pada entitas dengan id <b>entitas</b>.",
	"func_canUseChip_arg_1": "Jumlah chip yang akan diuji.",
	"func_canUseChip_arg_2": "ID entitas tempat Anda ingin menggunakan chip.",
	"func_canUseChip_return": "<i>benar</i> jika entitas Anda dapat menggunakan chip, <i>salah</i> jika tidak.",
	"func_canUseChipOnCell": "Menentukan apakah entitas Anda dapat menggunakan chip <b>chip</b> pada sel <b>cell</b>.",
	"func_canUseChipOnCell_arg_1": "Jumlah chip yang akan diuji.",
	"func_canUseChipOnCell_arg_2": "Jumlah sel tempat Anda ingin menggunakan chip.",
	"func_canUseChipOnCell_return": "<i>benar</i> jika entitas Anda dapat menggunakan chip, <i>salah</i> jika tidak.",
	"func_canUseWeapon": "Menentukan apakah entitas Anda dapat menembak entitas dengan id <b>entitas</b> dengan senjata <b>senjata</b>.",
	"func_canUseWeapon_arg_1": "Senjata untuk menguji. Default untuk senjata Anda saat ini dilengkapi.",
	"func_canUseWeapon_arg_2": "ID entitas yang ingin Anda aktifkan.",
	"func_canUseWeapon_return": "<i>benar</i> jika entitas Anda dapat diaktifkan, <i>salah</i> jika tidak.",
	"func_canUseWeaponOnCell": "Menentukan apakah entitas Anda dapat menembak sel <b>sel</b> dengan senjata <b>senjata</b>.",
	"func_canUseWeaponOnCell_arg_1": "Senjata untuk menguji. Default untuk senjata Anda saat ini dilengkapi.",
	"func_canUseWeaponOnCell_arg_2": "Jumlah sel yang ingin Anda tembak.",
	"func_canUseWeaponOnCell_return": "<i>benar</i> jika entitas Anda dapat diaktifkan, <i>salah</i> jika tidak.",
	"func_cbrt": "Hitung akar pangkat tiga dari <b>bilangan</b>",
	"func_cbrt_arg_1": "Bilangan yang akar pangkat tiganya ingin kita hitung.",
	"func_cbrt_return": "Akar pangkat tiga dari <b>bilangan</b>.",
	"func_ceil": "Mengembalikan pembulatan ke atas dari <b>angka</b>.<br/>Anda dapat menemukan pembulatan ke bawah dengan #floor, dan pembulatan dengan #round.",
	"func_ceil_arg_1": "Angka yang ingin kita temukan pembulatannya ke atas.",
	"func_ceil_return": "Bulatkan <b>angka</b>.",
	"func_charAt": "Mengembalikan karakter string <b>string</b> yang terletak di posisi <b>position</b>.<br/>Karakter pertama string berada di posisi 0.",
	"func_charAt_arg_1": "String dari mana kita ingin mengambil karakter.",
	"func_charAt_arg_2": "Posisi karakter untuk menemukan.",
	"func_charAt_return": "string berisi karakter yang dicari, atau string kosong jika posisinya tidak valid.",
	"func_chipNeedLos": "Mengembalikan apakah <b>chip</b> perlu saling berhadapan untuk digunakan.",
	"func_chipNeedLos_arg_1": "ID chip yang akan diuji.",
	"func_chipNeedLos_return": "<i>benar</i> jika <b>chip</b> perlu saling berhadapan untuk digunakan, <i>salah</i> sebaliknya.",
	"func_clearMarks": "Menghapus semua tanda yang dibuat oleh mark() dan markText() di bidang.",
	"func_clone": "Menyalin <b>nilai</b> yang diteruskan sebagai parameter pada level <b>level</b> dan mengembalikan salinannya. Dalam kasus array misalnya, clone(array, 1) akan menyalin array tetapi bukan elemennya, clone(array, 2) akan menyalin array serta semua elemen.",
	"func_clone_arg_1": "Nilai untuk mengkloning",
	"func_clone_arg_2": "Jumlah level yang akan dikloning",
	"func_clone_return": "Nilai kloning",
	"func_contains": "Menentukan apakah string <b>penelusuran</b> berada di dalam string <b>string</b>.",
	"func_contains_arg_1": "String tempat pencarian akan dilakukan.",
	"func_contains_arg_2": "String untuk dicari.",
	"func_contains_return": "benar jika <b>pencarian</b> ada di <b>string</b>, salah jika sebaliknya.",
	"func_cos": "Menghitung kosinus <b>sudut</b>.",
	"func_cos_arg_1": "Angka yang cosinusnya akan dihitung (dalam radian).",
	"func_cos_return": "Kosinus <b>sudut</b>, dalam rentang [-1, 1].",
	"func_count": "Menghitung jumlah elemen dalam larik <b>array</b>.",
	"func_count_arg_1": "Array yang jumlah elemennya akan dihitung.",
	"func_count_return": "Jumlah elemen larik.",
	"func_debug": "Rekam pesan <b>objek</b> di log pribadi, tersedia dalam laporan di akhir pertarungan.",
	"func_debug_arg_1": "Pesan yang akan direkam.",
	"func_debugC": "Rekam pesan <b>objek</b> di log pribadi, tersedia dalam laporan di akhir pertarungan, dari warna <b>warna</b>.",
	"func_debugC_arg_1": "Pesan yang akan direkam.",
	"func_debugC_arg_2": "Warna pesan. Anda dapat menggunakan fungsi #getColor.",
	"func_debugE": "Merekam pesan kesalahan <b>objek</b> di log pribadi, tersedia dalam laporan di akhir pertarungan.<br />Pesan kesalahan muncul dengan warna merah di laporan pertarungan.",
	"func_debugE_arg_1": "Pesan kesalahan untuk login.",
	"func_debugW": "Merekam pesan peringatan <b>objek</b> di log pribadi, tersedia dalam laporan di akhir pertarungan. <br />Pesan peringatan muncul dalam warna oranye di laporan pertempuran.",
	"func_debugW_arg_1": "Pesan peringatan untuk direkam.",
	"func_deleteRegister": "Menghapus registri yang terkait dengan kunci <b>key</b> jika ada.",
	"func_deleteRegister_arg_1": "Kunci registri untuk dihapus.",
	"func_endsWith": "Menentukan apakah <b>string</b> diakhiri dengan string <b>akhiran</b>.",
	"func_endsWith_arg_1": "String tempat pencarian akan dilakukan",
	"func_endsWith_arg_2": "Akhiran untuk mencari",
	"func_endsWith_return": "benar jika <b>string</b> diakhiri dengan <b>akhiran</b>",
	"func_exp": "Menaikkan nomor Euler #E menjadi <b>angka</b> pangkat.",
	"func_exp_arg_1": "Eksponen yang akan dipangkatkan #E.",
	"func_exp_return": "#E** <b>angka</b>.",
	"func_fill": "Mengubah ukuran array <b>array</b> menjadi <b>size</b> dan mengisi semua kotaknya dengan <b>nilai</b>.",
	"func_fill_arg_1": "Tabel yang akan diselesaikan.",
	"func_fill_arg_2": "Nilai untuk setiap kotak dalam larik.",
	"func_fill_arg_3": "Jumlah kotak. Secara default, ukuran array saat ini.",
	"func_floor": "Menghitung pembulatan ke bawah dari <b>angka</b>.<br/>Anda dapat menemukan pembulatan ke atas dengan #ceil, dan pembulatan dengan #round.",
	"func_floor_arg_1": "Angka yang ingin kita temukan pembulatannya ke bawah.",
	"func_floor_return": "Dibulatkan ke bawah menjadi <b>angka</b>.",
	"func_getAbsoluteShield": "Mengembalikan perisai absolut entitas dengan id <b>entitas</b>. Untuk langsung mengambil perisai absolut entitas Anda, gunakan #getAbsoluteShield() tanpa parameter.",
	"func_getAbsoluteShield_arg_1": "Id entitas yang perisai absolutnya akan dikembalikan.",
	"func_getAbsoluteShield_return": "Perisai absolut dari entitas <b>entitas</b>.",
	"func_getAgility": "Mengembalikan kelincahan entitas dengan id <b>entitas</b>. Untuk langsung mengambil kelincahan entitas Anda, gunakan #getAgility() tanpa parameter.",
	"func_getAgility_arg_1": "Id entitas yang kelincahannya akan dikembalikan.",
	"func_getAgility_return": "Kelincahan entitas <b>entitas</b>.",
	"func_getAIID": "Mengembalikan ID AI dari entitas <b>entitas</b>. Gunakan getAIID tanpa parameter untuk mengambil id AI Anda.",
	"func_getAIID_arg_1": "Id entitas yang id AI-nya akan dikembalikan.",
	"func_getAIID_return": "Id AI entitas <b>entitas</b>.",
	"func_getAIName": "Mengembalikan nama AI entitas <b>entitas</b>. Gunakan getAINam() tanpa parameter untuk mengambil nama AI Anda.",
	"func_getAIName_arg_1": "Id entitas yang nama AI-nya akan dikembalikan.",
	"func_getAIName_return": "Nama AI entitas <b>entitas</b>.",
	"func_getAliveAllies": "Mengembalikan susunan semua sekutu Anda yang hidup dalam pertempuran.",
	"func_getAliveAllies_return": "Array yang berisi id dari semua sekutu Anda yang masih hidup.",
	"func_getAliveEnemies": "Mengembalikan susunan semua musuh Anda yang hidup dalam pertempuran.",
	"func_getAliveEnemiesCount": "Mengembalikan jumlah musuh yang masih hidup dalam pertarungan.",
	"func_getAliveEnemiesCount_return": "Jumlah musuh hidup.",
	"func_getAliveEnemies_return": "Array yang berisi id dari semua musuh Anda yang masih hidup.",
	"func_getAllChips": "Mengembalikan daftar semua chip dalam game.",
	"func_getAllChips_return": "Daftar semua chip dalam game.",
	"func_getAllEffects": "Mengembalikan daftar semua efek dalam game.",
	"func_getAllEffects_return": "Daftar semua efek dalam game.",
	"func_getAllWeapons": "Mengembalikan daftar semua senjata dalam game.",
	"func_getAllWeapons_return": "Daftar semua senjata dalam game.",
	"func_getAlliedTurret": "Mengembalikan id menara tim Anda atau -1 jika tidak ada.",
	"func_getAlliedTurret_return": "ID menara tim Anda.",
	"func_getEnemyTurret": "Mengembalikan id turret musuh atau -1 jika tidak ada.",
	"func_getEnemyTurret_return": "ID menara musuh.",
	"func_getAllies": "Mengembalikan array yang berisi sekutu Anda, dan entitas Anda.",
	"func_getAlliesCount": "Mengembalikan jumlah sekutu dalam pertarungan.",
	"func_getAlliesCount_return": "Jumlah sekutu.",
	"func_getAlliesLife": "Mengembalikan kesehatan total sekutu Anda.",
	"func_getAlliesLife_return": "Kesehatan total sekutu Anda.",
	"func_getAllies_return": "Tabel sekutu dan entitas Anda.",
	"func_getBirthTurn": "Mengembalikan giliran pertarungan tempat <b>entitas</b> muncul. Mengembalikan 1 jika itu adalah daun bawang misalnya, dan 5 jika itu adalah panggilan yang dipanggil pada giliran 5.",
	"func_getBirthTurn_arg_1": "Id entitas yang giliran kemunculannya akan dikembalikan.",
	"func_getBirthTurn_return": "Giliran pertarungan tempat <b>entitas</b> muncul.",
	"func_getBlue": "Mengembalikan jumlah biru dalam warna <b>warna</b>, antara 0 dan 255. Misalnya, getBlue(#COLOR_BLUE) = 255 dan getBlue(#COLOR_GREEN) = 0.",
	"func_getBlue_arg_1": "Warna yang rate birunya akan dikembalikan.",
	"func_getBlue_return": "Tingkat biru dalam warna <b>warna</b>",
	"func_getBulbChips": "Mengembalikan daftar chip yang akan dipasang pada bohlam yang dipanggil oleh chip <b>bulb_chip</b>.",
	"func_getBulbChips_arg_1": "Chips bohlam.",
	"func_getBulbChips_return": "Chip bola lampu yang dipanggil oleh chip <b>bulb_chip</b>.",
	"func_getCell": "Mengembalikan sel tempat entitas dengan id <b>entitas</b> berada. Gunakan getCell() tanpa parameter untuk mengambil sel Anda.",
	"func_getCell_arg_1": "Id entitas yang selnya akan dikembalikan.",
	"func_getCell_return": "Nomor sel tempat <b>entitas</b> berada.",
	"func_getCellContent": "Mengembalikan konten sel dengan id <b>cell</b>.",
	"func_getCellContent_arg_1": "Id sel yang isinya akan dikembalikan.",
	"func_getCellContent_return": "Isi sel <b>sel</b>: #CELL_EMPTY untuk sel kosong, #CELL_ENTITY untuk entitas, #CELL_OBSTACLE untuk penghalang.",
	"func_getCellDistance": "Mengembalikan jarak antara dua sel <b>sel1</b> dan <b>sel2</b>. <br />Jarak yang dikembalikan dinyatakan dalam jumlah sel, dan tidak memperhitungkan berbagai kendala antara dua sel.\nUntuk mendapatkan jarak saat gagak terbang, lihat #getDistance dan untuk mendapatkan jarak jalur antara dua sel yang menghindari rintangan, lihat #getPathLength.",
	"func_getCellDistance_arg_1": "ID sel awal.",
	"func_getCellDistance_arg_2": "ID sel kedatangan.",
	"func_getCellDistance_return": "Jarak antara dua sel <b>sel1</b> dan <b>sel2</b>.",
	"func_getCellFromXY": "Mengembalikan id sel pada posisi (<b>x</b>, <b>y</b>).",
	"func_getCellFromXY_arg_1": "Posisi x sel.",
	"func_getCellFromXY_arg_2": "Posisi y sel.",
	"func_getCellFromXY_return": "ID sel pada posisi (<b>x</b>, <b>y</b>), <b>null</b> jika sel tidak ada.",
	"func_getCellsToUseChip": "Mengembalikan daftar sel tempat entitas Anda dapat menggunakan chip <b>chip</b> pada entitas <b>entitas</b>.",
	"func_getCellsToUseChip_arg_1": "Chip yang ingin digunakan oleh entitas.",
	"func_getCellsToUseChip_arg_2": "Entitas sasaran.",
	"func_getCellsToUseChip_arg_3": "Larik sel untuk diabaikan.",
	"func_getCellsToUseChip_return": "Daftar sel tempat chip dapat digunakan.",
	"func_getCellsToUseChipOnCell": "Mengembalikan daftar sel tempat entitas Anda dapat menggunakan chip <b>chip</b> pada sel <b>sel</b>.",
	"func_getCellsToUseChipOnCell_arg_1": "Chip yang ingin digunakan oleh entitas.",
	"func_getCellsToUseChipOnCell_arg_2": "Sel sasaran.",
	"func_getCellsToUseChipOnCell_arg_3": "Larik sel untuk diabaikan.",
	"func_getCellsToUseChipOnCell_return": "Daftar sel tempat chip dapat digunakan.",
	"func_getCellsToUseWeapon": "Mengembalikan daftar sel tempat entitas Anda dapat menggunakan senjata <b>senjata</b> pada entitas <b>entitas</b>.",
	"func_getCellsToUseWeapon_arg_1": "Senjata untuk menguji. Default untuk senjata Anda saat ini dilengkapi.",
	"func_getCellsToUseWeapon_arg_2": "Entitas sasaran.",
	"func_getCellsToUseWeapon_arg_3": "Larik sel untuk diabaikan. Secara default array kosong.",
	"func_getCellsToUseWeapon_return": "Daftar sel tempat senjata dapat digunakan.",
	"func_getCellsToUseWeaponOnCell": "Mengembalikan daftar sel tempat entitas Anda dapat menggunakan senjata <b>senjata</b> pada sel <b>sel</b>.",
	"func_getCellsToUseWeaponOnCell_arg_1": "Senjata untuk menguji. Default untuk senjata Anda saat ini dilengkapi.",
	"func_getCellsToUseWeaponOnCell_arg_2": "Sel sasaran.",
	"func_getCellsToUseWeaponOnCell_arg_3": "Larik sel untuk diabaikan. Secara default array kosong.",
	"func_getCellsToUseWeaponOnCell_return": "Daftar sel tempat senjata dapat digunakan.",
	"func_getCellX": "Menentukan posisi di X sel <b>sel</b>.",
	"func_getCellX_arg_1": "Sel yang posisinya di X akan ditentukan.",
	"func_getCellX_return": "Posisi sel X.",
	"func_getCellY": "Menentukan posisi Y sel <b>sel</b>.",
	"func_getCellY_arg_1": "Sel yang posisinya di Y akan ditentukan.",
	"func_getCellY_return": "Posisi Y sel.",
	"func_getChipArea": "Mengembalikan jenis area efek dari <b>chip</b>.",
	"func_getChipArea_arg_1": "Chip yang tipe zonanya akan dikembalikan.",
	"func_getChipArea_return": "Jenis area chip <b>chip</b> di antara konstanta AREA_*:\n<ul>\n<li>#AREA_POINT: luas persegi tunggal</li>\n<li>#AREA_LASER_LINE: garis laser</li>\n<li>#AREA_CIRCLE_1: luas lingkaran berdiameter 3 persegi</li>\n<li>#AREA_CIRCLE_2: luas lingkaran dengan diameter 5 persegi</li>\n<li>#AREA_CIRCLE_3: luas lingkaran dengan diameter 7 persegi</li>\n</ul>",
	"func_getChipCooldown": "Mengembalikan waktu pemulihan chip <b>chip</b>, yang diambil dari pasar.",
	"func_getChipCooldown_arg_1": "Chip yang cooldownnya akan dikembalikan.",
	"func_getChipCooldown_return": "Cooldown <b>chip</b>.",
	"func_getChipCost": "Mengembalikan biaya dalam TP chip <b>chip</b>.",
	"func_getChipCost_arg_1": "Chip yang harganya akan dikembalikan.",
	"func_getChipCost_return": "Biaya <b>chip</b>.",
	"func_getChipEffectiveArea": "Mengembalikan daftar sel yang akan terpengaruh jika chip <b>chip</b> digunakan pada sel <b>sel</b> dari sel <b>dari</b>.",
	"func_getChipEffectiveArea_arg_1": "Chip yang akan diuji.",
	"func_getChipEffectiveArea_arg_2": "Sel sasaran.",
	"func_getChipEffectiveArea_arg_3": "Sel tempat chip digunakan.",
	"func_getChipEffectiveArea_return": "Larik yang berisi id dari semua sel yang akan terpengaruh.",
	"func_getChipEffects": "Mengembalikan efek dari chip <b>chip</b>.",
	"func_getChipEffects_arg_1": "Chip yang efeknya akan dikembalikan.",
	"func_getChipEffects_return": "Efek dari chip <b>chip</b>. Nilai hasil yang sama dengan fungsi #getWeaponEffects.",
	"func_getChipFailure": "Mengembalikan persentase risiko kegagalan chip <b>chip</b>.",
	"func_getChipFailure_arg_1": "Chip yang persentase kegagalannya akan dikembalikan.",
	"func_getChipFailure_return": "<b>chip</b> persentase kegagalan chip, bilangan bulat antara <b>0</b> dan <b>100</b>.",
	"func_getChipMaxRange": "Mengembalikan rentang maksimum chip <b>chip</b>.",
	"func_getChipMaxRange_arg_1": "Chip yang jangkauan maksimumnya akan dikembalikan.",
	"func_getChipMaxRange_return": "Rentang maksimum <b>chip</b>.",
	"func_getChipMaxScope": "Mengembalikan rentang maksimum chip <b>chip</b>.",
	"func_getChipMaxScope_arg_1": "Chip yang jangkauan maksimumnya akan dikembalikan.",
	"func_getChipMaxScope_return": "Rentang maksimum <b>chip</b>.",
	"func_getChipMinRange": "Mengembalikan rentang minimum dari chip <b>chip</b>.",
	"func_getChipMinRange_arg_1": "Chip yang jangkauan minimumnya akan dikembalikan.",
	"func_getChipMinRange_return": "Kisaran minimum <b>chip</b>.",
	"func_getChipMinScope": "Mengembalikan rentang minimum dari chip <b>chip</b>.",
	"func_getChipMinScope_arg_1": "Chip yang jangkauan minimumnya akan dikembalikan.",
	"func_getChipMinScope_return": "Kisaran minimum <b>chip</b>.",
	"func_getChipLaunchType": "Mengembalikan mode peluncuran chip <b>chip</b>, di antara konstanta LAUNCH_TYPE_*.",
	"func_getChipLaunchType_arg_1": "ID chip yang mode lemparnya akan dikembalikan.",
	"func_getChipLaunchType_return": "Mode melempar chip <b>chip</b>.",
	"func_getChipName": "Mengembalikan nama chip <b>chip</b>.",
	"func_getChipName_arg_1": "Chip yang namanya akan dikembalikan.",
	"func_getChipName_return": "Nama chip <b>chip</b>.",
	"func_getChips": "Mengembalikan butir entitas dengan id <b>entitas</b>.",
	"func_getChips_arg_1": "Id entitas yang pelurunya akan dikembalikan.",
	"func_getChips_return": "Array yang berisi bullet id dari entitas <b>entitas</b>.",
	"func_getChipTargets": "Mengembalikan entitas yang akan terpengaruh jika chip <b>chip</b> digunakan pada sel <b>sel</b>.",
	"func_getChipTargets_arg_1": "Chip yang akan diuji.",
	"func_getChipTargets_arg_2": "Sel sasaran.",
	"func_getChipTargets_return": "Larik yang berisi id semua entitas yang akan terpengaruh.",
	"func_getColor": "Mengembalikan bilangan bulat yang sesuai dengan warna (<b>merah</b>, <b>hijau</b>, <b>biru</b>) yang diberikan sebagai parameter.",
	"func_getColor_arg_1": "Nilai merah antara 0 dan 255.",
	"func_getColor_arg_2": "Nilai hijau antara 0 dan 255.",
	"func_getColor_arg_3": "Nilai biru antara 0 dan 255.",
	"func_getColor_return": "int sesuai dengan warna yang diberikan sebagai parameter.",
	"func_getCooldown": "Mengembalikan cooldown <b>chip</b> saat ini dari entitas <b>entity</b>.",
	"func_getCooldown_arg_1": "Chip yang cooldown saat ini akan dikembalikan.",
	"func_getCooldown_arg_2": "Entitas yang cooldown-nya akan dikembalikan.",
	"func_getCooldown_return": "Cooldown chip <b>chip</b> saat ini, ini adalah jumlah putaran sebelum chip dapat digunakan, <b>0</b> jika saat ini dapat digunakan.",
	"func_getCores": "Mengembalikan jumlah inti entitas dengan id <b>entitas</b>.",
	"func_getCores_arg_1": "Entitas yang jumlah core-nya akan dikembalikan.",
	"func_getCores_return": "Jumlah inti entitas <b>entitas</b>.",
	"func_getDate": "Mengembalikan tanggal pertarungan, dalam format dd/MM/yyyy.",
	"func_getDate_return": "Tanggal pertarungan.",
	"func_getTime": "Mengembalikan waktu pertempuran, dalam format HH:mm:ss.",
	"func_getTime_return": "Waktu pertarungan.",
	"func_getTimestamp": "Mengembalikan stempel waktu pertarungan, sama dengan jumlah detik sejak 1 Januari 1970.",
	"func_getTimestamp_return": "Stempel waktu pertarungan.",
	"func_getDamageReturn": "Mengembalikan tingkat pengembalian kerusakan entitas dengan id <b>entitas</b>.",
	"func_getDamageReturn_arg_1": "ID entitas tempat rujukan kerusakan akan dikembalikan.",
	"func_getDamageReturn_return": "Tingkat pengembalian kerusakan entitas dengan id <b>entitas</b> (dalam %).",
	"func_getDeadAllies": "Mengembalikan entitas ramah yang mati.",
	"func_getDeadAllies_return": "Tabel entitas mati yang bersekutu.",
	"func_getDeadEnemies": "Mengembalikan entitas musuh yang mati.",
	"func_getDeadEnemiesCount": "Mengembalikan jumlah musuh yang mati dalam pertempuran.",
	"func_getDeadEnemiesCount_return": "Jumlah musuh yang mati.",
	"func_getDeadEnemies_return": "Tabel entitas musuh yang mati.",
	"func_getDistance": "Menghitung jarak saat burung gagak terbang di antara dua sel <b>sel1</b> dan <b>sel2</b>. <br />Untuk mendapatkan jarak dalam jumlah sel, lihat #getCellDistance, dan untuk mendapatkan panjang jalur antara dua sel yang menghindari berbagai rintangan, lihat #getPathLength.",
	"func_getDistance_arg_1": "Sel awal.",
	"func_getDistance_arg_2": "Sel kedatangan.",
	"func_getDistance_return": "Jarak saat burung gagak terbang di antara dua sel.",
	"func_getPassiveEffects": "Mengembalikan daftar efek pasif entitas dengan id <b>entitas</b>. Untuk langsung mengambil daftar efek pasif entitas Anda, gunakan #getPassiveEffects() tanpa parameter.",
	"func_getPassiveEffects_arg_1": "Id entitas yang daftar efek pasifnya akan dikembalikan.",
	"func_getPassiveEffects_return": "Daftar efek pasif yang saat ini ada di entitas <b>entitas</b>.\nDaftar efek pasif adalah larik yang berisi efek.\nSebuah efek itu sendiri merupakan larik dari 7 kotak dengan bentuk: [<b>type</b>, <b>value</b>, <b>caster_id</b>, <b>turns</b >, <b>penting</b>, <b>item_id</b>, <b>target_id</b>]. Ini adalah struktur yang sama dengan efek klasik yang dikembalikan oleh fungsi #getEffects.",
	"func_getEnemies": "Mengembalikan entitas musuh (hidup atau mati) ke dalam pertempuran.",
	"func_getEnemiesCount": "Mengembalikan jumlah musuh dalam pertarungan.",
	"func_getEnemiesCount_return": "Jumlah musuh.",
	"func_getEnemiesLife": "Menghitung jumlah hit point dari semua entitas musuh.",
	"func_getEnemiesLife_return": "Jumlah hit point dari tim musuh.",
	"func_getEnemies_return": "Array yang berisi id dari semua entitas musuh.",
	"func_getEntityTurnOrder": "Mengembalikan nilai antara 1 dan n (jumlah entitas yang sedang dimainkan) yang menunjukkan posisi <b>entitas</b> dalam urutan pemutaran.",
	"func_getEntityTurnOrder_arg_1": "Id entitas yang perintah mainnya akan dikembalikan",
	"func_getEntityTurnOrder_return": "Tempatkan urutan permainan entitas <b>entitas</b>",
	"func_getFarmerID": "Mengembalikan id peternak dari entitas <b>entitas</b>.",
	"func_getFarmerID_arg_1": "Id entitas yang id peternaknya akan dikembalikan.",
	"func_getFarmerID_return": "ID pemulia entitas <b>entitas</b>.",
	"func_getFarmerCountry": "Mengembalikan negara pembiak entitas <b>entitas</b>.",
	"func_getFarmerCountry_arg_1": "ID entitas yang negara pembiaknya akan dikembalikan.",
	"func_getFarmerCountry_return": "Negara pemulia entitas <b>entitas</b>, atau \"?\" jika tidak diindikasikan.",
	"func_getFarmerName": "Mengembalikan nama pemulia dari entitas <b>entitas</b>.",
	"func_getFarmerName_arg_1": "Id entitas yang nama peternaknya akan dikembalikan.",
	"func_getFarmerName_return": "Nama peternak entitas <b>entitas</b>.",
	"func_getFarthestAlly": "Menentukan sekutu terjauh entitas Anda, saat burung gagak terbang.",
	"func_getFarthestAlly_return": "Id dari entitas ramah terjauh.",
	"func_getFarthestEnemy": "Menentukan musuh terjauh dari entitas Anda, saat burung gagak terbang.",
	"func_getFarthestEnemy_return": "Id dari entitas musuh terjauh.",
	"func_getFightID": "Mengembalikan id pertarungan saat ini.",
	"func_getFightID_return": "ID pertarungan saat ini.",
	"func_getFightContext": "Mengembalikan konteks pertarungan saat ini.",
	"func_getFightContext_return": "Bergantung pada konteks pertempuran: Uji Pertempuran (#FIGHT_CONTEXT_TEST), Pertempuran Arena (#FIGHT_CONTEXT_GARDEN), Pertempuran Turnamen (#FIGHT_CONTEXT_TOURNAMENT), Pertempuran Tantangan (#FIGHT_CONTEXT_CHALLENGE), Pertempuran Royale (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "Mengembalikan jenis pertempuran saat ini.",
	"func_getFightType_return": "Bergantung pada jenis pertarungan: Pertarungan solo (#FIGHT_TYPE_SOLO), Pertarungan petani (#FIGHT_TYPE_FARMER), Pertarungan tim (#FIGHT_TYPE_TEAM), Battle Royale (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "Mengembalikan kekuatan entitas dengan id <b>entitas</b>.",
	"func_getForce_arg_1": "Entitas yang kekuatannya akan dikembalikan.",
	"func_getForce_return": "Kekuatan entitas <b>entitas</b>.",
	"func_getFrequency": "Mengembalikan frekuensi entitas dengan id <b>entitas</b>. Gunakan getFrequency() tanpa parameter untuk mengambil frekuensi Anda.",
	"func_getFrequency_arg_1": "Entitas yang frekuensinya akan dikembalikan.",
	"func_getFrequency_return": "Frekuensi entitas <b>entitas</b>.",
	"func_getGreen": "Mengembalikan jumlah warna hijau dalam warna <b>warna</b>, antara 0 dan 255. Misalnya, getGreen(#COLOR_GREEN) = 255 dan getGreen(#COLOR_RED) = 0.",
	"func_getGreen_arg_1": "Warna yang tingkat hijaunya akan dikembalikan.",
	"func_getGreen_return": "Tingkat hijau dalam warna <b>warna</b>",
	"func_getInstructionsCount": "Mengembalikan jumlah instruksi yang telah dilakukan entitas Anda selama giliran saat ini.",
	"func_getInstructionsCount_return": "Jumlah instruksi yang dilakukan entitas Anda selama giliran saat ini.",
	"func_getLaunchedEffects": "Mengembalikan daftar efek yang disebabkan oleh entitas dengan id <b>entitas</b>.",
	"func_getLaunchedEffects_arg_1": "Id entitas yang daftar efek penyebabnya akan dikembalikan.",
	"func_getLaunchedEffects_return": "Daftar efek yang disebabkan oleh entitas dengan id <b>entity</b>, dalam bentuk yang sama dengan larik yang dikembalikan oleh #getEffects.",
	"func_getLeek": "Mengembalikan id entitas Anda.",
	"func_getLeek_return": "ID entitas Anda.",
	"func_getEntity": "Mengembalikan id entitas Anda.",
	"func_getEntity_return": "ID entitas Anda.",
	"func_getLeekID": "Mengembalikan id sebenarnya dari daun bawang dari id <b>leek</b>.",
	"func_getLeekID_arg_1": "Id daun bawang yang id aslinya akan dikembalikan.",
	"func_getLeekID_return": "ID sebenarnya dari <b>entitas</b> daun bawang.",
	"func_getLeekOnCell": "Mengembalikan entitas yang ada di sel <b>sel</b>.",
	"func_getLeekOnCell_arg_1": "Sel yang entitasnya ingin kita ambil.",
	"func_getLeekOnCell_return": "Id entitas pada sel, atau -1 jika sel tidak memiliki entitas.",
	"func_getEntityOnCell": "Mengembalikan entitas yang ada di sel <b>sel</b>.",
	"func_getEntityOnCell_arg_1": "Sel yang entitasnya ingin kita ambil.",
	"func_getEntityOnCell_return": "Id entitas pada sel, atau -1 jika sel tidak memiliki entitas.",
	"func_getLevel": "Mengembalikan tingkat entitas dengan id <b>entitas</b>.",
	"func_getLevel_arg_1": "Id entitas yang levelnya akan dikembalikan.",
	"func_getLevel_return": "Level entitas dengan id <b>entitas</b>.",
	"func_getLife": "Mengembalikan umur entitas saat ini dengan id <b>entitas</b>. Gunakan getLife() tanpa parameter untuk mengambil hidup Anda.",
	"func_getLife_arg_1": "Id dari entitas yang nyawanya akan dikembalikan.",
	"func_getLife_return": "Masa pakai entitas <b>entitas</b> saat ini.",
	"func_getMagic": "Mengembalikan keajaiban entitas dengan id <b>entitas</b>. Gunakan getMagic() tanpa parameter untuk mengambil sihir Anda.",
	"func_getMagic_arg_1": "Id entitas yang sihirnya akan dikembalikan.",
	"func_getMagic_return": "Keajaiban entitas id <b>entitas</b>.",
	"func_getMapType": "Mengembalikan jenis medan tempat pertarungan berlangsung (pabrik, gurun, hutan, dll.), di antara konstanta #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER, dan #MAP_BEACH.",
	"func_getMapType_return": "Jenis medan.",
	"func_getMessageAuthor": "Mengembalikan id entitas yang menulis pesan <b>pesan</b>.",
	"func_getMessageAuthor_arg_1": "Pesan yang penulisnya akan dikembalikan.",
	"func_getMessageAuthor_return": "ID entitas pembuat pesan <b>pesan</b>.",
	"func_getMessageParams": "Mengembalikan larik parameter pesan <b>pesan</b>.",
	"func_getMessageParams_arg_1": "Pesan yang parameternya akan dikembalikan.",
	"func_getMessageParams_return": "Parameter pesan <b>pesan</b>.",
	"func_getMessages": "Mengembalikan larik pesan entitas <b>entitas</b>.",
	"func_getMessages_arg_1": "Entitas yang pesannya akan dikembalikan.",
	"func_getMessages_return": "Tabel pesan Anda.<br>Pesan itu sendiri direpresentasikan dalam bentuk a\nlarik bentuk: [<b>penulis</b>, <b>ketik</b>, <b>parameter</b>]<br>\nBerbagai jenis pesan diwakili oleh konstanta:\n<ul>\n<li>#MESSAGE_HEAL: permintaan perhatian</li>\n<li>#MESSAGE_ATTACK: permintaan untuk menyerang</li>\n<li>#MESSAGE_BUFF_FORCE: paksa permintaan peningkatan</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "Mengembalikan jenis pesan <b>pesan</b>.",
	"func_getMessageType_arg_1": "Pesan yang jenisnya akan dikembalikan (antara #MESSAGE_HEAL, #MESSAGE_ATTACK, dll.).",
	"func_getMessageType_return": "Jenis pesan <b>pesan</b>.",
	"func_getMP": "Mengembalikan hitungan titik pergerakan saat ini dari entitas <b>entitas</b>. Gunakan getMP() tanpa parameter untuk mengambil PM Anda.",
	"func_getMP_arg_1": "ID entitas yang jumlah PM-nya akan dikembalikan.",
	"func_getMP_return": "Nomor MP entitas <b>entitas</b>.",
	"func_getName": "Mengembalikan nama entitas dengan id <b>entitas</b>.",
	"func_getName_arg_1": "Id entitas yang namanya akan dikembalikan.",
	"func_getName_return": "Nama entitas <b>entitas</b>.",
	"func_getNearestAlly": "Mengembalikan entitas ramah terdekat ke entitas Anda.",
	"func_getNearestAlly_return": "ID entitas ramah terdekat.",
	"func_getNearestAllyTo": "Mengembalikan entitas sekutu terdekat ke entitas yang disediakan sebagai parameter.",
	"func_getNearestAllyTo_arg_1": "Id entitas yang sekutu terdekatnya ingin kita ketahui.",
	"func_getNearestAllyToCell": "Mengembalikan entitas sekutu terdekat ke sel yang disediakan sebagai parameter.",
	"func_getNearestAllyToCell_arg_1": "Id dari sel yang sekutu terdekatnya ingin kita ketahui.",
	"func_getNearestAllyToCell_return": "ID entitas ramah terdekat.",
	"func_getNearestAllyTo_return": "ID entitas ramah terdekat.",
	"func_getNearestEnemy": "Mengembalikan entitas musuh terdekat ke entitas Anda.",
	"func_getNearestEnemy_return": "Id dari entitas musuh terdekat.",
	"func_getNearestEnemyTo": "Mengembalikan entitas musuh terdekat ke entitas yang disediakan sebagai parameter.",
	"func_getNearestEnemyTo_arg_1": "Id dari entitas yang musuh terdekatnya ingin kita ketahui.",
	"func_getNearestEnemyToCell": "Mengembalikan entitas musuh terdekat ke sel yang disediakan sebagai parameter.",
	"func_getNearestEnemyToCell_arg_1": "ID sel yang musuh terdekatnya ingin kita ketahui.",
	"func_getNearestEnemyToCell_return": "Id dari entitas musuh terdekat.",
	"func_getNearestEnemyTo_return": "Id dari entitas musuh terdekat.",
	"func_getNextPlayer": "Mengembalikan id entitas yang akan diputar setelah pemain saat ini.",
	"func_getNextPlayer_return": "Pemain berikutnya.",
	"func_getObstacles": "Mengembalikan daftar ruang rintangan di medan.",
	"func_getObstacles_return": "Larik yang berisi id sel rintangan.",
	"func_getOperations": "Mengembalikan jumlah operasi yang digunakan oleh entitas Anda sejak awal giliran. Angka ini harus tetap lebih rendah dari #OPERATIONS_LIMIT agar entitas tidak mogok.",
	"func_getOperations_return": "Jumlah operasi yang digunakan oleh entitas Anda sejak awal giliran.",
	"func_getPath": "Mengembalikan jalur yang menghindari rintangan antara dua sel <b>cell1</b> dan <b>cell2</b>, jika ada, abaikan sel yang terdapat dalam larik <b>ignoredCells</b> . Jika pemain berada di sel yang diabaikan, jalur dapat melewatinya.<br /><br />\nSel awal <b>sel1</b> tidak pernah menjadi bagian dari jalur yang dihasilkan. Sel <b>cell2</b> adalah bagian dari jalur hasil jika dan hanya jika kosong atau diabaikan oleh <b>ignoredCells</b>.<br /><br />\nJika tidak ada jalur di antara kedua sel, <b>getPath</b> mengembalikan <i>null</i>.",
	"func_getPath_arg_1": "Sel awal.",
	"func_getPath_arg_2": "Sel kedatangan.",
	"func_getPath_arg_3": "Susunan sel yang akan diabaikan.",
	"func_getPath_return": "Larik yang berisi sel-sel yang membentuk jalur antara dua sel.",
	"func_getPathLength": "Mengembalikan panjang jalur antara dua sel <b>cell1</b> dan <b>cell2</b>, menghindari rintangan, mengabaikan sel yang terdapat dalam larik <b>ignoredCells</b>. Fungsi ini setara dengan <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>.\nJika pemain berada di sel yang diabaikan, jalur dapat melewatinya.<br /><br />\nSel awal <b>sel1</b> tidak pernah dihitung dalam hasil. Sel <b>cell2</b> dihitung dalam hasil jika dan hanya jika kosong atau diabaikan oleh <b>ignoredCells</b>.<br /><br />\nJika tidak ada jalur di antara kedua sel, <b>getPathLength</b> mengembalikan <i>null</i>.",
	"func_getPathLength_arg_1": "Sel awal.",
	"func_getPathLength_arg_2": "Sel kedatangan.",
	"func_getPathLength_arg_3": "Susunan sel yang akan diabaikan.",
	"func_getPathLength_return": "Panjang jalur antara <b>sel1</b> dan <b>sel2</b>.",
	"func_getPreviousPlayer": "Mengembalikan id entitas yang diputar sebelum pemain saat ini.",
	"func_getPreviousPlayer_return": "Pemain sebelumnya.",
	"func_getRed": "Mengembalikan jumlah warna merah dalam warna <b>warna</b>, antara 0 dan 255. Misalnya, getRed(#COLOR_RED) = 255 dan getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "Warna yang rate merahnya akan dikembalikan.",
	"func_getRed_return": "Tingkat merah dalam warna <b>warna</b>",
	"func_getRegister": "Mengembalikan nilai yang disimpan dalam registri entitas yang terkait dengan kunci <b>key</b> atau </i>null</i> jika registri tidak ada.",
	"func_getRegister_arg_1": "Kunci registri yang nilainya akan dikembalikan.",
	"func_getRegister_return": "Nilai disimpan di registri <b>kunci</b>.",
	"func_getRegisters": "Mengembalikan kumpulan register entitas sebagai larik asosiatif [<i>kunci registri</i>:<i>nilai register</i>]. Contoh: <code>debug(getRegisters());\n// Tampilan misalnya:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "Array asosiatif yang sesuai dengan semua register entitas.",
	"func_getRelativeShield": "Mengembalikan perisai relatif entitas dengan id <b>entitas</b>. Untuk mengambil perisai relatif entitas Anda secara langsung, gunakan #getRelativeShield() tanpa parameter.",
	"func_getRelativeShield_arg_1": "Id entitas yang perisai relatifnya akan dikembalikan.",
	"func_getRelativeShield_return": "Perisai relatif <b>entitas</b>, bilangan bulat antara <b>0</b> dan <b>100</b>.",
	"func_getResistance": "Mengembalikan resistensi entitas dengan id <b>entitas</b>. Gunakan getResistance() tanpa parameter untuk mengambil resistensi Anda.",
	"func_getResistance_arg_1": "Id entitas yang resistensinya akan dikembalikan.",
	"func_getResistance_return": "Resistensi entitas dengan id <b>entitas</b>.",
	"func_getScience": "Mengembalikan ilmu entitas dengan id <b>entitas</b>. Gunakan getScience() tanpa parameter untuk mengambil sains Anda.",
	"func_getScience_arg_1": "Id dari entitas yang ilmunya akan dikembalikan.",
	"func_getScience_return": "Ilmu entitas id <b>entitas</b>.",
	"func_getStrength": "Mengembalikan kekuatan entitas dengan id <b>entitas</b>. Gunakan getStrength() tanpa parameter untuk mendapatkan kembali kekuatan Anda.",
	"func_getStrength_arg_1": "Entitas yang kekuatannya akan dikembalikan.",
	"func_getStrength_return": "Kekuatan entitas <b>entitas</b>.",
	"func_getPower": "Mengembalikan kekuatan entitas dengan id <b>entitas</b>.",
	"func_getPower_arg_1": "Entitas yang kekuatannya akan dikembalikan.",
	"func_getPower_return": "Kekuatan <b>entitas</b>.",
	"func_getSummoner": "Mengembalikan entitas yang memanggil entitas <b>entitas</b>, jika itu adalah sebuah permintaan.",
	"func_getSummoner_arg_1": "Id dari entitas yang pemanggilnya akan dipecat.",
	"func_getSummoner_return": "Id entitas memanggil <b>entitas</b> jika itu adalah doa, <i>null</i> sebaliknya.",
	"func_getSummons": "Mengembalikan daftar id pemanggilan entitas yang saat ini aktif dengan id <b>entitas</b>.",
	"func_getSummons_arg_1": "Id entitas yang pemanggilannya akan dikembalikan.",
	"func_getSummons_return": "Daftar id pemanggilan entitas dengan id <b>entitas</b>.",
	"func_getTeamID": "Mengembalikan id tim dari entitas <b>entitas</b>.",
	"func_getTeamID_arg_1": "ID entitas yang id timnya akan dikembalikan.",
	"func_getTeamID_return": "ID tim dari entitas <b>entitas</b>.",
	"func_getTeamName": "Mengembalikan nama tim dari entitas <b>entitas</b>.",
	"func_getTeamName_arg_1": "ID entitas yang nama timnya akan dikembalikan.",
	"func_getTeamName_return": "Nama tim entitas <b>entitas</b>.",
	"func_getTotalLife": "Mengembalikan umur total entitas dengan id <b>entitas</b>. Gunakan getTotalLife() tanpa parameter untuk mengambil total hidup Anda.",
	"func_getTotalLife_arg_1": "Id dari entitas yang total nyawanya akan dikembalikan.",
	"func_getTotalLife_return": "Total kehidupan entitas.",
	"func_getTotalMP": "Mengembalikan jumlah maksimum titik pergerakan entitas <b>entitas</b>.",
	"func_getTotalMP_arg_1": "Id entitas yang titik pergerakan maksimumnya akan dikembalikan.",
	"func_getTotalMP_return": "Jumlah maksimum titik pergerakan untuk <b>entitas</b>.",
	"func_getTotalTP": "Mengembalikan jumlah maksimum titik belok entitas <b>entitas</b>.",
	"func_getTotalTP_arg_1": "ID entitas yang jumlah titik belok maksimumnya akan dikembalikan.",
	"func_getTotalTP_return": "Jumlah maksimum titik belok entitas <b>entitas</b>.",
	"func_getTP": "Mengembalikan jumlah titik belok entitas <b>entitas</b>. Gunakan getTP() tanpa parameter untuk mengambil PT Anda.",
	"func_getTP_arg_1": "ID entitas yang PT-nya akan dikembalikan.",
	"func_getTP_return": "Nomor PT dari entitas <b>entitas</b>.",
	"func_getTurn": "Mengembalikan giliran tempur saat ini. Jumlah belokan maksimum adalah #MAX_TURNS.",
	"func_getTurn_return": "Pergantian pertempuran saat ini.",
	"func_getType": "Mengembalikan tipe entitas dari entitas <b>entitas</b>.",
	"func_getType_arg_1": "Id entitas yang jenisnya akan dikembalikan.",
	"func_getType_return": "Jenis entitas <b>entitas</b>:<ul><li>#ENTITY_LEEK jika berbentuk daun bawang.</li><li>#ENTITY_BULB jika berbentuk 'bohlam.</li><li >#ENTITY_TURRET jika berupa turret.</li></ul>",
	"func_getWeapon": "Mengembalikan <b>entitas</b> senjata yang saat ini digunakan.",
	"func_getWeapon_arg_1": "Id entitas yang senjatanya saat ini akan dikembalikan.",
	"func_getWeapon_return": "Id senjata yang saat ini digunakan pada <b>entitas</b>, tidak berlaku jika entitas tidak memiliki senjata yang dilengkapi atau entitas tidak ada.",
	"func_getWeaponArea": "Mengembalikan jenis efek area dari senjata <b>senjata</b>.",
	"func_getWeaponArea_arg_1": "Senjata yang tipe areanya akan dikembalikan.",
	"func_getWeaponArea_return": "Jenis area senjata <b>senjata</b> di antara konstanta AREA_*:\n<ul>\n<li>#AREA_POINT: luas persegi tunggal</li>\n<li>#AREA_LASER_LINE: garis laser</li>\n<li>#AREA_CIRCLE_1: luas lingkaran berdiameter 3 sel</li>\n<li>#AREA_CIRCLE_2: luas lingkaran dengan diameter 5 persegi</li>\n<li>#AREA_CIRCLE_3: luas lingkaran berdiameter 7 persegi</li>\n</ul>",
	"func_getWeaponCost": "Mengembalikan biaya TP dari senjata <b>senjata</b>.",
	"func_getWeaponCost_arg_1": "Id senjata yang biayanya akan dikembalikan.",
	"func_getWeaponCost_return": "Biaya TP dari senjata <b>senjata</b>.",
	"func_getWeaponEffectiveArea": "Mengembalikan daftar sel yang akan terpengaruh jika senjata <b>senjata</b> digunakan pada sel <b>sel</b> dari sel <b>dari</b>.",
	"func_getWeaponEffectiveArea_arg_1": "Senjata untuk menguji.",
	"func_getWeaponEffectiveArea_arg_2": "Sel sasaran.",
	"func_getWeaponEffectiveArea_arg_3": "Sel tempat senjata ditembakkan.",
	"func_getWeaponEffectiveArea_return": "Larik yang berisi id dari semua sel yang akan terpengaruh.",
	"func_getWeaponPassiveEffects": "Mengembalikan efek pasif senjata <b>senjata</b>.",
	"func_getWeaponPassiveEffects_arg_1": "Id senjata yang efek pasifnya akan dikembalikan.",
	"func_getWeaponPassiveEffects_return": "Larik yang berisi efek senjata <b>senjata</b>. Setiap efek itu sendiri merupakan larik dari formulir\n[ketik, min, maks, belokan, target, pengubah]. Efek ini sama dengan yang dikembalikan oleh #getWeaponEffects.",
	"func_getWeaponFailure": "Mengembalikan persentase peluang kegagalan senjata <b>senjata</b>.",
	"func_getWeaponFailure_arg_1": "ID senjata yang persentase kegagalannya akan dikembalikan.",
	"func_getWeaponFailure_return": "Persentase kehilangan senjata <b>senjata</b>, bilangan bulat antara <b>0</b> dan <b>100</b>.",
	"func_getWeaponMaxRange": "Mengembalikan jangkauan maksimum senjata <b>senjata</b>.",
	"func_getWeaponMaxRange_arg_1": "ID senjata yang jangkauan maksimumnya akan dikembalikan.",
	"func_getWeaponMaxRange_return": "Jangkauan maksimum senjata <b>senjata</b>.",
	"func_getWeaponMaxScope": "Mengembalikan jangkauan maksimum senjata <b>senjata</b>.",
	"func_getWeaponMaxScope_arg_1": "ID senjata yang jangkauan maksimumnya akan dikembalikan.",
	"func_getWeaponMaxScope_return": "Jangkauan maksimum senjata <b>senjata</b>.",
	"func_getWeaponMinRange": "Mengembalikan jangkauan minimum senjata <b>senjata</b>.",
	"func_getWeaponMinRange_arg_1": "ID senjata yang jangkauan minimumnya akan dikembalikan.",
	"func_getWeaponMinRange_return": "Rentang minimum senjata <b>senjata</b>.",
	"func_getWeaponMinScope": "Mengembalikan jangkauan minimum senjata <b>senjata</b>.",
	"func_getWeaponMinScope_arg_1": "ID senjata yang jangkauan minimumnya akan dikembalikan.",
	"func_getWeaponMinScope_return": "Rentang minimum senjata <b>senjata</b>.",
	"func_getWeaponLaunchType": "Mengembalikan mode peluncuran senjata <b>senjata</b>, di antara konstanta LAUNCH_TYPE_*.",
	"func_getWeaponLaunchType_arg_1": "ID senjata yang mode lemparnya akan dikembalikan. Default untuk senjata Anda saat ini dilengkapi.",
	"func_getWeaponLaunchType_return": "Mode lempar senjata <b>senjata</b>.",
	"func_getWeaponName": "Mengembalikan nama senjata <b>senjata</b>.",
	"func_getWeaponName_arg_1": "Id senjata yang namanya akan dikembalikan.",
	"func_getWeaponName_return": "Nama senjata <b>senjata</b>.",
	"func_getWeapons": "Mengembalikan senjata entitas dengan id <b>entitas</b>.",
	"func_getWeapons_arg_1": "Id entitas yang senjatanya akan dikembalikan.",
	"func_getWeapons_return": "Larik yang berisi ID senjata <b>entitas</b>.",
	"func_getWeaponTargets": "Mengembalikan entitas yang akan terpengaruh jika <b>senjata</b> senjata digunakan di sel <b>sel</b>.",
	"func_getWeaponTargets_arg_1": "Senjata untuk menguji.",
	"func_getWeaponTargets_arg_2": "Sel sasaran.",
	"func_getWeaponTargets_return": "Larik yang berisi id semua entitas yang akan terpengaruh.",
	"func_getWisdom": "Mengembalikan kebijaksanaan entitas dengan id <b>entitas</b>. Gunakan getWisdom() tanpa parameter untuk mengambil kebijaksanaan Anda.",
	"func_getWisdom_arg_1": "Id dari entitas yang kebijaksanaannya akan dikembalikan.",
	"func_getWisdom_return": "Kebijaksanaan entitas id <b>entitas</b>.",
	"func_hypot": "Mengembalikan sisi miring segitiga siku-siku dengan sisi x dan y. Setara dengan <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "Nilai x.",
	"func_hypot_arg_2": "Nilai y.",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "Menentukan apakah elemen <b>elemen</b> berada dalam larik <b>array</b>.",
	"func_inArray_arg_1": "Meja pencarian.",
	"func_inArray_arg_2": "Item yang akan dicari.",
	"func_inArray_return": "<i>benar</i> jika elemennya ada di dalam larik, <i>salah</i> jika tidak.",
	"func_include": "Sertakan AI dengan nama <b>ai</b> di AI saat ini. <br><br><b>Peringatan</b>, fungsi include harus dipanggil hanya di blok utama, dan parameternya harus berupa string yang ditulis langsung\ndalam kode. Untuk informasi selengkapnya, lihat bagian dalam tutorial: <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a> .",
	"func_include_arg_1": "Nama AI untuk disertakan.",
	"func_indexOf": "Menentukan posisi pemunculan pertama string <b>search</b> dalam string <b>string</b>, dimulai dari posisi <b>mulai</b>.",
	"func_indexOf_arg_1": "String tempat pencarian akan dilakukan.",
	"func_indexOf_arg_2": "String yang akan dicari.",
	"func_indexOf_arg_3": "Posisi awal pencarian.",
	"func_indexOf_return": "Posisi kemunculan pertama <b>pencarian</b> di <b>string</b> dari <b>mulai</b>, -1 jika string tidak ditemukan.",
	"func_insert": "Menyisipkan elemen <b>elemen</b> ke dalam <b>larik</b> pada posisi <b>posisi</b>.",
	"func_insert_arg_1": "Larik tempat elemen akan disisipkan.",
	"func_insert_arg_2": "Item yang akan disisipkan.",
	"func_insert_arg_3": "Posisi penyisipan.",
	"func_isAlive": "Menentukan apakah <b>entitas</b> hidup. Setara dengan <i>getLife(<b>entitas</b>) > 0</i>.",
	"func_isAlive_arg_1": "ID entitas yang akan diuji.",
	"func_isAlive_return": "<i>benar</i> jika <b>entitas</b> hidup, <i>salah</i> jika mati.",
	"func_isAlly": "Menentukan apakah <b>entitas</b> adalah sekutu Anda.",
	"func_isAlly_arg_1": "ID entitas yang akan diuji.",
	"func_isAlly_return": "<i>benar</i> jika <b>entitas</b> adalah sekutu Anda atau diri Anda sendiri, <i>salah</i> jika itu adalah musuh.",
	"func_isChip": "Menentukan apakah suatu nilai merupakan konstanta yang mewakili chip.<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "Jumlah yang akan ditentukan.",
	"func_isChip_return": "<i>benar</i> jika nilainya adalah konstanta chip.",
	"func_isDead": "Menentukan apakah <b>entitas</b> sudah mati. Setara dengan <i>getLife(<b>entity</b>) == 0</i>.",
	"func_isDead_arg_1": "ID entitas yang akan diuji.",
	"func_isDead_return": "<i>benar</i> jika <b>entitas</b> mati, <i>salah</i> jika hidup.",
	"func_isEmpty": "Menentukan apakah array <b>array</b> kosong. Setara dengan <i>count(<b>array</b>) == 0</i>.",
	"func_isEmpty_arg_1": "Array untuk diuji.",
	"func_isEmptyCell": "Menentukan apakah sebuah sel kosong.",
	"func_isEmptyCell_arg_1": "Sel yang akan diuji.",
	"func_isEmptyCell_return": "<i>benar</i> jika sel kosong, <i>salah</i> jika tidak.",
	"func_isEmpty_return": "<i>benar</i> jika array kosong, <i>salah</i> jika tidak.",
	"func_isEnemy": "Menentukan apakah <b>entitas</b> adalah musuh Anda.",
	"func_isEnemy_arg_1": "ID entitas yang akan diuji.",
	"func_isEnemy_return": "<i>benar</i> jika <b>entitas</b> adalah musuh, <i>salah</i> jika itu adalah sekutu atau diri Anda sendiri.",
	"func_isInlineChip": "Menentukan apakah <b>chip</b> hanya dapat digunakan secara online.",
	"func_isInlineChip_arg_1": "ID chip yang akan diuji.",
	"func_isInlineChip_return": "<i>benar</i> jika chip hanya online, <i>salah</i> jika tidak.",
	"func_isInlineWeapon": "Menentukan apakah senjata <b>senjata</b> hanya dapat digunakan secara online.",
	"func_isInlineWeapon_arg_1": "ID senjata yang akan diuji.",
	"func_isInlineWeapon_return": "<i>benar</i> jika senjata hanya bisa digunakan secara online, <i>salah</i> jika tidak.",
	"func_isLeek": "Menentukan apakah konten sel <b>sel</b> adalah entitas.",
	"func_isLeek_arg_1": "Sel yang akan diuji.",
	"func_isLeek_return": "<i>benar</i> jika sel berisi entitas, <i>salah</i> jika tidak.",
	"func_isEntity": "Menentukan apakah konten sel <b>sel</b> adalah entitas.",
	"func_isEntity_arg_1": "Sel yang akan diuji.",
	"func_isEntity_return": "<i>benar</i> jika sel berisi entitas, <i>salah</i> jika tidak.",
	"func_isObstacle": "Menentukan apakah konten sel <b>sel</b> merupakan penghalang.",
	"func_isObstacle_arg_1": "Sel yang akan diuji.",
	"func_isObstacle_return": "<i>benar</i> jika sel berisi penghalang, <i>salah</i> jika tidak.",
	"func_isOnSameLine": "Menentukan apakah dua sel <b>sel1</b> dan <b>sel2</b> berada di baris yang sama.",
	"func_isOnSameLine_arg_1": "Sel pertama.",
	"func_isOnSameLine_arg_2": "Sel kedua.",
	"func_isOnSameLine_return": "<i>benar</i> jika kedua sel berada pada baris yang sama, <i>salah</i> jika tidak.",
	"func_isStatic": "Mengembalikan apakah <b>entitas</b> statis atau tidak. Entitas statis tidak dapat bergerak atau dipindahkan.",
	"func_isStatic_arg_1": "ID entitas yang akan diuji.",
	"func_isStatic_return": "<i>benar</i> jika <b>entitas</b> statis, <i>salah</i> jika tidak.",
	"func_isSummon": "Mengembalikan apakah <b>entitas</b> merupakan pemanggilan atau bukan.",
	"func_isSummon_arg_1": "ID entitas yang akan diuji.",
	"func_isSummon_return": "<i>benar</i> jika <b>entitas</b> adalah doa, <i>salah</i> jika tidak.",
	"func_isWeapon": "Menentukan apakah suatu nilai adalah konstanta yang mewakili senjata.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false.",
	"func_isWeapon_arg_1": "Jumlah yang akan ditentukan.",
	"func_isWeapon_return": "<i>benar</i> jika nilainya adalah konstanta senjata.",
	"func_join": "Menggabungkan beberapa elemen menjadi string, memisahkannya dengan pembatas <b>lem</b>.",
	"func_join_arg_1": "Array item untuk digabungkan.",
	"func_join_arg_2": "Pembatas elemen.",
	"func_join_return": "String yang dihasilkan dari penggabungan.",
	"func_jsonDecode": "Mendekodekan string <b>json</b> ke objek LeekScript (angka, string, larik...).",
	"func_jsonDecode_arg_1": "String JSON untuk didekode.",
	"func_jsonDecode_return": "Objek LeekScript yang didekodekan.",
	"func_jsonEncode": "Mengkodekan objek <b>objek</b> menjadi string JSON.",
	"func_jsonEncode_arg_1": "Objek yang akan dikodekan dalam JSON.",
	"func_jsonEncode_return": "String yang dikodekan JSON.",
	"func_keySort": "Urutkan <b>array</b> dengan kunci dalam urutan <b>urutan</b>.",
	"func_keySort_arg_1": "Array untuk mengurutkan.",
	"func_keySort_arg_2": "Urutan sortir: #SORT_ASC atau #SORT_DESC.",
	"func_length": "Mengembalikan panjang <b>string</b>.",
	"func_length_arg_1": "String yang panjangnya akan dikembalikan.",
	"func_length_return": "Panjang <b>string</b>.",
	"func_lineOfSight": "Memeriksa garis pandang antara sel <b>mulai</b> dan sel <b>akhir</b>, mengabaikan entitas <b>ignoredEntities</b>.",
	"func_lineOfSight_arg_1": "Mulai sel.",
	"func_lineOfSight_arg_2": "Sel sasaran.",
	"func_lineOfSight_arg_3": "Entitas atau daftar entitas yang akan diabaikan.",
	"func_lineOfSight_return": "Mengembalikan <i>true</i> jika saling berhadapan jelas.",
	"func_listen": "Mengembalikan larik say() dari entitas sebelumnya, dalam bentuk [entity_id, message].",
	"func_listen_return": "Array say() sebelumnya.",
	"func_log": "Menghitung logaritma natural dari angka <b>angka</b>.",
	"func_log_arg_1": "Angka dalam rentang ]0; +∞[.",
	"func_log_return": "Logaritma natural dari <b>bilangan</b>.",
	"func_log10": "Menghitung logaritma basis 10 dari angka <b>bilangan</b>.",
	"func_log10_arg_1": "Angka dalam rentang ]0; +∞[.",
	"func_log10_return": "Logaritma basis 10 dari <b>bilangan</b>.",
	"func_log2": "Menghitung logaritma basis 2 dari angka <b>bilangan</b>.",
	"func_log2_arg_1": "Angka dalam rentang ]0; +∞[.",
	"func_log2_return": "Logaritma basis 2 dari <b>bilangan</b>.",
	"func_mark": "Menandai satu atau lebih sel dengan warna yang ditunjukkan dalam parameter di tanah untuk jumlah belokan yang ditunjukkan dalam parameter. Penandaan ini hanya dapat dilihat oleh peternak entitas.",
	"func_mark_arg_1": "Sel atau larik dari beberapa sel yang akan ditandai",
	"func_mark_arg_2": "Menandai warna",
	"func_mark_arg_3": "Durasi penandaan",
	"func_mark_return": "Mengembalikan true jika semuanya berjalan dengan baik",
	"func_markText": "Menulis teks pada satu atau lebih sel dengan warna yang ditunjukkan dalam parameter di tanah untuk jumlah belokan yang ditunjukkan dalam parameter. Teks-teks ini hanya dapat dilihat oleh pemulia entitas.",
	"func_markText_arg_1": "Sel atau larik beberapa sel yang akan ditulisi",
	"func_markText_arg_2": "Teks yang akan ditulis (maksimum 10 karakter)",
	"func_markText_arg_3": "Warna teks",
	"func_markText_arg_4": "Durasi teks",
	"func_markText_return": "Mengembalikan true jika semuanya berjalan dengan baik",
	"func_max": "Mengembalikan nilai yang lebih besar antara dua angka <b>a</b> dan <b>b</b>.",
	"func_max_arg_1": "Sebuah angka.",
	"func_max_arg_2": "Sebuah angka.",
	"func_max_return": "Angka yang lebih besar antara <b>a</b> dan <b>b</b>.",
	"func_min": "Mengembalikan nilai yang lebih kecil antara dua angka <b>a</b> dan <b>b</b>.",
	"func_min_arg_1": "Sebuah angka.",
	"func_min_arg_2": "Sebuah angka.",
	"func_min_return": "Angka yang lebih kecil antara <b>a</b> dan <b>b</b>.",
	"func_moveAwayFrom": "Memindahkan entitas Anda dari <b>entitas</b> lain, menggunakan titik pergerakan <b>mp</b> maksimum.",
	"func_moveAwayFrom_arg_1": "Entitas yang harus dijauhi oleh entitas Anda.",
	"func_moveAwayFrom_arg_2": "Jumlah maksimum MP untuk digunakan. Secara default, tidak ada batasan.",
	"func_moveAwayFrom_return": "Jumlah poin gerakan yang digunakan.",
	"func_moveAwayFromCell": "Memindahkan entitas Anda sejauh satu <b>sel</b> sel, menggunakan lebih dari <b>mp</b> titik pergerakan.",
	"func_moveAwayFromCell_arg_1": "Sel tempat entitas Anda harus menjauh.",
	"func_moveAwayFromCell_arg_2": "Jumlah maksimum MP untuk digunakan. Secara default, tidak ada batasan.",
	"func_moveAwayFromCell_return": "Jumlah poin gerakan yang digunakan.",
	"func_moveAwayFromCells": "Memindahkan entitas Anda dari sekumpulan sel <b>sel</b>, menggunakan titik pergerakan <b>mp</b> maksimum.",
	"func_moveAwayFromCells_arg_1": "Larik yang berisi sel yang harus dipindahkan oleh entitas Anda.",
	"func_moveAwayFromCells_arg_2": "Jumlah maksimum MP untuk digunakan. Secara default, tidak ada batasan.",
	"func_moveAwayFromCells_return": "Jumlah poin gerakan yang digunakan.",
	"func_moveAwayFromLeeks": "Memindahkan entitas Anda dari sekumpulan <b>entitas</b>, menggunakan titik pergerakan <b>mp</b> maksimum.",
	"func_moveAwayFromLeeks_arg_1": "Larik yang berisi id entitas yang harus dipindahkan oleh entitas Anda.",
	"func_moveAwayFromLeeks_arg_2": "Jumlah maksimum MP untuk digunakan. Secara default, tidak ada batasan.",
	"func_moveAwayFromLeeks_return": "Jumlah poin gerakan yang digunakan.",
	"func_moveAwayFromEntities": "Memindahkan entitas Anda dari sekumpulan <b>entitas</b>, menggunakan titik pergerakan <b>mp</b> maksimum.",
	"func_moveAwayFromEntities_arg_1": "Larik yang berisi id entitas yang harus dipindahkan oleh entitas Anda.",
	"func_moveAwayFromEntities_arg_2": "Jumlah maksimum MP untuk digunakan. Secara default, tidak ada batasan.",
	"func_moveAwayFromEntities_return": "Jumlah poin gerakan yang digunakan.",
	"func_moveAwayFromLine": "Memindahkan entitas Anda dari garis yang ditentukan oleh dua sel <b>sel1</b> dan <b>sel2</b>, menggunakan maksimum titik gerakan <b>mp</b>.",
	"func_moveAwayFromLine_arg_1": "Sel 1.",
	"func_moveAwayFromLine_arg_2": "Sel 2.",
	"func_moveAwayFromLine_arg_3": "Jumlah maksimum MP untuk digunakan. Secara default, tidak ada batasan.",
	"func_moveAwayFromLine_return": "Jumlah poin gerakan yang digunakan.",
	"func_moveToward": "Dekatkan entitas Anda ke <b>entitas</b> lain, menggunakan titik pergerakan <b>mp</b> maksimum.",
	"func_moveToward_arg_1": "Entitas yang harus didekati oleh entitas Anda.",
	"func_moveToward_arg_2": "Jumlah maksimum MP untuk digunakan.",
	"func_moveToward_return": "Jumlah poin gerakan yang digunakan.",
	"func_moveTowardCell": "Memindahkan entitas Anda satu <b>sel</b> sel lebih dekat, menggunakan titik gerakan <b>mp</b> maksimum.",
	"func_moveTowardCell_arg_1": "Sel yang harus didekati oleh fitur Anda.",
	"func_moveTowardCell_arg_2": "Jumlah maksimum MP untuk digunakan. Secara default, tidak ada batasan.",
	"func_moveTowardCell_return": "Jumlah poin gerakan yang digunakan.",
	"func_moveTowardCells": "Mendekatkan entitas Anda ke sekumpulan sel <b>sel</b>, menggunakan titik pergerakan <b>mp</b> maksimum.",
	"func_moveTowardCells_arg_1": "Larik berisi sel yang harus didekati oleh entitas Anda.",
	"func_moveTowardCells_arg_2": "Jumlah maksimum MP untuk digunakan. Secara default, tidak ada batasan.",
	"func_moveTowardCells_return": "Jumlah poin gerakan yang digunakan.",
	"func_moveTowardLeeks": "Mendekatkan entitas Anda ke sekumpulan <b>entitas</b>, menggunakan titik pergerakan <b>mp</b> maksimum.",
	"func_moveTowardLeeks_arg_1": "Larik yang berisi id entitas yang harus didekati oleh entitas Anda.",
	"func_moveTowardLeeks_arg_2": "Jumlah maksimum MP untuk digunakan. Secara default, tidak ada batasan.",
	"func_moveTowardLeeks_return": "Jumlah poin gerakan yang digunakan.",
	"func_moveTowardEntities": "Mendekatkan entitas Anda ke sekumpulan <b>entitas</b>, menggunakan titik pergerakan <b>mp</b> maksimum.",
	"func_moveTowardEntities_arg_1": "Larik yang berisi id entitas yang harus didekati oleh entitas Anda.",
	"func_moveTowardEntities_arg_2": "Jumlah maksimum MP untuk digunakan. Secara default, tidak ada batasan.",
	"func_moveTowardEntities_return": "Jumlah poin gerakan yang digunakan.",
	"func_moveTowardLine": "Membawa entitas Anda lebih dekat ke garis yang ditentukan oleh dua sel <b>cell1</b> dan <b>cell2</b>, menggunakan titik pergerakan <b>mp</b> maksimum.",
	"func_moveTowardLine_arg_1": "Sel 1.",
	"func_moveTowardLine_arg_2": "Sel 2.",
	"func_moveTowardLine_arg_3": "Jumlah maksimum MP untuk digunakan. Secara default, tidak ada batasan.",
	"func_moveTowardLine_return": "Jumlah poin gerakan yang digunakan.",
	"func_number": "Mengonversi nilai menjadi angka. Jika nilainya berupa string, fungsi angka akan mencoba mengonversinya menjadi angka, jika nilainya sudah berupa angka, fungsi mengembalikan angka, dan untuk jenis lainnya, mengembalikan <i>null</i>.",
	"func_number_arg_1": "Nilai yang akan dikonversi menjadi angka.",
	"func_number_return": "Angka yang dikonversi.",
	"func_pause": "Jeda pertarungan, hanya untuk peternak entitas yang menggunakan fungsi tersebut.",
	"func_pop": "Menghapus sel terakhir dari <b>array</b> dan mengembalikan elemen yang sesuai.",
	"func_pop_arg_1": "Larik yang kotak terakhirnya akan dihapus.",
	"func_pop_return": "Item yang ada di kotak terakhir yang lama.",
	"func_pow": "Menaikkan bilangan dasar ke pangkat eksponen.",
	"func_pow_arg_1": "Dasar.",
	"func_pow_arg_2": "Peserta pameran.",
	"func_pow_return": "<b>basis</b> dinaikkan menjadi pangkat <b>eksponen</b>.",
	"func_push": "Menambahkan elemen <b>element</b> ke akhir <b>array</b>.",
	"func_pushAll": "Menambahkan semua elemen dari larik <b>elemen</b> ke akhir dari <b>array</b>.",
	"func_pushAll_arg_1": "Array tempat kita ingin menambahkan elemen.",
	"func_pushAll_arg_2": "Item untuk ditambahkan.",
	"func_push_arg_1": "Array tempat kita ingin menambahkan elemen.",
	"func_push_arg_2": "Item yang akan ditambahkan.",
	"func_rand": "Mengembalikan bilangan acak nyata, antara 0 (inklusif) dan 1 (eksklusif).",
	"func_randFloat": "Mengembalikan bilangan acak nyata, antara <b>a</b> (inklusif) dan <b>b</b> (eksklusif).",
	"func_randFloat_arg_1": "Batas bawah.",
	"func_randFloat_arg_2": "Batas atas.",
	"func_randFloat_return": "Angka acak dalam rentang [a; B[.",
	"func_randInt": "Mengembalikan bilangan bulat acak antara <b>a</b> (inklusif) dan <b>b</b> (eksklusif).",
	"func_randInt_arg_1": "Batas bawah.",
	"func_randInt_arg_2": "Batas atas.",
	"func_randInt_return": "Bilangan bulat acak dalam rentang [a; B[.",
	"func_rand_return": "Angka acak dalam rentang [0; 1[.",
	"func_remove": "Menghapus elemen dari array <b>array</b> pada posisi <b>position</b>, dan mengembalikan elemen yang dihapus.",
	"func_remove_arg_1": "Array dari mana kita ingin menghapus elemen.",
	"func_remove_arg_2": "Posisi elemen yang akan dihapus.",
	"func_removeElement": "Menghapus kemunculan pertama <b>elemen</b> di <b>array</b>. Jika elemen tidak ditemukan, array tidak diubah.",
	"func_removeElement_arg_1": "Array dari mana kita ingin menghapus elemen.",
	"func_removeElement_arg_2": "Item untuk ditemukan dan kemudian dihapus.",
	"func_removeKey": "Menghapus elemen dari array <b>array</b> yang terkait dengan kunci <b>key</b>.",
	"func_removeKey_arg_1": "Array dari mana kita ingin menghapus elemen.",
	"func_removeKey_arg_2": "Kunci yang terkait dengan item yang akan dihapus.",
	"func_remove_return": "Elemen dihapus dari array.",
	"func_replace": "Ganti semua kemunculan <b>pencarian</b> dengan <b>ganti</b> dalam string <b>string</b>.",
	"func_replace_arg_1": "String di mana penggantian dilakukan.",
	"func_replace_arg_2": "Substring untuk menggantikan.",
	"func_replace_arg_3": "Tali pengganti.",
	"func_replace_return": "String hasil, dengan penggantian.",
	"func_resurrect": "Menggunakan chip CHIP_RESURRECTION untuk membangkitkan entitas mati dengan id <b>entitas</b>, di sel <b>sel</b>.",
	"func_resurrect_arg_1": "Id entitas untuk dihidupkan kembali.",
	"func_resurrect_arg_2": "Sel tempat entitas akan muncul kembali.",
	"func_resurrect_return": "Hasil peluncuran chip, di antara konstanta USE_*.",
	"func_reverse": "Membalikkan <b>array</b>.",
	"func_reverse_arg_1": "Tabel untuk dibalik.",
	"func_round": "Mengembalikan pembulatan <b>angka</b>.<br/>Anda dapat menemukan pembulatan ke bawah dengan #floor, dan pembulatan ke atas dengan #ceil.",
	"func_round_arg_1": "Angka yang pembulatannya ingin kita temukan.",
	"func_round_return": "Dibulatkan dengan <b>angka</b>.",
	"func_say": "Buat entitas Anda berbicara.",
	"func_say_arg_1": "Pesan yang akan diumumkan entitas Anda di arena.",
	"func_search": "Menemukan elemen <b>elemen</b> dalam larik <b>array</b>, mulai dari posisi <b>mulai</b>.",
	"func_search_arg_1": "Meja pencarian.",
	"func_search_arg_2": "Item yang akan dicari.",
	"func_search_arg_3": "Posisi awal pencarian.",
	"func_search_return": "Posisi kemunculan pertama elemen <b>element</b> dalam <b>array</b>, dimulai dari <b>mulai</b>, null jika elemen belum ditemukan.",
	"func_sendAll": "Kirim pesan ke seluruh tim Anda.",
	"func_sendAll_arg_1": "Jenis pesan yang akan dikirim (lihat konstanta MESSAGE_*).",
	"func_sendAll_arg_2": "Parameter pesan, yang dapat berupa nilai apa pun.",
	"func_sendTo": "Mengirim pesan ke entitas dengan id <b>entitas</b>.",
	"func_sendTo_arg_1": "Id entitas tujuan pengiriman pesan.",
	"func_sendTo_arg_2": "Jenis pesan yang akan dikirim (lihat konstanta MESSAGE_*).",
	"func_sendTo_arg_3": "Parameter pesan, yang dapat berupa nilai apa pun.",
	"func_sendTo_return": "<i>benar</i> jika pengiriman berhasil, <i>salah</i> jika terjadi kesalahan.",
	"func_setRegister": "Menyimpan <b>nilai</b> di registri <b>kunci</b>.\nKunci dan nilainya adalah string yang masing-masing harus berisi <i>100</i> dan <i>5000</i> karakter. Daun bawang dapat memiliki maksimum <i>100</i> register, menyimpan dalam register baru tidak akan berfungsi jika semua register\nsudah sibuk.",
	"func_setRegister_arg_1": "Kunci registri tempat menyimpan nilai.",
	"func_setRegister_arg_2": "Nilai untuk disimpan.",
	"func_setRegister_return": "<i>benar</i> jika operasi berhasil, <i>salah</i> jika tidak.",
	"func_setWeapon": "Lengkapi senjata <b>senjata</b> di entitas Anda.",
	"func_setWeapon_arg_1": "ID senjata untuk melengkapi.",
	"func_shift": "Menghapus sel pertama dari <b>array</b> dan mengembalikan elemen yang sesuai.",
	"func_shift_arg_1": "Larik yang sel pertamanya akan dihapus.",
	"func_shift_return": "Elemen yang ada di kotak pertama yang lama.",
	"func_show": "Menampilkan pemain <b>sel</b> dari warna <b>warna</b> di lapangan selama 1 giliran. Menggunakan fitur ini dikenai biaya 1PT.",
	"func_show_arg_1": "Sel untuk ditampilkan",
	"func_show_arg_2": "Menandai warna",
	"func_show_return": "Mengembalikan true jika semuanya berjalan dengan baik",
	"func_shuffle": "Mengocok papan secara acak.",
	"func_shuffle_arg_1": "Papan acak.",
	"func_signum": "Menentukan tanda angka <b>angka</b>.",
	"func_signum_arg_1": "Angka yang tandanya akan ditentukan.",
	"func_signum_return": "1 jika bilangan positif, 0 jika bilangan nol, dan -1 jika bilangan negatif.",
	"func_sin": "Menghitung sinus sudut <b>sudut</b> yang diteruskan sebagai parameter",
	"func_sin_arg_1": "Sudut yang sinusnya akan dihitung",
	"func_sin_return": "Mengembalikan sinus sudut <b>sudut</b>",
	"func_sort": "Mengurutkan <b>array</b> dalam urutan menaik atau menurun. Urutan menaik default adalah:\n<ul>\n<li>Semua boolean (pertama salah lalu benar)</li>\n<li>Semua angka (diurutkan dari terkecil ke terbesar)</li>\n<li>Semua saluran (dalam urutan abjad)</li>\n<li>Semua elemen nol.</li>\n</ul>",
	"func_sort_arg_1": "Array untuk mengurutkan.",
	"func_sort_arg_2": "#SORT_ASC untuk mengurutkan <b>array</b> dalam urutan naik atau #SORT_DESC untuk mengurutkannya dalam urutan menurun.",
	"func_split": "Pisahkan string <b>string</b> menjadi substring yang dibatasi oleh <b>pembatas</b>.",
	"func_split_arg_1": "Rantai pemotongan.",
	"func_split_arg_2": "String yang membatasi bagian dari satu elemen ke elemen lainnya.",
	"func_split_arg_3": "Jumlah maksimum elemen larik.",
	"func_split_return": "Larik berisi substring yang ditemukan.",
	"func_sqrt": "Menghitung akar kuadrat dari angka <b>angka</b>.",
	"func_sqrt_arg_1": "Angka yang akarnya akan dihitung.",
	"func_sqrt_return": "Akar kuadrat dari <b>bilangan</b>.",
	"func_startsWith": "Memeriksa apakah <b>string</b> dimulai dengan <b>awalan</b>.",
	"func_startsWith_arg_1": "Rangkaian pencarian.",
	"func_startsWith_arg_2": "Awalan yang dicari.",
	"func_startsWith_return": "Benar jika <b>string</b> dimulai dengan <b>awalan</b>.",
	"func_string": "Mengonversi nilai menjadi string.<br>\nJika nilainya sudah berupa string, maka dikembalikan.<br>\nJika nilainya adalah angka x, \"x\" dikembalikan.<br>\nJika nilainya adalah larik, string dalam bentuk \"[key1: value1, key2: value2, ...]\" dikembalikan.<br>\nJika nilainya adalah Boolean, \"true\" dan \"false\" masing-masing dikembalikan untuk true dan false.<br>\nJika nilainya null, \"null\" dikembalikan.",
	"func_string_arg_1": "Nilai yang akan dikonversi menjadi string karakter.",
	"func_string_return": "String yang dikonversi.",
	"func_subArray": "Mengembalikan subarray dari <b>array</b> mulai dari posisi <b>mulai</b> dan berakhir pada posisi <b>akhir</b>.",
	"func_subArray_arg_1": "Tabel sumber.",
	"func_subArray_arg_2": "Mulai indeks.",
	"func_subArray_arg_3": "Indeks akhir.",
	"func_subArray_return": "Sub-array.",
	"func_substring": "Mengembalikan substring dari string <b>string</b> mulai dari karakter pada indeks <b>mulai</b> dan dengan ukuran <b>panjang</b>.",
	"func_substring_arg_1": "Rantai pemotongan.",
	"func_substring_arg_2": "Indeks karakter awal.",
	"func_substring_arg_3": "Panjang substring.",
	"func_substring_return": "Substring.",
	"func_sum": "Mengembalikan jumlah semua elemen numerik dari array <b>array</b>.",
	"func_sum_arg_1": "Tabel untuk dijumlahkan.",
	"func_summon": "Memanggil entitas yang ditentukan oleh chip <b>chip</b> pada sel <b>cell</b> yang memiliki fungsi <b>ai</b> untuk IA.",
	"func_summon_arg_1": "Chip yang digunakan untuk memanggil. Chip tersebut harus berupa chip jenis pemanggilan dan harus dipasang pada entitas yang menggunakan fungsi pemanggilan.",
	"func_summon_arg_2": "Sel atau pemanggilan akan muncul.",
	"func_summon_arg_3": "Doa AI, sebagai fungsi.",
	"func_summon_return": "Fungsi pemanggilan memiliki pengembalian yang sama dengan fungsi #useChip.",
	"func_sum_return": "Jumlah elemen array.",
	"func_tan": "Menghitung garis singgung sudut <b>sudut</b> (dalam radian).",
	"func_tan_arg_1": "Sudut dalam radian.",
	"func_tan_return": "Garis singgung <b>sudut</b>.",
	"function_category_array": "Daftar",
	"function_category_chip": "Chips",
	"function_category_color": "Warna",
	"function_category_fight": "Bertarung",
	"function_category_leek": "Daun bawang",
	"function_category_map": "Tabel",
	"function_category_field": "Tanah",
	"function_category_math": "Angka",
	"function_category_network": "Jaringan",
	"function_category_string": "Rantai",
	"function_category_utils": "Keperluan",
	"function_category_weapon": "Senjata",
	"func_toDegrees": "Mengonversi sudut <b>radian</b> menjadi derajat.",
	"func_toDegrees_arg_1": "Nilai dalam radian",
	"func_toDegrees_return": "Sudut dalam derajat.",
	"func_toLower": "Mengonversi karakter huruf besar dalam <b>string</b> menjadi huruf kecil.",
	"func_toLower_arg_1": "String untuk diubah",
	"func_toLower_return": "String huruf kecil",
	"func_toRadians": "Mengonversi sudut <b>derajat</b> menjadi radian.",
	"func_toRadians_arg_1": "Nilai dalam derajat",
	"func_toRadians_return": "Sudut dalam radian.",
	"func_toUpper": "Mengonversi karakter huruf kecil di <b>string</b> menjadi huruf besar.",
	"func_toUpper_arg_1": "String untuk diubah.",
	"func_toUpper_return": "Untaian huruf besar.",
	"func_typeOf": "Mengembalikan jenis nilai <b>nilai</b>, di antara jenis: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "Nilai yang jenisnya akan dikembalikan.",
	"func_typeOf_return": "Jenis <b>nilai</b>.",
	"func_unshift": "Menambahkan elemen <b>element</b> ke awal <b>array</b>.",
	"func_unshift_arg_1": "Array di mana elemen akan ditambahkan.",
	"func_unshift_arg_2": "Item untuk ditambahkan.",
	"func_useChip": "Gunakan chip <b>chip</b> pada entitas <b>entitas</b>.",
	"func_useChip_arg_1": "Chip untuk digunakan.",
	"func_useChip_arg_2": "Entitas sasaran.",
	"func_useChipOnCell": "Gunakan chip <b>chip</b> pada sel <b>sel</b>.",
	"func_useChipOnCell_arg_1": "Chip untuk digunakan.",
	"func_useChipOnCell_arg_2": "Sel sasaran.",
	"func_useChipOnCell_return": "Nilai lebih besar dari 0 jika serangan diluncurkan.",
	"func_useChip_return": "Nilai pengembalian useChip adalah:<br>\n<ul>\n<li>#USE_CRITICAL, pada pukulan kritis</li>\n<li>#USE_SUCCESS, jika berhasil</li>\n<li>#USE_FAILED, jika gagal</li>\n<li>#USE_INVALID_TARGET, jika target tidak ada</li>\n<li>#USE_NOT_ENOUGH_TP, jika entitas Anda tidak memiliki cukup TP</li>\n<li>#USE_INVALID_COOLDOWN, jika chip belum bisa digunakan</li>\n<li>#USE_INVALID_POSITION, jika jangkauan buruk atau garis pandang tidak jelas</li>\n</ul>",
	"func_useWeapon": "Gunakan senjata yang dipilih pada <b>entitas</b>.",
	"func_useWeapon_arg_1": "Entitas sasaran.",
	"func_useWeaponOnCell": "Gunakan senjata yang dipilih pada sel <b>sel</b>.",
	"func_useWeaponOnCell_arg_1": "Sel sasaran.",
	"func_useWeaponOnCell_return": "Nilai lebih besar dari 0 jika serangan diluncurkan.",
	"func_useWeapon_return": "Nilai pengembalian useWeapon adalah:<br>\n<ul>\n<li>#USE_CRITICAL, pada pukulan kritis</li>\n<li>#USE_SUCCESS, jika berhasil</li>\n<li>#USE_FAILED, jika gagal</li>\n<li>#USE_INVALID_TARGET, jika target tidak ada</li>\n<li>#USE_NOT_ENOUGH_TP, jika entitas Anda tidak memiliki cukup TP</li>\n<li>#USE_INVALID_POSITION, jika jangkauan buruk atau garis pandang tidak jelas</li>\n</ul>",
	"func_weaponNeedLos": "Mengembalikan jika senjata <b>senjata</b> membutuhkan saling berhadapan untuk menembak.",
	"func_weaponNeedLos_arg_1": "ID senjata yang akan diuji.",
	"func_weaponNeedLos_return": "<i>benar</i> jika senjata <b>senjata</b> membutuhkan saling berhadapan untuk menembak, <i>salah</i> sebaliknya.",
	"items": "Item",
	"max_cores": "maks",
	"max_level": "maks",
	"min_cores": "Minimal hati",
	"min_level": "Tingkat min",
	"n_operations": "<b>{0}</b> operasi",
	"parameters": "Pengaturan",
	"return": "Masukan",
	"value": "Nilai",
	"variable_operations": "Operasi <b>Variabel</b>",
	"arg_type_9": "paket",
	"arg_type_10": "interval",
	"function_category_set": "Paket",
	"function_category_interval": "Interval",
	"arg_type_96": "set<entier>"
}

	i18n.mergeLocaleMessage("id", { "doc": messages })
	