
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> 操作",
	"arg_type_-1": "？",
	"arg_type_1": "数字",
	"arg_type_2": "链",
	"arg_type_3": "布尔值",
	"arg_type_4": "列表",
	"arg_type_41": "列表<数字>",
	"arg_type_42": "列表<字符串>",
	"arg_type_43": "列表<布尔值>",
	"arg_type_44": "列表<列表>",
	"arg_type_46": "列表<整数>",
	"arg_type_47": "列表<真实>",
	"arg_type_5": "功能",
	"arg_type_6": "全部的",
	"arg_type_7": "真实的",
	"arg_type_8": "桌子",
	"arg_type_806": "表格<?, 整数>",
	"complexity": "复杂性 {c}",
	"operations": "{o} 操作",
	"details": "细节",
	"optional": "选修的",
	"deprecated_function": "此功能已弃用。",
	"deprecated_constant": "此常量已弃用。",
	"replaced_by": "它被 {0} 取代。",
	"const_AREA_CIRCLE_1": "圆形区域直径为 3 个正方形（十字）。",
	"const_AREA_CIRCLE_2": "直径为 5 个正方形的圆形区域。",
	"const_AREA_CIRCLE_3": "圆形区域，直径为 7 个正方形。",
	"const_AREA_LASER_LINE": "激光区域，从激光的最小范围到最大范围或障碍物的线。",
	"const_AREA_POINT": "由单个框组成的区域。",
	"const_CELL_EMPTY": "空单元格的 getCellContent(cell) 的返回值。",
	"const_CELL_OBSTACLE": "包含障碍物的单元格的 getCellContent(cell) 的返回值。",
	"const_CELL_PLAYER": "包含实体的单元格的 getCellContent(cell) 的返回值。",
	"const_CELL_ENTITY": "包含实体的单元格的 getCellContent(cell) 的返回值。",
	"const_COLOR_BLUE": "颜色为蓝色。",
	"const_COLOR_GREEN": "绿色。",
	"const_COLOR_RED": "红色。",
	"const_E": "欧拉数。",
	"const_EFFECT_ABSOLUTE_SHIELD": "为一个实体提供绝对护盾，将因伤害 (EFFECT_DAMAGE) 移除的生命值减少固定数量。被阻力放大。",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "移除实体的绝对护盾。未被特征放大。允许您按绝对数量增加因伤害 (EFFECT_DAMAGE) 移除的生命值。",
	"const_EFFECT_AFTEREFFECT": "移除实体的生命值。被科学放大。将最大生命值降低 5%，相当于移除的生命值。",
	"const_EFFECT_ANTIDOTE": "移除目标身上的所有毒药 (EFFECT_POISON)。",
	"const_EFFECT_BOOST_MAX_LIFE": "增加实体的生命值和最大生命值。由智慧放大。",
	"const_EFFECT_BUFF_AGILITY": "赋予实体敏捷性。被科学放大。",
	"const_EFFECT_BUFF_MP": "授予实体运动点数。被科学放大。",
	"const_EFFECT_BUFF_RESISTANCE": "赋予实体抗性。被科学放大。",
	"const_EFFECT_BUFF_STRENGTH": "赋予实体力量。被科学放大。",
	"const_EFFECT_BUFF_TP": "向实体授予行动点数。被科学放大。",
	"const_EFFECT_BUFF_WISDOM": "赋予实体智慧。被科学放大。",
	"const_EFFECT_DAMAGE": "移除实体的生命值。力放大。与护盾（EFFECT_ABSOLUTE_SHIELD、EFFECT_RELATIVE_SHIELD、EFFECT_VULNERABILITY、EFFECT_ABSOLUTE_VULNERABILITY）、吸血（施法者除外）和伤害返还（EFFECT_DAMAGE_RETURN）交互。将最大生命值降低 5%，相当于移除的生命值。",
	"const_EFFECT_DAMAGE_RETURN": "为实体提供伤害反射，允许移除对受益者造成伤害的实体的生命值。被敏捷放大。将最大生命值降低 5%，相当于移除的生命值。",
	"const_EFFECT_DEBUFF": "按百分比降低实体上所有效果的值。",
	"const_EFFECT_HEAL": "恢复实体的生命值，受最大生命值限制。由智慧放大。",
	"const_EFFECT_INVERT": "将施法者的位置与实体的位置交换。",
	"const_EFFECT_KILL": "移除实体的所有生命值。",
	"const_EFFECT_LIFE_DAMAGE": "根据施法者生命值的百分比，移除实体的生命值。与护盾（EFFECT_ABSOLUTE_SHIELD、EFFECT_RELATIVE_SHIELD、EFFECT_VULNERABILITY、EFFECT_ABSOLUTE_VULNERABILITY）和伤害返回（EFFECT_DAMAGE_RETURN）交互。将最大生命值降低 5%，相当于移除的生命值。",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "该效果乘以该区域中受影响实体的数量。",
	"const_EFFECT_MODIFIER_ON_CASTER": "该效果仍然影响施法者。",
	"const_EFFECT_MODIFIER_STACKABLE": "效果是累积的。",
	"const_EFFECT_NOVA_DAMAGE": "删除最大生命值。被科学放大。",
	"const_EFFECT_POISON": "移除实体的生命值。被魔法放大了。将最大生命值减少 10% 的生命值移除量。",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "为一个实体提供绝对护盾，将因伤害 (EFFECT_DAMAGE) 移除的生命值减少固定数量。不可放大。",
	"const_EFFECT_RAW_BUFF_AGILITY": "赋予实体敏捷性。不可放大。",
	"const_EFFECT_RAW_BUFF_MAGIC": "赋予实体魔法。不可放大。",
	"const_EFFECT_RAW_BUFF_MP": "授予实体运动点数。不可放大。",
	"const_EFFECT_RAW_BUFF_SCIENCE": "授予实体科学。不可放大。",
	"const_EFFECT_RAW_BUFF_STRENGTH": "赋予实体力量。不可放大。",
	"const_EFFECT_RAW_BUFF_TP": "向实体授予行动点数。不可放大。",
	"const_EFFECT_RELATIVE_SHIELD": "提供相对护盾，减少相对数量的伤害移除的生命值 (EFFECT_DAMAGE)。被阻力放大。",
	"const_EFFECT_RESURRECT": "复活实体，最大生命值等于实体复活前最大生命值的一半，当前生命值等于复活前最大生命值的四分之一。",
	"const_EFFECT_SHACKLE_MAGIC": "移除实体的魔法。被魔法放大了。",
	"const_EFFECT_SHACKLE_MP": "从实体中移除移动点。被魔法放大了。",
	"const_EFFECT_SHACKLE_STRENGTH": "移除实体的强度。被魔法放大了。",
	"const_EFFECT_SHACKLE_TP": "从实体中扣除行动点数。被魔法放大了。",
	"const_EFFECT_SUMMON": "召唤一个灯泡。如果达到团队召唤限制则无效。",
	"const_EFFECT_TARGET_ALLIES": "影响盟友。",
	"const_EFFECT_TARGET_CASTER": "影响施法者。",
	"const_EFFECT_TARGET_ENEMIES": "影响敌人。",
	"const_EFFECT_TARGET_NON_SUMMONS": "影响非召唤实体（韭菜和炮塔）。",
	"const_EFFECT_TARGET_SUMMONS": "影响被召唤的实体（灯泡）。",
	"const_EFFECT_TELEPORT": "改变施法者的位置。",
	"const_EFFECT_VULNERABILITY": "移除与实体相关的盾牌。未被特征放大。增加相对数量的伤害移除的生命值 (EFFECT_DAMAGE)。",
	"const_ENTITY_BULB": "指的是灯泡类型的实体。",
	"const_ENTITY_LEEK": "指一个韭菜类实体。",
	"const_ENTITY_TURRET": "指的是炮塔类型的实体。",
	"const_FIGHT_CONTEXT_CHALLENGE": "挑战式战斗背景。",
	"const_FIGHT_CONTEXT_GARDEN": "在菜园里打架的背景。",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "大逃杀中的战斗环境。",
	"const_FIGHT_CONTEXT_TEST": "测试战斗环境。",
	"const_FIGHT_CONTEXT_TOURNAMENT": "锦标赛战斗背景。",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "在大逃杀中战斗。",
	"const_FIGHT_TYPE_FARMER": "饲养员战斗。",
	"const_FIGHT_TYPE_SOLO": "单打独斗。",
	"const_FIGHT_TYPE_TEAM": "团战。\n",
	"const_INSTRUCTIONS_LIMIT": "一个实体在其回合中可以使用的最大指令数。",
	"const_MAX_TURNS": "一场战斗的最大回合数。",
	"const_OPERATIONS_LIMIT": "一个实体在其回合中可以使用的最大操作数。",
	"const_PI": "圆的周长与其直径之比。",
	"const_SORT_ASC": "告诉#sort 函数按升序排序。",
	"const_SORT_DESC": "告诉#sort 函数按降序排序。",
	"const_TYPE_ARRAY": "值类型<b>列表</b>",
	"const_TYPE_BOOLEAN": "值类型<b>布尔型</b>",
	"const_TYPE_FUNCTION": "值类型<b>函数</b>",
	"const_TYPE_NULL": "值类型 <b>null</b>",
	"const_TYPE_NUMBER": "值类型<b>数字</b>",
	"const_TYPE_STRING": "值类型<b>字符串</b>",
	"const_USE_CRITICAL": "#useWeapon、#useWeaponOnCell、#useChip 和#useChipOnCell 函数在发生重击时返回的值。",
	"const_USE_FAILED": "#useWeapon、#useWeaponOnCell、#useChip 和#useChipOnCell 函数在失败时返回的值。",
	"const_USE_INVALID_COOLDOWN": "如果芯片尚不可用，则#useChip 和#useChipOnCell 函数返回的值。",
	"const_USE_INVALID_POSITION": "#useWeapon、#useWeaponOnCell、#useChip 和#useChipOnCell 函数返回的值（如果射程不佳或视线不清晰）。",
	"const_USE_INVALID_TARGET": "如果目标不存在，则#useWeapon 和#useChip 函数返回的值。",
	"const_USE_NOT_ENOUGH_TP": "如果施法者没有足够的动作点来使用该对象，则由#useWeapon、#useWeaponOnCell、#useChip 和#useChipOnCell 函数返回的值。",
	"const_USE_RESURRECT_INVALID_ENTITY": "当指定的实体不存在或尚未死亡时，#resurrect 函数返回的值。",
	"const_USE_SUCCESS": "#useWeapon、#useWeaponOnCell、#useChip 和#useChipOnCell 函数在成功时返回的值。",
	"const_USE_TOO_MANY_SUMMONS": "当您已经有 <b>8</b> 个实时传票时，#summon 返回错误。\n",
	"func_abs": "返回数字 <b>number</b> 的绝对值。",
	"func_abs_arg_1": "将计算其绝对值的数字。",
	"func_abs_return": "数字的绝对值。",
	"func_acos": "计算区间 [0, #PI] 中 <b>argument</b> 的反余弦值。",
	"func_acos_arg_1": "将计算其反余弦值的数字。",
	"func_acos_return": "<b>参数</b>的反余弦。",
	"func_arrayConcat": "将两个数组首尾相加。保留文本键并重新索引所有数字键。",
	"func_arrayConcat_arg_1": "第一张桌子。",
	"func_arrayConcat_arg_2": "第二张桌子。",
	"func_arrayConcat_return": "两个数组的总和。",
	"func_arrayFilter": "返回一个新数组，其中包含回调函数返回 true 的源数组中的所有键/值对。如果回调函数带一个参数，则发送源数组的值，如果带两个参数，则发送键和值。",
	"func_arrayFilter_arg_1": "原表。",
	"func_arrayFilter_arg_2": "为每个元素调用的函数。",
	"func_arrayFilter_return": "新表。",
	"func_arrayFlatten": "返回一个包含源数组所有元素的新数组。子数组中包含的所有元素都被提取到新数组中。深度参数决定了要提取的最大子数组深度。\n<代码>var array = [1, 2, [[3, 4], 5], [6]];\n调试（arrayFlatten（数组，1））； // [1,2,[3,4],5,6]</code>",
	"func_arrayFlatten_arg_1": "原表。",
	"func_arrayFlatten_arg_2": "最大深度。",
	"func_arrayFlatten_return": "新表。",
	"func_arrayFoldLeft": "从值 <b>v0</b> 开始，从左侧开始减少数组 <b>array</b> [v1, v2, ..., vn] 并应用函数 <b>f</b >。等于 ：\n<代码>f(f(f(v0, v1), v2), ...)</代码>",
	"func_arrayFoldLeft_arg_1": "原表。",
	"func_arrayFoldLeft_arg_2": "要应用的函数。",
	"func_arrayFoldLeft_arg_3": "起始值。",
	"func_arrayFoldLeft_return": "缩小表。",
	"func_arrayFoldRight": "从值 <b>v0</b> 开始，从右开始减少数组 <b>array</b> [v1, v2, ..., vn] 并应用函数 <b>f</b >。等于 ：\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "原表。",
	"func_arrayFoldRight_arg_2": "要应用的函数。",
	"func_arrayFoldRight_arg_3": "起始值。",
	"func_arrayFoldRight_return": "缩小表。",
	"func_arrayIter": "为每个数组元素调用回调函数。如果回调函数带一个参数，则发送源数组的值，如果带两个参数，则发送键和值。",
	"func_arrayIter_arg_1": "原表。",
	"func_arrayIter_arg_2": "为每个元素调用的函数。",
	"func_arrayMap": "返回一个新数组，其中包含源数组的每个键以及回调函数返回的值。如果回调函数带一个参数，则发送源数组的值，如果带两个参数，则发送键和值。",
	"func_arrayMap_arg_1": "原表。",
	"func_arrayMap_arg_2": "为每个元素调用的函数。",
	"func_arrayMap_return": "新表。",
	"func_arrayMax": "返回<b>array</b> 数组的最大值元素。<br/>有关数组最大值的更多信息，请参阅#sort。",
	"func_arrayMax_arg_1": "将在其中寻找最大值的数组。",
	"func_arrayMax_return": "最大值项目。",
	"func_arrayMin": "返回<b>array</b> 数组的最小值元素。<br/>有关数组最小值的更多信息，请参阅#sort。",
	"func_arrayMin_arg_1": "将在其中寻找最小值的数组。",
	"func_arrayMin_return": "最小值项目。",
	"func_arrayPartition": "返回一个包含两个列表的新数组，第一个是回调函数返回 true 的所有键/值对，第二个是所有其他列表。如果回调函数带一个参数，则发送源数组的值，如果带两个参数，则发送键和值。",
	"func_arrayPartition_arg_1": "原表。",
	"func_arrayPartition_arg_2": "为每个元素调用的函数。",
	"func_arrayPartition_return": "新表。",
	"func_arraySort": "根据回调函数定义的顺序对数组进行排序。元素逐个比较，回调函数必须返回值 -1、0 或 1，具体取决于第一个值是在第二个值之前、同级别还是之后。如果回调函数带2个参数，则发送2个值，如果带4个，则发送键/值对。",
	"func_arraySort_arg_1": "原表",
	"func_arraySort_arg_2": "排序功能。",
	"func_arraySort_return": "排序后的数组",
	"func_asin": "计算区间 [0, #PI] 中 <b>argument</b> 的反正弦值。",
	"func_asin_arg_1": "将计算其反正弦值的数字。",
	"func_asin_return": "<b>参数</b>的反正弦值。",
	"func_assocSort": "根据 <b>order</b> 顺序对数组 <b>array</b> 进行排序，保留键：值关联。",
	"func_assocSort_arg_1": "要排序的数组。",
	"func_assocSort_arg_2": "排序顺序：#SORT_ASC 或#SORT_DESC。",
	"func_atan": "计算区间 [0, #PI] 中 <b>argument</b> 的反正切。",
	"func_atan2": "将笛卡尔坐标 (<b>x</b>, <b>y</b>) 转换为极坐标 (<b>r</b>, <b>theta</b>)。此函数使用参数的符号返回 -#PI 和 #PI 之间的 <b>theta</b> 角。",
	"func_atan2_arg_1": "Y 坐标。",
	"func_atan2_arg_2": "X 坐标。",
	"func_atan2_return": "点 (x, y) 的极坐标中的 <b>theta</b> 角。",
	"func_atan_arg_1": "将计算其反正切值的数字。",
	"func_atan_return": "<b>参数</b>的反正切。",
	"func_average": "计算数组 <b>array</b> 中包含的元素的平均值。",
	"func_average_arg_1": "我们要计算其平均值的数组。",
	"func_average_return": "平均值。",
	"func_canUseChip": "确定您的实体是否可以在 ID 为 <b>entity</b> 的实体上使用 <b>chip</b> 芯片。",
	"func_canUseChip_arg_1": "待测芯片编号。",
	"func_canUseChip_arg_2": "您要在其上使用芯片的实体 ID。",
	"func_canUseChip_return": "<i>true</i> 如果您的实体可以使用芯片，<i>false</i> 否则。",
	"func_canUseChipOnCell": "确定您的实体是否可以在 <b>cell</b> 单元格上使用 <b>chip</b> 芯片。",
	"func_canUseChipOnCell_arg_1": "待测芯片编号。",
	"func_canUseChipOnCell_arg_2": "您要在其上使用芯片的单元格的编号。",
	"func_canUseChipOnCell_return": "<i>true</i> 如果您的实体可以使用芯片，<i>false</i> 否则。",
	"func_canUseWeapon": "确定您的实体是否可以使用武器 <b>weapon</b> 射击 ID 为 <b>entity</b> 的实体。",
	"func_canUseWeapon_arg_1": "测试的武器。默认为您当前装备的武器。",
	"func_canUseWeapon_arg_2": "您要触发的实体的 ID。",
	"func_canUseWeapon_return": "<i>true</i> 如果你的实体可以开火，<i>false</i> 否则。",
	"func_canUseWeaponOnCell": "确定您的实体是否可以使用 <b>weapon</b> 武器射击 <b>cell</b> 细胞。",
	"func_canUseWeaponOnCell_arg_1": "测试的武器。默认为您当前装备的武器。",
	"func_canUseWeaponOnCell_arg_2": "您要拍摄的单元格的编号。",
	"func_canUseWeaponOnCell_return": "<i>true</i> 如果你的实体可以开火，<i>false</i> 否则。",
	"func_cbrt": "计算<b>数</b>的立方根",
	"func_cbrt_arg_1": "我们要计算其立方根的数字。",
	"func_cbrt_return": "<b>数</b>的立方根。",
	"func_ceil": "返回<b>number</b>的四舍五入。<br/>您可以使用#floor 找到四舍五入，使用#round 找到四舍五入。",
	"func_ceil_arg_1": "我们要为其查找上舍入的数字。",
	"func_ceil_return": "向上舍入<b>数字</b>。",
	"func_charAt": "返回位于 <b>position</b> 位置的字符串 <b>string</b> 的字符。<br/>字符串的第一个字符位于位置 0。",
	"func_charAt_arg_1": "我们要从中检索字符的字符串。",
	"func_charAt_arg_2": "要查找的字符的位置。",
	"func_charAt_return": "包含查找字符的字符串，如果位置无效则为空字符串。",
	"func_chipNeedLos": "返回<b>芯片</b>是否需要使用视线。",
	"func_chipNeedLos_arg_1": "待测芯片的id。",
	"func_chipNeedLos_return": "<i>true</i> 如果<b>芯片</b> 需要使用视线，否则为<i>false</i>。",
	"func_clearMarks": "清除字段中 mark() 和 markText() 所做的所有标记。",
	"func_clone": "复制在 <b>level</b> 级别上作为参数传递的 <b>value</b> 并返回副本。例如，对于数组，clone(array, 1) 将复制数组但不复制其元素，clone(array, 2) 将复制数组及其所有元素。",
	"func_clone_arg_1": "要克隆的值",
	"func_clone_arg_2": "要克隆的级别数",
	"func_clone_return": "克隆值",
	"func_contains": "确定 <b>search</b> 字符串是否在 <b>string</b> 字符串中。",
	"func_contains_arg_1": "将在其中执行搜索的字符串。",
	"func_contains_arg_2": "要搜索的字符串。",
	"func_contains_return": "如果 <b>search</b> 在 <b>string</b> 中，则为 true，否则为 false。",
	"func_cos": "计算<b>角度</b>的余弦。",
	"func_cos_arg_1": "将计算其余弦的数字（以弧度为单位）。",
	"func_cos_return": "<b>角度</b> 的余弦值，范围 [-1, 1]。",
	"func_count": "计算 <b>array</b> 数组中的元素数。",
	"func_count_arg_1": "将对其元素数进行计数的数组。",
	"func_count_return": "数组元素的数量。",
	"func_debug": "在个人日志中记录一条<b>对象</b>消息，可在战斗结束时的报告中找到。",
	"func_debug_arg_1": "要记录的消息。",
	"func_debugC": "在个人日志中记录一条<b>对象</b>消息，可在战斗结束时的报告中查看，颜色为<b>颜色</b>。",
	"func_debugC_arg_1": "要记录的消息。",
	"func_debugC_arg_2": "消息的颜色。您可以使用#getColor 函数。",
	"func_debugE": "在个人日志中记录一条<b>对象</b>错误信息，可在战斗结束时的报告中找到。<br />错误信息在战斗报告中以红色显示。",
	"func_debugE_arg_1": "要记录的错误消息。",
	"func_debugW": "在个人日志中记录一条<b>对象</b>警告消息，可在战斗结束时的报告中找到。 <br />战斗报告中的警告信息以橙色显示。",
	"func_debugW_arg_1": "要记录的警告消息。",
	"func_deleteRegister": "删除与密钥 <b>key</b> 关联的注册表（如果存在）。",
	"func_deleteRegister_arg_1": "要删除的注册表项。",
	"func_endsWith": "确定 <b>string</b> 是否以 <b>suffix</b> 字符串结尾。",
	"func_endsWith_arg_1": "将在其中执行搜索的字符串",
	"func_endsWith_arg_2": "要搜索的后缀",
	"func_endsWith_return": "如果 <b>string</b> 以 <b>suffix</b> 结尾则为真",
	"func_exp": "将欧拉数#E 提高到<b>数</b> 次方。",
	"func_exp_arg_1": "#E 将提高到的指数。",
	"func_exp_return": "#E** <b>数字</b>。",
	"func_fill": "将数组 <b>array</b> 的大小调整为 <b>size</b> 并用 <b>value</b> 填充其所有框。",
	"func_fill_arg_1": "表完成。",
	"func_fill_arg_2": "数组中每个方块的值。",
	"func_fill_arg_3": "盒子的数量。默认情况下，当前数组大小。",
	"func_floor": "计算 <b>number</b> 的向下舍入。<br/>您可以使用#ceil 查找向上舍入，使用#round 查找舍入。",
	"func_floor_arg_1": "我们要查找其向下舍入的数字。",
	"func_floor_return": "四舍五入为<b>数字</b>。",
	"func_getAbsoluteShield": "返回 ID 为 <b>entity</b> 的实体的绝对护盾。要直接检索实体的绝对护盾，请使用不带参数的#getAbsoluteShield()。",
	"func_getAbsoluteShield_arg_1": "将返回其绝对护盾的实体的 ID。",
	"func_getAbsoluteShield_return": "实体<b>实体</b>的绝对护盾。",
	"func_getAgility": "返回 ID 为 <b>entity</b> 的实体的敏捷性。要直接检索实体的敏捷性，请使用不带参数的#getAgility()。",
	"func_getAgility_arg_1": "将返回其敏捷性的实体的 ID。",
	"func_getAgility_return": "<b>实体</b>实体的敏捷性。",
	"func_getAIID": "返回实体<b>entity</b>的AI id。使用不带参数的 getAIID 来检索您的 AI 的 ID。",
	"func_getAIID_arg_1": "将返回其 AI id 的实体的 id。",
	"func_getAIID_return": "<b>entity</b> 实体 AI id。",
	"func_getAIName": "返回实体 AI <b>entity</b> 的名称。使用不带参数的 getAINam() 来检索您的 AI 的名称。",
	"func_getAIName_arg_1": "将返回其 AI 名称的实体的 ID。",
	"func_getAIName_return": "<b>实体</b>实体AI的名称。",
	"func_getAliveAllies": "返回战斗中所有活着的盟友的数组。",
	"func_getAliveAllies_return": "包含所有活着的盟友 ID 的数组。",
	"func_getAliveEnemies": "返回战斗中所有活着的敌人的数组。",
	"func_getAliveEnemiesCount": "返回战斗中存活的敌人数量。",
	"func_getAliveEnemiesCount_return": "活着的敌人的数量。",
	"func_getAliveEnemies_return": "包含所有活着的敌人的 ID 的数组。",
	"func_getAllChips": "返回游戏中所有筹码的列表。",
	"func_getAllChips_return": "游戏中所有筹码的列表。",
	"func_getAllEffects": "返回游戏中所有效果的列表。",
	"func_getAllEffects_return": "游戏中所有效果的列表。",
	"func_getAllWeapons": "返回游戏中所有武器的列表。",
	"func_getAllWeapons_return": "游戏中所有武器的列表。",
	"func_getAlliedTurret": "返回你团队的炮塔 ID，如果不存在则返回 -1。",
	"func_getAlliedTurret_return": "您团队的炮塔 ID。",
	"func_getEnemyTurret": "返回敌方炮塔 ID，如果不存在则返回 -1。",
	"func_getEnemyTurret_return": "敌方炮塔 ID。",
	"func_getAllies": "返回一个包含你的盟友和你的实体的数组。",
	"func_getAlliesCount": "返回战斗中盟友的数量。",
	"func_getAlliesCount_return": "盟友的数量。",
	"func_getAlliesLife": "返回盟友的总生命值。",
	"func_getAlliesLife_return": "你盟友的总生命值。",
	"func_getAllies_return": "盟友表和您的实体。",
	"func_getBirthTurn": "返回<b>实体</b>出现的战斗回合。例如，如果是韭菜，则返回 1，如果是在第 5 回合调用的传票，则返回 5。",
	"func_getBirthTurn_arg_1": "将返回生成回合的实体的 ID。",
	"func_getBirthTurn_return": "<b>实体</b>出现的战斗回合。",
	"func_getBlue": "返回颜色 <b>color</b> 中的蓝色量，介于 0 和 255 之间。例如，getBlue(#COLOR_BLUE) = 255 和 getBlue(#COLOR_GREEN) = 0。",
	"func_getBlue_arg_1": "将返回蓝色率的颜色。",
	"func_getBlue_return": "颜色<b>color</b>中蓝色的比例",
	"func_getBulbChips": "返回将装备在 <b>bulb_chip</b> 芯片召唤的灯泡上的芯片列表。",
	"func_getBulbChips_arg_1": "灯泡跳蚤。",
	"func_getBulbChips_return": "<b>bulb_chip</b> 芯片召唤的灯泡芯片。",
	"func_getCell": "返回 ID 为 <b>entity</b> 的实体所在的单元格。使用不带参数的 getCell() 来检索您的单元格。",
	"func_getCell_arg_1": "将返回其单元格的实体的 ID。",
	"func_getCell_return": "<b>实体</b>所在的单元格编号。",
	"func_getCellContent": "返回 ID 为 <b>cell</b> 的单元格的内容。",
	"func_getCellContent_arg_1": "将返回其内容的单元格的 ID。",
	"func_getCellContent_return": "<b>cell</b> 单元格的内容：#CELL_EMPTY 表示空单元格，#CELL_ENTITY 表示实体，#CELL_OBSTACLE 表示障碍物。",
	"func_getCellDistance": "返回两个单元格 <b>cell1</b> 和 <b>cell2</b> 之间的距离。 <br />返回的距离以单元格数表示，不考虑两个单元格之间的各种障碍物。\n要获取乌鸦飞行时的距离，请参见#getDistance，要获取两个单元之间避开障碍物的路径距离，请参见#getPathLength。",
	"func_getCellDistance_arg_1": "起始单元格的 ID。",
	"func_getCellDistance_arg_2": "到达小区的 ID。",
	"func_getCellDistance_return": "两个单元格 <b>cell1</b> 和 <b>cell2</b> 之间的距离。",
	"func_getCellFromXY": "返回位置 (<b>x</b>, <b>y</b>) 处的单元格 ID。",
	"func_getCellFromXY_arg_1": "单元格的 x 位置。",
	"func_getCellFromXY_arg_2": "单元格的 y 位置。",
	"func_getCellFromXY_return": "位置 (<b>x</b>, <b>y</b>) 的单元格 ID，如果单元格不存在，则为 <b>null</b>。",
	"func_getCellsToUseChip": "返回您的实体将能够在 <b>entity</b> 实体上使用 <b>chip</b> 芯片的单元格列表。",
	"func_getCellsToUseChip_arg_1": "实体希望能够使用的芯片。",
	"func_getCellsToUseChip_arg_2": "目标实体。",
	"func_getCellsToUseChip_arg_3": "要忽略的单元格数组。",
	"func_getCellsToUseChip_return": "可以使用芯片的电池列表。",
	"func_getCellsToUseChipOnCell": "返回您的实体将能够在 <b>cell</b> 单元格上使用 <b>chip</b> 芯片的单元格列表。",
	"func_getCellsToUseChipOnCell_arg_1": "实体希望能够使用的芯片。",
	"func_getCellsToUseChipOnCell_arg_2": "目标细胞。",
	"func_getCellsToUseChipOnCell_arg_3": "要忽略的单元格数组。",
	"func_getCellsToUseChipOnCell_return": "可以使用芯片的电池列表。",
	"func_getCellsToUseWeapon": "返回您的实体将能够在实体 <b>entity</b> 上使用武器 <b>weapon</b> 的单元格列表。",
	"func_getCellsToUseWeapon_arg_1": "测试的武器。默认为您当前装备的武器。",
	"func_getCellsToUseWeapon_arg_2": "目标实体。",
	"func_getCellsToUseWeapon_arg_3": "要忽略的单元格数组。默认为空数组。",
	"func_getCellsToUseWeapon_return": "可以使用武器的细胞列表。",
	"func_getCellsToUseWeaponOnCell": "返回您的实体将能够在 <b>cell</b> 单元格上使用 <b>weapon</b> 武器的单元格列表。",
	"func_getCellsToUseWeaponOnCell_arg_1": "测试的武器。默认为您当前装备的武器。",
	"func_getCellsToUseWeaponOnCell_arg_2": "目标细胞。",
	"func_getCellsToUseWeaponOnCell_arg_3": "要忽略的单元格数组。默认情况下是一个空数组。",
	"func_getCellsToUseWeaponOnCell_return": "可以使用武器的细胞列表。",
	"func_getCellX": "确定单元格 <b>cell</b> 在 X 中的位置。",
	"func_getCellX_arg_1": "将确定其在 X 中的位置的单元格。",
	"func_getCellX_return": "单元格的 X 位置。",
	"func_getCellY": "确定单元格 <b>cell</b> 的 Y 位置。",
	"func_getCellY_arg_1": "将确定其在 Y 中的位置的单元格。",
	"func_getCellY_return": "单元格的 Y 位置。",
	"func_getChipArea": "返回<b>芯片</b>效果类型的面积。",
	"func_getChipArea_arg_1": "其区域类型将被返回的筹码。",
	"func_getChipArea_return": "AREA_*常量中芯片<b>chip</b>的区域类型：\n<ul>\n<li>#AREA_POINT：单个正方形区域</li>\n<li>#AREA_LASER_LINE：激光线</li>\n<li>#AREA_CIRCLE_1：直径为3个正方形的圆形区域</li>\n<li>#AREA_CIRCLE_2：直径为5个正方形的圆形区域</li>\n<li>#AREA_CIRCLE_3：直径为 7 个正方形的圆形区域</li>\n</ul>",
	"func_getChipCooldown": "返回 <b>chip</b> 芯片恢复时间，取自市场。",
	"func_getChipCooldown_arg_1": "将返还冷却时间的筹码。",
	"func_getChipCooldown_return": "<b>芯片</b>冷却时间。",
	"func_getChipCost": "返回芯片 <b>chip</b> 的 TP 成本。",
	"func_getChipCost_arg_1": "花费的芯片将被退回。",
	"func_getChipCost_return": "<b>芯片</b>的成本。",
	"func_getChipEffectiveArea": "如果 <b>chip</b> 芯片用于 <b>from</b> 单元格中的 <b>cell</b> 单元格，则返回将受到影响的单元格列表。",
	"func_getChipEffectiveArea_arg_1": "待测芯片。",
	"func_getChipEffectiveArea_arg_2": "目标细胞。",
	"func_getChipEffectiveArea_arg_3": "使用芯片的电池。",
	"func_getChipEffectiveArea_return": "包含将受影响的所有单元格的 ID 的数组。",
	"func_getChipEffects": "返回<b>筹码</b>筹码的效果。",
	"func_getChipEffects_arg_1": "其效果将被退还的筹码。",
	"func_getChipEffects_return": "<b>芯片</b>芯片的效果。与#getWeaponEffects 函数的返回值相同。",
	"func_getChipFailure": "返回芯片 <b>chip</b> 失败的百分比风险。",
	"func_getChipFailure_arg_1": "返回失败百分比的芯片。",
	"func_getChipFailure_return": "<b>chip</b> 芯片故障百分比，<b>0</b>到<b>100</b>之间的整数。",
	"func_getChipMaxRange": "返回<b>芯片</b>芯片的最大范围。",
	"func_getChipMaxRange_arg_1": "将返回其最大射程的芯片。",
	"func_getChipMaxRange_return": "<b>芯片</b>的最大范围。",
	"func_getChipMaxScope": "返回<b>芯片</b>芯片的最大范围。",
	"func_getChipMaxScope_arg_1": "将返回其最大射程的芯片。",
	"func_getChipMaxScope_return": "<b>芯片</b>的最大范围。",
	"func_getChipMinRange": "返回<b>芯片</b>芯片的最小范围。",
	"func_getChipMinRange_arg_1": "将返回其最小范围的筹码。",
	"func_getChipMinRange_return": "<b>芯片</b>的最小范围。",
	"func_getChipMinScope": "返回<b>芯片</b>芯片的最小范围。",
	"func_getChipMinScope_arg_1": "将返回其最小范围的筹码。",
	"func_getChipMinScope_return": "<b>芯片</b>的最小范围。",
	"func_getChipLaunchType": "返回 <b>chip</b> 芯片的启动模式，在 LAUNCH_TYPE_* 常量中。",
	"func_getChipLaunchType_arg_1": "返回投掷模式的筹码id。",
	"func_getChipLaunchType_return": "<b>筹码</b>筹码的投掷方式。",
	"func_getChipName": "返回芯片的名称 <b>chip</b>。",
	"func_getChipName_arg_1": "将返回其名称的芯片。",
	"func_getChipName_return": "<b>芯片</b>芯片的名称。",
	"func_getChips": "返回 ID 为 <b>entity</b> 的实体的项目符号。",
	"func_getChips_arg_1": "将返回其子弹的实体的 ID。",
	"func_getChips_return": "包含实体 <b>entity</b> 的项目符号 ID 的数组。",
	"func_getChipTargets": "如果 <b>chip</b> chip 被用在 cell <b>cell</b> 上，返回将受到影响的实体。",
	"func_getChipTargets_arg_1": "待测芯片。",
	"func_getChipTargets_arg_2": "目标细胞。",
	"func_getChipTargets_return": "包含将受影响的所有实体的 ID 的数组。",
	"func_getColor": "返回与作为参数提供的颜色（<b>红色</b>、<b>绿色</b>、<b>蓝色</b>）相对应的整数。",
	"func_getColor_arg_1": "红色值介于 0 和 255 之间。",
	"func_getColor_arg_2": "绿色值介于 0 和 255 之间。",
	"func_getColor_arg_3": "蓝色值介于 0 和 255 之间。",
	"func_getColor_return": "与作为参数提供的颜色相对应的 int。",
	"func_getCooldown": "返回<b>实体</b>实体的当前<b>芯片</b>冷却时间。",
	"func_getCooldown_arg_1": "当前冷却时间将被返还的芯片。",
	"func_getCooldown_arg_2": "将返回其冷却时间的实体。",
	"func_getCooldown_return": "当前芯片的冷却时间 <b>chip</b>，这是芯片可用之前的转数，如果当前可用，则为 <b>0</b>。",
	"func_getCores": "返回 ID 为 <b>entity</b> 的实体的核心数。",
	"func_getCores_arg_1": "将返回其核心数的实体。",
	"func_getCores_return": "<b>实体</b>实体的核心数。",
	"func_getDate": "以 dd/MM/yyyy 格式返回战斗日期。",
	"func_getDate_return": "战斗的日期。",
	"func_getTime": "返回战斗时间，格式为 HH:mm:ss。",
	"func_getTime_return": "战斗的时间。",
	"func_getTimestamp": "返回战斗的时间戳，等于自 1970 年 1 月 1 日以来的秒数。",
	"func_getTimestamp_return": "战斗的时间戳。",
	"func_getDamageReturn": "返回 ID 为 <b>entity</b> 的实体的伤害返还率。",
	"func_getDamageReturn_arg_1": "将从中返回损坏引用的实体的 ID。",
	"func_getDamageReturn_return": "Id为<b>entity</b>的实体的伤害返还率（%）。",
	"func_getDeadAllies": "返回死去的友好实体。",
	"func_getDeadAllies_return": "同盟死亡实体表。",
	"func_getDeadEnemies": "返回死去的敌方实体。",
	"func_getDeadEnemiesCount": "返回在战斗中死亡的敌人数量。",
	"func_getDeadEnemiesCount_return": "死亡敌人的数量。",
	"func_getDeadEnemies_return": "死亡敌方实体表。",
	"func_getDistance": "计算乌鸦在两个单元格 <b>cell1</b> 和 <b>cell2</b> 之间飞行时的距离。 <br />要获取以单元格数量表示的距离，请参阅#getCellDistance，要获取两个单元格之间躲避各种障碍物的路径长度，请参阅#getPathLength。",
	"func_getDistance_arg_1": "起始细胞。",
	"func_getDistance_arg_2": "到达细胞。",
	"func_getDistance_return": "乌鸦在两个单元格之间飞行的距离。",
	"func_getPassiveEffects": "返回 ID 为 <b>entity</b> 的实体的被动效果列表。要直接检索实体的被动效果列表，请使用不带参数的#getPassiveEffects()。",
	"func_getPassiveEffects_arg_1": "将返回其被动效果列表的实体的 ID。",
	"func_getPassiveEffects_return": "<b>entity</b> 实体当前存在的被动效果列表。\n被动效果列表是一个包含效果的数组。\n效果本身是一个包含 7 个框的数组，格式如下：[<b>type</b>, <b>value</b>, <b>caster_id</b>, <b>turns</b>, <b>关键</b>、<b>item_id</b>、<b>target_id</b>]。它与#getEffects 函数返回的经典效果的结构相同。",
	"func_getEnemies": "将敌方实体（活着的或死去的）送回战斗。",
	"func_getEnemiesCount": "返回战斗中敌人的数量。",
	"func_getEnemiesCount_return": "敌人的数量。",
	"func_getEnemiesLife": "计算所有敌方实体的生命值总和。",
	"func_getEnemiesLife_return": "敌方队伍的生命值总和。",
	"func_getEnemies_return": "包含所有敌方实体 ID 的数组。",
	"func_getEntityTurnOrder": "返回一个介于 1 和 n（当前正在播放的实体数）之间的值，指示 <b>实体</b> 在播放顺序中的位置。",
	"func_getEntityTurnOrder_arg_1": "播放顺序将被返回的实体的id",
	"func_getEntityTurnOrder_return": "放置实体<b>实体</b>的播放顺序",
	"func_getFarmerID": "返回实体 <b>entity</b> 的饲养员 ID。",
	"func_getFarmerID_arg_1": "将返回其育种者 ID 的实体的 ID。",
	"func_getFarmerID_return": "<b>entity</b> 实体培育者的 id。",
	"func_getFarmerCountry": "返回实体 <b>entity</b> 的育种者所在的国家/地区。",
	"func_getFarmerCountry_arg_1": "将返回其育种者所在国家/地区的实体的 ID。",
	"func_getFarmerCountry_return": "<b>实体</b>实体育种者的国家，或“？”如果没有说明。",
	"func_getFarmerName": "返回 <b>entity</b> 实体的育种者名称。",
	"func_getFarmerName_arg_1": "将返回其育种者名称的实体的 ID。",
	"func_getFarmerName_return": "<b>entity</b> 实体的育种者姓名。",
	"func_getFarthestAlly": "确定你的实体最远的盟友，如乌鸦飞翔。",
	"func_getFarthestAlly_return": "最远友好实体的 ID。",
	"func_getFarthestEnemy": "确定距离您的实体最远的敌人，如乌鸦飞翔。",
	"func_getFarthestEnemy_return": "最远敌方实体的 ID。",
	"func_getFightID": "返回当前战斗 ID。",
	"func_getFightID_return": "当前战斗的id。",
	"func_getFightContext": "返回当前战斗的上下文。",
	"func_getFightContext_return": "根据战斗环境：测试战 (#FIGHT_CONTEXT_TEST)、竞技场战 (#FIGHT_CONTEXT_GARDEN)、锦标赛战 (#FIGHT_CONTEXT_TOURNAMENT)、挑战战 (#FIGHT_CONTEXT_CHALLENGE)、大逃杀 (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "返回当前的战斗类型。",
	"func_getFightType_return": "取决于战斗类型：单人战斗 (#FIGHT_TYPE_SOLO)、农夫战斗 (#FIGHT_TYPE_FARMER)、团队战斗 (#FIGHT_TYPE_TEAM)、大逃杀 (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "返回 ID 为 <b>entity</b> 的实体的强度。",
	"func_getForce_arg_1": "力量将被归还的实体。",
	"func_getForce_return": "<b>实体</b>实体的强度。",
	"func_getFrequency": "返回 ID 为 <b>entity</b> 的实体的频率。使用不带参数的 getFrequency() 来检索您的频率。",
	"func_getFrequency_arg_1": "将返回其频率的实体。",
	"func_getFrequency_return": "<b>实体</b>实体的频率。",
	"func_getGreen": "返回颜色 <b>color</b> 中的绿色量，介于 0 和 255 之间。例如，getGreen(#COLOR_GREEN) = 255 和 getGreen(#COLOR_RED) = 0。",
	"func_getGreen_arg_1": "将返回其绿色率的颜色。",
	"func_getGreen_return": "颜色<b>color</b>中绿色的比例",
	"func_getInstructionsCount": "返回您的实体在当前回合中执行的指令数。",
	"func_getInstructionsCount_return": "您的实体在当前回合中执行的指令数。",
	"func_getLaunchedEffects": "返回由 ID 为 <b>entity</b> 的实体引起的影响列表。",
	"func_getLaunchedEffects_arg_1": "将返回其造成影响列表的实体的 ID。",
	"func_getLaunchedEffects_return": "由 ID 为 <b>entity</b> 的实体引起的效果列表，其形式与 #getEffects 返回的数组相同。",
	"func_getLeek": "返回您的实体的 ID。",
	"func_getLeek_return": "您的实体的 ID。",
	"func_getEntity": "返回您的实体的 ID。",
	"func_getEntity_return": "您的实体的 ID。",
	"func_getLeekID": "返回id为<b>leek</b>的韭菜的真实id。",
	"func_getLeekID_arg_1": "将返回其实际 ID 的韭菜 ID。",
	"func_getLeekID_return": "韭菜<b>实体</b>的真实ID。",
	"func_getLeekOnCell": "返回单元格 <b>cell</b> 上的实体。",
	"func_getLeekOnCell_arg_1": "我们要检索其实体的单元格。",
	"func_getLeekOnCell_return": "单元格上实体的 ID，如果单元格没有实体，则为 -1。",
	"func_getEntityOnCell": "返回单元格 <b>cell</b> 上的实体。",
	"func_getEntityOnCell_arg_1": "我们要检索其实体的单元格。",
	"func_getEntityOnCell_return": "单元格上实体的 ID，如果单元格没有实体，则为 -1。",
	"func_getLevel": "返回 ID 为 <b>entity</b> 的实体级别。",
	"func_getLevel_arg_1": "将返回其级别的实体的 ID。",
	"func_getLevel_return": "ID为<b>entity</b>的实体的级别。",
	"func_getLife": "返回 ID 为 <b>entity</b> 的实体的当前生命周期。使用不带参数的 getLife() 来恢复您的生命。",
	"func_getLife_arg_1": "将返回其生命的实体的 ID。",
	"func_getLife_return": "<b>实体</b>实体的当前生命周期。",
	"func_getMagic": "返回 id 为 <b>entity</b> 的实体的魔力。使用不带参数的 getMagic() 来检索您的魔法。",
	"func_getMagic_arg_1": "将返回其魔法的实体的 ID。",
	"func_getMagic_return": "Id entity <b>entity</b> 的魔力。",
	"func_getMapType": "返回发生战斗的地形类型（工厂、沙漠、森林等），在常量#MAP_NEXUS、#MAP_FACTORY、#MAP_DESERT、#MAP_FOREST、#MAP_GLACIER 和#MAP_BEACH 中。",
	"func_getMapType_return": "地形类型。",
	"func_getMessageAuthor": "返回创作消息的实体的 ID <b>消息</b>。",
	"func_getMessageAuthor_arg_1": "将返回其作者的邮件。",
	"func_getMessageAuthor_return": "编写消息 <b>message</b> 的实体的 ID。",
	"func_getMessageParams": "返回消息参数数组 <b>message</b>。",
	"func_getMessageParams_arg_1": "将返回其参数的消息。",
	"func_getMessageParams_return": "<b>消息</b>消息参数。",
	"func_getMessages": "返回 <b>entity</b> 实体消息数组。",
	"func_getMessages_arg_1": "将返回其消息的实体。",
	"func_getMessages_return": "您的消息表。<br>消息本身以\n形式的数组：[<b>author</b>, <b>type</b>, <b>parameters</b>]<br>\n不同类型的消息由常量表示：\n<ul>\n<li>#MESSAGE_HEAL：请求护理</li>\n<li>#MESSAGE_ATTACK：请求攻击</li>\n<li>#MESSAGE_BUFF_FORCE：强制提升请求</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "返回消息类型<b>消息</b>。",
	"func_getMessageType_arg_1": "将返回其类型的消息（#MESSAGE_HEAL、#MESSAGE_ATTACK 等）。",
	"func_getMessageType_return": "消息类型<b>消息</b>。",
	"func_getMP": "返回<b>实体</b>实体的当前移动点数。使用不带参数的 getMP() 来检索您的 PM。",
	"func_getMP_arg_1": "将返回其 PM 计数的实体的 ID。",
	"func_getMP_return": "<b>entity</b> 实体的 MP 编号。",
	"func_getName": "返回 ID 为 <b>entity</b> 的实体的名称。",
	"func_getName_arg_1": "将返回其名称的实体的 ID。",
	"func_getName_return": "实体名称<b>实体</b>。",
	"func_getNearestAlly": "返回离你的实体最近的友好实体。",
	"func_getNearestAlly_return": "最近的友好实体的 ID。",
	"func_getNearestAllyTo": "返回与作为参数提供的实体最接近的联合实体。",
	"func_getNearestAllyTo_arg_1": "我们想知道其最亲密盟友的实体的 ID。",
	"func_getNearestAllyToCell": "返回距离作为参数提供的单元格最近的同盟实体。",
	"func_getNearestAllyToCell_arg_1": "我们想知道其最亲密盟友的单元格的 ID。",
	"func_getNearestAllyToCell_return": "最近的友好实体的 ID。",
	"func_getNearestAllyTo_return": "最近的友好实体的 ID。",
	"func_getNearestEnemy": "将最近的敌人实体返回到您的实体。",
	"func_getNearestEnemy_return": "最近的敌方实体的 ID。",
	"func_getNearestEnemyTo": "返回距离作为参数提供的实体最近的敌人实体。",
	"func_getNearestEnemyTo_arg_1": "我们想知道其最接近敌人的实体的 ID。",
	"func_getNearestEnemyToCell": "返回距离作为参数提供的单元格最近的敌人实体。",
	"func_getNearestEnemyToCell_arg_1": "我们想知道其最近敌人的单元格的 ID。",
	"func_getNearestEnemyToCell_return": "最近的敌方实体的 ID。",
	"func_getNearestEnemyTo_return": "最近的敌方实体的 ID。",
	"func_getNextPlayer": "返回将在当前玩家之后播放的实体的 ID。",
	"func_getNextPlayer_return": "下一位选手。",
	"func_getObstacles": "返回地形中障碍空间的列表。",
	"func_getObstacles_return": "包含障碍单元 ID 的数组。",
	"func_getOperations": "返回您的实体自其回合开始以来消耗的操作数。此数字必须保持低于#OPERATIONS_LIMIT，这样实体才不会崩溃。",
	"func_getOperations_return": "您的实体自回合开始以来消耗的操作数。",
	"func_getPath": "返回避开两个单元格 <b>cell1</b> 和 <b>cell2</b> 之间的障碍物的路径，如果存在，则忽略数组 <b>ignoredCells</b> 中包含的单元格。如果玩家在被忽略的单元格上，路径可以越过他们。<br /><br />\n起始单元格 <b>cell1</b> 永远不会成为结果路径的一部分。当且仅当单元格 <b>cell2</b> 为空或被 <b>ignoredCells</b> 忽略时，单元格 <b>cell2</b> 才是结果路径的一部分。<br /><br />\n如果两个单元格之间不存在路径，<b>getPath</b> 返回 <i>null</i>。",
	"func_getPath_arg_1": "起始细胞。",
	"func_getPath_arg_2": "到达细胞。",
	"func_getPath_arg_3": "要忽略的单元格数组。",
	"func_getPath_return": "包含构成两个单元格之间路径的单元格的数组。",
	"func_getPathLength": "返回两个单元格 <b>cell1</b> 和 <b>cell2</b> 之间的路径长度，避开障碍物，忽略数组 <b>ignoredCells</b> 中包含的单元格。此函数等效于 <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>。\n如果玩家在被忽略的单元格上，路径可以越过他们。<br /><br />\n起始单元格 <b>cell1</b> 永远不会计入结果。当且仅当单元格 <b>cell2</b> 为空或被 <b>ignoredCells</b> 忽略时，它才会计入结果。<br /><br />\n如果两个单元格之间不存在路径，<b>getPathLength</b> 返回 <i>null</i>。",
	"func_getPathLength_arg_1": "起始细胞。",
	"func_getPathLength_arg_2": "到达细胞。",
	"func_getPathLength_arg_3": "要忽略的单元格数组。",
	"func_getPathLength_return": "<b>cell1</b> 和 <b>cell2</b> 之间的路径长度。",
	"func_getPreviousPlayer": "返回在当前玩家之前玩过的实体的 ID。",
	"func_getPreviousPlayer_return": "前任选手。",
	"func_getRed": "返回颜色 <b>color</b> 中红色的数量，介于 0 和 255 之间。例如，getRed(#COLOR_RED) = 255 和 getRed(#COLOR_BLUE) = 0。",
	"func_getRed_arg_1": "将返回其红色率的颜色。",
	"func_getRed_return": "颜色<b>color</b>中红色的比例",
	"func_getRegister": "返回存储在与键 <b>key</b> 关联的实体注册表中的值，如果注册表不存在，则返回 </i>null</i>。",
	"func_getRegister_arg_1": "将返回其值的注册表项。",
	"func_getRegister_return": "存储在 <b>key</b> 注册表中的值。",
	"func_getRegisters": "将实体寄存器集作为关联数组 [<i>注册表键</i>:<i>寄存器值</i>] 返回。示例：<代码>调试（getRegisters()）；\n// 显示例如：\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "实体所有寄存器对应的关联数组。",
	"func_getRelativeShield": "返回 ID 为 <b>entity</b> 的实体的相对护盾。要直接检索实体的相对护盾，请使用不带参数的#getRelativeShield()。",
	"func_getRelativeShield_arg_1": "将返回其相对盾牌的实体的 ID。",
	"func_getRelativeShield_return": "<b>实体</b>的相对护盾，一个介于<b>0</b>和<b>100</b>之间的整数。",
	"func_getResistance": "返回 ID 为 <b>entity</b> 的实体的阻力。使用不带参数的 getResistance() 来检索您的阻力。",
	"func_getResistance_arg_1": "将返回其阻力的实体的 ID。",
	"func_getResistance_return": "Id为<b>entity</b>的实体的阻力。",
	"func_getScience": "返回 ID 为 <b>entity</b> 的实体的科学。使用不带参数的 getScience() 来检索您的科学。",
	"func_getScience_arg_1": "将返回其科学的实体的 ID。",
	"func_getScience_return": "身份实体<b>实体</b>的科学。",
	"func_getStrength": "返回 ID 为 <b>entity</b> 的实体的强度。使用不带参数的 getStrength() 来检索您的强度。",
	"func_getStrength_arg_1": "力量将被归还的实体。",
	"func_getStrength_return": "<b>实体</b>实体的强度。",
	"func_getPower": "返回 ID 为 <b>entity</b> 的实体的力量。",
	"func_getPower_arg_1": "权力将被归还的实体。",
	"func_getPower_return": "<b>实体</b>的力量。",
	"func_getSummoner": "如果是调用，则返回调用实体 <b>entity</b> 的实体。",
	"func_getSummoner_arg_1": "其召唤者将被解雇的实体的 ID。",
	"func_getSummoner_return": "实体的 id 调用 <b>entity</b> 如果它是一个调用，则为 <i>null</i> 否则。",
	"func_getSummons": "返回 ID 为 <b>entity</b> 的实体的当前活动调用的 ID 列表。",
	"func_getSummons_arg_1": "其传票将被退回的实体的 ID。",
	"func_getSummons_return": "Id 为 <b>entity</b> 的实体的调用 id 列表。",
	"func_getTeamID": "返回实体 <b>entity</b> 的团队 ID。",
	"func_getTeamID_arg_1": "将返回其团队 ID 的实体的 ID。",
	"func_getTeamID_return": "实体 <b>entity</b> 的团队 ID。",
	"func_getTeamName": "返回实体 <b>entity</b> 的团队名称。",
	"func_getTeamName_arg_1": "将返回其团队名称的实体的 ID。",
	"func_getTeamName_return": "<b>实体</b>实体的团队名称。",
	"func_getTotalLife": "返回 ID 为 <b>entity</b> 的实体的总寿命。使用不带参数的 getTotalLife() 来检索您的总寿命。",
	"func_getTotalLife_arg_1": "将返回其总生命的实体的 ID。",
	"func_getTotalLife_return": "实体的总寿命。",
	"func_getTotalMP": "返回实体 <b>entity</b> 的最大移动点数。",
	"func_getTotalMP_arg_1": "将返回其最大移动点数的实体的 ID。",
	"func_getTotalMP_return": "<b>实体</b>的最大移动点数。",
	"func_getTotalTP": "返回实体 <b>entity</b> 的最大转折点数。",
	"func_getTotalTP_arg_1": "将返回其最大转折点数的实体的 ID。",
	"func_getTotalTP_return": "<b>实体</b>实体的最大转折点数。",
	"func_getTP": "返回实体 <b>entity</b> 的转折点数。使用不带参数的 getTP() 来检索您的 PT。",
	"func_getTP_arg_1": "将返回其 PT 的实体的 ID。",
	"func_getTP_return": "<b>entity</b> 实体的 PT 编号。",
	"func_getTurn": "返回当前的战斗回合。最大转数为#MAX_TURNS。",
	"func_getTurn_return": "当前的战斗回合。",
	"func_getType": "返回实体 <b>entity</b> 的实体类型。",
	"func_getType_arg_1": "将返回其类型的实体的 ID。",
	"func_getType_return": "<b>实体</b>的实体类型：<ul><li>#ENTITY_LEEK 如果它是韭菜。</li><li>#ENTITY_BULB 如果它是“灯泡”。</li><li >#ENTITY_TURRET 如果它是炮塔。</li></ul>",
	"func_getWeapon": "返回当前装备的武器<b>实体</b>。",
	"func_getWeapon_arg_1": "当前武器将被归还的实体的 ID。",
	"func_getWeapon_return": "<b>实体</b>当前装备的武器id，如果实体没有装备武器或者实体不存在则为null。",
	"func_getWeaponArea": "返回<b>武器</b>武器效果类型的区域。",
	"func_getWeaponArea_arg_1": "将返回其区域类型的武器。",
	"func_getWeaponArea_return": "AREA_* 常量中的 <b>weapon</b> 武器区域类型：\n<ul>\n<li>#AREA_POINT：单个正方形区域</li>\n<li>#AREA_LASER_LINE：激光线</li>\n<li>#AREA_CIRCLE_1：直径为 3 个像元的圆形区域</li>\n<li>#AREA_CIRCLE_2：直径为5个正方形的圆形区域</li>\n<li>#AREA_CIRCLE_3：直径为 7 个正方形的圆形区域</li>\n</ul>",
	"func_getWeaponCost": "返回<b>武器</b>武器的 TP 成本。",
	"func_getWeaponCost_arg_1": "返还费用的武器id。",
	"func_getWeaponCost_return": "<b>武器</b>武器的TP消耗。",
	"func_getWeaponEffectiveArea": "如果武器 <b>weapon</b> 在单元格 <b>from</b> 中的单元格 <b>cell</b> 上使用，则返回将受到影响的单元格列表。",
	"func_getWeaponEffectiveArea_arg_1": "要测试的武器。",
	"func_getWeaponEffectiveArea_arg_2": "目标细胞。",
	"func_getWeaponEffectiveArea_arg_3": "发射武器的单元格。",
	"func_getWeaponEffectiveArea_return": "包含将受影响的所有单元格的 ID 的数组。",
	"func_getWeaponPassiveEffects": "返回武器<b>武器</b>的被动效果。",
	"func_getWeaponPassiveEffects_arg_1": "将返回其被动效果的武器的 id。",
	"func_getWeaponPassiveEffects_return": "包含<b>武器</b>武器效果的数组。每个效果本身都是一个数组\n[类型、最小值、最大值、转弯、目标、修饰符]。这些效果与 #getWeaponEffects 返回的效果相同。",
	"func_getWeaponFailure": "返回<b>武器</b>武器失败几率百分比。",
	"func_getWeaponFailure_arg_1": "将返回失败百分比的武器的 id。",
	"func_getWeaponFailure_return": "<b>weapon</b> 武器未命中百分比，一个介于<b>0</b>和<b>100</b>之间的整数。",
	"func_getWeaponMaxRange": "返回<b>武器</b>武器的最大射程。",
	"func_getWeaponMaxRange_arg_1": "将返回其最大射程的武器的 ID。",
	"func_getWeaponMaxRange_return": "<b>武器</b>武器的最大射程。",
	"func_getWeaponMaxScope": "返回<b>武器</b>武器的最大射程。",
	"func_getWeaponMaxScope_arg_1": "将返回其最大射程的武器的 ID。",
	"func_getWeaponMaxScope_return": "<b>武器</b>武器的最大射程。",
	"func_getWeaponMinRange": "返回<b>武器</b>武器的最小射程。",
	"func_getWeaponMinRange_arg_1": "将返回其最小射程的武器的 ID。",
	"func_getWeaponMinRange_return": "<b>武器</b>武器的最小射程。",
	"func_getWeaponMinScope": "返回<b>武器</b>武器的最小射程。",
	"func_getWeaponMinScope_arg_1": "将返回其最小射程的武器的 ID。",
	"func_getWeaponMinScope_return": "<b>武器</b>武器的最小射程。",
	"func_getWeaponLaunchType": "返回 <b>weapon</b> 武器的发射模式，在 LAUNCH_TYPE_* 常量中。",
	"func_getWeaponLaunchType_arg_1": "将返回其投掷模式的武器的 ID。默认为您当前装备的武器。",
	"func_getWeaponLaunchType_return": "<b>武器</b>武器的投掷模式。",
	"func_getWeaponName": "返回<b>武器</b>武器的名称。",
	"func_getWeaponName_arg_1": "将返回其名称的武器的 ID。",
	"func_getWeaponName_return": "<b>武器</b>武器的名称。",
	"func_getWeapons": "返回 ID 为 <b>entity</b> 的实体的武器。",
	"func_getWeapons_arg_1": "其武器将被归还的实体的 ID。",
	"func_getWeapons_return": "包含<b>实体</b>武器 ID 的数组。",
	"func_getWeaponTargets": "返回如果武器 <b>weapon</b> 在单元格 <b>cell</b> 上使用将受到影响的实体。",
	"func_getWeaponTargets_arg_1": "测试的武器。",
	"func_getWeaponTargets_arg_2": "目标细胞。",
	"func_getWeaponTargets_return": "包含将受影响的所有实体的 ID 的数组。",
	"func_getWisdom": "返回 ID 为 <b>entity</b> 的实体的智慧。使用不带参数的 getWisdom() 来检索您的智慧。",
	"func_getWisdom_arg_1": "将返回其智慧的实体的 ID。",
	"func_getWisdom_return": "Id为<b>entity</b>的实体的智慧。",
	"func_hypot": "返回边为 x 和 y 的直角三角形的斜边。等价于 <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>。",
	"func_hypot_arg_1": "X 值。",
	"func_hypot_arg_2": "Y 值。",
	"func_hypot_return": "<i>平方（x**2 + y**2）</i>。",
	"func_inArray": "确定 <b>element</b> 元素是否在 <b>array</b> 数组中。",
	"func_inArray_arg_1": "搜索表。",
	"func_inArray_arg_2": "要搜索的项目。",
	"func_inArray_return": "<i>true</i> 如果元素在数组中，<i>false</i> 否则。",
	"func_include": "在当前AI中包含名称为<b>ai</b>的AI。 <br><br><b>警告</b>，include函数只能在主块中调用，参数必须是直接写的字符串\n在代码中。有关详细信息，请参阅教程中的部分：<a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a>。",
	"func_include_arg_1": "要包含的 AI 的名称。",
	"func_indexOf": "确定字符串 <b>search</b> 在字符串 <b>string</b> 中第一次出现的位置，从位置 <b>start</b> 开始。",
	"func_indexOf_arg_1": "将执行搜索的字符串。",
	"func_indexOf_arg_2": "要搜索的字符串。",
	"func_indexOf_arg_3": "搜索的起始位置。",
	"func_indexOf_return": "从 <b>start</b> 开始的 <b>string</b> 中 <b>search</b> 第一次出现的位置，如果未找到该字符串，则为 -1。",
	"func_insert": "将 <b>element</b> 元素插入到 <b>array</b> 的 <b>position</b> 位置。",
	"func_insert_arg_1": "将插入元素的数组。",
	"func_insert_arg_2": "要插入的项目。",
	"func_insert_arg_3": "插入的位置。",
	"func_isAlive": "确定<b>实体</b>是否存活。等同于 <i>getLife(<b>entity</b>) > 0</i>。",
	"func_isAlive_arg_1": "要测试的实体的 ID。",
	"func_isAlive_return": "<i>true</i> 如果 <b>entity</b> 还活着，<i>false</i> 如果死了。",
	"func_isAlly": "确定<b>实体</b>是否是你的盟友。",
	"func_isAlly_arg_1": "要测试的实体的 ID。",
	"func_isAlly_return": "<i>true</i> 如果<b>entity</b> 是你的盟友或你自己，<i>false</i> 如果它是敌人。",
	"func_isChip": "确定一个值是否是表示筹码的常量。<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false。",
	"func_isChip_arg_1": "数待定。",
	"func_isChip_return": "<i>true</i> 如果值是芯片常量。",
	"func_isDead": "确定<b>实体</b>是否已死亡。等同于 <i>getLife(<b>entity</b>) == 0</i>。",
	"func_isDead_arg_1": "要测试的实体的 ID。",
	"func_isDead_return": "<i>true</i> 如果 <b>entity</b> 已死，<i>false</i> 如果还活着。",
	"func_isEmpty": "确定数组 <b>array</b> 是否为空。相当于 <i>count(<b>array</b>) == 0</i>。",
	"func_isEmpty_arg_1": "要测试的数组。",
	"func_isEmptyCell": "确定单元格是否为空。",
	"func_isEmptyCell_arg_1": "要测试的电池。",
	"func_isEmptyCell_return": "<i>true</i> 如果单元格为空，<i>false</i> 否则。",
	"func_isEmpty_return": "<i>true</i> 如果数组为空，<i>false</i> 否则。",
	"func_isEnemy": "确定<b>实体</b>是否是你的敌人。",
	"func_isEnemy_arg_1": "要测试的实体的 ID。",
	"func_isEnemy_return": "<i>true</i> 如果 <b>entity</b> 是敌人，<i>false</i> 如果它是盟友或你自己。",
	"func_isInlineChip": "确定<b>芯片</b>是否只能在线使用。",
	"func_isInlineChip_arg_1": "待测芯片的id。",
	"func_isInlineChip_return": "<i>true</i> 如果芯片仅在线，则 <i>false</i> 否则。",
	"func_isInlineWeapon": "确定<b>武器</b>武器是否只能在线使用。",
	"func_isInlineWeapon_arg_1": "要测试的武器的 ID。",
	"func_isInlineWeapon_return": "<i>true</i> 如果武器只能在线使用，<i>false</i> 否则。",
	"func_isLeek": "确定单元格 <b>cell</b> 的内容是否为实体。",
	"func_isLeek_arg_1": "要测试的电池。",
	"func_isLeek_return": "<i>true</i> 如果单元格包含实体，则 <i>false</i> 否则。",
	"func_isEntity": "确定单元格 <b>cell</b> 的内容是否为实体。",
	"func_isEntity_arg_1": "要测试的电池。",
	"func_isEntity_return": "<i>true</i> 如果单元格包含实体，则 <i>false</i> 否则。",
	"func_isObstacle": "确定单元格 <b>cell</b> 的内容是否是一个障碍。",
	"func_isObstacle_arg_1": "要测试的电池。",
	"func_isObstacle_return": "<i>true</i> 如果单元格包含障碍物，<i>false</i> 否则。",
	"func_isOnSameLine": "确定两个单元格 <b>cell1</b> 和 <b>cell2</b> 是否在同一行。",
	"func_isOnSameLine_arg_1": "第一个细胞。",
	"func_isOnSameLine_arg_2": "第二个细胞。",
	"func_isOnSameLine_return": "<i>true</i> 如果两个单元格在同一行，则 <i>false</i> 否则。",
	"func_isStatic": "返回 <b>entity</b> 是否是静态的。静态实体不能移动或被移动。",
	"func_isStatic_arg_1": "要测试的实体的 ID。",
	"func_isStatic_return": "<i>true</i> 如果 <b>entity</b> 是静态的，<i>false</i> 否则。",
	"func_isSummon": "返回 <b>entity</b> 是否是一个调用。",
	"func_isSummon_arg_1": "要测试的实体的 ID。",
	"func_isSummon_return": "<i>true</i> 如果 <b>entity</b> 是调用，则 <i>false</i> 否则。",
	"func_isWeapon": "确定一个值是否是表示武器的常量。<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false。",
	"func_isWeapon_arg_1": "数待定。",
	"func_isWeapon_return": "<i>true</i> 如果该值为武器常量。",
	"func_join": "将多个元素合并成一个字符串，用 <b>glue</b> 分隔符分隔它们。",
	"func_join_arg_1": "要合并的项目数组。",
	"func_join_arg_2": "元素分隔符。",
	"func_join_return": "合并的结果字符串。",
	"func_jsonDecode": "将字符串 <b>json</b> 解码为 LeekScript 对象（数字、字符串、数组...）。",
	"func_jsonDecode_arg_1": "要解码的 JSON 字符串。",
	"func_jsonDecode_return": "解码后的 LeekScript 对象。",
	"func_jsonEncode": "将 <b>object</b> 对象编码为 JSON 字符串。",
	"func_jsonEncode_arg_1": "要在 JSON 中编码的对象。",
	"func_jsonEncode_return": "JSON 编码的字符串。",
	"func_keySort": "按 <b>order</b> 顺序按键对 <b>array</b> 进行排序。",
	"func_keySort_arg_1": "要排序的数组。",
	"func_keySort_arg_2": "排序顺序：#SORT_ASC 或#SORT_DESC。",
	"func_length": "返回<b>字符串</b>的长度。",
	"func_length_arg_1": "将返回其长度的字符串。",
	"func_length_return": "<b>字符串</b>的长度。",
	"func_lineOfSight": "检查 <b>start</b> 单元格和 <b>end</b> 单元格之间的视线，忽略 <b>ignoredEntities</b>。",
	"func_lineOfSight_arg_1": "起始细胞。",
	"func_lineOfSight_arg_2": "目标细胞。",
	"func_lineOfSight_arg_3": "要忽略的实体或实体列表。",
	"func_lineOfSight_return": "如果视线清晰，则返回 <i>true</i>。",
	"func_listen": "以 [entity_id, message] 的形式返回之前实体的 say() 数组。",
	"func_listen_return": "前面的 say() 的数组。",
	"func_log": "计算数字 <b>number</b> 的自然对数。",
	"func_log_arg_1": "]0 范围内的数字； +∞[。",
	"func_log_return": "<b>数</b>的自然对数。",
	"func_log10": "计算数字 <b>number</b> 的以 10 为底的对数。",
	"func_log10_arg_1": "]0 范围内的数字； +∞[。",
	"func_log10_return": "<b>number</b> 的以 10 为底的对数。",
	"func_log2": "计算数字 <b>number</b> 的以 2 为底的对数。",
	"func_log2_arg_1": "]0 范围内的数字； +∞[。",
	"func_log2_return": "<b>number</b> 的以 2 为底的对数。",
	"func_mark": "根据参数中指示的圈数，在地面上用参数中指示的颜色标记一个或多个单元格。此标记仅对实体的饲养者可见。",
	"func_mark_arg_1": "要标记的单元格或多个单元格的数组",
	"func_mark_arg_2": "标记颜色",
	"func_mark_arg_3": "打标时间",
	"func_mark_return": "如果一切顺利则返回真",
	"func_markText": "根据参数中指示的圈数，在地面上参数中指示的颜色的一个或多个单元格上写入文本。这些文本仅对实体的培育者可见。",
	"func_markText_arg_1": "要写入的单元格或多个单元格的数组",
	"func_markText_arg_2": "要写的文本（最多 10 个字符）",
	"func_markText_arg_3": "文字颜色",
	"func_markText_arg_4": "文字时长",
	"func_markText_return": "如果一切顺利则返回真",
	"func_max": "返回两个数字 <b>a</b> 和 <b>b</b> 之间的较大值。",
	"func_max_arg_1": "一个号码。",
	"func_max_arg_2": "一个号码。",
	"func_max_return": "<b>a</b> 和 <b>b</b> 之间的较大数字。",
	"func_min": "返回两个数字 <b>a</b> 和 <b>b</b> 之间的较小值。",
	"func_min_arg_1": "一个号码。",
	"func_min_arg_2": "一个号码。",
	"func_min_return": "<b>a</b> 和 <b>b</b> 之间的较小数字。",
	"func_moveAwayFrom": "使用最大 <b>mp</b> 移动点将您的实体从另一个<b>实体</b>移开。",
	"func_moveAwayFrom_arg_1": "您的实体应该远离的实体。",
	"func_moveAwayFrom_arg_2": "要使用的最大 MP 数。默认情况下，没有限制。",
	"func_moveAwayFrom_return": "使用的移动点数。",
	"func_moveAwayFromCell": "将你的实体移动一个 <b>cell</b> 个单元格，消耗 <b>mp</b> 个移动点。",
	"func_moveAwayFromCell_arg_1": "您的实体应该离开的单元格。",
	"func_moveAwayFromCell_arg_2": "要使用的最大 MP 数。默认情况下，没有限制。",
	"func_moveAwayFromCell_return": "使用的移动点数。",
	"func_moveAwayFromCells": "使用最大 <b>mp</b> 移动点将您的实体从一组 <b>cells</b> 单元格中移开。",
	"func_moveAwayFromCells_arg_1": "包含您的实体应该离开的单元格的数组。",
	"func_moveAwayFromCells_arg_2": "要使用的最大 MP 数。默认情况下，没有限制。",
	"func_moveAwayFromCells_return": "使用的移动点数。",
	"func_moveAwayFromLeeks": "使用最大 <b>mp</b> 移动点将您的实体从一组 <b>实体</b> 移开。",
	"func_moveAwayFromLeeks_arg_1": "包含您的实体应远离的实体的 ID 的数组。",
	"func_moveAwayFromLeeks_arg_2": "要使用的最大 MP 数。默认情况下，没有限制。",
	"func_moveAwayFromLeeks_return": "使用的移动点数。",
	"func_moveAwayFromEntities": "使用最大 <b>mp</b> 移动点将您的实体从一组 <b>实体</b> 移开。",
	"func_moveAwayFromEntities_arg_1": "包含您的实体应远离的实体的 ID 的数组。",
	"func_moveAwayFromEntities_arg_2": "要使用的最大 MP 数。默认情况下，没有限制。",
	"func_moveAwayFromEntities_return": "使用的移动点数。",
	"func_moveAwayFromLine": "使用最大 <b>mp</b> 移动点将您的实体移离由两个单元格 <b>cell1</b> 和 <b>cell2</b> 定义的线。",
	"func_moveAwayFromLine_arg_1": "单元格 1。",
	"func_moveAwayFromLine_arg_2": "单元格 2。",
	"func_moveAwayFromLine_arg_3": "要使用的最大 MP 数。默认情况下，没有限制。",
	"func_moveAwayFromLine_return": "使用的移动点数。",
	"func_moveToward": "使用最大 <b>mp</b> 移动点使您的实体更接近另一个<b>实体</b>。",
	"func_moveToward_arg_1": "您的实体应该接近的实体。",
	"func_moveToward_arg_2": "要使用的最大 MP 数。",
	"func_moveToward_return": "使用的移动点数。",
	"func_moveTowardCell": "使用最大 <b>mp</b> 移动点将您的实体移动一个 <b>cell</b> 单元格。",
	"func_moveTowardCell_arg_1": "您的功能应该接近的单元格。",
	"func_moveTowardCell_arg_2": "要使用的最大 MP 数。默认情况下，没有限制。",
	"func_moveTowardCell_return": "使用的移动点数。",
	"func_moveTowardCells": "使用最大 <b>mp</b> 移动点将您的实体移近一组 <b>cells</b> 单元格。",
	"func_moveTowardCells_arg_1": "包含您的实体应该接近的单元格的数组。",
	"func_moveTowardCells_arg_2": "要使用的最大 MP 数。默认情况下，没有限制。",
	"func_moveTowardCells_return": "使用的移动点数。",
	"func_moveTowardLeeks": "使用最大 <b>mp</b> 移动点，使您的实体更接近一组 <b>实体</b>。",
	"func_moveTowardLeeks_arg_1": "包含您的实体应该接近的实体的 ID 的数组。",
	"func_moveTowardLeeks_arg_2": "要使用的最大 MP 数。默认情况下，没有限制。",
	"func_moveTowardLeeks_return": "使用的移动点数。",
	"func_moveTowardEntities": "使用最大 <b>mp</b> 移动点，使您的实体更接近一组 <b>实体</b>。",
	"func_moveTowardEntities_arg_1": "包含您的实体应该接近的实体的 ID 的数组。",
	"func_moveTowardEntities_arg_2": "要使用的最大 MP 数。默认情况下，没有限制。",
	"func_moveTowardEntities_return": "使用的移动点数。",
	"func_moveTowardLine": "使用最大 <b>mp</b> 移动点使您的实体更接近由两个单元格 <b>cell1</b> 和 <b>cell2</b> 定义的线。",
	"func_moveTowardLine_arg_1": "单元格 1。",
	"func_moveTowardLine_arg_2": "单元格 2。",
	"func_moveTowardLine_arg_3": "要使用的最大 MP 数。默认情况下，没有限制。",
	"func_moveTowardLine_return": "使用的移动点数。",
	"func_number": "将值转换为数字。如果值是字符串，数字函数将尝试将其转换为数字，如果值已经是数字，函数返回数字，对于任何其他类型，它返回 <i>null</i>。",
	"func_number_arg_1": "要转换为数字的值。",
	"func_number_return": "转换后的数字。",
	"func_pause": "暂停战斗，仅适用于使用该功能的实体的饲养员。",
	"func_pop": "从 <b>array</b> 数组中删除最后一个单元格并返回相应的元素。",
	"func_pop_arg_1": "最后一个框将被删除的数组。",
	"func_pop_return": "在旧的最后一个盒子里的物品。",
	"func_pow": "将基数提高到指数次方。",
	"func_pow_arg_1": "基地。",
	"func_pow_arg_2": "参展商。",
	"func_pow_return": "<b>base</b> 的 <b>exponent</b> 次方。",
	"func_push": "将 <b>element</b> 元素添加到 <b>array</b> 的末尾。",
	"func_pushAll": "将 <b>elements</b> 数组的所有元素附加到 <b>array</b> 的末尾。",
	"func_pushAll_arg_1": "我们要添加元素的数组。",
	"func_pushAll_arg_2": "要添加的项目。",
	"func_push_arg_1": "我们要添加元素的数组。",
	"func_push_arg_2": "要添加的项目。",
	"func_rand": "返回一个真正的随机数，介于 0（含）和 1（不含）之间。",
	"func_randFloat": "返回一个实随机数，介于 <b>a</b>（含）和 <b>b</b>（不含）之间。",
	"func_randFloat_arg_1": "下限。",
	"func_randFloat_arg_2": "上限。",
	"func_randFloat_return": "[a; 范围内的随机数b[。",
	"func_randInt": "返回 <b>a</b>（含）和 <b>b</b>（不含）之间的随机整数。",
	"func_randInt_arg_1": "下限。",
	"func_randInt_arg_2": "上限。",
	"func_randInt_return": "[a; 范围内的随机整数b[。",
	"func_rand_return": "[0; 范围内的随机数1[。",
	"func_remove": "从数组 <b>array</b> 的位置 <b>position</b> 移除一个元素，并返回删除的元素。",
	"func_remove_arg_1": "我们要从中删除元素的数组。",
	"func_remove_arg_2": "要删除的元素的位置。",
	"func_removeElement": "删除 <b>数组</b> 中第一个出现的<b>元素</b>。如果未找到元素，则不修改数组。",
	"func_removeElement_arg_1": "我们要从中删除元素的数组。",
	"func_removeElement_arg_2": "要查找然后删除的项目。",
	"func_removeKey": "从与键 <b>key</b> 关联的数组 <b>array</b> 中删除一个元素。",
	"func_removeKey_arg_1": "我们要从中删除元素的数组。",
	"func_removeKey_arg_2": "与要删除的项目关联的键。",
	"func_remove_return": "从数组中移除的元素。",
	"func_replace": "将字符串 <b>string</b> 中所有出现的 <b>search</b> 替换为 <b>replace</b>。",
	"func_replace_arg_1": "进行替换的字符串。",
	"func_replace_arg_2": "要替换的子字符串。",
	"func_replace_arg_3": "替换字符串。",
	"func_replace_return": "结果字符串，带有替换。",
	"func_resurrect": "使用 CHIP_RESURRECTION 芯片在单元格 <b>cell</b> 上复活一个 ID 为 <b>entity</b> 的死实体。",
	"func_resurrect_arg_1": "要复活的实体的 ID。",
	"func_resurrect_arg_2": "实体将重新出现的单元格。",
	"func_resurrect_return": "启动芯片的结果，常量USE_*中。",
	"func_reverse": "反转<b>数组</b>。",
	"func_reverse_arg_1": "要反转的表。",
	"func_round": "返回 <b>number</b> 的舍入。<br/>您可以使用#floor 查找向下舍入，使用#ceil 查找向上舍入。",
	"func_round_arg_1": "我们要查找其舍入的数字。",
	"func_round_return": "按<b>数字</b>四舍五入。",
	"func_say": "让你的实体说话。",
	"func_say_arg_1": "您的实体将在竞技场中宣布的消息。",
	"func_search": "在数组 <b>array</b> 中查找元素 <b>element</b>，从位置 <b>start</b> 开始。",
	"func_search_arg_1": "搜索表。",
	"func_search_arg_2": "要搜索的项目。",
	"func_search_arg_3": "搜索开始的位置。",
	"func_search_return": "<b>array</b>中<b>element</b>元素第一次出现的位置，从<b>start</b>开始，如果没有找到则为null。",
	"func_sendAll": "向您的整个团队发送消息。",
	"func_sendAll_arg_1": "要发送的消息类型（请参阅 MESSAGE_* 常量）。",
	"func_sendAll_arg_2": "消息参数，可以是任意值。",
	"func_sendTo": "向 ID 为 <b>entity</b> 的实体发送消息。",
	"func_sendTo_arg_1": "消息将发送到的实体的 ID。",
	"func_sendTo_arg_2": "要发送的消息类型（请参阅 MESSAGE_* 常量）。",
	"func_sendTo_arg_3": "消息参数，可以是任意值。",
	"func_sendTo_return": "<i>true</i> 如果发送成功，<i>false</i> 如果发生错误。",
	"func_setRegister": "将 <b>value</b> 存储在 <b>key</b> 注册表中。\n键和值是字符串，最多分别包含<i>100</i>和<i>5000</i>字符。一个韭菜最多可以有<i>100</i>个寄存器，如果所有的寄存器都存储在一个新的寄存器中是行不通的\n已经被占用。",
	"func_setRegister_arg_1": "存储值的注册表项。",
	"func_setRegister_arg_2": "要存储的值。",
	"func_setRegister_return": "<i>true</i> 如果操作成功，<i>false</i> 否则。",
	"func_setWeapon": "为您的实体装备<b>武器</b>武器。",
	"func_setWeapon_arg_1": "要装备的武器的ID。",
	"func_shift": "从 <b>array</b> 中删除第一个单元格并返回相应的元素。",
	"func_shift_arg_1": "将删除其第一个单元格的数组。",
	"func_shift_return": "旧第一个单元格中的元素。",
	"func_show": "向玩家显示场上 <b>color</b> 颜色的 <b>cell</b> 1 回合。使用此功能需要 1PT。",
	"func_show_arg_1": "要显示的单元格",
	"func_show_arg_2": "标记颜色",
	"func_show_return": "如果一切顺利则返回真",
	"func_shuffle": "随机洗牌。",
	"func_shuffle_arg_1": "沙狐球。",
	"func_signum": "确定数字 <b>number</b> 的符号。",
	"func_signum_arg_1": "将确定其符号的数字。",
	"func_signum_return": "如果数字为正则为 1，如果数字为零则为 0，如果数字为负则为 -1。",
	"func_sin": "计算作为参数传递的角度 <b>angle</b> 的正弦值",
	"func_sin_arg_1": "将计算其正弦值的角度",
	"func_sin_return": "返回角度 <b>angle</b> 的正弦值",
	"func_sort": "按升序或降序对<b>数组</b> 进行排序。默认的升序是：\n<ul>\n<li>所有布尔值（先假后真）</li>\n<li>所有数字（从小到大排序）</li>\n<li>所有频道（按字母顺序排列）</li>\n<li>所有空元素。</li>\n</ul>",
	"func_sort_arg_1": "要排序的数组。",
	"func_sort_arg_2": "#SORT_ASC 以升序对<b>数组</b> 进行排序，或#SORT_DESC 以降序对其进行排序。",
	"func_split": "将字符串 <b>string</b> 拆分为由 <b>delimiter</b> 分隔的子字符串。",
	"func_split_arg_1": "切割链条。",
	"func_split_arg_2": "分隔从一个元素到另一个元素的通道的字符串。",
	"func_split_arg_3": "数组元素的最大数量。",
	"func_split_return": "包含找到的子字符串的数组。",
	"func_sqrt": "计算数字 <b>number</b> 的平方根。",
	"func_sqrt_arg_1": "将计算其根的数字。",
	"func_sqrt_return": "<b>数字</b>的平方根。",
	"func_startsWith": "检查 <b>string</b> 是否以 <b>prefix</b> 开头。",
	"func_startsWith_arg_1": "搜索字符串。",
	"func_startsWith_arg_2": "搜索前缀。",
	"func_startsWith_return": "如果 <b>string</b> 以 <b>prefix</b> 开头则为真。",
	"func_string": "将值转换为字符串。<br>\n如果该值已经是一个字符串，则返回它。<br>\n如果值为数字 x，则返回“x”。<br>\n如果值是数组，则返回格式为“[key1: value1, key2: value2, ...]”的字符串。<br>\n如果值为布尔值，则分别为 true 和 false 返回“true”和“false”。<br>\n如果值为 null，则返回“null”。",
	"func_string_arg_1": "要转换为字符串的值。",
	"func_string_return": "转换后的字符串。",
	"func_subArray": "返回 <b>array</b> 的子数组，从 <b>start</b> 位置开始，到 <b>end</b> 位置结束。",
	"func_subArray_arg_1": "源表。",
	"func_subArray_arg_2": "起始索引。",
	"func_subArray_arg_3": "结束索引。",
	"func_subArray_return": "子阵。",
	"func_substring": "返回字符串 <b>string</b> 的子字符串，从索引 <b>start</b> 处的字符开始，大小为 <b>length</b>。",
	"func_substring_arg_1": "切割链条。",
	"func_substring_arg_2": "起始字符索引。",
	"func_substring_arg_3": "子串的长度。",
	"func_substring_return": "子字符串。",
	"func_sum": "返回数组 <b>array</b> 中所有数字元素的总和。",
	"func_sum_arg_1": "表求和。",
	"func_summon": "在具有 IA 函数 <b>ai</b> 的单元格 <b>cell</b> 上调用由芯片 <b>chip</b> 确定的实体。",
	"func_summon_arg_1": "用于召唤的筹码。芯片必须是召唤型芯片，并且必须装备在使用召唤功能的实体身上。",
	"func_summon_arg_2": "细胞或传票应该出现。",
	"func_summon_arg_3": "调用 AI，作为一个函数。",
	"func_summon_return": "Summon 函数与#useChip 函数具有相同的返回值。",
	"func_sum_return": "数组元素的总和。",
	"func_tan": "计算角度 <b>angle</b> 的正切值（以弧度为单位）。",
	"func_tan_arg_1": "以弧度表示的角度。",
	"func_tan_return": "<b>角度</b>的正切。",
	"function_category_array": "列表",
	"function_category_chip": "跳蚤",
	"function_category_color": "颜色",
	"function_category_fight": "斗争",
	"function_category_leek": "韭菜",
	"function_category_map": "表",
	"function_category_field": "地面",
	"function_category_math": "数字",
	"function_category_network": "网络",
	"function_category_string": "链条",
	"function_category_utils": "公用事业",
	"function_category_weapon": "武器",
	"func_toDegrees": "将角度 <b>弧度</b> 转换为度数。",
	"func_toDegrees_arg_1": "弧度值",
	"func_toDegrees_return": "以度为单位的角度。",
	"func_toLower": "将 <b>string</b> 中的大写字符转换为小写。",
	"func_toLower_arg_1": "要转换的字符串",
	"func_toLower_return": "小写字符串",
	"func_toRadians": "将角度 <b>度数</b> 转换为弧度。",
	"func_toRadians_arg_1": "以度为单位的价值",
	"func_toRadians_return": "以弧度表示的角度。",
	"func_toUpper": "将 <b>string</b> 中的小写字符转换为大写。",
	"func_toUpper_arg_1": "要转换的字符串。",
	"func_toUpper_return": "大写字符串。",
	"func_typeOf": "返回值 <b>value</b> 的类型，类型包括：#TYPE_NULL、#TYPE_NUMBER、#TYPE_BOOLEAN、#TYPE_STRING、#TYPE_ARRAY、#TYPE_FUNCTION。",
	"func_typeOf_arg_1": "将返回其类型的值。",
	"func_typeOf_return": "<b>值</b>的类型。",
	"func_unshift": "将 <b>element</b> 元素添加到 <b>array</b> 的开头。",
	"func_unshift_arg_1": "将在其中添加元素的数组。",
	"func_unshift_arg_2": "要添加的项目。",
	"func_useChip": "在实体<b>实体</b>上使用芯片<b>芯片</b>。",
	"func_useChip_arg_1": "芯片使用。",
	"func_useChip_arg_2": "目标实体。",
	"func_useChipOnCell": "在单元格<b>单元格</b>上使用芯片<b>芯片</b>。",
	"func_useChipOnCell_arg_1": "芯片使用。",
	"func_useChipOnCell_arg_2": "目标细胞。",
	"func_useChipOnCell_return": "如果发起攻击，则该值大于 0。",
	"func_useChip_return": "UseChip的返回值是：<br>\n<ul>\n<li>#USE_CRITICAL，重击</li>\n<li>#USE_SUCCESS，如果成功</li>\n<li>#USE_FAILED，失败</li>\n<li>#USE_INVALID_TARGET，如果目标不存在</li>\n<li>#USE_NOT_ENOUGH_TP，如果您的实体没有足够的 TP</li>\n<li>#USE_INVALID_COOLDOWN，如果芯片还不能使用</li>\n<li>#USE_INVALID_POSITION，如果范围不好或视线不清晰</li>\n</ul>",
	"func_useWeapon": "对<b>实体</b>使用选定的武器。",
	"func_useWeapon_arg_1": "目标实体。",
	"func_useWeaponOnCell": "在 <b>cell</b> 单元格上使用选定的武器。",
	"func_useWeaponOnCell_arg_1": "目标细胞。",
	"func_useWeaponOnCell_return": "如果发起攻击，则该值大于 0。",
	"func_useWeapon_return": "UseWeapon的返回值是：<br>\n<ul>\n<li>#USE_CRITICAL，重击</li>\n<li>#USE_SUCCESS，如果成功</li>\n<li>#USE_FAILED，失败</li>\n<li>#USE_INVALID_TARGET，如果目标不存在</li>\n<li>#USE_NOT_ENOUGH_TP，如果您的实体没有足够的 TP</li>\n<li>#USE_INVALID_POSITION，如果范围不好或视线不清晰</li>\n</ul>",
	"func_weaponNeedLos": "返回 <b>weapon</b> 武器是否需要瞄准线才能开火。",
	"func_weaponNeedLos_arg_1": "要测试的武器的 ID。",
	"func_weaponNeedLos_return": "<i>true</i> 如果 <b>weapon</b> 武器需要瞄准线才能开火，<i>false</i> 否则。",
	"items": "项目",
	"max_cores": "最大限度",
	"max_level": "最大限度",
	"min_cores": "最少的红心",
	"min_level": "最低水平",
	"n_operations": "<b>{0}</b> 操作",
	"parameters": "设置",
	"return": "反馈",
	"value": "价值",
	"variable_operations": "<b>变量</b>操作",
	"arg_type_9": "包装",
	"arg_type_10": "间隙",
	"function_category_set": "套餐",
	"function_category_interval": "间隔",
	"arg_type_96": "set<entier>"
}

	i18n.mergeLocaleMessage("zh", { "doc": messages })
	