
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> ऑपरेशन",
	"arg_type_-1": "?",
	"arg_type_1": "संख्या",
	"arg_type_2": "ज़ंजीर",
	"arg_type_3": "बूलियन",
	"arg_type_4": "सूची",
	"arg_type_41": "सूची <संख्या>",
	"arg_type_42": "सूची <स्ट्रिंग>",
	"arg_type_43": "सूची <बूलियन>",
	"arg_type_44": "सूची <सूची>",
	"arg_type_46": "सूची <पूर्णांक>",
	"arg_type_47": "सूची <वास्तविक>",
	"arg_type_5": "समारोह",
	"arg_type_6": "पूरा",
	"arg_type_7": "असली",
	"arg_type_8": "मेज",
	"arg_type_806": "तालिका <?, पूर्णांक>",
	"complexity": "जटिलता {c}",
	"operations": "{o} संचालन",
	"details": "विवरण",
	"optional": "वैकल्पिक",
	"deprecated_function": "यह समारोह बहिष्कृत है।",
	"deprecated_constant": "यह स्थिरांक बहिष्कृत है।",
	"replaced_by": "इसे {0} से बदल दिया गया है।",
	"const_AREA_CIRCLE_1": "वृत्ताकार क्षेत्रफल 3 वर्ग व्यास (क्रॉस) में।",
	"const_AREA_CIRCLE_2": "वृत्ताकार क्षेत्रफल 5 वर्ग व्यास।",
	"const_AREA_CIRCLE_3": "वृत्ताकार क्षेत्रफल 7 वर्ग व्यास का।",
	"const_AREA_LASER_LINE": "एक लेज़र का क्षेत्र, लेज़र की न्यूनतम सीमा से इसकी अधिकतम सीमा या एक बाधा तक की रेखा।",
	"const_AREA_POINT": "एकल कोशिका वाला क्षेत्र।",
	"const_CELL_EMPTY": "खाली सेल के लिए getCellContent(cell) का रिटर्न वैल्यू।",
	"const_CELL_OBSTACLE": "एक बाधा वाले सेल के लिए getCellContent(cell) का वापसी मान।",
	"const_CELL_PLAYER": "इकाई वाले सेल के लिए getCellContent(cell) का वापसी मान।",
	"const_CELL_ENTITY": "इकाई वाले सेल के लिए getCellContent(cell) का वापसी मान।",
	"const_COLOR_BLUE": "रंग नीला।",
	"const_COLOR_GREEN": "हरा रंग।",
	"const_COLOR_RED": "लाल रंग।",
	"const_E": "यूलर का नंबर।",
	"const_EFFECT_ABSOLUTE_SHIELD": "एक निश्चित राशि से क्षति (EFFECT_DAMAGE) द्वारा हटाए गए हिट पॉइंट की मात्रा को कम करते हुए, एक इकाई को पूर्ण ढाल प्रदान करता है। प्रतिरोध द्वारा प्रवर्धित।",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "एक इकाई से पूर्ण ढाल हटाता है। एक विशेषता द्वारा प्रवर्धित नहीं है। आपको क्षति (EFFECT_DAMAGE) द्वारा हटाए गए हिट पॉइंट को पूर्ण मात्रा में बढ़ाने की अनुमति देता है।",
	"const_EFFECT_AFTEREFFECT": "किसी इकाई से हिट पॉइंट निकालता है। विज्ञान द्वारा प्रवर्धित। हटाए गए हिट पॉइंट्स की मात्रा के अधिकतम हिट पॉइंट्स को 5% कम कर देता है।",
	"const_EFFECT_ANTIDOTE": "एक लक्ष्य पर मौजूद सभी विष (EFFECT_POISON) को निकालता है।",
	"const_EFFECT_BOOST_MAX_LIFE": "किसी इकाई के हिट पॉइंट और अधिकतम हिट पॉइंट बढ़ाता है। बुद्धि द्वारा बढ़ाया गया।",
	"const_EFFECT_BUFF_AGILITY": "एक इकाई को चपलता प्रदान करता है। विज्ञान द्वारा प्रवर्धित।",
	"const_EFFECT_BUFF_MP": "अनुदान आंदोलन एक इकाई को इंगित करता है। विज्ञान द्वारा प्रवर्धित।",
	"const_EFFECT_BUFF_RESISTANCE": "किसी इकाई को प्रतिरोध देता है। विज्ञान द्वारा प्रवर्धित।",
	"const_EFFECT_BUFF_STRENGTH": "एक इकाई को ताकत देता है। विज्ञान द्वारा प्रवर्धित।",
	"const_EFFECT_BUFF_TP": "किसी निकाय को कार्रवाई बिंदु देता है. विज्ञान द्वारा प्रवर्धित।",
	"const_EFFECT_BUFF_WISDOM": "एक इकाई को ज्ञान प्रदान करें। विज्ञान द्वारा प्रवर्धित।",
	"const_EFFECT_DAMAGE": "किसी इकाई से हिट पॉइंट निकालता है। बल प्रवर्धित। शील्ड्स (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), लाइफस्टील (कॉस्टर को छोड़कर), और डैमेज रिटर्न (EFFECT_DAMAGE_RETURN) के साथ इंटरैक्ट करता है। हटाए गए हिट पॉइंट्स की मात्रा के अधिकतम हिट पॉइंट्स को 5% कम कर देता है।",
	"const_EFFECT_DAMAGE_RETURN": "एक इकाई को क्षति प्रतिबिंब प्रदान करता है, जिससे प्राप्तकर्ता को नुकसान पहुंचाने वाली संस्थाओं से हिट पॉइंट को हटाने की अनुमति मिलती है। चपलता से प्रवर्धित। हटाए गए हिट पॉइंट्स की मात्रा के अधिकतम हिट पॉइंट्स को 5% कम कर देता है।",
	"const_EFFECT_DEBUFF": "एक इकाई पर सभी प्रभावों के मूल्य को एक प्रतिशत कम कर देता है।",
	"const_EFFECT_HEAL": "अधिकतम हिट बिंदुओं द्वारा सीमित हिट बिंदुओं को एक इकाई में पुनर्स्थापित करता है। बुद्धि द्वारा बढ़ाया गया।",
	"const_EFFECT_INVERT": "एक इकाई के साथ ढलाईकार की स्थिति की अदला-बदली करता है।",
	"const_EFFECT_KILL": "किसी निकाय से सभी हिट बिंदु निकालता है.",
	"const_EFFECT_LIFE_DAMAGE": "कास्टर के हिट पॉइंट के प्रतिशत के आधार पर, किसी इकाई से हिट पॉइंट हटाता है। शील्ड्स (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) और डैमेज रिटर्न (EFFECT_DAMAGE_RETURN) के साथ इंटरैक्ट करता है। हटाए गए हिट पॉइंट्स की मात्रा के अधिकतम हिट पॉइंट्स को 5% कम कर देता है।",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "प्रभाव क्षेत्र में प्रभावित संस्थाओं की संख्या से गुणा किया जाता है।",
	"const_EFFECT_MODIFIER_ON_CASTER": "प्रभाव अभी भी ढलाईकार को प्रभावित करता है।",
	"const_EFFECT_MODIFIER_STACKABLE": "प्रभाव संचयी है।",
	"const_EFFECT_NOVA_DAMAGE": "अधिकतम जीवन अंक निकालता है। विज्ञान द्वारा प्रवर्धित।",
	"const_EFFECT_POISON": "किसी इकाई से हिट पॉइंट निकालता है। जादू द्वारा बढ़ाया गया। हटाए गए हिट पॉइंट्स की मात्रा के 10% से अधिकतम हिट पॉइंट कम कर देता है।",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "एक निश्चित राशि से क्षति (EFFECT_DAMAGE) द्वारा हटाए गए हिट पॉइंट की मात्रा को कम करते हुए, एक इकाई को पूर्ण ढाल प्रदान करता है। प्रवर्धित नहीं।",
	"const_EFFECT_RAW_BUFF_AGILITY": "एक इकाई को चपलता प्रदान करता है। प्रवर्धित नहीं।",
	"const_EFFECT_RAW_BUFF_MAGIC": "एक इकाई को जादू देता है। प्रवर्धित नहीं।",
	"const_EFFECT_RAW_BUFF_MP": "अनुदान आंदोलन एक इकाई को इंगित करता है। प्रवर्धित नहीं।",
	"const_EFFECT_RAW_BUFF_SCIENCE": "एक इकाई को विज्ञान देता है। प्रवर्धित नहीं।",
	"const_EFFECT_RAW_BUFF_STRENGTH": "एक इकाई को ताकत देता है। प्रवर्धित नहीं।",
	"const_EFFECT_RAW_BUFF_TP": "किसी निकाय को कार्रवाई बिंदु प्रदान करता है. प्रवर्धित नहीं।",
	"const_EFFECT_RELATIVE_SHIELD": "एक रिश्तेदार ढाल प्रदान करता है, क्षति (EFFECT_DAMAGE) द्वारा हटाए गए हिट पॉइंट की मात्रा को एक सापेक्ष राशि से कम करता है। प्रतिरोध द्वारा प्रवर्धित।",
	"const_EFFECT_RESURRECT": "पुनरुत्थान से पहले इकाई की अधिकतम एचपी संख्या के आधे के बराबर अधिकतम एचपी संख्या और पुनरुत्थान से पहले अधिकतम एचपी संख्या के एक चौथाई के बराबर वर्तमान एचपी संख्या के साथ एक इकाई को पुनर्जीवित करता है।",
	"const_EFFECT_SHACKLE_MAGIC": "एक इकाई से जादू हटा दें। जादू द्वारा बढ़ाया गया।",
	"const_EFFECT_SHACKLE_MP": "एक निकाय से संचलन बिंदु निकालें. जादू द्वारा बढ़ाया गया।",
	"const_EFFECT_SHACKLE_STRENGTH": "एक इकाई से ताकत हटा देता है। जादू द्वारा बढ़ाया गया।",
	"const_EFFECT_SHACKLE_TP": "एक इकाई से कार्रवाई बिंदुओं को घटाएं। जादू द्वारा बढ़ाया गया।",
	"const_EFFECT_SUMMON": "बल्ब बुलाता है। टीम सम्मन सीमा तक पहुँचने पर कोई प्रभाव नहीं।",
	"const_EFFECT_TARGET_ALLIES": "सहयोगियों को प्रभावित करता है।",
	"const_EFFECT_TARGET_CASTER": "ढलाईकार को प्रभावित करता है।",
	"const_EFFECT_TARGET_ENEMIES": "शत्रुओं को प्रभावित करता है।",
	"const_EFFECT_TARGET_NON_SUMMONS": "गैर-सम्मनित संस्थाओं को प्रभावित करता है (लीक और टर्रेट्स)।",
	"const_EFFECT_TARGET_SUMMONS": "सम्मनित संस्थाओं (बल्ब) को प्रभावित करता है।",
	"const_EFFECT_TELEPORT": "ढलाईकार की स्थिति बदलता है।",
	"const_EFFECT_VULNERABILITY": "किसी इकाई के सापेक्ष शील्ड को हटाता है। एक विशेषता द्वारा प्रवर्धित नहीं है। क्षति (EFFECT_DAMAGE) द्वारा हटाए गए हिट बिंदुओं को सापेक्ष मात्रा में बढ़ाता है।",
	"const_ENTITY_BULB": "एक बल्ब-प्रकार इकाई को संदर्भित करता है।",
	"const_ENTITY_LEEK": "एक लीक-प्रकार इकाई को संदर्भित करता है।",
	"const_ENTITY_TURRET": "बुर्ज प्रकार की इकाई को संदर्भित करता है।",
	"const_FIGHT_CONTEXT_CHALLENGE": "चैलेंज टाइप कॉम्बैट बैकग्राउंड।",
	"const_FIGHT_CONTEXT_GARDEN": "सब्जी मंडी में मारपीट का प्रसंग।",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "बैटल रॉयल में युद्ध का प्रसंग।",
	"const_FIGHT_CONTEXT_TEST": "परीक्षण युद्ध संदर्भ।",
	"const_FIGHT_CONTEXT_TOURNAMENT": "टूर्नामेंट लड़ाई पृष्ठभूमि।",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "बैटल रॉयल में लड़ें।",
	"const_FIGHT_TYPE_FARMER": "ब्रीडर लड़ाई।",
	"const_FIGHT_TYPE_SOLO": "एकल लड़ाई।",
	"const_FIGHT_TYPE_TEAM": "दल की लड़ाई।\n",
	"const_INSTRUCTIONS_LIMIT": "एक इकाई अपनी बारी के दौरान निर्देशों की अधिकतम संख्या का उपयोग कर सकती है।",
	"const_MAX_TURNS": "लड़ाई में घुमावों की अधिकतम संख्या।",
	"const_OPERATIONS_LIMIT": "एक इकाई अपनी बारी के दौरान संचालन की अधिकतम संख्या का उपयोग कर सकती है।",
	"const_PI": "किसी वृत्त की परिधि का उसके व्यास से अनुपात।",
	"const_SORT_ASC": "#Sort फ़ंक्शन को आरोही क्रम में सॉर्ट करने के लिए कहता है।",
	"const_SORT_DESC": "#Sort फ़ंक्शन को अवरोही क्रम में सॉर्ट करने के लिए कहता है।",
	"const_TYPE_ARRAY": "मान प्रकार <b>सूची</b>",
	"const_TYPE_BOOLEAN": "मान का प्रकार <b>बूलियन</b>",
	"const_TYPE_FUNCTION": "मान प्रकार <b>फ़ंक्शन</b>",
	"const_TYPE_NULL": "मान का प्रकार <b>शून्य</b>",
	"const_TYPE_NUMBER": "मान प्रकार <b>संख्या</b>",
	"const_TYPE_STRING": "मान का प्रकार <b>अक्षर स्ट्रिंग</b>",
	"const_USE_CRITICAL": "महत्वपूर्ण हिट की स्थिति में #useWeapon, #useWeaponOnCell, #useChip और #useChipOnCell फ़ंक्शंस द्वारा लौटाया गया मान।",
	"const_USE_FAILED": "विफलता पर #useWeapon, #useWeaponOnCell, #useChip और #useChipOnCell फ़ंक्शंस द्वारा लौटाया गया मान।",
	"const_USE_INVALID_COOLDOWN": "यदि चिप अभी तक प्रयोग करने योग्य नहीं है तो #useChip और #useChipOnCell फ़ंक्शंस द्वारा लौटाया गया मान।",
	"const_USE_INVALID_POSITION": "यदि सीमा खराब है या दृष्टि रेखा स्पष्ट नहीं है, तो #useWeapon, #useWeaponOnCell, #useChip और #useChipOnCell फ़ंक्शंस द्वारा लौटाया गया मान।",
	"const_USE_INVALID_TARGET": "यदि लक्ष्य मौजूद नहीं है तो #useWeapon और #useChip फ़ंक्शंस द्वारा लौटाया गया मान।",
	"const_USE_NOT_ENOUGH_TP": "#useWeapon, #useWeaponOnCell, #useChip और #useChipOnCell फ़ंक्शंस द्वारा लौटाया गया मान यदि कोस्टर के पास ऑब्जेक्ट का उपयोग करने के लिए पर्याप्त क्रिया बिंदु नहीं हैं।",
	"const_USE_RESURRECT_INVALID_ENTITY": "निर्दिष्ट इकाई मौजूद नहीं होने या अभी तक मृत नहीं होने पर #resurrect फ़ंक्शन द्वारा लौटाया गया मान।",
	"const_USE_SUCCESS": "सफलता पर #useWeapon, #useWeaponOnCell, #useChip और #useChipOnCell फ़ंक्शंस द्वारा लौटाया गया मान।",
	"const_USE_TOO_MANY_SUMMONS": "आपके पास पहले से ही <b>8</b> लाइव समन होने पर #summon द्वारा त्रुटि लौटाई गई।\n",
	"func_abs": "संख्या <b>संख्या</b> का पूर्ण मान लौटाता है।",
	"func_abs_arg_1": "वह संख्या जिसके निरपेक्ष मान की गणना की जाएगी।",
	"func_abs_return": "संख्या का निरपेक्ष मान।",
	"func_acos": "अंतराल [0, #PI] में <b>तर्क</b> के आर्क कोसाइन की गणना करता है।",
	"func_acos_arg_1": "वह संख्या जिसकी आर्क कोसाइन की गणना की जाएगी।",
	"func_acos_return": "<b>तर्क</b> का चाप कोसाइन।",
	"func_arrayConcat": "दो सरणियों को सिरे से अंत तक जोड़ें। पाठ कुंजियाँ संरक्षित हैं और सभी संख्यात्मक कुंजियाँ पुन: अनुक्रमित हैं।",
	"func_arrayConcat_arg_1": "पहली तालिका।",
	"func_arrayConcat_arg_2": "दूसरी तालिका।",
	"func_arrayConcat_return": "दो सरणियों का योग।",
	"func_arrayFilter": "स्रोत सरणी से सभी कुंजी/मान जोड़े वाली एक नई सरणी देता है जिसके लिए कॉलबैक फ़ंक्शन सत्य लौटाता है। यदि कॉलबैक फ़ंक्शन एक पैरामीटर लेता है, तो स्रोत सरणी का मान भेजा जाएगा, यदि यह दो पैरामीटर लेता है, तो कुंजी और मान भेजा जाएगा।",
	"func_arrayFilter_arg_1": "मूल तालिका।",
	"func_arrayFilter_arg_2": "फ़ंक्शन प्रत्येक तत्व के लिए कहा जाता है।",
	"func_arrayFilter_return": "नई तालिका।",
	"func_arrayFlatten": "स्रोत सरणी के सभी तत्वों वाली एक नई सरणी लौटाता है। उप-सरणी में शामिल सभी तत्वों को नई सरणी में निकाला जाता है। गहराई तर्क निकालने के लिए अधिकतम उप-सरणी गहराई निर्धारित करता है।\n<कोड> वर सरणी = [1, 2, [[3, 4], 5], [6]];\nडीबग (arrayFlatten (सरणी, 1)); // [1,2, [3,4], 5,6] </कोड>",
	"func_arrayFlatten_arg_1": "मूल तालिका।",
	"func_arrayFlatten_arg_2": "अधिकतम गहराई।",
	"func_arrayFlatten_return": "नई तालिका।",
	"func_arrayFoldLeft": "<b>सरणी</b> [v1, v2, ..., vn] को <b>v0</b> मान से शुरू करते हुए बाईं ओर से घटाएं और फ़ंक्शन <b>f</b> लागू करें। बराबर :\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "मूल तालिका।",
	"func_arrayFoldLeft_arg_2": "आवेदन करने का कार्य।",
	"func_arrayFoldLeft_arg_3": "प्रारंभिक मूल्य।",
	"func_arrayFoldLeft_return": "घटी हुई मेज।",
	"func_arrayFoldRight": "<b>सरणी</b> [v1, v2, ..., vn] को मान <b>v0</b> से शुरू करते हुए दाईं ओर से कम करें और फ़ंक्शन <b>f</b> लागू करें। बराबर :\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "मूल तालिका।",
	"func_arrayFoldRight_arg_2": "आवेदन करने का कार्य।",
	"func_arrayFoldRight_arg_3": "प्रारंभिक मूल्य।",
	"func_arrayFoldRight_return": "घटी हुई मेज।",
	"func_arrayIter": "प्रत्येक सरणी तत्व के लिए कॉलबैक फ़ंक्शन को कॉल करता है। यदि कॉलबैक फ़ंक्शन एक पैरामीटर लेता है, तो स्रोत सरणी का मान भेजा जाएगा, यदि यह दो पैरामीटर लेता है, तो कुंजी और मान भेजा जाएगा।",
	"func_arrayIter_arg_1": "मूल तालिका।",
	"func_arrayIter_arg_2": "फ़ंक्शन प्रत्येक तत्व के लिए कहा जाता है।",
	"func_arrayMap": "स्रोत सरणी की प्रत्येक कुंजी के लिए एक नया सरणी देता है, कॉलबैक फ़ंक्शन द्वारा लौटाया गया मान। यदि कॉलबैक फ़ंक्शन एक पैरामीटर लेता है, तो स्रोत सरणी का मान भेजा जाएगा, यदि यह दो पैरामीटर लेता है, तो कुंजी और मान भेजा जाएगा।",
	"func_arrayMap_arg_1": "मूल तालिका।",
	"func_arrayMap_arg_2": "फ़ंक्शन प्रत्येक तत्व के लिए कहा जाता है।",
	"func_arrayMap_return": "नई तालिका।",
	"func_arrayMax": "<b>सरणी</b> सरणी का अधिकतम मान तत्व लौटाता है।<br/>किसी सरणी के अधिकतम मान के बारे में अधिक जानकारी के लिए #sort देखें।",
	"func_arrayMax_arg_1": "सरणी जिसमें अधिकतम मूल्य मांगा जाएगा।",
	"func_arrayMax_return": "अधिकतम मूल्य की वस्तु।",
	"func_arrayMin": "<b>सरणी</b> सरणी का न्यूनतम मान तत्व लौटाता है।<br/>किसी सरणी के न्यूनतम मान के बारे में अधिक जानकारी के लिए #sort देखें।",
	"func_arrayMin_arg_1": "ऐरे जिसमें न्यूनतम मूल्य मांगा जाएगा।",
	"func_arrayMin_return": "न्यूनतम मूल्य की वस्तु।",
	"func_arrayPartition": "दो सूचियों वाली एक नई सरणी लौटाता है, पहले में वे सभी कुंजी/मान युग्म होते हैं जिनके लिए कॉलबैक फ़ंक्शन सही होता है, दूसरे में अन्य सभी होते हैं। यदि कॉलबैक फ़ंक्शन एक पैरामीटर लेता है, तो स्रोत सरणी का मान भेजा जाएगा, यदि यह दो पैरामीटर लेता है, तो कुंजी और मान भेजा जाएगा।",
	"func_arrayPartition_arg_1": "मूल तालिका।",
	"func_arrayPartition_arg_2": "फ़ंक्शन प्रत्येक तत्व के लिए कहा जाता है।",
	"func_arrayPartition_return": "नई तालिका।",
	"func_arraySort": "कॉलबैक फ़ंक्शन द्वारा परिभाषित क्रम के अनुसार सरणी को सॉर्ट करता है। तत्वों की तुलना दो से दो की जाती है, कॉलबैक फ़ंक्शन को मान -1, 0 या 1 वापस करना चाहिए, इस पर निर्भर करता है कि पहला मान पहले, समान स्तर पर या दूसरे मान के बाद है। यदि कॉलबैक फ़ंक्शन 2 पैरामीटर लेता है, तो दो मान भेजे जाते हैं, यदि यह 4 लेता है, तो कुंजी / मान जोड़े भेजे जाते हैं।",
	"func_arraySort_arg_1": "मूल तालिका",
	"func_arraySort_arg_2": "छँटाई समारोह।",
	"func_arraySort_return": "क्रमबद्ध सरणी",
	"func_asin": "अंतराल [0, #PI] में <b>तर्क</b> के आर्कसाइन की गणना करता है।",
	"func_asin_arg_1": "वह संख्या जिसकी चापज्या की गणना की जाएगी।",
	"func_asin_return": "<b>तर्क</b> का आर्कसाइन।",
	"func_assocSort": "क्रम <b>आदेश</b> के अनुसार कुंजी: मान संघ को संरक्षित करते हुए सरणी <b>सरणी</b> को क्रमबद्ध करता है।",
	"func_assocSort_arg_1": "क्रमबद्ध करने के लिए सरणी।",
	"func_assocSort_arg_2": "सॉर्ट क्रम: #SORT_ASC या #SORT_DESC।",
	"func_atan": "अंतराल [0, #PI] में <b>तर्क</b> के चापस्पर्शज्या की गणना करता है।",
	"func_atan2": "कार्तीय निर्देशांक (<b>x</b>, <b>y</b>) को ध्रुवीय निर्देशांक (<b>r</b>, <b>थीटा</b>) में बदलता है। यह फ़ंक्शन तर्कों के संकेतों का उपयोग करके -#PI और #PI के बीच <b>थीटा</b> कोण लौटाता है।",
	"func_atan2_arg_1": "Y-निर्देशांक।",
	"func_atan2_arg_2": "एक्स समन्वय।",
	"func_atan2_return": "बिंदु (x, y) के ध्रुवीय निर्देशांक में <b>थीटा</b> कोण।",
	"func_atan_arg_1": "वह संख्या जिसकी चाप स्पर्शरेखा की गणना की जाएगी।",
	"func_atan_return": "<b>तर्क</b> की चाप स्पर्शरेखा।",
	"func_average": "सरणी <b>सरणी</b> में निहित तत्वों के औसत की गणना करता है।",
	"func_average_arg_1": "ऐरे जिसका औसत हम गणना करना चाहते हैं।",
	"func_average_return": "औसत मूल्य।",
	"func_canUseChip": "यह निर्धारित करता है कि आपकी इकाई आईडी <b>निकाय</b> वाली इकाई पर <b>चिप</b> चिप का उपयोग कर सकती है या नहीं।",
	"func_canUseChip_arg_1": "परीक्षण की जाने वाली चिप की संख्या।",
	"func_canUseChip_arg_2": "वह इकाई आईडी जिस पर आप चिप का उपयोग करना चाहते हैं।",
	"func_canUseChip_return": "<i>true</i> यदि आपकी इकाई चिप का उपयोग कर सकती है, <i>false</i> अन्यथा।",
	"func_canUseChipOnCell": "निर्धारित करता है कि आपकी इकाई <b>सेल</b> सेल पर <b>चिप</b> चिप का उपयोग कर सकती है या नहीं।",
	"func_canUseChipOnCell_arg_1": "परीक्षण की जाने वाली चिप की संख्या।",
	"func_canUseChipOnCell_arg_2": "उस सेल की संख्या जिस पर आप चिप का उपयोग करना चाहते हैं।",
	"func_canUseChipOnCell_return": "<i>true</i> यदि आपकी इकाई चिप का उपयोग कर सकती है, <i>false</i> अन्यथा।",
	"func_canUseWeapon": "यह निर्धारित करता है कि आपकी इकाई आईडी <b>इकाई</b> के साथ हथियार <b>हथियार</b> के साथ इकाई को शूट कर सकती है या नहीं।",
	"func_canUseWeapon_arg_1": "परीक्षण करने का हथियार। आपके वर्तमान में सुसज्जित हथियार के लिए चूक।",
	"func_canUseWeapon_arg_2": "उस इकाई की आईडी जिस पर आप आग लगाना चाहते हैं।",
	"func_canUseWeapon_return": "<i>true</i> यदि आपकी इकाई आग लगा सकती है, <i>false</i> अन्यथा।",
	"func_canUseWeaponOnCell": "यह निर्धारित करता है कि आपकी इकाई <b>सेल</b> सेल को <b>हथियार</b> हथियार से शूट कर सकती है या नहीं।",
	"func_canUseWeaponOnCell_arg_1": "परीक्षण करने का हथियार। आपके वर्तमान में सुसज्जित हथियार के लिए चूक।",
	"func_canUseWeaponOnCell_arg_2": "उस सेल की संख्या जिसे आप शूट करना चाहते हैं।",
	"func_canUseWeaponOnCell_return": "<i>true</i> यदि आपकी इकाई आग लगा सकती है, <i>false</i> अन्यथा।",
	"func_cbrt": "<b>संख्या</b> के घनमूल की गणना करें",
	"func_cbrt_arg_1": "वह संख्या जिसका घनमूल हम निकालना चाहते हैं।",
	"func_cbrt_return": "<b>संख्या</b> का घनमूल।",
	"func_ceil": "<b>संख्या</b> का राउंड अप लौटाता है।<br/>आप #फर्श के साथ नीचे का राउंडिंग और #राउंड के साथ राउंडिंग पा सकते हैं।",
	"func_ceil_arg_1": "वह संख्या जिसके लिए हम ऊपरी गोलाई खोजना चाहते हैं।",
	"func_ceil_return": "राउंड अप <b>नंबर</b>।",
	"func_charAt": "स्थिति <b>स्थिति</b> पर स्थित स्ट्रिंग <b>स्ट्रिंग</b> का वर्ण लौटाता है।<br/>स्ट्रिंग का पहला वर्ण स्थिति 0 पर है।",
	"func_charAt_arg_1": "स्ट्रिंग जिससे हम एक चरित्र को पुनः प्राप्त करना चाहते हैं।",
	"func_charAt_arg_2": "खोजने के लिए चरित्र की स्थिति।",
	"func_charAt_return": "स्ट्रिंग जिसमें मांगा गया वर्ण है, या स्थिति अमान्य होने पर एक खाली स्ट्रिंग है।",
	"func_chipNeedLos": "लौटाता है कि <b>चिप</b> को उपयोग करने के लिए दृष्टि रेखा की आवश्यकता है या नहीं।",
	"func_chipNeedLos_arg_1": "परीक्षण की जाने वाली चिप की आईडी।",
	"func_chipNeedLos_return": "<i>सही</i> यदि <b>चिप</b> को दृष्टि की रेखा की आवश्यकता है, अन्यथा <i>गलत</i>।",
	"func_clearMarks": "क्षेत्र में मार्क () और मार्कटेक्स्ट () द्वारा किए गए सभी चिह्नों को साफ़ करता है।",
	"func_clone": "<b>मान</b> को <b>स्तर</b> स्तरों पर एक पैरामीटर के रूप में पास किया जाता है और प्रतिलिपि लौटाता है। उदाहरण के लिए एक सरणी के मामले में, एक क्लोन (सरणी, 1) सरणी की नकल करेगा, लेकिन उसके तत्वों की नहीं, एक क्लोन (सरणी, 2) सरणी के साथ-साथ सभी तत्वों की नकल करेगा।",
	"func_clone_arg_1": "क्लोन करने के लिए मान",
	"func_clone_arg_2": "क्लोन करने के लिए स्तरों की संख्या",
	"func_clone_return": "क्लोन किया गया मान",
	"func_contains": "यह निर्धारित करता है कि <b>खोज</b> स्ट्रिंग <b>स्ट्रिंग</b> स्ट्रिंग के भीतर है या नहीं।",
	"func_contains_arg_1": "स्ट्रिंग जिसमें खोज की जाएगी।",
	"func_contains_arg_2": "खोजने के लिए स्ट्रिंग।",
	"func_contains_return": "सही अगर <b>खोज</b> <b>स्ट्रिंग</b> में है, तो गलत अन्यथा।",
	"func_cos": "<b>कोण</b> के कोज्या की गणना करता है।",
	"func_cos_arg_1": "वह संख्या जिसकी कोसाइन की गणना की जाएगी (रेडियन में)।",
	"func_cos_return": "[-1, 1] श्रेणी में <b>कोण</b> की कोसाइन।",
	"func_count": "<b>सरणी</b> सरणी में तत्वों की संख्या की गणना करता है।",
	"func_count_arg_1": "सरणी जिसके तत्वों की संख्या गिना जाएगा।",
	"func_count_return": "सरणी तत्वों की संख्या।",
	"func_debug": "लड़ाई के अंत में रिपोर्ट में उपलब्ध व्यक्तिगत लॉग में एक <b>ऑब्जेक्ट</b> संदेश रिकॉर्ड करता है।",
	"func_debug_arg_1": "रिकॉर्ड करने के लिए संदेश।",
	"func_debugC": "व्यक्तिगत लॉग में एक <b>ऑब्जेक्ट</b> संदेश रिकॉर्ड करता है, लड़ाई के अंत में रिपोर्ट में उपलब्ध <b>रंग</b> रंग का।",
	"func_debugC_arg_1": "रिकॉर्ड करने के लिए संदेश।",
	"func_debugC_arg_2": "संदेश का रंग। आप #getColor फ़ंक्शन का उपयोग कर सकते हैं।",
	"func_debugE": "लड़ाई के अंत में रिपोर्ट में उपलब्ध व्यक्तिगत लॉग में एक <b>वस्तु</b> त्रुटि संदेश रिकॉर्ड करता है।<br />त्रुटि संदेश लड़ाई रिपोर्ट में लाल रंग में दिखाई देते हैं।",
	"func_debugE_arg_1": "लॉग करने के लिए त्रुटि संदेश।",
	"func_debugW": "लड़ाई के अंत में रिपोर्ट में उपलब्ध व्यक्तिगत लॉग में एक <b>ऑब्जेक्ट</b> चेतावनी संदेश रिकॉर्ड करता है। <br />युद्ध रिपोर्ट में चेतावनी संदेश नारंगी रंग में दिखाई देते हैं।",
	"func_debugW_arg_1": "रिकॉर्ड करने के लिए चेतावनी संदेश।",
	"func_deleteRegister": "कुंजी <b>कुंजी</b> से संबद्ध रजिस्ट्री को हटा देता है यदि यह मौजूद है।",
	"func_deleteRegister_arg_1": "हटाने के लिए रजिस्ट्री कुंजी।",
	"func_endsWith": "निर्धारित करता है कि <b>स्ट्रिंग</b> <b>प्रत्यय</b> स्ट्रिंग के साथ समाप्त होता है या नहीं।",
	"func_endsWith_arg_1": "स्ट्रिंग जिसमें खोज की जाएगी",
	"func_endsWith_arg_2": "खोजने का प्रत्यय",
	"func_endsWith_return": "सही अगर <b>स्ट्रिंग</b> <b>प्रत्यय</b> से समाप्त होता है",
	"func_exp": "यूलर की संख्या #E को <b>नंबर</b> की घात में बढ़ाता है।",
	"func_exp_arg_1": "वह घातांक जिससे #E उठाया जाएगा।",
	"func_exp_return": "#E** <b>नंबर</b>।",
	"func_fill": "सरणी <b>सरणी</b> का आकार बदलकर <b>आकार</b> कर देता है और इसके सभी बॉक्स को <b>मान</b> से भर देता है।",
	"func_fill_arg_1": "तालिका को पूरा करना है।",
	"func_fill_arg_2": "सरणी में प्रत्येक वर्ग के लिए मान।",
	"func_fill_arg_3": "बक्सों की संख्या। डिफ़ॉल्ट रूप से, वर्तमान सरणी आकार।",
	"func_floor": "<b>नंबर</b> के राउंडिंग डाउन की गणना करता है।<br/>आप #ceil के साथ राउंडिंग अप और #राउंड के साथ राउंडिंग पा सकते हैं।",
	"func_floor_arg_1": "संख्या जिसका पूर्णांकन हम ज्ञात करना चाहते हैं।",
	"func_floor_return": "<b>संख्या</b> तक नीचे किया गया।",
	"func_getAbsoluteShield": "आईडी <b>इकाई</b> के साथ इकाई का पूर्ण शील्ड लौटाता है। सीधे अपनी इकाई के पूर्ण शील्ड को पुनः प्राप्त करने के लिए, बिना किसी पैरामीटर के #getAbsoluteShield() का उपयोग करें।",
	"func_getAbsoluteShield_arg_1": "उस इकाई की आईडी जिसका पूर्ण शील्ड लौटाया जाएगा।",
	"func_getAbsoluteShield_return": "इकाई <b>निकाय</b> का पूर्ण ढाल।",
	"func_getAgility": "आईडी <b>इकाई</b> के साथ इकाई की चपलता लौटाता है। सीधे अपनी इकाई की चपलता को पुनः प्राप्त करने के लिए, बिना किसी पैरामीटर के #getAgility() का उपयोग करें।",
	"func_getAgility_arg_1": "उस इकाई की आईडी जिसकी चपलता वापस आ जाएगी।",
	"func_getAgility_return": "<b>इकाई</b> इकाई की चपलता।",
	"func_getAIID": "निकाय <b>निकाय</b> की AI आईडी लौटाता है। अपने एआई की आईडी को पुनः प्राप्त करने के लिए getAIID का उपयोग बिना किसी पैरामीटर के करें।",
	"func_getAIID_arg_1": "उस इकाई की आईडी जिसका एआई आईडी लौटाया जाएगा।",
	"func_getAIID_return": "<b>निकाय</b> निकाय AI आईडी।",
	"func_getAIName": "निकाय <b>निकाय</b> का AI नाम लौटाता है। अपने एआई के नाम को पुनः प्राप्त करने के लिए बिना किसी पैरामीटर के getAINam() का उपयोग करें।",
	"func_getAIName_arg_1": "उस इकाई की आईडी जिसका एआई नाम लौटाया जाएगा।",
	"func_getAIName_return": "<b>निकाय</b> निकाय का नाम AI.",
	"func_getAliveAllies": "युद्ध में जीवित अपने सभी सहयोगियों की एक सरणी लौटाता है।",
	"func_getAliveAllies_return": "आपके सभी जीवित सहयोगियों की आईडी वाली एक सरणी।",
	"func_getAliveEnemies": "युद्ध में जीवित अपने सभी शत्रुओं की एक सरणी लौटाता है।",
	"func_getAliveEnemiesCount": "लड़ाई में जीवित दुश्मनों की संख्या लौटाता है।",
	"func_getAliveEnemiesCount_return": "जीवित शत्रुओं की संख्या।",
	"func_getAliveEnemies_return": "आपके सभी जीवित शत्रुओं की आईडी वाली एक सरणी।",
	"func_getAllChips": "खेल में सभी चिप्स की सूची लौटाता है।",
	"func_getAllChips_return": "खेल में सभी चिप्स की सूची।",
	"func_getAllEffects": "खेल में सभी प्रभावों की सूची लौटाता है।",
	"func_getAllEffects_return": "खेल में सभी प्रभावों की सूची।",
	"func_getAllWeapons": "खेल में सभी हथियारों की सूची लौटाता है।",
	"func_getAllWeapons_return": "खेल में सभी हथियारों की सूची।",
	"func_getAlliedTurret": "यदि आपकी टीम मौजूद नहीं है तो बुर्ज आईडी या -1 लौटाता है।",
	"func_getAlliedTurret_return": "आपकी टीम की बुर्ज आईडी।",
	"func_getEnemyTurret": "यदि यह मौजूद नहीं है तो दुश्मन बुर्ज आईडी या -1 लौटाता है।",
	"func_getEnemyTurret_return": "दुश्मन बुर्ज आईडी।",
	"func_getAllies": "आपके सहयोगियों और आपकी इकाई वाली एक सरणी देता है।",
	"func_getAlliesCount": "लड़ाई में सहयोगियों की संख्या लौटाता है।",
	"func_getAlliesCount_return": "सहयोगियों की संख्या।",
	"func_getAlliesLife": "आपके सहयोगियों का कुल स्वास्थ्य लौटाता है।",
	"func_getAlliesLife_return": "आपके सहयोगियों का कुल स्वास्थ्य।",
	"func_getAllies_return": "सहयोगियों और आपकी इकाई की तालिका।",
	"func_getBirthTurn": "लड़ाई का वह मोड़ लौटाता है जहां <b>निकाय</b> प्रकट हुआ था। उदाहरण के लिए लीक होने पर 1 लौटाता है, और 5 देता है, अगर यह 5 की बारी पर बुलाया गया सम्मन है।",
	"func_getBirthTurn_arg_1": "उस इकाई की आईडी जिसका स्पॉन टर्न लौटाया जाएगा।",
	"func_getBirthTurn_return": "मुकाबला मोड़ जहां <b>निकाय</b> दिखाई दिया।",
	"func_getBlue": "0 और 255 के बीच, <b>रंग</b> रंग में नीले रंग की मात्रा लौटाता है। उदाहरण के लिए, getBlue(#COLOR_BLUE) = 255 और getBlue(#COLOR_GREEN) = 0।",
	"func_getBlue_arg_1": "वह रंग जिसका नीला रेट लौटाया जाएगा।",
	"func_getBlue_return": "<b>रंग</b> रंग में नीले रंग की दर",
	"func_getBulbChips": "उन चिप्स की सूची लौटाता है जो <b>बल्ब_चिप</b> चिप द्वारा बुलाए गए बल्ब पर लगे होंगे।",
	"func_getBulbChips_arg_1": "बल्ब पिस्सू।",
	"func_getBulbChips_return": "<b>बल्ब_चिप</b> चिप द्वारा बुलाए गए बल्ब के चिप्स।",
	"func_getCell": "वह सेल लौटाता है जहां <b>इकाई</b> आईडी वाली इकाई स्थित है। अपने सेल को पुनः प्राप्त करने के लिए बिना किसी पैरामीटर के getCell() का उपयोग करें।",
	"func_getCell_arg_1": "उस इकाई की आईडी जिसका सेल लौटाया जाएगा।",
	"func_getCell_return": "वह सेल नंबर जहां <b>निकाय</b> स्थित है।",
	"func_getCellContent": "आईडी <b>सेल</b> वाले सेल की सामग्री लौटाता है।",
	"func_getCellContent_arg_1": "सेल की आईडी जिसकी सामग्री लौटाई जाएगी।",
	"func_getCellContent_return": "<b>सेल</b> सेल की सामग्री: एक खाली सेल के लिए #CELL_EMPTY, एक इकाई के लिए #CELL_ENTITY, एक बाधा के लिए #CELL_OBSTACLE।",
	"func_getCellDistance": "दो सेल <b>सेल1</b> और <b>सेल2</b> के बीच की दूरी लौटाता है। <br />लौटाई गई दूरी कोशिकाओं की संख्या में व्यक्त की जाती है, और दो कोशिकाओं के बीच विभिन्न बाधाओं को ध्यान में नहीं रखा जाता है।\nकौए के उड़ते समय दूरी पाने के लिए, #getDistance देखें और बाधाओं से बचने वाली दो कोशिकाओं के बीच पथ की दूरी पाने के लिए, #getPathLength देखें।",
	"func_getCellDistance_arg_1": "प्रारंभिक सेल की आईडी।",
	"func_getCellDistance_arg_2": "आगमन सेल की आईडी।",
	"func_getCellDistance_return": "दो सेल <b>सेल1</b> और <b>सेल2</b> के बीच की दूरी।",
	"func_getCellFromXY": "स्थिति (<b>x</b>, <b>y</b>) पर सेल की आईडी लौटाता है।",
	"func_getCellFromXY_arg_1": "सेल की एक्स-स्थिति।",
	"func_getCellFromXY_arg_2": "सेल की वाई-स्थिति।",
	"func_getCellFromXY_return": "स्थिति में सेल आईडी (<b>x</b>, <b>y</b>), <b>शून्य</b> यदि सेल मौजूद नहीं है।",
	"func_getCellsToUseChip": "उन सेल की सूची लौटाता है जिनसे आपकी इकाई <b>चिप</b> चिप का <b>निकाय</b> इकाई पर उपयोग करने में सक्षम होगी।",
	"func_getCellsToUseChip_arg_1": "वह चिप जिसे इकाई उपयोग करने में सक्षम होना चाहती है।",
	"func_getCellsToUseChip_arg_2": "लक्ष्य निकाय।",
	"func_getCellsToUseChip_arg_3": "अनदेखा करने के लिए कोशिकाओं की सरणी।",
	"func_getCellsToUseChip_return": "सेल की सूची जहां चिप का उपयोग किया जा सकता है।",
	"func_getCellsToUseChipOnCell": "उन सेल की सूची लौटाता है जिनसे आपकी इकाई <b>सेल</b> सेल पर <b>चिप</b> चिप का उपयोग करने में सक्षम होगी।",
	"func_getCellsToUseChipOnCell_arg_1": "वह चिप जिसे इकाई उपयोग करने में सक्षम होना चाहती है।",
	"func_getCellsToUseChipOnCell_arg_2": "लक्ष्य सेल।",
	"func_getCellsToUseChipOnCell_arg_3": "अनदेखा करने के लिए कोशिकाओं की सरणी।",
	"func_getCellsToUseChipOnCell_return": "सेल की सूची जहां चिप का उपयोग किया जा सकता है।",
	"func_getCellsToUseWeapon": "उन सेल की सूची लौटाता है जिनसे आपकी इकाई <b>हथियार</b> का उपयोग इकाई <b>निकाय</b> पर कर सकेगी।",
	"func_getCellsToUseWeapon_arg_1": "परीक्षण करने का हथियार। आपके वर्तमान में सुसज्जित हथियार के लिए चूक।",
	"func_getCellsToUseWeapon_arg_2": "लक्ष्य निकाय।",
	"func_getCellsToUseWeapon_arg_3": "अनदेखा करने के लिए कोशिकाओं की सरणी। डिफ़ॉल्ट रूप से एक खाली सरणी।",
	"func_getCellsToUseWeapon_return": "उन कक्षों की सूची जिनसे हथियार का उपयोग किया जा सकता है।",
	"func_getCellsToUseWeaponOnCell": "उन सेल की सूची लौटाता है जिनसे आपकी इकाई <b>सेल</b> सेल पर <b>हथियार</b> हथियार का उपयोग करने में सक्षम होगी।",
	"func_getCellsToUseWeaponOnCell_arg_1": "परीक्षण करने का हथियार। आपके वर्तमान में सुसज्जित हथियार के लिए चूक।",
	"func_getCellsToUseWeaponOnCell_arg_2": "लक्ष्य सेल।",
	"func_getCellsToUseWeaponOnCell_arg_3": "अनदेखा करने के लिए कोशिकाओं की सरणी। डिफ़ॉल्ट रूप से एक खाली सरणी।",
	"func_getCellsToUseWeaponOnCell_return": "उन कक्षों की सूची जिनसे हथियार का उपयोग किया जा सकता है।",
	"func_getCellX": "सेल <b>सेल</b> के X में स्थिति निर्धारित करता है।",
	"func_getCellX_arg_1": "वह सेल जिसकी X में स्थिति निर्धारित की जाएगी।",
	"func_getCellX_return": "सेल की एक्स स्थिति।",
	"func_getCellY": "सेल <b>सेल</b> की Y स्थिति निर्धारित करता है।",
	"func_getCellY_arg_1": "वह सेल जिसकी Y में स्थिति निर्धारित की जाएगी।",
	"func_getCellY_return": "सेल की Y स्थिति।",
	"func_getChipArea": "<b>चिप</b> के प्रभाव प्रकार का क्षेत्र लौटाता है।",
	"func_getChipArea_arg_1": "वह चिप जिसका ज़ोन प्रकार लौटाया जाएगा।",
	"func_getChipArea_return": "क्षेत्र_* स्थिरांक के बीच चिप का क्षेत्र प्रकार <b>चिप</b>:\n<उल>\n<li>#AREA_POINT: एकल वर्ग क्षेत्र</li>\n<li>#AREA_LASER_LINE: लेज़र की रेखा</li>\n<li>#AREA_CIRCLE_1: गोलाकार क्षेत्रफल 3 वर्ग व्यास</li>\n<li>#AREA_CIRCLE_2: गोलाकार क्षेत्रफल 5 वर्ग व्यास में</li>\n<li>#AREA_CIRCLE_3: गोलाकार क्षेत्रफल 7 वर्ग व्यास में</li>\n</ul>",
	"func_getChipCooldown": "बाज़ार से लिया गया <b>चिप</b> चिप पुनर्प्राप्ति समय लौटाता है।",
	"func_getChipCooldown_arg_1": "वह चिप जिसका कोल्डाउन लौटाया जाएगा।",
	"func_getChipCooldown_return": "<b>चिप</b> कूलडाउन।",
	"func_getChipCost": "चिप <b>चिप</b> की TP में लागत लौटाता है।",
	"func_getChipCost_arg_1": "जिस चिप की कीमत चुकानी होगी वह वापस कर दी जाएगी।",
	"func_getChipCost_return": "<b>चिप</b> की कीमत।",
	"func_getChipEffectiveArea": "उन सेल की सूची लौटाता है जो प्रभावित होंगे यदि <b>चिप</b> चिप का उपयोग <b>सेल</b> सेल पर <b>से</b> सेल से किया जाता है।",
	"func_getChipEffectiveArea_arg_1": "चिप का परीक्षण किया जाना है।",
	"func_getChipEffectiveArea_arg_2": "लक्ष्य सेल।",
	"func_getChipEffectiveArea_arg_3": "वह सेल जिससे चिप का उपयोग किया जाता है।",
	"func_getChipEffectiveArea_return": "प्रभावित होने वाले सभी सेल की आईडी वाली सरणी।",
	"func_getChipEffects": "<b>चिप</b> चिप के प्रभाव लौटाता है।",
	"func_getChipEffects_arg_1": "वह चिप जिसका प्रभाव लौटाया जाएगा।",
	"func_getChipEffects_return": "<b>चिप</b> चिप के प्रभाव। #getWeaponEffects फ़ंक्शन के समान वापसी मान।",
	"func_getChipFailure": "चिप <b>चिप</b> की विफलता का प्रतिशत जोखिम लौटाता है।",
	"func_getChipFailure_arg_1": "वह चिप जिसका विफलता प्रतिशत लौटाया जाएगा।",
	"func_getChipFailure_return": "<b>चिप</b> चिप विफलता प्रतिशत, <b>0</b> और <b>100</b> के बीच एक पूर्णांक।",
	"func_getChipMaxRange": "<b>चिप</b> चिप की अधिकतम रेंज लौटाता है।",
	"func_getChipMaxRange_arg_1": "वह चिप जिसकी अधिकतम सीमा लौटाई जाएगी।",
	"func_getChipMaxRange_return": "<b>चिप</b> की अधिकतम सीमा।",
	"func_getChipMaxScope": "<b>चिप</b> चिप की अधिकतम रेंज लौटाता है।",
	"func_getChipMaxScope_arg_1": "वह चिप जिसकी अधिकतम सीमा लौटाई जाएगी।",
	"func_getChipMaxScope_return": "<b>चिप</b> की अधिकतम सीमा।",
	"func_getChipMinRange": "<b>चिप</b> चिप की न्यूनतम सीमा लौटाता है।",
	"func_getChipMinRange_arg_1": "वह चिप जिसकी न्यूनतम सीमा लौटा दी जाएगी।",
	"func_getChipMinRange_return": "<b>चिप</b> की न्यूनतम सीमा।",
	"func_getChipMinScope": "<b>चिप</b> चिप की न्यूनतम सीमा लौटाता है।",
	"func_getChipMinScope_arg_1": "वह चिप जिसकी न्यूनतम सीमा लौटा दी जाएगी।",
	"func_getChipMinScope_return": "<b>चिप</b> की न्यूनतम सीमा।",
	"func_getChipLaunchType": "LAUNCH_TYPE_* स्थिरांकों के बीच, <b>चिप</b> चिप का लॉन्च मोड लौटाता है।",
	"func_getChipLaunchType_arg_1": "चिप की आईडी जिसका फेंकने वाला मोड लौटाया जाएगा।",
	"func_getChipLaunchType_return": "<b>चिप</b> चिप का फेंकने का तरीका।",
	"func_getChipName": "चिप <b>चिप</b> का नाम लौटाता है।",
	"func_getChipName_arg_1": "वह चिप जिसका नाम लौटाया जाएगा।",
	"func_getChipName_return": "<b>चिप</b> चिप का नाम।",
	"func_getChips": "आईडी <b>इकाई</b> के साथ इकाई के बुलेट लौटाता है।",
	"func_getChips_arg_1": "उस इकाई की आईडी जिसकी बुलेट लौटाई जाएगी।",
	"func_getChips_return": "इकाई <b>इकाई</b> के बुलेट आईडी वाली एक सरणी।",
	"func_getChipTargets": "सेल <b>सेल</b> पर <b>चिप</b> चिप का उपयोग करने पर प्रभावित होने वाली संस्थाओं को लौटाता है।",
	"func_getChipTargets_arg_1": "चिप का परीक्षण किया जाना है।",
	"func_getChipTargets_arg_2": "लक्ष्य सेल।",
	"func_getChipTargets_return": "प्रभावित होने वाली सभी संस्थाओं की आईडी वाली सरणी।",
	"func_getColor": "पैरामीटर के रूप में प्रदान किए गए रंग (<b>लाल</b>, <b>हरा</b>, <b>नीला</b>) के अनुरूप पूर्णांक लौटाता है।",
	"func_getColor_arg_1": "0 और 255 के बीच लाल मान।",
	"func_getColor_arg_2": "0 और 255 के बीच हरा मान।",
	"func_getColor_arg_3": "नीला मान 0 और 255 के बीच।",
	"func_getColor_return": "एक पैरामीटर के रूप में प्रदान किए गए रंग के अनुरूप int।",
	"func_getCooldown": "<b>निकाय</b> इकाई का वर्तमान <b>चिप</b> कूलडाउन लौटाता है।",
	"func_getCooldown_arg_1": "वह चिप जिसका वर्तमान कोल्डाउन लौटाया जाएगा।",
	"func_getCooldown_arg_2": "वह इकाई जिसका कूलडाउन लौटाया जाएगा।",
	"func_getCooldown_return": "चिप <b>चिप</b> का वर्तमान कोल्डाउन, यह चिप के उपयोग योग्य बनने से पहले घुमावों की संख्या है, <b>0</b> यदि यह वर्तमान में उपयोग करने योग्य है।",
	"func_getCores": "आईडी <b>इकाई</b> के साथ इकाई के कोर की संख्या लौटाता है।",
	"func_getCores_arg_1": "वह इकाई जिसके कोर की संख्या लौटाई जाएगी।",
	"func_getCores_return": "<b>इकाई</b> इकाई के कोर की संख्या।",
	"func_getDate": "Dd/MM/yyyy प्रारूप में लड़ाई की तारीख लौटाता है।",
	"func_getDate_return": "लड़ाई की तारीख।",
	"func_getTime": "एचएच:एमएम:एसएस प्रारूप में लड़ाई का समय देता है।",
	"func_getTime_return": "लड़ाई का समय।",
	"func_getTimestamp": "1 जनवरी, 1970 से सेकंड की संख्या के बराबर लड़ाई का टाइमस्टैम्प लौटाता है।",
	"func_getTimestamp_return": "लड़ाई का टाइमस्टैम्प।",
	"func_getDamageReturn": "आईडी <b>इकाई</b> के साथ इकाई की क्षति वापसी दर लौटाता है।",
	"func_getDamageReturn_arg_1": "उस इकाई का आईडी जिससे नुकसान का रेफ़रल लौटाया जाएगा।",
	"func_getDamageReturn_return": "आईडी <b>इकाई</b> के साथ इकाई की क्षति वापसी दर (% में)।",
	"func_getDeadAllies": "मृत दोस्ताना संस्थाओं को लौटाता है।",
	"func_getDeadAllies_return": "संबद्ध मृत संस्थाओं की तालिका।",
	"func_getDeadEnemies": "मृत शत्रु संस्थाओं को लौटाता है।",
	"func_getDeadEnemiesCount": "युद्ध में मारे गए शत्रुओं की संख्या लौटाता है।",
	"func_getDeadEnemiesCount_return": "मृत शत्रुओं की संख्या।",
	"func_getDeadEnemies_return": "मृत शत्रु संस्थाओं की तालिका।",
	"func_getDistance": "दूरी की गणना करता है क्योंकि कौआ दो कोशिकाओं <b>सेल1</b> और <b>सेल2</b> के बीच उड़ता है। <br />कोशिकाओं की संख्या में दूरी प्राप्त करने के लिए, #getCellDistance देखें, और विभिन्न बाधाओं से बचते हुए दो कक्षों के बीच पथ की लंबाई प्राप्त करने के लिए, #getPathLength देखें।",
	"func_getDistance_arg_1": "प्रारंभिक सेल।",
	"func_getDistance_arg_2": "आगमन प्रकोष्ठ।",
	"func_getDistance_return": "दो कोठरियों के बीच कौआ जितनी दूरी से उड़ता है।",
	"func_getPassiveEffects": "आईडी <b>निकाय</b> के साथ इकाई के निष्क्रिय प्रभावों की सूची लौटाता है। अपनी इकाई के निष्क्रिय प्रभावों की सूची सीधे प्राप्त करने के लिए, पैरामीटर के बिना #getPassiveEffects() का उपयोग करें।",
	"func_getPassiveEffects_arg_1": "उस इकाई का आईडी जिसके निष्क्रिय प्रभावों की सूची दी जाएगी।",
	"func_getPassiveEffects_return": "वर्तमान में <b>निकाय</b> निकाय पर मौजूद निष्क्रिय प्रभावों की सूची।\nनिष्क्रिय प्रभावों की सूची एक सरणी है जिसमें प्रभाव होते हैं।\nएक इफेक्ट अपने आप में फॉर्म के 7 बॉक्स की एक सरणी है: [<b>टाइप</b>, <b>वैल्यू</b>, <b>caster_id</b>, <b>टर्न</b>, <b>महत्वपूर्ण</b>, <b>item_id</b>, <b>target_id</b>]। यह #getEffects फ़ंक्शन द्वारा लौटाए गए क्लासिक प्रभावों के समान संरचना है।",
	"func_getEnemies": "युद्ध में शत्रु संस्थाओं (जीवित या मृत) को लौटाता है।",
	"func_getEnemiesCount": "लड़ाई में दुश्मनों की संख्या लौटाता है।",
	"func_getEnemiesCount_return": "शत्रुओं की संख्या।",
	"func_getEnemiesLife": "सभी शत्रु संस्थाओं के हिट पॉइंट्स के योग की गणना करता है।",
	"func_getEnemiesLife_return": "दुश्मन टीम के हिट पॉइंट्स का योग।",
	"func_getEnemies_return": "एक सरणी जिसमें सभी शत्रु संस्थाओं की आईडी होती है।",
	"func_getEntityTurnOrder": "1 और n (वर्तमान में चल रही संस्थाओं की संख्या) के बीच एक मान लौटाता है, जो प्ले ऑर्डर में <b>निकाय</b> की स्थिति दर्शाता है।",
	"func_getEntityTurnOrder_arg_1": "उस इकाई का आईडी जिसका प्ले ऑर्डर लौटाया जाएगा",
	"func_getEntityTurnOrder_return": "निकाय <b>निकाय</b> के चलने के क्रम में स्थान",
	"func_getFarmerID": "इकाई <b>निकाय</b> की ब्रीडर आईडी लौटाता है।",
	"func_getFarmerID_arg_1": "उस इकाई का आईडी जिसकी ब्रीडर आईडी लौटाई जाएगी।",
	"func_getFarmerID_return": "<b>इकाई</b> इकाई ब्रीडर की आईडी।",
	"func_getFarmerCountry": "इकाई <b>निकाय</b> के ब्रीडर का देश लौटाता है।",
	"func_getFarmerCountry_arg_1": "उस इकाई की आईडी जिसके ब्रीडर का देश लौटाया जाएगा।",
	"func_getFarmerCountry_return": "<b>निकाय</b> इकाई के ब्रीडर का देश, या \"?\" यदि इंगित नहीं किया गया है।",
	"func_getFarmerName": "<b>निकाय</b> निकाय का प्रजनक नाम लौटाता है।",
	"func_getFarmerName_arg_1": "उस इकाई का आईडी जिसका प्रजनक नाम लौटाया जाएगा।",
	"func_getFarmerName_return": "<b>निकाय</b> इकाई के ब्रीडर का नाम।",
	"func_getFarthestAlly": "कौआ उड़ते ही आपकी इकाई के सबसे दूर के सहयोगी को निर्धारित करता है।",
	"func_getFarthestAlly_return": "सबसे दूर के अनुकूल इकाई की आईडी।",
	"func_getFarthestEnemy": "कौआ उड़ते ही अपनी इकाई से सबसे दूर के दुश्मन को निर्धारित करता है।",
	"func_getFarthestEnemy_return": "सबसे दूर दुश्मन इकाई की आईडी।",
	"func_getFightID": "वर्तमान लड़ाई आईडी लौटाता है।",
	"func_getFightID_return": "वर्तमान लड़ाई की आईडी।",
	"func_getFightContext": "वर्तमान लड़ाई के संदर्भ को लौटाता है।",
	"func_getFightContext_return": "युद्ध के संदर्भ पर निर्भर करता है: टेस्ट बैटल (#FIGHT_CONTEXT_TEST), एरिना बैटल (#FIGHT_CONTEXT_GARDEN), टूर्नामेंट बैटल (#FIGHT_CONTEXT_TOURNAMENT), चैलेंज बैटल (#FIGHT_CONTEXT_CHALLENGE), बैटल रॉयल (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "वर्तमान युद्ध प्रकार लौटाता है।",
	"func_getFightType_return": "लड़ाई के प्रकार के आधार पर: एकल लड़ाई (#FIGHT_TYPE_SOLO), किसान लड़ाई (#FIGHT_TYPE_FARMER), टीम लड़ाई (#FIGHT_TYPE_TEAM), बैटल रॉयल (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "आईडी <b>इकाई</b> के साथ इकाई की ताकत लौटाता है।",
	"func_getForce_arg_1": "वह इकाई जिसकी ताकत लौटा दी जाएगी।",
	"func_getForce_return": "<b>इकाई</b> इकाई की ताकत।",
	"func_getFrequency": "आईडी <b>इकाई</b> के साथ इकाई की आवृत्ति लौटाता है। अपनी आवृत्ति को पुनः प्राप्त करने के लिए बिना किसी पैरामीटर के getFrequency() का उपयोग करें।",
	"func_getFrequency_arg_1": "वह निकाय जिसकी फ़्रीक्वेंसी लौटाई जाएगी.",
	"func_getFrequency_return": "<b>निकाय</b> निकाय की आवृत्ति।",
	"func_getGreen": "0 और 255 के बीच, <b>रंग</b> रंग में हरे रंग की मात्रा लौटाता है। उदाहरण के लिए, getGreen(#COLOR_GREEN) = 255 और getGreen(#COLOR_RED) = 0।",
	"func_getGreen_arg_1": "वह रंग जिसका हरा रेट लौटाया जाएगा।",
	"func_getGreen_return": "<b>रंग</b> रंग में हरे रंग की दर",
	"func_getInstructionsCount": "वर्तमान मोड़ के दौरान आपकी इकाई द्वारा किए गए निर्देशों की संख्या लौटाता है।",
	"func_getInstructionsCount_return": "वर्तमान मोड़ के दौरान आपकी इकाई द्वारा किए गए निर्देशों की संख्या।",
	"func_getLaunchedEffects": "आईडी <b>इकाई</b> के साथ इकाई के कारण होने वाले प्रभावों की सूची लौटाता है।",
	"func_getLaunchedEffects_arg_1": "उस इकाई का आईडी जिसके कारण प्रभावों की सूची दी जाएगी।",
	"func_getLaunchedEffects_return": "#getEffects द्वारा लौटाए गए सरणी के रूप में आईडी <b>इकाई</b> के साथ इकाई के कारण होने वाले प्रभावों की सूची।",
	"func_getLeek": "आपकी इकाई की आईडी लौटाता है।",
	"func_getLeek_return": "आपकी इकाई की आईडी।",
	"func_getEntity": "आपकी इकाई की आईडी लौटाता है।",
	"func_getEntity_return": "आपकी इकाई की आईडी।",
	"func_getLeekID": "लीक ऑफ आईडी <b>लीक</b> की असली आईडी लौटाता है।",
	"func_getLeekID_arg_1": "लीक की आईडी जिसका वास्तविक आईडी लौटाया जाएगा।",
	"func_getLeekID_return": "लीक <b>इकाई</b> की असली आईडी।",
	"func_getLeekOnCell": "वह इकाई लौटाता है जो सेल <b>सेल</b> पर है।",
	"func_getLeekOnCell_arg_1": "वह सेल जिसकी इकाई को हम पुनः प्राप्त करना चाहते हैं।",
	"func_getLeekOnCell_return": "सेल पर इकाई की आईडी, या -1 अगर सेल में कोई इकाई नहीं है।",
	"func_getEntityOnCell": "वह इकाई लौटाता है जो सेल <b>सेल</b> पर है।",
	"func_getEntityOnCell_arg_1": "वह सेल जिसकी इकाई को हम पुनः प्राप्त करना चाहते हैं।",
	"func_getEntityOnCell_return": "सेल पर इकाई की आईडी, या -1 अगर सेल में कोई इकाई नहीं है।",
	"func_getLevel": "आईडी <b>इकाई</b> के साथ इकाई स्तर लौटाता है।",
	"func_getLevel_arg_1": "उस इकाई की आईडी जिसका स्तर लौटाया जाएगा।",
	"func_getLevel_return": "आईडी <b>इकाई</b> के साथ इकाई का स्तर।",
	"func_getLife": "आईडी <b>इकाई</b> के साथ इकाई का वर्तमान जीवन लौटाता है। अपने जीवन को पुनः प्राप्त करने के लिए बिना किसी पैरामीटर के getLife() का उपयोग करें।",
	"func_getLife_arg_1": "उस इकाई की आईडी जिसका जीवन लौटाया जाएगा।",
	"func_getLife_return": "<b>निकाय</b> इकाई का वर्तमान जीवन।",
	"func_getMagic": "आईडी <b>इकाई</b> के साथ इकाई का जादू लौटाता है। अपने जादू को पुनः प्राप्त करने के लिए बिना किसी पैरामीटर के getMagic() का उपयोग करें।",
	"func_getMagic_arg_1": "उस इकाई की आईडी जिसका जादू लौटाया जाएगा।",
	"func_getMagic_return": "आईडी इकाई <b>इकाई</b> का जादू।",
	"func_getMapType": "स्थिरांक #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER और #MAP_BEACH के बीच उस प्रकार के इलाके को लौटाता है, जिस पर लड़ाई होती है (कारखाना, रेगिस्तान, जंगल आदि)।",
	"func_getMapType_return": "भूभाग का प्रकार।",
	"func_getMessageAuthor": "<b>संदेश</b> संदेश लिखने वाले निकाय की आईडी लौटाता है।",
	"func_getMessageAuthor_arg_1": "संदेश जिसका लेखक लौटाया जाएगा।",
	"func_getMessageAuthor_return": "संदेश <b>संदेश</b> लिखने वाले निकाय की आईडी।",
	"func_getMessageParams": "संदेश पैरामीटर की सरणी <b>संदेश</b> लौटाता है।",
	"func_getMessageParams_arg_1": "वह संदेश जिसके पैरामीटर लौटाए जाएंगे।",
	"func_getMessageParams_return": "<b>संदेश</b> संदेश पैरामीटर।",
	"func_getMessages": "<b>निकाय</b> इकाई संदेश सरणी लौटाता है।",
	"func_getMessages_arg_1": "वह निकाय जिसके संदेश लौटाए जाएंगे.",
	"func_getMessages_return": "आपके संदेशों की तालिका।<br>संदेश स्वयं एक के रूप में प्रस्तुत किया जाता है\nफ़ॉर्म की श्रेणी: [<b>लेखक</b>, <b>प्रकार</b>, <b>पैरामीटर</b>]<br>\nविभिन्न प्रकार के संदेशों को स्थिरांक द्वारा दर्शाया जाता है:\n<उल>\n<li>#MESSAGE_HEAL: देखभाल के लिए अनुरोध</li>\n<li>#MESSAGE_ATTACK: हमला करने का अनुरोध</li>\n<li>#MESSAGE_BUFF_FORCE: फोर्स बूस्ट रिक्वेस्ट</li>\n<ली>...</li>\n</ul>",
	"func_getMessageType": "संदेश प्रकार <b>संदेश</b> लौटाता है।",
	"func_getMessageType_arg_1": "वह संदेश जिसका प्रकार लौटाया जाएगा (#MESSAGE_HEAL, #MESSAGE_ATTACK, आदि के बीच)।",
	"func_getMessageType_return": "संदेश प्रकार <b>संदेश</b>।",
	"func_getMP": "<b>निकाय</b> निकाय की वर्तमान संचलन बिंदु संख्या लौटाता है। अपने पीएम को पुनः प्राप्त करने के लिए बिना किसी पैरामीटर के getMP() का उपयोग करें।",
	"func_getMP_arg_1": "उस इकाई की आईडी जिसकी पीएम गिनती लौटाई जाएगी।",
	"func_getMP_return": "<b>इकाई</b> इकाई का एमपी नंबर।",
	"func_getName": "आईडी <b>इकाई</b> के साथ इकाई का नाम लौटाता है।",
	"func_getName_arg_1": "उस इकाई का आईडी जिसका नाम लौटाया जाएगा।",
	"func_getName_return": "इकाई का नाम <b>इकाई</b>।",
	"func_getNearestAlly": "आपकी इकाई के लिए निकटतम अनुकूल इकाई लौटाता है।",
	"func_getNearestAlly_return": "निकटतम दोस्ताना इकाई की आईडी।",
	"func_getNearestAllyTo": "पैरामीटर के रूप में प्रदान की गई इकाई को निकटतम संबद्ध इकाई लौटाता है।",
	"func_getNearestAllyTo_arg_1": "उस इकाई की आईडी जिसका निकटतम सहयोगी हम जानना चाहते हैं।",
	"func_getNearestAllyToCell": "पैरामीटर के रूप में प्रदान किए गए सेल में निकटतम संबद्ध इकाई लौटाता है।",
	"func_getNearestAllyToCell_arg_1": "उस सेल की आईडी जिसके निकटतम सहयोगी को हम जानना चाहते हैं।",
	"func_getNearestAllyToCell_return": "निकटतम दोस्ताना इकाई की आईडी।",
	"func_getNearestAllyTo_return": "निकटतम दोस्ताना इकाई की आईडी।",
	"func_getNearestEnemy": "आपकी इकाई के निकटतम शत्रु इकाई को लौटाता है।",
	"func_getNearestEnemy_return": "निकटतम शत्रु इकाई की आईडी।",
	"func_getNearestEnemyTo": "पैरामीटर के रूप में प्रदान की गई इकाई को निकटतम शत्रु इकाई लौटाता है।",
	"func_getNearestEnemyTo_arg_1": "उस इकाई की आईडी जिसका निकटतम शत्रु हम जानना चाहते हैं।",
	"func_getNearestEnemyToCell": "एक पैरामीटर के रूप में प्रदान किए गए सेल में निकटतम शत्रु इकाई लौटाता है।",
	"func_getNearestEnemyToCell_arg_1": "उस सेल की आईडी जिसके निकटतम शत्रु को हम जानना चाहते हैं।",
	"func_getNearestEnemyToCell_return": "निकटतम शत्रु इकाई की आईडी।",
	"func_getNearestEnemyTo_return": "निकटतम शत्रु इकाई की आईडी।",
	"func_getNextPlayer": "उस इकाई की आईडी लौटाता है जो वर्तमान खिलाड़ी के बाद खेलेगी।",
	"func_getNextPlayer_return": "अगला खिलाड़ी।",
	"func_getObstacles": "इलाके में बाधा स्थानों की सूची लौटाता है।",
	"func_getObstacles_return": "बाधा सेल आईडी वाली सरणी।",
	"func_getOperations": "आपकी इकाई द्वारा अपनी बारी की शुरुआत के बाद से उपयोग किए गए संचालन की संख्या लौटाता है। यह संख्या #OPERATIONS_LIMIT से कम रहनी चाहिए ताकि इकाई क्रैश न हो.",
	"func_getOperations_return": "अपनी बारी की शुरुआत के बाद से आपकी इकाई द्वारा उपयोग किए गए संचालन की संख्या।",
	"func_getPath": "दो सेल <b>सेल1</b> और <b>सेल2</b> के बीच बाधाओं से बचने का रास्ता लौटाता है, अगर यह मौजूद है, तो सरणी <b>अनदेखा किया गया</b> में मौजूद सेल को अनदेखा कर देता है। अगर कोई खिलाड़ी उपेक्षित सेल पर है, तो पथ उनके ऊपर से गुजर सकता है।<br /><br />\nप्रारंभिक सेल <b>cell1</b> कभी भी परिणामी पथ का हिस्सा नहीं होता है। सेल <b>सेल2</b> परिणामी पथ का हिस्सा है अगर और केवल अगर यह खाली है या <b>अनदेखा किया गया</b> द्वारा अनदेखा किया गया है।<br /><br />\nयदि दो सेल के बीच कोई पथ मौजूद नहीं है, तो <b>getPath</b> रिटर्न <i>null</i> देता है।",
	"func_getPath_arg_1": "प्रारंभिक सेल।",
	"func_getPath_arg_2": "आगमन प्रकोष्ठ।",
	"func_getPath_arg_3": "अनदेखा करने के लिए सेल की सरणी।",
	"func_getPath_return": "कोशिकाओं से युक्त सरणी दो कोशिकाओं के बीच पथ बनाती है।",
	"func_getPathLength": "दो सेल <b>सेल1</b> और <b>सेल2</b> के बीच पाथ की लंबाई लौटाता है, बाधाओं से बचता है, सरणी <b>अनदेखा किया गया</b> में मौजूद सेल को अनदेखा करता है। यह फ़ंक्शन <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i> के समतुल्य है।\nअगर कोई खिलाड़ी उपेक्षित सेल पर है, तो पथ उनके ऊपर से गुजर सकता है।<br /><br />\nआरंभिक सेल <b>cell1</b> को परिणाम में कभी नहीं गिना जाता। सेल <b>सेल2</b> को परिणाम में गिना जाता है यदि और केवल अगर यह खाली है या <b>अनदेखा सेल</b> द्वारा अनदेखा किया गया है।<br /><br />\nयदि दो सेल के बीच कोई पथ मौजूद नहीं है, तो <b>getPathLength</b> रिटर्न <i>null</i> देता है।",
	"func_getPathLength_arg_1": "प्रारंभिक सेल।",
	"func_getPathLength_arg_2": "आगमन प्रकोष्ठ।",
	"func_getPathLength_arg_3": "अनदेखा करने के लिए सेल की सरणी।",
	"func_getPathLength_return": "<b>सेल1</b> और <b>सेल2</b> के बीच पथ की लंबाई।",
	"func_getPreviousPlayer": "उस इकाई की आईडी लौटाता है जो वर्तमान खिलाड़ी से पहले खेली गई थी।",
	"func_getPreviousPlayer_return": "पूर्व खिलाड़ी।",
	"func_getRed": "0 और 255 के बीच, <b>रंग</b> रंग में लाल रंग की मात्रा लौटाता है। उदाहरण के लिए, getRed(#COLOR_RED) = 255 और getRed(#COLOR_BLUE) = 0।",
	"func_getRed_arg_1": "वह रंग जिसका लाल रेट लौटाया जाएगा।",
	"func_getRed_return": "<b>रंग</b> में लाल रंग की दर",
	"func_getRegister": "यदि रजिस्ट्री मौजूद नहीं है, तो कुंजी <b>कुंजी</b> या </i>null</i> से संबद्ध इकाई रजिस्ट्री में संग्रहीत मान लौटाता है।",
	"func_getRegister_arg_1": "रजिस्ट्री कुंजी जिसका मान लौटाया जाएगा।",
	"func_getRegister_return": "<b>कुंजी</b> रजिस्ट्री में संग्रहीत मान।",
	"func_getRegisters": "एक साहचर्य सरणी [<i>रजिस्ट्री कुंजी</i>:<i>रजिस्टर मूल्य</i>] के रूप में इकाई रजिस्टरों का सेट लौटाता है। उदाहरण: <कोड> डिबग (getRegisters ());\n// उदाहरण के लिए प्रदर्शित करता है:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "इकाई के सभी रजिस्टरों के अनुरूप साहचर्य सरणी।",
	"func_getRelativeShield": "आईडी <b>इकाई</b> के साथ इकाई का सापेक्ष शील्ड लौटाता है। अपनी इकाई के सापेक्ष ढाल को सीधे पुनः प्राप्त करने के लिए, बिना किसी पैरामीटर के #getRelativeShield() का उपयोग करें।",
	"func_getRelativeShield_arg_1": "उस इकाई की आईडी जिसकी सापेक्ष शील्ड लौटाई जाएगी।",
	"func_getRelativeShield_return": "<b>निकाय</b> की सापेक्ष ढाल, <b>0</b> और <b>100</b> के बीच एक पूर्णांक।",
	"func_getResistance": "आईडी <b>इकाई</b> के साथ इकाई का प्रतिरोध लौटाता है। अपने प्रतिरोध को पुनः प्राप्त करने के लिए बिना किसी पैरामीटर के getResistance() का उपयोग करें।",
	"func_getResistance_arg_1": "इकाई की आईडी जिसका प्रतिरोध लौटाया जाएगा।",
	"func_getResistance_return": "आईडी <b>इकाई</b> के साथ इकाई का प्रतिरोध।",
	"func_getScience": "आईडी <b>इकाई</b> के साथ इकाई का विज्ञान लौटाता है। अपने विज्ञान को पुनः प्राप्त करने के लिए बिना किसी पैरामीटर के getScience() का उपयोग करें।",
	"func_getScience_arg_1": "उस इकाई की आईडी जिसका विज्ञान लौटाया जाएगा।",
	"func_getScience_return": "आईडी इकाई <b>इकाई</b> का विज्ञान।",
	"func_getStrength": "आईडी <b>इकाई</b> के साथ इकाई की ताकत लौटाता है। अपनी ताकत को पुनः प्राप्त करने के लिए बिना किसी पैरामीटर के getStrength() का उपयोग करें।",
	"func_getStrength_arg_1": "वह इकाई जिसकी ताकत लौटा दी जाएगी।",
	"func_getStrength_return": "<b>इकाई</b> इकाई की ताकत।",
	"func_getPower": "आईडी <b>इकाई</b> के साथ इकाई की शक्ति लौटाता है।",
	"func_getPower_arg_1": "वह इकाई जिसकी शक्ति लौटा दी जाएगी।",
	"func_getPower_return": "<b>निकाय</b> की शक्ति।",
	"func_getSummoner": "इकाई <b>इकाई</b> का आह्वान करने वाली इकाई लौटाता है, अगर यह एक मंगलाचरण है।",
	"func_getSummoner_arg_1": "उस इकाई का आईडी जिसका सम्मनकर्ता निकाल दिया जाएगा।",
	"func_getSummoner_return": "आह्वान की गई इकाई की आईडी <b>इकाई</b> यदि यह मंगलाचरण है, <i>अशक्त</i> अन्यथा।",
	"func_getSummons": "आईडी <b>इकाई</b> के साथ इकाई के वर्तमान में सक्रिय आमंत्रणों की आईडी की सूची लौटाता है।",
	"func_getSummons_arg_1": "उस इकाई का आईडी जिसका सम्मन लौटाया जाएगा।",
	"func_getSummons_return": "आईडी <b>इकाई</b> के साथ इकाई के मंगलाचरण आईडी की सूची।",
	"func_getTeamID": "निकाय <b>निकाय</b> की टीम आईडी लौटाता है।",
	"func_getTeamID_arg_1": "उस इकाई का आईडी जिसकी टीम आईडी लौटाई जाएगी।",
	"func_getTeamID_return": "इकाई <b>इकाई</b> की टीम आईडी।",
	"func_getTeamName": "इकाई <b>इकाई</b> का टीम नाम लौटाता है।",
	"func_getTeamName_arg_1": "उस इकाई का आईडी जिसकी टीम का नाम लौटाया जाएगा।",
	"func_getTeamName_return": "<b>निकाय</b> इकाई का टीम नाम।",
	"func_getTotalLife": "आईडी <b>इकाई</b> के साथ इकाई का कुल जीवन लौटाता है। अपने कुल जीवन को पुनः प्राप्त करने के लिए बिना किसी पैरामीटर के getTotalLife() का उपयोग करें।",
	"func_getTotalLife_arg_1": "उस इकाई की आईडी जिसका कुल जीवन लौटाया जाएगा।",
	"func_getTotalLife_return": "इकाई का कुल जीवन।",
	"func_getTotalMP": "निकाय <b>निकाय</b> के संचलन बिंदुओं की अधिकतम संख्या लौटाता है।",
	"func_getTotalMP_arg_1": "उस इकाई की आईडी जिसका अधिकतम संचलन बिंदु लौटाया जाएगा।",
	"func_getTotalMP_return": "<b>निकाय</b> के लिए संचलन बिंदुओं की अधिकतम संख्या।",
	"func_getTotalTP": "निकाय <b>निकाय</b> के टर्न पॉइंट की अधिकतम संख्या लौटाता है।",
	"func_getTotalTP_arg_1": "उस इकाई की आईडी जिसके टर्न पॉइंट्स की अधिकतम संख्या लौटाई जाएगी।",
	"func_getTotalTP_return": "<b>निकाय</b> निकाय के टर्न पॉइंट की अधिकतम संख्या।",
	"func_getTP": "निकाय <b>निकाय</b> के टर्न पॉइंट की संख्या लौटाता है। अपने पीटी को पुनः प्राप्त करने के लिए बिना किसी पैरामीटर के getTP () का उपयोग करें।",
	"func_getTP_arg_1": "उस इकाई की आईडी जिसका पीटी लौटाया जाएगा।",
	"func_getTP_return": "<b>निकाय</b> निकाय का पीटी नंबर।",
	"func_getTurn": "वर्तमान मुकाबला मोड़ लौटाता है। घुमावों की अधिकतम संख्या #MAX_TURNS है।",
	"func_getTurn_return": "युद्ध का वर्तमान मोड़।",
	"func_getType": "इकाई का इकाई प्रकार लौटाता है <b>इकाई</b>।",
	"func_getType_arg_1": "उस इकाई का आईडी जिसका प्रकार लौटाया जाएगा।",
	"func_getType_return": "<b>निकाय</b> का इकाई प्रकार:<ul><li>#ENTITY_LEEK अगर यह लीक है।</li><li>#ENTITY_BULB अगर यह एक 'बल्ब' है।</li><li >#ENTITY_TURRET यदि यह बुर्ज है।</li></ul>",
	"func_getWeapon": "वर्तमान में सुसज्जित हथियार <b>निकाय</b> लौटाता है।",
	"func_getWeapon_arg_1": "उस इकाई की आईडी जिसका वर्तमान हथियार लौटाया जाएगा।",
	"func_getWeapon_return": "<b>इकाई</b> पर वर्तमान में सुसज्जित हथियार की आईडी, अगर इकाई के पास कोई सुसज्जित हथियार नहीं है या इकाई मौजूद नहीं है तो शून्य।",
	"func_getWeaponArea": "<b>हथियार</b> हथियार के प्रभाव प्रकार का क्षेत्र लौटाता है।",
	"func_getWeaponArea_arg_1": "वह हथियार जिसका क्षेत्र प्रकार लौटाया जाएगा।",
	"func_getWeaponArea_return": "<b>हथियार</b> हथियार का क्षेत्र प्रकार AREA_* स्थिरांकों में से है:\n<उल>\n<li>#AREA_POINT: एकल वर्ग क्षेत्र</li>\n<li>#AREA_LASER_LINE: लेज़र की रेखा</li>\n<li>#AREA_CIRCLE_1: गोलाकार क्षेत्र 3 सेल व्यास में</li>\n<li>#AREA_CIRCLE_2: गोलाकार क्षेत्रफल 5 वर्ग व्यास में</li>\n<li>#AREA_CIRCLE_3: गोलाकार क्षेत्रफल 7 वर्ग व्यास में</li>\n</ul>",
	"func_getWeaponCost": "<b>हथियार</b> हथियार की TP लागत लौटाता है।",
	"func_getWeaponCost_arg_1": "हथियार की आईडी जिसकी कीमत वापस की जाएगी।",
	"func_getWeaponCost_return": "<b>हथियार</b> हथियार की TP लागत।",
	"func_getWeaponEffectiveArea": "उन सेल की सूची लौटाता है जो प्रभावित होंगे यदि हथियार <b>हथियार</b> सेल <b>सेल</b> पर सेल <b>से</b> से उपयोग किया जाता है।",
	"func_getWeaponEffectiveArea_arg_1": "परीक्षण करने का हथियार।",
	"func_getWeaponEffectiveArea_arg_2": "लक्ष्य सेल।",
	"func_getWeaponEffectiveArea_arg_3": "वह कोठरी जिससे हथियार चलाया जाता है।",
	"func_getWeaponEffectiveArea_return": "प्रभावित होने वाले सभी सेल की आईडी वाली सरणी।",
	"func_getWeaponPassiveEffects": "हथियार <b>हथियार</b> के निष्क्रिय प्रभाव लौटाता है।",
	"func_getWeaponPassiveEffects_arg_1": "उस हथियार की आईडी जिसका निष्क्रिय प्रभाव लौटाया जाएगा।",
	"func_getWeaponPassiveEffects_return": "<b>हथियार</b> हथियार के प्रभाव वाली एक सरणी। प्रत्येक प्रभाव अपने आप में प्रपत्र की एक सरणी है\n[प्रकार, न्यूनतम, अधिकतम, मोड़, लक्ष्य, संशोधक]। ये प्रभाव वही हैं जो #getWeaponEffects द्वारा लौटाए गए हैं।",
	"func_getWeaponFailure": "<b>हथियार</b> हथियार के विफल होने की संभावना का प्रतिशत लौटाता है।",
	"func_getWeaponFailure_arg_1": "हथियार की आईडी जिसकी विफलता प्रतिशत लौटा दी जाएगी।",
	"func_getWeaponFailure_return": "<b>हथियार</b> हथियार चूक प्रतिशत, <b>0</b> और <b>100</b> के बीच एक पूर्णांक।",
	"func_getWeaponMaxRange": "<b>हथियार</b> हथियार की अधिकतम रेंज लौटाता है।",
	"func_getWeaponMaxRange_arg_1": "उस हथियार की आईडी जिसकी अधिकतम सीमा लौटाई जाएगी।",
	"func_getWeaponMaxRange_return": "<b>हथियार</b> हथियार की अधिकतम सीमा।",
	"func_getWeaponMaxScope": "<b>हथियार</b> हथियार की अधिकतम रेंज लौटाता है।",
	"func_getWeaponMaxScope_arg_1": "उस हथियार की आईडी जिसकी अधिकतम सीमा लौटाई जाएगी।",
	"func_getWeaponMaxScope_return": "<b>हथियार</b> हथियार की अधिकतम सीमा।",
	"func_getWeaponMinRange": "<b>हथियार</b> हथियार की न्यूनतम रेंज लौटाता है।",
	"func_getWeaponMinRange_arg_1": "उस हथियार की आईडी जिसकी न्यूनतम सीमा लौटा दी जाएगी।",
	"func_getWeaponMinRange_return": "<b>हथियार</b> हथियार की न्यूनतम सीमा।",
	"func_getWeaponMinScope": "<b>हथियार</b> हथियार की न्यूनतम रेंज लौटाता है।",
	"func_getWeaponMinScope_arg_1": "उस हथियार की आईडी जिसकी न्यूनतम सीमा लौटा दी जाएगी।",
	"func_getWeaponMinScope_return": "<b>हथियार</b> हथियार की न्यूनतम सीमा।",
	"func_getWeaponLaunchType": "LAUNCH_TYPE_* स्थिरांक के बीच, <b>हथियार</b> हथियार का लॉन्च मोड लौटाता है।",
	"func_getWeaponLaunchType_arg_1": "उस हथियार की आईडी जिसका थ्रो मोड लौटाया जाएगा। आपके वर्तमान में सुसज्जित हथियार के लिए चूक।",
	"func_getWeaponLaunchType_return": "<b>हथियार</b> हथियार का थ्रो मोड।",
	"func_getWeaponName": "<b>हथियार</b> हथियार का नाम लौटाता है।",
	"func_getWeaponName_arg_1": "हथियार की आईडी जिसका नाम लौटाया जाएगा।",
	"func_getWeaponName_return": "<b>हथियार</b> हथियार का नाम।",
	"func_getWeapons": "आईडी <b>इकाई</b> के साथ इकाई के हथियार लौटाता है।",
	"func_getWeapons_arg_1": "उस इकाई की आईडी जिसके हथियार लौटाए जाएंगे।",
	"func_getWeapons_return": "<b>निकाय</b> हथियार आईडी वाली एक सरणी।",
	"func_getWeaponTargets": "सेल <b>सेल</b> पर हथियार <b>हथियार</b> का उपयोग करने पर प्रभावित होने वाली संस्थाओं को लौटाता है।",
	"func_getWeaponTargets_arg_1": "परीक्षण करने का हथियार।",
	"func_getWeaponTargets_arg_2": "लक्ष्य सेल।",
	"func_getWeaponTargets_return": "प्रभावित होने वाली सभी संस्थाओं की आईडी वाली सरणी।",
	"func_getWisdom": "आईडी <b>इकाई</b> के साथ इकाई का ज्ञान लौटाता है। अपने ज्ञान को पुनः प्राप्त करने के लिए बिना किसी पैरामीटर के getWisdom() का उपयोग करें।",
	"func_getWisdom_arg_1": "उस इकाई की आईडी जिसका ज्ञान लौटाया जाएगा।",
	"func_getWisdom_return": "आईडी <b>इकाई</b> के साथ इकाई का ज्ञान।",
	"func_hypot": "X और y भुजाओं वाले समकोण त्रिभुज का कर्ण लौटाता है। <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i> के बराबर।",
	"func_hypot_arg_1": "एक्स-वैल्यू।",
	"func_hypot_arg_2": "Y-मान।",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>।",
	"func_inArray": "निर्धारित करता है कि <b>तत्व</b> तत्व <b>सरणी</b> सरणी में है या नहीं।",
	"func_inArray_arg_1": "खोज तालिका।",
	"func_inArray_arg_2": "खोजने के लिए आइटम।",
	"func_inArray_return": "<i>true</i> यदि तत्व सरणी में है, <i>false</i> अन्यथा।",
	"func_include": "वर्तमान AI में नाम <b>ai</b> के साथ AI शामिल करें। <br><br><b>चेतावनी</b>, शामिल फ़ंक्शन को केवल मुख्य ब्लॉक में ही कॉल किया जाना चाहिए, और इसके पैरामीटर को सीधे लिखी गई स्ट्रिंग होना चाहिए\nकोड में। अधिक जानकारी के लिए, ट्यूटोरियल में अनुभाग देखें: <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a>।",
	"func_include_arg_1": "शामिल करने के लिए एआई का नाम।",
	"func_indexOf": "स्थिति <b>प्रारंभ</b> से शुरू करते हुए, स्ट्रिंग <b>स्ट्रिंग</b> में स्ट्रिंग <b>खोज</b> की पहली घटना की स्थिति निर्धारित करता है।",
	"func_indexOf_arg_1": "स्ट्रिंग जहां खोज की जाएगी।",
	"func_indexOf_arg_2": "खोजने के लिए स्ट्रिंग।",
	"func_indexOf_arg_3": "खोज की प्रारंभिक स्थिति।",
	"func_indexOf_return": "<b>प्रारंभ</b> से <b>string</b> में <b>खोज</b> की पहली घटना की स्थिति, -1 अगर स्ट्रिंग नहीं मिली।",
	"func_insert": "स्थिति <b>स्थिति</b> पर <b>सरणी</b> में एक <b>तत्व</b> तत्व सम्मिलित करता है।",
	"func_insert_arg_1": "सरणी जहां तत्व डाला जाएगा।",
	"func_insert_arg_2": "डालने की वस्तु।",
	"func_insert_arg_3": "सम्मिलन की स्थिति।",
	"func_isAlive": "यह निर्धारित करता है कि कोई <b>निकाय</b> जीवित है या नहीं। <i>getLife(<b>entity</b>) > 0</i> के समतुल्य।",
	"func_isAlive_arg_1": "परीक्षण करने के लिए इकाई की आईडी।",
	"func_isAlive_return": "<i>true</i> यदि <b>निकाय</b> जीवित है, <i>false</i> यदि मृत है।",
	"func_isAlly": "तय करता है कि <b>इकाई</b> आपकी सहयोगी है या नहीं।",
	"func_isAlly_arg_1": "परीक्षण करने के लिए इकाई की आईडी।",
	"func_isAlly_return": "<i>सही</i> अगर <b>इकाई</b> आपकी सहयोगी है या आप खुद, <i>गलत</i> अगर यह दुश्मन है।",
	"func_isChip": "निर्धारित करता है कि कोई मान एक चिप का प्रतिनिधित्व करने वाला एक स्थिरांक है या नहीं।<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false।",
	"func_isChip_arg_1": "संख्या निर्धारित की जानी है।",
	"func_isChip_return": "<i>true</i> यदि मान एक चिप स्थिरांक है।",
	"func_isDead": "निर्धारित करता है कि क्या <b>निकाय</b> मृत है। <i>getLife(<b>entity</b>) == 0</i> के समतुल्य।",
	"func_isDead_arg_1": "परीक्षण करने के लिए इकाई की आईडी।",
	"func_isDead_return": "<i>true</i> यदि <b>निकाय</b> मृत है, <i>false</i> यदि जीवित है।",
	"func_isEmpty": "निर्धारित करता है कि क्या सरणी <b>सरणी</b> खाली है। <i>गिनती(<b>सरणी</b>) == 0</i> के बराबर।",
	"func_isEmpty_arg_1": "परीक्षण करने के लिए सरणी।",
	"func_isEmptyCell": "निर्धारित करता है कि कोई सेल खाली है या नहीं।",
	"func_isEmptyCell_arg_1": "सेल का परीक्षण किया जाना है।",
	"func_isEmptyCell_return": "<i>true</i> यदि सेल खाली है, <i>false</i> अन्यथा।",
	"func_isEmpty_return": "<i>true</i> अगर सरणी खाली है, <i>false</i> अन्यथा।",
	"func_isEnemy": "निर्धारित करता है कि क्या <b>निकाय</b> आपका शत्रु है।",
	"func_isEnemy_arg_1": "परीक्षण करने के लिए इकाई की आईडी।",
	"func_isEnemy_return": "<i>सही</i> अगर <b>इकाई</b> दुश्मन है, <i>गलत</i> अगर वह सहयोगी है या आप।",
	"func_isInlineChip": "निर्धारित करता है कि <b>चिप</b> का उपयोग केवल ऑनलाइन किया जा सकता है या नहीं।",
	"func_isInlineChip_arg_1": "परीक्षण की जाने वाली चिप की आईडी।",
	"func_isInlineChip_return": "<i>सही</i> यदि चिप केवल-ऑनलाइन है, अन्यथा <i>गलत</i>।",
	"func_isInlineWeapon": "निर्धारित करता है कि <b>हथियार</b> हथियार का उपयोग केवल ऑनलाइन ही किया जा सकता है या नहीं।",
	"func_isInlineWeapon_arg_1": "परीक्षण करने के लिए हथियार की आईडी।",
	"func_isInlineWeapon_return": "<i>सच</i> अगर हथियार का इस्तेमाल केवल ऑनलाइन किया जा सकता है, <i>गलत</i> अन्यथा।",
	"func_isLeek": "यह निर्धारित करता है कि सेल <b>सेल</b> की सामग्री एक इकाई है या नहीं।",
	"func_isLeek_arg_1": "सेल का परीक्षण किया जाना है।",
	"func_isLeek_return": "<i>सही</i> अगर सेल में एक इकाई है, <i>गलत</i> अन्यथा।",
	"func_isEntity": "यह निर्धारित करता है कि सेल <b>सेल</b> की सामग्री एक इकाई है या नहीं।",
	"func_isEntity_arg_1": "सेल का परीक्षण किया जाना है।",
	"func_isEntity_return": "<i>सही</i> अगर सेल में एक इकाई है, <i>गलत</i> अन्यथा।",
	"func_isObstacle": "निर्धारित करता है कि सेल <b>सेल</b> की सामग्री एक बाधा है या नहीं।",
	"func_isObstacle_arg_1": "सेल का परीक्षण किया जाना है।",
	"func_isObstacle_return": "<i>सही</i> अगर सेल में कोई बाधा है, तो <i>गलत</i> अन्यथा।",
	"func_isOnSameLine": "निर्धारित करता है कि क्या दो सेल <b>सेल1</b> और <b>सेल2</b> एक ही लाइन पर हैं।",
	"func_isOnSameLine_arg_1": "पहली कोशिका।",
	"func_isOnSameLine_arg_2": "दूसरी कोशिका।",
	"func_isOnSameLine_return": "<i>true</i> यदि दो सेल एक ही लाइन पर हों, <i>false</i> अन्यथा।",
	"func_isStatic": "लौटाता है कि <b>निकाय</b> स्थिर है या नहीं। एक स्थिर इकाई को स्थानांतरित या स्थानांतरित नहीं किया जा सकता है।",
	"func_isStatic_arg_1": "परीक्षण करने के लिए इकाई की आईडी।",
	"func_isStatic_return": "<i>true</i> यदि <b>निकाय</b> स्थिर है, <i>false</i> अन्यथा।",
	"func_isSummon": "लौटाता है कि <b>निकाय</b> मंगलाचरण है या नहीं।",
	"func_isSummon_arg_1": "परीक्षण करने के लिए इकाई की आईडी।",
	"func_isSummon_return": "<i>सच</i> अगर <b>इकाई</b> एक मंगलाचरण है, <i>गलत</i> अन्यथा।",
	"func_isWeapon": "यह निर्धारित करता है कि क्या कोई मान एक हथियार का प्रतिनिधित्व करने वाला स्थिरांक है।<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false।",
	"func_isWeapon_arg_1": "संख्या निर्धारित की जाए।",
	"func_isWeapon_return": "<i>true</i> यदि मान एक शस्त्र स्थिरांक है।",
	"func_join": "एकाधिक तत्वों को एक स्ट्रिंग में मर्ज करता है, उन्हें <b>गोंद</b> सीमांकक से अलग करता है।",
	"func_join_arg_1": "विलय करने के लिए मदों की एक सरणी।",
	"func_join_arg_2": "तत्व सीमांकक।",
	"func_join_return": "मर्ज की परिणामी स्ट्रिंग।",
	"func_jsonDecode": "स्ट्रिंग <b>json</b> को LeekScript ऑब्जेक्ट (संख्या, स्ट्रिंग, सरणी...) में डिकोड करता है।",
	"func_jsonDecode_arg_1": "डीकोड करने के लिए JSON स्ट्रिंग।",
	"func_jsonDecode_return": "डीकोडेड लीकस्क्रिप्ट ऑब्जेक्ट।",
	"func_jsonEncode": "<b>ऑब्जेक्ट</b> ऑब्जेक्ट को JSON स्ट्रिंग में एन्कोड करता है।",
	"func_jsonEncode_arg_1": "JSON में एनकोड की जाने वाली वस्तु।",
	"func_jsonEncode_return": "JSON-एन्कोडेड स्ट्रिंग।",
	"func_keySort": "<b>सरणी</b> को कुंजियों द्वारा <b>क्रम</b> क्रम में क्रमित करता है।",
	"func_keySort_arg_1": "क्रमबद्ध करने के लिए सरणी।",
	"func_keySort_arg_2": "सॉर्ट क्रम: #SORT_ASC या #SORT_DESC।",
	"func_length": "<b>स्ट्रिंग</b> की लंबाई लौटाता है।",
	"func_length_arg_1": "वह स्ट्रिंग जिसकी लंबाई दी जाएगी.",
	"func_length_return": "<b>स्ट्रिंग</b> की लंबाई।",
	"func_lineOfSight": "<b>प्रारंभ</b> सेल और <b>अंत</b> सेल के बीच दृष्टि रेखा की जांच करता है, <b>उपेक्षित संस्थाओं</b> संस्थाओं को अनदेखा करता है।",
	"func_lineOfSight_arg_1": "सेल शुरू हो रही है।",
	"func_lineOfSight_arg_2": "लक्ष्य कोशिका।",
	"func_lineOfSight_arg_3": "अनदेखा करने के लिए एक इकाई या संस्थाओं की सूची।",
	"func_lineOfSight_return": "यदि दृष्टि रेखा स्पष्ट है तो <i>सत्य</i> लौटाता है।",
	"func_listen": "[entity_id, message] के रूप में पिछली संस्थाओं के कहने () की सरणी देता है।",
	"func_listen_return": "पिछले कहना () एस की सरणी।",
	"func_log": "संख्या <b>संख्या</b> के प्राकृतिक लघुगणक की गणना करता है।",
	"func_log_arg_1": "श्रेणी में एक संख्या ]0; +∞[.",
	"func_log_return": "<b>संख्या</b> का प्राकृतिक लघुगणक।",
	"func_log10": "संख्या <b>संख्या</b> के आधार 10 लघुगणक की गणना करता है।",
	"func_log10_arg_1": "श्रेणी में एक संख्या ]0; +∞[.",
	"func_log10_return": "<b>संख्या</b> का आधार 10 लघुगणक।",
	"func_log2": "संख्या <b>संख्या</b> के आधार 2 लघुगणक की गणना करता है।",
	"func_log2_arg_1": "श्रेणी में एक संख्या ]0; +∞[.",
	"func_log2_return": "<b>संख्या</b> का आधार 2 लघुगणक।",
	"func_mark": "पैरामीटर में दर्शाए गए घुमावों की संख्या के लिए ग्राउंड पर पैरामीटर में दर्शाए गए रंग के एक या अधिक सेलों को चिह्नित करता है। यह चिह्न केवल इकाई के प्रजनक को दिखाई देता है।",
	"func_mark_arg_1": "चिह्नित करने के लिए एकाधिक सेल का सेल या सरणी",
	"func_mark_arg_2": "अंकन रंग",
	"func_mark_arg_3": "अंकन की अवधि",
	"func_mark_return": "अगर सब कुछ ठीक रहा तो सच हो जाता है",
	"func_markText": "पैरामीटर में संकेतित घुमावों की संख्या के लिए ग्राउंड पर पैरामीटर में दर्शाए गए रंग के एक या अधिक सेल पर टेक्स्ट लिखता है। ये ग्रंथ केवल इकाई के ब्रीडर को दिखाई दे रहे हैं।",
	"func_markText_arg_1": "लिखने के लिए एकाधिक सेल का सेल या सरणी",
	"func_markText_arg_2": "लिखने के लिए पाठ (अधिकतम 10 वर्ण)",
	"func_markText_arg_3": "पाठ का रंग",
	"func_markText_arg_4": "पाठ की अवधि",
	"func_markText_return": "अगर सब कुछ ठीक रहा तो सच हो जाता है",
	"func_max": "दो संख्याओं <b>a</b> और <b>b</b> के बीच बड़ा मान लौटाता है।",
	"func_max_arg_1": "एक संख्या।",
	"func_max_arg_2": "एक संख्या।",
	"func_max_return": "<b>a</b> और <b>b</b> के बीच की बड़ी संख्या।",
	"func_min": "दो संख्याओं <b>a</b> और <b>b</b> के बीच छोटा मान लौटाता है।",
	"func_min_arg_1": "एक संख्या।",
	"func_min_arg_2": "एक संख्या।",
	"func_min_return": "<b>a</b> और <b>b</b> के बीच छोटी संख्या।",
	"func_moveAwayFrom": "अधिकतम <b>mp</b> संचलन बिंदुओं का उपयोग करके आपकी इकाई को दूसरी <b>इकाई</b> से दूर ले जाता है।",
	"func_moveAwayFrom_arg_1": "वह इकाई जिससे आपकी इकाई को दूर जाना चाहिए।",
	"func_moveAwayFrom_arg_2": "उपयोग करने के लिए एमपी की अधिकतम संख्या। डिफ़ॉल्ट रूप से, कोई सीमा नहीं।",
	"func_moveAwayFrom_return": "उपयोग किए जाने वाले आंदोलन बिंदुओं की संख्या।",
	"func_moveAwayFromCell": "अप <b>mp</b> संचलन बिंदुओं का उपयोग करते हुए, अपनी इकाई को एक <b>सेल</b> सेल दूर ले जाता है।",
	"func_moveAwayFromCell_arg_1": "वह सेल जिससे आपकी इकाई को दूर जाना चाहिए।",
	"func_moveAwayFromCell_arg_2": "उपयोग करने के लिए एमपी की अधिकतम संख्या। डिफ़ॉल्ट रूप से, कोई सीमा नहीं।",
	"func_moveAwayFromCell_return": "उपयोग किए जाने वाले आंदोलन बिंदुओं की संख्या।",
	"func_moveAwayFromCells": "अधिकतम <b>mp</b> संचलन बिंदुओं का उपयोग करके, आपकी इकाई को <b>सेल</b> सेल के सेट से दूर ले जाता है।",
	"func_moveAwayFromCells_arg_1": "वह सरणी जिसमें वे कक्ष हैं जिनसे आपकी इकाई को दूर जाना चाहिए।",
	"func_moveAwayFromCells_arg_2": "उपयोग करने के लिए एमपी की अधिकतम संख्या। डिफ़ॉल्ट रूप से, कोई सीमा नहीं।",
	"func_moveAwayFromCells_return": "उपयोग किए जाने वाले आंदोलन बिंदुओं की संख्या।",
	"func_moveAwayFromLeeks": "अधिकतम <b>mp</b> संचलन बिंदुओं का उपयोग करके, अपनी इकाई को <b>इकाइयों</b> के सेट से दूर ले जाता है।",
	"func_moveAwayFromLeeks_arg_1": "आपकी इकाई को जिन संस्थाओं से दूर जाना चाहिए, उनकी आईडी वाली सरणी।",
	"func_moveAwayFromLeeks_arg_2": "उपयोग करने के लिए एमपी की अधिकतम संख्या। डिफ़ॉल्ट रूप से, कोई सीमा नहीं।",
	"func_moveAwayFromLeeks_return": "उपयोग किए जाने वाले आंदोलन बिंदुओं की संख्या।",
	"func_moveAwayFromEntities": "अधिकतम <b>mp</b> संचलन बिंदुओं का उपयोग करके, अपनी इकाई को <b>इकाइयों</b> के सेट से दूर ले जाता है।",
	"func_moveAwayFromEntities_arg_1": "आपकी इकाई को जिन संस्थाओं से दूर जाना चाहिए, उनकी आईडी वाली सरणी।",
	"func_moveAwayFromEntities_arg_2": "उपयोग करने के लिए एमपी की अधिकतम संख्या। डिफ़ॉल्ट रूप से, कोई सीमा नहीं।",
	"func_moveAwayFromEntities_return": "प्रयुक्त आंदोलन बिंदुओं की संख्या।",
	"func_moveAwayFromLine": "अधिकतम <b>mp</b> संचलन बिंदुओं का उपयोग करते हुए, आपकी इकाई को दो सेल <b>सेल1</b> और <b>सेल2</b> द्वारा परिभाषित रेखा से दूर ले जाता है।",
	"func_moveAwayFromLine_arg_1": "सेल 1.",
	"func_moveAwayFromLine_arg_2": "सेल 2.",
	"func_moveAwayFromLine_arg_3": "उपयोग करने के लिए एमपी की अधिकतम संख्या। डिफ़ॉल्ट रूप से, कोई सीमा नहीं।",
	"func_moveAwayFromLine_return": "उपयोग किए जाने वाले आंदोलन बिंदुओं की संख्या।",
	"func_moveToward": "अधिकतम <b>mp</b> संचलन बिंदुओं का उपयोग करके आपकी इकाई को किसी अन्य <b>इकाई</b> के करीब लाता है।",
	"func_moveToward_arg_1": "आपकी इकाई को जिस इकाई से संपर्क करना चाहिए।",
	"func_moveToward_arg_2": "उपयोग करने के लिए एमपी की अधिकतम संख्या।",
	"func_moveToward_return": "उपयोग किए जाने वाले आंदोलन बिंदुओं की संख्या।",
	"func_moveTowardCell": "अधिकतम <b>mp</b> संचलन बिंदुओं का उपयोग करते हुए, आपकी इकाई को एक <b>सेल</b> सेल करीब लाता है।",
	"func_moveTowardCell_arg_1": "वह सेल जिस तक आपकी सुविधा पहुंचनी चाहिए।",
	"func_moveTowardCell_arg_2": "उपयोग करने के लिए एमपी की अधिकतम संख्या। डिफ़ॉल्ट रूप से, कोई सीमा नहीं।",
	"func_moveTowardCell_return": "उपयोग किए जाने वाले आंदोलन बिंदुओं की संख्या।",
	"func_moveTowardCells": "अधिकतम <b>mp</b> संचलन बिंदुओं का उपयोग करते हुए, आपकी इकाई को <b>सेल</b> सेल के सेट के करीब ले जाता है।",
	"func_moveTowardCells_arg_1": "आपकी इकाई को जिन कक्षों तक पहुंचना चाहिए, वह सरणी।",
	"func_moveTowardCells_arg_2": "उपयोग करने के लिए एमपी की अधिकतम संख्या। डिफ़ॉल्ट रूप से, कोई सीमा नहीं।",
	"func_moveTowardCells_return": "प्रयुक्त आंदोलन बिंदुओं की संख्या।",
	"func_moveTowardLeeks": "अधिकतम <b>mp</b> संचलन बिंदुओं का उपयोग करके, आपकी इकाई को <b>इकाइयों</b> के एक सेट के करीब लाता है।",
	"func_moveTowardLeeks_arg_1": "उन संस्थाओं की आईडी वाली सरणी जिन्हें आपकी इकाई को संपर्क करना चाहिए।",
	"func_moveTowardLeeks_arg_2": "उपयोग करने के लिए एमपी की अधिकतम संख्या। डिफ़ॉल्ट रूप से, कोई सीमा नहीं।",
	"func_moveTowardLeeks_return": "उपयोग किए जाने वाले आंदोलन बिंदुओं की संख्या।",
	"func_moveTowardEntities": "अधिकतम <b>mp</b> संचलन बिंदुओं का उपयोग करके, आपकी इकाई को <b>इकाइयों</b> के एक सेट के करीब लाता है।",
	"func_moveTowardEntities_arg_1": "उन संस्थाओं की आईडी वाली सरणी जिन्हें आपकी इकाई को संपर्क करना चाहिए।",
	"func_moveTowardEntities_arg_2": "उपयोग करने के लिए एमपी की अधिकतम संख्या। डिफ़ॉल्ट रूप से, कोई सीमा नहीं।",
	"func_moveTowardEntities_return": "उपयोग किए जाने वाले आंदोलन बिंदुओं की संख्या।",
	"func_moveTowardLine": "अधिकतम <b>mp</b> संचलन बिंदुओं का उपयोग करते हुए, आपकी इकाई को दो सेल <b>सेल1</b> और <b>सेल2</b> द्वारा परिभाषित रेखा के करीब लाता है।",
	"func_moveTowardLine_arg_1": "सेल 1.",
	"func_moveTowardLine_arg_2": "सेल 2.",
	"func_moveTowardLine_arg_3": "उपयोग करने के लिए एमपी की अधिकतम संख्या। डिफ़ॉल्ट रूप से, कोई सीमा नहीं।",
	"func_moveTowardLine_return": "उपयोग किए जाने वाले आंदोलन बिंदुओं की संख्या।",
	"func_number": "मान को संख्या में बदलता है. यदि मान एक स्ट्रिंग है, तो संख्या फ़ंक्शन इसे एक संख्या में बदलने का प्रयास करेगा, यदि मान पहले से ही एक संख्या है, तो फ़ंक्शन संख्या लौटाता है, और किसी अन्य प्रकार के लिए, यह <i>null</i> लौटाता है।",
	"func_number_arg_1": "किसी संख्या में बदलने के लिए मान।",
	"func_number_return": "परिवर्तित संख्या।",
	"func_pause": "लड़ाई को रोकता है, केवल फ़ंक्शन का उपयोग करने वाली इकाई के ब्रीडर के लिए।",
	"func_pop": "<b>सरणी</b> से अंतिम सेल को निकालता है और संबंधित तत्व लौटाता है।",
	"func_pop_arg_1": "वह सरणी जिसका अंतिम बॉक्स हटा दिया जाएगा।",
	"func_pop_return": "वह वस्तु जो पुराने अंतिम डिब्बे में थी।",
	"func_pow": "आधार संख्या को घातांक की शक्ति तक बढ़ाता है।",
	"func_pow_arg_1": "आधार।",
	"func_pow_arg_2": "प्रदर्शक।",
	"func_pow_return": "<b>बेस</b> को <b>एक्सपोनेंट</b> पावर तक बढ़ाया गया।",
	"func_push": "<b>तत्व</b> तत्व को <b>सरणी</b> के अंत में जोड़ता है।",
	"func_pushAll": "<b>तत्वों</b> सरणी के सभी तत्वों को <b>सरणी</b> के अंत में जोड़ता है।",
	"func_pushAll_arg_1": "वह सरणी जहां हम तत्वों को जोड़ना चाहते हैं।",
	"func_pushAll_arg_2": "जोड़ने के लिए आइटम।",
	"func_push_arg_1": "वह सरणी जहां हम तत्व जोड़ना चाहते हैं।",
	"func_push_arg_2": "जोड़ने के लिए आइटम।",
	"func_rand": "0 (शामिल) और 1 (अनन्य) के बीच एक वास्तविक यादृच्छिक संख्या देता है।",
	"func_randFloat": "<b>a</b> (शामिल) और <b>b</b> (अनन्य) के बीच एक वास्तविक यादृच्छिक संख्या लौटाता है।",
	"func_randFloat_arg_1": "निम्न परिबंध।",
	"func_randFloat_arg_2": "ऊपरी सीमा।",
	"func_randFloat_return": "श्रेणी में एक यादृच्छिक संख्या [ए; बी[।",
	"func_randInt": "<b>a</b> (शामिल) और <b>b</b> (अनन्य) के बीच एक यादृच्छिक पूर्णांक संख्या देता है।",
	"func_randInt_arg_1": "निम्न परिबंध।",
	"func_randInt_arg_2": "ऊपरी सीमा।",
	"func_randInt_return": "श्रेणी में एक यादृच्छिक पूर्णांक [ए; बी[।",
	"func_rand_return": "श्रेणी में एक यादृच्छिक संख्या [0; 1 [।",
	"func_remove": "स्थिति <b>स्थिति</b> पर सरणी <b>सरणी</b> से एक तत्व निकालता है, और हटाए गए तत्व को लौटाता है।",
	"func_remove_arg_1": "ऐरे जिसमें से हम एक तत्व को हटाना चाहते हैं।",
	"func_remove_arg_2": "हटाए जाने वाले तत्व की स्थिति।",
	"func_removeElement": "किसी <b>तत्व</b> की <b>सरणी</b> में पहली बार आने को हटाता है। यदि तत्व नहीं मिला है, तो सरणी संशोधित नहीं है।",
	"func_removeElement_arg_1": "ऐरे जिसमें से हम एक तत्व को हटाना चाहते हैं।",
	"func_removeElement_arg_2": "खोजने और फिर हटाने के लिए आइटम।",
	"func_removeKey": "कुंजी <b>कुंजी</b> से संबद्ध सरणी <b>सरणी</b> से एक तत्व हटाता है।",
	"func_removeKey_arg_1": "ऐरे जिसमें से हम एक तत्व को हटाना चाहते हैं।",
	"func_removeKey_arg_2": "हटाए जाने वाले आइटम से संबद्ध कुंजी.",
	"func_remove_return": "तत्व सरणी से निकाला गया।",
	"func_replace": "स्ट्रिंग <b>स्ट्रिंग</b> में <b>खोज</b> की सभी घटनाओं को <b>बदलें</b> से बदल देता है।",
	"func_replace_arg_1": "स्ट्रिंग जिसमें प्रतिस्थापन किए जाते हैं।",
	"func_replace_arg_2": "बदलने के लिए सबस्ट्रिंग।",
	"func_replace_arg_3": "रिप्लेसमेंट स्ट्रिंग।",
	"func_replace_return": "परिणाम स्ट्रिंग, प्रतिस्थापन के साथ।",
	"func_resurrect": "सेल <b>सेल</b> पर आईडी <b>इकाई</b> के साथ एक मृत इकाई को पुनर्जीवित करने के लिए CHIP_RESURRECTION चिप का उपयोग करता है।",
	"func_resurrect_arg_1": "पुनर्जीवित करने के लिए इकाई की आईडी।",
	"func_resurrect_arg_2": "वह सेल जिस पर इकाई प्रतिक्रिया देगी।",
	"func_resurrect_return": "स्थिरांक USE_* के बीच चिप को लॉन्च करने का परिणाम।",
	"func_reverse": "<b>सरणी</b> को उलट देता है।",
	"func_reverse_arg_1": "उलटने के लिए टेबल।",
	"func_round": "<b>संख्या</b> का पूर्णांकन लौटाता है।<br/>आप #मंजिल के साथ नीचे का पूर्णांकन और #ceil के साथ पूर्णांकन कर सकते हैं।",
	"func_round_arg_1": "संख्या जिसका पूर्णांकन हम ज्ञात करना चाहते हैं।",
	"func_round_return": "<b>संख्या</b> द्वारा गोल किया गया।",
	"func_say": "अपनी संस्था को बोलें।",
	"func_say_arg_1": "संदेश कि आपकी संस्था अखाड़े में घोषणा करेगी।",
	"func_search": "स्थिति <b>प्रारंभ</b> से प्रारंभ करते हुए, <b>सरणी</b> सरणी में तत्व <b>तत्व</b> ढूँढता है।",
	"func_search_arg_1": "खोज तालिका।",
	"func_search_arg_2": "खोजने के लिए आइटम।",
	"func_search_arg_3": "खोज की शुरुआत की स्थिति।",
	"func_search_return": "<b>तत्व</b> तत्व की <b>सरणी</b> में पहली बार होने की स्थिति, <b>प्रारंभ</b> से शुरू होकर, शून्य अगर तत्व नहीं मिला है।",
	"func_sendAll": "अपनी पूरी टीम को संदेश भेजें।",
	"func_sendAll_arg_1": "भेजने के लिए संदेश का प्रकार (MESSAGE_* स्थिरांक देखें)।",
	"func_sendAll_arg_2": "संदेश पैरामीटर, जो कोई भी मान हो सकता है।",
	"func_sendTo": "आईडी <b>इकाई</b> के साथ इकाई को एक संदेश भेजता है।",
	"func_sendTo_arg_1": "उस इकाई की आईडी जिसे संदेश भेजा जाएगा।",
	"func_sendTo_arg_2": "भेजने के लिए संदेश का प्रकार (MESSAGE_* स्थिरांक देखें)।",
	"func_sendTo_arg_3": "संदेश पैरामीटर, जो कोई भी मान हो सकता है।",
	"func_sendTo_return": "<i>true</i> यदि प्रेषण सफल रहा, <i>false</i> यदि कोई त्रुटि हुई।",
	"func_setRegister": "<b>मान</b> को <b>कुंजी</b> रजिस्ट्री में संग्रहित करता है।\nकुंजी और मान तार हैं जिनमें क्रमशः <i>100</i> और <i>5000</i> वर्ण होने चाहिए। एक लीक में अधिकतम <i>100</i> रजिस्टर हो सकते हैं, नए रजिस्टर में स्टोर करना काम नहीं करेगा यदि सभी रजिस्टर\nपहले से ही काबिज हैं।",
	"func_setRegister_arg_1": "रजिस्ट्री कुंजी जहां मान संग्रहीत करना है।",
	"func_setRegister_arg_2": "स्टोर करने के लिए मूल्य।",
	"func_setRegister_return": "<i>true</i> अगर ऑपरेशन सफल रहा, <i>false</i> अन्यथा।",
	"func_setWeapon": "अपनी इकाई पर <b>हथियार</b> हथियार तैयार करें।",
	"func_setWeapon_arg_1": "लैस करने के लिए हथियार की आईडी।",
	"func_shift": "<b>सरणी</b> से पहला सेल हटाता है और संबंधित तत्व लौटाता है।",
	"func_shift_arg_1": "वह सरणी जिसका पहला सेल हटा दिया जाएगा।",
	"func_shift_return": "वह तत्व जो पुराने पहले सेल में था।",
	"func_show": "खिलाड़ियों को 1 मोड़ के लिए मैदान पर <b>रंग</b> रंग का <b>सेल</b> दिखाता है। इस सुविधा का उपयोग करने पर 1PT का खर्च आता है।",
	"func_show_arg_1": "दिखाने के लिए सेल",
	"func_show_arg_2": "अंकन रंग",
	"func_show_return": "अगर सब कुछ ठीक रहा तो सच हो जाता है",
	"func_shuffle": "एक बोर्ड को बेतरतीब ढंग से शफ़ल करता है।",
	"func_shuffle_arg_1": "फेरबदल बोर्ड।",
	"func_signum": "संख्या <b>संख्या</b> का चिह्न निर्धारित करता है।",
	"func_signum_arg_1": "वह संख्या जिसका चिन्ह निर्धारित किया जाएगा।",
	"func_signum_return": "1 यदि संख्या धनात्मक है, 0 यदि संख्या शून्य है, और -1 यदि संख्या ऋणात्मक है।",
	"func_sin": "पैरामीटर के रूप में पारित कोण <b>कोण</b> की साइन की गणना करता है",
	"func_sin_arg_1": "वह कोण जिसकी ज्या की गणना की जाएगी",
	"func_sin_return": "कोण <b>कोण</b> की ज्या लौटाता है",
	"func_sort": "<b>सरणी</b> को आरोही या अवरोही क्रम में व्यवस्थित करता है। डिफ़ॉल्ट आरोही क्रम है:\n<उल>\n<li>सभी बूलियन (पहले असत्य फिर सत्य)</li>\n<li>सभी नंबर (सबसे छोटे से सबसे बड़े के क्रम में)</li>\n<li>सभी चैनल (वर्णानुक्रम में)</li>\n<li>सभी अशक्त तत्व।</li>\n</ul>",
	"func_sort_arg_1": "क्रमबद्ध करने के लिए सरणी।",
	"func_sort_arg_2": "#SORT_ASC <b>array</b> को आरोही क्रम में लगाने के लिए या #SORT_DESC इसे अवरोही क्रम में लगाने के लिए।",
	"func_split": "स्ट्रिंग <b>स्ट्रिंग</b> को <b>सीमांकक</b> द्वारा सीमांकित सबस्ट्रिंग में विभाजित करता है।",
	"func_split_arg_1": "काटने की जंजीर।",
	"func_split_arg_2": "एक तत्व से दूसरे तत्व तक मार्ग को परिसीमित करने वाली स्ट्रिंग।",
	"func_split_arg_3": "सरणी तत्वों की अधिकतम संख्या।",
	"func_split_return": "मिली सबस्ट्रिंग वाली सरणी।",
	"func_sqrt": "संख्या <b>संख्या</b> के वर्गमूल की गणना करता है।",
	"func_sqrt_arg_1": "संख्या जिसकी जड़ की गणना की जाएगी।",
	"func_sqrt_return": "<b>संख्या</b> का वर्गमूल।",
	"func_startsWith": "जांचता है कि <b>स्ट्रिंग</b> <b>उपसर्ग</b> से शुरू होता है या नहीं।",
	"func_startsWith_arg_1": "खोज स्ट्रिंग।",
	"func_startsWith_arg_2": "खोजा गया उपसर्ग।",
	"func_startsWith_return": "सही है अगर <b>स्ट्रिंग</b> <b>उपसर्ग</b> से शुरू होता है।",
	"func_string": "मान को स्ट्रिंग में बदलता है.<br>\nयदि मान पहले से ही एक स्ट्रिंग है, तो इसे वापस कर दिया जाता है।<br>\nयदि मान संख्या x है, तो \"x\" दिया जाता है।<br>\nयदि मान एक सरणी है, तो फ़ॉर्म की एक स्ट्रिंग \"[key1: value1, key2: value2, ...]\" दी जाती है।<br>\nयदि मान एक बूलियन है, तो \"true\" और \"false\" क्रमशः सही और गलत के लिए दिए जाते हैं।<br>\nयदि मान शून्य है, तो \"शून्य\" लौटाया जाता है।",
	"func_string_arg_1": "वर्ण स्ट्रिंग में बदलने के लिए मान।",
	"func_string_return": "परिवर्तित स्ट्रिंग।",
	"func_subArray": "स्थिति <b>प्रारंभ</b> से शुरू होकर <b>अंत</b> स्थान पर समाप्त होने वाली <b>सरणी</b> की एक उपश्रेणी लौटाता है।",
	"func_subArray_arg_1": "स्रोत तालिका।",
	"func_subArray_arg_2": "प्रारंभिक सूचकांक।",
	"func_subArray_arg_3": "अंत सूचकांक।",
	"func_subArray_return": "उप-सरणी।",
	"func_substring": "इंडेक्स <b>प्रारंभ</b> और आकार <b>लंबाई</b> के वर्ण से प्रारंभ करते हुए स्ट्रिंग <b>स्ट्रिंग</b> का सबस्ट्रिंग लौटाता है।",
	"func_substring_arg_1": "काटने की जंजीर।",
	"func_substring_arg_2": "प्रारंभिक चरित्र सूचकांक।",
	"func_substring_arg_3": "सबस्ट्रिंग की लंबाई।",
	"func_substring_return": "सबस्ट्रिंग।",
	"func_sum": "सरणी <b>सरणी</b> के सभी संख्यात्मक तत्वों का योग लौटाता है।",
	"func_sum_arg_1": "योग करने के लिए तालिका।",
	"func_summon": "IA के लिए फ़ंक्शन <b>ai</b> वाले सेल <b>सेल</b> पर चिप <b>चिप</b> द्वारा निर्धारित इकाई को आमंत्रित करता है।",
	"func_summon_arg_1": "तलब करने के लिए इस्तेमाल की जाने वाली चिप। चिप एक समन प्रकार की चिप होनी चाहिए और उस इकाई से सुसज्जित होनी चाहिए जो समन फ़ंक्शन का उपयोग करती है।",
	"func_summon_arg_2": "सेल या सम्मन प्रकट होना चाहिए।",
	"func_summon_arg_3": "मंगलाचरण एआई, एक समारोह के रूप में।",
	"func_summon_return": "सम्मन फ़ंक्शन में #useChip फ़ंक्शन के समान रिटर्न होता है।",
	"func_sum_return": "सरणी तत्वों का योग।",
	"func_tan": "कोण <b>कोण</b> (रेडियन में) की स्पर्शज्या की गणना करता है।",
	"func_tan_arg_1": "रेडियन में कोण।",
	"func_tan_return": "<b>कोण</b> की स्पर्शरेखा।",
	"function_category_array": "सूचियों",
	"function_category_chip": "पिस्सू",
	"function_category_color": "रंग",
	"function_category_fight": "झगड़ा करना",
	"function_category_leek": "लीक",
	"function_category_map": "टेबल",
	"function_category_field": "ज़मीन",
	"function_category_math": "नंबर",
	"function_category_network": "नेटवर्क",
	"function_category_string": "चेन",
	"function_category_utils": "उपयोगिताओं",
	"function_category_weapon": "हथियार, शस्त्र",
	"func_toDegrees": "कोण <b>रेडियन</b> को डिग्री में बदलता है.",
	"func_toDegrees_arg_1": "रेडियन में मान",
	"func_toDegrees_return": "डिग्री में कोण।",
	"func_toLower": "<b>स्ट्रिंग</b> के अपरकेस वर्णों को लोअरकेस में बदलता है.",
	"func_toLower_arg_1": "बदलने के लिए स्ट्रिंग",
	"func_toLower_return": "लोअरकेस स्ट्रिंग",
	"func_toRadians": "कोण <b>डिग्री</b> को रेडियन में बदलता है.",
	"func_toRadians_arg_1": "डिग्री में मूल्य",
	"func_toRadians_return": "रेडियन में कोण।",
	"func_toUpper": "<b>स्ट्रिंग</b> के लोअरकेस वर्णों को अपरकेस में बदलता है.",
	"func_toUpper_arg_1": "बदलने के लिए स्ट्रिंग।",
	"func_toUpper_return": "अपरकेस स्ट्रिंग।",
	"func_typeOf": "प्रकार के बीच <b>मान</b> मान का प्रकार लौटाता है: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION।",
	"func_typeOf_arg_1": "वह मान जिसका प्रकार लौटाया जाएगा।",
	"func_typeOf_return": "<b>वैल्यू</b> का प्रकार।",
	"func_unshift": "<b>तत्व</b> तत्व को <b>सरणी</b> की शुरुआत में जोड़ता है।",
	"func_unshift_arg_1": "ऐरे जिसमें तत्व जोड़ा जाएगा।",
	"func_unshift_arg_2": "जोड़ने के लिए आइटम।",
	"func_useChip": "इकाई <b>निकाय</b> पर चिप <b>चिप</b> का उपयोग करें।",
	"func_useChip_arg_1": "उपयोग करने के लिए चिप।",
	"func_useChip_arg_2": "लक्ष्य निकाय।",
	"func_useChipOnCell": "सेल <b>सेल</b> पर चिप <b>चिप</b> का प्रयोग करें।",
	"func_useChipOnCell_arg_1": "उपयोग करने के लिए चिप।",
	"func_useChipOnCell_arg_2": "लक्ष्य कोशिका।",
	"func_useChipOnCell_return": "यदि हमला शुरू किया गया था तो 0 से अधिक मान।",
	"func_useChip_return": "UseChip के वापसी मूल्य हैं:<br>\n<उल>\n<li>#USE_CRITICAL, गंभीर हिट पर</li>\n<li>#USE_SUCCESS, सफल होने पर</li>\n<li>#USE_FAILED, विफल होने पर</li>\n<li>#USE_INVALID_TARGET, यदि लक्ष्य मौजूद नहीं है</li>\n<li>#USE_NOT_ENOUGH_TP, यदि आपकी इकाई के पास पर्याप्त TP नहीं है</li>\n<li>#USE_INVALID_COOLDOWN, यदि चिप अभी तक प्रयोग करने योग्य नहीं है</li>\n<li>#USE_INVALID_POSITION, यदि सीमा खराब है या दृष्टि रेखा स्पष्ट नहीं है</li>\n</ul>",
	"func_useWeapon": "<b>निकाय</b> पर चयनित हथियार का उपयोग करें।",
	"func_useWeapon_arg_1": "लक्ष्य निकाय।",
	"func_useWeaponOnCell": "<b>सेल</b> सेल पर चयनित हथियार का प्रयोग करें।",
	"func_useWeaponOnCell_arg_1": "लक्ष्य कोशिका।",
	"func_useWeaponOnCell_return": "यदि हमला शुरू किया गया था तो 0 से अधिक मान।",
	"func_useWeapon_return": "UseWeapon का रिटर्न वैल्यू है:<br>\n<उल>\n<li>#USE_CRITICAL, गंभीर हिट पर</li>\n<li>#USE_SUCCESS, सफल होने पर</li>\n<li>#USE_FAILED, विफल होने पर</li>\n<li>#USE_INVALID_TARGET, यदि लक्ष्य मौजूद नहीं है</li>\n<li>#USE_NOT_ENOUGH_TP, यदि आपकी इकाई के पास पर्याप्त TP नहीं है</li>\n<li>#USE_INVALID_POSITION, यदि सीमा खराब है या दृष्टि रेखा स्पष्ट नहीं है</li>\n</ul>",
	"func_weaponNeedLos": "लौटाता है कि क्या <b>हथियार</b> हथियार को फायर करने के लिए लाइन ऑफ़ साइट की ज़रूरत है या नहीं।",
	"func_weaponNeedLos_arg_1": "परीक्षण करने के लिए हथियार की आईडी।",
	"func_weaponNeedLos_return": "<i>सच</i> अगर <b>हथियार</b> हथियार को दागने के लिए लाइन ऑफ साइट की जरूरत है, <i>गलत</i> अन्यथा।",
	"items": "सामान",
	"max_cores": "अधिकतम",
	"max_level": "अधिकतम",
	"min_cores": "न्यूनतम दिल",
	"min_level": "न्यूनतम स्तर",
	"n_operations": "<b>{0}</b> संचालन",
	"parameters": "समायोजन",
	"return": "प्रतिक्रिया",
	"value": "कीमत",
	"variable_operations": "<b>परिवर्तनीय</b> संचालन"
}

	i18n.mergeLocaleMessage("hi", { "doc": messages })
	