
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> handling",
	"arg_type_-1": "?",
	"arg_type_1": "nummer",
	"arg_type_2": "kæde",
	"arg_type_3": "boolesk",
	"arg_type_4": "liste",
	"arg_type_41": "liste<nummer>",
	"arg_type_42": "liste<streng>",
	"arg_type_43": "liste<boolesk>",
	"arg_type_44": "liste<liste>",
	"arg_type_46": "liste<heltal>",
	"arg_type_47": "liste<rigtige>",
	"arg_type_5": "fungere",
	"arg_type_6": "hel",
	"arg_type_7": "ægte",
	"arg_type_8": "bord",
	"arg_type_806": "tabel<?, heltal>",
	"complexity": "Kompleksitet {c}",
	"operations": "{o} operationer",
	"const_AREA_CIRCLE_1": "Cirkulært område 3 firkanter i diameter (kryds).",
	"const_AREA_CIRCLE_2": "Cirkulært område 5 firkanter i diameter.",
	"const_AREA_CIRCLE_3": "Cirkulært område 7 firkanter i diameter.",
	"const_AREA_LASER_LINE": "Zone af en laser, linje fra laserens minimumsområde til dens maksimale rækkevidde eller en forhindring.",
	"const_AREA_POINT": "Område bestående af en enkelt celle.",
	"const_CELL_EMPTY": "Returner værdien af getCellContent(celle) for en tom celle.",
	"const_CELL_OBSTACLE": "Returner værdien af getCellContent(celle) for en celle, der indeholder en forhindring.",
	"const_CELL_PLAYER": "Returner værdien af getCellContent(celle) for en celle, der indeholder en enhed.",
	"const_CELL_ENTITY": "Returner værdien af getCellContent(celle) for en celle, der indeholder en enhed.",
	"const_COLOR_BLUE": "Farve blå.",
	"const_COLOR_GREEN": "Grøn farve.",
	"const_COLOR_RED": "Rød farve.",
	"const_E": "Eulers nummer.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Giver absolut skjold til en enhed, hvilket reducerer antallet af hitpoint, der er fjernet ved skade (EFFECT_DAMAGE) med et fast beløb. Forstærkes af modstand.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Fjerner absolut skjold fra en enhed. Forstærkes ikke af en karakteristik. Giver dig mulighed for at øge hit-point, der er fjernet ved skade (EFFECT_DAMAGE) med et absolut beløb.",
	"const_EFFECT_AFTEREFFECT": "Fjerner hitpoint fra en enhed. Forstærket af videnskab. Reducerer maksimale hitpoints med 5 % af mængden af fjernede hitpoint.",
	"const_EFFECT_ANTIDOTE": "Fjerner al gift (EFFECT_POISON), der er til stede på et mål.",
	"const_EFFECT_BOOST_MAX_LIFE": "Øger hitpoint og maksimum hitpoint for en enhed. Forstærket af visdom.",
	"const_EFFECT_BUFF_AGILITY": "Giver smidighed til en enhed. Forstærket af videnskab.",
	"const_EFFECT_BUFF_MP": "Giver bevægelsespoint til en enhed. Forstærket af videnskab.",
	"const_EFFECT_BUFF_RESISTANCE": "Giver modstand til en enhed. Forstærket af videnskab.",
	"const_EFFECT_BUFF_STRENGTH": "Giver styrke til en enhed. Forstærket af videnskab.",
	"const_EFFECT_BUFF_TP": "Tildeler handlingspunkter til en enhed. Forstærket af videnskab.",
	"const_EFFECT_BUFF_WISDOM": "Giver visdom til en enhed. Forstærket af videnskab.",
	"const_EFFECT_DAMAGE": "Fjerner hitpoint fra en enhed. Force Amplified. Interagerer med skjolde (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), lifesteal (undtagen caster) og skaderetur (EFFECT_DAMAGE_RETURN). Reducerer maksimale hitpoints med 5 % af mængden af fjernede hitpoint.",
	"const_EFFECT_DAMAGE_RETURN": "Giver skaderefleksion til en enhed, hvilket gør det muligt at fjerne hitpoints fra enheder, der påfører modtageren skade. Forstærket af smidighed. Reducerer maksimale hitpoints med 5 % af mængden af fjernede hitpoint.",
	"const_EFFECT_DEBUFF": "Reducerer værdien af alle effekter på en enhed med en procentdel.",
	"const_EFFECT_HEAL": "Gendanner hitpoint til en enhed, begrænset af de maksimale hitpoints. Forstærket af visdom.",
	"const_EFFECT_INVERT": "Skifter positionen af hjulet med en enheds position.",
	"const_EFFECT_KILL": "Fjerner alle hitpunkter fra en enhed.",
	"const_EFFECT_LIFE_DAMAGE": "Fjerner hitpoint fra en enhed baseret på en procentdel af casterens hitpoint. Interagerer med skjolde (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) og skadesretur (EFFECT_DAMAGE_RETURN). Reducerer maksimale hitpoints med 5 % af mængden af fjernede hitpoint.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "Effekten ganges med antallet af berørte enheder i området.",
	"const_EFFECT_MODIFIER_ON_CASTER": "Effekten påvirker stadig casteren.",
	"const_EFFECT_MODIFIER_STACKABLE": "Effekten er kumulativ.",
	"const_EFFECT_NOVA_DAMAGE": "Fjerner max livspoint. Forstærket af videnskab.",
	"const_EFFECT_POISON": "Fjerner hitpoint fra en enhed. Forstærket af magi. Reducerer maksimale hitpoint med 10 % af mængden af fjernede hitpoint.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Giver absolut skjold til en enhed, hvilket reducerer antallet af hitpoint, der er fjernet ved skade (EFFECT_DAMAGE) med et fast beløb. Kan ikke forstærkes.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Giver smidighed til en enhed. Kan ikke forstærkes.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Giver magi til en enhed. Kan ikke forstærkes.",
	"const_EFFECT_RAW_BUFF_MP": "Giver bevægelsespoint til en enhed. Kan ikke forstærkes.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Giver videnskab til en enhed. Kan ikke forstærkes.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Giver styrke til en enhed. Kan ikke forstærkes.",
	"const_EFFECT_RAW_BUFF_TP": "Tildeler handlingspunkter til en enhed. Kan ikke forstærkes.",
	"const_EFFECT_RELATIVE_SHIELD": "Giver et relativt skjold, der reducerer antallet af hitpoints, der fjernes ved skade (EFFECT_DAMAGE) med et relativt beløb. Forstærkes af modstand.",
	"const_EFFECT_RESURRECT": "Genopliver en enhed med et maksimalt HP-tal svarende til halvdelen af entitetens maksimale HP-tal før genopstandelse og et nuværende HP-tal svarende til en fjerdedel af det maksimale HP-tal før genopstandelse.",
	"const_EFFECT_SHACKLE_MAGIC": "Fjern magi fra en enhed. Forstærket af magi.",
	"const_EFFECT_SHACKLE_MP": "Fjern bevægelsespunkter fra en enhed. Forstærket af magi.",
	"const_EFFECT_SHACKLE_STRENGTH": "Fjerner styrke fra en enhed. Forstærket af magi.",
	"const_EFFECT_SHACKLE_TP": "Træk handlingspunkter fra en enhed. Forstærket af magi.",
	"const_EFFECT_SUMMON": "Tilkalder en pære. Ingen effekt, hvis teamindkaldelsesgrænsen er nået.",
	"const_EFFECT_TARGET_ALLIES": "Påvirker allierede.",
	"const_EFFECT_TARGET_CASTER": "Påvirker hjulet.",
	"const_EFFECT_TARGET_ENEMIES": "Påvirker fjender.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Påvirker ikke-indkaldte enheder (porrer og tårne).",
	"const_EFFECT_TARGET_SUMMONS": "Påvirker tilkaldte enheder (pærer).",
	"const_EFFECT_TELEPORT": "Ændrer hjulets position.",
	"const_EFFECT_VULNERABILITY": "Fjerner skjold i forhold til en enhed. Forstærkes ikke af en karakteristik. Øger hitpoint, der er fjernet ved skade (EFFECT_DAMAGE) med et relativt beløb.",
	"const_ENTITY_BULB": "Refererer til en enhed af pæretypen.",
	"const_ENTITY_LEEK": "Refererer til en enhed af porretypen.",
	"const_ENTITY_TURRET": "Refererer til en enhed af typen Turret.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Kampbaggrund af udfordringstype.",
	"const_FIGHT_CONTEXT_GARDEN": "Kontekst af kamp i køkkenhaven.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Kontekst af kamp i Battle Royale.",
	"const_FIGHT_CONTEXT_TEST": "Test kampkontekst.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Baggrund for turneringskamp.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Kæmp i Battle Royale.",
	"const_FIGHT_TYPE_FARMER": "Opdrætter kamp.",
	"const_FIGHT_TYPE_SOLO": "Solo kamp.",
	"const_FIGHT_TYPE_TEAM": "Holdkamp.\n",
	"const_INSTRUCTIONS_LIMIT": "Maksimalt antal instruktioner en enhed kan bruge under sin tur.",
	"const_MAX_TURNS": "Maksimalt antal omgange i en kamp.",
	"const_OPERATIONS_LIMIT": "Maksimalt antal operationer en enhed kan bruge under sin tur.",
	"const_PI": "Forholdet mellem en cirkels omkreds og dens diameter.",
	"const_SORT_ASC": "Fortæller #sort-funktionen at sortere i stigende rækkefølge.",
	"const_SORT_DESC": "Fortæller #sort-funktionen at sortere i faldende rækkefølge.",
	"const_TYPE_ARRAY": "Værditype <b>liste</b>",
	"const_TYPE_BOOLEAN": "Værditype <b>boolesk</b>",
	"const_TYPE_FUNCTION": "Værditype <b>funktion</b>",
	"const_TYPE_NULL": "Værditype <b>null</b>",
	"const_TYPE_NUMBER": "Værditype <b>tal</b>",
	"const_TYPE_STRING": "Type værdi <b>tegnstreng</b>",
	"const_USE_CRITICAL": "Værdi returneret af funktionerne #useWeapon, #useWeaponOnCell, #useChip og #useChipOnCell i tilfælde af et kritisk hit.",
	"const_USE_FAILED": "Værdi returneret af funktionerne #useWeapon, #useWeaponOnCell, #useChip og #useChipOnCell ved fejl.",
	"const_USE_INVALID_COOLDOWN": "Værdi returneret af funktionerne #useChip og #useChipOnCell, hvis chippen endnu ikke er brugbar.",
	"const_USE_INVALID_POSITION": "Værdi returneret af funktionerne #useWeapon, #useWeaponOnCell, #useChip og #useChipOnCell, hvis rækkevidden er dårlig, eller synslinjen ikke er klar.",
	"const_USE_INVALID_TARGET": "Værdi returneret af funktionerne #useWeapon og #useChip, hvis målet ikke eksisterer.",
	"const_USE_NOT_ENOUGH_TP": "Værdi returneret af funktionerne #useWeapon, #useWeaponOnCell, #useChip og #useChipOnCell, hvis casteren ikke har nok handlingspunkter til at bruge objektet.",
	"const_USE_RESURRECT_INVALID_ENTITY": "Værdi returneret af #resurrect-funktionen, når den angivne enhed ikke eksisterer eller endnu ikke er død.",
	"const_USE_SUCCESS": "Værdi returneret af funktionerne #useWeapon, #useWeaponOnCell, #useChip og #useChipOnCell efter succes.",
	"const_USE_TOO_MANY_SUMMONS": "Fejl returneret af #summon, når du allerede har <b>8</b> live-indkaldelser.\n",
	"func_abs": "Returnerer den absolutte værdi af tallet <b>tal</b>.",
	"func_abs_arg_1": "Det tal, hvis absolutte værdi vil blive beregnet.",
	"func_abs_return": "Den absolutte værdi af tallet.",
	"func_acos": "Beregner arccosinus for <b>argument</b> i intervallet [0, #PI].",
	"func_acos_arg_1": "Antal, hvis arccosine vil blive beregnet.",
	"func_acos_return": "Buecosinus for <b>argument</b>.",
	"func_arrayConcat": "Tilføj de to arrays ende mod ende. Teksttaster bevares, og alle numeriske taster indekseres igen.",
	"func_arrayConcat_arg_1": "Første bord.",
	"func_arrayConcat_arg_2": "Andet bord.",
	"func_arrayConcat_return": "Summen af de to arrays.",
	"func_arrayFilter": "Returnerer et nyt array, der indeholder alle nøgle/værdi-par fra kildearrayet, for hvilket tilbagekaldsfunktionen returnerede sand. Hvis tilbagekaldsfunktionen tager én parameter, vil værdien af kildearrayet blive sendt, hvis den tager to parametre, vil nøglen og værdien blive sendt.",
	"func_arrayFilter_arg_1": "Originalt bord.",
	"func_arrayFilter_arg_2": "Funktion kaldet for hvert element.",
	"func_arrayFilter_return": "Nyt bord.",
	"func_arrayFlatten": "Returnerer et nyt array, der indeholder alle elementerne i kildearrayet. Alle elementer indeholdt i en sub-array udtrækkes i den nye matrix. Dybdeargumentet bestemmer den maksimale sub-array-dybde, der skal udtrækkes.\n<kode>var array = [1, 2, [[3, 4], 5], [6]];\ndebug(arrayFlatten(array, 1)); // [1,2,[3,4],5,6]</code>",
	"func_arrayFlatten_arg_1": "Originalt bord.",
	"func_arrayFlatten_arg_2": "Maksimal dybde.",
	"func_arrayFlatten_return": "Nyt bord.",
	"func_arrayFoldLeft": "Reducer arrayet <b>array</b> [v1, v2, ..., vn] fra venstre startende fra værdien <b>v0</b> og anvend funktionen <b>f</b >. Lige med :\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "Originalt bord.",
	"func_arrayFoldLeft_arg_2": "Funktion at anvende.",
	"func_arrayFoldLeft_arg_3": "Startværdi.",
	"func_arrayFoldLeft_return": "Reduceret bord.",
	"func_arrayFoldRight": "Reducer arrayet <b>array</b> [v1, v2, ..., vn] fra højre startende fra værdien <b>v0</b> og anvend funktionen <b>f</b >. Lige med :\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "Originalt bord.",
	"func_arrayFoldRight_arg_2": "Funktion at anvende.",
	"func_arrayFoldRight_arg_3": "Startværdi.",
	"func_arrayFoldRight_return": "Reduceret bord.",
	"func_arrayIter": "Kalder tilbagekaldsfunktionen for hvert array-element. Hvis tilbagekaldsfunktionen tager én parameter, vil værdien af kildearrayet blive sendt, hvis den tager to parametre, vil nøglen og værdien blive sendt.",
	"func_arrayIter_arg_1": "Originalt bord.",
	"func_arrayIter_arg_2": "Funktion kaldet for hvert element.",
	"func_arrayMap": "Returnerer et nyt array, der for hver nøgle i kildearrayet indeholder den værdi, der returneres af tilbagekaldsfunktionen. Hvis tilbagekaldsfunktionen tager én parameter, vil værdien af kildearrayet blive sendt, hvis den tager to parametre, vil nøglen og værdien blive sendt.",
	"func_arrayMap_arg_1": "Originalt bord.",
	"func_arrayMap_arg_2": "Funktion kaldet for hvert element.",
	"func_arrayMap_return": "Nyt bord.",
	"func_arrayMax": "Returnerer maksimumværdielementet i <b>array</b>-arrayet.<br/>Se #sort for mere information om den maksimale værdi af en matrix.",
	"func_arrayMax_arg_1": "Array, hvori den maksimale værdi søges.",
	"func_arrayMax_return": "Vare med maksimal værdi.",
	"func_arrayMin": "Returnerer minimumværdielementet for <b>array</b>-arrayet.<br/>For flere oplysninger om minimumværdien af en matrix se #sort.",
	"func_arrayMin_arg_1": "Array, hvori minimumsværdien søges.",
	"func_arrayMin_return": "Minimum værdi vare.",
	"func_arrayPartition": "Returnerer et nyt array, der indeholder to lister, i den første er alle nøgle/værdi-par, for hvilke tilbagekaldsfunktionen returnerede sand, i den anden er alle de andre. Hvis tilbagekaldsfunktionen tager én parameter, vil værdien af kildearrayet blive sendt, hvis den tager to parametre, vil nøglen og værdien blive sendt.",
	"func_arrayPartition_arg_1": "Originalt bord.",
	"func_arrayPartition_arg_2": "Funktion kaldet for hvert element.",
	"func_arrayPartition_return": "Nyt bord.",
	"func_arraySort": "Sorterer arrayet i henhold til rækkefølgen defineret af tilbagekaldsfunktionen. Elementerne sammenlignes to og to, tilbagekaldsfunktionen skal returnere værdierne -1, 0 eller 1 afhængig af om den første værdi er før, på samme niveau eller efter den anden værdi. Hvis tilbagekaldsfunktionen tager 2 parametre, sendes de to værdier, hvis det tager 4, sendes nøgle/værdi-parrene.",
	"func_arraySort_arg_1": "Originalt bord",
	"func_arraySort_arg_2": "Sorteringsfunktion.",
	"func_arraySort_return": "Det sorterede array",
	"func_asin": "Beregner arcsinus for <b>argument</b> i intervallet [0, #PI].",
	"func_asin_arg_1": "Antal, hvis arcsine vil blive beregnet.",
	"func_asin_return": "Arcsinus for <b>argument</b>.",
	"func_assocSort": "Sorterer arrayet <b>array</b> og bevarer nøgle:værdi-tilknytningen i henhold til rækkefølgen <b>rækkefølge</b>.",
	"func_assocSort_arg_1": "Matrixen til at sortere.",
	"func_assocSort_arg_2": "Sorteringsrækkefølgen: #SORT_ASC eller #SORT_DESC.",
	"func_atan": "Beregner arctangensen for <b>argument</b> i intervallet [0, #PI].",
	"func_atan2": "Konverterer kartesiske koordinater (<b>x</b>, <b>y</b>) til polære koordinater (<b>r</b>, <b>theta</b>). Denne funktion returnerer <b>theta</b>-vinklen mellem -#PI og #PI ved at bruge argumenternes fortegn.",
	"func_atan2_arg_1": "Y-koordinat.",
	"func_atan2_arg_2": "X koordinat.",
	"func_atan2_return": "<b>theta</b>-vinklen i punktets polære koordinater (x, y).",
	"func_atan_arg_1": "Antal, hvis arctangens vil blive beregnet.",
	"func_atan_return": "Buetangensen for <b>argument</b>.",
	"func_average": "Beregner gennemsnittet af de elementer, der er indeholdt i arrayet <b>array</b>.",
	"func_average_arg_1": "Array, hvis gennemsnit vi ønsker at beregne.",
	"func_average_return": "Gennemsnits værdi.",
	"func_canUseChip": "Bestemmer, om din enhed kan bruge <b>chippen</b> på enheden med id <b>entity</b>.",
	"func_canUseChip_arg_1": "Nummeret på den chip, der skal testes.",
	"func_canUseChip_arg_2": "Det enheds-id, som du vil bruge chippen på.",
	"func_canUseChip_return": "<i>sandt</i> hvis din enhed kan bruge chippen, <i>false</i> ellers.",
	"func_canUseChipOnCell": "Bestemmer, om din enhed kan bruge <b>chippen</b> på <b>cellen</b>-cellen.",
	"func_canUseChipOnCell_arg_1": "Nummeret på den chip, der skal testes.",
	"func_canUseChipOnCell_arg_2": "Nummeret på den celle, hvor du vil bruge chippen.",
	"func_canUseChipOnCell_return": "<i>sandt</i> hvis din enhed kan bruge chippen, <i>false</i> ellers.",
	"func_canUseWeapon": "Bestemmer, om din enhed kan skyde enheden med id <b>entity</b> med våbenet <b>våben</b>.",
	"func_canUseWeapon_arg_1": "Våbenet til at teste. Standard til dit aktuelt udstyrede våben.",
	"func_canUseWeapon_arg_2": "Id'et for den enhed, du vil skyde på.",
	"func_canUseWeapon_return": "<i>sandt</i> hvis din enhed kan fyre, <i>falsk</i> ellers.",
	"func_canUseWeaponOnCell": "Bestemmer, om din enhed kan skyde <b>celle</b>-cellen med <b>våben</b>-våbenet.",
	"func_canUseWeaponOnCell_arg_1": "Våbenet til at teste. Standard til dit aktuelt udstyrede våben.",
	"func_canUseWeaponOnCell_arg_2": "Nummeret på den celle, du vil skyde.",
	"func_canUseWeaponOnCell_return": "<i>sandt</i> hvis din enhed kan fyre, <i>falsk</i> ellers.",
	"func_cbrt": "Beregn terningroden af <b>tal</b>",
	"func_cbrt_arg_1": "Tal, hvis terningerod vi ønsker at beregne.",
	"func_cbrt_return": "Terningrod af <b>tal</b>.",
	"func_ceil": "Returnerer afrundingen op af <b>tal</b>.<br/>Du kan finde afrundingen nedad med #gulv og afrundingen med #rund.",
	"func_ceil_arg_1": "Tal, som vi ønsker at finde den øverste afrunding for.",
	"func_ceil_return": "Afrund <b>tal</b> opad.",
	"func_charAt": "Returnerer tegnet af strengen <b>streng</b> placeret ved position <b>position</b>.<br/>Det første tegn i strengen er på position 0.",
	"func_charAt_arg_1": "Streng, hvorfra vi ønsker at hente en karakter.",
	"func_charAt_arg_2": "Karakterens position at finde.",
	"func_charAt_return": "streng, der indeholder det søgte tegn, eller en tom streng, hvis positionen er ugyldig.",
	"func_chipNeedLos": "Returnerer, om <b>chippen</b> har brug for synsvidde for at blive brugt.",
	"func_chipNeedLos_arg_1": "Id'et for den chip, der skal testes.",
	"func_chipNeedLos_return": "<i>sandt</i> hvis <b>chippen</b> har brug for synsvidde for at blive brugt, <i>falsk</i> ellers.",
	"func_clearMarks": "Rydder alle markeringer lavet af mark() og markText() i feltet.",
	"func_clone": "Kopierer <b>værdien</b>, der er sendt som en parameter på <b>niveau</b>-niveauer og returnerer kopien. I tilfælde af et array for eksempel, vil en klon(array, 1) kopiere arrayet, men ikke dets elementer, en klon(array, 2) vil kopiere arrayet såvel som alle elementerne.",
	"func_clone_arg_1": "Værdien at klone",
	"func_clone_arg_2": "Antallet af niveauer, der skal klones",
	"func_clone_return": "Den klonede værdi",
	"func_contains": "Bestemmer, om en <b>søgestreng</b> er inden for en <b>streng</b>.",
	"func_contains_arg_1": "Streng, som søgningen udføres i.",
	"func_contains_arg_2": "Streng at søge efter.",
	"func_contains_return": "sand, hvis <b>søgning</b> er i <b>streng</b>, ellers falsk.",
	"func_cos": "Beregner cosinus for <b>vinkel</b>.",
	"func_cos_arg_1": "Tal, hvis cosinus vil blive beregnet (i radianer).",
	"func_cos_return": "Cosinus for <b>vinkel</b> i intervallet [-1, 1].",
	"func_count": "Tæller antallet af elementer i <b>array</b>-arrayet.",
	"func_count_arg_1": "Array, hvis antal elementer vil blive talt.",
	"func_count_return": "Antal array-elementer.",
	"func_debug": "Registrerer en <b>objekt</b>-besked i den personlige log, tilgængelig i rapporten ved slutningen af kampen.",
	"func_debug_arg_1": "Beskeden der skal optages.",
	"func_debugC": "Registrerer en <b>objekt</b> besked i den personlige log, tilgængelig i rapporten ved kampens afslutning, med farven <b>farve</b>.",
	"func_debugC_arg_1": "Beskeden der skal optages.",
	"func_debugC_arg_2": "Farven på beskeden. Du kan bruge #getColor-funktionen.",
	"func_debugE": "Registrerer en <b>objekt</b> fejlmeddelelse i den personlige log, tilgængelig i rapporten ved kampens afslutning.<br />Fejlmeddelelser vises med rødt i kamprapporten.",
	"func_debugE_arg_1": "Fejlmeddelelsen til at logge.",
	"func_debugW": "Registrerer en advarselsmeddelelse om <b>objekt</b> i den personlige log, tilgængelig i rapporten ved slutningen af kampen. <br />Advarselsmeddelelser vises i orange i kamprapporten.",
	"func_debugW_arg_1": "Advarselsmeddelelsen om at optage.",
	"func_deleteRegister": "Sletter registreringsdatabasen, der er knyttet til nøglen <b>nøgle</b>, hvis den findes.",
	"func_deleteRegister_arg_1": "Registreringsnøglen til at slette.",
	"func_endsWith": "Bestemmer, om en <b>streng</b> slutter med en <b>suffiks</b> streng.",
	"func_endsWith_arg_1": "Streng, som søgningen udføres i",
	"func_endsWith_arg_2": "Suffiks for at søge",
	"func_endsWith_return": "sand, hvis <b>streng</b> slutter med <b>suffiks</b>",
	"func_exp": "Hæver Eulers tal #E til <b>tal</b> potensen.",
	"func_exp_arg_1": "Eksponenten som #E vil blive hævet til.",
	"func_exp_return": "#E** <b>nummer</b>.",
	"func_fill": "Ændrer størrelsen på arrayet <b>array</b> til <b>størrelse</b> og fylder alle dets felter med <b>værdi</b>.",
	"func_fill_arg_1": "Tabellen skal udfyldes.",
	"func_fill_arg_2": "Værdien for hver firkant i arrayet.",
	"func_fill_arg_3": "Antallet af kasser. Som standard er den aktuelle matrixstørrelse.",
	"func_floor": "Beregner afrundingen ned af <b>tal</b>.<br/>Du kan finde afrundingen opad med #loft og afrundingen med #rund.",
	"func_floor_arg_1": "Tal, hvis afrunding nedad vi ønsker at finde.",
	"func_floor_return": "Rundet ned til <b>antal</b>.",
	"func_getAbsoluteShield": "Returnerer det absolutte skjold for entiteten med id <b>entity</b>. For direkte at hente din enheds absolutte skjold skal du bruge #getAbsoluteShield() uden parametre.",
	"func_getAbsoluteShield_arg_1": "Id'et for den enhed, hvis absolutte skjold vil blive returneret.",
	"func_getAbsoluteShield_return": "Enhedens <b>entitet</b>s absolutte skjold.",
	"func_getAgility": "Returnerer agiliteten af entiteten med id <b>entity</b>. For direkte at hente din enheds agility, brug #getAgility() uden parametre.",
	"func_getAgility_arg_1": "Id'et for den enhed, hvis smidighed vil blive returneret.",
	"func_getAgility_return": "Agiliteten af <b>entitet</b> entiteten.",
	"func_getAIID": "Returnerer AI-id'et for entitetens <b>entitet</b>. Brug getAIID uden parameter til at hente din AI's id.",
	"func_getAIID_arg_1": "Id'et for den enhed, hvis AI-id vil blive returneret.",
	"func_getAIID_return": "<b>entity</b>-enhedens AI-id.",
	"func_getAIName": "Returnerer AI-navnet på enhedens <b>entitet</b>. Brug getAINam() uden parametre til at hente navnet på din AI.",
	"func_getAIName_arg_1": "Id'et for den enhed, hvis AI-navn vil blive returneret.",
	"func_getAIName_return": "Navnet på <b>entity</b>-enhedens AI.",
	"func_getAliveAllies": "Returnerer en række af alle dine allierede i live i kamp.",
	"func_getAliveAllies_return": "Et array, der indeholder id'erne for alle dine levende allierede.",
	"func_getAliveEnemies": "Returnerer en række af alle dine fjender i live i kamp.",
	"func_getAliveEnemiesCount": "Returnerer antallet af fjender i live i kampen.",
	"func_getAliveEnemiesCount_return": "Antallet af levende fjender.",
	"func_getAliveEnemies_return": "Et array, der indeholder id'erne for alle dine levende fjender.",
	"func_getAllChips": "Returnerer listen over alle jetoner i spillet.",
	"func_getAllChips_return": "Listen over alle jetoner i spillet.",
	"func_getAllEffects": "Returnerer listen over alle effekter i spillet.",
	"func_getAllEffects_return": "Listen over alle effekter i spillet.",
	"func_getAllWeapons": "Returnerer listen over alle våben i spillet.",
	"func_getAllWeapons_return": "Listen over alle våben i spillet.",
	"func_getAlliedTurret": "Returnerer dit holds tårn-id eller -1, hvis det ikke findes.",
	"func_getAlliedTurret_return": "Dit holds tårn-id.",
	"func_getEnemyTurret": "Returnerer fjendens tårn-id eller -1, hvis det ikke findes.",
	"func_getEnemyTurret_return": "Fjendens tårn ID.",
	"func_getAllies": "Returnerer et array, der indeholder dine allierede og din enhed.",
	"func_getAlliesCount": "Returnerer antallet af allierede i kampen.",
	"func_getAlliesCount_return": "Antallet af allierede.",
	"func_getAlliesLife": "Returnerer dine allieredes samlede helbred.",
	"func_getAlliesLife_return": "Dine allieredes samlede helbred.",
	"func_getAllies_return": "Tabellen over allierede og din enhed.",
	"func_getBirthTurn": "Returnerer kampens tur, hvor <b>entiteten</b> dukkede op. Returnerer 1, hvis det f.eks. er en porre, og 5, hvis det er en indkaldelse, der er påberåbt i omgang 5.",
	"func_getBirthTurn_arg_1": "Id'et for den enhed, hvis spawn-tur vil blive returneret.",
	"func_getBirthTurn_return": "Kampsvinget, hvor <b>entitet</b> dukkede op.",
	"func_getBlue": "Returnerer mængden af blå i farven <b>farve</b>, mellem 0 og 255. For eksempel getBlue(#COLOR_BLUE) = 255 og getBlue(#COLOR_GREEN) = 0.",
	"func_getBlue_arg_1": "Den farve, hvis blå sats vil blive returneret.",
	"func_getBlue_return": "Frekvensen af blå i farven <b>farve</b>",
	"func_getBulbChips": "Returnerer listen over chips, der vil blive udstyret på en pære, der tilkaldes af <b>bulb_chip</b> chippen.",
	"func_getBulbChips_arg_1": "Pæreloppen.",
	"func_getBulbChips_return": "Chips af en pære fremkaldt af <b>bulb_chip</b> chippen.",
	"func_getCell": "Returnerer den celle, hvor entiteten med id <b>entity</b> er placeret. Brug getCell() uden parametre til at hente din celle.",
	"func_getCell_arg_1": "Id'et for den enhed, hvis celle vil blive returneret.",
	"func_getCell_return": "Cellenummeret, hvor <b>enheden</b> er placeret.",
	"func_getCellContent": "Returnerer indholdet af en celle med id <b>celle</b>.",
	"func_getCellContent_arg_1": "Id'et for den celle, hvis indhold vil blive returneret.",
	"func_getCellContent_return": "Indholdet af <b>cellen</b>-cellen: #CELL_EMPTY for en tom celle, #CELL_ENTITY for en enhed, #CELL_OBSTACLE for en forhindring.",
	"func_getCellDistance": "Returnerer afstanden mellem to celler <b>celle1</b> og <b>celle2</b>. <br />Den returnerede afstand er udtrykt i antal celler og tager ikke højde for de forskellige forhindringer mellem de to celler.\nFor at få afstanden i luftlinje, se #getDistance og for at få vejafstanden mellem de to celler, hvor du undgår forhindringer, se #getPathLength.",
	"func_getCellDistance_arg_1": "Startcellens id.",
	"func_getCellDistance_arg_2": "Ankomstcellens id.",
	"func_getCellDistance_return": "Afstanden mellem de to celler <b>celle1</b> og <b>celle2</b>.",
	"func_getCellFromXY": "Returnerer id'et for cellen ved position (<b>x</b>, <b>y</b>).",
	"func_getCellFromXY_arg_1": "Cellens x-position.",
	"func_getCellFromXY_arg_2": "Cellens y-position.",
	"func_getCellFromXY_return": "Celle-id'et ved position (<b>x</b>, <b>y</b>), <b>null</b>, hvis cellen ikke eksisterer.",
	"func_getCellsToUseChip": "Returnerer listen over celler, hvorfra din enhed vil være i stand til at bruge <b>chippen</b> på <b>enheden</b> entiteten.",
	"func_getCellsToUseChip_arg_1": "Den chip, som enheden ønsker at kunne bruge.",
	"func_getCellsToUseChip_arg_2": "Målenheden.",
	"func_getCellsToUseChip_arg_3": "Array af celler at ignorere.",
	"func_getCellsToUseChip_return": "Liste over celler, hvor chippen kan bruges.",
	"func_getCellsToUseChipOnCell": "Returnerer listen over celler, hvorfra din enhed vil være i stand til at bruge <b>chippen</b> på <b>cellen</b>-cellen.",
	"func_getCellsToUseChipOnCell_arg_1": "Den chip, som enheden ønsker at kunne bruge.",
	"func_getCellsToUseChipOnCell_arg_2": "Målcellen.",
	"func_getCellsToUseChipOnCell_arg_3": "Array af celler at ignorere.",
	"func_getCellsToUseChipOnCell_return": "Liste over celler, hvor chippen kan bruges.",
	"func_getCellsToUseWeapon": "Returnerer listen over celler, hvorfra din enhed vil være i stand til at bruge våben-<b>våbenet</b> på entitetens <b>entitet</b>.",
	"func_getCellsToUseWeapon_arg_1": "Våbenet til at teste. Standard til dit aktuelt udstyrede våben.",
	"func_getCellsToUseWeapon_arg_2": "Målenheden.",
	"func_getCellsToUseWeapon_arg_3": "Array af celler at ignorere. Som standard et tomt array.",
	"func_getCellsToUseWeapon_return": "Liste over celler, hvorfra våbnet kan bruges.",
	"func_getCellsToUseWeaponOnCell": "Returnerer listen over celler, hvorfra din enhed vil være i stand til at bruge <b>våben</b> våbnet på en <b>celle</b> celle.",
	"func_getCellsToUseWeaponOnCell_arg_1": "Våbenet til at teste. Standard til dit aktuelt udstyrede våben.",
	"func_getCellsToUseWeaponOnCell_arg_2": "Målcellen.",
	"func_getCellsToUseWeaponOnCell_arg_3": "Array af celler at ignorere. Som standard et tomt array.",
	"func_getCellsToUseWeaponOnCell_return": "Liste over celler, hvorfra våbnet kan bruges.",
	"func_getCellX": "Bestemmer positionen i X af cellen <b>cellen</b>.",
	"func_getCellX_arg_1": "Den celle, hvis position i X vil blive bestemt.",
	"func_getCellX_return": "Cellens X-position.",
	"func_getCellY": "Bestemmer Y-positionen for cellen <b>cellen</b>.",
	"func_getCellY_arg_1": "Den celle, hvis position i Y vil blive bestemt.",
	"func_getCellY_return": "Cellens Y-position.",
	"func_getChipArea": "Returnerer effektområdet for <b>chippen</b>.",
	"func_getChipArea_arg_1": "Den chip, hvis zonetype vil blive returneret.",
	"func_getChipArea_return": "Arealtypen for chippen <b>chippen</b> blandt AREA_* konstanterne:\n<ul>\n<li>#AREA_POINT: enkelt kvadratisk område</li>\n<li>#AREA_LASER_LINE: linje af en laser</li>\n<li>#AREA_CIRCLE_1: cirkulært område med 3 firkanter i diameter</li>\n<li>#AREA_CIRCLE_2: cirkulært område med 5 kvadrater i diameter</li>\n<li>#AREA_CIRCLE_3: cirkulært område med 7 kvadrater i diameter</li>\n</ul>",
	"func_getChipCooldown": "Returnerer <b>chip</b>-chipgendannelsestiden, taget fra markedet.",
	"func_getChipCooldown_arg_1": "Den chip, hvis nedkøling vil blive returneret.",
	"func_getChipCooldown_return": "<b>chip</b>-afkølingen.",
	"func_getChipCost": "Returnerer prisen i TP for chippen <b>chippen</b>.",
	"func_getChipCost_arg_1": "Chippen, som koster, vil blive returneret.",
	"func_getChipCost_return": "Prisen for <b>chippen</b>.",
	"func_getChipEffectiveArea": "Returnerer listen over celler, der vil blive påvirket, hvis <b>chippen</b>-chippen bruges på <b>celle</b>-cellen fra en <b>fra</b>-celle.",
	"func_getChipEffectiveArea_arg_1": "Den chip, der skal testes.",
	"func_getChipEffectiveArea_arg_2": "Målcellen.",
	"func_getChipEffectiveArea_arg_3": "Cellen, hvorfra chippen bruges.",
	"func_getChipEffectiveArea_return": "Arrayet, der indeholder id'erne for alle celler, der vil blive påvirket.",
	"func_getChipEffects": "Returnerer effekterne af <b>chippen</b> chippen.",
	"func_getChipEffects_arg_1": "Den chip, hvis effekter vil blive returneret.",
	"func_getChipEffects_return": "Effekterne af <b>chippen</b> chippen. Samme returværdi som for #getWeaponEffects-funktionen.",
	"func_getChipFailure": "Returnerer den procentvise risiko for svigt af <b>chippen</b>.",
	"func_getChipFailure_arg_1": "Den chip, hvis fejlprocent vil blive returneret.",
	"func_getChipFailure_return": "<b>chip</b> chipfejlprocent, et heltal mellem <b>0</b> og <b>100</b>.",
	"func_getChipMaxRange": "Returnerer det maksimale område for <b>chippen</b> chippen.",
	"func_getChipMaxRange_arg_1": "Den chip, hvis maksimale rækkevidde vil blive returneret.",
	"func_getChipMaxRange_return": "Den maksimale rækkevidde for <b>chippen</b>.",
	"func_getChipMaxScope": "Returnerer det maksimale område for <b>chippen</b> chippen.",
	"func_getChipMaxScope_arg_1": "Den chip, hvis maksimale rækkevidde vil blive returneret.",
	"func_getChipMaxScope_return": "Den maksimale rækkevidde for <b>chippen</b>.",
	"func_getChipMinRange": "Returnerer minimumsområdet for <b>chippen</b> chippen.",
	"func_getChipMinRange_arg_1": "Den chip, hvis minimumsområde vil blive returneret.",
	"func_getChipMinRange_return": "Minimumsområdet for <b>chippen</b>.",
	"func_getChipMinScope": "Returnerer minimumsområdet for <b>chippen</b> chippen.",
	"func_getChipMinScope_arg_1": "Den chip, hvis minimumsområde vil blive returneret.",
	"func_getChipMinScope_return": "Minimumsområdet for <b>chippen</b>.",
	"func_getChipLaunchType": "Returnerer starttilstanden for <b>chippen</b> chippen blandt LAUNCH_TYPE_* konstanterne.",
	"func_getChipLaunchType_arg_1": "Id'et for den chip, hvis kastetilstand vil blive returneret.",
	"func_getChipLaunchType_return": "Kasttilstanden for <b>chippen</b>-chippen.",
	"func_getChipName": "Returnerer navnet på chippen <b>chip</b>.",
	"func_getChipName_arg_1": "Den chip, hvis navn vil blive returneret.",
	"func_getChipName_return": "Navnet på <b>chippen</b> chippen.",
	"func_getChips": "Returnerer punkterne for objektet med id <b>entity</b>.",
	"func_getChips_arg_1": "Id'et for den enhed, hvis kugler vil blive returneret.",
	"func_getChips_return": "Et array, der indeholder punkt-id'erne for entiteten <b>entitet</b>.",
	"func_getChipTargets": "Returnerer de enheder, der vil blive påvirket, hvis <b>chippen</b> bruges på celle <b>celle</b>.",
	"func_getChipTargets_arg_1": "Den chip, der skal testes.",
	"func_getChipTargets_arg_2": "Målcellen.",
	"func_getChipTargets_return": "Arrayet, der indeholder id'erne for alle enheder, der vil blive påvirket.",
	"func_getColor": "Returnerer det heltal, der svarer til farven (<b>rød</b>, <b>grøn</b>, <b>blå</b>), der er angivet som parametre.",
	"func_getColor_arg_1": "Rød værdi mellem 0 og 255.",
	"func_getColor_arg_2": "Grøn værdi mellem 0 og 255.",
	"func_getColor_arg_3": "Blå værdi mellem 0 og 255.",
	"func_getColor_return": "int svarende til farven angivet som parameter.",
	"func_getCooldown": "Returnerer den aktuelle <b>chip</b> nedkøling af <b>enheden</b> entiteten.",
	"func_getCooldown_arg_1": "Den chip, hvis aktuelle nedkøling vil blive returneret.",
	"func_getCooldown_arg_2": "Den enhed, hvis nedkøling vil blive returneret.",
	"func_getCooldown_return": "Den aktuelle nedkøling af chippen <b>chippen</b>, dette er antallet af omdrejninger før chippen bliver brugbar, <b>0</b> hvis den i øjeblikket er brugbar.",
	"func_getCores": "Returnerer antallet af kerner i enheden med id <b>entity</b>.",
	"func_getCores_arg_1": "Den enhed, hvis antal kerner vil blive returneret.",
	"func_getCores_return": "Antallet af kerner i <b>enheden</b> entiteten.",
	"func_getDate": "Returnerer datoen for kampen i formatet dd/MM/åååå.",
	"func_getDate_return": "Datoen for kampen.",
	"func_getTime": "Returnerer kamptiden i HH:mm:ss-format.",
	"func_getTime_return": "Kampens tidspunkt.",
	"func_getTimestamp": "Returnerer kampens tidsstempel svarende til antallet af sekunder siden 1. januar 1970.",
	"func_getTimestamp_return": "Kampens tidsstempel.",
	"func_getDamageReturn": "Returnerer skadereturneringsprocenten for enheden med id <b>entity</b>.",
	"func_getDamageReturn_arg_1": "Id'et for den enhed, hvorfra skadehenvisningen vil blive returneret.",
	"func_getDamageReturn_return": "Skaderetursatsen for enheden med id <b>entity</b> (i %).",
	"func_getDeadAllies": "Returnerer døde venlige enheder.",
	"func_getDeadAllies_return": "Tabellen over allierede døde enheder.",
	"func_getDeadEnemies": "Returnerer døde fjendtlige enheder.",
	"func_getDeadEnemiesCount": "Returnerer antallet af fjender, der døde i kamp.",
	"func_getDeadEnemiesCount_return": "Antallet af døde fjender.",
	"func_getDeadEnemies_return": "Tabellen over døde fjendens enheder.",
	"func_getDistance": "Beregner afstanden i luftlinje mellem to celler <b>celle1</b> og <b>celle2</b>. <br />For at få afstanden i antal celler, se #getCellDistance, og for at få længden af stien mellem de to celler, der undviger de forskellige forhindringer, se #getPathLength.",
	"func_getDistance_arg_1": "Startcellen.",
	"func_getDistance_arg_2": "Ankomstcellen.",
	"func_getDistance_return": "Afstanden i luftlinje mellem de to celler.",
	"func_getPassiveEffects": "Returnerer listen over passive effekter af objektet med id <b>entitet</b>. For direkte at hente listen over passive effekter af din enhed, brug #getPassiveEffects() uden parameter.",
	"func_getPassiveEffects_arg_1": "Id'et for den enhed, hvis liste over passive effekter vil blive returneret.",
	"func_getPassiveEffects_return": "Listen over passive effekter, der i øjeblikket findes på <b>enheden</b> entiteten.\nListen over passive effekter er et array, der indeholder effekterne.\nEn effekt er i sig selv en række af 7 felter af formen: [<b>type</b>, <b>værdi</b>, <b>caster_id</b>, <b>turns</b>, <b>kritisk</b>, <b>item_id</b>, <b>target_id</b>]. Det er den samme struktur som de klassiske effekter, der returneres af #getEffects-funktionen.",
	"func_getEnemies": "Returnerer fjendtlige enheder (levende eller døde) i kamp.",
	"func_getEnemiesCount": "Returnerer antallet af fjender i kampen.",
	"func_getEnemiesCount_return": "Antallet af fjender.",
	"func_getEnemiesLife": "Beregner summen af hitpoint for alle fjendtlige enheder.",
	"func_getEnemiesLife_return": "Summen af fjendens holds hitpoint.",
	"func_getEnemies_return": "Et array, der indeholder id'erne for alle fjendens enheder.",
	"func_getEntityTurnOrder": "Returnerer en værdi mellem 1 og n (antal enheder i øjeblikket i spil), der angiver <b>enhedens</b>s position i afspilningsrækkefølge.",
	"func_getEntityTurnOrder_arg_1": "Id'et for den enhed, hvis spillerækkefølge vil blive returneret",
	"func_getEntityTurnOrder_return": "Placer i rækkefølge af entitetens <b>entitet</b>",
	"func_getFarmerID": "Returnerer opdrætter-id'et for entitetens <b>entitet</b>.",
	"func_getFarmerID_arg_1": "Id'et for den enhed, hvis opdrætter-id vil blive returneret.",
	"func_getFarmerID_return": "Id'et for <b>entity</b>-entity-opdrætteren.",
	"func_getFarmerCountry": "Returnerer landet for opdrætteren af enhedens <b>entitet</b>.",
	"func_getFarmerCountry_arg_1": "Id'et for den enhed, hvis opdrætterland vil blive returneret.",
	"func_getFarmerCountry_return": "Landet for <b>enheden</b>-enhedens opdrætter, eller \"?\" hvis ikke angivet.",
	"func_getFarmerName": "Returnerer opdrætternavnet på <b>enheden</b>-enheden.",
	"func_getFarmerName_arg_1": "Id'et for den enhed, hvis opdrætternavn vil blive returneret.",
	"func_getFarmerName_return": "Navnet på opdrætteren af <b>enheden</b>-enheden.",
	"func_getFarthestAlly": "Bestemmer den fjerneste allierede af din enhed, i luftlinje.",
	"func_getFarthestAlly_return": "Id'et for den længst venlige enhed.",
	"func_getFarthestEnemy": "Bestemmer den fjende, der er længst væk fra din enhed, i luftlinje.",
	"func_getFarthestEnemy_return": "Id'et for den fjerneste fjende enhed.",
	"func_getFightID": "Returnerer det aktuelle kamp-id.",
	"func_getFightID_return": "ID for den aktuelle kamp.",
	"func_getFightContext": "Returnerer konteksten for den aktuelle kamp.",
	"func_getFightContext_return": "Afhængigt af kampkontekst: Test Battle (#FIGHT_CONTEXT_TEST), Arena Battle (#FIGHT_CONTEXT_GARDEN), Tournament Battle (#FIGHT_CONTEXT_TOURNAMENT), Challenge Battle (#FIGHT_CONTEXT_CHALLENGE), Battle Royale (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "Returnerer den aktuelle kamptype.",
	"func_getFightType_return": "Afhængigt af typen af kamp: Solokamp (#FIGHT_TYPE_SOLO), Farmer-kamp (#FIGHT_TYPE_FARMER), holdkamp (#FIGHT_TYPE_TEAM), Battle Royale (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "Returnerer styrken af enheden med id <b>entity</b>.",
	"func_getForce_arg_1": "Den enhed, hvis styrke vil blive returneret.",
	"func_getForce_return": "Styrken af <b>entitet</b>-enheden.",
	"func_getFrequency": "Returnerer frekvensen af enheden med id <b>entity</b>. Brug getFrequency() uden parametre til at hente din frekvens.",
	"func_getFrequency_arg_1": "Den enhed, hvis frekvens vil blive returneret.",
	"func_getFrequency_return": "Hyppigheden af <b>entitet</b>-enheden.",
	"func_getGreen": "Returnerer mængden af grønt i farven <b>farve</b> mellem 0 og 255. For eksempel getGreen(#COLOR_GREEN) = 255 og getGreen(#COLOR_RED) = 0.",
	"func_getGreen_arg_1": "Den farve, hvis grønne sats vil blive returneret.",
	"func_getGreen_return": "Frekvensen for grøn i farven <b>farve</b>",
	"func_getInstructionsCount": "Returnerer antallet af instruktioner, din enhed har udført under den aktuelle tur.",
	"func_getInstructionsCount_return": "Antallet af instruktioner, din enhed har udført under den aktuelle tur.",
	"func_getLaunchedEffects": "Returnerer listen over effekter forårsaget af objektet med id <b>entitet</b>.",
	"func_getLaunchedEffects_arg_1": "Id'et for den enhed, hvis liste over forårsagede virkninger vil blive returneret.",
	"func_getLaunchedEffects_return": "Listen over effekter forårsaget af objektet med id <b>entity</b>, i samme form som arrayet returneret af #getEffects.",
	"func_getLeek": "Returnerer id'et for din enhed.",
	"func_getLeek_return": "Id'et for din enhed.",
	"func_getEntity": "Returnerer id'et for din enhed.",
	"func_getEntity_return": "Id'et for din enhed.",
	"func_getLeekID": "Returnerer det rigtige id for porren af id <b>porre</b>.",
	"func_getLeekID_arg_1": "Id'et for porren, hvis faktiske id vil blive returneret.",
	"func_getLeekID_return": "Porrens reelle id for <b>entiteten</b>.",
	"func_getLeekOnCell": "Returnerer den enhed, der er på celle <b>celle</b>.",
	"func_getLeekOnCell_arg_1": "Cellen, hvis enhed vi ønsker at hente.",
	"func_getLeekOnCell_return": "Enhedens id i cellen eller -1, hvis cellen ikke har nogen entitet.",
	"func_getEntityOnCell": "Returnerer den enhed, der er på celle <b>celle</b>.",
	"func_getEntityOnCell_arg_1": "Cellen, hvis enhed vi ønsker at hente.",
	"func_getEntityOnCell_return": "Enhedens id i cellen eller -1, hvis cellen ikke har nogen entitet.",
	"func_getLevel": "Returnerer enhedsniveauet med id <b>entity</b>.",
	"func_getLevel_arg_1": "Id'et for den enhed, hvis niveau vil blive returneret.",
	"func_getLevel_return": "Niveauet for enheden med id <b>entity</b>.",
	"func_getLife": "Returnerer den aktuelle levetid for enheden med id <b>entity</b>. Brug getLife() uden parametre til at hente dit liv.",
	"func_getLife_arg_1": "Id'et for den enhed, hvis liv vil blive returneret.",
	"func_getLife_return": "Den aktuelle levetid for <b>enheden</b>-enheden.",
	"func_getMagic": "Returnerer magien i entiteten med id <b>entity</b>. Brug getMagic() uden parametre til at hente din magi.",
	"func_getMagic_arg_1": "Id'et for den enhed, hvis magi vil blive returneret.",
	"func_getMagic_return": "Magien ved id-entitet <b>entity</b>.",
	"func_getMapType": "Returnerer den type terræn, som kampen finder sted på (fabrik, ørken, skov osv.), blandt konstanterne #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER og #MAP_BEACH.",
	"func_getMapType_return": "Terrænets type.",
	"func_getMessageAuthor": "Returnerer id'et for den enhed, der forfatter meddelelsen <b>meddelelsen</b>.",
	"func_getMessageAuthor_arg_1": "Den besked, hvis forfatter vil blive returneret.",
	"func_getMessageAuthor_return": "Id'et for den enhed, der forfatter meddelelsen <b>meddelelsen</b>.",
	"func_getMessageParams": "Returnerer rækken af meddelelsesparametre <b>meddelelse</b>.",
	"func_getMessageParams_arg_1": "Den besked, hvis parametre vil blive returneret.",
	"func_getMessageParams_return": "<b>meddelelses</b>-meddelelsesparametrene.",
	"func_getMessages": "Returnerer <b>entity</b> enhedsmeddelelsesarrayet.",
	"func_getMessages_arg_1": "Den enhed, hvis meddelelser vil blive returneret.",
	"func_getMessages_return": "Tabellen over dine beskeder.<br>En besked er i sig selv repræsenteret i form af en\nmatrix af formen: [<b>forfatter</b>, <b>type</b>, <b>parametre</b>]<br>\nDe forskellige typer meddelelser er repræsenteret af konstanterne:\n<ul>\n<li>#MESSAGE_HEAL: anmodning om pleje</li>\n<li>#MESSAGE_ATTACK: anmodning om at angribe</li>\n<li>#MESSAGE_BUFF_FORCE: force boost anmodning</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "Returnerer beskedtypen <b>meddelelse</b>.",
	"func_getMessageType_arg_1": "Den besked, hvis type vil blive returneret (blandt #MESSAGE_HEAL, #MESSAGE_ATTACK osv.).",
	"func_getMessageType_return": "Meddelelsestypen <b>meddelelse</b>.",
	"func_getMP": "Returnerer det aktuelle antal bevægelsespunkter for <b>enheden</b>-enheden. Brug getMP() uden parametre til at hente dine PM'er.",
	"func_getMP_arg_1": "Id'et for den enhed, hvis PM-antal vil blive returneret.",
	"func_getMP_return": "<b>enheden</b>-enhedens MP-nummer.",
	"func_getName": "Returnerer navnet på enheden med id <b>entity</b>.",
	"func_getName_arg_1": "Id'et for den enhed, hvis navn vil blive returneret.",
	"func_getName_return": "Enhedens navn <b>entitet</b>.",
	"func_getNearestAlly": "Returnerer den nærmeste venlige enhed til din enhed.",
	"func_getNearestAlly_return": "Id'et for den nærmeste venlige enhed.",
	"func_getNearestAllyTo": "Returnerer den nærmeste allierede enhed til den enhed, der er angivet som en parameter.",
	"func_getNearestAllyTo_arg_1": "Id'et for den enhed, hvis nærmeste allierede vi ønsker at kende.",
	"func_getNearestAllyToCell": "Returnerer den nærmeste allierede enhed til cellen angivet som parameter.",
	"func_getNearestAllyToCell_arg_1": "Id'et for den celle, hvis nærmeste allierede vi ønsker at kende.",
	"func_getNearestAllyToCell_return": "Id'et for den nærmeste venlige enhed.",
	"func_getNearestAllyTo_return": "Id'et for den nærmeste venlige enhed.",
	"func_getNearestEnemy": "Returnerer den nærmeste fjende enhed til din enhed.",
	"func_getNearestEnemy_return": "Id'et for den nærmeste fjende enhed.",
	"func_getNearestEnemyTo": "Returnerer den nærmeste fjende enhed til den entitet, der er angivet som en parameter.",
	"func_getNearestEnemyTo_arg_1": "Id'et for den enhed, hvis nærmeste fjende vi ønsker at kende.",
	"func_getNearestEnemyToCell": "Returnerer den nærmeste fjende enhed til cellen angivet som en parameter.",
	"func_getNearestEnemyToCell_arg_1": "Id'et for den celle, hvis nærmeste fjende vi ønsker at kende.",
	"func_getNearestEnemyToCell_return": "Id'et for den nærmeste fjende enhed.",
	"func_getNearestEnemyTo_return": "Id'et for den nærmeste fjende enhed.",
	"func_getNextPlayer": "Returnerer id'et for den enhed, der vil spille efter den aktuelle spiller.",
	"func_getNextPlayer_return": "Den næste spiller.",
	"func_getObstacles": "Returnerer listen over forhindringspladser i terrænet.",
	"func_getObstacles_return": "Arrayet, der indeholder forhindringscelle-id'erne.",
	"func_getOperations": "Returnerer antallet af operationer, der er forbrugt af din enhed siden starten af dets tur. Dette tal skal forblive lavere end #OPERATIONS_LIMIT, så enheden ikke går ned.",
	"func_getOperations_return": "Antal operationer forbrugt af din enhed siden starten af dets tur.",
	"func_getPath": "Returnerer stien, der undgår forhindringer mellem to celler <b>celle1</b> og <b>celle2</b>, hvis den findes, og ignorerer cellerne indeholdt i arrayet <b>ignoredCells</b> . Hvis en spiller er på en ignoreret celle, kan stien passere over dem.<br /><br />\nStartcellen <b>celle1</b> er aldrig en del af den resulterende sti. Celle <b>celle2</b> er en del af den resulterende sti, hvis og kun hvis den er tom eller ignoreret af <b>ignoredCells</b>.<br /><br />\nHvis der ikke er nogen sti mellem de to celler, returnerer <b>getPath</b> <i>null</i>.",
	"func_getPath_arg_1": "Startcellen.",
	"func_getPath_arg_2": "Ankomstcellen.",
	"func_getPath_arg_3": "Den række af celler, der skal ignoreres.",
	"func_getPath_return": "Arrayet, der indeholder cellerne, der udgør stien mellem de to celler.",
	"func_getPathLength": "Returnerer længden af stien mellem to celler <b>celle1</b> og <b>celle2</b>, undgår forhindringer, ignorerer cellerne indeholdt i arrayet <b>ignoredCells</b>. Denne funktion svarer til <i>count(getPath(<b>celle1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>.\nHvis en spiller er på en ignoreret celle, kan stien passere over dem.<br /><br />\nStartcellen <b>celle1</b> tælles aldrig med i resultatet. Celle <b>celle2</b> tælles med i resultatet, hvis og kun hvis den er tom eller ignoreret af <b>ignoredCells</b>.<br /><br />\nHvis der ikke er nogen sti mellem de to celler, returnerer <b>getPathLength</b> <i>null</i>.",
	"func_getPathLength_arg_1": "Startcellen.",
	"func_getPathLength_arg_2": "Ankomstcellen.",
	"func_getPathLength_arg_3": "Den række af celler, der skal ignoreres.",
	"func_getPathLength_return": "Længden af stien mellem <b>celle1</b> og <b>celle2</b>.",
	"func_getPreviousPlayer": "Returnerer id'et for den enhed, der spillede før den aktuelle spiller.",
	"func_getPreviousPlayer_return": "Den forrige spiller.",
	"func_getRed": "Returnerer mængden af rød i farven <b>farve</b> mellem 0 og 255. For eksempel getRed(#COLOR_RED) = 255 og getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "Den farve, hvis røde sats vil blive returneret.",
	"func_getRed_return": "Frekvensen af rød i <b>farve</b>",
	"func_getRegister": "Returnerer den værdi, der er gemt i enhedsregistret tilknyttet nøgle <b>nøgle</b> eller </i>null</i>, hvis registreringsdatabasen ikke eksisterer.",
	"func_getRegister_arg_1": "Registreringsnøglen, hvis værdi vil blive returneret.",
	"func_getRegister_return": "Værdien gemt i <b>nøgle</b> registreringsdatabasen.",
	"func_getRegisters": "Returnerer sættet af enhedsregistre som en associativ matrix [<i>registreringsnøgle</i>:<i>registerværdi</i>]. Eksempel: <code>debug(getRegisters());\n// Viser for eksempel:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "Det associative array, der svarer til alle entitetens registre.",
	"func_getRelativeShield": "Returnerer det relative skjold for enheden med id <b>entity</b>. For direkte at hente din enheds relative skjold skal du bruge #getRelativeShield() uden parametre.",
	"func_getRelativeShield_arg_1": "Id'et for den enhed, hvis relative skjold vil blive returneret.",
	"func_getRelativeShield_return": "Det relative skjold for <b>enheden</b>, et heltal mellem <b>0</b> og <b>100</b>.",
	"func_getResistance": "Returnerer modstanden for enheden med id <b>entity</b>. Brug getResistance() uden parametre til at hente din modstand.",
	"func_getResistance_arg_1": "Id'et for den enhed, hvis modstand vil blive returneret.",
	"func_getResistance_return": "Modstanden for enheden med id <b>entity</b>.",
	"func_getScience": "Returnerer videnskaben om entiteten med id <b>entity</b>. Brug getScience() uden parametre til at hente din videnskab.",
	"func_getScience_arg_1": "Id'et for den enhed, hvis videnskab vil blive returneret.",
	"func_getScience_return": "Videnskaben om id-entitet <b>entitet</b>.",
	"func_getStrength": "Returnerer styrken af enheden med id <b>entity</b>. Brug getStrength() uden parametre til at hente din styrke.",
	"func_getStrength_arg_1": "Den enhed, hvis styrke vil blive returneret.",
	"func_getStrength_return": "Styrken af <b>entitet</b>-enheden.",
	"func_getPower": "Returnerer styrken af entiteten med id <b>entity</b>.",
	"func_getPower_arg_1": "Den enhed, hvis magt vil blive returneret.",
	"func_getPower_return": "Kraften i <b>entitet</b>.",
	"func_getSummoner": "Returnerer den enhed, der påkaldte entiteten <b>entitet</b>, hvis det er en påkaldelse.",
	"func_getSummoner_arg_1": "Id'et for den enhed, hvis tilkalder vil blive fyret.",
	"func_getSummoner_return": "Id'et for den påkaldte <b>entitet</b>, hvis det er en påkaldelse, <i>null</i> ellers.",
	"func_getSummons": "Returnerer listen over id'er for aktuelt aktive påkald af entiteten med id <b>entity</b>.",
	"func_getSummons_arg_1": "Id'et for den enhed, hvis indkaldelse vil blive returneret.",
	"func_getSummons_return": "Listen over påkaldelses-id'er for enheden med id <b>entity</b>.",
	"func_getTeamID": "Returnerer team-id'et for enhed <b>entitet</b>.",
	"func_getTeamID_arg_1": "Id'et for den enhed, hvis team-id vil blive returneret.",
	"func_getTeamID_return": "Team-id'et for enhedens <b>entitet</b>.",
	"func_getTeamName": "Returnerer teamnavnet på entitetens <b>entitet</b>.",
	"func_getTeamName_arg_1": "Id'et for den enhed, hvis teamnavn vil blive returneret.",
	"func_getTeamName_return": "Holdnavnet på <b>enheden</b>-enheden.",
	"func_getTotalLife": "Returnerer den samlede levetid for enheden med id <b>entity</b>. Brug getTotalLife() uden parametre til at hente dit samlede liv.",
	"func_getTotalLife_arg_1": "Id'et for den enhed, hvis samlede levetid vil blive returneret.",
	"func_getTotalLife_return": "Enhedens samlede levetid.",
	"func_getTotalMP": "Returnerer det maksimale antal bevægelsespunkter for objektets <b>entitet</b>.",
	"func_getTotalMP_arg_1": "Id'et for den enhed, hvis maksimale bevægelsespoint vil blive returneret.",
	"func_getTotalMP_return": "Det maksimale antal bevægelsespunkter for <b>enheden</b>.",
	"func_getTotalTP": "Returnerer det maksimale antal vendepunkter for enhedens <b>entitet</b>.",
	"func_getTotalTP_arg_1": "Id'et for den enhed, hvis maksimale antal vendepunkter vil blive returneret.",
	"func_getTotalTP_return": "Det maksimale antal vendepunkter for <b>enheden</b>-enheden.",
	"func_getTP": "Returnerer antallet af vendepunkter for enhedens <b>entitet</b>. Brug getTP() uden parametre til at hente dine PT'er.",
	"func_getTP_arg_1": "Id'et for den enhed, hvis PT'er vil blive returneret.",
	"func_getTP_return": "PT-nummeret for <b>enheden</b>-enheden.",
	"func_getTurn": "Returnerer den aktuelle kampdrejning. Det maksimale antal omgange er #MAX_TURNS.",
	"func_getTurn_return": "Kampens nuværende tur.",
	"func_getType": "Returnerer enhedstypen for enhed <b>entitet</b>.",
	"func_getType_arg_1": "Id'et for den enhed, hvis type vil blive returneret.",
	"func_getType_return": "Enhedstypen for <b>entitet</b>:<ul><li>#ENTITY_LEEK hvis det er en porre.</li><li>#ENTITY_BULB hvis det er en 'en pære.</li><li >#ENTITY_TURRET hvis det er et tårn.</li></ul>",
	"func_getWeapon": "Returnerer den aktuelt udstyrede <b>entitet</b>.",
	"func_getWeapon_arg_1": "Id'et for den enhed, hvis nuværende våben vil blive returneret.",
	"func_getWeapon_return": "Id'et for det aktuelt udstyrede våben på <b>enheden</b>, null, hvis enheden ikke har noget udstyret våben, eller enheden ikke eksisterer.",
	"func_getWeaponArea": "Returnerer effektområdets type for <b>våben</b> våbnet.",
	"func_getWeaponArea_arg_1": "Det våben, hvis områdetype vil blive returneret.",
	"func_getWeaponArea_return": "<b>våben</b> våbnets arealtype blandt AREA_* konstanterne:\n<ul>\n<li>#AREA_POINT: enkelt kvadratisk område</li>\n<li>#AREA_LASER_LINE: linje af en laser</li>\n<li>#AREA_CIRCLE_1: cirkulært område med 3 celler i diameter</li>\n<li>#AREA_CIRCLE_2: cirkulært område med 5 kvadrater i diameter</li>\n<li>#AREA_CIRCLE_3: cirkulært område med 7 kvadrater i diameter</li>\n</ul>",
	"func_getWeaponCost": "Returnerer TP-omkostningerne for <b>våben</b>-våbnet.",
	"func_getWeaponCost_arg_1": "Id'et for det våben, hvis omkostninger vil blive returneret.",
	"func_getWeaponCost_return": "TP-omkostningerne for <b>våben</b>-våbnet.",
	"func_getWeaponEffectiveArea": "Returnerer listen over celler, der vil blive påvirket, hvis våbenet <b>våben</b> bruges på celle <b>celle</b> fra celle <b>fra</b>.",
	"func_getWeaponEffectiveArea_arg_1": "Våbenet til at teste.",
	"func_getWeaponEffectiveArea_arg_2": "Målcellen.",
	"func_getWeaponEffectiveArea_arg_3": "Cellen, hvorfra våbnet affyres.",
	"func_getWeaponEffectiveArea_return": "Arrayet, der indeholder id'erne for alle celler, der vil blive påvirket.",
	"func_getWeaponPassiveEffects": "Returnerer de passive effekter af våbenets <b>våben</b>.",
	"func_getWeaponPassiveEffects_arg_1": "Id'et for det våben, hvis passive effekter vil blive returneret.",
	"func_getWeaponPassiveEffects_return": "Et array, der indeholder effekterne af <b>våben</b> våbnet. Hver effekt er i sig selv en række af formen\n[type, min, max, drejninger, mål, modifikatorer]. Disse effekter er de samme som dem, der returneres af #getWeaponEffects.",
	"func_getWeaponFailure": "Returnerer <b>våben</b>-procenten for våbensvigt.",
	"func_getWeaponFailure_arg_1": "Id'et for det våben, hvis fejlprocent vil blive returneret.",
	"func_getWeaponFailure_return": "<b>våben</b> våbenmiss-procent, et heltal mellem <b>0</b> og <b>100</b>.",
	"func_getWeaponMaxRange": "Returnerer den maksimale rækkevidde for <b>våben</b> våbnet.",
	"func_getWeaponMaxRange_arg_1": "Id'et for det våben, hvis maksimale rækkevidde vil blive returneret.",
	"func_getWeaponMaxRange_return": "Den maksimale rækkevidde for <b>våben</b> våbnet.",
	"func_getWeaponMaxScope": "Returnerer den maksimale rækkevidde for <b>våben</b> våbnet.",
	"func_getWeaponMaxScope_arg_1": "Id'et for det våben, hvis maksimale rækkevidde vil blive returneret.",
	"func_getWeaponMaxScope_return": "Den maksimale rækkevidde for <b>våben</b> våbnet.",
	"func_getWeaponMinRange": "Returnerer minimumsområdet for <b>våben</b> våbnet.",
	"func_getWeaponMinRange_arg_1": "Id'et for det våben, hvis minimum rækkevidde vil blive returneret.",
	"func_getWeaponMinRange_return": "Minimumsrækkevidden for <b>våben</b> våbnet.",
	"func_getWeaponMinScope": "Returnerer minimumsområdet for <b>våben</b> våbnet.",
	"func_getWeaponMinScope_arg_1": "Id'et for det våben, hvis minimum rækkevidde vil blive returneret.",
	"func_getWeaponMinScope_return": "Minimumsrækkevidden for <b>våben</b> våbnet.",
	"func_getWeaponLaunchType": "Returnerer affyringstilstanden for <b>våben</b> våbnet blandt LAUNCH_TYPE_* konstanterne.",
	"func_getWeaponLaunchType_arg_1": "Id'et for det våben, hvis kastetilstand vil blive returneret. Standard til dit aktuelt udstyrede våben.",
	"func_getWeaponLaunchType_return": "Kastetilstanden for <b>våben</b> våbnet.",
	"func_getWeaponName": "Returnerer navnet på <b>våbenet</b> våbenet.",
	"func_getWeaponName_arg_1": "Id'et for det våben, hvis navn vil blive returneret.",
	"func_getWeaponName_return": "Navnet på <b>våbenet</b> våbenet.",
	"func_getWeapons": "Returnerer våben for enheden med id <b>entity</b>.",
	"func_getWeapons_arg_1": "Id'et for den enhed, hvis våben vil blive returneret.",
	"func_getWeapons_return": "Et array, der indeholder <b>entitet</b> våben-id'erne.",
	"func_getWeaponTargets": "Returnerer de enheder, der vil blive påvirket, hvis våbenet <b>våben</b> bruges på celle <b>celle</b>.",
	"func_getWeaponTargets_arg_1": "Våbenet til at teste.",
	"func_getWeaponTargets_arg_2": "Målcellen.",
	"func_getWeaponTargets_return": "Arrayet, der indeholder id'erne for alle enheder, der vil blive påvirket.",
	"func_getWisdom": "Returnerer visdommen for entiteten med id <b>entity</b>. Brug getWisdom() uden parametre til at hente din visdom.",
	"func_getWisdom_arg_1": "Id'et for den enhed, hvis visdom vil blive returneret.",
	"func_getWisdom_return": "Enhedens visdom med id <b>entity</b>.",
	"func_hypot": "Returnerer hypotenusen af den retvinklede trekant med siderne x og y. Svarer til <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "X-værdien.",
	"func_hypot_arg_2": "Y-værdien.",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "Bestemmer, om <b>element</b>-elementet er i <b>array</b>-arrayet.",
	"func_inArray_arg_1": "Søgetabellen.",
	"func_inArray_arg_2": "Varen der skal søges efter.",
	"func_inArray_return": "<i>true</i> hvis elementet er i arrayet, <i>false</i> ellers.",
	"func_include": "Inkluder AI med navnet <b>ai</b> i den aktuelle AI. <br><br><b>Advarsel</b>, include-funktionen må kun kaldes i hovedblokken, og dens parameter skal være en streng skrevet direkte\ni koden. For mere information, se afsnittet i selvstudiet: <a href='http://leekwas.com/help/tutorial#includes'>http://leekwas.com/help/tutorial#includes</a>.",
	"func_include_arg_1": "Navnet på den AI, der skal inkluderes.",
	"func_indexOf": "Bestemmer positionen for den første forekomst af strengen <b>søgning</b> i strengen <b>streng</b>, startende fra position <b>start</b>.",
	"func_indexOf_arg_1": "Den streng, hvor søgningen vil blive udført.",
	"func_indexOf_arg_2": "Den streng, der skal søges efter.",
	"func_indexOf_arg_3": "Søgningens startposition.",
	"func_indexOf_return": "Placeringen af den første forekomst af <b>søgning</b> i <b>streng</b> fra <b>start</b>, -1, hvis strengen ikke blev fundet.",
	"func_insert": "Indsætter et <b>element</b> element i <b>arrayet</b> ved position <b>position</b>.",
	"func_insert_arg_1": "Arrayet, hvor elementet vil blive indsat.",
	"func_insert_arg_2": "Elementet, der skal indsættes.",
	"func_insert_arg_3": "Indsættelsens position.",
	"func_isAlive": "Bestemmer, om en <b>entitet</b> er i live. Svarer til <i>getLife(<b>entity</b>) > 0</i>.",
	"func_isAlive_arg_1": "Id'et for den enhed, der skal testes.",
	"func_isAlive_return": "<i>sand</i> hvis <b>entitet</b> er i live, <i>falsk</i> hvis død.",
	"func_isAlly": "Bestemmer, om <b>entitet</b> er din allierede.",
	"func_isAlly_arg_1": "Id'et for den enhed, der skal testes.",
	"func_isAlly_return": "<i>sandt</i> hvis <b>enheden</b> er din allierede eller dig selv, <i>falsk</i> hvis det er en fjende.",
	"func_isChip": "Bestemmer, om en værdi er en konstant, der repræsenterer en chip.<br><br>isChip(CHIP_RAGE) = sand;<br>isChip(WEAPON_PISTOL) = falsk.",
	"func_isChip_arg_1": "Antallet, der skal bestemmes.",
	"func_isChip_return": "<i>sand</i> hvis værdien er en chipkonstant.",
	"func_isDead": "Bestemmer, om <b>enheden</b> er død. Svarer til <i>getLife(<b>entity</b>) == 0</i>.",
	"func_isDead_arg_1": "Id'et for den enhed, der skal testes.",
	"func_isDead_return": "<i>sandt</i> hvis <b>enheden</b> er død, <i>falsk</i> hvis den er i live.",
	"func_isEmpty": "Bestemmer om arrayet <b>array</b> er tomt. Svarer til <i>count(<b>array</b>) == 0</i>.",
	"func_isEmpty_arg_1": "Arrayet til at teste.",
	"func_isEmptyCell": "Bestemmer om en celle er tom.",
	"func_isEmptyCell_arg_1": "Cellen, der skal testes.",
	"func_isEmptyCell_return": "<i>sand</i> hvis cellen er tom, <i>false</i> ellers.",
	"func_isEmpty_return": "<i>true</i> hvis arrayet er tomt, <i>false</i> ellers.",
	"func_isEnemy": "Bestemmer, om <b>entitet</b> er din fjende.",
	"func_isEnemy_arg_1": "Id'et for den enhed, der skal testes.",
	"func_isEnemy_return": "<i>sandt</i> hvis <b>enheden</b> er en fjende, <i>falsk</i> hvis det er en allieret eller dig selv.",
	"func_isInlineChip": "Bestemmer, om <b>chippen</b> kun kan bruges online.",
	"func_isInlineChip_arg_1": "Id'et for den chip, der skal testes.",
	"func_isInlineChip_return": "<i>sand</i> hvis chippen kun er online, <i>false</i> ellers.",
	"func_isInlineWeapon": "Bestemmer, om <b>våben</b>-våbnet kun kan bruges online.",
	"func_isInlineWeapon_arg_1": "ID'et for det våben, der skal testes.",
	"func_isInlineWeapon_return": "<i>sandt</i> hvis våbnet kun kan bruges online, <i>falskt</i> ellers.",
	"func_isLeek": "Bestemmer, om indholdet af celle <b>celle</b> er en enhed.",
	"func_isLeek_arg_1": "Cellen, der skal testes.",
	"func_isLeek_return": "<i>sand</i> hvis cellen indeholder en enhed, <i>falsk</i> ellers.",
	"func_isEntity": "Bestemmer, om indholdet af celle <b>celle</b> er en enhed.",
	"func_isEntity_arg_1": "Cellen, der skal testes.",
	"func_isEntity_return": "<i>sand</i> hvis cellen indeholder en enhed, <i>falsk</i> ellers.",
	"func_isObstacle": "Bestemmer, om indholdet af celle <b>celle</b> er en hindring.",
	"func_isObstacle_arg_1": "Cellen, der skal testes.",
	"func_isObstacle_return": "<i>sand</i> hvis cellen indeholder en forhindring, <i>falsk</i> ellers.",
	"func_isOnSameLine": "Bestemmer om to celler <b>celle1</b> og <b>celle2</b> er på samme linje.",
	"func_isOnSameLine_arg_1": "Den første celle.",
	"func_isOnSameLine_arg_2": "Den anden celle.",
	"func_isOnSameLine_return": "<i>sand</i> hvis de to celler er på samme linje, <i>false</i> ellers.",
	"func_isStatic": "Returnerer, om <b>entitet</b> er statisk eller ej. En statisk enhed kan ikke flyttes eller flyttes.",
	"func_isStatic_arg_1": "Id'et for den enhed, der skal testes.",
	"func_isStatic_return": "<i>true</i> hvis <b>entitet</b> er statisk, <i>false</i> ellers.",
	"func_isSummon": "Returnerer, om <b>entitet</b> er en påkaldelse eller ej.",
	"func_isSummon_arg_1": "Id'et for den enhed, der skal testes.",
	"func_isSummon_return": "<i>sand</i> hvis <b>entitet</b> er en påkaldelse, <i>falsk</i> ellers.",
	"func_isWeapon": "Bestemmer om en værdi er en konstant, der repræsenterer et våben.<br><br>isWeapon(WEAPON_LASER) = sand;<br>isWeapon(CHIP_TELEPORTATION) = falsk.",
	"func_isWeapon_arg_1": "Antallet, der skal bestemmes.",
	"func_isWeapon_return": "<i>sand</i> hvis værdien er en våbenkonstant.",
	"func_join": "Fletter flere elementer til en streng og adskiller dem med et <b>lim</b>-skilletegn.",
	"func_join_arg_1": "En række elementer, der skal flettes.",
	"func_join_arg_2": "Elementafgrænseren.",
	"func_join_return": "Den resulterende streng af fletningen.",
	"func_jsonDecode": "Afkoder streng <b>json</b> til LeekScript-objekt (nummer, streng, matrix...).",
	"func_jsonDecode_arg_1": "JSON-strengen, der skal afkodes.",
	"func_jsonDecode_return": "Det afkodede LeekScript-objekt.",
	"func_jsonEncode": "Koder <b>objekt</b>-objektet til en JSON-streng.",
	"func_jsonEncode_arg_1": "Objektet, der skal kodes i JSON.",
	"func_jsonEncode_return": "Den JSON-kodede streng.",
	"func_keySort": "Sorterer <b>arrayet</b> efter nøgler i <b>rækkefølge</b>.",
	"func_keySort_arg_1": "Matrixen til at sortere.",
	"func_keySort_arg_2": "Sorteringsrækkefølgen: #SORT_ASC eller #SORT_DESC.",
	"func_length": "Returnerer længden af <b>streng</b>.",
	"func_length_arg_1": "Den streng, hvis længde vil blive returneret.",
	"func_length_return": "Længden af <b>streng</b>.",
	"func_lineOfSight": "Kontrollerer sigtelinjen mellem <b>start</b>-cellen og <b>slut</b>-cellen og ignorerer <b>ignoredEntities</b>-enheder.",
	"func_lineOfSight_arg_1": "Startcelle.",
	"func_lineOfSight_arg_2": "Målcelle.",
	"func_lineOfSight_arg_3": "En enhed eller liste over enheder, der skal ignoreres.",
	"func_lineOfSight_return": "Returnerer <i>sand</i>, hvis sigtelinjen er fri.",
	"func_listen": "Returnerer arrayet af say() af tidligere entiteter i formen [entity_id, message].",
	"func_listen_return": "Rækken af tidligere say()s.",
	"func_log": "Beregner den naturlige logaritme af tallet <b>tallet</b>.",
	"func_log_arg_1": "Et tal i området ]0; +∞[.",
	"func_log_return": "Den naturlige logaritme af <b>tal</b>.",
	"func_log10": "Beregner grundtallet 10-logaritmen for tallet <b>tallet</b>.",
	"func_log10_arg_1": "Et tal i området ]0; +∞[.",
	"func_log10_return": "Grundtallet 10-logaritmen af <b>tal</b>.",
	"func_log2": "Beregner grundtallet 2-logaritmen for tallet <b>tallet</b>.",
	"func_log2_arg_1": "Et tal i området ]0; +∞[.",
	"func_log2_return": "Grundtallet 2-logaritmen for <b>tal</b>.",
	"func_mark": "Markerer en eller flere celler i den farve, der er angivet i parameteren, på jorden for det antal drejninger, der er angivet i parameteren. Denne mærkning er kun synlig for opdrætteren af enheden.",
	"func_mark_arg_1": "Cellen eller matrixen af flere celler, der skal markeres",
	"func_mark_arg_2": "Markeringsfarve",
	"func_mark_arg_3": "Mærkningens varighed",
	"func_mark_return": "Returnerer sandt, hvis alt gik godt",
	"func_markText": "Skriver en tekst på en eller flere celler af den farve, der er angivet i parameter på jorden for det antal drejninger, der er angivet i parameter. Disse tekster er kun synlige for opdrætteren af enheden.",
	"func_markText_arg_1": "Cellen eller rækken af flere celler, der skal skrives til",
	"func_markText_arg_2": "Teksten der skal skrives (maks. 10 tegn)",
	"func_markText_arg_3": "Tekstfarve",
	"func_markText_arg_4": "Tekstvarighed",
	"func_markText_return": "Returnerer sandt, hvis alt gik godt",
	"func_max": "Returnerer den største værdi mellem de to tal <b>a</b> og <b>b</b>.",
	"func_max_arg_1": "Et nummer.",
	"func_max_arg_2": "Et nummer.",
	"func_max_return": "Det største tal mellem <b>a</b> og <b>b</b>.",
	"func_min": "Returnerer den mindste værdi mellem de to tal <b>a</b> og <b>b</b>.",
	"func_min_arg_1": "Et nummer.",
	"func_min_arg_2": "Et nummer.",
	"func_min_return": "Det mindre tal mellem <b>a</b> og <b>b</b>.",
	"func_moveAwayFrom": "Flytter din enhed væk fra en anden <b>entitet</b> ved at bruge maksimale <b>mp</b> bevægelsespunkter.",
	"func_moveAwayFrom_arg_1": "Enheden din enhed skal flytte væk fra.",
	"func_moveAwayFrom_arg_2": "Det maksimale antal MP at bruge. Som standard ingen grænse.",
	"func_moveAwayFrom_return": "Antallet af anvendte bevægelsespunkter.",
	"func_moveAwayFromCell": "Flytter din enhed én <b>celle</b> celle væk ved at bruge op til <b>mp</b> bevægelsespunkter.",
	"func_moveAwayFromCell_arg_1": "Cellen din enhed skal flytte væk fra.",
	"func_moveAwayFromCell_arg_2": "Det maksimale antal MP at bruge. Som standard ingen grænse.",
	"func_moveAwayFromCell_return": "Antallet af anvendte bevægelsespunkter.",
	"func_moveAwayFromCells": "Flytter din enhed væk fra et sæt <b>celler</b>-celler ved at bruge maksimale <b>mp</b>-bevægelsespunkter.",
	"func_moveAwayFromCells_arg_1": "Arrayet, der indeholder de celler, din enhed skal flytte væk fra.",
	"func_moveAwayFromCells_arg_2": "Det maksimale antal MP at bruge. Som standard ingen grænse.",
	"func_moveAwayFromCells_return": "Antallet af anvendte bevægelsespunkter.",
	"func_moveAwayFromLeeks": "Flytter din enhed væk fra et sæt <b>enheder</b> ved at bruge maksimale <b>mp</b> bevægelsespunkter.",
	"func_moveAwayFromLeeks_arg_1": "Arrayet, der indeholder id'erne for de entiteter, din enhed skal flytte væk fra.",
	"func_moveAwayFromLeeks_arg_2": "Det maksimale antal MP at bruge. Som standard ingen grænse.",
	"func_moveAwayFromLeeks_return": "Antallet af anvendte bevægelsespunkter.",
	"func_moveAwayFromEntities": "Flytter din enhed væk fra et sæt <b>enheder</b> ved at bruge maksimale <b>mp</b> bevægelsespunkter.",
	"func_moveAwayFromEntities_arg_1": "Arrayet, der indeholder id'erne for de entiteter, din enhed skal flytte væk fra.",
	"func_moveAwayFromEntities_arg_2": "Det maksimale antal MP at bruge. Som standard ingen grænse.",
	"func_moveAwayFromEntities_return": "Antallet af anvendte bevægelsespunkter.",
	"func_moveAwayFromLine": "Flytter din enhed væk fra en linje defineret af to celler <b>celle1</b> og <b>celle2</b> med maksimalt <b>mp</b> bevægelsespunkter.",
	"func_moveAwayFromLine_arg_1": "Celle 1.",
	"func_moveAwayFromLine_arg_2": "Celle 2.",
	"func_moveAwayFromLine_arg_3": "Det maksimale antal MP at bruge. Som standard ingen grænse.",
	"func_moveAwayFromLine_return": "Antallet af anvendte bevægelsespunkter.",
	"func_moveToward": "Bringer din enhed tættere på en anden <b>entitet</b> ved at bruge maksimale <b>mp</b> bevægelsespunkter.",
	"func_moveToward_arg_1": "Den enhed din virksomhed skal henvende sig til.",
	"func_moveToward_arg_2": "Det maksimale antal MP at bruge.",
	"func_moveToward_return": "Antallet af anvendte bevægelsespunkter.",
	"func_moveTowardCell": "Flytter din enhed en <b>celle</b> celle tættere på ved at bruge maksimale <b>mp</b> bevægelsespunkter.",
	"func_moveTowardCell_arg_1": "Den celle din funktion skal nærme sig.",
	"func_moveTowardCell_arg_2": "Det maksimale antal MP at bruge. Som standard ingen grænse.",
	"func_moveTowardCell_return": "Antallet af anvendte bevægelsespunkter.",
	"func_moveTowardCells": "Flytter din enhed tættere på et sæt <b>celler</b>-celler ved at bruge maksimale <b>mp</b>-bevægelsespunkter.",
	"func_moveTowardCells_arg_1": "Arrayet, der indeholder de celler, din enhed skal nærme sig.",
	"func_moveTowardCells_arg_2": "Det maksimale antal MP at bruge. Som standard ingen grænse.",
	"func_moveTowardCells_return": "Antallet af anvendte bevægelsespunkter.",
	"func_moveTowardLeeks": "Bringer din enhed tættere på et sæt <b>enheder</b> ved at bruge maksimale <b>mp</b> bevægelsespunkter.",
	"func_moveTowardLeeks_arg_1": "Arrayet, der indeholder id'erne for de entiteter, din enhed skal henvende sig til.",
	"func_moveTowardLeeks_arg_2": "Det maksimale antal MP at bruge. Som standard ingen grænse.",
	"func_moveTowardLeeks_return": "Antallet af anvendte bevægelsespunkter.",
	"func_moveTowardEntities": "Bringer din enhed tættere på et sæt <b>enheder</b> ved at bruge maksimale <b>mp</b> bevægelsespunkter.",
	"func_moveTowardEntities_arg_1": "Arrayet, der indeholder id'erne for de entiteter, din enhed skal henvende sig til.",
	"func_moveTowardEntities_arg_2": "Det maksimale antal MP at bruge. Som standard ingen grænse.",
	"func_moveTowardEntities_return": "Antallet af anvendte bevægelsespunkter.",
	"func_moveTowardLine": "Bringer din enhed tættere på en linje defineret af to celler <b>celle1</b> og <b>celle2</b> ved at bruge maksimale <b>mp</b> bevægelsespunkter.",
	"func_moveTowardLine_arg_1": "Celle 1.",
	"func_moveTowardLine_arg_2": "Celle 2.",
	"func_moveTowardLine_arg_3": "Det maksimale antal MP at bruge. Som standard ingen grænse.",
	"func_moveTowardLine_return": "Antallet af anvendte bevægelsespunkter.",
	"func_number": "Konverterer en værdi til et tal. Hvis værdien er en streng, vil talfunktionen forsøge at konvertere den til et tal, hvis værdien allerede er et tal, returnerer funktionen tallet, og for enhver anden type returnerer den <i>null</i>.",
	"func_number_arg_1": "Værdien, der skal konverteres til et tal.",
	"func_number_return": "Det konverterede tal.",
	"func_pause": "Sætter kampen på pause, kun for opdrætteren af den enhed, der bruger funktionen.",
	"func_pop": "Fjerner den sidste celle fra <b>array</b>-arrayet og returnerer det tilsvarende element.",
	"func_pop_arg_1": "Det array, hvis sidste boks vil blive slettet.",
	"func_pop_return": "Varen der var i den gamle sidste kasse.",
	"func_pow": "Hæver grundtallet til eksponentpotensen.",
	"func_pow_arg_1": "Basen.",
	"func_pow_arg_2": "Udstilleren.",
	"func_pow_return": "<b>base</b> hævet til <b>eksponent</b> potens.",
	"func_push": "Tilføjer <b>element</b>-elementet til slutningen af <b>arrayet</b>.",
	"func_pushAll": "Tilføjer alle elementer i <b>elements</b>-arrayet til slutningen af <b>array</b>.",
	"func_pushAll_arg_1": "Arrayet, hvor vi vil tilføje elementerne.",
	"func_pushAll_arg_2": "Elementer, der skal tilføjes.",
	"func_push_arg_1": "Det array, hvor vi vil tilføje elementet.",
	"func_push_arg_2": "Elementet, der skal tilføjes.",
	"func_rand": "Returnerer et reelt tilfældigt tal, mellem 0 (inklusive) og 1 (eksklusiv).",
	"func_randFloat": "Returnerer et reelt tilfældigt tal mellem <b>a</b> (inklusive) og <b>b</b> (eksklusivt).",
	"func_randFloat_arg_1": "Nedre grænse.",
	"func_randFloat_arg_2": "Øvre grænse.",
	"func_randFloat_return": "Et tilfældigt tal i området [a; b[.",
	"func_randInt": "Returnerer et tilfældigt heltal mellem <b>a</b> (inklusive) og <b>b</b> (eksklusivt).",
	"func_randInt_arg_1": "Nedre grænse.",
	"func_randInt_arg_2": "Øvre grænse.",
	"func_randInt_return": "Et tilfældigt heltal i området [a; b[.",
	"func_rand_return": "Et tilfældigt tal i området [0; 1[.",
	"func_remove": "Fjerner et element fra arrayet <b>array</b> ved position <b>position</b> og returnerer det slettede element.",
	"func_remove_arg_1": "Array, hvorfra vi ønsker at slette et element.",
	"func_remove_arg_2": "Placering af elementet, der skal slettes.",
	"func_removeElement": "Sletter den første forekomst af et <b>element</b> i <b>arrayet</b>. Hvis elementet ikke findes, ændres arrayet ikke.",
	"func_removeElement_arg_1": "Array, hvorfra vi ønsker at slette et element.",
	"func_removeElement_arg_2": "Element, der skal findes og derefter slettes.",
	"func_removeKey": "Sletter et element fra arrayet <b>array</b> forbundet med nøglen <b>nøgle</b>.",
	"func_removeKey_arg_1": "Array, hvorfra vi ønsker at slette et element.",
	"func_removeKey_arg_2": "Nøglen, der er knyttet til det element, der skal slettes.",
	"func_remove_return": "Elementet fjernet fra arrayet.",
	"func_replace": "Erstatter alle forekomster af <b>søgning</b> med <b>erstat</b> i strengen <b>streng</b>.",
	"func_replace_arg_1": "String, hvori der foretages udskiftninger.",
	"func_replace_arg_2": "Understreng, der skal erstattes.",
	"func_replace_arg_3": "Erstatningsstreng.",
	"func_replace_return": "Resultatstrengen med udskiftninger.",
	"func_resurrect": "Bruger CHIP_RESURRECTION chippen til at genoplive en død enhed med id <b>entity</b> på celle <b>celle</b>.",
	"func_resurrect_arg_1": "Id'et for den enhed, der skal genoplives.",
	"func_resurrect_arg_2": "Den celle, som enheden vil genoprette.",
	"func_resurrect_return": "Resultatet af lanceringen af chippen, blandt konstanterne USE_*.",
	"func_reverse": "Vender <b>arrayet</b> om.",
	"func_reverse_arg_1": "Tabel til at vende.",
	"func_round": "Returnerer afrundingen af <b>tal</b>.<br/>Du kan finde afrundingen nedad med #gulv og afrundingen opad med #loft.",
	"func_round_arg_1": "Tal, hvis afrunding vi ønsker at finde.",
	"func_round_return": "Afrundet efter <b>tal</b>.",
	"func_say": "Få din enhed til at tale.",
	"func_say_arg_1": "Besked, som din enhed vil annoncere i arenaen.",
	"func_search": "Finder elementet <b>element</b> i arrayet <b>array</b>, startende fra position <b>start</b>.",
	"func_search_arg_1": "Søgetabellen.",
	"func_search_arg_2": "Varen der skal søges efter.",
	"func_search_arg_3": "Positionen for starten af søgningen.",
	"func_search_return": "Placeringen af den første forekomst af <b>element</b>-elementet i <b>arrayet</b>, startende fra <b>start</b>, null, hvis elementet ikke er blevet fundet.",
	"func_sendAll": "Send en besked til hele dit team.",
	"func_sendAll_arg_1": "Den type besked, der skal sendes (se MESSAGE_* konstanterne).",
	"func_sendAll_arg_2": "Meddelelsesparametrene, som kan være en hvilken som helst værdi.",
	"func_sendTo": "Sender en besked til enheden med id <b>entity</b>.",
	"func_sendTo_arg_1": "Id'et for den enhed, som meddelelsen vil blive sendt til.",
	"func_sendTo_arg_2": "Den type besked, der skal sendes (se MESSAGE_* konstanterne).",
	"func_sendTo_arg_3": "Meddelelsesparametrene, som kan være en hvilken som helst værdi.",
	"func_sendTo_return": "<i>true</i> hvis afsendelsen lykkedes, <i>false</i> hvis der opstod en fejl.",
	"func_setRegister": "Gemmer <b>værdien</b> i <b>nøgle</b> registreringsdatabasen.\nNøglen og værdien er strenge, som højst må indeholde <i>100</i> og <i>5000</i> tegn. En porre kan maksimalt have <i>100</i> registre, lagring i et nyt register fungerer ikke, hvis alle registre\ner allerede besat.",
	"func_setRegister_arg_1": "Registreringsnøglen, hvor værdien skal gemmes.",
	"func_setRegister_arg_2": "Værdien at opbevare.",
	"func_setRegister_return": "<i>sand</i> hvis handlingen lykkedes, ellers <i>false</i>.",
	"func_setWeapon": "Udstyr <b>våben</b>-våbenet på din enhed.",
	"func_setWeapon_arg_1": "Id for det våben, der skal udstyres.",
	"func_shift": "Fjerner den første celle fra <b>arrayet</b> og returnerer det tilsvarende element.",
	"func_shift_arg_1": "Det array, hvis første boks vil blive slettet.",
	"func_shift_return": "Elementet, der var i den gamle første celle.",
	"func_show": "Viser spillerne en <b>celle</b> af farven <b>farve</b> på banen i 1 omgang. Brug af denne funktion koster 1PT.",
	"func_show_arg_1": "Cellen der skal vises",
	"func_show_arg_2": "Markeringsfarve",
	"func_show_return": "Returnerer sandt, hvis alt gik godt",
	"func_shuffle": "Blander et bræt tilfældigt.",
	"func_shuffle_arg_1": "Blandebrættet.",
	"func_signum": "Bestemmer tegnet for tallet <b>tallet</b>.",
	"func_signum_arg_1": "Det nummer, hvis tegn vil blive bestemt.",
	"func_signum_return": "1 hvis tallet er positivt, 0 hvis tallet er nul, og -1 hvis tallet er negativt.",
	"func_sin": "Beregner sinus for vinklen <b>vinklen</b> sendt som parameter",
	"func_sin_arg_1": "Vinklen, hvis sinus vil blive beregnet",
	"func_sin_return": "Returnerer sinus for vinklen <b>vinklen</b>",
	"func_sort": "Sorterer <b>arrayet</b> i stigende eller faldende rækkefølge. Standard stigende rækkefølge er:\n<ul>\n<li>Alle booleaner (først falsk og derefter sand)</li>\n<li>Alle tal (ordnet fra mindste til største)</li>\n<li>Alle kanaler (i alfabetisk rækkefølge)</li>\n<li>Alle null-elementer.</li>\n</ul>",
	"func_sort_arg_1": "Array til at sortere.",
	"func_sort_arg_2": "#SORT_ASC for at sortere <b>array</b> i stigende rækkefølge eller #SORT_DESC for at sortere det i faldende rækkefølge.",
	"func_split": "Opdeler strengen <b>streng</b> i understrenge afgrænset af <b>adskiller</b>.",
	"func_split_arg_1": "Skære kæde.",
	"func_split_arg_2": "Streng, der afgrænser passagen fra et element til et andet.",
	"func_split_arg_3": "Maksimalt antal array-elementer.",
	"func_split_return": "Array, der indeholder de fundne understrenge.",
	"func_sqrt": "Beregner kvadratroden af tallet <b>tal</b>.",
	"func_sqrt_arg_1": "Tal, hvis rod vil blive beregnet.",
	"func_sqrt_return": "Kvadratroden af <b>tal</b>.",
	"func_startsWith": "Kontrollerer, om <b>streng</b> begynder med <b>præfiks</b>.",
	"func_startsWith_arg_1": "Søgestreng.",
	"func_startsWith_arg_2": "Søgte præfiks.",
	"func_startsWith_return": "Sandt, hvis <b>strengen</b> begynder med <b>præfiks</b>.",
	"func_string": "Konverterer en værdi til en streng.<br>\nHvis værdien allerede er en streng, returneres den.<br>\nHvis værdien er nummer x, returneres \"x\".<br>\nHvis værdien er et array, returneres en streng med formen \"[nøgle1: værdi1, nøgle2: værdi2, ...]\".<br>\nHvis værdien er en boolesk værdi, returneres \"true\" og \"false\" for henholdsvis sand og falsk.<br>\nHvis værdien er null, returneres \"null\".",
	"func_string_arg_1": "Værdien, der skal konverteres til en tegnstreng.",
	"func_string_return": "Den konverterede streng.",
	"func_subArray": "Returnerer en undermatrix af <b>matrix</b>, der starter ved position <b>start</b> og slutter ved position <b>slut</b>.",
	"func_subArray_arg_1": "Kilde tabel.",
	"func_subArray_arg_2": "Startindeks.",
	"func_subArray_arg_3": "Slutindeks.",
	"func_subArray_return": "Underarray.",
	"func_substring": "Returnerer understrengen af strengen <b>streng</b> startende fra tegnet ved indeks <b>start</b> og af størrelse <b>længde</b>.",
	"func_substring_arg_1": "Skære kæde.",
	"func_substring_arg_2": "Startkarakterindeks.",
	"func_substring_arg_3": "Længden af understrengen.",
	"func_substring_return": "Understreng.",
	"func_sum": "Returnerer summen af alle de numeriske elementer i arrayet <b>array</b>.",
	"func_sum_arg_1": "Tabel for at opsummere.",
	"func_summon": "Kalder en enhed bestemt af chippen <b>chip</b> på cellen <b>celle</b> med funktionen <b>ai</b> for IA.",
	"func_summon_arg_1": "Chippen brugt til tilkaldelse. Chippen skal være en summon-chip og skal være udstyret på den enhed, der bruger summon-funktionen.",
	"func_summon_arg_2": "Cellen eller indkaldelsen skal vises.",
	"func_summon_arg_3": "Invokationen AI, som en funktion.",
	"func_summon_return": "Summon-funktionen har samme retur som #useChip-funktionen.",
	"func_sum_return": "Summen af array-elementer.",
	"func_tan": "Beregner tangens af vinklen <b>vinkel</b> (i radianer).",
	"func_tan_arg_1": "Vinkel i radianer.",
	"func_tan_return": "Tangent af <b>vinkel</b>.",
	"function_category_array": "Lister",
	"function_category_chip": "Lopper",
	"function_category_color": "Farve",
	"function_category_fight": "Kæmpe",
	"function_category_leek": "Porrer",
	"function_category_map": "Tabeller",
	"function_category_field": "Jord",
	"function_category_math": "Tal",
	"function_category_network": "Netværk",
	"function_category_string": "Kæder",
	"function_category_utils": "Hjælpeprogrammer",
	"function_category_weapon": "Våben",
	"func_toDegrees": "Konverterer vinklen <b>radianer</b> til grader.",
	"func_toDegrees_arg_1": "Værdi i radianer",
	"func_toDegrees_return": "Vinkel i grader.",
	"func_toLower": "Konverterer store bogstaver i <b>streng</b> til små bogstaver.",
	"func_toLower_arg_1": "String til at transformere",
	"func_toLower_return": "Snor med små bogstaver",
	"func_toRadians": "Konverterer vinklen <b>grader</b> til radianer.",
	"func_toRadians_arg_1": "Værdi i grader",
	"func_toRadians_return": "Vinkel i radianer.",
	"func_toUpper": "Konverterer små bogstaver i <b>streng</b> til store bogstaver.",
	"func_toUpper_arg_1": "String til at transformere.",
	"func_toUpper_return": "Store bogstaver streng.",
	"func_typeOf": "Returnerer typen af værdien <b>værdi</b> blandt typerne: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "Værdien, hvis type vil blive returneret.",
	"func_typeOf_return": "Typen <b>værdi</b>.",
	"func_unshift": "Tilføjer <b>element</b>-elementet til begyndelsen af <b>arrayet</b>.",
	"func_unshift_arg_1": "Array, hvor elementet vil blive tilføjet.",
	"func_unshift_arg_2": "Element, der skal tilføjes.",
	"func_useChip": "Brug chip <b>chip</b> på entity <b>entity</b>.",
	"func_useChip_arg_1": "Chip at bruge.",
	"func_useChip_arg_2": "Målenhed.",
	"func_useChipOnCell": "Brug chip <b>chip</b> på celle <b>celle</b>.",
	"func_useChipOnCell_arg_1": "Chip at bruge.",
	"func_useChipOnCell_arg_2": "Målcelle.",
	"func_useChipOnCell_return": "En værdi større end 0, hvis angrebet blev iværksat.",
	"func_useChip_return": "Returværdierne for useChip er:<br>\n<ul>\n<li>#USE_CRITICAL, ved kritisk hit</li>\n<li>#USE_SUCCESS, hvis det lykkes</li>\n<li>#USE_FAILED, ved fejl</li>\n<li>#USE_INVALID_TARGET, hvis målet ikke findes</li>\n<li>#USE_NOT_ENOUGH_TP, hvis din enhed ikke har nok TP</li>\n<li>#USE_INVALID_COOLDOWN, hvis chippen ikke kan bruges endnu</li>\n<li>#USE_INVALID_POSITION, hvis rækkevidden er dårlig eller sigtelinjen ikke er klar</li>\n</ul>",
	"func_useWeapon": "Brug det valgte våben på <b>entitet</b>.",
	"func_useWeapon_arg_1": "Målenhed.",
	"func_useWeaponOnCell": "Brug det valgte våben i <b>celle</b>-cellen.",
	"func_useWeaponOnCell_arg_1": "Målcelle.",
	"func_useWeaponOnCell_return": "En værdi større end 0, hvis angrebet blev iværksat.",
	"func_useWeapon_return": "Returværdierne for useWeapon er:<br>\n<ul>\n<li>#USE_CRITICAL, ved kritisk hit</li>\n<li>#USE_SUCCESS, hvis det lykkes</li>\n<li>#USE_FAILED, ved fejl</li>\n<li>#USE_INVALID_TARGET, hvis målet ikke findes</li>\n<li>#USE_NOT_ENOUGH_TP, hvis din enhed ikke har nok TP</li>\n<li>#USE_INVALID_POSITION, hvis rækkevidden er dårlig eller sigtelinjen ikke er klar</li>\n</ul>",
	"func_weaponNeedLos": "Returnerer, om <b>våbenet</b>-våbnet har brug for synsvidde for at skyde.",
	"func_weaponNeedLos_arg_1": "ID'et for det våben, der skal testes.",
	"func_weaponNeedLos_return": "<i>sandt</i> hvis <b>våbenet</b> våbnet har brug for synsvidde for at skyde, <i>falskt</i> ellers.",
	"items": "Genstande",
	"max_cores": "max",
	"max_level": "max",
	"min_cores": "Minimum hjerter",
	"min_level": "Min-niveau",
	"n_operations": "<b>{0}</b> handlinger",
	"parameters": "Indstillinger",
	"return": "Feedback",
	"value": "Værdi",
	"variable_operations": "<b>Variabel</b> operationer",
	"deprecated_function": "Denne funktion er forældet.",
	"deprecated_constant": "Denne konstant er forældet.",
	"replaced_by": "Den erstattes af {0}.",
	"details": "Detaljer",
	"optional": "valgfri",
	"arg_type_9": "pakke",
	"arg_type_10": "interval",
	"function_category_set": "Pakker",
	"function_category_interval": "Intervaller",
	"arg_type_96": "set<entier>"
}

	i18n.mergeLocaleMessage("da", { "doc": messages })
	