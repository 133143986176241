
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> operation",
	"arg_type_-1": "?",
	"arg_type_1": "siffra",
	"arg_type_2": "kedja",
	"arg_type_3": "booleskt",
	"arg_type_4": "lista",
	"arg_type_41": "lista<nummer>",
	"arg_type_42": "lista<sträng>",
	"arg_type_43": "lista<boolesk>",
	"arg_type_44": "lista<lista>",
	"arg_type_46": "lista<heltal>",
	"arg_type_47": "lista<riktig>",
	"arg_type_5": "fungera",
	"arg_type_6": "hel",
	"arg_type_7": "verklig",
	"arg_type_8": "tabell",
	"arg_type_806": "tabell<?, heltal>",
	"complexity": "Komplexitet {c}",
	"operations": "{o} operationer",
	"const_AREA_CIRCLE_1": "Cirkulär area 3 rutor i diameter (kors).",
	"const_AREA_CIRCLE_2": "Cirkulär yta 5 rutor i diameter.",
	"const_AREA_CIRCLE_3": "Cirkulär yta 7 rutor i diameter.",
	"const_AREA_LASER_LINE": "Zon för en laser, linje från laserns minsta räckvidd till dess maximala räckvidd eller ett hinder.",
	"const_AREA_POINT": "Område som består av en enda cell.",
	"const_CELL_EMPTY": "Returvärdet för getCellContent(cell) för en tom cell.",
	"const_CELL_OBSTACLE": "Returvärdet för getCellContent(cell) för en cell som innehåller ett hinder.",
	"const_CELL_PLAYER": "Returvärdet för getCellContent(cell) för en cell som innehåller en entitet.",
	"const_CELL_ENTITY": "Returvärdet för getCellContent(cell) för en cell som innehåller en entitet.",
	"const_COLOR_BLUE": "Färgen blå.",
	"const_COLOR_GREEN": "Grön färg.",
	"const_COLOR_RED": "Röd färg.",
	"const_E": "Eulers nummer.",
	"const_EFFECT_ABSOLUTE_SHIELD": "Ger absolut skydd till en enhet, vilket minskar antalet träffpoäng som tas bort av skada (EFFECT_DAMAGE) med ett fast belopp. Förstärks av motstånd.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "Tar bort absolut sköld från en enhet. Förstärks inte av en egenskap. Låter dig öka träffpoäng borttagna av skada (EFFECT_DAMAGE) med ett absolut belopp.",
	"const_EFFECT_AFTEREFFECT": "Tar bort träffpunkter från en enhet. Förstärkt av vetenskap. Minskar maximala träffpoäng med 5 % av antalet träffpoäng som tas bort.",
	"const_EFFECT_ANTIDOTE": "Tar bort allt gift (EFFECT_POISON) som finns på ett mål.",
	"const_EFFECT_BOOST_MAX_LIFE": "Ökar träffpoäng och maximal träffpoäng för en enhet. Förstärkt av visdom.",
	"const_EFFECT_BUFF_AGILITY": "Ger smidighet till en enhet. Förstärkt av vetenskap.",
	"const_EFFECT_BUFF_MP": "Ger rörelsepoäng till en enhet. Förstärkt av vetenskap.",
	"const_EFFECT_BUFF_RESISTANCE": "Ger motstånd till en enhet. Förstärkt av vetenskap.",
	"const_EFFECT_BUFF_STRENGTH": "Ger styrka till en enhet. Förstärkt av vetenskap.",
	"const_EFFECT_BUFF_TP": "Ger åtgärdspunkter till en enhet. Förstärkt av vetenskap.",
	"const_EFFECT_BUFF_WISDOM": "Ge visdom till en enhet. Förstärkt av vetenskap.",
	"const_EFFECT_DAMAGE": "Tar bort träffpunkter från en enhet. Force Amplified. Interagerar med sköldar (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), lifesteal (förutom caster) och skaderetur (EFFECT_DAMAGE_RETURN). Minskar maximala träffpoäng med 5 % av antalet träffpoäng som tas bort.",
	"const_EFFECT_DAMAGE_RETURN": "Ger skadereflektion till en enhet, vilket gör det möjligt att ta bort träffpunkter från enheter som orsakar skada på mottagaren. Förstärks av smidighet. Minskar maximala träffpoäng med 5 % av antalet träffpoäng som tas bort.",
	"const_EFFECT_DEBUFF": "Minskar värdet av alla effekter på en enhet med en procentsats.",
	"const_EFFECT_HEAL": "Återställer träffpunkter till en enhet, begränsad av de maximala träffpunkterna. Förstärkt av visdom.",
	"const_EFFECT_INVERT": "Byter positionen för hjulet med den för en enhet.",
	"const_EFFECT_KILL": "Tar bort alla träffpunkter från en enhet.",
	"const_EFFECT_LIFE_DAMAGE": "Tar bort träffpoäng från en enhet, baserat på en procentandel av kastarens träffpoäng. Interagerar med sköldar (EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) och skaderetur (EFFECT_DAMAGE_RETURN). Minskar maximala träffpoäng med 5 % av antalet träffpoäng som tas bort.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "Effekten multipliceras med antalet berörda enheter i området.",
	"const_EFFECT_MODIFIER_ON_CASTER": "Effekten påverkar fortfarande hjulet.",
	"const_EFFECT_MODIFIER_STACKABLE": "Effekten är kumulativ.",
	"const_EFFECT_NOVA_DAMAGE": "Tar bort max livspoäng. Förstärkt av vetenskap.",
	"const_EFFECT_POISON": "Tar bort träffpunkter från en enhet. Förstärkt av magi. Minskar maximala träffpoäng med 10 % av antalet träffpoäng som tas bort.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "Ger absolut skydd till en enhet, vilket minskar antalet träffpoäng som tas bort av skada (EFFECT_DAMAGE) med ett fast belopp. Ej förstärkningsbar.",
	"const_EFFECT_RAW_BUFF_AGILITY": "Ger smidighet till en enhet. Ej förstärkningsbar.",
	"const_EFFECT_RAW_BUFF_MAGIC": "Ger magi till en enhet. Ej förstärkningsbar.",
	"const_EFFECT_RAW_BUFF_MP": "Ger rörelsepoäng till en enhet. Ej förstärkningsbar.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "Beviljer vetenskap till en enhet. Ej förstärkningsbar.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "Ger styrka till en enhet. Ej förstärkningsbar.",
	"const_EFFECT_RAW_BUFF_TP": "Ger åtgärdspunkter till en enhet. Ej förstärkningsbar.",
	"const_EFFECT_RELATIVE_SHIELD": "Ger en relativ sköld, vilket minskar antalet träffpunkter som tas bort av skada (EFFECT_DAMAGE) med ett relativt belopp. Förstärks av motstånd.",
	"const_EFFECT_RESURRECT": "Återupplivar en enhet, med ett maximalt HP-tal som är lika med hälften av enhetens maximala HP-tal före uppståndelsen, och ett aktuellt HP-tal som är lika med en fjärdedel av det maximala HP-talet före uppståndelsen.",
	"const_EFFECT_SHACKLE_MAGIC": "Ta bort magi från en enhet. Förstärkt av magi.",
	"const_EFFECT_SHACKLE_MP": "Ta bort rörelsepunkter från en enhet. Förstärkt av magi.",
	"const_EFFECT_SHACKLE_STRENGTH": "Tar bort styrka från en enhet. Förstärkt av magi.",
	"const_EFFECT_SHACKLE_TP": "Dra av åtgärdspoäng från en enhet. Förstärkt av magi.",
	"const_EFFECT_SUMMON": "Kallar fram en glödlampa. Ingen effekt om laginkallningsgränsen nås.",
	"const_EFFECT_TARGET_ALLIES": "Påverkar allierade.",
	"const_EFFECT_TARGET_CASTER": "Påverkar hjulet.",
	"const_EFFECT_TARGET_ENEMIES": "Påverkar fiender.",
	"const_EFFECT_TARGET_NON_SUMMONS": "Påverkar icke-inkallade enheter (purjolök och torn).",
	"const_EFFECT_TARGET_SUMMONS": "Påverkar tillkallade enheter (lampor).",
	"const_EFFECT_TELEPORT": "Ändrar hjulets position.",
	"const_EFFECT_VULNERABILITY": "Tar bort sköld i förhållande till en enhet. Förstärks inte av en egenskap. Ökar träffpoäng borttagna av skada (EFFECT_DAMAGE) med ett relativt belopp.",
	"const_ENTITY_BULB": "Avser en enhet av Bulb-typ.",
	"const_ENTITY_LEEK": "Avser en enhet av purjolök-typ.",
	"const_ENTITY_TURRET": "Avser en enhet av Turret-typ.",
	"const_FIGHT_CONTEXT_CHALLENGE": "Utmaningstyp stridsbakgrund.",
	"const_FIGHT_CONTEXT_GARDEN": "Kontext av slagsmål i grönsaksträdgården.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "Kontext av strid i Battle Royale.",
	"const_FIGHT_CONTEXT_TEST": "Testa stridssammanhang.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "Bakgrund för turneringskamp.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "Slåss i Battle Royale.",
	"const_FIGHT_TYPE_FARMER": "Uppfödarkamp.",
	"const_FIGHT_TYPE_SOLO": "Solokamp.",
	"const_FIGHT_TYPE_TEAM": "Lagkamp.\n",
	"const_INSTRUCTIONS_LIMIT": "Maximalt antal instruktioner som en enhet kan använda under sin tur.",
	"const_MAX_TURNS": "Maximalt antal varv i en kamp.",
	"const_OPERATIONS_LIMIT": "Maximalt antal operationer som en enhet kan använda under sin tur.",
	"const_PI": "Förhållandet mellan en cirkels omkrets och dess diameter.",
	"const_SORT_ASC": "Anger för #sort-funktionen att sortera i stigande ordning.",
	"const_SORT_DESC": "Anger för #sort-funktionen att sortera i fallande ordning.",
	"const_TYPE_ARRAY": "Värdetyp <b>lista</b>",
	"const_TYPE_BOOLEAN": "Typ av värde <b>booleskt</b>",
	"const_TYPE_FUNCTION": "Värdetyp <b>funktion</b>",
	"const_TYPE_NULL": "Typ av värde <b>null</b>",
	"const_TYPE_NUMBER": "Värdetyp <b>nummer</b>",
	"const_TYPE_STRING": "Typ av värde <b>teckensträng</b>",
	"const_USE_CRITICAL": "Värde som returneras av funktionerna #useWeapon, #useWeaponOnCell, #useChip och #useChipOnCell i händelse av en kritisk träff.",
	"const_USE_FAILED": "Värde som returneras av funktionerna #useWeapon, #useWeaponOnCell, #useChip och #useChipOnCell vid fel.",
	"const_USE_INVALID_COOLDOWN": "Värde som returneras av funktionerna #useChip och #useChipOnCell om chippet ännu inte är användbart.",
	"const_USE_INVALID_POSITION": "Värde som returneras av funktionerna #useWeapon, #useWeaponOnCell, #useChip och #useChipOnCell om räckvidden är dålig eller siktlinjen är otydlig.",
	"const_USE_INVALID_TARGET": "Värde som returneras av funktionerna #useWeapon och #useChip om målet inte finns.",
	"const_USE_NOT_ENOUGH_TP": "Värde som returneras av funktionerna #useWeapon, #useWeaponOnCell, #useChip och #useChipOnCell om castern inte har tillräckligt med åtgärdspunkter för att använda objektet.",
	"const_USE_RESURRECT_INVALID_ENTITY": "Värde som returneras av #resurrect-funktionen när den angivna enheten inte finns eller ännu inte är död.",
	"const_USE_SUCCESS": "Värde som returneras av funktionerna #useWeapon, #useWeaponOnCell, #useChip och #useChipOnCell efter framgång.",
	"const_USE_TOO_MANY_SUMMONS": "Fel returneras av #summon när du redan har <b>8</b> live-kallelser.\n",
	"func_abs": "Returnerar det absoluta värdet av talet <b>numret</b>.",
	"func_abs_arg_1": "Antalet vars absoluta värde kommer att beräknas.",
	"func_abs_return": "Det absoluta värdet av talet.",
	"func_acos": "Beräknar arccosinus för <b>argument</b>, i intervallet [0, #PI].",
	"func_acos_arg_1": "Antal vars arccosine kommer att beräknas.",
	"func_acos_return": "Bågkosinus för <b>argument</b>.",
	"func_arrayConcat": "Lägg till de två arrayerna från början till slut. Textnycklar bevaras och alla numeriska tangenter återindexeras.",
	"func_arrayConcat_arg_1": "Första bordet.",
	"func_arrayConcat_arg_2": "Andra bordet.",
	"func_arrayConcat_return": "Summan av de två arrayerna.",
	"func_arrayFilter": "Returnerar en ny matris som innehåller alla nyckel/värdepar från källmatrisen för vilken återuppringningsfunktionen returnerade sant. Om återuppringningsfunktionen tar en parameter kommer värdet på källmatrisen att skickas, om den tar två parametrar kommer nyckeln och värdet att skickas.",
	"func_arrayFilter_arg_1": "Original bord.",
	"func_arrayFilter_arg_2": "Funktion anropad för varje element.",
	"func_arrayFilter_return": "Nytt bord.",
	"func_arrayFlatten": "Returnerar en ny array som innehåller alla element i källarrayen. Alla element som finns i en sub-array extraheras till den nya arrayen.Depth-argumentet bestämmer det maximala sub-array-djupet som ska extraheras.\n<code>var array = [1, 2, [[3, 4], 5], [6]];\ndebug(arrayFlatten(array, 1)); // [1,2,[3,4],5,6]</code>",
	"func_arrayFlatten_arg_1": "Original bord.",
	"func_arrayFlatten_arg_2": "Maximalt djup.",
	"func_arrayFlatten_return": "Nytt bord.",
	"func_arrayFoldLeft": "Minska arrayen <b>array</b> [v1, v2, ..., vn] från vänster med början från värdet <b>v0</b> och använd funktionen <b>f</b >. Lika med:\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "Original bord.",
	"func_arrayFoldLeft_arg_2": "Funktion att tillämpa.",
	"func_arrayFoldLeft_arg_3": "Startvärde.",
	"func_arrayFoldLeft_return": "Reducerat bord.",
	"func_arrayFoldRight": "Minska arrayen <b>array</b> [v1, v2, ..., vn] från höger från värdet <b>v0</b> och använd funktionen <b>f</b >. Lika med:\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "Original bord.",
	"func_arrayFoldRight_arg_2": "Funktion att tillämpa.",
	"func_arrayFoldRight_arg_3": "Startvärde.",
	"func_arrayFoldRight_return": "Reducerat bord.",
	"func_arrayIter": "Anropar återuppringningsfunktionen för varje matriselement. Om återuppringningsfunktionen tar en parameter kommer värdet på källmatrisen att skickas, om den tar två parametrar kommer nyckeln och värdet att skickas.",
	"func_arrayIter_arg_1": "Original bord.",
	"func_arrayIter_arg_2": "Funktion anropad för varje element.",
	"func_arrayMap": "Returnerar en ny array som för varje nyckel i källmatrisen innehåller värdet som returneras av callback-funktionen. Om återuppringningsfunktionen tar en parameter kommer värdet på källmatrisen att skickas, om den tar två parametrar kommer nyckeln och värdet att skickas.",
	"func_arrayMap_arg_1": "Original bord.",
	"func_arrayMap_arg_2": "Funktion anropad för varje element.",
	"func_arrayMap_return": "Nytt bord.",
	"func_arrayMax": "Returnerar maxvärdeselementet för arrayen <b>array</b>.<br/>För mer information om maxvärdet för en array se #sort.",
	"func_arrayMax_arg_1": "Array där det maximala värdet kommer att sökas.",
	"func_arrayMax_return": "Högsta värde objekt.",
	"func_arrayMin": "Returnerar minimivärdeselementet för <b>array</b>-matrisen.<br/>För mer information om minimivärdet för en matris, se #sort.",
	"func_arrayMin_arg_1": "Array där minimivärdet kommer att sökas.",
	"func_arrayMin_return": "Minimivärde objekt.",
	"func_arrayPartition": "Returnerar en ny array som innehåller två listor, i den första är alla nyckel/värdepar för vilka återuppringningsfunktionen returnerade sant, i den andra är alla andra. Om återuppringningsfunktionen tar en parameter kommer värdet på källmatrisen att skickas, om den tar två parametrar kommer nyckeln och värdet att skickas.",
	"func_arrayPartition_arg_1": "Original bord.",
	"func_arrayPartition_arg_2": "Funktion anropad för varje element.",
	"func_arrayPartition_return": "Nytt bord.",
	"func_arraySort": "Sorterar matrisen enligt den ordning som definieras av återuppringningsfunktionen. Elementen jämförs två och två, återuppringningsfunktionen måste returnera värdena -1, 0 eller 1 beroende på om det första värdet är före, på samma nivå eller efter det andra värdet. Om återuppringningsfunktionen tar 2 parametrar skickas de två värdena, om det tar 4 skickas nyckel/värdeparen.",
	"func_arraySort_arg_1": "Originalbord",
	"func_arraySort_arg_2": "Sorteringsfunktion.",
	"func_arraySort_return": "Den sorterade arrayen",
	"func_asin": "Beräknar bågen för <b>argument</b>, i intervallet [0, #PI].",
	"func_asin_arg_1": "Antal vars arcsine kommer att beräknas.",
	"func_asin_return": "Bågen för <b>argument</b>.",
	"func_assocSort": "Sorterar arrayen <b>array</b> och bevarar nyckel:värde-associationen enligt ordningen <b>ordning</b>.",
	"func_assocSort_arg_1": "Arrayen att sortera.",
	"func_assocSort_arg_2": "Sorteringsordningen: #SORT_ASC eller #SORT_DESC.",
	"func_atan": "Beräknar arctangensen för <b>argument</b>, i intervallet [0, #PI].",
	"func_atan2": "Konverterar kartesiska koordinater (<b>x</b>, <b>y</b>) till polära koordinater (<b>r</b>, <b>theta</b>). Den här funktionen returnerar <b>theta</b>-vinkeln mellan -#PI och #PI med argumentens tecken.",
	"func_atan2_arg_1": "Y-koordinat.",
	"func_atan2_arg_2": "X-koordinat.",
	"func_atan2_return": "<b>theta</b>-vinkeln i polära koordinater för punkten (x, y).",
	"func_atan_arg_1": "Antal vars arctangens kommer att beräknas.",
	"func_atan_return": "Bågtangensen för <b>argument</b>.",
	"func_average": "Beräknar medelvärdet av elementen i arrayen <b>array</b>.",
	"func_average_arg_1": "Array vars medelvärde vi vill beräkna.",
	"func_average_return": "Genomsnittligt värde.",
	"func_canUseChip": "Avgör om din enhet kan använda <b>chipet</b> på enheten med id <b>entity</b>.",
	"func_canUseChip_arg_1": "Numret på det chip som ska testas.",
	"func_canUseChip_arg_2": "Entitets-id som du vill använda chippet på.",
	"func_canUseChip_return": "<i>true</i> om din enhet kan använda chippet, <i>false</i> annars.",
	"func_canUseChipOnCell": "Avgör om din enhet kan använda <b>chipet</b> på <b>cellen</b>-cellen.",
	"func_canUseChipOnCell_arg_1": "Numret på det chip som ska testas.",
	"func_canUseChipOnCell_arg_2": "Numret på den cell som du vill använda chippet på.",
	"func_canUseChipOnCell_return": "<i>true</i> om din enhet kan använda chippet, <i>false</i> annars.",
	"func_canUseWeapon": "Bestämmer om din enhet kan skjuta enheten med id <b>entity</b> med vapnet <b>vapen</b>.",
	"func_canUseWeapon_arg_1": "Vapnet att testa. Standardinställning för ditt för närvarande utrustade vapen.",
	"func_canUseWeapon_arg_2": "Id för den enhet du vill skjuta på.",
	"func_canUseWeapon_return": "<i>true</i> om din enhet kan avfyra, <i>false</i> annars.",
	"func_canUseWeaponOnCell": "Bestämmer om din enhet kan skjuta <b>cell</b>-cellen med <b>vapen</b>-vapnet.",
	"func_canUseWeaponOnCell_arg_1": "Vapnet att testa. Standardinställning för ditt för närvarande utrustade vapen.",
	"func_canUseWeaponOnCell_arg_2": "Numret på den cell du vill skjuta.",
	"func_canUseWeaponOnCell_return": "<i>true</i> om din enhet kan avfyra, <i>false</i> annars.",
	"func_cbrt": "Beräkna kubroten av <b>tal</b>",
	"func_cbrt_arg_1": "Tal vars kubrot vi vill beräkna.",
	"func_cbrt_return": "Kubrot av <b>tal</b>.",
	"func_ceil": "Returnerar avrundningen uppåt av <b>tal</b>.<br/>Du kan hitta avrundningen nedåt med #golv och avrundningen med #runda.",
	"func_ceil_arg_1": "Tal som vi vill hitta den övre avrundningen för.",
	"func_ceil_return": "Runda upp <b>nummer</b>.",
	"func_charAt": "Returnerar tecknet för strängen <b>sträng</b> som finns på position <b>position</b>.<br/>Det första tecknet i strängen är på position 0.",
	"func_charAt_arg_1": "Sträng från vilken vi vill hämta en karaktär.",
	"func_charAt_arg_2": "Position för karaktären att hitta.",
	"func_charAt_return": "sträng som innehåller det sökta tecknet, eller en tom sträng om positionen är ogiltig.",
	"func_chipNeedLos": "Returnerar om <b>chippet</b> behöver siktlinje för att användas.",
	"func_chipNeedLos_arg_1": "ID för chipet som ska testas.",
	"func_chipNeedLos_return": "<i>true</i> om <b>chipet</b> behöver siktlinje för att användas, <i>false</i> annars.",
	"func_clearMarks": "Rensar alla markeringar gjorda av mark() och markText() i fältet.",
	"func_clone": "Kopierar <b>värdet</b> som skickats som en parameter på <b>nivå</b>-nivåer och returnerar kopian. I fallet med en array till exempel, kommer en klon(array, 1) att kopiera arrayen men inte dess element, en clone(array, 2) kommer att kopiera arrayen såväl som alla element.",
	"func_clone_arg_1": "Värdet att klona",
	"func_clone_arg_2": "Antalet nivåer att klona",
	"func_clone_return": "Det klonade värdet",
	"func_contains": "Avgör om en <b>söksträng</b> finns inom en <b>sträng</b>.",
	"func_contains_arg_1": "Sträng där sökningen kommer att utföras.",
	"func_contains_arg_2": "Sträng att söka efter.",
	"func_contains_return": "sant om <b>sökning</b> är i <b>sträng</b>, annars falskt.",
	"func_cos": "Beräknar cosinus för <b>vinkel</b>.",
	"func_cos_arg_1": "Antal vars cosinus kommer att beräknas (i radianer).",
	"func_cos_return": "Cosinus för <b>vinkel</b>, i intervallet [-1, 1].",
	"func_count": "Räknar antalet element i arrayen <b>array</b>.",
	"func_count_arg_1": "Array vars antal element kommer att räknas.",
	"func_count_return": "Antal arrayelement.",
	"func_debug": "Spelar in ett <b>objekt</b>-meddelande i den personliga loggen, tillgänglig i rapporten i slutet av kampen.",
	"func_debug_arg_1": "Meddelandet att spela in.",
	"func_debugC": "Spelar in ett <b>objekt</b>-meddelande i den personliga loggen, tillgänglig i rapporten i slutet av kampen, med färgen <b>färg</b>.",
	"func_debugC_arg_1": "Meddelandet att spela in.",
	"func_debugC_arg_2": "Färgen på meddelandet. Du kan använda #getColor-funktionen.",
	"func_debugE": "Registrerar ett <b>objekt</b>-felmeddelande i den personliga loggen, tillgängligt i rapporten i slutet av kampen.<br />Felmeddelanden visas i rött i kamprapporten.",
	"func_debugE_arg_1": "Felmeddelandet att logga.",
	"func_debugW": "Spelar in ett <b>objekt</b>-varningsmeddelande i den personliga loggen, tillgänglig i rapporten i slutet av kampen. <br />Varningsmeddelanden visas i orange i stridsrapporten.",
	"func_debugW_arg_1": "Varningsmeddelandet att spela in.",
	"func_deleteRegister": "Tar bort registret som är associerat med nyckel <b>nyckel</b> om det finns.",
	"func_deleteRegister_arg_1": "Registernyckeln att radera.",
	"func_endsWith": "Bestämmer om en <b>sträng</b> slutar med en <b>suffix</b>-sträng.",
	"func_endsWith_arg_1": "Sträng där sökningen kommer att utföras",
	"func_endsWith_arg_2": "Suffix för att söka",
	"func_endsWith_return": "sant om <b>sträng</b> slutar med <b>suffix</b>",
	"func_exp": "Höjer Eulers nummer #E till <b>nummer</b>.",
	"func_exp_arg_1": "Exponenten till vilken #E kommer att höjas.",
	"func_exp_return": "#E** <b>nummer</b>.",
	"func_fill": "Ändrar storleken på arrayens <b>array</b> till <b>storlek</b> och fyller alla dess rutor med <b>värde</b>.",
	"func_fill_arg_1": "Tabellen ska fyllas i.",
	"func_fill_arg_2": "Värdet för varje kvadrat i arrayen.",
	"func_fill_arg_3": "Antalet lådor. Som standard är den aktuella arraystorleken.",
	"func_floor": "Beräknar avrundningen nedåt för <b>tal</b>.<br/>Du kan hitta avrundningen uppåt med #tak och avrundningen med #runda.",
	"func_floor_arg_1": "Tal vars avrundning nedåt vi vill hitta.",
	"func_floor_return": "Avrundat nedåt till <b>antal</b>.",
	"func_getAbsoluteShield": "Returnerar den absoluta skölden för entiteten med id <b>entitet</b>. För att direkt hämta din entitets absoluta sköld, använd #getAbsoluteShield() utan parametrar.",
	"func_getAbsoluteShield_arg_1": "Id för den enhet vars absoluta sköld kommer att returneras.",
	"func_getAbsoluteShield_return": "Entitetens absoluta sköld <b>entitet</b>.",
	"func_getAgility": "Returnerar smidigheten för entiteten med id <b>entity</b>. För att direkt hämta din enhets smidighet, använd #getAgility() utan parametrar.",
	"func_getAgility_arg_1": "Id för den enhet vars smidighet kommer att returneras.",
	"func_getAgility_return": "Agiliteten hos <b>entity</b>-entiteten.",
	"func_getAIID": "Returnerar AI-id för entitetens <b>entitet</b>. Använd getAIID utan parameter för att hämta din AI:s id.",
	"func_getAIID_arg_1": "Id för den enhet vars AI-id kommer att returneras.",
	"func_getAIID_return": "<b>entitet</b>-entitets-AI-id.",
	"func_getAIName": "Returnerar namnet på entiteten AI <b>entity</b>. Använd getAINam() utan parametrar för att hämta namnet på din AI.",
	"func_getAIName_arg_1": "Id för den enhet vars AI-namn kommer att returneras.",
	"func_getAIName_return": "Namnet på <b>entitet</b>-entiteten AI.",
	"func_getAliveAllies": "Returnerar en mängd alla dina allierade vid liv i strid.",
	"func_getAliveAllies_return": "En array som innehåller id:erna för alla dina levande allierade.",
	"func_getAliveEnemies": "Returnerar en rad av alla dina fiender vid liv i strid.",
	"func_getAliveEnemiesCount": "Returnerar antalet fiender som lever i kampen.",
	"func_getAliveEnemiesCount_return": "Antalet levande fiender.",
	"func_getAliveEnemies_return": "En array som innehåller id för alla dina levande fiender.",
	"func_getAllChips": "Returnerar listan över alla marker i spelet.",
	"func_getAllChips_return": "Listan över alla marker i spelet.",
	"func_getAllEffects": "Returnerar listan över alla effekter i spelet.",
	"func_getAllEffects_return": "Listan över alla effekter i spelet.",
	"func_getAllWeapons": "Returnerar listan över alla vapen i spelet.",
	"func_getAllWeapons_return": "Listan över alla vapen i spelet.",
	"func_getAlliedTurret": "Returnerar ditt lags torn-id eller -1 om det inte finns.",
	"func_getAlliedTurret_return": "Ditt lags torn-ID.",
	"func_getEnemyTurret": "Returnerar fiendens torn-id eller -1 om det inte finns.",
	"func_getEnemyTurret_return": "Fiendens torn-ID.",
	"func_getAllies": "Returnerar en array som innehåller dina allierade och din enhet.",
	"func_getAlliesCount": "Returnerar antalet allierade i kampen.",
	"func_getAlliesCount_return": "Antalet allierade.",
	"func_getAlliesLife": "Returnerar dina allierades totala hälsa.",
	"func_getAlliesLife_return": "Dina allierades totala hälsa.",
	"func_getAllies_return": "Tabellen över allierade och din enhet.",
	"func_getBirthTurn": "Returnerar vändningen i kampen där <b>entiteten</b> dök upp. Returnerar 1 om det till exempel är purjolök och 5 om det är en kallelse som åberopas vid tur 5.",
	"func_getBirthTurn_arg_1": "Id för den enhet vars spawn-tur kommer att returneras.",
	"func_getBirthTurn_return": "Kampsvängen där <b>entitet</b> dök upp.",
	"func_getBlue": "Returnerar mängden blått i färgen <b>färg</b>, mellan 0 och 255. Till exempel getBlue(#COLOR_BLUE) = 255 och getBlue(#COLOR_GREEN) = 0.",
	"func_getBlue_arg_1": "Färgen vars blå kurs kommer att returneras.",
	"func_getBlue_return": "Hastigheten för blått i färgen <b>färg</b>",
	"func_getBulbChips": "Returnerar listan över marker som kommer att utrustas på en glödlampa som anropas av <b>bulb_chip</b>-chippet.",
	"func_getBulbChips_arg_1": "Lökloppan.",
	"func_getBulbChips_return": "Chipsen från en glödlampa som framkallas av <b>bulb_chip</b>-chippet.",
	"func_getCell": "Returnerar cellen där entiteten med id <b>entitet</b> finns. Använd getCell() utan parametrar för att hämta din cell.",
	"func_getCell_arg_1": "Id för den enhet vars cell kommer att returneras.",
	"func_getCell_return": "Cellnumret där <b>entiteten</b> finns.",
	"func_getCellContent": "Returnerar innehållet i en cell med id <b>cell</b>.",
	"func_getCellContent_arg_1": "Id för cellen vars innehåll kommer att returneras.",
	"func_getCellContent_return": "Innehållet i cellen <b>cell</b>: #CELL_EMPTY för en tom cell, #CELL_ENTITY för en enhet, #CELL_OBSTACLE för ett hinder.",
	"func_getCellDistance": "Returnerar avståndet mellan två celler <b>cell1</b> och <b>cell2</b>. <br />Det returnerade avståndet uttrycks i antal celler och tar inte hänsyn till de olika hindren mellan de två cellerna.\nFör att få avståndet fågelvägen, se #getDistance och för att få vägavståndet mellan de två cellerna för att undvika hinder, se #getPathLength.",
	"func_getCellDistance_arg_1": "Id för startcellen.",
	"func_getCellDistance_arg_2": "Ankomstcellens ID.",
	"func_getCellDistance_return": "Avståndet mellan de två cellerna <b>cell1</b> och <b>cell2</b>.",
	"func_getCellFromXY": "Returnerar cellens id vid position (<b>x</b>, <b>y</b>).",
	"func_getCellFromXY_arg_1": "Cellens x-position.",
	"func_getCellFromXY_arg_2": "Cellens y-position.",
	"func_getCellFromXY_return": "Cell-ID vid position (<b>x</b>, <b>y</b>), <b>null</b> om cellen inte finns.",
	"func_getCellsToUseChip": "Returnerar listan över celler från vilka din enhet kommer att kunna använda <b>chipet</b> på <b>entiteten</b> entiteten.",
	"func_getCellsToUseChip_arg_1": "Chipet som enheten vill kunna använda.",
	"func_getCellsToUseChip_arg_2": "Målenheten.",
	"func_getCellsToUseChip_arg_3": "Array av celler att ignorera.",
	"func_getCellsToUseChip_return": "Lista över celler där chipet kan användas.",
	"func_getCellsToUseChipOnCell": "Returnerar listan över celler från vilka din enhet kommer att kunna använda <b>chippet</b> på cell <b>cell</b>.",
	"func_getCellsToUseChipOnCell_arg_1": "Chipet som enheten vill kunna använda.",
	"func_getCellsToUseChipOnCell_arg_2": "Målcellen.",
	"func_getCellsToUseChipOnCell_arg_3": "Array av celler att ignorera.",
	"func_getCellsToUseChipOnCell_return": "Lista över celler där chipet kan användas.",
	"func_getCellsToUseWeapon": "Returnerar listan över celler från vilka din enhet kommer att kunna använda vapnets <b>vapen</b> på entitetens <b>entitet</b>.",
	"func_getCellsToUseWeapon_arg_1": "Vapnet att testa. Standardinställning för ditt för närvarande utrustade vapen.",
	"func_getCellsToUseWeapon_arg_2": "Målenheten.",
	"func_getCellsToUseWeapon_arg_3": "Array av celler att ignorera. Som standard en tom array.",
	"func_getCellsToUseWeapon_return": "Lista över celler från vilka vapnet kan användas.",
	"func_getCellsToUseWeaponOnCell": "Returnerar listan över celler från vilka din enhet kommer att kunna använda <b>vapen</b> vapnet på en <b>cell</b> cell.",
	"func_getCellsToUseWeaponOnCell_arg_1": "Vapnet att testa. Standardinställning för ditt för närvarande utrustade vapen.",
	"func_getCellsToUseWeaponOnCell_arg_2": "Målcellen.",
	"func_getCellsToUseWeaponOnCell_arg_3": "Array av celler att ignorera. Som standard en tom array.",
	"func_getCellsToUseWeaponOnCell_return": "Lista över celler från vilka vapnet kan användas.",
	"func_getCellX": "Bestämmer positionen i X för cellens <b>cell</b>.",
	"func_getCellX_arg_1": "Cellen vars position i X kommer att bestämmas.",
	"func_getCellX_return": "Cellens X-position.",
	"func_getCellY": "Bestämmer Y-positionen för cellens <b>cell</b>.",
	"func_getCellY_arg_1": "Cellen vars position i Y kommer att bestämmas.",
	"func_getCellY_return": "Cellens Y-position.",
	"func_getChipArea": "Returnerar typen av effektområde för <b>chippet</b>.",
	"func_getChipArea_arg_1": "Chipet vars zontyp kommer att returneras.",
	"func_getChipArea_return": "Areatypen för chipets <b>chip</b> bland konstanterna AREA_*:\n<ul>\n<li>#AREA_POINT: enkel kvadratisk area</li>\n<li>#AREA_LASER_LINE: laserlinje</li>\n<li>#AREA_CIRCLE_1: cirkulär yta med 3 rutor i diameter</li>\n<li>#AREA_CIRCLE_2: cirkulär yta 5 rutor i diameter</li>\n<li>#AREA_CIRCLE_3: cirkulär yta 7 rutor i diameter</li>\n</ul>",
	"func_getChipCooldown": "Returnerar återställningstiden för <b>chip</b>, hämtad från marknaden.",
	"func_getChipCooldown_arg_1": "Chipet vars nedkylning kommer att returneras.",
	"func_getChipCooldown_return": "Nedkylningen av <b>chipet</b>.",
	"func_getChipCost": "Returnerar kostnaden i TP för chipets <b>chip</b>.",
	"func_getChipCost_arg_1": "Chipet som kostar kommer att returneras.",
	"func_getChipCost_return": "Kostnaden för <b>chippet</b>.",
	"func_getChipEffectiveArea": "Returnerar listan över celler som kommer att påverkas om <b>chipet</b> används på <b>cell</b>-cellen från en <b>från</b>-cell.",
	"func_getChipEffectiveArea_arg_1": "Chipet som ska testas.",
	"func_getChipEffectiveArea_arg_2": "Målcellen.",
	"func_getChipEffectiveArea_arg_3": "Cellen från vilken chipet används.",
	"func_getChipEffectiveArea_return": "Arrayen som innehåller id för alla celler som kommer att påverkas.",
	"func_getChipEffects": "Returnerar effekterna av <b>chippet</b>.",
	"func_getChipEffects_arg_1": "Chipet vars effekter kommer att returneras.",
	"func_getChipEffects_return": "Effekterna av <b>chipet</b>-chippet. Samma returvärde som för #getWeaponEffects-funktionen.",
	"func_getChipFailure": "Returnerar den procentuella risken för fel på <b>chipet</b>.",
	"func_getChipFailure_arg_1": "Chipet vars felprocent kommer att returneras.",
	"func_getChipFailure_return": "<b>chip</b> chipfelprocent, ett heltal mellan <b>0</b> och <b>100</b>.",
	"func_getChipMaxRange": "Returnerar det maximala intervallet för <b>chippet</b>.",
	"func_getChipMaxRange_arg_1": "Chipet vars maximala räckvidd kommer att returneras.",
	"func_getChipMaxRange_return": "Maximal räckvidd för <b>chippet</b>.",
	"func_getChipMaxScope": "Returnerar det maximala intervallet för <b>chippet</b>.",
	"func_getChipMaxScope_arg_1": "Chipet vars maximala räckvidd kommer att returneras.",
	"func_getChipMaxScope_return": "Maximal räckvidd för <b>chippet</b>.",
	"func_getChipMinRange": "Returnerar minimiintervallet för <b>chipet</b>-chippet.",
	"func_getChipMinRange_arg_1": "Chipet vars minsta intervall kommer att returneras.",
	"func_getChipMinRange_return": "Minsta intervall för <b>chippet</b>.",
	"func_getChipMinScope": "Returnerar minimiintervallet för <b>chipet</b>-chippet.",
	"func_getChipMinScope_arg_1": "Chipet vars minsta intervall kommer att returneras.",
	"func_getChipMinScope_return": "Minsta intervall för <b>chippet</b>.",
	"func_getChipLaunchType": "Returnerar startläget för <b>chipet</b>-chippet, bland LAUNCH_TYPE_*-konstanterna.",
	"func_getChipLaunchType_arg_1": "Id för det chip vars kastläge kommer att returneras.",
	"func_getChipLaunchType_return": "Kastläget för <b>chipet</b>-chippet.",
	"func_getChipName": "Returnerar namnet på <b>chipet</b>.",
	"func_getChipName_arg_1": "Chipet vars namn kommer att returneras.",
	"func_getChipName_return": "Namnet på <b>chipet</b>.",
	"func_getChips": "Returnerar punkterna för entiteten med id <b>entitet</b>.",
	"func_getChips_arg_1": "Id för den enhet vars kulor kommer att returneras.",
	"func_getChips_return": "En array som innehåller punkt-ID:n för entitetens <b>entitet</b>.",
	"func_getChipTargets": "Returnerar entiteterna som kommer att påverkas om <b>chipet</b> används på cell <b>cell</b>.",
	"func_getChipTargets_arg_1": "Chipet som ska testas.",
	"func_getChipTargets_arg_2": "Målcellen.",
	"func_getChipTargets_return": "Arrayen som innehåller ID:n för alla enheter som kommer att påverkas.",
	"func_getColor": "Returnerar det heltal som motsvarar färgen (<b>röd</b>, <b>grön</b>, <b>blå</b>) som anges som parametrar.",
	"func_getColor_arg_1": "Rött värde mellan 0 och 255.",
	"func_getColor_arg_2": "Grönt värde mellan 0 och 255.",
	"func_getColor_arg_3": "Blått värde mellan 0 och 255.",
	"func_getColor_return": "int som motsvarar den färg som anges som en parameter.",
	"func_getCooldown": "Returnerar den aktuella nedkylningen av <b>chipet</b> för <b>entiteten</b> entiteten.",
	"func_getCooldown_arg_1": "Chipet vars nuvarande nedkylning kommer att returneras.",
	"func_getCooldown_arg_2": "Entiteten vars nedkylning kommer att returneras.",
	"func_getCooldown_return": "Den aktuella nedkylningen av chippets <b>chip</b>, detta är antalet varv innan chippet blir användbart, <b>0</b> om det för närvarande är användbart.",
	"func_getCores": "Returnerar antalet kärnor i entiteten med id <b>entitet</b>.",
	"func_getCores_arg_1": "Entiteten vars antal kärnor kommer att returneras.",
	"func_getCores_return": "Antalet kärnor i <b>entity</b>-entiteten.",
	"func_getDate": "Returnerar datumet för kampen, i formatet dd/MM/åååå.",
	"func_getDate_return": "Datumet för slagsmålet.",
	"func_getTime": "Returnerar stridstiden, i HH:mm:ss-format.",
	"func_getTime_return": "Tiden för kampen.",
	"func_getTimestamp": "Returnerar kampens tidsstämpel, lika med antalet sekunder sedan 1 januari 1970.",
	"func_getTimestamp_return": "Kampens tidsstämpel.",
	"func_getDamageReturn": "Returnerar skadeavkastningsgraden för entiteten med id <b>entitet</b>.",
	"func_getDamageReturn_arg_1": "Id för den enhet från vilken skadehänvisningen kommer att returneras.",
	"func_getDamageReturn_return": "Skadeavkastningsgraden för entiteten med id <b>entity</b> (i %).",
	"func_getDeadAllies": "Returnerar dödsvänliga enheter.",
	"func_getDeadAllies_return": "Tabellen över allierade döda enheter.",
	"func_getDeadEnemies": "Returnerar döda fiendeenheter.",
	"func_getDeadEnemiesCount": "Returnerar antalet fiender som dog i strid.",
	"func_getDeadEnemiesCount_return": "Antalet döda fiender.",
	"func_getDeadEnemies_return": "Tabellen över döda fiendens entiteter.",
	"func_getDistance": "Beräknar fågelvägsavståndet mellan två celler <b>cell1</b> och <b>cell2</b>. <br />För att få avståndet i antal celler, se #getCellDistance, och för att få längden på vägen mellan de två cellerna som undviker de olika hindren, se #getPathLength.",
	"func_getDistance_arg_1": "Startcellen.",
	"func_getDistance_arg_2": "Ankomstcellen.",
	"func_getDistance_return": "Avståndet fågelvägen mellan de två cellerna.",
	"func_getPassiveEffects": "Returnerar listan över passiva effekter av entiteten med id <b>entity</b>. För att direkt hämta listan över passiva effekter för din entitet, använd #getPassiveEffects() utan parametrar.",
	"func_getPassiveEffects_arg_1": "Id för den enhet vars lista över passiva effekter kommer att returneras.",
	"func_getPassiveEffects_return": "Listan över passiva effekter som för närvarande finns på <b>entity</b>-entiteten.\nListan över passiva effekter är en array som innehåller effekterna.\nEn effekt är i sig en uppsättning av 7 rutor av formen: [<b>typ</b>, <b>värde</b>, <b>caster_id</b>, <b>turns</b>, <b>kritiskt</b>, <b>item_id</b>, <b>target_id</b>]. Det är samma struktur som de klassiska effekterna som returneras av #getEffects-funktionen.",
	"func_getEnemies": "Återför fientliga enheter (levande eller döda) till strid.",
	"func_getEnemiesCount": "Returnerar antalet fiender i kampen.",
	"func_getEnemiesCount_return": "Antalet fiender.",
	"func_getEnemiesLife": "Beräknar summan av träffpoäng för alla fiendens entiteter.",
	"func_getEnemiesLife_return": "Summan av träffpoängen för fiendens lag.",
	"func_getEnemies_return": "En array som innehåller ID för alla fiendens enheter.",
	"func_getEntityTurnOrder": "Returnerar ett värde mellan 1 och n (antal enheter som för närvarande spelas) som indikerar positionen för <b>entiteten</b> i spelordning.",
	"func_getEntityTurnOrder_arg_1": "Id för den enhet vars spelordning kommer att returneras",
	"func_getEntityTurnOrder_return": "Placera i spelordning för enhet <b>enhet</b>",
	"func_getFarmerID": "Returnerar uppfödar-id för entitetens <b>entitet</b>.",
	"func_getFarmerID_arg_1": "ID för den enhet vars uppfödar-ID kommer att returneras.",
	"func_getFarmerID_return": "ID för <b>entity</b>-enhetsuppfödaren.",
	"func_getFarmerCountry": "Returnerar landet för uppfödaren av enhetens <b>enhet</b>.",
	"func_getFarmerCountry_arg_1": "ID för den enhet vars uppfödarland kommer att returneras.",
	"func_getFarmerCountry_return": "Landet för <b>enheten</b> enhetens uppfödare, eller \"?\" om det inte anges.",
	"func_getFarmerName": "Returnerar uppfödarnamnet för <b>entiteten</b>-entiteten.",
	"func_getFarmerName_arg_1": "Id för den enhet vars uppfödarnamn kommer att returneras.",
	"func_getFarmerName_return": "Namnet på uppfödaren av <b>entitet</b>-enheten.",
	"func_getFarthestAlly": "Bestämmer den längst borta allierade för din entitet, fågelvägen.",
	"func_getFarthestAlly_return": "Id för den mest vänliga enheten.",
	"func_getFarthestEnemy": "Bestämmer den fiende som ligger längst bort från din enhet, fågelvägen.",
	"func_getFarthestEnemy_return": "Id för den fientliga enheten längst bort.",
	"func_getFightID": "Returnerar aktuellt kamp-ID.",
	"func_getFightID_return": "ID för den aktuella kampen.",
	"func_getFightContext": "Returnerar sammanhanget för den aktuella kampen.",
	"func_getFightContext_return": "Beroende på stridssammanhang: Test Battle (#FIGHT_CONTEXT_TEST), Arena Battle (#FIGHT_CONTEXT_GARDEN), Tournament Battle (#FIGHT_CONTEXT_TOURNAMENT), Challenge Battle (#FIGHT_CONTEXT_CHALLENGE), Battle Royale (#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "Returnerar den aktuella stridstypen.",
	"func_getFightType_return": "Beroende på typen av slagsmål: Solokamp (#FIGHT_TYPE_SOLO), Farmer fight (#FIGHT_TYPE_FARMER), Lagkamp (#FIGHT_TYPE_TEAM), Battle Royale (#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "Returnerar styrkan för entiteten med id <b>entitet</b>.",
	"func_getForce_arg_1": "Enheten vars styrka kommer att återlämnas.",
	"func_getForce_return": "Styrkan hos <b>entitet</b> entiteten.",
	"func_getFrequency": "Returnerar frekvensen för entiteten med id <b>entity</b>. Använd getFrequency() utan parametrar för att hämta din frekvens.",
	"func_getFrequency_arg_1": "Entiteten vars frekvens kommer att returneras.",
	"func_getFrequency_return": "Frekvensen för <b>entitet</b> entiteten.",
	"func_getGreen": "Returnerar mängden grönt i färgen <b>färg</b>, mellan 0 och 255. Till exempel getGreen(#COLOR_GREEN) = 255 och getGreen(#COLOR_RED) = 0.",
	"func_getGreen_arg_1": "Färgen vars gröna kurs kommer att returneras.",
	"func_getGreen_return": "Frekvensen för grönt i färgen <b>färg</b>",
	"func_getInstructionsCount": "Returnerar antalet instruktioner som din enhet har utfört under den aktuella svängen.",
	"func_getInstructionsCount_return": "Antalet instruktioner din enhet har utfört under den aktuella svängen.",
	"func_getLaunchedEffects": "Returnerar listan över effekter som orsakas av entiteten med id <b>entitet</b>.",
	"func_getLaunchedEffects_arg_1": "Id för den enhet vars lista över orsakade effekter kommer att returneras.",
	"func_getLaunchedEffects_return": "Listan över effekter som orsakas av entiteten med id <b>entity</b>, av samma form som arrayen som returneras av #getEffects.",
	"func_getLeek": "Returnerar id:t för din enhet.",
	"func_getLeek_return": "Id för din enhet.",
	"func_getEntity": "Returnerar id:t för din enhet.",
	"func_getEntity_return": "Id för din enhet.",
	"func_getLeekID": "Returnerar det verkliga id:t för purjolöken av id <b>purjolök</b>.",
	"func_getLeekID_arg_1": "Id för purjolöken vars faktiska id kommer att returneras.",
	"func_getLeekID_return": "Det verkliga ID:t för purjolökens <b>entitet</b>.",
	"func_getLeekOnCell": "Returnerar entiteten som finns i cell <b>cell</b>.",
	"func_getLeekOnCell_arg_1": "Cellen vars enhet vi vill hämta.",
	"func_getLeekOnCell_return": "Entitetens ID i cellen, eller -1 om cellen inte har någon enhet.",
	"func_getEntityOnCell": "Returnerar entiteten som finns i cell <b>cell</b>.",
	"func_getEntityOnCell_arg_1": "Cellen vars enhet vi vill hämta.",
	"func_getEntityOnCell_return": "Entitetens ID i cellen, eller -1 om cellen inte har någon enhet.",
	"func_getLevel": "Returnerar enhetsnivån med id <b>entitet</b>.",
	"func_getLevel_arg_1": "Id för den enhet vars nivå kommer att returneras.",
	"func_getLevel_return": "Nivån för enheten med id <b>entitet</b>.",
	"func_getLife": "Returnerar den aktuella livslängden för entiteten med id <b>entitet</b>. Använd getLife() utan parametrar för att hämta ditt liv.",
	"func_getLife_arg_1": "Id för den enhet vars liv kommer att återlämnas.",
	"func_getLife_return": "Aktuell livslängd för <b>entitet</b>-entiteten.",
	"func_getMagic": "Returnerar entitetens magi med id <b>entity</b>. Använd getMagic() utan parametrar för att hämta din magi.",
	"func_getMagic_arg_1": "Id för den enhet vars magi kommer att returneras.",
	"func_getMagic_return": "Magin med id-enhet <b>entitet</b>.",
	"func_getMapType": "Returnerar typen av terräng där kampen äger rum (fabrik, öken, skog etc.), bland konstanterna #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER och #MAP_BEACH.",
	"func_getMapType_return": "Typen av terräng.",
	"func_getMessageAuthor": "Returnerar id:t för den enhet som skapar meddelandet <b>meddelandet</b>.",
	"func_getMessageAuthor_arg_1": "Meddelandet vars författare kommer att returneras.",
	"func_getMessageAuthor_return": "Id för den enhet som skapar meddelandet <b>meddelande</b>.",
	"func_getMessageParams": "Returnerar arrayen av meddelandeparametrar <b>meddelande</b>.",
	"func_getMessageParams_arg_1": "Meddelandet vars parametrar kommer att returneras.",
	"func_getMessageParams_return": "Parametrarna för <b>meddelande</b>.",
	"func_getMessages": "Returnerar entitetsmeddelandematrisen <b>entity</b>.",
	"func_getMessages_arg_1": "Entiteten vars meddelanden kommer att returneras.",
	"func_getMessages_return": "Tabellen över dina meddelanden.<br>Ett meddelande representeras i sig självt i form av en\narray av formen: [<b>författare</b>, <b>typ</b>, <b>parametrar</b>]<br>\nDe olika typerna av meddelanden representeras av konstanterna:\n<ul>\n<li>#MESSAGE_HEAL: begäran om vård</li>\n<li>#MESSAGE_ATTACK: begäran om attack</li>\n<li>#MESSAGE_BUFF_FORCE: tvinga fram förstärkningsbegäran</li>\n<li>...</li>\n</ul>",
	"func_getMessageType": "Returnerar meddelandetypen <b>meddelande</b>.",
	"func_getMessageType_arg_1": "Meddelandet vars typ kommer att returneras (bland #MESSAGE_HEAL, #MESSAGE_ATTACK, etc.).",
	"func_getMessageType_return": "Meddelandetypen <b>meddelande</b>.",
	"func_getMP": "Returnerar det aktuella antalet rörelsepunkter för <b>entiteten</b>-entiteten. Använd getMP() utan parametrar för att hämta dina PM.",
	"func_getMP_arg_1": "Id för den enhet vars PM-antal kommer att returneras.",
	"func_getMP_return": "<b>Entity</b>-enhetens MP-nummer.",
	"func_getName": "Returnerar namnet på enheten med id <b>entitet</b>.",
	"func_getName_arg_1": "Id för den enhet vars namn kommer att returneras.",
	"func_getName_return": "Entitetsnamnet <b>entitet</b>.",
	"func_getNearestAlly": "Returnerar den närmast vänliga enheten till din enhet.",
	"func_getNearestAlly_return": "Id för närmaste vänliga enhet.",
	"func_getNearestAllyTo": "Returnerar den entitet som ligger närmast den entitet som tillhandahålls som en parameter.",
	"func_getNearestAllyTo_arg_1": "Id för den enhet vars närmaste allierade vi vill veta.",
	"func_getNearestAllyToCell": "Returnerar den närmaste allierade enheten till cellen som tillhandahålls som parameter.",
	"func_getNearestAllyToCell_arg_1": "Id för cellen vars närmaste allierade vi vill veta.",
	"func_getNearestAllyToCell_return": "Id för närmaste vänliga enhet.",
	"func_getNearestAllyTo_return": "Id för närmaste vänliga enhet.",
	"func_getNearestEnemy": "Returnerar den fiende som ligger närmast din enhet.",
	"func_getNearestEnemy_return": "Id för närmaste fiendeenhet.",
	"func_getNearestEnemyTo": "Returnerar den entitet som ligger närmast fienden till den enhet som tillhandahålls som en parameter.",
	"func_getNearestEnemyTo_arg_1": "Id för den enhet vars närmaste fiende vi vill veta.",
	"func_getNearestEnemyToCell": "Returnerar den fientliga enheten närmast till cellen som tillhandahålls som parameter.",
	"func_getNearestEnemyToCell_arg_1": "Id för cellen vars närmaste fiende vi vill veta.",
	"func_getNearestEnemyToCell_return": "Id för den närmaste fiendens enhet.",
	"func_getNearestEnemyTo_return": "Id för närmaste fiendeenhet.",
	"func_getNextPlayer": "Returnerar id för den enhet som kommer att spela efter den aktuella spelaren.",
	"func_getNextPlayer_return": "Nästa spelare.",
	"func_getObstacles": "Returnerar listan över hinderutrymmen i terrängen.",
	"func_getObstacles_return": "Arrayen som innehåller hindercells-ID.",
	"func_getOperations": "Returnerar antalet operationer som förbrukats av din enhet sedan starten av sin tur. Detta nummer måste förbli lägre än #OPERATIONS_LIMIT så att enheten inte kraschar.",
	"func_getOperations_return": "Antal operationer som förbrukats av din enhet sedan starten av sin tur.",
	"func_getPath": "Returnerar sökvägen som undviker hinder mellan två celler <b>cell1</b> och <b>cell2</b>, om den finns, ignorerar cellerna i arrayen <b>ignoredCells</b> . Om en spelare befinner sig i en ignorerad cell, kan vägen passera över dem.<br /><br />\nStartcellen <b>cell1</b> är aldrig en del av den resulterande sökvägen. Cell <b>cell2</b> är en del av den resulterande sökvägen om och bara om den är tom eller ignoreras av <b>ignoredCells</b>.<br /><br />\nOm det inte finns någon sökväg mellan de två cellerna returnerar <b>getPath</b> <i>null</i>.",
	"func_getPath_arg_1": "Startcellen.",
	"func_getPath_arg_2": "Ankomstcellen.",
	"func_getPath_arg_3": "Uppsättningen av celler att ignorera.",
	"func_getPath_return": "Arrayen som innehåller cellerna som utgör vägen mellan de två cellerna.",
	"func_getPathLength": "Returnerar längden på sökvägen mellan två celler <b>cell1</b> och <b>cell2</b>, undviker hinder, ignorerar cellerna i arrayen <b>ignoredCells</b>. Denna funktion motsvarar <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>.\nOm en spelare befinner sig i en ignorerad cell, kan vägen passera över dem.<br /><br />\nStartcellen <b>cell1</b> räknas aldrig med i resultatet. Cell <b>cell2</b> räknas i resultatet om och endast om den är tom eller ignoreras av <b>ignoredCells</b>.<br /><br />\nOm det inte finns någon sökväg mellan de två cellerna returnerar <b>getPathLength</b> <i>null</i>.",
	"func_getPathLength_arg_1": "Startcellen.",
	"func_getPathLength_arg_2": "Ankomstcellen.",
	"func_getPathLength_arg_3": "Uppsättningen av celler att ignorera.",
	"func_getPathLength_return": "Längden på sökvägen mellan <b>cell1</b> och <b>cell2</b>.",
	"func_getPreviousPlayer": "Returnerar id för den enhet som spelade före den aktuella spelaren.",
	"func_getPreviousPlayer_return": "Den tidigare spelaren.",
	"func_getRed": "Returnerar mängden rött i färgen <b>färg</b>, mellan 0 och 255. Till exempel getRed(#COLOR_RED) = 255 och getRed(#COLOR_BLUE) = 0.",
	"func_getRed_arg_1": "Färgen vars röda frekvens kommer att returneras.",
	"func_getRed_return": "Frekvensen för rött i <b>färg</b>",
	"func_getRegister": "Returnerar värdet som är lagrat i enhetsregistret som är kopplat till nyckel <b>nyckel</b> eller </i>null</i> om registret inte finns.",
	"func_getRegister_arg_1": "Registernyckeln vars värde kommer att returneras.",
	"func_getRegister_return": "Värdet som lagras i <b>nyckel</b>-registret.",
	"func_getRegisters": "Returnerar uppsättningen av entitetsregister som en associativ array [<i>registernyckel</i>:<i>registervärde</i>]. Exempel: <code>debug(getRegisters());\n// Visar till exempel:\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "Den associativa matrisen som motsvarar enhetens alla register.",
	"func_getRelativeShield": "Returnerar den relativa skölden för entiteten med id <b>entitet</b>. För att direkt hämta din entitets relativa sköld, använd #getRelativeShield() utan parametrar.",
	"func_getRelativeShield_arg_1": "Id för den enhet vars relativa sköld kommer att returneras.",
	"func_getRelativeShield_return": "Den relativa skölden för <b>entiteten</b>, ett heltal mellan <b>0</b> och <b>100</b>.",
	"func_getResistance": "Returnerar motståndet för enheten med id <b>entitet</b>. Använd getResistance() utan parametrar för att hämta ditt motstånd.",
	"func_getResistance_arg_1": "Id för den enhet vars motstånd kommer att returneras.",
	"func_getResistance_return": "Motståndet för enheten med id <b>entitet</b>.",
	"func_getScience": "Returnerar vetenskapen om entiteten med id <b>entity</b>. Använd getScience() utan parametrar för att hämta din vetenskap.",
	"func_getScience_arg_1": "Id för den enhet vars vetenskap kommer att returneras.",
	"func_getScience_return": "Vetenskapen om id-enhet <b>entitet</b>.",
	"func_getStrength": "Returnerar styrkan för entiteten med id <b>entitet</b>. Använd getStrength() utan parametrar för att hämta din styrka.",
	"func_getStrength_arg_1": "Enheten vars styrka kommer att återlämnas.",
	"func_getStrength_return": "Styrkan hos <b>entitet</b> entiteten.",
	"func_getPower": "Returnerar styrkan för entiteten med id <b>entity</b>.",
	"func_getPower_arg_1": "Enheten vars makt kommer att återlämnas.",
	"func_getPower_return": "Kraften hos <b>entitet</b>.",
	"func_getSummoner": "Returnerar entiteten som anropade entitetens <b>entitet</b>, om det är ett anrop.",
	"func_getSummoner_arg_1": "Id för den enhet vars kallelse kommer att avskedas.",
	"func_getSummoner_return": "ID:t för den anropade <b>entiteten</b> om det är en anrop, <i>null</i> annars.",
	"func_getSummons": "Returnerar listan över id för aktiva anrop av entiteten med id <b>entity</b>.",
	"func_getSummons_arg_1": "Id för den enhet vars kallelse kommer att returneras.",
	"func_getSummons_return": "Listan över anrops-ID för entiteten med id <b>entitet</b>.",
	"func_getTeamID": "Returnerar team-id för entitet <b>entitet</b>.",
	"func_getTeamID_arg_1": "Id för den enhet vars team-id kommer att returneras.",
	"func_getTeamID_return": "Team-ID:t för entitetens <b>entitet</b>.",
	"func_getTeamName": "Returnerar teamnamnet för entitetens <b>entitet</b>.",
	"func_getTeamName_arg_1": "Id för den enhet vars lagnamn kommer att returneras.",
	"func_getTeamName_return": "<b>Entity</b>-enhetens teamnamn.",
	"func_getTotalLife": "Returnerar den totala livslängden för entiteten med id <b>entitet</b>. Använd getTotalLife() utan parametrar för att hämta ditt totala liv.",
	"func_getTotalLife_arg_1": "Id för den enhet vars totala liv kommer att returneras.",
	"func_getTotalLife_return": "Entitetens totala livslängd.",
	"func_getTotalMP": "Returnerar det maximala antalet rörelsepunkter för entitetens <b>entitet</b>.",
	"func_getTotalMP_arg_1": "Id för den enhet vars maximala rörelsepoäng kommer att returneras.",
	"func_getTotalMP_return": "Det maximala antalet rörelsepunkter för <b>entiteten</b>.",
	"func_getTotalTP": "Returnerar det maximala antalet vändpunkter för entitetens <b>entitet</b>.",
	"func_getTotalTP_arg_1": "Id för den enhet vars maximala antal vändpunkter kommer att returneras.",
	"func_getTotalTP_return": "Det maximala antalet vändpunkter för <b>entiteten</b> entiteten.",
	"func_getTP": "Returnerar antalet vändpunkter för entitetens <b>entitet</b>. Använd getTP() utan parametrar för att hämta dina PT:er.",
	"func_getTP_arg_1": "Id för den enhet vars PT:er kommer att returneras.",
	"func_getTP_return": "PT-numret för <b>entiteten</b>-enheten.",
	"func_getTurn": "Returnerar den aktuella stridsvängen. Det maximala antalet varv är #MAX_TURNS.",
	"func_getTurn_return": "Stridens nuvarande vändning.",
	"func_getType": "Returnerar enhetstypen för entitets <b>entitet</b>.",
	"func_getType_arg_1": "Id för den enhet vars typ kommer att returneras.",
	"func_getType_return": "Entitetstypen för <b>entitet</b>:<ul><li>#ENTITY_LEEK om det är en purjolök.</li><li>#ENTITY_BULB om det är en 'a bulb.</li><li >#ENTITY_TURRET om det är ett torn.</li></ul>",
	"func_getWeapon": "Returnerar den för närvarande utrustade <b>entiteten</b> för vapen.",
	"func_getWeapon_arg_1": "Id för den enhet vars nuvarande vapen kommer att returneras.",
	"func_getWeapon_return": "ID:t för det för närvarande utrustade vapnet på <b>entiteten</b>, null om enheten inte har något utrustat vapen eller om enheten inte existerar.",
	"func_getWeaponArea": "Returnerar typen av effektområde för <b>vapen</b> vapnet.",
	"func_getWeaponArea_arg_1": "Vapnet vars zontyp kommer att returneras.",
	"func_getWeaponArea_return": "<b>vapen</b> vapnets områdestyp bland AREA_* konstanterna:\n<ul>\n<li>#AREA_POINT: enkel kvadratisk area</li>\n<li>#AREA_LASER_LINE: laserlinje</li>\n<li>#AREA_CIRCLE_1: cirkulärt område med 3 celler i diameter</li>\n<li>#AREA_CIRCLE_2: cirkulär yta 5 rutor i diameter</li>\n<li>#AREA_CIRCLE_3: cirkulär yta 7 rutor i diameter</li>\n</ul>",
	"func_getWeaponCost": "Returnerar TP-kostnaden för <b>vapnet</b> vapnet.",
	"func_getWeaponCost_arg_1": "Vapnets ID vars kostnad kommer att returneras.",
	"func_getWeaponCost_return": "TP-kostnaden för <b>vapnet</b> vapnet.",
	"func_getWeaponEffectiveArea": "Returnerar listan över celler som kommer att påverkas om vapnet <b>vapnet</b> används i cell <b>cell</b> från cell <b>från</b>.",
	"func_getWeaponEffectiveArea_arg_1": "Vapnet att testa.",
	"func_getWeaponEffectiveArea_arg_2": "Målcellen.",
	"func_getWeaponEffectiveArea_arg_3": "Cellen från vilken vapnet avfyras.",
	"func_getWeaponEffectiveArea_return": "Arrayen som innehåller id för alla celler som kommer att påverkas.",
	"func_getWeaponPassiveEffects": "Returnerar de passiva effekterna av vapnets <b>vapnet</b>.",
	"func_getWeaponPassiveEffects_arg_1": "Id för vapnet vars passiva effekter kommer att returneras.",
	"func_getWeaponPassiveEffects_return": "En array som innehåller effekterna av <b>vapnet</b> vapnet. Varje effekt är i sig en array av formen\n[typ, min, max, vändningar, mål, modifierare]. Dessa effekter är desamma som de som returneras av #getWeaponEffects.",
	"func_getWeaponFailure": "Returnerar procentandelen för <b>vapen</b> risk för att vapen misslyckats.",
	"func_getWeaponFailure_arg_1": "Id för vapnet vars felprocent kommer att returneras.",
	"func_getWeaponFailure_return": "<b>vapen</b> vapenmissprocent, ett heltal mellan <b>0</b> och <b>100</b>.",
	"func_getWeaponMaxRange": "Returnerar den maximala räckvidden för <b>vapen</b> vapnet.",
	"func_getWeaponMaxRange_arg_1": "Id för vapnet vars maximala räckvidd kommer att returneras.",
	"func_getWeaponMaxRange_return": "Den maximala räckvidden för <b>vapen</b> vapnet.",
	"func_getWeaponMaxScope": "Returnerar den maximala räckvidden för <b>vapen</b> vapnet.",
	"func_getWeaponMaxScope_arg_1": "Id för vapnet vars maximala räckvidd kommer att returneras.",
	"func_getWeaponMaxScope_return": "Den maximala räckvidden för <b>vapen</b> vapnet.",
	"func_getWeaponMinRange": "Returnerar minimiområdet för <b>vapnet</b> vapnet.",
	"func_getWeaponMinRange_arg_1": "Id för vapnet vars minsta räckvidd kommer att returneras.",
	"func_getWeaponMinRange_return": "Minsta räckvidd för <b>vapen</b> vapnet.",
	"func_getWeaponMinScope": "Returnerar minimiområdet för <b>vapnet</b> vapnet.",
	"func_getWeaponMinScope_arg_1": "Id för vapnet vars minsta räckvidd kommer att returneras.",
	"func_getWeaponMinScope_return": "Minsta räckvidd för <b>vapen</b> vapnet.",
	"func_getWeaponLaunchType": "Returnerar startläget för <b>vapen</b> vapnet, bland LAUNCH_TYPE_* konstanterna.",
	"func_getWeaponLaunchType_arg_1": "Id för vapnet vars kastläge kommer att returneras. Standardinställning för ditt för närvarande utrustade vapen.",
	"func_getWeaponLaunchType_return": "Kastläget för <b>vapen</b> vapnet.",
	"func_getWeaponName": "Returnerar namnet på <b>vapnet</b> vapnet.",
	"func_getWeaponName_arg_1": "Id för vapnet vars namn kommer att returneras.",
	"func_getWeaponName_return": "Namnet på <b>vapnet</b> vapnet.",
	"func_getWeapons": "Returnerar vapen för entiteten med id <b>entity</b>.",
	"func_getWeapons_arg_1": "Id för den enhet vars vapen kommer att returneras.",
	"func_getWeapons_return": "En array som innehåller <b>entitets</b> vapen-ID.",
	"func_getWeaponTargets": "Returnerar entiteterna som kommer att påverkas om vapnet <b>vapnet</b> används på cell <b>cell</b>.",
	"func_getWeaponTargets_arg_1": "Vapnet att testa.",
	"func_getWeaponTargets_arg_2": "Målcellen.",
	"func_getWeaponTargets_return": "Arrayen som innehåller ID:n för alla enheter som kommer att påverkas.",
	"func_getWisdom": "Returnerar visdomen för entiteten med id <b>entity</b>. Använd getWisdom() utan parametrar för att hämta din visdom.",
	"func_getWisdom_arg_1": "Id för den entitet vars visdom kommer att returneras.",
	"func_getWisdom_return": "Entitetens visdom med id <b>entity</b>.",
	"func_hypot": "Returnerar hypotenusan för den räta triangeln med sidorna x och y. Motsvarar <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>.",
	"func_hypot_arg_1": "X-värdet.",
	"func_hypot_arg_2": "Y-värdet.",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "Bestämmer om <b>elementet</b>-elementet finns i arrayen <b>array</b>.",
	"func_inArray_arg_1": "Söktabellen.",
	"func_inArray_arg_2": "Objektet att söka efter.",
	"func_inArray_return": "<i>true</i> om elementet finns i arrayen, <i>false</i> annars.",
	"func_include": "Inkludera AI med namnet <b>ai</b> i den aktuella AI:n. <br><br><b>Varning</b>, funktionen include måste endast anropas i huvudblocket, och dess parameter måste vara en sträng skriven direkt\ni koden. För mer information, se avsnittet i handledningen: <a href='http://leekwas.com/help/tutorial#includes'>http://leekwas.com/help/tutorial#includes</a>.",
	"func_include_arg_1": "Namnet på den AI som ska inkluderas.",
	"func_indexOf": "Bestämmer positionen för den första förekomsten av strängen <b>sökning</b> i strängen <b>sträng</b>, med start från position <b>start</b>.",
	"func_indexOf_arg_1": "Strängen där sökningen kommer att utföras.",
	"func_indexOf_arg_2": "Strängen att söka efter.",
	"func_indexOf_arg_3": "Sökningens startposition.",
	"func_indexOf_return": "Positionen för den första förekomsten av <b>sökning</b> i <b>sträng</b> från <b>start</b>, -1 om strängen inte hittades.",
	"func_insert": "Infogar ett <b>element</b> i <b>arrayen</b> vid position <b>position</b>.",
	"func_insert_arg_1": "Arrayen där elementet kommer att infogas.",
	"func_insert_arg_2": "Objektet som ska infogas.",
	"func_insert_arg_3": "Införandets läge.",
	"func_isAlive": "Avgör om en <b>enhet</b> är vid liv. Motsvarar <i>getLife(<b>entity</b>) > 0</i>.",
	"func_isAlive_arg_1": "Id för den enhet som ska testas.",
	"func_isAlive_return": "<i>sant</i> om <b>entitet</b> är vid liv, <i>false</i> om död.",
	"func_isAlly": "Avgör om <b>entitet</b> är din allierade.",
	"func_isAlly_arg_1": "Id för den enhet som ska testas.",
	"func_isAlly_return": "<i>sant</i> om <b>entiteten</b> är din allierade eller dig själv, <i>falskt</i> om det är en fiende.",
	"func_isChip": "Bestämmer om ett värde är en konstant som representerar ett chip.<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "Antalet som ska bestämmas.",
	"func_isChip_return": "<i>true</i> om värdet är en chipkonstant.",
	"func_isDead": "Avgör om <b>entitet</b> är död. Motsvarar <i>getLife(<b>entity</b>) == 0</i>.",
	"func_isDead_arg_1": "Id för den enhet som ska testas.",
	"func_isDead_return": "<i>sant</i> om <b>entiteten</b> är död, <i>falskt</i> om den lever.",
	"func_isEmpty": "Bestämmer om arrayen <b>array</b> är tom. Motsvarar <i>count(<b>array</b>) == 0</i>.",
	"func_isEmpty_arg_1": "Arrayen att testa.",
	"func_isEmptyCell": "Bestämmer om en cell är tom.",
	"func_isEmptyCell_arg_1": "Cellen som ska testas.",
	"func_isEmptyCell_return": "<i>true</i> om cellen är tom, <i>false</i> annars.",
	"func_isEmpty_return": "<i>true</i> om arrayen är tom, <i>false</i> annars.",
	"func_isEnemy": "Avgör om <b>entitet</b> är din fiende.",
	"func_isEnemy_arg_1": "Id för den enhet som ska testas.",
	"func_isEnemy_return": "<i>sant</i> om <b>entiteten</b> är en fiende, <i>falskt</i> om det är en allierad eller dig själv.",
	"func_isInlineChip": "Avgör om <b>chippet</b> endast kan användas online.",
	"func_isInlineChip_arg_1": "ID för chipet som ska testas.",
	"func_isInlineChip_return": "<i>true</i> om chipet endast är online, <i>false</i> annars.",
	"func_isInlineWeapon": "Avgör om <b>vapnet</b> vapnet endast kan användas online.",
	"func_isInlineWeapon_arg_1": "ID för vapnet som ska testas.",
	"func_isInlineWeapon_return": "<i>sant</i> om vapnet bara kan användas online, <i>false</i> annars.",
	"func_isLeek": "Bestämmer om innehållet i cell <b>cell</b> är en enhet.",
	"func_isLeek_arg_1": "Cellen som ska testas.",
	"func_isLeek_return": "<i>true</i> om cellen innehåller en enhet, <i>false</i> annars.",
	"func_isEntity": "Bestämmer om innehållet i cell <b>cell</b> är en enhet.",
	"func_isEntity_arg_1": "Cellen som ska testas.",
	"func_isEntity_return": "<i>true</i> om cellen innehåller en enhet, <i>false</i> annars.",
	"func_isObstacle": "Bestämmer om innehållet i cell <b>cell</b> är ett hinder.",
	"func_isObstacle_arg_1": "Cellen som ska testas.",
	"func_isObstacle_return": "<i>true</i> om cellen innehåller ett hinder, <i>false</i> annars.",
	"func_isOnSameLine": "Bestämmer om två celler <b>cell1</b> och <b>cell2</b> är på samma rad.",
	"func_isOnSameLine_arg_1": "Den första cellen.",
	"func_isOnSameLine_arg_2": "Den andra cellen.",
	"func_isOnSameLine_return": "<i>true</i> om de två cellerna är på samma rad, <i>false</i> annars.",
	"func_isStatic": "Returnerar om <b>entitet</b> är statisk eller inte. En statisk enhet kan inte flyttas eller flyttas.",
	"func_isStatic_arg_1": "Id för den enhet som ska testas.",
	"func_isStatic_return": "<i>true</i> om <b>entitet</b> är statisk, <i>false</i> annars.",
	"func_isSummon": "Returnerar om <b>entitet</b> är en anrop eller inte.",
	"func_isSummon_arg_1": "Id för den enhet som ska testas.",
	"func_isSummon_return": "<i>true</i> om <b>entitet</b> är en anrop, <i>false</i> annars.",
	"func_isWeapon": "Bestämmer om ett värde är en konstant som representerar ett vapen.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false.",
	"func_isWeapon_arg_1": "Antalet som ska bestämmas.",
	"func_isWeapon_return": "<i>sant</i> om värdet är en vapenkonstant.",
	"func_join": "Slår ihop flera element till en sträng och separerar dem med en <b>lim</b>-avgränsare.",
	"func_join_arg_1": "En rad objekt att slå samman.",
	"func_join_arg_2": "Elementavgränsaren.",
	"func_join_return": "Den resulterande strängen för sammanslagningen.",
	"func_jsonDecode": "Avkodar sträng <b>json</b> till LeekScript-objekt (nummer, sträng, array...).",
	"func_jsonDecode_arg_1": "JSON-strängen som ska avkodas.",
	"func_jsonDecode_return": "Det avkodade LeekScript-objektet.",
	"func_jsonEncode": "Kodar <b>object</b>-objektet till en JSON-sträng.",
	"func_jsonEncode_arg_1": "Objektet som ska kodas i JSON.",
	"func_jsonEncode_return": "Den JSON-kodade strängen.",
	"func_keySort": "Sorterar <b>arrayen</b> efter nycklar i <b>ordning</b> ordning.",
	"func_keySort_arg_1": "Arrayen att sortera.",
	"func_keySort_arg_2": "Sorteringsordningen: #SORT_ASC eller #SORT_DESC.",
	"func_length": "Returnerar längden på <b>sträng</b>.",
	"func_length_arg_1": "Strängen vars längd kommer att returneras.",
	"func_length_return": "Längden på <b>sträng</b>.",
	"func_lineOfSight": "Kontrollerar siktlinjen mellan <b>start</b>-cellen och <b>slut</b>-cellen och ignorerar <b>ignoredEntities</b>-enheter.",
	"func_lineOfSight_arg_1": "Startcell.",
	"func_lineOfSight_arg_2": "Målcell.",
	"func_lineOfSight_arg_3": "En enhet eller lista över enheter att ignorera.",
	"func_lineOfSight_return": "Returnerar <i>true</i> om siktlinjen är fri.",
	"func_listen": "Returnerar arrayen av say() för tidigare entiteter, i formen [entity_id, message].",
	"func_listen_return": "Arrayen av tidigare say()s.",
	"func_log": "Beräknar den naturliga logaritmen för talet <b>talet</b>.",
	"func_log_arg_1": "Ett tal i intervallet ]0; +∞[.",
	"func_log_return": "Den naturliga logaritmen för <b>tal</b>.",
	"func_log10": "Beräknar basen 10-logaritmen för talet <b>talet</b>.",
	"func_log10_arg_1": "Ett tal i intervallet ]0; +∞[.",
	"func_log10_return": "Basen 10-logaritmen för <b>tal</b>.",
	"func_log2": "Beräknar bas 2-logaritmen för talet <b>tal</b>.",
	"func_log2_arg_1": "Ett tal i intervallet ]0; +∞[.",
	"func_log2_return": "Bas 2-logaritmen för <b>tal</b>.",
	"func_mark": "Markerar en eller flera celler med den färg som anges i parametern på marken för antalet varv som anges i parametern. Denna märkning är endast synlig för uppfödaren av enheten.",
	"func_mark_arg_1": "Cellen eller arrayen med flera celler som ska markeras",
	"func_mark_arg_2": "Markeringsfärg",
	"func_mark_arg_3": "Märkningens varaktighet",
	"func_mark_return": "Returnerar sant om allt gick bra",
	"func_markText": "Skriver en text på en eller flera celler med den färg som anges i parametern på marken för antalet varv som anges i parametern. Dessa texter är endast synliga för uppfödaren av enheten.",
	"func_markText_arg_1": "Cellen eller arrayen med flera celler att skriva till",
	"func_markText_arg_2": "Texten att skriva (max 10 tecken)",
	"func_markText_arg_3": "Text färg",
	"func_markText_arg_4": "Textens varaktighet",
	"func_markText_return": "Returnerar sant om allt gick bra",
	"func_max": "Returnerar det större värdet mellan de två talen <b>a</b> och <b>b</b>.",
	"func_max_arg_1": "Ett nummer.",
	"func_max_arg_2": "Ett nummer.",
	"func_max_return": "Det större antalet mellan <b>a</b> och <b>b</b>.",
	"func_min": "Returnerar det mindre värdet mellan de två talen <b>a</b> och <b>b</b>.",
	"func_min_arg_1": "Ett nummer.",
	"func_min_arg_2": "Ett nummer.",
	"func_min_return": "Det mindre antalet mellan <b>a</b> och <b>b</b>.",
	"func_moveAwayFrom": "Flyttar din enhet bort från en annan <b>entitet</b> med maximala <b>mp</b> rörelsepoäng.",
	"func_moveAwayFrom_arg_1": "Entiteten din enhet bör flytta ifrån.",
	"func_moveAwayFrom_arg_2": "Det maximala antalet MP att använda. Som standard, ingen gräns.",
	"func_moveAwayFrom_return": "Antalet rörelsepunkter som används.",
	"func_moveAwayFromCell": "Flyttar din enhet en <b>cell</b> cell bort och använder upp <b>mp</b> rörelsepunkter.",
	"func_moveAwayFromCell_arg_1": "Cellen din enhet bör flytta ifrån.",
	"func_moveAwayFromCell_arg_2": "Det maximala antalet MP att använda. Som standard, ingen gräns.",
	"func_moveAwayFromCell_return": "Antalet rörelsepunkter som används.",
	"func_moveAwayFromCells": "Flyttar din enhet bort från en uppsättning <b>cell</b>-celler, med maximala <b>mp</b>-rörelsepunkter.",
	"func_moveAwayFromCells_arg_1": "Arrayen som innehåller cellerna som din enhet ska flytta ifrån.",
	"func_moveAwayFromCells_arg_2": "Det maximala antalet MP att använda. Som standard, ingen gräns.",
	"func_moveAwayFromCells_return": "Antalet rörelsepunkter som används.",
	"func_moveAwayFromLeeks": "Flyttar din enhet bort från en uppsättning <b>entiteter</b>, med maximala <b>mp</b> rörelsepunkter.",
	"func_moveAwayFromLeeks_arg_1": "Arrayen som innehåller id:erna för de entiteter som din enhet ska flytta ifrån.",
	"func_moveAwayFromLeeks_arg_2": "Det maximala antalet MP att använda. Som standard, ingen gräns.",
	"func_moveAwayFromLeeks_return": "Antalet rörelsepunkter som används.",
	"func_moveAwayFromEntities": "Flyttar din enhet bort från en uppsättning <b>entiteter</b>, med maximala <b>mp</b> rörelsepunkter.",
	"func_moveAwayFromEntities_arg_1": "Arrayen som innehåller id:erna för de entiteter som din enhet ska flytta ifrån.",
	"func_moveAwayFromEntities_arg_2": "Det maximala antalet MP att använda. Som standard, ingen gräns.",
	"func_moveAwayFromEntities_return": "Antalet rörelsepunkter som används.",
	"func_moveAwayFromLine": "Flyttar din enhet bort från en linje som definieras av två celler <b>cell1</b> och <b>cell2</b>, med maximalt <b>mp</b> rörelsepunkter.",
	"func_moveAwayFromLine_arg_1": "Cell 1.",
	"func_moveAwayFromLine_arg_2": "Cell 2.",
	"func_moveAwayFromLine_arg_3": "Det maximala antalet MP att använda. Som standard, ingen gräns.",
	"func_moveAwayFromLine_return": "Antalet rörelsepunkter som används.",
	"func_moveToward": "För din enhet närmare en annan <b>enhet</b> med maximala <b>mp</b> rörelsepunkter.",
	"func_moveToward_arg_1": "Entiteten din enhet bör vända sig till.",
	"func_moveToward_arg_2": "Det maximala antalet MP att använda.",
	"func_moveToward_return": "Antalet rörelsepunkter som används.",
	"func_moveTowardCell": "Flyttar din enhet en <b>cell</b> cell närmare, med maximala <b>mp</b> rörelsepunkter.",
	"func_moveTowardCell_arg_1": "Cellen din funktion bör närma sig.",
	"func_moveTowardCell_arg_2": "Det maximala antalet MP att använda. Som standard, ingen gräns.",
	"func_moveTowardCell_return": "Antalet rörelsepunkter som används.",
	"func_moveTowardCells": "Flyttar din enhet närmare en uppsättning <b>cell</b>-celler, med maximala <b>mp</b>-rörelsepunkter.",
	"func_moveTowardCells_arg_1": "Arrayen som innehåller cellerna som din enhet bör närma sig.",
	"func_moveTowardCells_arg_2": "Det maximala antalet MP att använda. Som standard, ingen gräns.",
	"func_moveTowardCells_return": "Antalet rörelsepunkter som används.",
	"func_moveTowardLeeks": "För din enhet närmare en uppsättning <b>entiteter</b>, med maximala <b>mp</b> rörelsepunkter.",
	"func_moveTowardLeeks_arg_1": "Arrayen som innehåller id:erna för de enheter som din enhet bör närma sig.",
	"func_moveTowardLeeks_arg_2": "Det maximala antalet MP att använda. Som standard, ingen gräns.",
	"func_moveTowardLeeks_return": "Antalet rörelsepunkter som används.",
	"func_moveTowardEntities": "För din enhet närmare en uppsättning <b>entiteter</b>, med maximala <b>mp</b> rörelsepunkter.",
	"func_moveTowardEntities_arg_1": "Arrayen som innehåller id:erna för de enheter som din enhet bör närma sig.",
	"func_moveTowardEntities_arg_2": "Det maximala antalet MP att använda. Som standard, ingen gräns.",
	"func_moveTowardEntities_return": "Antalet rörelsepunkter som används.",
	"func_moveTowardLine": "För din enhet närmare en linje som definieras av två celler <b>cell1</b> och <b>cell2</b>, med maximala <b>mp</b> rörelsepunkter.",
	"func_moveTowardLine_arg_1": "Cell 1.",
	"func_moveTowardLine_arg_2": "Cell 2.",
	"func_moveTowardLine_arg_3": "Det maximala antalet MP att använda. Som standard, ingen gräns.",
	"func_moveTowardLine_return": "Antalet rörelsepunkter som används.",
	"func_number": "Konverterar ett värde till ett tal. Om värdet är en sträng kommer talfunktionen att försöka konvertera det till ett tal, om värdet redan är ett tal returnerar funktionen talet och för alla andra typer returnerar den <i>null</i>.",
	"func_number_arg_1": "Värdet som ska konverteras till ett tal.",
	"func_number_return": "Det konverterade talet.",
	"func_pause": "Pausar kampen, endast för uppfödaren av enheten som använder funktionen.",
	"func_pop": "Tar bort den sista cellen från arrayen <b>array</b> och returnerar motsvarande element.",
	"func_pop_arg_1": "Arrayen vars sista ruta kommer att tas bort.",
	"func_pop_return": "Varan som låg i den gamla sista lådan.",
	"func_pow": "Höjer bastalet till exponentpotentialen.",
	"func_pow_arg_1": "Basen.",
	"func_pow_arg_2": "Utställaren.",
	"func_pow_return": "<b>bas</b> höjt till <b>exponent</b> potens.",
	"func_push": "Lägger till <b>element</b>-elementet i slutet av <b>arrayen</b>.",
	"func_pushAll": "Lägger till alla element i <b>elements</b>-matrisen till slutet av <b>arrayen</b>.",
	"func_pushAll_arg_1": "Arrayen där vi vill lägga till elementen.",
	"func_pushAll_arg_2": "Objekt att lägga till.",
	"func_push_arg_1": "Arrayen där vi vill lägga till elementet.",
	"func_push_arg_2": "Objektet att lägga till.",
	"func_rand": "Returnerar ett verkligt slumptal, mellan 0 (inklusive) och 1 (exklusivt).",
	"func_randFloat": "Returnerar ett verkligt slumptal, mellan <b>a</b> (inklusive) och <b>b</b> (exklusivt).",
	"func_randFloat_arg_1": "Nedre gräns.",
	"func_randFloat_arg_2": "Övre gräns.",
	"func_randFloat_return": "Ett slumptal i intervallet [a; b[.",
	"func_randInt": "Returnerar ett slumpmässigt heltal mellan <b>a</b> (inklusive) och <b>b</b> (exklusivt).",
	"func_randInt_arg_1": "Nedre gräns.",
	"func_randInt_arg_2": "Övre gräns.",
	"func_randInt_return": "Ett slumpmässigt heltal i intervallet [a; b[.",
	"func_rand_return": "Ett slumptal i intervallet [0; 1[.",
	"func_remove": "Tar bort ett element från arrayen <b>array</b> vid position <b>position</b> och returnerar det borttagna elementet.",
	"func_remove_arg_1": "Array från vilken vi vill ta bort ett element.",
	"func_remove_arg_2": "Position för elementet som ska raderas.",
	"func_removeElement": "Tar bort den första förekomsten av ett <b>element</b> i <b>arrayen</b>. Om elementet inte hittas ändras inte arrayen.",
	"func_removeElement_arg_1": "Array från vilken vi vill ta bort ett element.",
	"func_removeElement_arg_2": "Objekt att hitta och sedan ta bort.",
	"func_removeKey": "Tar bort ett element från arrayen <b>array</b> som är associerad med nyckeln <b>key</b>.",
	"func_removeKey_arg_1": "Array från vilken vi vill ta bort ett element.",
	"func_removeKey_arg_2": "Nyckeln som är kopplad till objektet som ska raderas.",
	"func_remove_return": "Elementet som togs bort från arrayen.",
	"func_replace": "Ersätter alla förekomster av <b>sökning</b> med <b>ersätt</b> i strängen <b>sträng</b>.",
	"func_replace_arg_1": "Sträng i vilken ersättningar görs.",
	"func_replace_arg_2": "Delsträng att ersätta.",
	"func_replace_arg_3": "Ersättningssträng.",
	"func_replace_return": "Resultatsträngen, med ersättningar.",
	"func_resurrect": "Använder CHIP_RESURRECTION-chippet för att återuppliva en död enhet med id <b>entity</b>, på cell <b>cell</b>.",
	"func_resurrect_arg_1": "Id för den enhet som ska återupplivas.",
	"func_resurrect_arg_2": "Cellen där enheten kommer att återuppstå.",
	"func_resurrect_return": "Resultatet av lanseringen av chipet, bland konstanterna USE_*.",
	"func_reverse": "Vänder om <b>arrayen</b>.",
	"func_reverse_arg_1": "Tabell att invertera.",
	"func_round": "Returnerar avrundningen av <b>tal</b>.<br/>Du kan hitta avrundningen nedåt med #golv och avrundningen uppåt med #tak.",
	"func_round_arg_1": "Tal vars avrundning vi vill hitta.",
	"func_round_return": "Avrundat efter <b>nummer</b>.",
	"func_say": "Få din enhet att tala.",
	"func_say_arg_1": "Meddelande som din enhet kommer att meddela på arenan.",
	"func_search": "Hittar elementet <b>element</b> i arrayen <b>array</b>, med start från position <b>start</b>.",
	"func_search_arg_1": "Söktabellen.",
	"func_search_arg_2": "Objektet att söka efter.",
	"func_search_arg_3": "Positionen för början av sökningen.",
	"func_search_return": "Positionen för den första förekomsten av <b>element</b>-elementet i <b>arrayen</b>, med start från <b>start</b>, null om elementet inte har hittats.",
	"func_sendAll": "Skicka ett meddelande till hela ditt team.",
	"func_sendAll_arg_1": "Typen av meddelande som ska skickas (se MESSAGE_* konstanterna).",
	"func_sendAll_arg_2": "Meddelandeparametrarna, som kan vara vilket värde som helst.",
	"func_sendTo": "Skickar ett meddelande till entiteten med id <b>entity</b>.",
	"func_sendTo_arg_1": "Id för den enhet som meddelandet kommer att skickas till.",
	"func_sendTo_arg_2": "Typen av meddelande som ska skickas (se MESSAGE_* konstanterna).",
	"func_sendTo_arg_3": "Meddelandeparametrarna, som kan vara vilket värde som helst.",
	"func_sendTo_return": "<i>true</i> om sändningen lyckades, <i>false</i> om ett fel inträffade.",
	"func_setRegister": "Lagrar <b>värdet</b> i <b>nyckel</b>-registret.\nNyckeln och värdet är strängar som högst måste innehålla <i>100</i> respektive <i>5000</i> tecken. En purjolök kan ha maximalt <i>100</i> register, lagring i ett nytt register fungerar inte om alla register\när redan upptagna.",
	"func_setRegister_arg_1": "Registernyckeln där värdet ska lagras.",
	"func_setRegister_arg_2": "Värdet att lagra.",
	"func_setRegister_return": "<i>true</i> om operationen lyckades, <i>false</i> annars.",
	"func_setWeapon": "Utrusta <b>vapen</b> vapnet på din enhet.",
	"func_setWeapon_arg_1": "Id för vapnet som ska utrustas.",
	"func_shift": "Tar bort den första cellen från <b>arrayen</b> och returnerar motsvarande element.",
	"func_shift_arg_1": "Arrayen vars första box kommer att tas bort.",
	"func_shift_return": "Elementet som fanns i den gamla första lådan.",
	"func_show": "Visar spelarna en <b>cell</b> med färgen <b>färg</b> på fältet under 1 tur. Att använda denna funktion kostar 1PT.",
	"func_show_arg_1": "Cellen som ska visas",
	"func_show_arg_2": "Markeringsfärg",
	"func_show_return": "Returnerar sant om allt gick bra",
	"func_shuffle": "Blandar en bräda slumpmässigt.",
	"func_shuffle_arg_1": "Shufflebrädan.",
	"func_signum": "Bestämmer tecknet för talet <b>talet</b>.",
	"func_signum_arg_1": "Antalet vars tecken kommer att bestämmas.",
	"func_signum_return": "1 om talet är positivt, 0 om talet är noll och -1 om talet är negativt.",
	"func_sin": "Beräknar sinus för vinkeln <b>vinkel</b> som skickas som parameter",
	"func_sin_arg_1": "Vinkeln vars sinus kommer att beräknas",
	"func_sin_return": "Returnerar sinus för vinkeln <b>vinkel</b>",
	"func_sort": "Sorterar <b>matrisen</b> i stigande eller fallande ordning. Standard stigande ordning är:\n<ul>\n<li>Alla booleaner (först falskt sedan sant)</li>\n<li>Alla nummer (ordnade från minsta till största)</li>\n<li>Alla kanaler (i alfabetisk ordning)</li>\n<li>Alla null-element.</li>\n</ul>",
	"func_sort_arg_1": "Array att sortera.",
	"func_sort_arg_2": "#SORT_ASC för att sortera <b>array</b> i stigande ordning eller #SORT_DESC för att sortera den i fallande ordning.",
	"func_split": "Delar upp strängen <b>sträng</b> i delsträngar avgränsade med <b>avgränsare</b>.",
	"func_split_arg_1": "Skärande kedja.",
	"func_split_arg_2": "Sträng som avgränsar passagen från ett element till ett annat.",
	"func_split_arg_3": "Maximalt antal arrayelement.",
	"func_split_return": "Array som innehåller de hittade delsträngarna.",
	"func_sqrt": "Beräknar kvadratroten av talet <b>tal</b>.",
	"func_sqrt_arg_1": "Antal vars rot kommer att beräknas.",
	"func_sqrt_return": "Kvadratroten ur <b>tal</b>.",
	"func_startsWith": "Kontrollerar om <b>sträng</b> börjar med <b>prefix</b>.",
	"func_startsWith_arg_1": "Söksträng.",
	"func_startsWith_arg_2": "Sökte efter prefix.",
	"func_startsWith_return": "Sant om <b>strängen</b> börjar med <b>prefix</b>.",
	"func_string": "Konverterar ett värde till en sträng.<br>\nOm värdet redan är en sträng returneras det.<br>\nOm värdet är nummer x, returneras \"x\".<br>\nOm värdet är en matris returneras en sträng av formen \"[nyckel1: värde1, nyckel2: värde2, ...]\".<br>\nOm värdet är ett booleskt värde, returneras \"true\" och \"false\" för true respektive false.<br>\nOm värdet är null returneras \"null\".",
	"func_string_arg_1": "Värdet som ska konverteras till en teckensträng.",
	"func_string_return": "Den konverterade strängen.",
	"func_subArray": "Returnerar en undermatris av <b>matris</b> som börjar vid position <b>start</b> och slutar vid position <b>slut</b>.",
	"func_subArray_arg_1": "Källtabell.",
	"func_subArray_arg_2": "Startindex.",
	"func_subArray_arg_3": "Slutindex.",
	"func_subArray_return": "Sub-array.",
	"func_substring": "Returnerar delsträngen för strängen <b>sträng</b> med början från tecknet vid index <b>start</b> och storleken <b>längd</b>.",
	"func_substring_arg_1": "Skärande kedja.",
	"func_substring_arg_2": "Startteckenindex.",
	"func_substring_arg_3": "Längden på delsträngen.",
	"func_substring_return": "Delsträng.",
	"func_sum": "Returnerar summan av alla numeriska element i arrayen <b>array</b>.",
	"func_sum_arg_1": "Tabell för att summera.",
	"func_summon": "Anropar en enhet som bestäms av chipet <b>chip</b> på cellen <b>cell</b> som har för IA funktionen <b>ai</b>.",
	"func_summon_arg_1": "Chipet som används för att kalla. Chipet måste vara ett chip av typen summon och måste vara utrustat på den enhet som använder summonfunktionen.",
	"func_summon_arg_2": "Cellen eller kallelsen ska visas.",
	"func_summon_arg_3": "Anropet AI, som en funktion.",
	"func_summon_return": "Summon-funktionen har samma avkastning som #useChip-funktionen.",
	"func_sum_return": "Summan av arrayelement.",
	"func_tan": "Beräknar tangenten för vinkeln <b>vinkel</b> (i radianer).",
	"func_tan_arg_1": "Vinkel i radianer.",
	"func_tan_return": "Tangent för <b>vinkel</b>.",
	"function_category_array": "Listor",
	"function_category_chip": "Loppor",
	"function_category_color": "Färg",
	"function_category_fight": "Bekämpa",
	"function_category_leek": "Purjolök",
	"function_category_map": "Tabeller",
	"function_category_field": "Jord",
	"function_category_math": "Tal",
	"function_category_network": "Nätverk",
	"function_category_string": "Kedjor",
	"function_category_utils": "Verktyg",
	"function_category_weapon": "Vapen",
	"func_toDegrees": "Konverterar vinkel <b>radianer</b> till grader.",
	"func_toDegrees_arg_1": "Värde i radianer",
	"func_toDegrees_return": "Vinkel i grader.",
	"func_toLower": "Konverterar versaler i <b>sträng</b> till gemener.",
	"func_toLower_arg_1": "Sträng för att transformera",
	"func_toLower_return": "Snöre med små bokstäver",
	"func_toRadians": "Konverterar vinkel <b>grader</b> till radianer.",
	"func_toRadians_arg_1": "Värde i grader",
	"func_toRadians_return": "Vinkel i radianer.",
	"func_toUpper": "Konverterar gemener i <b>sträng</b> till versaler.",
	"func_toUpper_arg_1": "Sträng för att transformera.",
	"func_toUpper_return": "Versalsträng.",
	"func_typeOf": "Returnerar typen av värdet <b>värde</b>, bland typerna: #TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION.",
	"func_typeOf_arg_1": "Värdet vars typ kommer att returneras.",
	"func_typeOf_return": "Typen av <b>värde</b>.",
	"func_unshift": "Lägger till <b>element</b>-elementet i början av <b>arrayen</b>.",
	"func_unshift_arg_1": "Array där elementet kommer att läggas till.",
	"func_unshift_arg_2": "Objekt att lägga till.",
	"func_useChip": "Använd chip <b>chip</b> på entity <b>entity</b>.",
	"func_useChip_arg_1": "Chip att använda.",
	"func_useChip_arg_2": "Målenhet.",
	"func_useChipOnCell": "Använd chip <b>chip</b> på cell <b>cell</b>.",
	"func_useChipOnCell_arg_1": "Chip att använda.",
	"func_useChipOnCell_arg_2": "Målcell.",
	"func_useChipOnCell_return": "Ett värde större än 0 om attacken inleddes.",
	"func_useChip_return": "Returvärdena för useChip är:<br>\n<ul>\n<li>#USE_CRITICAL, på kritisk träff</li>\n<li>#USE_SUCCESS, om det lyckas</li>\n<li>#USE_FAILED, vid misslyckande</li>\n<li>#USE_INVALID_TARGET, om målet inte finns</li>\n<li>#USE_NOT_ENOUGH_TP, om din enhet inte har tillräckligt med TP</li>\n<li>#USE_INVALID_COOLDOWN, om chipet inte är användbart än</li>\n<li>#USE_INVALID_POSITION, om räckvidden är dålig eller om siktlinjen inte är tydlig</li>\n</ul>",
	"func_useWeapon": "Använd valt vapen på <b>entitet</b>.",
	"func_useWeapon_arg_1": "Målenhet.",
	"func_useWeaponOnCell": "Använd det valda vapnet i cellen <b>cell</b>.",
	"func_useWeaponOnCell_arg_1": "Målcell.",
	"func_useWeaponOnCell_return": "Ett värde större än 0 om attacken inleddes.",
	"func_useWeapon_return": "Returvärdena för useWeapon är:<br>\n<ul>\n<li>#USE_CRITICAL, på kritisk träff</li>\n<li>#USE_SUCCESS, om det lyckas</li>\n<li>#USE_FAILED, vid misslyckande</li>\n<li>#USE_INVALID_TARGET, om målet inte finns</li>\n<li>#USE_NOT_ENOUGH_TP, om din enhet inte har tillräckligt med TP</li>\n<li>#USE_INVALID_POSITION, om räckvidden är dålig eller om siktlinjen inte är tydlig</li>\n</ul>",
	"func_weaponNeedLos": "Returnerar om <b>vapnet</b> vapnet behöver siktlinje för att avfyra.",
	"func_weaponNeedLos_arg_1": "ID för vapnet som ska testas.",
	"func_weaponNeedLos_return": "<i>sant</i> om <b>vapnet</b> vapnet behöver siktlinje för att avfyra, <i>falskt</i> annars.",
	"items": "Föremål",
	"max_cores": "max",
	"max_level": "max",
	"min_cores": "Minsta hjärtan",
	"min_level": "Min nivå",
	"n_operations": "<b>{0}</b> operationer",
	"parameters": "Inställningar",
	"return": "Respons",
	"value": "Värde",
	"variable_operations": "<b>Variabel</b> operationer",
	"deprecated_function": "Denna funktion är föråldrad.",
	"deprecated_constant": "Denna konstant är utfasad.",
	"replaced_by": "Den ersätts av {0}.",
	"details": "Detaljer",
	"optional": "frivillig",
	"arg_type_9": "paket",
	"arg_type_10": "intervall",
	"function_category_set": "Paket",
	"function_category_interval": "Intervall",
	"arg_type_96": "uppsättning<entier>"
}

	i18n.mergeLocaleMessage("sv", { "doc": messages })
	