
	import { i18n } from '@/model/i18n'
	const messages = {
	"1_operation": "<b>1</b> 작업",
	"arg_type_-1": "?",
	"arg_type_1": "숫자",
	"arg_type_2": "체인",
	"arg_type_3": "부울",
	"arg_type_4": "목록",
	"arg_type_41": "목록<숫자>",
	"arg_type_42": "목록<문자열>",
	"arg_type_43": "목록<부울>",
	"arg_type_44": "목록<목록>",
	"arg_type_46": "목록<정수>",
	"arg_type_47": "목록<진짜>",
	"arg_type_5": "기능",
	"arg_type_6": "전체",
	"arg_type_7": "진짜",
	"arg_type_8": "테이블",
	"arg_type_806": "테이블<?, 정수>",
	"complexity": "복잡성 {c}",
	"operations": "{o} 작업",
	"details": "세부",
	"optional": "선택 과목",
	"deprecated_function": "이 기능은 더 이상 사용되지 않습니다.",
	"deprecated_constant": "이 상수는 더 이상 사용되지 않습니다.",
	"replaced_by": "{0}로 대체됩니다.",
	"const_AREA_CIRCLE_1": "원형 영역 직경 3칸(십자형).",
	"const_AREA_CIRCLE_2": "지름이 5제곱인 원형 영역.",
	"const_AREA_CIRCLE_3": "원형 영역은 직경이 7제곱입니다.",
	"const_AREA_LASER_LINE": "레이저 영역, 레이저의 최소 범위에서 최대 범위 또는 장애물까지의 선.",
	"const_AREA_POINT": "단일 상자로 구성된 영역.",
	"const_CELL_EMPTY": "빈 셀에 대한 getCellContent(cell)의 반환 값.",
	"const_CELL_OBSTACLE": "장애물을 포함하는 셀에 대한 getCellContent(cell)의 반환 값.",
	"const_CELL_PLAYER": "엔터티를 포함하는 셀에 대한 getCellContent(cell)의 반환 값입니다.",
	"const_CELL_ENTITY": "엔터티를 포함하는 셀에 대한 getCellContent(cell)의 반환 값입니다.",
	"const_COLOR_BLUE": "파란색.",
	"const_COLOR_GREEN": "채색.",
	"const_COLOR_RED": "빨간색.",
	"const_E": "오일러의 수.",
	"const_EFFECT_ABSOLUTE_SHIELD": "엔터티에 절대 보호막을 부여하여 손상으로 제거되는 체력(EFFECT_DAMAGE)을 고정된 양만큼 줄입니다. 저항으로 증폭됩니다.",
	"const_EFFECT_ABSOLUTE_VULNERABILITY": "엔터티에서 절대 보호막을 제거합니다. 특성으로 증폭되지 않습니다. 손상(EFFECT_DAMAGE)으로 제거되는 체력을 절대량만큼 증가시킬 수 있습니다.",
	"const_EFFECT_AFTEREFFECT": "엔터티에서 히트 포인트를 제거합니다. 과학으로 증폭. 제거된 체력의 5%만큼 최대 체력이 감소합니다.",
	"const_EFFECT_ANTIDOTE": "대상에 존재하는 모든 독(EFFECT_POISON)을 제거합니다.",
	"const_EFFECT_BOOST_MAX_LIFE": "엔터티의 체력과 최대 체력을 증가시킵니다. 지혜로 증폭됩니다.",
	"const_EFFECT_BUFF_AGILITY": "엔터티에 민첩성을 부여합니다. 과학으로 증폭.",
	"const_EFFECT_BUFF_MP": "엔터티에 이동 포인트를 부여합니다. 과학으로 증폭.",
	"const_EFFECT_BUFF_RESISTANCE": "엔티티에 대한 저항력을 부여합니다. 과학으로 증폭.",
	"const_EFFECT_BUFF_STRENGTH": "개체에 힘을 부여합니다. 과학으로 증폭.",
	"const_EFFECT_BUFF_TP": "엔터티에 작업 포인트를 부여합니다. 과학으로 증폭.",
	"const_EFFECT_BUFF_WISDOM": "개체에 지혜를 부여합니다. 과학으로 증폭.",
	"const_EFFECT_DAMAGE": "엔터티에서 히트 포인트를 제거합니다. 포스 증폭. 실드(EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY), 라이프스틸(캐스터 제외) 및 데미지 리턴(EFFECT_DAMAGE_RETURN)과 상호 작용합니다. 제거된 체력의 5%만큼 최대 체력이 감소합니다.",
	"const_EFFECT_DAMAGE_RETURN": "개체에 손상 반사를 제공하여 수혜자에게 피해를 입히는 개체에서 체력을 제거할 수 있습니다. 민첩성으로 증폭됩니다. 제거된 체력의 5%만큼 최대 체력이 감소합니다.",
	"const_EFFECT_DEBUFF": "개체에 대한 모든 효과의 값을 백분율로 줄입니다.",
	"const_EFFECT_HEAL": "최대 체력에 의해 제한되는 엔티티에 대한 체력을 복원합니다. 지혜로 증폭됩니다.",
	"const_EFFECT_INVERT": "시전자의 위치를 엔터티의 위치로 바꿉니다.",
	"const_EFFECT_KILL": "엔티티에서 모든 히트 포인트를 제거합니다.",
	"const_EFFECT_LIFE_DAMAGE": "시전자의 체력 비율에 따라 엔티티에서 체력을 제거합니다. 보호막(EFFECT_ABSOLUTE_SHIELD, EFFECT_RELATIVE_SHIELD, EFFECT_VULNERABILITY, EFFECT_ABSOLUTE_VULNERABILITY) 및 피해 반환(EFFECT_DAMAGE_RETURN)과 상호 작용합니다. 제거된 체력의 5%만큼 최대 체력이 감소합니다.",
	"const_EFFECT_MODIFIER_MULTIPLIED_BY_TARGETS": "효과는 해당 지역의 영향을 받는 개체의 수로 곱해집니다.",
	"const_EFFECT_MODIFIER_ON_CASTER": "효과는 여전히 시전자에게 영향을 미칩니다.",
	"const_EFFECT_MODIFIER_STACKABLE": "효과는 누적됩니다.",
	"const_EFFECT_NOVA_DAMAGE": "최대 라이프 포인트를 제거합니다. 과학으로 증폭.",
	"const_EFFECT_POISON": "엔터티에서 히트 포인트를 제거합니다. 마법으로 증폭. 제거된 체력의 10%만큼 최대 체력이 감소합니다.",
	"const_EFFECT_RAW_ABSOLUTE_SHIELD": "엔터티에 절대 보호막을 부여하여 손상으로 제거되는 체력(EFFECT_DAMAGE)을 고정된 양만큼 줄입니다. 증폭 불가.",
	"const_EFFECT_RAW_BUFF_AGILITY": "엔터티에 민첩성을 부여합니다. 증폭 불가능.",
	"const_EFFECT_RAW_BUFF_MAGIC": "엔티티에 마법을 부여합니다. 증폭 불가능.",
	"const_EFFECT_RAW_BUFF_MP": "엔터티에 이동 포인트를 부여합니다. 증폭 불가능.",
	"const_EFFECT_RAW_BUFF_SCIENCE": "엔터티에 과학을 부여합니다. 증폭 불가능.",
	"const_EFFECT_RAW_BUFF_STRENGTH": "개체에 힘을 부여합니다. 증폭 불가능.",
	"const_EFFECT_RAW_BUFF_TP": "엔터티에 작업 포인트를 부여합니다. 증폭 불가능.",
	"const_EFFECT_RELATIVE_SHIELD": "상대 보호막을 제공하여 손상(EFFECT_DAMAGE)으로 제거되는 체력을 상대적으로 줄입니다. 저항으로 증폭됩니다.",
	"const_EFFECT_RESURRECT": "부활 전 최대 HP 수의 절반에 해당하는 최대 HP 수와 부활 전 최대 HP 수의 4분의 1에 해당하는 현재 HP 수로 개체를 부활시킵니다.",
	"const_EFFECT_SHACKLE_MAGIC": "엔티티에서 마법을 제거합니다. 마법으로 증폭.",
	"const_EFFECT_SHACKLE_MP": "엔터티에서 이동 지점을 제거합니다. 마법으로 증폭.",
	"const_EFFECT_SHACKLE_STRENGTH": "엔터티에서 힘을 제거합니다. 마법으로 증폭.",
	"const_EFFECT_SHACKLE_TP": "엔터티에서 작업 포인트를 차감합니다. 마법으로 증폭.",
	"const_EFFECT_SUMMON": "전구를 소환합니다. 팀 소환 제한에 도달하면 효과가 없습니다.",
	"const_EFFECT_TARGET_ALLIES": "아군에게 영향을 미칩니다.",
	"const_EFFECT_TARGET_CASTER": "시전자에게 영향을 미칩니다.",
	"const_EFFECT_TARGET_ENEMIES": "적에게 영향을 미칩니다.",
	"const_EFFECT_TARGET_NON_SUMMONS": "소환되지 않은 개체(부추 및 포탑)에 영향을 미칩니다.",
	"const_EFFECT_TARGET_SUMMONS": "소환된 개체(전구)에 영향을 미칩니다.",
	"const_EFFECT_TELEPORT": "캐스터의 위치를 변경합니다.",
	"const_EFFECT_VULNERABILITY": "엔티티와 관련된 실드를 제거합니다. 특성으로 증폭되지 않습니다. 손상(EFFECT_DAMAGE)으로 인해 제거되는 체력이 상대적으로 증가합니다.",
	"const_ENTITY_BULB": "벌브 유형의 엔티티를 나타냅니다.",
	"const_ENTITY_LEEK": "부추 유형 개체를 나타냅니다.",
	"const_ENTITY_TURRET": "Turret 유형 엔티티를 나타냅니다.",
	"const_FIGHT_CONTEXT_CHALLENGE": "도전형 전투 배경.",
	"const_FIGHT_CONTEXT_GARDEN": "채소밭에서의 싸움의 맥락.",
	"const_FIGHT_CONTEXT_BATTLE_ROYALE": "배틀로얄의 전투 상황.",
	"const_FIGHT_CONTEXT_TEST": "전투 상황을 테스트합니다.",
	"const_FIGHT_CONTEXT_TOURNAMENT": "토너먼트 전투 배경.",
	"const_FIGHT_TYPE_BATTLE_ROYALE": "배틀 로얄에서 싸우십시오.",
	"const_FIGHT_TYPE_FARMER": "사육자 싸움.",
	"const_FIGHT_TYPE_SOLO": "솔로 싸움.",
	"const_FIGHT_TYPE_TEAM": "팀 싸움.\n",
	"const_INSTRUCTIONS_LIMIT": "엔터티가 자신의 차례 동안 사용할 수 있는 최대 명령 수입니다.",
	"const_MAX_TURNS": "전투의 최대 턴 수입니다.",
	"const_OPERATIONS_LIMIT": "엔터티가 자신의 차례 동안 사용할 수 있는 최대 작업 수입니다.",
	"const_PI": "원의 둘레와 지름의 비율.",
	"const_SORT_ASC": "오름차순으로 정렬하도록 #sort 함수에 지시합니다.",
	"const_SORT_DESC": "내림차순으로 정렬하도록 #sort 함수에 지시합니다.",
	"const_TYPE_ARRAY": "값 유형 <b>목록</b>",
	"const_TYPE_BOOLEAN": "<b>부울</b> 값 유형",
	"const_TYPE_FUNCTION": "값 유형 <b>함수</b>",
	"const_TYPE_NULL": "<b>null</b> 값 유형",
	"const_TYPE_NUMBER": "값 유형 <b>숫자</b>",
	"const_TYPE_STRING": "<b>문자열</b> 값 유형",
	"const_USE_CRITICAL": "치명타 발생 시 #useWeapon, #useWeaponOnCell, #useChip 및 #useChipOnCell 함수에서 반환되는 값입니다.",
	"const_USE_FAILED": "실패 시 #useWeapon, #useWeaponOnCell, #useChip 및 #useChipOnCell 함수에서 반환되는 값입니다.",
	"const_USE_INVALID_COOLDOWN": "칩을 아직 사용할 수 없는 경우 #useChip 및 #useChipOnCell 함수에서 반환되는 값입니다.",
	"const_USE_INVALID_POSITION": "범위가 나쁘거나 시야가 명확하지 않은 경우 #useWeapon, #useWeaponOnCell, #useChip 및 #useChipOnCell 함수에서 반환되는 값입니다.",
	"const_USE_INVALID_TARGET": "대상이 존재하지 않는 경우 #useWeapon 및 #useChip 함수에서 반환되는 값입니다.",
	"const_USE_NOT_ENOUGH_TP": "캐스터가 오브젝트를 사용하기에 충분한 액션 포인트를 가지고 있지 않은 경우 #useWeapon, #useWeaponOnCell, #useChip 및 #useChipOnCell 함수에 의해 반환되는 값입니다.",
	"const_USE_RESURRECT_INVALID_ENTITY": "지정된 엔터티가 존재하지 않거나 아직 죽지 않은 경우 #resurrect 함수에서 반환하는 값입니다.",
	"const_USE_SUCCESS": "성공 시 #useWeapon, #useWeaponOnCell, #useChip 및 #useChipOnCell 함수에서 반환되는 값입니다.",
	"const_USE_TOO_MANY_SUMMONS": "이미 <b>8</b>개의 라이브 소환이 있는 경우 #summon에서 반환된 오류입니다.\n",
	"func_abs": "숫자 <b>숫자</b>의 절대값을 반환합니다.",
	"func_abs_arg_1": "절대값을 계산할 숫자입니다.",
	"func_abs_return": "숫자의 절대값입니다.",
	"func_acos": "[0, #PI] 간격으로 <b>인수</b>의 아크코사인을 계산합니다.",
	"func_acos_arg_1": "아크코사인이 계산될 숫자입니다.",
	"func_acos_return": "<b>인수</b>의 아크코사인입니다.",
	"func_arrayConcat": "두 어레이를 끝에서 끝까지 추가합니다. 텍스트 키는 보존되고 모든 숫자 키는 다시 인덱싱됩니다.",
	"func_arrayConcat_arg_1": "첫 번째 테이블.",
	"func_arrayConcat_arg_2": "두 번째 테이블.",
	"func_arrayConcat_return": "두 배열의 합입니다.",
	"func_arrayFilter": "콜백 함수가 true를 반환한 소스 배열의 모든 키/값 쌍을 포함하는 새 배열을 반환합니다. 콜백 함수가 하나의 매개변수를 사용하는 경우 소스 배열의 값이 전송되고 두 개의 매개변수를 사용하는 경우 키와 값이 전송됩니다.",
	"func_arrayFilter_arg_1": "원래 테이블.",
	"func_arrayFilter_arg_2": "각 요소에 대해 호출되는 함수입니다.",
	"func_arrayFilter_return": "새 테이블.",
	"func_arrayFlatten": "소스 배열의 모든 요소를 포함하는 새 배열을 반환합니다. 하위 배열에 포함된 모든 요소는 새 배열로 추출되며, depth 인수는 추출할 최대 하위 배열 깊이를 결정합니다.\n<코드>var 배열 = [1, 2, [[3, 4], 5], [6]];\ndebug(arrayFlatten(배열, 1)); // [1,2,[3,4],5,6]</코드>",
	"func_arrayFlatten_arg_1": "원래 테이블.",
	"func_arrayFlatten_arg_2": "최대 깊이.",
	"func_arrayFlatten_return": "새 테이블.",
	"func_arrayFoldLeft": "<b>v0</b> 값에서 시작하여 함수 <b>f</b>를 적용하여 왼쪽에서 배열 <b>배열</b>[v1, v2, ..., vn]을 줄입니다. 같음 :\n<code>f(f(f(v0, v1), v2), ...)</code>",
	"func_arrayFoldLeft_arg_1": "원래 테이블.",
	"func_arrayFoldLeft_arg_2": "적용할 함수입니다.",
	"func_arrayFoldLeft_arg_3": "시작 값.",
	"func_arrayFoldLeft_return": "줄어든 테이블.",
	"func_arrayFoldRight": "<b>v0</b> 값에서 시작하여 함수 <b>f</b>를 적용하여 오른쪽에서 배열 <b>배열</b> [v1, v2, ..., vn]을 줄입니다. 같음 :\n<code>f(v1, f(v2, ..., f(vn, v0)))</code>",
	"func_arrayFoldRight_arg_1": "원래 테이블.",
	"func_arrayFoldRight_arg_2": "적용할 함수입니다.",
	"func_arrayFoldRight_arg_3": "시작 값.",
	"func_arrayFoldRight_return": "줄어든 테이블.",
	"func_arrayIter": "각 배열 요소에 대한 콜백 함수를 호출합니다. 콜백 함수가 하나의 매개변수를 사용하는 경우 소스 배열의 값이 전송되고 두 개의 매개변수를 사용하는 경우 키와 값이 전송됩니다.",
	"func_arrayIter_arg_1": "원래 테이블.",
	"func_arrayIter_arg_2": "각 요소에 대해 호출되는 함수입니다.",
	"func_arrayMap": "소스 배열의 각 키에 대해 콜백 함수가 반환한 값을 포함하는 새 배열을 반환합니다. 콜백 함수가 하나의 매개변수를 사용하는 경우 소스 배열의 값이 전송되고 두 개의 매개변수를 사용하는 경우 키와 값이 전송됩니다.",
	"func_arrayMap_arg_1": "원래 테이블.",
	"func_arrayMap_arg_2": "각 요소에 대해 호출되는 함수입니다.",
	"func_arrayMap_return": "새 테이블.",
	"func_arrayMax": "<b>배열</b> 배열의 최대값 요소를 반환합니다.<br/>배열의 최대값에 대한 자세한 내용은 #sort를 참조하세요.",
	"func_arrayMax_arg_1": "최대값을 찾을 배열입니다.",
	"func_arrayMax_return": "최대 가치 항목.",
	"func_arrayMin": "<b>배열</b> 배열의 최소값 요소를 반환합니다.<br/>배열의 최소값에 대한 자세한 내용은 #sort를 참조하세요.",
	"func_arrayMin_arg_1": "최소값을 찾을 배열입니다.",
	"func_arrayMin_return": "최소값 항목.",
	"func_arrayPartition": "두 목록을 포함하는 새 배열을 반환합니다. 첫 번째 목록은 콜백 함수가 true를 반환한 모든 키/값 쌍이고 두 번째 목록은 나머지 목록입니다. 콜백 함수가 하나의 매개변수를 사용하는 경우 소스 배열의 값이 전송되고 두 개의 매개변수를 사용하는 경우 키와 값이 전송됩니다.",
	"func_arrayPartition_arg_1": "원래 테이블.",
	"func_arrayPartition_arg_2": "각 요소에 대해 호출되는 함수입니다.",
	"func_arrayPartition_return": "새 테이블.",
	"func_arraySort": "콜백 함수에 의해 정의된 순서에 따라 배열을 정렬합니다. 요소는 2개씩 비교되며 콜백 함수는 첫 번째 값이 두 번째 값 이전인지, 동일한 수준인지 또는 이후인지에 따라 값을 -1, 0 또는 1로 반환해야 합니다. 콜백 함수가 2개의 매개변수를 취하면 2개의 값을 보내고, 4를 취하면 키/값 쌍을 보낸다.",
	"func_arraySort_arg_1": "원래 테이블",
	"func_arraySort_arg_2": "정렬 기능.",
	"func_arraySort_return": "정렬된 배열",
	"func_asin": "간격 [0, #PI]에서 <b>인수</b>의 아크사인을 계산합니다.",
	"func_asin_arg_1": "아크사인이 계산될 숫자입니다.",
	"func_asin_return": "<b>인수</b>의 아크사인입니다.",
	"func_assocSort": "순서 <b>순서</b>에 따라 키:값 연결을 유지하는 배열 <b>배열</b>을 정렬합니다.",
	"func_assocSort_arg_1": "정렬할 배열입니다.",
	"func_assocSort_arg_2": "정렬 순서: #SORT_ASC 또는 #SORT_DESC.",
	"func_atan": "간격 [0, #PI]에서 <b>인수</b>의 아크탄젠트를 계산합니다.",
	"func_atan2": "데카르트 좌표(<b>x</b>, <b>y</b>)를 극좌표(<b>r</b>, <b>theta</b>)로 변환합니다. 이 함수는 인수의 부호를 사용하여 -#PI와 #PI 사이의 <b>세타</b> 각도를 반환합니다.",
	"func_atan2_arg_1": "Y 좌표.",
	"func_atan2_arg_2": "X 좌표.",
	"func_atan2_return": "점(x, y)의 극좌표에서 <b>세타</b> 각도입니다.",
	"func_atan_arg_1": "아크탄젠트가 계산될 숫자입니다.",
	"func_atan_return": "<b>인수</b>의 아크탄젠트입니다.",
	"func_average": "배열 <b>배열</b>에 포함된 요소의 평균을 계산합니다.",
	"func_average_arg_1": "평균을 계산하려는 배열입니다.",
	"func_average_return": "평균값.",
	"func_canUseChip": "엔티티가 ID가 <b>엔티티</b>인 엔티티에서 <b>칩</b> 칩을 사용할 수 있는지 여부를 결정합니다.",
	"func_canUseChip_arg_1": "테스트할 칩의 번호입니다.",
	"func_canUseChip_arg_2": "칩을 사용하려는 엔티티 ID입니다.",
	"func_canUseChip_return": "엔티티가 칩을 사용할 수 있으면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_canUseChipOnCell": "엔티티가 <b>셀</b> 셀에서 <b>칩</b> 칩을 사용할 수 있는지 여부를 결정합니다.",
	"func_canUseChipOnCell_arg_1": "테스트할 칩의 번호입니다.",
	"func_canUseChipOnCell_arg_2": "칩을 사용하려는 셀의 번호입니다.",
	"func_canUseChipOnCell_return": "엔티티가 칩을 사용할 수 있으면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_canUseWeapon": "엔티티가 무기 <b>무기</b>로 ID가 <b>엔티티</b>인 엔티티를 쏠 수 있는지 결정합니다.",
	"func_canUseWeapon_arg_1": "테스트할 무기입니다. 현재 장착한 무기가 기본값입니다.",
	"func_canUseWeapon_arg_2": "실행하려는 엔티티의 ID입니다.",
	"func_canUseWeapon_return": "엔티티가 실행될 수 있으면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_canUseWeaponOnCell": "엔티티가 <b>무기</b> 무기로 <b>셀</b> 셀을 쏠 수 있는지 결정합니다.",
	"func_canUseWeaponOnCell_arg_1": "테스트할 무기입니다. 현재 장착한 무기가 기본값입니다.",
	"func_canUseWeaponOnCell_arg_2": "촬영하려는 셀의 번호입니다.",
	"func_canUseWeaponOnCell_return": "엔티티가 실행될 수 있으면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_cbrt": "<b>숫자</b>의 세제곱근 계산",
	"func_cbrt_arg_1": "세제곱근을 계산하려는 숫자입니다.",
	"func_cbrt_return": "<b>숫자</b>의 세제곱근.",
	"func_ceil": "<b>숫자</b>의 반올림을 반환합니다.<br/>내림은 #floor로, 반올림은 #round로 찾을 수 있습니다.",
	"func_ceil_arg_1": "상위 반올림을 찾으려는 숫자입니다.",
	"func_ceil_return": "<b>숫자</b>를 반올림합니다.",
	"func_charAt": "위치 <b>위치</b>에 있는 문자열 <b>문자열</b>의 문자를 반환합니다.<br/>문자열의 첫 번째 문자는 위치 0에 있습니다.",
	"func_charAt_arg_1": "문자를 검색하려는 문자열입니다.",
	"func_charAt_arg_2": "찾을 문자의 위치입니다.",
	"func_charAt_return": "찾는 문자를 포함하는 문자열 또는 위치가 유효하지 않은 경우 빈 문자열.",
	"func_chipNeedLos": "<b>칩</b>을 사용하려면 시선이 필요한지 여부를 반환합니다.",
	"func_chipNeedLos_arg_1": "테스트할 칩의 ID입니다.",
	"func_chipNeedLos_return": "<b>칩</b>에 시야를 사용해야 하는 경우 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_clearMarks": "필드에서 mark() 및 markText()로 만든 모든 표시를 지웁니다.",
	"func_clone": "<b>수준</b> 수준에서 매개변수로 전달된 <b>값</b>을 복사하고 복사본을 반환합니다. 예를 들어 배열의 경우 clone(array, 1)은 배열을 복사하지만 요소는 복사하지 않고 clone(array, 2)는 배열과 모든 요소를 복사합니다.",
	"func_clone_arg_1": "복제할 값",
	"func_clone_arg_2": "복제할 레벨 수",
	"func_clone_return": "복제된 값",
	"func_contains": "<b>검색</b> 문자열이 <b>문자열</b> 문자열 내에 있는지 확인합니다.",
	"func_contains_arg_1": "검색이 수행될 문자열입니다.",
	"func_contains_arg_2": "검색할 문자열입니다.",
	"func_contains_return": "<b>검색</b>이 <b>문자열</b>에 있으면 true이고 그렇지 않으면 false입니다.",
	"func_cos": "<b>각도</b>의 코사인을 계산합니다.",
	"func_cos_arg_1": "코사인을 계산할 숫자(라디안 단위).",
	"func_cos_return": "[-1, 1] 범위의 <b>각도</b>의 코사인입니다.",
	"func_count": "<b>배열</b> 배열의 요소 수를 계산합니다.",
	"func_count_arg_1": "요소 수가 계산될 배열입니다.",
	"func_count_return": "배열 요소의 수.",
	"func_debug": "개인 로그에 <b>개체</b> 메시지를 기록하고 전투 종료 시 보고서에서 확인할 수 있습니다.",
	"func_debug_arg_1": "녹음할 메시지입니다.",
	"func_debugC": "개인 로그에 <b>색상</b> 색상의 <b>개체</b> 메시지를 기록합니다. 전투 종료 시 보고서에서 확인할 수 있습니다.",
	"func_debugC_arg_1": "녹음할 메시지입니다.",
	"func_debugC_arg_2": "메시지의 색상입니다. #getColor 기능을 사용할 수 있습니다.",
	"func_debugE": "개인 로그에 <b>개체</b> 오류 메시지를 기록하고 전투 종료 시 보고서에서 확인할 수 있습니다.<br />오류 메시지는 전투 보고서에 빨간색으로 표시됩니다.",
	"func_debugE_arg_1": "기록할 오류 메시지입니다.",
	"func_debugW": "개인 로그에 <b>개체</b> 경고 메시지를 기록하고 전투 종료 시 보고서에서 확인할 수 있습니다. <br />전투 보고서에 경고 메시지가 주황색으로 표시됩니다.",
	"func_debugW_arg_1": "기록할 경고 메시지입니다.",
	"func_deleteRegister": "키 <b>key</b>가 있는 경우 해당 레지스트리를 삭제합니다.",
	"func_deleteRegister_arg_1": "삭제할 레지스트리 키입니다.",
	"func_endsWith": "<b>문자열</b>이 <b>접미사</b> 문자열로 끝나는지 여부를 결정합니다.",
	"func_endsWith_arg_1": "검색이 수행될 문자열",
	"func_endsWith_arg_2": "검색 접미사",
	"func_endsWith_return": "<b>문자열</b>이 <b>접미사</b>로 끝나는 경우 참",
	"func_exp": "오일러의 수 #E를 <b>수</b>의 거듭제곱으로 올립니다.",
	"func_exp_arg_1": "#E를 올릴 지수.",
	"func_exp_return": "#E** <b>숫자</b>.",
	"func_fill": "배열 <b>배열</b>의 크기를 <b>크기</b>로 조정하고 모든 상자를 <b>값</b>으로 채웁니다.",
	"func_fill_arg_1": "완료할 테이블입니다.",
	"func_fill_arg_2": "배열의 각 사각형에 대한 값입니다.",
	"func_fill_arg_3": "상자의 수입니다. 기본적으로 현재 배열 크기입니다.",
	"func_floor": "<b>숫자</b>의 내림을 계산합니다.<br/>올림은 #ceil로, 반올림은 #round로 찾을 수 있습니다.",
	"func_floor_arg_1": "반올림하여 찾고자 하는 숫자입니다.",
	"func_floor_return": "<b>숫자</b>로 내림합니다.",
	"func_getAbsoluteShield": "ID가 <b>엔티티</b>인 엔티티의 절대 실드를 반환합니다. 엔터티의 절대 실드를 직접 검색하려면 매개변수 없이 #getAbsoluteShield()를 사용하십시오.",
	"func_getAbsoluteShield_arg_1": "절대 쉴드를 반환할 엔터티의 ID입니다.",
	"func_getAbsoluteShield_return": "<b>개체</b> 개체의 절대 방패입니다.",
	"func_getAgility": "ID가 <b>엔티티</b>인 엔티티의 민첩성을 반환합니다. 엔티티의 민첩성을 직접 검색하려면 매개변수 없이 #getAgility()를 사용하십시오.",
	"func_getAgility_arg_1": "민첩성을 반환할 엔터티의 ID입니다.",
	"func_getAgility_return": "<b>entity</b> 엔터티의 민첩성.",
	"func_getAIID": "엔티티 <b>entity</b>의 AI id를 반환합니다. 매개변수 없이 getAIID를 사용하여 AI의 ID를 검색하십시오.",
	"func_getAIID_arg_1": "AI ID가 반환될 개체의 ID입니다.",
	"func_getAIID_return": "<b>엔티티</b> 엔터티 AI ID입니다.",
	"func_getAIName": "엔터티 AI <b>entity</b>의 이름을 반환합니다. 매개변수 없이 getAINam()을 사용하여 AI의 이름을 검색하십시오.",
	"func_getAIName_arg_1": "AI 이름을 반환할 개체의 ID입니다.",
	"func_getAIName_return": "<b>엔티티</b> 엔터티 AI의 이름입니다.",
	"func_getAliveAllies": "전투에서 살아 있는 모든 아군의 배열을 반환합니다.",
	"func_getAliveAllies_return": "모든 살아있는 동맹의 ID를 포함하는 배열.",
	"func_getAliveEnemies": "전투에서 살아있는 모든 적의 배열을 반환합니다.",
	"func_getAliveEnemiesCount": "전투에서 살아 있는 적의 수를 반환합니다.",
	"func_getAliveEnemiesCount_return": "살아있는 적의 수.",
	"func_getAliveEnemies_return": "살아있는 모든 적의 ID를 포함하는 배열입니다.",
	"func_getAllChips": "게임의 모든 칩 목록을 반환합니다.",
	"func_getAllChips_return": "게임의 모든 칩 목록입니다.",
	"func_getAllEffects": "게임의 모든 효과 목록을 반환합니다.",
	"func_getAllEffects_return": "게임의 모든 효과 목록입니다.",
	"func_getAllWeapons": "게임의 모든 무기 목록을 반환합니다.",
	"func_getAllWeapons_return": "게임의 모든 무기 목록입니다.",
	"func_getAlliedTurret": "팀의 터렛 ID를 반환하거나 존재하지 않는 경우 -1을 반환합니다.",
	"func_getAlliedTurret_return": "팀의 터렛 ID입니다.",
	"func_getEnemyTurret": "적 포탑 ID를 반환하거나 존재하지 않으면 -1을 반환합니다.",
	"func_getEnemyTurret_return": "적 포탑 ID입니다.",
	"func_getAllies": "동맹과 엔티티를 포함하는 배열을 반환합니다.",
	"func_getAlliesCount": "전투에서 아군 수를 반환합니다.",
	"func_getAlliesCount_return": "동맹의 수.",
	"func_getAlliesLife": "동맹국의 전체 건강을 반환합니다.",
	"func_getAlliesLife_return": "아군의 전체 건강.",
	"func_getAllies_return": "동맹국과 귀하의 엔티티 테이블.",
	"func_getBirthTurn": "<b>엔티티</b>가 나타난 전투의 차례를 반환합니다. 예를 들어 부추라면 1을 반환하고, 5턴에 발동된 소환이라면 5를 반환합니다.",
	"func_getBirthTurn_arg_1": "스폰 턴이 반환될 개체의 ID입니다.",
	"func_getBirthTurn_return": "<b>엔티티</b>가 나타난 전투 턴.",
	"func_getBlue": "<b>color</b> 색상의 파란색 양을 0에서 255 사이로 반환합니다. 예를 들어 getBlue(#COLOR_BLUE) = 255 및 getBlue(#COLOR_GREEN) = 0입니다.",
	"func_getBlue_arg_1": "파란색 비율을 반환할 색상입니다.",
	"func_getBlue_return": "<b>color</b> 색상의 파란색 비율",
	"func_getBulbChips": "<b>bulb_chip</b> 칩이 소환한 전구에 장착할 칩 목록을 반환합니다.",
	"func_getBulbChips_arg_1": "전구 벼룩.",
	"func_getBulbChips_return": "<b>bulb_chip</b> 칩에 의해 소환되는 전구 칩.",
	"func_getCell": "ID가 <b>entity</b>인 엔티티가 있는 셀을 반환합니다. 매개변수 없이 getCell()을 사용하여 셀을 검색하십시오.",
	"func_getCell_arg_1": "셀이 반환될 엔터티의 ID입니다.",
	"func_getCell_return": "<b>엔티티</b>가 위치한 셀 번호입니다.",
	"func_getCellContent": "ID가 <b>cell</b>인 셀의 내용을 반환합니다.",
	"func_getCellContent_arg_1": "내용이 반환될 셀의 ID입니다.",
	"func_getCellContent_return": "<b>cell</b> 셀의 내용: 빈 셀의 경우 #CELL_EMPTY, 엔티티의 경우 #CELL_ENTITY, 장애물의 경우 #CELL_OBSTACLE.",
	"func_getCellDistance": "두 셀 <b>cell1</b>과 <b>cell2</b> 사이의 거리를 반환합니다. 반환된 거리는 셀 수로 표현되며 두 셀 사이의 다양한 장애물을 고려하지 않습니다.\n까마귀가 날아갈 때 거리를 얻으려면 #getDistance를 참조하고 장애물을 피하는 두 셀 사이의 경로 거리를 얻으려면 #getPathLength를 참조하십시오.",
	"func_getCellDistance_arg_1": "시작 셀의 ID입니다.",
	"func_getCellDistance_arg_2": "도착 셀의 ID입니다.",
	"func_getCellDistance_return": "두 셀 <b>cell1</b>과 <b>cell2</b> 사이의 거리입니다.",
	"func_getCellFromXY": "위치(<b>x</b>, <b>y</b>)에 있는 셀의 ID를 반환합니다.",
	"func_getCellFromXY_arg_1": "셀의 x 위치입니다.",
	"func_getCellFromXY_arg_2": "셀의 y 위치입니다.",
	"func_getCellFromXY_return": "위치(<b>x</b>, <b>y</b>)의 셀 ID, 셀이 존재하지 않는 경우 <b>null</b>.",
	"func_getCellsToUseChip": "엔티티가 <b>entity</b> 엔티티에서 <b>chip</b> 칩을 사용할 수 있는 셀 목록을 반환합니다.",
	"func_getCellsToUseChip_arg_1": "엔터티가 사용할 수 있기를 원하는 칩입니다.",
	"func_getCellsToUseChip_arg_2": "대상 엔터티입니다.",
	"func_getCellsToUseChip_arg_3": "무시할 셀의 배열입니다.",
	"func_getCellsToUseChip_return": "칩을 사용할 수 있는 셀 목록입니다.",
	"func_getCellsToUseChipOnCell": "엔티티가 <b>cell</b> 셀에서 <b>chip</b> 칩을 사용할 수 있는 셀 목록을 반환합니다.",
	"func_getCellsToUseChipOnCell_arg_1": "엔터티가 사용할 수 있기를 원하는 칩입니다.",
	"func_getCellsToUseChipOnCell_arg_2": "표적 세포.",
	"func_getCellsToUseChipOnCell_arg_3": "무시할 셀의 배열입니다.",
	"func_getCellsToUseChipOnCell_return": "칩을 사용할 수 있는 셀 목록입니다.",
	"func_getCellsToUseWeapon": "엔티티가 엔티티 <b>엔티티</b>에서 무기 <b>무기</b>를 사용할 수 있는 셀 목록을 반환합니다.",
	"func_getCellsToUseWeapon_arg_1": "테스트할 무기입니다. 현재 장착한 무기가 기본값입니다.",
	"func_getCellsToUseWeapon_arg_2": "대상 엔터티입니다.",
	"func_getCellsToUseWeapon_arg_3": "무시할 셀의 배열입니다. 기본적으로 빈 배열입니다.",
	"func_getCellsToUseWeapon_return": "무기를 사용할 수 있는 셀 목록입니다.",
	"func_getCellsToUseWeaponOnCell": "개체가 <b>셀</b> 셀에서 <b>무기</b> 무기를 사용할 수 있는 셀 목록을 반환합니다.",
	"func_getCellsToUseWeaponOnCell_arg_1": "테스트할 무기입니다. 현재 장착한 무기가 기본값입니다.",
	"func_getCellsToUseWeaponOnCell_arg_2": "표적 세포.",
	"func_getCellsToUseWeaponOnCell_arg_3": "무시할 셀의 배열입니다. 기본적으로 빈 배열입니다.",
	"func_getCellsToUseWeaponOnCell_return": "무기를 사용할 수 있는 셀 목록입니다.",
	"func_getCellX": "셀 <b>셀</b>의 X 위치를 결정합니다.",
	"func_getCellX_arg_1": "X에서 위치를 결정할 셀입니다.",
	"func_getCellX_return": "셀의 X 위치입니다.",
	"func_getCellY": "셀 <b>셀</b>의 Y 위치를 결정합니다.",
	"func_getCellY_arg_1": "Y에서 위치를 결정할 셀입니다.",
	"func_getCellY_return": "셀의 Y 위치입니다.",
	"func_getChipArea": "<b>칩</b>의 효과 유형 영역을 반환합니다.",
	"func_getChipArea_arg_1": "반환될 영역 유형의 칩입니다.",
	"func_getChipArea_return": "AREA_* 상수 중 칩 <b>칩</b>의 영역 유형:\n<ul>\n<li>#AREA_POINT: 단일 정사각형 영역</li>\n<li>#AREA_LASER_LINE: 레이저 라인</li>\n<li>#AREA_CIRCLE_1: 직경 3칸의 원형 영역</li>\n<li>#AREA_CIRCLE_2: 직경이 5칸인 원형 영역</li>\n<li>#AREA_CIRCLE_3: 직경이 7인 원형 영역</li>\n</ul>",
	"func_getChipCooldown": "시장에서 가져온 <b>칩</b> 칩 복구 시간을 반환합니다.",
	"func_getChipCooldown_arg_1": "재사용 대기 시간이 반환되는 칩입니다.",
	"func_getChipCooldown_return": "<b>칩</b> 쿨다운.",
	"func_getChipCost": "칩 <b>칩</b>의 비용을 TP로 반환합니다.",
	"func_getChipCost_arg_1": "비용이 반환되는 칩.",
	"func_getChipCost_return": "<b>칩</b>의 비용.",
	"func_getChipEffectiveArea": "<b>from</b> 셀에서 <b>cell</b> 셀에 <b>chip</b> 칩이 사용되는 경우 영향을 받는 셀 목록을 반환합니다.",
	"func_getChipEffectiveArea_arg_1": "테스트할 칩입니다.",
	"func_getChipEffectiveArea_arg_2": "표적 세포.",
	"func_getChipEffectiveArea_arg_3": "칩이 사용되는 셀입니다.",
	"func_getChipEffectiveArea_return": "영향을 받는 모든 셀의 ID를 포함하는 배열입니다.",
	"func_getChipEffects": "<b>칩</b> 칩의 효과를 반환합니다.",
	"func_getChipEffects_arg_1": "효과가 반환되는 칩.",
	"func_getChipEffects_return": "<b>칩</b> 칩의 효과. #getWeaponEffects 함수와 동일한 반환 값입니다.",
	"func_getChipFailure": "칩 <b>칩</b>의 실패 위험 백분율을 반환합니다.",
	"func_getChipFailure_arg_1": "실패율이 반환될 칩.",
	"func_getChipFailure_return": "<b>칩</b> 칩 실패율, <b>0</b>과 <b>100</b> 사이의 정수.",
	"func_getChipMaxRange": "<b>칩</b> 칩의 최대 범위를 반환합니다.",
	"func_getChipMaxRange_arg_1": "최대 범위가 반환되는 칩.",
	"func_getChipMaxRange_return": "<b>칩</b>의 최대 범위.",
	"func_getChipMaxScope": "<b>칩</b> 칩의 최대 범위를 반환합니다.",
	"func_getChipMaxScope_arg_1": "최대 범위가 반환되는 칩.",
	"func_getChipMaxScope_return": "<b>칩</b>의 최대 범위.",
	"func_getChipMinRange": "<b>chip</b> 칩의 최소 범위를 반환합니다.",
	"func_getChipMinRange_arg_1": "최소 범위가 반환되는 칩.",
	"func_getChipMinRange_return": "<b>칩</b>의 최소 범위.",
	"func_getChipMinScope": "<b>chip</b> 칩의 최소 범위를 반환합니다.",
	"func_getChipMinScope_arg_1": "최소 범위가 반환되는 칩.",
	"func_getChipMinScope_return": "<b>칩</b>의 최소 범위.",
	"func_getChipLaunchType": "LAUNCH_TYPE_* 상수 중 <b>chip</b> 칩의 시작 모드를 반환합니다.",
	"func_getChipLaunchType_arg_1": "던지기 모드가 반환될 칩의 ID입니다.",
	"func_getChipLaunchType_return": "<b>칩</b> 칩의 던지기 모드입니다.",
	"func_getChipName": "칩 <b>칩</b>의 이름을 반환합니다.",
	"func_getChipName_arg_1": "이름이 반환될 칩입니다.",
	"func_getChipName_return": "<b>칩</b> 칩의 이름입니다.",
	"func_getChips": "ID가 <b>엔티티</b>인 엔티티의 글머리 기호를 반환합니다.",
	"func_getChips_arg_1": "글머리 기호가 반환될 엔터티의 ID입니다.",
	"func_getChips_return": "엔티티 <b>entity</b>의 글머리 기호 ID를 포함하는 배열입니다.",
	"func_getChipTargets": "<b>cell</b> 셀에서 <b>chip</b> 칩이 사용되는 경우 영향을 받는 엔티티를 반환합니다.",
	"func_getChipTargets_arg_1": "테스트할 칩입니다.",
	"func_getChipTargets_arg_2": "표적 세포.",
	"func_getChipTargets_return": "영향을 받을 모든 엔터티의 ID를 포함하는 배열입니다.",
	"func_getColor": "매개변수로 제공된 색상(<b>빨간색</b>, <b>녹색</b>, <b>파란색</b>)에 해당하는 정수를 반환합니다.",
	"func_getColor_arg_1": "0에서 255 사이의 빨간색 값.",
	"func_getColor_arg_2": "0에서 255 사이의 녹색 값입니다.",
	"func_getColor_arg_3": "파란색 값은 0에서 255 사이입니다.",
	"func_getColor_return": "매개변수로 제공된 색상에 해당하는 int.",
	"func_getCooldown": "<b>엔티티</b> 엔티티의 현재 <b>칩</b> 쿨다운을 반환합니다.",
	"func_getCooldown_arg_1": "현재 재사용 대기 시간이 반환되는 칩입니다.",
	"func_getCooldown_arg_2": "재사용 대기 시간이 반환되는 개체입니다.",
	"func_getCooldown_return": "칩 <b>칩</b>의 현재 쿨다운은 칩을 사용할 수 있게 되기까지의 턴 수이며, 현재 사용할 수 있는 경우 <b>0</b>입니다.",
	"func_getCores": "ID가 <b>엔티티</b>인 엔티티의 코어 수를 반환합니다.",
	"func_getCores_arg_1": "코어 수가 반환될 엔터티입니다.",
	"func_getCores_return": "<b>엔티티</b> 엔티티의 코어 수입니다.",
	"func_getDate": "경기 날짜를 dd/MM/yyyy 형식으로 반환합니다.",
	"func_getDate_return": "싸움의 날짜.",
	"func_getTime": "전투 시간을 HH:mm:ss 형식으로 반환합니다.",
	"func_getTime_return": "싸움의 시간.",
	"func_getTimestamp": "1970년 1월 1일 이후의 초 수와 동일한 싸움의 타임스탬프를 반환합니다.",
	"func_getTimestamp_return": "싸움의 타임 스탬프.",
	"func_getDamageReturn": "ID가 <b>개체</b>인 개체의 피해 반환 비율을 반환합니다.",
	"func_getDamageReturn_arg_1": "손상 추천을 반환할 엔터티의 ID입니다.",
	"func_getDamageReturn_return": "ID가 <b>개체</b>인 개체의 피해 반환 비율(단위: %).",
	"func_getDeadAllies": "죽은 친근한 개체를 반환합니다.",
	"func_getDeadAllies_return": "동맹 죽은 개체의 표.",
	"func_getDeadEnemies": "죽은 적 엔티티를 반환합니다.",
	"func_getDeadEnemiesCount": "전투에서 사망한 적의 수를 반환합니다.",
	"func_getDeadEnemiesCount_return": "죽은 적의 수.",
	"func_getDeadEnemies_return": "죽은 적 개체의 테이블입니다.",
	"func_getDistance": "까마귀가 두 셀 <b>cell1</b>과 <b>cell2</b> 사이를 날아갈 때의 거리를 계산합니다. <br />셀 수로 거리를 얻으려면 #getCellDistance를 참조하고 다양한 장애물을 피하는 두 셀 사이의 경로 길이를 얻으려면 #getPathLength를 참조하십시오.",
	"func_getDistance_arg_1": "시작 셀입니다.",
	"func_getDistance_arg_2": "도착 셀.",
	"func_getDistance_return": "까마귀가 두 세포 사이를 날아가는 거리.",
	"func_getPassiveEffects": "ID가 <b>개체</b>인 개체의 수동 효과 목록을 반환합니다. 엔티티의 패시브 효과 목록을 직접 검색하려면 매개변수 없이 #getPassiveEffects()를 사용하십시오.",
	"func_getPassiveEffects_arg_1": "패시브 효과 목록을 반환할 개체의 ID입니다.",
	"func_getPassiveEffects_return": "<b>개체</b> 개체에 현재 존재하는 수동 효과 목록입니다.\n패시브 효과 목록은 효과를 포함하는 배열입니다.\n효과는 그 자체로 [<b>유형</b>, <b>값</b>, <b>caster_id</b>, <b>턴</b>, <b>중요</b>, <b>item_id</b>, <b>target_id</b>]. #getEffects 함수가 반환하는 클래식 효과와 동일한 구조입니다.",
	"func_getEnemies": "적 개체(살아 있거나 죽은 상태)를 전투로 되돌립니다.",
	"func_getEnemiesCount": "전투에서 적의 수를 반환합니다.",
	"func_getEnemiesCount_return": "적의 수.",
	"func_getEnemiesLife": "모든 적 엔티티의 체력 합계를 계산합니다.",
	"func_getEnemiesLife_return": "적 팀의 체력 합계입니다.",
	"func_getEnemies_return": "모든 적 엔티티의 ID를 포함하는 배열입니다.",
	"func_getEntityTurnOrder": "재생 순서에서 <b>개체</b>의 위치를 나타내는 1에서 n(현재 재생 중인 개체 수) 사이의 값을 반환합니다.",
	"func_getEntityTurnOrder_arg_1": "재생 순서가 반환될 엔터티의 ID",
	"func_getEntityTurnOrder_return": "엔터티 <b>엔티티</b>의 플레이 순서대로 배치",
	"func_getFarmerID": "엔티티 <b>entity</b>의 브리더 ID를 반환합니다.",
	"func_getFarmerID_arg_1": "브리더 ID가 반환될 개체의 ID입니다.",
	"func_getFarmerID_return": "<b>개체</b> 개체 브리더의 ID입니다.",
	"func_getFarmerCountry": "엔티티 <b>엔티티</b>의 사육자의 국가를 반환합니다.",
	"func_getFarmerCountry_arg_1": "브리더 국가가 반환될 엔티티의 ID입니다.",
	"func_getFarmerCountry_return": "<b>개체</b> 개체의 육종가 국가 또는 \"?\" 표시되지 않은 경우.",
	"func_getFarmerName": "<b>엔티티</b> 엔티티의 브리더 이름을 반환합니다.",
	"func_getFarmerName_arg_1": "브리더 이름이 반환될 개체의 ID입니다.",
	"func_getFarmerName_return": "<b>개체</b> 개체의 사육자 이름입니다.",
	"func_getFarthestAlly": "까마귀가 날아가는 것처럼 엔터티의 가장 먼 동맹을 결정합니다.",
	"func_getFarthestAlly_return": "가장 가까운 엔터티의 ID입니다.",
	"func_getFarthestEnemy": "까마귀가 날아갈 때 엔터티에서 가장 먼 적을 결정합니다.",
	"func_getFarthestEnemy_return": "가장 멀리 있는 적의 id.",
	"func_getFightID": "현재 전투 ID를 반환합니다.",
	"func_getFightID_return": "현재 전투 중인 아이디입니다.",
	"func_getFightContext": "현재 전투의 컨텍스트를 반환합니다.",
	"func_getFightContext_return": "전투 상황에 따라: 테스트 전투(#FIGHT_CONTEXT_TEST), 아레나 전투(#FIGHT_CONTEXT_GARDEN), 토너먼트 전투(#FIGHT_CONTEXT_TOURNAMENT), 도전 전투(#FIGHT_CONTEXT_CHALLENGE), 배틀 로얄(#FIGHT_CONTEXT_BATTLE_ROYALE)",
	"func_getFightType": "현재 전투 유형을 반환합니다.",
	"func_getFightType_return": "전투 유형에 따라: 개인전(#FIGHT_TYPE_SOLO), 파머전(#FIGHT_TYPE_FARMER), 팀전(#FIGHT_TYPE_TEAM), 배틀로얄(#FIGHT_TYPE_BATTLE_ROYALE)",
	"func_getForce": "ID가 <b>엔티티</b>인 엔티티의 강도를 반환합니다.",
	"func_getForce_arg_1": "힘을 돌려줄 개체.",
	"func_getForce_return": "<b>엔티티</b> 엔터티의 강도입니다.",
	"func_getFrequency": "ID가 <b>엔티티</b>인 엔티티의 빈도를 반환합니다. 빈도를 검색하려면 매개변수 없이 getFrequency()를 사용하십시오.",
	"func_getFrequency_arg_1": "빈도가 반환될 항목입니다.",
	"func_getFrequency_return": "<b>엔티티</b> 항목의 빈도입니다.",
	"func_getGreen": "<b>color</b> 색상의 녹색 양을 0에서 255 사이로 반환합니다. 예를 들어 getGreen(#COLOR_GREEN) = 255 및 getGreen(#COLOR_RED) = 0입니다.",
	"func_getGreen_arg_1": "녹색 비율이 반환될 색상입니다.",
	"func_getGreen_return": "색상 <b>color</b>의 녹색 비율",
	"func_getInstructionsCount": "엔티티가 현재 턴 동안 수행한 명령 수를 반환합니다.",
	"func_getInstructionsCount_return": "엔티티가 현재 턴 동안 수행한 명령의 수입니다.",
	"func_getLaunchedEffects": "ID가 <b>개체</b>인 개체에 의해 발생한 효과 목록을 반환합니다.",
	"func_getLaunchedEffects_arg_1": "원인 효과 목록이 반환될 개체의 ID입니다.",
	"func_getLaunchedEffects_return": "#getEffects에 의해 반환된 배열과 동일한 형식으로 ID가 <b>entity</b>인 개체로 인해 발생한 효과 목록입니다.",
	"func_getLeek": "엔티티의 ID를 반환합니다.",
	"func_getLeek_return": "엔티티의 ID입니다.",
	"func_getEntity": "엔티티의 ID를 반환합니다.",
	"func_getEntity_return": "엔티티의 ID입니다.",
	"func_getLeekID": "Id <b>leek</b>의 리크의 실제 id를 반환합니다.",
	"func_getLeekID_arg_1": "실제 ID가 반환될 리크의 ID입니다.",
	"func_getLeekID_return": "부추 <b>엔티티</b>의 실제 ID입니다.",
	"func_getLeekOnCell": "<b>cell</b> 셀에 있는 항목을 반환합니다.",
	"func_getLeekOnCell_arg_1": "엔터티를 검색하려는 셀입니다.",
	"func_getLeekOnCell_return": "셀에 있는 엔터티의 ID 또는 셀에 엔터티가 없는 경우 -1입니다.",
	"func_getEntityOnCell": "<b>cell</b> 셀에 있는 항목을 반환합니다.",
	"func_getEntityOnCell_arg_1": "엔터티를 검색하려는 셀입니다.",
	"func_getEntityOnCell_return": "셀에 있는 엔터티의 ID 또는 셀에 엔터티가 없는 경우 -1입니다.",
	"func_getLevel": "ID가 <b>엔티티</b>인 엔티티 수준을 반환합니다.",
	"func_getLevel_arg_1": "레벨을 반환할 엔터티의 ID입니다.",
	"func_getLevel_return": "ID가 <b>엔티티</b>인 엔티티의 수준입니다.",
	"func_getLife": "ID가 <b>엔티티</b>인 엔티티의 현재 수명을 반환합니다. 생명을 되찾기 위해 파라미터 없이 getLife()를 사용하십시오.",
	"func_getLife_arg_1": "생명을 돌려받을 개체의 ID입니다.",
	"func_getLife_return": "<b>엔티티</b> 엔티티의 현재 수명입니다.",
	"func_getMagic": "ID가 <b>엔티티</b>인 엔티티의 매직을 반환합니다. 매개변수 없이 getMagic()을 사용하여 마법을 검색하십시오.",
	"func_getMagic_arg_1": "마법을 돌려줄 개체의 아이디.",
	"func_getMagic_return": "Id 엔티티 <b>entity</b>의 마법.",
	"func_getMapType": "상수 #MAP_NEXUS, #MAP_FACTORY, #MAP_DESERT, #MAP_FOREST, #MAP_GLACIER 및 #MAP_BEACH 중에서 전투가 일어나는 지형의 유형(공장, 사막, 숲 등)을 반환합니다.",
	"func_getMapType_return": "지형의 유형입니다.",
	"func_getMessageAuthor": "메시지 <b>message</b>를 작성하는 엔터티의 ID를 반환합니다.",
	"func_getMessageAuthor_arg_1": "작성자가 반환될 메시지입니다.",
	"func_getMessageAuthor_return": "메시지 <b>message</b>를 작성하는 엔터티의 ID입니다.",
	"func_getMessageParams": "메시지 매개변수 <b>message</b>의 배열을 반환합니다.",
	"func_getMessageParams_arg_1": "매개변수가 반환될 메시지입니다.",
	"func_getMessageParams_return": "<b>메시지</b> 메시지 매개변수.",
	"func_getMessages": "<b>entity</b> 항목 메시지 배열을 반환합니다.",
	"func_getMessages_arg_1": "메시지가 반환될 엔터티입니다.",
	"func_getMessages_return": "메시지 표입니다.<br>메시지 자체는\n형식의 배열: [<b>author</b>, <b>type</b>, <b>parameters</b>]<br>\n다른 유형의 메시지는 상수로 표시됩니다.\n<ul>\n<li>#MESSAGE_HEAL: 치료 요청</li>\n<li>#MESSAGE_ATTACK: 공격 요청</li>\n<li>#MESSAGE_BUFF_FORCE: 강제 부스트 요청</li>\n<리>...</리>\n</ul>",
	"func_getMessageType": "메시지 유형 <b>message</b>를 반환합니다.",
	"func_getMessageType_arg_1": "반환될 메시지 유형(#MESSAGE_HEAL, #MESSAGE_ATTACK 등).",
	"func_getMessageType_return": "메시지 유형 <b>메시지</b>.",
	"func_getMP": "<b>엔티티</b> 엔티티의 현재 이동 포인트 수를 반환합니다. 매개변수 없이 getMP()를 사용하여 PM을 검색하십시오.",
	"func_getMP_arg_1": "PM 수가 반환될 엔터티의 ID입니다.",
	"func_getMP_return": "<b>엔티티</b> 엔터티의 MP 번호입니다.",
	"func_getName": "ID가 <b>entity</b>인 엔티티의 이름을 반환합니다.",
	"func_getName_arg_1": "이름이 반환될 엔터티의 ID입니다.",
	"func_getName_return": "엔티티 이름 <b>entity</b>.",
	"func_getNearestAlly": "엔티티에 가장 가까운 아군 엔티티를 반환합니다.",
	"func_getNearestAlly_return": "가장 가까운 친근한 엔터티의 ID입니다.",
	"func_getNearestAllyTo": "매개변수로 제공된 개체와 가장 가까운 동맹 개체를 반환합니다.",
	"func_getNearestAllyTo_arg_1": "우리가 알고 싶은 가장 가까운 동맹의 엔티티 ID입니다.",
	"func_getNearestAllyToCell": "매개변수로 제공된 셀에 가장 가까운 동맹 엔티티를 반환합니다.",
	"func_getNearestAllyToCell_arg_1": "우리가 알고자 하는 가장 가까운 동맹이 있는 셀의 id입니다.",
	"func_getNearestAllyToCell_return": "가장 가까운 친근한 엔터티의 ID입니다.",
	"func_getNearestAllyTo_return": "가장 가까운 친근한 엔터티의 ID입니다.",
	"func_getNearestEnemy": "엔티티에 가장 가까운 적 엔티티를 반환합니다.",
	"func_getNearestEnemy_return": "가장 가까운 적 엔티티의 ID입니다.",
	"func_getNearestEnemyTo": "매개변수로 제공된 개체에 가장 가까운 적 개체를 반환합니다.",
	"func_getNearestEnemyTo_arg_1": "우리가 알고자 하는 가장 가까운 적을 가진 개체의 ID입니다.",
	"func_getNearestEnemyToCell": "매개변수로 제공된 셀에 가장 가까운 적 개체를 반환합니다.",
	"func_getNearestEnemyToCell_arg_1": "우리가 알고자 하는 가장 가까운 적이 속한 셀의 id입니다.",
	"func_getNearestEnemyToCell_return": "가장 가까운 적 엔티티의 ID입니다.",
	"func_getNearestEnemyTo_return": "가장 가까운 적 엔티티의 ID입니다.",
	"func_getNextPlayer": "현재 플레이어 다음에 재생할 엔티티의 ID를 반환합니다.",
	"func_getNextPlayer_return": "다음 선수.",
	"func_getObstacles": "지형의 장애물 공간 목록을 반환합니다.",
	"func_getObstacles_return": "장애물 셀 ID를 포함하는 배열입니다.",
	"func_getOperations": "차례가 시작된 이후 엔티티가 소비한 작업 수를 반환합니다. 엔티티가 충돌하지 않도록 이 숫자는 #OPERATIONS_LIMIT보다 낮게 유지되어야 합니다.",
	"func_getOperations_return": "차례가 시작된 이후 엔터티에서 사용한 작업 수입니다.",
	"func_getPath": "두 셀 <b>cell1</b>과 <b>cell2</b> 사이의 장애물을 피하는 경로를 반환합니다. 존재하는 경우 <b>ignoredCells</b> 배열에 포함된 셀을 무시합니다. 플레이어가 무시된 셀에 있으면 경로가 플레이어를 통과할 수 있습니다.<br /><br />\n시작 셀 <b>cell1</b>은 결과 경로의 일부가 아닙니다. 셀 <b>cell2</b>는 비어 있거나 <b>ignoredCells</b>에 의해 무시되는 경우에만 결과 경로의 일부입니다.<br /><br />\n두 셀 사이에 경로가 없으면 <b>getPath</b>는 <i>null</i>을 반환합니다.",
	"func_getPath_arg_1": "시작 셀입니다.",
	"func_getPath_arg_2": "도착 셀.",
	"func_getPath_arg_3": "무시할 셀의 배열입니다.",
	"func_getPath_return": "두 셀 사이의 경로를 구성하는 셀을 포함하는 배열입니다.",
	"func_getPathLength": "<b>ignoredCells</b> 배열에 포함된 셀을 무시하고 장애물을 피하면서 두 셀 <b>cell1</b>과 <b>cell2</b> 사이의 경로 길이를 반환합니다. 이 함수는 <i>count(getPath(<b>cell1</b>, <b>cell2</b>, <b>ignoredCells</b>))</i>와 동일합니다.\n플레이어가 무시된 셀에 있으면 경로가 플레이어를 통과할 수 있습니다.<br /><br />\n시작 셀 <b>cell1</b>은 결과에 포함되지 않습니다. <b>cell2</b> 셀은 비어 있거나 <b>ignoredCells</b>에 의해 무시되는 경우에만 결과에서 계산됩니다.<br /><br />\n두 셀 사이에 경로가 없으면 <b>getPathLength</b>는 <i>null</i>을 반환합니다.",
	"func_getPathLength_arg_1": "시작 셀입니다.",
	"func_getPathLength_arg_2": "도착 셀.",
	"func_getPathLength_arg_3": "무시할 셀의 배열입니다.",
	"func_getPathLength_return": "<b>cell1</b>과 <b>cell2</b> 사이의 경로 길이.",
	"func_getPreviousPlayer": "현재 플레이어보다 먼저 플레이한 엔티티의 ID를 반환합니다.",
	"func_getPreviousPlayer_return": "이전 플레이어.",
	"func_getRed": "<b>color</b> 색상의 빨간색 양을 0에서 255 사이로 반환합니다. 예를 들어 getRed(#COLOR_RED) = 255 및 getRed(#COLOR_BLUE) = 0입니다.",
	"func_getRed_arg_1": "빨간색 비율을 반환할 색상입니다.",
	"func_getRed_return": "색상 <b>color</b>의 빨간색 비율",
	"func_getRegister": "레지스트리가 존재하지 않는 경우 <b>key</b> 또는 </i>null</i> 키와 연결된 엔터티 레지스트리에 저장된 값을 반환합니다.",
	"func_getRegister_arg_1": "값이 반환될 레지스트리 키입니다.",
	"func_getRegister_return": "<b>키</b> 레지스트리에 저장된 값입니다.",
	"func_getRegisters": "엔티티 레지스터 집합을 연관 배열 [<i>레지스트리 키</i>:<i>등록 값</i>]으로 반환합니다. 예: <code>debug(getRegisters());\n// 예를 들면 다음과 같이 표시됩니다.\n// ['reg1': '314323', 'reg2': 'test_string']</code>",
	"func_getRegisters_return": "엔터티의 모든 레지스터에 해당하는 연관 배열입니다.",
	"func_getRelativeShield": "ID가 <b>엔티티</b>인 엔티티의 상대적 실드를 반환합니다. 엔티티의 상대 실드를 직접 검색하려면 매개변수 없이 #getRelativeShield()를 사용하십시오.",
	"func_getRelativeShield_arg_1": "상대 방패가 반환될 개체의 ID입니다.",
	"func_getRelativeShield_return": "<b>엔티티</b>의 상대적 실드, <b>0</b>과 <b>100</b> 사이의 정수.",
	"func_getResistance": "ID가 <b>개체</b>인 개체의 저항을 반환합니다. 매개변수 없이 getResistance()를 사용하여 저항을 검색하십시오.",
	"func_getResistance_arg_1": "저항을 반환할 개체의 ID입니다.",
	"func_getResistance_return": "ID가 <b>개체</b>인 개체의 저항.",
	"func_getScience": "ID가 <b>엔티티</b>인 엔티티의 과학을 반환합니다. 매개변수 없이 getScience()를 사용하여 과학을 검색하십시오.",
	"func_getScience_arg_1": "과학이 반환될 개체의 ID입니다.",
	"func_getScience_return": "Id 엔티티 <b>entity</b>의 과학.",
	"func_getStrength": "ID가 <b>엔티티</b>인 엔티티의 강도를 반환합니다. 강도를 검색하려면 매개변수 없이 getStrength()를 사용하십시오.",
	"func_getStrength_arg_1": "힘을 돌려줄 개체.",
	"func_getStrength_return": "<b>엔티티</b> 엔터티의 강도입니다.",
	"func_getPower": "ID가 <b>엔티티</b>인 엔티티의 힘을 반환합니다.",
	"func_getPower_arg_1": "힘을 되찾을 개체.",
	"func_getPower_return": "<b>엔티티</b>의 힘.",
	"func_getSummoner": "호출인 경우 엔터티 <b>엔티티</b>를 호출한 엔터티를 반환합니다.",
	"func_getSummoner_arg_1": "소환사를 해고할 엔티티의 ID입니다.",
	"func_getSummoner_return": "호출인 경우 <b>entity</b>를 호출한 엔티티의 ID이고, 그렇지 않으면 <i>null</i>입니다.",
	"func_getSummons": "ID가 <b>엔티티</b>인 엔티티의 현재 활성 호출의 ID 목록을 반환합니다.",
	"func_getSummons_arg_1": "소환장이 반환될 개체의 ID입니다.",
	"func_getSummons_return": "ID가 <b>엔티티</b>인 엔티티의 호출 ID 목록입니다.",
	"func_getTeamID": "엔티티 <b>entity</b>의 팀 ID를 반환합니다.",
	"func_getTeamID_arg_1": "팀 ID를 반환할 엔터티의 ID입니다.",
	"func_getTeamID_return": "엔티티 <b>entity</b>의 팀 ID입니다.",
	"func_getTeamName": "엔티티 <b>entity</b>의 팀 이름을 반환합니다.",
	"func_getTeamName_arg_1": "팀 이름을 반환할 엔터티의 ID입니다.",
	"func_getTeamName_return": "<b>엔티티</b> 엔터티의 팀 이름입니다.",
	"func_getTotalLife": "ID가 <b>엔티티</b>인 엔티티의 전체 수명을 반환합니다. 총 수명을 검색하려면 매개변수 없이 getTotalLife()를 사용하십시오.",
	"func_getTotalLife_arg_1": "전체 수명을 반환할 개체의 ID입니다.",
	"func_getTotalLife_return": "엔터티의 전체 수명입니다.",
	"func_getTotalMP": "엔티티 <b>엔티티</b>의 최대 이동 포인트 수를 반환합니다.",
	"func_getTotalMP_arg_1": "최대 이동 포인트를 반환할 개체의 ID입니다.",
	"func_getTotalMP_return": "<b>개체</b>의 최대 이동 지점 수입니다.",
	"func_getTotalTP": "엔티티 <b>entity</b>의 최대 전환점 수를 반환합니다.",
	"func_getTotalTP_arg_1": "최대 전환점 수를 반환할 엔터티의 ID입니다.",
	"func_getTotalTP_return": "<b>entity</b> 개체의 최대 전환점 수입니다.",
	"func_getTP": "엔티티 <b>entity</b>의 전환점 수를 반환합니다. 매개변수 없이 getTP()를 사용하여 PT를 검색합니다.",
	"func_getTP_arg_1": "PT가 반환될 엔터티의 ID입니다.",
	"func_getTP_return": "<b>엔티티</b> 엔터티의 PT 번호입니다.",
	"func_getTurn": "현재 전투 턴을 반환합니다. 최대 턴 수는 #MAX_TURNS입니다.",
	"func_getTurn_return": "현재 전투의 차례입니다.",
	"func_getType": "엔티티 <b>entity</b>의 엔티티 유형을 반환합니다.",
	"func_getType_arg_1": "반환할 유형의 엔터티 ID입니다.",
	"func_getType_return": "<b>엔티티</b>의 엔티티 유형:<ul><li>부추인 경우 #ENTITY_LEEK.</li><li>'구근인 경우 #ENTITY_BULB'입니다.</li><li >#ENTITY_TURRET 포탑인 경우</li></ul>",
	"func_getWeapon": "현재 장착된 무기 <b>엔티티</b>를 반환합니다.",
	"func_getWeapon_arg_1": "현재 무기를 반환할 개체의 ID입니다.",
	"func_getWeapon_return": "<b>개체</b>에 현재 장착된 무기의 id, 개체에 장착된 무기가 없거나 개체가 존재하지 않는 경우 null입니다.",
	"func_getWeaponArea": "<b>무기</b> 무기의 효과 유형 영역을 반환합니다.",
	"func_getWeaponArea_arg_1": "영역 유형이 반환될 무기입니다.",
	"func_getWeaponArea_return": "AREA_* 상수 중 <b>무기</b> 무기의 영역 유형:\n<ul>\n<li>#AREA_POINT: 단일 정사각형 영역</li>\n<li>#AREA_LASER_LINE: 레이저 라인</li>\n<li>#AREA_CIRCLE_1: 직경 3셀의 원형 영역</li>\n<li>#AREA_CIRCLE_2: 직경이 5칸인 원형 영역</li>\n<li>#AREA_CIRCLE_3: 직경이 7인 원형 영역</li>\n</ul>",
	"func_getWeaponCost": "<b>무기</b> 무기의 TP 비용을 반환합니다.",
	"func_getWeaponCost_arg_1": "비용이 반환될 무기의 ID입니다.",
	"func_getWeaponCost_return": "<b>무기</b> 무기의 TP 비용입니다.",
	"func_getWeaponEffectiveArea": "무기 <b>무기</b>가 <b>from</b> 셀의 <b>cell</b> 셀에서 사용되는 경우 영향을 받는 셀 목록을 반환합니다.",
	"func_getWeaponEffectiveArea_arg_1": "테스트할 무기입니다.",
	"func_getWeaponEffectiveArea_arg_2": "표적 세포.",
	"func_getWeaponEffectiveArea_arg_3": "무기가 발사되는 셀입니다.",
	"func_getWeaponEffectiveArea_return": "영향을 받는 모든 셀의 ID를 포함하는 배열입니다.",
	"func_getWeaponPassiveEffects": "무기 <b>무기</b>의 패시브 효과를 반환합니다.",
	"func_getWeaponPassiveEffects_arg_1": "패시브 효과를 반환할 무기의 id.",
	"func_getWeaponPassiveEffects_return": "<b>weapon</b> 무기의 효과를 포함하는 배열입니다. 각 효과는 그 자체로 다음 형식의 배열입니다.\n[유형, 최소, 최대, 회전, 대상, 수정자]. 이러한 효과는 #getWeaponEffects에서 반환된 것과 동일합니다.",
	"func_getWeaponFailure": "<b>무기</b> 무기 실패 확률을 반환합니다.",
	"func_getWeaponFailure_arg_1": "실패율이 반환될 무기의 ID입니다.",
	"func_getWeaponFailure_return": "<b>무기</b> 무기 실패율, <b>0</b>에서 <b>100</b> 사이의 정수.",
	"func_getWeaponMaxRange": "<b>무기</b> 무기의 최대 사거리를 반환합니다.",
	"func_getWeaponMaxRange_arg_1": "최대 사거리를 반환할 무기의 id.",
	"func_getWeaponMaxRange_return": "<b>무기</b> 무기의 최대 사거리.",
	"func_getWeaponMaxScope": "<b>무기</b> 무기의 최대 사거리를 반환합니다.",
	"func_getWeaponMaxScope_arg_1": "최대 사거리를 반환할 무기의 id.",
	"func_getWeaponMaxScope_return": "<b>무기</b> 무기의 최대 사거리.",
	"func_getWeaponMinRange": "무기 <b>무기</b>의 최소 사거리를 반환합니다.",
	"func_getWeaponMinRange_arg_1": "최소 사거리를 반환할 무기의 ID입니다.",
	"func_getWeaponMinRange_return": "<b>무기</b> 무기의 최소 사거리.",
	"func_getWeaponMinScope": "무기 <b>무기</b>의 최소 사거리를 반환합니다.",
	"func_getWeaponMinScope_arg_1": "최소 사거리를 반환할 무기의 ID입니다.",
	"func_getWeaponMinScope_return": "<b>무기</b> 무기의 최소 사거리.",
	"func_getWeaponLaunchType": "LAUNCH_TYPE_* 상수 중 <b>weapon</b> 무기의 발사 모드를 반환합니다.",
	"func_getWeaponLaunchType_arg_1": "투척 모드가 반환될 무기의 ID입니다. 현재 장착한 무기가 기본값입니다.",
	"func_getWeaponLaunchType_return": "<b>무기</b> 무기의 던지기 모드입니다.",
	"func_getWeaponName": "<b>무기</b> 무기의 이름을 반환합니다.",
	"func_getWeaponName_arg_1": "이름을 반환할 무기의 ID입니다.",
	"func_getWeaponName_return": "<b>무기</b> 무기의 이름입니다.",
	"func_getWeapons": "ID가 <b>엔티티</b>인 엔티티의 무기를 반환합니다.",
	"func_getWeapons_arg_1": "무기를 반환할 개체의 ID입니다.",
	"func_getWeapons_return": "<b>개체</b> 무기 ID를 포함하는 배열입니다.",
	"func_getWeaponTargets": "무기 <b>weapon</b>이 셀 <b>cell</b>에서 사용되는 경우 영향을 받는 엔티티를 반환합니다.",
	"func_getWeaponTargets_arg_1": "테스트할 무기입니다.",
	"func_getWeaponTargets_arg_2": "표적 세포.",
	"func_getWeaponTargets_return": "영향을 받을 모든 엔터티의 ID를 포함하는 배열입니다.",
	"func_getWisdom": "ID가 <b>엔티티</b>인 엔티티의 지혜를 반환합니다. 매개변수 없이 getWisdom()을 사용하여 지혜를 검색하십시오.",
	"func_getWisdom_arg_1": "지혜를 돌려줄 개체의 id.",
	"func_getWisdom_return": "ID가 <b>개체</b>인 개체의 지혜.",
	"func_hypot": "변 x와 y가 있는 직각 삼각형의 빗변을 반환합니다. <i>sqrt(x**2&nbsp;+&nbsp;y**2)</i>와 같습니다.",
	"func_hypot_arg_1": "X 값입니다.",
	"func_hypot_arg_2": "Y 값입니다.",
	"func_hypot_return": "<i>sqrt(x**2 + y**2)</i>.",
	"func_inArray": "<b>요소</b> 요소가 <b>배열</b> 배열에 있는지 여부를 결정합니다.",
	"func_inArray_arg_1": "검색 테이블입니다.",
	"func_inArray_arg_2": "검색할 항목입니다.",
	"func_inArray_return": "요소가 배열에 있으면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_include": "현재 AI에 이름이 <b>ai</b>인 AI를 포함합니다. <br><br><b>경고</b>, include 함수는 메인 블록에서만 호출해야 하며 해당 매개변수는 직접 작성된 문자열이어야 합니다.\n코드에서. 자세한 내용은 튜토리얼의 섹션을 참조하세요. <a href='http://leekwars.com/help/tutorial#includes'>http://leekwars.com/help/tutorial#includes</a> .",
	"func_include_arg_1": "포함할 AI의 이름.",
	"func_indexOf": "<b>start</b> 위치에서 시작하여 <b>string</b> 문자열에서 <b>search</b> 문자열이 처음 나타나는 위치를 결정합니다.",
	"func_indexOf_arg_1": "검색이 수행될 문자열입니다.",
	"func_indexOf_arg_2": "검색할 문자열입니다.",
	"func_indexOf_arg_3": "검색의 시작 위치입니다.",
	"func_indexOf_return": "<b>start</b>의 <b>string</b>에서 <b>search</b>가 처음 나타나는 위치, 문자열을 찾을 수 없는 경우 -1.",
	"func_insert": "<b>배열</b>의 위치 <b>위치</b>에 <b>요소</b> 요소를 삽입합니다.",
	"func_insert_arg_1": "요소가 삽입될 배열입니다.",
	"func_insert_arg_2": "삽입할 항목입니다.",
	"func_insert_arg_3": "삽입 위치입니다.",
	"func_isAlive": "<b>엔티티</b>가 활성 상태인지 확인합니다. <i>getLife(<b>entity</b>) > 0</i>과 같습니다.",
	"func_isAlive_arg_1": "테스트할 엔터티의 ID입니다.",
	"func_isAlive_return": "<b>entity</b>가 살아있는 경우 <i>true</i>, 죽은 경우 <i>false</i>입니다.",
	"func_isAlly": "<b>개체</b>가 당신의 동맹인지 결정합니다.",
	"func_isAlly_arg_1": "테스트할 엔터티의 ID입니다.",
	"func_isAlly_return": "<b>엔티티</b>가 아군이거나 자신이면 <i>참</i>, 적이면 <i>거짓</i>입니다.",
	"func_isChip": "값이 칩을 나타내는 상수인지 여부를 결정합니다.<br><br>isChip(CHIP_RAGE) = true;<br>isChip(WEAPON_PISTOL) = false.",
	"func_isChip_arg_1": "결정할 숫자입니다.",
	"func_isChip_return": "값이 칩 상수인 경우 <i>true</i>입니다.",
	"func_isDead": "<b>엔티티</b>가 죽었는지 확인합니다. <i>getLife(<b>entity</b>) == 0</i>과 같습니다.",
	"func_isDead_arg_1": "테스트할 엔터티의 ID입니다.",
	"func_isDead_return": "<b>entity</b>가 죽은 경우 <i>true</i>, 살아 있으면 <i>false</i>입니다.",
	"func_isEmpty": "배열 <b>배열</b>이 비어 있는지 확인합니다. <i>count(<b>배열</b>) == 0</i>과 같습니다.",
	"func_isEmpty_arg_1": "테스트할 배열입니다.",
	"func_isEmptyCell": "셀이 비어 있는지 확인합니다.",
	"func_isEmptyCell_arg_1": "테스트할 셀입니다.",
	"func_isEmptyCell_return": "셀이 비어 있으면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_isEmpty_return": "배열이 비어 있으면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_isEnemy": "<b>개체</b>가 당신의 적인지 판단합니다.",
	"func_isEnemy_arg_1": "테스트할 엔터티의 ID입니다.",
	"func_isEnemy_return": "<b>엔티티</b>가 적이라면 <i>참</i>, 아군이거나 자신이라면 <i>거짓</i>입니다.",
	"func_isInlineChip": "<b>칩</b>을 온라인에서만 사용할 수 있는지 여부를 결정합니다.",
	"func_isInlineChip_arg_1": "테스트할 칩의 ID입니다.",
	"func_isInlineChip_return": "칩이 온라인 전용이면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_isInlineWeapon": "<b>무기</b> 무기를 온라인에서만 사용할 수 있는지 여부를 결정합니다.",
	"func_isInlineWeapon_arg_1": "테스트할 무기의 ID입니다.",
	"func_isInlineWeapon_return": "무기를 온라인에서만 사용할 수 있으면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_isLeek": "<b>cell</b> 셀의 내용이 엔터티인지 여부를 결정합니다.",
	"func_isLeek_arg_1": "테스트할 셀입니다.",
	"func_isLeek_return": "셀에 항목이 있으면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_isEntity": "<b>cell</b> 셀의 내용이 엔터티인지 여부를 결정합니다.",
	"func_isEntity_arg_1": "테스트할 셀입니다.",
	"func_isEntity_return": "셀에 항목이 있으면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_isObstacle": "셀 <b>셀</b>의 내용이 장애물인지 확인합니다.",
	"func_isObstacle_arg_1": "테스트할 셀입니다.",
	"func_isObstacle_return": "셀에 장애물이 있으면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_isOnSameLine": "두 셀 <b>cell1</b> 및 <b>cell2</b>가 같은 줄에 있는지 확인합니다.",
	"func_isOnSameLine_arg_1": "첫 번째 세포.",
	"func_isOnSameLine_arg_2": "두 번째 세포.",
	"func_isOnSameLine_return": "두 셀이 같은 줄에 있으면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_isStatic": "<b>엔티티</b>가 정적인지 여부를 반환합니다. 정적 엔터티는 이동하거나 이동할 수 없습니다.",
	"func_isStatic_arg_1": "테스트할 엔터티의 ID입니다.",
	"func_isStatic_return": "<b>entity</b>가 정적이면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_isSummon": "<b>엔티티</b>가 호출인지 여부를 반환합니다.",
	"func_isSummon_arg_1": "테스트할 엔터티의 ID입니다.",
	"func_isSummon_return": "<b>entity</b>가 호출이면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_isWeapon": "값이 무기를 나타내는 상수인지 결정합니다.<br><br>isWeapon(WEAPON_LASER) = true;<br>isWeapon(CHIP_TELEPORTATION) = false.",
	"func_isWeapon_arg_1": "결정할 숫자입니다.",
	"func_isWeapon_return": "값이 무기 상수인 경우 <i>true</i>입니다.",
	"func_join": "여러 요소를 문자열로 병합하고 <b>글루</b> 구분 기호로 구분합니다.",
	"func_join_arg_1": "병합할 항목의 배열입니다.",
	"func_join_arg_2": "요소 구분 기호입니다.",
	"func_join_return": "병합 결과 문자열입니다.",
	"func_jsonDecode": "문자열 <b>json</b>을 LeekScript 객체(숫자, 문자열, 배열...)로 디코딩합니다.",
	"func_jsonDecode_arg_1": "디코딩할 JSON 문자열입니다.",
	"func_jsonDecode_return": "디코딩된 LeekScript 개체입니다.",
	"func_jsonEncode": "<b>객체</b> 객체를 JSON 문자열로 인코딩합니다.",
	"func_jsonEncode_arg_1": "JSON으로 인코딩할 개체입니다.",
	"func_jsonEncode_return": "JSON 인코딩 문자열입니다.",
	"func_keySort": "키별로 <b>배열</b>을 <b>순서</b> 순서로 정렬합니다.",
	"func_keySort_arg_1": "정렬할 배열입니다.",
	"func_keySort_arg_2": "정렬 순서: #SORT_ASC 또는 #SORT_DESC.",
	"func_length": "<b>문자열</b>의 길이를 반환합니다.",
	"func_length_arg_1": "길이가 반환될 문자열입니다.",
	"func_length_return": "<b>문자열</b>의 길이입니다.",
	"func_lineOfSight": "<b>ignoredEntities</b> 엔터티를 무시하고 <b>시작</b> 셀과 <b>끝</b> 셀 사이의 시선을 확인합니다.",
	"func_lineOfSight_arg_1": "셀을 시작합니다.",
	"func_lineOfSight_arg_2": "표적 세포.",
	"func_lineOfSight_arg_3": "무시할 엔터티 또는 엔터티 목록입니다.",
	"func_lineOfSight_return": "시야가 확보되면 <i>true</i>를 반환합니다.",
	"func_listen": "이전 엔터티의 say() 배열을 [entity_id, message] 형식으로 반환합니다.",
	"func_listen_return": "이전 say()의 배열입니다.",
	"func_log": "숫자 <b>숫자</b>의 자연 로그를 계산합니다.",
	"func_log_arg_1": "범위의 숫자 ]0; +∞[.",
	"func_log_return": "<b>숫자</b>의 자연 로그입니다.",
	"func_log10": "숫자 <b>숫자</b>의 밑이 10인 로그를 계산합니다.",
	"func_log10_arg_1": "범위의 숫자 ]0; +∞[.",
	"func_log10_return": "<b>숫자</b>의 밑이 10인 로그입니다.",
	"func_log2": "숫자 <b>숫자</b>의 밑이 2인 로그를 계산합니다.",
	"func_log2_arg_1": "범위의 숫자 ]0; +∞[.",
	"func_log2_return": "<b>숫자</b>의 밑이 2인 로그입니다.",
	"func_mark": "매개변수에 표시된 회전 수만큼 바닥에 매개변수에 표시된 색상의 하나 이상의 셀을 표시합니다. 이 표시는 독립체의 사육자만 볼 수 있습니다.",
	"func_mark_arg_1": "표시할 셀 또는 여러 셀의 배열",
	"func_mark_arg_2": "마킹 색상",
	"func_mark_arg_3": "마킹 기간",
	"func_mark_return": "모든 것이 잘되면 true를 반환합니다.",
	"func_markText": "파라미터에 표시된 회전 수만큼 지면에 파라미터에 표시된 색상의 하나 이상의 셀에 텍스트를 씁니다. 이 텍스트는 독립체의 사육자만 볼 수 있습니다.",
	"func_markText_arg_1": "쓸 셀 또는 여러 셀의 배열",
	"func_markText_arg_2": "작성할 텍스트(최대 10자)",
	"func_markText_arg_3": "텍스트 색상",
	"func_markText_arg_4": "텍스트 길이",
	"func_markText_return": "모든 것이 잘되면 true를 반환합니다.",
	"func_max": "두 숫자 <b>a</b>와 <b>b</b> 사이의 더 큰 값을 반환합니다.",
	"func_max_arg_1": "숫자.",
	"func_max_arg_2": "숫자.",
	"func_max_return": "<b>a</b>와 <b>b</b> 사이의 큰 숫자.",
	"func_min": "두 숫자 <b>a</b>와 <b>b</b> 사이의 작은 값을 반환합니다.",
	"func_min_arg_1": "숫자.",
	"func_min_arg_2": "숫자.",
	"func_min_return": "<b>a</b>와 <b>b</b> 사이의 작은 숫자.",
	"func_moveAwayFrom": "최대 <b>mp</b> 이동 지점을 사용하여 엔티티를 다른 <b>엔티티</b>에서 멀리 이동합니다.",
	"func_moveAwayFrom_arg_1": "엔터티가 이동해야 하는 엔터티입니다.",
	"func_moveAwayFrom_arg_2": "사용할 수 있는 최대 MP 수입니다. 기본적으로 제한이 없습니다.",
	"func_moveAwayFrom_return": "사용된 이동 포인트 수입니다.",
	"func_moveAwayFromCell": "최대 <b>mp</b> 이동 포인트를 사용하여 엔터티를 한 <b>셀</b> 셀 멀리 이동합니다.",
	"func_moveAwayFromCell_arg_1": "엔터티가 이동해야 하는 셀입니다.",
	"func_moveAwayFromCell_arg_2": "사용할 수 있는 최대 MP 수입니다. 기본적으로 제한이 없습니다.",
	"func_moveAwayFromCell_return": "사용된 이동 포인트 수입니다.",
	"func_moveAwayFromCells": "최대 <b>mp</b> 이동 지점을 사용하여 엔티티를 <b>셀</b> 셀 집합에서 멀리 이동합니다.",
	"func_moveAwayFromCells_arg_1": "엔터티가 멀리 이동해야 하는 셀을 포함하는 배열입니다.",
	"func_moveAwayFromCells_arg_2": "사용할 수 있는 최대 MP 수입니다. 기본적으로 제한이 없습니다.",
	"func_moveAwayFromCells_return": "사용된 이동 포인트 수입니다.",
	"func_moveAwayFromLeeks": "최대 <b>mp</b> 이동 지점을 사용하여 엔티티를 <b>엔티티</b> 집합에서 멀리 이동합니다.",
	"func_moveAwayFromLeeks_arg_1": "엔터티가 이동해야 하는 엔터티의 ID가 포함된 배열입니다.",
	"func_moveAwayFromLeeks_arg_2": "사용할 수 있는 최대 MP 수입니다. 기본적으로 제한이 없습니다.",
	"func_moveAwayFromLeeks_return": "사용된 이동 포인트 수입니다.",
	"func_moveAwayFromEntities": "최대 <b>mp</b> 이동 지점을 사용하여 엔티티를 <b>엔티티</b> 집합에서 멀리 이동합니다.",
	"func_moveAwayFromEntities_arg_1": "엔터티가 이동해야 하는 엔터티의 ID가 포함된 배열입니다.",
	"func_moveAwayFromEntities_arg_2": "사용할 수 있는 최대 MP 수입니다. 기본적으로 제한이 없습니다.",
	"func_moveAwayFromEntities_return": "사용된 이동 포인트 수입니다.",
	"func_moveAwayFromLine": "최대 <b>mp</b> 이동 지점을 사용하여 두 개의 셀 <b>cell1</b> 및 <b>cell2</b>로 정의된 선에서 엔티티를 멀리 이동합니다.",
	"func_moveAwayFromLine_arg_1": "셀 1.",
	"func_moveAwayFromLine_arg_2": "셀 2.",
	"func_moveAwayFromLine_arg_3": "사용할 수 있는 최대 MP 수입니다. 기본적으로 제한이 없습니다.",
	"func_moveAwayFromLine_return": "사용된 이동 포인트 수입니다.",
	"func_moveToward": "최대 <b>mp</b> 이동 지점을 사용하여 개체를 다른 <b>개체</b>에 더 가깝게 만듭니다.",
	"func_moveToward_arg_1": "엔터티가 접근해야 하는 엔터티입니다.",
	"func_moveToward_arg_2": "사용할 수 있는 최대 MP 수입니다.",
	"func_moveToward_return": "사용된 이동 포인트 수입니다.",
	"func_moveTowardCell": "최대 <b>mp</b> 이동 지점을 사용하여 엔터티를 한 <b>셀</b> 셀 더 가깝게 이동합니다.",
	"func_moveTowardCell_arg_1": "기능이 접근해야 하는 셀입니다.",
	"func_moveTowardCell_arg_2": "사용할 수 있는 최대 MP 수입니다. 기본적으로 제한이 없습니다.",
	"func_moveTowardCell_return": "사용된 이동 포인트 수입니다.",
	"func_moveTowardCells": "최대 <b>mp</b> 이동 지점을 사용하여 엔터티를 <b>셀</b> 셀 집합에 더 가깝게 이동합니다.",
	"func_moveTowardCells_arg_1": "엔터티가 접근해야 하는 셀을 포함하는 배열입니다.",
	"func_moveTowardCells_arg_2": "사용할 수 있는 최대 MP 수입니다. 기본적으로 제한이 없습니다.",
	"func_moveTowardCells_return": "사용된 이동 포인트 수입니다.",
	"func_moveTowardLeeks": "최대 <b>mp</b> 이동 지점을 사용하여 엔티티를 <b>엔티티</b> 집합에 더 가깝게 만듭니다.",
	"func_moveTowardLeeks_arg_1": "엔터티가 접근해야 하는 엔터티의 ID를 포함하는 배열입니다.",
	"func_moveTowardLeeks_arg_2": "사용할 수 있는 최대 MP 수입니다. 기본적으로 제한이 없습니다.",
	"func_moveTowardLeeks_return": "사용된 이동 포인트 수입니다.",
	"func_moveTowardEntities": "최대 <b>mp</b> 이동 지점을 사용하여 엔티티를 <b>엔티티</b> 집합에 더 가깝게 만듭니다.",
	"func_moveTowardEntities_arg_1": "엔터티가 접근해야 하는 엔터티의 ID를 포함하는 배열입니다.",
	"func_moveTowardEntities_arg_2": "사용할 수 있는 최대 MP 수입니다. 기본적으로 제한이 없습니다.",
	"func_moveTowardEntities_return": "사용된 이동 포인트 수입니다.",
	"func_moveTowardLine": "최대 <b>mp</b> 이동 지점을 사용하여 엔티티를 두 개의 셀 <b>cell1</b> 및 <b>cell2</b>로 정의된 선에 더 가깝게 가져옵니다.",
	"func_moveTowardLine_arg_1": "셀 1.",
	"func_moveTowardLine_arg_2": "셀 2.",
	"func_moveTowardLine_arg_3": "사용할 수 있는 최대 MP 수입니다. 기본적으로 제한이 없습니다.",
	"func_moveTowardLine_return": "사용된 이동 포인트 수입니다.",
	"func_number": "값을 숫자로 변환합니다. 값이 문자열인 경우 숫자 함수는 값을 숫자로 변환하려고 시도하고 값이 이미 숫자인 경우 함수는 숫자를 반환하며 다른 유형의 경우 <i>null</i>을 반환합니다.",
	"func_number_arg_1": "숫자로 변환할 값입니다.",
	"func_number_return": "변환된 숫자입니다.",
	"func_pause": "기능을 사용하는 개체의 사육자에게만 전투를 일시 중지합니다.",
	"func_pop": "<b>배열</b> 배열에서 마지막 셀을 제거하고 해당 요소를 반환합니다.",
	"func_pop_arg_1": "마지막 상자가 삭제될 배열입니다.",
	"func_pop_return": "이전 마지막 상자에 있던 항목입니다.",
	"func_pow": "밑수를 지수 거듭제곱으로 올립니다.",
	"func_pow_arg_1": "베이스.",
	"func_pow_arg_2": "출품자.",
	"func_pow_return": "<b>밑</b>은 <b>지수</b> 거듭제곱입니다.",
	"func_push": "<b>배열</b>의 끝에 <b>요소</b> 요소를 추가합니다.",
	"func_pushAll": "<b>elements</b> 배열의 모든 요소를 <b>배열</b>의 끝에 추가합니다.",
	"func_pushAll_arg_1": "요소를 추가하려는 배열입니다.",
	"func_pushAll_arg_2": "추가할 항목입니다.",
	"func_push_arg_1": "요소를 추가하려는 배열입니다.",
	"func_push_arg_2": "추가할 항목입니다.",
	"func_rand": "0(포함)과 1(제외) 사이의 실제 난수를 반환합니다.",
	"func_randFloat": "<b>a</b>(포함)와 <b>b</b>(제외) 사이의 실제 난수를 반환합니다.",
	"func_randFloat_arg_1": "하한.",
	"func_randFloat_arg_2": "상한.",
	"func_randFloat_return": "범위 [a; 비[.",
	"func_randInt": "<b>a</b>(포함)와 <b>b</b>(제외) 사이의 임의의 정수를 반환합니다.",
	"func_randInt_arg_1": "하한.",
	"func_randInt_arg_2": "상한.",
	"func_randInt_return": "범위 [a; 비[.",
	"func_rand_return": "범위 [0; 1[.",
	"func_remove": "배열 <b>배열</b>에서 <b>position</b> 위치에 있는 요소를 제거하고 삭제된 요소를 반환합니다.",
	"func_remove_arg_1": "요소를 삭제하려는 배열입니다.",
	"func_remove_arg_2": "삭제할 요소의 위치입니다.",
	"func_removeElement": "<b>배열</b>에서 <b>요소</b>의 첫 번째 항목을 삭제합니다. 요소를 찾을 수 없으면 배열이 수정되지 않습니다.",
	"func_removeElement_arg_1": "요소를 삭제하려는 배열입니다.",
	"func_removeElement_arg_2": "찾아서 삭제할 항목입니다.",
	"func_removeKey": "키 <b>key</b>와 연결된 배열 <b>배열</b>에서 요소를 삭제합니다.",
	"func_removeKey_arg_1": "요소를 삭제하려는 배열입니다.",
	"func_removeKey_arg_2": "삭제할 항목과 연결된 키입니다.",
	"func_remove_return": "배열에서 제거된 요소입니다.",
	"func_replace": "문자열 <b>string</b>에서 모든 <b>search</b> 항목을 <b>replace</b>로 바꿉니다.",
	"func_replace_arg_1": "교체가 이루어지는 문자열입니다.",
	"func_replace_arg_2": "교체할 하위 문자열입니다.",
	"func_replace_arg_3": "교체 문자열.",
	"func_replace_return": "교체가 있는 결과 문자열입니다.",
	"func_resurrect": "CHIP_RESURRECTION 칩을 사용하여 <b>cell</b> 셀에서 ID가 <b>entity</b>인 죽은 개체를 부활시킵니다.",
	"func_resurrect_arg_1": "소생시킬 개체의 아이디.",
	"func_resurrect_arg_2": "엔터티가 다시 나타날 셀입니다.",
	"func_resurrect_return": "상수 USE_* 중 칩을 실행한 결과입니다.",
	"func_reverse": "<b>배열</b>을 뒤집습니다.",
	"func_reverse_arg_1": "반전할 테이블.",
	"func_round": "<b>숫자</b>의 반올림을 반환합니다.<br/>반올림은 #floor로, 반올림은 #ceil로 찾을 수 있습니다.",
	"func_round_arg_1": "반올림을 찾으려는 숫자입니다.",
	"func_round_return": "<b>숫자</b>로 반올림됩니다.",
	"func_say": "엔티티가 말하게하십시오.",
	"func_say_arg_1": "귀하의 엔티티가 경기장에서 발표할 메시지입니다.",
	"func_search": "<b>start</b> 위치에서 시작하여 배열 <b>array</b>에서 <b>element</b> 요소를 찾습니다.",
	"func_search_arg_1": "검색 테이블입니다.",
	"func_search_arg_2": "검색할 항목입니다.",
	"func_search_arg_3": "검색 시작 위치입니다.",
	"func_search_return": "<b>시작</b>부터 시작하여 <b>배열</b>에서 <b>요소</b> 요소의 첫 번째 발생 위치입니다. 요소를 찾을 수 없으면 null입니다.",
	"func_sendAll": "팀 전체에 메시지를 보내십시오.",
	"func_sendAll_arg_1": "보낼 메시지 유형(MESSAGE_* 상수 참조).",
	"func_sendAll_arg_2": "모든 값이 될 수 있는 메시지 매개변수.",
	"func_sendTo": "ID가 <b>엔티티</b>인 엔티티에 메시지를 보냅니다.",
	"func_sendTo_arg_1": "메시지를 보낼 엔터티의 ID입니다.",
	"func_sendTo_arg_2": "보낼 메시지 유형(MESSAGE_* 상수 참조).",
	"func_sendTo_arg_3": "모든 값이 될 수 있는 메시지 매개변수.",
	"func_sendTo_return": "전송에 성공하면 <i>true</i>, 오류가 발생하면 <i>false</i>입니다.",
	"func_setRegister": "<b>키</b> 레지스트리에 <b>값</b>을 저장합니다.\n키와 값은 각각 최대 <i>100</i> 및 <i>5000</i>자를 포함해야 하는 문자열입니다. 리크는 최대 <i>100</i>개의 레지스터를 가질 수 있으며, 모든 레지스터가\n이미 점유되어 있습니다.",
	"func_setRegister_arg_1": "값을 저장할 레지스트리 키입니다.",
	"func_setRegister_arg_2": "저장할 값입니다.",
	"func_setRegister_return": "작업이 성공하면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"func_setWeapon": "독립체에 무기 <b>무기</b>를 장착하십시오.",
	"func_setWeapon_arg_1": "장착할 무기의 이드.",
	"func_shift": "<b>배열</b>에서 첫 번째 셀을 제거하고 해당 요소를 반환합니다.",
	"func_shift_arg_1": "첫 번째 셀이 삭제될 배열입니다.",
	"func_shift_return": "이전 첫 번째 셀에 있던 요소입니다.",
	"func_show": "플레이어에게 1턴 동안 필드에서 <b>색상</b> 색상의 <b>셀</b>을 보여줍니다. 이 기능을 사용하는 비용은 1PT입니다.",
	"func_show_arg_1": "표시할 셀",
	"func_show_arg_2": "마킹 색상",
	"func_show_return": "모든 것이 잘되면 true를 반환합니다.",
	"func_shuffle": "보드를 무작위로 섞습니다.",
	"func_shuffle_arg_1": "셔플 보드.",
	"func_signum": "숫자 <b>숫자</b>의 부호를 결정합니다.",
	"func_signum_arg_1": "부호가 결정될 숫자입니다.",
	"func_signum_return": "숫자가 양수이면 1, 숫자가 0이면 0, 음수이면 -1입니다.",
	"func_sin": "매개변수로 전달된 각도 <b>angle</b>의 사인을 계산합니다.",
	"func_sin_arg_1": "사인을 계산할 각도",
	"func_sin_return": "각도 <b>angle</b>의 사인을 반환합니다.",
	"func_sort": "오름차순 또는 내림차순으로 <b>배열</b>을 정렬합니다. 기본 오름차순은 다음과 같습니다.\n<ul>\n<li>모든 부울(처음에는 false, 그 다음에는 true)</li>\n<li>모든 숫자(작은 것부터 큰 순서로)</li>\n<li>모든 채널(알파벳순)</li>\n<li>모든 null 요소.</li>\n</ul>",
	"func_sort_arg_1": "정렬할 배열입니다.",
	"func_sort_arg_2": "#SORT_ASC는 <b>배열</b>을 오름차순으로 정렬하거나 #SORT_DESC는 내림차순으로 정렬합니다.",
	"func_split": "문자열 <b>문자열</b>을 <b>구분 기호</b>로 구분된 하위 문자열로 분할합니다.",
	"func_split_arg_1": "절단 체인.",
	"func_split_arg_2": "한 요소에서 다른 요소로의 이동을 구분하는 문자열입니다.",
	"func_split_arg_3": "최대 배열 요소 수입니다.",
	"func_split_return": "찾은 하위 문자열을 포함하는 배열입니다.",
	"func_sqrt": "숫자 <b>숫자</b>의 제곱근을 계산합니다.",
	"func_sqrt_arg_1": "근이 계산될 숫자입니다.",
	"func_sqrt_return": "<b>숫자</b>의 제곱근.",
	"func_startsWith": "<b>문자열</b>이 <b>접두사</b>로 시작하는지 확인합니다.",
	"func_startsWith_arg_1": "문자열을 검색합니다.",
	"func_startsWith_arg_2": "검색된 접두어입니다.",
	"func_startsWith_return": "<b>문자열</b>이 <b>접두사</b>로 시작하는 경우 참입니다.",
	"func_string": "값을 문자열로 변환합니다.<br>\n값이 이미 문자열이면 반환됩니다.<br>\n값이 숫자 x이면 \"x\"가 반환됩니다.<br>\n값이 배열이면 \"[key1: value1, key2: value2, ...]\" 형식의 문자열이 반환됩니다.<br>\n값이 부울인 경우 \"true\" 및 \"false\"가 각각 true 및 false로 반환됩니다.<br>\n값이 null이면 \"null\"이 반환됩니다.",
	"func_string_arg_1": "문자열로 변환할 값입니다.",
	"func_string_return": "변환된 문자열입니다.",
	"func_subArray": "<b>start</b> 위치에서 시작하여 <b>end</b> 위치에서 끝나는 <b>array</b>의 하위 배열을 반환합니다.",
	"func_subArray_arg_1": "소스 테이블.",
	"func_subArray_arg_2": "시작 색인.",
	"func_subArray_arg_3": "색인을 종료합니다.",
	"func_subArray_return": "하위 배열.",
	"func_substring": "인덱스 <b>start</b>에 있는 문자에서 시작하여 크기가 <b>길이</b>인 문자열 <b>문자열</b>의 하위 문자열을 반환합니다.",
	"func_substring_arg_1": "절단 체인.",
	"func_substring_arg_2": "시작 문자 인덱스.",
	"func_substring_arg_3": "하위 문자열의 길이입니다.",
	"func_substring_return": "하위 문자열.",
	"func_sum": "배열 <b>배열</b>의 모든 숫자 요소의 합계를 반환합니다.",
	"func_sum_arg_1": "합산할 표입니다.",
	"func_summon": "IA에 대해 함수 <b>ai</b>를 갖는 셀 <b>셀</b>의 칩 <b>칩</b>에 의해 결정된 엔티티를 호출합니다.",
	"func_summon_arg_1": "소환에 사용되는 칩. 칩은 소환형 칩이어야 하며 소환 기능을 사용하는 개체에 장착되어 있어야 합니다.",
	"func_summon_arg_2": "세포나 소환장이 나타나야 합니다.",
	"func_summon_arg_3": "함수로서의 호출 AI.",
	"func_summon_return": "소환 기능은 #useChip 기능과 동일한 리턴값을 가집니다.",
	"func_sum_return": "배열 요소의 합입니다.",
	"func_tan": "각도 <b>angle</b>의 탄젠트를 계산합니다(라디안 단위).",
	"func_tan_arg_1": "라디안 단위의 각도.",
	"func_tan_return": "<b>각도</b>의 탄젠트.",
	"function_category_array": "기울기",
	"function_category_chip": "벼룩",
	"function_category_color": "색상",
	"function_category_fight": "싸움",
	"function_category_leek": "부추",
	"function_category_map": "테이블",
	"function_category_field": "지면",
	"function_category_math": "숫자",
	"function_category_network": "회로망",
	"function_category_string": "쇠사슬",
	"function_category_utils": "유용",
	"function_category_weapon": "무기",
	"func_toDegrees": "각도 <b>라디안</b>을 도로 변환합니다.",
	"func_toDegrees_arg_1": "라디안 값",
	"func_toDegrees_return": "각도(도).",
	"func_toLower": "<b>문자열</b>의 대문자를 소문자로 변환합니다.",
	"func_toLower_arg_1": "변환할 문자열",
	"func_toLower_return": "소문자 문자열",
	"func_toRadians": "각도 <b>도</b>를 라디안으로 변환합니다.",
	"func_toRadians_arg_1": "도 단위의 값",
	"func_toRadians_return": "라디안 단위의 각도.",
	"func_toUpper": "<b>문자열</b>의 소문자를 대문자로 변환합니다.",
	"func_toUpper_arg_1": "변환할 문자열입니다.",
	"func_toUpper_return": "대문자 문자열.",
	"func_typeOf": "#TYPE_NULL, #TYPE_NUMBER, #TYPE_BOOLEAN, #TYPE_STRING, #TYPE_ARRAY, #TYPE_FUNCTION 유형 중에서 값 <b>값</b>의 유형을 반환합니다.",
	"func_typeOf_arg_1": "반환할 유형의 값입니다.",
	"func_typeOf_return": "<b>값</b>의 유형입니다.",
	"func_unshift": "<b>배열</b>의 시작 부분에 <b>요소</b> 요소를 추가합니다.",
	"func_unshift_arg_1": "요소가 추가될 배열입니다.",
	"func_unshift_arg_2": "추가할 항목입니다.",
	"func_useChip": "엔티티 <b>entity</b>에 칩 <b>chip</b>을(를) 사용합니다.",
	"func_useChip_arg_1": "사용할 칩.",
	"func_useChip_arg_2": "대상 엔터티.",
	"func_useChipOnCell": "셀 <b>셀</b>에 칩 <b>칩</b>을(를) 사용하십시오.",
	"func_useChipOnCell_arg_1": "사용할 칩.",
	"func_useChipOnCell_arg_2": "표적 세포.",
	"func_useChipOnCell_return": "공격이 시작된 경우 0보다 큰 값입니다.",
	"func_useChip_return": "UseChip의 반환 값은 다음과 같습니다.<br>\n<ul>\n<li>#USE_CRITICAL, 치명타 시</li>\n<li>#USE_SUCCESS, 성공한 경우</li>\n<li>#USE_FAILED, 실패 시</li>\n<li>#USE_INVALID_TARGET, 대상이 존재하지 않는 경우</li>\n<li>#USE_NOT_ENOUGH_TP, 엔터티에 TP가 충분하지 않은 경우</li>\n<li>#USE_INVALID_COOLDOWN, 칩을 아직 사용할 수 없는 경우</li>\n<li>#USE_INVALID_POSITION, 범위가 나쁘거나 시야가 명확하지 않은 경우</li>\n</ul>",
	"func_useWeapon": "<b>개체</b> 개체에 선택한 무기를 사용합니다.",
	"func_useWeapon_arg_1": "대상 엔터티.",
	"func_useWeaponOnCell": "<b>셀</b> 셀에서 선택한 무기를 사용합니다.",
	"func_useWeaponOnCell_arg_1": "표적 세포.",
	"func_useWeaponOnCell_return": "공격이 시작된 경우 0보다 큰 값입니다.",
	"func_useWeapon_return": "UseWeapon의 반환 값은 다음과 같습니다.<br>\n<ul>\n<li>#USE_CRITICAL, 치명타 시</li>\n<li>#USE_SUCCESS, 성공한 경우</li>\n<li>#USE_FAILED, 실패 시</li>\n<li>#USE_INVALID_TARGET, 대상이 존재하지 않는 경우</li>\n<li>#USE_NOT_ENOUGH_TP, 엔터티에 TP가 충분하지 않은 경우</li>\n<li>#USE_INVALID_POSITION, 범위가 나쁘거나 시야가 명확하지 않은 경우</li>\n</ul>",
	"func_weaponNeedLos": "<b>무기</b> 무기를 발사하려면 시선이 필요한지 여부를 반환합니다.",
	"func_weaponNeedLos_arg_1": "테스트할 무기의 ID입니다.",
	"func_weaponNeedLos_return": "<b>무기</b> 무기가 발사하려면 시야가 필요하면 <i>true</i>, 그렇지 않으면 <i>false</i>입니다.",
	"items": "항목",
	"max_cores": "최대",
	"max_level": "최대",
	"min_cores": "최소 하트",
	"min_level": "최소 레벨",
	"n_operations": "<b>{0}</b> 작업",
	"parameters": "설정",
	"return": "피드백",
	"value": "값",
	"variable_operations": "<b>변수</b> 작업",
	"arg_type_9": "패키지",
	"arg_type_10": "간격",
	"function_category_set": "패키지",
	"function_category_interval": "간격",
	"arg_type_96": "set<entier>"
}

	i18n.mergeLocaleMessage("ko", { "doc": messages })
	