import { render, staticRenderFns } from "./effect.vue?vue&type=template&id=797a5aa0&scoped=true&lang=html"
import script from "./effect.vue?vue&type=script&lang=ts"
export * from "./effect.vue?vue&type=script&lang=ts"
import style0 from "./effect.vue?vue&type=style&index=0&id=797a5aa0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "797a5aa0",
  null
  
)

export default component.exports